import { Menu, MenuItem, Typography, useMediaQuery } from "@mui/material";
import { useContext, useState } from "react";
import "./ordersMenuBar.css";
import { ExpressButton } from "../../../core/expressButton/expressButton.component";
import { ReactComponent as ArrowDropDownIcon } from "../../../assets/ArrowBlueDropDownIcon.svg";
import {
  ordersMenuBarNewOrderModel,
  ordersMenuBarSupplyOrderModel,
} from "./ordersMenuBar.model";
import { useHistory } from "react-router-dom";
import {
  RolesPermissionContext,
  RolesPermissionContextType,
} from "../../../context/RolesPermissionContext";
import { IPermissionData } from "../../../RolesPermission/RolesPermission.model";
import { IOrdersMenuBarSupplyOrder } from "./ordersMenuBar.interface";
import { AuthContext, AuthContextType } from "../../../context/AuthContext";
import {
  InpatientOrderContext,
  InpatientOrderContextType,
} from "../../../context/InpatientOrderContext";
import {
  InpatientSupplyOrderContext,
  InpatientSupplyOrderContextType,
} from "../../../context/InpatientSupplyOrderContext";
import { InpatientSupplyOrderPageSection } from "../../inpatientSupplyOrder/inpatientSupplyOrder.enum";

interface Props {
  redirectToSupplyOrder: Function;
}

export const OrdersMenuBar = ({ redirectToSupplyOrder }: Props) => {
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
  const [openMenuFlag, setOpenMenuFlag] = useState<boolean>(false);
  const permissionsObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );
  const roles = permissionsObj && permissionsObj.mappedRolesPermissionData;
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const inpatientObj = useContext<InpatientOrderContextType | null>(
    InpatientOrderContext
  );
  const inPatientSupplyOrderObj =
    useContext<InpatientSupplyOrderContextType | null>(
      InpatientSupplyOrderContext
    );
  const isMobileScreen = useMediaQuery("(width:768px) ");
  const isTabScreen = useMediaQuery("(width:1024px) ");
  const history = useHistory();
  const isPostAcuteCheck =
    authObj && authObj?.registeredFaciltyAddress?.isPostAcute;
  const isShowSupplyOrderHeader =
    roles &&
    (roles.IsShowSupplyOrderButton ||
      (isPostAcuteCheck
        ? false
        : roles.IsShowInPatientSupplyOrderButton ||
          roles.IsShowInPatientStockSupplyOrderButton));
  const isShowVACOrderHeader =
    roles &&
    (roles.IsShowVacOrderButton ||
      (isPostAcuteCheck ? false : roles.IsShowInPatientOrderButton));

  const handleOpenActionMenu = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setOpenMenuFlag(!openMenuFlag);
    setAnchorElement(anchorElement ? null : event.currentTarget);
  };

  const handleCloseActionMenu = () => {
    setOpenMenuFlag(false);
    setAnchorElement(null);
  };

  const handleNavigation = (path: string) => {
    if (path.includes("/orders/supplyOrderList")) {
      redirectToSupplyOrder();
    } else if (path.includes("/orders/inpatientOrder")) {
      inpatientObj?.resetContext();
      inPatientSupplyOrderObj?.resetContext();
      inpatientObj?.setIsFromStartNewOrder(true);
      history.push({
        pathname: "/orders/inpatientOrder",
        state: {
          allProducts: [],
          product: null,
        },
      });
    } else if (path.includes("/orders/inpatientSupplyOrder")) {
      inPatientSupplyOrderObj?.resetContext();
      history.push({
        pathname: "/orders/inpatientSupplyOrder",
      });
    } else if (path.includes("/orders/inpatientStockSupplyOrder")) {
      inPatientSupplyOrderObj?.resetContext();
      inPatientSupplyOrderObj?.setPage(
        InpatientSupplyOrderPageSection.FIRST_PAGE
      );
      history.push({
        pathname: "/orders/inpatientStockSupplyOrder",
        state: { isFromStockSupplies: true },
      });
    } else {
      history.push(path);
    }
  };

  return (
    <div className="order-menu-bar-main-div">
      {(isShowVACOrderHeader || isShowSupplyOrderHeader) && (
        <div className="order-menu-start-new-order-div">
          <ExpressButton
            id="menuBarButtonId"
            clickHandler={handleOpenActionMenu}
            variant="text"
            parentClass="order-menu-start-new-order"
            endIcon={<ArrowDropDownIcon />}
            testId="order-menu-start-new-order-testId"
          >
            Start New Order
          </ExpressButton>
        </div>
      )}
      <Menu
        anchorEl={anchorElement}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          paper: "menu-bar-paperstyle",
        }}
        data-testid="menu-bar-testId"
        id="order-menu-bar"
        open={openMenuFlag}
        onClose={handleCloseActionMenu}
        sx={{
          top: isMobileScreen ? "46px" : isTabScreen ? "70px" : "54px",
        }}
        MenuListProps={{
          "aria-labelledby": "menuBarButtonId",
          sx: {
            width: anchorElement && anchorElement.offsetWidth,
          },
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className="content-menuBar">
          <>
            {isShowVACOrderHeader && (
              <Typography
                className="menu-bar-new-order-title"
                data-testid="menu-bar-new-order-title-testId"
              >
                Order V.A.C.<sup>®</sup> Therapy
              </Typography>
            )}
            {ordersMenuBarNewOrderModel.map(
              (newOrderObject: IOrdersMenuBarSupplyOrder) => (
                <>
                  {roles &&
                    authObj &&
                    roles[
                      newOrderObject.accessorKey as keyof IPermissionData
                    ] &&
                    (newOrderObject.accessorKey === "IsShowVacOrderButton" ||
                      newOrderObject.isPostAcute === isPostAcuteCheck) && (
                      <MenuItem
                        id="orders-menu-bar-vacOrder-id"
                        className="order-menu-bar-OrderLink"
                        onClick={() => {
                          handleNavigation(newOrderObject.path);
                        }}
                        data-testid="order-menu-bar-newOrder-testId"
                      >
                        <img src={newOrderObject.icon} />
                        {newOrderObject.labelText}
                      </MenuItem>
                    )}
                </>
              )
            )}
            {isShowSupplyOrderHeader && (
              <Typography
                className="menu-bar-new-order-title"
                data-testid="menu-bar-supply-order-title-testId"
              >
                Order Supplies
              </Typography>
            )}
            {ordersMenuBarSupplyOrderModel.map(
              (supplyOrderObject: IOrdersMenuBarSupplyOrder) => (
                <>
                  {roles &&
                    authObj &&
                    roles[
                      supplyOrderObject.accessorKey as keyof IPermissionData
                    ] &&
                    (supplyOrderObject.accessorKey ===
                      "IsShowSupplyOrderButton" ||
                      supplyOrderObject.isPostAcute === isPostAcuteCheck) &&
                    (!supplyOrderObject.isHide ||
                      (supplyOrderObject.isHide &&
                        !supplyOrderObject.isHide)) && (
                      <MenuItem
                        id="orders-menu-bar-supplyOrder-id"
                        className="order-menu-bar-OrderLink"
                        onClick={() => {
                          handleNavigation(supplyOrderObject.path);
                        }}
                        data-testid="order-menu-bar-supplyOrder-testId"
                      >
                        <img src={supplyOrderObject.icon} alt="" />
                        {supplyOrderObject.labelText}
                      </MenuItem>
                    )}
                </>
              )
            )}
          </>
        </div>
      </Menu>
    </div>
  );
};
