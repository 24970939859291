import "./prescriberSearch.css";
import { IPrescriberInformation } from "../../../prescriberInformation.interface";
import { PrescriberResult } from "./prescriberResult/prescriberResult.component";

export interface ISearchPrescriber {
  changeSearchType: Function;
  nationalRegistries: IPrescriberInformation[];
  selectButtonAction: Function;
  selectedNpi?: string;
}
export const SearchPrescriber = ({
  changeSearchType,
  nationalRegistries,
  selectButtonAction,
  selectedNpi,
}: ISearchPrescriber) => {
  return (
    <div
      className="prescriber-search-component"
      data-testid="prescriber-search-component"
    >
      <p
        className="prescriber-search-header"
        data-testid="prescriber-search-header"
        id="prescriber-search-header"
      >
        Prescriber Search
      </p>
      <div className="prescriber-search-results">
        <PrescriberResult
          changeSearchType={changeSearchType}
          nationalRegistries={nationalRegistries}
          selectButtonAction={selectButtonAction}
          selectedNpi={selectedNpi}
        />
      </div>
    </div>
  );
};
