import { PopperDiv, ActionsDiv } from "./patientActions.style";
import { IPatientActionsInterface } from "./patientActions.interface";
import { IMenuAction } from "../patient.interface";
import { useEffect } from "react";

export const PatientActions = ({
  setSelectedValue,
  clickedOutside,
  menuData,
}: IPatientActionsInterface) => {
  const handleOutsideClick = (event: MouseEvent) => {
    clickedOutside(event);
  };

  const handleActionClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setSelectedValue(event, event.currentTarget.id);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [handleOutsideClick]);

  const patientActionOptionList = menuData?.map(
    (x: IMenuAction, index: number) => (
      <ActionsDiv
        key={index.toString()}
        onMouseDown={handleActionClick}
        id={x.text}
      >
        {x.text}
      </ActionsDiv>
    )
  );

  return (
    <PopperDiv data-testid="dotMenuTest">{patientActionOptionList}</PopperDiv>
  );
};
