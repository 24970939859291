import "./vacTherapyUnit.css";
import parse from "html-react-parser";
import { Grid, useMediaQuery } from "@mui/material";
import { makeCapitalEachWordInString } from "../../../../../util/utilityFunctions";
import { VacTherapyUnitReviewRequest } from "./reviewRequest/vacTherapyUnitReviewRequest.component";
import { ICustomerPlusListingDetails } from "../../../customerPlusInventory/customerPlusInventory.interface";

export interface IVacTherapyUnit {
  isReviewRequest?: boolean;
  selectedProduct: ICustomerPlusListingDetails;
  isSolventumOrder?: boolean;
}

export const VacTherapyUnit = ({
  isReviewRequest = false,
  selectedProduct,
  isSolventumOrder,
}: IVacTherapyUnit) => {
  const isMobileScreen = useMediaQuery("(max-width:475px)");
  const isBiggerMobileScreen = useMediaQuery("(max-width:600px)");

  const showTitleAndValue = (
    title: string,
    value: string | null | undefined,
    testId: string
  ) => {
    return (
      <div className="show-title-and-value-div">
        <p
          className="show-title-and-value-title"
          data-testid={`${testId}-title`}
        >
          {title}
        </p>
        <p
          className="show-title-and-value-value"
          data-testid={`${testId}-value`}
        >
          {value && value.length > 0 ? value : "--"}
        </p>
      </div>
    );
  };

  return (
    <div
      className="create-service-request-vac-therapy-unit-component"
      data-testid="create-service-request-vac-therapy-unit-component"
      id="create-service-request-vac-therapy-unit-component"
    >
      {isReviewRequest ? (
        <VacTherapyUnitReviewRequest
          selectedProduct={selectedProduct}
          isSolventumOrder={isSolventumOrder}
        />
      ) : (
        <div className="create-service-request-vac-therapy-unit-div">
          <p
            className="vac-therapy-unit-title"
            data-testid="vac-therapy-unit-title"
          >
            {parse("V.A.C.<sup>®</sup> Therapy Unit")}
          </p>
          <Grid className="vac-therapy-unit-grid-container" container>
            <Grid
              className="vac-therapy-unit-grid-item"
              item
              xs={isMobileScreen ? 12 : isBiggerMobileScreen ? 6 : 4}
            >
              {showTitleAndValue(
                "Unit Tag ID",
                makeCapitalEachWordInString(selectedProduct.unitTagId),
                "unit-tag"
              )}
            </Grid>
            <Grid
              className="vac-therapy-unit-grid-item"
              item
              xs={isMobileScreen ? 6 : isBiggerMobileScreen ? 3 : 4}
            >
              {showTitleAndValue(
                "Product",
                selectedProduct.productNameDescription,
                "product-name"
              )}
            </Grid>
            <Grid
              className="vac-therapy-unit-grid-item"
              item
              xs={isMobileScreen ? 6 : isBiggerMobileScreen ? 3 : 4}
            >
              {showTitleAndValue(
                "Serial Number",
                selectedProduct.serialNumber,
                "serial-number"
              )}
            </Grid>
            <Grid className="vac-therapy-unit-grid-item" item xs={12}>
              {showTitleAndValue(
                "Location",
                selectedProduct.currentLocation,
                "location"
              )}
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
};
