import { MultipleActionsProps } from "../../../../../newOrder/newOrderWoundInfoStepper/newOrderWoundInfo.interface";

export const woundAssessComorboditiesData: MultipleActionsProps[] = [
  {
    value: "Diabetes Mellitus (DM)",
    label: "Diabetes Mellitus (DM)",
    code: "DIABETES",
    selected: false,
  },
  {
    value: "End-Stage Renal Disease (ESRD)",
    label: "End-Stage Renal Disease (ESRD)",
    code: "ESRD",
    selected: false,
  },
  {
    value: "Peripheral vascular disease (PVD)",
    label: "Peripheral Vascular Disease (PVD)",
    code: "PVD",
    selected: false,
  },
  {
    value: "Peripheral arterial disease (PAD)",
    label: "Peripheral Arterial Disease (PAD)",
    code: "PAD",
    selected: false,
  },
  {
    value: "Immunocompromised",
    label: "Immunocompromised",
    code: "IMMUNOCOMPROMISED",
    selected: false,
  },
  {
    value: "Obesity",
    label: "Obesity",
    code: "OBESITY",
    selected: false,
  },
  {
    value: "Depression",
    label: "Depression",
    code: "DEPRESSION",
    selected: false,
  },
  { value: "Smoking", label: "Smoking", code: "SMOKING", selected: false },
  {
    value: "Paraplegia",
    label: "Paraplegia",
    code: "Paraplegia",
    selected: false,
  },
  {
    value: "Quadraplegia",
    label: "Quadraplegia",
    code: "Quadraplegia",
    selected: false,
  },
  {
    value: "Wheelchair dependent",
    label: "Wheelchair Dependent",
    code: "Wheelchair Dependent",
    selected: false,
  },
  { value: "Bedbound", label: "Bedbound", code: "Bedbound", selected: false },
  {
    value: "Not applicable (N/A)",
    label: "Not Applicable (N/A)",
    code: "Not Applicable (N/A)",
    selected: false,
  },
];
