import { ValidationStatus } from "../../../../core/interfaces/input.interface";
import { IEnterPickUpInformationLocation } from "./enterPickupInformation.interface";

export const defaultPickUpInformation: IEnterPickUpInformationLocation = {
  therapyDischargeDate: {
    componentId: "acuteOrder-pickup-request-therapy-discharge-date",
    id: "acuteOrder-pickup-request-therapy-discharge-date-input",
    required: true,
    order: 1,
    title: "Therapy Discharge Date",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  pickupLocation: {
    componentId: "acuteOrder-pickup-request-pickup-location",
    id: "acuteOrder-pickup-request-pickup-location-input",
    required: true,
    order: 2,
    title: "Update Storage Location",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  roomNo: {
    componentId: "acuteOrder-pickup-request-room-number",
    id: "acuteOrder-pickup-request-room-number-input",
    required: false,
    order: 3,
    title: "Patient Room Number",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  specialInstructions: {
    componentId: "acuteOrder-pickup-request-special-instructions",
    id: "acuteOrder-pickup-request-special-instructions-input",
    isDefaultValid: true,
    required: false,
    order: 4,
    title: "Special Instructions",
    valid: ValidationStatus.VALID,
    value: "",
  },
};
