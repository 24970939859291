import SearchIcon from "@mui/icons-material/Search";
import { InputBase } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { format } from "react-string-format";
import { useDebounce } from "use-debounce";
import { AuthContext, AuthContextType } from "../../../../context/AuthContext";
import {
  ProfileFormContext,
  ProfileFormContextType,
} from "../../../../context/ProfileFormContext";
import { CustomDropDown } from "../../../../core/customDropdown/customDropdown.component";
import {
  getSalesTerritoryDetailsForArea,
  getSalesTerritoryDetailsForRegion,
} from "../../../../util/3meService";
import { getdropDownContent } from "../../../../util/dropDownService";
import { DD_ALL_ROLES } from "../../../../util/staticText";
import {
  changeDateFormatOnlyDate,
  useSortableTable,
} from "../../../../util/utilityFunctions";
import { ISaleManageUserListTableData } from "../../salesManageUsers/salesManageUserTable/saleManageUsersListTable.interface";
import { TerritoryListTable } from "./territoryListTable/territoryListTable.component";
import {
  ISalesPerson,
  ITerritoryDetailList,
} from "./territoryListTable/territoryListTable.interface";
import { columns } from "./territoryListTable/territoryListTable.model";
import "./viewTerritories.css";
import { getSalesTerritories } from "../../../../util/userService";
import SalesRegions from "../../salesRegionList/SalesRegionList.Component";

import {
  RolesPermissionContext,
  RolesPermissionContextType,
} from "../../../../context/RolesPermissionContext";

interface Props {
  setLoaderFlag: Function;
}

const ViewTerritories = ({ setLoaderFlag }: Props) => {
  const [searchInput, setSearchInput] = useState<string>("");
  const [selectedRoleType, setSelectedRoleType] = useState<string>("");
  const [roleText, setRoleText] = useState<string[]>([]);
  const [debouncedText] = useDebounce(searchInput, 500);
  const [searchedInput, setSearchedInput] = useState<string>("");
  const [originalData, setOriginalData] = useState<ITerritoryDetailList[]>([]);
  const [sortData, setSortData, handleSortedData] = useSortableTable(
    [],
    columns
  );
  const history = useHistory();
  const profileForm = useContext<ProfileFormContextType | null>(
    ProfileFormContext
  );
  const AuthObj = useContext<AuthContextType | null>(AuthContext);
  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );

  const validateAndSetRoleData = (e: any) => {
    setSelectedRoleType(e.target.value);
    filteredUsers(debouncedText, e.target.value);
  };

  const handleSearch = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const re = /^[a-zA-Z0-9- ]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setSearchInput(e.target.value);
    }
  };

  const fetchDropDownContent = async () => {
    try {
      const ddContent = format("{0}", DD_ALL_ROLES ?? "");
      const data = await getdropDownContent(ddContent);
      if (data.items.length > 0) {
        const roleTypeObject = data.items.filter(
          (item: { name: string }) => item.name === DD_ALL_ROLES
        );
        const roleTypeData = roleTypeObject[0].data.sort(
          (a: { order: number }, b: { order: number }) =>
            a.order > b.order ? 1 : -1
        );
        setRoleText(roleTypeData.map((x: { text: string }) => x.text));
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getTerritoryDetailForRegion = async (regionCode: string) => {
    setLoaderFlag(true);
    const result: any = await Promise.all([
      getSalesTerritoryDetailsForRegion(regionCode),
      fetchDropDownContent(),
    ]);
    setLoaderFlag(false);
    setOriginalData(result[0].data);
    setSortData(result[0].data);
  };
  const getTerritoryDetailForArea = async (areaCode: string) => {
    setLoaderFlag(true);
    const result: any = await Promise.all([
      getSalesTerritoryDetailsForArea(areaCode),
      fetchDropDownContent(),
    ]);
    setLoaderFlag(false);
    setOriginalData(result[0].data);
    setSortData(result[0].data);
  };

  const filteredUsers = (searchParam: string, role: string) => {
    setSearchedInput(searchParam);
    let searchRole = "";
    if (role !== "All roles") {
      searchRole = role;
    }

    if (originalData && originalData.length > 0) {
      let filteredUsers = originalData;
      if (searchRole.length > 0) {
        filteredUsers = filteredUsers.filter((user: ITerritoryDetailList) =>
          user.roles.includes(searchRole)
        );
      }
      if (searchParam.length > 0) {
        filteredUsers = filteredUsers.filter(
          (user: ITerritoryDetailList) =>
            user.territoryCode
              .toLocaleLowerCase()
              .includes(searchParam.toLowerCase()) ||
            user.territoryName
              .toLocaleLowerCase()
              .includes(searchParam.toLowerCase()) ||
            JSON.stringify(
              user.salesPeople.map(({ userId, ...rest }) => {
                return rest;
              })
            )
              .toLocaleLowerCase()
              .includes(searchParam.toLowerCase())
        );
      }
      setSortData(filteredUsers);
    }
  };
  const getSalesTerritory = async () => {
    if (
      AuthObj &&
      AuthObj.userProfile &&
      AuthObj.userProfile.userName &&
      AuthObj.userProfile.userName.length > 0
    ) {
      const reqBody = {
        userName: AuthObj.userProfile.userName,
        CurrentDate: changeDateFormatOnlyDate(new Date().toString()),
      };
      const salesTerritories = await getSalesTerritories(reqBody);
      profileForm?.setTerritoryData(salesTerritories.data);
    }
  };

  useEffect(() => {
    if (
      AuthObj?.userProfile?.area &&
      permissionObj?.mappedRolesPermissionData?.IsProdManagerRole
    ) {
      getTerritoryDetailForArea(AuthObj?.userProfile.area!);
    }
    if (AuthObj?.userProfile?.region) {
      getTerritoryDetailForRegion(AuthObj?.userProfile.region!);
    }
  }, [AuthObj?.userProfile]);

  const validateAndSetAreaData = (e: any) => {
    setSearchInput("");
    setSelectedRoleType("");
    AuthObj?.setUserProfile({
      ...AuthObj?.userProfile!,
      area: e.target.value,
    });
  };

  useEffect(() => {
    if (
      (debouncedText.length === 0 && searchedInput !== debouncedText) ||
      debouncedText.length > 0
    ) {
      filteredUsers(debouncedText, selectedRoleType);
    }
  }, [debouncedText]);

  const openTerritoryDetail = (data: ITerritoryDetailList) => {
    history.push({
      pathname: "/administration/manageTerritories/territoryDetail",
      state: {
        selectedTerritory: data,
      },
    });
  };

  const openSaleUserDetails = (
    salesPersonObj: ISalesPerson,
    data: ITerritoryDetailList
  ) => {
    let selectedSalePeople: ISaleManageUserListTableData = {
      userID: salesPersonObj.userId,
      firstName: salesPersonObj.firstName,
      lastName: salesPersonObj.lastName,
      roles: data.roles,
      region: data?.region!,
      territories: [data.territoryCode + "-" + data.territoryName],
    };
    history.push({
      pathname: "/administration/salesManageUsers/saleUserProfile",
      state: {
        selectedSalePeople: selectedSalePeople,
      },
    });
  };

  return (
    <>
      <div className="manage-territory-main-section">
        <span
          className="manage-territory-main-section-header"
          data-testid="manage-territory-main-section-header"
        >
          View Territories
        </span>
      </div>
      {permissionObj?.mappedRolesPermissionData.IsProdManagerRole && (
        <SalesRegions
          validateAndSetAreaData={validateAndSetAreaData}
          selectedRegionType={AuthObj?.userProfile?.area!}
        />
      )}
      <div className="territory-filter-mng-usr">
        <div className="territory-user-searchbar">
          <div className="territory-search-icon-div">
            <SearchIcon className="territory-search-icon" />
          </div>
          <InputBase
            className="territory-user-search-input"
            data-testid="territory-user-search-input"
            name="search-input"
            onChange={handleSearch}
            placeholder="Filter by Territory Code,  Territory Name, or Salesperson"
            value={searchInput}
          />
        </div>
        <CustomDropDown
          handleChange={validateAndSetRoleData}
          menuItem={["All roles"].concat(roleText)}
          name="territory-user-role"
          placeHolder="All roles"
          selectpropsClassName={
            selectedRoleType ? "territory-user-status-select" : "placeHolder"
          }
          selectClassName={
            selectedRoleType ? "territory-user-status-input" : "placeHolder"
          }
          testId="territory-user-status"
          value={selectedRoleType ? selectedRoleType : null}
        />
      </div>
      <div className="territory-users-table">
        <TerritoryListTable
          columns={columns}
          handleSorting={handleSortedData}
          openSaleUserDetails={openSaleUserDetails}
          openTerritoryDetail={openTerritoryDetail}
          sortedData={sortData}
        />
      </div>
    </>
  );
};

export default ViewTerritories;
