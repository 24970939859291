import "./customOrderUploadDocList.css";
import { Grid, useMediaQuery } from "@mui/material";
import RedCheck from "../../../../assets/redCheck.svg";
import SelectCloseIcon from "../../../../assets/selectclose.svg";
import { ERROR_TO_SELECT_ANY_OPTION } from "../../../../util/errorMsg";
import { ValidationStatus } from "../../../../core/interfaces/input.interface";
import { CustomDropDown } from "../../../../core/customDropdown/customDropdown.component";
import { InputWithLabel } from "../../../../core/inputWithLabel/inputWithLabel.component";
import { IDropZoneDocumentSelect } from "../../../../core/customDropZone/dropZoneDocumentSelect.interface";

//We are using this component for the new Order upload document section
type Props = {
  data: IDropZoneDocumentSelect[];
  setData: any;
  onDelete: (file: IDropZoneDocumentSelect, key: any) => void;
  documentTypeText?: any;
  isToShowDocumentTypeDropDown?: boolean;
};

const CustomOrderUploadDocList = ({
  data,
  setData,
  onDelete,
  documentTypeText,
  isToShowDocumentTypeDropDown = true,
}: Props) => {
  const isMobileScreen = useMediaQuery("(max-width:912px)");

  const validateAndSetData = (e: any, selectedIndex: number) => {
    const temp = data.map((x: IDropZoneDocumentSelect, index: number) => {
      if (index === selectedIndex) {
        x.documentType = {
          ...x.documentType,
          valid: ValidationStatus.VALID,
          value: e.target.value,
        };
        return x;
      }
      return x;
    });
    setData(temp);
  };

  return (
    <>
      {data && data.length > 0 && (
        <div className="new-order-upload-document">
          {data.map((file: IDropZoneDocumentSelect, index: number) => {
            return (
              <Grid
                className="new-order-upload-document-grid-container"
                container
                spacing={2}
              >
                <Grid
                  className="new-order-upload-document-grid-item"
                  item
                  xs={isMobileScreen ? 12 : 6}
                >
                  <div className="new-order-upload-document-filename-div">
                    <h4 className="new-order-upload-document-filename-title">
                      File Name
                    </h4>
                    <h4
                      className="new-order-upload-document-filename-value"
                      data-testid={"documentNameTest" + index}
                    >
                      {file.documentName}
                    </h4>
                  </div>
                </Grid>
                <Grid
                  className="new-order-upload-document-grid-item"
                  item
                  xs={isMobileScreen ? 12 : 6}
                >
                  <div className="new-order-document-type-or-status-div">
                    {!file.succeeded ? (
                      <div
                        className={`docUploadfileStatus${
                          !file.succeeded ? `error` : ``
                        }`}
                        data-testid={
                          `docUploadfileStatus${
                            !file.succeeded ? `error` : ``
                          }` + index
                        }
                        id={`upload-documents-${index + 1}`}
                      >
                        <img
                          style={{
                            verticalAlign: "text-bottom",
                            marginRight: "5px",
                            marginBottom: "4px",
                          }}
                          src={RedCheck}
                          alt={RedCheck}
                        />
                        <h4 className="new-order-upload-error-text">
                          {file.errorMessage}
                        </h4>
                      </div>
                    ) : (
                      <div className="new-order-upload-document-type-div">
                        {isToShowDocumentTypeDropDown && (
                          <InputWithLabel
                            error={
                              file.documentType?.valid ===
                              ValidationStatus.INVALID
                            }
                            id={`upload-documents-${index + 1}`}
                            isRequired={true}
                            isShowWarning={true}
                            label="Document Type"
                            testId={"order-document-upload" + index}
                            warningMessage={ERROR_TO_SELECT_ANY_OPTION}
                            isDropdown={true}
                          >
                            <CustomDropDown
                              name="documentType"
                              value={
                                file.documentType ? file.documentType.value : ""
                              }
                              handleChange={(event: any) =>
                                validateAndSetData(event, index)
                              }
                              selectpropsClassName={
                                file.documentType
                                  ? "new-order-documentType-info-select"
                                  : "placeholder"
                              }
                              selectClassName="new-order-documentType-info-input"
                              testId={"documentType-testid" + index}
                              menuItem={documentTypeText}
                              placeHolder="Select document Type"
                            />
                          </InputWithLabel>
                        )}
                      </div>
                    )}
                    <div
                      className={
                        file.succeeded
                          ? "new-order-docUploadfileCloseSuccess"
                          : "new-order-docUploadfileCloseFail"
                      }
                      data-testid={"document-upload-close-icon" + index}
                      onClick={() => onDelete(file, index)}
                    >
                      <img src={SelectCloseIcon} alt={SelectCloseIcon} />
                    </div>
                  </div>
                </Grid>
              </Grid>
            );
          })}
        </div>
      )}
    </>
  );
};

export default CustomOrderUploadDocList;
