import { createContext, useState } from "react";
import { AcuteOrderPickUpRequestPageSection } from "../components/acutePickUpRequest/acuteOrderPickUpRequest.enum";
import { IAcuteOrderPickUpRequest } from "../components/acutePickUpRequest/acuteOrderPickUpRequest.interface";
import { getDeepClone } from "../util/ObjectFunctions";
import { defaultAcutePickUpRequestData } from "../components/acutePickUpRequest/acuteOrderPickUpRequest.model";
import { IPatient } from "../components/myPatients/patient.interface";

export type AcuteOrderPickUpRequestContextType = {
  data: IAcuteOrderPickUpRequest;
  setData: React.Dispatch<React.SetStateAction<IAcuteOrderPickUpRequest>>;
  page: AcuteOrderPickUpRequestPageSection;
  setPage: React.Dispatch<
    React.SetStateAction<AcuteOrderPickUpRequestPageSection>
  >;
  patient: IPatient | null;
  setPatient: React.Dispatch<React.SetStateAction<IPatient | null>>;
  storageLocations: string[];
  setStorageLocations: React.Dispatch<React.SetStateAction<string[]>>;
  resetData: () => void;
};

type AcuteOrderPickUpRequestContextProviderProps = {
  children: React.ReactNode;
};

export const AcuteOrderPickUpRequestContext =
  createContext<AcuteOrderPickUpRequestContextType | null>(null);

export const AcuteOrderPickUpRequestContextProvider = ({
  children,
}: AcuteOrderPickUpRequestContextProviderProps) => {
  const [data, setData] = useState<IAcuteOrderPickUpRequest>(
    getDeepClone(defaultAcutePickUpRequestData)
  );
  const [page, setPage] = useState<AcuteOrderPickUpRequestPageSection>(
    AcuteOrderPickUpRequestPageSection.ACUTE_PICKUP_FIRST_PAGE
  );
  const [patient, setPatient] = useState<IPatient | null>(null);
  const [storageLocations, setStorageLocations] = useState<string[]>([]);

  const resetData = () => {
    setData(getDeepClone(defaultAcutePickUpRequestData));
    setPage(AcuteOrderPickUpRequestPageSection.ACUTE_PICKUP_FIRST_PAGE);
    setPatient(null);
    setStorageLocations([]);
  };

  return (
    <AcuteOrderPickUpRequestContext.Provider
      value={{
        data,
        page,
        patient,
        resetData,
        setData,
        setPage,
        setPatient,
        storageLocations,
        setStorageLocations,
      }}
    >
      {children}
    </AcuteOrderPickUpRequestContext.Provider>
  );
};
