import { Grid, InputBase, useMediaQuery } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import {
  SupplyOrderContext,
  SupplyOrderContextType,
} from "../../../context/SupplyOrderContext";
import { CustomCheckBox } from "../../../core/checkBox/checkBox.component";
import { CustomDropDown } from "../../../core/customDropdown/customDropdown.component";
import { InputWithLabel } from "../../../core/inputWithLabel/inputWithLabel.component";
import { ValidationStatus } from "../../../core/interfaces/input.interface";
import { getPatientAddresses } from "../../../util/3meService";
import { getdropDownContent } from "../../../util/dropDownService";
import { getDeepClone } from "../../../util/ObjectFunctions";
import {
  DD_US_STATES_CONTENT,
  SC_MEDICALSOLUTION_PHONENUMBER,
} from "../../../util/staticText";
import {
  getCodeFromText,
  getTextFromCode,
  getValuePostUPSValidation,
  makeCapitalEachWordInString,
} from "../../../util/utilityFunctions";
import { validateUpsAddress } from "../../../util/validateUpsAddressService";
import { IAddress } from "../../newOrder/patientInformation/patientInformation.interface";
import { ValidateUPSAddressInfo } from "../../newOrder/patientInformation/validateUPSAddressInfo.component";
import { ISupplyOrder } from "../supplyOrder.interface";
import { SupplyOrderValidator } from "../supplyOrder.validator";
import "./deliveryAddress.css";
import DeliveryAddressReviewOrder from "./reviewOrder/deliveryAddressReviewOrder.component";
import { CustomAutoComplete } from "../../../core/autoCompleteDropdown/autoCompleteDropdown.component";
import { format } from "react-string-format";
import { getStaticContent } from "../../../util/staticContentService";
import { MobileDisplayContext } from "../../../context/MobileDisplayContext";
import ErrorPopup from "../../../core/errorPopup/errorPopup.component";
import { ERROR_MSG_INITIAL_SUPPLY_ORDER_LOAD } from "../../../util/errorMsg";
import { SupplyOrderPageSection } from "../SupplyOrderPageSection.enum";

export interface IDeliveryAddress {
  data: ISupplyOrder;
  setData: any;
  Validator?: SupplyOrderValidator;
  isReviewOrder: boolean;
  openSupplyOrderPageEdit: any;
  isShowCurrentAddress: boolean;
  setIsShowCurrentAddress: Function;
}

export const DeliveryAddress = ({
  data,
  setData,
  Validator,
  isReviewOrder,
  openSupplyOrderPageEdit,
  isShowCurrentAddress,
  setIsShowCurrentAddress,
}: IDeliveryAddress) => {
  const [validator] = useState<SupplyOrderValidator>(Validator!);
  const supplyOrderObj = useContext<SupplyOrderContextType | null>(
    SupplyOrderContext
  );
  const [showCurrent, setShowCurrent] = useState(
    data.sameAsCurrentAddress.value === "Yes" ? true : false
  );
  const [address, setAddress] = useState<IAddress | null>();
  const [noAddressFound, setNoAddressFound] = useState(false);
  const [selectedUPSAddress, setSelectedUPSAddress] = useState("");
  const [validateAddress, setValidateUPSAddress] = useState<any>([]);
  const [openAddress, setOpenAddress] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setIsLoading] = useState(false);
  const [ddStates, setDDStates] = useState([]);
  const [allStates, setAllStates] = useState([]);
  const [showValidateUPSAddress, setShowValidateUPSAddress] = useState<
    boolean | null
  >(null);
  const [contactMedicalSolution, setContactMedicalSolution] = useState("");
  const { isMobileScreen } = useContext(MobileDisplayContext);
  const [errorPopUp, setErrorPopUp] = useState(false);
  const [errorCode, setErrorCode] = useState("");

  const handleAddressConitnueButton = () => {
    let upsValidateAddr: any;
    if (selectedUPSAddress.includes("enteredAddress")) {
      if (selectedUPSAddress && selectedUPSAddress.length >= 2) {
        upsValidateAddr = JSON.parse(
          selectedUPSAddress.split("enteredAddress-")[1]
        );
      }
    } else if (selectedUPSAddress) {
      upsValidateAddr = JSON.parse(selectedUPSAddress);
    }
    if (upsValidateAddr) {
      setData((data: ISupplyOrder) => ({
        ...data,
        addressLine1: {
          value: getValuePostUPSValidation(
            Validator!,
            upsValidateAddr.addressLine1,
            "addressLine1"
          ),
          valid: ValidationStatus.VALID,
          required: true,
        },
        addressLine2: {
          value:
            upsValidateAddr.addressLine2 === null
              ? ""
              : getValuePostUPSValidation(
                  Validator!,
                  upsValidateAddr.addressLine2,
                  "addressLine2"
                ),
          valid: ValidationStatus.VALID,
          required: false,
        },
        city: {
          value: getValuePostUPSValidation(
            Validator!,
            upsValidateAddr.city,
            "city"
          ),
          valid: ValidationStatus.VALID,
          required: true,
        },
        state: {
          value: upsValidateAddr.state,
          valid: ValidationStatus.VALID,
          required: true,
        },
        zipCode: {
          value: upsValidateAddr.zipCode,
          valid: ValidationStatus.VALID,
          required: true,
        },
      }));
    }
    setOpenAddress(false);
  };

  const validateUPSAddress = async (obj: ISupplyOrder) => {
    const reqParams = {
      AddressLine1: obj.addressLine1.value.trim(),
      AddressLine2: obj.addressLine2.value.trim(),
      City: obj.city.value,
      State: obj.state.value,
      zipCode: obj.zipCode.value,
    };
    const address: IAddress = {
      address1: obj.addressLine1.value,
      address2: obj.addressLine2.value,
      city: obj.city.value,
      state: obj.state.value,
      zip: obj.zipCode.value,
    };
    setAddress(address);
    setOpenAddress(true);
    setIsLoading(true);
    const response = await validateUpsAddress(reqParams);
    if (response && response.item) {
      if (response.item.status !== "ExactMatch") {
        if (response.item.addresses.length > 0) {
          setNoAddressFound(false);
          setValidateUPSAddress(response.item.addresses);
          if (
            JSON.stringify(response.item.addresses[0]) ===
            JSON.stringify(reqParams)
          ) {
          } else {
            setSelectedUPSAddress(JSON.stringify(response.item.addresses[0]));
          }
        } else {
          setSelectedUPSAddress("");
          setMessage("No address found.");
          setNoAddressFound(true);
        }
        setIsLoading(false);
      } else {
        setOpenAddress(false);
      }
    } else {
      setMessage("Unable to process address validation. Please continue.");
      setIsLoading(false);
      setNoAddressFound(true);
    }
  };

  const validateAndSetData = (e: any) => {
    let val = e.target.value;
    const required = e.target.required;
    let isValid = validator.validate(val, e.target.name);
    if (e.target.name === "sameAsCurrentAddress") {
      const checked = e.target.checked;
      const isCurrentAddressUnavailabel =
        !data.caAddressLine1.value ||
        !data.caCity.value ||
        !data.caZipCode.value;
      setShowCurrent(checked && !isCurrentAddressUnavailabel);
      if (checked && isCurrentAddressUnavailabel) {
        setIsShowCurrentAddress(false);
      } else {
        setIsShowCurrentAddress(checked);
      }
      setData(
        Object.assign({}, data, {
          [e.target.name]: {
            value: checked ? "Yes" : "No",
            valid: ValidationStatus.VALID,
            required: required,
          },
        })
      );
    } else {
      if (e.target.name === "zipCode" && !showValidateUPSAddress) {
        setShowValidateUPSAddress(true);
      }
      setData(
        Object.assign({}, data, {
          [e.target.name]: {
            value: val,
            valid: isValid?.status,
            required: required,
          },
        })
      );
    }
  };

  const validateAndSetStateData = async (e: any, val: string, name: string) => {
    let value = getCodeFromText(allStates, val);
    if (value !== "") {
      let isValid = validator.validate(value, "state");
      setData(
        Object.assign({}, data, {
          [name]: {
            value: value,
            valid: isValid?.status,
            required: true,
          },
        })
      );
    }
  };

  const validateAndSetCheckboxData = (e: any) => {
    const checked = e.target.checked;
    const isCurrentAddressUnavailabel =
      !data.caAddressLine1.value || !data.caCity.value || !data.caZipCode.value;
    setShowCurrent(checked && !isCurrentAddressUnavailabel);
    if (checked && isCurrentAddressUnavailabel) {
      setIsShowCurrentAddress(false);
    } else {
      setIsShowCurrentAddress(checked);
    }
    if (!isCurrentAddressUnavailabel) {
      setData((data: ISupplyOrder) => ({
        ...data,
        sameAsCurrentAddress: {
          value: checked ? "Yes" : "No",
          valid: ValidationStatus.VALID,
          required: false,
          isOptional: true,
        },
        addressLine1: {
          value: "",
          valid: ValidationStatus.UNTOUCHED,
          required: true,
          isOptional: checked ? true : false,
        },
        addressLine2: {
          value: "",
          valid: ValidationStatus.VALID,
          required: false,
          isOptional: true,
        },
        state: {
          value: "",
          valid: ValidationStatus.UNTOUCHED,
          required: true,
          isOptional: checked ? true : false,
        },
        city: {
          value: "",
          valid: ValidationStatus.UNTOUCHED,
          required: true,
          isOptional: checked ? true : false,
        },
        zipCode: {
          value: "",
          valid: ValidationStatus.UNTOUCHED,
          required: true,
          isOptional: checked ? true : false,
        },
      }));
    } else {
      setData((data: ISupplyOrder) => ({
        ...data,
        sameAsCurrentAddress: {
          value: checked ? "Yes" : "No",
          valid: ValidationStatus.VALID,
          required: false,
          isOptional: true,
        },
      }));
    }
  };

  const getAndSetPatientCurrentAddress = async (reqParams: any) => {
    try {
      const caData = await getPatientAddresses(reqParams);
      if (caData.succeeded && caData.item) {
        setData((dt: ISupplyOrder) => ({
          ...dt,
          caAddressLine1: {
            value: caData.item.currentAddress.addressLine1,
            valid: ValidationStatus.VALID,
            required: true,
          },
          caAddressLine2: {
            value: caData.item.currentAddress.addressLine2,
            valid: ValidationStatus.VALID,
            required: true,
          },
          caCity: {
            value: caData.item.currentAddress.city,
            valid: ValidationStatus.VALID,
            required: true,
          },
          caState: {
            value: caData.item.currentAddress.state,
            valid: ValidationStatus.VALID,
            required: true,
          },
          caZipCode: {
            value: caData.item.currentAddress.zipCode?.toString(),
            valid: ValidationStatus.VALID,
            required: true,
          },
        }));
      } else if (!caData.succeeded) {
        setErrorPopUp(true);
        setErrorCode(caData?.error?.errorCode || caData?.status);
      }
    } catch (error) {
      console.log("error occurred ", error);
    }
  };

  const fetchStates = async () => {
    getdropDownContent(DD_US_STATES_CONTENT).then((resp) => {
      const sortedData = resp.items[0].data.sort((a: any, b: any) =>
        a.order > b.order ? 1 : -1
      );
      setAllStates(sortedData);
      const ddSortedData = resp.items[0].data
        .sort((a: any, b: any) => (a.order > b.order ? 1 : -1))
        .map((x: any) => x.text);
      setDDStates(ddSortedData);
    });
  };
  const fetchStaticContentMedicalSolution = async () => {
    try {
      const staticContentHelpLineNum = format(
        "{0}",
        SC_MEDICALSOLUTION_PHONENUMBER
      );
      const data = await getStaticContent(staticContentHelpLineNum);
      if (data.succeeded) {
        if (data.items.length > 0) {
          const medicalSolutionsContactObj = data.items.filter(
            (item: { key: string }) =>
              item.key === SC_MEDICALSOLUTION_PHONENUMBER
          );
          const medicalSolutionsContact = medicalSolutionsContactObj[0].value;
          setContactMedicalSolution(medicalSolutionsContact);
        }
      } else {
        setErrorPopUp(true);
        setErrorCode(data?.error?.errorCode || data?.status);
        setContactMedicalSolution("1-800-275-4524");
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    fetchStates();
    if (supplyOrderObj && supplyOrderObj.selectedPatient) {
      const reqParams = {
        RentalOrderNumber: supplyOrderObj.selectedPatient.roNumber,
        dob: supplyOrderObj.selectedPatient.dob,
      };
      getAndSetPatientCurrentAddress(reqParams);
      fetchStaticContentMedicalSolution();
      if (supplyOrderObj.isBackFromReviewPage) {
        setShowValidateUPSAddress(false);
      } else {
        setShowValidateUPSAddress(true);
      }
    }
  }, []);

  useEffect(() => {
    const obj = getDeepClone(data);
    if (
      obj.addressLine1.valid === ValidationStatus.VALID &&
      obj.city.valid === ValidationStatus.VALID &&
      obj.state.valid === ValidationStatus.VALID &&
      obj.zipCode.valid === ValidationStatus.VALID &&
      obj.addressLine2.valid === ValidationStatus.VALID &&
      data.sameAsCurrentAddress.value.toLowerCase() === "no" &&
      showValidateUPSAddress
    ) {
      validateUPSAddress(obj);
    }
  }, [data.zipCode.value]);

  useEffect(() => {
    const checked = data.sameAsCurrentAddress.value === "Yes";
    const isCurrentAddressUnavailabel =
      !data.caAddressLine1.value || !data.caCity.value || !data.caZipCode.value;
    setShowCurrent(checked && !isCurrentAddressUnavailabel);
    if (checked && isCurrentAddressUnavailabel) {
      setIsShowCurrentAddress(false);
    } else {
      setIsShowCurrentAddress(checked);
    }
  }, [data.caAddressLine1.value]);

  return (
    <Grid className="delivery-address-container" container spacing={2}>
      {!isReviewOrder && (
        <>
          <Grid className="delivery-address-grid-item" item xs={12}>
            <div className="address-header" data-testid="deliveryAddress">
              Delivery Address
            </div>
            <div className="delivery-instruction-div">
              <span
                className="deliveryAddress-instruction-section"
                data-testid="deliveryAddress-instruction-section"
              >
                Note: Orders placed on this platform will be shipped via ground
                delivery (3-5 days). If you need an expedited delivery, please
                call 3M Medical Solutions at{" "}
                <a href={`tel:${contactMedicalSolution}`}>
                  {contactMedicalSolution}.
                </a>
              </span>
            </div>
            <InputWithLabel
              isRequired={data.sameAsCurrentAddress.required}
              error={
                data.sameAsCurrentAddress.valid === ValidationStatus.INVALID
              }
              testId="sameAsCurrentAddress"
            >
              <CustomCheckBox
                handleChange={validateAndSetCheckboxData}
                labelClassName="current-address-label"
                value={data.sameAsCurrentAddress.value}
                checked={data.sameAsCurrentAddress.value === "Yes"}
                labelText="Same as Current Address"
                selectClassName="current-address-chkbox"
                name="sameAsCurrentAddress"
                required={data.sameAsCurrentAddress.required}
                testId="sameAsCurrentAddressChkbox"
              />
            </InputWithLabel>
          </Grid>
          {showCurrent ? (
            <Grid
              item
              className="current-address-container"
              spacing={2}
              xs={12}
            >
              <div
                className="patient-current-address"
                data-testid="currentAddressHeader"
              >
                Current Address
              </div>
              <div className="address-lines" data-testid="addressLines">
                {data.caAddressLine1.value !== ""
                  ? `${makeCapitalEachWordInString(
                      data.caAddressLine1.value
                    )}, ${makeCapitalEachWordInString(
                      data.caAddressLine2.value
                    )}`
                  : "--"}
              </div>
              {data.caZipCode.value && (
                <div className="address-lines">
                  {`${makeCapitalEachWordInString(data.caCity.value)}` +
                    "," +
                    " " +
                    `${data.caState.value}` +
                    " " +
                    `${data.caZipCode.value.split("-")[0]}`}
                </div>
              )}
            </Grid>
          ) : (
            <>
              <Grid container className="address-line-container">
                <Grid
                  item
                  className="address-line1-item"
                  xs={isMobileScreen ? 12 : 6}
                >
                  <InputWithLabel
                    label="Address Line 1 (No P.O. Boxes)"
                    isRequired={data.addressLine1.required}
                    error={data.addressLine1.valid === ValidationStatus.INVALID}
                  >
                    <InputBase
                      className="address-line1"
                      required={data.addressLine1.required}
                      name="addressLine1"
                      onChange={validateAndSetData}
                      value={data.addressLine1.value}
                      inputProps={{ "data-testid": "addressLine1" }}
                    />
                  </InputWithLabel>
                </Grid>
                <Grid
                  item
                  className="address-line2-item"
                  xs={isMobileScreen ? 12 : 6}
                >
                  <InputWithLabel
                    label="Address Line 2"
                    isRequired={data.addressLine2.required}
                    error={data.addressLine2.valid === ValidationStatus.INVALID}
                  >
                    <InputBase
                      required={data.addressLine2.required}
                      name="addressLine2"
                      className="address-line2"
                      onChange={validateAndSetData}
                      value={data.addressLine2.value}
                      inputProps={{ "data-testid": "addressLine2" }}
                    />
                  </InputWithLabel>
                </Grid>
              </Grid>
              <Grid className="city-state-container" container>
                <Grid item className="city-item" xs={isMobileScreen ? 12 : 6}>
                  <InputWithLabel
                    label="City"
                    isRequired={data.city.required}
                    error={data.city.valid === ValidationStatus.INVALID}
                  >
                    <InputBase
                      className="city"
                      required={data.city.required}
                      name="city"
                      onChange={validateAndSetData}
                      value={data.city.value}
                      inputProps={{ "data-testid": "city" }}
                    />
                  </InputWithLabel>
                </Grid>
                <Grid item className="state-item" xs={isMobileScreen ? 12 : 3}>
                  <InputWithLabel
                    label="State"
                    isRequired={true}
                    error={data.state.valid === ValidationStatus.INVALID}
                  >
                    <CustomAutoComplete
                      handleChange={(e: any, val: string) =>
                        validateAndSetStateData(e, val, "state")
                      }
                      menuItem={ddStates}
                      name="state"
                      selectClassName={
                        data?.state.valid === ValidationStatus.INVALID
                          ? "supplyOrder-address-state-input supplyOrder-address-state-error"
                          : "supplyOrder-address-state-input"
                      }
                      selectpropsClassName="supplyOrder-address-state-root"
                      value={
                        data.state.value
                          ? getTextFromCode(allStates, data.state.value)
                          : null
                      }
                      testId="state"
                    />
                  </InputWithLabel>
                </Grid>
                <Grid
                  item
                  className="zipcode-item"
                  xs={isMobileScreen ? 12 : 3}
                >
                  <InputWithLabel
                    label="ZIP Code"
                    isRequired={data.zipCode.required}
                    error={data.zipCode.valid === ValidationStatus.INVALID}
                  >
                    <InputBase
                      className="zipcode"
                      required={data.zipCode.required}
                      name="zipCode"
                      onChange={validateAndSetData}
                      value={data.zipCode.value}
                      inputProps={{ "data-testid": "zipCode", maxLength: 5 }}
                    />
                  </InputWithLabel>
                </Grid>
              </Grid>
            </>
          )}
          <ValidateUPSAddressInfo
            address={address!}
            openAddress={openAddress}
            setOpenAddress={setOpenAddress}
            loading={loading}
            setSelectedUPSAddress={setSelectedUPSAddress}
            selectedUPSAddress={selectedUPSAddress}
            validateAddress={validateAddress}
            handleAddressConitnueButton={handleAddressConitnueButton}
            noAddressFound={noAddressFound}
            message={message}
            title=" Please confirm patient address "
          />
        </>
      )}
      {isReviewOrder && (
        <DeliveryAddressReviewOrder
          data={data}
          openSupplyOrderPageEdit={openSupplyOrderPageEdit}
          isShowCurrentAddress={isShowCurrentAddress}
        />
      )}
      {errorPopUp && (
        <ErrorPopup
          popUpStyles="error-popup-design"
          errorMessage={ERROR_MSG_INITIAL_SUPPLY_ORDER_LOAD}
          errorPopupFlag={errorPopUp}
          handleBackButton={() => {
            setErrorPopUp(false);
            supplyOrderObj?.setSupplyOrderPage(
              SupplyOrderPageSection.SUPPLYORDER_PATIENT_LIST
            );
          }}
          buttontitle="Done"
          showTitle={false}
          isShortSentence={true}
          errorCode={errorCode}
        />
      )}
    </Grid>
  );
};
