import "./patientCurrentAddress.css";
import {
  getCodeFromText,
  getTextFromCode,
} from "../../../util/utilityFunctions";
import InputMask from "react-input-mask";
import {
  NewOrderContext,
  NewOrderContextType,
} from "../../../context/NewOrderContext";
import { useContext, useState } from "react";
import {
  IInputField,
  ValidationStatus,
} from "../../../core/interfaces/input.interface";
import { NewOrderValidator } from "../newOrder.validator";
import { Box, Grid, InputBase, useMediaQuery } from "@mui/material";
import { CustomCheckBox } from "../../../core/checkBox/checkBox.component";
import { IPatientCurrentAddress } from "./patientCurrentAddress.interface";
import { InputWithLabel } from "../../../core/inputWithLabel/inputWithLabel.component";
import { CustomAutoComplete } from "../../../core/autoCompleteDropdown/autoCompleteDropdown.component";
import { PatientCurrentAddressReviewOrder } from "./reviewOrder/partientCurrentAddressReviewOrder.component";

export const PatientCurrentAddress = ({
  data,
  Validator = new NewOrderValidator(),
  setData,
  states,
  statesText,
  isReviewOrder = false,
  editButtonClicked,
  isOrderSummary = false,
}: IPatientCurrentAddress) => {
  const isMobileScreen = useMediaQuery("(max-width:920px)");
  const [validator] = useState<NewOrderValidator>(Validator!);
  const [focusClasses, setFocusClasses] = useState({
    phone: "",
  });
  const newOrderObj = useContext<NewOrderContextType | null>(NewOrderContext);

  const setClasses = (e: any, classname: string) => {
    setFocusClasses(
      Object.assign({}, focusClasses, { [e.target.name]: classname })
    );
  };

  const handlecheckBoxChange = (e: any) => {
    newOrderObj?.setIsHandleChangeTriggered(true);
    let tempData = {
      ...data,
      IsSamePermanentAddress: {
        ...data.IsSamePermanentAddress,
        value:
          e.target.checked !== undefined ? e.target.checked.toString() : null,
      },
    };
    if (e.target.checked === false) {
      tempData = {
        ...tempData,
        patientCurrentAddress1: {
          ...data.patientCurrentAddress1,
          errorMessage: null,
          required: true,
          valid: ValidationStatus.UNTOUCHED,
          value: "",
        },
        patientCurrentAddress2: {
          ...data.patientCurrentAddress2,
          errorMessage: null,
          valid: ValidationStatus.VALID,
          value: "",
        },
        patientCurrentAddressCity: {
          ...data.patientCurrentAddressCity,
          errorMessage: null,
          required: true,
          valid: ValidationStatus.UNTOUCHED,
          value: "",
        },
        patientCurrentAddressState: {
          ...data.patientCurrentAddressState,
          errorMessage: null,
          required: true,
          valid: ValidationStatus.UNTOUCHED,
          value: "",
        },
        patientCurrentAddressZip: {
          ...data.patientCurrentAddressZip,
          errorMessage: null,
          required: true,
          valid: ValidationStatus.UNTOUCHED,
          value: "",
        },
        patientCurrentAddressPhone: {
          ...tempData.patientCurrentAddressPhone,
          errorMessage: null,
          required: true,
          valid: ValidationStatus.UNTOUCHED,
          value: "",
        },
      };
    } else {
      tempData = {
        ...tempData,
        patientCurrentAddress1: {
          ...tempData.patientCurrentAddress1,
          errorMessage: null,
          valid: ValidationStatus.UNTOUCHED,
          value: data.address1.value,
        },
        patientCurrentAddress2: {
          ...tempData.patientCurrentAddress2,
          errorMessage: null,
          valid: ValidationStatus.VALID,
          value: data.address2.value,
        },
        patientCurrentAddressCity: {
          ...tempData.patientCurrentAddressCity,
          errorMessage: null,
          valid: ValidationStatus.UNTOUCHED,
          value: data.city.value,
        },
        patientCurrentAddressState: {
          ...tempData.patientCurrentAddressState,
          errorMessage: null,
          valid: ValidationStatus.UNTOUCHED,
          value: data.state.value,
        },
        patientCurrentAddressZip: {
          ...tempData.patientCurrentAddressZip,
          errorMessage: null,
          valid: ValidationStatus.UNTOUCHED,
          value: data.zip.value,
        },
        patientCurrentAddressPhone: {
          ...tempData.patientCurrentAddressPhone,
          errorMessage: null,
          required: true,
          valid: ValidationStatus.VALID,
          value: data.phone.value,
        },
      };
    }
    setData(tempData);
  };

  const validateAndSetData = (e: any) => {
    newOrderObj?.setIsHandleChangeTriggered(true);
    const { name, value } = e.target;
    let isValid = validator.validate(value, name);
    if (
      name === "patientCurrentAddressPhone" &&
      data.patientCurrentAddressPhone.valid === ValidationStatus.UNTOUCHED &&
      (value === "(___) ___-____" || value === "")
    ) {
      return;
    }
    let inputObj: IInputField = {
      ...Object(data)[name],
      errorMessage: null,
      valid: isValid!.status,
      value: value,
    };
    const tempData = {
      ...data,
      [name]: inputObj,
    };
    setData(tempData);
  };

  const validateAndSetStateData = async (e: any, val: string, name: string) => {
    newOrderObj?.setIsHandleChangeTriggered(true);
    const value = getCodeFromText(states, val);
    const isValid = validator.validate(value, name);
    let stateObj: IInputField = {
      ...data.patientCurrentAddressState,
      errorMessage: null,
      valid: isValid!.status,
      value: value,
    };
    setData({
      ...data,
      patientCurrentAddressState: stateObj,
    });
  };

  return (
    <div className="patient-current-address-info-component">
      {!isReviewOrder && (
        <div className="patient-current-address-info">
          <div className="patient-current-address-header">
            <div className="patient-current-address-title-div">
              <h2
                className="patient-current-address-title"
                data-testid="patientCurrentAddressHeaderTest"
              >
                Patient’s Current Address
              </h2>
            </div>
            <h5
              className="patient-current-address-description"
              data-testid="patientCurrentAddressDescTest"
            >
              The address where the V.A.C.<sup>®</sup> Therapy unit will be
              used. The patient's current address may be used to determine: 1)
              eligibility, 2) the delivery of supplies. Please ensure the
              address is accurate. The current address must be a physical
              address; P.O. Boxes are not acceptable.
            </h5>
          </div>
          <Box
            className="patient-current-address-box-container"
            sx={{ flexGrow: 1 }}
          >
            <Grid
              className="patient-current-address-grid-container"
              container
              spacing={1}
            >
              <Grid>
                <CustomCheckBox
                  value={JSON.parse(data.IsSamePermanentAddress.value)}
                  checked={JSON.parse(data.IsSamePermanentAddress.value)}
                  handleChange={handlecheckBoxChange}
                  selectClassName="patientCurrentAddrCheckBox"
                  selectpropsClassName="patientCurrentAddrChkBoxRoot"
                  labelClassName="chkBoxDescriptionLabel"
                  labelText="Same as Permanent Address"
                  name="IsSamePermanentAddress"
                  testId="patientCurrentAddressCheckBoxTest"
                />
              </Grid>
            </Grid>
          </Box>
          {!JSON.parse(data?.IsSamePermanentAddress.value) && (
            <>
              <Box
                className="patient-current-address-box-container"
                sx={{ flexGrow: 1 }}
              >
                <Grid
                  className="patient-current-address-grid-container"
                  container
                  spacing={2}
                >
                  <Grid item md={6} xs={12}>
                    <InputWithLabel
                      error={
                        data.patientCurrentAddress1.valid ===
                        ValidationStatus.INVALID
                      }
                      id={data.patientCurrentAddress1.componentId!}
                      isRequired={true}
                      isShowWarning={true}
                      label={data.patientCurrentAddress1.title!}
                      warningMessage={data.patientCurrentAddress1.errorMessage}
                    >
                      <InputBase
                        className="patient-current-address-input"
                        data-testid="patientCurrentAddress1Test"
                        id={data.patientCurrentAddress1.id!}
                        name="patientCurrentAddress1"
                        onChange={validateAndSetData}
                        value={data.patientCurrentAddress1.value}
                      />
                    </InputWithLabel>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <InputWithLabel
                      error={
                        data.patientCurrentAddress2.valid ===
                        ValidationStatus.INVALID
                      }
                      id={data.patientCurrentAddress2.componentId!}
                      isRequired={false}
                      isShowWarning={true}
                      label={data.patientCurrentAddress2.title!}
                      warningMessage={data.patientCurrentAddress2.errorMessage}
                    >
                      <InputBase
                        className="patient-current-address-input"
                        data-testid="patientCurrentAddress2Test"
                        id={data.patientCurrentAddress2.id!}
                        name="patientCurrentAddress2"
                        onChange={validateAndSetData}
                        value={data.patientCurrentAddress2.value}
                      />
                    </InputWithLabel>
                  </Grid>
                </Grid>
              </Box>
              <Box
                className="patient-current-address-box-container"
                sx={{ flexGrow: 1 }}
              >
                <Grid
                  className="patient-current-address-grid-container"
                  container
                  spacing={2}
                >
                  <Grid item md={6} xs={12}>
                    <InputWithLabel
                      error={
                        data.patientCurrentAddressCity.valid ===
                        ValidationStatus.INVALID
                      }
                      id={data.patientCurrentAddressCity.componentId!}
                      isRequired={true}
                      isShowWarning={true}
                      label={data.city.title!}
                      warningMessage={
                        data.patientCurrentAddressCity.errorMessage
                      }
                    >
                      <InputBase
                        className="patient-current-address-input"
                        data-testid="patientCurrentAddressCityTest"
                        id={data.patientCurrentAddressCity.id!}
                        name="patientCurrentAddressCity"
                        onChange={validateAndSetData}
                        value={data.patientCurrentAddressCity.value}
                      />
                    </InputWithLabel>
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <InputWithLabel
                      error={
                        data?.patientCurrentAddressState.valid ===
                        ValidationStatus.INVALID
                      }
                      id={data.patientCurrentAddressState.componentId!}
                      isRequired={true}
                      isShowWarning={true}
                      label={data.patientCurrentAddressState.title!}
                      warningMessage={
                        data.patientCurrentAddressState.errorMessage
                      }
                    >
                      <CustomAutoComplete
                        handleChange={(e: any, val: string) =>
                          validateAndSetStateData(
                            e,
                            val,
                            "patientCurrentAddressState"
                          )
                        }
                        id={data.patientCurrentAddressState.id!}
                        menuItem={statesText}
                        name="patientCurrentAddressState"
                        selectClassName={
                          data.patientCurrentAddressState.valid ===
                          ValidationStatus.INVALID
                            ? "patient-currentaddress-state-input patient-currentaddress-state-error"
                            : "patient-currentaddress-state-input"
                        }
                        selectpropsClassName="patient-currentaddress-state-root"
                        value={
                          data.patientCurrentAddressState.value
                            ? getTextFromCode(
                                states,
                                data.patientCurrentAddressState.value
                              )
                            : null
                        }
                      />
                    </InputWithLabel>
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <InputWithLabel
                      error={
                        data.patientCurrentAddressZip.valid ===
                        ValidationStatus.INVALID
                      }
                      id={data.patientCurrentAddressZip.componentId!}
                      isRequired={true}
                      isShowWarning={true}
                      label={data.patientCurrentAddressZip.title!}
                      warningMessage={
                        data.patientCurrentAddressZip.errorMessage
                      }
                    >
                      <InputBase
                        className="patient-current-address-input"
                        data-testid="patientCurrentAddressZipTest"
                        id={data.patientCurrentAddressZip.id!}
                        inputProps={{
                          maxLength: 5,
                          inputMode: "numeric",
                          pattern: "[0-9*]",
                        }}
                        name="patientCurrentAddressZip"
                        onChange={validateAndSetData}
                        type="text"
                        value={data.patientCurrentAddressZip.value}
                      />
                    </InputWithLabel>
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
          <Box className="patient-info-box-container" sx={{ flexGrow: 1 }}>
            <Grid className="patient-info-grid-container" container spacing={2}>
              <Grid item xs={isMobileScreen ? 12 : 6}>
                <InputWithLabel
                  error={
                    data.patientCurrentAddressPhone.valid ===
                    ValidationStatus.INVALID
                  }
                  id={data.patientCurrentAddressPhone.componentId!}
                  isRequired={true}
                  isShowWarning={true}
                  label={data.patientCurrentAddressPhone.title!}
                  labelClassName={focusClasses.phone}
                  warningMessage={data.patientCurrentAddressPhone.errorMessage}
                >
                  <InputMask
                    className="patientCurrentAddressPhone"
                    id={data.patientCurrentAddressPhone.id!}
                    key="phonecurrent"
                    mask="(999) 999-9999"
                    name="patientCurrentAddressPhone"
                    onBlur={(e) => setClasses(e, "")}
                    onChange={validateAndSetData}
                    onFocus={(e) => setClasses(e, "Mui-focused")}
                    pattern="[0–9]*"
                    placeholder="(___) ___-____"
                    type="tel"
                    value={data.patientCurrentAddressPhone.value}
                  />
                </InputWithLabel>
              </Grid>
            </Grid>
          </Box>
        </div>
      )}
      {isReviewOrder && (
        <PatientCurrentAddressReviewOrder
          data={data}
          editButtonClicked={editButtonClicked}
          isOrderSummary={isOrderSummary}
        />
      )}
    </div>
  );
};
