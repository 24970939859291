const config = {
  oidc: {
    issuer: `${process.env.REACT_APP_BASE_URL}oauth2/default`,
    clientId: `${process.env.REACT_APP_CLIENTID}`,
    scopes: ["openid", "profile", "email", "offline_access"],
    redirectUri: `${window.location.origin}/login/callback`,
    tokenManager: {
      storage: "sessionStorage",
      expireEarlySeconds: 30,
      autoRenew: false,
    },
    services: {
      autoRenew: false,
    },

    // useInteractionCodeFlow: false,

    // useClassicEngine: true,
  },
  oidc2: {
    issuer: `${process.env.REACT_APP_INTERNAL_BASE_URL}`,
    clientId: `${process.env.REACT_APP_INTERNAL_CLIENTID}`,
    scopes: ["openid", "profile", "email", "phone", "groups", "offline_access"],
    redirectUri: `${window.location.origin}/login/callback`,
    // For testing auto renew token (pream)
    tokenManager: {
      storage: "sessionStorage",
      //autoRenew: true,
      expireEarlySeconds: 30,
    },
    // services: {
    //   autoRenew: true,
    // },
  },

  widget: {
    baseUrl: `${process.env.REACT_APP_BASE_URL}`,
    clientId: `${process.env.REACT_APP_CLIENTID}`,
    scopes: ["openid", "profile", "email", "offline_access"],
    issuer: `${process.env.REACT_APP_BASE_URL}oauth2/default`,
    redirectUri: `${window.location.origin}/login/callback`,
    // Embeded version using SPA
    // useInteractionCodeFlow: false,
    //useClassicEngine: true,
    services: {
      autoRenew: false,
    },
    tokenManager: {
      storage: "sessionStorage",
      autoRenew: false,
    },

    /* The features listed gives the required global functionality to the widget and are set as 3M defaults.*/
    features: {
      //autoFocus: true,
      showPasswordToggleOnSignInPage: true,
    },

    /* These values customize the logo and brand globally. */
    logo: "okta3mlogo.svg",
    logoText: "3M",
    brandName: "3M",

    /* Custom Buttons Section */
    // customButtons: [
    //   {
    //     /* Registration Button on login screen */
    //     i18nKey: "registration.signup.text",
    //     className: "btn-registration",
    //     click: () => {
    //       /* Default functionality for redirecting to another Page */
    //       //window.location.href = `https://fuzetest.3m.com/3M/en_US/VSRM/Registration/`;
    //       /* Custom Registration function to check URL for regCode and compare overrideURL against Fuze cookie domain */
    //       //  fuzeRegistration();
    //     },
    //   },
    // ],

    /**
     * This i18n object overrides the text labels of Okta SignIn Widget.
     * It is also the object where country specific labels can be overwriten.
     * See @Okta\okta-signin-widget\dist\labels\ directory for default labels
     * {0} is the user index, please do not edit
     */
    i18n: {
      /* en - English is default from login.json/properties files */
      en: {
        /* Login screen */
        "primaryauth.title": "Sign in to your 3M account",
        "primaryauth.username.placeholder": "Email Address",
        "socialauth.divider.text": "Don't have an existing account?",
        "registration.signup.text": "Create an account",
        "error.username.required": "Email Address Required",
        "email.format.error": "Invalid email address format",
        "oform.errorbanner.title":
          "We found an error. Please review and make corrections.",

        /* Password screen */
        "oie.password.challenge.title": "Sign in to your 3M account",
        "model.validation.field.blank": "This field cannot be left blank",
        "errors.E0000004": "Invalid email address / password combination",
        forgotpassword: "Forgot password?",
        goback: "Back to Sign In",

        /* Reset Password Screen  ( Okta Hosted only ) */
        "errors.E0000014": "Password reset failed",
        "oie.password.confirmPasswordLabel": "Confirm Password",
        "mfa.challenge.verify": "Sign In",
      },
    },
  },
};

export default config;
