import moment from "moment";
import { useMediaQuery } from "@mui/material";
import { useHistory } from "react-router-dom";
import "./acuteOrderPickUpRequestContainer.css";
import {
  IInputField,
  ValidationStatus,
} from "../../../core/interfaces/input.interface";
import {
  AcuteOrderPickUpRequestContext,
  AcuteOrderPickUpRequestContextType,
} from "../../../context/AcutePickUpRequestContext";
import ErrorPopup, {
  defaultErrorPopUp,
  IErrorPopUp,
} from "../../../core/errorPopup/errorPopup.component";
import { Popup } from "../../../core/popup/popup.component";
import { getSiteUseId } from "../../../util/utilityFunctions";
import { ReactNode, useContext, useEffect, useState } from "react";
import { LoadingSpinner } from "../../../core/loader/LoadingSpinner";
import { savePickupRequest } from "../../../util/pickUpOrDischargeService";
import { AuthContext, AuthContextType } from "../../../context/AuthContext";
import {
  ERROR_MSG_GET_GET_CURRENT_DATE_TIME,
  STORAGE_LOCATION_LOAD_SERVICE_ERROR,
  SVC_ACUTE_ORDER_PICK_UP_REQUST_ERROR,
} from "../../../util/errorMsg";
import { getFacilityStorageLocation } from "../../../util/inventoryMgrService";
import { retrievePatientDetails } from "../../../util/pickUpOrDischargeService";
import { AcuteOrderPickUpRequestPageSection } from "../acuteOrderPickUpRequest.enum";
import { AcuteOrderPickUpRequestValidator } from "../acuteOrderPickUpRequest.validatior";
import { IStorageLocation } from "../../inventory/popUpContainer/inventoryPopUpContainer.interface";
import { AcuteOrderPickUpRequestSummaryPage } from "../summary/summaryAcuteOrderPickUpRequest.component";
import { AcuteOrderPickUpRequestReviewPage } from "../reviewPage/reviewAcuteOrderPickUpRequest.component";
import { AcuteOrderPickUpRequestFirstPage } from "../firstPage/acuteOrderPickUpRequestFirstPage.component";
import { AcuteOrderPickUpRequestFooter } from "../components/footer/acuteOrderPickUpRequestFooter.component";
import { ISubmitPickupRequest } from "../../pickUpAndDischargeRequest/pickUpRequest/pickUpRequest.interface";
import { IEnterPickUpInformationLocation } from "../components/enterPickupInformation/enterPickupInformation.interface";
import {
  RolesPermissionContext,
  RolesPermissionContextType,
} from "../../../context/RolesPermissionContext";
import {
  REGISTERED_FACILITY_OBJ_FROM_AUTH_CONTEXT_FAILURE,
  SITEUSEID_FROM_REGISTERED_FACILITY_OBJ_FAILURE,
} from "../../../util/errorCode";
import { getCurrentServerDateTime } from "../../../util/3meService";

export const AcuteOrderPickUpContainer = () => {
  const history = useHistory();
  const [error, setError] = useState<IErrorPopUp>(defaultErrorPopUp);
  const [showLoader, setShowLoader] = useState(false);
  const [pickUpWorkOrderNumber, setPickUpWorkOrderNumber] =
    useState<string>("");
  const [isCustomeOwned, setIsCustomerOwned] = useState(false);
  const acuteOrderPickUpRequestObj =
    useContext<AcuteOrderPickUpRequestContextType | null>(
      AcuteOrderPickUpRequestContext
    );
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );

  const data = acuteOrderPickUpRequestObj!.data;
  const patient = acuteOrderPickUpRequestObj!.patient;
  const setData = acuteOrderPickUpRequestObj!.setData;
  const setPatient = acuteOrderPickUpRequestObj!.setPatient;
  const storageLocations = acuteOrderPickUpRequestObj!.storageLocations;
  const setStorageLocations = acuteOrderPickUpRequestObj!.setStorageLocations;
  const customerOwnedProducts: string[] = [
    "WNDACC",
    "WNDAOA",
    "WNDAOU",
    "WNDFCF",
    "WNDFCO",
    "WNDINC",
    "WNDSCO",
    "WNDUCO",
  ];
  const isMobileScreen = useMediaQuery("(max-width: 600px)");

  const loadPatientProductInfo = async () => {
    if (patient) {
      let reqParams = {
        rentalOrderNumber: patient!.roNumber,
        dob:
          patient!.dob && patient!.dob !== ""
            ? moment(patient!.dob).format("yyyy-MM-DD")
            : null,
        isAcuteOrder: patient!.isAcuteOrder ?? false,
      };
      setShowLoader(true);
      try {
        const response = await retrievePatientDetails(reqParams);
        if (response && response.succeeded) {
          let tempPatientData = {
            ...patient,
            productName: response.item.productName,
            productNameDescription: response.item.productDescription,
            placementDate: response.item.placemetDate,
            productSerialNumber: response.item.productSerialNumber,
            workOrderNumber: response.item.deliveryWorkOrderNumber,
            rentalOrderProductNumber: response.item.rentalOrderProductNumber,
            customerNumber: response.item.customerNumber,
          };
          setPatient(tempPatientData);
          const isOwned = customerOwnedProducts.some(
            (item) => item === tempPatientData?.productName
          );
          setIsCustomerOwned(isOwned);
          updateValidations(isOwned, tempPatientData.patientLocation!);
        } else {
          const errorFailure = {
            errorCode:
              response?.error?.code ||
              response?.error?.errorCode ||
              response?.status,
            errorFlag: true,
            errorMessage: STORAGE_LOCATION_LOAD_SERVICE_ERROR,
          };
          setError(errorFailure);
        }
        setShowLoader(false);
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  const getStorageLocations = async () => {
    setShowLoader(true);
    const request = {
      siteUseId: getSiteUseId(authObj),
      userName: authObj && authObj.userProfile!.userName,
    };
    const response = await getFacilityStorageLocation(request);
    if (response && response.succeeded) {
      const allLocations: IStorageLocation[] = response.items;
      const filteredResult: IStorageLocation[] = allLocations.filter(
        (location: IStorageLocation) => location.isEnable
      );
      const locations = filteredResult.map((location) => location.locationName);
      setStorageLocations(locations);
    } else {
      const errorFailure = {
        errorCode:
          response?.error?.code ||
          response?.error?.errorCode ||
          response?.status,
        errorFlag: true,
        errorMessage: STORAGE_LOCATION_LOAD_SERVICE_ERROR,
      };
      setError(errorFailure);
    }
    setShowLoader(false);
  };

  const callSubmitPickupRequest = async () => {
    setShowLoader(true);
    const currentDateRes = await getCurrentServerDateTime();
    if (currentDateRes?.succeeded) {
      const formatedDate = currentDateRes?.currentCstTime;
      const enterPickUpInformation: IEnterPickUpInformationLocation =
        data.enterPickUpInformation.value;
      const notes = isCustomeOwned
        ? `${enterPickUpInformation.pickupLocation.value} 
      ${enterPickUpInformation.specialInstructions.value}`
        : data.isCallOnEstimatedArrivalTime.value
        ? `${enterPickUpInformation.specialInstructions.value} Call with ETA, ${data.firstName.value} ${data.lastName.value}, ${data.department.value}, ${data.phoneNumber.value}`
        : "";
      if (
        authObj &&
        patient &&
        authObj.userProfile &&
        authObj.registeredFaciltyAddress &&
        authObj.registeredFaciltyAddress.siteUseId &&
        authObj.registeredFaciltyAddress.accountNumber
      ) {
        const params: ISubmitPickupRequest = {
          RON: patient!.roNumber,
          ROPN: patient!.rentalOrderProductNumber ?? "",
          WON: patient!.workOrderNumber ?? "",
          DischargeReason: "",
          PlacementDate: moment(patient!.placementDate).format("yyyy-MM-DD"),
          returnToUPS: null,
          patientContactUPS: null,
          ContactPatientClient: null,
          IsInjuredFromDevice:
            data.injuryCauseBy3MDevice.value === "yes" ? true : false,
          InjuryDescription: "",
          DeviceProblemDescription: data.describeTheProblem.value,
          DOB: patient!.dob ? moment(patient!.dob).format("yyyy-MM-DD") : null,
          SiteUseId: getSiteUseId(authObj),
          PatientTransferComments: notes,
          isAcuteOrder: patient?.isAcuteOrder ?? false,
          orderType: patient?.type,
          theraphyDischargeDate: moment(
            enterPickUpInformation?.therapyDischargeDate?.value
          ).format("yyyy-MM-DD"),
          productCode: patient.productName ?? "",
          productSerialNo: patient.productSerialNumber ?? "",
          storageLocation: enterPickUpInformation.pickupLocation.value,
          stopBillDate: moment(Date()).format("yyyy-MM-DD"),
          pickupDateTime: formatedDate,
          contactFirstName: data.isCallOnEstimatedArrivalTime.value
            ? data.firstName.value
            : authObj.userProfile.firstName,
          contactLastName: data.isCallOnEstimatedArrivalTime.value
            ? data.lastName.value
            : authObj.userProfile.lastName,
          contactPhone: data.isCallOnEstimatedArrivalTime.value
            ? data.phoneNumber.value
            : authObj.userProfile.phoneNumber,
          patientFirstName: patient.firstName,
          patientLastName: patient.lastName,
          patientLocation:
            enterPickUpInformation?.roomNo?.value ?? patient.patientLocation,
          loggedInUserPhone: authObj.userProfile.phoneNumber
            ? authObj.userProfile.phoneNumber
            : authObj.userProfile.mobilePhoneNumber,
          loggedInUserFacility: authObj.registeredFaciltyAddress.accountName,
          loggedInUserFacilityAccNo:
            authObj.registeredFaciltyAddress.accountNumber.toString(),
          loggedInUserFacilityAddress: {
            addressLine1: authObj.registeredFaciltyAddress.address1,
            addressLine2: authObj.registeredFaciltyAddress.address2,
            city: authObj.registeredFaciltyAddress.city,
            state: authObj.registeredFaciltyAddress.state,
            zipCode: authObj.registeredFaciltyAddress.zip.toString(),
          },
          appVersion: process.env.REACT_APP_VERSION ?? "",
        };
        const response = await savePickupRequest(params);
        if (response && response.succeeded) {
          const workOrderNumber = extractPickupIdUsingSplit(response.data);
          setPickUpWorkOrderNumber(workOrderNumber);
          window.scrollTo(0, 0);
          acuteOrderPickUpRequestObj!.setPage(
            AcuteOrderPickUpRequestPageSection.ACUTE_PICKUP_SUMMARY_PAGE
          );
        } else {
          const errorFailure = {
            errorCode:
              response?.error?.code ||
              response?.error?.errorCode ||
              response?.status,
            errorFlag: true,
            errorMessage: SVC_ACUTE_ORDER_PICK_UP_REQUST_ERROR,
          };
          setError(errorFailure);
        }
        setShowLoader(false);
      } else {
        let errorCode = "";
        if (!authObj || (authObj && !authObj.registeredFaciltyAddress)) {
          errorCode = REGISTERED_FACILITY_OBJ_FROM_AUTH_CONTEXT_FAILURE;
        } else if (authObj && !authObj.registeredFaciltyAddress?.siteUseId) {
          errorCode = SITEUSEID_FROM_REGISTERED_FACILITY_OBJ_FAILURE;
        }
        const errorFailure = {
          errorCode: errorCode,
          errorFlag: true,
          errorMessage: SVC_ACUTE_ORDER_PICK_UP_REQUST_ERROR,
        };
        setError(errorFailure);
      }
    } else {
      const errorFailure = {
        errorCode:
          currentDateRes?.error?.errorCode ||
          currentDateRes?.error?.code ||
          currentDateRes?.status,
        errorFlag: true,
        errorMessage: ERROR_MSG_GET_GET_CURRENT_DATE_TIME,
      };
      setError(errorFailure);
    }
  };

  const checkForPreviousButtonText = (): string => {
    return acuteOrderPickUpRequestObj?.page !==
      AcuteOrderPickUpRequestPageSection.ACUTE_PICKUP_FIRST_PAGE
      ? "Previous"
      : "";
  };

  const checkForNextButtonText = (): string => {
    return acuteOrderPickUpRequestObj?.page ===
      AcuteOrderPickUpRequestPageSection.ACUTE_PICKUP_REVIEW_PAGE
      ? isMobileScreen
        ? "Submit"
        : "Submit Request"
      : "Continue";
  };

  const checkForNextButtonDisabled = () => {
    return (
      permissionObj?.mappedRolesPermissionData.IsSupportRole &&
      acuteOrderPickUpRequestObj?.page ===
        AcuteOrderPickUpRequestPageSection.ACUTE_PICKUP_REVIEW_PAGE
    );
  };

  const editButtonClick = (scrollableComponentClassName: string) => {
    window.scroll(0, 0);
    acuteOrderPickUpRequestObj!.setPage(
      AcuteOrderPickUpRequestPageSection.ACUTE_PICKUP_FIRST_PAGE
    );
    setTimeout(() => {
      if (scrollableComponentClassName) {
        let scrollableComponent = document.getElementsByClassName(
          scrollableComponentClassName
        )[0];
        if (scrollableComponent) {
          scrollableComponent.scrollIntoView({
            behavior: "smooth",
          });
        }
      }
    }, 500);
  };

  const nextButtonAction = () => {
    switch (acuteOrderPickUpRequestObj!.page) {
      case AcuteOrderPickUpRequestPageSection.ACUTE_PICKUP_FIRST_PAGE:
        const validator = new AcuteOrderPickUpRequestValidator();
        const [isAllValid, errorField] = validator.validateAll(data, setData);
        if (errorField) {
          checkErroredFieldAndFocusOnInputField(errorField);
        }
        if (isAllValid === ValidationStatus.VALID) {
          window.scrollTo(0, 0);
          acuteOrderPickUpRequestObj?.setPage(
            AcuteOrderPickUpRequestPageSection.ACUTE_PICKUP_REVIEW_PAGE
          );
        }
        break;
      case AcuteOrderPickUpRequestPageSection.ACUTE_PICKUP_REVIEW_PAGE:
        callSubmitPickupRequest();
        break;
      default:
        break;
    }
  };

  const previousButtonAction = () => {
    switch (acuteOrderPickUpRequestObj!.page) {
      case AcuteOrderPickUpRequestPageSection.ACUTE_PICKUP_FIRST_PAGE:
        window.scrollTo(0, 0);
        history.goBack();
        break;
      case AcuteOrderPickUpRequestPageSection.ACUTE_PICKUP_REVIEW_PAGE:
        window.scrollTo(0, 0);
        acuteOrderPickUpRequestObj!.setPage(
          AcuteOrderPickUpRequestPageSection.ACUTE_PICKUP_FIRST_PAGE
        );
        break;
      default:
        break;
    }
  };

  const cancelButtonAction = () => {
    history.goBack();
  };

  const checkErroredFieldAndFocusOnInputField = async (
    errorField: IInputField
  ) => {
    if (errorField.componentId && errorField.componentId !== "") {
      const scrollableComponent = document.getElementById(
        errorField.componentId
      );
      await setTimeout(() => {
        if (scrollableComponent) {
          scrollableComponent.scrollIntoView({
            behavior: "smooth",
          });
          if (errorField.id && errorField.id !== "") {
            const autoCursorComponentId = document.getElementById(
              errorField.id
            );
            setTimeout(() => {
              if (autoCursorComponentId) {
                autoCursorComponentId.focus();
              }
            }, 500);
          }
        }
      }, 500);
    }
  };

  const extractPickupIdUsingSplit = (input: string): string => {
    // Split the string based on the "Pickup ID - " delimiter
    const parts = input.split("WorkOrderNumber - ");
    // If there are at least two parts and the second part contains the ID
    if (parts.length > 1) {
      const pickupId = parts[1].trim().split(" ")[0]; // Get the first part after the split and trim any extra spaces
      return pickupId;
    }
    return "";
  };

  const updateValidations = (isOwned: boolean, patientLocation: string) => {
    const name = "roomNo";
    let tempData = { ...data };
    if (isOwned) {
      tempData = {
        ...tempData,
        isCallOnEstimatedArrivalTime: {
          ...tempData.isCallOnEstimatedArrivalTime,
          required: false,
        },
      };
    } else {
      tempData = {
        ...tempData,
        isCallOnEstimatedArrivalTime: {
          ...tempData.isCallOnEstimatedArrivalTime,
          required: true,
        },
        enterPickUpInformation: {
          ...tempData.enterPickUpInformation,
          value: {
            ...tempData.enterPickUpInformation.value,
            [name]: {
              ...Object(tempData.enterPickUpInformation.value)[name],
              errorMessage: null,
              valid: ValidationStatus.VALID,
              value: patientLocation,
              required: true,
            },
            pickupLocation: {
              ...Object(tempData.enterPickUpInformation.value)[
                "pickupLocation"
              ],
              required: false,
            },
          },
        },
      };
    }
    setData(tempData);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (storageLocations.length === 0) {
      loadPatientProductInfo();
      getStorageLocations();
    }
  }, []);

  const sectionToDisplay = () => {
    let page: ReactNode;
    switch (acuteOrderPickUpRequestObj!.page) {
      case AcuteOrderPickUpRequestPageSection.ACUTE_PICKUP_FIRST_PAGE:
        page = (
          <AcuteOrderPickUpRequestFirstPage
            data={data}
            patient={patient!}
            setData={setData}
            storageLocations={storageLocations}
            isCustomerOwnedProduct={isCustomeOwned}
          />
        );
        break;
      case AcuteOrderPickUpRequestPageSection.ACUTE_PICKUP_REVIEW_PAGE:
        page = (
          <AcuteOrderPickUpRequestReviewPage
            data={data}
            editButtonAction={editButtonClick}
            isReviewOrder={true}
            patient={patient!}
            setData={setData}
            isCustomerOwnedProduct={isCustomeOwned}
          />
        );
        break;
      case AcuteOrderPickUpRequestPageSection.ACUTE_PICKUP_SUMMARY_PAGE:
        page = (
          <AcuteOrderPickUpRequestSummaryPage
            data={data}
            editButtonAction={editButtonClick}
            isReviewOrder={true}
            isSummaryOrder={true}
            patient={patient!}
            setData={setData}
            workOrderNumber={pickUpWorkOrderNumber}
            isCustomerOwnedProduct={isCustomeOwned}
          />
        );
        break;
    }
    return page;
  };

  return (
    <div
      className="acute-pick-up-request-container-componenet"
      data-testid="acute-pick-up-request-container-componenet"
    >
      {sectionToDisplay()}
      {acuteOrderPickUpRequestObj?.page !==
        AcuteOrderPickUpRequestPageSection.ACUTE_PICKUP_SUMMARY_PAGE && (
        <AcuteOrderPickUpRequestFooter
          firstButtonAction={cancelButtonAction}
          firstButtonDisabled={false}
          firstButtonTitle="Cancel"
          secondButtonAction={previousButtonAction}
          secondButtonDisabled={false}
          secondButtonTitle={checkForPreviousButtonText()}
          thirdButtonAction={nextButtonAction}
          thirdButtonDisabled={checkForNextButtonDisabled()}
          thirdButtonTitle={checkForNextButtonText()}
        />
      )}
      <Popup
        closeHandler={() => setShowLoader(false)}
        dialogParentClass={"acute-pick-up-request-loader-pop-up"}
        data-testid="acute-pick-up-request-pop-up"
        hideCloseButton={true}
        openFlag={showLoader}
      >
        <div
          className="acute-pick-up-request-loader"
          data-testid="acute-pick-up-request-loader"
        >
          <LoadingSpinner />
        </div>
      </Popup>
      <ErrorPopup
        buttontitle={"Done"}
        errorCode={error.errorCode}
        errorPopupFlag={error.errorFlag}
        errorMessage={error.errorMessage}
        isShortSentence={true}
        handleBackButton={() => setError(defaultErrorPopUp)}
        popUpStyles="errorPopup"
        isSupportPhoneRequired={true}
        showTitle={false}
      />
    </div>
  );
};
