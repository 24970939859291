import { InputBase } from "@mui/material";
import { InputWithLabel } from "../../../../core/inputWithLabel/inputWithLabel.component";

type TextProps = {
  name: string;
  label: string;
  required: boolean;
  error: boolean;
  onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  value: string;
  testid?: string;
  errorMessage?: string;
  id?: string | null;
  componentId?: string | null;
};
export const TextQuestion = ({
  name,
  label,
  required,
  error,
  onChange,
  value,
  testid,
  errorMessage,
  id,
  componentId,
}: TextProps) => {
  return (
    <div className="question-container">
      <InputWithLabel
        label={label}
        isRequired={required}
        error={error}
        testId={testid!}
        isShowWarning={true}
        warningMessage={errorMessage}
        id={componentId ?? ""}
      >
        <InputBase
          className="text-question"
          name={name}
          value={value}
          onChange={onChange}
          required={required}
          placeholder="Describe the surgical procedure"
          id={id ?? ""}
        />
      </InputWithLabel>
    </div>
  );
};
