import "./category.css";
import { IFacilitySettingPermission } from "../../facilitySettings.interface";
import { PermissionCheckbox } from "./permissionCheckbox/permissionCheckbox.component";
import { getFacilityPermissionCategoryName } from "../../../../../util/utilityFunctions";

interface CategoryProps {
  category: string;
  permissions: IFacilitySettingPermission[];
  onTogglePermission: (permissionName: string) => void;
  onToggleSubPermission: (
    parentPermissionName: string,
    subPermissionName: string
  ) => void;
}

export const Category = ({
  category,
  permissions,
  onTogglePermission,
  onToggleSubPermission,
}: CategoryProps) => {
  return (
    <div className="facility-permissions-category-component">
      <p
        className="permission-category-title"
        data-testid="permission-category-title"
        id="permission-category-title"
      >
        {getFacilityPermissionCategoryName(category)}
      </p>
      {permissions.map((permission, index) => (
        <PermissionCheckbox
          key={permission.permissionName}
          index={index}
          permission={permission}
          onToggle={onTogglePermission}
          onToggleSubPermission={onToggleSubPermission}
        />
      ))}
    </div>
  );
};
