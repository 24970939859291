import "./vacDressingKit.css";
import {
  ProductInformation,
  VacDressingKitModel,
} from "./vacDressingKit.interface";
import {
  SpecialProduct,
  checkSelectedProdcutForType,
  checkSelectedVacProdcutForType,
  getVacProductsWithout,
} from "../../../../util/utilityFunctions";
import {
  NewOrderContext,
  NewOrderContextType,
} from "../../../../context/NewOrderContext";
import { Button, Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useContext, useEffect, useMemo, useState } from "react";
import { NewOrderValidator } from "../../newOrder.validator";
import { VacDressing } from "./VacDressingKitMain.component";
import { IDressingKit, IProductInfo } from "../../newOrder.interface";
import { AuthContextType, AuthContext } from "../../../../context/AuthContext";
import { ValidationStatus } from "../../../../core/interfaces/input.interface";
import {
  InternalSignOnContext,
  InternalSignOnContextType,
} from "../../../../context/InternalSignOnContext";
import {
  CMS_SUPPLY_CONTENT,
  PRODUCT_TYPE_DRESSING,
} from "../../../../util/staticText";
import { IInsuranceInformation } from "../../insuranceInformation/insuranceInformation/insuranceInformation.interface";
import { getCMSContent } from "../../../../util/cmsService";
import { IPeelAndPlace } from "./peelAndPlace/peelAndPlace.component";

interface IVacDressingKit {
  data: IDressingKit;
  primaryInsurance?: IInsuranceInformation;
  productInfo: IProductInfo;
  setData: Function;
  vacProducts?: any;
  vacAllProducts?: VacDressingKitModel | null;
  Validator?: NewOrderValidator;
  isDefaultClicked?: boolean;
  defaultVacdressingKit?: string;
}

export const VacDressingKit = ({
  data,
  primaryInsurance,
  productInfo,
  setData,
  vacProducts,
  vacAllProducts,
  Validator = new NewOrderValidator(),
  isDefaultClicked,
  defaultVacdressingKit,
}: IVacDressingKit) => {
  const [isCharityCareSelected, setIsCharityCareSelected] =
    useState<boolean>(false);
  const [vacProductSize, setVacProductSize] = useState<any>([]);
  const [vacSecondaryProductSize, setVacSecondaryProductSize] = useState<any>(
    []
  );
  const [isPeelAndPlaceDressing, setIsPeelAndPlaceDressing] =
    useState<boolean>(false);
  const [
    isShowWarningForSecondaryDressing,
    setIsShowWarningForSecondaryDressing,
  ] = useState<boolean>(false);
  const [instruction, setInstruction] = useState<IPeelAndPlace | null>(null);
  const [note, setNote] = useState<IPeelAndPlace | null>(null);

  const authObj = useContext<AuthContextType | null>(AuthContext);
  const newOrderObj = useContext<NewOrderContextType | null>(NewOrderContext);
  const internalObj = useContext<InternalSignOnContextType | null>(
    InternalSignOnContext
  );

  const fetchPeelAndPlaceInstructionsAndNotes = async () => {
    const data = await getCMSContent(CMS_SUPPLY_CONTENT);
    if (data && data.succeeded && data.item) {
      setInstruction(data.item.instruction);
      setNote(data.item.note);
    }
  };

  const checkForReadyCareFlag = () => {
    return (
      (internalObj?.isVACOrderSSO &&
        newOrderObj?.newOrderData &&
        newOrderObj?.newOrderData.requestingFacility?.value &&
        newOrderObj?.newOrderData.requestingFacility?.value?.readyCareFlag &&
        newOrderObj?.newOrderData.requestingFacility?.value?.readyCareFlag ===
          "Y") ||
      (authObj &&
        authObj.registeredFaciltyAddress &&
        authObj.registeredFaciltyAddress.readyCareFlag &&
        authObj.registeredFaciltyAddress.readyCareFlag === "Y")
    );
  };

  const getSelectedVacProduct = (
    sku: string,
    vacAllProducts: any
  ): ProductInformation | null => {
    let obj = null;
    if (vacAllProducts) {
      vacAllProducts.items.map((element: ProductInformation) => {
        if (element.sku === sku) {
          obj = element;
        } else if (element.sizes) {
          element.sizes.forEach((sa: any) => {
            if (sa.sku === sku) {
              obj = element;
            }
          });
        }
      });
    }
    return obj;
  };

  const setProductSizeDropDown = (selectedVac: any, type: string) => {
    const vacProducts = selectedVac?.sizes.map(
      (x: { name: any; sku: any }) => ({
        id: x.sku,
        code: x.sku,
        text: x.name,
      })
    )!;
    if (type === "primary") {
      setVacProductSize(vacProducts);
    } else {
      setVacSecondaryProductSize(vacProducts);
    }
  };

  const checkForPeelAndPlaceDressing = (
    selectedVac: ProductInformation,
    tempData: IDressingKit,
    isUpdateRequired: boolean = false
  ): boolean => {
    const isSelectedProductHasPeelAndPlace = checkSelectedVacProdcutForType(
      selectedVac,
      SpecialProduct.PEELANDPLACE
    );
    if (isSelectedProductHasPeelAndPlace) {
      tempData = {
        ...tempData,
        productQuantity: {
          valid: ValidationStatus.VALID,
          value: "1",
        },
      };
      validateProductQuantity(`1`, tempData.secProductQuantity.value);
      setIsPeelAndPlaceDressing(true);
      if (isUpdateRequired) {
        setData(tempData);
      }
      return true;
    } else if (isPeelAndPlaceDressing) {
      setIsPeelAndPlaceDressing(false);
    }
    return false;
  };

  const validateAndSetData = (e: any, param: any, type: string) => {
    newOrderObj?.setIsHandleChangeTriggered(true);
    const { name, value } = e.target;
    const isValid = Validator.validate(value, name);
    const selectedCode =
      param.key.split("-")[1] === "null" ? "" : param.key.split("-")[1];
    const selectedVac = vacAllProducts?.items.filter(
      (x) => x.productName === value
    )[0];
    let tempData = data;
    if (type === "primary") {
      if (selectedVac) {
        const isPeelAndPlace = checkForPeelAndPlaceDressing(
          selectedVac,
          tempData
        );
        setIsShowWarningForSecondaryDressing(
          isPeelAndPlace &&
            checkSelectedProdcutForType(
              vacAllProducts.items,
              SpecialProduct.WHITEFOAM,
              data.secProductName.value
            )
        );
      }
      newOrderObj?.setshowQunatity(true);
      if (selectedVac?.sizes !== null && selectedVac?.sizes.length! > 1) {
        setProductSizeDropDown(selectedVac, type);
        newOrderObj?.setShowSize(true);
        tempData = {
          ...tempData,
          productName: {
            ...tempData.productName,
            errorMessage: null,
            isOptional: false,
            valid: isValid!.status,
            value: value,
          },
          productSizeName: {
            ...tempData.productSizeName,
            isOptional: false,
            required: true,
            valid: ValidationStatus.UNTOUCHED,
            value: "",
          },
          productId: {
            valid: ValidationStatus.VALID,
            value: selectedCode ? selectedCode : "",
          },
          productCode: {
            ...tempData.productCode,
            value: selectedCode ? selectedCode : "",
          },
          productSizeID: {
            valid: ValidationStatus.VALID,
            value: "",
          },
          productSizeCode: {
            valid: ValidationStatus.VALID,
            value: "",
          },
          productQuantity: {
            valid: ValidationStatus.VALID,
            value: data.productQuantity.value,
          },
        };
      } else {
        setVacProductSize([]);
        newOrderObj?.setShowSize(false);
        tempData = {
          ...tempData,
          productName: {
            ...tempData.productName,
            errorMessage: null,
            isOptional: false,
            valid: isValid!.status,
            value: value,
          },
          productSizeName: {
            ...tempData.productSizeName,
            isOptional: false,
            required: true,
            valid: ValidationStatus.VALID,
            value: "",
          },
          productId: {
            valid: ValidationStatus.VALID,
            value: selectedCode ? selectedCode : "",
          },
          productCode: {
            valid: ValidationStatus.VALID,
            value: selectedCode ? selectedCode : "",
          },
          productSizeCode: {
            valid: ValidationStatus.VALID,
            value: "",
          },
          productQuantity: {
            ...tempData.productSizeCode,
            errorMessage: null,
            valid: ValidationStatus.VALID,
            value: data.productQuantity.value,
          },
        };
      }
    } else {
      if (isPeelAndPlaceDressing && selectedVac) {
        setIsShowWarningForSecondaryDressing(
          checkSelectedVacProdcutForType(selectedVac, SpecialProduct.WHITEFOAM)
        );
      }
      newOrderObj?.setshowSecQunatity(true);
      if (selectedVac && selectedVac.sizes && selectedVac.sizes.length > 1) {
        setProductSizeDropDown(selectedVac, type);
        newOrderObj?.setShowSecSize(true);
        tempData = {
          ...tempData,
          secProductName: {
            ...tempData.secProductName,
            errorMessage: null,
            isOptional: false,
            valid: isValid!.status,
            value: value,
          },
          secProductSizeName: {
            ...tempData.secProductSizeName,
            isOptional: false,
            required: true,
            valid: ValidationStatus.UNTOUCHED,
            value: "",
          },
          secProductId: {
            valid: ValidationStatus.VALID,
            value: selectedCode ? selectedCode : "",
          },
          secProductCode: {
            valid: ValidationStatus.VALID,
            value: selectedCode ? selectedCode : "",
          },
          secProductSizeID: {
            valid: ValidationStatus.VALID,
            value: "",
          },
          secProductSizeCode: {
            valid: ValidationStatus.VALID,
            value: "",
          },
          secProductQuantity: {
            valid: ValidationStatus.VALID,
            value: "1",
          },
        };
      } else {
        setVacSecondaryProductSize([]);
        newOrderObj?.setShowSecSize(false);
        tempData = {
          ...tempData,
          secProductName: {
            ...tempData.secProductName,
            errorMessage: null,
            isOptional: false,
            valid: isValid!.status,
            value: value,
          },
          secProductSizeName: {
            ...tempData.secProductSizeName,
            isOptional: false,
            required: true,
            valid: ValidationStatus.VALID,
            value: "",
          },
          secProductId: {
            valid: ValidationStatus.VALID,
            value: selectedCode ? selectedCode : "",
          },
          secProductCode: {
            valid: ValidationStatus.VALID,
            value: selectedCode ? selectedCode : "",
          },
          secProductSizeID: {
            valid: ValidationStatus.VALID,
            value: "",
          },
          secProductSizeCode: {
            valid: ValidationStatus.VALID,
            value: "",
          },
          secProductQuantity: {
            valid: ValidationStatus.VALID,
            value: "1",
          },
        };
      }
      // Check priamry quantity, if it is 3 then update it 2
      const maxQuantity =
        checkForReadyCareFlag() &&
        productInfo.productInformation.value &&
        productInfo.productInformation.value === "yes"
          ? 2
          : 3;
      if (maxQuantity === 3 && tempData.productQuantity.value === "3") {
        tempData.productQuantity.value = "2";
      } else if (maxQuantity === 2 && tempData.productQuantity.value === "2") {
        tempData.productQuantity.value = "1";
      }
    }
    setData(tempData);
    validateProductQuantity(
      tempData.productQuantity.value,
      tempData.secProductQuantity.value
    );
  };

  const validateAndSetSize = (e: any, param: any, type: string) => {
    const { name, value } = e.target;
    const isValid = Validator.validate(value, name);
    const selectedCode = param.key.split("-")[1];
    if (type === "primary") {
      setData({
        ...data,
        productSizeName: {
          ...data.productSizeName,
          errorMessage: null,
          isOptional: false,
          valid: isValid!.status,
          value: value,
        },
        productSizeCode: {
          value: selectedCode,
          valid: isValid!.status,
        },
        productCode: {
          valid: isValid!.status,
          value: selectedCode,
        },
      });
    } else {
      setData({
        ...data,
        secProductSizeName: {
          ...data.secProductSizeName,
          errorMessage: null,
          isOptional: false,
          valid: isValid!.status,
          value: value,
        },
        secProductSizeCode: {
          valid: ValidationStatus.VALID,
          value: selectedCode,
        },
        secProductCode: {
          valid: isValid!.status,
          value: selectedCode,
        },
      });
    }
  };

  const validateProductQuantity = (
    primaryQuantity: string,
    secondaryQuantity: string,
    checkForDefault: boolean = false
  ) => {
    let isPrimaryQunatityNeedToUpdate = false;
    let quantity1 = primaryQuantity !== "" ? parseInt(primaryQuantity) : 0;
    let quantity2 = secondaryQuantity !== "" ? parseInt(secondaryQuantity) : 0;
    let totalQuantity = quantity1 + quantity2;
    const maxQuantity =
      checkForReadyCareFlag() &&
      productInfo.productInformation.value &&
      productInfo.productInformation.value === "yes"
        ? 2
        : 3;
    // check for something went wrong
    if (checkForDefault && totalQuantity > maxQuantity) {
      totalQuantity = maxQuantity;
      quantity1 = maxQuantity;
      quantity2 = 0;
      isPrimaryQunatityNeedToUpdate = true;
    }
    if (totalQuantity === 3) {
      newOrderObj?.setIsPrimaryVacKitDressingPlusDisabled(true);
      newOrderObj?.setIsSecondaryVacKitDressingPlusDisabled(true);
      // quantity1 never will be 0
      if (quantity1 === 1) {
        // If quantity1 is 1 then quantity2 will be 2
        newOrderObj?.setIsPrimaryVacKitDressingMinusDisabled(true);
        newOrderObj?.setIsSecondaryVacKitDressingMinusDisabled(false);
      } else if (quantity1 === 2) {
        // If quantity1 is 2 then quantity2 will be 1
        newOrderObj?.setIsPrimaryVacKitDressingMinusDisabled(false);
        newOrderObj?.setIsSecondaryVacKitDressingMinusDisabled(true);
      } else {
        // else quantity 1 will be 3
        newOrderObj?.setIsPrimaryVacKitDressingMinusDisabled(false);
      }
    } else if (totalQuantity === 2) {
      if (quantity1 === 0) {
        // // If quantity1 is 0 then quantity2 will be 2
        newOrderObj?.setIsSecondaryVacKitDressingPlusDisabled(false);
        newOrderObj?.setIsSecondaryVacKitDressingMinusDisabled(false);
      } else if (quantity1 === 1) {
        // If quantity1 is 1 then quantity2 will be 1
        newOrderObj?.setIsPrimaryVacKitDressingPlusDisabled(
          maxQuantity === 3 ? false : true
        );
        newOrderObj?.setIsPrimaryVacKitDressingMinusDisabled(true);
        newOrderObj?.setIsSecondaryVacKitDressingPlusDisabled(
          maxQuantity === 3 ? false : true
        );
        newOrderObj?.setIsSecondaryVacKitDressingMinusDisabled(true);
      } else {
        // Quantity1 is 2 then quantity2 will be 0
        newOrderObj?.setIsPrimaryVacKitDressingPlusDisabled(
          maxQuantity === 3 ? false : true
        );
        newOrderObj?.setIsPrimaryVacKitDressingMinusDisabled(false);
      }
    } else if (totalQuantity === 1) {
      if (quantity1 === 0) {
        // // If quantity1 is 0 then quantity2 will be 1
        newOrderObj?.setIsSecondaryVacKitDressingPlusDisabled(false);
        newOrderObj?.setIsSecondaryVacKitDressingMinusDisabled(true);
      } else {
        // If quantity1 is 1 then quantity2 will be 1
        newOrderObj?.setIsPrimaryVacKitDressingPlusDisabled(false);
        newOrderObj?.setIsPrimaryVacKitDressingMinusDisabled(true);
      }
    }
    return checkForDefault
      ? [isPrimaryQunatityNeedToUpdate, maxQuantity]
      : [undefined, undefined];
  };

  const handlePrimaryVacKitDressingPlusClick = () => {
    const increamentedPrimaryDressingQunatity =
      data.productQuantity.value !== ""
        ? parseInt(data.productQuantity.value) + 1
        : 1;
    const maxQuantity =
      checkForReadyCareFlag() &&
      productInfo.productInformation.value &&
      productInfo.productInformation.value === "yes"
        ? 2
        : 3;
    if (increamentedPrimaryDressingQunatity > maxQuantity) {
      return;
    }
    const tempData = {
      ...data,
      productQuantity: {
        valid: ValidationStatus.VALID,
        value: `${increamentedPrimaryDressingQunatity}`,
      },
    };
    setData(tempData);
    validateProductQuantity(
      `${increamentedPrimaryDressingQunatity}`,
      tempData.secProductQuantity.value
    );
  };

  const handlePrimaryVacKitDressingMinusClick = () => {
    const decreamentedPrimaryDressingQunatity =
      parseInt(data.productQuantity.value) - 1;
    if (decreamentedPrimaryDressingQunatity === 0) {
      return;
    }
    const tempData = {
      ...data,
      productQuantity: {
        valid: ValidationStatus.VALID,
        value: `${decreamentedPrimaryDressingQunatity}`,
      },
    };
    setData(tempData);
    validateProductQuantity(
      `${decreamentedPrimaryDressingQunatity}`,
      tempData.secProductQuantity.value
    );
  };

  const handleSecondaryVacKitDressingPlusClick = () => {
    const increamentedSecondaryDressingQunatity =
      parseInt(data.secProductQuantity.value) + 1;
    const maxQuantity =
      checkForReadyCareFlag() &&
      productInfo.productInformation.value &&
      productInfo.productInformation.value === "yes"
        ? 2
        : 3;
    if (increamentedSecondaryDressingQunatity > maxQuantity) {
      return;
    }
    const tempData = {
      ...data,
      secProductQuantity: {
        valid: ValidationStatus.VALID,
        value: `${increamentedSecondaryDressingQunatity}`,
      },
    };
    setData(tempData);
    validateProductQuantity(
      tempData.productQuantity.value,
      `${increamentedSecondaryDressingQunatity}`
    );
  };

  const handleSecondaryVacKitDressingMinusClick = () => {
    const decreamentedSecondaryDressingQunatity =
      parseInt(data.secProductQuantity.value) - 1;
    if (decreamentedSecondaryDressingQunatity === 0) {
      return;
    }
    const tempData = {
      ...data,
      secProductQuantity: {
        valid: ValidationStatus.VALID,
        value: `${decreamentedSecondaryDressingQunatity}`,
      },
    };
    setData(tempData);
    validateProductQuantity(
      tempData.productQuantity.value,
      `${decreamentedSecondaryDressingQunatity}`
    );
  };

  const moveDressingSecondaryToPrimary = () => {
    newOrderObj?.setShowPrimaryDressingKit(true);
    newOrderObj?.setShowSize(newOrderObj?.showSecSize ? true : false);
    newOrderObj?.setshowQunatity(newOrderObj?.showSecQunatity ? true : false);
    newOrderObj?.setIsPrimaryVacKitDressingPlusDisabled(
      newOrderObj?.isSecondaryVacKitDressingDisabled ? true : false
    );
    newOrderObj?.setIsPrimaryVacKitDressingMinusDisabled(
      newOrderObj?.isSecondaryVacKitDressingMinusDisabled ? true : false
    );
    newOrderObj?.setShowSecSize(false);
    newOrderObj?.setshowSecQunatity(false);
    newOrderObj?.setIsSecondaryVacKitDressingPlusDisabled(false);
    newOrderObj?.setIsSecondaryVacKitDressingMinusDisabled(true);
    setData({
      ...data,
      productName: {
        ...data.productName,
        isOptional: false,
        valid: data.secProductName.valid,
        value: data.secProductName.value,
      },
      productSizeName: {
        ...data.productSizeName,
        isOptional: false,
        valid: data.secProductSizeName.valid,
        value: data.secProductSizeName.value,
      },
      productId: {
        valid: data.secProductId.valid,
        value: data.secProductId.value,
      },
      productCode: {
        valid: data.secProductCode.valid,
        value: data.secProductCode.value,
      },
      productSizeId: {
        valid: data.secProductSizeID.valid,
        value: data.secProductSizeID.value,
      },
      productSizeCode: {
        valid: data.secProductSizeCode.valid,
        value: data.secProductSizeCode.value,
      },
      productQuantity: {
        valid: data.secProductQuantity.valid,
        value: data.secProductQuantity.value,
      },
      secProductName: {
        ...data.secProductName,
        isOptional: false,
        valid: ValidationStatus.UNTOUCHED,
        value: "",
      },
      secProductSizeName: {
        ...data.secProductSizeName,
        isOptional: false,
        valid: ValidationStatus.UNTOUCHED,
        value: "",
      },
      secProductId: {
        valid: ValidationStatus.VALID,
        value: "",
      },
      secProductCode: {
        valid: ValidationStatus.VALID,
        value: "",
      },
      secProductSizeID: {
        valid: ValidationStatus.VALID,
        value: "",
      },
      secProductSizeCode: {
        valid: ValidationStatus.VALID,
        value: "",
      },
      secProductQuantity: {
        valid: ValidationStatus.VALID,
        value: "0",
      },
    });
  };

  const addDressingKitClick = () => {
    if (!newOrderObj?.showPrimaryDressingKit) {
      // Check if Secondary dressing exists, If exists transfer secondary dressing to primary and make secondary empty.
      if (newOrderObj?.showSecondaryDressingKit) {
        moveDressingSecondaryToPrimary();
      } else {
        newOrderObj?.setShowPrimaryDressingKit(true);
        newOrderObj?.setShowSize(false);
        newOrderObj?.setshowQunatity(false);
        const defaultPrimaryQuantity =
          checkForReadyCareFlag() &&
          productInfo.productInformation.value &&
          productInfo.productInformation.value === "yes"
            ? "2"
            : "3";
        setData({
          ...data,
          productName: {
            ...data.productName,
            isOptional: false,
            required: true,
            valid: ValidationStatus.UNTOUCHED,
            value: "",
          },
          productSizeName: {
            ...data.productSizeName,
            isOptional: false,
            valid: ValidationStatus.UNTOUCHED,
            value: "",
          },
          productId: { value: "", valid: ValidationStatus.VALID },
          productCode: { value: "", valid: ValidationStatus.VALID },
          productSizeID: { value: "", valid: ValidationStatus.VALID },
          productSizeCode: { value: "", valid: ValidationStatus.VALID },
          productQuantity: {
            value: defaultPrimaryQuantity,
            valid: ValidationStatus.UNTOUCHED,
          },
        });
      }
    } else {
      newOrderObj?.setShowSecondaryDressingKit(true);
      setData({
        ...data,
        secProductName: {
          ...data.secProductName,
          isOptional: false,
          valid: ValidationStatus.UNTOUCHED,
          value: "",
        },
        secProductSizeName: {
          ...data.secProductSizeName,
          isOptional: false,
          valid: ValidationStatus.UNTOUCHED,
          value: "",
        },
        secProductId: { valid: ValidationStatus.VALID, value: "" },
        secProductCode: { valid: ValidationStatus.VALID, value: "" },
        secProductSizeID: { valid: ValidationStatus.VALID, value: "" },
        secProductSizeCode: { valid: ValidationStatus.VALID, value: "" },
        secProductQuantity: { valid: ValidationStatus.VALID, value: "" },
      });
    }
  };

  const handleCloseIcon = () => {
    const tempData = {
      ...data,
      productName: {
        ...data.productName,
        isOptional: true,
        valid: ValidationStatus.VALID,
        value: "",
      },
      productSizeName: {
        ...data.productSizeName,
        isOptional: true,
        valid: ValidationStatus.VALID,
        value: "",
      },
      productId: { valid: ValidationStatus.VALID, value: "" },
      productCode: { valid: ValidationStatus.VALID, value: "" },
      productSizeID: { valid: ValidationStatus.VALID, value: "" },
      productSizeCode: { valid: ValidationStatus.VALID, value: "" },
      productQuantity: { valid: ValidationStatus.VALID, value: "" },
    };
    newOrderObj?.setShowPrimaryDressingKit(false);
    newOrderObj?.setShowSize(false);
    newOrderObj?.setshowQunatity(false);
    setData(tempData);
    setIsPeelAndPlaceDressing(false);
    validateProductQuantity(
      tempData.productQuantity.value,
      tempData.secProductQuantity.value
    );
  };

  const handleCloseSecIcon = () => {
    newOrderObj?.setShowSecondaryDressingKit(false);
    handleResetSecondaryDressingKit();
    newOrderObj?.setShowSecSize(false);
    newOrderObj?.setshowSecQunatity(false);
  };

  const handleResetSecondaryDressingKit = () => {
    const tempData = {
      ...data,
      secProductName: {
        ...data.secProductName,
        isOptional: true,
        valid: ValidationStatus.VALID,
        value: "",
      },
      secProductSizeName: {
        ...data.secProductSizeName,
        isOptional: true,
        valid: ValidationStatus.VALID,
        value: "",
      },
      secProductId: { valid: ValidationStatus.VALID, value: "" },
      secProductCode: { valid: ValidationStatus.VALID, value: "" },
      secProductSizeID: { valid: ValidationStatus.VALID, value: "" },
      secProductSizeCode: { valid: ValidationStatus.VALID, value: "" },
      secProductQuantity: {
        value: "0",
        valid: ValidationStatus.VALID,
      },
    };
    newOrderObj?.setShowSecSize(false);
    newOrderObj?.setshowSecQunatity(false);
    setData(tempData);
    setIsShowWarningForSecondaryDressing(false);
    validateProductQuantity(
      tempData.productQuantity.value,
      tempData.secProductQuantity.value
    );
  };

  const setDefaultDressingQty = () => {
    const isReadyCare: boolean =
      checkForReadyCareFlag() &&
      productInfo.productInformation.value &&
      productInfo.productInformation.value === "yes"
        ? true
        : false;
    newOrderObj?.setShowSize(true);
    newOrderObj?.setshowQunatity(true);
    newOrderObj?.setShowAddDressingBtn(!isReadyCare);
    let dressing: ProductInformation | null = getSelectedVacProduct(
      defaultVacdressingKit!,
      vacAllProducts
    );
    if (!dressing) {
      return;
    }
    setProductSizeDropDown(dressing, "primary");
    const filteredResults = dressing!.sizes?.filter(
      (x: any) => x.sku === defaultVacdressingKit!
    );
    const selectedDressingSize = filteredResults[0];
    const dressingSizeName = selectedDressingSize.name;
    const defaultPrimaryQuantity = isReadyCare ? "2" : "3";
    const tempData = {
      ...data,
      productName: {
        ...data.productName,
        isOptional: false,
        valid: ValidationStatus.VALID,
        value: dressing!.productName,
      },
      productSizeName: {
        ...data.productSizeName,
        isOptional: false,
        valid: ValidationStatus.VALID,
        value: dressingSizeName,
      },
      productId: { valid: ValidationStatus.VALID, value: "" },
      productCode: {
        valid: ValidationStatus.VALID,
        value: defaultVacdressingKit!,
      },
      productSizeID: { valid: ValidationStatus.VALID, value: "" },
      productSizeCode: {
        value: defaultVacdressingKit!,
        valid: ValidationStatus.VALID,
      },
      productQuantity: {
        value: defaultPrimaryQuantity,
        valid: ValidationStatus.VALID,
      },
    };
    setData(tempData);
    validateProductQuantity(
      tempData.productQuantity.value,
      tempData.secProductQuantity.value
    );
  };

  const setDefaultDressingQtyandResetSecondary = () => {
    const isReadyCare: boolean =
      checkForReadyCareFlag() &&
      productInfo.productInformation.value &&
      productInfo.productInformation.value === "yes"
        ? true
        : false;
    newOrderObj?.setShowSize(true);
    newOrderObj?.setshowQunatity(true);
    newOrderObj?.setShowAddDressingBtn(!isReadyCare);
    let dressing: ProductInformation | null = getSelectedVacProduct(
      defaultVacdressingKit!,
      vacAllProducts
    );
    if (!dressing) {
      return;
    }
    setProductSizeDropDown(dressing, "primary");
    const filteredResults = dressing!.sizes?.filter(
      (x: any) => x.sku === defaultVacdressingKit!
    );
    const selectedDressingSize = filteredResults[0];
    const dressingSizeName = selectedDressingSize.name;
    const defaultPrimaryQuantity = isReadyCare ? "2" : "3";
    const tempData = {
      ...data,
      productName: {
        ...data.productName,
        isOptional: false,
        valid: ValidationStatus.VALID,
        value: dressing!.productName,
      },
      productSizeName: {
        ...data.productSizeName,
        isOptional: false,
        valid: ValidationStatus.VALID,
        value: dressingSizeName,
      },
      productId: { valid: ValidationStatus.VALID, value: "" },
      productCode: {
        valid: ValidationStatus.VALID,
        value: defaultVacdressingKit!,
      },
      productSizeID: { valid: ValidationStatus.VALID, value: "" },
      productSizeCode: {
        value: defaultVacdressingKit!,
        valid: ValidationStatus.VALID,
      },
      productQuantity: {
        value: defaultPrimaryQuantity,
        valid: ValidationStatus.VALID,
      },
      secProductName: {
        ...data.secProductName,
        isOptional: true,
        valid: ValidationStatus.VALID,
        value: "",
      },
      secProductSizeName: {
        ...data.secProductSizeName,
        isOptional: true,
        valid: ValidationStatus.VALID,
        value: "",
      },
      secProductId: { valid: ValidationStatus.VALID, value: "" },
      secProductCode: { valid: ValidationStatus.VALID, value: "" },
      secProductSizeID: { valid: ValidationStatus.VALID, value: "" },
      secProductSizeCode: { valid: ValidationStatus.VALID, value: "" },
      secProductQuantity: {
        value: "0",
        valid: ValidationStatus.VALID,
      },
    };
    newOrderObj?.setShowSecSize(false);
    newOrderObj?.setshowSecQunatity(false);
    newOrderObj?.setShowSecondaryDressingKit(false);
    setIsShowWarningForSecondaryDressing!(false);
    setData(tempData);
    validateProductQuantity(
      tempData.productQuantity.value,
      tempData.secProductQuantity.value
    );
  };

  const showAddDressingKitBtn = () => {
    let readyCareFlag =
      checkForReadyCareFlag() &&
      productInfo?.productInformation.value === "yes";
    let showBtn = newOrderObj?.showAddDressingBtn;
    if (newOrderObj?.showAddDressingBtn) {
      if (readyCareFlag && newOrderObj?.showPrimaryDressingKit) {
        showBtn = false;
      } else if (
        newOrderObj?.showPrimaryDressingKit &&
        newOrderObj?.showSecondaryDressingKit
      ) {
        showBtn = false;
      } else {
        showBtn = true;
      }
    }
    return showBtn;
  };

  useEffect(() => {
    const [isNeedToUpdateQuantity, maxQuantity] = validateProductQuantity(
      data.productQuantity.value,
      data.secProductQuantity.value,
      true
    );
    if (isNeedToUpdateQuantity) {
      setData({
        ...data,
        productQuantity: {
          valid: ValidationStatus.VALID,
          value: maxQuantity,
        },
      });
    }
  }, [productInfo.productInformation]);

  useEffect(() => {
    if (
      authObj &&
      authObj.registeredFaciltyAddress &&
      data.productName.valid === ValidationStatus.UNTOUCHED &&
      vacAllProducts &&
      !newOrderObj?.isComingFromPrev
    ) {
      setDefaultDressingQty();
    }
  }, [
    vacAllProducts,
    productInfo.productInformation.value,
    authObj?.registeredFaciltyAddress?.readyCareFlag,
    defaultVacdressingKit,
  ]);

  useMemo(() => {
    if (
      authObj &&
      authObj.registeredFaciltyAddress &&
      vacAllProducts &&
      !newOrderObj?.isComingFromPrev &&
      newOrderObj?.isDefaultDressingSelection
    ) {
      newOrderObj?.setIsDefaultDressingSelection!(false);
      if (
        productInfo.productInformation.value === "no" &&
        newOrderObj?.showSecondaryDressingKit
      ) {
        setDefaultDressingQtyandResetSecondary();
      } else {
        newOrderObj?.setShowPrimaryDressingKit(true);
        setDefaultDressingQty();
      }
    }
  }, [
    vacAllProducts,
    productInfo.productInformation.value,
    authObj?.registeredFaciltyAddress?.readyCareFlag,
    newOrderObj?.isDefaultDressingSelection,
  ]);

  useEffect(() => {
    if (
      !newOrderObj?.newOrderData.requestingFacility?.value &&
      !newOrderObj?.isComingFromPrev &&
      internalObj?.isVACOrderSSO
    ) {
      newOrderObj?.setIsDefaultDressingSelection!(false);
      setDefaultDressingQty();
    }
  }, [vacAllProducts, newOrderObj?.isDefaultDressingSelection]);

  useEffect(() => {
    if (newOrderObj) {
      if (productInfo.productInformation.value === "yes") {
        handleResetSecondaryDressingKit();
        if (newOrderObj.showPrimaryDressingKit) {
          newOrderObj.setShowAddDressingBtn(false);
        } else {
          newOrderObj.setShowAddDressingBtn(true);
        }
      } else {
        if (
          newOrderObj.showPrimaryDressingKit &&
          newOrderObj.showSecondaryDressingKit
        ) {
          newOrderObj.setShowAddDressingBtn(false);
        } else {
          newOrderObj?.setShowAddDressingBtn(true);
        }
      }
    }
  }, [
    newOrderObj?.showPrimaryDressingKit,
    newOrderObj?.showSecondaryDressingKit,
  ]);

  useEffect(() => {
    let isPeelAndPlaceDressing = false;
    if (data.productName.value !== "" && vacAllProducts) {
      const selectedVac = vacAllProducts.items.filter(
        (x) => x.productName === data.productName.value
      )[0]!;
      if (selectedVac) {
        isPeelAndPlaceDressing = checkForPeelAndPlaceDressing(
          selectedVac,
          data,
          true
        );
        if (selectedVac.sizes.length > 0 && selectedVac.hasMultipleSize) {
          setProductSizeDropDown(selectedVac, "primary");
        }
      }
    }
    if (data.secProductName.value !== "") {
      const selectedVac = vacAllProducts?.items.filter(
        (x) => x.productName === data?.secProductName.value
      )[0]!;
      setIsShowWarningForSecondaryDressing(
        (isPeelAndPlaceDressing &&
          vacAllProducts &&
          checkSelectedProdcutForType(
            vacAllProducts.items,
            SpecialProduct.WHITEFOAM,
            data.secProductName.value
          )) ??
          false
      );
      if (selectedVac?.sizes !== null && selectedVac?.hasMultipleSize) {
        setProductSizeDropDown(selectedVac, "secondary");
      }
    }
    if ((!instruction || !note) && isPeelAndPlaceDressing) {
      fetchPeelAndPlaceInstructionsAndNotes();
    }
  }, [vacAllProducts, data.productName.value, data.secProductName.value]);

  useMemo(() => {
    // Check for primary insurance is Charity Care or not.
    // If it is Cahrity care then setting isCharityCareSelected as true.
    // So based on this variable it will update vacProducts list to dropdown.
    setIsCharityCareSelected(
      (primaryInsurance &&
        primaryInsurance.insuranceType.value.toLocaleLowerCase() ===
          "Charity Care".toLocaleLowerCase()) ??
        false
    );
  }, [primaryInsurance]);

  return (
    <>
      {!newOrderObj?.isCondensedDressing && (
        <div className="dressingKitInfo">
          <h2 className="dressingKitHeader" data-testid="dressingKitHeaderTest">
            3M™ V.A.C.<sup>®</sup> Dressing Kits
          </h2>
          <p className="dressingKitBody" data-testid="dressingKitBodyTest">
            {productInfo.productInformation.value !== "yes" ? (
              <span>
                These are the default supplies selected based on your length of
                therapy and device.
              </span>
            ) : (
              ""
            )}
          </p>
          {newOrderObj?.showPrimaryDressingKit && (
            <>
              <VacDressing
                isPrimary={true}
                validateAndSetData={(e: any, param: any) =>
                  validateAndSetData(e, param, "primary")
                }
                vacProducts={
                  isCharityCareSelected
                    ? getVacProductsWithout(
                        vacAllProducts?.items ?? [],
                        PRODUCT_TYPE_DRESSING,
                        SpecialProduct.PEELANDPLACE
                      )
                    : vacProducts
                }
                handleCloseIcon={handleCloseIcon}
                validateAndSetSize={(e: any, param: any) =>
                  validateAndSetSize(e, param, "primary")
                }
                showSize={newOrderObj?.showSize}
                vacProductSize={vacProductSize}
                showQunatity={newOrderObj?.showQunatity}
                handleVacKitDressingPlusClick={
                  handlePrimaryVacKitDressingPlusClick
                }
                handleVacKitDressingMinusClick={
                  handlePrimaryVacKitDressingMinusClick
                }
                isVacKitDressingPlusDisabled={
                  newOrderObj?.isPrimaryVacKitDressingDisabled
                }
                isVacKitDressingMinusDisabled={
                  newOrderObj?.isPrimaryVacKitDressingMinusDisabled
                }
                productName={data.productName}
                productCode={data.productCode}
                productSizeName={data.productSizeName}
                productQuantityCode={data.productQuantity}
                productLableName="productName"
                productSizeLabelName="productSizeName"
                disableDropdown={false}
                isPeelAndPlaceDressing={isPeelAndPlaceDressing}
                isReadyCareFlag={
                  ((authObj &&
                    authObj.registeredFaciltyAddress &&
                    authObj.registeredFaciltyAddress.readyCareFlag &&
                    authObj.registeredFaciltyAddress.readyCareFlag === "Y") ||
                    (newOrderObj.newOrderData.requestingFacility &&
                      newOrderObj.newOrderData.requestingFacility.value &&
                      newOrderObj.newOrderData.requestingFacility.value
                        .readyCareFlag === "Y")) &&
                  productInfo.productInformation.value === "yes"
                }
                instruction={instruction}
              />
            </>
          )}
          {newOrderObj?.showSecondaryDressingKit && (
            <>
              <VacDressing
                isPrimary={false}
                validateAndSetData={(e: any, param: any) =>
                  validateAndSetData(e, param, "secondary")
                }
                vacProducts={getVacProductsWithout(
                  vacAllProducts?.items ?? [],
                  PRODUCT_TYPE_DRESSING,
                  SpecialProduct.PEELANDPLACE
                )}
                handleCloseIcon={handleCloseSecIcon}
                validateAndSetSize={(e: any, param: any) =>
                  validateAndSetSize(e, param, "secondary")
                }
                showSize={newOrderObj?.showSecSize}
                vacProductSize={vacSecondaryProductSize}
                showQunatity={newOrderObj?.showSecQunatity}
                handleVacKitDressingPlusClick={
                  handleSecondaryVacKitDressingPlusClick
                }
                handleVacKitDressingMinusClick={
                  handleSecondaryVacKitDressingMinusClick
                }
                isVacKitDressingPlusDisabled={
                  newOrderObj?.isSecondaryVacKitDressingDisabled
                }
                isVacKitDressingMinusDisabled={
                  newOrderObj?.isSecondaryVacKitDressingMinusDisabled
                }
                productName={data?.secProductName}
                productCode={data?.secProductCode}
                productSizeName={data?.secProductSizeName}
                productQuantityCode={data?.secProductQuantity}
                productLableName="secProductName"
                productSizeLabelName="secProductSizeName"
                isShowWarningForSecondaryDressing={
                  isShowWarningForSecondaryDressing
                }
                note={note}
              />
            </>
          )}
          {showAddDressingKitBtn() && (
            <Grid className="dressingKitBtnGrid">
              <Grid item xs={12}>
                <Button
                  classes={{ root: "dressingKit-add-button" }}
                  data-testid="dressingKit-add-button"
                  onClick={addDressingKitClick}
                  startIcon={<AddIcon classes={{ root: "dressingadd-icon" }} />}
                >
                  Add Dressing Kit
                </Button>
              </Grid>
            </Grid>
          )}
        </div>
      )}
    </>
  );
};
