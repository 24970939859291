import "./newOrderOptions.css";
import parse from "html-react-parser";
import {
  IAnalyticsData,
  sendAnalyticsData,
} from "../../../util/utilityFunctions";
import {
  NewOrderContext,
  NewOrderContextType,
} from "../../../context/NewOrderContext";
import {
  SupplyOrderContext,
  SupplyOrderContextType,
} from "../../../context/SupplyOrderContext";
import {
  IOrderOption,
  OrderOptionSection,
  OrderOptionType,
} from "./orderOption/orderOption.interface";
import { useHistory } from "react-router-dom";
import {
  RolesPermissionContext,
  RolesPermissionContextType,
} from "../../../context/RolesPermissionContext";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { OrderOption } from "./orderOption/orderOption.component";
import { AuthContext, AuthContextType } from "../../../context/AuthContext";
import { SupplyOrderPageSection } from "../../supplyOrder/SupplyOrderPageSection.enum";
import InPatientIcon from "../../../assets/inPatientIcon.svg";
import PostAcuteOrderIcon from "../../../assets/homeOrderIcon.svg";
import InPatientDisabledIcon from "../../../assets/inPatientIconDisabled.svg";
import SuppliesIcon from "../../../assets/orderSupplies.svg";
import HomeOrderIcon from "../../../assets/orderVACTherapy.svg";
import StockIcon from "../../../assets/stockSupplies.svg";
import StockDisabledIcon from "../../../assets/stockSuppliesDisabled.svg";
import {
  InpatientOrderContext,
  InpatientOrderContextType,
} from "../../../context/InpatientOrderContext";
import {
  InpatientSupplyOrderContextType,
  InpatientSupplyOrderContext,
} from "../../../context/InpatientSupplyOrderContext";
import {
  IFacilitysupplyOrdersContextType,
  FaciliitySupplyOrdersContext,
} from "../../../context/FacilitySupplyOrdersContext";
import { InpatientSupplyOrderPageSection } from "../../inpatientSupplyOrder/inpatientSupplyOrder.enum";

export const NewOrderOptions = () => {
  const history = useHistory();
  const [options, setOptions] = useState<IOrderOption[]>([]);
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const newOrderObj = useContext<NewOrderContextType | null>(NewOrderContext);
  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );
  const supplyOrderObj = useContext<SupplyOrderContextType | null>(
    SupplyOrderContext
  );
  const inPatientObj = useContext<InpatientOrderContextType | null>(
    InpatientOrderContext
  );
  const inPatientSupplyOrderObj =
    useContext<InpatientSupplyOrderContextType | null>(
      InpatientSupplyOrderContext
    );

  const roles = permissionObj && permissionObj.mappedRolesPermissionData;
  const isMobileScreen = useMediaQuery("(max-width:768px)");
  const isBiggerIpadScreen = useMediaQuery(
    "(min-width:769px) and (max-width:1075px)"
  );

  const isPostAcuteCheck =
    authObj && authObj?.registeredFaciltyAddress?.isPostAcute;
  const isShowSupplyOrderHeader =
    roles &&
    (roles.IsShowSupplyOrderButton ||
      (isPostAcuteCheck
        ? false
        : roles.IsShowInPatientSupplyOrderButton ||
          roles.IsShowInPatientStockSupplyOrderButton));
  const isShowVACOrderHeader =
    roles &&
    (roles.IsShowVacOrderButton ||
      (isPostAcuteCheck ? false : roles.IsShowInPatientOrderButton));
  const facilitySupplyOrderContextObj =
    useContext<IFacilitysupplyOrdersContextType | null>(
      FaciliitySupplyOrdersContext
    );
  const vacOrderAnalytics = (eventName: string) => {
    let analyticsData: IAnalyticsData = {
      page_type: "react",
      view_name: "NewOrderComponent",
      event_type: "click",
      event_name: eventName,
      tealium_event: "New_Order",
      mmmex_userrecordid: authObj?.userProfile?.userID!,
      mmmex_facilityid: authObj?.registeredFaciltyAddress?.siteUseId!,
      mmmex_roleid: permissionObj?.mappedRolesPermissionData?.roleName!,
      mmmex_pagename: "New Order",
    };
    sendAnalyticsData(analyticsData);
  };

  const handleOptionClick = (option: IOrderOption) => {
    switch (option.type) {
      case OrderOptionType.HOME_ORDER:
        handleRedirectNewOrder();
        break;
      case OrderOptionType.FOR_HOME_PATIENTS:
        handleRedirectSupplyOrder();
        break;
      case OrderOptionType.IN_PATIENT_ORDER:
        handleRedirectInPatientOrder();
        break;
      case OrderOptionType.IN_PATIENT_SUPPLIES:
        handleRedirectInPatientSupplyOrder();
        break;
      case OrderOptionType.STOCK_SUPPLIES:
        handleRedirectInPatientSupplyOrder(true);
        break;
      default:
        break;
    }
  };

  const handleRedirectNewOrder = () => {
    vacOrderAnalytics("New_Order");
    newOrderObj?.resetNewOrderForm();
    history.push("/orders/newOrder");
  };

  const handleRedirectSupplyOrder = () => {
    vacOrderAnalytics("Supply_Order");
    supplyOrderObj?.resetSupplyOrder();
    supplyOrderObj?.setSupplyOrderPage(
      SupplyOrderPageSection.SUPPLYORDER_PATIENT_LIST
    );
    history.push("/orders/supplyOrderList");
  };

  const handleRedirectInPatientOrder = () => {
    inPatientObj?.resetContext();
    inPatientObj?.setIsFromStartNewOrder(true);
    inPatientSupplyOrderObj?.resetContext();
    history.push({
      pathname: "/orders/inpatientOrder",
      state: {
        allProducts: [],
        product: null,
      },
    });
  };

  const handleRedirectInPatientSupplyOrder = (
    isFromStockSupplies: boolean = false
  ) => {
    inPatientSupplyOrderObj?.resetContext();
    if (isFromStockSupplies) {
      inPatientSupplyOrderObj?.setPage(
        InpatientSupplyOrderPageSection.FIRST_PAGE
      );
      history.push({
        pathname: "/orders/inpatientStockSupplyOrder",
        state: { isFromStockSupplies: true },
      });
    } else {
      history.push({
        pathname: "/orders/inpatientSupplyOrder",
      });
    }
  };

  const createOrderOptions = () => {
    const inPatientOrder: IOrderOption = {
      description:
        "Order V.A.C.<sup>®</sup> Therapy for patients within your facility",
      icon: InPatientIcon,
      order: 1,
      secion: OrderOptionSection.VAC_THERAPY,
      title: "In-Patient Order",
      type: OrderOptionType.IN_PATIENT_ORDER,
    };
    const homeOrder: IOrderOption = {
      description: "Order V.A.C.<sup>®</sup> Therapy for patients going home",
      icon: PostAcuteOrderIcon,
      order: 2,
      secion: OrderOptionSection.VAC_THERAPY,
      title: "Home Order",
      type: OrderOptionType.HOME_ORDER,
    };
    const stockSupplies: IOrderOption = {
      description: "Order supplies for facility stock",
      icon: StockIcon,
      order: 3,
      secion: OrderOptionSection.SUPPLIES,
      title: "Stock Supplies",
      type: OrderOptionType.STOCK_SUPPLIES,
    };
    const inPatientSupplies: IOrderOption = {
      description:
        "Order supplies for a patient on V.A.C.<sup>®</sup> Therapy within your facility",
      icon: InPatientIcon,
      order: 4,
      secion: OrderOptionSection.SUPPLIES,
      title: "In-Patient Supplies",
      type: OrderOptionType.IN_PATIENT_SUPPLIES,
    };
    const homePatients: IOrderOption = {
      description:
        "Order supplies for a patient on V.A.C.<sup>®</sup> Therapy at home",
      icon: PostAcuteOrderIcon,
      order: 5,
      secion: OrderOptionSection.SUPPLIES,
      title: "For Home Patients",
      type: OrderOptionType.FOR_HOME_PATIENTS,
    };
    let updateOptions: IOrderOption[] = [];
    if (permissionObj?.mappedRolesPermissionData.IsShowVacOrderButton) {
      updateOptions.push(homeOrder);
    }
    if (
      permissionObj?.mappedRolesPermissionData.IsShowInPatientOrderButton &&
      !isPostAcuteCheck
    ) {
      updateOptions.push(inPatientOrder);
    }
    if (
      permissionObj?.mappedRolesPermissionData
        .IsShowInPatientStockSupplyOrderButton &&
      !isPostAcuteCheck
    ) {
      updateOptions.push(stockSupplies);
    }
    if (
      permissionObj?.mappedRolesPermissionData
        .IsShowInPatientSupplyOrderButton &&
      !isPostAcuteCheck
    ) {
      updateOptions.push(inPatientSupplies);
    }
    if (
      authObj &&
      permissionObj?.mappedRolesPermissionData.IsShowSupplyOrderButton
    ) {
      updateOptions.push(homePatients);
    }
    setOptions(updateOptions);
  };

  const handleNavigation = () => {
    facilitySupplyOrderContextObj!.resetData();
    history.push("/orders/facilitySupplyOrders");
  };

  useEffect(() => {
    createOrderOptions();
  }, []);

  return (
    <div className="new-order-option-component">
      {isShowVACOrderHeader && (
        <div className="new-order-option-section">
          <p className="new-order-option-title">
            {parse("Order V.A.C.<sup>®</sup> Therapy")}
          </p>
          <Grid className="order-option-grid-container" container spacing={2}>
            {options
              .filter(
                (option: IOrderOption) =>
                  option.secion === OrderOptionSection.VAC_THERAPY
              )
              .map((option: IOrderOption, index: number) => {
                return (
                  <Grid
                    className="order-option-grid-item"
                    item
                    key={`grid-${option.title}-${index}`}
                    xs={isMobileScreen ? 12 : 6}
                  >
                    <OrderOption
                      key={`${option.title}-${index}`}
                      onClick={handleOptionClick}
                      option={option}
                    />
                  </Grid>
                );
              })}
          </Grid>
        </div>
      )}
      {isShowSupplyOrderHeader && (
        <div className="new-order-option-section">
          <div className="new-order-option-hearder-div">
            <p className="new-order-option-title">Order Supplies</p>
            {permissionObj.mappedRolesPermissionData
              .IsShowFacilitySupplyOrders &&
              !authObj?.registeredFaciltyAddress?.isPostAcute && (
                <Typography
                  className="view-status-supply-order-link"
                  data-testid="view-status-supply-order-link-testid"
                  id="view-status-supply-order-link-id"
                  onClick={() => {
                    handleNavigation();
                  }}
                >
                  View status of your facility's supply orders
                </Typography>
              )}
          </div>
          <Grid className="order-option-grid-container" container spacing={2}>
            {options
              .filter(
                (option: IOrderOption) =>
                  option.secion === OrderOptionSection.SUPPLIES
              )
              .map((option: IOrderOption, index: number) => {
                return (
                  <Grid
                    className="order-option-grid-item"
                    item
                    key={`grid-${option.title}-${index}`}
                    xs={isMobileScreen ? 12 : isBiggerIpadScreen ? 6 : 4}
                  >
                    <OrderOption
                      key={`${option.title}-${index}`}
                      onClick={handleOptionClick}
                      option={option}
                    />
                  </Grid>
                );
              })}
          </Grid>
        </div>
      )}
    </div>
  );
};
