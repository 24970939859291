import { useEffect, useState } from "react";
import { CustomDropDown } from "../../../core/customDropdown/customDropdown.component";
import "./SalesRegion.css";
import { getSalesAreaList } from "../../../util/3meService";

interface ISalesRegion {
  validateAndSetAreaData: any;
  selectedRegionType: any;
}

const SalesRegions = ({
  validateAndSetAreaData,
  selectedRegionType,
}: ISalesRegion) => {
  const [regionsArray, setRegionsArray] = useState([]);
  const fetchDropDownContent = async () => {
    try {
      const response = await getSalesAreaList();
      if (response.data.length > 0) {
        setRegionsArray(response.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    fetchDropDownContent();
  }, []);

  return (
    <div className="sales-region-main">
      <CustomDropDown
        handleChange={validateAndSetAreaData}
        menuItem={regionsArray}
        name="sale-user-role"
        placeHolder="Select Area"
        selectpropsClassName={
          selectedRegionType ? "sale-user-region-select" : "placeHolder"
        }
        selectClassName={
          selectedRegionType ? "sale-user-region-input" : "placeHolder"
        }
        testId="sale-region-list-test"
        value={selectedRegionType ? selectedRegionType : null}
      />
    </div>
  );
};

export default SalesRegions;
