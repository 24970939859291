import "./permissionCheckbox.css";
import { IFacilitySettingPermission } from "../../../facilitySettings.interface";
import { getFacilityPermissionName } from "../../../../../../util/utilityFunctions";
import { CustomCheckBox } from "../../../../../../core/checkBox/checkBox.component";
import { SubPermissionCheckbox } from "./subPermissionCheckbox/subPermissionCheckbox.component";

interface PermissionCheckboxProps {
  index: number;
  permission: IFacilitySettingPermission;
  onToggle: (permissionName: string) => void;
  onToggleSubPermission: (
    parentPermissionName: string,
    subPermissionName: string
  ) => void;
}

export const PermissionCheckbox = ({
  index,
  permission,
  onToggle,
  onToggleSubPermission,
}: PermissionCheckboxProps) => {
  return (
    <div
      className="permission-checkbox-component"
      data-testid={`permission-checkbox-component-${index + 1}`}
    >
      <CustomCheckBox
        checked={permission.isSelected}
        handleChange={() => onToggle(permission.permissionName)}
        indeterminate={permission.indeterminate}
        labelClassName={
          permission.isSelected
            ? "facility-permissions-checkbox-description-text-active"
            : "facility-permissions-checkbox-description-text"
        }
        labelText={getFacilityPermissionName(permission.permissionName)}
        name={permission.permissionName}
        required={false}
        selectClassName="facility-permissions-checkbox"
        selectpropsClassName="facility-permissions-checkbox-root"
        testId={`permission-checkbox-item-${index + 1}`}
        value={permission.permissionName}
      />
      {permission.subPermissions && (
        <>
          {permission.subPermissions.map((subPermission, subIndex) => (
            <SubPermissionCheckbox
              key={subPermission.permissionName}
              index={subIndex}
              subPermission={subPermission}
              onToggle={() =>
                onToggleSubPermission(
                  permission.permissionName,
                  subPermission.permissionName
                )
              }
            />
          ))}
        </>
      )}
    </div>
  );
};
