import { ReactNode, useState } from "react";
import { getDeepClone } from "../../../../../../../util/ObjectFunctions";
import { AddSalePersonDetail } from "../addSalePersonDetail/addSalePersonDetail.component";
import { IAddSalePersonDetail } from "../addSalePersonDetail/addSalePersonDetail.interface";
import { addSalesPersonMock } from "../addSalePersonDetail/addSalePersonDetailMockData.model";
import { AddSalesPersonDetailList } from "../addSalePersonDetailList/addSalePersonDetailList.component";
import { AddSalePersonToTerritory } from "./addTerritorySalePerson.enum";
import { IAddSalesPersonContainer } from "./addTerritorySalePerson.interface";
import { ISalesData } from "../addSalePersonDetailList/addSalePersonDetailList.interface";

export const AddTerritorySalesPersonContainer = ({
  defaultPageSection = AddSalePersonToTerritory.LIST_SALE_PERSONS,
  setOpenpopup,
  salesperonListApiResponse,
  salesMngUserData,
  setSalesMngUserData,
  callAddSalesPerson,
  dateValue,
  setDateValue,
}: IAddSalesPersonContainer) => {
  const [addSalesData, setAddSalesData] = useState<ISalesData | null>(
    getDeepClone(addSalesPersonMock)
  );

  const [selectedTerritoryDetails, setSelectedTerritoryDetails] =
    useState<IAddSalePersonDetail>(getDeepClone(addSalesPersonMock));

  const [currentSection, setCurrentSection] =
    useState<AddSalePersonToTerritory>(defaultPageSection);

  const redirectHandler = (changeSectionTo: AddSalePersonToTerritory): void => {
    setCurrentSection(changeSectionTo);
  };

  const sectionToDisplay = () => {
    let page: ReactNode;
    switch (currentSection) {
      case AddSalePersonToTerritory.LIST_SALE_PERSONS:
        page = (
          <AddSalesPersonDetailList
            data={salesperonListApiResponse}
            setSalesMngUserData={setSalesMngUserData}
            changeFormHandler={redirectHandler}
            setOpenpopup={setOpenpopup}
          />
        );
        break;
      case AddSalePersonToTerritory.ADD_SALE_PERSON_DETAILS:
        page = (
          <AddSalePersonDetail
            addSalesData={addSalesData!}
            setAddSalesData={setAddSalesData}
            salesMngUserData={salesMngUserData!}
            setOpenpopup={setOpenpopup}
            selectedSalesPersonDetails={selectedTerritoryDetails!}
            changeFormHandler={redirectHandler}
            dateValue={dateValue}
            setDateValue={setDateValue}
            callAddSalesPerson={callAddSalesPerson}
          />
        );
        break;
    }
    return page;
  };
  return <>{sectionToDisplay()}</>;
};
