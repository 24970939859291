import React, { useContext, useState } from "react";
import { Typography } from "@mui/material";
import "../reSubmitErrorPopup/ReSubmitErrorPopup.css";
import { ExpressButton } from "../../../../../core/expressButton/expressButton.component";
import { IAcuteOrderFailurePopup } from "../inpatientOrderPopup.enum";
import { useHistory } from "react-router-dom";
import {
  InpatientOrderContext,
  InpatientOrderContextType,
} from "../../../../../context/InpatientOrderContext";

interface IReSubmitAcuteOrderErrorPopup {
  popupContent: IAcuteOrderFailurePopup;
  handleButtonAction: Function;
}

const ReSubmitAcuteOrderErrorPopup = ({
  popupContent,
  handleButtonAction,
}: IReSubmitAcuteOrderErrorPopup) => {
  const history = useHistory();
  const inpatientOrderObj = useContext<InpatientOrderContextType | null>(
    InpatientOrderContext
  );

  const handleFirstButtonClick = () => {
    inpatientOrderObj?.setSubmitClickCount((prevCount) => prevCount + 1);
    handleButtonAction();
  };

  return (
    <div className="inpatient-order-error-popup">
      <Typography
        className="inpatient-order-error-popup-header"
        data-testid="resubmit-errorpopup-title"
        id="resubmit-errorpopup-title-id"
      >
        {popupContent.popupTitle}
      </Typography>
      <Typography
        className="inpatient-order-error-popup-description"
        data-testid="resubmit-errorpopup-description1"
        id="resubmit-errorpopup-description-id"
      >
        {popupContent.popupDescription1p1}
        <strong>My Patients</strong>
        {popupContent.popupDescription1p2}
      </Typography>
      <Typography
        className="inpatient-order-error-popup-description2"
        data-testid="resubmit-errorpopup-description2"
        id="resubmit-errorpopup-description2-id"
      >
        {popupContent.popUpDescription2}
      </Typography>
      <ExpressButton
        parentClass="inpatient-order-error-popup-btn1"
        clickHandler={() => handleFirstButtonClick()}
        variant="contained"
        disabled={inpatientOrderObj?.submitClickCount === 3}
        data-testid="resubmit-errorpopup-button1"
        id="resubmit-errorpopup-button1-id"
      >
        {popupContent.popUpButton1Title}
      </ExpressButton>
      <ExpressButton
        parentClass="inpatient-order-error-popup-btn2"
        clickHandler={() => history.push("/home")}
        variant="outlined"
        data-testid="resubmit-errorpopup-button2"
        id="resubmit-errorpopup-button2-id"
      >
        {popupContent.popUpButton2Title}
      </ExpressButton>
    </div>
  );
};

export default ReSubmitAcuteOrderErrorPopup;
