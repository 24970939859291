import {
  FormControlLabel,
  Grid,
  InputBase,
  Radio,
  RadioGroup,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { InputWithLabel } from "../../../../../core/inputWithLabel/inputWithLabel.component";
import { ValidationStatus } from "../../../../../core/interfaces/input.interface";
import {
  IContentProps,
  IPreviewContentShownObject,
  ISiteStatusContent,
} from "./siteStatusContent.interface";
import { ReactComponent as RadioButtonIcon } from "../../../../../assets/radioButton.svg";
import { ReactComponent as SelectedRadioButtonIcon } from "../../../../../assets/selectedRadioButton.svg";
import { useContext, useEffect, useState } from "react";
import "./siteStatusContent.css";
import { getDeepClone } from "../../../../../util/ObjectFunctions";
import SiteStatusInfo from "../../../../../core/siteStatusInfo/siteStatusInfo.component";
import { AddSiteStatusValidator } from "../addSiteStatus.validator";
import { ERROR_TO_SELECT_ANY_OPTION } from "../../../../../util/errorMsg";
import { MessageTypeContent } from "./messageType.enum";
import { defaultAddSiteStatusData } from "../addSiteStatus.model";
import {
  AddSiteStatusContextType,
  AddSiteStatusContext,
} from "../../../../../context/AddSiteStatusContext";

const initialContentAllShownObj: IPreviewContentShownObject = {
  title: true,
  detail: true,
  linkText: true,
};

const allShownFalseObject: IPreviewContentShownObject = {
  title: false,
  detail: false,
  linkText: false,
};

const FormRenderArray = [
  {
    labelText: "Information",
    value: MessageTypeContent.SITE_STATUS_INFORMATION,
  },
  {
    labelText: "Warning",
    value: MessageTypeContent.SITE_STATUS_WARNING,
  },
  {
    labelText: "Alert",
    value: MessageTypeContent.SITE_STATUS_ALERT,
  },
];

export const SiteStatusContent = ({
  Validator = new AddSiteStatusValidator(),
  data,
  setData,
}: IContentProps) => {
  const siteStatusObj = useContext<AddSiteStatusContextType | null>(
    AddSiteStatusContext
  );
  const [preivewData, setPreivewData] = useState<ISiteStatusContent>(
    getDeepClone(defaultAddSiteStatusData)
  );
  const [validator] = useState<AddSiteStatusValidator>(Validator);
  const [contentShownObject, setContentShowObject] =
    useState<IPreviewContentShownObject>(initialContentAllShownObj);
  const [initialChangeHappened, setInitialChangeHappened] =
    useState<boolean>(false);
  const isMobileScreen = useMediaQuery("(max-width:920px)");

  const validateAndSetDataForRadioButton = (e: any) => {
    const { name, value } = e.target;
    const isValid = validator.validate(value, name);
    switch (value) {
      case "info":
        siteStatusObj?.setSiteStatusDetails((updatedData) => ({
          ...updatedData,
          messageFormat: {
            ...updatedData.messageFormat,
            value: MessageTypeContent.SITE_STATUS_INFORMATION,
          },
        }));
        break;
      case "warning":
        siteStatusObj?.setSiteStatusDetails((updatedData) => ({
          ...updatedData,
          messageFormat: {
            ...updatedData.messageFormat,
            value: MessageTypeContent.SITE_STATUS_INFORMATION,
          },
        }));
        break;
      case "error":
        siteStatusObj?.setSiteStatusDetails((updatedData) => ({
          ...updatedData,
          messageFormat: {
            ...updatedData.messageFormat,
            value: MessageTypeContent.SITE_STATUS_INFORMATION,
          },
        }));
        break;
    }
    const tempData = {
      ...data,
      [name]: {
        ...Object(data)[name],
        errorMessage: null,
        valid: isValid!.status,
        value: value,
      },
    };
    setData(tempData);
  };

  const validateAndSetData = (e: any) => {
    const { name, value } = e.target;
    const isValid = validator.validate(value, name);
    const tempData = {
      ...data,
      [name]: {
        ...Object(data)[name],
        errorMessage: null,
        valid: isValid!.status,
        value: value,
      },
    };
    setData(tempData);
  };

  const handleBlur = (e: any) => {
    const { name, value } = e.target;
    let tempObj: IPreviewContentShownObject;
    let tempPreviewObj: ISiteStatusContent;
    if (siteStatusObj?.isAddButtonClick) {
      const contentShownObj = initialChangeHappened
        ? contentShownObject
        : allShownFalseObject;
      tempObj = { ...contentShownObj, [name]: true };
      setInitialChangeHappened(true);
      tempPreviewObj = {
        ...preivewData,
        [name]: { ...Object(data)[name], value: value },
      };
    } else {
      tempObj = { ...contentShownObject, [name]: true };
      tempPreviewObj = {
        ...preivewData,
        [name]: { ...Object(data)[name], value: value },
      };
    }
    setContentShowObject(tempObj);
    setPreivewData(tempPreviewObj);
  };

  useEffect(() => {
    if (siteStatusObj && !siteStatusObj?.isAddButtonClick) {
      setPreivewData(data);
    }
  }, []);

  return (
    <div className="site-status-content-component">
      <Typography
        className="site-status-content-heading"
        data-testid="site-status-content-heading"
      >
        Content
      </Typography>
      <Typography
        className="site-status-content-description"
        data-testid="site-status-content-description"
      >
        Customize the information and formatting of the site status to site
        visitors
      </Typography>
      <Grid
        className="site-status-content-grid-container"
        container
        spacing={2}
      >
        <Grid className="site-status-content-grid-item" item xs={12}>
          <InputWithLabel
            error={data.title.valid === ValidationStatus.INVALID}
            isRequired={data.title.required}
            label={data.title.title}
            labelClassName="site-status-content-title"
            testId="site-status-content-title-test"
            warningMessage={data.title.errorMessage}
            isShowWarning={true}
            id={data.title.componentId!}
          >
            <InputBase
              className="site-status-content-input"
              id={data.title.id!}
              name="title"
              inputProps={{ "data-testid": "siteStatus-content-inputTitle" }}
              onChange={validateAndSetData}
              onBlur={handleBlur}
              value={data.title.value}
            />
          </InputWithLabel>
        </Grid>
        <Grid className="site-status-content-grid-item" item xs={12}>
          <InputWithLabel
            error={data.detail.valid === ValidationStatus.INVALID}
            isRequired={data.detail.required}
            label={data.detail.title}
            labelClassName="site-status-content-title"
            testId="site-status-content-detail-test"
            warningMessage={data.detail.errorMessage}
            isShowWarning={true}
            id={data.detail.componentId!}
          >
            <InputBase
              className="site-status-content-input"
              id={data.detail.id!}
              name="detail"
              inputProps={{
                "data-testid": "siteStatus-content-inputDetail",
                maxLength: 500,
              }}
              onChange={validateAndSetData}
              onBlur={handleBlur}
              value={data.detail.value}
            />
          </InputWithLabel>
        </Grid>
      </Grid>
      <Grid className="site-status-content-link-details" container spacing={2}>
        <Grid
          className="site-status-content-link-item"
          item
          xs={isMobileScreen ? 12 : 6}
        >
          <InputWithLabel
            error={data.urlLink.valid === ValidationStatus.INVALID}
            isRequired={data.urlLink.required}
            label={data.urlLink.title}
            labelClassName="site-status-content-title"
            testId="site-status-content-url-link-test"
            warningMessage={data.urlLink.errorMessage}
            isShowWarning={true}
            id={data.urlLink.componentId!}
          >
            <InputBase
              className="content-url-link"
              id={data.urlLink.id!}
              name="urlLink"
              inputProps={{ "data-testid": "siteStatus-content-inputUrlLink" }}
              onChange={validateAndSetData}
              value={data.urlLink.value}
            />
          </InputWithLabel>
        </Grid>
        <Grid
          className="site-status-content-link-item"
          item
          xs={isMobileScreen ? 12 : 6}
        >
          <InputWithLabel
            error={data.linkText.valid === ValidationStatus.INVALID}
            isRequired={data.linkText.required}
            label={data.linkText.title}
            labelClassName="site-status-content-title"
            testId="site-status-content-url-text-test"
            warningMessage={data.linkText.errorMessage}
            isShowWarning={true}
            id={data.linkText.componentId!}
          >
            <InputBase
              className="content-url-link"
              id={data.linkText.id!}
              name="linkText"
              inputProps={{ "data-testid": "siteStatus-content-inputLinkText" }}
              onChange={validateAndSetData}
              onBlur={handleBlur}
              value={data.linkText.value}
            />
          </InputWithLabel>
        </Grid>
      </Grid>
      <Grid className="site-status-content-msg-format" item xs={12}>
        <InputWithLabel
          label={data.messageFormat.title}
          isRequired={data.messageFormat.required}
          error={data.messageFormat.valid === ValidationStatus.INVALID}
          testId="site-status-content-message-type-label"
          warningMessage={
            data.messageFormat.value === "" ? ERROR_TO_SELECT_ANY_OPTION : ""
          }
          isShowWarning={true}
          id={data.messageFormat.componentId!}
        >
          <RadioGroup
            name="messageFormat"
            classes={{ root: "radioRoot" }}
            onChange={validateAndSetDataForRadioButton}
            value={siteStatusObj?.siteStatusDetails.messageFormat.value}
          >
            {FormRenderArray.map((selectMessageFormat) => (
              <FormControlLabel
                classes={{
                  root:
                    data.messageFormat.valid === ValidationStatus.INVALID
                      ? "optionRoot-error"
                      : siteStatusObj?.siteStatusDetails.messageFormat.value ===
                        selectMessageFormat.value
                      ? "optionRoot-active"
                      : "optionRoot",
                }}
                componentsProps={{
                  typography: {
                    classes: {
                      root:
                        siteStatusObj?.siteStatusDetails.messageFormat.value ===
                        selectMessageFormat.value
                          ? "optiontxtSelect"
                          : "optiontxt",
                    },
                  },
                }}
                control={
                  <Radio
                    icon={<RadioButtonIcon />}
                    checkedIcon={<SelectedRadioButtonIcon />}
                  />
                }
                label={selectMessageFormat.labelText}
                value={selectMessageFormat.value}
              />
            ))}
          </RadioGroup>
        </InputWithLabel>
      </Grid>

      <Grid
        className="custom-site-status-info"
        xs={12}
        data-testid="custom-site-status-info-test"
      >
        <Typography className="custom-site-status-preview">Preview</Typography>
        <SiteStatusInfo
          alertType={siteStatusObj?.siteStatusDetails.messageFormat.value}
          siteStatusContentObject={{
            title: preivewData.title.value,
            description: preivewData.detail.value,
            url: preivewData.linkText.value,
          }}
          contentShownObject={contentShownObject}
        />
      </Grid>
    </div>
  );
};
