import React, { useEffect, useRef } from "react";
import OktaSignIn from "@okta/okta-signin-widget";
import config from "../config";

const OktaSignInWidget = ({ onSuccess, onError }) => {
  const widgetRef = useRef();
  useEffect(() => {
    if (!widgetRef.current) {
      return false;
    }
    const searchParams = new URL(window.location.href).searchParams;
    const error = searchParams.get("error");
    if (error) {
      alert("This email link is expired.please resend it.");
    }
    config.widget.otp = searchParams.get("otp");
    config.widget.state = searchParams.get("state");
    const widget = new OktaSignIn(config.widget);
    widget.showSignInToGetTokens({
      // Assumes there is an empty element on the page with an id of ‘osw-container’
      el: widgetRef.current,
    }).then(onSuccess).catch(onError);
    return () => widget.remove();
  }, []);

  return <div ref={widgetRef} />;
};

export default OktaSignInWidget;
