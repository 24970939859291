import {
  IInputField,
  Validation,
  ValidationStatus,
} from "../../core/interfaces/input.interface";
import { Validator } from "../../util/order.validations";
import { getDeepClone } from "../../util/ObjectFunctions";
import {
  IInpatientOrder,
  IInpatientOrderSecondPage,
} from "./inpatientOrder.interface";
import { getValidObj, updateErrorMessage } from "../../util/utilityFunctions";

export class InpatientOrderValidator {
  private _validator;
  constructor(defValidator = new Validator()) {
    this._validator = defValidator;
  }

  private noValidation(): Validation {
    return getValidObj();
  }

  private fieldToMethodMapping(
    field: string
  ): ((txt: string) => Validation) | undefined {
    const mapping = new Map<string, (txt: string) => Validation>([
      // Patient Information
      ["firstName", this._validator.inPatientOrderNameValidation],
      ["lastName", this._validator.inPatientOrderNameValidation],
      ["dateOfBirth", this._validator.dobValidation],
      ["room", this._validator.roomValidation],
      ["patientId", this._validator.patientIdValidation],
      // Therapy Date
      ["therapyStartDate", this._validator.therapyDateValidation],
      // Wound Location
      ["woundType", this._validator.emptyCheck],
      ["woundLocation", this._validator.emptyCheck],
      ["woundDirection", this._validator.emptyCheck],
      ["woundOrientation", this._validator.emptyCheck],
      [
        "woundMeasurementDate",
        this._validator.measurementDateInPatientValidation,
      ],
      ["woundLength", this._validator.woundDimension],
      ["woundWidth", this._validator.woundDimension],
      ["woundDepth", this._validator.woundDimension],

      ["deliveryLocation", this._validator.emptyCheck],
      ["isCallOnEstimatedArrivalTime", this._validator.emptyCheck],
      ["department", this._validator.departmentValidationForMandatory],
      ["phoneNumber", this._validator.phoneValidation],
      ["deliveryInstructions", this._validator.additionalInfoValidation],

      // Placement Date
      ["placementDate", this._validator.inPatientOrderPlacementDateValidation],
      ["rentalPONumber", this._validator.rentalPONumber],
      ["salesPONumber", this._validator.salesPurchasePONumber],
      ["needByTime", this._validator.emptyCheck],
      ["needByDate", this._validator.deliveryDateValidation],
    ]);
    const validator = mapping.get(field);
    return validator ? validator : this.noValidation;
  }

  public validate(input: string, field: string) {
    try {
      const validator = this.fieldToMethodMapping(field)!;
      return validator(input);
    } catch (error) {
      console.log(`validator method for field ${field} is not configured`);
    }
  }

  public validateAll(
    data: IInpatientOrder,
    updateDataIfUntouchedAndValidated: Function
  ): [ValidationStatus, IInputField | null] {
    let tempData = getDeepClone(data);
    let smallestOrderWithInvalidStatus: IInputField | null = null;
    Object.keys(tempData).forEach((key: string) => {
      let subTempData = tempData[key].value;
      if (key === "patientWoundInformation") {
        smallestOrderWithInvalidStatus = this.validateWoundInformation(
          tempData[key],
          smallestOrderWithInvalidStatus
        )[1];
      } else if (tempData[key].hasChildsToValidate && subTempData) {
        let isAllValid = ValidationStatus.VALID;
        Object.keys(subTempData).forEach((subkey: string) => {
          let inputField = subTempData[subkey];
          smallestOrderWithInvalidStatus = this.validateInputField(
            inputField,
            smallestOrderWithInvalidStatus
          );
          if (inputField.valid === ValidationStatus.INVALID) {
            if (isAllValid !== ValidationStatus.INVALID) {
              isAllValid = ValidationStatus.INVALID;
            }
          }
        });
        tempData = {
          ...tempData,
          [key]: {
            ...Object(tempData)[key],
            valid: isAllValid,
            value: subTempData,
          },
        };
      } else {
        let inputField = tempData[key];
        smallestOrderWithInvalidStatus = this.validateInputField(
          inputField,
          smallestOrderWithInvalidStatus
        );
      }
    });
    updateDataIfUntouchedAndValidated(tempData);
    const ifAllValid = Object.keys(tempData)
      .filter(
        (key: string) => tempData[key].isDefaultValid || tempData[key].required
      )
      .every((key: string) => tempData[key].valid === ValidationStatus.VALID);
    return [
      ifAllValid ? ValidationStatus.VALID : ValidationStatus.INVALID,
      smallestOrderWithInvalidStatus,
    ];
  }

  public validateAllForSecondPage(
    data: IInpatientOrderSecondPage,
    updateDataIfUntouchedAndValidated: Function
  ): [ValidationStatus, IInputField | null] {
    let tempData = getDeepClone(data);
    let smallestOrderWithInvalidStatus: IInputField | null = null;
    Object.keys(tempData).forEach((key: string) => {
      let subTempData = tempData[key].value;
      if (tempData[key].hasChildsToValidate && subTempData) {
        let isAllValid = ValidationStatus.VALID;
        Object.keys(subTempData).forEach((subkey: string) => {
          let inputField = subTempData[subkey];
          smallestOrderWithInvalidStatus = this.validateInputField(
            inputField,
            smallestOrderWithInvalidStatus
          );
          if (inputField.valid === ValidationStatus.INVALID) {
            if (isAllValid !== ValidationStatus.INVALID) {
              isAllValid = ValidationStatus.INVALID;
            }
          }
        });
        tempData = {
          ...tempData,
          [key]: {
            ...Object(tempData)[key],
            valid: isAllValid,
            value: subTempData,
          },
        };
      } else {
        let inputField = tempData[key];
        smallestOrderWithInvalidStatus = this.validateInputField(
          inputField,
          smallestOrderWithInvalidStatus
        );
      }
    });
    updateDataIfUntouchedAndValidated(tempData);
    const ifAllValid = Object.keys(tempData)
      .filter(
        (key: string) => tempData[key].isDefaultValid || tempData[key].required
      )
      .every((key: string) => tempData[key].valid === ValidationStatus.VALID);
    return [
      ifAllValid ? ValidationStatus.VALID : ValidationStatus.INVALID,
      smallestOrderWithInvalidStatus,
    ];
  }

  private validateInputField(
    input: IInputField,
    smallestOrderWithInvalidStatus: IInputField | null
  ) {
    if (input.isOptional === true && input.valid !== ValidationStatus.VALID) {
      input.valid = ValidationStatus.VALID;
    } else if (input.required && input.valid === ValidationStatus.UNTOUCHED) {
      input.valid = ValidationStatus.INVALID;
    } else if (input.isDefaultValid && input.valid !== ValidationStatus.VALID) {
      input.valid = ValidationStatus.INVALID;
    }
    if (input.valid === ValidationStatus.INVALID && input.order) {
      if (
        !smallestOrderWithInvalidStatus ||
        (smallestOrderWithInvalidStatus.order &&
          input.order &&
          smallestOrderWithInvalidStatus.order > input.order)
      ) {
        smallestOrderWithInvalidStatus = input;
      }
    }
    updateErrorMessage(input);
    return smallestOrderWithInvalidStatus;
  }

  private validateWoundInformation(
    woundData: IInputField,
    smallestOrderWithInvalidStatus: IInputField | null
  ): [IInputField, IInputField | null] {
    let woundDetails = woundData.value;
    let woundCount = Number(woundDetails.woundInfoCount.value);
    if (woundCount > 0) {
      let isWoundInformationValid = true;
      Object.keys(woundDetails.primaryWoundInformation).forEach(
        (key: string) => {
          let woundDetail = woundDetails.primaryWoundInformation[key];
          smallestOrderWithInvalidStatus = this.validateInputField(
            woundDetail,
            smallestOrderWithInvalidStatus
          );
          if (
            woundDetail.valid === ValidationStatus.INVALID &&
            isWoundInformationValid
          ) {
            isWoundInformationValid = false;
          }
        }
      );
      if (woundCount === 2) {
        Object.keys(woundDetails.secondaryWoundInformation).forEach(
          (key: string) => {
            let woundDetail = woundDetails.secondaryWoundInformation[key];
            smallestOrderWithInvalidStatus = this.validateInputField(
              woundDetail,
              smallestOrderWithInvalidStatus
            );
            if (
              woundDetail.valid === ValidationStatus.INVALID &&
              isWoundInformationValid
            ) {
              isWoundInformationValid = false;
            }
          }
        );
      }
      if (!isWoundInformationValid) {
        woundData.valid = ValidationStatus.INVALID;
      }
    }
    return [woundData, smallestOrderWithInvalidStatus];
  }
}
