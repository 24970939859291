import { Grid, useMediaQuery } from "@mui/material";
import { useHistory } from "react-router-dom";
import OrderSummarySuccess from "../../../../../../assets/OrderSummarySuccess.svg";
import { ExpressButton } from "../../../../../../core/expressButton/expressButton.component";
import "./woundAssessmentSummaryHeader.css";
import { COMORBODITIES_AUTOPOPULATE } from "../addWoundAssessment.interface";

interface IWoundAssessmentSummaryHeaderProps {
  handleAutoPopulateComorbidites: Function;
}

const WoundAssessmentSummaryHeader = ({
  handleAutoPopulateComorbidites,
}: IWoundAssessmentSummaryHeaderProps) => {
  const history = useHistory();
  const isIpadScreen = useMediaQuery(
    "(min-width:451px) and (max-width:1024px)"
  );
  const isMobileScreen = useMediaQuery("(max-width:450px)");

  return (
    <div className="WoundAssessmentSummaryHeader">
      <Grid
        className="orderSummaryForm"
        container
        display="flex"
        flexDirection="row"
      >
        <Grid xs={isMobileScreen ? 12 : isIpadScreen ? 2.5 : 1.5}>
          <div data-testid="successimgTest">
            <img src={OrderSummarySuccess} alt={OrderSummarySuccess} />
          </div>
        </Grid>
        <Grid xs={isMobileScreen ? 12 : isIpadScreen ? 8 : 9}>
          <Grid
            className="orderSummaryForm-sub-form"
            container
            display="flex"
            flexDirection="column"
          >
            <Grid item>
              <p className="orderSuccessPara" data-testid="successTextTest">
                Thank you!
              </p>
            </Grid>
            <Grid item>
              <p className="orderSuccessPara2" data-testid="successText2Test">
                Your Wound Assessment has been submitted.
              </p>
            </Grid>
            <Grid item>
              <p className="orderSuccessPara3" data-testid="successText3Test">
                This patient requires wound measurements be submitted weekly to
                justify the insurance billing process for a patient on V.A.C.®
                Therapy.
              </p>
            </Grid>
            <Grid className="orderSummaryBtn" item>
              <ExpressButton
                clickHandler={() => {
                  history.push("/home");
                  handleAutoPopulateComorbidites(
                    COMORBODITIES_AUTOPOPULATE.DefaultAutoPopuplate
                  );
                }}
                parentClass="backtoPatientBtn"
                testId="OrderSucessTest"
                variant="contained"
              >
                Back to My Patients
              </ExpressButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default WoundAssessmentSummaryHeader;
