import {
  Grid,
  ListItem,
  Step,
  StepConnector,
  stepConnectorClasses,
  Stepper,
  styled,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as DownloadIcon } from "../../../../assets/download.svg";
import { ReactComponent as StepperIcon } from "../../../../assets/stepperIcon.svg";
import { ReactComponent as StepperOval } from "../../../../assets/stepperOval.svg";
import {
  OrderDetailContext,
  OrderDetailContextType,
} from "../../../../context/OrderDetailsContext";
import { ExpressButton } from "../../../../core/expressButton/expressButton.component";
import SnackBar from "../../../../core/snackBar/snackBar.component";
import {
  checkPopUpBlocker,
  getTextFromCode,
  makeCapitalEachWordInString,
  maskProductInUse,
} from "../../../../util/utilityFunctions";
import { Navigator } from "../../../helpAndSupport/Navigator/navigator.component";
import OrderDetailAlertBanner from "../orderDetailsAlertBanner/orderDetailsAlertBanner.component";
import { IOrderOverviewProps } from "../orderOverview/orderOverview.interface";
import { alertTypes } from "../orderOverview/orderOverviewContainer.enum";
import Moment from "moment";
import "./orderDetailsTracking.css";
import { AuthContext, AuthContextType } from "../../../../context/AuthContext";
import { CustomTag } from "../../../../core/customTags/customTag.component";
import parse from "html-react-parser";
import ErrorPopup from "../../../../core/errorPopup/errorPopup.component";
import { ERROR_MSG_POPUP_BLOCKER } from "../../../../util/errorMsg";
import { OPEN_PDF_POP_UP_BLOCKED } from "../../../../util/errorCode";
import {
  storedProductCapusle,
  customerPlusCapusle,
} from "./orderDetailsTracking.model";
import {
  IAlertTypes,
  IPatient,
  IPatientAlert,
  ISeverityTypes,
} from "../../patient.interface";
import { getdropDownContent } from "../../../../util/dropDownService";
import { CMS_ORDER_SOURCE_CONTENT } from "../../../../util/staticText";

interface orderDetails {
  date: any;
  status: string;
  current: boolean;
}

export interface ICapsuleInfo {
  text: string;
  className: string;
}

export const OrderDetailsTracking = ({
  patientData,
  orderDetailsTrackingData,
  pdfUrl,
  isOrderFlow,
  alertsForRO,
  productInfo,
  setUpdatedAlertDataForRO,
  orderSourceCode
}: IOrderOverviewProps) => {
  const orderOverviewObj = useContext<OrderDetailContextType | null>(
    OrderDetailContext
  );
  const isMobileScreen = useMediaQuery("(max-width:920px)");
  const [orderDetailsToastFlag, setorderDetailsToastFlag] = useState(false);
  let acceptedAlerts: Array<string> = [];
  acceptedAlerts = Object.values(alertTypes).map((element: any) => element);
  const error = orderOverviewObj?.error;
  const history: any = useHistory();
  const [orders, setOrders] = useState<orderDetails[]>([]);
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const [isPopUpBlocked, setIsPopUpBlocked] = useState<boolean>(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    const ordersWithReadyCare = [
      {
        date: new Date(
          Moment.utc(orderDetailsTrackingData?.orderDate).local().format("lll")
        ),
        status: "Order Received",
        current: orderDetailsTrackingData?.receivedFlag === "Y" ? true : false,
      },
      {
        date: orderDetailsTrackingData?.benefitsComplDate,
        status: "Benefits Verified",
        current: orderDetailsTrackingData?.validatedFlag === "Y" ? true : false,
      },
      {
        date: orderDetailsTrackingData?.releaseDate,
        status: "Placement Approved",
        current:
          orderDetailsTrackingData?.releaseToShipFlag === "Y" ? true : false,
      },
      {
        date: orderDetailsTrackingData?.therapyDate,
        status: "Therapy",
        current: orderDetailsTrackingData?.therapyFlag === "Y" ? true : false,
      },
    ];

    const ordersWithoutReadyCare = [
      {
        date: new Date(
          Moment.utc(orderDetailsTrackingData?.orderDate).local().format("lll")
        ),
        status: "Order Received",
        current: orderDetailsTrackingData?.receivedFlag === "Y" ? true : false,
      },
      {
        date: orderDetailsTrackingData?.benefitsComplDate,
        status: "Benefits Verified",
        current: orderDetailsTrackingData?.validatedFlag === "Y" ? true : false,
      },
      {
        date: orderDetailsTrackingData?.releaseDate,
        status: "Preparing Shipment",
        current:
          orderDetailsTrackingData?.releaseToShipFlag === "Y" ? true : false,
      },
      {
        date:
          orderDetailsTrackingData?.outDeliveryDate === null
            ? orderDetailsTrackingData?.releaseDate
            : orderDetailsTrackingData?.outDeliveryDate,
        status: "Out for Delivery",
        current:
          orderDetailsTrackingData?.outForDeliveryFlag === "Y" ||
          orderDetailsTrackingData?.releaseToShipFlag === "Y"
            ? true
            : false,
      },
      {
        date: orderDetailsTrackingData?.deliveredDate,
        status: "Delivered",
        current: orderDetailsTrackingData?.deliveredFlag === "Y" ? true : false,
      },
      {
        date: orderDetailsTrackingData?.therapyDate,
        status: "Therapy",
        current: orderDetailsTrackingData?.therapyFlag === "Y" ? true : false,
      },
    ];
    const acuteOrderTracker = [
      {
        date: new Date(
          Moment.utc(orderDetailsTrackingData?.orderDate).local().format("lll")
        ),
        status: "Order Received",
        current: orderDetailsTrackingData?.receivedFlag === "Y" ? true : false,
      },
      {
        date: orderDetailsTrackingData?.therapyDate,
        status: "Therapy",
        current: orderDetailsTrackingData?.therapyFlag === "Y" ? true : false,
      },
    ];
    const orders = patientData.isAcuteOrder
      ? acuteOrderTracker
      : productInfo &&
        productInfo.isReadyCare &&
        productInfo.isReadyCare.value === "Yes"
      ? ordersWithReadyCare
      : ordersWithoutReadyCare;

    if (orders) {
      for (let i = 1; i < orders.length; i++) {
        if (orders[i].current === true) {
          for (let j = 0; j < i; j++) {
            orders[j].current = true;
          }
        }
      }
    }
    setOrders(orders);
  }, []);

  const activeStep = orders?.findIndex((order) => order.current);

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`& .${stepConnectorClasses.line}`]: {
      width: 2,
      minHeight: 14,
      marginLeft: 12.5,
      border: 0,
      backgroundColor: "#76767a",
    },

    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "#0049BD",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        minHeight: 54,
      },
    },

    [`& .${stepConnectorClasses.vertical}`]: {
      zIndex: 9,
      position: "relative",
      top: "-14px",
      height: "15px",
    },
  }));

  const orderSourceHealogicAlertData: IPatientAlert = {
    alertID: "",
    alertName: "This order was sourced through Healogics WoundQ",
    alertSubLabel: "No action is necessary at this time",
    alertDate: new Date(),
    alertType: IAlertTypes.ORDSRC,
    severity: ISeverityTypes.LOW,
  };

  const renderStepperIcon = (status: any) => {
    switch (status) {
      case true:
        return <StepperIcon />;
      case false:
        return <StepperOval />;
      default:
        return "";
    }
  };

  const getStylesByStatus = (status: any) => {
    switch (status) {
      case true:
        return "enteredIconStyles";
      case false:
        return "disabledStyle";
      default:
        return "";
    }
  };

  const handleClickPdfDownload = async () => {
    if (pdfUrl) {
      const windowObject = window.open(pdfUrl, "_blank");
      const result = checkPopUpBlocker(windowObject);
      setIsPopUpBlocked(result);
    } else {
      setorderDetailsToastFlag(true);
      setTimeout(() => {
        setorderDetailsToastFlag(false);
      }, 5000);
    }
  };



  const getCapsuleInfo = (patient: IPatient): ICapsuleInfo => {
    let capsuleInfo: ICapsuleInfo = {
      text: "",
      className: "",
    };
    if (patient && patient.type) {
      if (patient.type.toLowerCase() === "storedproduct") {
        capsuleInfo = storedProductCapusle;
      } else if (patient.type.toLowerCase() === "customerplus") {
        capsuleInfo = customerPlusCapusle;
      }
    }
    return capsuleInfo;
  };

  return (
    <>
      <div className="order-details-component">
        <SnackBar
          toastStyle={
            isMobileScreen
              ? "orderDetailsTrackingToastMobile"
              : "orderDetailsTrackingToast"
          }
          autoClose={false}
          handleCloseAlert={() => {
            setorderDetailsToastFlag(false);
          }}
          msg="File does not exist.Please contact 3M for assistance with this order"
          phoneNumber="1-800-275-4524."
          openFlag={orderDetailsToastFlag}
        />
        <div className="order-detailsDiv">
          <div className="order-details-navigator-main">
            {isOrderFlow && (
              <div className="order-details-navigator">
                <Navigator
                  array={[
                    {
                      route: authObj?.isUnlinkedFacilityOrder
                        ? "unlinkOrderOverview"
                        : "/home/orderOverview",
                      onLinkClick: () => {
                        orderOverviewObj?.setOrderTabTitle("Orders");
                        orderOverviewObj?.setIsOrderFlow(false);
                        orderOverviewObj?.resetSeletedSupplyOrderData();
                        if (isMobileScreen) {
                          orderOverviewObj?.setIsTabHeaderVisible(true);
                        }
                      },
                      pageName: "Orders",
                    },
                  ]}
                  className="order-overview-route-section"
                  title="Order Detail"
                  isStateDataPresent={true}
                  stateData={patientData}
                />
              </div>
            )}
          </div>
          <div className="alert-banner">
            {alertsForRO?.alerts
              .filter((item) => acceptedAlerts.includes(item.alertName))
              .map((alert) => (
                <OrderDetailAlertBanner
                  alertData={alert}
                  key={alert.alertID.toString()}
                  patientData={patientData}
                  alertsForRO={alertsForRO}
                  setUpdatedAlertDataForRO={setUpdatedAlertDataForRO}
                />
              ))}
          </div>
          {authObj &&
            authObj.isInternalUser &&
            orderDetailsTrackingData &&
            orderDetailsTrackingData.orderSource &&
            orderDetailsTrackingData.orderSource!.toLowerCase() === "hea" && (
              <div className="alert-banner">
                <OrderDetailAlertBanner
                  alertData={orderSourceHealogicAlertData}
                  patientData={patientData}
                />
              </div>
            )}
          <div className="order-details-div-title">
            <Grid
              container
              display="flex"
              flexDirection="row"
              className="order-details-grid-container"
              spacing={2}
            >
              <Grid className="order-details-grid-item" item xs={6}>
                <h2 className="orderDetail" data-testid="detail-header">
                  Order Detail
                </h2>
              </Grid>
              <Grid item xs={6} className="order-details-grid-item">
                {!patientData.isAcuteOrder && (
                  <div className="orderDetailsSummaryPdfBtn-div">
                    <ExpressButton
                      clickHandler={() => {
                        handleClickPdfDownload();
                      }}
                      parentClass="orderDetailsSummaryPdfBtn"
                      testId="acc-cancel-test"
                      variant="text"
                      startIcon={<DownloadIcon />}
                    >
                      Save and Print Order Summary
                    </ExpressButton>
                  </div>
                )}
              </Grid>
            </Grid>
          </div>

          <div className="container">
            <div className="column" data-testid="detail-container">
              {!patientData.isAcuteOrder && (
                <h4 className="vac-header">
                  {orderDetailsTrackingData &&
                  orderDetailsTrackingData.rentalProduct !== ""
                    ? maskProductInUse(orderDetailsTrackingData.rentalProduct)
                    : "Product Not Available"}
                </h4>
              )}
              {patientData.isAcuteOrder && (
                <div className="device-details-order-overview">
                  <img
                    className="device-image"
                    data-testid="device-image"
                    src={orderDetailsTrackingData.imageLink}
                    alt=""
                  />
                  <h3
                    className="device-descriptiopn"
                    data-testid="device-descriptiopn"
                  >
                    {parse(orderDetailsTrackingData.brandName!)}
                  </h3>
                </div>
              )}
              <div
                className="orderDetailsTrackingDataContent"
                data-testid="detail-status-label"
              >
                <p>Status</p>
                <div className="order-status-div">
                  <div
                    className="oval"
                    style={{
                      backgroundColor:
                        patientData?.statusColor === "blueCircle"
                          ? "blue"
                          : patientData?.statusColor === "orangeCircle"
                          ? "orange"
                          : patientData?.statusColor === "yellowCircle"
                          ? "yellow"
                          : patientData?.statusColor === "redCircle"
                          ? "red"
                          : patientData?.statusColor === "greenCircle"
                          ? "green"
                          : patientData?.statusColor === "greyCircle"
                          ? "grey"
                          : patientData?.statusColor === "purpleCircle"
                          ? "purple"
                          : "",
                    }}
                  ></div>
                  <p className="leftsidecolumnvalue">{patientData?.status}</p>
                </div>
                <br></br>
                {orderSourceCode &&
                  authObj &&
                  authObj.isInternalUser &&
                  orderDetailsTrackingData &&
                  orderDetailsTrackingData.orderSource && (
                    <div>
                      <p>Order Source</p>
                      <p
                        className="leftsidecolumnvalue"
                        data-testid="order-source-capsule-Value"
                      >
                        <CustomTag
                          tagClassName="orderSource"
                          tagText={getTextFromCode(
                            orderSourceCode,
                            orderDetailsTrackingData.orderSource
                          )}
                        />
                      </p>
                      <br></br>
                    </div>
                  )}
                <p>Rental Order Number</p>
                <p className="leftsidecolumnvalue" id="id_leftsidecolumnvalue">
                  {patientData?.roNumber}
                </p>
                <br></br>
                <div className="location-type-div" id="id_location-type-div">
                  <div>
                    <p>Delivery Location Type</p>
                    <p
                      className="leftsidecolumnvalue"
                      data-testid="order-Details-Column-Value"
                    >
                      {orderDetailsTrackingData?.deliverySiteType
                        ? patientData?.isAcuteOrder
                          ? orderDetailsTrackingData.deliverySiteType
                          : makeCapitalEachWordInString(
                              orderDetailsTrackingData.deliverySiteType
                            )
                        : "Not Available"}
                    </p>
                  </div>
                  <span
                    id="id_is-customer-plus"
                    className={getCapsuleInfo(patientData).className}
                  >
                    {getCapsuleInfo(patientData).text}
                  </span>
                </div>
                <br></br>
                {!patientData?.isAcuteOrder &&
                  productInfo?.isReadyCare &&
                  productInfo.isReadyCare.value.toLowerCase() === "yes" && (
                    <>
                      <p>Service Type</p>
                      <p
                        className="leftsidecolumnvalue"
                        data-testid="order-Details-Column-Value"
                      >
                        <div
                          data-testid="serviceTypeTagDiv"
                          className="serviceTypeTagDiv"
                        >
                          <CustomTag
                            tagClassName="readycareMedium"
                            tagText="Ready Care"
                          />
                        </div>
                      </p>
                      <br></br>
                    </>
                  )}
                <p>Therapy Start Date</p>
                <p className="leftsidecolumnvalue">
                  {orderDetailsTrackingData?.therapyDate
                    ? orderDetailsTrackingData.therapyDate &&
                      new Date(
                        orderDetailsTrackingData.therapyDate
                      ).toLocaleDateString("en-US", {
                        month: "2-digit",
                        day: "2-digit",
                        year: "numeric",
                      })
                    : "--"}
                </p>
                <br></br>
                {!patientData?.isAcuteOrder && (
                  <>
                    <p>Therapy End Date</p>
                    <p
                      className="leftsidecolumnvalue"
                      data-testid="therapy-end-date"
                    >
                      {orderDetailsTrackingData?.orderClosedDate
                        ? orderDetailsTrackingData.orderClosedDate &&
                          new Date(
                            orderDetailsTrackingData.orderClosedDate
                          ).toLocaleDateString("en-US", {
                            month: "2-digit",
                            day: "2-digit",
                            year: "numeric",
                          })
                        : "--"}
                    </p>
                  </>
                )}
              </div>
            </div>
            {(orderDetailsTrackingData || error) && (
              <div className="column">
                <div>
                  <h4 className="ordertracking1">Order Tracking</h4>
                  <div
                    className="orderDetailsContent"
                    data-testid="order-Details-content"
                  >
                    {error ? (
                      <div className="error" data-testid="order-Details-error">
                        No tracking information available
                      </div>
                    ) : (
                      <div
                        className="milestone"
                        data-testid="milestone-stepper"
                      >
                        <Stepper
                          style={{
                            marginTop: "10px",
                            padding: "0px",
                            display: "flex",
                            flexDirection: "column",
                          }}
                          nonLinear
                          orientation="vertical"
                          activeStep={activeStep}
                          connector={<ColorlibConnector />}
                        >
                          {orders?.map((order, index) => (
                            <Step
                              key={index}
                              active={order.current}
                              completed={
                                order.status === "Delivered" ? true : false
                              }
                            >
                              <>
                                <ListItem
                                  style={{
                                    fontSize: "1rem",
                                    height: "18px",
                                    zIndex: order.current === true ? 2 : 12,
                                  }}
                                  className="stepperMain"
                                >
                                  <Typography
                                    className={getStylesByStatus(order.current)}
                                    style={{
                                      color:
                                        order.current === false
                                          ? "gray"
                                          : "white",
                                      right: "1px",
                                      position: "relative",
                                    }}
                                  >
                                    {renderStepperIcon(order.current)}
                                  </Typography>
                                  <Typography
                                    className={
                                      order.current === true
                                        ? "activeLabel"
                                        : "notActiveLabel"
                                    }
                                  >
                                    <span className="StepperStatus">
                                      {order.status}
                                      <span
                                        className={
                                          order.status.replace(/\s+/g, "") +
                                          (productInfo &&
                                          productInfo.isReadyCare &&
                                          productInfo.isReadyCare.value ===
                                            "Yes"
                                            ? "DateReadyCare"
                                            : "Date")
                                        }
                                      >
                                        {order.date &&
                                        order.status === "Order Received"
                                          ? Moment(order.date).format(
                                              "MM/DD/yyyy  hh:mm A"
                                            )
                                          : order.date &&
                                            Moment(order.date).format(
                                              "MM/DD/YYYY"
                                            )}
                                      </span>
                                    </span>

                                    {order.status === "Out for Delivery" && (
                                      <div className="trackMain">
                                        <p
                                          className={
                                            orderDetailsTrackingData?.trackingLink ===
                                            ""
                                              ? "trackingLinkp"
                                              : "trackingLinkpActive"
                                          }
                                        >
                                          Track Package:
                                        </p>
                                        <a
                                          className="trackingLink"
                                          href={
                                            orderDetailsTrackingData?.trackingLink
                                          }
                                          target="_blank"
                                        >
                                          {
                                            orderDetailsTrackingData?.trackingNumber
                                          }
                                        </a>
                                      </div>
                                    )}
                                  </Typography>
                                </ListItem>
                              </>
                            </Step>
                          ))}
                        </Stepper>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <ErrorPopup
          errorPopupFlag={isPopUpBlocked}
          popUpStyles="errorPopup"
          showTitle={false}
          handleBackButton={() => setIsPopUpBlocked(false)}
          isSupportPhoneRequired={false}
          errorMessage={ERROR_MSG_POPUP_BLOCKER}
          errorCode={OPEN_PDF_POP_UP_BLOCKED}
        />
      </div>
    </>
  );
};
