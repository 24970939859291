import "./showTitleAndValue.css";

export interface IShowTitleAndValue {
  title: string;
  value: string | null;
  testId: string;
}

export const ShowTitleAndValue = ({
  title,
  value,
  testId,
}: IShowTitleAndValue) => {
  return (
    <div className="show-title-and-value-div">
      <p
        className="show-title-and-value-title"
        data-testid={`${testId}-title`}
        id={`${testId}-title-id`}
      >
        {title}
      </p>
      <p
        className="show-title-and-value-value"
        data-testid={`${testId}-value`}
        id={`${testId}-value-id`}
      >
        {value && value !== "" ? value.trim() : "--"}
      </p>
    </div>
  );
};
