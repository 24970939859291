import react, { useContext, useEffect, useState } from "react";
import { IFacility } from "../manageProfile/facilityInformation/facility.interface";
import { AuthContext, AuthContextType } from "../../context/AuthContext";
import { CustomDropDown } from "../../core/customDropdown/customDropdown.component";
import {
  getFcilityAddress,
  getLinkedfacilityAddressData,
  getMobileFacilityAddress,
  showVacOrderMenuOption,
} from "../../util/utilityFunctions";
import { getUserRolePermission } from "../../util/userService";
import { IPermissionData } from "../../RolesPermission/RolesPermission.model";
import { mapUserRolesAndPermissionData } from "../../RolesPermission/RolesPermission.Mapper";
import { ISideNav } from "../sideNav/sideNavMenuOption.interface";
import {
  RolesPermissionContext,
  RolesPermissionContextType,
} from "../../context/RolesPermissionContext";
import { useHistory, useLocation } from "react-router-dom";
import {
  caregiverFacilitySearch,
  checkPostAcuteFacility,
} from "../../util/3meService";
import { searchFacility } from "../manageProfile/facilityInformation/addFacility/addFacility.service";
import { IFacilitySearchRequest } from "../manageProfile/facilityInformation/addFacility/searchFacility.interface";
import {
  MyPatientContext,
  MyPatientContextType,
} from "../../context/MyPatientContext";
import {
  IMobileDisplayContext,
  MobileDisplayContext,
} from "../../context/MobileDisplayContext";

type Props = {
  userLinkedFacilityData: IFacility[];
};
export const FacilityDropDown = ({ userLinkedFacilityData }: Props) => {
  const location = useLocation();
  const history = useHistory();
  const authObj = react.useContext<AuthContextType | null>(AuthContext);
  const [selectedFacility, setSelectedFacility] = useState<string | null>(null);
  const [facilityList, setFacilityList] = useState<string[] | null>(null);
  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );
  const myPatientObj = useContext<MyPatientContextType | null>(
    MyPatientContext
  );
  const { isMobileScreen } =
    useContext<IMobileDisplayContext>(MobileDisplayContext);

  const validateAndSetDropDownData = async (e: any) => {
    const facilityData = e.target.value.split("|");
    const indexVal = Number(facilityData[0]);
    setSelectedFacility(facilityData[1]);
    if (selectedFacility !== facilityData[1]) {
      myPatientObj?.setIsPatientDataLoaded(false);
    }
    let selectedFacilityData = e.target.value.split(", ");
    let facilityObj: any;
    let facilityName = selectedFacilityData[0];
    if (isMobileScreen) {
      facilityName = facilityName.split(" (#")[0];
    }
    if (selectedFacilityData.length > 0) {
      facilityObj = userLinkedFacilityData.filter(
        (x, index) =>
          index === indexVal &&
          facilityData[1].toLowerCase().includes(x.accountName.toLowerCase())
      )[0];
    }
    if (facilityObj?.facilityAddressID !== undefined) {
      var facilitySearchRequest: IFacilitySearchRequest = {
        customerNumber: facilityObj?.accountNumber?.toString()!,
        customerName: "",
        postalCode: "",
      };
      const searchedFacilities = await searchFacility(facilitySearchRequest);
      if (searchedFacilities) {
        const facilityRes = searchedFacilities.filter((x) => {
          if (x.siteUseId === facilityObj?.siteUseId?.toString()) {
            return x;
          }
        })[0];
        facilityObj.customerServiceCode = facilityRes.customerServiceCode;
        //Set CareGiverId
        if (
          facilityRes &&
          facilityRes.siteUseCode &&
          facilityRes.siteUseCode?.includes("CG")
        ) {
          const reqBody = {
            customerAccountNumber: facilityRes.accountNumber?.toString(),
          };
          const response = await caregiverFacilitySearch(reqBody);
          if (response && response.items && response.items.length > 0) {
            let homeCareGiverDetails = response.items;
            const homeCareGiverDetail = homeCareGiverDetails[0];
            if (facilityRes.siteUseCode?.includes("ST/CG")) {
              facilityRes.careGiverSiteUseId = homeCareGiverDetail.siteUseId;
              facilityRes.careGiverId = homeCareGiverDetail.origSystemReference;
              facilityObj.careGiverSiteUseId = facilityRes.careGiverSiteUseId;
              facilityObj.careGiverId = facilityRes.careGiverId;
            } else {
              facilityRes.careGiverId = homeCareGiverDetail.origSystemReference;
              facilityObj.careGiverId = facilityRes.careGiverId;
            }
          }
        }
      }
      const userRolesPermissionResponse = await getUserRolePermission(
        facilityObj?.facilityAddressID
      );
      authObj?.setuserRolePermissionData(userRolesPermissionResponse.data);
      const rolesPermissionRes: IPermissionData =
        await mapUserRolesAndPermissionData(
          userRolesPermissionResponse.data!,
          facilityObj?.readyCareFlag
        );
      permissionObj?.setMappedRolesPermissionData(rolesPermissionRes);
      authObj?.setsideNavMenuOptionModelData((dt: ISideNav) => ({
        ...dt,
        orders: {
          ...dt.orders,
          isVisible: showVacOrderMenuOption(rolesPermissionRes),
        },
        inventory: {
          ...dt.inventory,
          isVisible: rolesPermissionRes.IsShowInventoryOption,
        },
        administration: {
          ...dt.administration,
          isVisible: rolesPermissionRes.IsShowAdminstrationOption,
        },
      }));
      authObj?.setuserRolesAndPermissionLoaded(true);
      if (facilityObj) {
        await checkThePostAcute(facilityObj);
        myPatientObj?.resetSearchFilterData();
        if (
          location.pathname.includes("/addWoundAssessment") ||
          location.pathname.includes("/inventory") ||
          location.pathname.includes("/orders/inpatientOrder") ||
          location.pathname.includes("/home/confirmPlacement") ||
          location.pathname.includes("/home/dischargeRequest") ||
          location.pathname.includes("/home/orderOverview") ||
          location.pathname.includes("/home/proofOfDelivery") ||
          location.pathname.includes("/home/pickUpRequest") ||
          location.pathname.includes("/home/sendNote") ||
          location.pathname.includes("/orders") ||
          location.pathname.includes("/orders/newOrder") ||
          location.pathname.includes("/orders/supplyOrderList") ||
          location.pathname.includes(
            "/administration/manageUsers/userProfile"
          ) ||
          location.pathname.includes("/administration/myLists") ||
          location.pathname.includes("/orders/inpatientSupplyOrder")
        ) {
          history.replace("/home", {});
        }
      }
    }
  };

  const updateFacilityList = () => {
    let selectedVal: string = "";
    const newArray = getLinkedfacilityAddressData(
      userLinkedFacilityData,
      isMobileScreen
    );
    if (authObj?.registeredFaciltyAddress) {
      selectedVal = isMobileScreen
        ? getMobileFacilityAddress(authObj.registeredFaciltyAddress)
        : getFcilityAddress(authObj.registeredFaciltyAddress);
    } else if (userLinkedFacilityData.length > 0) {
      selectedVal = isMobileScreen
        ? getMobileFacilityAddress(userLinkedFacilityData[0])
        : getFcilityAddress(userLinkedFacilityData[0]);
    }
    setSelectedFacility(selectedVal);
    setFacilityList(newArray);
  };

  useEffect(() => {
    updateFacilityList();
  }, [isMobileScreen]);

  useEffect(() => {
    if (authObj?.registeredFaciltyAddress) {
      setSelectedFacility(
        isMobileScreen
          ? getMobileFacilityAddress(authObj?.registeredFaciltyAddress!)
          : getFcilityAddress(authObj?.registeredFaciltyAddress!)
      );
    }
  }, [authObj?.registeredFaciltyAddress]);

  const checkThePostAcute = async (facilityObj: IFacility) => {
    const typeCode = facilityObj.typeCode;
    const postAcuteResponse = await checkPostAcuteFacility({
      FacilityTypeCode: typeCode,
    });
    const postAcute = postAcuteResponse.postAcuteFound;
    if (postAcuteResponse && postAcute) {
      facilityObj.isPostAcute = true;
    } else {
      facilityObj.isPostAcute = false;
    }
    authObj?.setregisteredFaciltyAddress(facilityObj);
  };

  return (
    <>
      {selectedFacility && facilityList && (
        <CustomDropDown
          isIndexRequiredInValue={true}
          name="facilityDropDown"
          menuItem={facilityList}
          handleChange={validateAndSetDropDownData}
          selectpropsClassName="facilityBanner-select"
          selectClassName="facilityBanner-input"
          testId="facility-DropDown"
          value={selectedFacility}
          paperPropsClassName="facilityPaperProps"
          disabled={
            myPatientObj?.isPatientDataLoaded === false &&
            location.pathname === "/home"
              ? true
              : false
          }
        />
      )}
      {!selectedFacility && <div></div>}
    </>
  );
};
