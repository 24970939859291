import { useContext, useState } from "react";
import { Button, Grid, useMediaQuery } from "@mui/material";
import { ExpressButton } from "../../../../core/expressButton/expressButton.component";
import "./proofOfDeliveryDocumentUpload.css";
import { ReactComponent as PrintIcon } from "../../../../assets/print.svg";
import RedCheck from "../../../../assets/redCheck.svg";
import GreenCheck from "../../../../assets/greenCheck.svg";
import SelectCloseIcon from "../../../../assets/selectclose.svg";
import CustomDropZone from "../../../../core/customDropZone/customDropZone.component";
import {
  SubmitProofOfDeliveryContext,
  SubmitProofOfDeliveryContextType,
} from "../../../../context/submitProofOfDeliveryContext";
import { uploadDocToIface } from "../../../../util/orderOverViewService";
import { generatePODPDF } from "../../../../util/podService";
import moment from "moment";
import { Popup } from "../../../../core/popup/popup.component";
import { SendNoteFailure } from "../../../send3MNote/popUps/failurePopUp/sendNoteFailure.component";
import {
  checkPopUpBlocker,
  getPdfUrl,
} from "../../../../util/utilityFunctions";
import { WindowService } from "../../../../util/window.service";
import {
  RolesPermissionContextType,
  RolesPermissionContext,
} from "../../../../context/RolesPermissionContext";
import ErrorPopup from "../../../../core/errorPopup/errorPopup.component";
import { ERROR_MSG_POPUP_BLOCKER } from "../../../../util/errorMsg";
import { OPEN_PDF_POP_UP_BLOCKED } from "../../../../util/errorCode";
import { ERROR_MESSAGE_POD_PDF, ERROR_MESSAGE_POD_PDF_NO_PAYOR_DETAILS } from "../../../../util/staticText";

type Props = {
  setIsUploadSuccess: Function;
  setIsLoading: Function;
};

export const ProofOfDeliveryDocumentUpload = ({
  setIsUploadSuccess,
  setIsLoading,
}: Props) => {
  const submitPodObj = useContext<SubmitProofOfDeliveryContextType | null>(
    SubmitProofOfDeliveryContext
  );
  const proofOfDeliveryUploadDocs = submitPodObj?.proofOfDeliveryUploadDocs!;
  const setProofOfDeliveryUploadDocs =
    submitPodObj?.setProofOfDeliveryUploadDocs!;
  const patientData = submitPodObj?.patient;
  const [openFailurePopUp, setOpenFailurePopUp] = useState<boolean>(false);
  const [failureMessage, setFailureMessage] = useState<string>(ERROR_MESSAGE_POD_PDF);
  const [errorCode,setErrorCode] = useState<number|null>(null);
  const isMobileScreen = useMediaQuery("(max-width:600px)");
  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );
  const [isPopUpBlocked, setIsPopUpBlocked] = useState<boolean>(false);
  const allowedFiles = {
    "image/png": [".jpeg", ".gif", ".jpg", ".png", ".tiff"],
    "text/pdf": [".pdf"],
  };
  const generateProofOfDelieveyPDF = async () => {
    if (patientData) {
      setIsLoading(true);
      const reqBody = {
        DOB: moment(patientData.dob).format("DD-MMM-yyyy"),
        FirstName: patientData.firstName,
        LastName: patientData.lastName,
        RentalOrderNumber: patientData.roNumber,
      };
      const response = await generatePODPDF(reqBody);
      setIsLoading(false);
      if (response && response.succeeded) {
        const url = getPdfUrl(response.item);
        const windowObject = window.open(url, "_blank");
        const result = checkPopUpBlocker(windowObject);
        setIsPopUpBlocked(result);
      } else {
        if(response?.error?.errorCode === 5179) {
          setFailureMessage(ERROR_MESSAGE_POD_PDF_NO_PAYOR_DETAILS);
          setErrorCode(response.error.errorCode);
        } 
        setOpenFailurePopUp(true);
        setErrorCode(response.error.errorCode);
      }
    }
  };

  const openProofOfDeliveryPdf = () => {
    generateProofOfDelieveyPDF();
  };

  const closeButtonAction = () => {
    setOpenFailurePopUp(false);
  };

  const removeDocumentAction = () => {
    setProofOfDeliveryUploadDocs([]);
  };

  const uploadDocumentAction = async () => {
    setIsLoading(true);
    if (
      proofOfDeliveryUploadDocs.length > 0 &&
      proofOfDeliveryUploadDocs[0].succeeded
    ) {
      let podUploadDoc = proofOfDeliveryUploadDocs[0];
      let document = {
        fileName: podUploadDoc.documentName,
        FileNetTypeCode: "POHD",
        Base64Content: podUploadDoc.documentBase64,
      };
      let uploadDocRequest = {
        RentalOrderNumber: patientData?.roNumber.toString(),
        files: [document],
      };
      const podDocResult = await uploadDocToIface(uploadDocRequest);
      setIsLoading(false);
      if (podDocResult && podDocResult.succeeded) {
        if (podDocResult.items[0].code === 7016) {
          setIsUploadSuccess(true);
        } else {
          proofOfDeliveryUploadDocs[0].succeeded = false;
          proofOfDeliveryUploadDocs[0].errorMessage = "Virus Detected";
        }
      } else {
        setIsUploadSuccess(false);
      }
    }
  };

  return (
    <div className="proofOfDeliveryUpload" data-testid="proofOfDeliveryUpload">
      <>
        <Grid container className="pod-upload-container">
          <Grid
            item
            xs={isMobileScreen ? 6 : 7}
            className="pod-upload-title-msg"
            data-testid="pod-title-msg"
          >
            The Proof of Delivery and Acceptance of Benefits form is required to
            be signed and dated by the patient or an authorized agent to receive
            care.
          </Grid>
          <Grid item xs={isMobileScreen ? 6 : 4}>
            <Button
              classes={{ root: "print-pod-button" }}
              data-testid="print-pod-button"
              variant="outlined"
              onClick={openProofOfDeliveryPdf}
              startIcon={<PrintIcon />}
            >
              Download and Print
            </Button>
          </Grid>
        </Grid>
        {proofOfDeliveryUploadDocs?.length === 0 ? (
          <Grid item xs={12} className="pod-upload-doc-container">
            <div className="pod-upload-desp" data-testid="pod-upload-desp">
              Files must be in one of the following formats: JPG, GIF, JPEG,
              PNG, TIFF or PDF. Each file cannot exceed 15 MB (15360 KB) in
              size. Only one file can be uploaded.
            </div>
            <CustomDropZone
              allowedFiles={allowedFiles}
              setData={setProofOfDeliveryUploadDocs}
              data={proofOfDeliveryUploadDocs}
              data-testid="pod-drop-zone"
              dragDropText="Drag and drop signed POD file here to upload"
              buttonDropText="Select POD file to upload"
              dropZoneStyles="dropZoneStyles"
              dragAndDropStyles="dragAndDrop"
              uploadWidgetCard="uploadWidgetCard"
              uploadIconStyle="uploadIconStyle"
              listingType="prescriptionDoc"
              buttonDropClassName="selectPodBtn"
              maxFileSize={15728640}
              singleFile={true}
            />
          </Grid>
        ) : (
          <Grid item xs={12} className="pod-upload-doc-container">
            <div className="pod-selectedFileDiv">
              <div className={"pod-selected-file-div"}>
                <div key={0} className="pod-fileDetailCard">
                  <div className="pod-filename" data-testid="pod-file-name">
                    {proofOfDeliveryUploadDocs[0].documentName}
                  </div>
                  <div
                    className={`pod-fileStatus${
                      !proofOfDeliveryUploadDocs[0].succeeded ? `error` : ``
                    }`}
                    data-testid={
                      !proofOfDeliveryUploadDocs[0].succeeded
                        ? "pod-error"
                        : "pod-success"
                    }
                  >
                    {!proofOfDeliveryUploadDocs[0].succeeded ? (
                      <div className="check-mark-img">
                        <img src={RedCheck} alt={RedCheck} />
                      </div>
                    ) : (
                      <div className="check-mark-img">
                        <img src={GreenCheck} alt={GreenCheck} />
                      </div>
                    )}
                    <span className="upload-status">
                      {proofOfDeliveryUploadDocs[0].succeeded
                        ? "Upload successful!"
                        : proofOfDeliveryUploadDocs[0].errorMessage}
                    </span>
                  </div>
                  <div
                    className="pod-fileClose"
                    data-testid="pod-fileClose"
                    onClick={removeDocumentAction}
                  >
                    <img src={SelectCloseIcon} alt={SelectCloseIcon} />
                  </div>
                </div>
              </div>
              <div className="pod-uploadDocBtn" data-testid="pod-uploadDocBtn">
                <ExpressButton
                  disabled={
                    permissionObj?.mappedRolesPermissionData.IsSupportRole ||
                    !proofOfDeliveryUploadDocs[0].succeeded
                  }
                  parentClass="btnUpload"
                  variant="contained"
                  clickHandler={uploadDocumentAction}
                >
                  Submit POD
                </ExpressButton>
              </div>
            </div>
          </Grid>
        )}
      </>
      <ErrorPopup
        errorPopupFlag={openFailurePopUp}
        popUpStyles="errorPopup"
        showTitle={false}
        handleBackButton={closeButtonAction}
        isSupportPhoneRequired={true}
        errorMessage={failureMessage}
        errorCode={errorCode}
      />
      <ErrorPopup
        errorPopupFlag={isPopUpBlocked}
        popUpStyles="errorPopup"
        showTitle={false}
        handleBackButton={() => setIsPopUpBlocked(false)}
        isSupportPhoneRequired={false}
        errorMessage={ERROR_MSG_POPUP_BLOCKER}
        errorCode={OPEN_PDF_POP_UP_BLOCKED}
      />
    </div>
  );
};
