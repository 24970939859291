import { useContext, useEffect, useState } from "react";
import {
  HelpAndSupportDiv,
  HelpAndSupportSubDiv,
  StyledCardContent,
  RightMenuDiv,
  HeaderDiv,
  OptionsDiv,
  OptionDiv,
  TitleAndDescriptionDiv,
  Title,
  Description,
} from "./helpAndSupport.style";
import {
  Link,
  Route,
  Switch,
  useRouteMatch,
  useHistory,
} from "react-router-dom";
import "./helpAndSupport.css";
import FAQ from "./faq/faq.component";
import SystemRequirements from "../systemRequirements/systemReq.components";
import { Grid, useMediaQuery } from "@mui/material";
import { Navigator } from "./Navigator/navigator.component";
import { NeedHelp } from "../needHelp/needHelp.component";
import { ContactUs } from "./contactUs/contactUs.component";
import { AuthContext, AuthContextType } from "../../context/AuthContext";
import { ContactUsAcknowledgement } from "./contactUsAcknowledgement/contactUsAcknowledgement.component";
import { ReactComponent as Bullet } from "../../assets/bullet.svg";
import { ReactComponent as SelectIcon } from "../../assets/selectIcon.svg";
import { CMS_HELPSUPPORT_CONTENT } from "../../util/staticText";
import { getCMSContent } from "../../util/cmsService";
import { IHelpAndSupport, IHelpFile } from "./helpAndSupport.interface";
import parse from "html-react-parser";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

export const HelpAndSupport = () => {
  let history = useHistory();

  const redirectToContactUs = () => {
    history.push("/helpAndSupport/ContactUs");
  };

  const redirectToFAQ = () => {
    history.push("/helpAndSupport/faq");
  };

  let { path } = useRouteMatch();

  const AuthObj = useContext<AuthContextType | null>(AuthContext);
  const isLoggedIn = AuthObj?.isLoggedIn ? AuthObj?.isLoggedIn : false;
  const termsOfUseAccepted = AuthObj?.termsOfUseAccepted
    ? AuthObj?.termsOfUseAccepted
    : false;
  const [pageContent, setPageContent] = useState<IHelpAndSupport>();
  const [resource, setResource] = useState<IHelpFile[]>();
  const isMobileScreen = useMediaQuery("(max-width:600px)");
  useEffect(() => {
    fetchPageContent();
  }, []);

  const fetchPageContent = async () => {
    //async and await
    try {
      const data = await getCMSContent(CMS_HELPSUPPORT_CONTENT);
      if (data.item !== undefined) {
        setPageContent(data.item);
        setResource(pageContent?.resources?.filter((x) => x.type === 1));
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const helpAndSupport = (
    <HelpAndSupportDiv>
      <HelpAndSupportSubDiv>
        <StyledCardContent>
          <HeaderDiv>
            <div className="help-header">Help and Support</div>
            <OptionsDiv>
              <OptionDiv>
                <TitleAndDescriptionDiv
                  onClick={redirectToFAQ}
                  data-testid="video-tutorials-faq"
                >
                  <Title>FAQ and Video Tutorials</Title>
                  <Description>
                    Learn how to use 3M Express Therapy Portal
                  </Description>
                </TitleAndDescriptionDiv>
              </OptionDiv>
              <OptionDiv>
                <TitleAndDescriptionDiv
                  onClick={redirectToContactUs}
                  data-testid="contact-us"
                >
                  <Title>Contact Us</Title>
                  <Description>Send us a message if you need help</Description>
                </TitleAndDescriptionDiv>
              </OptionDiv>
            </OptionsDiv>
          </HeaderDiv>

          {pageContent && (
            <>
              <div className="resourcediv">
                <div className="resource-header">
                  {parse(pageContent.resourceTitle)}
                </div>

                <Grid
                  container
                  rowSpacing={isMobileScreen ? 0 : 1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  className="helpAndSupportGridContainer"
                  classes={{ root: "gridRoot" }}
                  direction="row"
                >
                  <>
                    {pageContent?.resources
                      ?.filter((x) => x.type === 1)
                      .sort((a, b) => (a.sortOrder! > b.sortOrder! ? 1 : -1))
                      .map((res) => {
                        return (
                          <>
                            <Grid
                              item
                              xs={0.3}
                              className="list-item"
                              classes={{ root: "list-root" }}
                            >
                              {" "}
                              <Bullet height={10} />{" "}
                            </Grid>
                            <Grid
                              item
                              xs={isMobileScreen ? 11 : 5.6}
                              className="list-item"
                              classes={{ root: "list-root" }}
                            >
                              <a
                                href={res?.fileLink}
                                target="_blank"
                                rel="noreferrer"
                                className="anchorfont"
                              >
                                {parse(res?.labelText)}
                              </a>
                              {res?.fileType && res?.fileSize && (
                                <span className="spanFont">{`- ${res?.fileType}, ${res?.fileSize}`}</span>
                              )}
                            </Grid>
                          </>
                        );
                      })}
                  </>
                </Grid>
              </div>

              <div className="printdiv">
                <div className="froms-header">
                  {parse(pageContent.printableFormTitle)}
                </div>
                <div className="subHeaderText" data-testid="subHeaderText">
                  Easily access 3M documents for order processing. Tap on a
                  document below to email, print or download to your device.
                </div>
                <Grid
                  container
                  direction="row"
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  className="helpAndSupportGridContainer"
                  classes={{ root: "gridRoot" }}
                >
                  {pageContent?.resources
                    ?.filter((x) => x.type === 2)
                    .sort((a, b) => (a.sortOrder! > b.sortOrder! ? 1 : -1))
                    .map((res, index) => {
                      return (
                        <>
                          <Grid item xs={0.3}>
                            {" "}
                            <Bullet height={10} />{" "}
                          </Grid>
                          <Grid
                            item
                            xs={11.5}
                            className="list-item"
                            classes={{ root: "list-root" }}
                          >
                            <a
                              href={res?.fileLink}
                              target="_blank"
                              rel="noreferrer"
                              className="anchorfont"
                            >
                              {parse(res?.labelText)}
                            </a>
                            {res?.fileType && res?.fileSize && (
                              <span className="spanFont">{`- ${res?.fileType}, ${res?.fileSize}`}</span>
                            )}

                            <div className="subText">
                              {parse(res?.description)}
                            </div>
                          </Grid>
                        </>
                      );
                    })}
                </Grid>
              </div>
            </>
          )}
        </StyledCardContent>
      </HelpAndSupportSubDiv>
      <RightMenuDiv>
        <NeedHelp
          isLoggedIn={isLoggedIn && termsOfUseAccepted}
          userRole={AuthObj?.userRolePermissionData?.userRole}
          isFromHelpSupport={true}
        />
      </RightMenuDiv>
    </HelpAndSupportDiv>
  );

  const contactUsNavigator = (
    <div className="route-section">
      <Navigator
        array={[
          {
            pageName: "Help and Support",
            route: "/helpAndSupport",
          },
        ]}
        title="Contact Us"
      />
    </div>
  );

  const sysreqNavigator = (
    <div className="route-section-sys-req">
      <Link className="link-to-help" to="/helpAndSupport">
        Help and Support
      </Link>
      <SelectIcon className="arrow-right" />
      <span className="contact-us-txt">System Requirements</span>
    </div>
  );

  const FAQNavigator = (
    <div className="route-section">
      <Navigator
        array={[
          {
            pageName: "Help and Support",
            route: "/helpAndSupport",
          },
        ]}
        title="FAQ and Video Tutorials"
      />
    </div>
  );

  return (
    <>
      <Switch>
        <Route exact path={path}>
          {helpAndSupport}
        </Route>
        <Route path={`${path}/faq`}>
          <div
            className={`faq-section 
            ${
              !isLoggedIn && !termsOfUseAccepted ? "faq-section-no-margin" : ""
            }`}
          >
            {FAQNavigator}
            <FAQ />
          </div>
        </Route>
        <Route path={`${path}/contactUs`}>
          <div className="contact-us-section">
            {contactUsNavigator}
            <GoogleReCaptchaProvider
              reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITEKEY ?? ""}
              scriptProps={{ async: true, defer: true, appendTo: "body" }}
              container={{
                element: "recaptcha-cs-container",
                parameters: {
                  badge: "inline",
                },
              }}
            >
              <ContactUs />
            </GoogleReCaptchaProvider>
          </div>
        </Route>
        <Route path={`${path}/contactUsSent`}>
          <div className="contact-us-sent-section">
            {contactUsNavigator}
            <ContactUsAcknowledgement />
          </div>
        </Route>
        <Route path={`${path}/sysreq`}>
          <div
            className={`sys-req-section 
            ${!isLoggedIn && !termsOfUseAccepted ? "sys-req-no-margin" : ""}`}
          >
            {sysreqNavigator}
            {/* commenting out since its not required Keeping it for future purpose */}
            {/* <SystemRequirements
              data={pageContent?.systemRequirement?.description!}
            /> */}
          </div>
        </Route>
      </Switch>
    </>
  );
};
