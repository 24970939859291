import { InputBase } from "@mui/material";
import { ExpressButton } from "../../../../../../core/expressButton/expressButton.component";
import { InputWithLabel } from "../../../../../../core/inputWithLabel/inputWithLabel.component";
import "./updateLocationName.css";
import { useContext } from "react";
import {
  RolesPermissionContextType,
  RolesPermissionContext,
} from "../../../../../../context/RolesPermissionContext";
import { IMyListUpdateLocation } from "../../myListStorageLocations.interface";

interface Props {
  setOpenpopup: Function;
  updateLocationObj: IMyListUpdateLocation;
  validateAndSetLocationName: any;
  updateLocationNameToDB: any;
  isLocationNameChange: boolean;
  setIsLocationNameChange: Function;
}

export const UpdateStorageLocationName = ({
  setOpenpopup,
  updateLocationObj,
  validateAndSetLocationName,
  updateLocationNameToDB,
  isLocationNameChange,
  setIsLocationNameChange,
}: Props) => {
  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );
  return (
    <div className="Update-location-name-div">
      <div
        className="update-location-title1"
        data-testid="update-location-title1-test"
      >
        Update storage location
      </div>
      <div
        className="update-location-title2"
        data-testid="update-location-title2-test"
      >
        Enter the storage location below. Select “Update location” to save the
        storage location.
      </div>
      <InputWithLabel
        label="Location"
        testId="update-loc-Input-field"
        labelClassName="update-loc-label"
        isRequired={true}
      >
        <InputBase
          required={true}
          className="update-inputBox"
          value={updateLocationObj.locationName}
          onChange={validateAndSetLocationName}
          autoFocus
        />
      </InputWithLabel>

      <div className="update-location-buttons">
        <ExpressButton
          parentClass="update-location-cancel-btn"
          variant="outlined"
          clickHandler={() => {
            setOpenpopup(false);
            setIsLocationNameChange(true);
          }}
          testId="update-add-location-cancelBtn"
        >
          Cancel
        </ExpressButton>
        <ExpressButton
          parentClass="update-loc-update-btn"
          variant="contained"
          clickHandler={updateLocationNameToDB}
          testId="update-location-btn"
          disabled={
            updateLocationObj.locationName.length === 0 ||
            permissionObj?.mappedRolesPermissionData.IsSupportRole ||
            isLocationNameChange
          }
        >
          Update location
        </ExpressButton>
      </div>
    </div>
  );
};
