export const SCANBOT_LICENSE_NAME = "3MELicenseKey--Scanbot";
export const AGGRID_LICENSE_NAME = "3MELicenseKey--AGgrid";
export const ALERTMANAGER_FUNCTION_KEY =
  "3MEAuthorizationKey--AlertManagerFunction";
export const CONTENTMANAGER_FUNCTION_KEY =
  "3MEAuthorizationKey--ContentManagerFunction";
export const DOCUMENTMANAGER_FUNCTION_KEY =
  "3MEAuthorizationKey--DocumentManagerFunction";
export const DURABLE_FUNCTION_KEY = "3MEAuthorizationKey--DurableFunction";
export const INVENTORYMANAGER_FUNCTION_KEY =
  "3MEAuthorizationKey--InventoryManagerFunction";
export const OFFLINEPROCESS_FUNCTION_KEY =
  "3MEAuthorizationKey--OfflineProcessFunction";
export const PRODUCTMANAGER_FUNCTION_KEY =
  "3MEAuthorizationKey--ProductManagerFunction";
export const SITEACCESSMANAGER_FUNCTION_KEY =
  "3MEAuthorizationKey--SiteAccessManagerFunction";
export const ORDERMANAGERFUNCTION_FUNCTION_KEY =
  "3MEAuthorizationKey--OrderManagerFunction";
export const USERMANAGERFUNCTION_FUNCTION_KEY =
  "3MEAuthorizationKey--UserManagerFunction";
export const FUNCTION_SERVICE_KEY = "3MEAuthorizationKey--FunctionService";
export const FACILITY_MANAGER_FUNCTION_KEY =
  "3MEAuthorizationKey--FacilityManagerFunction";

export const FUNCTION_KEY_REQUESTBODY = [
  AGGRID_LICENSE_NAME,
  ALERTMANAGER_FUNCTION_KEY,
  FUNCTION_SERVICE_KEY,
  ORDERMANAGERFUNCTION_FUNCTION_KEY,
  USERMANAGERFUNCTION_FUNCTION_KEY,
  DOCUMENTMANAGER_FUNCTION_KEY,
  PRODUCTMANAGER_FUNCTION_KEY,
  INVENTORYMANAGER_FUNCTION_KEY,
  DURABLE_FUNCTION_KEY,
  SITEACCESSMANAGER_FUNCTION_KEY,
  FACILITY_MANAGER_FUNCTION_KEY,
];
