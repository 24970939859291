import "./header.css";
import {
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  NewOrderContext,
  NewOrderContextType,
} from "../../context/NewOrderContext";
import {
  ProfileFormContext,
  ProfileFormContextType,
} from "../../context/ProfileFormContext";
import {
  OrderDetailContext,
  OrderDetailContextType,
} from "../../context/OrderDetailsContext";
import { useOktaAuth } from "@okta/okta-react";
import { IdleTimerProvider } from "react-idle-timer";
import { UnlockVacOrder } from "../../util/vacOrderService";
import { IDLETIME, IDLEPOPTIMER } from "../../util/staticText";
import { ReactComponent as Line } from "../../assets/line.svg";
import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/headerlogo.svg";
import { AuthContext, AuthContextType } from "../../context/AuthContext";
import { NewOrderPageSection } from "../newOrder/NewOrderContainer.enum";
import { ReactComponent as VectorBlue } from "../../assets/VectorBlue.svg";
import { IdleTimeoutModal } from "../../IdleTimeout/idleTimeOutModal.component";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { ReactComponent as KeyboardArrowDown } from "../../assets/KeyboardArrowDown.svg";
import { OktaAuth } from "@okta/okta-auth-js";
import config from "../../config";

export const Header = () => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const AuthObj = useContext<AuthContextType | null>(AuthContext);
  const userName = AuthObj?.userName;
  const isLoggedIn = AuthObj?.isLoggedIn;
  const termsOfUseAccepted = AuthObj?.termsOfUseAccepted;
  const history = useHistory();
  const location = useLocation();
  const open = Boolean(anchorEl);
  const loginPath = "/";
  const signUpPath = "/signup";
  const helpPath = "/helpAndSupport";
  const manageProfile = "/manageProfile";
  const manageAccount = "/manageAccount";
  const { oktaAuth, authState } = useOktaAuth();
  const NewOrderObj = useContext<NewOrderContextType | null>(NewOrderContext);
  const [currentPath, setCurrentPath] = useState<string | null>(null);
  const profileForm = useContext<ProfileFormContextType | null>(
    ProfileFormContext
  );
  const orderOverViewObj = useContext<OrderDetailContextType | null>(
    OrderDetailContext
  );
  let [timeout, setTimeout1] = useState(IDLETIME); //dev
  const [timer, setTimer] = useState<any>(null);
  const [showModal, setShowModal] = useState(false);
  const isMobileScreen = useMediaQuery("(max-width:700px)");
  const handleMenu = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget as Element);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const redirectToManageProfile = () => {
    if (currentPath === "/ssoRedirect") {
      localStorage.setItem("isComingFromSSO", "true");
    }
    setAnchorEl(null);
    history.push({
      pathname: manageProfile,
    });
  };

  const redirectToManageAccount = () => {
    setAnchorEl(null);
    history.push(manageAccount);
  };

  useEffect(() => {
    if (location.pathname !== currentPath) {
      setCurrentPath(location.pathname);
    }
    if (currentPath === "/orders/newOrder" && NewOrderObj?.vacOrderID) {
      UnlockVacOrder(NewOrderObj?.vacOrderID);
      NewOrderObj?.resetNewOrderForm();
    }
    if (location.pathname !== "/home/orderOverview") {
      orderOverViewObj?.resetData();
      orderOverViewObj?.resetSeletedSupplyOrderData();
      orderOverViewObj?.resetWoundData();
    }
  }, [location.pathname]);

  const confirmLogout = async () => {
    if (
      NewOrderObj?.vacOrderID &&
      (NewOrderObj?.newOrderPage ===
        NewOrderPageSection.NEWORDER_PATIENT_INFO ||
        NewOrderObj?.newOrderPage ===
          NewOrderPageSection.NEWORDER_PATIENT_WOUND_INFO ||
        NewOrderObj?.newOrderPage === NewOrderPageSection.NEWORDER_REVIEW_ORDER)
    ) {
      UnlockVacOrder(NewOrderObj?.vacOrderID);
    }
    setAnchorEl(null);
    localStorage.clear();
    AuthObj?.setUserProfile(undefined);
    AuthObj?.setDeepLinkPath(null);
    sessionStorage.removeItem("DeepLinkPath");
    await oktaAuth.closeSession();
    await oktaAuth.signOut();
  };

  const clearData = () => {
    profileForm?.resetProfileForm();
  };
  const onPrompt = () => {
    if (oktaAuth) {
      if (authState?.isAuthenticated!) {
        setShowModal(true);
      }
    }
  };

  const idleTime = () => {
    if (oktaAuth) {
      if (authState?.isAuthenticated!) {
        setShowModal(false);
        confirmLogout();
      }
    }
  };

  useEffect(() => {
    if (oktaAuth && authState?.isAuthenticated) {
      oktaAuth.getUser().then((info) => {
        AuthObj?.setUserName(info.name);
      });
    }
  }, [AuthObj]);

  const handleCloseIdle = async () => {
    setShowModal(false);
    clearTimeout(timer);
  };

  const handleLogout = async () => {
    setShowModal(false);
    clearTimeout(timer);
    confirmLogout();
    AuthObj?.setDeepLinkPath(null);
    sessionStorage.removeItem("DeepLinkPath");
  };
  const handleCheckForSSOPath = () => {
    if (location.pathname.includes("/ssoRedirect")) {
      AuthObj?.setregisteredFaciltyAddress(undefined);
      AuthObj?.setIsInternalUserFacilitySelected(false);
    }
    history.push(helpPath);
  };

  return (
    <>
      <Grid container className="headerContainer">
        <Grid
          item
          className={
            oktaAuth &&
            authState?.isAuthenticated &&
            location.pathname !== "/ssoRedirect"
              ? "appLogo"
              : "appLoginLogo"
          }
        >
          <div
            className="logoClass"
            onClick={() => {
              if (!AuthObj?.isInternalUserFacilitySelected) {
                setCurrentPath(location.pathname);
              } else {
                history.push("/home");
              }
            }}
          >
            <Logo />
            <Line className="line" />
            <div className="appName">Express Therapy Portal</div>
          </div>
        </Grid>
        <Grid item className="appHelp">
          <div
            onClick={handleCheckForSSOPath}
            className={
              !location.pathname.includes(helpPath) ? "help" : "helpactive"
            }
          >
            {isMobileScreen ? "Help" : "Help and Support"}
          </div>
          {userName &&
            location.pathname !== signUpPath &&
            location.pathname !== loginPath && (
              <div
                className={
                  location.pathname !== manageProfile ? "login" : "loginactive"
                }
              >
                <Typography
                  classes={{
                    root:
                      location.pathname !== manageProfile
                        ? "userNameStyle"
                        : " userNameStyle userNameStyleActive",
                  }}
                  className="userstyle"
                >
                  {isMobileScreen ? "" : userName}
                </Typography>
                <IconButton
                  className="dropArrow"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={(event) => handleMenu(event)}
                  color="inherit"
                  sx={{ height: "9px" }}
                >
                  {isMobileScreen ? (
                    <AccountCircleOutlinedIcon />
                  ) : location.pathname === manageProfile ? (
                    <VectorBlue width="18" height="9" />
                  ) : (
                    <KeyboardArrowDown width="18" height="9" />
                  )}
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={open}
                  onClose={handleClose}
                  sx={{
                    top: "40px",
                  }}
                  classes={{
                    paper: "paperstyle",
                  }}
                >
                  <MenuItem
                    disabled={!termsOfUseAccepted}
                    className="manageprofile"
                    classes={{
                      root: "typostyle",
                    }}
                    onClick={redirectToManageProfile}
                  >
                    Manage Profile
                  </MenuItem>

                  <MenuItem
                    className="manageprofile"
                    classes={{
                      root: "typostyle typoStyle2",
                    }}
                    onClick={confirmLogout}
                  >
                    Log Out
                  </MenuItem>
                </Menu>
              </div>
            )}
          {!userName &&
            !isLoggedIn &&
            location.pathname !== loginPath &&
            location.pathname !== "/ssoRedirect" && (
              <Link
                className={"loginLabelactive"}
                data-testid="login"
                onClick={clearData}
                to={loginPath}
              >
                Login
              </Link>
            )}

          <IdleTimerProvider
            timeout={timeout}
            onIdle={idleTime}
            promptBeforeIdle={IDLEPOPTIMER}
            onPrompt={onPrompt}
          >
            {showModal && (
              <IdleTimeoutModal
                showModal={showModal}
                handleLogout={handleLogout}
                setShowModal={setShowModal}
                confirmLogout={confirmLogout}
              />
            )}
          </IdleTimerProvider>
        </Grid>
      </Grid>
    </>
  );
};
