import { Grid } from "@mui/material";
import React, { ReactNode, useContext, useState } from "react";
import { AddFacility } from "../addFacility/addFacility.component";
import { AddFacilityManually } from "../addFacilityManually/addFacilityManually.component";
import { IFacility } from "../facility.interface";
import { FacilityFound } from "../facilityFound/facilityFound.component";
import { FacilityNotFound } from "../facilityNotFound/facilityNotFound.component";
import { AddFacilityContext } from "./addFacilityContainer.context";
import { SearchSection } from "./addFacilityContainer.enum";
import { IAddFacilityContainer } from "./addFacilityContainer.interface";
import { MyListAddHcpFound } from "../../../administration/myLists/myListHomeCareProvider/myListAddHCPFound/myListAddHCPFound.component";

export const AddFacilityContainer = ({
  defaultPageSection = SearchSection.SEARCH_FORM,
  isForNewOrder,
  isForAdminFlow,
  isUnlinkedUserFlow = false,
  requestedFacility,
}: IAddFacilityContainer) => {
  const { addFacilityToList } = useContext(AddFacilityContext);
  const [currentSection, setCurrentSection] =
    useState<SearchSection>(defaultPageSection);
  const [foundSearchData, setfoundSearchData] = React.useState<IFacility[]>();
  const redirectHandler = (
    changeSectionTo: SearchSection,
    searchData?: IFacility[]
  ): void => {
    if (searchData) {
      setfoundSearchData(searchData);
    }
    setCurrentSection(changeSectionTo);
  };
  const sectionToDisplay = () => {
    let page: ReactNode;
    switch (currentSection) {
      case SearchSection.FOUND:
        page = (
          <FacilityFound
            facilities={foundSearchData!}
            redirectHandler={redirectHandler}
            requestedFacility={requestedFacility}
            isUnlinkedUserFlow={isUnlinkedUserFlow}
          />
        );
        break;
      case SearchSection.MY_LIST_HCP_FOUND:
        page = (
          <MyListAddHcpFound
            facilities={foundSearchData!}
            redirectHandler={redirectHandler}
          />
        );
        break;
      case SearchSection.NOT_FOUND:
        page = (
          <FacilityNotFound
            redirectHandler={redirectHandler}
            isForNewOrder={isForNewOrder}
            isForAdminFlow={isForAdminFlow}
            isUnlinkedUserFlow={isUnlinkedUserFlow}
          />
        );
        break;
      case SearchSection.SEARCH_FORM:
        page = (
          <AddFacility
            redirectHandler={redirectHandler}
            addNewFacility={addFacilityToList}
            isForAdminFlow={isForAdminFlow}
            requestedFacility={requestedFacility}
            isUnlinkedUserFlow={isUnlinkedUserFlow}
          />
        );
        break;
      case SearchSection.MANUAL_ADD:
        page = (
          <AddFacilityManually
            redirectHandler={redirectHandler}
            addNewFacility={addFacilityToList}
          />
        );
        break;
    }
    return page;
  };
  return (
    <div>
      <Grid>{sectionToDisplay()}</Grid>
    </div>
  );
};
