import { ICapsuleInfo } from "./orderDetailsTracking.component";

export let customerPlusCapusle: ICapsuleInfo = {
  className: "is-customer-plus",
  text: "Customer Plus",
};
export let storedProductCapusle: ICapsuleInfo = {
  className: "is-stored-product-orderOverView",
  text: "Stored Product",
};
