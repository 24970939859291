import { Grid, Typography } from "@mui/material";
import { ExpressButton } from "../../../core/expressButton/expressButton.component";
import { PageSection } from "../searchHomeCareOrderContainer.enum";
import { ISearchHomeCareRedirect } from "../searchHomeCareOrderContainer.interface";
import "./HomeCareOrderNotFound.css";

const HomeCareOrderNotFound = ({
  redirectHandler,
}: ISearchHomeCareRedirect) => {
  return (
    <Grid container data-testid="order-not-found-popup-content">
      <p
        className="home-care-order-not-found-header"
        data-testid="hc-order-not-found"
      >
        Home Care Order Not Found
      </p>
      <div className="home-care-order-not-found-card">
        <Typography
          className="home-care-order-not-found-title"
          data-testid="home-care-order-not-found-title"
        >
          We were unable to locate an order matching the search criteria
          provided.
        </Typography>
        <Typography
          className="home-care-order-not-found-sub-title"
          data-testid="home-care-order-not-found-sub-title"
        >
          Please ‘Search Again’ or tap the X to close.
        </Typography>
      </div>
      <div className="search-agian-button-div">
        <ExpressButton
          variant="outlined"
          testId="search-again-button"
          parentClass="search-again-button"
          clickHandler={() =>
            redirectHandler(PageSection.SEARCH_HOME_CARE_ORDER_FORM)
          }
        >
          Search Again
        </ExpressButton>
      </div>
    </Grid>
  );
};

export default HomeCareOrderNotFound;
