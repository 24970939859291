import React from "react";
import { ISalesAssistance } from "../../salesAssistanceNeedHelp.interface";
import { Typography } from "@mui/material";
import "./salesAssistanceUploadDocuments.css";
import CustomDropZone from "../../../../../core/customDropZone/customDropZone.component";
import { IDropZoneDocumentSelect } from "../../../../../core/customDropZone/dropZoneDocumentSelect.interface";
import { IVacTherapyInformation } from "../../../../newOrder/woundBed/vacTherapyInformation.interface";

interface ISalesAssistanceUploadDocuments {
  data: ISalesAssistance;
  setData: Function;
  uploadDocuments: IDropZoneDocumentSelect[];
  setUploadDocuments: Function;
  documentTypeText: string[];
  vacTherapyInformationData?: IVacTherapyInformation;
}

const allowedFiles = {
  "image/png": [".jpeg", ".gif", ".jpg", ".png", ".tiff"],
  "text/pdf": [".pdf"],
};

const SalesAssistanceUploadDocuments: React.FC<
  ISalesAssistanceUploadDocuments
> = ({
  data,
  setData,
  uploadDocuments,
  setUploadDocuments,
  documentTypeText,
  vacTherapyInformationData,
}): React.ReactElement => {
  return (
    <div className="salesAssistance">
      <div
        className="salesAssistance-upload-document-header"
        data-testid="salesAssistance-upload-document-header"
        id="salesAssistance-upload-document-header"
      >
        Upload Documents
      </div>
      <div className="uploadDocDesp">
        <p id="salesAssistance-upload-document-description">
          Clinical documents are needed to process your order. Please upload or
          fax the patient face sheet, prescription, insurance name and policy
          number, H&P, any clinical notes, and wound documentation (
          <a
            href={vacTherapyInformationData?.npwtRequirement?.fileLink}
            target="_blank"
            className="npwtLinkFont"
          >
            {`${vacTherapyInformationData?.npwtRequirement?.labelText} `}
          </a>
          provides a complete list of needed documents).
        </p>
        <p>
          Uploaded files must be in one of the following formats: JPG, GIF,
          JPEG, PNG, TIFF or PDF. Each file cannot exceed 15 MB (15360 KB) in
          size.
        </p>
      </div>
      <div
        data-testid="salesAssistance-upload-document-drop-zone"
        id="salesAssistance-upload-document-drop-zone"
      >
        <CustomDropZone
          allowedFiles={allowedFiles}
          data={uploadDocuments}
          setData={setUploadDocuments}
          dragDropText="Drag and drop files here to upload"
          buttonDropText="Select files to upload"
          dropZoneStyles="dropZoneStyles"
          dragAndDropStyles="dragAndDrop"
          uploadWidgetCard="uploadWidgetCard"
          uploadIconStyle="uploadIconStyle"
          listingType="customUploadDoc"
          maxFileSize={15728640}
          singleFile={false}
          documentTypeText={documentTypeText}
          isToShowDocumentTypeDropDown={false}
        />
      </div>
    </div>
  );
};

export default SalesAssistanceUploadDocuments;
