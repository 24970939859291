import { Grid, Typography } from "@mui/material";
import React, { useContext } from "react";
import {
  IMobileDisplayContext,
  MobileDisplayContext,
} from "../../../../context/MobileDisplayContext";
import "./productSelection.css";
import { InputWithLabel } from "../../../../core/inputWithLabel/inputWithLabel.component";
import { ValidationStatus } from "../../../../core/interfaces/input.interface";
import { IInpatientOrder } from "../../inpatientOrder.interface";
import {
  InpatientOrderContext,
  InpatientOrderContextType,
} from "../../../../context/InpatientOrderContext";
import { ISolventumProduct } from "../../../inventory/inventoryTabContainer.enum";
import { defaultFilterValues } from "../../../inpatientSupplyOrder/container/inpatientSupplyOrderContainer.component";
import {
  InpatientSupplyOrderContext,
  InpatientSupplyOrderContextType,
} from "../../../../context/InpatientSupplyOrderContext";

interface Props {
  data: IInpatientOrder;
  setData: Function;
  setFilterValues: Function;
  resetCart: Function;
}

const ProductSelection = ({
  data,
  setData,
  setFilterValues,
  resetCart,
}: Props) => {
  const { isMobileScreen } =
    useContext<IMobileDisplayContext>(MobileDisplayContext);
  const inpatientOrderObj = useContext<InpatientOrderContextType | null>(
    InpatientOrderContext
  );
  const inPatientSupplyOrderObj =
    useContext<InpatientSupplyOrderContextType | null>(
      InpatientSupplyOrderContext
    );
  const handleProductClick = (solventumProduct: ISolventumProduct) => {
    if (data.orderWithSolventum.value) {
      if (solventumProduct.sku !== data.orderWithSolventum.value.sku) {
        resetCart();
        setFilterValues(defaultFilterValues);
        inPatientSupplyOrderObj?.setInPatientSuppliesData(null);
      }
    }
    setData({
      ...data,
      orderWithSolventum: {
        ...data.orderWithSolventum,
        valid: ValidationStatus.VALID,
        required: true,
        value: solventumProduct,
      },
    });
    const tempProduct = {
      imageLink: solventumProduct.productImage,
      name: solventumProduct.productCompleteDescrption ?? "",
      serialNumber: "",
    };
    inpatientOrderObj!.setProduct(tempProduct);
  };

  return (
    <div className="inpatient-product-selection-main-div">
      <div className="inpatient-product-grid-div">
        <Typography
          className="inpatient-product-selection-card-header"
          data-testid="inpatient-product-selection-card-header"
        >
          Product Selection
        </Typography>
        <InputWithLabel
          error={data.orderWithSolventum.valid === ValidationStatus.INVALID}
          id={data.orderWithSolventum.componentId!}
          isRequired={true}
          isShowWarning={true}
          label={data.orderWithSolventum.title}
          labelClassName={`inpatient-product-selection-header-title${
            data.orderWithSolventum.valid === ValidationStatus.INVALID ??
            " error-label"
          }`}
          warningMessage={
            data.orderWithSolventum.errorMessage
              ? "Please select any one of the above option"
              : ""
          }
        >
          <Grid container className="productOptionGrid">
            {inpatientOrderObj!.solventumProducts &&
              inpatientOrderObj!.solventumProducts.map(
                (solventumProduct: ISolventumProduct) => (
                  <Grid
                    onClick={() => {
                      handleProductClick(solventumProduct);
                    }}
                    item
                    xs={isMobileScreen ? 12 : 5}
                    className={`productOptionGridItem ${
                      data.orderWithSolventum?.value?.productDesc ===
                      solventumProduct.productDesc
                        ? "productOptionGridItem-selected"
                        : ""
                    }`}
                  >
                    {solventumProduct.productImage !== "" && (
                      <img
                        alt={solventumProduct.productCompleteDescrption}
                        className="in-patient-solventum-order-product-image"
                        data-testid="in-patient-solventum-order-product-image"
                        id="in-patient-solventum-order-product-image"
                        src={solventumProduct.productImage}
                      />
                    )}
                    <div>{solventumProduct.productCompleteDescrption}</div>
                  </Grid>
                )
              )}
          </Grid>
        </InputWithLabel>
      </div>
    </div>
  );
};

export default ProductSelection;
