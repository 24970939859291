import SearchIcon from "@mui/icons-material/Search";
import { Button, InputBase, Typography, useMediaQuery } from "@mui/material";
import "./myListStorageLocations.css";
import { CustomDropDown } from "../../../../core/customDropdown/customDropdown.component";
import AddIcon from "@mui/icons-material/AddCircleOutline";
import { useContext, useEffect, useRef, useState } from "react";
import {
  RolesPermissionContextType,
  RolesPermissionContext,
} from "../../../../context/RolesPermissionContext";
import { AgGridReact } from "ag-grid-react";
import {
  agGridGetRowStyle,
  getSiteUseId,
  locationNameValidation,
} from "../../../../util/utilityFunctions";
import { ColDef } from "ag-grid-enterprise";
import { DD_MY_LIST_STORAGE_LOC_STATUS_TYPE } from "../../../../util/staticText";
import { getdropDownContent } from "../../../../util/dropDownService";
import { useDebounce } from "use-debounce";
import { AuthContext, AuthContextType } from "../../../../context/AuthContext";
import {
  enableOrDsiableFacilityStorageLocation,
  getFacilityStorageLocation,
  updateStorageLocationName,
} from "../../../../util/inventoryMgrService";
import { IMyListStorageLocation } from "./myListStorageLocations.interface";
import { CustomCheckBox } from "../../../../core/checkBox/checkBox.component";
import { Popup } from "../../../../core/popup/popup.component";
import { LoadingSpinner } from "../../../../core/loader/LoadingSpinner";
import ErrorPopup from "../../../../core/errorPopup/errorPopup.component";
import {
  ERROR_MSG_DUPLICATE_STORAGE_LOCATIONS,
  STORAGE_LOCATION_LOAD_SERVICE_ERROR,
  STORAGE_LOCATION_UPDATE_SERVICE_ERROR,
} from "../../../../util/errorMsg";
import { MyListAddUpdateContainer } from "./myListAddUpdateLocationsContainer/myListAddUpdateContainer.component";
import { MyListStorageLocationPopUps } from "./myListAddUpdateLocationsContainer/myListAddUpdateConatiner.enum";
import { ValidationStatus } from "../../../../core/interfaces/input.interface";
import { MyListStorageLocationsCustomHeader } from "./myListStorageLocationsCustomHeader/myListStorageLocationsCustomHeader.component";

export interface IMyListUpdateLocation {
  storageId: string;
  locationName: string;
}

export interface IUpdateLocationReqBody {
  siteUseId: string;
  locationName: string;
  storageId: string;
  username: string;
}

export const MyListStoredLocations = () => {
  const gridRef: any = useRef(null);
  const [statusText, setStatusText] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState<string>("");
  const [locationData, setLocationData] = useState<IMyListStorageLocation[]>(
    []
  );
  const [originalData, setOriginalData] = useState<IMyListStorageLocation[]>(
    []
  );
  const [buttonText, setButtonText] = useState("Done");
  const [showSpinner, setShowSpinner] = useState(false);
  const [searchedInput, setSearchedInput] = useState<string>("");
  const [searchInput, setSearchInput] = useState<string>("");
  const [debouncedText] = useDebounce(searchInput, 500);
  const [errorPopPpFlag, setErrorPopUpFlag] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorCode, setErrorCode] = useState<number>(0);
  const [openpopup, setOpenpopup] = useState(false);
  const [currentSection, setCurrentSection] =
    useState<MyListStorageLocationPopUps>(
      MyListStorageLocationPopUps.ADD_STORAGE_LOCATION
    );
  const [customLocationsCount, setCustomLocationsCount] = useState<number>(0);

  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );
  const isMobileScreen = useMediaQuery("(max-width:920px)");
  const isTabScreen = useMediaQuery("(min-width:600px) and (max-width:920px)");
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const [updateLocationObj, setUpdateLocationObj] =
    useState<IMyListUpdateLocation>({
      storageId: "",
      locationName: "",
    });
  const errorPopupProps = {
    isSupportPhoneRequired: false,
    isShortSentence: false,
    phone: "",
  };
  const [previousLocationName, setPreviousLocationName] = useState<string>("");
  const [isLocationNameChange, setIsLocationNameChange] = useState(true);
  const props = errorCode === 2012 ? errorPopupProps : {};

  const defaultColDef = {
    sortable: true,
    unSortIcon: true,
    suppressMenu: true,
    autoHeight: true,
    filter: false,
  };

  const columnDefs: ColDef[] = [
    {
      headerName: "Enabled",
      field: "isEnable",
      sortable: false,
      suppressMovable: true,
      width: 150,
      headerComponent: MyListStorageLocationsCustomHeader,
      cellRenderer: function (params: any) {
        return (
          <div>
            <CustomCheckBox
              selectClassName="facility-permissions-checkbox"
              selectpropsClassName="facility-permissions-checkbox-root"
              handleChange={(e: any) => valdiateAndSetCheckBox(e, params.data)}
              labelClassName={"wound-exposed-structures-unchecked"}
              checked={params.data.isEnable}
              value={selectedStatus}
              required={false}
              isDisabled={
                permissionObj?.mappedRolesPermissionData.IsSupportRole ||
                params.data.hasLocationAssigned
              }
            />
          </div>
        );
      },
    },
    {
      headerName: "Storage Location",
      field: "locationName",
      sortable: true,
      filter: false,
      suppressMovable: true,
      minWidth: isTabScreen ? 480 : 350,
      cellRenderer: function (params: any) {
        return (
          <>
            {!params.data.isCustom ? (
              <div className={"myList-location-name"}>
                {params.data.locationName}
              </div>
            ) : (
              <Button
                variant="text"
                disabled={
                  permissionObj?.mappedRolesPermissionData?.IsSupportRole
                }
                className="custom-added-location custom-location-link"
                onClick={() => {
                  let locationName = params.data.locationName
                    .split("-")
                    .slice(1)
                    .join("-")
                    .trim();
                  setUpdateLocationObj({
                    storageId: params.data.storageId,
                    locationName: locationName,
                  });

                  setPreviousLocationName(locationName);
                  setOpenpopup(true);
                  setCurrentSection(
                    MyListStorageLocationPopUps.UPDATE_STORAGE_LOCATION_NAME
                  );
                }}
              >
                {params.data.locationName.toUpperCase()}
              </Button>
            )}
          </>
        );
      },
    },
  ];

  const fetchDropDownContent = async () => {
    try {
      const data = await getdropDownContent(DD_MY_LIST_STORAGE_LOC_STATUS_TYPE);
      if (data.items.length > 0) {
        const statusTypeObj = data.items.filter(
          (item: { name: string }) =>
            item.name === DD_MY_LIST_STORAGE_LOC_STATUS_TYPE
        );
        const statusTypeData = statusTypeObj[0].data.sort(
          (a: { order: number }, b: { order: number }) =>
            a.order > b.order ? 1 : -1
        );
        setStatusText(statusTypeData.map((x: { text: string }) => x.text));
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const validateAndSetLocationName = (e: any) => {
    if (
      e.target.value.toLowerCase().trim() ===
        previousLocationName.toLowerCase() ||
      e.target.value.toLowerCase().length > 39
    ) {
      setIsLocationNameChange(true);
    } else setIsLocationNameChange(false);
    const validation = locationNameValidation(e.target.value);
    if (validation.status === ValidationStatus.VALID) {
      setUpdateLocationObj((p) => ({ ...p, locationName: e.target.value }));
    }
  };

  const updateLocationNameToDB = async () => {
    setIsLocationNameChange(true);
    if (
      updateLocationObj.locationName.length > 0 &&
      originalData &&
      originalData.length > 0
    ) {
      const reqBody: IUpdateLocationReqBody = {
        siteUseId: getSiteUseId(authObj),
        locationName:
          "OTH - " + updateLocationObj.locationName.toUpperCase().trim(),
        storageId: updateLocationObj.storageId,
        username: authObj?.userProfile?.userName!,
      };
      setOpenpopup(false);
      setShowSpinner(true);
      const updateLocationObjResponse = await updateStorageLocationName(
        reqBody
      );
      setShowSpinner(false);
      if (updateLocationObjResponse && updateLocationObjResponse.succeeded) {
        const updatedStorageLocationsList = originalData.map(
          (location: IMyListStorageLocation) => {
            if (
              location.storageId.toString() ===
              updateLocationObj.storageId.toString()
            ) {
              return {
                ...location,
                locationName: "OTH - " + updateLocationObj.locationName,
              };
            } else return location;
          }
        );
        setOriginalData(updatedStorageLocationsList);
        setLocationData(updatedStorageLocationsList);
        setSearchInput("");
        setSelectedStatus("");
      } else {
        setErrorCode(
          updateLocationObjResponse?.error?.errorCode ||
            updateLocationObjResponse?.status
        );
        let code = updateLocationObjResponse?.error?.errorCode;
        if (code === 2012) {
          setErrorMessage(ERROR_MSG_DUPLICATE_STORAGE_LOCATIONS);
          setButtonText("Back");
          setErrorPopUpFlag(true);
        } else {
          setErrorMessage(STORAGE_LOCATION_UPDATE_SERVICE_ERROR);
          setErrorPopUpFlag(true);
        }
      }
    }
  };

  const updateCheckBoxLocally = (
    value: boolean,
    data: IMyListStorageLocation
  ) => {
    const updateDate = (location: IMyListStorageLocation) => {
      if (data.storageId === location.storageId) {
        return {
          ...location,
          isEnable: value,
        };
      } else return location;
    };
    const updatedStorageLocations = locationData.map(updateDate);
    const updatedOriginalStorageLocations = originalData.map(updateDate);
    setLocationData(updatedStorageLocations);
    setOriginalData(updatedOriginalStorageLocations);
    filterUsers(searchInput, selectedStatus, updatedOriginalStorageLocations);
  };

  const valdiateAndSetCheckBox = async (
    e: any,
    data: IMyListStorageLocation
  ) => {
    const value = e.target.checked;
    updateCheckBoxLocally(value, data);
    setShowSpinner(true);
    const reqBody = {
      siteUseId: getSiteUseId(authObj),
      isEnable: value,
      storageId: data.storageId,
      username: authObj?.userProfile?.userName,
    };
    const enableOrDisableResponse =
      await enableOrDsiableFacilityStorageLocation(reqBody);
    setShowSpinner(false);
    if (enableOrDisableResponse && !enableOrDisableResponse.succeeded) {
      updateCheckBoxLocally(!value, data);
      setErrorMessage(STORAGE_LOCATION_UPDATE_SERVICE_ERROR);
      setErrorCode(
        enableOrDisableResponse?.error?.errorCode ||
          enableOrDisableResponse?.status
      );
      setErrorPopUpFlag(true);
    }
  };

  const getFacilityStorageLocationAPICall = async () => {
    setShowSpinner(true);
    const request = {
      siteUseId: getSiteUseId(authObj),
      userName: authObj && authObj.userProfile?.userName,
    };
    const response = await getFacilityStorageLocation(request);
    if (response && response.succeeded) {
      setShowSpinner(false);
      setLocationData(response.items);
      setOriginalData(response.items);
    } else {
      setShowSpinner(false);
      setErrorMessage(STORAGE_LOCATION_LOAD_SERVICE_ERROR);
      setErrorCode(response?.error?.errorCode || response?.status);
      setErrorPopUpFlag(true);
    }
  };

  const handleSearch = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const re = /^[\sa-zA-Z0-9.\-/]{0,100}$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setSearchInput(e.target.value);
    }
  };

  const filterUsers = (
    searchParam: string,
    status: string,
    locationsList: IMyListStorageLocation[] = originalData
  ) => {
    setSearchedInput(searchParam);
    let searchStatus = "";
    if (status !== "All Statuses") {
      searchStatus = status;
    }
    const isEnabled = searchStatus === "Enabled";
    const isDisabled = searchStatus === "Disabled";
    if (originalData && originalData.length > 0) {
      let filtedUsers = locationsList;
      if (searchStatus.length > 0) {
        filtedUsers = filtedUsers.filter((x: IMyListStorageLocation) => {
          if ((isEnabled && x.isEnable) || (isDisabled && !x.isEnable))
            return x;
        });
      }
      if (searchParam.length > 0) {
        filtedUsers = filtedUsers.filter((data: IMyListStorageLocation) =>
          data.locationName.toLowerCase().includes(searchParam.toLowerCase())
        );
      }
      setLocationData(filtedUsers);
    }
  };

  const validateAndSetData = (e: any) => {
    setSelectedStatus(e.target.value);
    filterUsers(debouncedText, e.target.value);
  };

  useEffect(() => {
    fetchDropDownContent();
    getFacilityStorageLocationAPICall();
  }, []);

  useEffect(() => {
    if (
      (debouncedText.length === 0 && searchedInput !== debouncedText) ||
      debouncedText.length > 2
    ) {
      filterUsers(debouncedText, selectedStatus);
    }
  }, [debouncedText]);

  useEffect(() => {
    let isCustomCount = 0;
    originalData.forEach((location) => {
      if (location.isCustom) {
        isCustomCount += 1;
      }
    });
    setCustomLocationsCount(isCustomCount);
  }, [originalData]);

  const spinner = () => {
    return (
      <div className="myList-storage-loc-spinner">
        <LoadingSpinner />
      </div>
    );
  };

  return (
    <div className="myList-storage-location-main-div">
      <div className="myList-storage-loc-sub-div">
        <div
          className="myList-storage-loc-searchbar"
          data-testid="myList-storage-loc-searchbar"
        >
          <div className="myList-storage-loc-icon-div">
            <SearchIcon className="search-icon" />
          </div>
          <InputBase
            className="myList-storage-loc-search-inputBase"
            name="search-input"
            onChange={handleSearch}
            placeholder="Filter by Location Name"
            value={searchInput}
          />
        </div>
        <div className="storage-loc-status-button">
          <CustomDropDown
            handleChange={validateAndSetData}
            menuItem={statusText}
            placeHolder="All Statuses"
            selectClassName={"storage-loc-allStatus-input"}
            selectpropsClassName={"storage-location-allStatus-select"}
            testId="myList-storage-loc-drop-down"
            value={selectedStatus}
          />
          <Button
            classes={{ root: "myList-storage-loc-add-btn" }}
            startIcon={<AddIcon />}
            onClick={() => {
              setOpenpopup(true);
              setCurrentSection(
                MyListStorageLocationPopUps.ADD_STORAGE_LOCATION
              );
            }}
            disabled={permissionObj?.mappedRolesPermissionData.IsSupportRole}
            data-testid="myList-storage-loc-add-btn"
          >
            {isMobileScreen ? "Add New" : "Add new storage location"}
          </Button>
        </div>
      </div>
      <div
        data-testid="myList-storage-location-ag-table"
        className="ag-theme-quartz"
        style={{ height: 400, width: "100%" }}
      >
        <AgGridReact
          autoSizeStrategy={{
            type: "fitGridWidth",
            defaultMinWidth: 100,
          }}
          ref={gridRef}
          getRowStyle={agGridGetRowStyle}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowData={locationData}
          suppressRowClickSelection={true}
          suppressDragLeaveHidesColumns={true}
          suppressContextMenu={true}
        />
      </div>
      {showSpinner && (
        <Popup
          hideCloseButton={true}
          openFlag={showSpinner}
          closeHandler={() => {}}
          dialogParentClass="storage-location-loader"
        >
          {spinner()}
        </Popup>
      )}
      <ErrorPopup
        errorPopupFlag={errorPopPpFlag}
        handleBackButton={() => setErrorPopUpFlag(false)}
        popUpStyles="errorPopup"
        errorMessage={errorMessage}
        buttontitle={buttonText}
        showTitle={false}
        isShortSentence={false}
        errorCode={errorCode}
        {...props}
      />
      <Popup
        openFlag={openpopup}
        closeHandler={() => {
          setOpenpopup(false);
          setIsLocationNameChange(true);
        }}
        dialogParentClass={"myList-storage-location-pop-up"}
      >
        <MyListAddUpdateContainer
          currentSection={currentSection}
          setCurrentSection={setCurrentSection}
          setOpenpopup={setOpenpopup}
          getFacilityStorageLocationAPICall={getFacilityStorageLocationAPICall}
          updateLocationObj={updateLocationObj}
          validateAndSetLocationName={validateAndSetLocationName}
          updateLocationNameToDB={updateLocationNameToDB}
          customLocationsCount={customLocationsCount}
          setShowSpinner={setShowSpinner}
          setSelectedStatus={setSelectedStatus}
          setSearchInput={setSearchInput}
          isLocationNameChange={isLocationNameChange}
          setIsLocationNameChange={setIsLocationNameChange}
        />
      </Popup>
    </div>
  );
};
