import {
  IInpatientWoundInformation,
  IWoundInfoDropDownValues,
  IWoundInformation,
} from "../woundInformation.interface";
import { useContext, useState } from "react";
import {
  convertStringToDate,
  formatedWoundValue,
} from "../../../../../util/utilityFunctions";
import {
  IMobileDisplayContext,
  MobileDisplayContext,
} from "../../../../../context/MobileDisplayContext";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { IInpatientOrder } from "../../../inpatientOrder.interface";
import { Typography, TextField, InputBase, Grid } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { InpatientOrderValidator } from "../../../inpatientOrder.validator";
import { ValidationStatus } from "../../../../../core/interfaces/input.interface";
import { ReactComponent as CalendarIcon } from "../../../../../assets/calendar.svg";
import { CustomDropDown } from "../../../../../core/customDropdown/customDropdown.component";
import { InputWithLabel } from "../../../../../core/inputWithLabel/inputWithLabel.component";
import moment from "moment";

interface IWoundSectionProp {
  data: IInpatientOrder;
  isPrimary: boolean;
  setData: Function;
  Validator?: InpatientOrderValidator;
  woundInfoDropDownValues: IWoundInfoDropDownValues;
  woundInfoData: IInpatientWoundInformation;
}

const WoundSection = ({
  data,
  isPrimary,
  setData,
  woundInfoData,
  woundInfoDropDownValues,
  Validator = new InpatientOrderValidator(),
}: IWoundSectionProp) => {
  const { isMobileScreen } =
    useContext<IMobileDisplayContext>(MobileDisplayContext);
  const [validator] = useState(Validator!);

  const validateAndSetData = (e: any) => {
    const { name, value } = e.target;
    const isValid = validator.validate(value, name);
    if (
      (name === "woundLength" ||
        name === "woundWidth" ||
        name === "woundDepth") &&
      isValid?.status === ValidationStatus.INVALID
    ) {
      return null;
    }
    let updatedInputField: IInpatientWoundInformation = {
      ...woundInfoData,
      [name]: {
        ...Object(woundInfoData)[name],
        value: value,
        valid: isValid!.status,
      },
    };
    handleUpdateData(updatedInputField);
  };

  const validateAndSetDate = (date: string | null | undefined) => {
    const formattedDate = date === null ? "" : convertStringToDate(date);
    const isValid = validator.validate(formattedDate, "woundMeasurementDate");
    let updatedInputField: IInpatientWoundInformation = {
      ...woundInfoData,
      woundMeasurementDate: {
        ...woundInfoData.woundMeasurementDate,
        errorMessage: null,
        value: formattedDate,
        valid: isValid!.status,
      },
    };
    handleUpdateData(updatedInputField);
  };

  const handleUpdateData = (updatedInputField: IInpatientWoundInformation) => {
    let tempData: IWoundInformation = data.patientWoundInformation.value;
    if (isPrimary) {
      tempData = {
        ...tempData,
        primaryWoundInformation: updatedInputField,
      };
    } else {
      tempData = {
        ...tempData,
        secondaryWoundInformation: updatedInputField,
      };
    }
    setData({
      ...data,
      patientWoundInformation: {
        ...data.patientWoundInformation,
        valid: ValidationStatus.VALID,
        value: tempData,
      },
    });
  };

  const formatWoundZeros = async (e: any) => {
    let { name } = e.target;
    let key: keyof typeof woundInfoData = name;
    let formatedValue = formatedWoundValue(woundInfoData[key].value);
    let updatedInputField: IInpatientWoundInformation = {
      ...woundInfoData,
      [name]: {
        ...Object(woundInfoData)[name],
        value: formatedValue,
      },
    };
    handleUpdateData(updatedInputField);
  };

  return (
    <Grid
      container
      className="inpatient-wound-info-content-div"
      data-testid={`inpatient-wound-info-${
        isPrimary ? "primary" : "secondary"
      }-input-section`}
    >
      <Grid item xs={12} mb={2}>
        <Typography
          className="inpatient-wound-info-content-header"
          data-testid="inpatient-wound-info-content-header"
        >
          Wound #{isPrimary ? 1 : 2}
        </Typography>
      </Grid>
      <Grid
        item
        className={`inpatient-wound-info-content-input-grid ${
          !isMobileScreen && `margin-right`
        }`}
        xs={isMobileScreen ? 12 : 5.85}
      >
        <InputWithLabel
          label={woundInfoData.woundType.title}
          isRequired={woundInfoData.woundType.required}
          error={woundInfoData.woundType.valid === ValidationStatus.INVALID}
          testId={woundInfoData.woundType.componentId!}
          id={woundInfoData.woundType.componentId!}
          warningMessage={woundInfoData.woundType.errorMessage}
          isShowWarning={true}
          isDropdown={true}
        >
          <CustomDropDown
            handleChange={validateAndSetData}
            menuItem={woundInfoDropDownValues.woundTypeDropDownValues}
            name="woundType"
            placeHolder={`Patient’s ${
              isPrimary ? "Primary " : "Secondary"
            } Wound Type`}
            selectpropsClassName={
              woundInfoData?.woundType.value
                ? "primary-select"
                : "primary-type-placeholder"
            }
            selectClassName={
              woundInfoData?.woundType.value
                ? "wound-information-input"
                : "placeHolder"
            }
            testId="inpatient-wound-information-wound-type-value"
            value={woundInfoData.woundType.value}
          />
        </InputWithLabel>
      </Grid>
      <Grid
        item
        className="inpatient-wound-info-content-input-grid"
        xs={isMobileScreen ? 12 : 5.85}
      >
        <InputWithLabel
          label={woundInfoData.woundLocation.title}
          isRequired={woundInfoData.woundLocation.required}
          error={woundInfoData.woundLocation.valid === ValidationStatus.INVALID}
          testId={woundInfoData.woundLocation.componentId!}
          id={woundInfoData.woundLocation.componentId!}
          warningMessage={woundInfoData.woundLocation.errorMessage}
          isShowWarning={true}
          isDropdown={true}
        >
          <CustomDropDown
            handleChange={validateAndSetData}
            menuItem={woundInfoDropDownValues.woundLocationDropDownValues}
            name="woundLocation"
            placeHolder="Wound Location"
            selectpropsClassName={
              woundInfoData?.woundLocation.value
                ? "primary-select"
                : "primary-type-placeholder"
            }
            selectClassName={
              woundInfoData?.woundLocation.value
                ? "wound-information-input"
                : "placeHolder"
            }
            testId="inpatient-wound-information-wound-location-value"
            value={woundInfoData.woundLocation.value}
          />
        </InputWithLabel>
      </Grid>
      <Grid
        item
        className={`inpatient-wound-info-content-input-grid ${
          !isMobileScreen && `margin-right`
        }`}
        xs={isMobileScreen ? 12 : 5.85}
      >
        <InputWithLabel
          label={woundInfoData.woundDirection.title}
          isRequired={woundInfoData.woundDirection.required}
          error={
            woundInfoData.woundDirection.valid === ValidationStatus.INVALID
          }
          testId={woundInfoData.woundDirection.componentId!}
          id={woundInfoData.woundDirection.componentId!}
          warningMessage={woundInfoData.woundDirection.errorMessage}
          isShowWarning={true}
          isDropdown={true}
        >
          <CustomDropDown
            handleChange={validateAndSetData}
            menuItem={woundInfoDropDownValues.woundDirectionDropDownValues}
            name="woundDirection"
            placeHolder="Select Direction"
            selectpropsClassName={
              woundInfoData?.woundDirection.value
                ? "primary-select"
                : "primary-type-placeholder"
            }
            selectClassName={
              woundInfoData?.woundDirection.value
                ? "wound-information-input"
                : "placeHolder"
            }
            testId="inpatient-wound-information-wound-direction-value"
            value={woundInfoData.woundDirection.value}
          />
        </InputWithLabel>
      </Grid>
      <Grid
        item
        className="inpatient-wound-info-content-input-grid"
        xs={isMobileScreen ? 12 : 5.85}
      >
        <InputWithLabel
          label={woundInfoData.woundOrientation.title}
          isRequired={woundInfoData.woundOrientation.required}
          error={
            woundInfoData.woundOrientation.valid === ValidationStatus.INVALID
          }
          testId={woundInfoData.woundOrientation.componentId!}
          id={woundInfoData.woundOrientation.componentId!}
          warningMessage={woundInfoData.woundOrientation.errorMessage}
          isShowWarning={true}
          isDropdown={true}
        >
          <CustomDropDown
            handleChange={validateAndSetData}
            menuItem={woundInfoDropDownValues.woundOrientationDropDownValues}
            name="woundOrientation"
            placeHolder="Select Orientation"
            selectpropsClassName={
              woundInfoData?.woundOrientation.value
                ? "primary-select"
                : "primary-type-placeholder"
            }
            selectClassName={
              woundInfoData?.woundOrientation.value
                ? "wound-information-input"
                : "placeHolder"
            }
            testId="inpatient-wound-information-wound-orientation-value"
            value={woundInfoData.woundOrientation.value}
          />
        </InputWithLabel>
      </Grid>
      <Grid
        item
        className={`inpatient-wound-info-content-input-grid ${
          !isMobileScreen && `margin-right`
        }`}
        xs={isMobileScreen ? 12 : 5.85}
      >
        <InputWithLabel
          isRequired={woundInfoData.woundMeasurementDate.required}
          isShowWarning={true}
          label={woundInfoData.woundMeasurementDate.title}
          testId={woundInfoData.woundMeasurementDate.componentId!}
          id={woundInfoData.woundMeasurementDate.componentId!}
          warningMessage={woundInfoData.woundMeasurementDate.errorMessage}
          error={
            woundInfoData.woundMeasurementDate.valid ===
            ValidationStatus.INVALID
          }
        >
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              components={{ OpenPickerIcon: CalendarIcon }}
              InputAdornmentProps={{
                classes: {
                  root: "adornedRoot",
                },
              }}
              InputProps={{
                classes: {
                  root: `dateOfAccident ${
                    woundInfoData.woundMeasurementDate.valid ===
                    ValidationStatus.INVALID
                      ? "showError"
                      : "noError"
                  }`,
                  input: "input",
                  notchedOutline: "outline",
                },
              }}
              onChange={(value) => {
                validateAndSetDate(value);
              }}
              renderInput={(params) => {
                params.error = false;
                params.inputProps!.placeholder = "__/__/____";
                return <TextField name="woundMeasurementDate" {...params} />;
              }}
              value={woundInfoData.woundMeasurementDate.value}
              maxDate={moment().toString()}
            />
          </LocalizationProvider>
        </InputWithLabel>
      </Grid>
      <Grid item xs={isMobileScreen ? 12 : 9}>
        <Grid className="dimension-grid-container" container spacing={2}>
          <Grid item xs={isMobileScreen ? 4 : 3}>
            <InputWithLabel
              label="Length (cm)"
              isRequired={woundInfoData.woundLength.required}
              testId={woundInfoData.woundLength.componentId!}
              id={woundInfoData.woundLength.componentId!}
              warningMessage={woundInfoData.woundLength.errorMessage}
              error={
                woundInfoData.woundLength.valid === ValidationStatus.INVALID
              }
              isShowWarning={true}
            >
              <InputBase
                placeholder="0"
                className="depth-input"
                name="woundLength"
                value={woundInfoData.woundLength.value}
                onChange={validateAndSetData}
                onBlur={formatWoundZeros}
                required={woundInfoData.woundLength.required}
                data-testid="inpatient-wound-information-wound-length-value"
                type="text"
                inputProps={{
                  inputMode: "decimal",
                  pattern: "^[0-9.]+$",
                }}
              />
            </InputWithLabel>
          </Grid>
          <Grid item xs={isMobileScreen ? 4 : 3}>
            <InputWithLabel
              label="Width (cm)"
              isRequired={woundInfoData.woundWidth.required}
              testId={woundInfoData.woundWidth.componentId!}
              id={woundInfoData.woundWidth.componentId!}
              warningMessage={woundInfoData.woundWidth.errorMessage}
              error={
                woundInfoData.woundWidth.valid === ValidationStatus.INVALID
              }
              isShowWarning={true}
            >
              <InputBase
                placeholder="0"
                className="depth-input"
                name="woundWidth"
                value={woundInfoData.woundWidth.value}
                onChange={validateAndSetData}
                onBlur={formatWoundZeros}
                required={woundInfoData.woundWidth.required}
                data-testid="inpatient-wound-information-wound-width-value"
                type="text"
                inputProps={{
                  inputMode: "decimal",
                  pattern: "^[0-9.]+$",
                }}
              />
            </InputWithLabel>
          </Grid>
          <Grid item xs={isMobileScreen ? 4 : 3}>
            <InputWithLabel
              label="Depth (cm)"
              isRequired={woundInfoData.woundDepth.required}
              testId={woundInfoData.woundDepth.componentId!}
              id={woundInfoData.woundDepth.componentId!}
              warningMessage={woundInfoData.woundDepth.errorMessage}
              error={
                woundInfoData.woundDepth.valid === ValidationStatus.INVALID
              }
              isShowWarning={true}
            >
              <InputBase
                placeholder="0"
                className="depth-input"
                name="woundDepth"
                value={woundInfoData.woundDepth.value}
                onChange={validateAndSetData}
                onBlur={formatWoundZeros}
                required={woundInfoData.woundDepth.required}
                data-testid="inpatient-wound-information-wound-depth-value"
                type="text"
                inputProps={{
                  inputMode: "decimal",
                  pattern: "^[0-9.]+$",
                }}
              />
            </InputWithLabel>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default WoundSection;
