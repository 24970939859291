import moment from "moment";
import { OrderOverViewTabsTitle } from "../components/myPatients/patientAndTherapyDetails/orderOverview/orderOverviewContainer.enum";
import { ISupplyOrdersInfo } from "../components/myPatients/patientAndTherapyDetails/orderSupplyDetail/orderSupplyDetails.interface";
import { ExchangeVacUnitContextType } from "../context/ExchangeVacUnitContext";
import { OrderDetailContextType } from "../context/OrderDetailsContext";
import { getPatient, getPatientMenuAction } from "../util/3meService";
import { getOrderStatusDetails } from "../util/vacOrderService";
import { IPatient } from "../components/myPatients/patient.interface";

const getOrderType = (type: string): string => {
  let orderType: string = "";
  if (type.toLowerCase() === "inpatientsupply") {
    orderType = "solventumProduct";
  } else {
    orderType = type;
  }
  return orderType;
};
export const checkDeeplinkPath = async (
  deepLinkPath: string,
  orderDetailObj: OrderDetailContextType,
  isFromSSO: boolean = false,
  exchangeVacObj?: ExchangeVacUnitContextType
) => {
  if (deepLinkPath.includes("/home/orderOverview/deepLink")) {
    const urlData = deepLinkPath.split("/");
    const onbj: any = JSON.parse(atob(urlData[4]));
    let patientData: any;
    let menuActions: any;
    if (!onbj.hasOwnProperty("isAcuteOrder")) {
      onbj.isAcuteOrder = "false";
    }
    try {
      const getPatientAPIResponse = await getPatient(
        onbj.ron,
        onbj.dob,
        onbj.siteUseId,
        onbj.isAcuteOrder.toLowerCase() === "true",
        onbj?.orderType.toLowerCase() === "customerplus"
      );
      if (
        getPatientAPIResponse.succeeded &&
        getPatientAPIResponse.data !== null
      ) {
        patientData = getPatientAPIResponse.data;
        patientData.isDeepLink = true;
        patientData.type = patientData.isAcuteOrder
          ? onbj?.orderType
          : patientData.type!;
        patientData.isCustomerPlus =
          onbj?.orderType.toLowerCase() === "customerplus" ?? false;
        menuActions = await getPatientMenuAction(
          patientData.isAcuteOrder
            ? getOrderType(onbj?.orderType)
            : patientData.type!,
          patientData.isFromAllFacility,
          onbj.ron,
          onbj.dob,
          onbj.isAcuteOrder.toLowerCase() === "true",
          onbj.siteUseId
        );
        patientData.color = menuActions.data.color;
        patientData.siteUseId = onbj.siteUseId;
        patientData.statusColor = menuActions.data.color + "Circle";
        patientData.menuActions = menuActions.data.menuActions;
        patientData.isAcuteOrder = onbj.isAcuteOrder.toLowerCase() === "true";
        if (onbj.tabname.toLowerCase() === "orders") {
          orderDetailObj?.resetData();
          orderDetailObj?.resetSeletedSupplyOrderData();
          orderDetailObj?.resetWoundData();
          orderDetailObj?.setSelectedOrderTab("Orders");
          return {
            pathname: "/home/orderOverview",
            state: {
              stateData: patientData,
            },
          };
        } else if (onbj.tabname === "Woundprogress") {
          orderDetailObj?.setSelectedOrderTab(
            OrderOverViewTabsTitle.WOUND_PROGRESS_TAB_TITLE
          );
          orderDetailObj?.setWoundId(onbj.woundId);
          return {
            pathname: "/home/orderOverview",
            state: {
              stateData: patientData,
            },
          };
        } else if (onbj.tabname === "Documents") {
          orderDetailObj?.setSelectedOrderTab(
            OrderOverViewTabsTitle.DOCUMENTS_TAB_TITLE
          );
          return {
            pathname: "/home/orderOverview",
            state: {
              stateData: patientData,
            },
          };
        } else {
          return "/home";
        }
      } else {
        if (isFromSSO) {
          return "/ssoRedirect";
        } else return "/home";
      }
    } catch (error) {
      if (isFromSSO) {
        return "/ssoRedirect";
      } else return "/home";
    }
  } else if (deepLinkPath.includes("/administration/manageUsers/deepLink")) {
    try {
      const urlData = deepLinkPath.split("/");
      const siteUseId = atob(urlData[4]);
      return {
        pathname: "/administration/manageUsers",
        state: {
          stateData: siteUseId,
        },
      };
    } catch (error) {
      return "/home";
    }
  } else if (deepLinkPath.includes("/orders/newOrder/deepLink")) {
    const urlData = deepLinkPath.split("/");
    const decodeData = JSON.parse(atob(urlData[4]));
    let OrderID_siteUseId = {
      orderID: decodeData.orderId,
      siteUseId: decodeData.siteUseId,
    };
    return {
      pathname: "/orders/newOrder",
      state: {
        stateData: OrderID_siteUseId,
      },
    };
  } else if (deepLinkPath.includes("/orders/supplyOrderDetail/deepLink")) {
    const urlData = deepLinkPath.split("/");
    const onbj = JSON.parse(atob(urlData[4]));
    const patientData = {
      roNumber: onbj.ron,
      siteUseID: onbj.siteUseId,
      ropNumber: onbj.ropn,
    };

    if (onbj.orderType.toLowerCase() === "stocksupply") {
      return {
        pathname: "/orders/supplyOrderDetail",
        state: {
          stateData: patientData,
        },
      };
    } else {
      if (isFromSSO) {
        return "/ssoRedirect";
      } else {
        return "/home";
      }
    }
  } else if (
    deepLinkPath.includes("/administration/manageUsers/userProfile/deepLink")
  ) {
    try {
      const urlData = deepLinkPath.split("/");
      const onbj: any = JSON.parse(atob(urlData[5]));
      return {
        pathname: "/administration/manageUsers/userProfile",
        state: {
          isAddingNewUser: false,
          selectedUserName: onbj.SelectedUserName,
          SiteUseId: onbj.SiteUseId,
        },
      };
    } catch (error) {
      return "/home";
    }
  } else if (deepLinkPath.includes("/home/exchangeVacUnit/deepLink")) {
    const urlData = deepLinkPath.split("/");
    const obj: any = JSON.parse(atob(urlData[4]));
    let patientDetails: any;
    let menuActions: any;
    if (!obj.hasOwnProperty("isAcuteOrder")) {
      obj.isAcuteOrder = "false";
    }
    try {
      const patientDataResult = await getPatient(
        obj.ron,
        obj.dob,
        obj.siteUseId,
        obj.isAcuteOrder.toLowerCase() === "true"
      );
      if (patientDataResult.succeeded && patientDataResult.data !== null) {
        patientDetails = patientDataResult.data;
        patientDetails.isDeepLink = true;
        patientDetails.type = patientDetails.isAcuteOrder
          ? obj?.orderType
          : patientDetails.type!;
        menuActions = await getPatientMenuAction(
          patientDetails.isAcuteOrder ? obj?.orderType : patientDetails.type!,
          patientDetails.isFromAllFacility,
          obj.ron,
          obj.dob,
          obj.isAcuteOrder.toLowerCase() === "true"
        );
        patientDetails.color = menuActions.data.color;
        patientDetails.statusColor = menuActions.data.color + "Circle";
        patientDetails.menuActions = menuActions.data.menuActions;
        patientDetails.siteUseId = obj.siteUseId;
        return {
          pathname: "/home/exchangeVacUnit",
          state: {
            stateData: patientDetails,
          },
        };
      } else {
        if (isFromSSO) {
          return "/ssoRedirect";
        } else return "/home";
      }
    } catch (error) {
      if (isFromSSO) {
        return "/ssoRedirect";
      } else return "/home";
    }
  } else {
    return deepLinkPath;
  }
};
export const getTherapyStartDateWithOrderDetailsContext = async (
  ron: number,
  dob: string,
  userName: string,
  siteUseId: string,
  orderDetailObj: OrderDetailContextType
) => {
  if (userName && userName !== "" && siteUseId && siteUseId !== "") {
    const reqParam = {
      RentalOrderNumber: ron.toString(),
      DOB: dob,
      userName: userName,
      siteUseId: siteUseId,
    };
    try {
      const result = await getOrderStatusDetails(reqParam);
      if (result.succeeded) {
        const dateStr = result.item.therapyDate;
        const date = moment(dateStr, "YYYY-MM-DDTHH:mm:ss").toDate();
        orderDetailObj?.setSelectedSupplyTherapyStartDate(date.toString()!);
        return true;
      }
    } catch {
      return false;
    }
  }
};
