import moment from "moment";
import {
  INTERNAL_SALES_MGR_ROLE_TEXT,
  INTERNAL_SALES_ROLE_TEXT,
} from "../util/staticText";
import {
  allFacilitySearch,
  caregiverFacilitySearch,
  checkPostAcuteFacility,
  homeCareOrderSearch,
} from "../util/3meService";
import { AuthContextType } from "../context/AuthContext";
import { checkDeeplinkPath } from "./deeplinkHandler";
import { ICheckPostAcuteFacility } from "../components/manageProfile/facilityInformation/facilityFound/facilityFound.interface";
import { IFacility } from "../components/manageProfile/facilityInformation/facility.interface";
import { selectFacility } from "../util/userService";
import { OrderDetailContextType } from "../context/OrderDetailsContext";
import { RolesPermissionContextType } from "../context/RolesPermissionContext";
import { mapUserRolesAndPermissionData } from "../RolesPermission/RolesPermission.Mapper";
import { LocationDescriptor } from "history";
import { showVacOrderMenuOption } from "../util/utilityFunctions";

export interface IDeepLinkSSOProps {
  group: string;
  deeplinkHandlerPath: string;
  authObj: AuthContextType;
  orderOverviewObj: OrderDetailContextType;
  permissionsObj: RolesPermissionContextType;
}

let getDataForDeepLinkHandler = async (
  deeplinkHandlerPath: string,
  authObj: AuthContextType,
  orderOverviewObj: OrderDetailContextType,
  permissionsObj: RolesPermissionContextType
) => {
  const deepLinkData = deeplinkHandlerPath?.split("/");
  const onbj: any = JSON.parse(atob(deepLinkData[4]));
  authObj?.setUpdateFacilityBannerFacility(true);

  const facilityReqBody = {
    customerName: "",
    customerNumber: "",
    state: "",
    siteUseId: onbj.siteUseId,
  };

  const getAllFacilityResponse = await allFacilitySearch(facilityReqBody);

  if (getAllFacilityResponse && getAllFacilityResponse.succeeded) {
    let facilityList = getAllFacilityResponse.items;
    let facilityResponse = await getSelectedFacility(
      facilityList,
      onbj.siteUseId,
      authObj
    );
    if (facilityResponse) {
      authObj?.setregisteredFaciltyAddress(facilityResponse);
      const rolesAndPermissionsReqBody = {
        UserName: authObj?.userProfile?.userName,
        SiteUseId: onbj.siteUseId,
      };
      const rolesAndPermissionsResponse = await selectFacility(
        rolesAndPermissionsReqBody
      );
      if (rolesAndPermissionsResponse) {
        const mappedAoleAndPermissionReponse =
          await mapUserRolesAndPermissionData(
            rolesAndPermissionsResponse,
            facilityResponse.readyCareFlag
          );
        permissionsObj?.setMappedRolesPermissionData(
          mappedAoleAndPermissionReponse
        );
        let adminText: any = "Administration";
        if (authObj && authObj.unLinkedFacilitesCount) {
          adminText = (
            <div className="sideNavmadminBtnMain">
              Administration
              <span className="sideNavmadminBtnStyle">
                {authObj.unLinkedFacilitesCount}{" "}
              </span>{" "}
            </div>
          );
        }
        authObj?.setsideNavMenuOptionModelData({
          ...authObj?.sideNavMenuOptionModelData,
          orders: {
            labelText: "Start New Order",
            isVisible: showVacOrderMenuOption(mappedAoleAndPermissionReponse),
          },
          inventory: {
            labelText: "Inventory",
            isVisible: mappedAoleAndPermissionReponse.IsShowInventoryOption,
          },
          administration: {
            labelText: adminText,
            isVisible: mappedAoleAndPermissionReponse.IsShowAdminstrationOption,
          },
        });
        authObj?.setuserRolesAndPermissionLoaded(true);
        authObj?.setIsInternalUserFacilitySelected(true);
        return await checkDeeplinkPath(
          deeplinkHandlerPath,
          orderOverviewObj!,
          true
        );
      } else {
        return "/ssoRedirect";
      }
    } else {
      return "/ssoRedirect";
    }
  } else {
    return "/ssoRedirect";
  }
};

const getSelectedFacility = async (
  facilityList: IFacility[],
  siteUseId: string,
  authObj: AuthContextType
): Promise<IFacility | null> => {
  authObj?.setAllFacilties(facilityList);
  let tempFacilityObj: any;
  let selectedFacility: IFacility;

  if (facilityList.length === 1) {
    tempFacilityObj = facilityList[0];
  } else if (facilityList.length > 1) {
    const filteredFacilities = facilityList.filter(
      (facility: IFacility) =>
        facility.siteUseId?.toString() === siteUseId?.toString()
    );
    tempFacilityObj = filteredFacilities[0];
  }
  if (tempFacilityObj) {
    selectedFacility = {
      ...tempFacilityObj,
      isPostAcute: await checkPostAcuteFacilityFound(tempFacilityObj.typeCode),
      careGiverId: await getCareGiverValue(tempFacilityObj),
    };
    return selectedFacility;
  } else {
    return null;
  }
};

const checkPostAcuteFacilityFound = async (
  typeCode: string
): Promise<boolean> => {
  const reqBody = { FacilityTypeCode: typeCode };
  const response: ICheckPostAcuteFacility = await checkPostAcuteFacility(
    reqBody
  );
  return response.postAcuteFound;
};

const getCareGiverValue = async (facility: IFacility): Promise<string> => {
  let caregiverIdValue: string = "";
  const isCareGiver = facility.siteUseCode?.includes("CG");
  if (isCareGiver) {
    const careGiverIdRes = await caregiverFacilitySearch({
      customerAccountNumber: facility.accountNumber,
    });
    caregiverIdValue = careGiverIdRes.items[0].origSystemReference;
  } else caregiverIdValue = "";
  return caregiverIdValue;
};

export const deeplinkHandlerForSSO = async ({
  group,
  deeplinkHandlerPath,
  authObj,
  orderOverviewObj,
  permissionsObj,
}: IDeepLinkSSOProps): Promise<LocationDescriptor<unknown>> => {
  if (
    deeplinkHandlerPath &&
    (deeplinkHandlerPath.includes("/home/orderOverview/deepLink") ||
      deeplinkHandlerPath.includes("/home/exchangeVacUnit/deepLink") ||
      deeplinkHandlerPath.includes("/orders/supplyOrderDetail/deepLink"))
  ) {
    const urlData = deeplinkHandlerPath?.split("/");
    const onbj: any = JSON.parse(atob(urlData[4]));
    if (!onbj.hasOwnProperty("isAcuteOrder")) {
      onbj.isAcuteOrder = "false";
    }
    const reqBody = {
      dob: moment(onbj.dob).format("MM/DD/yyyy"),
      lastName: "",
      ron: onbj.ron,
      zipCode: "",
    };
    if (onbj?.isAcuteOrder !== "true") {
      let homeOrderResponse = await homeCareOrderSearch(reqBody);
      let hcpAccess =
        homeOrderResponse.item.requestor.siteUseID === onbj.siteUseId &&
        homeOrderResponse.item.requestor.address.facilityAccess;
      let requestorAccess =
        homeOrderResponse.item.hcp.siteUseID === onbj.siteUseId &&
        homeOrderResponse.item.hcp.address.facilityAccess;

      let salesRolePermissions =
        group.toLowerCase() === INTERNAL_SALES_MGR_ROLE_TEXT ||
        group.toLowerCase() === INTERNAL_SALES_ROLE_TEXT;
      if (salesRolePermissions && group !== "") {
        if (hcpAccess || requestorAccess) {
          return getDataForDeepLinkHandler(
            deeplinkHandlerPath,
            authObj,
            orderOverviewObj,
            permissionsObj
          );
        } else {
          return "/ssoRedirect";
        }
      } else {
        return getDataForDeepLinkHandler(
          deeplinkHandlerPath,
          authObj,
          orderOverviewObj,
          permissionsObj
        );
      }
    } else {
      return getDataForDeepLinkHandler(
        deeplinkHandlerPath,
        authObj,
        orderOverviewObj,
        permissionsObj
      );
    }
  } else {
    return "/ssoRedirect";
  }
};
