import "./salesPurchaseOrderNumber.css";
import { Grid, InputBase, useMediaQuery } from "@mui/material";
import { InputWithLabel } from "../../../../core/inputWithLabel/inputWithLabel.component";
import { ValidationStatus } from "../../../../core/interfaces/input.interface";

import {
  BILLING_INFO_POREQUIRED_FALSE,
  BILLING_INFO_POREQUIRED_TRUE,
} from "../../../../util/staticText";
import { IInpatientSupplyOrder } from "../../inpatientSupplyOrder.interface";
import { InpatientSupplyOrderValidator } from "../../inpatientSupplyOrder.validator";
import { useState } from "react";

export interface ISalesPurchaseOrderNumber {
  data: IInpatientSupplyOrder;
  setData: any;
  Validator?: InpatientSupplyOrderValidator;
}
export const SalesPurchaseOrderNumber = ({
  data,
  setData,
  Validator = new InpatientSupplyOrderValidator(),
}: ISalesPurchaseOrderNumber) => {
  const [validator] = useState<InpatientSupplyOrderValidator>(Validator!);
  const isMobileScreen = useMediaQuery("(max-width:600px)");
  const salesPurchaseInformation = data.salesPurchaseOrderInformation!;

  const billingInfoDescription = () => {
    if (salesPurchaseInformation.isPoRequired === true) {
      return BILLING_INFO_POREQUIRED_TRUE;
    } else if (salesPurchaseInformation.isPoRequired === false) {
      return BILLING_INFO_POREQUIRED_FALSE;
    } else return "";
  };

  const validateAndSetData = (e: any) => {
    const { name, value } = e.target;
    const updatedValue = value.toUpperCase();
    const isValid = validator.validate(updatedValue, name);
    setData((dt: IInpatientSupplyOrder) => ({
      ...dt,
      salesPurchaseOrderInformation: {
        ...dt.salesPurchaseOrderInformation,
        [name]: {
          ...Object(dt.salesPurchaseOrderInformation)[name],
          errorMessage: null,
          valid:
            (!data.salesPurchaseOrderInformation?.salesPONumber.required &&
              updatedValue === "") ||
            isValid!.status !== ValidationStatus.INVALID
              ? ValidationStatus.VALID
              : isValid!.status,
          value: updatedValue,
        },
      },
    }));
  };

  return (
    <div
      className="in-patient-order-salespurchase-information-component"
      data-testid="in-patient-order-salespurchase-information-component"
    >
      <p
        className="in-patient-order-salespurchase-information-title"
        data-testid="in-patient-order-salespurchase-information-title"
        id="in-patient-order-salespurchase-information-title"
      >
        Sales Purchase Order Number
      </p>
      <div className="salespurchaseInfoDiv">
        <p
          className="salespurchaseInfoDesc"
          data-testid="salespurchase-informantion-desc"
        >
          {billingInfoDescription()}
        </p>
      </div>
      <Grid
        className="in-patient-order-salespurchase-information-grid-container"
        container
      >
        <Grid
          className="in-patient-order-salespurchase-information-grid-item"
          item
          xs={isMobileScreen ? 12 : 6}
        >
          <InputWithLabel
            error={
              salesPurchaseInformation?.salesPONumber?.valid! ===
              ValidationStatus.INVALID
            }
            isRequired={salesPurchaseInformation.salesPONumber.required!}
            id={salesPurchaseInformation.salesPONumber.componentId!}
            isShowWarning={true}
            label={salesPurchaseInformation.salesPONumber.title!}
            labelClassName="salespurchase-information-title"
            testId={salesPurchaseInformation.salesPONumber.componentId!}
            warningMessage={salesPurchaseInformation.salesPONumber.errorMessage}
          >
            <InputBase
              className="salespurchase-information-input"
              data-testid={salesPurchaseInformation.salesPONumber.id!}
              id={salesPurchaseInformation.salesPONumber.id!}
              name="salesPONumber"
              onChange={validateAndSetData}
              value={salesPurchaseInformation.salesPONumber.value}
              inputProps={{
                maxLength: 30,
              }}
            />
          </InputWithLabel>
        </Grid>
      </Grid>
    </div>
  );
};
