import { Grid } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import {
  Validation,
  ValidationStatus,
} from "../../../../core/interfaces/input.interface";
import { LoadingSpinner } from "../../../../core/loader/LoadingSpinner";
import { getDeepClone } from "../../../../util/ObjectFunctions";
import { getInvalidObj } from "../../../../util/utilityFunctions";
import { AddFacilityContext } from "../addFacilityContainer/addFacilityContainer.context";
import { SearchSection } from "../addFacilityContainer/addFacilityContainer.enum";
import "./addFacility.css";
import { IAddFacility, ICareGiverSearch } from "./addFacility.inteface";
import { searchFacility } from "./addFacility.service";
import { SearchByID } from "./searchByID/searchByID.component";
import { SearchByName } from "./searchByName/searchByName.component";
import {
  IFacilitySearchRequest,
  ISearchFacilityByID,
  ISearchFacilityByName,
} from "./searchFacility.interface";
import {
  defaultSearchDataForID,
  defaultSearchDataForName,
} from "./searchFacility.model";
import { UnlinkedUsersRequestedFacility } from "../../../administration/manageUserAccounts/UnlinkedUsers/UnlinkedUsersRequestedFacility/unlinkedUsersRequestedFacility.component";
import NewOrderErrorPopupMessage from "../../../newOrder/newOrderFooterGroup/newOrderErrorPopupMessage.component";
import { ALL_FACILITY_SEARCH_SERVICE_ERROR } from "../../../../util/errorMsg";
import {
  AdminMyListsContext,
  AdminMyListsContextType,
} from "../../../../context/AdministrationMyListsContext";
import { caregiverFacilitySearch } from "../../../../util/3meService";

export const AddFacility = ({
  redirectHandler,
  addNewFacility,
  isForAdminFlow = false,
  isUnlinkedUserFlow = false,
  requestedFacility,
}: IAddFacility) => {
  const { facilitySearchValidator } = useContext(AddFacilityContext);
  const [facilitySearchForName, setFacilitySearchForName] =
    useState<ISearchFacilityByName>(getDeepClone(defaultSearchDataForName));
  const [facilitySearchForID, setFacilitySearchForID] =
    useState<ISearchFacilityByID>(getDeepClone(defaultSearchDataForID));
  const [isFormValid, setIsFormValid] = useState<ValidationStatus>(
    ValidationStatus.INVALID
  );
  const [isLoading, setIsLoading] = useState(false);
  const [showerrorpopup, setShowerrorpopup] = useState(false);
  const [errorCode, setErrorCode] = useState("");

  const validate = () => {
    let isValid: Validation = getInvalidObj(null);
    if (facilitySearchForID.search.value === "true") {
      isValid =
        facilitySearchValidator.validateAllSearchFields(facilitySearchForID);
    } else if (facilitySearchForName.search.value === "true") {
      isValid = facilitySearchValidator.validateAllSearchFields(
        facilitySearchForName
      );
    }
    setIsFormValid(isValid.status);
  };
  const myListObj = useContext<AdminMyListsContextType | null>(
    AdminMyListsContext
  );
  const handleSubmit = async () => {
    setIsLoading(true);
    if (myListObj && myListObj.isFromMyListHcp) {
      const requestBody: ICareGiverSearch = {
        customerAccountNumber: facilitySearchForID.facilityID.value,
        customerName: facilitySearchForName.facilityName.value,
        state: facilitySearchForName.facilityState.value,
      };
      const response = await caregiverFacilitySearch(requestBody);
      if (response && response.succeeded) {
        const updatedArray = response.items.map((eachHcpData: any) => {
          return {
            careGiverId: eachHcpData.origSystemReference,
            accountNumber: eachHcpData.customerAccountNumber,
            accountName: eachHcpData.customerName,
            address1: eachHcpData.address1,
            address2: eachHcpData.address2 ?? "",
            city: eachHcpData.city,
            state: eachHcpData.state,
            zip: parseInt(eachHcpData.postalCode ?? 0),
            classificationCode: eachHcpData.typeCode,
            typeName: eachHcpData.marketingSegmentDescription,
            phoneNo: eachHcpData.phoneNo ?? "",
          };
        });
        redirectHandler(SearchSection.MY_LIST_HCP_FOUND, updatedArray);
      } else {
        redirectHandler(SearchSection.NOT_FOUND);
      }
    } else {
      var facilitySearchRequest: IFacilitySearchRequest = {
        customerNumber: facilitySearchForID.facilityID.value,
        customerName: facilitySearchForName.facilityName.value,
        state: facilitySearchForName.facilityState.value,
      };
      searchFacility(facilitySearchRequest)
        .then((value) => {
          if (value.length > 0) {
            setIsLoading(false);
            if (isForAdminFlow) addNewFacility(value);
            else redirectHandler(SearchSection.FOUND, value);
          } else {
            setIsLoading(false);
            redirectHandler(SearchSection.NOT_FOUND);
          }
        })
        .catch((reason) => {
          setIsLoading(false);
          setErrorCode(reason?.errorCode);
          setShowerrorpopup(true);
        });
    }
  };

  useEffect(() => {
    validate();
  }, [facilitySearchForName, facilitySearchForID]);

  const closeErrorPopup = () => {
    redirectHandler(SearchSection.SEARCH_FORM);
    setShowerrorpopup(false);
  };
  return (
    <>
      {showerrorpopup && (
        <NewOrderErrorPopupMessage
          popUpStyles="allFacilityErrorPopup"
          handleBackButton={closeErrorPopup}
          errorPopupFlag={true}
          errorMessage={ALL_FACILITY_SEARCH_SERVICE_ERROR}
          handleExitButton={closeErrorPopup}
          isSupportPhoneShow={true}
          errorCode={errorCode}
        />
      )}
      <Grid className="search-facility-container">
        <div className="search-facility-title" data-testid="facility-search">
          {myListObj?.isFromMyListHcp
            ? "Home Care Provider Search"
            : "Facility Search"}
        </div>
        {myListObj?.isFromMyListHcp && (
          <div className="myList-hcp-title2">Search Facilities Database</div>
        )}
        {isUnlinkedUserFlow && (
          <UnlinkedUsersRequestedFacility
            requestedFacility={requestedFacility!}
          />
        )}
        {isLoading ? (
          <div className="search-facility-loader">
            <LoadingSpinner />
          </div>
        ) : (
          <div>
            <SearchByName
              facilitySearchDataForID={facilitySearchForID}
              facilitySearchDataForName={facilitySearchForName}
              setfacilitySearchDataForID={setFacilitySearchForID}
              setfacilitySearchDataForName={setFacilitySearchForName}
              onSubmit={handleSubmit}
            />
            <div className="name-id-separator">OR</div>
            <SearchByID
              facilitySearchDataForID={facilitySearchForID}
              facilitySearchDataForName={facilitySearchForName}
              setfacilitySearchDataForID={setFacilitySearchForID}
              setfacilitySearchDataForName={setFacilitySearchForName}
              onSubmit={handleSubmit}
            />
          </div>
        )}
      </Grid>
    </>
  );
};
