import "./unlinkedUsers.css";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getUnlinkedUsers, linkToFacility } from "../../../../util/userService";
import { IUnlinkedUser } from "../manageUserAccounts.interface";
import {
  ERROR_GET_UNLINKED_USERS,
  ERROR_LINK_TO_FACILITY,
} from "../../../../util/errorMsg";
import ErrorPopup from "../../../../core/errorPopup/errorPopup.component";
import { UnlinkedUsersTable } from "./UnlinkedUsersTable/unlinkedUsersTable.component";
import { Popup } from "../../../../core/popup/popup.component";
import { AddFacilityContainer } from "../../../manageProfile/facilityInformation/addFacilityContainer/addFacilityContainer.component";
import { AddFacilityContext } from "../../../manageProfile/facilityInformation/addFacilityContainer/addFacilityContainer.context";
import { IFacility } from "../../../manageProfile/facilityInformation/facility.interface";
import { IFacilityToUser } from "../../manageUsers/userProfile/addFacility/addFacilityToUser.interface";
import { Validator } from "../../../../util/order.validations";
import { UnlinkedUserLinkedSuccess } from "./UnlinkedUserLinkedSuccess/unlinkedUserLinkedSuccess.component";
import { makeCapitalEachWordInString } from "../../../../util/utilityFunctions";
import { AuthContextType, AuthContext } from "../../../../context/AuthContext";
import { LoadingSpinner } from "../../../../core/loader/LoadingSpinner";
import { ISideNav } from "../../../sideNav/sideNavMenuOption.interface";

type Props = {
  openSelectedUserProfile: Function;
};
export const UnlinkedUsers = ({ openSelectedUserProfile }: Props) => {
  const history = useHistory();
  const [error, setError] = useState<boolean>(false);
  const [errorCode, setErrorCode] = useState<string>("");
  const [isShortSentence, setIsShortSentence] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openLoaderPopUp, setOpenLoaderPopUp] = useState<boolean>(false);
  const [unlinkedUsers, setUnlinkedUsers] = useState<IUnlinkedUser[] | null>(
    null
  );
  const [isUnlinkedUserFlow, setIsUnlinkedUserFlow] = useState<boolean>(false);
  const [selectedUserForLinkFacility, setSelectedUserForLinkFacility] =
    useState<IUnlinkedUser | null>(null);
  const [selectedFacility, setSelectedFacility] = useState<IFacilityToUser>();
  const [facilitySearchopen, setFacilitySearchopen] = useState<boolean>(false);
  const [phone, setPhone] = useState<any | null>(null);
  const authObj = useContext<AuthContextType | null>(AuthContext);

  const fetchUnlinkedUsers = async () => {
    setIsLoading(true);
    const response = await getUnlinkedUsers();
    if (response && response.succeeded) {
      setUnlinkedUsers(response.data);
      if (
        authObj &&
        authObj.unLinkedFacilitesCount !== response.data.length.toString()
      ) {
        authObj.setUnLinkedFacilityCount(response.data.length.toString());
        const adminText: any = (
          <div className="sideNavmadminBtnMain">
            Administration
            <span className="sideNavmadminBtnStyle">
              {` ${response.data.length} `}
            </span>
          </div>
        );
        const updateSideNavMenuOption: ISideNav = {
          ...authObj.sideNavMenuOptionModelData!,
          administration: {
            labelText: adminText,
            isVisible:
              authObj.sideNavMenuOptionModelData?.administration?.isVisible!,
          },
        };
        authObj.setsideNavMenuOptionModelData(updateSideNavMenuOption);
      }
      setIsLoading(false);
    } else {
      setIsLoading(false);
      setError(true);
      setErrorCode(
        response?.error?.code || response?.status || response?.error?.errorCode
      );
      setErrorMsg(ERROR_GET_UNLINKED_USERS);
      setPhone("(800) 275-4524");
      setIsShortSentence(false);
    }
  };

  useEffect(() => {
    fetchUnlinkedUsers();
  }, []);

  const openFacilityPopUp = (data: any) => {
    setSelectedUserForLinkFacility(data);
    setIsUnlinkedUserFlow(true);
    setFacilitySearchopen(true);
  };

  const addFacilitiesFromSearch = (facility: any) => {
    setFacilitySearchopen(false);
    setOpenLoaderPopUp(true);
    const tempfacilityMap: IFacilityToUser = {
      activityStauts: 2,
      address1: facility.address1,
      address2: facility.address2,
      city: facility.city,
      facilityAddressID: facility.facilityAddressID ?? "",
      facilityName: facility.accountName,
      isSelected: false,
      isOriginalSelected: false,
      number: facility.accountNumber,
      siteUseId: facility.siteUseId?.toString() ?? "",
      state: facility.state,
      zipCode: facility.zip.toString(),
      careGiverId:
        facility.careGiverId === null ? "" : facility.careGiverId.toString(),
      typeCode: facility.typeCode.toString(),
    };
    setSelectedFacility(tempfacilityMap);
    updateLinkFacilityDetails(tempfacilityMap);
  };

  const updateLinkFacilityDetails = async (
    tempfacilityMap: IFacilityToUser
  ) => {
    setOpenLoaderPopUp(true);
    const reqParam = {
      LoggedInUserEmail: authObj?.userProfile?.userName!,
      UserEmail: selectedUserForLinkFacility?.email!,
      ManualFacilityAddressId:
        selectedUserForLinkFacility?.facilityAddressId.toString()!,
      FacilitySiteUseId: tempfacilityMap?.siteUseId!,
      FacilityNumber: tempfacilityMap?.number?.toString()!,
      FacilityName: tempfacilityMap?.facilityName!,
      CareGiverID: tempfacilityMap?.careGiverId!,
      FacilityTypeCode: tempfacilityMap?.typeCode!,
      FacilityAddress: {
        AddressLine1: tempfacilityMap?.address1!,
        AddressLine2: tempfacilityMap?.address2!,
        City: tempfacilityMap?.city!,
        State: tempfacilityMap?.state!,
        ZipCode: tempfacilityMap?.zipCode!,
      },
    };
    const response = await linkToFacility(reqParam);
    if (response && response.succeeded) {
      setSuccess(true);
      setOpenLoaderPopUp(false);
      fetchUnlinkedUsers();
    } else {
      setError(true);
      setErrorCode(
        response?.error?.code || response?.status || response?.error?.errorCode
      );
      setErrorMsg(ERROR_LINK_TO_FACILITY);
      setPhone("(800) 275-4524 ext. 41858");
      setIsShortSentence(true);
      setOpenLoaderPopUp(false);
    }
  };

  return (
    <div>
      <div className="unlinked-users-main-component">
        <div
          className="unlinked-users-header"
          data-testid="unlinked-users-header"
        >
          <p
            className="unlinked-users-header-title"
            data-testid="unlinked-users-header-title"
          >
            Users requesting to join facilities
          </p>
          <p
            className="unlinked-users-header-description"
            data-testid="unlinked-users-header-description"
          >
            The following users have requested to be associated with the
            facilities indicated. Confirm the connection by choosing “Link to
            facility” next to their name. Choose their username to view more
            information and change their settings.
          </p>
        </div>
        <UnlinkedUsersTable
          openFacilityPopUp={openFacilityPopUp}
          openSelectedUserProfile={openSelectedUserProfile}
          setUnlinkedUsers={setUnlinkedUsers}
          skeletonLoading={isLoading}
          unlinkedUsers={unlinkedUsers ?? []}
        />
      </div>
      <AddFacilityContext.Provider
        value={{
          closePopup: () => setFacilitySearchopen(false),
          facilitySearchValidator: new Validator(),
          addFacilityToList: addFacilitiesFromSearch,
        }}
      >
        <Popup
          dialogParentClass="unlinked-requested-add-facility-popup"
          openFlag={facilitySearchopen}
          closeHandler={() => setFacilitySearchopen(false)}
        >
          <AddFacilityContainer
            requestedFacility={selectedUserForLinkFacility!}
            isUnlinkedUserFlow={isUnlinkedUserFlow}
          />
        </Popup>
      </AddFacilityContext.Provider>
      <Popup
        dialogParentClass="unlinked-requested-add-facility-popup"
        openFlag={success}
        closeHandler={() => setSuccess(false)}
      >
        <UnlinkedUserLinkedSuccess
          buttonAction={() => setSuccess(false)}
          buttonTitle={"Close"}
          description={
            "The user <b>" +
            makeCapitalEachWordInString(
              selectedUserForLinkFacility?.firstName!
            ) +
            " " +
            makeCapitalEachWordInString(
              selectedUserForLinkFacility?.lastName!
            ) +
            "</b> has been associated with the following facility."
          }
          title={"Success!"}
          requestedFacility={selectedFacility!}
        />
      </Popup>
      <ErrorPopup
        popUpStyles="error-popup-design"
        errorMessage={errorMsg}
        errorCode={errorCode}
        errorPopupFlag={error}
        handleBackButton={() => {
          setError(false);
          if (errorMsg === ERROR_GET_UNLINKED_USERS)
            history.push("/administration");
        }}
        buttontitle="Done"
        showTitle={false}
        isShortSentence={isShortSentence}
        phone={phone}
      />
      <Popup
        openFlag={openLoaderPopUp}
        closeHandler={() => setOpenLoaderPopUp(false)}
        dialogParentClass={"update-facility-type-loader-pop-up"}
        data-testid="loader-pop-up"
        hideCloseButton={true}
      >
        <div className="update-facility-type-loader">
          <LoadingSpinner />
        </div>
      </Popup>
    </div>
  );
};
