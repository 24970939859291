import { InputBase } from "@mui/material";
import { InputWithLabel } from "../../../../../../core/inputWithLabel/inputWithLabel.component";
import "./addLocations.css";
import { ExpressButton } from "../../../../../../core/expressButton/expressButton.component";
import { useContext, useState } from "react";
import {
  ValidationStatus,
  IInputField,
} from "../../../../../../core/interfaces/input.interface";
import { myListStorageLocation } from "../../myListStorageLocations.model";
import {
  getSiteUseId,
  locationNameValidation,
} from "../../../../../../util/utilityFunctions";
import {
  AuthContext,
  AuthContextType,
} from "../../../../../../context/AuthContext";
import { addStorageLocation } from "../../../../../../util/inventoryMgrService";
import ErrorPopup from "../../../../../../core/errorPopup/errorPopup.component";
import { MyListStorageLocationPopUps } from "../myListAddUpdateConatiner.enum";
import {
  ERROR_MSG_DUPLICATE_STORAGE_LOCATIONS,
  STORAGE_LOCATION_ADD_LOC_SERVICE_ERROR,
} from "../../../../../../util/errorMsg";
import {
  IAddLocationReqBody,
  IAddStorageLocations,
} from "./addStorageLocations.interface";

export const AddStorageLocation = ({
  redirectHandler,
  setOpenpopup,
  getFacilityStorageLocationAPICall,
  customLocationsCount,
  setShowSpinner,
  setSearchInput,
  setSelectedStatus,
}: IAddStorageLocations) => {
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const [addLocationObj, setAddLocationObj] = useState<IInputField>(
    myListStorageLocation
  );
  const [errorFlag, setErrorFlag] = useState(false);
  const [errorCode, setErrorCode] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [buttonText, setButtonText] = useState<string>("Done");

  const validateAndSetData = (e: any) => {
    const validation = locationNameValidation(e.target.value);

    if (validation.status === ValidationStatus.VALID) {
      setAddLocationObj((p) => ({ ...p, value: e.target.value }));
    }
  };

  const errorPopupProps = {
    isSupportPhoneRequired: false,
    isShortSentence: false,
    phone: "",
  };
  const props = errorCode === 2012 ? errorPopupProps : {};

  const handleAddLocationToDB = async () => {
    setShowSpinner(true);
    if (customLocationsCount <= 10) {
      const reqBody: IAddLocationReqBody = {
        siteUseId: getSiteUseId(authObj),
        locationName: "OTH - " + addLocationObj.value.toUpperCase().trim(),
        userName: authObj?.userProfile?.userName!,
        isCustom: true,
      };
      const addLocationResponse = await addStorageLocation(reqBody);
      if (addLocationResponse && addLocationResponse.succeeded) {
        await getFacilityStorageLocationAPICall();
        setShowSpinner(false);
        setOpenpopup(false);
        setSearchInput("");
        setSelectedStatus("");
      } else {
        setShowSpinner(false);
        let code = addLocationResponse?.error?.errorCode;
        setErrorCode(addLocationResponse?.error?.errorCode);
        if (code === 2012) {
          setErrorMessage(ERROR_MSG_DUPLICATE_STORAGE_LOCATIONS);
          setButtonText("Back");
        } else if (code === 2010) {
          redirectHandler(
            MyListStorageLocationPopUps.RESTRICTING_ADD_LOCATION_POPUP_MESSAGE
          );
        } else {
          setErrorMessage(STORAGE_LOCATION_ADD_LOC_SERVICE_ERROR);
        }
        setErrorFlag(true);
        setErrorCode(
          addLocationResponse?.error?.errorCode || addLocationResponse?.status
        );
      }
    }
  };

  return (
    <div className="add-location">
      <div className="add-location-title" data-testid="add-location-title-test">
        Add a new storage location
      </div>
      <div
        className="add-location-title2"
        data-testid="add-location-title2-test"
      >
        Enter the storage location below. Select “Add location” to save the new
        storage location.
      </div>
      <InputWithLabel
        label="Location"
        isRequired={addLocationObj.required}
        error={addLocationObj.valid === ValidationStatus.INVALID}
        testId="add-location-input-label"
      >
        <InputBase
          required={addLocationObj.required}
          className="add-loc-input"
          value={addLocationObj.value}
          onChange={validateAndSetData}
          autoFocus
        />
      </InputWithLabel>
      <div className="add-locations-buttons">
        <ExpressButton
          parentClass="add-location-cancel-btn"
          variant="outlined"
          clickHandler={() => {
            setOpenpopup(false);
          }}
          testId="add-location-cancelBtn"
        >
          Cancel
        </ExpressButton>
        <ExpressButton
          disabled={addLocationObj.value.length === 0}
          parentClass="add-storage-loc-btn"
          variant="contained"
          clickHandler={handleAddLocationToDB}
          testId="add-location-button"
        >
          Add location
        </ExpressButton>
      </div>
      <ErrorPopup
        handleBackButton={() => {
          setErrorFlag(false);
        }}
        errorPopupFlag={errorFlag}
        errorMessage={errorMessage}
        buttontitle={buttonText}
        errorCode={errorCode}
        popUpStyles={"errorPopup"}
        showTitle={false}
        {...props}
      />
    </div>
  );
};
