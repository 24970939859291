import { IDropZoneDocumentSelect } from "../../../../core/customDropZone/dropZoneDocumentSelect.interface";
import { ValidationStatus } from "../../../../core/interfaces/input.interface";
import { IDeliveryInformation } from "../../../newOrder/newOrder.interface";
import { IOrderOverviewProductInfo } from "../orderSummary/orderDetailsProductInformation/orderDetailsProductInformation.interface";
import { ISeletedPatient } from "./orderOverview.interface";

export let defaultOrderOverviewData: ISeletedPatient = {
  dob: {
    valid: ValidationStatus.UNTOUCHED,
    value: "12/12/2023",
    minimumRequired: true,
  },

  firstName: {
    valid: ValidationStatus.UNTOUCHED,
    value: "Pallavi",
    minimumRequired: true,
  },
  lastName: {
    valid: ValidationStatus.UNTOUCHED,
    value: "Nayek",
    minimumRequired: true,
  },
  dropDownMenuOption: [
    "Add Wound Information",
    "Upload Document",
    "Add Configuration",
  ],
};
export let defaultOrderOverviewProductInfo: IOrderOverviewProductInfo = {
  isReadyCare: {
    valid: ValidationStatus.VALID,
    value: "",
    isDefaultValid: true,
  },
  productValue: {
    valid: ValidationStatus.VALID,
    value: "",
    isDefaultValid: true,
  },
  serialNumber: {
    valid: ValidationStatus.VALID,
    value: "",
    isDefaultValid: true,
  },
};
export let defaultOrderUploadDocumentData: IDropZoneDocumentSelect[] = [
  {
    documentType: {
      value: "CN",
      valid: ValidationStatus.UNTOUCHED,
      required: true,
    },
    documentName: "TestFile.png",
    documentBase64: "",
    succeeded: true,
    errorMessage: null,
    isFetchingBase64: false,
  },
  {
    documentType: {
      value: "CN",
      valid: ValidationStatus.UNTOUCHED,
      required: true,
    },
    documentName: "TestErrorFile.doc",
    documentBase64: "",
    succeeded: false,
    errorMessage: null,
    isFetchingBase64: false,
  },
];
export let defaultDeliveryInformationOverview: IDeliveryInformation = {
  // Delivery Information
  deliveryProductNeedByDate: {
    componentId: "delivery-information-product-need-by-date",
    id: "delivery-information-product-need-by-date-input",
    order: 53,
    required: true,
    title: "Product Need By Date",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  deliveryProductNeedByTime: {
    componentId: "delivery-information-product-need-by-time",
    order: 54,
    required: true,
    title: "Need by Time",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  deliverySiteType: {
    componentId: "delivery-information-address",
    valid: ValidationStatus.VALID,
    value: "",
  },
  deliveryFacilityName: {
    componentId: "delivery-information-address",
    order: 55,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  deliveryAddressLine1: {
    componentId: "delivery-information-address",
    order: 55,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  deliveryAddressLine2: {
    componentId: "delivery-information-address",
    order: 55,
    valid: ValidationStatus.VALID,
    value: "",
    isDefaultValid: true,
  },
  deliveryCity: {
    componentId: "delivery-information-address",
    order: 55,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  deliveryState: {
    componentId: "delivery-information-address",
    order: 54,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  deliveryZipCode: {
    componentId: "delivery-information-address",
    order: 55,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
};
