import React, { useContext } from "react";
import { Grid, InputBase, useMediaQuery } from "@mui/material";
import { NewOrderValidator } from "../newOrder.validator";
import { CustomCheckBox } from "../../../core/checkBox/checkBox.component";
import { ValidationStatus } from "../../../core/interfaces/input.interface";
import { InputWithLabel } from "../../../core/inputWithLabel/inputWithLabel.component";
import { INewOrderWoundInfo } from "../newOrderWoundInfoStepper/newOrderWoundInfo.interface";
import "./previousTherapies.css";
import { PreviousTherapiesReviewOrder } from "./reviewOrder/previousTherapyReviewOrder.component";
import parse from "html-react-parser";
import {
  NewOrderContext,
  NewOrderContextType,
} from "../../../context/NewOrderContext";
interface Props {
  editButtonClicked?: any;
  isOrderSummary?: boolean;
  isReviewOrder?: boolean;
  setWoundInfoData: Function;
  woundInfoData: INewOrderWoundInfo;
  Validator?: NewOrderValidator;
}

const PreviousTherapy = ({
  isReviewOrder = false,
  editButtonClicked,
  isOrderSummary = false,
  setWoundInfoData,
  woundInfoData,
  Validator = new NewOrderValidator(),
}: Props) => {
  const [validator] = React.useState<NewOrderValidator>(Validator!);
  const NewOrderObj = useContext<NewOrderContextType | null>(NewOrderContext);
  const isMobileScreen = useMediaQuery("(max-width:920px)");

  const validatePreviousTherapy = (e: any) => {
    NewOrderObj?.setIsHandleChangeTriggered(true);

    woundInfoData.previousTherapies.value.map((item: any) => {
      if (item.value === e.target.name) {
        item.selected = e.target.checked;
      }
      if (e.target.name === "Other" && e.target.checked) {
        woundInfoData.previousTherapyOther.required = true;
        woundInfoData.previousTherapyOther.value = "";
        woundInfoData.previousTherapyOther.valid = ValidationStatus.UNTOUCHED;
        woundInfoData.previousTherapyOther.title = "Other";
      } else if (e.target.name === "Other" && !e.target.checked) {
        woundInfoData.previousTherapyOther.required = false;
        woundInfoData.previousTherapyOther.value = "";
        woundInfoData.previousTherapyOther.valid = ValidationStatus.UNTOUCHED;
      }
      if (e.target.name === "None" && e.target.checked) {
        woundInfoData.previousTherapies.value
          .filter((item: any) => item.value !== "None")
          .map((item: any) => {
            item.selected = false;
          });
        woundInfoData.previousTherapyOther.required = false;
      } else {
        woundInfoData.previousTherapies.value
          .filter((item: any) => item.value === "None")
          .map((item: any) => {
            item.selected = false;
          });
      }
    });
    const isValid = validator.validate(
      woundInfoData.previousTherapies.value,
      "previousTherapies"
    );
    if (isValid?.status === ValidationStatus.VALID)
      woundInfoData.previousTherapies.errorMessage = "";

    woundInfoData.previousTherapies.valid = isValid!.status!;
    setWoundInfoData(Object.assign({}, woundInfoData));
  };

  const validatePreviousTherapyCause = (e: any) => {
    NewOrderObj?.setIsHandleChangeTriggered(true);
    woundInfoData.previousTherapiesCauses.value.map((item: any) => {
      woundInfoData.previousTherapiesCausesOther.errorMessage = null;
      if (item.value === e.target.name) {
        item.selected = e.target.checked;
      }
      if (e.target.name === "Other" && e.target.checked) {
        woundInfoData.previousTherapiesCausesOther.required = true;
        woundInfoData.previousTherapiesCausesOther.value = "";
        woundInfoData.previousTherapiesCausesOther.valid =
          ValidationStatus.UNTOUCHED;
        woundInfoData.previousTherapiesCausesOther.title = "Other";
      } else if (e.target.name === "Other" && !e.target.checked) {
        woundInfoData.previousTherapiesCausesOther.required = false;
        woundInfoData.previousTherapiesCausesOther.title = "Other";
        woundInfoData.previousTherapiesCausesOther.value = "";
        woundInfoData.previousTherapiesCausesOther.valid =
          ValidationStatus.UNTOUCHED;
      }
    });
    const isValid = validator.validate(
      woundInfoData.previousTherapiesCauses.value,
      "previousTherapies"
    );
    woundInfoData.previousTherapiesCauses.valid = isValid!.status!;
    setWoundInfoData(Object.assign({}, woundInfoData));
  };

  const validateAndSetData = (e: any) => {
    NewOrderObj?.setIsHandleChangeTriggered(true);
    let value = e.target.value;
    let isValid = validator.validate(e.target.value, e.target.name);
    setWoundInfoData({
      ...woundInfoData,
      [e.target.name]: {
        ...Object(woundInfoData)[e.target.name],
        value: value,
        valid: isValid?.status,
        required: true,
        errorMessage: null,
      },
    });
  };

  return (
    <div className="previous-therapy-main-container">
      {!isReviewOrder && (
        <div className="previous-therapy-component">
          <span className="woundinfo-title">Previous Therapies</span>
          <div
            className={`woundinfo-desp${
              woundInfoData.previousTherapies.valid === ValidationStatus.INVALID
                ? "-error"
                : ""
            }`}
            data-testid="previousTherapyDesc"
            id={woundInfoData.previousTherapies.componentId!}
          >
            Indicate other therapies that have been previously tried and/or
            failed to maintain a moist wound environment.
            <span className="requiredStar">*</span>
          </div>
          <div className="previousTherapy-data">
            <InputWithLabel
              error={
                woundInfoData.previousTherapies.valid ===
                ValidationStatus.INVALID
              }
              isShowWarning={true}
              testId="action-checkboxes"
              warningMessage={woundInfoData.previousTherapies.errorMessage}
              warning_icon_div_custom_class="custom-waring-icon-div"
            >
              <Grid container className="previous-therapies-options-container">
                {woundInfoData.previousTherapies.value.map(
                  (x: any, index: any) => (
                    <Grid item xs={isMobileScreen ? 12 : 6}>
                      <CustomCheckBox
                        key={`pt${index.toString()}`}
                        name={x.value}
                        selectClassName="previousTherapiesCheckbox"
                        selectpropsClassName="previousTherapiesCheckboxRoot"
                        handleChange={validatePreviousTherapy}
                        labelClassName={
                          x.selected
                            ? "chkBoxDescriptionText-active"
                            : "chkBoxDescriptionText"
                        }
                        checked={x.selected}
                        value={x.value}
                        required={
                          woundInfoData.previousTherapyOther.required &&
                          x.value === "Other"
                        }
                        labelText={x.label}
                        testId={x.value}
                      />
                    </Grid>
                  )
                )}
              </Grid>
            </InputWithLabel>
            {woundInfoData.previousTherapyOther.required && (
              <InputWithLabel
                error={
                  woundInfoData.previousTherapyOther.valid ===
                  ValidationStatus.INVALID
                }
                id={woundInfoData.previousTherapyOther.componentId!}
                isShowWarning={true}
                testId="previousTherapyOtherField"
                warningMessage={woundInfoData.previousTherapyOther.errorMessage}
                warning_icon_div_custom_class="previous-thery-warning-error-message-div"
              >
                <InputBase
                  autoFocus={woundInfoData.previousTherapyOther.value === ""}
                  className="previousTherapy-other-input"
                  id={woundInfoData.previousTherapyOther.id!}
                  name="previousTherapyOther"
                  onChange={validateAndSetData}
                  placeholder="Please Describe"
                  value={woundInfoData.previousTherapyOther.value}
                />
              </InputWithLabel>
            )}
          </div>
          <div
            className={`woundinfo-out${
              woundInfoData.previousTherapiesCauses.valid ===
              ValidationStatus.INVALID
                ? "-error"
                : ""
            }`}
            id={woundInfoData.previousTherapiesCauses.componentId!}
          >
            {parse(
              "If other therapies were considered and ruled out, what conditions prevented you from using other therapies prior to applying V.A.C.<sup>®</sup> Therapy "
            )}
            <span className="requiredStar">*</span>
          </div>
          <div className="previousTherapy-cause">
            <InputWithLabel
              error={
                woundInfoData.previousTherapiesCauses.valid ===
                ValidationStatus.INVALID
              }
              isShowWarning={true}
              testId="action-checkboxes"
              warningMessage={
                woundInfoData.previousTherapiesCauses.errorMessage
              }
              warning_icon_div_custom_class="custom-waring-icon-div"
            >
              <Grid container className="previous-therapies-options-container">
                {woundInfoData.previousTherapiesCauses.value.map(
                  (x: any, index: number) => (
                    <Grid item xs={isMobileScreen ? 12 : 6}>
                      <CustomCheckBox
                        name={x.value}
                        selectClassName="previousTherapiesCheckbox"
                        selectpropsClassName="previousTherapiesCheckboxRoot"
                        handleChange={validatePreviousTherapyCause}
                        labelClassName={
                          x.selected
                            ? "chkBoxDescriptionText-active"
                            : "chkBoxDescriptionText"
                        }
                        checked={x.selected}
                        value={x.value}
                        key={`ptc${index.toString()}`}
                        labelText={x.label}
                        testId={x.value}
                        required={
                          woundInfoData.previousTherapiesCausesOther.required &&
                          x.value === "Other"
                        }
                      />
                    </Grid>
                  )
                )}
              </Grid>
            </InputWithLabel>
            {woundInfoData.previousTherapiesCausesOther.required && (
              <InputWithLabel
                error={
                  woundInfoData.previousTherapiesCausesOther.valid ===
                  ValidationStatus.INVALID
                }
                id={woundInfoData.previousTherapiesCausesOther.componentId!}
                isShowWarning={true}
                warningMessage={
                  woundInfoData.previousTherapiesCausesOther.errorMessage
                }
                warning_icon_div_custom_class="previous-thery-warning-error-message-div"
              >
                <InputBase
                  autoFocus={
                    woundInfoData.previousTherapiesCausesOther.value === ""
                  }
                  className="previousTherapy-otherCause-input"
                  data-testid="previousTherapyCauseField"
                  id={woundInfoData.previousTherapiesCausesOther.id!}
                  name="previousTherapiesCausesOther"
                  onChange={validateAndSetData}
                  placeholder="Please Describe"
                  value={woundInfoData.previousTherapiesCausesOther.value}
                />
              </InputWithLabel>
            )}
          </div>
        </div>
      )}
      {isReviewOrder && (
        <PreviousTherapiesReviewOrder
          editButtonClicked={editButtonClicked}
          isOrderSummary={isOrderSummary}
          woundInfoData={woundInfoData}
        />
      )}
    </div>
  );
};

export default PreviousTherapy;
