import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { useState } from "react";
import "./deviceComplications.css";
import { ERROR_TO_SELECT_ANY_OPTION } from "../../../../../util/errorMsg";
import { ICreateServiceRequest } from "../../createServiceRequest.interface";
import { ValidationStatus } from "../../../../../core/interfaces/input.interface";
import { CreateServiceRequestValidator } from "../../createServiceRequest.validator";
import { ReactComponent as RadioButtonIcon } from "../../../../../assets/radioButton.svg";
import { InputWithLabel } from "../../../../../core/inputWithLabel/inputWithLabel.component";
import { ReactComponent as SelectedRadioButtonIcon } from "../../../../../assets/selectedRadioButton.svg";
import { DeviceComplicationsReviewRequest } from "./reviewRequest/deviceComplicationsReviewRequest.component";
import { IAcuteOrderPickUpRequest } from "../../../../acutePickUpRequest/acuteOrderPickUpRequest.interface";

export interface IDeviceComplications {
  data: ICreateServiceRequest | IAcuteOrderPickUpRequest;
  editButtonAction?: any;
  isReviewRequest?: boolean;
  isReviewSummary?: boolean;
  setData: Function;
}

export const DeviceComplications = ({
  data,
  editButtonAction,
  isReviewRequest = false,
  isReviewSummary = false,
  setData,
}: IDeviceComplications) => {
  const [focusClasses, setFocusClasses] = useState({
    describeTheProblem: "describeTheProblem",
  });

  const setClasses = (e: any, classname: string) => {
    setFocusClasses(
      Object.assign({}, focusClasses, { [e.target.name]: classname })
    );
  };

  const validateAndSetData = (e: any) => {
    const { name, value } = e.target;
    const validator = new CreateServiceRequestValidator();
    const isValid = validator.validate(value, name);
    if (
      name === "describeTheProblem" &&
      isValid!.status === ValidationStatus.INVALID &&
      value.length > 200
    ) {
      return;
    }
    let tempData = {
      ...data,
      [name]: {
        ...Object(data)[name],
        errorMessage: null,
        valid: isValid!.status,
        value: value,
      },
    };
    if (
      (name === "injuryCauseBy3MDevice" &&
        tempData.problemWith3MDevice.value !== "yes") ||
      (name === "problemWith3MDevice" &&
        tempData.injuryCauseBy3MDevice.value !== "yes")
    ) {
      tempData = {
        ...tempData,
        describeTheProblem: {
          ...tempData.describeTheProblem,
          errorMessage: null,
          required: value === "yes",
          valid: ValidationStatus.UNTOUCHED,
          value: "",
        },
      };
    }
    setData(tempData);
  };

  return (
    <div
      className="device-complications-component"
      data-testid="device-complications-component"
      id="device-complications-component"
    >
      {isReviewRequest ? (
        <DeviceComplicationsReviewRequest
          data={data}
          editButtonAction={editButtonAction}
          isReviewSummary={isReviewSummary}
        />
      ) : (
        <>
          <p
            className="device-complications-header"
            data-testid="device-complications-header"
            id="device-complications-header-id"
          >
            Device Complications
          </p>
          <Grid
            className="device-complications-grid-container"
            container
            spacing={2}
          >
            <Grid className="device-complications-grid-item" item xs={12}>
              <InputWithLabel
                error={
                  data.injuryCauseBy3MDevice.valid === ValidationStatus.INVALID
                }
                id={data.injuryCauseBy3MDevice.componentId!}
                isRequired={data.injuryCauseBy3MDevice.required}
                isShowWarning={true}
                label={data.injuryCauseBy3MDevice.title!}
                labelClassName="device-complications-header-title"
                testId="device-complications-injuryCauseBy3MDevice-title"
                warningMessage={
                  data.injuryCauseBy3MDevice.errorMessage
                    ? ERROR_TO_SELECT_ANY_OPTION
                    : ""
                }
              >
                <RadioGroup
                  name="injuryCauseBy3MDevice"
                  classes={{ root: "radioRoot" }}
                  onChange={validateAndSetData}
                  value={data.injuryCauseBy3MDevice.value}
                >
                  <FormControlLabel
                    classes={{
                      root:
                        data.injuryCauseBy3MDevice.valid ===
                        ValidationStatus.INVALID
                          ? "optionRoot-error"
                          : data.injuryCauseBy3MDevice.value === "yes"
                          ? "optionRoot-active"
                          : "optionRoot",
                    }}
                    componentsProps={{
                      typography: {
                        classes: {
                          root:
                            data.injuryCauseBy3MDevice.value === "yes"
                              ? "optiontxtSelect"
                              : "optiontxt",
                        },
                      },
                    }}
                    control={
                      <Radio
                        icon={<RadioButtonIcon />}
                        checkedIcon={<SelectedRadioButtonIcon />}
                        required={data.injuryCauseBy3MDevice.required}
                      />
                    }
                    data-testid="device-complications-injuryCauseBy3MDevice-yes"
                    label="Yes"
                    value="yes"
                  />
                  <FormControlLabel
                    classes={{
                      root:
                        data.injuryCauseBy3MDevice.valid ===
                        ValidationStatus.INVALID
                          ? "optionRoot-error"
                          : data.injuryCauseBy3MDevice.value === "no"
                          ? "optionRoot-active"
                          : "optionRoot",
                    }}
                    componentsProps={{
                      typography: {
                        classes: {
                          root:
                            data.injuryCauseBy3MDevice.value === "no"
                              ? "optiontxtSelect"
                              : "optiontxt",
                        },
                      },
                    }}
                    control={
                      <Radio
                        icon={<RadioButtonIcon />}
                        checkedIcon={<SelectedRadioButtonIcon />}
                        required={data.injuryCauseBy3MDevice.required}
                      />
                    }
                    data-testid="device-complications-injuryCauseBy3MDevice-no"
                    label="No"
                    value="no"
                  />
                  <FormControlLabel
                    classes={{
                      root:
                        data.injuryCauseBy3MDevice.valid ===
                        ValidationStatus.INVALID
                          ? "optionRoot-error unknown"
                          : data.injuryCauseBy3MDevice.value === "unknown"
                          ? "optionRoot-active unknown"
                          : "optionRoot unknown",
                    }}
                    componentsProps={{
                      typography: {
                        classes: {
                          root:
                            data.injuryCauseBy3MDevice.value === "unknown"
                              ? "optiontxtSelect"
                              : "optiontxt",
                        },
                      },
                    }}
                    control={
                      <Radio
                        icon={<RadioButtonIcon />}
                        checkedIcon={<SelectedRadioButtonIcon />}
                        required={data.injuryCauseBy3MDevice.required}
                      />
                    }
                    data-testid="device-complications-injuryCauseBy3MDevice-unknown"
                    label="Unknown"
                    value="unknown"
                  />
                </RadioGroup>
              </InputWithLabel>
            </Grid>
            <Grid className="device-complications-grid-item" item xs={12}>
              <InputWithLabel
                error={
                  data.problemWith3MDevice.valid === ValidationStatus.INVALID
                }
                id={data.problemWith3MDevice.componentId!}
                isRequired={data.problemWith3MDevice.required}
                isShowWarning={true}
                label={data.problemWith3MDevice.title!}
                labelClassName="device-complications-header-title"
                testId="device-complications-problemWith3MDevice-title"
                warningMessage={
                  data.problemWith3MDevice.errorMessage
                    ? ERROR_TO_SELECT_ANY_OPTION
                    : ""
                }
              >
                <RadioGroup
                  name="problemWith3MDevice"
                  classes={{ root: "radioRoot" }}
                  onChange={validateAndSetData}
                  value={data.problemWith3MDevice.value}
                >
                  <FormControlLabel
                    classes={{
                      root:
                        data.problemWith3MDevice.valid ===
                        ValidationStatus.INVALID
                          ? "optionRoot-error"
                          : data.problemWith3MDevice.value === "yes"
                          ? "optionRoot-active"
                          : "optionRoot",
                    }}
                    componentsProps={{
                      typography: {
                        classes: {
                          root:
                            data.problemWith3MDevice.value === "yes"
                              ? "optiontxtSelect"
                              : "optiontxt",
                        },
                      },
                    }}
                    control={
                      <Radio
                        icon={<RadioButtonIcon />}
                        checkedIcon={<SelectedRadioButtonIcon />}
                        required={data.problemWith3MDevice.required}
                      />
                    }
                    data-testid="device-complications-problemWith3MDevice-yes"
                    label="Yes"
                    value="yes"
                  />
                  <FormControlLabel
                    classes={{
                      root:
                        data.problemWith3MDevice.valid ===
                        ValidationStatus.INVALID
                          ? "optionRoot-error"
                          : data.problemWith3MDevice.value === "no"
                          ? "optionRoot-active"
                          : "optionRoot",
                    }}
                    componentsProps={{
                      typography: {
                        classes: {
                          root:
                            data.problemWith3MDevice.value === "no"
                              ? "optiontxtSelect"
                              : "optiontxt",
                        },
                      },
                    }}
                    control={
                      <Radio
                        icon={<RadioButtonIcon />}
                        checkedIcon={<SelectedRadioButtonIcon />}
                        required={data.problemWith3MDevice.required}
                      />
                    }
                    data-testid="device-complications-problemWith3MDevice-no"
                    label="No"
                    value="no"
                  />
                  <FormControlLabel
                    classes={{
                      root:
                        data.problemWith3MDevice.valid ===
                        ValidationStatus.INVALID
                          ? "optionRoot-error unknown"
                          : data.problemWith3MDevice.value === "unknown"
                          ? "optionRoot-active unknown"
                          : "optionRoot unknown",
                    }}
                    componentsProps={{
                      typography: {
                        classes: {
                          root:
                            data.problemWith3MDevice.value === "unknown"
                              ? "optiontxtSelect"
                              : "optiontxt",
                        },
                      },
                    }}
                    control={
                      <Radio
                        icon={<RadioButtonIcon />}
                        checkedIcon={<SelectedRadioButtonIcon />}
                        required={data.problemWith3MDevice.required}
                      />
                    }
                    data-testid="device-complications-problemWith3MDevice-unknown"
                    label="Unknown"
                    value="unknown"
                  />
                </RadioGroup>
              </InputWithLabel>
            </Grid>
            {(data.injuryCauseBy3MDevice.value === "yes" ||
              data.problemWith3MDevice.value === "yes") && (
              <Grid className="device-complications-grid-item" item xs={12}>
                <InputWithLabel
                  error={
                    data.describeTheProblem.valid === ValidationStatus.INVALID
                  }
                  id={data.describeTheProblem.componentId!}
                  isRequired={data.describeTheProblem.required}
                  isShowWarning={true}
                  label="Describe the injury and/or the problem with the 3M Device"
                  testId="device-complications-describe-the-problem-title"
                  warningMessage={data.describeTheProblem.errorMessage}
                  labelClassName="describe-injury-with-3m"
                >
                  <TextField
                    data-testid="device-complications-describe-the-problem-value"
                    error={
                      data.describeTheProblem.valid === ValidationStatus.INVALID
                    }
                    fullWidth
                    id={data.describeTheProblem.id!}
                    InputProps={{
                      inputProps: { maxLength: 200 },
                      classes: {
                        root: "textarea-root",
                      },
                    }}
                    multiline
                    name="describeTheProblem"
                    onBlur={(e) => setClasses(e, "")}
                    onChange={validateAndSetData}
                    onFocus={(e) => setClasses(e, "Mui-focused")}
                    placeholder=""
                    required={data.describeTheProblem.required}
                    rows={4}
                    value={data.describeTheProblem.value}
                  />
                </InputWithLabel>
              </Grid>
            )}
          </Grid>
        </>
      )}
    </div>
  );
};
