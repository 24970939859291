import { useState, useEffect, useContext } from "react";
import "./AddSalesTerritoryForSaleManager.css";
import { Button, Grid, InputBase } from "@mui/material";
import {
  IAddSalesTerritory,
  ISalePeople,
  ITerritoryDetails,
} from "../AddSalesTerritory/addSalesTerritory.interface";
import SearchIcon from "@mui/icons-material/Search";
import { CustomDropDown } from "../../../../../core/customDropdown/customDropdown.component";
import { CustomCheckBox } from "../../../../../core/checkBox/checkBox.component";
import { format } from "react-string-format";
import { DD_ALL_ROLES } from "../../../../../util/staticText";
import { getdropDownContent } from "../../../../../util/dropDownService";
import { ManualAddTerritories } from "../AddSalesTerritory/ManualAddSalesTerritory/manualAddTerritory.component";
import { mockManualAddTerritory } from "../AddSalesTerritory/ManualAddSalesTerritory/manualAddTerritory.model";
import {
  changeDateFormatOnlyDate,
  makeCapitalEachWordInString,
} from "../../../../../util/utilityFunctions";
import { useDebounce } from "use-debounce";
import {
  AuthContext,
  AuthContextType,
} from "../../../../../context/AuthContext";
import { getSalesTerritories } from "../../../../../util/userService";
import { LoadingSpinner } from "../../../../../core/loader/LoadingSpinner";

export const AddSalesTerritoryForSaleManager = ({
  data,
  setData,
  setSalesMngUserData,
  salesMngUserData,
  setOpenpopup,
}: IAddSalesTerritory) => {
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const [searchedInput, setSearchedInput] = useState<string>("");
  const [searchInput, setSearchInput] = useState<string>("");
  const [debouncedText] = useDebounce(searchInput, 500);
  const [roleText, setRoleText] = useState<string[]>([]);
  const [selectedRoleType, setSelectedRoleType] = useState<string>("");
  const [selectedTerritoryDetails, setSelectedTerritoryDetails] =
    useState<ITerritoryDetails | null>(null);
  const [selectedTerritory, setSelectedTerritory] = useState(false);
  const [territoriesData, setTerritoriesData] = useState<
    ITerritoryDetails[] | null
  >();
  const [filteredTerritoryData, setFilteredTerritoryData] = useState<
    ITerritoryDetails[] | null
  >();
  const [showMyTeamOnly, setShowMyTeamOnly] = useState(true);
  const [showInPageLoader, setShowInPageLoader] = useState(false);
  const selectOpen = (territory: ITerritoryDetails | null) => {
    setData(mockManualAddTerritory);
    setSelectedTerritory(true);
    setSelectedTerritoryDetails(territory);
  };
  const handleSearch = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const re = /^[a-zA-Z0-9- ]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setSearchInput(e.target.value);
    }
  };

  const fetchDropDownContent = async () => {
    try {
      const ddContent = format("{0}", DD_ALL_ROLES ?? "");
      const data = await getdropDownContent(ddContent);
      if (data.items.length > 0) {
        const roleTypeObject = data.items.filter(
          (item: { name: string }) => item.name === DD_ALL_ROLES
        );
        const roleTypeData = roleTypeObject[0].data.sort(
          (a: { order: number }, b: { order: number }) =>
            a.order > b.order ? 1 : -1
        );
        setRoleText(roleTypeData.map((x: { text: string }) => x.text));
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const validateAndSetRoleData = (e: any) => {
    setSelectedRoleType(e.target.value);
    filterUsers(debouncedText, e.target.value);
  };

  const filterUsers = (searchParam: string, role: string) => {
    setSearchedInput(searchParam);
    let searchRole = "";
    if (role.toLowerCase() !== "all roles") {
      searchRole = role;
    }
    if (territoriesData && territoriesData.length > 0) {
      let filtedUsers: ITerritoryDetails[] = territoriesData;
      if (searchRole.length > 0) {
        filtedUsers = filtedUsers.filter((user: ITerritoryDetails) =>
          user.roleType.includes(searchRole)
        );
      }
      if (searchParam.length > 0) {
        filtedUsers = filtedUsers.filter(
          (user: ITerritoryDetails) =>
            user.name.toLocaleLowerCase().includes(searchParam.toLowerCase()) ||
            user.code.toLocaleLowerCase().includes(searchParam.toLowerCase()) ||
            JSON.stringify(
              user.salesPeople?.map(({ ...rest }) => {
                return rest;
              })
            )
              .toLocaleLowerCase()
              .includes(searchParam.toLowerCase())
        );
      }
      setFilteredTerritoryData(filtedUsers);
    }
  };
  const handleCheckBoxChange = (e: any) => {
    setSearchInput("");
    setSelectedRoleType("All roles");
    setShowMyTeamOnly(e.target.checked);
    getSalesTerritoriesAPI!(e.target.checked);
  };

  const getSalesTerritoriesAPI = async (myTeamOn?: boolean) => {
    setFilteredTerritoryData(null);
    setTerritoriesData(null);
    let params;
    if (authObj && authObj.userProfile) {
      if (authObj?.userProfile.area && authObj?.userProfile.region) {
        params = {
          region: myTeamOn ? authObj.userProfile?.region : "",
          area: !myTeamOn ? authObj.userProfile?.area : "",
          CurrentDate: changeDateFormatOnlyDate(new Date().toString()),
        };
        setShowInPageLoader(true);
      }
      const response = await getSalesTerritories(params);
      if (response && response.succeeded) {
        let allTerritories = response.data;
        const result = allTerritories.filter(
          (x: any) =>
            !salesMngUserData?.territories.some(
              (y) => x.code === y.territoryCode
            )
        );
        setTerritoriesData(result);
        setFilteredTerritoryData(result);
      }
    }
    setShowInPageLoader(false);
  };

  useEffect(() => {
    getSalesTerritoriesAPI(true);
    fetchDropDownContent();
  }, []);

  useEffect(() => {
    if (
      (debouncedText.length === 0 && searchedInput !== debouncedText) ||
      debouncedText.length > 0
    ) {
      filterUsers(debouncedText, selectedRoleType);
    }
  }, [debouncedText]);

  return (
    <div
      className={
        !selectedTerritory
          ? "addSTForSaleManager"
          : "addSTForSaleManagerSelected"
      }
    >
      <p
        className="addSTForSaleManagerTitle"
        data-testid="addSTForSaleManager-header"
      >
        Add Sales Territory
      </p>
      {!selectedTerritory ? (
        <>
          <Grid container gap={1.2} className="addSTForSaleManager-search">
            <Grid item sm={6.5} xs={12}>
              <div className="sale-user-searchbar">
                <div className="sale-search-icon-div">
                  <SearchIcon className="sale-search-icon" />
                </div>
                <InputBase
                  className="sale-user-search-input"
                  data-testid="sale-user-search-input"
                  name="search-input"
                  onChange={handleSearch}
                  placeholder="Filter by Territory, Code or Salesperson"
                  value={searchInput}
                />
              </div>
            </Grid>
            <Grid item sm={2.5} xs={6}>
              <CustomDropDown
                handleChange={validateAndSetRoleData}
                menuItem={["All roles"].concat(roleText)}
                name="sale-user-role"
                placeHolder="All roles"
                selectpropsClassName={
                  selectedRoleType ? "sale-user-status-select" : "placeHolder"
                }
                selectClassName={
                  selectedRoleType ? "sale-user-status-input" : "placeHolder"
                }
                testId="sale-user-status"
                value={selectedRoleType ? selectedRoleType : null}
              />
            </Grid>
            <Grid item sm={2.5}>
              <div style={{ width: "120px" }}>
                <CustomCheckBox
                  handleChange={handleCheckBoxChange}
                  value={showMyTeamOnly}
                  checked={showMyTeamOnly}
                  labelText="Show my team only"
                  labelClassName="checkboxLabel"
                  testId="addSTCheckBox"
                />
              </div>
            </Grid>
          </Grid>
          <div className="addSTForSaleManager-list">
            {showInPageLoader ? (
              <div className="addSTLoadingSpinner">
                <LoadingSpinner />
              </div>
            ) : (
              <>
                {filteredTerritoryData && filteredTerritoryData.length > 0 ? (
                  <>
                    {filteredTerritoryData?.map(
                      (territory: ITerritoryDetails, index: number) => {
                        return (
                          <Grid
                            className={`addSTterritoryList${
                              index % 2 === 1 ? " gray" : ""
                            }`}
                          >
                            <Button
                              className="selectButton"
                              onClick={() => {
                                selectOpen(territory);
                              }}
                              data-testid={"add-sales-territory"}
                            >
                              Select
                            </Button>
                            <div className="addSTDataGrid">
                              <Grid className="addSTterritoryRegionBlock">
                                <Grid
                                  className="addSTterritoryRegion"
                                  data-testid={"territoryRegionTestId"}
                                  lg={12}
                                >
                                  Territory & Region
                                </Grid>
                                <Grid
                                  className="addSTterritoriesDetails"
                                  data-testid={"territoriesDetailsTestId"}
                                >
                                  {territory.code} - {territory.name}
                                  <Grid>{territory.regionDistrict}</Grid>
                                </Grid>
                              </Grid>
                              <Grid>
                                <Grid className="salesPersonTitle">
                                  Salesperson
                                </Grid>
                                {territory?.salesPeople &&
                                territory?.salesPeople?.length > 0 ? (
                                  <>
                                    {territory.salesPeople.map(
                                      (salepeople: ISalePeople) => (
                                        <Grid className="salesPersonValue">
                                          {makeCapitalEachWordInString(
                                            salepeople.lastName
                                          )}
                                          {", "}
                                          {makeCapitalEachWordInString(
                                            salepeople.firstName
                                          )}
                                        </Grid>
                                      )
                                    )}
                                  </>
                                ) : (
                                  "-"
                                )}
                              </Grid>
                            </div>
                          </Grid>
                        );
                      }
                    )}
                  </>
                ) : (
                  <>
                    <div
                      className="addSTNoRecords"
                      data-testid={"addSTNoRecords"}
                    >
                      No records to display.
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </>
      ) : (
        <>
          <ManualAddTerritories
            selectedTerritoryDetails={selectedTerritoryDetails!}
            data={data}
            setData={setData}
            selectOpen={selectOpen}
            setSelectedTerritory={setSelectedTerritory}
            setSalesMngUserData={setSalesMngUserData}
            salesMngUserData={salesMngUserData!}
            setOpenpopup={setOpenpopup}
          />
        </>
      )}
    </div>
  );
};
