import { Popup } from "../../../../../core/popup/popup.component";
import "./userProfileUpdateConfirmation.css";
import { ExpressButton } from "../../../../../core/expressButton/expressButton.component";
import "./userProfileUpdateConfirmation.css";

interface Props {
  closeModal: any;
  handleExitButton: any;
}

const UserProfileUpdateConfirmationPopup = ({
  closeModal,
  handleExitButton,
}: Props) => {
  return (
    <>
      <div>
        <Popup
          openFlag={true}
          closeHandler={closeModal}
          dialogParentClass={"update-user-prompt-pop-up"}
          data-testid="update-user-prompt-pop-up"
        >
          <div
            className="saveUserConfirmPopup"
            data-testid="saveUserConfirmPopup"
          >
            <h2 className="header-title" data-testid="header-title">
              You haven't saved your update
            </h2>
            <h5 className="header-sub-title" data-testid="header-sub-title">
              If you exit without saving, any changes you have made to the
              user's profile will be lost
            </h5>
            <div className="return-to-order-entry">
              <ExpressButton
                parentClass="return-to-order-entry-button"
                variant="outlined"
                clickHandler={() => closeModal()}
                testId="button-1"
              >
                Return to User Profile
              </ExpressButton>
            </div>
            <div className="exit-without-saving">
              <ExpressButton
                parentClass="exit-without-saving-button"
                variant="outlined"
                testId="button-2"
                clickHandler={() => handleExitButton()}
              >
                Exit without Saving
              </ExpressButton>
            </div>
          </div>
        </Popup>
      </div>
    </>
  );
};

export default UserProfileUpdateConfirmationPopup;
