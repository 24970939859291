import React, { useContext } from "react";
import { Grid, IconButton, Stack, TextField, Typography } from "@mui/material";
import { ReactComponent as Quantityplusactive } from "../../../../assets/quantityplusactive.svg";
import { ReactComponent as Quantityminusactive } from "../../../../assets/quantityminusactive.svg";
import { ReactComponent as Minusbuttondisabled } from "../../../../assets/minusbuttondisabled.svg";
import { ReactComponent as Plusbuttondisabled } from "../../../../assets/plusbuttondisabled.svg";
import {
  IMobileDisplayContext,
  MobileDisplayContext,
} from "../../../../context/MobileDisplayContext";
import { IInpatientOrder } from "../../inpatientOrder.interface";
import { getDeepClone } from "../../../../util/ObjectFunctions";
import WoundInformationSectionReview from "./reviewWoundInformation/woundInformatoinReview.component";
import "../../components/woundInformation/woundInformation.css";
import { ValidationStatus } from "../../../../core/interfaces/input.interface";
import WoundSection from "./woundInfoCard/woundInfoCard.component";
import {
  IWoundInfoDropDownValues,
  IWoundInformation,
} from "./woundInformation.interface";
import {
  defaultInPatientPrimaryWoundInfo,
  defaultInPatientSecondaryWoundInfo,
} from "./woundInformation.model";
import { DevicePlacementTabs } from "../devicePlacement/devicePlacemet.enums";

interface IInPatientWoundInfoProps {
  data: IInpatientOrder;
  editButtonAction?: any;
  isReviewOrder?: boolean;
  isSummary?: boolean;
  setData: Function;
  woundInfoDropDownValues: IWoundInfoDropDownValues;
  woundInformation: IWoundInformation;
}

interface IWoundInfoRequiredData {
  data: IInpatientOrder;
  setData: Function;
  woundInfoDropDownValues: IWoundInfoDropDownValues;
}

const WoundInformationSection = ({
  data,
  editButtonAction,
  isReviewOrder = false,
  isSummary = false,
  setData,
  woundInfoDropDownValues,
  woundInformation,
}: IInPatientWoundInfoProps) => {
  const { isMobileScreen } =
    useContext<IMobileDisplayContext>(MobileDisplayContext);
  const woundInfoCountValue = Number(woundInformation.woundInfoCount.value);
  const isPlusBtnDisable = woundInfoCountValue === 2;
  const isMinusBtnDisable = woundInfoCountValue === 0;
  const isShowPrimaryWoundInfo =
    woundInfoCountValue === 1 || woundInfoCountValue > 1;
  const isShowSecondaryWoundInfo = woundInfoCountValue === 2;
  const isCustomerPlus =
    data.devicePlacement.value.productType.value !==
    DevicePlacementTabs.CUSTOMER_PLUS_TAB;

  const handlePlusOrMinusQty = (isToIncrease: boolean) => {
    let updatedCount = isToIncrease
      ? woundInfoCountValue + 1
      : woundInfoCountValue - 1;
    let tempData = woundInformation;
    tempData = {
      ...tempData,
      woundInfoCount: {
        ...tempData?.woundInfoCount,
        value: String(updatedCount),
      },
    };
    if (!isToIncrease) {
      if (isShowSecondaryWoundInfo) {
        tempData.secondaryWoundInformation = getDeepClone(
          defaultInPatientSecondaryWoundInfo
        );
      } else if (isShowPrimaryWoundInfo) {
        tempData.primaryWoundInformation = getDeepClone(
          defaultInPatientPrimaryWoundInfo
        );
      }
    } else {
      tempData.primaryWoundInformation.woundMeasurementDate.required =
        isCustomerPlus;
      tempData.secondaryWoundInformation.woundMeasurementDate.required =
        isCustomerPlus;
    }
    setData({
      ...data,
      patientWoundInformation: {
        ...data.patientWoundInformation,
        valid: ValidationStatus.VALID,
        value: tempData,
      },
    });
  };

  const requiredWoundSectionProps: IWoundInfoRequiredData = {
    woundInfoDropDownValues: woundInfoDropDownValues,
    data: data,
    setData: setData,
  };

  return (
    <>
      {isReviewOrder ? (
        <WoundInformationSectionReview
          data={data.patientWoundInformation.value}
          isSummary={isSummary}
          editButtonAction={editButtonAction}
        />
      ) : (
        <Grid container className="inpatient-wound-info-main-div">
          <Grid item xs={isMobileScreen ? 12 : 8}>
            <Typography
              className="inpatient-wound-info-card-header"
              data-testid="inpatient-wound-info-card-header"
            >
              Wound Information (optional)
            </Typography>
            <Stack className="inpatient-wound-info-counter-div">
              <Typography
                data-testid="inpatient-wound-info-card-sub-header"
                className="inpatient-wound-info-card-sub-header"
              >
                Number of wounds
              </Typography>
              <Stack className="inpatient-wound-info-qty-btn-div">
                <IconButton
                  onClick={() => handlePlusOrMinusQty(false)}
                  disabled={isMinusBtnDisable}
                  data-testid="inpatient-wound-info-qty-minus-icon-btn"
                >
                  {isMinusBtnDisable ? (
                    <Minusbuttondisabled />
                  ) : (
                    <Quantityminusactive />
                  )}
                </IconButton>
                <TextField
                  size="small"
                  className="quantityInput in-patient-qty-input"
                  name="quantityInput"
                  value={woundInformation.woundInfoCount.value}
                  inputProps={{
                    "data-testid": "inpatient-wound-info-qty-input",
                  }}
                  placeholder="0"
                />
                <IconButton
                  disabled={isPlusBtnDisable}
                  onClick={() => handlePlusOrMinusQty(true)}
                  data-testid="inpatient-wound-info-qty-plus-icon-btn"
                >
                  {isPlusBtnDisable ? (
                    <Plusbuttondisabled />
                  ) : (
                    <Quantityplusactive />
                  )}
                </IconButton>
              </Stack>
            </Stack>
          </Grid>
          <Grid xs={12}>
            {isShowPrimaryWoundInfo && (
              <WoundSection
                isPrimary={true}
                woundInfoData={woundInformation.primaryWoundInformation}
                {...requiredWoundSectionProps}
              />
            )}
            {isShowSecondaryWoundInfo && (
              <WoundSection
                isPrimary={false}
                woundInfoData={woundInformation.secondaryWoundInformation}
                {...requiredWoundSectionProps}
              />
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default WoundInformationSection;
