import {
  defaultShowSupplyVacDressing,
  showSupplyOrderSecondaryVacDressing,
  showSupplyOrderVacDressing,
} from "../../supplyOrder.model";
import { useContext, useMemo, useState } from "react";
import "./supplyOrderVacDressing.css";
import { Button, Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {
  SupplyOrderContext,
  SupplyOrderContextType,
} from "../../../../context/SupplyOrderContext";
import { ISupplyOrder } from "../../supplyOrder.interface";
import { SupplyOrderValidator } from "../../supplyOrder.validator";
import { IDressingKit } from "../../../newOrder/newOrder.interface";
import { ValidationStatus } from "../../../../core/interfaces/input.interface";
import { IVACProductInfo } from "../../patientVACDetail/patientVACDetail.interface";
import { SupplyOrderVacCannister } from "../supplyOrderVacCannister/supplyOrderVacCannister.component";
import { VacDressing } from "../../../newOrder/dressingSupplies/vacDressingKit/VacDressingKitMain.component";
import "./supplyOrderDressing.css";
import {
  SpecialProduct,
  checkSelectedProdcutForType,
  checkSelectedVacProdcutForType,
  getVacProductsWithout,
} from "../../../../util/utilityFunctions";
import { ProductInformation } from "../../../newOrder/dressingSupplies/vacDressingKit/vacDressingKit.interface";
import { IPeelAndPlace } from "../../../newOrder/dressingSupplies/vacDressingKit/peelAndPlace/peelAndPlace.component";
import { PRODUCT_TYPE_DRESSING } from "../../../../util/staticText";

interface ISupplyOrderVacDressingKit {
  cannisterProduct: any;
  data: ISupplyOrder;
  isPeelAndPlaceDressing?: boolean;
  isShowWarningForSecondaryDressing?: boolean;
  instruction?: IPeelAndPlace | null;
  note?: IPeelAndPlace | null;
  primaryInsurance?: string | null;
  setIsPeelAndPlaceDressing?: Function;
  setIsShowWarningForSecondaryDressing?: Function;
  setVacProductSize: any;
  setVacSecondaryProductSize: any;
  vacProductInfo: IVACProductInfo;
  vacProducts?: any;
  vacProductSize: any;
  vacSecondaryProductSize: any;
  Validator?: SupplyOrderValidator;
}

export const SupplyOrderVacDressingKit = ({
  cannisterProduct,
  data,
  isPeelAndPlaceDressing,
  isShowWarningForSecondaryDressing,
  instruction,
  note,
  primaryInsurance,
  setIsPeelAndPlaceDressing,
  setIsShowWarningForSecondaryDressing,
  setVacProductSize,
  setVacSecondaryProductSize,
  vacProductInfo,
  vacProducts,
  vacProductSize,
  vacSecondaryProductSize,
  Validator = new SupplyOrderValidator(),
}: ISupplyOrderVacDressingKit) => {
  const supplyOrderObj = useContext<SupplyOrderContextType | null>(
    SupplyOrderContext
  );
  const replenishSupplyOrderData = supplyOrderObj!.replenishSupplyOrderData;
  const canister = supplyOrderObj!.canister;
  const dressing = supplyOrderObj!.dressingKit;
  const setCanister = supplyOrderObj!.setCanister;
  const setDressing = supplyOrderObj!.setDressingKit;
  const vacAllProducts = supplyOrderObj!.vacAllProducts;
  const [validator] = useState<SupplyOrderValidator>(Validator!);
  const [isCharityCareSelected, setIsCharityCareSelected] =
    useState<boolean>(false);

  const setProductSizeDropDown = (selectedVac: any, type: string) => {
    const vacProducts = selectedVac?.sizes.map(
      (x: { name: any; sku: any }) => ({
        id: x.sku,
        code: x.sku,
        text: x.name,
      })
    )!;
    if (type === "primary") {
      setVacProductSize(vacProducts);
    } else {
      setVacSecondaryProductSize(vacProducts);
    }
  };

  const validateAndSetData = (e: any, param: any, type: string) => {
    let response: showSupplyOrderVacDressing =
      supplyOrderObj?.showSupplyOrderVacDressingKit!;
    let secResponse: showSupplyOrderSecondaryVacDressing =
      supplyOrderObj?.showSupplyOrderSecondaryVacDressingKit!;
    let tempData: IDressingKit;
    const isValid = Validator.validate(e.target.value, e.target.name);
    const selectedCode =
      param.key.split("-")[1] === "null" ? "" : param.key.split("-")[1];
    const selectedVac = vacAllProducts.items.filter(
      (x) => x.productName === e.target.value
    )[0];
    let isPeelAndPlace = false;
    if (type === "primary") {
      if (selectedVac) {
        isPeelAndPlace = checkSelectedVacProdcutForType(
          selectedVac,
          SpecialProduct.PEELANDPLACE
        );
        setIsShowWarningForSecondaryDressing!(
          isPeelAndPlace &&
            checkSelectedProdcutForType(
              vacAllProducts.items,
              SpecialProduct.WHITEFOAM,
              dressing.secProductName.value
            )
        );
      }
      setIsPeelAndPlaceDressing!(isPeelAndPlace);
      response = {
        ...response,
        showQunatity: true,
      };
      if (selectedVac && selectedVac.sizes.length > 1) {
        setProductSizeDropDown(selectedVac, type);
        response = { ...response, showSize: true };
        tempData = {
          ...dressing,
          productName: {
            value: e.target.value,
            valid: isValid!.status,
          },
          productId: {
            value: selectedCode ? selectedCode : "",
            valid: ValidationStatus.VALID,
          },
          productCode: {
            value: selectedCode ? selectedCode : "",
            valid: ValidationStatus.VALID,
          },
          productSizeName: {
            value: "",
            valid: ValidationStatus.UNTOUCHED,
          },
          productSizeCode: { value: "", valid: ValidationStatus.VALID },
          productQuantity: {
            value: isPeelAndPlace ? "1" : "3",
            valid: ValidationStatus.VALID,
          },
        };
      } else {
        setVacProductSize([]);
        response = { ...response, showSize: false };
        tempData = {
          ...dressing,
          productName: {
            value: e.target.value,
            valid: isValid!.status,
          },
          productId: {
            value: selectedCode ? selectedCode : "",
            valid: ValidationStatus.VALID,
          },
          productCode: {
            value: selectedCode ? selectedCode : "",
            valid: ValidationStatus.VALID,
          },
          productSizeName: {
            value: "",
            valid: ValidationStatus.VALID,
          },
          productSizeCode: { value: "", valid: ValidationStatus.VALID },
          productQuantity: {
            value: isPeelAndPlace ? "1" : "3",
            valid: ValidationStatus.VALID,
          },
        };
      }
      response = {
        ...response,
        isPrimaryVacKitDressingDisabled: true,
        isPrimaryVacKitDressingMinusDisabled: isPeelAndPlace ? true : false,
      };
    } else {
      secResponse = {
        ...secResponse,
        showSecQunatity: true,
      };
      isPeelAndPlace = isPeelAndPlaceDressing ?? false;
      if (isPeelAndPlace && selectedVac) {
        setIsShowWarningForSecondaryDressing!(
          checkSelectedVacProdcutForType(selectedVac, SpecialProduct.WHITEFOAM)
        );
      }
      if (selectedVac && selectedVac.sizes.length > 1) {
        setProductSizeDropDown(selectedVac, type);
        secResponse = { ...secResponse, showSecSize: true };
        tempData = {
          ...dressing,
          secProductName: {
            value: e.target.value,
            valid: isValid!.status,
          },
          secProductId: {
            value: selectedCode ? selectedCode : "",
            valid: ValidationStatus.VALID,
          },
          secProductCode: {
            value: selectedCode ? selectedCode : "",
            valid: ValidationStatus.VALID,
          },
          secProductSizeName: {
            value: "",
            valid: ValidationStatus.UNTOUCHED,
          },
          secProductSizeCode: {
            value: "",
            valid: ValidationStatus.VALID,
          },
          secProductQuantity: {
            value: "1",
            valid: ValidationStatus.VALID,
          },
        };
      } else {
        setVacSecondaryProductSize([]);
        secResponse = { ...secResponse, showSecSize: false };
        tempData = {
          ...dressing,
          secProductName: {
            value: e.target.value,
            valid: isValid!.status,
          },
          secProductId: {
            value: selectedCode ? selectedCode : "",
            valid: ValidationStatus.VALID,
          },
          secProductCode: {
            value: selectedCode ? selectedCode : "",
            valid: ValidationStatus.VALID,
          },
          secProductSizeName: {
            value: "",
            valid: ValidationStatus.VALID,
          },
          secProductSizeCode: {
            value: "",
            valid: ValidationStatus.VALID,
          },
          secProductQuantity: {
            value: "1",
            valid: ValidationStatus.VALID,
          },
        };
      }
      secResponse = {
        ...secResponse,
        isSecondaryVacKitDressingDisabled: false,
        isSecondaryVacKitDressingMinusDisabled: true,
      };
    }
    let primaryQuantity =
      tempData.productQuantity.value !== ""
        ? parseInt(tempData.productQuantity.value)
        : 0;
    const secondaryQuantity =
      tempData.secProductQuantity.value !== ""
        ? parseInt(tempData.secProductQuantity.value)
        : 0;
    if (primaryQuantity === 3 && !secResponse.showSecondaryDressingKit) {
      response = {
        ...response,
        isPrimaryVacKitDressingDisabled: true,
        isPrimaryVacKitDressingMinusDisabled: false,
      };
    } else if (primaryQuantity + secondaryQuantity >= 3) {
      if (primaryQuantity + secondaryQuantity > 3) {
        tempData.productQuantity.value = `${3 - secondaryQuantity}`;
        primaryQuantity = parseInt(tempData.productQuantity.value);
      }
      response = {
        ...response,
        isPrimaryVacKitDressingMinusDisabled: primaryQuantity === 1,
        isPrimaryVacKitDressingDisabled:
          primaryQuantity + secondaryQuantity >= 3,
      };
      secResponse = {
        ...secResponse,
        isSecondaryVacKitDressingMinusDisabled: secondaryQuantity === 1,
        isSecondaryVacKitDressingDisabled:
          primaryQuantity + secondaryQuantity >= 3,
      };
    } else if (isPeelAndPlace) {
      secResponse = {
        ...secResponse,
        isSecondaryVacKitDressingMinusDisabled: secondaryQuantity === 1,
        isSecondaryVacKitDressingDisabled:
          primaryQuantity + secondaryQuantity >= 3,
      };
    }
    setDressing(tempData);
    supplyOrderObj?.setShowSupplyOrderVacDressingKit(response);
    supplyOrderObj?.setShowSupplyOrderSecondaryVacDressingKit(secResponse);
    validator.validateOrderSupplyProducts(supplyOrderObj!, true);
  };

  const validateAndSetSize = (e: any, param: any, type: string) => {
    const isValid = Validator.validate(e.target.value, e.target.name);
    const selectedCode = param.key.split("-")[1];
    let tempData: IDressingKit;
    if (type === "primary") {
      tempData = {
        ...dressing,
        productSizeName: {
          value: e.target.value,
          valid: isValid!.status,
        },
        productSizeCode: { value: selectedCode, valid: isValid!.status },
        productCode: { value: selectedCode, valid: isValid!.status },
      };
    } else {
      tempData = {
        ...dressing,
        secProductSizeName: {
          value: e.target.value,
          valid: isValid!.status,
        },
        secProductSizeCode: {
          value: selectedCode,
          valid: ValidationStatus.VALID,
        },
        secProductCode: { value: selectedCode, valid: isValid!.status },
      };
    }
    setDressing(tempData);
    validator.validateOrderSupplyProducts(supplyOrderObj!, true);
  };

  const validateMaxProductQuantity = (
    primaryQuantity: number,
    secondaryQuantity: number
  ) => {
    primaryQuantity =
      isPeelAndPlaceDressing && primaryQuantity > 1 ? 1 : primaryQuantity;
    if (primaryQuantity + secondaryQuantity > 2) {
      return true;
    } else {
      return false;
    }
  };

  const handlePrimaryVacKitDressingPlusClick = () => {
    // primary
    let res: showSupplyOrderVacDressing =
      supplyOrderObj?.showSupplyOrderVacDressingKit!;
    // secondary
    let secSupplyOrderVacDressing: showSupplyOrderSecondaryVacDressing =
      supplyOrderObj?.showSupplyOrderSecondaryVacDressingKit!;
    res = {
      ...res,
      isPrimaryVacKitDressingDisabled: false,
      isPrimaryVacKitDressingMinusDisabled: false,
    };
    const dressingPlus = parseInt(dressing.productQuantity.value) + 1;
    const secDressingQuantityCount =
      dressing.secProductName.value !== ""
        ? parseInt(dressing.secProductQuantity.value)
        : 0;
    if (validateMaxProductQuantity(dressingPlus, secDressingQuantityCount)) {
      res = {
        ...res,
        isPrimaryVacKitDressingDisabled: true,
      };
      secSupplyOrderVacDressing = {
        ...secSupplyOrderVacDressing,
        isSecondaryVacKitDressingDisabled: true,
      };
    } else {
      res = {
        ...res,
        isPrimaryVacKitDressingDisabled: false,
      };
      secSupplyOrderVacDressing = {
        ...secSupplyOrderVacDressing,
        isSecondaryVacKitDressingDisabled: false,
      };
    }
    if (dressingPlus === 3) {
      res = {
        ...res,
        isPrimaryVacKitDressingDisabled: true,
      };
    }
    setDressing({
      ...dressing,
      productQuantity: {
        value: dressingPlus.toString(),
        valid: ValidationStatus.VALID,
      },
    });
    supplyOrderObj?.setShowSupplyOrderVacDressingKit(res);
    supplyOrderObj?.setShowSupplyOrderSecondaryVacDressingKit(
      secSupplyOrderVacDressing
    );
  };

  const handlePrimaryVacKitDressingMinusClick = () => {
    // primary
    let minusResp: showSupplyOrderVacDressing =
      supplyOrderObj?.showSupplyOrderVacDressingKit!;
    // secondary
    let secSupplyOrderVacDressing: showSupplyOrderSecondaryVacDressing =
      supplyOrderObj?.showSupplyOrderSecondaryVacDressingKit!;
    minusResp = { ...minusResp, isPrimaryVacKitDressingMinusDisabled: false };
    const dressingPlus = parseInt(dressing.productQuantity.value) - 1;
    const secDressingQuantityCount =
      dressing.secProductName.value !== ""
        ? parseInt(dressing.secProductQuantity.value)
        : 0;
    if (validateMaxProductQuantity(dressingPlus, secDressingQuantityCount)) {
      minusResp = {
        ...minusResp,
        isPrimaryVacKitDressingDisabled: true,
      };
      secSupplyOrderVacDressing = {
        ...secSupplyOrderVacDressing,
        isSecondaryVacKitDressingDisabled: true,
      };
    } else {
      minusResp = {
        ...minusResp,
        isPrimaryVacKitDressingDisabled: false,
      };
      secSupplyOrderVacDressing = {
        ...secSupplyOrderVacDressing,
        isSecondaryVacKitDressingDisabled: false,
      };
    }
    if (dressingPlus === 1) {
      minusResp = { ...minusResp, isPrimaryVacKitDressingMinusDisabled: true };
    }
    if (dressingPlus < 3) {
      minusResp = { ...minusResp, isPrimaryVacKitDressingDisabled: false };
    }
    setDressing({
      ...dressing,
      productQuantity: {
        value: dressingPlus.toString(),
        valid: ValidationStatus.VALID,
      },
    });
    supplyOrderObj?.setShowSupplyOrderVacDressingKit(minusResp);
    supplyOrderObj?.setShowSupplyOrderSecondaryVacDressingKit(
      secSupplyOrderVacDressing
    );
  };

  const handleSecondaryVacKitDressingPlusClick = () => {
    // primary
    let primaryRes: showSupplyOrderVacDressing =
      supplyOrderObj?.showSupplyOrderVacDressingKit!;
    // secondary
    let secPlusRes: showSupplyOrderSecondaryVacDressing =
      supplyOrderObj?.showSupplyOrderSecondaryVacDressingKit!;
    secPlusRes = {
      ...secPlusRes,
      isSecondaryVacKitDressingDisabled: false,
      isSecondaryVacKitDressingMinusDisabled: false,
    };
    const dressingPlus = parseInt(dressing.secProductQuantity.value) + 1;
    const priDressingPlus =
      dressing.productName.value !== ""
        ? parseInt(dressing.productQuantity.value)
        : 0;
    if (validateMaxProductQuantity(priDressingPlus, dressingPlus)) {
      primaryRes = {
        ...primaryRes,
        isPrimaryVacKitDressingDisabled: true,
      };
      secPlusRes = {
        ...secPlusRes,
        isSecondaryVacKitDressingDisabled: true,
      };
    } else {
      primaryRes = {
        ...primaryRes,
        isPrimaryVacKitDressingDisabled: false,
      };
      secPlusRes = {
        ...secPlusRes,
        isSecondaryVacKitDressingDisabled: false,
      };
    }
    if (dressingPlus === 3) {
      secPlusRes = { ...secPlusRes, isSecondaryVacKitDressingDisabled: true };
    }
    setDressing({
      ...dressing,
      secProductQuantity: {
        value: dressingPlus.toString(),
        valid: ValidationStatus.VALID,
      },
    });
    supplyOrderObj?.setShowSupplyOrderSecondaryVacDressingKit(secPlusRes);
    supplyOrderObj?.setShowSupplyOrderVacDressingKit(primaryRes);
  };

  const handleSecondaryVacKitDressingMinusClick = () => {
    // primary
    let primaryRes: showSupplyOrderVacDressing =
      supplyOrderObj?.showSupplyOrderVacDressingKit!;
    // secondary
    let secMinusRes: showSupplyOrderSecondaryVacDressing =
      supplyOrderObj?.showSupplyOrderSecondaryVacDressingKit!;
    secMinusRes = {
      ...secMinusRes,
      isSecondaryVacKitDressingMinusDisabled: false,
    };
    const dressingPlus = parseInt(dressing.secProductQuantity.value) - 1;
    const priDressingPlus =
      dressing.productName.value !== ""
        ? parseInt(dressing.productQuantity.value)
        : 0;
    if (validateMaxProductQuantity(priDressingPlus, dressingPlus)) {
      primaryRes = {
        ...primaryRes,
        isPrimaryVacKitDressingDisabled: true,
      };
      secMinusRes = {
        ...secMinusRes,
        isSecondaryVacKitDressingDisabled: true,
      };
    } else {
      primaryRes = {
        ...primaryRes,
        isPrimaryVacKitDressingDisabled: false,
      };
      secMinusRes = {
        ...secMinusRes,
        isSecondaryVacKitDressingDisabled: false,
      };
    }
    if (dressingPlus === 1) {
      secMinusRes = {
        ...secMinusRes,
        isSecondaryVacKitDressingMinusDisabled: true,
      };
    }
    if (dressingPlus < 3) {
      secMinusRes = {
        ...secMinusRes,
        isSecondaryVacKitDressingDisabled: false,
      };
    }
    setDressing({
      ...dressing,
      secProductQuantity: {
        value: dressingPlus.toString(),
        valid: ValidationStatus.VALID,
      },
    });
    supplyOrderObj?.setShowSupplyOrderSecondaryVacDressingKit(secMinusRes);
    supplyOrderObj?.setShowSupplyOrderVacDressingKit(primaryRes);
  };

  const moveDressingSecondaryToPrimary = () => {
    supplyOrderObj?.setShowSupplyOrderVacDressingKit({
      ...supplyOrderObj?.showSupplyOrderVacDressingKit,
      showPrimaryDressingKit: true,
      showSize: supplyOrderObj?.showSupplyOrderSecondaryVacDressingKit
        .showSecSize
        ? true
        : false,
      showQunatity: supplyOrderObj?.showSupplyOrderSecondaryVacDressingKit
        .showSecQunatity
        ? true
        : false,
      isPrimaryVacKitDressingDisabled: supplyOrderObj
        ?.showSupplyOrderSecondaryVacDressingKit
        .isSecondaryVacKitDressingDisabled
        ? true
        : false,
      isPrimaryVacKitDressingMinusDisabled: supplyOrderObj
        ?.showSupplyOrderSecondaryVacDressingKit
        .isSecondaryVacKitDressingMinusDisabled
        ? true
        : false,
    });
    supplyOrderObj?.setShowSupplyOrderSecondaryVacDressingKit({
      ...supplyOrderObj?.showSupplyOrderSecondaryVacDressingKit,
      showSecSize: false,
      showSecQunatity: false,
      isSecondaryVacKitDressingDisabled: false,
      isSecondaryVacKitDressingMinusDisabled: true,
    });
    setDressing({
      ...dressing,
      productSizeName: {
        value: dressing.secProductSizeName.value,
        valid: dressing.secProductSizeName.valid,
      },
      productSizeCode: {
        value: dressing.secProductSizeCode.value,
        valid: dressing.secProductSizeCode.valid,
      },
      productName: {
        value: dressing.secProductName.value,
        valid: dressing.secProductName.valid,
      },
      productId: {
        value: dressing.secProductId.value,
        valid: dressing.secProductId.valid,
      },
      productCode: {
        value: dressing.secProductCode.value,
        valid: dressing.secProductCode.valid,
      },
      productQuantity: {
        value: dressing.secProductQuantity.value,
        valid: dressing.secProductQuantity.valid,
      },
      secProductSizeName: {
        value: "",
        valid: ValidationStatus.UNTOUCHED,
      },
      secProductSizeCode: {
        value: "",
        valid: ValidationStatus.VALID,
      },
      secProductName: {
        value: "",
        valid: ValidationStatus.UNTOUCHED,
      },
      secProductId: { value: "", valid: ValidationStatus.VALID },
      secProductCode: { value: "", valid: ValidationStatus.VALID },
      secProductQuantity: {
        value: "1",
        valid: ValidationStatus.VALID,
      },
    });
  };

  const addDressingKitClick = () => {
    if (!supplyOrderObj?.showSupplyOrderVacDressingKit.showPrimaryDressingKit) {
      setIsPeelAndPlaceDressing!(false);
      // Check if Secondary dressing exists, If exists transfer secondary dressing to primary and make secondary empty.
      if (
        supplyOrderObj?.showSupplyOrderSecondaryVacDressingKit
          .showSecondaryDressingKit
      ) {
        moveDressingSecondaryToPrimary();
      } else {
        supplyOrderObj?.setShowSupplyOrderVacDressingKit({
          ...supplyOrderObj?.showSupplyOrderVacDressingKit,
          showPrimaryDressingKit: true,
        });
        setDressing({
          ...dressing,
          productSizeName: {
            value: "",
            valid: ValidationStatus.UNTOUCHED,
          },
          productSizeCode: { value: "", valid: ValidationStatus.VALID },
          productName: {
            value: "",
            valid: ValidationStatus.UNTOUCHED,
          },
          productId: { value: "", valid: ValidationStatus.VALID },
          productCode: { value: "", valid: ValidationStatus.VALID },
          productQuantity: { value: "3", valid: ValidationStatus.VALID },
        });
      }
    } else {
      supplyOrderObj?.setShowSupplyOrderSecondaryVacDressingKit({
        ...supplyOrderObj?.showSupplyOrderSecondaryVacDressingKit,
        showSecondaryDressingKit: true,
        showSecQunatity: false,
        showSecSize: false,
      });
      setIsShowWarningForSecondaryDressing!(false);
      setDressing({
        ...dressing,
        secProductSizeName: {
          value: "",
          valid: ValidationStatus.UNTOUCHED,
        },
        secProductSizeCode: {
          value: "",
          valid: ValidationStatus.VALID,
        },
        secProductName: {
          value: "",
          valid: ValidationStatus.UNTOUCHED,
        },
        secProductId: { value: "", valid: ValidationStatus.VALID },
        secProductCode: { value: "", valid: ValidationStatus.VALID },
      });
    }
  };
  const updateSecondaryWithPrimary = () => {
    const updatedDressingKit: IDressingKit = {
      ...dressing,
      //updating primary with secondary
      productCode: dressing.secProductCode,
      productSizeCode: dressing.secProductSizeCode,
      productSizeName: dressing.secProductSizeName,
      productName: dressing.secProductName,
      productQuantity: dressing.secProductQuantity,
      productId: dressing.secProductId,
      productSizeID: dressing.secProductSizeID,
      //resetting secondary after moving the values
      secProductSizeName: {
        value: "",
        valid: ValidationStatus.VALID,
        required: false,
      },
      secProductSizeCode: {
        value: "",
        valid: ValidationStatus.VALID,
      },
      secProductName: {
        value: "",
        valid: ValidationStatus.UNTOUCHED,
        required: false,
      },
      secProductId: { value: "", valid: ValidationStatus.VALID },
      secProductCode: { value: "", valid: ValidationStatus.VALID },
      secProductQuantity: {
        value: "",
        valid: ValidationStatus.VALID,
      },
      secProductSizeID: { value: "", valid: ValidationStatus.VALID },
    };
    setDressing(updatedDressingKit);
  };

  const handleCloseIcon = () => {
    setIsPeelAndPlaceDressing!(false);
    const isPrimaryAndSecondaryDressingKitOpen =
      supplyOrderObj?.showSupplyOrderVacDressingKit.showPrimaryDressingKit &&
      supplyOrderObj.showSupplyOrderSecondaryVacDressingKit
        .showSecondaryDressingKit;
    let checkSecondaryProductNameNotEmpty =
      supplyOrderObj &&
      supplyOrderObj.dressingKit &&
      supplyOrderObj.dressingKit.secProductName &&
      supplyOrderObj.dressingKit.secProductName.value !== "";
    if (
      isPrimaryAndSecondaryDressingKitOpen &&
      checkSecondaryProductNameNotEmpty
    ) {
      updateSecondaryWithPrimary();
      supplyOrderObj?.setShowSupplyOrderVacDressingKit({
        ...supplyOrderObj?.showSupplyOrderVacDressingKit,
        showPrimaryDressingKit: true,
        showSize:
          supplyOrderObj?.showSupplyOrderSecondaryVacDressingKit.showSecSize,
        showQunatity:
          supplyOrderObj?.showSupplyOrderSecondaryVacDressingKit
            .showSecQunatity,
        isPrimaryVacKitDressingDisabled: false,
        isPrimaryVacKitDressingMinusDisabled:
          supplyOrderObj.dressingKit.secProductQuantity.value === "1",
      });
      supplyOrderObj?.setShowSupplyOrderSecondaryVacDressingKit({
        ...supplyOrderObj?.showSupplyOrderSecondaryVacDressingKit,
        isSecondaryVacKitDressingDisabled: false,
        showSecondaryDressingKit: false,
        isSecondaryVacKitDressingMinusDisabled: true,
      });
    } else {
      setDressing({
        ...dressing,
        productSizeName: {
          value: "",
          valid: ValidationStatus.VALID,
          required: false,
        },
        productSizeCode: { value: "", valid: ValidationStatus.VALID },
        productName: {
          value: "",
          valid: ValidationStatus.UNTOUCHED,
          required: false,
        },
        productId: { value: "", valid: ValidationStatus.VALID },
        productCode: { value: "", valid: ValidationStatus.VALID },
        productQuantity: {
          value: "",
          valid: ValidationStatus.VALID,
        },
      });
      supplyOrderObj?.setShowSupplyOrderVacDressingKit(
        defaultShowSupplyVacDressing
      );
    }
  };

  const handleCloseSecIcon = () => {
    setDressing({
      ...dressing,
      secProductSizeName: {
        value: "",
        valid: ValidationStatus.VALID,
        required: false,
      },
      secProductSizeCode: {
        value: "",
        valid: ValidationStatus.VALID,
      },
      secProductName: {
        value: "",
        valid: ValidationStatus.VALID,
        required: false,
      },
      secProductId: { value: "", valid: ValidationStatus.VALID },
      secProductCode: { value: "", valid: ValidationStatus.VALID },
      secProductQuantity: {
        value: "",
        valid: ValidationStatus.VALID,
      },
    });
    setIsShowWarningForSecondaryDressing!(false);
    supplyOrderObj?.setShowSupplyOrderSecondaryVacDressingKit({
      ...supplyOrderObj?.showSupplyOrderSecondaryVacDressingKit,
      showSecondaryDressingKit: false,
      showSecSize: false,
      showSecQunatity: false,
    });
    let primaryQuantity =
      dressing.productQuantity.value !== ""
        ? parseInt(dressing.productQuantity.value)
        : 0;
    supplyOrderObj?.setShowSupplyOrderVacDressingKit({
      ...supplyOrderObj?.showSupplyOrderVacDressingKit,
      isPrimaryVacKitDressingMinusDisabled: primaryQuantity === 1,
      isPrimaryVacKitDressingDisabled: primaryQuantity >= 3,
    });
  };

  useMemo(() => {
    // Check for primary insurance is Charity Care or not.
    // If it is Cahrity care then setting isCharityCareSelected as true.
    // So based on this variable it will update vacProducts list to dropdown.
    setIsCharityCareSelected(
      primaryInsurance?.toLowerCase() === "Charity Care".toLowerCase() ?? false
    );
  }, []);

  return (
    <>
      <div
        className={`supplyOrderDressingKitInfo ${
          !supplyOrderObj?.isSuppliesSelected
            ? `supplyOrderDressingKitInfoError`
            : ``
        }`}
      >
        <h2
          className="supplydressingKitHeader"
          data-testid="supplyDressingKitHeaderTest"
        >
          3M™ V.A.C.<sup>®</sup> Dressing Kits
        </h2>
        {supplyOrderObj?.showSupplyOrderVacDressingKit
          .showPrimaryDressingKit && (
          <>
            <VacDressing
              isPrimary={true}
              isPrimaryCanClose={true}
              validateAndSetData={(e: any, param: any) =>
                validateAndSetData(e, param, "primary")
              }
              vacProducts={
                isCharityCareSelected
                  ? getVacProductsWithout(
                      vacAllProducts?.items ?? [],
                      PRODUCT_TYPE_DRESSING,
                      SpecialProduct.PEELANDPLACE
                    )
                  : vacProducts
              }
              handleCloseIcon={handleCloseIcon}
              validateAndSetSize={(e: any, param: any) =>
                validateAndSetSize(e, param, "primary")
              }
              showSize={supplyOrderObj?.showSupplyOrderVacDressingKit.showSize}
              vacProductSize={vacProductSize}
              showQunatity={
                supplyOrderObj?.showSupplyOrderVacDressingKit.showQunatity
              }
              handleVacKitDressingPlusClick={
                handlePrimaryVacKitDressingPlusClick
              }
              handleVacKitDressingMinusClick={
                handlePrimaryVacKitDressingMinusClick
              }
              isVacKitDressingPlusDisabled={
                supplyOrderObj?.showSupplyOrderVacDressingKit
                  .isPrimaryVacKitDressingDisabled
              }
              isVacKitDressingMinusDisabled={
                supplyOrderObj?.showSupplyOrderVacDressingKit
                  .isPrimaryVacKitDressingMinusDisabled
              }
              productName={dressing.productName}
              productCode={dressing.productCode}
              productSizeName={dressing.productSizeName}
              productQuantityCode={dressing.productQuantity}
              productLableName="productName"
              productSizeLabelName="productSizeName"
              isPeelAndPlaceDressing={isPeelAndPlaceDressing}
              instruction={instruction}
              isPeelAndPlaceEdgeCase={supplyOrderObj.isPeelAndPlaceEdgeCase}
            />
          </>
        )}
        {supplyOrderObj?.showSupplyOrderSecondaryVacDressingKit
          .showSecondaryDressingKit && (
          <>
            <VacDressing
              isPrimary={false}
              validateAndSetData={(e: any, param: any) =>
                validateAndSetData(e, param, "secondary")
              }
              vacProducts={getVacProductsWithout(
                vacAllProducts?.items ?? [],
                PRODUCT_TYPE_DRESSING,
                SpecialProduct.PEELANDPLACE
              )}
              handleCloseIcon={handleCloseSecIcon}
              validateAndSetSize={(e: any, param: any) =>
                validateAndSetSize(e, param, "secondary")
              }
              showSize={
                supplyOrderObj?.showSupplyOrderSecondaryVacDressingKit
                  .showSecSize
              }
              vacProductSize={vacSecondaryProductSize}
              showQunatity={
                supplyOrderObj?.showSupplyOrderSecondaryVacDressingKit
                  .showSecQunatity
              }
              handleVacKitDressingPlusClick={
                handleSecondaryVacKitDressingPlusClick
              }
              handleVacKitDressingMinusClick={
                handleSecondaryVacKitDressingMinusClick
              }
              isVacKitDressingPlusDisabled={
                supplyOrderObj?.showSupplyOrderSecondaryVacDressingKit
                  .isSecondaryVacKitDressingDisabled
              }
              isVacKitDressingMinusDisabled={
                supplyOrderObj?.showSupplyOrderSecondaryVacDressingKit
                  .isSecondaryVacKitDressingMinusDisabled
              }
              productName={dressing.secProductName}
              productCode={dressing.secProductCode}
              productSizeName={dressing.secProductSizeName}
              productQuantityCode={dressing.secProductQuantity}
              productLableName="secProductName"
              productSizeLabelName="secProductSizeName"
              isShowWarningForSecondaryDressing={
                isShowWarningForSecondaryDressing
              }
              note={note}
            />
          </>
        )}
        {supplyOrderObj?.showSupplyOrderVacDressingKit.showAddDressingBtn && (
          <Grid className="supplyOrderDressingKitBtnGrid">
            <Grid item xs={12}>
              <Button
                classes={{ root: "supplyOrder-dressingKit-add-button" }}
                data-testid="supply-dressingKit-add-button"
                onClick={addDressingKitClick}
                startIcon={<AddIcon classes={{ root: "dressingadd-icon" }} />}
              >
                Add Dressing Kit
              </Button>
            </Grid>
          </Grid>
        )}
      </div>
      <div>
        <SupplyOrderVacCannister
          canister={canister}
          cannisterProduct={cannisterProduct}
          data={data}
          replenishSupplyOrderData={replenishSupplyOrderData}
          setCanister={setCanister}
          vacProductInfo={vacProductInfo}
        />
      </div>
    </>
  );
};
