import {
  IInputField,
  ValidationStatus,
} from "../../../../core/interfaces/input.interface";
import { IAdditionalInfoData } from "../additionalInformation/additionalInformation.component";

export const defaultNewPrescriberData: IInputField = {
  valid: ValidationStatus.UNTOUCHED,
  value: null,
  required: true,
};

export const defaultNewEmailData: IInputField = {
  valid: ValidationStatus.UNTOUCHED,
  value: "",
  required: false,
};
export const defaultNewEmailDataMultiple: IInputField = {
  valid: ValidationStatus.UNTOUCHED,
  value: {
    isDefault: false,
    value: "",
  },
  required: false,
}
export const defaultAdditionalInfoData: IAdditionalInfoData = {
  additionalInformation: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
  },
};
