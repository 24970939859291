import "./siteStatusLocation.css";
import { AddSiteStatusValidator } from "../addSiteStatus.validator";
import { Grid, useMediaQuery } from "@mui/material";
import { InputWithLabel } from "../../../../../core/inputWithLabel/inputWithLabel.component";
import { ValidationStatus } from "../../../../../core/interfaces/input.interface";
import { CustomCheckBox } from "../../../../../core/checkBox/checkBox.component";
import { IAddSiteStatus } from "../addSiteStatus.interface";
import { MultipleActionCheckBoxProps } from "./siteStatusLocation.data";

export interface ISiteStatusLocation {
  Validator?: AddSiteStatusValidator;
  data: IAddSiteStatus;
  setData: Function;
}

export const SiteStatusLocation = ({ data, setData }: ISiteStatusLocation) => {
  const isMobileScreen = useMediaQuery("(max-width:920px)");

  const validatesiteStatusLocations = (e: any) => {
    let selectedItemId: string;
    selectedItemId = "";
    data.location.value.forEach((item: MultipleActionCheckBoxProps) => {
      if (item.id === e.target.name) {
        item.selected = e.target.checked;
        selectedItemId = item.parentId;
      } else {
        if (item.childId === selectedItemId) {
          item.disabled = !e.target.checked;
          if (item.disabled) {
            item.selected = false;
          }
        }
      }
    });
    const valid = data.location.value.every((x: any) => x.selected === false);
    setData({
      ...data,
      location: {
        ...data.location,
        errorMessage: null,
        valid: valid ? ValidationStatus.INVALID : ValidationStatus.VALID,
        value: data.location.value,
        required: true,
      },
    });
  };

  return (
    <div
      className="site-status-location-component"
      data-testid="site-status-location-component"
    >
      <p
        className="site-status-location-header"
        data-testid="site-status-location-header"
      >
        Locations
      </p>
      <InputWithLabel
        label="Where on 3M™ Express Therapy Portal should this message appear?"
        isRequired={data.location.required}
        id={data.location.componentId!}
        isShowWarning={true}
        error={data.location.valid === ValidationStatus.INVALID}
        labelClassName="site-status-location-label"
        warningMessage={data.location.errorMessage}
        testId="site-status-location-label"
      >
        <Grid container xs={10} className="site-status-location-check-block">
          {data.location.value.map(
            (x: MultipleActionCheckBoxProps, index: number) => (
              <Grid item xs={isMobileScreen ? 12 : 5}>
                <div className="site-status-location-checkbox-item">
                  <CustomCheckBox
                    name={x.id}
                    selectClassName="site-status-location-checkbox"
                    selectpropsClassName="site-status-location-checkbox-root"
                    handleChange={validatesiteStatusLocations}
                    labelClassName={"site-status-location-chkbox-desp"}
                    checked={x.selected}
                    value={x.value}
                    key={index}
                    required={false}
                    labelText={x.label}
                    testId={x.value}
                    isDisabled={x.disabled}
                  />
                </div>
              </Grid>
            )
          )}
        </Grid>
      </InputWithLabel>
    </div>
  );
};
