import AddIcon from "@mui/icons-material/AddCircleOutline";
import Table from "../../../../core/customSortingTable/table.component";
import { ExpressButton } from "../../../../core/expressButton/expressButton.component";
import { makeCapitalEachWordInString } from "../../../../util/utilityFunctions";
import {
  IHomeCareProviderDetail,
  IMyListEnabledHCP,
} from "../homeCareProvider.interface";
import "./homeCareProviderList.css";

const HomeCareProviderList = ({
  columns,
  homeCareProviderList,
  handleSorting,
  displayAddManualHomeCare,
  handleSelectOption,
}: IMyListEnabledHCP) => {
  return (
    <>
      <div className="hcp-table" data-testId="hcp-table">
        <div className="hcplist-table">
          <Table
            tableClassName="table"
            tableColumns={columns}
            handleSorting={handleSorting}
          >
            {homeCareProviderList && homeCareProviderList.length > 0 ? (
              <tbody>
                {homeCareProviderList.map(
                  (data: IHomeCareProviderDetail, index) => {
                    return (
                      <tr>
                        <td
                          className="select-patient-link"
                          onClick={() => handleSelectOption(data)}
                          data-testId={"hcp-col-select" + index}
                        >
                          Select
                        </td>
                        <td
                          className="table-static-data"
                          data-testId="hcp-col-facility"
                        >
                          <span>{makeCapitalEachWordInString(data.name)}</span>
                          <br />
                          <span>
                            {`${makeCapitalEachWordInString(data.address1)}, ${
                              data.address2 && data.address2 !== ""
                                ? `${makeCapitalEachWordInString(
                                    data.address2
                                  )},`
                                : ""
                            } ${makeCapitalEachWordInString(data.city)}, ${
                              data.state
                            } ${data.zipCode}`}
                          </span>
                        </td>
                        <td
                          className="table-static-data"
                          data-testId={"hcp-col-accnum" + index}
                        >
                          {data.accountNumber}
                        </td>
                        <td
                          className="table-static-data"
                          data-testId={"hcp-col-facility-type" + index}
                        >
                          {makeCapitalEachWordInString(data.facilityType)}
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            ) : (
              ""
            )}
          </Table>
        </div>
      </div>
      <div className="addmanualHCPBtn">
        <ExpressButton
          startIcon={<AddIcon />}
          clickHandler={displayAddManualHomeCare}
          parentClass="hcp-manualAdd-btn"
          variant="outlined"
          testId="hcp-manualAdd-btn"
        >
          Add New Home Care Provider
        </ExpressButton>
      </div>
    </>
  );
};

export default HomeCareProviderList;
