import { IUserProfile } from "./userProfile.interface";
import { ValidationStatus } from "../../../../core/interfaces/input.interface";

export let defaultUserProfileData: IUserProfile = {
  // account information
  firstName: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: true,
  },
  lastName: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: true,
  },
  userName: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: true,
    errorMessage: null,
  },
  licenseType: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: true,
  },
  department: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: true,
  },
  title: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: true,
  },

  // contact information
  email: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: true,
    errorMessage: null,
  },
  phone: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: true,
  },
  phoneType: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
  },
  extension: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
  },

  // site access
  facilities: [],

  // requested sites
  unlinkedFacilities: null,
};
