import "./cart.css";
import { IconButton, useMediaQuery } from "@mui/material";
import { ICart } from "./cart.interface";
import { CartItem } from "./cartItem/cartItem.component";
import { ReactComponent as CloseIcon } from "../../../../assets/popupcloseicon.svg";
import { ICartItem } from "./cartItem/cartItem.interface";

export const Cart = ({
  cartState,
  deleteCartItem,
  handleClose,
  updateItemQuantity,
}: ICart) => {
  const isMobileScreen = useMediaQuery("(max-width:600px)");
  return (
    <div className="cart-component" data-testid="cart-component">
      <div className="cart-header" data-testid="cart-header">
        <p className="cart-title" data-testid="cart-title" id="cart-title">
          {`Your Order (${cartState.totalQuantity})`}
        </p>
        <IconButton
          className="cart-close-button"
          data-testid="cart-close-button"
          onClick={() => handleClose()}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <div
        className={`cart-item-container ${
          isMobileScreen && cartState.totalQuantity > 0 ? "add-border" : ""
        }`}
      >
        {cartState.cartItems.length === 0 ? (
          <div className="no-cart-item" data-testid="no-cart-item">
            <p
              className="no-cart-item-title"
              data-testid="no-cart-item-title"
              id="no-cart-item-title"
            >
              No products have been added to your order
            </p>
          </div>
        ) : (
          cartState.cartItems.map((cartItem: ICartItem) => (
            <div
              className="cart-items"
              data-testid={`cart-items-${cartItem.sku}`}
              key={cartItem.sku}
            >
              <CartItem
                key={cartItem.sku}
                cartItem={cartItem}
                deleteItem={deleteCartItem}
                updateItemQuantity={updateItemQuantity}
              />
            </div>
          ))
        )}
      </div>
      <div className="cart-price-details" data-testid="cart-price-details">
        <p
          className="cart-price-details-title"
          data-testid="cart-price-details-title"
          id="cart-price-details-title"
        >
          Price
        </p>
        <p
          className="cart-price-details-description"
          data-testid="cart-price-details-description"
          id="cart-price-details-description"
        >
          {"Note: "}
          <span
            className="cart-price-details-description-note"
            data-testid="cart-price-details-description-note"
            id="cart-price-details-description-note"
          >
            The total shown is before tax has been applied.
          </span>
        </p>
        <div
          className="cart-pricing-details"
          data-testid="cart-pricing-details"
        >
          <p
            className="cart-pricing-details-title"
            data-testid="cart-pricing-details-title"
            id="cart-pricing-details-title"
          >
            Total Product Price
          </p>
          <p
            className="cart-pricing-details-value"
            data-testid="cart-pricing-details-value"
            id="cart-pricing-details-value"
          >
            {`$ ${
              cartState.totalCartPrice > 0
                ? cartState.totalCartPrice.toFixed(2)
                : "--"
            }`}
          </p>
        </div>
        <div
          className="cart-shipping-pricing-details"
          data-testid="cart-shipping-pricing-details"
        >
          <p
            className="cart-shipping-pricing-details-title"
            data-testid="cart-shipping-pricing-details-title"
            id="cart-shipping-pricing-details-title"
          >
            Shipping Total
          </p>
          <p
            className="cart-shipping-pricing-details-value"
            data-testid="cart-shipping-pricing-details-value"
            id="cart-shipping-pricing-details-value"
          >
            {` ${cartState.totalCartPrice > 0 ? "pending" : "$ --"}`}
          </p>
        </div>
        <div
          className="cart-total-pricing-details"
          data-testid="cart-total-pricing-details"
        >
          <p
            className="cart-total-pricing-details-title"
            data-testid="cart-total-pricing-details-title"
            id="cart-total-pricing-details-title"
          >
            Order Total
          </p>
          <p
            className="cart-total-pricing-details-value"
            data-testid="cart-total-pricing-details-value"
            id="cart-total-pricing-details-value"
          >
            {`$ ${
              cartState.totalCartPrice > 0
                ? cartState.totalCartPrice.toFixed(2)
                : "--"
            }`}
          </p>
        </div>
      </div>
    </div>
  );
};
