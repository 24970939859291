import { NewOrderValidator } from "../newOrder.validator";
import { ValidationStatus } from "../../../core/interfaces/input.interface";
import { INewOrderWoundInfo } from "../newOrderWoundInfoStepper/newOrderWoundInfo.interface";

export enum QuestionTypes {
  RADIO = "RadioButton",
  TEXT = "Text",
  DATE = "Date",
}
export type ClinicalInforProps = {
  woundInfoData: INewOrderWoundInfo;
  setWoundInfoData: Function;
  Validator?: NewOrderValidator;
};

export type Question = {
  componentId?: string | null;
  errorMessage?: string | null;
  id?: string | null;
  isRequiredToShow?: boolean;
  order?: number | null;
  required: string;
  text: string;
  type: QuestionTypes;
  valid: ValidationStatus;
  value: string;
};

export type WoundQuestionaries = {
  woundType: string;
  category: string;
  additionalQuestion: Question[];
};
