import moment from "moment";
import { Grid, useMediaQuery } from "@mui/material";
import "./enterPickupInformationReview.css";
import { IEnterPickUpInformationLocation } from "../enterPickupInformation.interface";
import { convertStringToDate } from "../../../../../util/utilityFunctions";
import { ShowTitleAndValue } from "../../../../../core/showTitleAndValue/showTitleAndValue.component";

interface Props {
  data: IEnterPickUpInformationLocation;
  editButtonAction: any;
  isSummaryOrder?: boolean;
  isCustomerOwnedProduct?: boolean;
}

export const EnterPickupInformationReview = ({
  data,
  editButtonAction,
  isSummaryOrder = false,
  isCustomerOwnedProduct = false,
}: Props) => {
  const isMobileScreen = useMediaQuery("(max-width:450px)");
  const currentDate = new Date().toString();

  return (
    <div
      className="pickup-information-review-component"
      data-testid="pickup-information-review-component"
      id="pickup-information-review-component"
    >
      <div
        className="pickup-information-review-header"
        id="id_pickup-information-review-header"
      >
        <p
          className="pickup-information-review-title"
          data-testid="pickup-information-review-title"
          id="pickup-information-review-title-id"
        >
          Pickup Information
        </p>
        {!isSummaryOrder && (
          <button
            className="pickup-information-review-edit-button"
            data-testid="pickup-information-review-edit-button"
            id="pickup-information-review-edit-button-id"
            onClick={() =>
              editButtonAction("enter-pickup-information-component")
            }
          >
            Edit
          </button>
        )}
      </div>
      <Grid
        className="pickup-information-review-grid-container"
        container
        id="id_pickup-information-review-grid-container"
      >
        <Grid
          className="pickup-information-review-grid-item"
          id="id_pickup-information-review-grid-item"
          item
          xs={6}
        >
          <ShowTitleAndValue
            title="Therapy Discharge Date"
            value={convertStringToDate(data.therapyDischargeDate.value)}
            testId="pickup-therapy-discharge-date"
          />
        </Grid>
        <Grid
          className="pickup-information-review-grid-item"
          item
          id="id_pickup-information-review-grid-item"
          xs={6}
        >
          <ShowTitleAndValue
            title="Stop Bill Date"
            value={convertStringToDate(currentDate)}
            testId="pickup-stop-bill-date"
          />
        </Grid>
        {isCustomerOwnedProduct ? (
          <Grid
            className="pickup-information-review-grid-item"
            item
            xs={isMobileScreen ? 12 : 6}
          >
            <ShowTitleAndValue
              title="Pickup Location"
              value={data.pickupLocation.value}
              testId="pickup-location"
            />
          </Grid>
        ) : (
          <Grid
            className="pickup-information-review-grid-item"
            item
            xs={isMobileScreen ? 12 : 6}
          >
            <ShowTitleAndValue
              title="Patient Room Number"
              value={data.roomNo.value}
              testId="patient-room-number"
            />
          </Grid>
        )}
        <Grid
          className="pickup-information-review-grid-item"
          item
          xs={isMobileScreen ? 12 : 6}
        >
          <ShowTitleAndValue
            title="Special Instructions"
            value={data.specialInstructions.value}
            testId="pickup-request-special-instructions"
          />
        </Grid>
      </Grid>
    </div>
  );
};
