import { Button, Grid } from "@mui/material";
import "./moveUnitToStoredProductSuccess.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { IInventoryProductDetails } from "../../../inventoryTabContainer.enum";
import parse from "html-react-parser";

interface IMoveUnitToStoredProductSuccessProps {
  closePopUp: Function;
  isMoveStoredProduct: boolean;
  selectedProdcut: IInventoryProductDetails;
}

export const MoveUnitToStoredProductSuccess = ({
  closePopUp,
  isMoveStoredProduct,
  selectedProdcut,
}: IMoveUnitToStoredProductSuccessProps) => {
  return (
    <div className="move-unit-to-stored-product-success-component">
      <p className="title" data-testid="title">
        Unit Listing Updated
      </p>
      <Grid className="grid-container" container>
        <Grid className="grid-item" xs={12}>
          <div className="success">
            <CheckCircleIcon className="success-icon" />
            <p className="success-text">
              <span>
                {parse(
                  `${
                    isMoveStoredProduct
                      ? "Success! The unit has been moved to the Stored Product"
                      : "Success! The unit has been moved to the V.A.C.<sup>®</sup> Ready Care Listing"
                  }`
                )}
              </span>
            </p>
          </div>
        </Grid>
        <Grid className="grid-item" xs={6}>
          <div className="product-details">
            <p className="product-detail-title">Product</p>
            <p className="product-detail-value">{selectedProdcut.product}</p>
          </div>
        </Grid>
        <Grid className="grid-item" xs={6}>
          <div className="product-details">
            <p className="product-detail-title">Serial Number</p>
            <p className="product-detail-value">
              {selectedProdcut.serialNumber}
            </p>
          </div>
        </Grid>
        <Grid className="grid-item top-space" xs={12}>
          <div className="product-details">
            <p className="product-detail-title">Storage Location</p>
            <p className="product-detail-value">
              {selectedProdcut.storageLocation}
            </p>
          </div>
        </Grid>
        <Grid className="grid-item" xs={12}>
          <Button
            classes={{ root: "return-to-inventory-button" }}
            data-testid="return-to-inventory-button"
            onClick={() => closePopUp()}
            variant="contained"
          >
            Return to Inventory
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
