import "./additionalInformation.css";
import { useState } from "react";
import { Grid, InputBase } from "@mui/material";
import { SendNoteValidator } from "../../sendNote.validator";
import {
  IInputField,
  ValidationStatus,
} from "../../../../core/interfaces/input.interface";
import { InputWithLabel } from "../../../../core/inputWithLabel/inputWithLabel.component";

export interface IAdditionalInformation {
  data: IAdditionalInfoData;
  setData: Function;
  Validator?: SendNoteValidator;
}

export interface IAdditionalInfoData {
  additionalInformation: IInputField;
}
export const AdditionalInformation = ({
  data,
  setData,
  Validator = new SendNoteValidator(),
}: IAdditionalInformation) => {
  const [validator] = useState<SendNoteValidator>(Validator!);

  const validateAndSetData = (e: any) => {
    const { name, value } = e.target;
    const isValid = validator.validate(value, name);
    const tempData = {
      ...data,
      [name]: {
        ...Object(data)[name],
        errorMessage: null,
        valid: isValid!.status,
        value: value,
      },
    };
    setData(tempData);
  };

  return (
    <div
      className="additional-info-component"
      data-testid="additional-info-component"
    >
      <Grid className="additional-info-grid-container" container>
        <Grid className="additional-info-grid-item" item xs={12}>
          <InputWithLabel
            label={"Additional Information"}
            labelClassName="additional-info-input-label"
            error={
              data?.additionalInformation?.valid === ValidationStatus.INVALID
            }
            isRequired={data.additionalInformation.required}
            testId="question-input-label"
          >
            <InputBase
              className="additional-info-input"
              data-testid="question-input-value"
              error={
                data?.additionalInformation?.valid === ValidationStatus.INVALID
              }
              inputProps={{
                className:
                  data?.additionalInformation?.valid ===
                  ValidationStatus.INVALID
                    ? "showCommentError"
                    : "noCommentError",
                maxLength: 100,
              }}
              multiline={true}
              name="additionalInformation"
              onChange={validateAndSetData}
              rows={4}
              value={data?.additionalInformation?.value}
            />
          </InputWithLabel>
        </Grid>
      </Grid>
    </div>
  );
};
