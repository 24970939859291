import { ValidationStatus } from "../../../core/interfaces/input.interface";
import { comorbiditiesData } from "../comorbodities/comorbodities.data";
import { nutriActionData } from "../nutrition/nutritionAction.data";
import {
  osteomyelitisies,
  previousTherapy,
  previousTherapyCause,
} from "../previousTherapies/previousTherapiesData";
import { INewOrderWoundInfo } from "./newOrderWoundInfo.interface";

export let defaultNewOrderWoundInfoData: INewOrderWoundInfo = {
  // Nutrition
  nutriStatusCompromized: {
    componentId: "nutrition-nutri-status-compromized",
    errorMessage: "Nutistatus compromised is required",
    isShowWarning: true,
    order: 1,
    required: true,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    title: "Nutrition",
  },
  nutritionActions: {
    componentId: "nutrition-nutrition-actions-check-boxes",
    defaultRequired: false,
    order: 2,
    required: true,
    title: "Nutrition Action",
    valid: ValidationStatus.UNTOUCHED,
    value: nutriActionData,
  },
  // Previous therapy
  previousTherapies: {
    componentId: "previous-therapies-check-boxes",
    order: 3,
    required: true,
    title: "Previous Therapies",
    valid: ValidationStatus.UNTOUCHED,
    value: previousTherapy,
  },
  previousTherapyOther: {
    componentId: "previous-therapies-other",
    id: "previous-therapies-other-input",
    order: 4,
    required: false,
    title: "Other",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  previousTherapiesCauses: {
    componentId: "previous-therapies-causes-check-boxes",
    order: 5,
    required: true,
    title: "Previous Therapies Cause",
    valid: ValidationStatus.UNTOUCHED,
    value: previousTherapyCause,
  },
  previousTherapiesCausesOther: {
    componentId: "previous-therapies-causes-other",
    id: "previous-therapies-causes-other-input",
    order: 6,
    required: false,
    title: "Other",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },

  // Comorbodities
  wndInfoComorbidities: {
    componentId: "comorbidities-check-boxes",
    order: 7,
    value: comorbiditiesData,
    valid: ValidationStatus.UNTOUCHED,
    required: true,
    title: "Comorbidities",
  },
  wndInfoComorbiditiesOther: {
    componentId: "comorbidities-checkbox-other",
    id: "comorbidities-other-input",
    order: 9,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
    title: "Other",
  },
  wndInfoComorbiditiesDiabetese: {
    componentId: "comorbidities-checkbox-diabetes",
    order: 8,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
    title: "Comprehensive Diabetic Management Program",
  },

  // Osteomyelitis
  isOsteomyelitisPresent: {
    componentId: "osteomyelitis-present-radio-button-check",
    order: 10,
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: true,
    title: "Osteomyelitis",
  },
  osteomyelitisies: {
    componentId: "osteomyelitis-check",
    order: 11,
    value: osteomyelitisies,
    valid: ValidationStatus.UNTOUCHED,
    required: false,
    title: "Treatment Regimen / Antibiotic Name",
  },
  isTreatemenForResolveBoneInfection: {
    componentId: "osteomyelitis-bone-infection-radio",
    order: 14,
    value: "",
    valid: ValidationStatus.VALID,
    required: true,
    title: "Treatment For Resolving Bone Infection",
  },
  // Debridement
  debridementAttempted: {
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
    title: "Debridement Attempted",
  },
  serialDebridementRequired: {
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
    title: "Serial Debridement",
  },
  debridementDate: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
    title: "Debridement Date",
    componentId: "debridement-date-picker",
    id: "debridement-date-input",
    order: 39,
  },
  debridementType: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
    title: "Debridement Type",
    componentId: "debridement-type-drop-down",
    order: 38,
  },
  woundTunneling: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
    title: "Wound Tunneling",
    componentId: "primary-wound-tunneling",
    order: 52,
  },
  location1Depth: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
    title: "Location Depth",
    componentId: "primary-wound-tunneling-location1-depth",
    id: "primary-wound-tunneling-location1-depth-input",
    order: 53,
  },
  location1Position: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
    title: "Clock Position",
    componentId: "primary-wound-tunneling-location1-position",
    order: 54,
  },
  location2Depth: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
    title: "Location Depth",
    componentId: "primary-wound-tunneling-location2-depth",
    id: "primary-wound-tunneling-location2-depth-input",
    order: 55,
  },
  location2Position: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
    title: "Clock Position",
    componentId: "primary-wound-tunneling-location2-position",
    order: 56,
  },
  woundMeasurementDate: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
    title: "wound Measurement Date",
    componentId: "wound-measurement-date-picker",
    order: 40,
    id: "wound-measurement-date-input",
  },
  woundLength: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
    title: "Length",
    componentId: "wound-measurement-length",
    order: 41,
    id: "wound-measurement-length-input",
  },
  woundWidth: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
    title: "Width",
    componentId: "wound-measurement-width",
    order: 42,
    id: "wound-measurement-width-input",
  },
  woundDepth: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
    title: "Depth",
    componentId: "wound-measurement-depth",
    order: 43,
    id: "wound-measurement-depth-input",
  },
  woundThickness: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
    title: "Wound Thickness",
    componentId: "wound-measurement-thickness",
    order: 44,
  },
  // wound exudate
  exudateAmount: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
    title: "Exudate Amount",
    componentId: "primary-wound-exudate",
  },
  exudateAppearance: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
    title: "Exudate Apperance",
    componentId: "primary-wound-exudate-appearance",
    order: 58,
  },

  // exposed tissue
  exposedTissue: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: true,
    title: "Exposed Tissue",
    componentId: "exposed-tissue",
    id: "exposed-tissue-id",
    order: 59,
  },

  // woundbed
  granulationValue: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
    title: "Granulation Value",
  },
  epthilizationValue: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
    title: "Epithelialization Value",
  },
  sloughValue: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
    title: "Slough Value",
  },
  escharValue: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
    title: "Eschar Value",
  },
  woundBedTotal: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
    title: "Wound Bed Total",
    componentId: "primary-wound-bed-total",
    order: 57,
  },

  // Clinical Information
  shortNarrativeOfPossibleConsequences: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
    title: "Short Narrative",
    componentId: "clinical-information-short-narrative",
    id: "clinical-information-short-narrative-input",
    order: 15,
  },
  woundType: {
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: true,
    title: "Wound Type",
    componentId: "wound-type-dropdown",
    order: 16,
  },
  woundAge: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: true,
    title: "Wound Age",
    componentId: "wound-age-title",
    id: "wound-age-input-field",
    order: 35,
  },
  woundAgeType: {
    valid: ValidationStatus.VALID,
    value: "2",
    required: true,
    title: "woundAgeType",
  },
  woundLocation: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: true,
    title: "Wound Location",
    componentId: "wound-location-dropdown",
    order: 36,
  },
  woundDirection: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
    title: "Wound Direction",
  },
  woundOrientation: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
    title: "Wound Orientation",
  },
  isTissuePresent: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: true,
    title: "Eschar tissue present",
    componentId: "wound-eschar-tissue-present",
    order: 37,
  },
  woundUndermining: {
    componentId: "primary-wound-undermining",
    order: 45,
    required: false,
    title: "Wound Undermining",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  underminingLocation1Depth: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
    title: "Location Depth",
    componentId: "primary-wound-undermining-location1Depth",
    id: "primary-wound-undermining-location1Depth-input",
    order: 46,
  },
  underminingLocation1PositionFrom: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
    title: "Starting Position",
    componentId: "primary-wound-undermining-location1-from",
    order: 47,
  },
  underminingLocation1PositionTo: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
    title: "Ending Position",
    componentId: "primary-wound-undermining-location1-to",
    order: 48,
  },
  underminingLocation2Depth: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
    title: "Location Depth",
    componentId: "primary-wound-undermining-location2Depth",
    id: "primary-wound-undermining-location2Depth-input",
    order: 49,
  },
  underminingLocation2PositionFrom: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
    title: "Starting Position",
    componentId: "primary-wound-undermining-location2-from",
    order: 50,
  },
  underminingLocation2PositionTo: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
    title: "Ending Position",
    componentId: "primary-wound-undermining-location2-to",
    order: 51,
  },
  isShowSecondaryWoundInfo: {
    valid: ValidationStatus.VALID,
    value: "No",
    isDefaultValid: true,
    title: "Secondary Wound Info",
  },
};
