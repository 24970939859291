import { Box, Grid, InputBase } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import InputMask from "react-input-mask";
import { AuthContext, AuthContextType } from "../../../context/AuthContext";
import { MobileDisplayContext } from "../../../context/MobileDisplayContext";
import {
  NewOrderContext,
  NewOrderContextType,
} from "../../../context/NewOrderContext";
import { CustomCheckBox } from "../../../core/checkBox/checkBox.component";
import { ExpressButton } from "../../../core/expressButton/expressButton.component";
import { InputWithLabel } from "../../../core/inputWithLabel/inputWithLabel.component";
import { ValidationStatus } from "../../../core/interfaces/input.interface";
import { Popup } from "../../../core/popup/popup.component";
import { Validator } from "../../../util/order.validations";
import { AddFacilityContainer } from "../../manageProfile/facilityInformation/addFacilityContainer/addFacilityContainer.component";
import { AddFacilityContext } from "../../manageProfile/facilityInformation/addFacilityContainer/addFacilityContainer.context";
import { IFacility } from "../../manageProfile/facilityInformation/facility.interface";
import { FacilityInfoDetail } from "../../manageProfile/facilityInformation/facilityInfoDetail.component";
import { IRequesterInfo } from "../newOrder.interface";
import { NewOrderValidator } from "../newOrder.validator";
import { VerifyRequesterInfoReviewOrder } from "./reviewOrder/verifyRequesterInfoReviewOrder.component";
import "./verifyRequesterInfo.css";
import { IVerifyRequesterInfo } from "./verifyRequesterInfo.interface";
import removeAccents from "remove-accents";
export const VerifyRequesterInfo = ({
  requesterInfo,
  requestValidator = new NewOrderValidator(),
  setRequesterInfo,
  isReviewOrder = false,
  editButtonClicked,
  isOrderSummary = false,
  isVacOrderFromSSO = false,
  data,
  setData = () => {},
}: IVerifyRequesterInfo) => {
  const [validator] = React.useState<NewOrderValidator>(requestValidator!);
  const [facility, setFacility] = useState<IFacility>();
  const [open, setOpen] = useState<boolean>(false);
  const { isMobileScreen } = useContext(MobileDisplayContext);
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const newOrderObj = useContext<NewOrderContextType | null>(NewOrderContext);

  const [focusClasses, setFocusClasses] = React.useState({
    requesterPhoneNumber: "",
  });

  const setClasses = (e: any, classname: string) => {
    setFocusClasses(
      Object.assign({}, focusClasses, { [e.target.name]: classname })
    );
  };

  useEffect(() => {
    if (
      requesterInfo.requesterFacilityAsDefault === false &&
      requesterInfo.requesterFacility !== null
    ) {
      setFacility(requesterInfo.requesterFacility!);
    } else if (
      authObj?.userProfile?.facilities.length !== 0 ||
      authObj?.isInternalUser
    ) {
      updateRegisteredFacility();
    }
  }, [authObj?.userProfile?.facilities, authObj?.registeredFaciltyAddress]);

  useEffect(() => {
    if (
      requesterInfo.requesterFacilityAsDefault === false &&
      requesterInfo.requesterFacility !== null
    ) {
      setFacility(requesterInfo.requesterFacility!);
    }
  }, [requesterInfo.requesterFacility]);

  useEffect(() => {
    if (isVacOrderFromSSO && !newOrderObj?.isComingFromPrev) {
      requesterInfo.IsRequesterSameasSubmitter.value = "no";
    }
  }, []);

  useEffect(() => {
    if (newOrderObj?.newOrderData?.requestingFacility?.value !== null) {
      setFacility(newOrderObj?.newOrderData?.requestingFacility?.value);
      requesterInfo.requesterFacility =
        newOrderObj?.newOrderData?.requestingFacility?.value;
    }
  }, [newOrderObj?.newOrderData?.requestingFacility?.value]);

  useEffect(() => {
    if (
      authObj?.userProfile &&
      !newOrderObj?.isComingFromPrev &&
      !isReviewOrder
    ) {
      if (
        requesterInfo!.IsRequesterSameasSubmitter.value.toLowerCase() === "yes"
      ) {
        updateRequesterBasicDetails();
      } else {
        resetRequesterBasicDetails();
      }
    }
  }, [authObj?.userProfile]);

  const validateAndSetData = (e: any) => {
    let { value, name } = e.target;
    if (name === "requesterFirstName" || name === "requesterLastName") {
      value = removeAccents(value);
    }
    if (
      name === "requesterPhoneNumber" &&
      requesterInfo.requesterPhoneNumber.valid === ValidationStatus.UNTOUCHED &&
      (value === "(___) ___-____" || value === "")
    ) {
      return;
    }
    newOrderObj?.setIsHandleChangeTriggered(true);
    let isValid = validator.validate(value, name);
    setRequesterInfo((dt: IRequesterInfo) => ({
      ...dt,
      [name]: {
        ...Object(dt)[name],
        errorMessage: null,
        valid: isValid!.status,
        value: value,
      },
    }));
  };

  const updateRegisteredFacility = () => {
    if (authObj?.registeredFaciltyAddress) {
      const userFacility = authObj?.registeredFaciltyAddress!;
      requesterInfo.requesterFacility = userFacility;
      requesterInfo.requesterFacilityAsDefault = true;
      setFacility(userFacility);
    }
  };

  const updateRequesterBasicDetails = () => {
    if (authObj && authObj.userProfile) {
      if (authObj.userProfile.facilities.length !== 0) {
        updateRegisteredFacility();
      }
      const firstName = authObj.userProfile?.firstName
        ? removeAccents(authObj.userProfile.firstName)
        : "";
      const lastName = authObj.userProfile?.lastName
        ? removeAccents(authObj.userProfile.lastName)
        : "";
      const title = authObj.userProfile.title;
      const emailAddress = authObj.userProfile.emailAddress;
      const phoneNumber =
        authObj.userProfile.phoneNumber || authObj.userProfile.mobilePhoneNumber
          ? authObj.userProfile.phoneNumber
            ? authObj.userProfile.phoneNumber
            : authObj.userProfile.mobilePhoneNumber
          : "";
      setRequesterInfo((dt: IRequesterInfo) => ({
        ...dt,
        IsRequesterSameasSubmitter: {
          ...dt.IsRequesterSameasSubmitter,
          errorMessage: null,
          valid: ValidationStatus.VALID,
          value: "yes",
        },
        requesterFirstName: {
          ...dt.requesterFirstName,
          errorMessage: null,
          valid:
            firstName !== ""
              ? validator.validate(firstName, "requesterFirstName")?.status
              : ValidationStatus.UNTOUCHED,
          value: firstName,
        },
        requesterLastName: {
          ...dt.requesterLastName,
          errorMessage: null,
          valid:
            lastName !== ""
              ? validator.validate(lastName, "requesterLastName")?.status
              : ValidationStatus.UNTOUCHED,
          value: lastName,
        },
        requesterTitle: {
          ...dt.requesterTitle,
          errorMessage: null,
          required: false,
          valid:
            title && title !== ""
              ? ValidationStatus.VALID
              : ValidationStatus.UNTOUCHED,
          value: title,
        },
        requesterEmail: {
          ...dt.requesterEmail,
          errorMessage: null,
          valid:
            emailAddress !== ""
              ? validator.validate(emailAddress, "requesterEmail")?.status
              : ValidationStatus.UNTOUCHED,
          value: emailAddress,
        },
        requesterPhoneNumber: {
          ...dt.requesterPhoneNumber,
          errorMessage: null,
          valid:
            phoneNumber && phoneNumber !== ""
              ? validator.validate(phoneNumber, "requesterPhoneNumber")?.status
              : ValidationStatus.UNTOUCHED,
          value: phoneNumber,
        },
      }));
    }
  };

  const resetRequesterBasicDetails = () => {
    setRequesterInfo((dt: IRequesterInfo) => ({
      ...dt,
      IsRequesterSameasSubmitter: {
        ...dt.IsRequesterSameasSubmitter,
        errorMessage: null,
        valid: ValidationStatus.VALID,
        value: "no",
      },
      requesterFirstName: {
        ...dt.requesterFirstName,
        errorMessage: null,
        valid: ValidationStatus.UNTOUCHED,
        value: "",
      },
      requesterLastName: {
        ...dt.requesterLastName,
        errorMessage: null,
        valid: ValidationStatus.UNTOUCHED,
        value: "",
      },
      requesterTitle: {
        ...dt.requesterTitle,
        errorMessage: null,
        required: true,
        valid: ValidationStatus.UNTOUCHED,
        value: "",
      },
      requesterEmail: {
        ...dt.requesterEmail,
        errorMessage: null,
        valid: ValidationStatus.UNTOUCHED,
        value: "",
      },
      requesterPhoneNumber: {
        ...dt.requesterPhoneNumber,
        errorMessage: null,
        valid: ValidationStatus.UNTOUCHED,
        value: "",
      },
    }));
  };

  const handlecheckBoxChange = async (e: any) => {
    if (e.target.checked) {
      updateRequesterBasicDetails();
    } else {
      resetRequesterBasicDetails();
    }
  };

  const changeFacilityButtonClick = () => {
    setOpen(true);
  };

  const addNewFacility = (newFacility: IFacility) => {
    setFacility(newFacility);
    requesterInfo.requesterFacility = newFacility;
    requesterInfo.requesterFacilityAsDefault = false;
    setData({
      ...data,
      inpatientFacility: newFacility,
      inpatientFacilityAsDefault: false,
    });
    setOpen(false);
  };

  return (
    <div className="verify-requester-info-component">
      {!isReviewOrder && (
        <div className="verify-requester-info">
          <div className="verify-requester-info-header">
            <div className="verify-requester-info-title-div">
              <h2
                className="verify-requester-info-title"
                data-testid="verify-requester-infoTest"
              >
                Verify Requester Info
              </h2>
            </div>
          </div>
          <Box
            className="verify-requester-info-box-container"
            sx={{ flexGrow: 1 }}
          >
            <Grid
              className="verify-requester-info-grid-container"
              container
              spacing={1}
            >
              <Grid>
                <CustomCheckBox
                  checked={
                    requesterInfo.IsRequesterSameasSubmitter.value === "yes"
                  }
                  value={requesterInfo.IsRequesterSameasSubmitter.value}
                  handleChange={handlecheckBoxChange}
                  selectClassName="verifyRequesterCheckBox"
                  selectpropsClassName="verifyRequesterCheckBoxChkBoxRoot"
                  labelClassName="verifychkBoxDescriptionLabel"
                  labelText="Requester same as submitter"
                  name="IsRequesterSameasSubmitter"
                  testId="verifyRequesterCheckBoxTest"
                />
              </Grid>
            </Grid>
          </Box>
          <div className="mainGridVerifyContainer">
            <Grid
              container
              spacing={2}
              classes={{ root: "verify-requester-component" }}
            >
              <Grid item xs={isMobileScreen ? 12 : 6}>
                <InputWithLabel
                  error={
                    requesterInfo.requesterFirstName.valid ===
                    ValidationStatus.INVALID
                  }
                  id={requesterInfo.requesterFirstName.componentId!}
                  isRequired={true}
                  isShowWarning={true}
                  label={requesterInfo.requesterFirstName.title!}
                  warningMessage={requesterInfo.requesterFirstName.errorMessage}
                >
                  <InputBase
                    className="verify-requester-input"
                    data-testid="requesterFirstNameTest"
                    id={requesterInfo.requesterFirstName.id!}
                    name="requesterFirstName"
                    onChange={validateAndSetData}
                    readOnly={
                      requesterInfo.IsRequesterSameasSubmitter.value === "yes"
                    }
                    value={requesterInfo.requesterFirstName.value}
                  />
                </InputWithLabel>
              </Grid>
              <Grid item xs={isMobileScreen ? 12 : 6}>
                <InputWithLabel
                  error={
                    requesterInfo.requesterLastName.valid ===
                    ValidationStatus.INVALID
                  }
                  id={requesterInfo.requesterLastName.componentId!}
                  isRequired={true}
                  isShowWarning={true}
                  label={requesterInfo.requesterLastName.title!}
                  warningMessage={requesterInfo.requesterLastName.errorMessage}
                >
                  <InputBase
                    className="verify-requester-input"
                    data-testid="requesterLastNameTest"
                    id={requesterInfo.requesterLastName.id!}
                    name="requesterLastName"
                    onChange={validateAndSetData}
                    readOnly={
                      requesterInfo.IsRequesterSameasSubmitter.value === "yes"
                    }
                    value={requesterInfo.requesterLastName.value}
                  />
                </InputWithLabel>
              </Grid>
              <Grid item xs={isMobileScreen ? 12 : 6}>
                <InputWithLabel
                  error={
                    requesterInfo.requesterEmail.valid ===
                    ValidationStatus.INVALID
                  }
                  id={requesterInfo.requesterEmail.componentId!}
                  isRequired={true}
                  isShowWarning={true}
                  label={requesterInfo.requesterEmail.title!}
                  warningMessage={requesterInfo.requesterEmail.errorMessage}
                >
                  <InputBase
                    className="verify-requester-input"
                    id={requesterInfo.requesterEmail.id!}
                    name="requesterEmail"
                    onChange={validateAndSetData}
                    readOnly={
                      requesterInfo.IsRequesterSameasSubmitter.value === "yes"
                    }
                    value={requesterInfo.requesterEmail.value}
                  />
                </InputWithLabel>
              </Grid>
              <Grid item xs={isMobileScreen ? 12 : 6}>
                <InputWithLabel
                  error={
                    requesterInfo.requesterPhoneNumber.valid ===
                    ValidationStatus.INVALID
                  }
                  id={requesterInfo.requesterPhoneNumber.componentId!}
                  isRequired={requesterInfo.requesterPhoneNumber.required}
                  isShowWarning={true}
                  label={requesterInfo.requesterPhoneNumber.title!}
                  testId="requesterPhoneNumber"
                  warningMessage={
                    requesterInfo.requesterPhoneNumber.errorMessage
                  }
                >
                  <InputMask
                    className="phone"
                    id={requesterInfo.requesterPhoneNumber.id!}
                    mask="(999) 999-9999"
                    name="requesterPhoneNumber"
                    onChange={validateAndSetData}
                    onFocus={(e) => setClasses(e, "Mui-focused")}
                    onBlur={(e) => setClasses(e, "")}
                    pattern="[0–9]*"
                    placeholder="(___) ___-____"
                    required={requesterInfo.requesterPhoneNumber.required}
                    type="tel"
                    value={requesterInfo.requesterPhoneNumber.value}
                  />
                </InputWithLabel>
              </Grid>
              {requesterInfo.requesterTitle.required && (
                <Grid item xs={isMobileScreen ? 12 : 6}>
                  <InputWithLabel
                    error={
                      requesterInfo.requesterTitle.valid ===
                      ValidationStatus.INVALID
                    }
                    id={requesterInfo.requesterTitle.componentId!}
                    isRequired={requesterInfo.requesterTitle.required}
                    isShowWarning={true}
                    label={requesterInfo.requesterTitle.title!}
                    warningMessage={requesterInfo.requesterTitle.errorMessage}
                  >
                    <InputBase
                      className="verify-requester-input"
                      data-testid="requesterTitleTest"
                      id={requesterInfo.requesterTitle.id!}
                      name="requesterTitle"
                      onChange={validateAndSetData}
                      readOnly={
                        requesterInfo.IsRequesterSameasSubmitter.value === "yes"
                      }
                      required={requesterInfo.requesterTitle.required}
                      value={requesterInfo.requesterTitle.value}
                    />
                  </InputWithLabel>
                </Grid>
              )}
            </Grid>
            <Grid
              className="verify-requester-facility-component"
              container
              spacing={isMobileScreen ? 0 : 2}
            >
              <Grid
                className="verify-requester-facility-grid-item"
                item
                xs={12}
              >
                <div className="facilityDetail">
                  {facility && (
                    <FacilityInfoDetail
                      facilityInfo={facility}
                      index={0}
                      key={0}
                      openConfirmationBox={null}
                      showTrash={false}
                      hideFacilityID={facility.siteUseCode === "CG"}
                      showManualFacilityNote={false}
                    />
                  )}
                </div>
              </Grid>
              {requesterInfo.IsRequesterSameasSubmitter.value !== "yes" &&
                isVacOrderFromSSO === false && (
                  <ExpressButton
                    variant="outlined"
                    parentClass="changefacilitybtn"
                    testId="ChangeFacilityTest"
                    clickHandler={changeFacilityButtonClick}
                  >
                    Change Facility
                  </ExpressButton>
                )}
            </Grid>
            <AddFacilityContext.Provider
              value={{
                closePopup: () => setOpen(false),
                facilitySearchValidator: new Validator(),
                addFacilityToList: addNewFacility,
              }}
            >
              <Popup
                dialogParentClass="add-facility-popup"
                openFlag={open}
                closeHandler={() => setOpen(false)}
              >
                <AddFacilityContainer isForNewOrder={true} />
              </Popup>
            </AddFacilityContext.Provider>
          </div>
        </div>
      )}
      {isReviewOrder && (
        <VerifyRequesterInfoReviewOrder
          data={requesterInfo}
          facility={requesterInfo.requesterFacility}
          editButtonClicked={editButtonClicked}
          isOrderSummary={isOrderSummary}
          hideFacilityID={requesterInfo.requesterFacility?.siteUseCode === "CG"}
        />
      )}
    </div>
  );
};
