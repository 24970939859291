import moment from "moment";
import { ValidationStatus } from "../../../../../core/interfaces/input.interface";
import { IWoundAssessmentsReportFilter } from "./woundAssessmentsReportFilter.interface";

// Set the current date and 30 days back date
const today = moment();
const thirtyDaysAgo = moment().subtract(30, "days");

export const defaultWoundAssessmentsReportFilter: IWoundAssessmentsReportFilter =
  {
    searchInput: {
      id: "wound-assessements-report-filter-search-input",
      order: 1,
      required: true,
      title: "Filter results",
      valid: ValidationStatus.UNTOUCHED,
      value: "",
    },
    startDate: {
      id: "wound-assessements-report-filter-start-date",
      order: 2,
      required: true,
      title: "Start Date",
      valid: ValidationStatus.VALID,
      value: thirtyDaysAgo.format("MM/DD/YYYY"),
    },
    endDate: {
      id: "wound-assessements-report-filter-end-date",
      order: 3,
      required: true,
      title: "End Date",
      valid: ValidationStatus.UNTOUCHED,
      value: today.format("MM/DD/YYYY"),
    },
  };
