import moment from "moment";
import "./therapyStartDate.css";
import { useContext, useState } from "react";
import { Grid, TextField } from "@mui/material";
import { IInpatientOrder } from "../../inpatientOrder.interface";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { convertStringToDate } from "../../../../util/utilityFunctions";
import { InpatientOrderValidator } from "../../inpatientOrder.validator";
import { ValidationStatus } from "../../../../core/interfaces/input.interface";
import { MobileDisplayContext } from "../../../../context/MobileDisplayContext";
import { ReactComponent as CalendarIcon } from "../../../../assets/calendar.svg";
import { InputWithLabel } from "../../../../core/inputWithLabel/inputWithLabel.component";
import { TherapyStartDateReview } from "./reviewTherapyStartDate/therapyStartDateReview.component";

interface props {
  data: IInpatientOrder;
  editButtonAction?: any;
  isReviewOrder?: boolean;
  isSummary?: boolean;
  setData: Function;
}

export const TherapyStartDate = ({
  data,
  editButtonAction,
  isReviewOrder = false,
  isSummary = false,
  setData,
}: props) => {
  const [focusClasses, setFocusClasses] = useState({
    therapyStartDate: "therapy-start-date-header-desc",
  });
  const setClasses = (e: any, classname: string) => {
    setFocusClasses(
      Object.assign({}, focusClasses, { [e.target.name]: classname })
    );
  };
  const { isMobileScreen } = useContext(MobileDisplayContext);
  const validator = new InpatientOrderValidator();
  const validateAndSetDate = (date: string | null | undefined) => {
    const formattedDate = date ? convertStringToDate(date) : "";
    const isValid = validator.validate(formattedDate!, "therapyStartDate");
    const tempData = {
      ...data,
      therapyStartDate: {
        ...data.therapyStartDate,
        value: formattedDate,
        valid: isValid!.status,
        errorMessage: null,
      },
    };
    setData(tempData);
  };
  return (
    <div
      className="inpatient-therapy-start-date-main-div"
      data-testId="inpatient-therapy-start-date-main-div"
    >
      {isReviewOrder ? (
        <TherapyStartDateReview
          data={data.therapyStartDate}
          editButtonAction={editButtonAction}
          isSummary={isSummary}
        />
      ) : (
        <Grid container spacing={0}>
          <div className="inpatient-therapy-start-date-sub-div">
            <h3 className="therapy-start-date-header">Therapy Information</h3>
            <Grid item xs={isMobileScreen ? 12 : 4}>
              <InputWithLabel
                testId={data.therapyStartDate.componentId!}
                id={data.therapyStartDate.componentId!}
                labelClassName={focusClasses.therapyStartDate}
                label={data.therapyStartDate.title}
                isRequired={data.therapyStartDate.required}
                error={data.therapyStartDate.valid === ValidationStatus.INVALID}
                isShowWarning={true}
                warningMessage={data.therapyStartDate.errorMessage}
              >
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    InputAdornmentProps={{
                      classes: {
                        root: "adornedRoot",
                      },
                    }}
                    minDate={moment().subtract(21, "d").toString()}
                    maxDate={moment().add(0, "d").toString()}
                    InputProps={{
                      classes: {
                        root: `therapyStartDate ${
                          data.therapyStartDate?.valid ===
                          ValidationStatus.INVALID
                            ? "showError"
                            : "noError"
                        }`,
                        input: "input",
                        notchedOutline: "outline",
                      },
                      readOnly: true,
                    }}
                    components={{ OpenPickerIcon: CalendarIcon }}
                    value={data.therapyStartDate?.value}
                    onChange={(value: any) => validateAndSetDate(value)}
                    renderInput={(params: any) => {
                      params.inputProps!.placeholder = "__/__/____";
                      return (
                        <TextField
                          name="therapyStartDate"
                          onFocus={(e) => setClasses(e, "Mui-focused")}
                          onBlur={(e) => setClasses(e, "")}
                          {...params}
                        />
                      );
                    }}
                  />
                </LocalizationProvider>
              </InputWithLabel>
            </Grid>
          </div>
        </Grid>
      )}
    </div>
  );
};
