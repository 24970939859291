import React, { useContext } from "react";

import "./resubmissionOfOrderOrWound.css";
import { ExpressButton } from "../../../../../../core/expressButton/expressButton.component";
import { IPatient } from "../../../../patient.interface";
import { IAlertsIndicatorPopupProps } from "../../../../patientAlerts/alerts/alerts.interface";
import {
  RolesPermissionContext,
  RolesPermissionContextType,
} from "../../../../../../context/RolesPermissionContext";

interface props {
  indicatorPopupProps: IAlertsIndicatorPopupProps;
  patientData: IPatient;
  closePopUpAction: Function;
  resubmitAcuteOrderInfo: Function;
  resubmitWoundInformation: Function;
  openInPatientReviewOrder: Function;
}
export const ResubmissionIndicatorPopup = ({
  indicatorPopupProps,
  patientData,
  closePopUpAction,
  resubmitAcuteOrderInfo,
  resubmitWoundInformation,
  openInPatientReviewOrder,
}: props) => {
  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );
  const handleClose = () => {
    closePopUpAction();
  };

  const clickHandlerFisrtButton = () => {
    patientData.isAcuteOrderFailed ? openInPatientReviewOrder() : handleClose();
  };

  const clickHandlerSecondButton = () => {
    patientData.isAcuteOrderFailed
      ? resubmitAcuteOrderInfo()
      : resubmitWoundInformation();
  };

  return (
    <div>
      <div className="resubmission-indicator-header">
        <h5
          className="resubmission-indicator-title"
          data-testid="resubmission-indicator-title"
        >
          {indicatorPopupProps.indicatorHeader}
        </h5>
      </div>
      <div className="resubmission-indicator-body-div">
        <p
          className="resubmission-indicator-body"
          data-testid="resubmission-indicator-body"
        >
          {indicatorPopupProps.indicatorDescription}
        </p>
        <p
          className="resubmission-indicator-body-description"
          data-testid="resubmission-indicator-body-description1"
        >
          {indicatorPopupProps.indicatorDescriptionSubSection1}
        </p>
        {indicatorPopupProps.indicatorDescriptionSubSection2 && (
          <p
            className="resubmission-indicator-body-description"
            data-testid="resubmission-indicator-body-description2"
          >
            {indicatorPopupProps.indicatorDescriptionSubSection2}
          </p>
        )}
      </div>

      <div className="resubmission-indicator-footer">
        <ExpressButton
          parentClass="buttonClass"
          variant="outlined"
          clickHandler={clickHandlerFisrtButton}
        >
          {indicatorPopupProps.firstButtonLabel}
        </ExpressButton>

        <ExpressButton
          parentClass="buttonClass"
          variant="contained"
          clickHandler={clickHandlerSecondButton}
          disabled={permissionObj?.mappedRolesPermissionData.IsSupportRole}
        >
          {indicatorPopupProps.secondButtonLable}
        </ExpressButton>
      </div>
    </div>
  );
};
