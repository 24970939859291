import { ValidationStatus } from "../../../core/interfaces/input.interface";
import { IManageUserAccounts } from "./manageUserAccounts.interface";

export let defaultManageUserAccountsData: IManageUserAccounts = {
  lastName: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
  },
  firstName: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
  },
  facilityId: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
  },
  email: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
  },
};
