import { useState, useContext, useEffect, ReactNode } from "react";
import { format } from "react-string-format";
import {
  AdminMyListsContextType,
  AdminMyListsContext,
} from "../../../../../context/AdministrationMyListsContext";
import { getdropDownContent } from "../../../../../util/dropDownService";
import { DD_US_STATES_CONTENT } from "../../../../../util/staticText";
import NationalRegistryNoResult from "../../../../newOrder/prescriberInformation/nationalRegistrySearch/nationalRegistryNoResult/nationalRegistryNoResult.component";

import { NationalRegistrySearch } from "../../../../newOrder/prescriberInformation/nationalRegistrySearch/nationalRegistrySearch.component";
import { SearchPrescriberModal } from "../../../../newOrder/prescriberInformation/searchPrescriber.enum";
import { IMyListPrescriber } from "../myListPrescriber.interface";
import NationalRegistryResult from "../../../../newOrder/prescriberInformation/nationalRegistrySearch/nationalRegistryResult/nationalRegistryResult.component";

interface Props {
  handleFilteredPrescribers: any;
  handleAddPrescriberToDB: any;
}

const AddPrescriberContainer = ({
  handleFilteredPrescribers,
  handleAddPrescriberToDB,
}: Props) => {
  const [states, setStates] = useState([]);
  const [statesText, setStatesText] = useState([]);
  const myListObj = useContext<AdminMyListsContextType | null>(
    AdminMyListsContext
  );
  const handleNpiPrescriberSearchResult = (data: IMyListPrescriber[]) => {
    handleFilteredPrescribers(data);
  };
  const fetchDropdownContents = async () => {
    try {
      const ddContent = format("{0}", DD_US_STATES_CONTENT);
      const data = await getdropDownContent(ddContent);
      if (data.items.length > 0) {
        const statesObject = data.items.filter(
          (item: { name: string }) => item.name === DD_US_STATES_CONTENT
        );
        const statesData = statesObject[0].data.sort(
          (a: { order: number }, b: { order: number }) =>
            a.order > b.order ? 1 : -1
        );
        setStates(statesData);
        setStatesText(statesData.map((x: { text: string }) => x.text));
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    fetchDropdownContents();
  }, []);

  const handlePrescriberSearchType = (section: SearchPrescriberModal) => {
    myListObj?.setAddPrescriberPopupSection(section);
  };

  const searchAddPrescriberPopupSection = () => {
    let page: ReactNode;
    switch (myListObj?.addPrescriberPopupSection) {
      case SearchPrescriberModal.NATIONAL_REGISTRY_SEARCH:
        page = (
          <NationalRegistrySearch
            handlePrescriberSearchType={handlePrescriberSearchType}
            states={states}
            statesText={statesText}
            handleNpiPrescriberSearchResult={handleNpiPrescriberSearchResult}
          />
        );
        break;
      case SearchPrescriberModal.NATIONAL_REGISTRY_RESULT:
        page = (
          <NationalRegistryResult
            handlePrescriberSearchType={handlePrescriberSearchType}
            nationalRegistryList={myListObj?.npiPrescriberTabList}
            handleAddPrescriberToDB={handleAddPrescriberToDB}
          />
        );
        break;
      case SearchPrescriberModal.NATIONAL_REGISTRY_NO_RESULT:
        page = (
          <NationalRegistryNoResult
            handlePrescriberSearchType={handlePrescriberSearchType}
          />
        );
        break;
    }
    return page;
  };

  return <div>{searchAddPrescriberPopupSection()}</div>;
};

export default AddPrescriberContainer;
