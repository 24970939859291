import {
  FormControlLabel,
  Grid,
  InputBase,
  Radio,
  RadioGroup,
  useMediaQuery,
} from "@mui/material";
import { useState } from "react";
import "./estimatedArrivalTime.css";
import InputMask from "react-input-mask";
import { ERROR_TO_SELECT_ANY_OPTION } from "../../../../../util/errorMsg";
import { ValidationStatus } from "../../../../../core/interfaces/input.interface";
import { ReactComponent as RadioButtonIcon } from "../../../../../assets/radioButton.svg";
import { InputWithLabel } from "../../../../../core/inputWithLabel/inputWithLabel.component";
import { ReactComponent as SelectedRadioButtonIcon } from "../../../../../assets/selectedRadioButton.svg";

export const EstimatedArrivalTimeMain = ({
  data,
  validateAndSetData,
  isInPatientOrder = false,
  isSolventumProduct = false,
  isFromAcuteOrderPickup = false,
  isCustomerOwnedProduct = false,
}: any) => {
  const isSmallerMobileScreen = useMediaQuery("(max-width:430px)");
  const [focusClasses, setFocusClasses] = useState({
    phoneNumber: "",
  });

  const setClasses = (e: any, classname: string) => {
    setFocusClasses(
      Object.assign({}, focusClasses, { [e.target.name]: classname })
    );
  };

  return (
    <>
      {!isCustomerOwnedProduct && (
        <>
          {!isInPatientOrder && (
            <p
              className="estimated-arrival-time-title"
              data-testid="estimated-arrival-time-title"
            >
              Estimated Arrival Time
            </p>
          )}

          <Grid
            className="estimated-arrival-time-grid-container"
            container
            spacing={1}
          >
            <Grid
              className="estimated-arrival-time-grid-item padding-right"
              item
              xs={12}
            >
              <InputWithLabel
                error={
                  data.isCallOnEstimatedArrivalTime.valid ===
                  ValidationStatus.INVALID
                }
                id={data.isCallOnEstimatedArrivalTime.componentId!}
                isRequired={true}
                isShowWarning={true}
                label={data.isCallOnEstimatedArrivalTime.title}
                labelClassName="estimated-arrival-time-header-title"
                testId="estimated-arrival-time-radio-button"
                warningMessage={
                  data.isCallOnEstimatedArrivalTime.errorMessage
                    ? ERROR_TO_SELECT_ANY_OPTION
                    : ""
                }
              >
                <RadioGroup
                  name="isCallOnEstimatedArrivalTime"
                  classes={{ root: "radioRoot" }}
                  onChange={validateAndSetData}
                  value={data.isCallOnEstimatedArrivalTime.value}
                >
                  <FormControlLabel
                    classes={{
                      root:
                        data.isCallOnEstimatedArrivalTime.valid ===
                        ValidationStatus.INVALID
                          ? "optionRoot-error"
                          : data.isCallOnEstimatedArrivalTime.value === "yes"
                          ? "optionRoot-active"
                          : "optionRoot",
                    }}
                    componentsProps={{
                      typography: {
                        classes: {
                          root:
                            data.isCallOnEstimatedArrivalTime.value === "yes"
                              ? "optiontxtSelect"
                              : "optiontxt",
                        },
                      },
                    }}
                    control={
                      <Radio
                        icon={<RadioButtonIcon />}
                        checkedIcon={<SelectedRadioButtonIcon />}
                      />
                    }
                    data-testid="estimated-arrival-time-radio-button-yes"
                    label="Yes"
                    value="yes"
                  />
                  <FormControlLabel
                    classes={{
                      root:
                        data.isCallOnEstimatedArrivalTime.valid ===
                        ValidationStatus.INVALID
                          ? "optionRoot-error"
                          : data.isCallOnEstimatedArrivalTime.value === "no"
                          ? "optionRoot-active"
                          : "optionRoot",
                    }}
                    componentsProps={{
                      typography: {
                        classes: {
                          root:
                            data.isCallOnEstimatedArrivalTime.value === "no"
                              ? "optiontxtSelect"
                              : "optiontxt",
                        },
                      },
                    }}
                    control={
                      <Radio
                        icon={<RadioButtonIcon />}
                        checkedIcon={<SelectedRadioButtonIcon />}
                      />
                    }
                    data-testid="estimated-arrival-time-radio-button-no"
                    label="No"
                    value="no"
                  />
                </RadioGroup>
              </InputWithLabel>
            </Grid>
            {data.isCallOnEstimatedArrivalTime.value === "yes" && (
              <>
                <Grid
                  className="estimated-arrival-time-grid-item padding-right"
                  item
                  xs={isSmallerMobileScreen ? 12 : 6}
                >
                  <InputWithLabel
                    error={data.firstName.valid === ValidationStatus.INVALID}
                    id={data.firstName.componentId!}
                    isRequired={data.firstName.required ?? false}
                    isShowWarning={true}
                    label={data.firstName.title!}
                    warningMessage={data.firstName.errorMessage}
                  >
                    <InputBase
                      className="estimated-arrival-time-input"
                      id={data.firstName.id!}
                      name="firstName"
                      onChange={validateAndSetData}
                      value={data.firstName.value}
                    />
                  </InputWithLabel>
                </Grid>
                <Grid
                  className="estimated-arrival-time-grid-item padding-left"
                  item
                  xs={isSmallerMobileScreen ? 12 : 6}
                >
                  <InputWithLabel
                    error={data.lastName.valid === ValidationStatus.INVALID}
                    id={data.lastName.componentId!}
                    isRequired={data.lastName.required ?? false}
                    isShowWarning={true}
                    label={data.lastName.title!}
                    warningMessage={data.lastName.errorMessage}
                  >
                    <InputBase
                      className="estimated-arrival-time-input"
                      id={data.lastName.id!}
                      name="lastName"
                      onChange={validateAndSetData}
                      value={data.lastName.value}
                    />
                  </InputWithLabel>
                </Grid>
                {!isSolventumProduct && (
                  <Grid
                    className="estimated-arrival-time-grid-item padding-right"
                    item
                    xs={isSmallerMobileScreen ? 12 : 6}
                  >
                    <InputWithLabel
                      error={data.department.valid === ValidationStatus.INVALID}
                      id={data.department.componentId!}
                      isRequired={
                        isFromAcuteOrderPickup
                          ? false
                          : data.department.required ?? false
                      }
                      isShowWarning={true}
                      label={data.department.title!}
                      warningMessage={data.department.errorMessage}
                    >
                      <InputBase
                        className="estimated-arrival-time-input"
                        id={data.department.id!}
                        name="department"
                        onChange={validateAndSetData}
                        value={data.department.value}
                      />
                    </InputWithLabel>
                  </Grid>
                )}
                {!isInPatientOrder && data.email && (
                  <Grid
                    className="estimated-arrival-time-grid-item padding-left"
                    item
                    xs={isSmallerMobileScreen ? 12 : 6}
                  >
                    <InputWithLabel
                      error={data.email.valid === ValidationStatus.INVALID}
                      id={data.email.componentId!}
                      isRequired={data.email.required ?? false}
                      isShowWarning={true}
                      label={data.email.title!}
                      warningMessage={data.email.errorMessage}
                    >
                      <InputBase
                        className="estimated-arrival-time-input"
                        id={data.email.id!}
                        name="email"
                        onChange={validateAndSetData}
                        value={data.email.value}
                      />
                    </InputWithLabel>
                  </Grid>
                )}
                <Grid
                  className={`estimated-arrival-time-grid-item padding-${
                    (!isInPatientOrder && data.email) || isSolventumProduct
                      ? "right"
                      : "left"
                  }`}
                  item
                  xs={isSmallerMobileScreen ? 12 : 6}
                >
                  <InputWithLabel
                    error={data.phoneNumber.valid === ValidationStatus.INVALID}
                    id={data.phoneNumber.componentId!}
                    isRequired={data.phoneNumber.required ?? false}
                    isShowWarning={true}
                    label={data.phoneNumber.title!}
                    labelClassName={focusClasses.phoneNumber}
                    warningMessage={data.phoneNumber.errorMessage}
                  >
                    <InputMask
                      className="phoneNumber"
                      id={data.phoneNumber.id!}
                      mask="(999) 999-9999"
                      name="phoneNumber"
                      onBlur={(e) => setClasses(e, "")}
                      onChange={validateAndSetData}
                      onFocus={(e) => setClasses(e, "Mui-focused")}
                      placeholder="(___) ___-____"
                      value={data.phoneNumber.value}
                      type="tel"
                      pattern="[0–9]*"
                    />
                  </InputWithLabel>
                </Grid>
              </>
            )}
          </Grid>
        </>
      )}
    </>
  );
};
