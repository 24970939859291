import "./customDropdown.css";
import { MenuItem, Select } from "@mui/material";
import { ReactComponent as SelectIcon } from "../../assets/Accinfo.svg";
import { ReactComponent as SelectIconDisabled } from "../../assets/Accinfodisabled.svg";
type Props = {
  disabled?: boolean;
  dropDownMenuObj?: Array<DropDownValue>;
  handleChange: any; // onchange function
  hasBothCodeValue?: boolean;
  iconComponent?: any; // drop down icon
  iconPropsClassName?: string; // dropdown input props style element classname
  key?: string;
  menuItem?: Array<string>; // dropdown list in array
  menuOptionClassName?: string;
  name?: string; //dropdown name element
  paperPropsClassName?: string;
  placeHolder?: string;
  selectClassName?: string; // dropdown classname for style
  selectpropsClassName?: string; // dropdown input props style element classname
  testId?: string; // testid for easily trageting while writing unittestcases
  value: any; // dropdown selected value
  isIndexRequiredInValue?: boolean;
};

export const CustomDropDown = ({
  disabled = false,
  dropDownMenuObj,
  handleChange,
  hasBothCodeValue,
  iconPropsClassName = "",
  iconComponent,
  key,
  menuItem,
  menuOptionClassName = "menuItemDrop",
  name,
  paperPropsClassName = "paperpropsClass",
  placeHolder,
  selectClassName,
  selectpropsClassName,
  testId,
  value,
  isIndexRequiredInValue = false,
}: Props) => {
  return (
    <Select
      classes={{
        select: selectpropsClassName,
        root: "customDropdownRoot",
        disabled: "customDropdownDisabled",
      }}
      sx={{
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#94949a",
        },
      }}
      className={selectClassName}
      disabled={disabled}
      displayEmpty={true}
      inputProps={{
        classes: {
          icon: iconPropsClassName,
        },
      }}
      IconComponent={
        iconComponent ?? disabled ? SelectIconDisabled : SelectIcon
      }
      data-testid={testId}
      key={key}
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        PaperProps: {
          className: paperPropsClassName,
        },
      }}
      name={name}
      onChange={handleChange}
      renderValue={(value) => (value?.length ? value : placeHolder)}
      variant="outlined"
      value={value}
    >
      {menuItem &&
        !hasBothCodeValue &&
        Array.isArray(menuItem) &&
        menuItem.map((element, index): any => {
          return (
            <MenuItem
              className={menuOptionClassName}
              key={index}
              value={isIndexRequiredInValue ? `${index}|${element}` : element}
            >
              {element}
            </MenuItem>
          );
        })}
      {dropDownMenuObj &&
        hasBothCodeValue &&
        dropDownMenuObj.map((elementCode, index): any => {
          return (
            <MenuItem
              key={index + "-" + elementCode.code}
              value={elementCode.text}
            >
              <div>{elementCode.text}</div>
            </MenuItem>
          );
        })}
    </Select>
  );
};

export interface DropDownValue {
  code: string;
  text: string;
}
