import { IConfirmPlacement } from "./confirmPlacement.interface";
import { ValidationStatus } from "../../core/interfaces/input.interface";

export let defaultConfirmPlacementData: IConfirmPlacement = {
  serialNumber: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: true,
  },

  placementTime: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: true,
  },

  placementDate: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: true,
  },
};
