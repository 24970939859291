import "./missingRxEPrescription.css";
import React, { useEffect, useState } from "react";
import { MissingRxSection } from "../missingRx.enum";
import { IPatient } from "../../../patient.interface";
import { IPrescriberDetailInterface } from "./prescriberDetail.interface";
import { makeCapitalEachWordInString } from "../../../../../util/utilityFunctions";
import ChangeEPrescription from "../changePrescriptionType/changeEPrescription/changeEPrescription.component";
import { IErrorObj } from "../missingRx.component";

interface Props {
  changePopUpSection: (newSection: MissingRxSection) => void;
  closePopUpAction: Function;
  patientData: IPatient;
  prescriberDetails: IPrescriberDetailInterface;
  showErrorMessage: boolean;
  setPrescriberDetails: React.Dispatch<
    React.SetStateAction<IPrescriberDetailInterface>
  >;
  errorObj: IErrorObj;
  setErrorObj: Function;
}

const MissingRxEPrescription = ({
  changePopUpSection,
  patientData,
  prescriberDetails,
  showErrorMessage,
  setPrescriberDetails,
  closePopUpAction,
  errorObj,
  setErrorObj,
}: Props) => {
  const [editPrescriberMode, setEditPrescriberMode] = useState(false);
  const [originalPrescriberDetails] =
    useState<IPrescriberDetailInterface>(prescriberDetails);

  const callReSendPrescription = () => {
    changePopUpSection(MissingRxSection.MISSING_RX_LOADER);
    setTimeout(() => {
      changePopUpSection(MissingRxSection.E_PRESBRIBER_SENT);
    }, 500);
  };

  return (
    <div className="missingRxEPrescription">
      <div
        className="description-missing-rx"
        data-testid="description-missing-rx"
      >
        A 3M™ ActiV.A.C.™ Therapy System order has been submitted for{" "}
        <span className="patientNamespan">
          {makeCapitalEachWordInString(patientData.firstName) +
            " " +
            makeCapitalEachWordInString(patientData.lastName)}
        </span>
        , but a signed Rx has not been received. An ActiV.A.C. Therapy System
        cannot be released without a signed prescription.
      </div>
      <div className="descp-text-link">
        <p className="descp-text" data-testid="descp-text">
          Prescription type from your order
        </p>
        <div
          className="descp-link"
          data-testid="descp-link"
          onClick={() =>
            changePopUpSection(MissingRxSection.CHANGE_PRESCRIBER_TYPE)
          }
        >
          Change prescription type
        </div>
      </div>
      <div className="title-text">E-Prescription</div>
      <div className="title-msg" data-testid="title-msg">
        <span>
          A DocuSign<sup>®</sup> email was originally sent to the following
          prescriber:
        </span>
      </div>
      <div className="change-e-prescription">
        <ChangeEPrescription
          showErrorMessage={showErrorMessage}
          changePopUpSection={changePopUpSection}
          editPrescriberMode={editPrescriberMode}
          prescriberDetails={prescriberDetails}
          setEditPrescriberMode={setEditPrescriberMode}
          setPrescriberDetails={setPrescriberDetails}
          resendPrescriptionClicked={callReSendPrescription}
          patientData={patientData}
          closePopUp={closePopUpAction}
          errorObj={errorObj}
          setErrorObj={setErrorObj}
        />
      </div>
    </div>
  );
};

export default MissingRxEPrescription;
