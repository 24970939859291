import { ValidationStatus } from "../../../../../../../core/interfaces/input.interface";
import { IManualAddSalesPerson } from "./addSalePersonDetail.interface";

export let defaultManualAddSalesPerson: IManualAddSalesPerson = {
  startDate: {
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: true,
  },
  endDate: {
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: true,
  },
  reasonForAssignmentChange: {
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: true,
  },
};
