import "./orderSupplyDetails.css";
import { ReactComponent as SupplyIconBlack } from "../../../../assets/orderSuppliesBlack.svg";
import { ReactComponent as Navigate } from "../../../../assets/Vector_navigate.svg";
import React, { useContext } from "react";
import { Popup } from "../../../../core/popup/popup.component";
import { ISupplyOrdersInfo } from "./orderSupplyDetails.interface";
import { makeCapitalEachWordInString } from "../../../../util/utilityFunctions";
import moment from "moment";
import {
  OrderDetailContextType,
  OrderDetailContext,
} from "../../../../context/OrderDetailsContext";
import OrderDetailAlertBanner from "../orderDetailsAlertBanner/orderDetailsAlertBanner.component";
import { useMediaQuery } from "@mui/material";

interface IOrderSupplyDetail {
  supplyOrderData: ISupplyOrdersInfo;
  therapyStartDate?: string;
  alertsForRO?: any;
  patientData?: any;
}
const OrderSupplyDetail = ({
  supplyOrderData,
  therapyStartDate,
  alertsForRO,
  patientData,
}: IOrderSupplyDetail) => {
  const [openPopup, setOpenPopup] = React.useState<boolean>(false);
  const orderOverViewObj = useContext<OrderDetailContextType | null>(
    OrderDetailContext
  );
  const isMobileScreen = useMediaQuery("(max-width:920px)");
  const handleNavigateToSupplyOrderDetail = () => {
    orderOverViewObj?.setSelectedSupplyOrder(supplyOrderData);
    orderOverViewObj?.setSelectedSupplyTherapyStartDate(therapyStartDate!);
    orderOverViewObj?.setIsOrderFlow(true);
    orderOverViewObj?.setOrderTabTitle("Supply Orders");
    if (isMobileScreen) {
      orderOverViewObj?.setIsTabHeaderVisible(false);
    }
  };

  const renderStatusAndDate = (status: string, date: string | null) => {
    return (
      <div className="status-banner">
        <p className="order-supply-detail-status-label">
          {makeCapitalEachWordInString(status)}
        </p>
        {date && (
          <p className="order-supply-detail-status-label">
            {moment(date).format("MM/DD/yyyy")}
          </p>
        )}
      </div>
    );
  };

  return (
    <>
      <div className="order-supplies-detail-component">
        <div className="order-supplies-detail-container">
          <div
            className="supply-icon-div"
            data-testId="supply-order-icon-black"
          >
            <SupplyIconBlack
              className="icon"
              test-Id="supply-order-icon-black"
            />
          </div>

          <div className="details">
            <h4
              className="order-dressing-supply-order-label"
              data-testId="order-dressing-supply-order-heading"
            >
              {patientData.isAcuteOrder
                ? "In-Patient Supply Order"
                : "Dressing & Supply Order"}
            </h4>
            <p
              className="order-supply-detail-label"
              data-testId="supply-order-initiatedBy"
            >
              Initiated by :
              {" " +
                makeCapitalEachWordInString(supplyOrderData.initiatedName!)}
            </p>
            <div className="therapy-status-section">
              <span
                className="order-supply-detail-status-label"
                data-testId="supply-order-status"
              >
                Status
              </span>
              <div
                className="oval"
                style={{
                  backgroundColor:
                    supplyOrderData?.statusColor !== ""
                      ? supplyOrderData?.statusColor?.toLowerCase()
                      : "grey",
                }}
              ></div>
              {supplyOrderData.status === "SHIPPED" ||
              supplyOrderData.status === "DELIVERED"
                ? renderStatusAndDate(
                    supplyOrderData.status!,
                    supplyOrderData.status === "DELIVERED"
                      ? supplyOrderData.deliveredDate
                      : supplyOrderData.shippedDate
                  )
                : renderStatusAndDate(supplyOrderData.status!, null)}
            </div>
            <h6
              className="order-supply-detail-createdOn-label"
              data-testId="supply-order-created-on"
            >
              Created on{" "}
              {moment(supplyOrderData.createdDate!).format(
                "MMM DD, yyyy hh:mm A"
              )}
            </h6>
          </div>
          <div
            className="order-supplies-detail-navigate-icon-div"
            data-testId="navigate-icon-right"
          >
            <Navigate
              className="navigate-icon"
              onClick={() => {
                handleNavigateToSupplyOrderDetail();
              }}
              test-id="navigate-icon"
            />
          </div>
        </div>
        <div className="alert-banner-supply">
          {alertsForRO?.alerts
            .filter(
              (item: any) =>
                (item.alertName.toLowerCase() ===
                  "Pending Supply Order".toLowerCase() ||
                  item.alertName.toLowerCase() ===
                    "Excessive Supply".toLowerCase()) &&
                item.ropn === supplyOrderData?.ropn
            )
            .map((alert: any) => (
              <OrderDetailAlertBanner
                alertData={alert}
                key={alert.alertID.toString()}
                patientData={patientData}
                alertsForRO={alertsForRO}
              />
            ))}
        </div>
      </div>
      {openPopup && (
        <Popup
          openFlag={openPopup}
          closeHandler={() => setOpenPopup(false)}
          dialogParentClass={"order-supplies-empty-pop-up"}
        >
          <div></div>
        </Popup>
      )}
    </>
  );
};

export default OrderSupplyDetail;
