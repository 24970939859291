import { ExpressButton } from "../../../../../../core/expressButton/expressButton.component";
import "./restrictAddLocation.css";
import { ReactComponent as WarningIcon } from "../../../../../../assets/WarningIndicator.svg";

interface Props {
  setOpenpopup: Function;
}

export const RestrictAddLocation = ({ setOpenpopup }: Props) => {
  return (
    <div className="restrict-add-location">
      <div
        className="restrict-addLoc-title1"
        data-testid="restrict-addLoc-title1-test"
      >
        Add a new storage location
      </div>
      <div
        className="restrict-addLoc-title2"
        data-testid="restrict-addLoc-title2-test"
      >
        <span className="restrict-addLoc-warning-icon">
          <WarningIcon className="warning-icon-image" />
        </span>
        You cannot have more than ten (10) custom storage locations.
      </div>
      <div
        className="restrict-addLoc-description"
        data-testid="restrict-addLoc-description-test"
      >
        If you wish to change the name of one of your existing storage
        locations, select its name from the location list and update it.
      </div>
      <div className="restrict-addLoc-close-div">
        <ExpressButton
          parentClass="restrict-addLoc-close-btn"
          variant="contained"
          clickHandler={() => setOpenpopup(false)}
          testId="restrict-add-close-btn"
        >
          Close
        </ExpressButton>
      </div>
    </div>
  );
};
