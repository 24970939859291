import { Grid, TextField } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "./addSalePersonDetail.css";
import moment from "moment";
import { CustomDropDown } from "../../../../../../../core/customDropdown/customDropdown.component";
import { ExpressButton } from "../../../../../../../core/expressButton/expressButton.component";
import { InputWithLabel } from "../../../../../../../core/inputWithLabel/inputWithLabel.component";
import { ValidationStatus } from "../../../../../../../core/interfaces/input.interface";
import { ReactComponent as CalendarIcon } from "../../../../../../../assets/calendar.svg";
import {
  IAddSalePersonDetail,
  IManualAddSalesPerson,
} from "./addSalePersonDetail.interface";
import React, { useEffect, useState } from "react";
import {
  changeDateFormat,
  getInvalidObj,
  getValidObj,
  makeCapitalEachWordInString,
} from "../../../../../../../util/utilityFunctions";
import { ManualAddSalesPersonValidator } from "./addSalePersonDetail.validator";
import { format } from "react-string-format";
import { DD_ASSIGNMENT_CHANGE_REASON } from "../../../../../../../util/staticText";
import { getdropDownContent } from "../../../../../../../util/dropDownService";
import {
  ISalesData,
  ISalesPersonAPIData,
} from "../addSalePersonDetailList/addSalePersonDetailList.interface";
import { AddSalePersonToTerritory } from "../addTerritorySalePersonContainer/addTerritorySalePerson.enum";

interface Props {
  selectedSalesPersonDetails: IAddSalePersonDetail;
  Validator?: ManualAddSalesPersonValidator;
  setOpenpopup: any;
  salesMngUserData: ISalesPersonAPIData;
  changeFormHandler: any;
  setAddSalesData: Function;
  addSalesData: ISalesData;
  dateValue?: IManualAddSalesPerson;
  setDateValue?: any;
  callAddSalesPerson: any;
}

export const AddSalePersonDetail = ({
  selectedSalesPersonDetails,
  Validator = new ManualAddSalesPersonValidator(),
  setOpenpopup,
  salesMngUserData,
  changeFormHandler,
  setAddSalesData,
  addSalesData,
  dateValue,
  setDateValue,
  callAddSalesPerson,
}: Props) => {
  const [focusClasses, setFocusClasses] = React.useState({
    startDate: "",
    endDate: "",
  });

  const [validator] = React.useState<ManualAddSalesPersonValidator>(Validator);

  const validateAndSetDateStartDate = (
    date: string | null | undefined | any
  ) => {
    const formatteddate = changeDateFormat(date);
    const isValid = validator.validate(formatteddate, "startDate");
    const isValidEndDate = providedDatePlus180(
      dateValue?.endDate?.value!,
      formatteddate
    );
    setDateValue({
      ...dateValue,
      startDate: {
        value: formatteddate,
        valid: isValid?.status!,
        required: true,
      },
      endDate: {
        value: dateValue?.endDate?.value,
        valid:
          dateValue?.endDate.value === ""
            ? ValidationStatus.UNTOUCHED
            : isValidEndDate?.status!,
        required: true,
      },
    });
  };

  const validateAndSetDateEndDate = (date: string | null | undefined | any) => {
    const formatteddate = changeDateFormat(date);
    const isValid = providedDatePlus180(
      formatteddate,
      dateValue?.startDate?.value!
    );
    setDateValue({
      ...dateValue,
      endDate: {
        value: formatteddate,
        valid: isValid?.status!,
        required: true,
      },
    });
  };
  const providedDatePlus180 = (txt: string, startDateTxt: string) => {
    const formatteddate = moment(txt);
    const startDate = moment(startDateTxt);
    if (
      startDate.isValid() &&
      formatteddate.isValid() &&
      formatteddate.isBetween(
        startDate.subtract(1, "d").format("MM/DD/YYYY"),
        startDate.add(181, "d").format("MM/DD/YYYY")
      )
    ) {
      return getValidObj();
    }
    return getInvalidObj(null);
  };

  const [assignmentChangeReasonText, setAssignmentChangeReasonText] =
    useState();

  const setClasses = (e: any, classname: string) => {
    setFocusClasses(
      Object.assign({}, focusClasses, { [e.target.name]: classname })
    );
  };

  const validateAndSetData = (e: any) => {
    setDateValue({
      ...dateValue,
      [e.target.name]: {
        value: e.target.value,
        valid: ValidationStatus.VALID,
        required: true,
      },
    });
  };
  useEffect(() => {
    fetchDropDownContent();
  }, []);

  const fetchDropDownContent = async () => {
    try {
      const ddContent = format("{0}", DD_ASSIGNMENT_CHANGE_REASON ?? "");
      const data = await getdropDownContent(ddContent);
      if (data.items.length > 0) {
        const assignmentReasonObj = data.items.filter(
          (item: { name: string }) => item.name === DD_ASSIGNMENT_CHANGE_REASON
        );
        const assignmentReasonData = assignmentReasonObj[0].data.sort(
          (a: { order: number }, b: { order: number }) =>
            a.order > b.order ? 1 : -1
        );
        setAssignmentChangeReasonText(
          assignmentReasonData.map((x: { text: string }) => x.text)
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <>
      <Grid className="addSalesPersonMainContainer">
        <Grid
          className="addSalesPersonTittle"
          data-testid="addSalesPersonTittle"
        >
          Add Salesperson to this Territory
        </Grid>
        <Grid className="containerBody">
          <Grid className="addSalePersonMainContainer">
            <div
              className="changeButton"
              data-testid={"addSalesPerson_change"}
              onClick={() => {
                changeFormHandler(AddSalePersonToTerritory.LIST_SALE_PERSONS);
              }}
            >
              Change
            </div>
            <Grid className="name-and-territory">
              <div className="name" data-testid={"salesPerson_name"}>
                {makeCapitalEachWordInString(salesMngUserData.firstName) +
                  " " +
                  makeCapitalEachWordInString(salesMngUserData.lastName)}
              </div>
              <Grid className="heading-and-values" item xs={6}>
                <Grid
                  className="territory-heading"
                  data-testid={"territoryRegion_heading"}
                >
                  Sales Territory
                </Grid>{" "}
                <div className="name" data-testid={"territoryRegion_value"}>
                  {salesMngUserData.territories.map(
                    (selectedTerritory: any) => {
                      return <div className="name">{selectedTerritory}</div>;
                    }
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            className="addSalesAssignDatesContainer"
            data-testid={"AssignDates"}
          >
            <div className="assignDatesTitle">Assign dates</div>
            <div className="assignDatesFields">
              <InputWithLabel
                labelClassName={focusClasses.startDate}
                label="Start Date"
                isRequired={true}
                error={dateValue?.startDate?.valid === ValidationStatus.INVALID}
              >
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    InputAdornmentProps={{
                      classes: {
                        root: "adornedRoot",
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: `startDate ${
                          dateValue?.startDate?.valid ===
                          ValidationStatus.INVALID
                            ? "showError"
                            : "noError"
                        }`,
                        input: "input",
                        notchedOutline: "outline",
                      },
                    }}
                    components={{ OpenPickerIcon: CalendarIcon }}
                    value={dateValue?.startDate?.value}
                    onChange={(value) => validateAndSetDateStartDate(value)}
                    minDate={moment().toString()}
                    maxDate={moment().add(179, "d").toString()}
                    renderInput={(params) => {
                      params.error = false;
                      params.inputProps!.placeholder = "__/__/____";
                      return (
                        <TextField
                          name="startDate"
                          onFocus={(e) => setClasses(e, "Mui-focused")}
                          onBlur={(e) => setClasses(e, "")}
                          {...params}
                        />
                      );
                    }}
                  />
                </LocalizationProvider>
              </InputWithLabel>
              <InputWithLabel
                labelClassName={focusClasses.endDate}
                label="End Date"
                isRequired={true}
                error={dateValue?.endDate?.valid === ValidationStatus.INVALID}
              >
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    InputAdornmentProps={{
                      classes: {
                        root: "adornedRoot",
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: `endDate ${
                          dateValue?.endDate?.valid === ValidationStatus.INVALID
                            ? "showError"
                            : "noError"
                        }`,
                        input: "input",
                        notchedOutline: "outline",
                      },
                    }}
                    components={{ OpenPickerIcon: CalendarIcon }}
                    value={dateValue?.endDate?.value}
                    onChange={(value) => validateAndSetDateEndDate(value)}
                    minDate={
                      dateValue?.startDate?.valid === ValidationStatus.VALID
                        ? moment(dateValue?.startDate?.value).toString()
                        : ""
                    }
                    maxDate={
                      dateValue?.startDate?.valid === ValidationStatus.VALID
                        ? moment(dateValue?.startDate?.value)
                            .add(179, "d")
                            .toString()
                        : ""
                    }
                    renderInput={(params) => {
                      params.error = false;
                      params.inputProps!.placeholder = "__/__/____";
                      return (
                        <TextField
                          name="endDate"
                          onFocus={(e) => setClasses(e, "Mui-focused")}
                          onBlur={(e) => setClasses(e, "")}
                          {...params}
                        />
                      );
                    }}
                  />
                </LocalizationProvider>
              </InputWithLabel>
            </div>
          </Grid>
          <div className="addSalePersonAssignReasonFields">
            <InputWithLabel
              label="Reason for assignment change"
              isRequired={true}
              error={
                dateValue?.reasonForAssignmentChange?.valid ===
                ValidationStatus.INVALID
              }
              testId={"addSalesPerson_reason"}
            >
              <CustomDropDown
                handleChange={(e: any) => validateAndSetData(e)}
                menuItem={assignmentChangeReasonText}
                name="reasonForAssignmentChange"
                placeHolder="Select reason"
                selectpropsClassName={
                  dateValue?.reasonForAssignmentChange?.value
                    ? "reasonForAssignmentChange-select"
                    : "placeHolder"
                }
                selectClassName={
                  dateValue?.reasonForAssignmentChange?.value
                    ? "reasonForAssignmentChange-input"
                    : "placeHolder"
                }
                testId="reasonForAssignmentChange-id"
                value={dateValue?.reasonForAssignmentChange?.value}
              />
            </InputWithLabel>
          </div>
          <div className="addSalePersonBtnGroup">
            <ExpressButton
              parentClass="addSaleTerritoryCancelBtn"
              variant="outlined"
              clickHandler={() => {
                setOpenpopup(false);
              }}
              testId="cancelBtn"
            >
              Cancel
            </ExpressButton>
            <div className="addSalesPersonBtn">
              <ExpressButton
                disabled={
                  dateValue?.startDate?.valid !== ValidationStatus.VALID ||
                  dateValue?.endDate?.valid !== ValidationStatus.VALID ||
                  dateValue?.reasonForAssignmentChange?.valid !==
                    ValidationStatus.VALID
                }
                parentClass="addSaleTerritoryAddBtn"
                variant="contained"
                clickHandler={callAddSalesPerson}
                testId="addSalesPersonBtn"
              >
                Add salesperson
              </ExpressButton>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};
