import "./manageUserAccounts.css";
import {
  ManageUserAccountsTabs,
  UserProfileComingFrom,
} from "./manageUserAccounts.enum";
import {
  UserProfileContext,
  UserProfileContextType,
} from "../../../context/UserProfileContext";
import { useHistory } from "react-router-dom";
import { useTabs } from "react-headless-tabs";
import { Users } from "./Users/users.component";
import { Grid, useMediaQuery } from "@mui/material";
import {
  ManageUserAccountsContext,
  ManageUserAccountsContextType,
} from "../../../context/ManageUserAccountsContext";
import { ReactNode, useContext, useEffect, useState } from "react";
import { Popup } from "../../../core/popup/popup.component";
import { TabSelector } from "../../../core/customTab/tabSelector";
import { getRolePermissions } from "../../../util/facilityService";
import { LoadingSpinner } from "../../../core/loader/LoadingSpinner";
import {
  getFacilityPermissionName,
  updatePermissionBasedOnAcuteFacility,
} from "../../../util/utilityFunctions";
import { AuthContext, AuthContextType } from "../../../context/AuthContext";
import { Navigator } from "../../helpAndSupport/Navigator/navigator.component";
import {
  IUserFacilityData,
  IUserPermissionsData,
} from "../manageUsers/userProfile/userProfile.interface";
import { UnlinkedUsers } from "./UnlinkedUsers/unlinkedUsers.component";
import {
  InternalSignOnContextType,
  InternalSignOnContext,
} from "../../../context/InternalSignOnContext";

export const ManageUserAccounts = () => {
  const history = useHistory();

  const isSmallerMobileScreen = useMediaQuery("(max-width:450px)");
  const isMobileScreen = useMediaQuery("(max-width:600px)");
  const isIPadScreen = useMediaQuery("(max-width:768px)");

  const [openLoaderPopUp, setOpenLoaderPopUp] = useState<boolean>(false);

  const authObj = useContext<AuthContextType | null>(AuthContext);
  const userProfileObj = useContext<UserProfileContextType | null>(
    UserProfileContext
  );
  const manageUserAccountsObj =
    useContext<ManageUserAccountsContextType | null>(ManageUserAccountsContext);
  const internalObj = useContext<InternalSignOnContextType | null>(
    InternalSignOnContext
  );

  const [selectedTab, setSelectedTab] = useTabs([
    ManageUserAccountsTabs.USERS,
    ManageUserAccountsTabs.UNLINKED_USERS,
  ]);

  const updateSelectedTab = async (
    selectedTabDetails: ManageUserAccountsTabs
  ) => {
    manageUserAccountsObj?.resetData();
    setSelectedTab(selectedTabDetails);
    manageUserAccountsObj?.setSelectedManageUserAccountsTab(selectedTabDetails);
  };

  const addNewUserBtnAction = () => {
    openUserProfile(true, "");
  };

  const openSelectedUserProfile = (selectedUser: string) => {
    openUserProfile(false, selectedUser);
  };

  const openSelectedUnlinkedUserProfile = (selectedUser: string) => {
    openUserProfile(false, selectedUser, true);
  };

  const openUserProfile = async (
    isAddingNewUser: boolean,
    selectedUserName: string,
    showUnlinkedFacility: boolean = false
  ) => {
    userProfileObj?.resetUserProfile();
    if (isAddingNewUser) {
      setOpenLoaderPopUp(true);
      const addUserFaciltyDetails: IUserFacilityData | null =
        await mapUserFacilityDataAndUpdatePermissions();
      setOpenLoaderPopUp(false);
      if (addUserFaciltyDetails) {
        history.push({
          pathname: "/administration/manageUsers/userProfile",
          state: {
            isAddingNewUser: isAddingNewUser,
            isComingFrom: UserProfileComingFrom.MANAGE_USER_ACCOUNTS,
            regFacility: [addUserFaciltyDetails],
            selectedUserName: selectedUserName,
            showUnlinkedFacility: false,
          },
        });
      } else {
        if (internalObj?.isInternalAdministration) {
          history.push({
            pathname: "/administration/manageUsers/userProfile",
            state: {
              isAddingNewUser: isAddingNewUser,
              isComingFrom: UserProfileComingFrom.MANAGE_USER_ACCOUNTS,
              regFacility: [],
              selectedUserName: selectedUserName,
              showUnlinkedFacility: false,
            },
          });
        }
      }
    } else {
      history.push({
        pathname: "/administration/manageUsers/userProfile",
        state: {
          isAddingNewUser: isAddingNewUser,
          isComingFrom: UserProfileComingFrom.MANAGE_USER_ACCOUNTS,
          selectedUserName: selectedUserName,
          showUnlinkedFacility: showUnlinkedFacility,
        },
      });
    }
    manageUserAccountsObj?.resetData();
  };

  const mapUserFacilityDataAndUpdatePermissions = async () => {
    if (
      authObj &&
      authObj.registeredFaciltyAddress &&
      authObj.registeredFaciltyAddress.siteUseId &&
      authObj.registeredFaciltyAddress.siteUseId !== ""
    ) {
      const permissionData = await getRolePermissionsInfo(
        authObj.registeredFaciltyAddress.siteUseId!
      );
      const isPostAcute =
        authObj.registeredFaciltyAddress?.isPostAcute ?? false;
      let permissions: IUserPermissionsData[] = [];
      let enabledPermissionsCount = 0;
      let availablePermissionsCount = 0;
      permissionData.permissions.forEach((permission: any) => {
        let status: boolean = false;
        if (permission.adminRegistered) {
          if (typeof permission.adminRegistered === "string") {
            status = permission.adminRegistered === "Enabled";
          } else if (typeof permission.adminRegistered === "boolean") {
            status = permission.adminRegistered;
          }
        }
        const isShowPermission = updatePermissionBasedOnAcuteFacility(
          isPostAcute,
          permission.permissionName
        );
        if (isShowPermission) {
          if (permission.adminRegistered) {
            enabledPermissionsCount += 1;
          }
          availablePermissionsCount += 1;
        }
        permissions.push({
          category: permission.category,
          isHide: !isShowPermission,
          name: permission.permissionName,
          status: permission.adminRegistered ? status : false,
        });
      });
      const registeredFacility: IUserFacilityData = {
        accountName: authObj.registeredFaciltyAddress.accountName,
        activityStauts: 2,
        accountNumber: authObj.registeredFaciltyAddress.accountNumber ?? 0,
        address1: authObj.registeredFaciltyAddress.address1,
        address2: authObj.registeredFaciltyAddress.address2,
        facilityAddressID: parseInt(
          authObj.registeredFaciltyAddress.facilityAddressID ?? ""
        ),
        city: authObj.registeredFaciltyAddress.city,
        state: authObj.registeredFaciltyAddress.state,
        zip: authObj.registeredFaciltyAddress.zip.toString(),
        userRole: "Clinician",
        status: "Active",
        siteUseId: authObj.registeredFaciltyAddress.siteUseId!,
        permissions: permissions,
        enabledPermissionsCount: enabledPermissionsCount,
        availablePermissionsCount: availablePermissionsCount,
        siteUseCode: authObj.registeredFaciltyAddress.siteUseCode,
        typeCode: authObj.registeredFaciltyAddress.typeCode,
      };
      return registeredFacility;
    }
    return null;
  };

  const getRolePermissionsInfo = async (siteUseId: string) => {
    const reqBody = {
      siteUseId: siteUseId,
    };
    const response = await getRolePermissions(reqBody);
    if (response && response.succeeded) {
      const permissions = response.data.permissions;
      if (permissions.length > 0) {
        const validFacilityPermissionArray: [] = permissions.filter(
          (x: any) => getFacilityPermissionName(x.permissionName) !== ""
        );
        return {
          siteUseId: siteUseId,
          permissions: validFacilityPermissionArray,
        };
      }
    }
    setOpenLoaderPopUp(false);
    return {
      siteUseId: siteUseId,
      permissions: [],
    };
  };

  const sectionToDisplay = () => {
    let page: ReactNode;
    if (
      manageUserAccountsObj &&
      manageUserAccountsObj.selectedManageUserAccountsTab &&
      selectedTab !== manageUserAccountsObj.selectedManageUserAccountsTab
    ) {
      const selectedTabValue =
        manageUserAccountsObj.selectedManageUserAccountsTab ===
        ManageUserAccountsTabs.USERS
          ? ManageUserAccountsTabs.USERS
          : ManageUserAccountsTabs.UNLINKED_USERS;
      setSelectedTab(selectedTabValue);
    }
    switch (selectedTab) {
      case ManageUserAccountsTabs.USERS:
        page = (
          <Users
            addNewUserBtnAction={addNewUserBtnAction}
            openSelectedUserProfile={openSelectedUserProfile}
          />
        );
        break;
      case ManageUserAccountsTabs.UNLINKED_USERS:
        page = (
          <UnlinkedUsers
            openSelectedUserProfile={openSelectedUnlinkedUserProfile}
          />
        );
        break;
    }
    return page;
  };

  useEffect(() => {
    if (!authObj?.isInternalUser) {
      history.push("/home");
    }
  }, [authObj?.registeredFaciltyAddress]);

  return (
    <div className="manage-user-accounts-component-container">
      <div
        className="manage-user-accounts-component"
        data-testid="manage-user-accounts-component"
      >
        <Navigator
          array={[
            {
              route: internalObj?.isInternalAdministration
                ? "/internalAdministration"
                : "/administration",
              pageName: "Administration",
            },
          ]}
          className="manage-user-accounts-component-route-section"
          title="Manage User Accounts"
        />
        <p
          className="manage-user-accounts-header"
          data-testid="manage-user-accounts-header"
        >
          Manage User Accounts
        </p>
        <div className="short-form">
          <div
            className="manage-user-accounts-tabview-page"
            data-testid="tab-nav"
          >
            <nav className="manage-user-accounts-tab-style-div">
              <Grid
                container
                className="manage-user-accounts-tab-grid-conatiner"
                spacing={2}
              >
                <Grid
                  className="manage-user-accounts-tab-grid-item"
                  item
                  xs={
                    isSmallerMobileScreen
                      ? 12
                      : isMobileScreen
                      ? 6
                      : isIPadScreen
                      ? 4
                      : 3
                  }
                >
                  <TabSelector
                    isActive={selectedTab === ManageUserAccountsTabs.USERS}
                    onClick={() =>
                      updateSelectedTab(ManageUserAccountsTabs.USERS)
                    }
                    testId="user-tab"
                    title={ManageUserAccountsTabs.USERS}
                    titleClassName="manage-user-accounts"
                  />
                </Grid>
                <Grid
                  className="manage-user-accounts-tab-grid-item"
                  item
                  xs={
                    isSmallerMobileScreen
                      ? 12
                      : isMobileScreen
                      ? 6
                      : isIPadScreen
                      ? 4
                      : 3
                  }
                >
                  <TabSelector
                    isActive={
                      selectedTab === ManageUserAccountsTabs.UNLINKED_USERS
                    }
                    onClick={() =>
                      updateSelectedTab(ManageUserAccountsTabs.UNLINKED_USERS)
                    }
                    notificationCount={authObj?.unLinkedFacilitesCount}
                    tabType="Unlinked User"
                    testId="unlinked-user-tab"
                    title={ManageUserAccountsTabs.UNLINKED_USERS}
                    titleClassName="manage-user-accounts"
                  />
                </Grid>
              </Grid>
            </nav>
          </div>
          {sectionToDisplay()}
        </div>
      </div>
      <Popup
        openFlag={openLoaderPopUp}
        closeHandler={() => setOpenLoaderPopUp(false)}
        dialogParentClass={"manage-user-accounts-loader-pop-up"}
        data-testid="manage-user-accounts--pop-up"
        hideCloseButton={true}
      >
        <div className="manage-user-accounts-loader">
          <LoadingSpinner />
        </div>
      </Popup>
    </div>
  );
};
