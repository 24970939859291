import { Grid, InputBase, useMediaQuery } from "@mui/material";
import { MouseEventHandler } from "react";
import { CustomDropDown } from "../../../../../core/customDropdown/customDropdown.component";
import { ExpressButton } from "../../../../../core/expressButton/expressButton.component";
import { InputWithLabel } from "../../../../../core/inputWithLabel/inputWithLabel.component";
import { ValidationStatus } from "../../../../../core/interfaces/input.interface";
import { getDeepClone } from "../../../../../util/ObjectFunctions";
import { NewOrderValidator } from "../../../newOrder.validator";
import {
  defaultNationalRegistrySearchBox,
  INationalRegistrySearchByDetails,
} from "../nationRegistrySearch.model";
import "./nationalRegistrySearchByDetails.css";
import { CustomAutoComplete } from "../../../../../core/autoCompleteDropdown/autoCompleteDropdown.component";

interface Props {
  states: never[];
  statesText: never[];
  nationRegistrySearchByDetails: INationalRegistrySearchByDetails;
  setNationRegistrySearchByDetails: Function;
  setNationalRegistrySearchInputs: Function;
  handleSearchNPI: MouseEventHandler<HTMLButtonElement>;
}

const NationalRegistrySearchByDetails = ({
  states,
  statesText,
  nationRegistrySearchByDetails,
  setNationRegistrySearchByDetails,
  setNationalRegistrySearchInputs,
  handleSearchNPI,
}: Props) => {
  const isMobileScreen = useMediaQuery("(max-width:600px)");
  const validateAndSetData = (e: any) => {
    setNationalRegistrySearchInputs(
      getDeepClone(defaultNationalRegistrySearchBox)
    );

    let value = e.target.value;
    const validator = new NewOrderValidator();
    let isValid = validator.validate(e.target.value, e.target.name);
    setNationRegistrySearchByDetails(
      (nationRegistrySearchByDetails: INationalRegistrySearchByDetails) => ({
        ...nationRegistrySearchByDetails,
        [e.target.name]: { value: value, valid: isValid?.status },
      })
    );
  };
  const checkValidationStatus = () => {
    return nationRegistrySearchByDetails.state.valid !==
      ValidationStatus.VALID ||
      nationRegistrySearchByDetails.NPILastName.valid !== ValidationStatus.VALID
      ? true
      : false;
  };

  const validateAndSetStateData = async (e: any, val: string, name: string) => {
    setNationalRegistrySearchInputs(
      getDeepClone(defaultNationalRegistrySearchBox)
    );
    const validator = new NewOrderValidator();
    let value = getCodeFromText(states, val);
    let isValid = validator.validate(value, name);
    setNationRegistrySearchByDetails(
      (nationRegistrySearchByDetails: INationalRegistrySearchByDetails) => ({
        ...nationRegistrySearchByDetails,
        [name]: { value: value, valid: isValid?.status },
      })
    );
  };

  const getTextFromCode = (array: never[], code: string): string => {
    if (code !== "" && array.length === 0) {
      return code;
    }
    return array
      .filter((item: { text: string; code: string }) => item.code === code)
      .map((x: { text: string }) => x.text)[0];
  };

  const getCodeFromText = (array: never[], input: string): string => {
    return array
      .filter((item: { text: string; code: string }) => item.text === input)
      .map((x: { code: string }) => x.code)[0];
  };

  return (
    <Grid className="searchByDetails" data-testid="searchByDetails" container>
      <Grid className="first-Container-Search" container spacing={1}>
        <Grid item className="grid-search-item" xs={isMobileScreen ? 12 : 4}>
          <InputWithLabel
            testId="stateTestID"
            label="State"
            isRequired={true}
            error={
              nationRegistrySearchByDetails.state.valid ===
              ValidationStatus.INVALID
            }
          >
            <CustomAutoComplete
              disablePortal={isMobileScreen ? true : false}
              handleChange={(e: any, val: string) =>
                validateAndSetStateData(e, val, "state")
              }
              menuItem={statesText}
              name="state"
              placeHolder="Select State"
              selectClassName={
                nationRegistrySearchByDetails.state.valid ===
                ValidationStatus.INVALID
                  ? "nationRegistry-state-input nationRegistry-state-error"
                  : "nationRegistry-state-input"
              }
              selectpropsClassName="nationRegistry-state-root"
              value={
                nationRegistrySearchByDetails.state.value
                  ? getTextFromCode(
                      states,
                      nationRegistrySearchByDetails.state.value
                    )
                  : null
              }
            />
          </InputWithLabel>
        </Grid>
        <Grid item className="grid-search-item" xs={isMobileScreen ? 12 : 4}>
          <InputWithLabel
            labelClassName="label-genral"
            testId="NPILastName"
            label="Prescriber Last Name"
            isRequired={true}
            error={
              nationRegistrySearchByDetails.NPILastName.valid ===
              ValidationStatus.INVALID
            }
          >
            <InputBase
              className="prescriber-last-name"
              name="NPILastName"
              onChange={validateAndSetData}
              value={nationRegistrySearchByDetails.NPILastName.value}
            />
          </InputWithLabel>
        </Grid>
        <Grid item className="grid-search-item" xs={isMobileScreen ? 12 : 4}>
          <InputWithLabel
            testId="NPIFirstName"
            label="Prescriber First Name"
            isRequired={false}
            error={
              nationRegistrySearchByDetails.NPIFirstName.valid ===
              ValidationStatus.INVALID
            }
          >
            <InputBase
              className="prescriber-first-name"
              name="NPIFirstName"
              onChange={validateAndSetData}
              value={nationRegistrySearchByDetails.NPIFirstName.value}
            />
          </InputWithLabel>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <ExpressButton
          testId="searchNPIDetailsbutton"
          clickHandler={handleSearchNPI}
          parentClass="searchNRByDetails"
          variant="contained"
          disabled={checkValidationStatus()}
        >
          Search
        </ExpressButton>
      </Grid>
    </Grid>
  );
};

export default NationalRegistrySearchByDetails;
