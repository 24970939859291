import { Grid, InputBase, Typography, useMediaQuery } from "@mui/material";
import { IVACTherapyLocation } from "./VACTherapyLocation.interface";
import { InputWithLabel } from "../../../core/inputWithLabel/inputWithLabel.component";
import { ValidationStatus } from "../../../core/interfaces/input.interface";
import { CustomDropDown } from "../../../core/customDropdown/customDropdown.component";
import { useContext, useEffect, useState } from "react";
import {
  NewOrderContextType,
  NewOrderContext,
} from "../../../context/NewOrderContext";
import "./VACTherapyLocation.css";
import { getTextFromCode } from "../../../util/utilityFunctions";
import { NewOrderValidator } from "../newOrder.validator";
import { format } from "react-string-format";
import { DD_VAC_THERAPY_LOCATION_CONTENT } from "../../../util/staticText";
import { getdropDownContent } from "../../../util/dropDownService";
import { VacTherapyLocationReview } from "./VACTherapyLocationReview/VACTherapyLocationReview.component";
import { ERROR_TO_SELECT_ANY_OPTION } from "../../../util/errorMsg";
import parse from "html-react-parser";

export const VACTherapyLocation = ({
  data,
  setData,
  isReviewOrder,
  editButtonClicked,
  isOrderSummary = false,
}: IVACTherapyLocation) => {
  const newOrderObj = useContext<NewOrderContextType | null>(NewOrderContext);
  const [vacTherapyLocation, setVacTherapyLocation] = useState([]);
  const [vacTherapyLocationText, setVacTherapyLocationText] = useState([]);
  const isMobileScreen = useMediaQuery("(max-width:920px)");

  const fetchDropDownContent = async () => {
    try {
      const ddContent = format("{0}", DD_VAC_THERAPY_LOCATION_CONTENT ?? "");
      const data = await getdropDownContent(ddContent);
      if (data.items.length > 0) {
        const vacTherapyLocation = data.items.filter(
          (item: { name: string }) =>
            item.name === DD_VAC_THERAPY_LOCATION_CONTENT
        );
        const therapyLocData = vacTherapyLocation[0].data.sort(
          (a: { order: number }, b: { order: number }) =>
            a.order > b.order ? 1 : -1
        );
        setVacTherapyLocation(therapyLocData);
        setVacTherapyLocationText(
          therapyLocData.map((x: { text: string }) => x.text)
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const validateAndSetVACTherapyLocData = (e: any) => {
    newOrderObj?.setIsHandleChangeTriggered(true);
    const { name, value } = e.target;
    const validator = new NewOrderValidator();
    let isValid = validator.validate(value, name);

    let tempData = {
      ...data,
      vacTherapyLocation: {
        ...data.vacTherapyLocation,
        errorMessage: "",
        valid: isValid!.status,
        value: value,
      },
    };
    if (tempData.otherVacUseLocation?.required !== (value === "Other")) {
      tempData = {
        ...tempData,
        otherVacUseLocation: {
          ...tempData.otherVacUseLocation!,
          errorMessage: "",
          required: value === "Other",
          valid:
            value === "Other"
              ? ValidationStatus.UNTOUCHED
              : ValidationStatus.VALID,
          value: "",
          isOptional: false,
        },
      };
    }
    setData(tempData);
  };

  const validateAndSetOthersData = (e: any) => {
    newOrderObj?.setIsHandleChangeTriggered(true);
    const { value } = e.target;
    const validator = new NewOrderValidator();
    let isValid = validator.validate(e.target.value, e.target.name);
    setData({
      ...data,
      otherVacUseLocation: {
        ...data.otherVacUseLocation!,
        errorMessage: "",
        valid: isValid!.status,
        value: value,
      },
    });
  };

  useEffect(() => {
    fetchDropDownContent();
  }, []);

  return (
    <>
      <div className="vac-therapy-location-div">
        {!isReviewOrder && (
          <Grid container className="location-of-vac-therapy">
            <Typography
              className="location-of-vac-therapy-heading"
              data-testid="location-of-vac-therapy-heading"
            >
              Location of V.A.C.<sup>®</sup> Therapy Use
            </Typography>
            <Grid
              item
              xs={isMobileScreen ? 11.5 : 5.7}
              className="vac-therapy-container-grid"
            >
              <InputWithLabel
                error={
                  data.vacTherapyLocation?.valid === ValidationStatus.INVALID
                }
                id={data.vacTherapyLocation?.componentId!}
                isRequired={data.vacTherapyLocation?.required}
                isShowWarning={true}
                warningMessage={
                  data.vacTherapyLocation?.errorMessage
                    ? ERROR_TO_SELECT_ANY_OPTION
                    : ""
                }
                testId={"test-vac-therapy-location"}
                labelClassName="test-vac-therapy-location"
                label={parse(data.vacTherapyLocation?.title! || "")}
                isDropdown={true}
              >
                <CustomDropDown
                  handleChange={validateAndSetVACTherapyLocData}
                  menuItem={vacTherapyLocationText}
                  name="vacTherapyLocation"
                  placeHolder="Select Location"
                  selectpropsClassName={
                    data.vacTherapyLocation?.value
                      ? "vac-therapy-loc-select"
                      : "placeHolder"
                  }
                  selectClassName={
                    data.vacTherapyLocation?.value
                      ? "vac-therapy-loc-input"
                      : "placeHolder"
                  }
                  paperPropsClassName="drop-down-values"
                  testId="vac-therapy-location-type"
                  value={
                    data.vacTherapyLocation?.value
                      ? getTextFromCode(
                          vacTherapyLocation,
                          data.vacTherapyLocation?.value
                        )
                      : null
                  }
                />
              </InputWithLabel>
            </Grid>
            {data.vacTherapyLocation &&
              data.vacTherapyLocation.value === "Other" && (
                <Grid
                  className="vac-loc-other-detail-container"
                  item
                  xs={isMobileScreen ? 11.5 : 11.7}
                >
                  <InputWithLabel
                    error={
                      data.otherVacUseLocation?.valid ===
                      ValidationStatus.INVALID
                    }
                    id={data.otherVacUseLocation?.componentId}
                    isRequired={data.otherVacUseLocation?.required}
                    isShowWarning={true}
                    label={data.otherVacUseLocation?.title!}
                    warningMessage={data.otherVacUseLocation?.errorMessage}
                    testId="vac-therapy-location-additional-data"
                  >
                    <InputBase
                      error={
                        data.otherVacUseLocation?.valid ===
                        ValidationStatus.INVALID
                      }
                      id={data.otherVacUseLocation?.id!}
                      className="vac-therapy-loc-other-input"
                      data-testid="vac-therapy-loc-other-input"
                      name="otherVacUseLocation"
                      onChange={validateAndSetOthersData}
                      value={
                        data.otherVacUseLocation &&
                        data.otherVacUseLocation.value
                      }
                      required={
                        data.vacTherapyLocation &&
                        data.vacTherapyLocation.required
                      }
                    />
                  </InputWithLabel>
                </Grid>
              )}
          </Grid>
        )}
        {isReviewOrder && (
          <VacTherapyLocationReview
            data={data}
            editButtonClicked={editButtonClicked}
            isOrderSummary={isOrderSummary}
          />
        )}
      </div>
    </>
  );
};
