import { MAX_AVAILABLE_CART_QUANTITY } from "../../../../../util/staticText";
import "./cartItem.css";
import { ICartItemProps } from "./cartItem.interface";
import { Grid, InputBase, Typography, useMediaQuery } from "@mui/material";

export const CartItem = ({
  cartItem,
  deleteItem,
  updateItemQuantity,
}: ICartItemProps) => {
  const isMobileScreen = useMediaQuery("(max-width:600px)");

  const handleQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value.length > 2) return; //restrict user from entering more than 2 digits
    const newQuantity = value === "" ? 0 : parseInt(value, 10);
    if (!isNaN(newQuantity) && newQuantity >= 0) {
      updateItemQuantity(cartItem.sku, newQuantity);
    }
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "-") {
      event.preventDefault();
    }
  };
  return (
    <div
      className="cart-item-component"
      data-testid={`cart-item-component-${cartItem.sku}`}
    >
      <div className="cart-item-details">
        {!isMobileScreen && (
          <img
            alt={cartItem.image}
            className="cart-item-product-image"
            data-testid={`cart-item-product-image-${cartItem.sku}`}
            id={`cart-item-product-image-${cartItem.sku}`}
            src={cartItem.image}
          />
        )}
        <Grid
          className="cart-item-grid-container"
          data-testid="cart-item-grid-container"
          container
        >
          <Grid
            className="cart-item-grid-item"
            data-testid="cart-item-grid-item"
            item
            xs={12}
          >
            <p
              className="cart-item-product-name"
              data-testid={`cart-item-product-name-${cartItem.sku}`}
              id={`cart-item-product-name-${cartItem.sku}`}
            >
              {cartItem.productName}
            </p>
          </Grid>
          <Grid
            className="cart-item-grid-item"
            data-testid="cart-item-grid-item"
            item
            xs={6}
          >
            <div className="cart-item-delete-button-div">
              <p
                className={`cart-item-delete-button ${
                  isMobileScreen ? "mobile" : ""
                }`}
                data-testid={`cart-item-delete-button-${cartItem.sku}`}
                id={`cart-item-delete-button-${cartItem.sku}`}
                onClick={() => deleteItem(cartItem.sku)}
              >
                Delete
              </p>
            </div>
          </Grid>
          <Grid
            className="cart-item-grid-item"
            data-testid="cart-item-grid-item"
            item
            xs={6}
          >
            <div className="stepper-count-input-div">
              <InputBase
                id={"stepper-count-input"}
                inputProps={{
                  "data-testid": "stepper-count",
                  className: "stepper-count-input-value",
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
                onChange={handleQuantityChange}
                onKeyDown={handleKeyDown}
                type="number"
                value={cartItem.quantity || ""}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      {(cartItem.quantity > MAX_AVAILABLE_CART_QUANTITY ||
        cartItem.maxAvailableQuantity === 0) && (
        <div className="max-cart-items-div">
          <Typography
            className="max-cart-items-error-message"
            id="max-cart-items-error-message"
            data-testid="max-cart-items-error-message"
          >
            Currently only {cartItem.maxAvailableQuantity} items are available.
            Please adjust your quantity.
          </Typography>
        </div>
      )}
    </div>
  );
};
