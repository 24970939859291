import {
  IInputField,
  ValidationStatus,
} from "../../../core/interfaces/input.interface";

export interface IInPatientSearchData {
  firstName: IInputField;
  lastName: IInputField;
  roNumber: IInputField;
}

export let defaultInPatientSearchData: IInPatientSearchData = {
  firstName: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
  },
  lastName: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
  },
  roNumber: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
  },
};
