import { Grid } from "@mui/material";
import { ExpressButton } from "../../../../core/expressButton/expressButton.component";
import { InputWithLabel } from "../../../../core/inputWithLabel/inputWithLabel.component";
import { IFacilityBanner } from "../facilityBannerAdmin.component";
import { useContext } from "react";
import { AuthContextType, AuthContext } from "../../../../context/AuthContext";
import "../facilityBannerResponsive/facilityBannerResponsive.css";

const FacilityBannerDesktop = ({
  renderDropDown,
  manageUserBtnCondition,
  changeFacilityBtnCondition,
  adminBtnCondition,
  salesRoleBtnCondition,
  handleChangeFacility,
  redirectToFacilitySetting,
  redirectToManageUsers,
  handlePopup,
  isFacilitySettingsPath,
}: IFacilityBanner) => {
  const authObj = useContext<AuthContextType | null>(AuthContext);
  return (
    <Grid container className="facility-desktop-banner-grid">
      <Grid xs={2.5} className="facility-desktop-banner-grid-id">
        {!isNaN(authObj?.registeredFaciltyAddress?.accountNumber!) && (
          <div className="facility-banner-id">
            <p className="facility-banner-id-details">
              Facility ID #
              <span className="facility-id-details-account-number">
                {authObj?.registeredFaciltyAddress?.accountNumber}
              </span>
            </p>
          </div>
        )}
      </Grid>
      <Grid
        xs={6}
        item
        className="facility-desktop-banner-grid-dropdown"
        data-testid="facilityBannerAlign"
      >
        <div className="facility-banner-desktop-dropdown-div">
          <InputWithLabel isDropdown={true}>{renderDropDown()}</InputWithLabel>
        </div>
      </Grid>
      <Grid
        xs={3.5}
        item
        className={`facility-desktop-banner-grid-btns ${
          changeFacilityBtnCondition && "zoom-for-multi-buttons"
        }`}
      >
        {manageUserBtnCondition && (
          <ExpressButton
            clickHandler={handlePopup ? handlePopup : redirectToManageUsers}
            parentClass="manageUsers"
            variant="outlined"
            testId="manage-user-btn"
          >
            Manage Users
          </ExpressButton>
        )}
        {changeFacilityBtnCondition && (
          <>
            <ExpressButton
              sx={{ marginRight: 1 }}
              clickHandler={handleChangeFacility}
              parentClass="adminChangeFacility"
              variant="outlined"
              testId="facility-manage-user-btn-change-Facility-admin"
            >
              Change Facility
            </ExpressButton>
            {adminBtnCondition && (
              <ExpressButton
                disabled={isFacilitySettingsPath}
                clickHandler={redirectToFacilitySetting}
                parentClass="adminFacilitySetting"
                variant="outlined"
                testId="facility-manage-user-btn-faciltity-admin"
              >
                Facility Settings
              </ExpressButton>
            )}
          </>
        )}
        {salesRoleBtnCondition && (
          <ExpressButton
            clickHandler={handleChangeFacility}
            parentClass="salesChangeFacility"
            variant="outlined"
            testId="facility-manage-user-btn-facility-sales"
          >
            Change Facility
          </ExpressButton>
        )}
      </Grid>
    </Grid>
  );
};

export default FacilityBannerDesktop;
