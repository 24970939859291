import React, { useContext, useEffect, useState } from "react";
import CustomDropZone from "../../../core/customDropZone/customDropZone.component";
import "./orderUploadDocument.css";
import {
  NewOrderContext,
  NewOrderContextType,
} from "../../../context/NewOrderContext";
import { OrderUploadDocumentReviewOrder } from "./reviewOrder/orderUploadDocumentReviewOrder.component";
import { IDropZoneDocumentSelect } from "../../../core/customDropZone/dropZoneDocumentSelect.interface";
import { IVacTherapyInformation } from "../woundBed/vacTherapyInformation.interface";

type Props = {
  editButtonClicked?: any;
  isOrderSummary?: boolean;
  isReviewOrder?: boolean;
  newOrderDocuments?: IDropZoneDocumentSelect[];
  dropdownListingType?: string;
  documentTypeText?: any;
  documentTypeCodeList?: any;
  vacTherapyInformationData?: IVacTherapyInformation;
};

const OrderUploadDocument = ({
  editButtonClicked,
  isOrderSummary = false,
  isReviewOrder = false,
  newOrderDocuments,
  dropdownListingType = "customUploadDoc",
  documentTypeText,
  documentTypeCodeList,
  vacTherapyInformationData,
}: Props) => {
  const [allDocuments, setAllDocuments] = useState<IDropZoneDocumentSelect[]>(
    []
  );
  const newOrderObj = useContext<NewOrderContextType | null>(NewOrderContext);
  const allowedFiles = {
    "image/png": [".jpeg", ".gif", ".jpg", ".png", ".tiff"],
    "text/pdf": [".pdf"],
  };

  //set the is dirty to true if any new document uploaded or existing removed
  useEffect(() => {
    if (isReviewOrder) {
      const allDocs = [
        ...(newOrderObj?.prescriptionDocuments ?? []),
        ...(newOrderDocuments ?? []),
      ];
      if (allDocs.length > 0) {
        setAllDocuments(allDocs);
      }
    }
    if (
      (newOrderObj?.deletedOrderDocuments &&
        newOrderObj?.deletedOrderDocuments.length > 0) ||
      (newOrderObj?.deletedPrescriptionDocuments &&
        newOrderObj?.deletedPrescriptionDocuments.length > 0)
    ) {
      newOrderObj?.setIsHandleChangeTriggered(true);
    }
    if (
      (newOrderObj?.newOrderDocuments &&
        newOrderObj?.newOrderDocuments.filter(
          (x) => !x.documentId && x.succeeded !== false
        ).length > 0) ||
      (newOrderObj?.prescriptionDocuments &&
        newOrderObj?.prescriptionDocuments.filter(
          (x) => !x.documentId && x.succeeded !== false
        ).length > 0)
    ) {
      newOrderObj?.setIsHandleChangeTriggered(true);
    }
  }, [
    newOrderObj?.newOrderDocuments,
    newOrderObj?.deletedOrderDocuments,
    newOrderObj?.prescriptionDocuments,
    newOrderObj?.deletedPrescriptionDocuments,
  ]);

  return (
    <div className="newOrderDoc">
      {!isReviewOrder && (
        <>
          <div className="uploadDoc">Upload Documents</div>
          <div className="uploadDocDesp">
            <p>
              Clinical documents are needed to process your order. Please upload
              or fax the patient face sheet, prescription, insurance name and
              policy number, H&P, any clinical notes, and wound documentation (
              <a
                href={vacTherapyInformationData?.npwtRequirement?.fileLink}
                target="_blank"
                className="npwtLinkFont"
              >
                {`${vacTherapyInformationData?.npwtRequirement?.labelText} `}
              </a>
              provides a complete list of needed documents).
            </p>
            <p>
              Uploaded files must be in one of the following formats: JPG, GIF,
              JPEG, PNG, TIFF or PDF. Each file cannot exceed 15 MB (15360 KB)
              in size.
            </p>
          </div>
          <CustomDropZone
            allowedFiles={allowedFiles}
            setData={newOrderObj?.setNewOrderDocuments}
            data={newOrderObj?.newOrderDocuments}
            setDeletedData={newOrderObj?.setDeletedOrderDocuments}
            dragDropText="Drag and drop files here to upload"
            buttonDropText="Select files to upload"
            dropZoneStyles="dropZoneStyles"
            dragAndDropStyles="dragAndDrop"
            uploadWidgetCard="uploadWidgetCard"
            uploadIconStyle="uploadIconStyle"
            listingType={dropdownListingType} //Here we are using the customOrderUploadDocList for listing the uploaded documents
            maxFileSize={15728640}
            singleFile={false}
            documentTypeText={documentTypeText}
            isScanBotVisible
            additionalData={newOrderObj?.prescriptionDocuments}
            setAdditionalData={newOrderObj?.setPrescriptionDocuments}
          />
        </>
      )}
      {isReviewOrder &&
        allDocuments.length > 0 &&
        documentTypeCodeList &&
        documentTypeCodeList.length > 0 && (
          <OrderUploadDocumentReviewOrder
            editButtonClicked={editButtonClicked}
            isOrderSummary={isOrderSummary}
            newOrderDocuments={allDocuments}
            documentTypeCodeList={documentTypeCodeList}
          />
        )}
    </div>
  );
};

export default OrderUploadDocument;
