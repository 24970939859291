import { useMediaQuery, Grid } from "@mui/material";
import { ExpressButton } from "../../../../core/expressButton/expressButton.component";
import { InputWithLabel } from "../../../../core/inputWithLabel/inputWithLabel.component";
import { IFacilityBanner } from "../facilityBannerAdmin.component";
import "../facilityBannerResponsive/facilityBannerResponsive.css";

const FacilityBannerMobile = ({
  renderDropDown,
  manageUserBtnCondition,
  changeFacilityBtnCondition,
  adminBtnCondition,
  salesRoleBtnCondition,
  handleChangeFacility,
  redirectToFacilitySetting,
  redirectToManageUsers,
  handlePopup,
  isFacilitySettingsPath,
  isInternalRole,
}: IFacilityBanner) => {
  const isSmallMobile = useMediaQuery("(max-width:767px)");
  return (
    <Grid container className="facility-mobile-banner-grid">
      <Grid
        xs={!isSmallMobile && isInternalRole ? 7 : 12}
        item
        className="facility-mobile-banner-grid-dropdown"
        data-testid="facilityBannerAlign"
      >
        <InputWithLabel isDropdown={true}>{renderDropDown()}</InputWithLabel>
      </Grid>
      <Grid
        xs={!isSmallMobile && isInternalRole ? 5 : 12}
        item
        className="facility-mobile-banner-grid-btns"
      >
        {manageUserBtnCondition && (
          <ExpressButton
            clickHandler={handlePopup ? handlePopup : redirectToManageUsers}
            parentClass="manageUsers"
            variant="outlined"
            testId="manage-user-btn"
          >
            Manage Users
          </ExpressButton>
        )}

        {changeFacilityBtnCondition && (
          <>
            <ExpressButton
              sx={{ marginRight: 1 }}
              clickHandler={handleChangeFacility}
              parentClass="adminChangeFacility"
              variant="outlined"
              testId="facility-manage-user-btn-change-Facility-admin"
            >
              Change Facility
            </ExpressButton>
            {adminBtnCondition && (
              <ExpressButton
                disabled={isFacilitySettingsPath}
                clickHandler={redirectToFacilitySetting}
                parentClass="adminFacilitySetting"
                variant="outlined"
                testId="facility-manage-user-btn-faciltity-admin"
              >
                Facility Settings
              </ExpressButton>
            )}
          </>
        )}
        {salesRoleBtnCondition && (
          <ExpressButton
            clickHandler={handleChangeFacility}
            parentClass="salesChangeFacility"
            variant="outlined"
            testId="facility-manage-user-btn-facility-sales"
          >
            Change Facility
          </ExpressButton>
        )}
      </Grid>
    </Grid>
  );
};

export default FacilityBannerMobile;
