import { useContext } from "react";
import "./estimatedArrivalTime.css";
import {
  AuthContext,
  AuthContextType,
} from "../../../../../context/AuthContext";
import { ICreateServiceRequest } from "../../createServiceRequest.interface";
import { EstimatedArrivalTimeMain } from "./estimatedArrivalTimeMain.component";
import {
  IInputField,
  ValidationStatus,
} from "../../../../../core/interfaces/input.interface";
import { CreateServiceRequestValidator } from "../../createServiceRequest.validator";
import { IAcuteOrderPickUpRequest } from "../../../../acutePickUpRequest/acuteOrderPickUpRequest.interface";
import { EstimatedArrivalTimeReviewRequest } from "./reviewRequest/estimatedArrivalTimeReviewRequest.component";

export interface IEstimatedArrivalTime {
  data: IAcuteOrderPickUpRequest | ICreateServiceRequest;
  editButtonAction?: any;
  isReviewRequest?: boolean;
  isReviewSummary?: boolean;
  setData: Function;
  isFromAcuteOrderPickup?: boolean;
  isCustomerOwnedProduct?: boolean;
}

export const EstimatedArrivalTime = ({
  data,
  editButtonAction,
  isReviewRequest = false,
  isReviewSummary = false,
  setData,
  isFromAcuteOrderPickup = false,
  isCustomerOwnedProduct = false,
}: IEstimatedArrivalTime) => {
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const validateAndSetData = (e: any) => {
    let { name, value } = e.target;
    let tempData = { ...data };
    if (
      name === "isCallOnEstimatedArrivalTime" &&
      value !== data.isCallOnEstimatedArrivalTime.value
    ) {
      if (value === "yes") {
        tempData = updateBasicContactDetails(tempData);
      } else {
        tempData = resetContactDetails(tempData);
      }
    }
    if (
      name === "phoneNumber" &&
      data.phoneNumber.valid === ValidationStatus.UNTOUCHED &&
      (e.target.value === "(___) ___-____" || e.target.value === "")
    ) {
      return;
    }
    const validator = new CreateServiceRequestValidator();
    const isValid = validator.validate(value, name);
    const isEmpty =
      name === "phoneNumber" && isValid?.updatedVal?.trim() === "";
    tempData = {
      ...tempData,
      [name]: {
        ...Object(tempData)[name],
        errorMessage: isEmpty ? Object(tempData)[name].errorMessage : null,
        valid: isFromAcuteOrderPickup
          ? name === "department" && value === ""
            ? ValidationStatus.VALID
            : isValid!.status
          : isValid!.status,
        value: value,
      },
    };
    setData(tempData);
  };

  const updateBasicContactDetails = (
    tempData: IAcuteOrderPickUpRequest | ICreateServiceRequest
  ): IAcuteOrderPickUpRequest | ICreateServiceRequest => {
    if (authObj && authObj.userProfile) {
      const profile = authObj.userProfile;
      const validator = new CreateServiceRequestValidator();
      const firstNameValid = validator.validate(
        profile.firstName,
        "firstName"
      )!.status;
      const lastNameValid = validator.validate(
        profile.lastName,
        "lastName"
      )!.status;
      const phoneValue =
        profile.phoneNumber && profile.phoneNumber !== ""
          ? profile.phoneNumber.slice(-10)
          : profile.mobilePhoneNumber.slice(-10);
      const phoneValid = validator.validate(phoneValue, "phone")!.status;
      const emailValue = profile.emailAddress;
      const emailValid = validator.validate(emailValue, "email")!.status;
      const departmentValue = profile.departmentName;
      const departmentValid = validator.validate(
        departmentValue,
        "department"
      )!.status;
      const emailTempData: IInputField | undefined = tempData.email
        ? {
            ...tempData.email,
            required: true,
            valid: emailValid,
            value: emailValue,
          }
        : undefined;
      tempData = {
        ...tempData,
        firstName: {
          ...tempData.firstName,
          required: true,
          valid: firstNameValid,
          value: profile.firstName,
        },
        lastName: {
          ...tempData.lastName,
          required: true,
          valid: lastNameValid,
          value: profile.lastName,
        },
        department: {
          ...tempData.department,
          required: true,
          valid: departmentValid,
          value: departmentValue,
        },
        email: emailTempData,
        phoneNumber: {
          ...tempData.phoneNumber,
          required: true,
          valid: phoneValid,
          value: phoneValue,
        },
      };
    }
    return tempData;
  };

  const resetContactDetails = (
    tempData: IAcuteOrderPickUpRequest | ICreateServiceRequest
  ): IAcuteOrderPickUpRequest | ICreateServiceRequest => {
    const emailTempData: IInputField | undefined = tempData.email
      ? {
          ...tempData.email,
          required: false,
          valid: ValidationStatus.UNTOUCHED,
          value: "",
        }
      : undefined;
    tempData = {
      ...tempData,
      firstName: {
        ...tempData.firstName,
        required: false,
        valid: ValidationStatus.UNTOUCHED,
        value: "",
      },
      lastName: {
        ...tempData.lastName,
        required: false,
        valid: ValidationStatus.UNTOUCHED,
        value: "",
      },
      department: {
        ...tempData.department,
        required: false,
        valid: ValidationStatus.UNTOUCHED,
        value: "",
      },
      email: emailTempData,
      phoneNumber: {
        ...tempData.phoneNumber,
        required: false,
        valid: ValidationStatus.UNTOUCHED,
        value: "",
      },
    };
    return tempData;
  };

  return (
    <div
      className="estimated-arrival-time-component"
      data-testid="estimated-arrival-time-component"
      id="estimated-arrival-time-component"
    >
      {isReviewRequest ? (
        <EstimatedArrivalTimeReviewRequest
          data={data}
          editButtonAction={editButtonAction}
          isReviewSummary={isReviewSummary}
          isCustomerOwnedProduct={isCustomerOwnedProduct}
        />
      ) : (
        <EstimatedArrivalTimeMain
          data={data}
          validateAndSetData={validateAndSetData}
          isFromAcuteOrderPickup={isFromAcuteOrderPickup}
          isCustomerOwnedProduct={isCustomerOwnedProduct}
        />
      )}
    </div>
  );
};
