import { Grid } from "@mui/material";
import { IFacility } from "../../manageProfile/facilityInformation/facility.interface";
import { FacilityDropDown } from "../facilityDropDown.component";
import { useContext } from "react";
import {
  getFcilityAddress,
  getMobileFacilityAddress,
} from "../../../util/utilityFunctions";
import {
  RolesPermissionContext,
  RolesPermissionContextType,
} from "../../../context/RolesPermissionContext";
import { useHistory, useLocation } from "react-router-dom";
import { AuthContext, AuthContextType } from "../../../context/AuthContext";
import {
  FacilitySettingContext,
  FacilitySettingsContextType,
} from "../../../context/FacilitySettingsContext";
import {
  InternalSignOnContext,
  InternalSignOnContextType,
} from "../../../context/InternalSignOnContext";
import {
  IMobileDisplayContext,
  MobileDisplayContext,
} from "../../../context/MobileDisplayContext";
import FacilityBannerDesktop from "./facilityBannerResponsive/facilityBanner.desktop";
import FacilityBannerMobile from "./facilityBannerResponsive/facilityBanner.mobile";

export interface IFacilityBanner {
  renderDropDown: any;
  manageUserBtnCondition: boolean;
  changeFacilityBtnCondition: boolean;
  adminBtnCondition: boolean;
  salesRoleBtnCondition: boolean;
  handleChangeFacility: any;
  redirectToFacilitySetting: any;
  redirectToManageUsers: any;
  handlePopup: boolean;
  isFacilitySettingsPath: boolean;
  isInternalRole: boolean;
}

type Props = {
  userLinkedFacilityData: IFacility[];
  openPopUp?: boolean;
  handlePopUp?: any;
};
export const FacilityBannerAdmin = ({
  userLinkedFacilityData,
  handlePopUp,
}: Props) => {
  const history = useHistory();
  const location = useLocation();
  const { isMobileScreen } =
    useContext<IMobileDisplayContext>(MobileDisplayContext);
  const isFacilitySettingsPath = location.pathname.includes(
    "/administration/facilitySettings"
  );
  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );
  const internalSignOnObj = useContext<InternalSignOnContextType | null>(
    InternalSignOnContext
  );
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const facilitySettingsObj = useContext<FacilitySettingsContextType | null>(
    FacilitySettingContext
  );

  const handleChangeFacility = () => {
    history.push("/ssoRedirect");
    // commenting to solve dirty check
    // authObj?.setregisteredFaciltyAddress(undefined);
    // authObj?.setIsInternalUserFacilitySelected(false);
    internalSignOnObj?.setIsManualFacility(false);
  };

  const redirectToFacilitySetting = () => {
    if (authObj && authObj.registeredFaciltyAddress) {
      facilitySettingsObj?.resetFacilitySettings();
      history.push({
        pathname: "/administration/facilitySettings",
        state: {
          selectedFacility: authObj.registeredFaciltyAddress,
        },
      });
    }
  };

  const redirectToManageUsers = () => {
    history.push("/administration/manageUsers");
  };
  const isInternalRole = authObj?.isInternalUser;
  const textRenderCondition = isInternalRole && !isMobileScreen;

  const RenderDropDown = () => {
    return (
      <>
        {userLinkedFacilityData.length > 1 && (
          <FacilityDropDown userLinkedFacilityData={userLinkedFacilityData} />
        )}
        {userLinkedFacilityData.length === 1 &&
          !permissionObj?.mappedRolesPermissionData.IsClinicianRole && (
            <>
              <div
                className={`facilityBanner-label ${
                  textRenderCondition && "text-center"
                }`}
                data-testid="label-admin"
              >
                {internalSignOnObj?.isManualFacility
                  ? `${userLinkedFacilityData[0].accountName}`
                  : isMobileScreen
                  ? getMobileFacilityAddress(userLinkedFacilityData[0])
                  : getFcilityAddress(userLinkedFacilityData[0])}
              </div>
            </>
          )}
      </>
    );
  };

  const manageUserBtnCondition =
    permissionObj?.mappedRolesPermissionData.IsFacilityAdminRole &&
    permissionObj?.mappedRolesPermissionData.IsManageUsersBtn &&
    location.pathname !== "/administration/manageUsers";

  const changeFacilityBtnCondition =
    permissionObj?.mappedRolesPermissionData.Is3MAdminRole ||
    permissionObj?.mappedRolesPermissionData.IsSupportRole ||
    permissionObj?.mappedRolesPermissionData.IsProdManagerRole ||
    permissionObj?.mappedRolesPermissionData.IsNationalRole;

  const adminBtnCondition =
    permissionObj?.mappedRolesPermissionData.IsAdminFacilitySettingsButton;

  const salesRoleBtnCondition =
    permissionObj?.mappedRolesPermissionData.IsSalesRole ||
    permissionObj?.mappedRolesPermissionData.IsSalesManagerRole;

  const facilityBannerProps: IFacilityBanner = {
    renderDropDown: RenderDropDown,
    manageUserBtnCondition: manageUserBtnCondition!,
    changeFacilityBtnCondition: changeFacilityBtnCondition!,
    adminBtnCondition: adminBtnCondition!,
    salesRoleBtnCondition: salesRoleBtnCondition!,
    handleChangeFacility,
    redirectToFacilitySetting,
    redirectToManageUsers,
    handlePopup: handlePopUp,
    isFacilitySettingsPath,
    isInternalRole: isInternalRole!,
  };
  return (
    <>
      {isMobileScreen ? (
        <FacilityBannerMobile {...facilityBannerProps} />
      ) : (
        <FacilityBannerDesktop {...facilityBannerProps} />
      )}
    </>
  );
};
