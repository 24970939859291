import { IAcuteOrderFailurePopup } from "./inpatientOrderPopup.enum";

export const acuteOrderFailurePopupStaticText: IAcuteOrderFailurePopup = {
  popupTitle: "Your order cannot be submitted at this time. Please try again.",
  popupDescription1p1:
    "There was an issue in submitting your order. It has been saved to your ",
  popupDescription1p2: " list and can be submitted again.",
  popUpDescription2:
    "If you see this message repeatedly, please call the 3M Service Center at 1-800-275-4524 for more assistance.",
  popUpButton1Title: "Submit Order Again",
  popUpButton2Title: "Go to My Patients and try again later",
};

export const reSubmitWoundInfoFailurePopupStaticText: IAcuteOrderFailurePopup =
  {
    popupTitle: "Wound details were not added to your order. Please try again.",
    popupDescription1p1:
      "Your order has been successfully submitted, but your wound information failed to be added. Please submit your wound information again or go to ",
    popupDescription1p2: " and try again later.",
    popUpDescription2:
      "If you see this message repeatedly, please call the 3M Service Center at 1-800-275-4524 for more assistance.",
    popUpButton1Title: "Resubmit Wound Information",
    popUpButton2Title: "Go to My Patients and try again later",
  };
