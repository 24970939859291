import "./nationalRegistryNoResult.css";
import { ExpressButton } from "../../../../../../../core/expressButton/expressButton.component";

export interface INationalRegistryNoResult {
  searchAgainButtonAction: Function;
}

export const NationalRegistryNoResult = ({
  searchAgainButtonAction,
}: INationalRegistryNoResult) => {
  return (
    <div
      className="national-registry-no-result-component"
      data-testid="national-registry-no-result-component"
    >
      <p
        className="national-registry-no-result-header"
        data-testid="national-registry-no-result-header"
        id="national-registry-no-result-header"
      >
        National Registry Search Results
      </p>
      <div className="national-registry-no-result-description">
        <p
          className="national-registry-no-result-description-text"
          data-testid="national-registry-no-result-description-text"
          id="national-registry-no-result-description-text"
        >
          No results
        </p>
      </div>
      <ExpressButton
        clickHandler={() => searchAgainButtonAction()}
        id="national-registry-no-result-search-again-button"
        parentClass="national-registry-no-result-search-again-button"
        testId="national-registry-no-result-search-again-button"
        variant="outlined"
      >
        Search Again
      </ExpressButton>
    </div>
  );
};
