import { FacilityData } from "../homeCareOrderFound/facilityData/facilityFoundData.component";
import "./facilityNotConnectedOrder.css";
import { Typography } from "@mui/material";
import { IFacilityNotConnectedOrder } from "./facilityNotConnectedOrder.interface";

export const FacilityNotConnected = ({
  homeCareOrderDetails,
  redirectHandler,
}: IFacilityNotConnectedOrder) => {
  return (
    <>
      <div className="facility-not-connected-container">
        <Typography
          className="facility-not-connected-description1"
          data-testid="facility-not-connected-description1"
        >
          The facility associated with this order has not been added yet
        </Typography>
        <Typography
          className="facility-not-connected-description2"
          data-testid="facility-not-connected-description2"
        >
          Would you like to view the order anyway?
        </Typography>

        <div
          className={
            homeCareOrderDetails && !homeCareOrderDetails?.requestor?.siteUseID
              ? "facility-not-associated"
              : ""
          }
        >
          {homeCareOrderDetails &&
            !homeCareOrderDetails?.requestor?.siteUseID && (
              <FacilityData
                facilityData={homeCareOrderDetails.requestor!}
                facilityType="manualEntry"
                type={homeCareOrderDetails.type}
                patientData={homeCareOrderDetails.patientInfo}
                redirectHandler={redirectHandler}
                orderFacilityType="manual"
              />
            )}
        </div>
      </div>
    </>
  );
};
