import "./manageUsers.css";
import {
  getFacilityPermissionName,
  getSiteUseId,
  updatePermissionBasedOnAcuteFacility,
  useSortableTable,
} from "../../../util/utilityFunctions";
import { useDebounce } from "use-debounce";
import { format } from "react-string-format";
import {
  UserProfileContext,
  UserProfileContextType,
} from "../../../context/UserProfileContext";
import {
  RolesPermissionContext,
  RolesPermissionContextType,
} from "../../../context/RolesPermissionContext";
import { Button, InputBase } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import AddIcon from "@mui/icons-material/AddCircleOutline";
import { Popup } from "../../../core/popup/popup.component";
import { getProperty } from "../../../util/ObjectFunctions";
import { DD_ALLSTATUS_CONTENT } from "../../../util/staticText";
import { getdropDownContent } from "../../../util/dropDownService";
import { getRolePermissions } from "../../../util/facilityService";
import { LoadingSpinner } from "../../../core/loader/LoadingSpinner";
import { getUserMangerList } from "../../../util/facilityUserService";
import { columns } from "./manageUserTable/manageUsersListTable.model";
import {
  IGetUserMangerListApiResponse,
  IUserFacilityData,
  IUserFacilityReturnData,
  IUserPermissionsData,
} from "./userProfile/userProfile.interface";
import { AuthContext, AuthContextType } from "../../../context/AuthContext";
import { Navigator } from "../../helpAndSupport/Navigator/navigator.component";
import { UserProfileComingFrom } from "../manageUserAccounts/manageUserAccounts.enum";
import { IFacility } from "../../manageProfile/facilityInformation/facility.interface";
import { CustomDropDown } from "../../../core/customDropdown/customDropdown.component";
import { ManageUsersListTable } from "./manageUserTable/manageUsersListTable.component";
import { IManageUserListTableData } from "./manageUserTable/manageUsersListTable.interface";
import {
  InternalSignOnContextType,
  InternalSignOnContext,
} from "../../../context/InternalSignOnContext";
import ErrorPopup from "../../../core/errorPopup/errorPopup.component";
import { SVC_ADD_NEW_USER, SVC_FETCH_USERS } from "../../../util/errorMsg";
import {
  LOGGED_IN_USERNAME_FAILURE,
  SITEUSEID_FROM_REGISTERED_FACILITY_OBJ_FAILURE,
} from "../../../util/errorCode";

export const ManageUsers = () => {
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );
  const userProfileObj = useContext<UserProfileContextType | null>(
    UserProfileContext
  );
  const internalObj = useContext<InternalSignOnContextType | null>(
    InternalSignOnContext
  );
  const history = useHistory();
  const [statusType, setStatusType] = useState([]);
  const [statusTypeText, setStatusTypeText] = useState([]);
  const [siteUseId, setSiteUseId] = useState<string>("");
  const [selectedStatusType, setSelectedStatusType] = useState<string>("");
  const [searchInput, setSearchInput] = useState<string>("");
  const [searchedInput, setSearchedInput] = useState<string>("");
  const [debouncedText] = useDebounce(searchInput, 500);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [errorCode, setErrorCode] = useState<string>("");

  const [originalData, setOriginalData] = useState<IManageUserListTableData[]>(
    []
  );
  const [sortData, setSortData, handleSortedData] = useSortableTable(
    [],
    columns
  );
  const [openLoaderPopUp, setOpenLoaderPopUp] = useState<boolean>(false);
  const [failurePopUp, setFailurePopUp] = useState<boolean>(false);
  const location: any = useLocation();

  const checkManageUsersDeeplink = () => {
    return authObj?.deepLinkPath &&
      authObj?.deepLinkPath.includes("/administration/manageUsers/deepLink")
      ? true
      : false;
  };
  const setUpdateDeepLinkSiteUseID = () => {
    let parsedSiteUseId = null;
    if (checkManageUsersDeeplink() && location.state?.stateData === undefined) {
      const urlData = authObj?.deepLinkPath?.split("/");
      parsedSiteUseId = atob(urlData![4]);
    } else if (
      checkManageUsersDeeplink() &&
      location.state?.stateData !== undefined
    ) {
      parsedSiteUseId = location.state?.stateData;
    }
    return parsedSiteUseId;
  };
  const [deepLinkSiteUseID, setDeepLinkSiteUseID] = useState(
    setUpdateDeepLinkSiteUseID()
  );
  const checkValidFacility = (
    allFacilities: IFacility[],
    siteUseID: string
  ) => {
    let facilityFound: IFacility[] = allFacilities?.filter(
      (item: IFacility) => item.siteUseId === siteUseID
    );
    if (facilityFound.length === 0) {
      history.replace("/home");
    } else {
      authObj?.setregisteredFaciltyAddress(facilityFound[0]);
    }
  };
  const getUserMangerListFromServer = async () => {
    setOpenLoaderPopUp(true);
    const result = await Promise.all([
      getUserMangerListAPI(),
      fetchDropDownContent(),
    ]);
    setOpenLoaderPopUp(false);
    if (result.length === 2) {
      const getUserMangerListResp = result[0];
      if (!getUserMangerListResp.succeeded && getUserMangerListResp.errorCode) {
        setFailurePopUp(true);
        setErrorMessage(SVC_FETCH_USERS);
        setErrorCode(getUserMangerListResp?.errorCode);
      } else {
        if (getUserMangerListResp && getUserMangerListResp.data) {
          setOriginalData(getUserMangerListResp.data);
          setSortData(getUserMangerListResp.data);
        }
      }
    }
    setDeepLinkSiteUseID(null);
    if (
      authObj?.deepLinkPath &&
      authObj?.deepLinkPath.includes("/administration/manageUsers/deepLink")
    ) {
      authObj?.setDeepLinkPath(null);
      sessionStorage.removeItem("DeepLinkPath");
    }
  };

  const getUserMangerListAPI =
    async (): Promise<IGetUserMangerListApiResponse> => {
      const authDetails = sessionStorage.getItem("okta-token-storage");
      const data = JSON.parse(authDetails ?? "");
      const userName = data?.idToken?.claims?.preferred_username;
      const siteUseId = getSiteUseId(authObj);
      if (siteUseId !== "" && userName && userName !== "") {
        const params = {
          SiteUseId: checkManageUsersDeeplink() ? deepLinkSiteUseID : siteUseId,
          UserEmail: userName,
        };
        const response = await getUserMangerList(params);
        if (response && response.succeeded) {
          return { succeeded: true, data: response.items };
        } else {
          return {
            succeeded: false,
            errorCode:
              response?.error?.errorCode ||
              response?.status ||
              response?.error?.code,
          };
        }
      } else {
        if (
          !authObj ||
          (authObj && !authObj.registeredFaciltyAddress?.siteUseId) ||
          (authObj && authObj.registeredFaciltyAddress?.siteUseId === "")
        ) {
          return {
            succeeded: false,
            errorCode: SITEUSEID_FROM_REGISTERED_FACILITY_OBJ_FAILURE,
          };
        } else {
          return { succeeded: false, errorCode: LOGGED_IN_USERNAME_FAILURE };
        }
      }
    };

  const getRolePermissionsInfo = async (siteUseId: string) => {
    const reqBody = {
      siteUseId: siteUseId,
    };
    const response = await getRolePermissions(reqBody);
    if (response && response.succeeded) {
      const permissions = response.data.permissions;
      if (permissions.length > 0) {
        const validFacilityPermissionArray: [] = permissions.filter(
          (x: any) => getFacilityPermissionName(x.permissionName) !== ""
        );
        return {
          siteUseId: siteUseId,
          permissions: validFacilityPermissionArray,
          succeeded: true,
        };
      } else {
        return {
          siteUseId: siteUseId,
          permissions: [],
          succeeded: false,
          errorCode:
            response?.error?.errorCode ||
            response?.status ||
            response?.error?.code,
        };
      }
    } else {
      setErrorMessage(SVC_ADD_NEW_USER);
      setErrorCode(
        response?.error?.errorCode || response?.status || response?.error?.code
      );
      setOpenLoaderPopUp(false);
      setFailurePopUp(true);
      return {
        siteUseId: siteUseId,
        permissions: [],
        succeeded: false,
        errorCode:
          response?.error?.errorCode ||
          response?.status ||
          response?.error?.code,
      };
    }
  };

  const fetchDropDownContent = async () => {
    try {
      const ddContent = format("{0}", DD_ALLSTATUS_CONTENT ?? "");
      const data = await getdropDownContent(ddContent);
      if (data.items.length > 0) {
        const statusTypeObject = data.items.filter(
          (item: { name: string }) => item.name === DD_ALLSTATUS_CONTENT
        );
        const statusTypeData = statusTypeObject[0].data.sort(
          (a: { order: number }, b: { order: number }) =>
            a.order > b.order ? 1 : -1
        );
        setStatusType(statusTypeData);
        setStatusTypeText(statusTypeData.map((x: { text: string }) => x.text));
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const closeFailurePpoUp = () => {
    setFailurePopUp(false);
    history.push("/administration");
  };

  const openUserProfile = async (
    isAddingNewUser: boolean,
    selectedUserName: string
  ) => {
    userProfileObj?.resetUserProfile();
    if (isAddingNewUser) {
      setOpenLoaderPopUp(true);
      const addUserFaciltyDetails: IUserFacilityReturnData =
        await mapUserFacilityDataAndUpdatePermissions();
      setOpenLoaderPopUp(false);
      if (addUserFaciltyDetails && addUserFaciltyDetails.succeeded) {
        history.push({
          pathname: "/administration/manageUsers/userProfile",
          state: {
            isAddingNewUser: isAddingNewUser,
            isComingFrom: UserProfileComingFrom.MANAGE_USER,
            regFacility: [addUserFaciltyDetails.data],
            selectedUserName: selectedUserName,
          },
        });
      }
    } else {
      history.push({
        pathname: "/administration/manageUsers/userProfile",
        state: {
          isAddingNewUser: isAddingNewUser,
          isComingFrom: UserProfileComingFrom.MANAGE_USER,
          selectedUserName: selectedUserName,
        },
      });
    }
  };

  const handleAddNewUser = () => {
    openUserProfile(true, "");
  };

  const openUserDetails = (data: IManageUserListTableData) => {
    openUserProfile(false, data.userName);
  };

  const validateAndSetData = (e: any) => {
    setSelectedStatusType(e.target.value);
    filterUsers(debouncedText, e.target.value);
  };

  const handleSearch = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const re = /^[^\s][\s\S]*$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setSearchInput(e.target.value);
    }
  };

  const filterUsers = (searchParam: string, status: string) => {
    setSearchedInput(searchParam);
    let searchStatus = "";
    if (status !== "All statuses") {
      searchStatus = status;
    }
    if (originalData && originalData.length > 0) {
      let filtedUsers = originalData;
      if (searchStatus.length > 0) {
        filtedUsers = filtedUsers.filter(
          (user: IManageUserListTableData) =>
            user.status.toLocaleLowerCase() === searchStatus.toLowerCase()
        );
      }
      if (searchParam.length > 0) {
        filtedUsers = filtedUsers.filter(
          (user: IManageUserListTableData) =>
            user.userName
              .toLocaleLowerCase()
              .includes(searchParam.toLowerCase()) ||
            user.firstName
              .toLocaleLowerCase()
              .includes(searchParam.toLowerCase()) ||
            user.lastName
              .toLocaleLowerCase()
              .includes(searchParam.toLowerCase())
        );
      }
      setSortData(filtedUsers);
    }
  };

  const mapUserFacilityDataAndUpdatePermissions = async () => {
    let userFacilityReturnData: IUserFacilityReturnData;
    if (
      authObj &&
      authObj.registeredFaciltyAddress &&
      authObj.registeredFaciltyAddress.siteUseId &&
      authObj.registeredFaciltyAddress.siteUseId !== ""
    ) {
      const permissionData = await getRolePermissionsInfo(
        authObj.registeredFaciltyAddress.siteUseId!
      );
      if (permissionData && permissionData.succeeded) {
        const isPostAcute =
          authObj.registeredFaciltyAddress?.isPostAcute ?? false;
        let permissions: IUserPermissionsData[] = [];
        let enabledPermissionsCount = 0;
        let availablePermissionsCount = 0;
        permissionData.permissions.forEach((permission: any) => {
          let status: boolean = false;
          if (permission.adminRegistered) {
            if (typeof permission.adminRegistered === "string") {
              status = permission.adminRegistered === "Enabled";
            } else if (typeof permission.adminRegistered === "boolean") {
              status = permission.adminRegistered;
            }
          }
          const isShowPermission = updatePermissionBasedOnAcuteFacility(
            isPostAcute,
            permission.permissionName
          );
          if (isShowPermission) {
            if (permission.adminRegistered) {
              enabledPermissionsCount += 1;
            }
            availablePermissionsCount += 1;
          }
          permissions.push({
            category: permission.category,
            isHide: !isShowPermission,
            name: permission.permissionName,
            status: permission.adminRegistered ? status : false,
          });
        });
        const registeredFacility: IUserFacilityData = {
          accountName: authObj.registeredFaciltyAddress.accountName,
          activityStauts: 2,
          accountNumber: authObj.registeredFaciltyAddress.accountNumber ?? 0,
          address1: authObj.registeredFaciltyAddress.address1,
          address2: authObj.registeredFaciltyAddress.address2,
          facilityAddressID: parseInt(
            authObj.registeredFaciltyAddress.facilityAddressID ?? ""
          ),
          city: authObj.registeredFaciltyAddress.city,
          state: authObj.registeredFaciltyAddress.state,
          zip: authObj.registeredFaciltyAddress.zip.toString(),
          userRole: "Clinician",
          status: "Active",
          siteUseId: authObj.registeredFaciltyAddress.siteUseId!,
          permissions: permissions,
          enabledPermissionsCount: enabledPermissionsCount,
          availablePermissionsCount: availablePermissionsCount,
          siteUseCode: authObj.registeredFaciltyAddress.siteUseCode,
          typeCode: authObj.registeredFaciltyAddress.typeCode,
        };
        userFacilityReturnData = {
          succeeded: true,
          data: registeredFacility,
        };
        return userFacilityReturnData;
      } else {
        userFacilityReturnData = {
          succeeded: false,
          errorCode: permissionData.errorCode,
        };
        return userFacilityReturnData;
      }
    }
    userFacilityReturnData = {
      succeeded: false,
    };
    return userFacilityReturnData;
  };

  useEffect(() => {
    if (
      authObj &&
      authObj?.allFacilities &&
      authObj.registeredFaciltyAddress &&
      authObj.userRolesAndPermissionLoaded &&
      authObj.registeredFaciltyAddress.siteUseId &&
      authObj.registeredFaciltyAddress.siteUseId !== "" &&
      getProperty(authObj.sideNavMenuOptionModelData, "administration")
        .isVisible &&
      (siteUseId === "" ||
        siteUseId !== authObj.registeredFaciltyAddress.siteUseId)
    ) {
      if (checkManageUsersDeeplink()) {
        checkValidFacility(authObj?.allFacilities, deepLinkSiteUseID!);
      } else {
        checkValidFacility(
          authObj?.allFacilities,
          authObj?.registeredFaciltyAddress.siteUseId
        );
      }
      setSiteUseId(authObj.registeredFaciltyAddress.siteUseId);
      getUserMangerListFromServer();
    } else if (
      !getProperty(authObj?.sideNavMenuOptionModelData, "administration")
        .isVisible
    ) {
      history.push("/home");
    }
  }, [authObj?.registeredFaciltyAddress?.siteUseId, authObj?.allFacilities]);

  useEffect(() => {
    if (
      (debouncedText.length === 0 && searchedInput !== debouncedText) ||
      debouncedText.length > 0
    ) {
      filterUsers(debouncedText, selectedStatusType);
    }
  }, [debouncedText]);

  useEffect(() => {
    filterUsers(debouncedText, "All statuses");
  }, [originalData]);

  return (
    <div className="manage-users-component-container">
      <div
        className="manage-users-component"
        data-testid="manage-users-component"
      >
        <Navigator
          array={[
            {
              route: internalObj?.isInternalAdministration
                ? "/internalAdministration"
                : "/administration",
              pageName: "Administration",
            },
          ]}
          className="manage-users-component-route-section"
          title="Manage Users"
        />
        <div className="manage-users-main-section">
          <span
            className="manage-users-main-section-header"
            data-testid="manage-users-main-section-header"
          >
            Manage Users
          </span>
        </div>
        <div className="filter-mng-usr">
          <div className="user-searchbar">
            <div className="search-icon-div">
              <SearchIcon className="search-icon" />
            </div>
            <InputBase
              className="user-search-input"
              data-testid="user-search-input"
              name="search-input"
              onChange={handleSearch}
              placeholder="Filter by First Name, Last Name or Email Id"
              value={searchInput}
            />
          </div>
          <CustomDropDown
            handleChange={validateAndSetData}
            menuItem={statusTypeText}
            name="user-status"
            placeHolder="All statuses"
            selectpropsClassName={
              selectedStatusType ? "user-status-select" : "placeHolder"
            }
            selectClassName={
              selectedStatusType ? "user-status-input" : "placeHolder"
            }
            testId="user-status"
            value={selectedStatusType ? selectedStatusType : null}
          />
          <Button
            classes={{ root: "add-new-user-btn" }}
            startIcon={<AddIcon />}
            onClick={handleAddNewUser}
            disabled={permissionObj?.mappedRolesPermissionData.IsSupportRole}
            data-testid={"mng-add-usr-btn"}
          >
            Add New User
          </Button>
        </div>
        <div className="users-table">
          <ManageUsersListTable
            columns={columns}
            handleSorting={handleSortedData}
            openUserDetails={openUserDetails}
            sortedData={sortData}
          />
        </div>
      </div>
      <Popup
        openFlag={openLoaderPopUp}
        closeHandler={() => setOpenLoaderPopUp(false)}
        dialogParentClass={"fetch-users-loader-pop-up"}
        data-testid="fetch-users-pop-up"
        hideCloseButton={true}
      >
        <div className="fetch-users-loader">
          <LoadingSpinner />
        </div>
      </Popup>
      <ErrorPopup
        popUpStyles="error-popup-design"
        errorMessage={errorMessage}
        errorPopupFlag={failurePopUp}
        handleBackButton={() => {
          closeFailurePpoUp();
        }}
        buttontitle="Done"
        errorCode={errorCode}
        showTitle={false}
        isSupportPhoneRequired={false}
      />
    </div>
  );
};
