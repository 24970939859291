import {
  IInpatientWoundInformation,
  IWoundInformation,
} from "../woundInformation.interface";
import React, { useContext } from "react";
import { Grid, Typography } from "@mui/material";
import {
  IMobileDisplayContext,
  MobileDisplayContext,
} from "../../../../../context/MobileDisplayContext";
import "../reviewWoundInformation/woundInformationReview.css";
import { formatedWoundValue } from "../../../../../util/utilityFunctions";
import moment from "moment";

interface IWoundInformationReviewProps {
  data: IWoundInformation;
  editButtonAction: Function;
  isFromCustomerPlusOrderOverView?: boolean;
  isSummary: boolean;
  isFromOrderOverView?: boolean;
}

const WoundInformationSectionReview = ({
  data,
  isFromCustomerPlusOrderOverView,
  isSummary,
  editButtonAction,
  isFromOrderOverView = false,
}: IWoundInformationReviewProps) => {
  const { isMobileScreen } =
    useContext<IMobileDisplayContext>(MobileDisplayContext);
  const count = data?.woundInfoCount?.value
    ? Number(data?.woundInfoCount?.value)
    : 0;
  const checkForOrderOverView = isFromOrderOverView && count === 0;
  const isShowPrimaryWoundInfo = count === 1 || count > 1;
  const isShowSecondaryWoundInfo = count === 2;

  const getWoundVolume = (woundData: IInpatientWoundInformation): string => {
    const lengthValue = woundData.woundLength.value;
    const widthValue = woundData.woundWidth.value;
    const depthValue = woundData.woundDepth.value;
    if (
      isNaN(parseFloat(lengthValue)) ||
      isNaN(parseFloat(widthValue)) ||
      isNaN(parseFloat(depthValue))
    ) {
      return "--";
    }
    const length = parseFloat(lengthValue);
    const width = parseFloat(widthValue);
    const depth = parseFloat(depthValue);
    const volume = (length * width * depth).toFixed(1);
    return `${formatedWoundValue(volume.toString())} cm³`;
  };

  const checkAndSetUnits = (value: string, unit?: string) => {
    if (value === "" && !value) {
      return "";
    } else {
      return value + " " + unit;
    }
  };

  const showTitleAndValue = (
    title: string,
    value: string | null,
    testId: string
  ) => {
    return (
      <div className="show-title-and-value-div" data-testid={testId}>
        <p
          className="show-title-and-value-title"
          data-testid={`${testId}-title`}
          id={`${testId}-title-id`}
        >
          {title}
        </p>
        <p
          className="show-title-and-value-value"
          data-testid={`${testId}-value`}
          id={`${testId}-value-id`}
        >
          {value && value !== "" && value !== "\n" ? value.trim() : "--"}
        </p>
      </div>
    );
  };

  return (
    <Grid
      container
      className="inpatient-wound-info-review-component"
      data-testid="inpatient-wound-info-review-component"
    >
      <Grid
        item
        xs={12}
        className={`${
          isFromCustomerPlusOrderOverView
            ? "inpatient-wound-info-review-header-orderOverview"
            : "inpatient-wound-info-review-header"
        } ${count > 0 && "margin-bottom"}`}
      >
        <Typography
          className="inpatient-wound-info-review-title"
          data-testid="inpatient-wound-info-review-title"
        >
          Wound Information {!isFromOrderOverView && "(optional)"}
        </Typography>
        {!isSummary && (
          <button
            className="inpatient-wound-info-review-edit-button"
            data-testid="inpatient-wound-info-review-edit-button"
            id="inpatient-wound-info-review-edit-button-id"
            onClick={() => editButtonAction("inpatient-wound-info-main-div")}
          >
            Edit
          </button>
        )}
      </Grid>
      {!isShowSecondaryWoundInfo &&
        !isShowPrimaryWoundInfo &&
        !checkForOrderOverView &&
        showTitleAndValue("", "--", "in-patient-review-no-data")}
      {(isShowPrimaryWoundInfo || checkForOrderOverView) && (
        <>
          <Grid
            xs={12}
            data-testid="inpatient-wound-info-review-primary-section"
          >
            {!checkForOrderOverView && (
              <Typography
                className="inpatient-wound-info-review-sub-title"
                data-testid="inpatient-wound-info-review-sub-title-primary"
              >
                Wound #1
              </Typography>
            )}
          </Grid>
          <Grid item xs={isMobileScreen ? 12 : 4}>
            {showTitleAndValue(
              "Wound Type and Location",
              data.primaryWoundInformation.woundType.value +
                "\n" +
                data.primaryWoundInformation.woundLocation.value,
              "in-patient-review-primary-wound-type-location"
            )}
          </Grid>
          <Grid item xs={isMobileScreen ? 12 : 5}>
            {showTitleAndValue(
              "Wound Direction and Orientation",
              data.primaryWoundInformation.woundDirection.value +
                "\n" +
                data.primaryWoundInformation.woundOrientation.value,
              "in-patient-review-primary-wound-direction-orientation"
            )}
          </Grid>
          <Grid item xs={isMobileScreen ? 12 : 4}>
            {showTitleAndValue(
              "Wound Measurement Date",
              data.primaryWoundInformation.woundMeasurementDate.value !== ""
                ? `${moment(
                    data.primaryWoundInformation.woundMeasurementDate.value
                  ).format("MM/DD/YYYY")}`
                : "--",
              "in-patient-review-primary-wound-measurement-date"
            )}
          </Grid>
          <Grid item xs={isMobileScreen ? 12 : 8}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {showTitleAndValue(
                "Length",
                checkAndSetUnits(
                  data.primaryWoundInformation.woundLength.value,
                  "cm"
                ),
                "in-patient-review-primary-wound-length"
              )}
              {showTitleAndValue(
                "Width",
                checkAndSetUnits(
                  data.primaryWoundInformation.woundWidth.value,
                  "cm"
                ),
                "in-patient-review-primary-wound-width"
              )}
              {showTitleAndValue(
                "Depth",
                checkAndSetUnits(
                  data.primaryWoundInformation.woundDepth.value,
                  "cm"
                ),
                "in-patient-review-primary-wound-depth"
              )}{" "}
              {showTitleAndValue(
                "Volume",
                checkAndSetUnits(
                  getWoundVolume(data.primaryWoundInformation),
                  ""
                ),
                "in-patient-review-primary-wound-volume"
              )}
            </div>
          </Grid>
        </>
      )}
      {isShowSecondaryWoundInfo && (
        <>
          <Grid
            xs={12}
            data-testid="inpatient-wound-info-review-secondary-section"
          >
            <Typography
              className="inpatient-wound-info-review-sub-title"
              data-testid="inpatient-wound-info-review-sub-title-secondary"
            >
              Wound #2
            </Typography>
          </Grid>
          <Grid item xs={isMobileScreen ? 12 : 4}>
            {showTitleAndValue(
              "Wound Type and Location",
              data.secondaryWoundInformation.woundType.value +
                "\n" +
                data.secondaryWoundInformation.woundLocation.value,
              "in-patient-review-secondary-wound-type-location"
            )}
          </Grid>
          <Grid item xs={isMobileScreen ? 12 : 5}>
            {showTitleAndValue(
              "Wound Direction and Orientation",
              data.secondaryWoundInformation.woundDirection.value +
                "\n" +
                data.secondaryWoundInformation.woundOrientation.value,
              "in-patient-review-secondary-wound-direction-orientation"
            )}
          </Grid>
          <Grid item xs={isMobileScreen ? 12 : 4}>
            {showTitleAndValue(
              "Wound Measurement Date",
              data.secondaryWoundInformation.woundMeasurementDate.value !== ""
                ? `${moment(
                    data.secondaryWoundInformation.woundMeasurementDate.value
                  ).format("MM/DD/YYYY")}`
                : "--",
              "in-patient-review-secondary-wound-measurement-date"
            )}
          </Grid>
          <Grid item xs={isMobileScreen ? 12 : 8}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {showTitleAndValue(
                "Length",
                checkAndSetUnits(
                  data.secondaryWoundInformation.woundLength.value,
                  "cm"
                ),
                "in-patient-review-secondary-wound-length"
              )}
              {showTitleAndValue(
                "Width",
                checkAndSetUnits(
                  data.secondaryWoundInformation.woundWidth.value,
                  "cm"
                ),
                "in-patient-review-secondary-wound-width"
              )}
              {showTitleAndValue(
                "Depth",
                checkAndSetUnits(
                  data.secondaryWoundInformation.woundDepth.value,
                  "cm"
                ),
                "in-patient-review-secondary-wound-depth"
              )}{" "}
              {showTitleAndValue(
                "Volume",
                checkAndSetUnits(
                  getWoundVolume(data.secondaryWoundInformation),
                  ""
                ),
                "in-patient-review-secondary-wound-volume"
              )}
            </div>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default WoundInformationSectionReview;
