import "./patientData.css";
import { Grid, Typography } from "@mui/material";
import { IPatientDataProps } from "./patientData.interface";
import { makeCapitalEachWordInString } from "../../../../util/utilityFunctions";
import { useContext } from "react";
import { MobileDisplayContext } from "../../../../context/MobileDisplayContext";

export const PatientData = ({ patient }: IPatientDataProps) => {
  const { isMobileScreen } = useContext(MobileDisplayContext);
  return (
    <Grid className="patient-data-container" container>
      <Grid className="order-found-patient-name" item xs={12} marginBlock={1}>
        <Typography
          className="patient-name"
          variant="h6"
          data-testid="patient-name"
        >
          {makeCapitalEachWordInString(
            `${patient.firstName + ", " + patient.lastName}`
          )}
        </Typography>
      </Grid>
      <Grid item xs={isMobileScreen ? 12 : 6} data-testid="patient-address">
        <Typography className="patient-address">
          {makeCapitalEachWordInString(`${patient.address.addressLine1}`)}
        </Typography>
        <Typography className="patient-address">
          {patient.address.addressLine2
            ? makeCapitalEachWordInString(`${patient.address.addressLine2}`)
            : ""}
        </Typography>
        <Typography className="patient-address">
          {makeCapitalEachWordInString(
            `${
              patient.address.city +
              ", " +
              patient.address.state +
              " " +
              patient.address.zipCode
            }`
          )}
        </Typography>
      </Grid>
      <Grid item xs={isMobileScreen ? 12 : 6} className="patient-dob-ro-grid">
        <Grid container className="patient-dob-and-ro-grid-container">
          <Grid
            item
            xs={isMobileScreen ? 6 : 12}
            className="patient-dob-and-ro"
          >
            <Typography
              className="patient-address"
              data-testid="patient-dob-label"
            >
              DOB
            </Typography>
            <Typography
              className="patient-data-value"
              data-testid="patient-dob-value"
            >
              {patient.dob}
            </Typography>
          </Grid>
          <Grid
            item
            xs={isMobileScreen ? 6 : 12}
            className="patient-dob-and-ro"
          >
            <Typography
              className="patient-address"
              data-testid="patient-ro-label"
            >
              RO#
            </Typography>
            <Typography
              className="patient-data-value"
              data-testid="patient-ro-value"
            >
              {patient.roNumber}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
