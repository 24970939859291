import { ReactNode } from "react";
import { MyListStorageLocationPopUps } from "./myListAddUpdateConatiner.enum";
import { AddStorageLocation } from "./myListAddStorageLocation/addLocations.component";
import { UpdateStorageLocationName } from "./updateLocationName/updateLocationName.component";
import { RestrictAddLocation } from "./restrictAddLocation/restrictAddLocation.component";
import { IMyListStorageLocationContainer } from "./myListAddUpdateContainer.interface";

export const MyListAddUpdateContainer = ({
  currentSection,
  setCurrentSection,
  setOpenpopup,
  getFacilityStorageLocationAPICall,
  updateLocationObj,
  validateAndSetLocationName,
  updateLocationNameToDB,
  customLocationsCount,
  setShowSpinner,
  setSearchInput,
  setSelectedStatus,
  isLocationNameChange,
  setIsLocationNameChange,
}: IMyListStorageLocationContainer) => {
  const redirectHandler = (
    changeSectionTo: MyListStorageLocationPopUps
  ): void => {
    setCurrentSection(changeSectionTo);
  };

  const sectionToDisplay = () => {
    let page: ReactNode;
    switch (currentSection) {
      case MyListStorageLocationPopUps.ADD_STORAGE_LOCATION:
        page = (
          <AddStorageLocation
            redirectHandler={redirectHandler}
            setOpenpopup={setOpenpopup}
            getFacilityStorageLocationAPICall={
              getFacilityStorageLocationAPICall
            }
            customLocationsCount={customLocationsCount}
            setShowSpinner={setShowSpinner}
            setSearchInput={setSearchInput}
            setSelectedStatus={setSelectedStatus}
          />
        );
        break;
      case MyListStorageLocationPopUps.UPDATE_STORAGE_LOCATION_NAME:
        page = (
          <UpdateStorageLocationName
            setOpenpopup={setOpenpopup}
            updateLocationObj={updateLocationObj}
            validateAndSetLocationName={validateAndSetLocationName}
            updateLocationNameToDB={updateLocationNameToDB}
            isLocationNameChange={isLocationNameChange}
            setIsLocationNameChange={setIsLocationNameChange}
          />
        );
        break;
      case MyListStorageLocationPopUps.RESTRICTING_ADD_LOCATION_POPUP_MESSAGE:
        page = <RestrictAddLocation setOpenpopup={setOpenpopup} />;
        break;
    }
    return page;
  };
  return <>{sectionToDisplay()}</>;
};
