import React, { createContext, useState } from "react";
import { getDeepClone } from "../util/ObjectFunctions";
import { ISSOSideNav } from "../sso/ssoSideNav/ssoSideNavMenuOption.interface";
import { ssoSideNavMenuOptionModel } from "../sso/ssoSideNav/ssosideNaveMenuOptions.model";

export type InternalSignOnContextType = {
  ssoSideNavMenuOptionModelData: ISSOSideNav;
  setSsoNavMenuOptionModelData: React.Dispatch<
    React.SetStateAction<ISSOSideNav>
  >;
  isInternalAdministration: boolean;
  setIsInternalAdministration: React.Dispatch<React.SetStateAction<boolean>>;
  selectedTerritory: string;
  setSelectedTerritory: React.Dispatch<React.SetStateAction<string>>;
  isManualFacility: boolean;
  setIsManualFacility: React.Dispatch<React.SetStateAction<boolean>>;
  isVACOrderSSO: boolean;
  setIsVACOrderSSO: React.Dispatch<React.SetStateAction<boolean>>;
  isViewReportsScrolled: number;
  setIsViewReportsScrolled: React.Dispatch<React.SetStateAction<number>>;
};

type InternalSignOnProviderProps = {
  children: React.ReactNode;
};

export const InternalSignOnContext =
  createContext<InternalSignOnContextType | null>(null);

export const InternalSignOnProvider = ({
  children,
}: InternalSignOnProviderProps) => {
  const [ssoSideNavMenuOptionModelData, setSsoNavMenuOptionModelData] =
    useState<ISSOSideNav>(getDeepClone(ssoSideNavMenuOptionModel));
  const [isInternalAdministration, setIsInternalAdministration] =
    useState(false);
  const [selectedTerritory, setSelectedTerritory] = useState<string>("");
  const [isManualFacility, setIsManualFacility] = useState<boolean>(false);
  const [isVACOrderSSO, setIsVACOrderSSO] = useState(false);
  const [isViewReportsScrolled, setIsViewReportsScrolled] = useState(0);
  return (
    <InternalSignOnContext.Provider
      value={{
        ssoSideNavMenuOptionModelData,
        setSsoNavMenuOptionModelData,
        isInternalAdministration,
        setIsInternalAdministration,
        selectedTerritory,
        setSelectedTerritory,
        isManualFacility,
        setIsManualFacility,
        isVACOrderSSO,
        setIsVACOrderSSO,
        isViewReportsScrolled,
        setIsViewReportsScrolled,
      }}
    >
      {children}
    </InternalSignOnContext.Provider>
  );
};
