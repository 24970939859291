import parse from "html-react-parser";
import { Button } from "@mui/material";
import {
  getTextFromCode,
  makeCapitalEachWordInString,
} from "../../../../util/utilityFunctions";
import "./productInformationReviewOrder.css";
import { IProductInformationReviewOrder } from "./productInformationReviewOrder.interface";

export const ProductInformationReviewOrder = ({
  productInfo,
  editButtonClicked,
  productValues,
  isOrderSummary = false,
  isReadyCare = false,
}: IProductInformationReviewOrder) => {
  return (
    <div className="product-information-review-order">
      <div className="product-information-component-title">
        <h2
          className="product-information-review-order-title"
          data-testid="product-information-review-order-title"
        >
          Product Information
        </h2>
        {!isOrderSummary && (
          <Button
            classes={{ root: "product-information-review-order-edit-button" }}
            data-testid="product-information-review-order-edit-button"
            onClick={editButtonClicked}
          >
            Edit
          </Button>
        )}
      </div>
      <div className="all-content-div">
        {isReadyCare && (
          <div className="content-div">
            <div className="sub-content-div">
              <h5
                className="product-information-review-order-content-title"
                data-testid="product-information"
              >
                {parse(
                  "Will this order be using one of your 3M™ V.A.C.<sup>®</sup> Ready Care Program kits?"
                )}
              </h5>
              <h5
                className="product-information-review-order-content-value text"
                data-testid="product-information-value"
              >{`${makeCapitalEachWordInString(
                productInfo.productInformation.value === ""
                  ? "--"
                  : productInfo.productInformation.value
              )}`}</h5>
            </div>
          </div>
        )}
        <div className="content-div-last">
          <div className="sub-content-div">
            {productInfo.productInformation.value.toLowerCase() === "yes" ? (
              <p
                className="product-information-review-order-content-title"
                data-testid="product"
              >
                The following products will be given to the patient with the 3M™
                V.A.C.<sup>®</sup> Ready Care Program kit
              </p>
            ) : (
              <p
                className="product-information-review-order-content-title"
                data-testid="product"
              >
                Product
              </p>
            )}
            <div className="productInfo">
              <span className="dot-with-space">&bull; </span>
              <p
              className="product-information-review-order-content-value"
              data-testid="product-value"
            >
              {productInfo.productValue.value !== ""
                ? getTextFromCode(productValues, productInfo.productValue.value)
                : "--"}
            </p>
            </div>
            {isReadyCare &&
              productInfo.productInformation.value.toLowerCase() === "yes" && (
                <div>
                  <div className="productInfo">
                    <span className="dot-with-space">&bull; </span> 
                    <p
                    className="product-information-review-order-content-value"
                    data-testid="dressing-kit"
                  >
                    1 Case of 5 - 3M™ V.A.C.® Granufoam™ Dressing Kit, Medium (M8275052/5)
                  </p>
                  </div>
                  <div className="productInfo">
                    <span className="dot-with-space">&bull; </span>
                    <p
                    className="product-information-review-order-content-value"
                    data-testid="canister"
                  >
                    1 Case of 5 - ACTIV.A.C™ 300mL Canister w/Gel, (M8275058/5)
                  </p>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};
