import { Grid } from "@mui/material";
import "./billingInformation.css";
import { IBillingAddress } from "../../../../inpatientOrder/components/billingAddress/billingAddress.interface";
import {
  getAddress,
  makeCapitalEachWordInString,
} from "../../../../../util/utilityFunctions";
import { useContext } from "react";
import { MobileDisplayContext } from "../../../../../context/MobileDisplayContext";

interface IBillingInfomation {
  billingAddress: IBillingAddress | undefined;
  purchaseOrderNumber: string | undefined;
}

export const BillingInfomation = ({
  billingAddress,
  purchaseOrderNumber,
}: IBillingInfomation) => {
  const { isMobileScreen } = useContext(MobileDisplayContext);

  return (
    <div className="billing-main-info">
      <div className="billing-info-component-title">
        <h2
          className="billing-main-info-title"
          data-testid="billing-main-info-title"
        >
          Billing Information
        </h2>
      </div>
      <div className="all-content-div">
        <div className="content-div">
          <Grid className="grid-container" container>
            <Grid className="grid-item" item xs={isMobileScreen ? 12 : 4.5}>
              <div className="sub-content-div">
                <p
                  className="billing-main-info-content-title"
                  data-testid="product-is-reday-care"
                >
                  Purchase Order#
                </p>
                <h5
                  className="billing-main-info-content-value text"
                  data-testid="product-is-reday-care-value"
                >
                  {purchaseOrderNumber && purchaseOrderNumber !== ""
                    ? purchaseOrderNumber
                    : "--"}
                </h5>
              </div>
            </Grid>
            <Grid className="grid-item" item xs={isMobileScreen ? 12 : 7.5}>
              <div className="sub-content-div">
                <p
                  className="billing-main-info-content-title"
                  data-testid="product-is-reday-care"
                >
                  Billing Address
                </p>
                <h5
                  className="billing-main-info-content-value text"
                  data-testid="product-is-reday-care-value"
                >
                  {billingAddress
                    ? `${
                        billingAddress!.name
                          ? `${makeCapitalEachWordInString(
                              billingAddress!.name
                            )}, `
                          : ""
                      } ${getAddress(billingAddress!)}`
                    : "--"}
                </h5>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
