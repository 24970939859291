import moment from "moment";
import "./patientInformationReviewOrder.css";
import {
  makeCapitalEachWordInString,
  maskProductInUse,
} from "../../../../../util/utilityFunctions";
import { Grid, useMediaQuery } from "@mui/material";
import { IPatient } from "../../../../myPatients/patient.interface";
import { ShowTitleAndValue } from "../../../../../core/showTitleAndValue/showTitleAndValue.component";

interface Props {
  patient: IPatient;
}

export const PatientInformationReviewOrder = ({ patient }: Props) => {
  const isMobileScreen = useMediaQuery("(max-width:920px)");
  const getProductName = () => {
    return `${patient.productNameDescription ?? ""} ${
      patient.productSerialNumber && patient.productSerialNumber !== ""
        ? `- ${patient.productSerialNumber}`
        : ""
    }`;
  };

  return (
    <div
      className="patient-information-review-order-component"
      data-testid="patient-information-review-order-component"
    >
      <p
        className="patient-information-review-order-title"
        data-testid="patient-information-review-order-title"
        id="patient-information-review-order-title-id"
      >
        Patient Information
      </p>
      <Grid
        className="patient-information-review-order-grid-container"
        container
      >
        <Grid
          className="patient-information-review-order-grid-item"
          item
          xs={isMobileScreen ? 12 : 6}
        >
          <ShowTitleAndValue
            title="Full Name"
            value={
              patient.firstName
                ? makeCapitalEachWordInString(
                    patient.firstName + " " + patient.lastName
                  )
                : ""
            }
            testId="patient-full-name"
          />
        </Grid>
        <Grid
          className="patient-information-review-order-grid-item"
          item
          xs={isMobileScreen ? 12 : 6}
        >
          <ShowTitleAndValue
            title="Date of Birth"
            value={patient.dob ? moment(patient.dob).format("MM/DD/YYYY") : ""}
            testId="dob"
          />
        </Grid>
        <Grid
          className="patient-information-review-order-grid-item"
          item
          xs={isMobileScreen ? 12 : 6}
        >
          <ShowTitleAndValue
            title="Product"
            value={getProductName()}
            testId="patient-product"
          />
        </Grid>
        <Grid
          className="patient-information-review-order-grid-item"
          item
          xs={isMobileScreen ? 12 : 6}
        >
          <ShowTitleAndValue
            title="Placement Date"
            value={
              patient.orderCreationDate
                ? moment(patient.orderCreationDate).format("MM/DD/YYYY")
                : ""
            }
            testId="patient-placementDate"
          />
        </Grid>
        <Grid
          className="patient-information-review-order-grid-item"
          item
          xs={isMobileScreen ? 12 : 6}
        >
          <ShowTitleAndValue
            title="Patient Location"
            value={patient.patientLocation ?? ""}
            testId="patient-location"
          />
        </Grid>
      </Grid>
    </div>
  );
};
