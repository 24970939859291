import moment from "moment";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext, AuthContextType } from "../../../../context/AuthContext";
import {
  DischargeRequestContext,
  DischargeRequestContextType,
} from "../../../../context/DischargeRequestContext";
import {
  OrderDetailContext,
  OrderDetailContextType,
} from "../../../../context/OrderDetailsContext";
import {
  PickUpRequestContext,
  PickUpRequestContextType,
} from "../../../../context/PickUpRequestContext";
import { getOrderSupplies } from "../../../../util/orderOverViewService";
import { getOrderStatusDetails } from "../../../../util/vacOrderService";
import { ReSubmissionContainer } from "../../allAlerts/acuteOrderOrWoundApiResubmission/container/reSubmissionContainer.component";
import { ConfirmPlacement } from "../../allAlerts/confirmPlacement/confirmPlacement.component";
import MissingRx from "../../allAlerts/missingRx/missingRx.component";
import { PendingSupplyOrder } from "../../allAlerts/pendingSupplyOrder/pendingSupplyOrder.component";
import { ProofOfDeliveryNeeded } from "../../allAlerts/proofOfDeliveryNeeded/proofOfDeliveryNeeded.component";
import SharedOrder from "../../allAlerts/sharedOrder/sharedOrder.component";
import { SuppliesShipped } from "../../allAlerts/suppliesDelivered/suppliesShipped.component";
import { IAlertTypes } from "../../patient.interface";
import { OrderOverViewTabsTitle } from "../../patientAndTherapyDetails/orderOverview/orderOverviewContainer.enum";
import {
  IGetOrderSuppliesRequest,
  ISupplyOrdersInfo,
} from "../../patientAndTherapyDetails/orderSupplyDetail/orderSupplyDetails.interface";
import "./alerts.css";
import { IAlertsInterface } from "./alerts.interface";

export const Alerts = ({
  closePopUpAction,
  data,
  patient,
  successAndCloseIndicatorPopup,
  closeAlertPopupNoReload,
}: IAlertsInterface) => {
  const history = useHistory();

  const orderOverviewObj = useContext<OrderDetailContextType | null>(
    OrderDetailContext
  );
  const pickUpRequestObj = useContext<PickUpRequestContextType | null>(
    PickUpRequestContext
  );
  const dischargeRequestObj = useContext<DischargeRequestContextType | null>(
    DischargeRequestContext
  );
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const getTherapyStartDate = async (ron: number, dob: string) => {
    if (
      authObj &&
      authObj.userProfile &&
      authObj.userProfile.userName &&
      authObj.userProfile.userName !== "" &&
      authObj.registeredFaciltyAddress &&
      authObj.registeredFaciltyAddress.siteUseId &&
      authObj.registeredFaciltyAddress.siteUseId !== ""
    ) {
      const reqParam = {
        RentalOrderNumber: ron.toString(),
        DOB: dob,
        userName: authObj.userProfile.userName,
        siteUseId: authObj.registeredFaciltyAddress.siteUseId,
      };
      try {
        const result = await getOrderStatusDetails(reqParam);
        if (result.succeeded) {
          const dateStr = result.item.therapyDate;
          const date = dateStr
            ? moment(dateStr, "YYYY-MM-DDTHH:mm:ss").toDate().toString()!
            : null;
          orderOverviewObj?.setSelectedSupplyTherapyStartDate(date);
        }
        return true;
      } catch {
        return false;
      }
    }
  };
  const getOrderSuppliesDetail = async (
    ro: number,
    dob: string,
    ropn?: string | null
  ) => {
    let reqParams: IGetOrderSuppliesRequest = {
      RentalOrderNumber: ro,
      dob: moment(dob).format("DD-MMM-yyyy").toString(),
      isAcuteOrder:false
    };
    try {
      const responseObj = await getOrderSupplies(reqParams);
      if (responseObj?.succeeded) {
        const data = responseObj;
        const supplyDetails: ISupplyOrdersInfo = data.item.supplyOrders.find(
          (x: ISupplyOrdersInfo) => x.ropn === ropn
        );
        const supplyOrderData: ISupplyOrdersInfo = {
          createdDate: "",
          shippedDate: "",
          initiatedName: "",
          product: "",
          ropn: supplyDetails?.ropn!,
          status: supplyDetails.status,
          statusColor: supplyDetails.statusColor,
          deliveredDate: "",
        };
        orderOverviewObj?.setSelectedSupplyOrder(supplyOrderData);
        orderOverviewObj?.setIsOrderFlow(true);
        orderOverviewObj?.setOrderTabTitle("Supply Orders");
        history.push({
          pathname: "/home/orderOverview",
          state: {
            stateData: patient,
          },
        });
      } else return false;
    } catch (error) {
      console.log("error", error);
    }
  };

  switch (data.alertType) {
    case IAlertTypes.SUPSH:
      return (
        <SuppliesShipped
          date={data.alertDate!}
          closePopUpAction={closePopUpAction}
          data={data}
          patient={patient}
        />
      );
    case IAlertTypes.PNDSO:
      if (
        data?.alertSubLabel
          .toLowerCase()
          .includes("Missing Excessive Supply Form".toLowerCase())
      ) {
        return <PendingSupplyOrder closePopUpAction={closePopUpAction} />;
      } else {
        orderOverviewObj?.setSelectedOrderTab(
          OrderOverViewTabsTitle.SUPPLY_ORDER_TAB_TITLE
        );
        const supplyOrderData: ISupplyOrdersInfo = {
          createdDate: "",
          shippedDate: "",
          initiatedName: "",
          product: "",
          ropn: data?.ropn!,
          status: "Delivered",
          statusColor: "Green",
          deliveredDate: "",
        };
        getOrderSuppliesDetail(patient?.roNumber!, patient.dob!, data?.ropn);
        orderOverviewObj?.setSelectedSupplyOrder(supplyOrderData);
        orderOverviewObj?.setSelectedOrderTab(
          OrderOverViewTabsTitle.ORDER_TAB_TITLE
        );
        getTherapyStartDate(patient?.roNumber!, patient?.dob!);
        orderOverviewObj?.setIsOrderFlow(true);
        orderOverviewObj?.setOrderTabTitle("Supply Orders");
        history.push({
          pathname: "/home/orderOverview",
          state: {
            stateData: patient,
          },
        });
      }
      return null;
    case IAlertTypes.CONPL:
      return (
        <ConfirmPlacement
          closePopUpAction={closePopUpAction}
          patient={patient}
        />
      );

    case IAlertTypes.DISPEN:
      pickUpRequestObj?.resetData();
      dischargeRequestObj?.resetData();
      pickUpRequestObj?.setData((dt: any) => ({
        ...dt,
        isComingFromAlert: true,
      }));
      pickUpRequestObj?.setPatient(patient);
      history.push("/home/pickUpRequest");
      return null;

    case IAlertTypes.SHODR:
      return (
        <SharedOrder
          alertData={data}
          patientData={patient}
          closePopUpAction={closePopUpAction}
        />
      );
    case IAlertTypes.MISRX:
      return (
        <MissingRx closePopUpAction={closePopUpAction} patientData={patient} />
      );
    case IAlertTypes.MSDUE:
      window.scrollTo(0, 0);
      orderOverviewObj?.setSelectedOrderTab(
        OrderOverViewTabsTitle.WOUND_PROGRESS_TAB_TITLE
      );
      orderOverviewObj?.setWoundId(data.woundOrderID);
      history.push({
        pathname: "/home/orderOverview",
        state: {
          stateData: patient,
        },
      });
      return null;
    case IAlertTypes.MSDOC:
      window.scrollTo(0, 0);
      orderOverviewObj?.setSelectedOrderTab(
        OrderOverViewTabsTitle.DOCUMENTS_TAB_TITLE
      );
      history.push({
        pathname: "/home/orderOverview",
        state: {
          stateData: patient,
        },
      });
      return null;
    case IAlertTypes.PODEL:
      return (
        <ProofOfDeliveryNeeded
          closePopUpAction={closePopUpAction}
          patient={patient}
        />
      );
    case IAlertTypes.APIERROR:
      return (
        <ReSubmissionContainer
          closePopUpAction={closeAlertPopupNoReload!}
          successAndCloseIndicatorPopup={successAndCloseIndicatorPopup}
          patientData={patient}
        />
      );

    default:
      return <div className="alertsEmptyDiv"></div>;
  }
};
