import "./orderUploadDocumentReviewOrder.css";
import { Button, Grid } from "@mui/material";
import { IDropZoneDocumentSelect } from "../../../../core/customDropZone/dropZoneDocumentSelect.interface";
import { getCodeFromText } from "../../../../util/utilityFunctions";

type Props = {
  editButtonClicked?: any;
  isOrderSummary?: boolean;
  newOrderDocuments?: IDropZoneDocumentSelect[];
  documentTypeCodeList?: any;
};
export const OrderUploadDocumentReviewOrder = ({
  editButtonClicked,
  isOrderSummary,
  newOrderDocuments,
  documentTypeCodeList,
}: Props) => {
  
  const getDocumentType = (document: IDropZoneDocumentSelect): string => {
    if (document.documentType && document.documentType.value) {
      return getCodeFromText(documentTypeCodeList, document.documentType.value);
    } else {
      // For Prescription document we are not setting document Type So we are setting default as RX
      return "RX";
    }
  };

  return (
    <div className="order-upload-review-order">
      <div className="order-upload-review-order-header">
        <h2
          className="order-upload-review-order-title"
          data-testid="order-upload-review-order-title"
        >
          Documents
        </h2>
        {!isOrderSummary && (
          <Button
            classes={{ root: "order-upload-review-order-edit-button" }}
            data-testid="order-upload-review-order-edit-button"
            onClick={editButtonClicked}
          >
            Edit
          </Button>
        )}
      </div>

      <Grid className="order-upload-review-order-grid-item" item xs={12}>
        {newOrderDocuments &&
          newOrderDocuments.map((document: IDropZoneDocumentSelect) => (
            <h5
              className="order-upload-review-order-content-value"
              data-testid="order-upload-value"
            >
              <span className="dot-with-space">&bull; </span>
              {`${document.documentName} `}
              <span className="order-upload-review-order-doc-type-value">{` ${getDocumentType(
                document
              )}`}</span>
              <br></br>
            </h5>
          ))}
      </Grid>
    </div>
  );
};
