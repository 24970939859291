import moment from "moment";
import { AuthContextType } from "../../../../context/AuthContext";
import { ICustomerInformation } from "../../../../context/InpatientSupplyOrderContext";
import {
  changeDateFormat,
  getSiteUseId,
} from "../../../../util/utilityFunctions";
import { getCustomerClassName } from "../../../inpatientOrder/components/mapper/inpatientOrder.request.mapper";
import {
  IDeliveryDropwnTypes,
  IDropDownContent,
  IInpatientSupplyOrder,
} from "../../inpatientSupplyOrder.interface";
import { ICartItem } from "../cart/cartItem/cartItem.interface";
import {
  IAcuteSupply,
  ISubmitAcuteSupplyOrderRequest,
} from "./submitAcuteSupplyOrderRequest.interface";

export const mapAcuteSupplyOrderRequest = async (
  authObj: AuthContextType,
  customerInfoObj: ICustomerInformation,
  data: IInpatientSupplyOrder,
  isFromStockSupplies: boolean,
  cartItems: ICartItem[],
  product: string,
  deliveryMethodTypes: IDeliveryDropwnTypes[]
) => {
  const deliveryObj = deliveryMethodTypes.find(
    (x) =>
      x.text.toLowerCase() ===
      data.deliveryInformation.deliveryMethod.value.toLowerCase()
  );
  const roNumber =
    data &&
    data.patientAndProduct &&
    data.patientAndProduct.patient &&
    data.patientAndProduct.patient.roNumber;
  const salesRONumber = data.salesPurchaseOrderInformation.salesPONumber.value;
  const needByDate = data.deliveryInformation.needByDate.value;
  let acuteSupplyOrderRequestBody: ISubmitAcuteSupplyOrderRequest = {
    isStockSupplyOrder: isFromStockSupplies,
    rentalOrderNumber: isFromStockSupplies ? null : roNumber,
    employeeId: authObj.userProfile!.employeeID
      ? authObj.userProfile!.employeeID
      : "INTERNET",
    requestorFirstName: authObj.userProfile!.firstName,
    userName: authObj.userProfile!.userName,
    requestorLastName: authObj.userProfile!.lastName,
    requestorDepartment: authObj.isInternalUser
      ? "INTERNAL"
      : authObj.userProfile!.departmentName,
    requestorPhone: authObj.userProfile!.phoneNumber
      ? authObj.userProfile!.phoneNumber
      : authObj.userProfile!.mobilePhoneNumber,
    shipToFacilityName: data?.shippingAddress?.shippingFacilityName?.value,
    customerNumber: customerInfoObj.customerNumber,
    shipToAddressLine1: data?.shippingAddress?.shippingAddressLine1?.value,
    shipCity: data?.shippingAddress?.shippingAddressCity?.value,
    shipState: data?.shippingAddress?.shippingAddressState?.value,
    shipZipCode: data?.shippingAddress?.shippingAddressZip?.value,
    billToSiteUseId: customerInfoObj.billToSiteUseId,
    shipToSiteId: getSiteUseId(authObj),
    customerAccountNumber:
      authObj.registeredFaciltyAddress!.accountNumber!.toString(),
    customerAccountId: authObj.registeredFaciltyAddress!.accountId!,
    salesPONumber: salesRONumber !== "" ? salesRONumber : "",
    customerGroup: getCustomerClassName(authObj),
    serviceCenter: authObj.registeredFaciltyAddress!.customerServiceCode!,
    creditStatus: customerInfoObj.creditStatus,
    appVersion: process.env.REACT_APP_VERSION ?? "",
    deliveryContactFirstName: authObj.userProfile!.firstName!,
    deliveryContactLastName: authObj.userProfile!.lastName,
    deliveryContactPhone: authObj.userProfile!.phoneNumber
      ? authObj.userProfile!.phoneNumber
      : authObj.userProfile!.mobilePhoneNumber,
    deliveryContactDepartment: authObj.userProfile!.departmentName!,
    acuteSupplies: getCartItems(cartItems),
    shippingMethod: deliveryObj?.code!,
    shipDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
    product: isFromStockSupplies
      ? product !== ""
        ? product.split(",")[0]
        : ""
      : data.patientAndProduct.product.productCode,
    primaryPriceList: cartItems[0].primaryPriceListId!,
    secondaryPriceList: cartItems[0].secondaryPriceListId!,
    deliveryInstructions: deliveryObj?.deliveryDescription!,
    estimatedDeliveryDate: deliveryObj?.estArrivalDate!
      ? changeDateFormat(deliveryObj?.estArrivalDate!)
      : "",
    needByDate: needByDate ? changeDateFormat(needByDate) : "",
    requestorAddressLine1: authObj.registeredFaciltyAddress!.address1,
    requestorCity: authObj.registeredFaciltyAddress!.city,
    requestorState: authObj.registeredFaciltyAddress!.state,
    requestorZipCode: authObj.registeredFaciltyAddress!.zip.toString(),
    requestorFacilityName: authObj.registeredFaciltyAddress!.accountName,
    patientFirstName: data.patientAndProduct.patient?.firstName ?? "",
    patientLastName: data.patientAndProduct.patient?.lastName ?? "",
    patientLocation: data.patientAndProduct.patient?.patientLocation ?? "",
  };
  return acuteSupplyOrderRequestBody;
};

const getCartItems = (cartItems: ICartItem[]): IAcuteSupply[] => {
  let selectedAcuteSupplies: IAcuteSupply[] = [];
  if (cartItems.length > 0) {
    selectedAcuteSupplies = cartItems.map((eachCartItem) => {
      return {
        sku: eachCartItem.sku,
        inventoryID: eachCartItem.inventoryID.toString(),
        categoryId: eachCartItem.categoryID,
        qty: eachCartItem.quantity.toString(),
        price: eachCartItem.price.toString(),
        wareHouseId: eachCartItem.wareHouseID!.toString(),
      };
    });
  }
  return selectedAcuteSupplies;
};
