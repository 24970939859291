import { Grid, TextField, useMediaQuery } from "@mui/material";
import "../endTherapyPopUp/endTherapyPopup.css";
import { InputWithLabel } from "../../../../../core/inputWithLabel/inputWithLabel.component";
import { CustomDropDown } from "../../../../../core/customDropdown/customDropdown.component";
import { MouseEventHandler, useContext, useState } from "react";
import {
  IInputField,
  ValidationStatus,
} from "../../../../../core/interfaces/input.interface";
import { ExpressButton } from "../../../../../core/expressButton/expressButton.component";
import { STATIC_TEXT_END_THERAPY_POPUP_DESC } from "../../../../../util/staticText";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import React from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { ICustomerPlusListingDetails } from "../../../customerPlusInventory/customerPlusInventory.interface";
import moment from "moment";
import { EndTherapyStaticData } from "../endTherapyPopUpStaticData/endTherapyPopUpStaticData.component";
import { ReactComponent as CalendarIcon } from "../../../../../assets/calendar.svg";
import { convertStringToDate } from "../../../../../util/utilityFunctions";
import { EndTherapyPopupValidator } from "./endTherapyPopupValidator";
import {
  RolesPermissionContext,
  RolesPermissionContextType,
} from "../../../../../context/RolesPermissionContext";
interface IInventoryUpdateLocationProps {
  selectedProduct: ICustomerPlusListingDetails;
  storageLocations: string[];
  closePopUp: Function;
  endTherapyData: any;
  setEndTherapyData: any;
  endButtonAction: any;
  Validator?: EndTherapyPopupValidator;
}

export interface IEndTherapyData {
  therapyStartDate: IInputField;
  therapyEndDate: IInputField;
  storageLocations: IInputField;
}
const EndTherapyPopUp = ({
  selectedProduct,
  storageLocations,
  closePopUp,
  endTherapyData,
  setEndTherapyData,
  endButtonAction,
  Validator = new EndTherapyPopupValidator(),
}: IInventoryUpdateLocationProps) => {
  const [validator] = useState<EndTherapyPopupValidator>(Validator);
  const isMobileScreen = useMediaQuery("(max-width:374px)");
  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );
  const [focusClasses, setFocusClasses] = React.useState({
    therapyEndDate: "",
  });
  const setClasses = (e: any, classname: string) => {
    setFocusClasses(
      Object.assign({}, focusClasses, { [e.target.name]: classname })
    );
  };

  const validateAndSetDate = (value: any) => {
    const formattedDate = value === null ? null : convertStringToDate(value);
    const isValid = validator?.validate(formattedDate!, "therapyEndDate");
    setEndTherapyData({
      ...endTherapyData,
      therapyEndDate: {
        ...endTherapyData.therapyEndDate,
        value: formattedDate!,
        valid: isValid?.status!,
      },
    });
  };

  const cancelButtonAction = () => {
    closePopUp();
  };

  const validateAndSetDropDownData = (event: any) => {
    const isValid = validator?.validate(event.target.value, "storageLocations");
    setEndTherapyData({
      ...endTherapyData,
      storageLocations: {
        ...endTherapyData.storageLocations,
        value: event.target.value,
        valid: isValid?.status!,
      },
    });
  };

  return (
    <div className="end-therapy-component">
      <p
        className="end-therapy-title"
        data-testid="end-therapy-title"
        id="end-therapy-title-id"
      >
        End Therapy
      </p>

      <Grid className="end-therapy-grid-container" container spacing={1}>
        <Grid xs={12}>
          <span
            className="end-therapy-div-desc"
            data-testid="end-therapy-desc"
            id="end-therapy-div-desc-id"
          >
            {STATIC_TEXT_END_THERAPY_POPUP_DESC}
          </span>
        </Grid>
        <Grid xs={12}>
          <EndTherapyStaticData endTherapyData={selectedProduct} />
        </Grid>
        <Grid xs={12} className="therapy-date-container ">
          <Grid xs={6} data-testid="end-therapy-start-date">
            <InputWithLabel
              componentClassName="therapy-title-style"
              label="Therapy Start Date"
              isRequired={true}
              id="therapy-title-style-id"
            >
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  InputAdornmentProps={{
                    classes: {
                      root: "adornedRoot",
                    },
                  }}
                  disabled
                  className="therapy-start-date"
                  onChange={() => {}}
                  InputProps={{
                    classes: {
                      root: "startDate",
                      input: "input",
                      notchedOutline: "outline",
                      disabled: "disabled-input",
                    },
                  }}
                  components={{ OpenPickerIcon: CalendarIcon }}
                  value={selectedProduct.therapyStartDate}
                  renderInput={(params) => {
                    params.error = false;
                    params.inputProps!.placeholder = "__/__/____";
                    return <TextField name="startDate" {...params} />;
                  }}
                />
              </LocalizationProvider>
            </InputWithLabel>
          </Grid>
          <Grid xs={6} data-testid="end-therapy-end-date">
            <InputWithLabel
              componentClassName="therapy-end-date-title-style"
              labelClassName={focusClasses.therapyEndDate}
              label="Therapy End Date"
              isRequired={true}
              error={
                endTherapyData?.therapyEndDate?.valid ===
                ValidationStatus.INVALID
              }
              id="therapy-end-date-title-style-id"
            >
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  InputAdornmentProps={{
                    classes: {
                      root: "adornedRoot",
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: `endDate ${
                        endTherapyData?.therapyEndDate?.valid ===
                        ValidationStatus.INVALID
                          ? "showError"
                          : "noError"
                      }`,
                      input: "input",
                      notchedOutline: "outline",
                      disabled: "disabled-input",
                    },
                  }}
                  className="therapy-end-date"
                  components={{ OpenPickerIcon: CalendarIcon }}
                  value={endTherapyData?.therapyEndDate?.value}
                  onChange={(val: any) => {
                    validateAndSetDate(val);
                  }}
                  renderInput={(params) => {
                    params.error = false;
                    params.inputProps!.placeholder = "__/__/____";
                    return (
                      <TextField
                        name="therapyEndDate"
                        onFocus={(e) => setClasses(e, "Mui-focused")}
                        onBlur={(e) => setClasses(e, "")}
                        {...params}
                      />
                    );
                  }}
                />
              </LocalizationProvider>
            </InputWithLabel>
          </Grid>
        </Grid>
        <Grid className="grid-item" xs={12}>
          <InputWithLabel
            isDropdown={true}
            label="Return to Storage Location"
            labelClassName="storage-location-title"
            isRequired={true}
            testId="end-therapy-dropdown"
            error={
              endTherapyData?.storageLocations?.valid ===
              ValidationStatus.INVALID
            }
          >
            <CustomDropDown
              handleChange={(loc: any) => validateAndSetDropDownData(loc)}
              menuItem={storageLocations}
              placeHolder="Select Storage Location"
              name="storageLocation"
              selectpropsClassName="storage-location-select"
              selectClassName="storage-location-input storage-location"
              testId="storage-location-dropdown"
              value={endTherapyData?.storageLocations?.value}
            />
          </InputWithLabel>
        </Grid>
        <Grid xs={12} className="update-buttons-container">
          <Grid
            item
            xs={isMobileScreen ? 12 : 6}
            className="inventoryUpdate-button-container"
            id="inventoryUpdate-button-container-id"
          >
            <ExpressButton
              testId="cancel-button"
              clickHandler={cancelButtonAction}
              variant="outlined"
              sx={{ width: "100%" }}
            >
              Cancel
            </ExpressButton>
          </Grid>
          <Grid
            item
            xs={isMobileScreen ? 12 : 6}
            className="inventoryUpdate-button-container"
            id="inventoryUpdate-button-container-id"
          >
            <ExpressButton
              testId="next-button"
              variant="contained"
              sx={{ width: "100%" }}
              clickHandler={endButtonAction}
              disabled={permissionObj?.mappedRolesPermissionData.IsSupportRole}
            >
              End Therapy
            </ExpressButton>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default EndTherapyPopUp;
