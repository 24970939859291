import { ICreateServiceRequest } from "./createServiceRequest.interface";
import { ValidationStatus } from "../../../core/interfaces/input.interface";
import { IRouter } from "../../helpAndSupport/Navigator/navigator.interface";

export let defaultCreateServiceRequestData: ICreateServiceRequest = {
  pickupLocation: {
    componentId: "create-service-request-pickup-location",
    id: "create-service-request-pickup-location-input",
    required: false,
    order: 1,
    title: "Pickup Location",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  otherPickupLocation: {
    componentId: "create-service-request-other-pickup-location",
    id: "create-service-request-other-pickup-location-input",
    required: false,
    order: 2,
    title: "Other Pickup Location",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  serviceOptions: [
    {
      componentId: "create-service-request-service-option-1",
      order: 3,
      required: true,
      title: "Service Option",
      valid: ValidationStatus.UNTOUCHED,
      value: "",
    },
  ],
  specialInstrutions: {
    componentId: "create-service-request-special-instructions",
    id: "create-service-request-special-instructions-input",
    required: true,
    order: 11,
    title: "Special Instructions",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  isCallOnEstimatedArrivalTime: {
    componentId:
      "create-service-request-special-is-call-on-estimated-arrival-time",
    id: "create-service-request-special-is-call-on-estimated-arrival-time-input",
    required: true,
    order: 12,
    title: "Would you like a call with an estimated time of arrival?",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  firstName: {
    componentId: "create-service-request-first-name",
    id: "create-service-request-first-name-input",
    order: 13,
    required: false,
    title: "Contact First Name",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  lastName: {
    componentId: "create-service-request-last-name",
    id: "create-service-request-last-name-input",
    order: 14,
    required: false,
    title: "Contact Last Name",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  department: {
    componentId: "create-service-request-department",
    id: "create-service-request-department-input",
    order: 15,
    required: false,
    title: "Department",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  email: {
    componentId: "create-service-request-department",
    id: "create-service-request-department-input",
    order: 16,
    required: false,
    title: "Email Address",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  phoneNumber: {
    componentId: "create-service-request-phone-number",
    id: "create-service-request-phone-number-input",
    order: 17,
    required: false,
    title: "Phone number",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    isPhoneNumber: true,
  },
  injuryCauseBy3MDevice: {
    componentId: "create-service-request-is-cause-for-injury",
    id: "create-service-request-is-cause-for-injury-input",
    order: 18,
    required: true,
    title: "Did the 3M device potentially cause or contribute to an injury?",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  problemWith3MDevice: {
    componentId: "create-service-request-is-problem-with-device",
    id: "create-service-request-is-problem-with-device-input",
    order: 19,
    required: true,
    title: "Is there a problem with the functionality of the 3M device?",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  describeTheProblem: {
    componentId: "create-service-request-describe-injury-or-problem",
    id: "create-service-request-describe-injury-or-problem-input",
    order: 20,
    required: false,
    title: "Description",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
};

export const mpdNavigatorArray: IRouter[] = [
  {
    route: "/home",
    pageName: "My Patients",
  },
];

export const inventoryNavigatorArray: IRouter[] = [
  {
    route: "/inventory",
    pageName: "Inventory",
  },
];
