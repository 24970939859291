import React, { useContext } from "react";
import { ISupplyOrder } from "../../supplyOrder.interface";
import "./supplyOrderCondensed.css";
import {
  SupplyOrderContext,
  SupplyOrderContextType,
} from "../../../../context/SupplyOrderContext";
import parse from "html-react-parser";
import {
  SpecialProduct,
  checkSelectedProdcutForType,
  formatDate,
} from "../../../../util/utilityFunctions";
import {
  showSupplyOrderSecondaryVacDressing,
  showSupplyOrderVacDressing,
} from "../../supplyOrder.model";

interface Props {
  data: ISupplyOrder;
  setData: any;
}

const SupplyOrderCondensed = ({ data, setData }: Props) => {
  const supplyOrderObj = useContext<SupplyOrderContextType | null>(
    SupplyOrderContext
  );
  const dressing = supplyOrderObj?.dressingKit;
  const canister = supplyOrderObj?.canister;
  const accesssory = supplyOrderObj?.accessory;
  const setDressing = supplyOrderObj?.setDressingKit;
  const vacAllProducts = supplyOrderObj?.vacAllProducts;

  const isPrimaryDressingIsPeelAndPlaceWithMoreThan1Quantity = () => {
    if (
      dressing &&
      vacAllProducts &&
      checkSelectedProdcutForType(
        vacAllProducts.items,
        SpecialProduct.PEELANDPLACE,
        dressing.productName.value
      ) &&
      dressing.productQuantity.value &&
      parseInt(dressing.productQuantity.value) > 1
    ) {
      return true;
    }
    return false;
  };

  const checkAndUpdatePeelAndPlaceQuantityForEdgeCase = () => {
    if (isPrimaryDressingIsPeelAndPlaceWithMoreThan1Quantity()) {
      if (dressing && setDressing) {
        dressing!.productQuantity.value = "1";
        const secondDressingQuantity = parseInt(
          dressing!.secProductQuantity.value
        );
        const primaryDressing: showSupplyOrderVacDressing =
          supplyOrderObj!.showSupplyOrderVacDressingKit!;
        primaryDressing.isPrimaryVacKitDressingDisabled = true;
        primaryDressing.isPrimaryVacKitDressingMinusDisabled = true;

        const secondDressing: showSupplyOrderSecondaryVacDressing =
          supplyOrderObj!.showSupplyOrderSecondaryVacDressingKit!;
        secondDressing.isSecondaryVacKitDressingDisabled =
          secondDressingQuantity === 3;
        secondDressing.isSecondaryVacKitDressingMinusDisabled =
          secondDressingQuantity === 1;
        setDressing!(dressing!);
      }
      supplyOrderObj?.setIsPeelAndPlaceEdgeCase(true);
    }
  };

  const onEditSelectionClick = () => {
    supplyOrderObj?.setCondensedView(false);
    checkAndUpdatePeelAndPlaceQuantityForEdgeCase();
    setData({
      ...data,
      typeOfOrder: {
        ...data.typeOfOrder,
        value: "No",
      },
    });
  };

  const renderCanisterReview = () => {
    let cannisterData: any = "--";
    if (
      canister?.canisterProductCode.value !== "" &&
      canister?.canisterProductCode.value !== "--"
    ) {
      cannisterData = (
        <h5 className="dressing-kit-content-value">
          <span className="dot-with-space-kit">&bull; </span>
          {`${canister?.canisterProductQuantity.value} case of ${
            canister?.canisterProductCode.value.split("/")[1] ??
            canister?.canisterProductCode.value.match(/\d/g)?.join("")
          } - ${canister?.canisterProductName.value} `}
          <span className="dressing-kit-size-content-value">{`(${canister?.canisterProductCode.value})`}</span>
        </h5>
      );
    }
    return cannisterData;
  };

  return (
    <div>
      <div data-testid="titleOptions" className="titleOptions">
        <div
          className="selectedDressingTitle"
          data-testid="selectedDressingTitle"
        >
          {data?.typeOfOrder.value.toLowerCase() === "yes" ? (
            <div className="supply-order-title-and-date">
              Previous Supply Order
              {supplyOrderObj?.replenishSupplyOrderData &&
              supplyOrderObj?.replenishSupplyOrderData?.length > 0 ? (
                <>
                  <span className="submittedDate">
                    submitted{" "}
                    {supplyOrderObj?.replenishOrderDate
                      ? formatDate(supplyOrderObj?.replenishOrderDate)
                      : "--"}
                  </span>
                </>
              ) : (
                ""
              )}
            </div>
          ) : (
            "Selected Dressings & Supplies"
          )}
        </div>
        <div
          data-testid="editSelection"
          className="editSelection"
          onClick={onEditSelectionClick}
        >
          edit selection
        </div>
      </div>
      <h5 className="vacDressingkitHeader" data-testid="vacDressingkitHeader">
        {parse("3M™ V.A.C.<sup>®</sup> Dressing Kit")}
      </h5>
      {dressing?.productName.value !== "" ||
      dressing?.secProductName.value !== "" ||
      (dressing.productName.value !== "" &&
        dressing.secProductName.value !== "") ? (
        <>
          {dressing?.productName.value !== "" && (
            <h5
              className="dressing-kit-content-value"
              data-testid="dressing-kit-value-primary"
            >
              <span className="dot-with-space-kit">&bull; </span>
              {`${dressing?.productQuantity.value} case of ${
                dressing?.productCode.value &&
                dressing?.productCode.value !== ""
                  ? dressing?.productCode.value.split("/")[1] ??
                    dressing?.productCode.value
                      .match(/\d/g)
                      ?.join("")
                      .replace(/^0+/, "")
                  : dressing?.productSizeCode.value.split("/")[1] ??
                    dressing?.productSizeCode.value
                      .match(/\d/g)
                      ?.join("")
                      .replace(/^0+/, "")
              } - ${dressing?.productName.value}, ${
                dressing?.productSizeName.value.split(" (")[0]
              } `}
              <span className="dressing-kit-size-content-value">
                {`(${
                  dressing?.productCode.value &&
                  dressing?.productCode.value !== ""
                    ? dressing?.productCode.value
                    : dressing?.productSizeCode.value
                })`}
              </span>
            </h5>
          )}
          {dressing?.secProductName.value !== "" && (
            <h5
              className="dressing-kit-content-value"
              data-testid="dressing-kit-value-secondary"
            >
              <span className="dot-with-space-kit">&bull; </span>
              {`${dressing?.secProductQuantity.value} case of ${
                dressing?.secProductCode.value &&
                dressing?.secProductCode.value !== ""
                  ? dressing?.secProductCode.value.split("/")[1] ??
                    dressing?.secProductCode.value
                      .match(/\d/g)
                      ?.join("")
                      .replace(/^0+/, "")
                  : dressing?.secProductSizeCode.value.split("/")[1] ??
                    dressing?.secProductSizeCode.value
                      .match(/\d/g)
                      ?.join("")
                      .replace(/^0+/, "")
              } - ${dressing?.secProductName.value}, ${
                dressing?.secProductSizeName.value.split(" (")[0]
              } `}
              <span className="dressing-kit-size-content-value">
                {`(${
                  dressing?.secProductCode.value &&
                  dressing?.secProductCode.value !== ""
                    ? dressing?.secProductCode.value
                    : dressing?.secProductSizeCode.value
                })`}
              </span>
            </h5>
          )}
        </>
      ) : (
        "--"
      )}
      <h5 className="vacDressingkitHeader">
        {parse("3M™ V.A.C.<sup>®</sup> Canister(s)")}
      </h5>
      {renderCanisterReview()}
      <h5 className="vacDressingkitHeader" data-testid="accessories-kit">
        {parse("3M™ V.A.C.<sup>®</sup> Therapy Accessories")}
      </h5>
      {accesssory?.accessories && accesssory?.accessories.length > 0
        ? accesssory?.accessories.map(
            (
              accessory: {
                code: string;
                value: string;
              },
              index
            ) => {
              return (
                <h5
                  className="dressing-kit-content-value"
                  data-testid={`accessories-kit-value-${index + 1}`}
                  key={index}
                >
                  <span className="dot-with-space-kit">&bull; </span>
                  {`1 Pack of ${
                    accessory.code.split("/")[1] ??
                    accessory.code.match(/\d/g)?.join("")
                  } - ${accessory.value} `}
                  <span className="dressing-kit-size-content-value">{`(${accessory.code})`}</span>
                </h5>
              );
            }
          )
        : "--"}
    </div>
  );
};

export default SupplyOrderCondensed;
