import { Typography } from "@mui/material";
import React from "react";
import { ExpressButton } from "../../../../../core/expressButton/expressButton.component";
import { useHistory } from "react-router-dom";

const SendMessageSuccessPopup: React.FC = () => {
  const history = useHistory();
  return (
    <div>
      <Typography
        className="salesAssistanceSuccessPopup-header"
        data-testid="salesAssistanceSuccessPopup-header"
        id="salesAssistanceSuccessPopup-header"
      >
        Message Confirmation
      </Typography>
      <Typography
        className="salesAssistanceSuccessPopup-content"
        data-testid="salesAssistanceSuccessPopup-content"
        id="salesAssistanceSuccessPopup-content"
      >
        Thank you for submitting your request or comment. You will receive an
        email confirmation that your request/comment has been sent. Requests and
        comments are processed Monday through Friday (except for Holidays) from
        7 a.m. to 7 p.m. CST.
      </Typography>
      <ExpressButton
        variant="contained"
        parentClass="salesAssistanceSuccessPopup-button"
        clickHandler={() => history.push("/home")}
        testId="salesAssistanceSuccessPopup-button"
        id="salesAssistanceSuccessPopup-button"
      >
        Back to My Patients
      </ExpressButton>
    </div>
  );
};

export default SendMessageSuccessPopup;
