import SearchIcon from "@mui/icons-material/Search";
import { Grid, InputBase, TextField, useMediaQuery } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { useEffect, useState } from "react";
import { format } from "react-string-format";
import { ReactComponent as CalendarIcon } from "../../../assets/calendar.svg";
import { CustomDropDown } from "../../../core/customDropdown/customDropdown.component";
import { ExpressButton } from "../../../core/expressButton/expressButton.component";
import { InputWithLabel } from "../../../core/inputWithLabel/inputWithLabel.component";
import { ValidationStatus } from "../../../core/interfaces/input.interface";
import { getdropDownContent } from "../../../util/dropDownService";
import {
  DD_FACILITY_SUPPLY_ORDER_STATUSES,
  DD_FACILITY_SUPPLY_ORDER_TYPES,
} from "../../../util/staticText";
import { convertStringToDate } from "../../../util/utilityFunctions";
import "./facilitySupplyOrdersFilter.css";
import {
  IFacilitySupplyOrderFilter,
  IFacilitySupplyOrderFilterData,
} from "./facilitySupplyOrdersFilter.interface";
import { useDebounce } from "use-debounce";

export const FacilitySupplyOrderFilter = ({
  data,
  setData,
  handleApplyFilter,
}: IFacilitySupplyOrderFilter) => {
  const isMobileScreen = useMediaQuery("(max-width:1110px)");
  const [focusClasses, setFocusClasses] = useState({
    date: "facility-supply-order-report-filter-input-field-title",
  });
  const [orderTypeText, setOrderTypeText] = useState<string[]>([]);
  const [orderStatusesText, setOrderStatusesText] = useState<string[]>([]);
  const [searchInput, setSearchInput] = useState<string>("");
  const [searchedInput, setSearchedInput] = useState<string>("");
  const [debouncedText] = useDebounce(searchInput, 500);
  const setClasses = (e: any, classname: string) => {
    setFocusClasses(
      Object.assign({}, focusClasses, { [e.target.name]: classname })
    );
  };

  const checkApplyButtonDisablity = (): boolean => {
    return Object.keys(data).some(
      (key: string) =>
        data[key as keyof IFacilitySupplyOrderFilterData].valid ===
        ValidationStatus.INVALID
    );
  };

  const updateData = (
    isValid: boolean,
    name: string,
    value: string,
    updateOther: boolean = false
  ) => {
    let tempData = {
      ...data,
      [name]: {
        ...Object(data)[name],
        valid: isValid ? ValidationStatus.VALID : ValidationStatus.INVALID,
        value: value,
      },
    };
    if (updateOther) {
      const otherName = name === "startDate" ? "endDate" : "startDate";
      tempData = {
        ...tempData,
        [otherName]: {
          ...Object(data)[otherName],
          valid: isValid ? ValidationStatus.VALID : ValidationStatus.INVALID,
        },
      };
    }
    setData(tempData);
  };

  const validateAndSetData = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { value } = e.target;
    const regex = /^[^\s][\s\S]*$/;
    if (value === "" || regex.test(value)) {
      setSearchInput(value);
    }
  };

  const validateAndSetDate = (
    date1: string | null | undefined,
    name: string
  ) => {
    const formattedDate1 = date1 ? convertStringToDate(date1) : "";
    const date2: string =
      name === "endDate" ? data.startDate.value : data.endDate.value;
    const formattedDate2 = date2 ? convertStringToDate(date2) : "";
    let isValid: boolean = false;
    let updatedDate = formattedDate1;
    const currentDate = new Date();
    const start = new Date(
      name === "startDate" ? formattedDate1 : formattedDate2
    );
    const end = new Date(name === "endDate" ? formattedDate1 : formattedDate2);
    let isOtherRequiredToUpdate = false;
    if (formattedDate1 !== "" && formattedDate2 !== "") {
      isValid =
        start <= end &&
        ((name === "startDate" && start <= currentDate) ||
          (name === "endDate" && end <= currentDate));
      isOtherRequiredToUpdate =
        (name === "endDate" && start <= currentDate) ||
        (name === "startDate" && end <= currentDate);
    } else if (formattedDate1 !== "" || formattedDate2 !== "") {
      const selectedDate = new Date(formattedDate1);
      isValid = formattedDate1 !== "" && selectedDate <= currentDate;
    }
    updateData(isValid, name, updatedDate, isOtherRequiredToUpdate);
  };

  const handleApplyFilterClick = () => {
    handleApplyFilter();
  };

  const validateAndSetDropDownData = (e: any) => {
    const { name, value } = e.target;
    updateData(true, name, value);
  };

  const fetchDropDownContent = async () => {
    try {
      const ddContent = format(
        "{0},{1}",
        DD_FACILITY_SUPPLY_ORDER_TYPES,
        DD_FACILITY_SUPPLY_ORDER_STATUSES ?? ""
      );
      const data = await getdropDownContent(ddContent);
      if (data.items.length > 0) {
        const orderTypeObject = data.items.filter(
          (item: { name: string }) =>
            item.name === DD_FACILITY_SUPPLY_ORDER_TYPES
        );
        const orderTypeData = orderTypeObject[0].data.sort(
          (a: { order: number }, b: { order: number }) =>
            a.order > b.order ? 1 : -1
        );
        setOrderTypeText(orderTypeData.map((x: { text: string }) => x.text));
        const orderStatusesObject = data.items.filter(
          (item: { name: string }) =>
            item.name === DD_FACILITY_SUPPLY_ORDER_STATUSES
        );
        const orderStatusesData = orderStatusesObject[0].data.sort(
          (a: { order: number }, b: { order: number }) =>
            a.order > b.order ? 1 : -1
        );
        setOrderStatusesText(
          orderStatusesData.map((x: { text: string }) => x.text)
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    fetchDropDownContent();
  }, []);

  useEffect(() => {
    if (
      (debouncedText.length === 0 && searchedInput !== debouncedText) ||
      debouncedText.length >= 3
    ) {
      setSearchedInput(debouncedText);
      updateData(true, "searchInput", debouncedText);
    }
  }, [debouncedText]);

  return (
    <div
      className="facility-supply-order-report-filter-component"
      data-testid="facility-supply-order-report-filter-component"
    >
      <Grid
        className="facility-supply-order-report-filter-grid-container"
        container
      >
        <Grid
          className="facility-supply-order-report-filter-grid-item"
          item
          xs={isMobileScreen ? 12 : 4}
        >
          <InputWithLabel
            label="Search in table"
            labelClassName="facility-supply-order-report-filter-input-field-title"
            testId="searchInput"
          >
            <div className="facility-supply-order-report-filter-input-field-searchbar">
              <div className="search-icon-div">
                <SearchIcon className="search-icon" />
              </div>
              <InputBase
                className="facility-supply-order-report-filter-input"
                data-testid="facility-supply-order-report-filter-input"
                name="searchInput"
                onChange={validateAndSetData}
                placeholder="Filter by Order # or Sales PO #"
                value={searchInput}
              />
            </div>
          </InputWithLabel>
        </Grid>
        <Grid
          className="facility-supply-order-report-filter-grid-item"
          item
          xs={isMobileScreen ? 12 : 3}
        >
          <div className="facility-supply-order-report-filter-date-range">
            <InputWithLabel
              label={"Start Date"}
              labelClassName={focusClasses.date}
              testId="dateRangeStartDate"
            >
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  components={{ OpenPickerIcon: CalendarIcon }}
                  InputAdornmentProps={{
                    classes: {
                      root: "adornedRoot",
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: `facility-supply-order-report-filter-date ${
                        data.startDate.valid === ValidationStatus.INVALID
                          ? "show-error"
                          : "no-error"
                      }`,
                      input: "input",
                      notchedOutline: "outline",
                    },
                  }}
                  maxDate={data.endDate.value}
                  onChange={(value) => validateAndSetDate(value, "startDate")}
                  renderInput={(params) => {
                    params.error = false;
                    params.inputProps!.placeholder = "__/__/____";
                    return (
                      <TextField
                        id={data.startDate.id!}
                        name="startDate"
                        onFocus={(e) => setClasses(e, "Mui-focused")}
                        onBlur={(e) => setClasses(e, "")}
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          readOnly: true,
                        }}
                      />
                    );
                  }}
                  value={data.startDate.value}
                />
              </LocalizationProvider>
            </InputWithLabel>

            <InputWithLabel
              label={"End Date"}
              labelClassName={focusClasses.date}
              testId="dateRangeEndDate"
            >
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  components={{ OpenPickerIcon: CalendarIcon }}
                  InputAdornmentProps={{
                    classes: {
                      root: "adornedRoot",
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: `facility-supply-order-report-filter-date ${
                        data.endDate.valid === ValidationStatus.INVALID
                          ? "show-error"
                          : "no-error"
                      }`,
                      input: "input",
                      notchedOutline: "outline",
                    },
                  }}
                  maxDate={moment().toString()}
                  minDate={data.startDate.value}
                  onChange={(value) => validateAndSetDate(value, "endDate")}
                  renderInput={(params) => {
                    params.error = false;
                    params.inputProps!.placeholder = "__/__/____";
                    return (
                      <TextField
                        id={data.endDate.id!}
                        name="endDate"
                        onFocus={(e) => setClasses(e, "Mui-focused")}
                        onBlur={(e) => setClasses(e, "")}
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          readOnly: true,
                        }}
                      />
                    );
                  }}
                  value={data.endDate.value}
                />
              </LocalizationProvider>
            </InputWithLabel>
          </div>
        </Grid>
        <Grid
          className="facility-supply-order-report-filter-grid-item"
          item
          xs={isMobileScreen ? 12 : 3.4}
        >
          <div className="facility-supply-order-report-filter-types">
            <InputWithLabel
              label={"Order type"}
              labelClassName={focusClasses.date}
              testId="orderType"
            >
              <CustomDropDown
                handleChange={validateAndSetDropDownData}
                menuItem={["All order types"].concat(orderTypeText)}
                name="orderType"
                placeHolder="All order types"
                selectpropsClassName={
                  data.orderType.value
                    ? "facility-supply-order-select"
                    : "placeHolder"
                }
                selectClassName={
                  data.orderType.value
                    ? "facility-supply-order-select"
                    : "placeHolder"
                }
                testId="all-order-type"
                value={data.orderType.value ? data.orderType.value : null}
              />
            </InputWithLabel>
            <InputWithLabel
              label={"Status"}
              labelClassName={focusClasses.date}
              testId="orderStatus"
            >
              <CustomDropDown
                handleChange={validateAndSetDropDownData}
                menuItem={["All statuses"].concat(orderStatusesText)}
                name="orderStatus"
                placeHolder="All statuses"
                selectpropsClassName={
                  data.orderStatus.value
                    ? "facility-supply-order-select"
                    : "placeHolder"
                }
                selectClassName={
                  data.orderStatus.value
                    ? "facility-supply-order-select"
                    : "placeHolder"
                }
                testId="all-order-statuses"
                value={data.orderStatus.value ? data.orderStatus.value : null}
              />
            </InputWithLabel>
          </div>
        </Grid>
        <Grid
          className="facility-supply-order-report-filter-grid-item"
          item
          xs={isMobileScreen ? 12 : 1.6}
        >
          <div className="facility-supply-order-report-filter-apply-filter">
            <ExpressButton
              disabled={checkApplyButtonDisablity()}
              parentClass="apply-filter-button"
              variant="outlined"
              clickHandler={() => handleApplyFilterClick()}
              testId="apply-filter-btn-test"
            >
              Apply filter
            </ExpressButton>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
