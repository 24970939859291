import "./prescriberFound.css";
import {
  formatPhoneNumber,
  makeCapitalEachWordInString,
} from "../../../../../../../util/utilityFunctions";
import { Grid, useMediaQuery } from "@mui/material";
import { PrescriberPopUp } from "../../prescriberInformationPopUpContainer.enum";
import { IPrescriberInformation } from "../../../prescriberInformation.interface";
import { ExpressButton } from "../../../../../../../core/expressButton/expressButton.component";

export interface IPrescriberFound {
  changeSearchType: Function;
  prescriberInformation: IPrescriberInformation | null;
  setPrescriberInformation: Function;
}

export const PrescriberFound = ({
  changeSearchType,
  prescriberInformation,
  setPrescriberInformation,
}: IPrescriberFound) => {
  const isSmallerMobileScreen = useMediaQuery("(max-width:350px)");
  const isMobileScreen = useMediaQuery("(max-width:6000px)");

  return (
    <div
      className="prescriber-found-component"
      data-testid="prescriber-found-component"
    >
      <p
        className="prescriber-found-header"
        data-testid="prescriber-found-header"
        id="prescriber-found-header"
      >
        My Prescriber Search Result
      </p>
      <div className="prescriber-details">
        {prescriberInformation && (
          <Grid className="prescriber-details-grid-container" container>
            <Grid
              className="prescriber-details-grid-item"
              item
              xs={isSmallerMobileScreen ? 2.5 : isMobileScreen ? 2 : 1.5}
            ></Grid>
            <Grid
              className="prescriber-details-grid-item"
              item
              xs={isSmallerMobileScreen ? 9.5 : isMobileScreen ? 10 : 10.5}
            >
              <p
                className="prescriber-name prescriber-found-name-change"
                data-testid="prescriber-name"
                id="prescriber-name"
              >
                {makeCapitalEachWordInString(
                  `${prescriberInformation.firstName} ${prescriberInformation.lastName}`
                )}
              </p>
            </Grid>
            <Grid
              className="prescriber-details-grid-item"
              item
              xs={isSmallerMobileScreen ? 2.5 : isMobileScreen ? 2 : 1.5}
            >
              <p
                className="prescriber-select"
                data-testid="prescriber-select"
                id="prescriber-select"
                onClick={() => setPrescriberInformation(prescriberInformation)}
              >
                Select
              </p>
            </Grid>
            <Grid
              className="prescriber-details-grid-item"
              item
              xs={isSmallerMobileScreen ? 5.5 : isMobileScreen ? 6 : 5}
            >
              <p
                className="prescriber-name"
                data-testid="prescriber-npi"
                id="prescriber-npi"
              >
                <span
                  className="prescriberfoundSubHeader"
                  data-testid="prescriber-npi-title"
                  id="prescriber-npi-title"
                >
                  NPI #
                </span>
                {prescriberInformation.npi}
              </p>
              <p
                className="prescriber-name"
                data-testid="prescriber-phone"
                id="prescriber-phone"
              >
                <span
                  className="prescriberfoundSubHeader"
                  data-testid="prescriber-phone-title"
                  id="prescriber-phone-title"
                >
                  Phone
                </span>
                {formatPhoneNumber(prescriberInformation.telephoneNumber)}
              </p>
              <p
                className="prescriber-name"
                data-testid="prescriber-fax"
                id="prescriber-fax"
              >
                <span
                  className="prescriberfoundSubHeader"
                  data-testid="prescriber-fax-title"
                  id="prescriber-fax-title"
                >
                  Fax
                </span>
                <span className="prescriberFax">
                  {prescriberInformation.faxNumber &&
                  prescriberInformation.faxNumber !== ""
                    ? formatPhoneNumber(prescriberInformation.faxNumber)
                    : "-"}
                </span>
              </p>
            </Grid>
            <Grid className="prescriber-details-grid-item" item xs={4}>
              <p
                className="prescriber-address"
                data-testid="prescriber-address1"
                id="prescriber-address1"
              >
                {makeCapitalEachWordInString(prescriberInformation.address1)}
              </p>
              <p
                className="prescriber-address"
                data-testid="prescriber-address2"
                id="prescriber-address2"
              >
                {makeCapitalEachWordInString(prescriberInformation.address2)}
              </p>
              <p
                className="prescriber-address"
                data-testid="prescriber-city-state-zip"
                id="prescriber-city-state-zip"
              >
                {`${makeCapitalEachWordInString(prescriberInformation.city)}, ${
                  prescriberInformation.state
                } ${prescriberInformation.zipCode}`}
              </p>
            </Grid>
          </Grid>
        )}
        <ExpressButton
          clickHandler={() =>
            changeSearchType(PrescriberPopUp.SEARCH_PRESCRIBER)
          }
          parentClass="back-to-search-button"
          testId="back-to-search-button"
          variant="outlined"
        >
          Back to Search
        </ExpressButton>
      </div>
    </div>
  );
};
