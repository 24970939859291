import { ValidationStatus } from "../../core/interfaces/input.interface";
import {
  IInpatientSupplyOrder,
  ISalesPurchaseOrderNumber,
  IShippingAddressInfo,
  ISupplyOrderDeliveryInformation,
} from "./inpatientSupplyOrder.interface";

export let defaultShippingAddressData: IShippingAddressInfo = {
  // Verify Requester Info
  isSameasFacilityAddress: {
    isDefaultValid: true,
    required: true,
    title: "",
    valid: ValidationStatus.VALID,
    value: "yes",
  },
  shippingFacilityName: {
    componentId: "inpatient-supplyorder-shippingfacilityname",
    id: "inpatient-supplyorder-shippingfacilityname",
    order: 49,
    required: true,
    title: "Facility Name",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  shippingAddressLine1: {
    componentId: "inpatient-supplyorder-shippingaddressline1",
    id: "inpatient-supplyorder-shippingaddressline1",
    order: 49,
    required: true,
    title: "Address Line 1 (No PO Boxes)",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  shippingAddressLine2: {
    componentId: "inpatient-supplyorder-shippingaddressline2",
    id: "inpatient-supplyorder-shippingaddressline2",
    order: 50,
    required: true,
    title: "Address Line 2",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  shippingAddressCity: {
    componentId: "inpatient-supplyorder-shippingaddresscity",
    id: "inpatient-supplyorder-shippingaddresscity",
    order: 51,
    required: true,
    title: "City",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  shippingAddressState: {
    componentId: "inpatient-supplyorder-shippingaddresstate",
    id: "inpatient-supplyorder-shippingaddresstate",
    order: 52,
    required: true,
    title: "State",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    isPhoneNumber: true,
  },
  shippingAddressZip: {
    componentId: "inpatient-supplyorder-shippingaddreszip",
    id: "inpatient-supplyorder-shippingaddreszip",
    order: 53,
    required: true,
    title: "ZIP Code",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
};

export const defaultDeliveryInformation: ISupplyOrderDeliveryInformation = {
  needByDate: {
    componentId: "inpatient-supply-order-needby-date",
    id: "inpatient-supply-order-needby-date",
    order: 1,
    required: false,
    title: "Need by Date",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  deliveryMethod: {
    componentId: "inpatient-supply-order-delivery-method",
    id: "inpatient-supply-order-delivery-method",
    order: 2,
    required: true,
    title: "Select Delivery Method",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
};

export const defaultSalesPurchaseOrderNumberData: ISalesPurchaseOrderNumber = {
  salesPONumber: {
    componentId: "in-patient-order-salespurchase-information-component",
    id: "in-patient-supplyorder-sales-po-number-input",
    order: 13,
    title: "Sales Purchase Order Number",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  isPoRequired: null,
};

export const defaultInPatientSupplyOrder: IInpatientSupplyOrder = {
  sizeToGuide: null,
  patientAndProduct: null,
  deliveryInformation: defaultDeliveryInformation,
  shippingAddress: defaultShippingAddressData, //added for review and summary page.(assumptions)
  salesPurchaseOrderInformation: defaultSalesPurchaseOrderNumberData,
  billToSiteId: null,
  shipToSiteId: null,
};
