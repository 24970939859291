import { Grid } from "@mui/material";
import parse from "html-react-parser";
import { useContext } from "react";
import { MobileDisplayContext } from "../../../../../context/MobileDisplayContext";
import { maskProductInUse } from "../../../../../util/utilityFunctions";
import "./orderDetailsProductInformation.css";
import { IOrderOverviewProductInformation } from "./orderDetailsProductInformation.interface";
export const OrderDetailsProductInformation = ({
  productInfo,
  isAcuteOrder = false,
  isCustomerPlus = false,
}: IOrderOverviewProductInformation) => {
  const { isMobileScreen } = useContext(MobileDisplayContext);
  const getUIAlignmentGridSize = () => {
    return isMobileScreen ? 12 : isCustomerPlus ? 4 : 4.5;
  };
  return !isAcuteOrder ? (
    <div className="orderoverview-prod-info">
      <div className="product-information-component-title">
        <h2
          className="orderoverview-prod-info-title"
          data-testid="orderoverview-prod-info-title"
        >
          Product Information
        </h2>
      </div>
      <Grid className="grid-container" container>
        <div className="all-content-div">
          <div className="content-div">
            <Grid className="grid-item" item xs={6}>
              <div className="sub-content-div">
                <p
                  className="orderoverview-prod-info-content-title"
                  data-testid="product-is-reday-care"
                >
                  V.A.C.<sup>®</sup> Ready Care?
                </p>
                <h5
                  className="orderoverview-prod-info-content-value text"
                  data-testid="product-is-reday-care-value"
                >
                  {productInfo.isReadyCare.value !== ""
                    ? productInfo.isReadyCare.value
                    : "--"}
                </h5>
              </div>
            </Grid>
            <Grid className="grid-item" item xs={10}>
              <div className="sub-content-div">
                <h5
                  className="orderoverview-prod-info-content-title"
                  data-testid="serial-number-data"
                >
                  Serial Number
                </h5>
                <h5
                  className="orderoverview-prod-info-content-value text"
                  data-testid="serial-number-value"
                >
                  {productInfo?.serialNumber?.value !== ""
                    ? productInfo?.serialNumber?.value
                    : "--"}
                </h5>
              </div>
            </Grid>
          </div>
        </div>
        <div className="all-content-div">
          <div className="content-div-last">
            <Grid className="grid-item" item xs={12}>
              <div className="sub-content-div-product">
                {productInfo.isReadyCare.value === "Yes" ? (
                  <p
                    className="orderoverview-prod-info-content-title"
                    data-testid="product-product-data"
                  >
                    The following products will be given to the patient with the
                    3M™ V.A.C.<sup>®</sup> Ready Care Program kit
                  </p>
                ) : (
                  <p
                    className="orderoverview-prod-info-content-title"
                    data-testid="product-product-data"
                  >
                    Product
                  </p>
                )}
                <div className="productInfo">
                  {productInfo.productValue.value !== "" && (
                    <span className="dot-with-space">&bull; </span>
                  )}
                  <p
                    className="orderoverview-prod-info-content-value"
                    data-testid="product-product-value"
                  >
                    {productInfo.productValue.value !== ""
                      ? maskProductInUse(productInfo.productValue.value)
                      : "--"}
                  </p>
                </div>
                {productInfo.isReadyCare.value === "Yes" && (
                  <div>
                    <div className="productInfo">
                      <span className="dot-with-space">&bull; </span>
                      <p
                        className="orderoverview-prod-info-content-value"
                        data-testid="dressing-kit"
                      >
                        1 Case of 5 - 3M™ V.A.C.® Granufoam™ Dressing Kit,
                        Medium (M8275052/5)
                      </p>
                    </div>
                    <div className="productInfo">
                      <span className="dot-with-space"> &bull; </span>
                      <p
                        className="orderoverview-prod-info-content-value"
                        data-testid="canister"
                      >
                        1 Case of 5 - ACTIV.A.C™ 300mL Canister w/Gel,
                        (M8275058/5)
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </Grid>
          </div>
        </div>
      </Grid>
    </div>
  ) : (
    <div className="orderoverview-prod-info">
      <div className="product-information-component-title">
        <h2
          className="orderoverview-prod-info-title"
          data-testid="orderoverview-prod-info-title"
        >
          {isCustomerPlus ? "Device Placement" : "Product Information"}
        </h2>
      </div>
      <Grid className="grid-container" container>
        <div className="all-content-div">
          <div
            className={
              isMobileScreen ? "content-div-last-mobile" : "content-div-last"
            }
          >
            {isCustomerPlus && (
              <Grid className="grid-item" item xs={getUIAlignmentGridSize()}>
                <div className="sub-content-div-product">
                  <h5
                    id="orderoverview-prod-info-content-title"
                    className="orderoverview-prod-info-content-title"
                    data-testid="product-product-data"
                  >
                    Placement Type
                  </h5>
                  <h5
                    id="orderoverview-prod-info-content-value"
                    className="orderoverview-prod-info-content-value"
                    data-testid="product-product-value"
                  >
                    Customer Plus
                  </h5>
                </div>
              </Grid>
            )}
            <Grid className="grid-item" item xs={getUIAlignmentGridSize()}>
              <div className="sub-content-div-product">
                <h5
                  className="orderoverview-prod-info-content-title"
                  data-testid="product-product-data"
                >
                  Product
                </h5>
                <h5
                  className="orderoverview-prod-info-content-value"
                  data-testid="product-product-value"
                >
                  {productInfo.productValue.value !== ""
                    ? parse(productInfo.productValue.value)
                    : "--"}
                </h5>
              </div>
            </Grid>
            <Grid className="grid-item" item xs={isCustomerPlus ? 3 : 7.5}>
              <div className="sub-content-div">
                <h5
                  className="orderoverview-prod-info-content-title"
                  data-testid="serial-number-data"
                >
                  Serial Number
                </h5>
                <h5
                  className="orderoverview-prod-info-content-value"
                  data-testid="serial-number-value"
                >
                  {productInfo?.serialNumber?.value !== ""
                    ? productInfo?.serialNumber?.value
                    : "--"}
                </h5>
              </div>
            </Grid>
          </div>
        </div>
      </Grid>
    </div>
  );
};
