import "./serviceOptionsReviewRequest.css";
import { IDropdownValue } from "../serviceOptions.component";
import { getTextFromCode } from "../../../../../../util/utilityFunctions";
import { ICreateServiceRequest } from "../../../createServiceRequest.interface";
import { IInputField } from "../../../../../../core/interfaces/input.interface";

export interface IServiceOptionsReviewRequest {
  availableOptions: IDropdownValue[];
  data: ICreateServiceRequest;
  editButtonAction?: any;
  isReviewSummary?: boolean;
}

export const ServiceOptionsReviewRequest = ({
  availableOptions,
  data,
  editButtonAction,
  isReviewSummary = false,
}: IServiceOptionsReviewRequest) => {
  return (
    <div className="service-options-review-request-component">
      <div className="service-options-review-request-header">
        <p
          className="service-options-review-request-title"
          data-testid="service-options-review-request-title"
        >
          Service Options
        </p>
        {!isReviewSummary && (
          <button
            className="service-options-review-request-edit-button"
            data-testid="service-options-review-request-edit-button"
            onClick={() => editButtonAction("service-options-component")}
          >
            Edit
          </button>
        )}
      </div>
      {data.serviceOptions.map((option: IInputField, index: number) => {
        return (
          <div className="show-title-and-value-div">
            <p
              className="show-title-and-value-title"
              data-testid={`service-options-${index + 1}`}
            >
              {`${option.title} ${index + 1}`}
            </p>
            <p
              className="show-title-and-value-value"
              data-testid={`service-options-value-${index + 1}`}
            >
              {option.value && option.value !== ""
                ? getTextFromCode(availableOptions, option.value)
                : "--"}
            </p>
          </div>
        );
      })}
      <div className="show-title-and-value-div">
        <p
          className="show-title-and-value-title"
          data-testid="service-options-specail-instruction-title"
        >
          Special Instructions
        </p>
        <p
          className="show-title-and-value-value"
          data-testid="service-options-specail-instruction-value"
        >
          {data.specialInstrutions.value &&
          data.specialInstrutions.value.trim() !== ""
            ? data.specialInstrutions.value.trim()
            : "--"}
        </p>
      </div>
    </div>
  );
};
