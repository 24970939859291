import "./serviceRequestSummary.css";
import { SummaryHeader } from "./header/summaryHeader.component";
import {
  IDropdownValue,
  ServiceOptions,
} from "../createServiceRequest/serviceOptions/serviceOptions.component";
import { ICreateServiceRequest } from "../createServiceRequest.interface";
import { ReactComponent as DownloadIcon } from "../../../../assets/download.svg";
import { ExpressButton } from "../../../../core/expressButton/expressButton.component";
import { VacTherapyUnit } from "../createServiceRequest/vacTherapyUnit/vacTherapyUnit.component";
import { ICustomerPlusListingDetails } from "../../customerPlusInventory/customerPlusInventory.interface";
import { DeviceComplications } from "../createServiceRequest/deviceComplications/deviceComplications.component";
import { EstimatedArrivalTime } from "../createServiceRequest/estimatedArrivalTime/estimatedArrivalTime.component";
import { useHistory } from "react-router-dom";
import PickupLocation from "../createServiceRequest/pickupLocation/pickupLocation.component";
import WorkOrderNumber from "./reviewWorkOrderNumber/workOrderNumber.component";
import { PatientDetails } from "../../../pickUpAndDischargeRequest/pickUpRequest/patientDetails/patientDetails.component";
import { IPatient } from "../../../myPatients/patient.interface";

export interface IServiceRequestSummaryProps {
  availableOptions: IDropdownValue[];
  data: ICreateServiceRequest;
  editButtonAction: any;
  isReviewRequest: boolean;
  isReviewSummary: boolean;
  setAvailableOptions: Function;
  setData: Function;
  selectedProduct: ICustomerPlusListingDetails;
  workOrderNumber: string;
  isSolventumOrder?: boolean;
  patientData?: IPatient;
}

export const ServiceRequestSummary = ({
  availableOptions,
  data,
  editButtonAction,
  isReviewRequest,
  isReviewSummary,
  setAvailableOptions,
  setData,
  selectedProduct,
  workOrderNumber,
  isSolventumOrder,
  patientData,
}: IServiceRequestSummaryProps) => {
  const handleClickPdfDownload = async () => {
    window.print();
  };
  const history = useHistory();
  const handleNavigation = () => {
    history.goBack();
  };
  return (
    <div className="service-request-summary-component">
      <SummaryHeader
        successMessage="Success!"
        description1="Your Service Request has been submitted."
        description2="A 3M Medical Solutions representative will contact you if there are any questions around this service request."
        backButton={
          isSolventumOrder ? "Back to My Patients" : "Back to Inventory"
        }
        handleBackButton={handleNavigation}
      />
      <div className="service-request-summary-header">
        <p
          className="service-request-summary-title"
          data-testid="service-request-summary-title"
          id="service-request-summary-title"
        >
          Service Request Summary
        </p>
        <div className="service-request-summary-header-button">
          <ExpressButton
            clickHandler={() => handleClickPdfDownload()}
            id="service-request-summary-download-button"
            parentClass="service-request-summary-download-button"
            testId="download-button"
            variant="text"
            startIcon={<DownloadIcon />}
          >
            Save and Print Request Summary
          </ExpressButton>
        </div>
      </div>
      <div className="short-form">
        {isSolventumOrder ? (
          <>
            <WorkOrderNumber workOrderNumber={workOrderNumber} />
            <PatientDetails
              isAcuteOrderFlow={patientData!.isAcuteOrder ?? false}
              patient={patientData!}
              excangeVacFlow={true}
            />
            <PickupLocation
              data={data}
              setData={setData}
              isReviewOrder={isReviewRequest}
              isReviewSummary={isReviewSummary}
              editButtonAction={editButtonAction}
              storageLocations={[]}
            />
          </>
        ) : (
          <VacTherapyUnit
            isReviewRequest={isReviewRequest}
            selectedProduct={selectedProduct}
          />
        )}
        <ServiceOptions
          availableOptions={availableOptions}
          data={data}
          editButtonAction={editButtonAction}
          isReviewRequest={isReviewRequest}
          isReviewSummary={isReviewSummary}
          setAvailableOptions={setAvailableOptions}
          setData={setData}
        />
        <EstimatedArrivalTime
          data={data}
          editButtonAction={editButtonAction}
          isReviewRequest={isReviewRequest}
          isReviewSummary={isReviewSummary}
          setData={setData}
        />
        <DeviceComplications
          data={data}
          editButtonAction={editButtonAction}
          isReviewRequest={isReviewRequest}
          isReviewSummary={isReviewSummary}
          setData={setData}
        />
      </div>
    </div>
  );
};
