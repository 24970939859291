import { ValidationStatus } from "../../../../core/interfaces/input.interface";
import { ProductInformation } from "../../dressingSupplies/vacDressingKit/vacDressingKit.interface";
import { IDressingKit } from "../../newOrder.interface";
import { VacOrderSummaryData } from "./newOrderResponseMapper.interface";

export const vacPrimaryDressingKitMapper = (
  vacOrderSummary: VacOrderSummaryData,
  dressingMapperRes: IDressingKit,
  vacAllProducts: any,
  NewOrderObj: any
) => {
  let selectedProduct = getSelectedVacProduct(
    vacOrderSummary.isFromReadyCare === true
      ? vacOrderSummary.additionalDressing.sku
      : vacOrderSummary.mainDressing.sku,
    vacAllProducts
  )! as ProductInformation;
  dressingMapperRes = {
    ...dressingMapperRes,
    productId: {
      ...dressingMapperRes.productId,
      valid: ValidationStatus.VALID,
      value:
        selectedProduct.sku ??
        (vacOrderSummary.isFromReadyCare === true
          ? vacOrderSummary.additionalDressing.sku
          : vacOrderSummary.mainDressing.sku),
    },
    productCode: {
      ...dressingMapperRes.productCode,
      valid: ValidationStatus.VALID,
      value:
        selectedProduct.sku ??
        (vacOrderSummary.isFromReadyCare === true
          ? vacOrderSummary.additionalDressing.sku
          : vacOrderSummary.mainDressing.sku),
    },
    productName: {
      ...dressingMapperRes.productName,
      valid: ValidationStatus.VALID,
      value: selectedProduct.productName,
    },
    productQuantity: {
      ...dressingMapperRes.productQuantity,
      valid: ValidationStatus.VALID,
      value:
        vacOrderSummary.isFromReadyCare === true
          ? vacOrderSummary.additionalDressing.quantity.toString()
          : vacOrderSummary.mainDressing.quantity.toString(),
    },
  };
  NewOrderObj?.setShowSize(false);
  if (selectedProduct.sizes && selectedProduct.hasMultipleSize) {
    const selectedSku =
      vacOrderSummary?.isFromReadyCare === true
        ? vacOrderSummary.additionalDressing.sku
        : vacOrderSummary.mainDressing.sku;
    const selectedSizeProduct = selectedProduct.sizes.filter(
      (x) => x.sku === selectedSku
    )[0];
    dressingMapperRes = {
      ...dressingMapperRes,
      productSizeID: {
        ...dressingMapperRes.productSizeID,
        valid: ValidationStatus.VALID,
        value: selectedSizeProduct.sku,
      },
      productSizeCode: {
        ...dressingMapperRes.productSizeCode,
        valid: ValidationStatus.VALID,
        value: selectedSizeProduct.sku,
      },
      productSizeName: {
        ...dressingMapperRes.productSizeName,
        valid: ValidationStatus.VALID,
        value: selectedSizeProduct.name,
      },
    };
    NewOrderObj?.setShowSize(true);
  } else {
    dressingMapperRes = {
      ...dressingMapperRes,
      productSizeID: {
        ...dressingMapperRes.productSizeID,
        valid: ValidationStatus.VALID,
        value: "",
      },
      productSizeCode: {
        ...dressingMapperRes.productSizeCode,
        valid: ValidationStatus.VALID,
        value: "",
      },
      productSizeName: {
        ...dressingMapperRes.productSizeName,
        valid: ValidationStatus.VALID,
        value: "",
      },
    };
  }
  if (vacOrderSummary.isFromReadyCare) {
    if (vacOrderSummary.additionalDressing.quantity === 2) {
      NewOrderObj?.setIsPrimaryVacKitDressingPlusDisabled(true);
      NewOrderObj?.setIsPrimaryVacKitDressingMinusDisabled(false);
    }
    if (vacOrderSummary.additionalDressing.quantity === 1) {
      NewOrderObj?.setIsPrimaryVacKitDressingMinusDisabled(true);
      NewOrderObj?.setIsPrimaryVacKitDressingPlusDisabled(false);
    }
  } else {
    if (
      vacOrderSummary.mainDressing.quantity +
        vacOrderSummary.additionalDressing.quantity >
      2
    ) {
      NewOrderObj?.setIsPrimaryVacKitDressingPlusDisabled(true);
      if (vacOrderSummary.mainDressing.quantity === 1) {
        NewOrderObj?.setIsPrimaryVacKitDressingMinusDisabled(true);
      } else {
        NewOrderObj?.setIsPrimaryVacKitDressingMinusDisabled(false);
      }
    } else {
      if (vacOrderSummary.mainDressing.quantity === 3) {
        NewOrderObj?.setIsPrimaryVacKitDressingPlusDisabled(true);
        NewOrderObj?.setIsPrimaryVacKitDressingMinusDisabled(false);
      }
      if (vacOrderSummary.mainDressing.quantity === 1) {
        NewOrderObj?.setIsPrimaryVacKitDressingMinusDisabled(true);
        NewOrderObj?.setIsPrimaryVacKitDressingPlusDisabled(false);
      }
    }
  }
  NewOrderObj?.setshowQunatity(true);
  return dressingMapperRes;
};

export const vacSecondaryDressingKitMapper = (
  vacOrderSummary: VacOrderSummaryData,
  dressingMapperRes: IDressingKit,
  vacAllProducts: any,
  NewOrderObj: any
) => {
  let selectedProduct = getSelectedVacProduct(
    vacOrderSummary.additionalDressing.sku,
    vacAllProducts
  )! as ProductInformation;

  dressingMapperRes = {
    ...dressingMapperRes,
    secProductId: {
      valid: ValidationStatus.VALID,
      value: selectedProduct.sku ?? vacOrderSummary.additionalDressing.sku,
    },
    secProductCode: {
      valid: ValidationStatus.VALID,
      value: selectedProduct.sku ?? vacOrderSummary.additionalDressing.sku,
    },
    secProductName: {
      ...dressingMapperRes.secProductName,
      valid: ValidationStatus.VALID,
      value: selectedProduct.productName,
    },
    secProductQuantity: {
      valid: ValidationStatus.VALID,
      value: vacOrderSummary.additionalDressing.quantity.toString(),
    },
  };
  NewOrderObj?.setShowSecSize(false);
  if (selectedProduct.sizes && selectedProduct.hasMultipleSize) {
    const selectedSizeProduct = selectedProduct.sizes.filter(
      (x) => x.sku === vacOrderSummary.additionalDressing.sku
    )[0];
    dressingMapperRes = {
      ...dressingMapperRes,
      secProductSizeID: {
        valid: ValidationStatus.VALID,
        value: selectedSizeProduct.sku,
      },
      secProductSizeCode: {
        valid: ValidationStatus.VALID,
        value: selectedSizeProduct.sku,
      },
      secProductSizeName: {
        ...dressingMapperRes.secProductSizeName,
        valid: ValidationStatus.VALID,
        value: selectedSizeProduct.name,
      },
    };
    NewOrderObj?.setShowSecSize(true);
  }
  if (
    vacOrderSummary.mainDressing.quantity +
      vacOrderSummary.additionalDressing.quantity >
    2
  ) {
    NewOrderObj?.setIsSecondaryVacKitDressingPlusDisabled(true);
    if (vacOrderSummary.additionalDressing.quantity === 1) {
      NewOrderObj?.setIsSecondaryVacKitDressingMinusDisabled(true);
    } else {
      NewOrderObj?.setIsSecondaryVacKitDressingMinusDisabled(false);
    }
  } else {
    if (vacOrderSummary.additionalDressing.quantity === 3) {
      NewOrderObj?.setIsSecondaryVacKitDressingPlusDisabled(true);
      NewOrderObj?.setIsSecondaryVacKitDressingMinusDisabled(false);
    }
    if (vacOrderSummary.additionalDressing.quantity === 1) {
      NewOrderObj?.setIsSecondaryVacKitDressingMinusDisabled(true);
      NewOrderObj?.setIsSecondaryVacKitDressingPlusDisabled(false);
    }
  }
  NewOrderObj?.setShowSecondaryDressingKit(true);

  NewOrderObj?.setshowSecQunatity(true);

  return dressingMapperRes;
};

export const vacCannisterMapper = (
  vacOrderSummary: VacOrderSummaryData,
  NewOrderObj: any
) => {
  if (!vacOrderSummary.isFromReadyCare) {
    if (vacOrderSummary.canister && vacOrderSummary.canister.quantity === 2) {
      NewOrderObj?.setIsCannisterProductPlusDisabled(true);
      NewOrderObj?.setIsCannisterProductMinusDisabled(false);
    }
    if (vacOrderSummary.canister && vacOrderSummary.canister.quantity === 1) {
      NewOrderObj?.setIsCannisterProductPlusDisabled(false);
      NewOrderObj?.setIsCannisterProductMinusDisabled(true);
    }
  } else {
    if (vacOrderSummary.canister.quantity === 1) {
      NewOrderObj.setshowCannisterType(true);
    }
  }
};

export const getSelectedVacProduct = (
  productCode: string,
  vacAllProducts: any
) => {
  let obj;
  vacAllProducts?.map((element: any) => {
    if (element.sku === productCode) {
      obj = element;
    } else if (element.sizes) {
      element.sizes.forEach((sa: any) => {
        if (sa.sku === productCode) {
          obj = element;
        }
      });
    }
  });
  return obj;
};

export const vacReadyCareDefaultDressingMapper = (
  dressingMapperRes: IDressingKit
) => {
  dressingMapperRes = {
    ...dressingMapperRes,
    productId: {
      valid: ValidationStatus.VALID,
      value: "",
    },
    productCode: {
      valid: ValidationStatus.VALID,
      value: "",
    },
    productName: {
      valid: ValidationStatus.VALID,
      value: "",
    },
    productQuantity: {
      valid: ValidationStatus.VALID,
      value: "",
    },
    productSizeID: {
      valid: ValidationStatus.VALID,
      value: "",
    },
    productSizeCode: {
      valid: ValidationStatus.VALID,
      value: "",
    },
    productSizeName: {
      valid: ValidationStatus.VALID,
      value: "",
    },
  };
  return dressingMapperRes;
};
