import { ReactNode, useContext, useEffect, useState } from "react";
import "./myLists.css";
import { Navigator } from "../../helpAndSupport/Navigator/navigator.component";
import { useTabs } from "react-headless-tabs";
import { Grid, Tab, useMediaQuery } from "@mui/material";
import { TabSelector } from "../../../core/customTab/tabSelector";
import { MyListsTabs } from "./myLists.enum";
import { AdminMyListsContext } from "../../../context/AdministrationMyListsContext";
import { MyListHomeCareProvider } from "./myListHomeCareProvider/myListHomeCareProvider.component";
import { MyListPrescriber } from "./myListPrescribers/myListPrescriber.component";
import { AuthContext, AuthContextType } from "../../../context/AuthContext";
import { getProperty } from "../../../util/ObjectFunctions";
import { useHistory } from "react-router-dom";
import { MyListStoredLocations } from "./myListStorageLocations/myListStorageLocations.component";
import { ScrollableTabHeader } from "../../../core/scrollableTabs/scrollableTabHeader.component";

export const MyLists = () => {
  const [selectedTab, setSelectedTab] = useTabs([
    MyListsTabs.STORAGE_LOCATIONS,
    MyListsTabs.HOME_CARE_PROVIDER,
    MyListsTabs.PRESCRIBERS,
  ]);
  const myListsObj = useContext(AdminMyListsContext);
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const history = useHistory();
  const isSmallerMobileScreen = useMediaQuery("(max-width:450px)");
  const isMobileScreen = useMediaQuery("(max-width:600px)");
  const isIPadScreen = useMediaQuery("(max-width:1024px)");

  const updateSelectedTab = async (selectedTabDetails: MyListsTabs) => {
    myListsObj?.resetData();
    setSelectedTab(selectedTabDetails);
    myListsObj?.setSelectedMyListsTab(selectedTabDetails);
  };
  const [scrollTabValue, setScrollTabValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setScrollTabValue(newValue);
  };
  const sectionToDisplay = () => {
    let page: ReactNode;
    switch (selectedTab) {
      case MyListsTabs.PRESCRIBERS:
        page = <MyListPrescriber />;
        break;
      case MyListsTabs.HOME_CARE_PROVIDER:
        page = <MyListHomeCareProvider />;
        break;
      case MyListsTabs.STORAGE_LOCATIONS:
        page = <MyListStoredLocations />;
        break;
    }
    return page;
  };
  useEffect(() => {
    switch (selectedTab) {
      case MyListsTabs.PRESCRIBERS:
        setScrollTabValue(2);
        break;
      case MyListsTabs.HOME_CARE_PROVIDER:
        setScrollTabValue(1);
        break;
      case MyListsTabs.STORAGE_LOCATIONS:
        setScrollTabValue(0);
        break;
      default:
        setScrollTabValue(0);
        break;
    }
  }, [selectedTab]);

  useEffect(() => {
    if (
      !getProperty(authObj?.sideNavMenuOptionModelData, "administration")
        .isVisible
    ) {
      history.push("/home");
    }
  }, [authObj?.registeredFaciltyAddress?.siteUseId, authObj?.allFacilities]);

  return (
    <div className="myLists-component" data-testid="myLists-component">
      <Navigator
        array={[
          {
            route: "/administration",
            pageName: "Administration",
          },
        ]}
        className="myLists-component-route-section"
        title="Manage My Lists"
      />
      <div className="myLists-main-section">
        <span
          className="myLists-main-section-header"
          data-testid="manage-myLists-main-header"
        >
          Manage My Lists
        </span>
      </div>
      <div className="myLists-tab-div">
        <div className="myLists-scroll-table-header" data-testid="tab-nav">
          <ScrollableTabHeader
            scrollTabValue={scrollTabValue}
            handleChange={handleChange}
            tabsIndicator="scroll-tabs-indicator"
            tabsRoot="scroll-tabs"
            variant="scrollable"
          >
            <Tab
              classes={{
                textColorPrimary: "myLists-scroll-tabs-textlabel",
                selected: "myLists-scroll-tabs-textlabel-select",
                root: "scroll-tabs-height",
              }}
              iconPosition="end"
              label={MyListsTabs.STORAGE_LOCATIONS}
              data-testid="my-list-storage-loc-tab"
              onClick={() => {
                updateSelectedTab(MyListsTabs.STORAGE_LOCATIONS);
              }}
            />
            <Tab
              classes={{
                textColorPrimary: "myLists-scroll-tabs-textlabel",
                selected: "myLists-scroll-tabs-textlabel-select",
                root: "scroll-tabs-height",
              }}
              iconPosition="end"
              label={MyListsTabs.HOME_CARE_PROVIDER}
              data-testid="home-care-providers-tab"
              onClick={() => {
                updateSelectedTab(MyListsTabs.HOME_CARE_PROVIDER);
              }}
            />
            <Tab
              classes={{
                textColorPrimary: "myLists-scroll-tabs-textlabel",
                selected: "myLists-scroll-tabs-textlabel-select",
                root: "scroll-tabs-height",
              }}
              iconPosition="end"
              label={MyListsTabs.PRESCRIBERS}
              data-testid="prescribers-tab"
              onClick={() => {
                updateSelectedTab(MyListsTabs.PRESCRIBERS);
              }}
            />
          </ScrollableTabHeader>
        </div>
      </div>
      <div className="myListMainDiv">{sectionToDisplay()}</div>
    </div>
  );
};
