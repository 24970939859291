import moment from "moment";
import { useState } from "react";
import {
  alertColorsData,
  IAlertColours,
  AlertColourConfigBySeverity,
} from "../mockData/alertColorData";
import {
  IInputField,
  Validation,
  ValidationStatus,
} from "../core/interfaces/input.interface";
import {
  AcutePatientMenuActionTypes,
  IAlertTypes,
  ISeverityTypes,
} from "../components/myPatients/patient.interface";
import { AuthContextType } from "../context/AuthContext";
import { IManageProfile } from "../components/manageProfile/manageProfile.interface";
import { ManageProfileValidator } from "../components/manageProfile/manageProfile.validator";
import { IFacility } from "../components/manageProfile/facilityInformation/facility.interface";
import { ProductInformation } from "../components/newOrder/dressingSupplies/vacDressingKit/vacDressingKit.interface";
import { NewOrderValidator } from "../components/newOrder/newOrder.validator";
import { SupplyOrderValidator } from "../components/supplyOrder/supplyOrder.validator";
import { NewOrderContextType } from "../context/NewOrderContext";
import {
  IPatientAddressResponse,
  IValidateUPSAddress,
  IValidateUPSAddressResponse,
} from "../components/newOrder/patientInformation/patientInformation.interface";
import { IPermissionData } from "../RolesPermission/RolesPermission.model";
import { PlacementTypeSelection } from "../components/inpatientOrder/components/devicePlacement/devicePlacemet.enums";
import { IBillingAddress } from "../components/inpatientOrder/components/billingAddress/billingAddress.interface";
import parse from "html-react-parser";

export const getAlertBgAndLabelColor = (
  alertType: IAlertTypes,
  severity: ISeverityTypes | null
) => {
  let bgColor = "",
    color = "";
  const colorObj = alertColorsData.filter(
    (aData) => aData.alertType === alertType
  )[0];
  if (severity === null) {
    const parsedObj = colorObj.colours as IAlertColours;
    bgColor = parsedObj.backgroundColour;
    color = parsedObj.foreColour;
  } else {
    const parsedObj = colorObj.colours as AlertColourConfigBySeverity;
    bgColor = parsedObj[severity].backgroundColour;
    color = parsedObj[severity].foreColour;
  }
  return [bgColor, color];
};

export const getCodeValidateError = (errorCode: any) => {
  switch (errorCode) {
    case 1004:
      return "Too many code requests have been made. Please try again after 10 minutes";
    case 1005:
      return "Please enter valid code";
    case 1006:
      return "The code is no longer valid. Please resend code";
    case 1007:
      return "Please enter valid phone number";
    case 1014:
      return "Current username does not exist";
    case 1012:
      return "New username already exists, Please go back and change the Username";
    default:
      return "Not a valid verification code";
  }
};

export const isEmail = (text: string) => {
  let regex = /^[a-zA-Z0-9+-._@]+$/;
  const indexOfAt = text.indexOf("@");
  const email = indexOfAt === -1 ? text : text.substring(0, indexOfAt);
  if (regex.test(email)) {
    regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(text.toLowerCase());
  }
  return false;
};

export const getValidObj = (val?: string) => {
  const obj: Validation = {
    status: ValidationStatus.VALID,
    message: null,
    updatedVal: val,
  };
  return obj;
};

export const getInvalidObj = (msg: string | null, val?: string) => {
  const obj: Validation = {
    status: ValidationStatus.INVALID,
    message: msg,
    updatedVal: val,
  };
  return obj;
};

export const getUntouchedObj = () => {
  const obj: Validation = {
    status: ValidationStatus.UNTOUCHED,
    message: null,
  };
  return obj;
};

export function debounce<T extends unknown[]>(
  func: (...args: T) => void | Promise<void>,
  delay: number
): (...args: T) => void | Promise<void> {
  let timer: NodeJS.Timeout | null = null;
  return (...args: T) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      func(...args);
    }, delay);
  };
}

export const makeCapitalEachWordInString = (input: string): string => {
  //split the above string into an array of strings
  //whenever a blank space is encountered
  if (input === undefined || input === null) {
    return "";
  }
  if (input.length === 0) {
    return input;
  }
  const arr = input.toLocaleLowerCase().split(" ");
  //loop through each element of the array and capitalize the first letter.
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  //Join all the elements of the array back into a string
  //using a blankspace as a separator
  return arr.join(" ");
};

export const makeCapitalEachOfWordInString = (input: string): string => {
  //split the above string into an array of strings
  //whenever a blank space is encountered
  if (input === undefined || input === null) {
    return "";
  }
  if (input.length === 0) {
    return input;
  }
  const arr = input.toLocaleUpperCase().split(" ");
  //loop through each element of the array and capitalize the first letter.
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  //Join all the elements of the array back into a string
  //using a blankspace as a separator
  return arr.join(" ");
};

export const formatPhoneNumber = (inputNumber: string) => {
  if (inputNumber && inputNumber !== "") {
    let removeChar = inputNumber.replace(/[^a-zA-Z0-9]/g, "");
    return removeChar.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
  }
  return "";
};
export const formatPhoneNumberWithoutSpecialChar = (inputNumber: string) => {
  if (inputNumber && inputNumber !== "") {
    let removeChar = inputNumber.replace(/[^a-zA-Z0-9]/g, "");
    return removeChar.replace(/(\d{3})(\d{3})(\d{4})/, "$1$2$3");
  }
  return "";
};
export const bindManageProfileData = (
  manageProfileResp: any,
  manageProfile: IManageProfile,
  profileValidator: ManageProfileValidator
) => {
  manageProfile.firstName = validateAndBind(
    manageProfileResp.firstName,
    "firstName",
    profileValidator,
    true
  );
  manageProfile.lastName = validateAndBind(
    manageProfileResp.lastName,
    "lastName",
    profileValidator,
    true
  );
  manageProfile.userName = validateAndBind(
    manageProfileResp.userName,
    "userName",
    profileValidator,
    false
  );
  manageProfile.licenseType = validateAndBind(
    manageProfileResp.licenceType,
    "licenseType",
    profileValidator,
    manageProfileResp.departmentName === "Internal" ? false : true,
    manageProfileResp.departmentName === "Internal"
  );
  manageProfile.department = validateAndBind(
    manageProfileResp.departmentName,
    "department",
    profileValidator,
    true
  );
  manageProfile.title = validateAndBind(
    manageProfileResp.title,
    "title",
    profileValidator,
    true,
    manageProfileResp.departmentName === "Internal"
  );
  //Email will alway be valid one from the Response
  manageProfile.email = {
    value: manageProfileResp.emailAddress,
    valid: ValidationStatus.VALID,
    required: true,
  };
  if (
    manageProfileResp?.mobilePhoneNumber !== "" &&
    manageProfileResp?.mobilePhoneNumber !== null
  ) {
    manageProfile.phoneType.value = "mobile";
    manageProfile.phone.value = manageProfileResp?.mobilePhoneNumber;
  } else {
    manageProfile.phoneType.value = "phone";
    manageProfile.phone.value = manageProfileResp?.phoneNumber;
  }
  manageProfile.phone = validateAndBind(
    manageProfile.phone.value,
    "phone",
    profileValidator,
    true
  );
  manageProfile.phoneType = validateAndBind(
    manageProfile.phoneType.value,
    "phoneType",
    profileValidator,
    true
  );
  manageProfile.extension = validateAndBind(
    manageProfileResp.extension,
    "extension",
    profileValidator,
    true
  );
  manageProfile.rentalActivity = validateAndBind(
    manageProfileResp.emailNotifications[1].value === false ? "no" : "yes",
    "rentalActivity",
    profileValidator,
    true
  );
  manageProfile.salesActivity = validateAndBind(
    manageProfileResp.emailNotifications[2].value === false ? "no" : "yes",
    "salesActivity",
    profileValidator,
    true
  );
  manageProfile.pickUpRequest = validateAndBind(
    manageProfileResp.emailNotifications[0].value === false ? "no" : "yes",
    "pickUpRequest",
    profileValidator,
    true
  );
  const defaultValue = {
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
  };
  manageProfile.confirmPassword = defaultValue;
  manageProfile.newPassword = defaultValue;
  manageProfile.verifyEmail = defaultValue;
  return manageProfile;
};

const validateAndBind = (
  itemData: any,
  validatorName: string,
  validator: ManageProfileValidator,
  required: boolean,
  isValidValue?: boolean
) => {
  const isValid = validator.validate(itemData, validatorName);
  let retunValue: IInputField = {
    value: itemData,
    valid: isValidValue
      ? isValidValue
        ? ValidationStatus.VALID
        : ValidationStatus.INVALID
      : isValid?.status === ValidationStatus.VALID
      ? ValidationStatus.VALID
      : ValidationStatus.INVALID,
    required: required,
  };
  return retunValue;
};

export const formatedWoundValue = (woundValue: string) => {
  if (woundValue === ".") {
    return "00.0";
  } else if (woundValue.startsWith(".")) {
    return "00" + woundValue;
  } else if (woundValue.endsWith(".")) {
    return parseFloat(woundValue).toFixed(1);
  } else {
    return woundValue;
  }
};

export const formatYorN = (value: string | any) => {
  return value === null || value.toUpperCase() === "N" ? "No" : "Yes";
};

export const getDigits = (value: string | any) => {
  if (value == null) {
    return "0%";
  } else if (value != null) {
    const output = value.match(/\d/g)?.join("").replace(/^0+/, "") ?? "0";
    return output.length > 0 ? `${output}%` : "0%";
  }
};

export const isStringNullOrEmpty = (str: string) => {
  return str === "" || str === null;
};

function getDefaultSorting(defaultTableData: Array<any>, columns: any) {
  const sorted = [...defaultTableData].sort((a, b) => {
    const filterColumn = columns.filter((column: any) => column.sortbyOrder);

    let { accessor = "id", sortbyOrder = "asc" } = Object.assign(
      {},
      ...filterColumn
    );

    if (a[accessor] === null) return 1;
    if (b[accessor] === null) return -1;
    if (a[accessor] === null && b[accessor] === null) return 0;

    const ascending = a[accessor]
      .toString()
      .localeCompare(b[accessor].toString(), "en", {
        numeric: true,
      });

    return sortbyOrder === "asc" ? ascending : -ascending;
  });
  return sorted;
}

export const useSortableTable = (data: Array<any>, columns: any) => {
  const [tableData, setTableData] = useState<any>(
    getDefaultSorting(data, columns)
  );

  const handleSorting = (sortField: any, sortOrder: any) => {
    if (sortField) {
      const sorted = [...tableData].sort((a, b) => {
        if (a[sortField] === null) return 1;
        if (b[sortField] === null) return -1;
        if (a[sortField] === null && b[sortField] === null) return 0;
        return (
          a[sortField].toString().localeCompare(b[sortField].toString(), "en", {
            numeric: true,
          }) * (sortOrder === "asc" ? 1 : -1)
        );
      });
      setTableData(sorted);
    }
  };

  return [tableData, setTableData, handleSorting];
};

export const convertToMmmDDFormat = (dateValue: Date): string => {
  let day = dateValue.getDate();
  let monthName = dateValue.toLocaleString("en-US", {
    month: "short",
  });

  return `${monthName} ${day}`;
};

export const convertStringToDate = (dateValue: string | null | undefined) => {
  if (dateValue === "" || dateValue === null || dateValue === "Invalid date") {
    return "";
  } else return moment(dateValue).format("MM/DD/YYYY");
};

export const getTextFromCode: any = (
  array: never[] | any[],
  code: string
): string => {
  if (array.length === 0 || code === "") {
    return code;
  }
  if (Array.isArray(array)) {
    let output = array
      .filter((item: { text: string; code: string }) => item.code === code)
      .map((x: { text: string }) => x.text)[0];
    return output ? output : code;
  }
  return code;
};

export const getCodeFromText = (
  array: never[] | any[],
  input: string
): string => {
  if (array.length === 0) {
    return "";
  }
  if (Array.isArray(array)) {
    return array
      .filter((item: { text: string; code: string }) => item.text === input)
      .map((x: { code: string }) => x.code)[0];
  }
  return input;
};

export const getTextFromCodeWhenTextMayEmpty: any = (
  array: never[],
  code: string
): string => {
  if (array.length === 0 || code === "") {
    return code;
  }
  if (Array.isArray(array)) {
    const output = array
      .filter((item: { text: string; code: string }) => item.code === code)
      .map((x: { text: string }) => x.text)[0];
    return output ? output : code;
  }
  return code;
};

export const getCodeFromTextWhenTextMayEmpty = (
  array: never[],
  text: string
): string => {
  if (array.length === 0 || text === "") {
    return "";
  }
  if (Array.isArray(array)) {
    const output = array
      .filter((item: { text: string; code: string }) => item.text === text)
      .map((x: { code: string }) => x.code)[0];
    return output ? output : text;
  }
  return text;
};

export const formatNumber = (inputNumber: any) => {
  if (inputNumber) {
    let removeChar = inputNumber.replace(/[^a-zA-Z0-9]/g, "");
    return removeChar.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
  }
  return "";
};

export const formatNumberBrackets = (
  inputNumber: string | null | undefined
) => {
  if (inputNumber && inputNumber !== "") {
    let removeChar = inputNumber.replace(/[^a-zA-Z0-9]/g, "");
    return removeChar.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  }
  return "";
};

export const formatNumberSalesManager = (inputNumber: any) => {
  if (inputNumber) {
    let removeChar = inputNumber?.replace(/[^a-zA-Z0-9]/g, "").slice(-10);
    return removeChar?.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
  }
  return "--";
};

export const getFcilityAddress = (item: IFacility) => {
  if (item.address2) {
    return (
      makeCapitalEachWordInString(item.accountName) +
      ", " +
      makeCapitalEachWordInString(item.address1) +
      ", " +
      makeCapitalEachWordInString(item.address2) +
      ", " +
      makeCapitalEachWordInString(item.city) +
      ", " +
      item.state +
      ", " +
      item.zip
    );
  } else {
    return (
      makeCapitalEachWordInString(item.accountName) +
      ", " +
      makeCapitalEachWordInString(item.address1) +
      ", " +
      makeCapitalEachWordInString(item.city) +
      ", " +
      item.state +
      ", " +
      item.zip
    );
  }
};

export const getMobileFacilityAddress = (item: IFacility) => {
  if (item.address2) {
    return (
      makeCapitalEachWordInString(item.accountName) +
      " (#" +
      item.accountNumber +
      ")" +
      ", " +
      makeCapitalEachWordInString(item.address1) +
      ", " +
      makeCapitalEachWordInString(item.address2) +
      ", " +
      makeCapitalEachWordInString(item.city) +
      ", " +
      item.state +
      ", " +
      item.zip
    );
  } else {
    return (
      makeCapitalEachWordInString(item.accountName) +
      " (#" +
      item.accountNumber +
      ")" +
      ", " +
      makeCapitalEachWordInString(item.address1) +
      ", " +
      makeCapitalEachWordInString(item.city) +
      ", " +
      item.state +
      ", " +
      item.zip
    );
  }
};

export const getLinkedfacilityAddressData = (
  userLinkedFacilityData: IFacility[],
  isSmallMobile: boolean
): any => {
  let data = [];
  data = userLinkedFacilityData
    .sort((a, b) => a.accountName.localeCompare(b.accountName))
    .map((item) =>
      isSmallMobile ? getMobileFacilityAddress(item) : getFcilityAddress(item)
    );
  return data;
};

export const handleEmptyValue = (value: string) => {
  return value === "" || value === null ? "-" : value;
};

export const getPdfUrl = (base64String: any) => {
  const byteCharacters = atob(base64String);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: "application/pdf" });
  const url = URL.createObjectURL(blob);
  return url;
};

export const getPdfUrlGif = async (base64String: any) => {
  const byteCharacters = atob(base64String);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: "image/gif" });
  const url = URL.createObjectURL(blob);
  return url;
};

export const getCSV = async (base64String: any, fileName: string) => {
  const byteCharacters = atob(base64String);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: "text/csv;charset=utf-8" });
  const url = URL.createObjectURL(blob);
  var downloadLink = document.createElement("a");
  downloadLink.href = url;
  downloadLink.download =
    fileName +
    moment(new Date().toJSON().slice(0, 10)).format("MM-DD-YYYY") +
    "_Export.csv";
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export const getDocumentUrl = async (fileType: string, base64String: any) => {
  const byteCharacters = atob(base64String);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: getBlobType(fileType) });
  const url = URL.createObjectURL(blob);
  return url;
};

export const getBlobType = (fileType: string): string => {
  switch (fileType.toLowerCase()) {
    case "png":
    case "jpeg":
    case "jpg":
    case "tiff":
      return `image/${fileType.toLowerCase()}`;
    case "gif":
      return "image/jpg";
    case "csv":
      return "text/csv";
    default:
      return "";
  }
};

export const formatDateWithNull = (value: string | null | undefined) => {
  if (value === null || value === undefined) {
    return "-";
  } else {
    return value !== "" ? moment(value).format("L") : "-";
  }
};

export const formatDate = (value: string) => {
  return value !== "" ? moment(value).format("L") : "";
};

export const formatYesOrNo = (value: string) => {
  if (value === "") return "--";
  else return value.toLowerCase() === "yes" ? "Yes" : "No";
};

export const setActiveValue = (value: string) => {
  if (value.toLowerCase() === "yes") {
    return true;
  } else if (value.toLowerCase() === "no") {
    return false;
  } else {
    return null;
  }
};

export const getFacilityPermissionCategoryName = (category: string): string => {
  switch (category) {
    case "GENERAL":
      return "General";
    case "INVENTORY":
      return "Inventory";
    case "ORDER_PLACEMENT":
      return "Order Placement";
    default:
      return "";
  }
};

export const getFacilityPermissionName = (permissionName: string): string => {
  switch (permissionName) {
    case "3M_REP_ACCESS":
      return "View Solventum Reps";
    case "RENTAL_ORDERS":
      return "Home Transition Orders";
    case "SALES_ORDERS":
      return "Home Supply Orders";
    case "IN_PATIENT_ORDERS":
      return "In-Patient Orders";
    case "IN_PATIENT_SUPPLY_ORDERS":
      return "In-Patient Supply Orders";
    case "IN_PATIENT_STOCK_SUPPLY_ORDERS":
      return "In-Patient Stock Supply Orders";
    case "WOUND_MEASUREMENTS":
      return "Wound Measurements";
    case "ALL_FACILITY_PATIENTS":
      return "All Facility Patients";
    case "INVENTORY":
      return "Inventory";
    case "INVENTORY_READY_CARE":
      return "V.A.C.® Ready Care";
    case "INVENTORY_CUSTOMER_PLUS":
      return "Customer Plus";
    case "INVENTORY_STORED_PRODUCT":
      return "Stored Products";
    case "MFA":
      return "Multi Factor Authentication (MFA)";
    default:
      return "";
  }
};

export interface IAnalyticsData {
  page_type: string;
  view_name: string;
  event_type: string;
  event_name: string;
  tealium_event: string;
  mmmex_userrecordid: string;
  mmmex_facilityid: string;
  mmmex_pagename: string;
  mmmex_roleid: string;
}

export const sendAnalyticsData = (data: IAnalyticsData) => {
  //To do adobe analytics here
  let Data = {
    page_type: data.page_type,
    view_name: data.view_name,
    event_type: data.event_type,
    event_name: data.event_name,
    tealium_event: data.tealium_event,
    mmmex_userrecordid: data.mmmex_userrecordid,
    mmmex_facilityid: data.mmmex_facilityid,
    mmmex_pagename: data.mmmex_pagename,
    mmmex_roleid: data.mmmex_roleid,
  };
};

export const getFileExtension = (fileName: string): string => {
  if (fileName) {
    const extension = fileName.split(".").pop();
    return extension?.toUpperCase()!;
  }
  return "";
};

export const formatISODate = (value: string, valid: string) => {
  if (
    value === "" ||
    value === "--" ||
    value === null ||
    value === "Invalid date" ||
    valid !== ValidationStatus.VALID
  ) {
    return null;
  } else {
    let datestring = null;
    if (value && value.includes("T")) {
      return value;
    } else {
      datestring = value + " 00:00 UTC";
    }
    const event = new Date(datestring);
    return event.toISOString();
  }
};

export const inPatientformatISODate = (value: string, valid: string) => {
  if (
    value === "" ||
    value === "--" ||
    value === null ||
    value === "Invalid date" ||
    valid !== ValidationStatus.VALID
  ) {
    return null;
  } else {
    let datestring = null;
    if (value && value.includes("T")) {
      return value;
    } else {
      datestring = moment.utc(value).toISOString();
    }
    return datestring;
  }
};

export const changeDateFormat = (dateValue: string | null | undefined) => {
  return moment(dateValue).format("YYYY-MM-DDTHH:mm:ss");
};

export const changeDateFormatOnlyDate = (
  dateValue: string | null | undefined
) => {
  return moment(dateValue).format("YYYY-MM-DDT00:00:00");
};

export const updateSuperScriptContent = (
  name: string,
  isMenuOption: boolean = false
) => {
  if (name && name.includes("®")) {
    if (isMenuOption) {
      return name.replace("<sup>®</sup>", "®");
    }
    return name.replace("®", "<sup>®</sup>");
  }
  if (name === null) {
    return "";
  }
  return name;
};

export const maskProductInUse = (unitInUse: string | null) => {
  if (unitInUse && unitInUse.length > 0) {
    if (unitInUse.toUpperCase().includes("FREEDOM"))
      return "V.A.C. FREEDOM™ Therapy Unit";
    else return "3M™ ActiV.A.C.™ Therapy Unit";
  } else return "";
};

export const determineReadyCareFlag = (flag: string | null) => {
  if (flag === "Y") return "Yes";
  else {
    return "No";
  }
};

const zeroOrOneCheckForPhnNumber = (inputField: IInputField) => {
  let formattedPhnNumber = formatPhoneNumberWithoutSpecialChar(
    inputField.value
  );
  if (formatPhoneNumber.length === 0) {
    return `${inputField.title} is required`;
  } else if (formattedPhnNumber.length < 10) {
    return `${inputField.title} - Remove invalid/incomplete data`;
  } else {
    return `${inputField.title} - Remove invalid/incomplete data. Phone number must begin with value other than 0 or 1`;
  }
};

export const updateErrorMessage = (
  inputField: IInputField,
  isForceCheck: boolean = false
) => {
  if (inputField.valid === ValidationStatus.INVALID) {
    if (inputField.required || inputField.minimumRequired || isForceCheck) {
      if (
        (inputField.value &&
          inputField.value !== "" &&
          inputField.value !== "(___) ___-____") ||
        inputField.value === "Invalid date"
      ) {
        if (inputField.isPhoneNumber) {
          inputField.errorMessage = zeroOrOneCheckForPhnNumber(inputField);
        } else {
          inputField.errorMessage = `${inputField.title} - Remove invalid/incomplete data`;
        }
      } else {
        inputField.errorMessage = `${inputField.title} is required`;
      }
    } else {
      if (
        (inputField.value &&
          inputField.value !== "" &&
          inputField.value !== "(___) ___-____") ||
        inputField.value === "Invalid date"
      ) {
        if (inputField.isPhoneNumber) {
          inputField.errorMessage = zeroOrOneCheckForPhnNumber(inputField);
        } else
          inputField.errorMessage = `${inputField.title} - Remove invalid/incomplete data`;
      }
    }
  }
};

export function generateUUID() {
  const length = 5;
  let result = "";
  const characters = "0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function arrayBufferToBase64(buffer: ArrayBuffer): string {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

export const agGridGetRowStyle = (params: any) => {
  if (params.node.rowIndex % 2 === 0) {
    return { background: "#f8f8fa" };
  }
};

export const statusColor = (status: any) => {
  switch (status) {
    case "Submitted":
      return "orangeCircle";
    case "Saved":
      return "yellowCircle";
    case "In Process":
      return "greenCircle";
    case "Shipped":
      return "greenCircle";
    case "Placement Approved":
      return "greenCircle";
    case "Delivered":
      return "greenCircle";
    case "Therapy":
      return "blueCircle";
    case "Pickup Pending":
      return "greyCircle";
    case "Closed":
      return "greyCircle";
    case "Order Received":
      return "greenCircle";
    case "Hold":
      return "orangeCircle";
    case "Resumed":
      return "greenCircle";
    default:
      return "greenCircle";
  }
};

export const getSiteUseId = (authObj: AuthContextType | null): string => {
  return authObj &&
    authObj.registeredFaciltyAddress &&
    authObj.registeredFaciltyAddress.siteUseId &&
    authObj.registeredFaciltyAddress.siteUseId !== ""
    ? authObj.registeredFaciltyAddress.siteUseId
    : authObj &&
      authObj.userProfile &&
      authObj.userProfile.facilities &&
      authObj.userProfile.facilities.length > 0 &&
      authObj.userProfile.facilities[0].siteUseId &&
      authObj.userProfile.facilities[0].siteUseId !== ""
    ? authObj.userProfile.facilities[0].siteUseId
    : "";
};

export const getSiteUseIdFromNewOrderContext = (
  newOrderObj: NewOrderContextType | null
): string => {
  return newOrderObj &&
    newOrderObj.newOrderData &&
    newOrderObj.newOrderData.requestingFacility &&
    newOrderObj.newOrderData.requestingFacility.value &&
    newOrderObj.newOrderData.requestingFacility.value.siteUseId
    ? newOrderObj.newOrderData.requestingFacility.value.siteUseId
    : "";
};

export const getCareGiverId = (authObj: AuthContextType | null): string => {
  return authObj &&
    authObj.registeredFaciltyAddress &&
    authObj.registeredFaciltyAddress.careGiverId &&
    authObj.registeredFaciltyAddress.careGiverId !== ""
    ? authObj.registeredFaciltyAddress.careGiverId
    : authObj &&
      authObj.userProfile &&
      authObj.userProfile.facilities &&
      authObj.userProfile.facilities.length > 0 &&
      authObj.userProfile.facilities[0].careGiverId &&
      authObj.userProfile.facilities[0].careGiverId !== ""
    ? authObj.userProfile.facilities[0].careGiverId
    : "";
};
export const getcaregiverSiteUseId = (
  authObj: AuthContextType | null
): string => {
  return authObj &&
    authObj.registeredFaciltyAddress &&
    authObj.registeredFaciltyAddress.careGiverSiteUseId &&
    authObj.registeredFaciltyAddress.careGiverSiteUseId !== ""
    ? authObj.registeredFaciltyAddress.careGiverSiteUseId
    : "";
};

export enum SpecialProduct {
  PEELANDPLACE = 1,
  WHITEFOAM = 2,
}

export const checkSelectedVacProdcutForType = (
  product: ProductInformation,
  specialProduct: SpecialProduct
): boolean => {
  let checkingSkus: string[] = [];
  switch (specialProduct) {
    case SpecialProduct.PEELANDPLACE:
      checkingSkus = ["EZ5SML", "EZ5MED", "EZ5LRG"];
      break;
    case SpecialProduct.WHITEFOAM:
      checkingSkus = ["M6275033/10", "M6275034/10", "M8275068/5", "M8275067/5"];
      break;
    default:
      break;
  }
  if (product.hasMultipleSize) {
    const skus = product.sizes.map((size: any) => size.sku);
    if (
      skus.length > 0 &&
      checkingSkus.some((sku: any) => skus.includes(sku))
    ) {
      return true;
    }
  } else if (product.sku) {
    return checkingSkus.includes(product.sku);
  }
  return false;
};

export const checkSelectedProdcutForType = (
  allProducts: ProductInformation[],
  specialProduct: SpecialProduct,
  selectedProductName: string
) => {
  const selectedVacs = allProducts.filter(
    (product: ProductInformation) => product.productName === selectedProductName
  );
  if (selectedVacs.length > 0) {
    return checkSelectedVacProdcutForType(selectedVacs[0], specialProduct);
  } else {
    return false;
  }
};

export const getVacProductsWithout = (
  allProducts: ProductInformation[],
  productType: string,
  specialProduct: SpecialProduct
) => {
  let products: any[] = [];
  allProducts.forEach((product: ProductInformation) => {
    if (
      product.productType === productType &&
      !checkSelectedVacProdcutForType(product, specialProduct)
    ) {
      products.push({
        id: product.productID,
        code: product.sku,
        text: product.productName,
      });
    }
  });
  return products;
};

export const handleListHeight = (checkSize: boolean) => {
  const { innerWidth, innerHeight } = window;
  return (
    innerWidth >= 320 && innerWidth <= 1366 && innerHeight > 800 && checkSize
  );
};

export function checkPopUpBlocker(windowObject?: any) {
  let isBlocked: boolean;
  if (
    !windowObject ||
    windowObject.closed ||
    typeof windowObject.closed === undefined
  ) {
    isBlocked = true;
  } else isBlocked = false;
  return isBlocked;
}

export function locationNameValidation(txt: string): Validation {
  if (txt.trim().length === 0 && Array.from(txt)[0] === "") {
    return getInvalidObj(null);
  }
  const regex = /^(?!\s)[a-zA-Z0-9\s.-]{0,39}$/;
  return regex.test(txt) ? getValidObj() : getInvalidObj(null);
}
export function getValuePostUPSValidation(
  validator: NewOrderValidator | SupplyOrderValidator,
  value: string,
  fieldName: string
) {
  const isValid = validator.validate(value, fieldName);
  if (isValid!.updatedVal) return isValid!.updatedVal.trim();
  else return value;
}

export const removeSpecialCharacters = (input: string | null): string => {
  if (input && input.length > 0) {
    const pattern = /[^a-zA-Z0-9\s-]/g;
    return input.replace(pattern, "");
  }
  return "";
};

export const formattedValidatedUPSAddresses = async (data: any) => {
  const addresses: IValidateUPSAddress[] = data.item.addresses;
  const updatedAddress = addresses.map((address: IValidateUPSAddress) => ({
    ...address,
    addressLine1: removeSpecialCharacters(address.addressLine1),
    addressLine2: address.addressLine2
      ? removeSpecialCharacters(address.addressLine2)
      : null,
  }));
  const result: IValidateUPSAddressResponse = {
    item: {
      addresses: updatedAddress,
      status: data.item.status,
    },
    succeeded: true,
  };
  return result;
};

export const formattedPatientAddresses = async (data: any) => {
  const currentAddress: IValidateUPSAddress = data.item.currentAddress;
  const updatedCurrentAddress = {
    ...currentAddress,
    addressLine1: removeSpecialCharacters(currentAddress.addressLine1),
    addressLine2: currentAddress.addressLine2
      ? removeSpecialCharacters(currentAddress.addressLine2)
      : null,
  };
  const permanentAddress: IValidateUPSAddress = data.item.permanentAddress;
  const updatedPermanentAddress = {
    ...permanentAddress,
    addressLine1: removeSpecialCharacters(permanentAddress.addressLine1),
    addressLine2: permanentAddress.addressLine2
      ? removeSpecialCharacters(permanentAddress.addressLine2)
      : null,
  };
  const result: IPatientAddressResponse = {
    item: {
      currentAddress: updatedCurrentAddress,
      permanentAddress: updatedPermanentAddress,
    },
    succeeded: true,
  };
  return result;
};

export const showVacOrderMenuOption = (
  rolesPermissionData: IPermissionData
) => {
  const showNewOrderButton: boolean =
    rolesPermissionData.IsShowStartNewOrder ||
    rolesPermissionData.IsShowSupplyOrderButton ||
    rolesPermissionData.IsShowVacOrderButton ||
    rolesPermissionData.IsShowInPatientOrderButton ||
    rolesPermissionData.IsShowInPatientStockSupplyOrderButton ||
    rolesPermissionData.IsShowInPatientSupplyOrderButton
      ? true
      : false;
  return showNewOrderButton;
};

export const updatePermissionBasedOnAcuteFacility = (
  isPostAcuteFacility: boolean,
  permissionName: string,
  permissionStatus: string = ""
): boolean => {
  if (permissionStatus === "Hidden") {
    return false;
  } else if (!isPostAcuteFacility) {
    return true;
  } else {
    if (
      permissionName === "IN_PATIENT_ORDERS" ||
      permissionName === "IN_PATIENT_SUPPLY_ORDERS" ||
      permissionName === "IN_PATIENT_STOCK_SUPPLY_ORDERS"
    ) {
      return false;
    }
    return true;
  }
};

export const getAcuteMenuActionPermission = (
  menuActionType: string,
  permissionsObj: IPermissionData
): boolean => {
  let isMenuAcutePermission: boolean = false;
  switch (menuActionType.toLowerCase()) {
    case AcutePatientMenuActionTypes.CREATE_TRANSISTION_ORDER:
      if (permissionsObj.IsShowVacOrderButton) {
        isMenuAcutePermission = true;
      }
      break;
    case AcutePatientMenuActionTypes.END_THERAPHY:
    case AcutePatientMenuActionTypes.PLACE_ON_PATIENT:
      if (permissionsObj.IsShowInPatientOrderButton) {
        isMenuAcutePermission = true;
      }
      break;
    default:
      isMenuAcutePermission = true;
      break;
  }
  return isMenuAcutePermission;
};

export const getDevicePlacementType = (orderType: string) => {
  if (orderType === "3" || orderType === "4") {
    return PlacementTypeSelection.PLACE_FROM_STORAGE;
  } else {
    return PlacementTypeSelection.PLACE_ORDER_WITH_SOLVENTUM;
  }
};

export const getAddress = (billingAddress: IBillingAddress): string => {
  if (billingAddress.zip === "NA") {
    return "--";
  } else {
    let address = `${
      billingAddress.addressLine1 && billingAddress.addressLine1 !== ""
        ? `${billingAddress.addressLine1}`
        : ""
    }`;
    address = `${address !== "" ? `${address}, ` : ""}${
      billingAddress.addressLine2 && billingAddress.addressLine2 !== ""
        ? `${billingAddress.addressLine2}`
        : ""
    }`;
    address = `${
      address !== "" ? `${address}${address.endsWith(", ") ? "" : ", "}` : ""
    }${
      billingAddress.addressLine3 && billingAddress.addressLine3 !== ""
        ? `${billingAddress.addressLine3}`
        : ""
    }`;
    address = `${
      address !== "" ? `${address}${address.endsWith(", ") ? "" : ", "}` : ""
    }${
      billingAddress.addressLine4 && billingAddress.addressLine4 !== ""
        ? `${billingAddress.addressLine4},`
        : ""
    }`;
    address = `${makeCapitalEachWordInString(
      address
    )} ${makeCapitalEachWordInString(billingAddress.city!)}, ${
      billingAddress.state
    } ${billingAddress.zip}`;
    return address;
  }
};

export const deliveryPrefernceRoundToNearestMinutes = (input: Date) => {
  if (input.getMinutes() >= 0 && input.getMinutes() <= 15) {
    return moment(input).add(-input.getMinutes(), "m");
  }
  if (input.getMinutes() >= 16 && input.getMinutes() <= 44) {
    if (input.getMinutes() < 30) {
      return moment(input).add(30 - input.getMinutes(), "m");
    } else if (input.getMinutes() > 30) {
      return moment(input).add(30 - input.getMinutes(), "m");
    } else {
      return input;
    }
  }
  if (input.getMinutes() >= 45 && input.getMinutes() <= 59) {
    return moment(input).add(60 - input.getMinutes(), "m");
  }
  return input;
};

export const splitDateAndTime = (
  dateAndTime: string
): { date: string; time: string } => {
  const dateTimeValue = new Date(dateAndTime);
  const date = moment(dateTimeValue).format("MM/DD/YYYY");
  const time = moment(dateTimeValue).format("h:mm A");
  return { date, time };
};

export const formatDateOfBirth = (dateOfBirth: string | null): string => {
  return dateOfBirth === null
    ? "DOB --"
    : `DOB ${moment(dateOfBirth).format("MM/DD/YYYY")}`;
};

export function validateInputField(
  input: IInputField,
  smallestOrderWithInvalidStatus: IInputField | null
) {
  if (input.isOptional === true && input.valid !== ValidationStatus.VALID) {
    input.valid = ValidationStatus.VALID;
  } else if (input.required && input.valid === ValidationStatus.UNTOUCHED) {
    input.valid = ValidationStatus.INVALID;
  } else if (input.isDefaultValid && input.valid !== ValidationStatus.VALID) {
    input.valid = ValidationStatus.INVALID;
  }
  if (input.valid === ValidationStatus.INVALID && input.order) {
    if (
      !smallestOrderWithInvalidStatus ||
      (smallestOrderWithInvalidStatus.order &&
        input.order &&
        smallestOrderWithInvalidStatus.order > input.order)
    ) {
      smallestOrderWithInvalidStatus = input;
    }
  }
  updateErrorMessage(input);
  return smallestOrderWithInvalidStatus;
}

export const parsedTextValue = (text: string) => {
  if (text && text.length !== 0) {
    return parse(text);
  } else {
    return "";
  }
};
