import { createContext, useState } from "react";
import { getDeepClone } from "../util/ObjectFunctions";
import { ICreateServiceRequest } from "../components/inventory/requestService/createServiceRequest.interface";
import { defaultCreateServiceRequestData } from "../components/inventory/requestService/createServiceRequest.model";
import { CreateServiceRequestPageSection } from "../components/inventory/requestService/createServiceRequest.enum";
import { IDropdownValue } from "../components/inventory/requestService/createServiceRequest/serviceOptions/serviceOptions.component";

export type CreateServiceRequestContextType = {
  availableOptions: IDropdownValue[];
  data: ICreateServiceRequest;
  page: CreateServiceRequestPageSection;
  resetContext: () => void;
  setAvailableOptions: React.Dispatch<React.SetStateAction<IDropdownValue[]>>;
  setData: React.Dispatch<React.SetStateAction<ICreateServiceRequest>>;
  setPage: React.Dispatch<
    React.SetStateAction<CreateServiceRequestPageSection>
  >;
  workOrderNumber: string;
  setWorkOrderNumber: React.Dispatch<React.SetStateAction<string>>;
  initialLoadTriggerd: boolean;
  setInitialLoadTriggerd: React.Dispatch<React.SetStateAction<boolean>>;
  storageLocations: string[];
  setStorageLocations: React.Dispatch<React.SetStateAction<string[]>>;
  ropnValue: string;
  setRopnValue: React.Dispatch<React.SetStateAction<string>>;
};

type CreateServiceRequestContextProviderProps = {
  children: React.ReactNode;
};

export const CreateServiceRequestContext =
  createContext<CreateServiceRequestContextType | null>(null);

export const CreateServiceRequestContextProvider = ({
  children,
}: CreateServiceRequestContextProviderProps) => {
  const [availableOptions, setAvailableOptions] = useState<IDropdownValue[]>(
    []
  );
  const [data, setData] = useState<ICreateServiceRequest>(
    getDeepClone(defaultCreateServiceRequestData)
  );
  const [page, setPage] = useState<CreateServiceRequestPageSection>(
    CreateServiceRequestPageSection.CREATE_SERVICE_REQUEST
  );
  const [workOrderNumber, setWorkOrderNumber] = useState<string>("");
  const [initialLoadTriggerd, setInitialLoadTriggerd] =
    useState<boolean>(false);
  const [storageLocations, setStorageLocations] = useState<string[]>([]);
  const [ropnValue, setRopnValue] = useState<string>("");

  const resetContext = () => {
    setAvailableOptions([]);
    setData(getDeepClone(defaultCreateServiceRequestData));
    setPage(CreateServiceRequestPageSection.CREATE_SERVICE_REQUEST);
    setWorkOrderNumber("");
    setInitialLoadTriggerd(false);
  };

  return (
    <CreateServiceRequestContext.Provider
      value={{
        availableOptions,
        data,
        page,
        setAvailableOptions,
        setData,
        setPage,
        resetContext,
        workOrderNumber,
        setWorkOrderNumber,
        initialLoadTriggerd,
        setInitialLoadTriggerd,
        storageLocations,
        setStorageLocations,
        ropnValue,
        setRopnValue,
      }}
    >
      {children}
    </CreateServiceRequestContext.Provider>
  );
};
