import moment from "moment";
import "./patientDetails.css";
import { useContext } from "react";
import { Grid } from "@mui/material";
import { useHistory } from "react-router-dom";
import {
  DischargeRequestContext,
  DischargeRequestContextType,
} from "../../../../context/DischargeRequestContext";
import { IPatientDetails } from "./patientDetails.interface";
import {
  makeCapitalEachWordInString,
  maskProductInUse,
} from "../../../../util/utilityFunctions";
import parse from "html-react-parser";

export const PatientDetails = ({
  patient,
  isConfirmPlacementFlow = false,
  excangeVacFlow = false,
  isAcuteOrderPickUpDischargeFlow = false,
  isAcuteOrderFlow = false,
  isSendNoteFlow = false,
}: IPatientDetails) => {
  const history = useHistory();
  const dischargeReqObj = useContext<DischargeRequestContextType | null>(
    DischargeRequestContext
  );

  const openOrderDetail = () => {
    dischargeReqObj?.setIsPreviousClicked(true);
    if (patient) {
      history.push({
        pathname: "/home/orderOverview",
        state: {
          stateData: patient,
        },
      });
    }
  };

  const renderProductName = (): string => {
    let productName: string = "";
    if (patient && (patient.productName || patient.productNameDescription)) {
      if (isAcuteOrderFlow && !isSendNoteFlow) {
        productName = patient.productNameDescription ?? "";
      } else if (isSendNoteFlow) {
        if (isAcuteOrderFlow) {
          productName = patient.productName ?? "";
        } else {
          productName = maskProductInUse(patient.productName ?? "");
        }
      } else {
        productName = maskProductInUse(patient.productName ?? "");
      }
    } else {
      productName = "";
    }
    return productName;
  };

  return (
    <>
      <div
        className={`patient-details-component ${
          isConfirmPlacementFlow
            ? "patient-details-component-confrmplac-height-adjustment"
            : " "
        }`}
        data-testid="patient-details-component"
      >
        <Grid
          container
          spacing={2}
          classes={{ root: "patient-details-grid-container" }}
        >
          <Grid item xs={12}>
            <h4 className="patient-name" data-testid="patient-name">
              {makeCapitalEachWordInString(patient.firstName) +
                " " +
                makeCapitalEachWordInString(patient.lastName)}
            </h4>
          </Grid>
          <Grid
            item
            xs={isAcuteOrderPickUpDischargeFlow ? 7 : 6}
            className="patient-details-grid-item"
          >
            <div className="date-of-birth" data-testid="date-of-birth">
              <h5
                className="patient-details-title"
                data-testid="patient-details-title-dob"
              >
                Date of Birth
              </h5>
              <h5
                className="patient-details-value"
                data-testid="patient-details-value-dob"
              >
                {patient.dob ? moment(patient.dob).format("MM/DD/YYYY") : "--"}
              </h5>
            </div>
          </Grid>
          <Grid
            item
            xs={isAcuteOrderPickUpDischargeFlow ? 5 : 6}
            className="patient-details-grid-item"
          >
            <div className="rental-order" data-testid="rental-order">
              <h5
                className="patient-details-title"
                data-testid="patient-details-title-ro-number"
              >
                Rental Order #
              </h5>
              <h5
                className="patient-ro-value"
                data-testid="patient-details-value-ro-number"
                onClick={openOrderDetail}
              >
                {patient.roNumber.toString()}
              </h5>
            </div>
          </Grid>
          {patient.productName && (
            <Grid
              item
              xs={isAcuteOrderPickUpDischargeFlow ? 7 : 6}
              className="patient-details-grid-item"
            >
              <div className="product-name" data-testid="product-name">
                <h5
                  className="patient-details-title"
                  data-testid="patient-details-title-product-name"
                >
                  Product
                </h5>
                <h5
                  className="patient-details-value"
                  data-testid="patient-details-value-product-name"
                >
                  {parse(renderProductName())}
                  {patient.productSerialNumber &&
                  patient.productSerialNumber !== ""
                    ? ` - ${patient.productSerialNumber}`
                    : ""}
                </h5>
              </div>
            </Grid>
          )}
          {(patient.placementDate || isAcuteOrderPickUpDischargeFlow) && (
            <Grid
              item
              xs={isAcuteOrderPickUpDischargeFlow ? 5 : 6}
              className="patient-details-grid-item"
            >
              <div
                className="therapy-start-date"
                data-testid="therapy-start-date"
              >
                <h5
                  className="patient-details-title"
                  data-testid="patient-details-title-therapy-start-date"
                >
                  {excangeVacFlow || isAcuteOrderPickUpDischargeFlow
                    ? "Placement Date"
                    : "Therapy Start Date"}
                </h5>
                <h5
                  className="patient-details-value"
                  data-testid="patient-details-value-therapy-start-date"
                >
                  {isAcuteOrderPickUpDischargeFlow
                    ? patient.orderCreationDate
                      ? moment(patient.orderCreationDate).format("MM/DD/YYYY")
                      : null
                    : patient.placementDate
                    ? moment(patient.placementDate).format("MM/DD/YYYY")
                    : null}
                </h5>
              </div>
            </Grid>
          )}
          {patient.isAcuteOrder && isAcuteOrderPickUpDischargeFlow && (
            <Grid
              item
              xs={isAcuteOrderPickUpDischargeFlow ? 7 : 6}
              className="patient-details-grid-item"
            >
              <div className="patient-location" data-testid="patient-location">
                <h5
                  className="patient-details-title"
                  data-testid="patient-details-title-location"
                >
                  Location
                </h5>
                <h5
                  className="patient-details-value"
                  data-testid="patient-details-value-location"
                >
                  {patient.patientLocation ? patient.patientLocation : "--"}
                </h5>
              </div>
            </Grid>
          )}
        </Grid>
      </div>
    </>
  );
};
