import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { ReactNode, useContext, useEffect, useState } from "react";
import { ReactComponent as WarningIcon } from "../../../assets/WarningIndicator.svg";
import { ReactComponent as RadioButtonIcon } from "../../../assets/radioButton.svg";
import { ReactComponent as SearchIcon } from "../../../assets/searchPayer.svg";
import { ReactComponent as SelectedRadioButtonIcon } from "../../../assets/selectedRadioButton.svg";
import { AuthContext, AuthContextType } from "../../../context/AuthContext";
import {
  InternalSignOnContext,
  InternalSignOnContextType,
} from "../../../context/InternalSignOnContext";
import { MobileDisplayContext } from "../../../context/MobileDisplayContext";
import {
  NewOrderContext,
  NewOrderContextType,
} from "../../../context/NewOrderContext";
import { CaregiverFacilityFoundList } from "../../../core/caregiverSearchAndAdd/caregiverFacilityFoundList.component";
import { CaregiverFacilityNotFound } from "../../../core/caregiverSearchAndAdd/caregiverFacilityNotFound.component";
import { CaregiverFacilitySearch } from "../../../core/caregiverSearchAndAdd/caregiverFacilitySearch.component";
import {
  ICaregiverFacility,
  ICaregiverFacilitySearchRequest,
} from "../../../core/caregiverSearchAndAdd/caregiverFacilitySearchAndAdd.model";
import { defaultFacilityData } from "../../../core/caregiverSearchAndAdd/caregiverFacilitySearchDefaultData";
import { InputWithLabel } from "../../../core/inputWithLabel/inputWithLabel.component";
import { ValidationStatus } from "../../../core/interfaces/input.interface";
import { LoadingSpinner } from "../../../core/loader/LoadingSpinner";
import { Popup } from "../../../core/popup/popup.component";
import {
  caregiverFacilitySearch,
  getHomeCareProviderFacilityList,
} from "../../../util/3meService";
import { getDeepClone } from "../../../util/ObjectFunctions";
import {
  ERROR_TO_SEARCH_AND_SELECT_HOME_CARE_PROVIDER,
  ERROR_TO_SELECT_ANY_OPTION,
  HOME_CARE_PROVIDER_SEARCH_MS_SERVICE_ERROR,
} from "../../../util/errorMsg";
import {
  getSiteUseId,
  makeCapitalEachWordInString,
} from "../../../util/utilityFunctions";
import { INewOrder } from "../newOrder.interface";
import NewOrderErrorPopupMessage from "../newOrderFooterGroup/newOrderErrorPopupMessage.component";
import AddManualHomeCare from "./addManualHomeCareProvider/addManualHomeCare";
import "./homeCareProvider.css";
import {
  IHomeCareProvider,
  IHomeCareProviderDetail,
} from "./homeCareProvider.interface";
import { HomeCareProviderFound } from "./homeCareProviderFound/homeCareProviderFound.component";
import SearchHomeCareProvider from "./homeCareSearch/homeCareSearch.component";
import { SearchHomeCareProviderModal } from "./homeCareSearch/searchHomeCare.enum";
import { HomeCareProviderReviewOrder } from "./reviewOrder/homeCareProviderReviewOrder.component";

export const HomeCareProvider = ({
  data,
  setData,
  states,
  statesText,
  isReviewOrder = false,
  editButtonClicked,
  isOrderSummary = false,
}: IHomeCareProvider) => {
  const newOrderObj = useContext<NewOrderContextType | null>(NewOrderContext);
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const facilitySearchData = getDeepClone(defaultFacilityData);
  const [fData, setFData] = React.useState(facilitySearchData);
  const [enabledHCPFromMyList, setEnabledHCPFromMyList] = React.useState<
    IHomeCareProviderDetail[]
  >([]);
  const [defaultHCPInMyList, setDefaultHCPInMyList] =
    useState<IHomeCareProviderDetail | null>();
  const [facilities, setFacilities] = React.useState<ICaregiverFacility[]>([]);
  const { isMobileScreen } = useContext(MobileDisplayContext);
  const [selectedHomeCareProvider, setSelectedHomeCareProvider] =
    useState<IHomeCareProviderDetail | null>();
  const [showerrorpopup, setShowerrorpopup] = useState(false);
  const [errorCode, setErrorCode] = useState("");
  const internalObj = useContext<InternalSignOnContextType | null>(
    InternalSignOnContext
  );
  const clearSearchData = () => {
    setFData((dt: any) => ({
      facilityName: {
        required: true,
        valid: ValidationStatus.UNTOUCHED,
        value: "",
      },
      facilityState: {
        required: true,
        valid: ValidationStatus.UNTOUCHED,
        value: "",
      },
      facilityID: {
        required: true,
        valid: ValidationStatus.UNTOUCHED,
        value: "",
      },
    }));
    newOrderObj?.setManualHomeCareProviderFrom(null);
  };

  const handleCaregiverFacilitySearch = async () => {
    var facilitySearchRequest: ICaregiverFacilitySearchRequest = {
      customerAccountNumber: fData.facilityID.value,
      customerName: fData.facilityName.value,
      state: fData.facilityState.value ? fData.facilityState.value : "",
    };
    newOrderObj?.setHomecareproviderSearchAddPopUpSection(
      SearchHomeCareProviderModal.LOADER
    );
    clearSearchData();
    const data = await caregiverFacilitySearch(facilitySearchRequest);
    if (!data?.succeeded) {
      setErrorCode(data?.error?.errorCode);
      newOrderObj?.setHomecareproviderSearchAddPopUpSection(
        SearchHomeCareProviderModal.SEARCH_HOMECAREPROVIDER
      );
      newOrderObj?.setSearchHomeCareProviderPopup(false);
      setShowerrorpopup(true);
    } else if (data.items === null) {
      newOrderObj?.setHomecareproviderSearchAddPopUpSection(
        SearchHomeCareProviderModal.FACILITY_NOTFOUND
      );
    } else if (data.items.length > 0) {
      const hcFacility = data.items.sort(
        (a: { customerName: string }, b: { customerName: string }) =>
          a.customerName > b.customerName ? 1 : -1
      ) as ICaregiverFacility[];
      setFacilities(hcFacility);
      newOrderObj?.setHomecareproviderSearchAddPopUpSection(
        SearchHomeCareProviderModal.FACILITY_LIST
      );
    } else {
      newOrderObj?.setHomecareproviderSearchAddPopUpSection(
        SearchHomeCareProviderModal.FACILITY_NOTFOUND
      );
    }
  };

  const providerSearch = () => {
    clearSearchData();
    newOrderObj?.setHomecareproviderSearchAddPopUpSection(
      SearchHomeCareProviderModal.SEARCH_HOMECAREPROVIDER
    );
  };

  const handleSelectHomeCareProvider = (data: IHomeCareProviderDetail) => {
    handleSelectedHomeCareProvider(
      `${data.name}`,
      data.accountNumber,
      `${data.address1 ?? ""}`,
      `${data.address2 ?? ""}`,
      data.city,
      data.state,
      data.zipCode ?? "",
      data.phone,
      data.facilityType,
      data.extension,
      data.marketingSegmentCode!,
      data.siteUseId!,
      data.caregiverID!
    );
  };

  const handleFacilitySelect = (facility: ICaregiverFacility) => {
    handleSelectedHomeCareProvider(
      facility.customerName,
      facility.customerAccountNumber!,
      facility.address1,
      facility.address2,
      facility.city,
      facility.state,
      facility.postalCode,
      facility.phoneNo,
      facility.marketingSegmentDescription,
      facility.extension,
      facility.marketingSegmentCode!,
      facility.siteUseId!,
      facility.origSystemReference!
    );
  };

  const handleSelectedHomeCareProvider = async (
    name: string,
    accountNumber: string,
    address: string,
    address2: string,
    city: string,
    state: string,
    zipCode: string,
    phone: string,
    type: string,
    extension: string,
    marketingSegmentCode: string,
    siteUseId: string,
    caregiverId: string
  ) => {
    newOrderObj?.setHomecareproviderSearchAddPopUpSection(
      SearchHomeCareProviderModal.LOADER
    );
    siteUseId = siteUseId
      ? siteUseId
      : caregiverId && (await fetchSiteUseId(caregiverId));
    setData((dt: INewOrder) => ({
      ...dt,
      homeCareProvider: {
        ...dt.homeCareProvider,
        required: true,
        valid: ValidationStatus.VALID,
      },
      addedCaregiverName: {
        ...dt.addedCaregiverName,
        valid: ValidationStatus.VALID,
        value: name,
        required: true,
      },
      addedCaregiverAddress1: {
        ...dt.addedCaregiverAddress1,
        valid: ValidationStatus.VALID,
        value: address,
        required: true,
      },
      addedCaregiverAddress2: {
        ...dt.addedCaregiverAddress2,
        valid: ValidationStatus.VALID,
        value: address2,
        required: true,
      },
      addedCaregiverCity: {
        ...dt.addedCaregiverCity,
        valid: ValidationStatus.VALID,
        value: city,
        required: true,
      },
      addedCaregiverState: {
        ...dt.addedCaregiverState,
        valid: ValidationStatus.VALID,
        value: state,
        required: true,
      },
      addedCaregiverZip: {
        ...dt.addedCaregiverZip,
        valid: ValidationStatus.VALID,
        value: zipCode,
        required: true,
      },
      addedCaregiverPhone: {
        ...dt.addedCaregiverPhone,
        valid: ValidationStatus.VALID,
        value: phone,
        required: true,
      },
      addedCaregiverFacilityType: {
        ...dt.addedCaregiverPhone,
        valid: ValidationStatus.VALID,
        value: type,
        required: true,
      },
      addedCaregiverPhoneExtension: {
        ...dt.addedCaregiverPhoneExtension,
        valid: ValidationStatus.VALID,
        value: extension,
        required: true,
      },
      addedCaregiverFacilityTypeCode: {
        ...dt.addedCaregiverPhoneExtension,
        valid: ValidationStatus.VALID,
        value: marketingSegmentCode,
        required: true,
      },
      addedCaregiverSiteUseID: {
        ...dt.addedCaregiverPhoneExtension,
        valid: ValidationStatus.VALID,
        value: siteUseId,
        required: true,
      },
      addedCaregiverID: {
        ...dt.addedCaregiverPhoneExtension,
        valid: ValidationStatus.VALID,
        value: caregiverId,
        required: true,
      },
      addedCaregiverAccountNumber: {
        ...dt.addedCaregiverPhoneExtension,
        valid: ValidationStatus.VALID,
        value: accountNumber,
        required: true,
      },
    }));
    newOrderObj?.setCaregiverSelected(true);
    newOrderObj?.setSearchHomeCareProviderPopup(false);
  };

  const handleBackToSearch = () => {
    newOrderObj?.setHomecareproviderSearchAddPopUpSection(
      SearchHomeCareProviderModal.FACILITY_DATABASE_SEARCH
    );
  };

  const handleAddNewProvider = () => {
    newOrderObj?.setHomecareproviderSearchAddPopUpSection(
      SearchHomeCareProviderModal.ADD_MANUAL_HOMECAREPROVIDER
    );
  };

  const searchHomeCareProviderButtonAction = () => {
    newOrderObj?.setHomecareproviderSearchAddPopUpSection(
      SearchHomeCareProviderModal.SEARCH_HOMECAREPROVIDER
    );
    newOrderObj?.setSearchHomeCareProviderPopup(true);
  };

  const handleHCProviderSearchType = (section: SearchHomeCareProviderModal) => {
    newOrderObj?.setHomecareproviderSearchAddPopUpSection(section);
  };

  const searchHomeCareProviderPopupSection = () => {
    let page: ReactNode;
    switch (newOrderObj?.homecareproviderSearchAddPopUpSection) {
      case SearchHomeCareProviderModal.SEARCH_HOMECAREPROVIDER:
        page = (
          <SearchHomeCareProvider
            handleHomeCareProviderSearchType={handleHCProviderSearchType}
            setSelectedHomeCareProvider={setSelectedHomeCareProvider}
            enabledHCPFromMyList={enabledHCPFromMyList}
            handleSelectedHomeCareProvider={handleSelectHomeCareProvider}
          />
        );
        break;
      case SearchHomeCareProviderModal.HOMECAREPROVIDER_FOUND:
        page = (
          <HomeCareProviderFound
            data={selectedHomeCareProvider!}
            handleSelectedHomeCareProvider={handleSelectHomeCareProvider}
            handleHomeCareProviderSearchType={handleHCProviderSearchType}
          />
        );
        break;
      case SearchHomeCareProviderModal.ADD_MANUAL_HOMECAREPROVIDER:
        page = (
          <AddManualHomeCare
            states={states}
            statesText={statesText}
            handleFacilitySelect={handleFacilitySelect}
          />
        );
        break;
      case SearchHomeCareProviderModal.FACILITY_DATABASE_SEARCH:
        page = (
          <CaregiverFacilitySearch
            data={fData}
            setData={setFData}
            handleSearch={handleCaregiverFacilitySearch}
            redirectToProviderSearch={providerSearch}
            statesText={statesText}
            states={states}
          />
        );
        break;
      case SearchHomeCareProviderModal.FACILITY_LIST:
        page = (
          <CaregiverFacilityFoundList
            facilities={facilities}
            handleSelect={handleFacilitySelect}
            handleBackToSearch={handleBackToSearch}
            handleAddNewProvider={handleAddNewProvider}
          />
        );
        break;
      case SearchHomeCareProviderModal.FACILITY_NOTFOUND:
        page = (
          <CaregiverFacilityNotFound
            handleAddNewProvider={handleAddNewProvider}
            handleSearchAgain={handleBackToSearch}
          />
        );
        break;
      case SearchHomeCareProviderModal.LOADER:
        page = (
          <div className="homecareprovider-spinner">
            <LoadingSpinner />
          </div>
        );
        break;
    }
    return page;
  };

  const validateAndSetData = (e: any) => {
    newOrderObj?.setIsHandleChangeTriggered(true);
    const { name, value } = e.target;
    if (name === "homeCareProvider") {
      let isValid = ValidationStatus.VALID;
      if (value === "yes") {
        newOrderObj?.setActive(true);
        newOrderObj?.setCaregiverSelected(true);
      } else if (value === "no") {
        newOrderObj?.setActive(false);
        newOrderObj?.setCaregiverSelected(false);
      }
      setData((dt: INewOrder) => ({
        ...dt,
        homeCareProvider: {
          ...dt.homeCareProvider,
          required: true,
          valid: isValid,
          value: value,
        },
        addedCaregiverName: {
          required: true,
          valid: ValidationStatus.UNTOUCHED,
          value: "",
        },
        addedCaregiverAddress1: {
          required: true,
          valid: ValidationStatus.UNTOUCHED,
          value: "",
        },
        addedCaregiverAddress2: {
          required: true,
          valid: ValidationStatus.UNTOUCHED,
          value: "",
        },
        addedCaregiverCity: {
          required: true,
          valid: ValidationStatus.UNTOUCHED,
          value: "",
        },
        addedCaregiverState: {
          required: true,
          valid: ValidationStatus.UNTOUCHED,
          value: "",
        },
        addedCaregiverZip: {
          required: true,
          valid: ValidationStatus.UNTOUCHED,
          value: "",
        },
        addedCaregiverPhone: {
          required: true,
          valid: ValidationStatus.UNTOUCHED,
          value: "",
        },
        addedCaregiverFacilityType: {
          required: true,
          valid: ValidationStatus.UNTOUCHED,
          value: "",
        },
        addedCaregiverFacilityTypeCode: {
          required: true,
          valid: ValidationStatus.UNTOUCHED,
          value: "",
        },
        addedCaregiverSiteUseID: {
          required: true,
          valid: ValidationStatus.UNTOUCHED,
          value: "",
        },
        addedCaregiverAccountNumber: {
          required: true,
          valid: ValidationStatus.UNTOUCHED,
          value: "",
        },
        addedCaregiverID: {
          required: true,
          valid: ValidationStatus.UNTOUCHED,
          value: "",
        },
      }));
      if (value === "yes" && defaultHCPInMyList) {
        handleSelectHomeCareProvider(defaultHCPInMyList!);
      }
    }
  };
  const closeErrorPopup = () => {
    setShowerrorpopup(false);
  };

  useEffect(() => {
    if (
      !newOrderObj?.isComingFromPrev &&
      newOrderObj?.vacOrderID === "" &&
      !isReviewOrder
    ) {
      newOrderObj?.setActive(null);
      newOrderObj?.setCaregiverSelected(false);
      setData((dt: INewOrder) => ({
        ...dt,
        homeCareProvider: {
          ...dt.homeCareProvider,
          required: true,
          valid: ValidationStatus.UNTOUCHED,
          value: "",
        },
        addedCaregiverName: {
          ...dt.addedCaregiverName,
          required: true,
          valid: ValidationStatus.UNTOUCHED,
          value: "",
        },
        addedCaregiverAddress1: {
          ...dt.addedCaregiverAddress1,
          required: true,
          valid: ValidationStatus.UNTOUCHED,
          value: "",
        },
        addedCaregiverAddress2: {
          ...dt.addedCaregiverAddress2,
          required: true,
          valid: ValidationStatus.UNTOUCHED,
          value: "",
        },
        addedCaregiverCity: {
          ...dt.addedCaregiverCity,
          required: true,
          valid: ValidationStatus.UNTOUCHED,
          value: "",
        },
        addedCaregiverState: {
          ...dt.addedCaregiverState,
          required: true,
          valid: ValidationStatus.UNTOUCHED,
          value: "",
        },
        addedCaregiverZip: {
          ...dt.addedCaregiverZip,
          required: true,
          valid: ValidationStatus.UNTOUCHED,
          value: "",
        },
        addedCaregiverPhone: {
          ...dt.addedCaregiverPhone,
          required: true,
          valid: ValidationStatus.UNTOUCHED,
          value: "",
        },
        addedCaregiverFacilityType: {
          ...dt.addedCaregiverFacilityType,
          required: true,
          valid: ValidationStatus.UNTOUCHED,
          value: "",
        },
        addedCaregiverFacilityTypeCode: {
          ...dt.addedCaregiverFacilityTypeCode,
          required: true,
          valid: ValidationStatus.VALID,
          value: "",
        },
        addedCaregiverSiteUseID: {
          ...dt.addedCaregiverSiteUseID,
          required: true,
          valid: ValidationStatus.VALID,
          value: "",
        },
        addedCaregiverAccountNumber: {
          ...dt.addedCaregiverAccountNumber,
          required: true,
          valid: ValidationStatus.VALID,
          value: "",
        },
        addedCaregiverID: {
          ...dt.addedCaregiverID,
          required: true,
          valid: ValidationStatus.VALID,
          value: "",
        },
      }));
    }
  }, [authObj?.registeredFaciltyAddress]);

  useEffect(() => {
    setEnabledHCPFromMyList([]);
    setDefaultHCPInMyList(null);
    getHcpFacilityList();
  }, [
    JSON.stringify(newOrderObj?.newOrderData.requestingFacility?.value),
    authObj?.registeredFaciltyAddress,
  ]);

  const getHcpFacilityList = async () => {
    let siteUseIDValue = internalObj?.isVACOrderSSO
      ? newOrderObj?.newOrderData.requestingFacility?.value?.siteUseId!
      : getSiteUseId(authObj);
    if (siteUseIDValue) {
      const body = {
        SiteUseId: siteUseIDValue,
        ShowAllRecords: true,
      };
      const response = await getHomeCareProviderFacilityList(body);
      if (response && response?.succeeded) {
        let tempHCPList: IHomeCareProviderDetail[] = [];
        response.data.forEach((TempHCPObj: any) => {
          if (TempHCPObj.isEnabled) {
            const hcpData: IHomeCareProviderDetail = {
              name: TempHCPObj.name,
              accountNumber: TempHCPObj.accountNumber,
              address1: TempHCPObj?.address?.addressLine1,
              address2: TempHCPObj?.address?.addressLine2,
              city: TempHCPObj?.address?.city,
              state: TempHCPObj?.address?.stateCode,
              zipCode: TempHCPObj?.address?.postalCode,
              phone: "",
              facilityType: TempHCPObj.classification,
              extension: "",
              marketingSegmentCode: TempHCPObj.classificationCode,
              siteUseId: null,
              caregiverID: TempHCPObj.caregiverId,
            };
            tempHCPList.push(hcpData);
          }
        });
        setEnabledHCPFromMyList(tempHCPList);
        let filteredDefaultHCPData = response.data.filter(
          (x: any) => x.isDefault
        )[0];
        if (filteredDefaultHCPData)
          setDefaultHCPInMyList({
            ...defaultHCPInMyList,
            name: filteredDefaultHCPData.name,
            accountNumber: filteredDefaultHCPData.accountNumber,
            address1: filteredDefaultHCPData?.address?.addressLine1,
            address2: filteredDefaultHCPData?.address?.addressLine2,
            city: filteredDefaultHCPData?.address?.city,
            state: filteredDefaultHCPData?.address?.stateCode,
            zipCode: filteredDefaultHCPData?.address?.postalCode,
            phone: "",
            facilityType: filteredDefaultHCPData.classification,
            extension: "",
            marketingSegmentCode: filteredDefaultHCPData.classificationCode,
            siteUseId: await fetchSiteUseId(filteredDefaultHCPData.caregiverId),

            caregiverID: filteredDefaultHCPData.caregiverId,
          });
      }
    }
  };

  const fetchSiteUseId = async (careGiverId: string) => {
    const reqBody = {
      OrigSystemReference: careGiverId,
    };
    const response = await caregiverFacilitySearch(reqBody);
    if (response && response.succeeded && response.items.length > 0) {
      return response.items[0].siteUseId;
    } else return null;
  };
  return (
    <>
      {showerrorpopup && (
        <NewOrderErrorPopupMessage
          popUpStyles="homeCareProviderErrorPopup"
          handleBackButton={closeErrorPopup}
          errorPopupFlag={true}
          errorMessage={HOME_CARE_PROVIDER_SEARCH_MS_SERVICE_ERROR}
          handleExitButton={closeErrorPopup}
          isSupportPhoneShow={true}
          errorCode={errorCode}
        />
      )}
      <div className="homecareprovider-component">
        {!isReviewOrder && (
          <div className="homecareprovider">
            <h2
              className="homecareprovider-title"
              data-testid="homecareprovider-title"
            >
              Home Care Provider
            </h2>
            <InputWithLabel
              error={data.homeCareProvider.valid === ValidationStatus.INVALID}
              id={data.homeCareProvider.componentId!}
              isRequired={true}
              isShowWarning={true}
              label="Do you know who will be administering the patient's dressing changes?"
              labelClassName="homecare-provider-label-item"
              testId="homecareprovider-administering-dress"
              warningMessage={
                data.homeCareProvider.value === ""
                  ? ERROR_TO_SELECT_ANY_OPTION
                  : ""
              }
            >
              <Box
                className="homecare-provider-box-container"
                sx={{ flexGrow: 1 }}
              >
                <Grid
                  className="homecare-provider-grid-container"
                  container
                  spacing={2}
                >
                  <Grid
                    className="homecare-provider-grid-item"
                    item
                    xs={isMobileScreen ? 12 : 6}
                  >
                    <RadioGroup
                      name="homeCareProvider"
                      classes={{ root: "radioRoot" }}
                      onChange={validateAndSetData}
                      value={data.homeCareProvider.value}
                    >
                      <FormControlLabel
                        classes={{
                          root:
                            data.homeCareProvider.valid ===
                            ValidationStatus.INVALID
                              ? "optionRoot-error"
                              : newOrderObj?.active === true
                              ? "optionRoot-active"
                              : "optionRoot",
                        }}
                        componentsProps={{
                          typography: {
                            classes: {
                              root:
                                newOrderObj?.active === true
                                  ? "optiontxtSelect"
                                  : "optiontxt",
                            },
                          },
                        }}
                        control={
                          <Radio
                            icon={<RadioButtonIcon />}
                            checkedIcon={<SelectedRadioButtonIcon />}
                          />
                        }
                        data-testid="homecareprovider-administering-dress-yes"
                        label="Yes"
                        value="yes"
                      />
                      <FormControlLabel
                        classes={{
                          root:
                            data.homeCareProvider.valid ===
                            ValidationStatus.INVALID
                              ? "optionRoot-error"
                              : newOrderObj?.active === false
                              ? "optionRoot-active"
                              : "optionRoot",
                        }}
                        componentsProps={{
                          typography: {
                            classes: {
                              root:
                                newOrderObj?.active === false
                                  ? "optiontxtSelect"
                                  : "optiontxt",
                            },
                          },
                        }}
                        control={
                          <Radio
                            icon={<RadioButtonIcon />}
                            checkedIcon={<SelectedRadioButtonIcon />}
                          />
                        }
                        data-testid="homecareprovider-administering-dress-no"
                        label="No"
                        value="no"
                      />
                    </RadioGroup>
                  </Grid>
                  {/* Search for HCP  */}
                  {newOrderObj?.active &&
                    newOrderObj?.active === true &&
                    data.addedCaregiverAddress1.value === "" && (
                      <div className="homecareprovider">
                        <Box
                          className="homecareprovider-box-container"
                          sx={{ flexGrow: 1 }}
                        >
                          <Grid
                            className="homecareprovider-grid-container"
                            container
                            spacing={2}
                          >
                            <Grid
                              className="homecareprovider-item"
                              item
                              xs={12}
                            >
                              <InputWithLabel label="">
                                <Button
                                  classes={{ root: "homecareprovider-button" }}
                                  data-testid="homecareprovider-button"
                                  onClick={searchHomeCareProviderButtonAction}
                                  startIcon={<SearchIcon />}
                                  variant="outlined"
                                >
                                  Search For Home Care Provider
                                </Button>
                              </InputWithLabel>
                            </Grid>
                          </Grid>
                        </Box>
                        {data.addedCaregiverAddress1.valid ===
                          ValidationStatus.INVALID && (
                          <div
                            className="homecareprovider-warning-div"
                            data-testid="homecareprovider-warning-div"
                          >
                            <span
                              className="warning-icon"
                              data-testid="warning-icon"
                            >
                              <WarningIcon className="warning-icon-image" />
                            </span>
                            <p
                              className="error-with-warning-icon"
                              data-testid="error-with-warning-icon"
                            >
                              {ERROR_TO_SEARCH_AND_SELECT_HOME_CARE_PROVIDER}
                            </p>
                          </div>
                        )}
                      </div>
                    )}
                  {/* selected HCP section */}
                  {newOrderObj?.active &&
                    newOrderObj?.caregiverSelected &&
                    data?.addedCaregiverAddress1?.value !== "" && (
                      <Grid container className="caregiver-container">
                        <Grid className="caregiver-name-container" item xs={12}>
                          <div className="caregiver-name">
                            {makeCapitalEachWordInString(
                              data?.addedCaregiverName.value.toLowerCase()
                            )}
                          </div>
                          <div className="change-home-care-provider-btn">
                            <Button
                              onClick={() => {
                                newOrderObj?.setHomecareproviderSearchAddPopUpSection(
                                  SearchHomeCareProviderModal.SEARCH_HOMECAREPROVIDER
                                );
                                newOrderObj?.setSearchHomeCareProviderPopup(
                                  true
                                );
                              }}
                              classes={{
                                root: "hcp-change-provider-btn",
                              }}
                            >
                              Change Home care provider
                            </Button>
                          </div>
                        </Grid>
                        <Grid
                          className="caregiver-address-container"
                          item
                          xs={12}
                        >
                          <div className="address-part address-div">
                            <div className="address">
                              <div className="address-header">Address</div>
                              <div className="caregiver-address">
                                {makeCapitalEachWordInString(
                                  data?.addedCaregiverAddress1?.value
                                    ? data?.addedCaregiverAddress1?.value.toLowerCase()
                                    : ""
                                )}
                              </div>
                              <div className="caregiver-address">
                                {makeCapitalEachWordInString(
                                  data?.addedCaregiverAddress2?.value
                                    ? data?.addedCaregiverAddress2?.value.toLowerCase()
                                    : ""
                                )}
                              </div>
                              <div className="caregiver-address">
                                {`${`${makeCapitalEachWordInString(
                                  data?.addedCaregiverCity?.value?.toLowerCase()
                                )}, ${
                                  data?.addedCaregiverState?.value ?? ""
                                }, ${data?.addedCaregiverZip?.value?.toLowerCase()}`}`}
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="address-part phone-div">
                              <div className="address-header">
                                Facility Type
                              </div>
                              <div className="caregiver-address">
                                {makeCapitalEachWordInString(
                                  data?.addedCaregiverFacilityType.value
                                    ? data?.addedCaregiverFacilityType.value.toLowerCase()
                                    : "--"
                                )}
                              </div>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    )}
                </Grid>
              </Box>
            </InputWithLabel>
            <Popup
              openFlag={newOrderObj?.searchHomeCareProviderPopup}
              closeHandler={() => {
                clearSearchData();
                newOrderObj?.setSearchHomeCareProviderPopup(false);
              }}
              dialogParentClass={
                newOrderObj?.homecareproviderSearchAddPopUpSection
              }
            >
              {searchHomeCareProviderPopupSection()}
            </Popup>
          </div>
        )}
        {isReviewOrder && (
          <HomeCareProviderReviewOrder
            data={data}
            editButtonClicked={editButtonClicked}
            isOrderSummary={isOrderSummary}
          />
        )}
      </div>
    </>
  );
};
