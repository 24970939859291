import React, { useContext, useEffect } from "react";
import {
  WoundAssessmentContext,
  WoundAssessmentContextType,
} from "../../../../../../context/WoundAssessmentContext";
import CustomDropZone from "../../../../../../core/customDropZone/customDropZone.component";
import "./woundUploadDocument.css";
import { IVacTherapyInformation } from "../../../../../newOrder/woundBed/vacTherapyInformation.interface";

type Props = {
  isTestingComponent?: boolean;
  vacTherapyInformationData?: IVacTherapyInformation;
};

const WoundUploadDocument = ({
  isTestingComponent = false,
  vacTherapyInformationData,
}: Props) => {
  const woundAssessmentObj = useContext<WoundAssessmentContextType | null>(
    WoundAssessmentContext
  );
  const allowedFiles = {
    "image/png": [".jpeg", ".gif", ".jpg", ".png", ".tiff"],
    "text/pdf": [".pdf"],
  };
  useEffect(() => {
    if (
      woundAssessmentObj?.woundDocsUpload.some(
        (file) => file.succeeded === false
      )
    ) {
      woundAssessmentObj?.setErrorInDocUploadFiles(true);
    } else {
      woundAssessmentObj?.setErrorInDocUploadFiles(false);
    }
  }, [
    woundAssessmentObj?.woundDocsUpload,
    woundAssessmentObj?.deletedWoundDocuments,
  ]);

  useEffect(() => {
    if (
      woundAssessmentObj?.deletedWoundDocuments &&
      woundAssessmentObj?.deletedWoundDocuments.length > 0
    ) {
      woundAssessmentObj?.setIsHandleChangeTriggered(true);
    }
    if (
      woundAssessmentObj?.woundDocsUpload &&
      woundAssessmentObj?.woundDocsUpload.filter(
        (x) => !x.documentId && x.succeeded !== false
      ).length > 0
    ) {
      woundAssessmentObj?.setIsHandleChangeTriggered(true);
    }
  }, [
    woundAssessmentObj?.woundDocsUpload,
    woundAssessmentObj?.deletedWoundDocuments,
  ]);

  return (
    <div className="newWoundDoc">
      <div data-testid="wound-doc-header" className="uploadWoundDoc">
        Upload Additional Documents
        <div data-testid="wound-doc-desc" className="uploadWoundDocDesc">
          <p>
            Clinical documents are needed to process your order. Please upload
            or fax the patient face sheet, prescription, insurance name and
            policy number, H&P, any clinical notes, and wound documentation (
            <a
              href={vacTherapyInformationData?.npwtRequirement?.fileLink}
              target="_blank"
              className="npwtLinkFont-woundDocUpload"
            >
              {vacTherapyInformationData?.npwtRequirement?.labelText}
            </a>{" "}
            provides a complete list of needed documents).
          </p>
          <p>
            Uploaded files must be in one of the following formats: JPG, GIF,
            JPEG, PNG, TIFF or PDF. Each file cannot exceed 15 MB (15360 KB) in
            size.
          </p>
        </div>
        <CustomDropZone
          allowedFiles={allowedFiles}
          setData={woundAssessmentObj?.setwoundDocsUpload}
          data={woundAssessmentObj?.woundDocsUpload}
          setDeletedData={woundAssessmentObj?.setDeletedWoundDocuments}
          dragDropText="Drag and drop files here to upload"
          buttonDropText="Select files to upload"
          dropZoneStyles="dropZoneStyles"
          dragAndDropStyles="dragAndDrop"
          uploadWidgetCard="uploadWidgetCard"
          uploadIconStyle="uploadIconStyle"
          listingType="newOrder"
          maxFileSize={15728640}
          singleFile={false}
          isTestingComponent={isTestingComponent}
        />
      </div>
    </div>
  );
};
export default WoundUploadDocument;
