import { useContext, useEffect, useState } from "react";
import { Box, Grid, Toolbar, useMediaQuery, Drawer } from "@mui/material";
import { ExpressButton } from "../../../core/expressButton/expressButton.component";
import { PersistentFooter } from "../../../core/persistentFooter/persistentFooter.Component";
import "./newOrderFooterBtnGroup.css";
import ShareIcon from "@mui/icons-material/Share";
import Share from "../../../assets/share.svg";
import {
  NewOrderContext,
  NewOrderContextType,
} from "../../../context/NewOrderContext";
import { NewOrderPageSection } from "../NewOrderContainer.enum";
import {
  RolesPermissionContext,
  RolesPermissionContextType,
} from "../../../context/RolesPermissionContext";
import {
  InternalSignOnContext,
  InternalSignOnContextType,
} from "../../../context/InternalSignOnContext";

type INewOrderFooterProps = {
  validateAll: any;
  handlePrevious: any;
  handleSaveExit: () => void;
  handleShareOrder: () => void;
  handleSave: any;
  handleCancelOrder: any;
  cancelBtnVisibility: boolean;
  shareOrderButtonText: string;
  isSharedOrderNeedToBeDisable?: boolean;
};

export const NewOrderFooterButtonGroup = ({
  validateAll,
  handlePrevious,
  handleSaveExit,
  handleCancelOrder,
  handleShareOrder,
  handleSave,
  cancelBtnVisibility,
  shareOrderButtonText,
  isSharedOrderNeedToBeDisable,
}: INewOrderFooterProps) => {
  const newOrderObj = useContext<NewOrderContextType | null>(NewOrderContext);
  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );
  const internalObj = useContext<InternalSignOnContextType | null>(
    InternalSignOnContext
  );
  const isMobileScreen = useMediaQuery("(max-width:920px)");

  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const togglerDrawer = (isOpen: boolean) => {
    setOpenDrawer(isOpen);
  };

  return (
    <PersistentFooter footerButtonClass="neworder-footer">
      <Toolbar className="orderToolBar">
        {isMobileScreen ? (
          <>
            <div className="footer-button-mobile-container">
              <div className="footer-button-more-options">
                {!internalObj?.isVACOrderSSO && (
                  <ExpressButton
                    disabled={
                      permissionObj?.mappedRolesPermissionData.IsSupportRole ===
                      true
                        ? true
                        : newOrderObj
                        ? newOrderObj.showPrescriberUpdateEmail ||
                          newOrderObj.isEditingDeliveryInformation ||
                          newOrderObj.editMode
                        : false
                    }
                    clickHandler={() => togglerDrawer(true)}
                    parentClass={"footer-button-more-options-btn noHover"}
                    testId="more-options-test"
                    variant="text"
                  >
                    More options
                  </ExpressButton>
                )}
              </div>
              {internalObj?.isVACOrderSSO && (
                <Grid item xs={12} className="footer-delete-btn">
                  <ExpressButton
                    clickHandler={handleCancelOrder}
                    parentClass={"textOrderClass noHover"}
                    testId="cancelOrderTest"
                    variant="text"
                    disabled={
                      permissionObj?.mappedRolesPermissionData.IsSupportRole
                    }
                  >
                    Delete
                  </ExpressButton>
                </Grid>
              )}
              <div className="footer-button-mobile-container-div">
                <div className="footer-button-previous">
                  <ExpressButton
                    clickHandler={handlePrevious}
                    parentClass={`footer-button-previous-btn ${
                      newOrderObj?.newOrderPage ===
                      NewOrderPageSection.NEWORDER_REVIEW_ORDER
                        ? "submit"
                        : ""
                    }`}
                    testId="prevOrderTest"
                    variant="outlined"
                    disabled={
                      permissionObj?.mappedRolesPermissionData.IsSupportRole
                    }
                  >
                    Previous
                  </ExpressButton>
                </div>
                <div className="footer-button-next">
                  <ExpressButton
                    disabled={
                      permissionObj?.mappedRolesPermissionData.IsSupportRole ===
                      true
                        ? true
                        : newOrderObj
                        ? newOrderObj.showPrescriberUpdateEmail ||
                          newOrderObj.isEditingDeliveryInformation ||
                          newOrderObj.editMode
                        : false
                    }
                    clickHandler={validateAll}
                    parentClass={
                      newOrderObj?.newOrderPage ===
                      NewOrderPageSection.NEWORDER_REVIEW_ORDER
                        ? "footer-button-submit-btn"
                        : "footer-button-next-btn"
                    }
                    testId="nextOrderTest"
                    variant="contained"
                  >
                    {newOrderObj?.newOrderPage ===
                    NewOrderPageSection.NEWORDER_REVIEW_ORDER
                      ? "Submit Order"
                      : "Next"}
                  </ExpressButton>
                </div>
              </div>
            </div>
            {/* Drawer to open the bottom-pop-up on click of more-options */}
            <Drawer
              anchor="bottom"
              open={openDrawer}
              onClose={() => togglerDrawer(false)}
            >
              <div className="more-options-drawer">
                {newOrderObj?.newOrderPage !==
                NewOrderPageSection.NEWORDER_REVIEW_ORDER ? (
                  !internalObj?.isVACOrderSSO && (
                    <>
                      <Grid item xs={12}>
                        <ExpressButton
                          clickHandler={() => {
                            togglerDrawer(false);
                            handleSaveExit();
                          }}
                          testId="saveExitTest"
                          variant="outlined"
                          id="VacSaveExit"
                          disabled={
                            permissionObj?.mappedRolesPermissionData
                              .IsSupportRole
                          }
                          sx={{
                            textTransform: "none", // Override the textTransform property
                          }}
                          parentClass="save-and-exit-dragger"
                        >
                          Save and Exit
                        </ExpressButton>
                      </Grid>
                      <Grid item xs={12}>
                        <ExpressButton
                          clickHandler={() => {
                            togglerDrawer(false);
                            handleSave();
                          }}
                          testId="SaveOrderTest"
                          variant="outlined"
                          id="VacSave"
                          parentClass="save-dragger"
                          disabled={
                            permissionObj?.mappedRolesPermissionData
                              .IsSupportRole
                          }
                        >
                          Save
                        </ExpressButton>
                      </Grid>
                    </>
                  )
                ) : (
                  <Grid item xs={12}>
                    <ExpressButton
                      clickHandler={() => {
                        togglerDrawer(false);
                        handleCancelOrder();
                      }}
                      parentClass={"delete-Order-dragger noHover"}
                      testId="cancelOrderTest"
                      variant="text"
                      disabled={
                        permissionObj?.mappedRolesPermissionData.IsSupportRole
                      }
                    >
                      Delete order
                    </ExpressButton>
                  </Grid>
                )}
                {!internalObj?.isVACOrderSSO && (
                  <Grid item xs={12}>
                    <ExpressButton
                      clickHandler={() => {
                        togglerDrawer(false);
                        handleShareOrder();
                      }}
                      parentClass={"shareOrderButton-drawer"}
                      testId="shareOrderTest"
                      variant="outlined"
                      id="VacShareOrder"
                      disabled={
                        permissionObj?.mappedRolesPermissionData
                          .IsSupportRole || isSharedOrderNeedToBeDisable
                      }
                    >
                      {permissionObj?.mappedRolesPermissionData.IsSupportRole ||
                      isSharedOrderNeedToBeDisable ? (
                        <ShareIcon className="shareOrderImage"></ShareIcon>
                      ) : (
                        <img
                          src={Share}
                          alt={Share}
                          className="shareOrderImage"
                        ></img>
                      )}

                      {shareOrderButtonText}
                    </ExpressButton>
                  </Grid>
                )}

                {cancelBtnVisibility &&
                  newOrderObj?.newOrderPage !==
                    NewOrderPageSection.NEWORDER_REVIEW_ORDER && (
                    <Grid item xs={12}>
                      <ExpressButton
                        clickHandler={handleCancelOrder}
                        parentClass={"delete-Order-dragger noHover"}
                        testId="cancelOrderTest"
                        variant="text"
                        disabled={
                          permissionObj?.mappedRolesPermissionData.IsSupportRole
                        }
                      >
                        Delete order
                      </ExpressButton>
                    </Grid>
                  )}
              </div>
            </Drawer>
          </>
        ) : (
          <>
            <Grid container className="orderMaincontainer">
              <Grid
                item
                xs={7.5}
                className="orderLeftContainer"
                flexWrap="wrap"
              >
                <Box className="boxOrderStyle" p={1}>
                  {cancelBtnVisibility && (
                    <ExpressButton
                      clickHandler={handleCancelOrder}
                      parentClass={
                        internalObj?.isVACOrderSSO
                          ? "vacOrder-sso-textOrderClass noHover"
                          : "textOrderClass noHover"
                      }
                      testId="cancelOrderTest"
                      variant="text"
                      disabled={
                        permissionObj?.mappedRolesPermissionData.IsSupportRole
                      }
                    >
                      Delete order
                    </ExpressButton>
                  )}
                </Box>
                {!internalObj?.isVACOrderSSO && (
                  <>
                    <Box className="boxOrderStyle" p={1}>
                      {newOrderObj?.newOrderPage !==
                        NewOrderPageSection.NEWORDER_REVIEW_ORDER && (
                        <ExpressButton
                          clickHandler={handleSaveExit}
                          testId="saveExitTest"
                          variant="outlined"
                          id="VacSaveExit"
                          disabled={
                            permissionObj?.mappedRolesPermissionData
                              .IsSupportRole
                          }
                          sx={{
                            textTransform: "none", // Override the textTransform property
                          }}
                        >
                          Save and Exit
                        </ExpressButton>
                      )}
                    </Box>
                    <Box className="boxOrderStyle" p={1}>
                      {newOrderObj?.newOrderPage !==
                        NewOrderPageSection.NEWORDER_REVIEW_ORDER && (
                        <ExpressButton
                          clickHandler={handleSave}
                          testId="SaveOrderTest"
                          variant="outlined"
                          id="VacSave"
                          disabled={
                            permissionObj?.mappedRolesPermissionData
                              .IsSupportRole
                          }
                        >
                          Save
                        </ExpressButton>
                      )}
                    </Box>
                    <Box className="boxOrderStyle" p={1}>
                      <ExpressButton
                        clickHandler={handleShareOrder}
                        parentClass="shareOrderButton"
                        testId="shareOrderTest"
                        variant="outlined"
                        id="VacShareOrder"
                        disabled={
                          permissionObj?.mappedRolesPermissionData
                            .IsSupportRole || isSharedOrderNeedToBeDisable
                        }
                      >
                        {permissionObj?.mappedRolesPermissionData
                          .IsSupportRole || isSharedOrderNeedToBeDisable ? (
                          <ShareIcon className="shareOrderImage"></ShareIcon>
                        ) : (
                          <img
                            src={Share}
                            alt={Share}
                            className="shareOrderImage"
                          ></img>
                        )}
                        {shareOrderButtonText}
                      </ExpressButton>
                    </Box>
                  </>
                )}
              </Grid>
              <Grid
                item
                xs={4.5}
                className="orderRightContainer"
                flexWrap="wrap"
              >
                <Box className="boxOrderStyle" p={1}>
                  <ExpressButton
                    clickHandler={handlePrevious}
                    parentClass="previousBtn"
                    testId="prevOrderTest"
                    variant="outlined"
                    disabled={
                      permissionObj?.mappedRolesPermissionData.IsSupportRole
                    }
                  >
                    Previous
                  </ExpressButton>
                </Box>
                <Box className="boxOrderStyle" p={1}>
                  <ExpressButton
                    disabled={
                      permissionObj?.mappedRolesPermissionData.IsSupportRole ===
                      true
                        ? true
                        : newOrderObj
                        ? newOrderObj.showPrescriberUpdateEmail ||
                          newOrderObj.isEditingDeliveryInformation ||
                          newOrderObj.editMode
                        : false
                    }
                    clickHandler={validateAll}
                    parentClass="nextBtnNewOrder"
                    testId="nextOrderTest"
                    variant="contained"
                  >
                    {newOrderObj?.newOrderPage ===
                    NewOrderPageSection.NEWORDER_REVIEW_ORDER
                      ? "Submit Order"
                      : "Next"}
                  </ExpressButton>
                </Box>
              </Grid>
            </Grid>
          </>
        )}
      </Toolbar>
    </PersistentFooter>
  );
};
