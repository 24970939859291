import { ICreateServiceRequest } from "../../createServiceRequest.interface";
import "./workOrderNumber.css";

const showTitleAndValue = (
  title: string,
  value: string | null,
  testId: string
) => {
  return (
    <div className="show-title-and-value-div">
      <p
        className="show-title-and-value-title"
        data-testid={`${testId}-title`}
        id={`${testId}-title-id`}
      >
        {title}
      </p>
      <p
        className="show-title-and-value-value"
        data-testid={`${testId}-value`}
        id={`${testId}-value-id`}
      >
        {value && value !== "" ? value.trim() : "--"}
      </p>
    </div>
  );
};

interface IWorkOrderNumberReviewProps {
  workOrderNumber: string;
}

const WorkOrderNumber = ({ workOrderNumber }: IWorkOrderNumberReviewProps) => {
  return (
    <div className="work-order-number-review-request-component">
      <div className="work-order-number-review-request-header">
        <p
          className="work-order-number-review-request-title"
          data-testid="work-order-number-review-request-title"
        >
          Work Order Number
        </p>
      </div>
      {showTitleAndValue(
        "Work Order Number",
        "#" + workOrderNumber,
        "work-order-number-number"
      )}
    </div>
  );
};

export default WorkOrderNumber;
