import AddIcon from "@mui/icons-material/AddCircleOutline";
import { Box, Grid } from "@mui/material";
import { useContext, useState } from "react";
import {
  AuthContext,
  AuthContextType,
} from "../../../../../context/AuthContext";
import { ExpressButton } from "../../../../../core/expressButton/expressButton.component";
import { LoadingSpinner } from "../../../../../core/loader/LoadingSpinner";
import { Popup } from "../../../../../core/popup/popup.component";
import { getSalesTerritories } from "../../../../../util/userService";
import { ITerritoryDetails } from "../AddSalesTerritory/addSalesTerritory.interface";
import { AddSalesTerritoryList } from "../AddSalesTerritory/addSalesTerritoryList.component";
import {
  ISaleManageUsersEditProps,
  ITerritory,
} from "../salesUserProfile.interface";
import { mockSaleManageEditUsersData } from "../salesUserProfile.model";
import "./salesTerritories.css";
import { IManualAddTerritory } from "../AddSalesTerritory/ManualAddSalesTerritory/manualAddTerritory.interface";
import { mockManualAddTerritory } from "../AddSalesTerritory/ManualAddSalesTerritory/manualAddTerritory.model";
import moment from "moment";
import {
  RolesPermissionContext,
  RolesPermissionContextType,
} from "../../../../../context/RolesPermissionContext";
import { AddSalesTerritoryForSaleManager } from "../AddSalesTerritoryForSaleManager/AddSalesTerritoryForSaleManager.component";
import { changeDateFormatOnlyDate } from "../../../../../util/utilityFunctions";

const SalesTerritories = ({
  salesManagerUserData,
  setSalesMngUserData,
  salesMngUserData,
}: ISaleManageUsersEditProps) => {
  const [openpopup, setOpenpopup] = useState(false);
  const [openRemovepopup, setOpenRemovepopup] = useState(false);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [territories, setTerritories] = useState<ITerritoryDetails[]>([]);
  const [territoryRemovalIndex, setTerritoryRemovalIndex] =
    useState<number>(-1);
  const [addSaleTerritory, setAddSaleTerritory] = useState<IManualAddTerritory>(
    mockManualAddTerritory
  );
  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );

  const openAddButton = async () => {
    setOpenpopup(true);
    await getSalesTerritoriesAPI();
  };
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const addManualSalesTerritory = () => {
    const addManualTerritory = mockSaleManageEditUsersData.territories[0];
    salesManagerUserData.territories.push(addManualTerritory);
  };

  const handleCloseEmpty = () => {
    setOpenpopup(false);
  };
  const handleRemovelink = (index: number) => {
    setTerritoryRemovalIndex(index);
    setOpenRemovepopup(true);
  };
  const handleRemovePopup = () => {
    setOpenRemovepopup(false);
  };
  const handleBack = () => {
    setOpenRemovepopup(false);
  };

  const result = territories.filter(
    (x) =>
      !salesManagerUserData.territories.some((y) => x.code === y.territoryCode)
  );

  const handleRemoveAccess = () => {
    const territories = [...salesManagerUserData.territories];
    territories.splice(territoryRemovalIndex, 1);
    setSalesMngUserData((dt: any) => ({
      ...dt,
      territories: territories,
    }));
    setOpenRemovepopup(false);
  };
  const getSalesTerritoriesAPI = async () => {
    if (!permissionObj?.mappedRolesPermissionData.IsSalesManagerRole) {
      if (
        authObj &&
        authObj.userProfile &&
        salesManagerUserData.territories.length > 0
      ) {
        const region =
          salesManagerUserData.territories &&
          salesManagerUserData.territories[0].regionCode;
        const params = {
          region: region,
          CurrentDate: changeDateFormatOnlyDate(new Date().toString()),
        };
        setShowLoader(true);
        const response = await getSalesTerritories(params);
        if (response && response.succeeded) {
          let allTerritories = response.data;
          setTerritories(allTerritories);
        }
      }
      setShowLoader(false);
    }
  };
  const checkForValidEndDate = (date: string | null) => {
    if (date && date === "Invalid date") {
      return "";
    } else return date;
  };
  return (
    <Grid className="salesManage-territory-main">
      <Grid className="salesManage-territory">
        <div>
          <h2
            className="salesManageuser-profile-territory"
            data-testid="salesManageuser-profile-territory"
          >
            Sales Territories
          </h2>
        </div>
        <>
          {salesManagerUserData &&
            Array.isArray(salesManagerUserData?.territories) &&
            salesManagerUserData?.territories.map((data: ITerritory, index) => {
              return (
                <Box className="salesTerritoryBoxContainer">
                  <Grid className="salesTerritory-main-container">
                    <Grid
                      className="salesTerritoryRoleNameLabel"
                      data-testid="salesTerritoryRoleNameLabel"
                    >
                      Role
                      <div
                        className="salesTerritoryRoleNameValue"
                        data-testid="salesTerritoryRoletypeValue"
                      >
                        {data.roleType}
                      </div>
                    </Grid>
                    <Grid
                      className="salesTerritoryRegionRoleNameLabel"
                      data-testid="salesTerritoryRegionNameLabel"
                    >
                      Region code & Name
                      <div
                        className="salesTerritoryRoleNameValue"
                        data-testid="salesTerritoryRegioncodeValue"
                      >
                        {data.regionCode}
                      </div>
                      {!data.isPrimary && (
                        <Grid className="assignmentDatelabel">
                          Assignment Dates
                          <div className="assignmentDatelabelValue">
                            {moment(data?.assignedFrom!).format("MM/DD/YYYY")} -{" "}
                            {checkForValidEndDate(
                              moment(data?.assignedTo!).format("MM/DD/YYYY")
                            )}
                          </div>
                        </Grid>
                      )}
                    </Grid>
                    <Grid
                      className="salesTerritoryCodeRoleNameLabel"
                      data-testid="salesTerritorycodeNameLabel"
                    >
                      Territory code & Name
                      <div
                        className="salesTerritoryRolecodenameValue"
                        data-testid="salesTerritoryRolecodeValue"
                      >
                        {data.territoryCode}
                      </div>
                      <div
                        className="salesTerritoryRolecodenameValue"
                        data-testid="salesTerritoryRolecodenameValue"
                      >
                        {data.territoryName}
                      </div>
                    </Grid>
                    <Grid>
                      <div className="isprimarysalesterritory">
                        {data.isPrimary === true ? "Primary Territory" : ""}
                      </div>
                    </Grid>
                    {!data.isPrimary && (
                      <Grid
                        className={
                          permissionObj?.mappedRolesPermissionData
                            ?.IsSupportRole!
                            ? `salesTerritoryRoleRemoveLable-disable`
                            : `salesTerritoryRoleRemoveLable`
                        }
                        data-testid="salesTerritoryRoleRemoveLabel"
                      >
                        <a onClick={(e) => handleRemovelink(index)}>Remove</a>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              );
            })}
        </>
      </Grid>

      <div className="addTerritoryBtn" data-testid="addterritorybtn">
        <ExpressButton
          startIcon={<AddIcon />}
          clickHandler={openAddButton}
          parentClass="leave-btn"
          testId="leave-btn"
          variant="outlined"
          disabled={permissionObj?.mappedRolesPermissionData?.IsSupportRole!}
        >
          Add Sales Territory
        </ExpressButton>
      </div>

      {openpopup && (
        <Popup
          openFlag={openpopup}
          closeHandler={handleCloseEmpty}
          dialogParentClass={"norecord-pop-up"}
        >
          {showLoader ? (
            <div className="AddTerritory-loader">
              <LoadingSpinner />
            </div>
          ) : (
            <>
              {permissionObj?.mappedRolesPermissionData?.IsSalesManagerRole ? (
                <AddSalesTerritoryForSaleManager
                  data={addSaleTerritory}
                  setData={setAddSaleTerritory}
                  setSalesMngUserData={setSalesMngUserData}
                  salesMngUserData={salesMngUserData}
                  setOpenpopup={setOpenpopup}
                />
              ) : (
                <AddSalesTerritoryList
                  territories={result}
                  data={addSaleTerritory}
                  setData={setAddSaleTerritory}
                  setSalesMngUserData={setSalesMngUserData}
                  salesMngUserData={salesMngUserData}
                  setOpenpopup={setOpenpopup}
                />
              )}
            </>
          )}
        </Popup>
      )}
      <div className="remove-popup-main-container">
        {openRemovepopup && (
          <Popup
            openFlag={openRemovepopup}
            closeHandler={handleRemovePopup}
            dialogParentClass="empty-pop-up-remove"
          >
            <div className="pop-up-sub-main">
              <h2 className="remove-popup-heading" data-testid="remove-header">
                Are you sure you want to unassign this territory?
              </h2>
              <p
                className="remove-popup-sub-heading"
                data-testid="remove-sub-heading"
              >
                By taking this action, this territory will no longer have a
                sales person for all facilities
              </p>
            </div>
            <div className="remove-back-button">
              <ExpressButton
                parentClass="remove-territory-backbutton"
                variant="outlined"
                clickHandler={handleBack}
                testId="removeBack-button"
              >
                Back
              </ExpressButton>
            </div>
            <div className="remove-access-button">
              <ExpressButton
                parentClass="removeAccess-territory-button"
                variant="contained"
                clickHandler={handleRemoveAccess}
                testId="removeAccess-button"
              >
                Remove access to this territory
              </ExpressButton>
            </div>
          </Popup>
        )}
      </div>
    </Grid>
  );
};
export default SalesTerritories;
