import { Grid } from "@mui/material";
import {
  FETCH_STATES_FAILED,
  PRESCRIBER_SEARCH_DB_SERVICE_ERROR,
} from "../../../../../util/errorMsg";
import {
  NO_DROPDOWN_DATA_AVAIALABLE,
  NO_PRESCRIBER_DATA_AVAIALABLE,
  SITEUSEID_FROM_REGISTERED_FACILITY_OBJ_FAILURE,
} from "../../../../../util/errorCode";
import {
  AuthContext,
  AuthContextType,
} from "../../../../../context/AuthContext";
import { format } from "react-string-format";
import ErrorPopup, {
  IErrorPopUp,
  defaultErrorPopUp,
} from "../../../../../core/errorPopup/errorPopup.component";
import { prescriberSearch } from "../../../../../util/3meService";
import { getNPIPrescriber } from "../../../../../util/npiService";
import { ReactNode, useContext, useEffect, useState } from "react";
import { getSiteUseId } from "../../../../../util/utilityFunctions";
import { DD_US_STATES_CONTENT } from "../../../../../util/staticText";
import { getDropDownContent } from "../../../../../util/dropDownService";
import { LoadingSpinner } from "../../../../../core/loader/LoadingSpinner";
import { IPrescriberInformation } from "../prescriberInformation.interface";
import { PrescriberPopUp } from "./prescriberInformationPopUpContainer.enum";
import { PrescriberFound } from "./popUps/prescriberFound/prescriberFound.component";
import { SearchPrescriber } from "./popUps/prescriberSearch/prescriberSearch.component";
import { NationalRegistryResult } from "./popUps/nationalRegistryResult/nationalRegistryResult.component";
import { NationalRegistrySearch } from "./popUps/nationalRegistrySearch/nationalRegistrySearch.component";
import { IPrescriberInformationPopUpContainerProps } from "./prescriberInformationPopUpContainer.interface";
import { NationalRegistryNoResult } from "./popUps/nationalRegistryNoResult/nationalRegistryNoResult.component";

export const PrescriberInformationPopUpContainer = ({
  closePopUp,
  currentPopUp,
  setCurrentPopUp,
  successAndClose,
  selectedNpi,
}: IPrescriberInformationPopUpContainerProps) => {
  const [error, setError] = useState<IErrorPopUp>(defaultErrorPopUp);
  const [allNationalRegistries, setAllNationalRegistries] = useState<
    IPrescriberInformation[]
  >([]);
  const [states, setStates] = useState<[]>([]);
  const [statesText, setStatesText] = useState([]);
  const [selectedPrescriber, setSelectedPrescriber] =
    useState<IPrescriberInformation | null>(null);
  const [searchedResults, setSearchedResults] = useState<
    IPrescriberInformation[]
  >([]);

  const authObj = useContext<AuthContextType | null>(AuthContext);

  const fetchDropDownContent = async () => {
    const ddContent = format("{0}", DD_US_STATES_CONTENT);
    const response = await getDropDownContent(ddContent);
    if (
      response &&
      response.succeeded &&
      response.items &&
      response.items.length > 0
    ) {
      const statesObject = response.items.filter(
        (item: { name: string }) => item.name === DD_US_STATES_CONTENT
      );
      const statesData = statesObject[0].data.sort(
        (a: { order: number }, b: { order: number }) =>
          a.order > b.order ? 1 : -1
      );
      setStates(statesData);
      setStatesText(statesData.map((x: { text: string }) => x.text));
    } else {
      let code;
      if (response && response?.items && response?.items?.length === 0) {
        code = NO_DROPDOWN_DATA_AVAIALABLE;
      } else {
        code =
          response?.error?.code ||
          response?.error?.errorCode ||
          response?.status;
      }
      const localError: IErrorPopUp = {
        errorCode: code,
        errorFlag: true,
        errorMessage: FETCH_STATES_FAILED,
      };
      setError(localError);
      setCurrentPopUp(PrescriberPopUp.ERROR_POPUP);
    }
  };

  const searchAllPrescribers = async () => {
    const siteUseId = getSiteUseId(authObj);
    if (siteUseId !== "") {
      setCurrentPopUp(PrescriberPopUp.LOADER);
      let reqParams = {
        searchinput: null,
        siteUseId: siteUseId,
      };
      const response = await prescriberSearch(reqParams);
      if (response && response.succeeded && response.data) {
        const prescribers = response.data.map(
          (prescriber: IPrescriberInformation) => {
            return {
              ...prescriber,
              prescriberName: prescriber.firstName + "," + prescriber.lastName,
            };
          }
        );
        setAllNationalRegistries(prescribers);
        setCurrentPopUp(PrescriberPopUp.SEARCH_PRESCRIBER);
      } else {
        let code;
        if (response?.data?.length === 0) {
          code = NO_PRESCRIBER_DATA_AVAIALABLE;
        } else {
          code =
            response?.error?.code ||
            response?.error?.errorCode ||
            response?.status;
        }
        const localError = {
          errorCode: code,
          errorFlag: true,
          errorMessage: PRESCRIBER_SEARCH_DB_SERVICE_ERROR,
        };
        setError(localError);
        setCurrentPopUp(PrescriberPopUp.ERROR_POPUP);
      }
    } else {
      const localError = {
        errorCode: SITEUSEID_FROM_REGISTERED_FACILITY_OBJ_FAILURE,
        errorFlag: true,
        errorMessage: PRESCRIBER_SEARCH_DB_SERVICE_ERROR,
      };
      setError(localError);
      setCurrentPopUp(PrescriberPopUp.ERROR_POPUP);
    }
  };

  const serachNPIInNationalRegistries = async (
    prescriber: IPrescriberInformation
  ) => {
    let reqParams = {
      NPI: prescriber.npi,
    };
    setCurrentPopUp(PrescriberPopUp.LOADER);
    const response = await getNPIPrescriber(reqParams);
    if (response && response.succeeded) {
      const defaultEmail =
        prescriber.emails.length > 0
          ? prescriber.emails.find((y: { isDefault: any }) => y.isDefault)
          : "";
      const newPrescriber = {
        ...response.items[0],
        email: defaultEmail ? defaultEmail.value : "",
        emails: prescriber.emails,
        eScript: prescriber.eScript,
      };
      await setSelectedPrescriber(newPrescriber);
      setCurrentPopUp(PrescriberPopUp.PRESCRIPBER_FOUND);
    } else {
      const localError = {
        errorCode:
          response?.error?.code ||
          response?.error?.errorCode ||
          response?.status,
        errorFlag: true,
        errorMessage: PRESCRIBER_SEARCH_DB_SERVICE_ERROR,
      };
      setError(localError);
      setCurrentPopUp(PrescriberPopUp.ERROR_POPUP);
    }
  };

  const searchNpiApi = async (reqParams: any) => {
    setCurrentPopUp(PrescriberPopUp.LOADER);
    const response = await getNPIPrescriber(reqParams);
    setCurrentPopUp(PrescriberPopUp.LOADER);
    if (response && response.succeeded) {
      const updatedList = response.items.map((x: any) => {
        return { ...x, prescriberName: x.firstName + "," + x.lastName };
      });
      if (updatedList.length > 0) {
        setSearchedResults(updatedList);
        changeSearchType(PrescriberPopUp.NATIONAL_REGISTRY_RESULT);
      } else {
        changeSearchType(PrescriberPopUp.NATIONAL_REGISTRY_NO_RESULT);
      }
    } else {
      let code;
      if (response?.data?.length === 0) {
        code = NO_PRESCRIBER_DATA_AVAIALABLE;
      } else {
        code =
          response?.error?.code ||
          response?.error?.errorCode ||
          response?.status;
      }
      const localError = {
        errorCode: code,
        errorFlag: true,
        errorMessage: PRESCRIBER_SEARCH_DB_SERVICE_ERROR,
      };
      setError(localError);
      setCurrentPopUp(PrescriberPopUp.ERROR_POPUP);
    }
  };

  const changeSearchType = (popUp: PrescriberPopUp) => {
    setCurrentPopUp(popUp);
  };

  const searchAgainButtonAction = () => {
    setCurrentPopUp(PrescriberPopUp.NATIONAL_REGISTRY_SEARCH);
  };

  const selectButtonAction = async (prescriber: IPrescriberInformation) => {
    serachNPIInNationalRegistries(prescriber);
  };

  useEffect(() => {
    searchAllPrescribers();
  }, []);

  useEffect(() => {
    if (currentPopUp === PrescriberPopUp.NATIONAL_REGISTRY_SEARCH) {
      if (states.length === 0) {
        fetchDropDownContent();
      }
    }
  }, [currentPopUp]);

  const popUpToDisplay = () => {
    let page: ReactNode;
    switch (currentPopUp) {
      case PrescriberPopUp.ERROR_POPUP:
        page = (
          <ErrorPopup
            errorPopupFlag={error?.errorFlag}
            handleBackButton={closePopUp}
            popUpStyles="errorPopup"
            errorMessage={error?.errorMessage}
            buttontitle="Done"
            showTitle={false}
            isShortSentence={true}
            errorCode={error?.errorCode}
            isAlreadyPopUpOpen={true}
          />
        );
        break;

      case PrescriberPopUp.LOADER:
        page = (
          <div
            className="inventory-pop-up-spinner"
            data-testid="inventory-pop-up-spinner"
            id="inventory-pop-up-spinner"
          >
            <LoadingSpinner />
          </div>
        );
        break;

      case PrescriberPopUp.NATIONAL_REGISTRY_NO_RESULT:
        page = (
          <NationalRegistryNoResult
            searchAgainButtonAction={searchAgainButtonAction}
          />
        );
        break;

      case PrescriberPopUp.NATIONAL_REGISTRY_RESULT:
        page = (
          <NationalRegistryResult
            nationalRegistries={searchedResults}
            selectButtonAction={successAndClose}
            selectedNpi={selectedNpi}
          />
        );
        break;

      case PrescriberPopUp.NATIONAL_REGISTRY_SEARCH:
        page = (
          <NationalRegistrySearch
            changeSearchType={changeSearchType}
            searchNpi={searchNpiApi}
            states={states}
            statesText={statesText}
          />
        );
        break;

      case PrescriberPopUp.PRESCRIPBER_FOUND:
        page = (
          <PrescriberFound
            changeSearchType={changeSearchType}
            prescriberInformation={selectedPrescriber}
            setPrescriberInformation={successAndClose}
          />
        );
        break;

      case PrescriberPopUp.SEARCH_PRESCRIBER:
        page = (
          <SearchPrescriber
            changeSearchType={changeSearchType}
            nationalRegistries={allNationalRegistries}
            selectButtonAction={selectButtonAction}
            selectedNpi={selectedNpi}
          />
        );
        break;
    }
    return page;
  };

  return (
    <div>
      <Grid>{popUpToDisplay()}</Grid>
    </div>
  );
};
