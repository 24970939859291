import { MultipleActionsProps } from "../newOrderWoundInfoStepper/newOrderWoundInfo.interface";

export const comorbiditiesData: MultipleActionsProps[] = [
  {
    value: "Diabetes",
    label: "Diabetes",
    selected: false,
  },
  {
    value: "Immunocompromised",
    label: "Immunocompromised",
    selected: false,
  },
  {
    code: "ESRD",
    value: "End-Stage Renal Disease (ESRD)",
    label: "End-Stage Renal Disease (ESRD)",
    selected: false,
  },
  {
    code: "PVD",
    value: "Peripheral vascular disease (PVD)",
    label: "Peripheral Vascular Disease (PVD)",
    selected: false,
  },
  {
    code: "PAD",
    value: "Peripheral arterial disease (PAD)",
    label: "Peripheral Arterial Disease (PAD)",
    selected: false,
  },
  {
    value: "Obesity",
    label: "Obesity",
    selected: false,
  },
  {
    value: "Smoking",
    label: "Smoking",
    selected: false,
  },

  {
    value: "Quadriplegic",
    label: "Quadriplegic",
    selected: false,
  },
  {
    value: "Paraplegic",
    label: "Paraplegic",
    selected: false,
  },
  {
    value: "Bedbound",
    label: "Bedbound",
    selected: false,
  },
  {
    value: "Wheelchair Dependent",
    label: "Wheelchair Dependent",
    code: "WD",
    selected: false,
  },
  {
    value: "Depression",
    label: "Depression",
    selected: false,
  },
  {
    value: "Not Applicable",
    label: "Not Applicable",
    selected: false,
  },
];
export const mockComorbiditiesData: MultipleActionsProps[] = [
  { value: "Diabetes", label: "Diabetes", selected: false },
  { value: "Immobility", label: "Immobility", selected: true },
  { value: "Immunocompromised", label: "Immunocompromised", selected: false },
  {
    value: "End-Stage Renal Disease (ESRD)",
    label: "End-Stage Renal Disease (ESRD)",
    selected: true,
  },
  {
    value: "Peripheral vascular disease (PVD)",
    label: "Peripheral vascular disease (PVD)",
    selected: false,
  },
  {
    value: "Peripheral arterial disease (PAD)",
    label: "Peripheral arterial disease (PAD)",
    selected: true,
  },
  { value: "Obesity", label: "Obesity", selected: false },
  { value: "Smoking", label: "Smoking", selected: true },
  { value: "Depression", label: "Depression", selected: false },
];
