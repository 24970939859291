import React from "react";
import { ExpressButton } from "../expressButton/expressButton.component";
import { Popup } from "../popup/popup.component";
import "./errorPopup.css";
import parse from "html-react-parser";
import { ErrorInfo } from "../errorInfo/errorInfo.component";

type Props = {
  popUpStyles: any;
  handleBackButton: any;
  errorPopupFlag: any;
  errorMessage: any;
  showTitle?: boolean;
  buttontitle?: string;
  isSupportPhoneRequired?: boolean;
  isShortSentence?: boolean;
  phone?: any;
  isSupportEmailRequired?: boolean;
  email?: any;
  errorTitle?: string;
  errorCode?: any;
  isErrorCodeVisible?: any;
  isAlreadyPopUpOpen?: boolean;
};

export interface IErrorPopUp {
  errorActionType?: string;
  errorCode: string;
  errorFlag: boolean;
  errorMessage: string;
  isSupportPhoneRequired?: boolean;
}

export let defaultErrorPopUp: IErrorPopUp = {
  errorCode: "",
  errorMessage: "",
  errorFlag: false,
  isSupportPhoneRequired: true,
};

const ErrorPopup = ({
  popUpStyles,
  handleBackButton,
  errorPopupFlag,
  errorMessage,
  showTitle = true,
  buttontitle = "Back",
  isSupportPhoneRequired = true,
  isShortSentence = false,
  phone = "1-800-275-4524 ext. 41858.",
  isSupportEmailRequired = false,
  email = "expressdigitalsupport@solventum.com",
  errorTitle = "Error",
  errorCode = "",
  isErrorCodeVisible = true,
  isAlreadyPopUpOpen = false,
}: Props) => {
  const showError = () => {
    return (
      <div
        style={{ padding: "16px", display: "grid" }}
        data-testid="error-pop-up"
        id="error-pop-up"
      >
        {showTitle ? (
          <div className="errorTitle">{errorTitle}</div>
        ) : (
          <div className="errorTitle"></div>
        )}
        <span
          className={
            isErrorCodeVisible
              ? "errorDetail errorCode-errorDetail"
              : "errorDetail errorCode-errorDetail-no-error-code"
          }
        >
          {errorMessage ? parse(errorMessage) : ""}
          {isShortSentence && <span> </span>}
          {!isShortSentence && (
            <span>
              <br></br>
            </span>
          )}
          {isSupportPhoneRequired && (
            <a className="phone-value" href={`tel:${phone}`}>
              {phone}
            </a>
          )}
          {isSupportEmailRequired && (
            <>
              {` or email us at `}
              <a
                className="email-value"
                data-testid="email-value"
                href={`mailto:${email}`}
              >
                {email}
              </a>
            </>
          )}
        </span>
        {isErrorCodeVisible &&
          (errorCode && errorCode !== "" ? (
            <ErrorInfo errorCode={errorCode} />
          ) : (
            <ErrorInfo errorCode={"0000"} />
          ))}
        <ExpressButton variant="contained" clickHandler={handleBackButton}>
          {buttontitle}
        </ExpressButton>
      </div>
    );
  };

  return (
    <>
      {isAlreadyPopUpOpen ? (
        showError()
      ) : (
        <Popup
          openFlag={errorPopupFlag}
          dialogParentClass={popUpStyles}
          closeHandler={handleBackButton}
        >
          {showError()}
        </Popup>
      )}
    </>
  );
};

export default ErrorPopup;
