import { ClickAwayListener, Tooltip, useMediaQuery } from "@mui/material";
import { IAlertToolTip } from "./alertToolTip.interface";
import AlertButton from "../alertButton/alertButton.component";
import { IPatientAlert } from "../../components/myPatients/patient.interface";
import { useContext, useEffect, useState } from "react";
import React from "react";
import "./alertToolTip.css";
import {
  InternalSignOnContextType,
  InternalSignOnContext,
} from "../../context/InternalSignOnContext";
import { Popup } from "../popup/popup.component";
import { Alerts } from "../../components/myPatients/patientAlerts/alerts/alerts.component";
import {
  MyPatientContext,
  MyPatientContextType,
} from "../../context/MyPatientContext";
import AlertButtonToolTip from "../alertButton/alertButtonToolTip.component";

export const AlertToolTip = ({
  children,
  componentClassName = "labelRoot",
  alertCount,
  alertData,
  tableData,
  ropn,
  navigateToOrderOverview,
  patientData,
  isMPD = false,
  patientAnalytics,
}: IAlertToolTip) => {
  const [toolTipOpen, setToolTipOpen] = useState(false);
  const [alertOpen, setAlertOpen] = React.useState<boolean>(false);
  const [selectedAlert, setselectedAlert] = useState<IPatientAlert>();
  const handleToolTipClose = () => {
    setToolTipOpen(false);
  };
  const myPatientObj = useContext<MyPatientContextType | null>(
    MyPatientContext
  );
  const internalSignOnObj = useContext<InternalSignOnContextType | null>(
    InternalSignOnContext
  );
  const isMobileScreen = useMediaQuery("(max-width:1200px)");
  useEffect(() => {
    setToolTipOpen(false);
  }, [internalSignOnObj?.isViewReportsScrolled]);

  const handleToolTipOpen = () => {
    setToolTipOpen(true);
  };

  function getArrowColor(alertData: IPatientAlert[]) {
    let alertName = alertData[alertData.length - 1].alertName;
    switch (alertName) {
      case "Pending Supply Order":
        return "#FFF0D4";
      case "Supplies Shipped":
        return "#E3F7E8";
      case "Shared Order":
        return "#F1F1FC";
      default:
        return "#FFF0F0";
    }
  }
  const closeAlertPopupReload = (reloadPatiantList: boolean = false) => {
    setAlertOpen(false);
    myPatientObj?.setReloadMyPatient(reloadPatiantList);
  };
  const handleAlertClick = (alert: IPatientAlert) => {
    setselectedAlert(alert);
    patientAnalytics(`${alert.alertName} Alert MyPatient`);
    setAlertOpen(true);
  };
  const closeAlertPopupNoReload = () => {
    setAlertOpen(false);
  };
  return (
    <>
      <div className={componentClassName}>
        {alertData && alertData.length > 0 && (
          <ClickAwayListener onClickAway={handleToolTipClose}>
            <Tooltip
              className="alertToolTipMain"
              classes={{
                tooltip: "alertToolTip",
                popper: "alertToolTipPopper",
              }}
              PopperProps={{
                disablePortal: false,
              }}
              slotProps={{
                arrow: {
                  style: {
                    color: getArrowColor(alertData),
                  },
                },
              }}
              onClose={handleToolTipClose}
              open={toolTipOpen}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              placement="top"
              arrow
              title={
                <div className="tooltip-content">
                  {alertData.map((alert) => {
                    return (
                      <>
                        {isMobileScreen ? (
                          <AlertButtonToolTip
                            alertData={alert}
                            onClick={() => {
                              !isMPD
                                ? navigateToOrderOverview(
                                    tableData,
                                    alert,
                                    ropn
                                  )
                                : handleAlertClick(alert);
                            }}
                            isAlertToolTip={true}
                          />
                        ) : (
                          <AlertButton
                            alertData={alert}
                            onClick={() => {
                              !isMPD
                                ? navigateToOrderOverview(
                                    tableData,
                                    alert,
                                    ropn
                                  )
                                : handleAlertClick(alert);
                            }}
                            isAlertToolTip={true}
                          />
                        )}
                      </>
                    );
                  })}
                </div>
              }
              data-testid="deliveryInfoTooltipTest"
            >
              <button
                className="alertCountButton"
                data-testid="alertCountButton"
                onClick={handleToolTipOpen}
              >
                {alertCount !== "0" ? alertCount : ""}
              </button>
            </Tooltip>
          </ClickAwayListener>
        )}
        <Popup
          dialogParentClass={
            selectedAlert?.alertName.replace(/\s+/g, "") + "alert"
          }
          openFlag={alertOpen}
          closeHandler={() => setAlertOpen(false)}
        >
          {
            <Alerts
              closePopUpAction={closeAlertPopupReload}
              data={selectedAlert!}
              patient={patientData!}
              closeAlertPopupNoReload={closeAlertPopupNoReload}
            />
          }
        </Popup>
      </div>
    </>
  );
};
