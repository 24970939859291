import { Grid, useMediaQuery } from "@mui/material";
import { useHistory } from "react-router-dom";
import OrderSummarySuccess from "../../../assets/OrderSummarySuccess.svg";
import { ExpressButton } from "../../../core/expressButton/expressButton.component";
import { GET_VAC_ORDER_SUCCESS_MESSAGE } from "../../../util/staticText";

const OrderSummaryHeader = ({ isVacOrderFromSSO }: any) => {
  const history = useHistory();
  const isIpadScreen = useMediaQuery(
    "(min-width:451px) and (max-width:1024px)"
  );
  const isMobileScreen = useMediaQuery("(max-width:600px)");
  function vacOrderSuccessMsg() {
    return (
      <p className="orderSuccessPara3" data-testid="successText3Test">
        {GET_VAC_ORDER_SUCCESS_MESSAGE}
      </p>
    );
  }
  function backToMyPatientBtn() {
    return (
      <ExpressButton
        clickHandler={() => {
          history.push("/home");
        }}
        parentClass="backtoPatientBtn"
        testId="OrderSucessTest"
        variant="contained"
      >
        Back to My Patients
      </ExpressButton>
    );
  }
  function backToSelectFacility() {
    return (
      <ExpressButton
        clickHandler={() => {
          history.push("/ssoRedirect");
        }}
        parentClass="backtoPatientBtn"
        testId="OrderSucessTest"
        variant="contained"
      >
        Return to Select a Facility
      </ExpressButton>
    );
  }
  return (
    <Grid
      className="orderSummaryForm"
      container
      display="flex"
      flexDirection="row"
    >
      <Grid xs={isMobileScreen ? 4 : isIpadScreen ? 2.5 : 1.5}>
        <div className="success-image" data-testid="successimgTest">
          <img src={OrderSummarySuccess} alt={OrderSummarySuccess} />
        </div>
      </Grid>
      <Grid xs={isMobileScreen ? 8 : isIpadScreen ? 8 : 9}>
        <Grid className="mobile-resp" display="flex" flexDirection="column">
          <Grid item>
            <p className="orderSuccessPara" data-testid="successTextTest">
              Success!
            </p>
          </Grid>
          <Grid item>
            <p className="orderSuccessPara2" data-testid="successText2Test">
              Your ActiV.A.C.™ order has been submitted.
            </p>
          </Grid>
          {!isMobileScreen && <Grid item>{vacOrderSuccessMsg()}</Grid>}
          {!isVacOrderFromSSO && !isMobileScreen && (
            <Grid className="orderSummaryBtn" item>
              {backToMyPatientBtn()}
            </Grid>
          )}
          {isVacOrderFromSSO && !isMobileScreen && (
            <Grid className="orderSummaryBtn" item>
              {backToSelectFacility()}
            </Grid>
          )}
        </Grid>
      </Grid>
      {isVacOrderFromSSO && isMobileScreen && (
        <>
          <Grid item>{vacOrderSuccessMsg()}</Grid>
          <Grid className="orderSummaryBtn" item>
            {backToSelectFacility()}
          </Grid>
        </>
      )}
      {isMobileScreen && !isVacOrderFromSSO && (
        <>
          <Grid item>{vacOrderSuccessMsg()}</Grid>
          <Grid className="orderSummaryBtn" item>
            {backToMyPatientBtn()}
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default OrderSummaryHeader;
