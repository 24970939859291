import React from "react";
import "./missingRxUploadFail.css";
import { ExpressButton } from "../../../../../core/expressButton/expressButton.component";
import { MissingRxSection } from "../missingRx.enum";
interface Props {
  errorMessage?: string;
  changePopUpSection: (newSection: MissingRxSection) => void;
  isSupportPhoneRequired?: boolean;
}

export const MissingRxUploadFail = ({
  changePopUpSection,
  errorMessage = "",
  isSupportPhoneRequired = true,
}: Props) => {
  return (
    <div className="failRxUpload">
      {errorMessage && errorMessage.length > 0 ? (
        <div className="failTextLight">
          <span className="customErrorMessage">
            {errorMessage}{" "}
            {isSupportPhoneRequired && (
              <a
                className="phone-value"
                data-testid="phone-value"
                href={`tel:+8002754524`}
              >
                (800) 275-4524 ext 41858.
              </a>
            )}
          </span>
        </div>
      ) : (
        <div className="failTextLight">
          <h4 className="failText">Failed to upload!</h4>
          The files must be in one of the format: JPG, GIF, JPEG, PNG, DOC,
          DOCX, TXT, PDF or RTF. Each file cannot exceed size of (10MB) in size.
        </div>
      )}
      <div className="failRxUploadDoneBtn" data-testid="failRxUploadDoneBtn">
        <ExpressButton
          parentClass="failRxUploadBtn"
          variant="contained"
          clickHandler={() =>
            changePopUpSection(
              errorMessage && errorMessage.length > 0
                ? MissingRxSection.CHANGE_PRESCRIBER_TYPE
                : MissingRxSection.RX_UPLOAD
            )
          }
        >
          Go Back
        </ExpressButton>
      </div>
    </div>
  );
};
