import { useHistory } from "react-router-dom";
import Table from "../../../../../core/customSortingTable/table.component";
import { makeCapitalEachWordInString } from "../../../../../util/utilityFunctions";
import "./territoryListTable.css";
import { columns } from "./territoryListTable.model";
import { ITerritoryDetailList } from "./territoryListTable.interface";

interface Props {
  sortedData: ITerritoryDetailList[];
  handleSorting: any;
  columns: any;
  openSaleUserDetails: Function;
  openTerritoryDetail: Function;
}

export const TerritoryListTable = ({
  columns,
  handleSorting,
  openSaleUserDetails,
  openTerritoryDetail,
  sortedData,
}: Props) => {
  const history = useHistory();
  return (
    <div
      className="territory-table_container"
      data-testid="territory-patient-list"
    >
      <Table
        tableClassName="territory-Users_table"
        tableColumns={columns}
        handleSorting={handleSorting}
      >
        {sortedData.length > 0 ? (
          <tbody>
            {sortedData.map((data: ITerritoryDetailList) => {
              return (
                <tr key={data.territoryCode}>
                  <td
                    className="territory-select-username-link"
                    onClick={() => openTerritoryDetail(data)}
                  >
                    Edit
                  </td>
                  <td className="territory-table-static-data">
                    {data.territoryCode}
                  </td>
                  <td className="territory-table-static-data">
                    {data.territoryName}
                  </td>
                  <td className="territory-table-static-data">
                    {data.roles.map((role: any) => {
                      return <tr>{role}</tr>;
                    })}
                  </td>
                  {data.salesPeople.length > 0 ? (
                    <td className="territory-status-blue">
                      {data.salesPeople.map((salesPersonObj: any) => {
                        return (
                          <tr
                            onClick={() =>
                              openSaleUserDetails(salesPersonObj, data)
                            }
                          >
                            {makeCapitalEachWordInString(
                              `${
                                salesPersonObj.lastName +
                                ", " +
                                salesPersonObj.firstName
                              }`
                            )}
                          </tr>
                        );
                      })}
                    </td>
                  ) : (
                    <td className="territory-table-static-data-Open">OPEN</td>
                  )}
                </tr>
              );
            })}
          </tbody>
        ) : null}
      </Table>
      {sortedData && sortedData.length === 0 && (
        <p className="no-territory-list-data">No Rows To Show.</p>
      )}
    </div>
  );
};
