import moment from "moment";
import { ValidationStatus } from "../../../core/interfaces/input.interface";
import { changeDateFormat } from "../../../util/utilityFunctions";
import { IFacilitySupplyOrderFilterData } from "./facilitySupplyOrdersFilter.interface";

export let defaultFacilitySupplyorderFilter: IFacilitySupplyOrderFilterData = {
  searchInput: {
    valid: ValidationStatus.VALID,
    value: "",
  },
  startDate: {
    valid: ValidationStatus.VALID,
    value: changeDateFormat(moment().subtract(30, "days").toString()),
  },
  endDate: {
    valid: ValidationStatus.VALID,
    value: changeDateFormat(moment().toString()),
  },
  orderType: {
    valid: ValidationStatus.VALID,
    value: "All order types",
  },
  orderStatus: {
    valid: ValidationStatus.VALID,
    value: "All statuses",
  },
};
