import "./dressingSupplies.css";
import {
  SpecialProduct,
  checkSelectedProdcutForType,
  getVacProductsWithout,
} from "../../../util/utilityFunctions";
import {
  NewOrderContext,
  NewOrderContextType,
} from "../../../context/NewOrderContext";
import { useContext } from "react";
import parse from "html-react-parser";
import { DressingSuppliesCondensed } from "./condensedForm/dressingSuppliesCondensed.component";
import { ExpressButton } from "../../../core/expressButton/expressButton.component";
import { useMemo, useState } from "react";
import { NewOrderValidator } from "../newOrder.validator";
import { Accessories } from "./accessories/accessories.component";
import { PRODUCT_TYPE_ACCESSORIES } from "../../../util/staticText";
import { IDressingSuppliesInfo } from "./dressingSupplies.interface";
import { VacCannister } from "./vacCannister/vacCannister.component";
import { VacDressingKit } from "./vacDressingKit/vacDressingKit.component";
import { DressingSuppliesLink } from "./dressingSuppliesLink/dressingSuppliesLink";
import { DressingSuppliesReviewOrder } from "./reviewOrder/dressingSuppliesReviewOrder.component";
import { getDeepClone } from "../../../util/ObjectFunctions";
import { ValidationStatus } from "../../../core/interfaces/input.interface";

export const DressingSupplies = ({
  dressingKit,
  canister,
  accessory,
  productInfo,
  editButtonClicked,
  isReviewOrder = false,
  isOrderSummary = false,
  orderId,
  primaryInsurance,
  setDressingKit,
  setCanister,
  setAccessory,
  Validator = new NewOrderValidator(),
  vacTherapyInformationData,
  vacAllProducts,
  vacProducts,
  cannisterProducts,
  accessories,
  defaultVacdressingKit,
}: IDressingSuppliesInfo) => {
  const [validator] = useState<NewOrderValidator>(Validator!);

  const [isDefaultClicked, setIsDefaultclicked] = useState(false);

  const newOrderObj = useContext<NewOrderContextType | null>(NewOrderContext);
  const [isPeelAndPlaceDressing, setIsPeelAndPlaceDressing] =
    useState<boolean>(false);

  useMemo(() => {
    // Check for primary insurance is Charity Care or not.
    // If it is Cahrity care then setting isCharityCareSelected as true.
    // So based on this variable it will update accesories list to dropdown.
    if (dressingKit.productName.value && vacAllProducts) {
      setIsPeelAndPlaceDressing(
        checkSelectedProdcutForType(
          vacAllProducts.items,
          SpecialProduct.PEELANDPLACE,
          dressingKit.productName.value
        )
      );
    } else {
      setIsPeelAndPlaceDressing(false);
    }
  }, [dressingKit.productName, vacAllProducts]);

  const editSelectionButtonClicked = () => {
    setIsDefaultclicked(false);
    newOrderObj?.setIsCondensedDressing!(false);
  };
  const handleApplyButtonClick = () => {
    let dressingTemp = getDeepClone(dressingKit);
    Object.keys(dressingTemp).forEach((x: string) => {
      if (
        dressingTemp[x].isOptional === true &&
        dressingTemp[x].valid !== ValidationStatus.VALID
      ) {
        dressingTemp[x].valid = ValidationStatus.VALID;
      } else if (dressingTemp[x].valid === ValidationStatus.UNTOUCHED) {
        dressingTemp[x].valid = ValidationStatus.INVALID;
      }
    });
    setDressingKit(dressingTemp);
    const ifDressingValid = Object.keys(dressingTemp)
      .filter((x) => dressingTemp[x].valid)
      .every((x: string) => dressingTemp[x].valid === ValidationStatus.VALID);
    if (ifDressingValid) {
      newOrderObj?.setIsCondensedDressing!(true);
      scrollCondensedFile();
    }
  };

  const scrollCondensedFile = () => {
    newOrderObj?.setScrollableComponentClassName("dressingMain-component");
  };

  return (
    <div className="dressingMain-component">
      {isReviewOrder ? (
        <DressingSuppliesReviewOrder
          dressing={dressingKit}
          canister={canister}
          accesssory={accessory}
          editButtonClicked={editButtonClicked}
          isOrderSummary={isOrderSummary}
          newOrderObj={newOrderObj}
          isFromReadyCare={
            productInfo.productInformation.value === "yes" ? true : false
          }
        />
      ) : (
        <div className="dressingMain">
          <h2 className="dressingHeader" data-testid="dressingHeaderTest">
            Dressings/Supplies
          </h2>
          <p className="dressingBody">
            {parse(
              "A Letter of Medical Necessity must be completed by the clinician if the clinical condition of the wound(s) requires more than 15 dressings per wound or 10 canisters per month or if 3M™ V.A.C.<sup>®</sup> Therapy is required for greater than 4 months."
            )}
            <br />
            <br />
            The following supplies will be provided to the patient with the
            rental product.
          </p>
          <DressingSuppliesLink
            vacTherapyInformationData={vacTherapyInformationData}
          />

          {newOrderObj?.isCondensedDressing && (
            <DressingSuppliesCondensed
              dressing={dressingKit}
              canister={canister}
              accesssory={accessory}
              editButtonClicked={editSelectionButtonClicked}
              newOrderObj={newOrderObj}
              isFromReadyCare={
                productInfo.productInformation.value === "yes" ? true : false
              }
            />
          )}
          <div
            className={
              !newOrderObj?.isCondensedDressing ? "unCondensedDressingMain" : ""
            }
          >
            <div className="unCondensedDressingMainSub">
              {!newOrderObj?.isCondensedDressing && (
                <h2
                  className="dressing-supplies-title"
                  data-testid="dressing-supplies-title"
                >
                  Choose Dressings & Supplies
                </h2>
              )}

              <VacDressingKit
                data={dressingKit}
                productInfo={productInfo}
                primaryInsurance={primaryInsurance}
                setData={setDressingKit}
                vacProducts={vacProducts}
                vacAllProducts={vacAllProducts}
                Validator={validator}
                isDefaultClicked={isDefaultClicked}
                defaultVacdressingKit={defaultVacdressingKit}
              />
              <VacCannister
                cannisterProducts={cannisterProducts}
                data={canister}
                productInfo={productInfo}
                setData={setCanister}
                isDefaultClicked={isDefaultClicked}
              />
              <Accessories
                accessoriesList={
                  isPeelAndPlaceDressing
                    ? getVacProductsWithout(
                        vacAllProducts?.items ?? [],
                        PRODUCT_TYPE_ACCESSORIES,
                        SpecialProduct.WHITEFOAM
                      )
                    : accessories ?? []
                }
                data={accessory}
                dressingKit={dressingKit}
                orderId={orderId}
                setData={setAccessory}
                vacAllProducts={vacAllProducts}
              />
              {!newOrderObj?.isCondensedDressing && (
                <div className="unCondensedDressingbtnMain">
                  <ExpressButton
                    variant="text"
                    clickHandler={() => {
                      newOrderObj?.setIsCondensedDressing!(true);
                      newOrderObj?.setIsDefaultDressingSelection!(true);
                      scrollCondensedFile();
                    }}
                    parentClass="dressing-useDefaultDressingBtn"
                  >
                    Use default selection
                  </ExpressButton>{" "}
                  <ExpressButton
                    variant="outlined"
                    clickHandler={handleApplyButtonClick}
                  >
                    Apply
                  </ExpressButton>{" "}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
