import { ValidationStatus } from "../../../../../core/interfaces/input.interface";
import { getDeepClone } from "../../../../../util/ObjectFunctions";
import { MultipleActionsProps } from "../../../../confirmPlacement/confirmPlacement.interface";
import {
  IManageSiteStatus,
  MessageType,
  StatusType,
} from "../../manageSiteStatuses.interface";
import { IAddSiteStatus } from "../addSiteStatus.interface";
import { defaultAddSiteStatusData } from "../addSiteStatus.model";
import { AddSiteStatusValidator } from "../addSiteStatus.validator";
import { MessageTypeContent } from "../siteStatusContent/messageType.enum";
import { siteStatusDisplayAudienceData } from "../siteStatusDisplayAudience/siteStatusDisplayAudience.data";
import {
  MultipleActionCheckBoxProps,
  siteStatusLocationData,
} from "../siteStatusLocation/siteStatusLocation.data";

const getAudiancePermsisions = (resobj: IManageSiteStatus) => {
  let audianceOptions: MultipleActionCheckBoxProps[] = getDeepClone(
    siteStatusDisplayAudienceData
  );
  let selectedOptionCount = 0;
  if (resobj.showToUnAuthenticatedUsers) {
    const baseUser = audianceOptions[1];
    baseUser.selected = true;
    selectedOptionCount += 1;
  }
  if (resobj.showToExternalUsers) {
    const externalUser = audianceOptions[2];
    externalUser.selected = true;
    selectedOptionCount += 1;
  }
  if (resobj.showToInternalUsers) {
    const internalUser = audianceOptions[3];
    internalUser.selected = true;
    selectedOptionCount += 1;
  }
  if (selectedOptionCount === 3) {
    const allUsers = audianceOptions[0];
    allUsers.selected = true;
    while (selectedOptionCount > 0) {
      const user = audianceOptions[selectedOptionCount];
      user.disabled = true;
      selectedOptionCount -= 1;
    }
  }
  return audianceOptions;
};

const getLocationPermsisions = (resobj: IManageSiteStatus) => {
  let defaultLocationData: MultipleActionCheckBoxProps[] = getDeepClone(
    siteStatusLocationData
  );
  if (resobj.applyToLogin && resobj.applyToLogin > 0) {
    const loginValue = defaultLocationData[0];
    loginValue.selected = true;
    const cannotClearLoginValue = defaultLocationData[1];
    cannotClearLoginValue.selected = resobj.applyToLogin === 2;
    cannotClearLoginValue.disabled = false;
  }
  if (resobj.applyToFull && resobj.applyToFull > 0) {
    const fullValue = defaultLocationData[2];
    fullValue.selected = true;
    const cannotClearFullValue = defaultLocationData[3];
    cannotClearFullValue.selected = resobj.applyToFull === 2;
    cannotClearFullValue.disabled = false;
  }
  return defaultLocationData;
};

export const addSiteStatusResponseMapper = (
  siteStatusResponseData: IManageSiteStatus,
  Validator = new AddSiteStatusValidator()
): IAddSiteStatus => {
  const defaultSiteStatusDetails: IAddSiteStatus = getDeepClone(
    defaultAddSiteStatusData
  );
  const siteStatusDetails: IAddSiteStatus = {
    ...defaultSiteStatusDetails,
    id: {
      ...defaultSiteStatusDetails.statusType,
      value: siteStatusResponseData.id,
      valid: ValidationStatus.VALID,
    },
    statusType: {
      ...defaultSiteStatusDetails.statusType,
      value: siteStatusResponseData.statusType.toLowerCase(),
      valid: ValidationStatus.VALID,
    },
    activation: {
      ...defaultSiteStatusDetails.activation,
      valid: ValidationStatus.VALID,
      value: siteStatusResponseData.isActive
        ? siteStatusResponseData.isActive === true
          ? "yes"
          : "no"
        : "",
    },
    location: {
      ...defaultSiteStatusDetails.location,
      value: getLocationPermsisions(siteStatusResponseData),
      valid:
        siteStatusResponseData?.applyToLogin == null &&
        siteStatusResponseData.applyToFull == null
          ? ValidationStatus.INVALID
          : ValidationStatus.VALID,
    },
    audience: {
      ...defaultSiteStatusDetails.audience,
      value: getAudiancePermsisions(siteStatusResponseData),
      valid: ValidationStatus.VALID,
    },
    title: {
      ...defaultSiteStatusDetails.title,
      valid: validate(Validator, "title", siteStatusResponseData.title)!,
      value: siteStatusResponseData.title,
    },
    detail: {
      ...defaultSiteStatusDetails.detail,
      valid: validate(Validator, "detail", siteStatusResponseData.detail)!,
      value: siteStatusResponseData.detail,
    },
    urlLink: {
      ...defaultSiteStatusDetails.urlLink,
      valid: validate(Validator, "urlLink", siteStatusResponseData.urlLink)!,
      value: siteStatusResponseData.urlLink,
    },
    linkText: {
      ...defaultSiteStatusDetails.linkText,
      valid: validate(Validator, "linkText", siteStatusResponseData.linkText)!,
      value: siteStatusResponseData.linkText,
    },
    messageFormat: {
      ...defaultSiteStatusDetails.messageFormat,
      valid: ValidationStatus.VALID,
      value:
        siteStatusResponseData.messageType === MessageType.ALERT
          ? MessageTypeContent.SITE_STATUS_ALERT
          : siteStatusResponseData.messageType === MessageType.INFORMATION
          ? MessageTypeContent.SITE_STATUS_INFORMATION
          : MessageTypeContent.SITE_STATUS_WARNING,
    },
  };
  return siteStatusDetails;
};

const validate = (
  validator: AddSiteStatusValidator,
  Name: string,
  Value: string
) => {
  let notNullValue = Value === null ? "" : Value;
  const Status = validator?.validate(notNullValue, Name)?.status;
  return Status === ValidationStatus.INVALID
    ? ValidationStatus.UNTOUCHED
    : Status;
};
