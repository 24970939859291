import { Button, Grid } from "@mui/material";
import HomeCareProviderSearchDropDown from "./homeCareProviderSearchDropDown.component";
import "./homeCareSearch.css";
import { SearchHomeCareProviderModal } from "./searchHomeCare.enum";

interface Props {
  handleHomeCareProviderSearchType: any;
  clearSearchData: any;
  displayAddManualHomeCare: any;
  homeCareProviderTextBox: any;
  showHomeCareProviderCrossIcon: any;
  setShowHomeCareProviderCrossIcon: any;
  showNoResults: any;
  showHomeCareProviderOption: any;
  setShowHomeCareProviderOption: any;
  homeCareProviderList: any;
  validateAndSetData: any;
  handleSelectOption: any;
  enabledHCPFromMyList?: any;
}

const SearchHomeCareProviderChild = ({
  handleHomeCareProviderSearchType,
  clearSearchData,
  displayAddManualHomeCare,
  homeCareProviderTextBox,
  showHomeCareProviderCrossIcon,
  setShowHomeCareProviderCrossIcon,
  showNoResults,
  showHomeCareProviderOption,
  setShowHomeCareProviderOption,
  homeCareProviderList,
  validateAndSetData,
  handleSelectOption,
  enabledHCPFromMyList,
}: Props) => {
  return (
    <div>
      <Grid className="homecareprovider-informantion-item" container>
        <Grid item xs={6}>
          <div className="home-care-provider-search">
            <h5
              className="home-care-provider-search-label"
              data-testid="homeCareProviderSearchLabel"
            >
              Search My Home Care Provider List
            </h5>
          </div>
        </Grid>
        <Grid item xs={6} className="mobile-view-alignment">
          <div className="facilities-database-button-mobile-view">
            <Button
              onClick={() =>
                handleHomeCareProviderSearchType(
                  SearchHomeCareProviderModal.FACILITY_DATABASE_SEARCH
                )
              }
              classes={{ root: "facilities-database-button" }}
              data-testid="facilities-database-button"
            >
              Search Facilities Database instead
            </Button>
          </div>
        </Grid>
      </Grid>
      <HomeCareProviderSearchDropDown
        clearSearchData={clearSearchData}
        displayAddManualHomeCare={displayAddManualHomeCare}
        homeCareProviderTextBox={homeCareProviderTextBox}
        showHomeCareProviderCrossIcon={showHomeCareProviderCrossIcon}
        setShowHomeCareProviderCrossIcon={setShowHomeCareProviderCrossIcon}
        showNoResults={showNoResults}
        showHomeCareProviderOption={showHomeCareProviderOption}
        setShowHomeCareProviderOption={setShowHomeCareProviderOption}
        homeCareProviderList={homeCareProviderList}
        validateAndSetData={validateAndSetData}
        handleSelectOption={handleSelectOption}
        enabledHCPFromMyList={enabledHCPFromMyList}
      />
    </div>
  );
};

export default SearchHomeCareProviderChild;
