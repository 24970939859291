import React, { useState, useEffect, useContext } from "react";
import { Navigator } from "../../helpAndSupport/Navigator/navigator.component";
import SearchIcon from "@mui/icons-material/Search";
import { InputBase } from "@mui/material";
import { useDebounce } from "use-debounce";
import {
  CustomDropDown,
  DropDownValue,
} from "../../../core/customDropdown/customDropdown.component";
import "./SalesManageUsers.css";
import { useSortableTable } from "../../../util/utilityFunctions";
import { columns } from "./salesManageUserTable/saleManageUsersListTable.model";
import SalesManageUserTable from "./salesManageUserTable/salesManageUserTable.component";
import { useHistory } from "react-router-dom";
import { ISaleManageUserListTableData } from "./salesManageUserTable/saleManageUsersListTable.interface";
import {
  getSalesUsersForArea,
  getSalesUsersForRegion,
} from "../../../util/3meService";
import { Popup } from "../../../core/popup/popup.component";
import { LoadingSpinner } from "../../../core/loader/LoadingSpinner";
import { SendNoteFailure } from "../../send3MNote/popUps/failurePopUp/sendNoteFailure.component";
import { getUserTerritories } from "../../../util/userService";
import { AuthContext, AuthContextType } from "../../../context/AuthContext";
import { format } from "react-string-format";
import {
  DD_ALL_ROLES,
  GET_SALE_PEOPLE_LIST_FAILED,
} from "../../../util/staticText";
import { getdropDownContent } from "../../../util/dropDownService";
import {
  RolesPermissionContext,
  RolesPermissionContextType,
} from "../../../context/RolesPermissionContext";
import SalesRegions from "../salesRegionList/SalesRegionList.Component";
import {
  InternalSignOnContextType,
  InternalSignOnContext,
} from "../../../context/InternalSignOnContext";

type Props = {};

const SalesManageUsers = (props: Props) => {
  const [searchInput, setSearchInput] = useState<string>("");
  const [searchedInput, setSearchedInput] = useState<string>("");
  const [debouncedText] = useDebounce(searchInput, 500);
  const [selectedRoleType, setSelectedRoleType] = useState<string>("");
  const [selectedTerritoryType, setSelectedTerritoryType] =
    useState<string>("");
  const [sortData, setSortData, handleSortedData] = useSortableTable(
    [],
    columns
  );
  const [originalData, setOriginalData] = useState<
    ISaleManageUserListTableData[]
  >([]);
  const [openLoaderPopUp, setOpenLoaderPopUp] = useState<boolean>(false);
  const [failurePopUp, setFailurePopUp] = useState<boolean>(false);

  const [territoryText, setTerritoryText] = useState<string[]>([]);
  const [roleText, setRoleText] = useState<string[]>([]);
  const AuthObj = useContext<AuthContextType | null>(AuthContext);
  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );
  const internalObj = useContext<InternalSignOnContextType | null>(
    InternalSignOnContext
  );
  const history = useHistory();

  const handleSearch = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const re = /^[a-zA-Z0-9- ]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setSearchInput(e.target.value);
    }
  };

  const validateAndSetRoleData = (e: any) => {
    setSelectedRoleType(e.target.value);
    filterUsers(debouncedText, e.target.value, selectedTerritoryType);
  };

  const validateAndSetAreaData = (e: any) => {
    setSelectedRoleType("");
    setSearchInput("");
    setSelectedTerritoryType("");
    AuthObj?.setUserProfile({
      ...AuthObj?.userProfile!,
      area: e.target.value,
    });
  };

  const validateAndSetTerritoryData = (e: any) => {
    setSelectedTerritoryType(e.target.value);
    filterUsers(debouncedText, selectedRoleType, e.target.value);
  };

  const getSalesUsersList = async (regionCode: string) => {
    setOpenLoaderPopUp(true);
    const result: any = await Promise.all([
      getSalesUsersForRegion(regionCode),
      fetchDropDownContent(),
      getTerritories(),
    ]);
    setOpenLoaderPopUp(false);
    setOriginalData(result[0].data);
    setSortData(result[0].data);
  };

  const getSalesUsersListForArea = async (areaCode: string) => {
    setOpenLoaderPopUp(true);
    const result: any = await Promise.all([
      getSalesUsersForArea(areaCode),
      fetchDropDownContent(),
      getTerritories(),
    ]);
    setOpenLoaderPopUp(false);
    setOriginalData(result[0].data);
    setSortData(result[0].data);
  };

  const getTerritories = async () => {
    let reqBody;
    if (permissionObj?.mappedRolesPermissionData.IsProdManagerRole) {
      reqBody = {
        userName: AuthObj?.userProfile?.userName,
        area: AuthObj?.userProfile?.area,
      };
    } else {
      reqBody = {
        userName: AuthObj?.userProfile?.userName,
      };
    }

    const territories = await getUserTerritories(reqBody);
    if (territories !== undefined) {
      if (territories.length > 0) {
        let territoryObject: any = [];
        territories.map((x: { code: string; name: string }) => {
          const dropDownValue: DropDownValue = {
            code: x.code,
            text: `${
              x.name.toLowerCase() !== x.code.toLowerCase()
                ? `${x.code} ${x.name}`
                : ""
            }`,
          };
          territoryObject.push(dropDownValue);
        });
        const territoryTextObject = territories.map(
          (x: { name: string; code: string }) =>
            `${x.code}${
              x.name.toLowerCase() !== x.code.toLowerCase()
                ? " - " + x.name
                : ""
            }`
        );
        setTerritoryText(territoryTextObject);
      }
    }
  };

  const fetchDropDownContent = async () => {
    try {
      const ddContent = format("{0}", DD_ALL_ROLES ?? "");
      const data = await getdropDownContent(ddContent);
      if (data.items.length > 0) {
        const roleTypeObject = data.items.filter(
          (item: { name: string }) => item.name === DD_ALL_ROLES
        );
        const roleTypeData = roleTypeObject[0].data.sort(
          (a: { order: number }, b: { order: number }) =>
            a.order > b.order ? 1 : -1
        );
        setRoleText(roleTypeData.map((x: { text: string }) => x.text));
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const closeFailurePopUp = () => {
    setFailurePopUp(false);
    history.push("/administration");
  };

  useEffect(() => {
    if (
      AuthObj?.userProfile?.area &&
      (permissionObj?.mappedRolesPermissionData?.IsProdManagerRole ||
        permissionObj?.mappedRolesPermissionData?.IsSalesManagerRole)
    ) {
      getSalesUsersListForArea(AuthObj?.userProfile.area);
    }
  }, [AuthObj?.userProfile]);

  const filterUsers = (
    searchParam: string,
    role: string,
    territory: string
  ) => {
    setSearchedInput(searchParam);
    let searchRole = "";
    let searchTerritory: any = "";
    if (role !== "All roles") {
      searchRole = role;
    }
    if (territory !== "All territories") {
      searchTerritory = territory;
    }
    if (originalData && originalData.length > 0) {
      let filtedUsers = originalData;
      if (searchRole.length > 0) {
        filtedUsers = filtedUsers.filter((user: ISaleManageUserListTableData) =>
          user.roles.includes(searchRole)
        );
      }
      if (searchTerritory.length > 0) {
        filtedUsers = filtedUsers.filter((user: ISaleManageUserListTableData) =>
          user.territories.includes(searchTerritory)
        );
      }
      if (searchParam.length > 0) {
        filtedUsers = filtedUsers.filter(
          (user: ISaleManageUserListTableData) =>
            user.firstName
              .toLocaleLowerCase()
              .includes(searchParam.toLowerCase()) ||
            user.lastName
              .toLocaleLowerCase()
              .includes(searchParam.toLowerCase()) ||
            user.territories
              .toString()
              .toLocaleLowerCase()
              .includes(searchParam.toLowerCase())
        );
      }
      setSortData(filtedUsers);
    }
  };

  const openUserDetails = (data: ISaleManageUserListTableData) => {
    history.push({
      pathname: "/administration/salesManageUsers/saleUserProfile",
      state: {
        selectedSalePeople: data,
      },
    });
  };

  useEffect(() => {
    if (
      (debouncedText.length === 0 && searchedInput !== debouncedText) ||
      debouncedText.length > 0
    ) {
      filterUsers(debouncedText, selectedRoleType, selectedTerritoryType);
    }
  }, [debouncedText]);

  return (
    <div className="sale-manage-users-component-container">
      <div
        className="sale-manage-users-component"
        data-testid="sale-manage-users-component"
      >
        <Navigator
          array={[
            {
              route: internalObj?.isInternalAdministration
                ? "/internalAdministration"
                : "/administration",
              pageName: "Administration",
            },
          ]}
          className="sale-manage-users-component-route-section"
          title="Manage Salespeople"
        />
        <div className="sale-manage-users-main-section">
          <span
            className="sale-manage-users-main-section-header"
            data-testid="sale-manage-users-main-section-header"
          >
            Manage Salespeople
          </span>
        </div>
        {permissionObj?.mappedRolesPermissionData.IsProdManagerRole && (
          <div className="select-area-div">
            <SalesRegions
              validateAndSetAreaData={validateAndSetAreaData}
              selectedRegionType={AuthObj?.userProfile?.area}
            />
          </div>
        )}

        <div className="sale-filter-mng-usr">
          <div className="sale-user-searchbar">
            <div className="sale-search-icon-div">
              <SearchIcon className="sale-search-icon" />
            </div>
            <InputBase
              className="sale-user-search-input"
              data-testid="sale-user-search-input"
              name="search-input"
              onChange={handleSearch}
              placeholder="Filter by Name or Territory"
              value={searchInput}
            />
          </div>
          <CustomDropDown
            handleChange={validateAndSetRoleData}
            menuItem={["All roles"].concat(roleText)}
            name="sale-user-role"
            placeHolder="All roles"
            selectpropsClassName={
              selectedRoleType ? "sale-user-status-select" : "placeHolder"
            }
            selectClassName={
              selectedRoleType ? "sale-user-status-input" : "placeHolder"
            }
            testId="sale-user-status"
            value={selectedRoleType ? selectedRoleType : null}
          />
          <CustomDropDown
            handleChange={validateAndSetTerritoryData}
            menuItem={["All territories"].concat(territoryText)}
            name="sale-user-territories"
            placeHolder="All territories"
            selectpropsClassName={
              selectedTerritoryType ? "sale-user-status-select" : "placeHolder"
            }
            selectClassName={
              selectedTerritoryType ? "sale-user-status-input" : "placeHolder"
            }
            testId="sale-user-territories"
            value={selectedTerritoryType ? selectedTerritoryType : null}
          />
        </div>
        <div className="sale-users-table">
          <SalesManageUserTable
            columns={columns}
            handleSorting={handleSortedData}
            openUserDetails={openUserDetails}
            sortedData={sortData}
          />
        </div>
      </div>
      <Popup
        openFlag={openLoaderPopUp}
        closeHandler={() => setOpenLoaderPopUp(false)}
        dialogParentClass={"sale-fetch-users-loader-pop-up"}
        data-testid="sale-fetch-users-pop-up"
        hideCloseButton={true}
      >
        <div className="sale-fetch-users-loader">
          <LoadingSpinner />
        </div>
      </Popup>
      <Popup
        openFlag={failurePopUp}
        closeHandler={closeFailurePopUp}
        dialogParentClass={"sale-fetch-users-failure-pop-up"}
        data-testid="sale-fetch-users-failure-pop-up"
      >
        <div className="sale-fetch-users-failure-pop-up-div">
          <SendNoteFailure
            backButtonAction={closeFailurePopUp}
            message={GET_SALE_PEOPLE_LIST_FAILED}
          />
        </div>
      </Popup>
    </div>
  );
};

export default SalesManageUsers;
