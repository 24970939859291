import { useState } from "react";
import "./nationalRegistrySearch.css";
import { Button, Grid, InputBase, useMediaQuery } from "@mui/material";
import { getDeepClone } from "../../../../../../../util/ObjectFunctions";
import { PrescriberPopUp } from "../../prescriberInformationPopUpContainer.enum";
import { NewOrderValidator } from "../../../../../../newOrder/newOrder.validator";
import { ValidationStatus } from "../../../../../../../core/interfaces/input.interface";
import { ExpressButton } from "../../../../../../../core/expressButton/expressButton.component";
import { InputWithLabel } from "../../../../../../../core/inputWithLabel/inputWithLabel.component";
import {
  defaultNationalRegistrySearchBox,
  defaultNationalRegistrySearchByDetails,
  INationalRegistrySearch,
  INationalRegistrySearchByDetails,
} from "../../../../../../newOrder/prescriberInformation/nationalRegistrySearch/nationRegistrySearch.model";
import NationalRegistrySearchByDetails from "../../../../../../newOrder/prescriberInformation/nationalRegistrySearch/nationalRegistrySearchByDetails/nationalRegistrySearchByDetails.component";

export interface INationalRegistrySearchProps {
  changeSearchType: Function;
  searchNpi: Function;
  states: never[];
  statesText: never[];
}

export const NationalRegistrySearch = ({
  changeSearchType,
  searchNpi,
  states,
  statesText,
}: INationalRegistrySearchProps) => {
  const isSmallMobileScreen = useMediaQuery("(max-width:485px)");
  const isMobileScreen = useMediaQuery("(max-width:600px)");
  const [nationalRegistrySearchInputs, setNationalRegistrySearchInputs] =
    useState<INationalRegistrySearch>(
      getDeepClone(defaultNationalRegistrySearchBox)
    );
  const [nationRegistrySearchByDetails, setNationRegistrySearchByDetails] =
    useState<INationalRegistrySearchByDetails>(
      getDeepClone(defaultNationalRegistrySearchByDetails)
    );

  const validateAndSetData = (e: any) => {
    setNationRegistrySearchByDetails(
      getDeepClone(defaultNationalRegistrySearchByDetails)
    );
    const validator = new NewOrderValidator();
    const isvalid = validator.validate(e.target.value, e.target.name);
    setNationalRegistrySearchInputs((nationalRegistrySearchInputs) => ({
      ...nationalRegistrySearchInputs,
      [e.target.name]: { value: e.target.value, valid: isvalid?.status },
    }));
  };

  const checkValidationStatus = () => {
    return nationalRegistrySearchInputs.nationalRegistryNumber.valid !==
      ValidationStatus.VALID
      ? true
      : false;
  };

  const handleSearchNPI = () => {
    let reqParams = {
      NPI: nationalRegistrySearchInputs.nationalRegistryNumber.value,
      FirstName: nationRegistrySearchByDetails.NPIFirstName.value,
      LastName: nationRegistrySearchByDetails.NPILastName.value,
      State: nationRegistrySearchByDetails.state.value,
    };
    searchNpi(reqParams);
  };

  return (
    <div
      className="national-registry-search-component"
      data-testid="national-registry-search-component"
    >
      <p
        className="national-registry-search-header"
        data-testid="national-registry-search-header"
        id="national-registry-search-header"
      >
        Prescriber Search
      </p>
      <Grid
        className="national-registry-search-grid-container"
        container
        spacing={2}
      >
        <Grid
          className="national-registry-search-grid-item"
          item
          xs={isSmallMobileScreen ? 12 : 6}
        >
          <p
            className="national-registry-search-header-title"
            data-testid="national-registry-search-header-title"
            id="national-registry-search-header-title"
          >
            Search in National Registry
          </p>
        </Grid>
        <Grid
          className="national-registry-search-grid-item"
          item
          xs={isSmallMobileScreen ? 12 : 6}
        >
          <Button
            classes={{
              root: "search-my-prescriber-list-instead-button",
            }}
            data-testid="search-my-prescriber-list-instead-button"
            id="search-my-prescriber-list-instead-button"
            onClick={() => changeSearchType(PrescriberPopUp.SEARCH_PRESCRIBER)}
          >
            Search My Prescriber List instead
          </Button>
        </Grid>
        <Grid
          className="national-registry-search-grid-item top-padding"
          item
          xs={isMobileScreen ? 12 : 9}
        >
          <InputWithLabel
            error={
              nationalRegistrySearchInputs.nationalRegistryNumber.valid ===
              ValidationStatus.INVALID
            }
            isRequired={true}
            label="Prescriber NPI Number"
            testId="national-registry-search-npi-title"
          >
            <InputBase
              autoFocus
              className="national-registry-search-npi-input"
              data-testid="national-registry-search-npi-input"
              id="national-registry-search-npi-input"
              inputProps={{ inputMode: "numeric", pattern: "[0-9*]" }}
              name="nationalRegistryNumber"
              onChange={validateAndSetData}
              type="text"
              value={nationalRegistrySearchInputs.nationalRegistryNumber.value}
            />
          </InputWithLabel>
        </Grid>
        <Grid
          className="national-registry-search-grid-item top-padding"
          item
          xs={isMobileScreen ? 12 : 3}
        >
          <ExpressButton
            clickHandler={handleSearchNPI}
            disabled={checkValidationStatus()}
            id="search-with-npi-button"
            parentClass="search-with-npi-button"
            testId="search-with-npi-button"
            variant="contained"
          >
            Search
          </ExpressButton>
        </Grid>
      </Grid>
      <div className="orDiv">OR</div>
      <NationalRegistrySearchByDetails
        nationRegistrySearchByDetails={nationRegistrySearchByDetails}
        setNationRegistrySearchByDetails={setNationRegistrySearchByDetails}
        states={states}
        statesText={statesText}
        setNationalRegistrySearchInputs={setNationalRegistrySearchInputs}
        handleSearchNPI={handleSearchNPI}
      />
    </div>
  );
};
