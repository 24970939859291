import { useContext, useEffect, useState } from "react";
import { AuthContext, AuthContextType } from "../../../../context/AuthContext";
import { Box, Grid, InputBase, useMediaQuery } from "@mui/material";
import { CustomCheckBox } from "../../../../core/checkBox/checkBox.component";
import { InputWithLabel } from "../../../../core/inputWithLabel/inputWithLabel.component";
import { ValidationStatus } from "../../../../core/interfaces/input.interface";
import { ExpressButton } from "../../../../core/expressButton/expressButton.component";
import { InpatientSupplyOrderValidator } from "../../inpatientSupplyOrder.validator";
import { ISupplyOrder } from "../../../supplyOrder/supplyOrder.interface";
import { IInpatientSupplyOrder } from "../../inpatientSupplyOrder.interface";
import { CustomAutoComplete } from "../../../../core/autoCompleteDropdown/autoCompleteDropdown.component";
import {
  getCodeFromText,
  getTextFromCode,
  makeCapitalEachWordInString,
} from "../../../../util/utilityFunctions";
import { format } from "react-string-format";
import { DD_US_STATES_CONTENT } from "../../../../util/staticText";
import { getdropDownContent } from "../../../../util/dropDownService";
import "./shippingAddress.css";

export interface IshippingAddrress {
  data: IInpatientSupplyOrder;
  setData: any;
  Validator?: InpatientSupplyOrderValidator;
  states: any;
  statesText: any;
}
export const ShippingAddress = ({
  data,
  setData,
  Validator = new InpatientSupplyOrderValidator(),
  states,
  statesText,
}: IshippingAddrress) => {
  const [validator] = useState<InpatientSupplyOrderValidator>(Validator!);
  const shippingAddressData = data?.shippingAddress;
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const isMobileScreen = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    if (authObj?.registeredFaciltyAddress) {
      if (
        shippingAddressData.isSameasFacilityAddress.value.toLowerCase() ===
        "yes"
      ) {
        updateRequesterBasicDetails();
      }
    }
  }, [authObj?.registeredFaciltyAddress]);

  const validateAndSetData = (e: any) => {
    let { value, name } = e.target;
    let isValid = validator.validate(value, name);
    let tempData = data;
    if (name === "shippingAddressZip") {
      tempData = {
        ...tempData,
        shippingAddress: {
          ...tempData.shippingAddress,
          [name]: {
            ...Object(tempData.shippingAddress)[name],
            errorMessage: null,
            valid: isValid!.status,
            value: value,
          },
        },
        deliveryInformation: {
          ...tempData.deliveryInformation,
          deliveryMethod: {
            ...tempData.deliveryInformation.deliveryMethod,
            value: "",
            valid: ValidationStatus.UNTOUCHED,
          },
        },
      };
    } else {
      tempData = {
        ...tempData,
        shippingAddress: {
          ...tempData.shippingAddress,
          [name]: {
            ...Object(tempData.shippingAddress)[name],
            errorMessage: null,
            valid: isValid!.status,
            value: value,
          },
        },
      };
    }

    setData(tempData);
  };

  const updateRequesterBasicDetails = () => {
    if (authObj && authObj.registeredFaciltyAddress) {
      const facilityName = authObj.registeredFaciltyAddress?.accountName!;
      const addressLine1 = authObj.registeredFaciltyAddress?.address1!;
      const addressLine2 = authObj.registeredFaciltyAddress?.address2!;
      const city = authObj.registeredFaciltyAddress?.city!;
      const state = authObj.registeredFaciltyAddress?.state!;
      const zip = authObj.registeredFaciltyAddress?.zip!;
      setData((dt: IInpatientSupplyOrder) => ({
        ...dt,
        shippingAddress: {
          isSameasFacilityAddress: {
            ...dt.shippingAddress.isSameasFacilityAddress,
            errorMessage: null,
            valid: ValidationStatus.VALID,
            value: "yes",
          },
          shippingFacilityName: {
            ...dt.shippingAddress.shippingFacilityName,
            errorMessage: null,
            valid:
              facilityName !== ""
                ? ValidationStatus.VALID
                : ValidationStatus.UNTOUCHED,
            value: facilityName,
          },
          shippingAddressLine1: {
            ...dt.shippingAddress.shippingAddressLine1,
            errorMessage: null,
            valid:
              addressLine1 !== ""
                ? validator.validate(addressLine1, "shippingAddressLine1")
                    ?.status
                : ValidationStatus.UNTOUCHED,
            value: addressLine1,
          },
          shippingAddressLine2: {
            ...dt.shippingAddress.shippingAddressLine2,
            errorMessage: null,
            required: false,
            valid: ValidationStatus.VALID,
            value: addressLine2,
          },
          shippingAddressCity: {
            ...dt.shippingAddress.shippingAddressCity,
            errorMessage: null,
            valid:
              city !== ""
                ? validator.validate(city, "shippingAddressCity")?.status
                : ValidationStatus.UNTOUCHED,
            value: city,
          },
          shippingAddressState: {
            ...dt.shippingAddress.shippingAddressState,
            errorMessage: null,
            valid:
              state && state !== ""
                ? validator.validate(state, "shippingAddressState")?.status
                : ValidationStatus.UNTOUCHED,
            value: state,
          },
          shippingAddressZip: {
            ...dt.shippingAddress.shippingAddressZip,
            errorMessage: null,
            valid: zip
              ? validator.validate(zip.toString(), "shippingAddressZip")?.status
              : ValidationStatus.UNTOUCHED,
            value: zip.toString(),
          },
        },
      }));
    }
  };

  const validateAndSetStateData = async (e: any, val: string, name: string) => {
    let value = getCodeFromText(states, val);
    let isValid = validator.validate(value, name);
    setData((dt: IInpatientSupplyOrder) => ({
      ...dt,
      shippingAddress: {
        ...dt.shippingAddress,
        shippingAddressState: {
          ...Object(dt.shippingAddress)[name],
          errorMessage: null,
          valid: isValid!.status,
          value: value,
        },
      },
    }));
  };

  const handlecheckBoxChange = async (e: any) => {
    let { value, name } = e.target;
    if (!e.target.checked) {
      setData((dt: IInpatientSupplyOrder) => ({
        ...dt,
        shippingAddress: {
          ...dt.shippingAddress,
          [name]: {
            ...Object(dt.shippingAddress)[name],
            errorMessage: null,
            valid: ValidationStatus.VALID,
            value: "no",
          },
        },
      }));
    } else {
      updateRequesterBasicDetails();
    }
  };

  return (
    <div className="inpatient-supplyorder-shippingaddress-main-div">
      <div
        className="inpatient-supplyorder-shippingaddress-header"
        data-testid="inpatient-supplyorder-shippingaddress-header"
      >
        Confirm Shipping Address
      </div>
      <Box
        className="inpatient-supplyorder-shippingaddress-box-container"
        sx={{ flexGrow: 1 }}
      >
        <Grid
          className="inpatient-supplyorder-shippingaddress-grid-container"
          container
          spacing={1}
        >
          <Grid>
            <CustomCheckBox
              checked={
                shippingAddressData.isSameasFacilityAddress.value === "yes"
              }
              value={shippingAddressData.isSameasFacilityAddress.value}
              handleChange={handlecheckBoxChange}
              selectClassName="inpatient-supplyorder-shippingaddress-checkbox"
              selectpropsClassName="inpatient-supplyorder-shippingaddress-chkboxroot"
              labelClassName="inpatient-supplyorder-shippingaddress-descriptionlabel"
              labelText="Deliver to Facility Address"
              name="isSameasFacilityAddress"
              testId="isSameasFacilityAddressTest"
            />
          </Grid>
        </Grid>
      </Box>
      {shippingAddressData.isSameasFacilityAddress.value === "yes" ? (
        <div className="inpatient-supplyorder-shippingaddress-condensed">
          <div className="sub-content-div">
            <h5
              className="inpatient-supplyorder-shippingaddress-condensed-content-value"
              data-testid="inpatient-supplyorder-shippingaddress-facility-name-value"
            >
              {makeCapitalEachWordInString(
                shippingAddressData.shippingFacilityName.value !== ""
                  ? shippingAddressData.shippingFacilityName.value
                  : "--"
              )}
            </h5>
          </div>
          <div className="sub-content-div">
            {shippingAddressData.shippingAddressLine1.value !== "" ||
            shippingAddressData.shippingAddressCity.value !== "" ||
            shippingAddressData.shippingAddressState.value !== "" ? (
              <>
                {shippingAddressData.shippingAddressLine1.value &&
                  shippingAddressData.shippingAddressLine1.value !== "" && (
                    <h5
                      className="inpatient-supplyorder-shippingaddress-condensed-content-value"
                      data-testid="inpatient-supplyorder-shippingaddress-address1-value"
                    >
                      {makeCapitalEachWordInString(
                        shippingAddressData.shippingAddressLine1.value
                      )}
                    </h5>
                  )}
                {shippingAddressData.shippingAddressLine2.value &&
                  shippingAddressData.shippingAddressLine2.value !== "" && (
                    <h5
                      className="inpatient-supplyorder-shippingaddress-condensed-content-value"
                      data-testid="inpatient-supplyorder-shippingaddress-address2-value"
                    >
                      {makeCapitalEachWordInString(
                        shippingAddressData.shippingAddressLine2.value
                      )}
                    </h5>
                  )}
                {shippingAddressData.shippingAddressCity.value &&
                  shippingAddressData.shippingAddressCity.value !== "" &&
                  shippingAddressData.shippingAddressState.value &&
                  shippingAddressData.shippingAddressState.value !== "" && (
                    <h5
                      className="inpatient-supplyorder-shippingaddress-condensed-content-value"
                      data-testid="inpatient-supplyorder-shippingaddress-city-state-zip-value"
                    >
                      {`${makeCapitalEachWordInString(
                        shippingAddressData.shippingAddressCity.value
                      )}${
                        shippingAddressData.shippingAddressCity.value !== ""
                          ? ", "
                          : ""
                      }${shippingAddressData.shippingAddressState.value} ${
                        shippingAddressData.shippingAddressZip.value === 0
                          ? "--"
                          : shippingAddressData.shippingAddressZip.value
                      }`}
                    </h5>
                  )}
              </>
            ) : (
              <h5
                className="inpatient-supplyorder-shippingaddress-condensed-content-value"
                data-testid="address2-value"
              >
                {"--"}
              </h5>
            )}
          </div>
        </div>
      ) : (
        <>
          <div className="inpatient-supplyorder-shippingaddress-Inputs">
            <InputWithLabel
              testId="shippingFacilityName-label"
              label={shippingAddressData.shippingFacilityName.title}
              warningMessage={
                shippingAddressData.shippingFacilityName.errorMessage
              }
              isRequired={shippingAddressData.shippingFacilityName.required}
              id={shippingAddressData.shippingFacilityName.componentId}
              error={
                shippingAddressData.shippingFacilityName.valid ===
                ValidationStatus.INVALID
              }
              isShowWarning={true}
            >
              <InputBase
                className="inpatient-supplyorder-shippingaddress-Input"
                name="shippingFacilityName"
                onChange={validateAndSetData}
                value={shippingAddressData.shippingFacilityName.value}
                id="shippingFacilityNameid"
                data-testid="shippingFacilityNametest"
              />
            </InputWithLabel>
            <Grid
              columnSpacing={2}
              container
              style={{ marginTop: "8px" }}
              rowSpacing={1}
            >
              <Grid item xs={isMobileScreen ? 12 : 6}>
                <InputWithLabel
                  testId="shippingAddressLine1-label"
                  label={shippingAddressData.shippingAddressLine1.title}
                  warningMessage={
                    shippingAddressData.shippingAddressLine1.errorMessage
                  }
                  isRequired={shippingAddressData.shippingAddressLine1.required}
                  id={shippingAddressData.shippingAddressLine1.componentId}
                  error={
                    shippingAddressData.shippingAddressLine1.valid ===
                    ValidationStatus.INVALID
                  }
                  isShowWarning={true}
                >
                  <InputBase
                    name="shippingAddressLine1"
                    className="inpatient-supplyorder-shippingaddress-Input"
                    onChange={validateAndSetData}
                    value={shippingAddressData.shippingAddressLine1.value}
                    id="shippingAddressLine1"
                    data-testid="shippingAddressLine1test"
                  />
                </InputWithLabel>
              </Grid>
              <Grid item xs={isMobileScreen ? 12 : 6}>
                <InputWithLabel
                  testId="shippingAddressLine2-label"
                  label={shippingAddressData.shippingAddressLine2.title}
                  warningMessage={
                    shippingAddressData.shippingAddressLine2.errorMessage
                  }
                  isRequired={shippingAddressData.shippingAddressLine2.required}
                  id={shippingAddressData.shippingAddressLine2.componentId}
                  error={
                    shippingAddressData.shippingAddressLine2.valid ===
                    ValidationStatus.INVALID
                  }
                  isShowWarning={true}
                >
                  <InputBase
                    name="shippingAddressLine2"
                    className="inpatient-supplyorder-shippingaddress-Input"
                    onChange={validateAndSetData}
                    value={shippingAddressData.shippingAddressLine2.value}
                    id="shippingAddressLine2"
                    data-testid="shippingAddressLine2test"
                  />
                </InputWithLabel>
              </Grid>
            </Grid>
            <Grid
              columnSpacing={2}
              container
              style={{ marginTop: "8px" }}
              rowSpacing={1}
            >
              <Grid item xs={isMobileScreen ? 12 : 6}>
                <InputWithLabel
                  testId="shippingAddressCity-label"
                  label={shippingAddressData.shippingAddressCity.title}
                  warningMessage={
                    shippingAddressData.shippingAddressCity.errorMessage
                  }
                  isRequired={shippingAddressData.shippingAddressCity.required}
                  id={shippingAddressData.shippingAddressCity.componentId}
                  error={
                    shippingAddressData.shippingAddressCity.valid ===
                    ValidationStatus.INVALID
                  }
                  isShowWarning={true}
                >
                  <InputBase
                    name="shippingAddressCity"
                    required={shippingAddressData.shippingAddressCity.required}
                    className="inpatient-supplyorder-shippingaddress-Input"
                    onChange={validateAndSetData}
                    value={shippingAddressData.shippingAddressCity.value}
                    id="shippingAddressCity"
                    data-testid="shippingAddressCitytest"
                  />
                </InputWithLabel>
              </Grid>
              <Grid item xs={isMobileScreen ? 6 : 3}>
                {statesText.length > 0 && (
                  <InputWithLabel
                    testId="shippingAddressState-test"
                    label={shippingAddressData.shippingAddressState.title}
                    warningMessage={
                      shippingAddressData.shippingAddressState.errorMessage
                    }
                    isRequired={
                      shippingAddressData.shippingAddressState.required
                    }
                    id={shippingAddressData.shippingAddressState.componentId}
                    error={
                      shippingAddressData.shippingAddressState.valid ===
                      ValidationStatus.INVALID
                    }
                  >
                    <CustomAutoComplete
                      handleChange={(e: any, val: string) =>
                        validateAndSetStateData(e, val, "shippingAddressState")
                      }
                      menuItem={statesText}
                      name="shippingAddressState"
                      placeHolder="Select State"
                      selectClassName={
                        shippingAddressData.shippingAddressState.valid ===
                        ValidationStatus.INVALID
                          ? "inpatient-supplyorder-shippingaddress-state-input inpatient-supplyorder-shippingaddress-state-error"
                          : "inpatient-supplyorder-shippingaddress-state-input"
                      }
                      selectpropsClassName="inpatient-supplyorder-shippingaddress-state-root"
                      value={
                        shippingAddressData.shippingAddressState.value
                          ? getTextFromCode(
                              states,
                              shippingAddressData.shippingAddressState.value
                            )
                          : ""
                      }
                      id="shippingAddressState"
                      testId="shippingAddressStatetest"
                    />
                  </InputWithLabel>
                )}
              </Grid>
              <Grid item xs={isMobileScreen ? 6 : 3}>
                <InputWithLabel
                  testId="shippingAddressZip-label"
                  label={shippingAddressData.shippingAddressZip.title}
                  warningMessage={
                    shippingAddressData.shippingAddressZip.errorMessage
                  }
                  isRequired={shippingAddressData.shippingAddressZip.required}
                  id={shippingAddressData.shippingAddressZip.componentId}
                  error={
                    shippingAddressData.shippingAddressZip.valid ===
                    ValidationStatus.INVALID
                  }
                  isShowWarning={true}
                >
                  <InputBase
                    name="shippingAddressZip"
                    required={shippingAddressData.shippingAddressZip.required}
                    className="inpatient-supplyorder-shippingaddress-Input"
                    onChange={validateAndSetData}
                    value={shippingAddressData.shippingAddressZip.value}
                    type="text"
                    inputProps={{
                      maxLength: 5,
                      inputMode: "numeric",
                      pattern: "[0-9*]",
                    }}
                    id="shippingAddressZip"
                    data-testid="shippingAddressZipTest"
                  />
                </InputWithLabel>
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </div>
  );
};
