import "./privatePay.css";
import { useContext } from "react";
import {
  NewOrderContext,
  NewOrderContextType,
} from "../../../../../context/NewOrderContext";
import { ValidationStatus } from "../../../../../core/interfaces/input.interface";
import { ReactComponent as WarningIcon } from "../../../../../assets/WarningIndicator.svg";
type IPrivatePay = {
  isNextButtonClicked?: boolean;
};
const PrivatePay = ({ isNextButtonClicked }: IPrivatePay) => {
  const newOrderObj = useContext<NewOrderContextType | null>(NewOrderContext);

  const validateFieldsWithValue = () => {
    if (
      newOrderObj?.newOrderData.firstName.value === "" ||
      newOrderObj?.newOrderData.lastName.value === "" ||
      newOrderObj?.newOrderData.phone.value === ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  const validateFieldsWithValid = () => {
    if (
      newOrderObj?.newOrderData.firstName.valid === ValidationStatus.INVALID ||
      newOrderObj?.newOrderData.lastName.valid === ValidationStatus.INVALID ||
      newOrderObj?.newOrderData.phone.valid === ValidationStatus.INVALID
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="private-pay-box-main">
      <div
        className={`private-pay-box ${
          validateFieldsWithValid() ? "error" : ""
        }`}
      >
        <div className="patientName-box">
          <div className="patientDetailTitle">Patient Name</div>
          <div className="patientDetailsValue">
            {newOrderObj?.newOrderData?.firstName.value
              ? newOrderObj?.newOrderData?.firstName.value
              : "-"}{" "}
            {newOrderObj?.newOrderData?.lastName.value
              ? newOrderObj?.newOrderData?.lastName.value
              : "-"}
          </div>
        </div>
        <div className="patientName-box">
          <div className="patientDetailTitle">Contact Information</div>
          <div className="patientDetailsValue">
            {newOrderObj?.newOrderData?.email.value
              ? newOrderObj?.newOrderData?.email.value
              : "-"}
          </div>
          <div className="patientDetailsValue">
            {newOrderObj?.newOrderData?.phone.value
              ? newOrderObj?.newOrderData?.phone.value
              : "-"}
          </div>
        </div>
      </div>
      {isNextButtonClicked &&
        (validateFieldsWithValid() || validateFieldsWithValue()) && (
          <div className="private-pay-warning-div">
            <span className="warning-icon">
              <WarningIcon className="warning-icon-image" />
            </span>
            <p className="error-with-warning-icon">
              Please add patient details
            </p>
          </div>
        )}
    </div>
  );
};

export default PrivatePay;
