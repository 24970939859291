import { createContext, useState } from "react";
import {
  IExchangeVacValues,
} from "../components/myPatients/patient.interface";
import { IExchangeVACProps } from "../components/exchangeVacUnit/exchangeVacUnit.interface";
import { defaultExchangeVacData } from "../components/exchangeVacUnit/exchangeVac.model";
import { getDeepClone } from "../util/ObjectFunctions";
import { ExchangeVacPageSection } from "../components/exchangeVacUnit/exchangeVacPageSection.enum";
import { IDropZoneDocumentSelect } from "../core/customDropZone/dropZoneDocumentSelect.interface";

export type ExchangeVacUnitContextType = {
  exchangeVacPage: ExchangeVacPageSection;
  setExchangeVacPage: React.Dispatch<
    React.SetStateAction<ExchangeVacPageSection>
  >;
  patient: IExchangeVacValues | null;
  setPatient: React.Dispatch<React.SetStateAction<IExchangeVacValues | null>>;
  data: IExchangeVACProps;
  setData: React.Dispatch<React.SetStateAction<IExchangeVACProps>>;
  isUploadActive: string[];
  setIsUploadActive: React.Dispatch<React.SetStateAction<string[]>>;
  proofOfServiceUploadDocs: IDropZoneDocumentSelect[];
  setProofOfServiceUploadDocs: React.Dispatch<
    React.SetStateAction<IDropZoneDocumentSelect[]>
  >;
  resetData: () => void;
};
type ExchangeVacUnitContextProviderProps = {
  children: React.ReactNode;
};
export const ExchangeVacUnitContext =
  createContext<ExchangeVacUnitContextType | null>(null);
export const ExchangeVacUnitContextProvider = ({
  children,
}: ExchangeVacUnitContextProviderProps) => {
  const [patient, setPatient] = useState<IExchangeVacValues | null>(null);
  const [data, setData] = useState<IExchangeVACProps>(
    getDeepClone(defaultExchangeVacData)
  );
  const [exchangeVacPage, setExchangeVacPage] =
    useState<ExchangeVacPageSection>(
      ExchangeVacPageSection.EXCHANGE_VAC_SUBMIT_FORM
    );
  const [isUploadActive, setIsUploadActive] = useState<string[]>([]);
  const [proofOfServiceUploadDocs, setProofOfServiceUploadDocs] = useState<
    IDropZoneDocumentSelect[]
  >([]);
  const resetData = () => {
    setPatient(null);
    setData(getDeepClone(defaultExchangeVacData));
    setExchangeVacPage(ExchangeVacPageSection.EXCHANGE_VAC_SUBMIT_FORM);
    setIsUploadActive([]);
    setProofOfServiceUploadDocs([]);
  };

  return (
    <ExchangeVacUnitContext.Provider
      value={{
        resetData,
        patient,
        setPatient,
        data,
        setData,
        exchangeVacPage,
        setExchangeVacPage,
        isUploadActive,
        setIsUploadActive,
        proofOfServiceUploadDocs,
        setProofOfServiceUploadDocs,
      }}
    >
      {children}
    </ExchangeVacUnitContext.Provider>
  );
};
