import moment from "moment";
import "./placementDate.css";
import { useState } from "react";
import { Grid, TextField, useMediaQuery } from "@mui/material";
import { IPlacementDate } from "./placementDate.interface";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { convertStringToDate } from "../../../../util/utilityFunctions";
import { InpatientOrderValidator } from "../../inpatientOrder.validator";
import { ValidationStatus } from "../../../../core/interfaces/input.interface";
import { ReactComponent as CalendarIcon } from "../../../../assets/calendar.svg";
import { InputWithLabel } from "../../../../core/inputWithLabel/inputWithLabel.component";

export const PlacementDate = ({ data, setData }: IPlacementDate) => {
  const isMobileScreen = useMediaQuery("(max-width:600px)");
  const [focusClasses, setFocusClasses] = useState({
    placementDate: "placement-date",
  });
  const setClasses = (e: any, classname: string) => {
    setFocusClasses(
      Object.assign({}, focusClasses, { [e.target.name]: classname })
    );
  };

  const validateAndSetDate = (date: string | null | undefined) => {
    const formattedDate = date ? convertStringToDate(date) : "";
    const validator = new InpatientOrderValidator();
    const isValid = validator.validate(formattedDate!, "placementDate");
    const tempData = {
      ...data,
      placementDate: {
        ...data.placementDate,
        errorMessage: null,
        value: formattedDate,
        valid: isValid!.status,
      },
    };
    setData(tempData);
  };

  return (
    <div
      className="in-patient-order-placement-date-component"
      data-testid="in-patient-order-placement-date-component"
    >
      <p
        className="in-patient-order-placement-date-header"
        data-testid="in-patient-order-placement-date-header"
        id="in-patient-order-placement-date-header"
      >
        Placement Date
      </p>
      <Grid
        className="in-patient-order-placement-date-grid-container"
        container
      >
        <Grid
          className="in-patient-order-placement-date-grid-item"
          item
          xs={isMobileScreen ? 12 : 6}
        >
          <InputWithLabel
            error={data.placementDate.valid === ValidationStatus.INVALID}
            id={data.placementDate.componentId!}
            isRequired={data.placementDate.required}
            isShowWarning={true}
            label={data.placementDate.title}
            labelClassName={`in-patient-order-placement-date-title${
              data.placementDate.valid === ValidationStatus.INVALID
                ? " error"
                : ""
            } `}
            testId="in-patient-order-placement-date-title"
            warningMessage={data.placementDate.errorMessage}
          >
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                components={{ OpenPickerIcon: CalendarIcon }}
                InputAdornmentProps={{
                  classes: {
                    root: "adornedRoot",
                  },
                }}
                InputProps={{
                  classes: {
                    root: `in-patient-order-placement-date ${
                      data.placementDate.valid === ValidationStatus.INVALID
                        ? "show-error"
                        : "no-error"
                    }`,
                    input: "input",
                    notchedOutline: "outline",
                  },
                }}
                minDate={moment().subtract(21, "days").toString()}
                maxDate={moment().add(0, "d").toString()}
                onChange={(value) => validateAndSetDate(value)}
                renderInput={(params) => {
                  params.error = false;
                  params.inputProps!.placeholder = "__/__/____";
                  return (
                    <TextField
                      id={data.placementDate.id!}
                      name="placementDate"
                      onFocus={(e) => setClasses(e, "Mui-focused")}
                      onBlur={(e) => setClasses(e, "")}
                      {...params}
                    />
                  );
                }}
                value={data.placementDate.value}
              />
            </LocalizationProvider>
          </InputWithLabel>
        </Grid>
      </Grid>
    </div>
  );
};
