import { Grid } from "@mui/material";
import "./returnStoredProductDetails.css";
import { IInventoryProductDetails } from "../../../inventoryTabContainer.enum";

interface Props {
  returnStoredProductData: IInventoryProductDetails;
}
export const ReturnStoredProductDetails = ({
  returnStoredProductData,
}: Props) => {
  return (
    <>
      <div
        className="return-product-details-component"
        data-testid="return-product-details-component"
      >
        <Grid
          container
          spacing={2}
          classes={{ root: "return-product-details-grid-container" }}
        >
          <Grid item xs={6} className="return-product-details-grid-item">
            <div
              className="return-product-name"
              data-testid="return-product-name"
            >
              <p
                className="return-product-details-title"
                data-testid="return-product-details-title-product-name"
              >
                Product Name
              </p>
              <p
                className="return-product-details-value"
                data-testid="return-product-details-value-product-name"
              >
                {returnStoredProductData.product}
              </p>
            </div>
          </Grid>
          <Grid item xs={6} className="return-product-details-grid-item">
            <div
              className="return-product-serial"
              data-testid="return-product-serial"
            >
              <p
                className="return-product-details-title"
                data-testid="return-product-details-title-product-sn"
              >
                Product Serial #
              </p>
              <p
                className="return-product-details-value"
                data-testid="return-product-details-value-product-sn"
              >
                {returnStoredProductData.serialNumber}
              </p>
            </div>
          </Grid>
          <Grid item xs={12} className="return-product-details-grid-item">
            <div className="current-location" data-testid="current-location">
              <p className="return-product-details-title">Current Location</p>
              <p
                className="return-product-current-loc-value"
                data-testid="return-product-details-value-current-loc"
              >
                {returnStoredProductData.storageLocation}
              </p>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
