export interface IFunctionKeyData {
  AlertManagerFunction: string;
  ContentManagerFunction: string;
  DocumentManagerFunction: string;
  DurableFunction: string;
  InventoryManagerFunction: string;
  OfflineProcessFunction: string;
  ProductManagerFunction: string;
  OrderManagerFunction: string;
  UserManagerFunction: string;
  MWHService: string;
  SiteAccessFunction: string;
  FunctionKeyService: string;
  FacilityManagerFunction: string;
}

export let defaultFunctionkeyDataModel: IFunctionKeyData = {
  AlertManagerFunction: "",
  ContentManagerFunction: "",
  DocumentManagerFunction: "",
  DurableFunction: "",
  InventoryManagerFunction: "",
  OfflineProcessFunction: "",
  ProductManagerFunction: "",
  OrderManagerFunction: "",
  UserManagerFunction: "",
  MWHService: "",
  SiteAccessFunction: "",
  FunctionKeyService: "",
  FacilityManagerFunction: "",
};
