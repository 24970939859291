import React from "react";
import "./persistentFooter.css";

type Props = {
  children: any;
  footerButtonClass?: string;
};

export const PersistentFooter = ({ children, footerButtonClass }: Props) => {
  return (
    <div
      className={`footer-btn-group ${footerButtonClass} `}
      data-testid="footer-btn-group"
    >
      {children}
    </div>
  );
};
