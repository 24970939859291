import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AuthContext, AuthContextType } from "../../../context/AuthContext";
import {
  FacilitySettingContext,
  FacilitySettingsContextType,
} from "../../../context/FacilitySettingsContext";
import {
  RolesPermissionContext,
  RolesPermissionContextType,
} from "../../../context/RolesPermissionContext";
import { LoadingSpinner } from "../../../core/loader/LoadingSpinner";
import { Popup } from "../../../core/popup/popup.component";
import {
  fetchFacilityPermissions,
  updateFacilityPermissions,
} from "../../../util/3meService";
import {
  getFacilityPermissionName,
  updatePermissionBasedOnAcuteFacility,
} from "../../../util/utilityFunctions";
import { Navigator } from "../../helpAndSupport/Navigator/navigator.component";
import { NeedHelp } from "../../needHelp/needHelp.component";
import { FooterButtonGroup } from "../../send3MNote/footerButtonGroup/footerButtonGroup.component";
import { SendNoteFailure } from "../../send3MNote/popUps/failurePopUp/sendNoteFailure.component";
import { FacilityPermissions } from "./facilityPermissions/facilityPermissions.component";
import "./facilitySettings.css";
import { FacilitySettingsHeader } from "./facilitySettingsHeader/facilitySettingsHeader.component";
import { SuccessPopUp } from "./popUps/successPopUp/successPopUp.component";
import {
  IFacilitySettingPermission,
  IFacilitySettingSubPermission,
} from "./facilitySettings.interface";
import ErrorPopup from "../../../core/errorPopup/errorPopup.component";
import { ERROR_UPDATE_PERMISSION } from "../../../util/errorMsg";
import { USER_NAME_OR_SELECTED_FACILITY_IS_NULL } from "../../../util/errorCode";

export const FacilitySettings = () => {
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const facilitySettingsObj = useContext<FacilitySettingsContextType | null>(
    FacilitySettingContext
  );
  const history = useHistory();
  const location: any = useLocation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openSuccessPopUp, setOpenSuccessPopUp] = useState<boolean>(false);
  const [openFailurePopUp, setOpenFailurePopUp] = useState<boolean>(false);

  const permissions = facilitySettingsObj!.permissions;
  const setPermissions = facilitySettingsObj!.setPermissions;

  const originalPermissions = facilitySettingsObj!.originalPermissions;
  const setOriginalPermissions = facilitySettingsObj!.setOriginalPermissions;

  const setIsPermissionChanged =
    facilitySettingsObj!.setIsFacilityPermissionChanged;

  const selectedFacility = facilitySettingsObj!.selectedFacility;
  const setSelectedFacility = facilitySettingsObj!.setSelectedFacility;

  const userName = authObj?.userProfile?.userName;

  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );
  const isLoggedIn = authObj?.isLoggedIn ? authObj?.isLoggedIn : false;
  const userRole = authObj?.userRolePermissionData?.userRole;
  const [errorCode, setErrorCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorPopUpFlag, setErrorPopUpFlag] = useState(false);

  const getFacilityPermissions = async (
    userName: string,
    siteUseId: string
  ) => {
    const reqBody = {
      UserName: userName,
      SiteUseId: siteUseId,
    };
    setIsLoading(true);
    const response = await fetchFacilityPermissions(reqBody);
    if (response.error) {
      history.push("/administration");
    } else {
      const validPermissionArray = checkForPermissionToUpdateHideAndDisable(
        response.data as IFacilitySettingPermission[]
      );
      let cloneData: IFacilitySettingPermission[] = JSON.parse(
        JSON.stringify(validPermissionArray)
      );
      setOriginalPermissions(cloneData);
    }
    setIsLoading(false);
  };

  const updatePermissions = async () => {
    if (userName && selectedFacility) {
      let requestpermissionsParam = JSON.parse(JSON.stringify(permissions));
      requestpermissionsParam.forEach(
        (permissionValue: IFacilitySettingPermission) => {
          permissionValue.isHide = undefined;
          if (permissionValue.subPermissions) {
            if (permissionValue.isSelected || permissionValue.indeterminate) {
              permissionValue.isSelected = true;
              permissionValue.indeterminate = undefined;
            }
            permissionValue.subPermissions = permissionValue.subPermissions.map(
              (x) => ({
                isSelected: x.isSelected,
                permissionName: x.permissionName,
              })
            );
          } else {
            permissionValue.indeterminate = undefined;
          }
        }
      );
      const reqBody = {
        UserName: userName,
        SiteUseId: selectedFacility.siteUseId,
        Permissions: requestpermissionsParam,
      };
      setIsLoading(true);
      const response = await updateFacilityPermissions(reqBody);
      if (response.succeeded) {
        setOpenSuccessPopUp(true);
      } else {
        setErrorMessage(ERROR_UPDATE_PERMISSION);
        setErrorCode(
          response?.error?.code ||
            response?.error?.errorCode ||
            response?.status
        );
        setErrorPopUpFlag(true);
      }
      setIsLoading(false);
    } else {
      setErrorMessage(ERROR_UPDATE_PERMISSION);
      setErrorCode(USER_NAME_OR_SELECTED_FACILITY_IS_NULL);
      setErrorPopUpFlag(true);
    }
  };

  const openAdministrationPage = (isSuccess: boolean) => {
    if (isSuccess) {
      setOpenSuccessPopUp(false);
    } else {
      setOpenFailurePopUp(false);
    }
    setTimeout(() => {
      history.goBack();
    }, 500);
  };

  const checkForPermissionToUpdateHideAndDisable = (
    permissions: IFacilitySettingPermission[]
  ): IFacilitySettingPermission[] => {
    const isPostAcuteFacility =
      authObj!.registeredFaciltyAddress?.isPostAcute ?? false;
    let updatedPermissions: IFacilitySettingPermission[] = [];
    permissions.forEach((permission: IFacilitySettingPermission) => {
      const isShowPermission = updatePermissionBasedOnAcuteFacility(
        isPostAcuteFacility,
        permission.permissionName
      );
      if (getFacilityPermissionName(permission.permissionName) !== "") {
        switch (permission.permissionName) {
          case "INVENTORY":
            if (
              permission.subPermissions &&
              permission.subPermissions.length > 0
            ) {
              let checkedCount = 0;
              permission.subPermissions.forEach((subPermission) => {
                const isDisabled =
                  checkForSubPermissionToDisable(subPermission);
                subPermission.isDisabled = isDisabled;
                if (isDisabled) {
                  subPermission.isSelected = false;
                } else {
                  if (subPermission.isSelected) {
                    checkedCount += 1;
                  }
                }
              });
              permission.isSelected =
                permission.subPermissions.length === checkedCount;
              permission.indeterminate = permission.isSelected
                ? false
                : checkedCount > 0;
            } else {
              permission.indeterminate = false;
            }
            break;
          default:
            break;
        }
        updatedPermissions.push({
          ...permission,
          isHide: !isShowPermission,
        });
      }
    });
    return updatedPermissions;
  };

  const checkForSubPermissionToDisable = (
    subPermission: IFacilitySettingSubPermission
  ): boolean => {
    switch (subPermission.permissionName) {
      case "INVENTORY_READY_CARE":
        return (
          authObj!.registeredFaciltyAddress?.readyCareFlag !== "Y" ?? false
        );
      default:
        return false;
    }
  };

  useEffect(() => {
    if (!selectedFacility && !userName) {
      history.goBack();
    } else if (!selectedFacility) {
      const selectedFacilityLocal = location.state?.selectedFacility;
      setSelectedFacility(selectedFacilityLocal);
      if (selectedFacilityLocal && userName) {
        const siteUseId = selectedFacilityLocal.siteUseId;
        getFacilityPermissions(userName, siteUseId);
      }
    }
  }, []);

  return (
    <>
      <div className="facility-settings">
        <Navigator
          array={[
            {
              route: "/administration",
              pageName: "Administration",
            },
          ]}
          className="facility-settings-component-route-section"
          title="Facility Settings"
        />
        <div className="short-form">
          {selectedFacility && (
            <>
              <FacilitySettingsHeader
                selectedFacility={selectedFacility}
                setSelectedFacility={setSelectedFacility}
                userName={userName}
              />
              {originalPermissions.length > 0 && (
                <FacilityPermissions
                  originalPermissions={originalPermissions}
                  setIsPermissionChanged={setIsPermissionChanged}
                  setPermissions={setPermissions}
                />
              )}
              <div className="button-group" data-testid="button-group">
                <FooterButtonGroup
                  firstButtonTitle="Cancel"
                  firstButtonAction={() => {
                    history.goBack();
                  }}
                  secondButtonTitle="Update Permissions"
                  secondButtonDisabled={
                    permissionObj?.mappedRolesPermissionData.IsSupportRole ||
                    !facilitySettingsObj!.isFacilityPermissionChanged
                  }
                  secondButtonAction={updatePermissions}
                />
              </div>
            </>
          )}
        </div>
      </div>
      <div className="facility-settings-need-help-div">
        <NeedHelp
          isLoggedIn={isLoggedIn}
          userRole={userRole}
          isFromHelpSupport={false}
        />
      </div>
      <Popup
        closeHandler={() => setIsLoading(false)}
        dialogParentClass={"facility-settings-loader-pop-up"}
        hideCloseButton={true}
        openFlag={isLoading}
      >
        <div className="facility-settings-loader-pop-up-div">
          <LoadingSpinner />
        </div>
      </Popup>
      <Popup
        closeHandler={() => openAdministrationPage(true)}
        dialogParentClass={"facility-settings-success-pop-up"}
        openFlag={openSuccessPopUp}
      >
        <div className="facility-settings-success-pop-up-div">
          <SuccessPopUp
            buttonAction={() => openAdministrationPage(true)}
            buttonTitle="Done"
            description="Permission changes will apply to new users added to your facility site. Existing permissions with current users in your site will not be changed."
            title="Role permissions have been updated"
          />
        </div>
      </Popup>
      <Popup
        closeHandler={() => setOpenFailurePopUp(false)}
        dialogParentClass={"facility-settings-failure-pop-up"}
        hideCloseButton={true}
        openFlag={openFailurePopUp}
      >
        <div className="facility-settings-failure-pop-up-div">
          <SendNoteFailure
            message="Your request to update permission has failed. Please try again or contact
        3M for assistance with this order 1-800-275-4524."
            backButtonAction={() => openAdministrationPage(false)}
          />
        </div>
      </Popup>
      <ErrorPopup
        errorPopupFlag={errorPopUpFlag}
        handleBackButton={() => openAdministrationPage(false)}
        popUpStyles="errorPopup"
        errorMessage={errorMessage}
        buttontitle="Done"
        showTitle={false}
        isShortSentence={true}
        errorCode={errorCode}
      />
    </>
  );
};
