import "./exposedStructures.css";
import { useContext, useState } from "react";
import { NewOrderValidator } from "../newOrder.validator";
import { ExposedStructuresProps } from "./exposedStructures.interface";
import { FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";
import { ValidationStatus } from "../../../core/interfaces/input.interface";
import { makeCapitalEachWordInString } from "../../../util/utilityFunctions";
import { ReactComponent as RadioButtonIcon } from "../../../assets/radioButton.svg";
import { InputWithLabel } from "../../../core/inputWithLabel/inputWithLabel.component";
import { ExposedStructuresReviewOrder } from "./reviewOrder/exposedStructuresReviewOrder.component";
import { ReactComponent as SelectedRadioButtonIcon } from "../../../assets/selectedRadioButton.svg";
import { MobileDisplayContext } from "../../../context/MobileDisplayContext";

export const ExposedStructures = ({
  editButtonClicked,
  isReviewOrder = false,
  isOrderSummary = false,
  isSecondaryWoundInfo,
  setWoundInfoData,
  woundInfoData,
  Validator = new NewOrderValidator(),
}: ExposedStructuresProps) => {
  const [active, setActive] = useState<boolean | null>(null);
  const { isMobileScreen } = useContext(MobileDisplayContext);

  const validateAndSetData = (e: any) => {
    const { name, value } = e.target;
    if (name === "exposedTissue") {
      if (value === "Yes") {
        setActive(true);
      } else if (value === "No") {
        setActive(false);
      } else {
        setActive(null);
      }
    }
    setWoundInfoData({
      ...woundInfoData,
      exposedTissue: {
        value: value,
        valid: ValidationStatus.VALID,
        title: "Exposed Tissue",
        errorMessage: null,
        required: true,
      },
    });
  };

  return (
    <div
      className={`exposed-structures-main-container
        ${isSecondaryWoundInfo ? "-sec" : ""}`}
    >
      {!isReviewOrder && (
        <Grid
          className="exposed-tissue-grid-container"
          container
          data-testid="exposed-container"
        >
          <Grid item xs={12}>
            <div className="exposed-header" data-testid="exposed-header">
              Exposed Tissues
            </div>
          </Grid>
          <Grid
            className="exposed-tissue-grid-item"
            item
            xs={isMobileScreen ? 12 : 6}
          >
            <InputWithLabel
              id={woundInfoData.exposedTissue.componentId}
              label="Is muscle, tendon or bone exposed?"
              isRequired={woundInfoData.exposedTissue.required}
              testId="exposedTissue"
              isShowWarning={true}
              error={
                woundInfoData.exposedTissue.valid === ValidationStatus.INVALID
              }
              warningMessage={woundInfoData.exposedTissue.errorMessage}
            >
              <RadioGroup
                name="exposedTissue"
                classes={{ root: "radioRoot" }}
                onChange={validateAndSetData}
                value={makeCapitalEachWordInString(
                  woundInfoData.exposedTissue.value
                )}
              >
                <FormControlLabel
                  classes={{
                    root:
                      woundInfoData.exposedTissue.valid ===
                      ValidationStatus.INVALID
                        ? "optionRoot-error"
                        : active === true
                        ? "optionRoot-active"
                        : "optionRoot",
                  }}
                  componentsProps={{
                    typography: {
                      classes: {
                        root: active === true ? "optiontxtSelect" : "optiontxt",
                      },
                    },
                  }}
                  control={
                    <Radio
                      icon={<RadioButtonIcon />}
                      checkedIcon={<SelectedRadioButtonIcon />}
                    />
                  }
                  data-testid="exposed-tissue-yes"
                  label="Yes"
                  value="Yes"
                />
                <FormControlLabel
                  classes={{
                    root:
                      woundInfoData.exposedTissue.valid ===
                      ValidationStatus.INVALID
                        ? "optionRoot-error"
                        : active === false
                        ? "optionRoot-active"
                        : "optionRoot",
                  }}
                  componentsProps={{
                    typography: {
                      classes: {
                        root:
                          active === false ? "optiontxtSelect" : "optiontxt",
                      },
                    },
                  }}
                  control={
                    <Radio
                      icon={<RadioButtonIcon />}
                      checkedIcon={<SelectedRadioButtonIcon />}
                    />
                  }
                  data-testid="exposed-tissue-no"
                  label="No"
                  value="No"
                />
              </RadioGroup>
            </InputWithLabel>
          </Grid>
        </Grid>
      )}
      {isReviewOrder && (
        <ExposedStructuresReviewOrder
          editButtonClicked={editButtonClicked}
          isOrderSummary={isOrderSummary}
          woundInfoData={woundInfoData}
        />
      )}
    </div>
  );
};
