import "./siteStatusDisplayAudience.css";
import { IAddSiteStatus } from "../addSiteStatus.interface";
import { defaultAddSiteStatusData } from "../addSiteStatus.model";
import { AddSiteStatusValidator } from "../addSiteStatus.validator";
import { Grid, useMediaQuery } from "@mui/material";
import { InputWithLabel } from "../../../../../core/inputWithLabel/inputWithLabel.component";
import { ValidationStatus } from "../../../../../core/interfaces/input.interface";
import { CustomCheckBox } from "../../../../../core/checkBox/checkBox.component";
import { MultipleActionsProps } from "../../../../confirmPlacement/confirmPlacement.interface";

export interface ISiteStatusDisplayAudience {
  data: IAddSiteStatus;
  Validator?: AddSiteStatusValidator;
  setData: Function;
}

export const SiteStatusDisplayAudience = ({
  data = defaultAddSiteStatusData,
  setData,
}: ISiteStatusDisplayAudience) => {
  const isMobileScreen = useMediaQuery("(max-width:920px)");

  const validatesiteStatusAudience = (e: any) => {
    const oldAudience = data.audience.value;
    let isAllSiteChecked = false;
    let selectedItemCount = 0;
    let updatedAudience: MultipleActionsProps[] = [];
    oldAudience.forEach((item: MultipleActionsProps, index: number) => {
      const { name, checked } = e.target;
      if (item.value === name) {
        item.selected = checked;
        if (name === "All site visitors" && checked) {
          selectedItemCount = 3;
          isAllSiteChecked = true;
        }
      }
      if (index > 0) {
        if (isAllSiteChecked) {
          item.selected = true;
        } else if (item.value !== name && item.disabled) {
          item.selected = false;
        }
        item.disabled = isAllSiteChecked;
      }
      updatedAudience.push(item);
      if (item.selected && !isAllSiteChecked) {
        selectedItemCount += 1;
      }
      if (
        updatedAudience.length === oldAudience.length &&
        selectedItemCount === 3
      ) {
        while (selectedItemCount >= 0) {
          let updateItem = updatedAudience[selectedItemCount];
          updateItem.disabled = selectedItemCount > 0;
          updateItem.selected = true;
          selectedItemCount -= 1;
        }
      }
    });
    const isValid = updatedAudience.some(
      (x: MultipleActionsProps) => x.selected
    );
    setData({
      ...data,
      audience: {
        ...data.audience,
        errorMessage: null,
        valid: isValid ? ValidationStatus.VALID : ValidationStatus.INVALID,
        value: updatedAudience,
      },
    });
  };

  return (
    <div
      className="site-status-audience-component"
      data-testid="site-status-audience-component"
    >
      <span
        className="site-status-audience-header"
        data-testid="site-status-audience-header"
      >
        Display audience
      </span>
      <InputWithLabel
        label="Who should see this message?"
        isRequired={data.audience.required}
        id={data.audience.componentId!}
        isShowWarning={true}
        error={data.audience.valid === ValidationStatus.INVALID}
        labelClassName="site-status-audience-label"
        warningMessage={data.audience.errorMessage}
        testId="site-status-audience-label"
      >
        <Grid container xs={10} className="site-status-audience-check-block">
          {data.audience.value.map((x: MultipleActionsProps, index: number) => (
            <Grid item xs={isMobileScreen ? 12 : 5}>
              <div className="site-status-audience-checkbox-item">
                <CustomCheckBox
                  name={x.value}
                  selectClassName="site-status-audience-checkbox"
                  selectpropsClassName="site-status-audience-checkbox-root"
                  handleChange={validatesiteStatusAudience}
                  labelClassName={"site-status-audience-chkbox-desp"}
                  checked={x.selected}
                  value={x.value}
                  key={index}
                  required={false}
                  labelText={x.label}
                  testId={x.value}
                  isDisabled={x.disabled}
                />
              </div>
            </Grid>
          ))}
        </Grid>
      </InputWithLabel>
    </div>
  );
};
