import "./pickUpDetailReview.css";
import { IPickUpRequest } from "../../pickUpRequest.interface";
import WoundTitleValue from "../../../../myPatients/patientAndTherapyDetails/woundProgress/addWoundAssessment/woundReviewAssessment/woundTitleValue/woundTitleValue.component";
import { convertStringToDate } from "../../../../../util/utilityFunctions";

type Props = { data: IPickUpRequest; workOrderNum?: string | undefined };

const PickUpDetailReview = ({ data, workOrderNum }: Props) => {
  return (
    <div>
      <div className="pickupConfirmDesp-title" data-testId="pickupDesp-title">
        Pickup Details
      </div>
      <div className="pickupConfirmDesp-rowDiv">
        <WoundTitleValue
          title={"Pickup Request Date"}
          value={convertStringToDate(Date())}
        />
        <WoundTitleValue title={"Pickup Work Order"} value={workOrderNum!} />
      </div>
      <WoundTitleValue
        title={"Return Method"}
        value={data.returnMethod.value}
        formatValue={false}
      />
      <WoundTitleValue
        title={"Special Instructions"}
        value={data.specialInstructions.value}
        formatValue={false}
      />
    </div>
  );
};

export default PickUpDetailReview;
