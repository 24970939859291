import { IInputField } from "../../../../core/interfaces/input.interface";

export interface IProofOfDeliverESign {
  patientName: IInputField;
  updatedPatientEmail: IInputField;
}

export enum SubmitESignPodStatus {
  NONE = 0,
  SUCCESS = 1,
  FAILURE = 2,
}
