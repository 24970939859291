import { ValidationStatus } from "../../../core/interfaces/input.interface";
import {
  IProblemDetails,
  ISalesAssistance,
} from "./salesAssistanceNeedHelp.interface";

export let defaultProblemDetails: IProblemDetails = {
  deviceProblem: {
    componentId: "sales-assistance-need-help-device-problem",
    id: "sales-assistance-need-help-device-problem-input",
    order: 2,
    required: false,
    title: "On what kind of device did the problem occur?",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  errorCode: {
    componentId: "sales-assistance-need-help-error-code",
    id: "sales-assistance-need-help-error-code-input",
    order: 3,
    required: false,
    title: "Error Code",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  dateOfProblem: {
    componentId: "sales-assistance-need-help-date-of-problem",
    id: "sales-assistance-need-help-date-of-problem-input",
    order: 4,
    required: true,
    title: "Date of problem",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  facilityId: {
    componentId: "sales-assistance-need-help-facility-id",
    id: "sales-assistance-need-help-facility-id-input",
    order: 5,
    required: false,
    title: "Facility ID",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  roNumber: {
    componentId: "sales-assistance-need-help-ro-number",
    id: "sales-assistance-need-help-ro-number-input",
    order: 6,
    required: false,
    title: "RO #",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  featureAffected: {
    componentId: "sales-assistance-need-help-feature-affected",
    id: "sales-assistance-need-help-feature-affected-input",
    order: 7,
    required: true,
    title: "Feature is affected",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  otherFeatureAffected: {
    componentId: "sales-assistance-need-help-feature-affected-other",
    id: "sales-assistance-need-help-feature-affected-other-input",
    order: 8,
    required: false,
    title: "Other",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
};

export let defaultSalesAssistanceData: ISalesAssistance = {
  helpNeeded: {
    componentId: "sales-assistance-need-help-help-needed",
    id: "sales-assistance-need-help-help-needed-input",
    order: 1,
    required: true,
    title: "Contact Reason",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  problemDetails: defaultProblemDetails,
  additionalInformation: {
    componentId: "sales-assistance-need-help-additional-info",
    id: "sales-assistance-need-help-additional-info-input",
    order: 9,
    required: true,
    title: "Additional information or description",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
};
