import "./subPermissionCheckbox.css";
import { CustomCheckBox } from "../../../../../../../core/checkBox/checkBox.component";
import { getFacilityPermissionName } from "../../../../../../../util/utilityFunctions";
import { IFacilitySettingSubPermission } from "../../../../facilitySettings.interface";

interface SubPermissionCheckboxProps {
  index: number;
  subPermission: IFacilitySettingSubPermission;
  onToggle: () => void;
}

export const SubPermissionCheckbox = ({
  index,
  subPermission,
  onToggle,
}: SubPermissionCheckboxProps) => {
  return (
    <div
      className="sub-permission-checkbox-component"
      data-testid={`sub-permission-checkbox-component-${index + 1}`}
    >
      <CustomCheckBox
        checked={subPermission.isDisabled ? false : subPermission.isSelected}
        handleChange={onToggle}
        isDisabled={subPermission.isDisabled}
        labelClassName={
          subPermission.isSelected
            ? "facility-permissions-checkbox-description-text-active"
            : "facility-permissions-checkbox-description-text"
        }
        labelText={getFacilityPermissionName(subPermission.permissionName)}
        name={subPermission.permissionName}
        required={false}
        selectClassName="facility-permissions-checkbox"
        selectpropsClassName="facility-permissions-checkbox-root"
        testId={`sub-permission-checkbox-item-${index + 1}`}
        value={subPermission.permissionName}
      />
    </div>
  );
};
