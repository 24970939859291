import {
  INeedHelpRadioButtonGroup,
  ISalesAssistance,
} from "../../salesAssistanceNeedHelp.interface";
import "./helpNeeded.css";
import { FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { InputWithLabel } from "../../../../../core/inputWithLabel/inputWithLabel.component";
import { ValidationStatus } from "../../../../../core/interfaces/input.interface";
import { ReactComponent as RadioButtonIcon } from "../../../../../assets/radioButton.svg";
import { ReactComponent as SelectedRadioButtonIcon } from "../../../../../assets/selectedRadioButton.svg";
import { ProblemDetails } from "../problemDetails/problemDetails.component";
import { defaultSalesAssistanceData } from "../../salesAssisstanceNeedHelp.model";

export interface IHelpNeeded {
  data: ISalesAssistance;
  setData: Function;
  affectedFeaturesText?: any;
}
export enum HelpNeededRadioSelection {
  PROBLEM = "problem",
  QUESTION = "question",
  ENHANCEMENT = "enhancement",
}
const helpNeededRadioOtions: INeedHelpRadioButtonGroup[] = [
  {
    raidoButtonLabel: "Problem",
    radioButtonValue: HelpNeededRadioSelection.PROBLEM,
    radioButtonTestId: "need-help-problem",
    radioButtonDisabled: false,
  },
  {
    raidoButtonLabel: "Question",
    radioButtonValue: HelpNeededRadioSelection.QUESTION,
    radioButtonTestId: "need-help-question",
    radioButtonDisabled: false,
  },
  {
    raidoButtonLabel: "Enhancement",
    radioButtonValue: HelpNeededRadioSelection.ENHANCEMENT,
    radioButtonTestId: "need-help-enhancement",
    radioButtonDisabled: false,
  },
];
const HelpNeeded = ({ data, setData, affectedFeaturesText }: IHelpNeeded) => {
  const isProblemOptionSelected =
    data.helpNeeded.value === HelpNeededRadioSelection.PROBLEM;

  const validateAndSetData = (event: any) => {
    let tempData: ISalesAssistance = data;
    const { value, name } = event.target;
    tempData = {
      ...data,
      [name]: {
        ...Object(data)[name],
        errorMessage: null,
        valid: ValidationStatus.VALID,
        value: value,
      },
    };
    if (value === HelpNeededRadioSelection.PROBLEM) {
      tempData = {
        ...tempData,
        problemDetails: {
          ...tempData.problemDetails,
          dateOfProblem: {
            ...tempData.problemDetails.dateOfProblem,
            valid: ValidationStatus.UNTOUCHED,
            required: true,
          },
          featureAffected: {
            ...tempData.problemDetails.featureAffected,
            valid: ValidationStatus.UNTOUCHED,
            required: true,
          },
        },
      };
    } else {
      tempData = {
        ...tempData,
        problemDetails: {
          ...defaultSalesAssistanceData.problemDetails,
          dateOfProblem: {
            ...tempData.problemDetails.dateOfProblem,
            valid: ValidationStatus.UNTOUCHED,
            required: false,
            value: "",
          },
          featureAffected: {
            ...tempData.problemDetails.featureAffected,
            valid: ValidationStatus.UNTOUCHED,
            required: false,
            value: "",
          },
        },
      };
    }
    setData(tempData);
  };

  const getRadioButtonRootClassName = (
    needHelp: HelpNeededRadioSelection
  ): string => {
    return data.helpNeeded.value === needHelp
      ? "helpNeededRootActive"
      : "helpNeededOptionRoot";
  };

  return (
    <div
      className="help-needed-main-div"
      data-testid="help-needed-main-div"
      id="help-needed-main-div"
    >
      <Typography
        className="help-needed-header"
        data-testid="help-needed-header"
        id="help-needed-header"
      >
        Help needed
      </Typography>
      <InputWithLabel
        label={data.helpNeeded.title}
        isRequired={data.helpNeeded.required}
        warningMessage={data.helpNeeded.errorMessage}
        isShowWarning={data.helpNeeded.valid === ValidationStatus.INVALID}
        id={data.helpNeeded.componentId}
        error={data.helpNeeded.valid === ValidationStatus.INVALID}
        testId="needed-help-radio-button"
      >
        <RadioGroup
          value={data.helpNeeded.value}
          classes={{ root: "helpNeededradioRoot" }}
          onChange={validateAndSetData}
          name="helpNeeded"
        >
          {helpNeededRadioOtions.map(
            ({
              raidoButtonLabel,
              radioButtonValue,
              radioButtonTestId,
              radioButtonDisabled,
            }: INeedHelpRadioButtonGroup) => (
              <FormControlLabel
                key={radioButtonValue}
                classes={{
                  root: getRadioButtonRootClassName(radioButtonValue),
                }}
                componentsProps={{
                  typography: {
                    classes: { root: "neededHelpOptiontxt" },
                  },
                }}
                control={
                  <Radio
                    icon={<RadioButtonIcon />}
                    checkedIcon={<SelectedRadioButtonIcon />}
                  />
                }
                data-testid={radioButtonTestId}
                label={raidoButtonLabel}
                value={radioButtonValue}
                disabled={radioButtonDisabled}
              />
            )
          )}
        </RadioGroup>
      </InputWithLabel>
      {isProblemOptionSelected && (
        <ProblemDetails
          data={data}
          setData={setData}
          affectedFeaturesText={affectedFeaturesText}
        />
      )}
    </div>
  );
};

export default HelpNeeded;
