import "./facilityInformation.css";
import trash from "../../../assets/trash.svg";
import { IFacility } from "./facility.interface";
import { Typography, useMediaQuery } from "@mui/material";
import { makeCapitalEachWordInString } from "../../../util/utilityFunctions";

type Props = {
  facilityInfo: IFacility;
  showTrash: boolean;
  index: number;
  openConfirmationBox: any;
  hideFacilityID?: boolean;
  showManualFacilityNote?: boolean;
};

export const FacilityInfoDetail = ({
  facilityInfo,
  index,
  showTrash,
  openConfirmationBox,
  hideFacilityID = false,
  showManualFacilityNote = true,
}: Props) => {
  const isMobileScreen = useMediaQuery("(max-width:414px)");
  return (
    <div className="facilitywrapper">
      <div className="facility-detail-div">
        <div className="facility-detail">
          <div key={index} className="leftfaciltydetails">
            <div className="facilityname">
              {makeCapitalEachWordInString(facilityInfo.accountName)}
            </div>
            <Typography className="facilitytitle">Address</Typography>
            {isMobileScreen ? (
              <Typography className="facilityvalue">
                {makeCapitalEachWordInString(facilityInfo.address1)},{" "}
                {makeCapitalEachWordInString(facilityInfo.address2)}
                <span className="facilityvalue">
                  {makeCapitalEachWordInString(facilityInfo.city)},{" "}
                  {facilityInfo.state} {facilityInfo.zip}
                </span>
              </Typography>
            ) : (
              <>
                <Typography className="facilityvalue">
                  {makeCapitalEachWordInString(facilityInfo.address1)},{" "}
                  {makeCapitalEachWordInString(facilityInfo.address2)}
                </Typography>
                <Typography className="facilityvalue">
                  {makeCapitalEachWordInString(facilityInfo.city)},{" "}
                  {facilityInfo.state} {facilityInfo.zip}{" "}
                </Typography>
              </>
            )}
          </div>
          <div className="rightfaciltydetails">
            <div className="facility-type-and-value">
              <Typography className="facilitytitle">Facility Type</Typography>
              <Typography className="facilityvalue">
                {makeCapitalEachWordInString(facilityInfo.typeName)}
              </Typography>
            </div>

            {facilityInfo.accountNumber !== null && !hideFacilityID && (
              <div className="facility-id-and-value">
                <Typography className="facilitytitle">Facility ID</Typography>
                <Typography className="facilityvalue">
                  {facilityInfo.accountNumber}
                </Typography>
              </div>
            )}
          </div>
        </div>
        <div className="lastfaciltydetails">
          {showTrash && (
            <img
              className="trash"
              src={trash}
              alt={trash}
              onClick={() => openConfirmationBox(facilityInfo)}
            />
          )}
        </div>
      </div>
      {facilityInfo.accountNumber === null && showManualFacilityNote && (
        <h4 className="facility-detail-warning">
          Manual entry will require facility setup which may take 1 to 5
          business days to complete. You will receive an email notification when
          that access request has been approved.
        </h4>
      )}
    </div>
  );
};
