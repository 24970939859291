import { Button, Grid, useMediaQuery } from "@mui/material";
import moment from "moment";
import { useContext, useState } from "react";
import { ReactComponent as InfoIcon } from "../../../../assets/InfoIcon.svg";
import { ReactComponent as PrintIcon } from "../../../../assets/print.svg";
import { AuthContext, AuthContextType } from "../../../../context/AuthContext";
import {
  ExchangeVacUnitContext,
  ExchangeVacUnitContextType,
} from "../../../../context/ExchangeVacUnitContext";
import ErrorPopup from "../../../../core/errorPopup/errorPopup.component";
import {
  OPEN_PDF_POP_UP_BLOCKED,
  PATIENT_OBJ_FROM_POS_FAILURE,
  SERIAL_NUMBER_IS_EMPTY_OR_NULL,
} from "../../../../util/errorCode";
import {
  ERROR_MSG_POPUP_BLOCKER,
  EXCHANGE_VAC_SUBMIT_POS_DWONLOAD_ERROR,
} from "../../../../util/errorMsg";
import { generateProofOfServicePDF } from "../../../../util/proofOfService";
import {
  checkPopUpBlocker,
  getPdfUrl,
  getSiteUseId,
} from "../../../../util/utilityFunctions";
import "./submitProofOfServiceFax.css";

type Props = {
  setIsLoading: Function;
};

export const ProofOfServiceFax = ({ setIsLoading }: Props) => {
  const exchangeVacUnitObj = useContext<ExchangeVacUnitContextType | null>(
    ExchangeVacUnitContext
  );
  const patientData = exchangeVacUnitObj?.patient;
  const [isPopUpBlocker, setIsPopUpBlocker] = useState(false);
  const [errorPopUpFlag, setErrorPopUpFlag] = useState(false);
  const [errorCode, setErrorCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const isMobileScreen = useMediaQuery("(max-width:920px)");
  const authObj = useContext<AuthContextType | null>(AuthContext);

  const callGenerateProofOfServicePDF = async () => {
    let serialNumber: string | undefined = "";
    if (patientData && patientData.isExchangeVacSubmitted) {
      serialNumber = patientData.productSerialNumber;
    } else {
      serialNumber = exchangeVacUnitObj!.data.serialNumber.value;
      if (serialNumber) {
        serialNumber = serialNumber.split(" - ")[0];
      }
    }
    if (patientData && serialNumber && serialNumber !== "") {
      setIsLoading(true);
      const reqBody = {
        DOB: patientData.dob
          ? moment(patientData.dob).format("YYYY-MM-DD")
          : null,
        ron: patientData.roNumber,
        isAcuteOrder: patientData.isAcuteOrder,
        exchangeSerialNumber: serialNumber,
        siteUseId: getSiteUseId(authObj),
      };
      const response = await generateProofOfServicePDF(reqBody);

      setIsLoading(false);
      if (response && response.succeeded) {
        const url = getPdfUrl(response.item);
        const windowObject = window.open(url, "_blank");
        const result = checkPopUpBlocker(windowObject);
        setErrorPopUpFlag(result);
        if (result) {
          setIsPopUpBlocker(result);
          setErrorMessage(ERROR_MSG_POPUP_BLOCKER);
          setErrorCode(OPEN_PDF_POP_UP_BLOCKED);
          setErrorPopUpFlag(result);
        }
      } else {
        setErrorMessage(EXCHANGE_VAC_SUBMIT_POS_DWONLOAD_ERROR);
        setErrorCode(
          response?.error?.code ||
            response?.error?.errorCode ||
            response?.status
        );
        setErrorPopUpFlag(true);
      }
    } else {
      if (!patientData) {
        setErrorCode(PATIENT_OBJ_FROM_POS_FAILURE);
      } else if (!serialNumber || serialNumber === "") {
        setErrorCode(SERIAL_NUMBER_IS_EMPTY_OR_NULL);
      }
      setErrorMessage(EXCHANGE_VAC_SUBMIT_POS_DWONLOAD_ERROR);
      setErrorPopUpFlag(true);
    }
  };
  const openProofOfDeliveryPdf = () => {
    callGenerateProofOfServicePDF();
  };
  const handleErrorPopUpClose = () => {
    setErrorPopUpFlag(false);
    setIsPopUpBlocker(false);
  };
  return (
    <div className="pos-fax-main" data-testid="pos-fax-main">
      <Grid container className="pos-fax-container">
        <Grid
          item
          xs={isMobileScreen ? 12 : 6}
          className="pos-fax-msg"
          data-testid="pos-fax-msg"
        >
          <p className="fax-msg">
            The Proof of Service signed and dated by the patient or authorized
            agent is required to receive care.
          </p>
        </Grid>
        <Grid item xs={isMobileScreen ? 12 : 6}>
          <Button
            classes={{ root: "borderButton" }}
            data-testid="downLoad-print-pos"
            variant="outlined"
            onClick={openProofOfDeliveryPdf}
            startIcon={<PrintIcon />}
          >
            Download and Print Proof of Service
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            classes={{ root: "borderButton-static-text" }}
            variant="outlined"
            startIcon={<InfoIcon />}
          >
            <span className="static-text" data-testid="pos-static-txt-btn">
              Please fax the signed Proof of Service to 1-888-245-2295.
            </span>
          </Button>
        </Grid>
      </Grid>
      <ErrorPopup
        errorPopupFlag={errorPopUpFlag}
        handleBackButton={handleErrorPopUpClose}
        popUpStyles="errorPopup"
        errorMessage={errorMessage}
        buttontitle="Done"
        showTitle={false}
        isShortSentence={true}
        isSupportPhoneRequired={isPopUpBlocker ? false : true}
        errorCode={errorCode}
      />
    </div>
  );
};
