import { Tabs } from "@mui/material";

export interface IScrollableTabHeader {
  scrollTabValue: number;
  handleChange: any;
  children?: any;
  tabsRoot: any;
  tabsIndicator: any;
  variant: any;
}

export const ScrollableTabHeader = ({
  scrollTabValue,
  handleChange,
  children,
  tabsRoot,
  tabsIndicator,
  variant,
}: IScrollableTabHeader) => {
  return (
    <Tabs
      className="scrollableTabHeader"
      classes={{
        root: tabsRoot,
        indicator: tabsIndicator,
      }}
      value={scrollTabValue}
      onChange={handleChange}
      variant={variant}
      scrollButtons={false}
      allowScrollButtonsMobile
    >
      {children}
    </Tabs>
  );
};
