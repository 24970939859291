import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AuthContext, AuthContextType } from "../../../../context/AuthContext";
import {
  ProfileFormContext,
  ProfileFormContextType,
} from "../../../../context/ProfileFormContext";
import { ExpressButton } from "../../../../core/expressButton/expressButton.component";
import { LoadingSpinner } from "../../../../core/loader/LoadingSpinner";
import { Popup } from "../../../../core/popup/popup.component";
import { getSalesUserDetails } from "../../../../util/3meService";
import { saveAssignTerritories } from "../../../../util/userService";
import { Navigator } from "../../../helpAndSupport/Navigator/navigator.component";
import { ISaleManageUserListTableData } from "../salesManageUserTable/saleManageUsersListTable.interface";
import SalesManageUserFooterButtonGroup from "./SalesManageUserFotterButtonGroup/salesManageUserFooterbtnGroup.component";
import SalesTerritories from "./SalesTerritories/salesTerritories.component";
import SalesManagerUserContactinfo from "./salesManageUserContactInfo/salesManageUserContactinfo.component";
import "./salesUserProfile.css";
import { ISaleManageUserEditData } from "./salesUserProfile.interface";
import {
  RolesPermissionContext,
  RolesPermissionContextType,
} from "../../../../context/RolesPermissionContext";
import { changeDateFormatOnlyDate } from "../../../../util/utilityFunctions";

const SalesUserProfile = () => {
  const location: any = useLocation();
  const history = useHistory();
  const [loader, setLoader] = useState<boolean>(false);
  const [salesManagerData, setSalesManagerData] =
    useState<ISaleManageUserListTableData>(location.state?.selectedSalePeople);
  const [isDisabled, setIsDisabled] = useState(true);
  const [salesMngUserData, setSalesMngUserData] =
    useState<ISaleManageUserEditData>();
  const profileForm = useContext<ProfileFormContextType | null>(
    ProfileFormContext
  );
  const [errorPopUp, setErrorPopUp] = useState(false);
  const AuthObj = useContext<AuthContextType | null>(AuthContext);
  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );

  const getSalesMangerUserDetails = async (
    userID: string,
    regionDistrictCode: string,
    areaCode?: string
  ) => {
    let reqBody;
    const userUPID = userID;
    const regionCode = regionDistrictCode;
    if (permissionObj?.mappedRolesPermissionData.IsSalesManagerRole) {
      reqBody = {
        UserID: userUPID,
        RegionCode: regionCode,
        areaCode,
        CurrentDate: changeDateFormatOnlyDate(new Date().toString()),
      };
    } else {
      reqBody = {
        UserID: userUPID,
        RegionCode: regionCode,
        CurrentDate: changeDateFormatOnlyDate(new Date().toString()),
      };
    }
    setLoader(true);
    try {
      const response = await getSalesUserDetails(reqBody);
      if (!response || response.error) {
        setLoader(false);
      } else {
        setSalesMngUserData(response[0]);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      return false;
    }
  };

  useEffect(() => {
    if (
      AuthObj?.userProfile?.area &&
      permissionObj?.mappedRolesPermissionData?.IsProdManagerRole &&
      salesManagerData?.region
    ) {
      getSalesMangerUserDetails(
        salesManagerData.userID.toString(),
        salesManagerData?.region?.toString()!
      );
    } else {
      if (AuthObj?.userProfile?.region) {
        getSalesMangerUserDetails(
          salesManagerData.userID.toString(),
          "",
          AuthObj?.userProfile?.area!
        );
      }
    }
  }, [AuthObj?.userProfile]);

  const handleSaveAssignTerritories = async () => {
    let territoryList: any[] = [];
    salesMngUserData?.territories.forEach((territoryData) => {
      if (!territoryData.isPrimary && territoryData.reason) {
        let territoryObj = {
          StartDate: territoryData.assignedFrom,
          EndDate: territoryData.assignedTo ? territoryData.assignedTo : null,
          Reason: territoryData.reason,
          TerritoryCode: territoryData.territoryCode,
          TerritoryName: territoryData.territoryName,
        };
        territoryList.push(territoryObj);
      }
    });
    let reqParam = {
      salesManagerUserName: AuthObj?.userProfile?.userName,
      SalesPersonUserId: salesManagerData.userID,
      Territories: territoryList,
    };
    const assignTerritoryResponseObj = await saveAssignTerritories(reqParam);
    if (!assignTerritoryResponseObj.succeeded) {
      setErrorPopUp(true);
    } else {
      history.push("/administration/salesManageUsers");
    }
  };

  const closeErrorPopup = async () => {
    setErrorPopUp(false);
  };

  const errorCancelContent = () => {
    return (
      <div className="error-popup">
        <div className="error-popup-msg">
          Your request to Update the Territory assignment for the selected
          Salesperson has failed.Please try again or contact 3M for assistance
          1-800-275-4524.
        </div>
        <div className="error-popup-Closebtn">
          <ExpressButton
            parentClass="successStopShareButton"
            variant="contained"
            clickHandler={closeErrorPopup}
          >
            Done
          </ExpressButton>
        </div>
      </div>
    );
  };

  return (
    <div className="salemanageuser-edit-container">
      <div
        className="salemanageuser-edit-component"
        data-testid="salemanageuser-edit-component"
      >
        <Navigator
          array={[
            {
              route: "/administration",
              pageName: "Administration",
            },
            {
              route: "/administration/salesManageUsers",
              pageName: "Manage Salespeople",
            },
          ]}
          className="sale-manage-edit-component-route-section"
          title="Edit user profile"
        />
        <div className="salemanageuser-edit-main-section">
          <h2
            className="salemanageuser-edit-main-section-header"
            data-testid="salemanageuser-edit-main-section-header"
          >
            Edit user profile
          </h2>
          <Popup
            closeHandler={() => setLoader(false)}
            openFlag={loader}
            hideCloseButton={true}
          >
            <div className="inventory-spinner">
              <LoadingSpinner />
            </div>
          </Popup>
          <div className="salemanageuser-edit-contactinfo">
            <h2
              className="salemanageuser-edit-contactinfo"
              data-testid="salemanageuser-edit-contactinfo"
            >
              Contact information
            </h2>
          </div>
          <SalesManagerUserContactinfo
            salesManagerUserData={salesMngUserData!}
          />
        </div>
        <SalesTerritories
          salesManagerUserData={salesMngUserData!}
          setSalesMngUserData={setSalesMngUserData}
          salesMngUserData={salesMngUserData}
        />
      </div>
      <SalesManageUserFooterButtonGroup
        handleSave={handleSaveAssignTerritories}
      />
      <Popup openFlag={errorPopUp} closeHandler={closeErrorPopup}>
        {errorCancelContent()}
      </Popup>
    </div>
  );
};

export default SalesUserProfile;
