import "./homeCareOrderFound.css";
import { Typography } from "@mui/material";
import { PatientData } from "./patientData/patientData.component";
import { PageSection } from "../searchHomeCareOrderContainer.enum";
import { IHomeCareOrderFound } from "./homeCareOrderFound.interface";
import { ExpressButton } from "../../../core/expressButton/expressButton.component";
import { FacilityData } from "./facilityData/facilityFoundData.component";
import { FacilityNotConnected } from "../facilityNotConnectedOrder/facilityNotConnectedOrder.component";

export const HomeCareOrderFound = ({
  homeCareOrderData,
  redirectHandler,
}: IHomeCareOrderFound) => {
  const handleSearchAgainBtn = () => {
    redirectHandler(PageSection.SEARCH_HOME_CARE_ORDER_FORM);
  };

  return (
    <div className="order-found-main-div">
      <Typography
        className="seach-home-care-order-found-title"
        data-testid="seach-home-care-order-found-title"
      >
        Home Care Order Found
      </Typography>
      <PatientData patient={homeCareOrderData.patientInfo} />
      {homeCareOrderData &&
        (homeCareOrderData?.requestor?.facilityName ||
          homeCareOrderData?.hcp?.facilityName) &&
        (homeCareOrderData?.requestor?.siteUseID ||
          homeCareOrderData?.hcp?.careGiverId) && (
          <>
            <Typography
              className="seach-home-care-found-description-1"
              data-testid="seach-home-care-found-description-1"
            >
              This order is associated with the following facilities
            </Typography>
            <Typography
              className="seach-home-care-found-description-2"
              data-testid="seach-home-care-found-description-2"
            >
              Choose a facility in which to view this order
            </Typography>
          </>
        )}
      <div
        className={
          homeCareOrderData && homeCareOrderData.requestor?.facilityName
            ? "home-care-order-facility-found"
            : ""
        }
      >
        {homeCareOrderData && homeCareOrderData.requestor?.siteUseID && (
          <FacilityData
            facilityData={homeCareOrderData.requestor}
            facilityType={"requestor"}
            type={homeCareOrderData.type}
            patientData={homeCareOrderData.patientInfo}
            redirectHandler={redirectHandler}
          />
        )}

        {homeCareOrderData && homeCareOrderData.hcp?.careGiverId && (
          <FacilityData
            facilityData={homeCareOrderData.hcp}
            facilityType={"hcp"}
            type={homeCareOrderData.type}
            patientData={homeCareOrderData.patientInfo}
            redirectHandler={redirectHandler}
          />
        )}
      </div>
      <div
        className={
          homeCareOrderData && !homeCareOrderData?.requestor?.siteUseID
            ? "facility-not-found"
            : ""
        }
      >
        {homeCareOrderData && !homeCareOrderData?.requestor?.siteUseID && (
          <FacilityNotConnected
            homeCareOrderDetails={homeCareOrderData}
            redirectHandler={redirectHandler}
          />
        )}
      </div>
      <ExpressButton
        variant="outlined"
        clickHandler={handleSearchAgainBtn}
        parentClass="search-again-button"
        testId="seach-home-care-found-search-again"
      >
        Search Again
      </ExpressButton>
    </div>
  );
};
