export const columns = [
  { label: "", accessor: "", sortable: false },
  { label: "Last Name", accessor: "lastName", sortable: true },
  { label: "First Name", accessor: "firstName", sortable: true },
  { label: "Role", accessor: "roles", sortable: true },
  { label: "Territory Code", accessor: "territories", sortable: true },
];
export let mockSaleManageUsersData: any = {
  data: [
    {
      userID: 797,
      firstName: "LEONARD",
      lastName: "MILLER",
      roles: ["AAM", "PSS", "RSM"],
      territories: [
        "AAM0004 - Los Angeles CA",
        "AAM2031 - Fresno CA",
        "AAM2032 - Santa Barbara CA",
        "AAM2033 - Beverly Hills CA",
        "AAM2041 - South Bay CA",
        "PSS1048 - Fresno CA",
        "PSS1055 - Los Angeles CA",
        "PSS1208 - Santa Barbara CA",
        "PSS1308 - South Bay CA",
        "PSS2002 - Beverly Hills CA",
        "RSMAWTR202 - RSMAWTR202",
        "RSMR202 - RSMR202",
      ],
    },
    {
      userID: "123",
      firstName: "Thompson",
      lastName: "George",
      roles: ["AWT", "SSR"],
      territories: ["AAM4024 - Houston W TX", "AAM4031- Corpus Christi TX"],
    },
    {
      userID: "456",
      firstName: "BASEEM",
      lastName: "HAKEEM",
      roles: ["AAM"],
      territories: ["PSSS111 - McAllen TX", "AAM4031- Corpus Christi TX"],
    },
    {
      userID: "123",
      firstName: "KEM",
      lastName: "RAZA MAN",
      roles: ["AAM"],
      territories: ["PSSS111 - McAllen TX", "AAM4031- Corpus Christi TX"],
    },
    {
      userID: "123",
      firstName: "PREAM",
      lastName: "PREAM MAN",
      roles: ["AAM"],
      territories: ["PSSS111 - McAllen TX", "AAM4031- Corpus Christi TX"],
    },
    {
      userID: "123",
      firstName: "RAHUL",
      lastName: "PATEL",
      roles: ["AAM"],
      territories: ["PSSS111 - McAllen TX", "AAM4031- Corpus Christi TX"],
    },
    {
      userID: "123",
      firstName: "MUKTESH",
      lastName: "MANAGER",
      roles: ["AAM"],
      territories: ["PSSS111 - McAllen TX", "AAM4031- Corpus Christi TX"],
    },
  ],
};
