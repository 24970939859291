import { createContext, useState } from "react";
import { ISiteStatusesBanner } from "../signIn/siteStatusesBanners/siteStatusesBanner/siteStatusesBanner.interfaces";

export type SiteStatusesContextType = {
  loginSiteStatues: ISiteStatusesBanner[];
  setLoginSiteStatues: React.Dispatch<
    React.SetStateAction<ISiteStatusesBanner[]>
  >;
  fullScreenSiteStatues: ISiteStatusesBanner[] | null;
  setFullScreenSiteStatues: React.Dispatch<
    React.SetStateAction<ISiteStatusesBanner[] | null>
  >;
  resetSiteStatuses: () => void;
};

type SiteStatusesContextProviderProps = {
  children: React.ReactNode;
};

export const SiteStatusesContext =
  createContext<SiteStatusesContextType | null>(null);

export const SiteStatusesContextProvider = ({
  children,
}: SiteStatusesContextProviderProps) => {
  const [loginSiteStatues, setLoginSiteStatues] = useState<
    ISiteStatusesBanner[]
  >([]);
  const [fullScreenSiteStatues, setFullScreenSiteStatues] = useState<
    ISiteStatusesBanner[] | null
  >(null);

  const resetSiteStatuses = () => {
    setLoginSiteStatues([]);
    setFullScreenSiteStatues(null);
  };

  return (
    <SiteStatusesContext.Provider
      value={{
        loginSiteStatues,
        setLoginSiteStatues,
        resetSiteStatuses,
        fullScreenSiteStatues,
        setFullScreenSiteStatues,
      }}
    >
      {children}
    </SiteStatusesContext.Provider>
  );
};
