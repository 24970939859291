import { Grid } from "@mui/material";
import { ExpressButton } from "../../../../../core/expressButton/expressButton.component";
import { IInventoryProductDetails } from "../../../inventoryTabContainer.enum";
import "./returnStoredProductUnitSuccess.css";
import { IStoredProdReturnInformation } from "../returnStoredProductUnit.interface";

type Props = {
  returnStoredProductData: IInventoryProductDetails;
  closePopup: Function;
  returnInformationData: IStoredProdReturnInformation;
};

export const ReturnStoredProductUnitSuccess = ({
  returnStoredProductData,
  closePopup,
  returnInformationData,
}: Props) => {
  const returnToStoredProductTab = () => {
    closePopup!();
  };
  return (
    <div className="success-return-unit-to-solventum-popup-div">
      <p
        className="return-unit-to-solventum-success-title"
        data-testid="return-unit-to-solventum-success-title"
      >
        Unit Return Confirmation
      </p>
      <div className="return-unit-to-solventum-success-description">
        <span data-testid="return-unit-to-solventum-success-description">
          We apologize for any issues encountered with this unit. The following
          requests have been initiated:
        </span>
      </div>
      <Grid
        className="confirmation-information-grid-container"
        container
        spacing={2}
      >
        <Grid className="return-inventory-success-confirmation" item xs={6}>
          <div className="return-inventory-success-confirmation-div">
            <p
              className="confirmation-information-title"
              data-testid="confirmation-information-patient-name-title"
            >
              Pickup Request for
            </p>
            <p
              className="confirmation-information-title-value"
              data-testid="confirmation-information-patient-name-value"
            >
              {returnStoredProductData.product}
            </p>
          </div>
        </Grid>
        <Grid className="return-inventory-success-confirmation" item xs={6}>
          <div className="return-inventory-success-confirmation-div">
            <p
              className="confirmation-information-title"
              data-testid="confirmation-information-patient-dob-title"
            >
              Serial Number
            </p>
            <p
              className="confirmation-information-title-value"
              data-testid="confirmation-information-patient-dob-value"
            >
              {returnStoredProductData.serialNumber}
            </p>
          </div>
        </Grid>
        <Grid className="return-inventory-success-confirmation" item xs={12}>
          <div className="return-inventory-success-confirmation-div">
            <p
              className="confirmation-information-title"
              data-testid="confirmation-information-product-name-title"
            >
              Pickup Location
            </p>

            <p
              className="confirmation-information-title-value"
              data-testid="confirmation-information-product-name-value"
            >
              {returnInformationData.pickUpLocation.value}
            </p>
          </div>
        </Grid>
      </Grid>
      <p
        className="return-unit-to-solventum-success-note"
        data-testid="return-unit-to-solventum-success-note"
      >
        A request has been created to restock your unit inventory.
      </p>
      <Grid className="success-buttons-grid-container" container spacing={2}>
        <Grid className="success-buttons-grid-item" item xs={12}>
          <ExpressButton
            clickHandler={returnToStoredProductTab}
            parentClass="return-to-inventory"
            testId="return-to-inventory"
            variant="contained"
          >
            Return to Inventory
          </ExpressButton>
        </Grid>
      </Grid>
    </div>
  );
};
