import { useState } from "react";
import "./prescriberInformation.css";
import {
  formatPhoneNumber,
  makeCapitalEachWordInString,
} from "../../../../util/utilityFunctions";
import {
  IPrescriberInformation,
  IPrescriberInformationProps,
} from "./prescriberInformation.interface";
import { Button, Grid } from "@mui/material";
import { Popup } from "../../../../core/popup/popup.component";
import { ValidationStatus } from "../../../../core/interfaces/input.interface";
import { ReactComponent as SearchIcon } from "../../../../assets/searchPayer.svg";
import { ExpressButton } from "../../../../core/expressButton/expressButton.component";
import { InputWithLabel } from "../../../../core/inputWithLabel/inputWithLabel.component";
import { PrescriberPopUp } from "./popUpContainer/prescriberInformationPopUpContainer.enum";
import { PrescriberInformationPopUpContainer } from "./popUpContainer/prescriberInformationPopUpContainer.component";

export const PrescriberInformation = ({
  data,
  prescriberInformation,
  setData,
}: IPrescriberInformationProps) => {
  const [currentPopUp, setCurrentPopUp] = useState<PrescriberPopUp>(
    PrescriberPopUp.LOADER
  );
  const [openContainerPopupFlag, setOpenContainerPopupFlag] = useState(false);

  const closePopUp = () => {
    setOpenContainerPopupFlag(false);
    setTimeout(() => {
      setCurrentPopUp(PrescriberPopUp.LOADER);
    }, 500);
  };

  const searchPrescriberButtonAction = () => {
    setOpenContainerPopupFlag(true);
  };

  const handleClosePopUpAction = () => {
    closePopUp();
  };

  const handleSuccessAndClose = (prescriber: IPrescriberInformation) => {
    const tempData = {
      ...data,
      prescriberInformation: {
        ...data.prescriberInformation,
        valid: ValidationStatus.VALID,
        value: prescriber,
      },
    };
    setData(tempData);
    closePopUp();
  };

  const showTitleAndValue = (
    title: string,
    value: string | null,
    testId: string
  ) => {
    return (
      <div className="show-title-and-value-div">
        <p
          className="show-title-and-value-title"
          data-testid={`${testId}-title`}
          id={`${testId}-title-id`}
        >
          {title}
        </p>
        <p
          className="show-title-and-value-value"
          data-testid={`${testId}-value`}
          id={`${testId}-value-id`}
        >
          {value && value !== "" ? value.trim() : "--"}
        </p>
      </div>
    );
  };

  return (
    <div
      className="in-patient-order-prescriber-informantion-component"
      data-testid="in-patient-order-prescriber-informantion-component"
    >
      <p
        className="in-patient-order-prescriber-informantion-title"
        data-testid="in-patient-order-prescriber-informantion-title"
        id="in-patient-order-prescriber-informantion-title"
      >
        Prescriber Information
      </p>
      <p
        className="in-patient-order-prescriber-informantion-description"
        data-testid="in-patient-order-prescriber-informantion-description"
        id="in-patient-order-prescriber-informantion-description"
      >
        Note: Prescribers selected will be added to your Prescriber List after
        successfully submitting an order.
      </p>
      {prescriberInformation &&
      prescriberInformation.firstName &&
      prescriberInformation.firstName.length > 0 ? (
        <Grid className="prescriber-selected-details-grid-container" container>
          <Grid className="prescriber-selected-details-grid-item" item xs={6}>
            <p
              className="prescriber-name"
              data-testid="prescriber-name"
              id="prescriber-name"
            >
              {makeCapitalEachWordInString(
                `${prescriberInformation.firstName} ${prescriberInformation.lastName}`
              )}
            </p>
          </Grid>
          <Grid className="prescriber-selected-details-grid-item" item xs={6}>
            <Button
              classes={{
                root: "change-prescriber-button",
              }}
              data-testid="change-prescriber-button"
              id="change-prescriber-button"
              onClick={() => {
                searchPrescriberButtonAction();
              }}
            >
              Change prescriber
            </Button>
          </Grid>
          <Grid className="prescriber-selected-details-grid-item" item xs={6}>
            {showTitleAndValue(
              "NPI",
              prescriberInformation.npi,
              "prescriber-npi"
            )}
          </Grid>
          <Grid className="prescriber-selected-details-grid-item" item xs={6}>
            {showTitleAndValue(
              "Phone",
              formatPhoneNumber(prescriberInformation.telephoneNumber),
              "prescriber-phone"
            )}
          </Grid>
          <Grid className="prescriber-selected-details-grid-item" item xs={6}>
            {showTitleAndValue(
              "Fax",
              prescriberInformation.faxNumber === "" ||
                prescriberInformation.faxNumber === null
                ? "--"
                : formatPhoneNumber(prescriberInformation.faxNumber!),
              "prescriber-fax"
            )}
          </Grid>
          <Grid className="prescriber-selected-details-grid-item" item xs={6}>
            {showTitleAndValue(
              "Address",
              `${makeCapitalEachWordInString(
                prescriberInformation.address1
              )}\n${
                prescriberInformation.address2 &&
                prescriberInformation.address2 !== ""
                  ? `${makeCapitalEachWordInString(
                      prescriberInformation.address2
                    )}\n`
                  : ""
              }${makeCapitalEachWordInString(prescriberInformation.city)}, ${
                prescriberInformation.state
              } ${prescriberInformation.zipCode}`,
              "prescriber-address"
            )}
          </Grid>
        </Grid>
      ) : (
        <div className="in-patient-order-prescriber-search-prescriber-button-div">
          <InputWithLabel
            error={
              data.prescriberInformation.valid === ValidationStatus.INVALID
            }
            id={data.prescriberInformation.componentId!}
            isRequired={data.prescriberInformation.required}
            isShowWarning={true}
            warningMessage={
              data.prescriberInformation.errorMessage
                ? "Prescriber Information is required"
                : ""
            }
          >
            <ExpressButton
              id="search-prescriber-informantion-button"
              clickHandler={searchPrescriberButtonAction}
              parentClass="search-prescriber-informantion-button"
              startIcon={<SearchIcon />}
              testId="search-prescriber-informantion-button"
              variant="outlined"
            >
              Search for Prescriber
            </ExpressButton>
          </InputWithLabel>
        </div>
      )}
      <Popup
        openFlag={openContainerPopupFlag}
        dialogParentClass="in-patient-order-prescriber-pop-up-container"
        closeHandler={handleClosePopUpAction}
        hideCloseButton={currentPopUp === PrescriberPopUp.LOADER}
      >
        <PrescriberInformationPopUpContainer
          closePopUp={handleClosePopUpAction}
          currentPopUp={currentPopUp}
          setCurrentPopUp={setCurrentPopUp}
          successAndClose={handleSuccessAndClose}
        />
      </Popup>
    </div>
  );
};
