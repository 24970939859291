import "./submitProofOfServiceDocumentUpload.css";
import { useContext, useState } from "react";
import {
  ExchangeVacUnitContext,
  ExchangeVacUnitContextType,
} from "../../../../context/ExchangeVacUnitContext";
import { Button, Grid, useMediaQuery } from "@mui/material";
import {
  RolesPermissionContext,
  RolesPermissionContextType,
} from "../../../../context/RolesPermissionContext";
import moment from "moment";
import {
  checkPopUpBlocker,
  getPdfUrl,
  getSiteUseId,
} from "../../../../util/utilityFunctions";
import { uploadDocToIface } from "../../../../util/orderOverViewService";
import { ReactComponent as PrintIcon } from "../../../../assets/print.svg";
import RedCheck from "../../../../assets/redCheck.svg";
import GreenCheck from "../../../../assets/greenCheck.svg";
import SelectCloseIcon from "../../../../assets/selectclose.svg";
import CustomDropZone from "../../../../core/customDropZone/customDropZone.component";
import { ExpressButton } from "../../../../core/expressButton/expressButton.component";
import ErrorPopup from "../../../../core/errorPopup/errorPopup.component";
import {
  ERROR_MSG_POPUP_BLOCKER,
  EXCHANGE_VAC_GET_SERIAL_NUMBER_API_ERROR,
  EXCHANGE_VAC_SUBMIT_POS_DWONLOAD_ERROR,
} from "../../../../util/errorMsg";
import {
  OPEN_PDF_POP_UP_BLOCKED,
  PATIENT_OBJ_FROM_POS_FAILURE,
  SERIAL_NUMBER_IS_EMPTY_OR_NULL,
} from "../../../../util/errorCode";
import { useHistory } from "react-router-dom";
import { generateProofOfServicePDF } from "../../../../util/proofOfService";
import { AuthContextType, AuthContext } from "../../../../context/AuthContext";

type Props = {
  setIsLoading: Function;
};

export const ProofOfServiceDocumentUpload = ({ setIsLoading }: Props) => {
  const exchangeVacUnitObj = useContext<ExchangeVacUnitContextType | null>(
    ExchangeVacUnitContext
  );
  const proofOfServiceUploadDocs = exchangeVacUnitObj!.proofOfServiceUploadDocs;
  const setProofOfServiceUploadDocs =
    exchangeVacUnitObj!.setProofOfServiceUploadDocs;
  const history = useHistory();
  const patientData = exchangeVacUnitObj!.patient;
  const isMobileScreen = useMediaQuery("(max-width:920px)");
  const [isPopUpBlocker, setIsPopUpBlocker] = useState(false);
  const [errorPopUpFlag, setErrorPopUpFlag] = useState(false);
  const [errorCode, setErrorCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const allowedFiles = {
    "image/png": [".jpeg", ".gif", ".jpg", ".png", ".tiff"],
    "text/pdf": [".pdf"],
  };
  const callGenerateProofOfServicePDF = async () => {
    let serialNumber: string | undefined = "";
    if (patientData && patientData.isExchangeVacSubmitted) {
      serialNumber = patientData.productSerialNumber;
    } else {
      serialNumber = exchangeVacUnitObj!.data.serialNumber.value;
      if (serialNumber) {
        serialNumber = serialNumber.split(" - ")[0];
      }
    }
    if (patientData && serialNumber && serialNumber !== "") {
      setIsLoading(true);
      const reqBody = {
        DOB: patientData.dob
          ? moment(patientData.dob).format("YYYY-MM-DD")
          : null,
        ron: patientData.roNumber,
        isAcuteOrder: patientData.isAcuteOrder,
        exchangeSerialNumber: serialNumber,
        siteUseId: getSiteUseId(authObj),
      };
      const response = await generateProofOfServicePDF(reqBody);
      setIsLoading(false);
      if (response && response.succeeded) {
        const url = getPdfUrl(response.item);
        const windowObject = window.open(url, "_blank");
        const result = checkPopUpBlocker(windowObject);
        if (result) {
          setIsPopUpBlocker(result);
          setErrorMessage(ERROR_MSG_POPUP_BLOCKER);
          setErrorCode(OPEN_PDF_POP_UP_BLOCKED);
          setErrorPopUpFlag(result);
        }
      } else {
        setErrorMessage(EXCHANGE_VAC_SUBMIT_POS_DWONLOAD_ERROR);
        setErrorCode(
          response?.error?.code ||
            response?.error?.errorCode ||
            response?.status
        );
        setErrorPopUpFlag(true);
      }
    } else {
      if (!patientData) {
        setErrorCode(PATIENT_OBJ_FROM_POS_FAILURE);
      } else if (!serialNumber || serialNumber === "") {
        setErrorCode(SERIAL_NUMBER_IS_EMPTY_OR_NULL);
      }
      setErrorMessage(EXCHANGE_VAC_SUBMIT_POS_DWONLOAD_ERROR);
      setErrorPopUpFlag(true);
    }
  };
  const openProofOfDeliveryPdf = () => {
    callGenerateProofOfServicePDF();
  };
  const removeDocumentAction = () => {
    setProofOfServiceUploadDocs([]);
  };
  const uploadDocumentAction = async () => {
    setIsLoading(true);
    if (
      proofOfServiceUploadDocs.length > 0 &&
      proofOfServiceUploadDocs[0].succeeded
    ) {
      let posUploadDoc = proofOfServiceUploadDocs[0];
      let document = {
        fileName: posUploadDoc.documentName,
        FileNetTypeCode: "POSC",
        Base64Content: posUploadDoc.documentBase64,
      };
      let uploadDocRequest = {
        RentalOrderNumber: patientData?.roNumber.toString(),
        files: [document],
      };
      const posDocResult = await uploadDocToIface(uploadDocRequest);
      setIsLoading(false);
      if (posDocResult && posDocResult.succeeded) {
        if (posDocResult.items.length > 0) {
          if (posDocResult.items[0].code === 7016) {
            history.push("/home");
          } else {
            proofOfServiceUploadDocs[0].succeeded = false;
            proofOfServiceUploadDocs[0].errorMessage = "Virus Detected";
          }
        }
      } else {
        setErrorPopUpFlag(true);
        setErrorMessage(EXCHANGE_VAC_GET_SERIAL_NUMBER_API_ERROR);
        setErrorCode(
          posDocResult?.error?.code ||
            posDocResult?.error?.errorCode ||
            posDocResult?.status
        );
      }
    }
  };
  const handleErrorPopUpClose = () => {
    setErrorPopUpFlag(false);
    setIsPopUpBlocker(false);
  };
  return (
    <div className="proofOfServiceUpload" data-testid="proofOfServiceUpload">
      <>
        <Grid container className="pos-upload-container">
          <Grid
            item
            xs={isMobileScreen ? 12 : 6}
            className="pos-upload-title-msg"
            data-testid="pos-title-msg"
          >
            A Proof of Service signed and dated by the patient or authorized
            agent is required to receive care.
          </Grid>
          <Grid item xs={isMobileScreen ? 12 : 6}>
            <Button
              classes={{ root: "print-pos-button" }}
              data-testid="print-pos-button"
              variant="outlined"
              onClick={openProofOfDeliveryPdf}
              startIcon={<PrintIcon />}
            >
              Download and Print Proof of Service
            </Button>
          </Grid>
        </Grid>
        {proofOfServiceUploadDocs?.length === 0 ? (
          <Grid item xs={12} className="pos-upload-doc-container">
            <div className="pos-upload-desp" data-testid="pos-upload-desp">
              Uploaded files must be in one of the following formats: JPG, GIF,
              JPEG, PNG, TIFF or PDF. Each file cannot exceed 15 MB (15360 KB)
              in size.
            </div>
            <CustomDropZone
              allowedFiles={allowedFiles}
              setData={setProofOfServiceUploadDocs}
              data={proofOfServiceUploadDocs}
              data-testid="pos-drop-zone"
              dragDropText="Drag and drop signed Proof of Service file here to upload"
              buttonDropText="Select Proof of Service file to upload"
              dropZoneStyles="dropZoneStyles"
              dragAndDropStyles="dragAndDrop"
              uploadWidgetCard="uploadWidgetCard"
              uploadIconStyle="uploadIconStyle"
              listingType="prescriptionDoc"
              buttonDropClassName="selectPodBtn"
              maxFileSize={15728640}
              singleFile={true}
            />
          </Grid>
        ) : (
          <Grid item xs={12} className="pos-upload-doc-container">
            {!isMobileScreen ? (
              <div className="pos-selectedFileDiv">
                <div className={"pos-selected-file-div"}>
                  <div key={0} className="pos-fileDetailCard">
                    <div className="pos-filename" data-testid="pos-file-name">
                      {proofOfServiceUploadDocs[0].documentName}
                    </div>
                    <div
                      className={`pos-fileStatus${
                        !proofOfServiceUploadDocs[0].succeeded ? `error` : ``
                      }`}
                      data-testid={
                        !proofOfServiceUploadDocs[0].succeeded
                          ? "pos-error"
                          : "pos-success"
                      }
                    >
                      {!proofOfServiceUploadDocs[0].succeeded ? (
                        <div className="check-mark-img">
                          <img src={RedCheck} alt={RedCheck} />
                        </div>
                      ) : (
                        <div className="check-mark-img">
                          <img src={GreenCheck} alt={GreenCheck} />
                        </div>
                      )}
                      <span className="upload-status">
                        {proofOfServiceUploadDocs[0].succeeded
                          ? "Upload successful!"
                          : proofOfServiceUploadDocs[0].errorMessage}
                      </span>
                    </div>
                    <div
                      className="pos-fileClose"
                      data-testid="pos-fileClose"
                      onClick={removeDocumentAction}
                    >
                      <img src={SelectCloseIcon} alt={SelectCloseIcon} />
                    </div>
                  </div>
                </div>
                <div
                  className="pos-uploadDocBtn"
                  data-testid="pos-uploadDocBtn"
                >
                  <ExpressButton
                    disabled={
                      permissionObj?.mappedRolesPermissionData.IsSupportRole ||
                      !proofOfServiceUploadDocs[0].succeeded
                    }
                    parentClass="btnUpload"
                    variant="contained"
                    clickHandler={uploadDocumentAction}
                  >
                    Submit Proof of Service
                  </ExpressButton>
                </div>
              </div>
            ) : (
              <div className="pos-selectedFileDiv">
                <div className={"pos-selected-file-div"}>
                  <div key={0} className="pos-fileDetailCard">
                    <div className="pos-filename-close">
                      <div className="pos-filename" data-testid="pos-file-name">
                        {proofOfServiceUploadDocs[0].documentName}
                      </div>
                      <div
                        className="pos-fileClose"
                        data-testid="pos-fileClose"
                        onClick={removeDocumentAction}
                      >
                        <img src={SelectCloseIcon} alt={SelectCloseIcon} />
                      </div>
                    </div>
                    <div
                      className={`pos-fileStatus${
                        !proofOfServiceUploadDocs[0].succeeded ? `error` : ``
                      }`}
                      data-testid={
                        !proofOfServiceUploadDocs[0].succeeded
                          ? "pos-error"
                          : "pos-success"
                      }
                    >
                      {!proofOfServiceUploadDocs[0].succeeded ? (
                        <div className="check-mark-img">
                          <img src={RedCheck} alt={RedCheck} />
                        </div>
                      ) : (
                        <div className="check-mark-img">
                          <img src={GreenCheck} alt={GreenCheck} />
                        </div>
                      )}
                      <span className="upload-status">
                        {proofOfServiceUploadDocs[0].succeeded
                          ? "Upload successful!"
                          : proofOfServiceUploadDocs[0].errorMessage}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className="pos-uploadDocBtn"
                  data-testid="pos-uploadDocBtn"
                >
                  <ExpressButton
                    disabled={
                      permissionObj?.mappedRolesPermissionData.IsSupportRole ||
                      !proofOfServiceUploadDocs[0].succeeded
                    }
                    parentClass="btnUpload"
                    variant="contained"
                    clickHandler={uploadDocumentAction}
                  >
                    Submit Proof of Service
                  </ExpressButton>
                </div>
              </div>
            )}
          </Grid>
        )}
      </>
      <ErrorPopup
        errorPopupFlag={errorPopUpFlag}
        handleBackButton={handleErrorPopUpClose}
        popUpStyles="errorPopup"
        errorMessage={errorMessage}
        buttontitle="Done"
        showTitle={false}
        isShortSentence={true}
        isSupportPhoneRequired={isPopUpBlocker ? false : true}
        errorCode={errorCode}
      />
    </div>
  );
};
