import { useContext } from "react";
import { facilitySupplyOrderPageSections } from "../facilitySupplyOrders.enum";
import FacilitySupplyOrderListPage from "../facilitySupplyOrdersListPage/facilitySupplyOrderListPage.component";
import {
  FaciliitySupplyOrdersContext,
  IFacilitysupplyOrdersContextType,
} from "../../../context/FacilitySupplyOrdersContext";
import "./facilitySupplyOrders.css";

const FacilitySupplyOrdersContainer = () => {
  const facilitySupplyOrdersObj =
    useContext<IFacilitysupplyOrdersContextType | null>(
      FaciliitySupplyOrdersContext
    );
  const sectionsToDisplay = () => {
    switch (facilitySupplyOrdersObj?.page) {
      case facilitySupplyOrderPageSections.LIST_PAGE:
        return <FacilitySupplyOrderListPage />;
    }
  };

  return (
    <div
      className="facility-supply-order-main-div"
      data-testId="facility-supply-order-main-div-testId"
    >
      {sectionsToDisplay()}
    </div>
  );
};

export default FacilitySupplyOrdersContainer;
