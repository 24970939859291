import { Children, createContext, useState } from "react";
import { ISaleManageUsersEditProps } from "../components/administration/salesManageUsers/salesUserProfile/salesUserProfile.interface";
import { mockSaleManageEditUsersData } from "../components/administration/salesManageUsers/salesUserProfile/salesUserProfile.model";
import { getDeepClone } from "../util/ObjectFunctions";
import { AddSalePersonToTerritory } from "../components/administration/manageTerritories/viewTerritories/territoryDetails/addTerritorySalePerson/addTerritorySalePersonContainer/addTerritorySalePerson.enum";

export type SalesManagerRoleContextType = {
  salesManagerRoleData: ISaleManageUsersEditProps;
  setSalesManagerRoleData: React.Dispatch<
    React.SetStateAction<ISaleManageUsersEditProps>
  >;
  addSalepersonDetailPageSection: AddSalePersonToTerritory;
  setAddSalepersonDetailPageSection: React.Dispatch<
    React.SetStateAction<AddSalePersonToTerritory>
  >;
  resetData: () => void;
};
type SalesManagerRoleProviderProps = {
  children: React.ReactNode;
};

export const SalesMnagerRoleContext =
  createContext<SalesManagerRoleContextType | null>(null);

export const SalesManagerRoleContextProvider = ({
  children,
}: SalesManagerRoleProviderProps) => {
  const [salesManagerRoleData, setSalesManagerRoleData] =
    useState<ISaleManageUsersEditProps>(
      getDeepClone(mockSaleManageEditUsersData)
    );

  const resetData = () => {
    setSalesManagerRoleData(getDeepClone(mockSaleManageEditUsersData));
    setAddSalepersonDetailPageSection(
      AddSalePersonToTerritory.ADD_SALE_PERSON_DETAILS
    );
  };
  const [addSalepersonDetailPageSection, setAddSalepersonDetailPageSection] =
    useState<AddSalePersonToTerritory>(
      AddSalePersonToTerritory.ADD_SALE_PERSON_DETAILS
    );
  return (
    <SalesMnagerRoleContext.Provider
      value={{
        salesManagerRoleData,
        setSalesManagerRoleData,
        addSalepersonDetailPageSection,
        setAddSalepersonDetailPageSection,
        resetData,
      }}
    >
      {children}
    </SalesMnagerRoleContext.Provider>
  );
};
