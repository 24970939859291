import { Alert, AlertTitle, IconButton } from "@mui/material";
import { ICustomAlertProps, IAlertCSSObj } from "./siteStatusInfo.interface";
import CloseIcon from "@mui/icons-material/Close";
import "./siteStatusInfo.css";

function SiteStatusInfo({
  alertType,
  siteStatusContentObject,
  contentShownObject,
}: ICustomAlertProps) {
  const alertCssObj = (alertType: string): IAlertCSSObj => {
    switch (alertType) {
      case "info":
        return {
          severity: "info",
          className: "site-status-info-css",
        };
      case "warning":
        return {
          severity: "warning",
          className: "site-status-warning-css",
        };
      case "error":
        return {
          severity: "error",
          className: "site-status-error-css",
        };
      default:
        return {
          className: "site-status-default-css",
        };
    }
  };
  const { severity, className } = alertCssObj(alertType);
  return (
    <div className="site-status-content-alert-div">
      <Alert
        severity={severity}
        icon={false}
        className={`site-status-alert-component ${className}`}
        classes={{ message: "site-status-alert-message" }}
      >
        <div className="site-status-title-and-description">
          {contentShownObject.title && (
            <AlertTitle className={`site-status-aler-title ${className}`}>
              {siteStatusContentObject?.title !== ""
                ? siteStatusContentObject?.title
                : "Title of message"}
            </AlertTitle>
          )}
          <div className="custom-siteStatus-deatil-and-LinkText">
            {contentShownObject.detail && (
              <span className="site-status-details-of-msg">
                {siteStatusContentObject?.description !== ""
                  ? siteStatusContentObject?.description
                  : "Details of message"}
                {"."}
              </span>
            )}
            {contentShownObject.linkText && (
              <span className={`site-status-custom-url-link ${className}`}>
                {siteStatusContentObject?.url
                  ? siteStatusContentObject?.url
                  : "Link text"}
              </span>
            )}
          </div>
        </div>
        <div>
          <IconButton>
            <CloseIcon fontSize="medium" />
          </IconButton>
        </div>
      </Alert>
    </div>
  );
}
export default SiteStatusInfo;
