import "./salesManagerHistory.css";
import { Grid, useMediaQuery } from "@mui/material";
import {
  ISalesManagerHistory,
  ISalesManagerHistoryData,
} from "./salesManagerHistory.interface";
import {
  getAuditHistory,
  getAuditHistoryCsvData,
  getAuditHistoryDropdownContents,
} from "../../../util/auditHistoryService";
import { useContext, useEffect, useState } from "react";
import { getDocumentUrl } from "../../../util/utilityFunctions";
import SnackBar from "../../../core/snackBar/snackBar.component";
import { ReactComponent as DownloadIcon } from "../../../assets/download.svg";
import { Navigator } from "../../helpAndSupport/Navigator/navigator.component";
import { ExpressButton } from "../../../core/expressButton/expressButton.component";
import { CustomDropDown } from "../../../core/customDropdown/customDropdown.component";
import { SalesManagerHistoryTable } from "./historyTable/salesManagerHistoryTable.component";
import { ReactComponent as DownloadDisabledIcon } from "../../../assets/downloadDisabled.svg";
import { InternalSignOnContextType, InternalSignOnContext } from "../../../context/InternalSignOnContext";

const SalesManagerHistory = () => {
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedRequestor, setSelectedRequestor] = useState("");
  const [selectedSalesperson, setSelectedSalesperson] = useState("");
  const [selectedSortField, setSelectedSortField] = useState(1);
  const [selectedSortOrder, setSelectedSortOrder] = useState(2);
  const [historyYearArray, setHistoryYearArray] = useState<string[]>([]);
  const [requestorArray, setRequestorArray] = useState<string[]>([]);
  const [salespeopleArray, setSalespeopleArray] = useState<string[]>([]);
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const isMobileScreen = useMediaQuery("(max-width:600px)");
  const isIPadScreen = useMediaQuery("(max-width:1024px)");
  const internalObj = useContext<InternalSignOnContextType | null>(
    InternalSignOnContext
  );
  // Loader
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // Full Data
  const [allHistories, setAllHistories] = useState<
    ISalesManagerHistoryData[] | null
  >(null);
  const [histories, setHistories] = useState<ISalesManagerHistory[] | null>(
    null
  );
  const [totalHistoriesCount, setTotalHistoriesCount] = useState<number>(0);

  // Pagination
  const [perPageCount, setPerPageCount] = useState<any>(25);
  const [selectedPageNumber, setSelectedPageNumber] = useState<any>(1);
  const [numberOfPages, setNumberOfPages] = useState<any[]>([]);
  const [allPageNumbers, setAllPageNumbers] = useState<any[]>([]);

  // API Calls
  const auditHistoryDropdownContents = async () => {
    const response = await getAuditHistoryDropdownContents();
    if (response && response.succeeded) {
      setHistoryYearArray(response.data.year);
      setRequestorArray(response.data.requesters);
      setSalespeopleArray(response.data.salespeople);
    }
  };

  const getAuditHistoryAPI = async () => {
    if (
      selectedYear !== "" ||
      selectedRequestor !== "" ||
      selectedSalesperson !== ""
    ) {
      const year = selectedYear === "All years" ? "" : selectedYear;
      const requester =
        selectedRequestor === "All requestors"
          ? ""
          : selectedRequestor.replaceAll(",", "");
      const salesperson =
        selectedSalesperson === "All salespeople"
          ? ""
          : selectedSalesperson.replaceAll(",", "");
      const reqBody = {
        pageIndex: selectedPageNumber,
        pageItemCount: perPageCount,
        requester: requester,
        salesperson: salesperson,
        sortColumn: selectedSortField,
        sortOrder: selectedSortOrder,
        year: year,
      };
      setIsLoading(true);
      setHistories([]);
      const response = await getAuditHistory(reqBody);
      if (response && response.succeeded && response.data !== null) {
        const result = {
          index: selectedPageNumber,
          items: response.data.auditHistoryList,
        };
        if (allHistories) {
          let updateAllHistories = [...allHistories];
          updateAllHistories.push(result);
          setAllHistories(updateAllHistories);
        } else {
          setAllHistories([result]);
        }
        setTotalHistoriesCount(response.data.count);
        setHistories(response.data.auditHistoryList);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        console.log("No data Available");
      }
    }
  };

  const handleDownload = async () => {
    const year = selectedYear === "All years" ? "" : selectedYear;
    const requester =
      selectedRequestor === "All requestors"
        ? ""
        : selectedRequestor.replaceAll(",", "");
    const salesperson =
      selectedSalesperson === "All salespeople"
        ? ""
        : selectedSalesperson.replaceAll(",", "");
    const reqBody = {
      Year: year,
      SalesPerson: salesperson,
      Requester: requester,
    };
    const res = await getAuditHistoryCsvData(reqBody);
    setOpenSnack(true);
    setSnackMessage("Download InProgress!");
    if (res.succeeded) {
      const url = await getDocumentUrl("csv", res.data);
      const link = document.createElement("a");
      link.href = url;
      link.download = "RegionAuditHistory";
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
      setOpenSnack(false);
      setSnackMessage("");
    } else {
      setOpenSnack(true);
      setSnackMessage("Download Failed. Please Try Again Later");
    }
  };

  const handleCloseAlert = () => {
    setOpenSnack(false);
    setSnackMessage("");
  };

  const handleYearChange = (e: any) => {
    setSelectedYear(e.target.value);
    clearResult();
    if (historyYearArray.length > 0 && historyYearArray[0] !== "All years") {
      let yearArray: string[] = [...historyYearArray];
      yearArray.unshift("All years");
      setHistoryYearArray(yearArray);
    } else if (e.target.value === "All years") {
      let yearArray: string[] = [...historyYearArray];
      yearArray.splice(0, 1);
      setHistoryYearArray(yearArray);
    }
  };

  const handleRequestorChange = (e: any) => {
    setSelectedRequestor(e.target.value);
    clearResult();
    if (requestorArray.length > 0 && requestorArray[0] !== "All requestors") {
      let allRequestors: string[] = [...requestorArray];
      allRequestors.unshift("All requestors");
      setRequestorArray(allRequestors);
    } else if (e.target.value === "All requestors") {
      let allRequestors: string[] = [...requestorArray];
      allRequestors.splice(0, 1);
      setRequestorArray(allRequestors);
    }
  };

  const handleSalespeopleChange = (e: any) => {
    setSelectedSalesperson(e.target.value);
    clearResult();
    if (
      salespeopleArray.length > 0 &&
      salespeopleArray[0] !== "All salespeople"
    ) {
      let allSalespeople: string[] = [...salespeopleArray];
      allSalespeople.unshift("All salespeople");
      setSalespeopleArray(allSalespeople);
    } else if (e.target.value === "All salespeople") {
      let allSalespeople: string[] = [...salespeopleArray];
      allSalespeople.splice(0, 1);
      setSalespeopleArray(allSalespeople);
    }
  };

  // Pagination Methods
  const handleSelectChange = (e: any) => {
    e.preventDefault();
    clearResult();
    let count = parseInt(e.target.value);
    setPerPageCount(count);
  };

  const handlePreviousClick = () => {
    setSelectedPageNumber((prev: number) => (prev <= 1 ? prev : prev - 1));
  };

  const handleNextClick = () => {
    setSelectedPageNumber((next: number) =>
      next >= numberOfPages.length ? next : next + 1
    );
  };

  const handlePageNumberClick = (item: any) => {
    if (typeof item === "string" && item.includes("...")) {
      getPageNumbers(item);
    } else {
      setSelectedPageNumber(parseInt(item));
    }
  };

  const clearResult = () => {
    setAllHistories([]);
    setSelectedPageNumber(1);
  };

  const getPageNumbers = (pageNumber: any) => {
    if (histories && histories.length > 0) {
      const howManyPages = Math.ceil(totalHistoriesCount / perPageCount);
      const pages: any[] = [];
      for (let i = 1; i <= howManyPages; i++) {
        pages.push(i);
      }
      setNumberOfPages(pages);
      var tempNumberOfPages: any[] = [];
      tempNumberOfPages = [...allPageNumbers];
      let dotsInitial = "...";
      let dotsLeft = "... ";
      let dotsRight = " ...";
      if (pages.length < 6) {
        tempNumberOfPages = pages;
      } else if (pageNumber >= 1 && pageNumber <= 3) {
        tempNumberOfPages = [1, 2, 3, 4, dotsInitial, pages.length];
      } else if (pageNumber === 4) {
        const sliced = pages.slice(0, 4);
        tempNumberOfPages = [...sliced, dotsInitial, pages.length];
      } else if (pageNumber > 4 && pageNumber < pages.length - 2) {
        // from 5 to 8 -> (10 - 2)
        const sliced1 = pages.slice(pageNumber - 2, pageNumber);
        // sliced1 (5-2, 5) -> [4,5]
        const sliced2 = pages.slice(pageNumber, pageNumber + 1);
        // sliced1 (5, 5+1) -> [6]
        tempNumberOfPages = [
          1,
          dotsLeft,
          ...sliced1,
          ...sliced2,
          dotsRight,
          pages.length,
        ];
        // [1, '...', 4, 5, 6, '...', 10]
      } else if (pageNumber > pages.length - 3) {
        // > 7
        const sliced = pages.slice(pages.length - 4);
        // slice(10-4)
        tempNumberOfPages = [1, dotsLeft, ...sliced];
      } else if (pageNumber === dotsInitial) {
        // [1, 2, 3, 4, "...", 10].length = 6 - 3  = 3
        // arrOfCurrButtons[3] = 4 + 1 = 5
        // or
        // [1, 2, 3, 4, 5, "...", 10].length = 7 - 3 = 4
        // [1, 2, 3, 4, 5, "...", 10][4] = 5 + 1 = 6
        setSelectedPageNumber(allPageNumbers[allPageNumbers.length - 3] + 1);
      } else if (pageNumber === dotsRight) {
        setSelectedPageNumber(allPageNumbers[3] + 2);
      } else if (pageNumber === dotsLeft) {
        setSelectedPageNumber(allPageNumbers[3] - 2);
      }
      setAllPageNumbers(tempNumberOfPages);
    } else {
      if (!isLoading) {
        setAllPageNumbers([]);
        setNumberOfPages([]);
        setSelectedPageNumber(1);
      }
    }
  };

  const checkDownloadBtnDisability = (): boolean => {
    return (
      (selectedYear === "" &&
        selectedRequestor === "" &&
        selectedSalesperson === "") ||
      totalHistoriesCount === 0
    );
  };

  useEffect(() => {
    auditHistoryDropdownContents();
  }, []);

  useEffect(() => {
    const index = allHistories
      ? allHistories.findIndex(
          (data: ISalesManagerHistoryData) => data.index === selectedPageNumber
        )
      : -1;
    if (index > -1) {
      const result = allHistories![index];
      setHistories(result.items);
    } else {
      getAuditHistoryAPI();
    }
  }, [
    perPageCount,
    selectedPageNumber,
    selectedYear,
    selectedRequestor,
    selectedSalesperson,
    selectedSortField,
    selectedSortOrder,
  ]);

  useEffect(() => {
    getPageNumbers(selectedPageNumber);
  }, [histories, totalHistoriesCount]);

  return (
    <>
      <div className="sales-history-component-container">
        <div className="sales-history-component">
          <div className="sales-history-child-component">
            <Navigator
              array={[
                {
                  route: internalObj?.isInternalAdministration ? "/internalAdministration" : "/administration",
                  pageName: "Administration",
                },
              ]}
              className="sales-history-component-route-section"
              title="History"
            />

            <p
              className="sales-history-title"
              data-testid="sales-history-title-test"
            >
              Audit History
            </p>
            <div className="sales-history-subDesc-component">
              <p
                className="sales-history-subDesc"
                data-testid="sales-history-subDesc-test"
              >
                The following captures all manual updates for the sales
                territory assignments
              </p>

              <ExpressButton
                clickHandler={handleDownload}
                parentClass="sales-history-downloadbtn"
                testId="sales-history-downloadbtn-test"
                variant="text"
                startIcon={
                  checkDownloadBtnDisability() ? (
                    <DownloadDisabledIcon />
                  ) : (
                    <DownloadIcon />
                  )
                }
                disabled={checkDownloadBtnDisability()}
              >
                Download
              </ExpressButton>
            </div>
            <Grid container className="sales-history-dropdown-grid-main">
              <Grid
                item
                xs={isMobileScreen ? 6 : isIPadScreen ? 3 : 2}
                className="dropDownGridItem"
              >
                <CustomDropDown
                  handleChange={(e: any) => handleYearChange(e)}
                  menuItem={historyYearArray}
                  name="salesHistoryYear"
                  selectpropsClassName="sales-history-year-dropdown"
                  selectClassName="sales-history-year-dropdown-input"
                  testId="sales-history-year-test"
                  value={selectedYear !== "" ? selectedYear : "Year"}
                />
              </Grid>
              <Grid
                item
                xs={isMobileScreen ? 10 : isIPadScreen ? 4 : 3}
                className="dropDownGridItem"
              >
                <CustomDropDown
                  handleChange={(e: any) => handleRequestorChange(e)}
                  menuItem={requestorArray}
                  selectpropsClassName="sales-history-requestor-dropdown"
                  selectClassName="sales-history-requestor-dropdown-input"
                  testId="sales-history-requestor-test"
                  value={
                    selectedRequestor !== ""
                      ? selectedRequestor
                      : "All requestors"
                  }
                />
              </Grid>
              <Grid
                item
                xs={isMobileScreen ? 10 : isIPadScreen ? 4 : 3}
                className="dropDownGridItem"
              >
                <CustomDropDown
                  handleChange={(e: any) => handleSalespeopleChange(e)}
                  menuItem={salespeopleArray}
                  name="territory-user-role"
                  selectpropsClassName="sales-history-salespeople-dropdown"
                  selectClassName="sales-history-salespeople-dropdown-input"
                  testId="sales-history-salespeople-test"
                  value={
                    selectedSalesperson !== ""
                      ? selectedSalesperson
                      : "All salespeople"
                  }
                />
              </Grid>
            </Grid>
            {selectedYear !== "" ||
            selectedRequestor !== "" ||
            selectedSalesperson !== "" ? (
              <SalesManagerHistoryTable
                allPageNumbers={allPageNumbers}
                clearResult={clearResult}
                histories={histories ?? []}
                isLoading={isLoading}
                numberOfPages={numberOfPages}
                perPageCount={perPageCount}
                selectedPageNumber={selectedPageNumber}
                totalHistoriesCount={totalHistoriesCount}
                handleSelectChange={handleSelectChange}
                handlePreviousClick={handlePreviousClick}
                handleNextClick={handleNextClick}
                handlePageNumberClick={handlePageNumberClick}
                setSelectedSortField={setSelectedSortField}
                setSelectedSortOrder={setSelectedSortOrder}
              />
            ) : (
              <div className="no-data">
                <p className="no-data-message">
                  Please select report criteria in order to view the Sales audit
                  history data.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <SnackBar
        toastStyle="salesHistoryCsvSaveToast"
        openFlag={openSnack}
        msg={snackMessage}
        handleCloseAlert={handleCloseAlert}
      ></SnackBar>
    </>
  );
};

export default SalesManagerHistory;
