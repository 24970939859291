import { Grid, GridSize } from "@mui/material";
import "./deliveryPreferenceReview.css";
import { MobileDisplayContext } from "../../../../../context/MobileDisplayContext";
import { useContext } from "react";
import {
  formatPhoneNumber,
  makeCapitalEachWordInString,
} from "../../../../../util/utilityFunctions";
import { InpatientOrderPageSection } from "../../../inpatientOrder.enum";
import { IDeliveryPreference } from "../delivveryPreference.interface";

interface Props {
  data: IDeliveryPreference;
  editButtonAction: any;
  isSummary?: boolean;
  isFromOrderOverview?: boolean;
}

export const DeliveryPreferenceReview = ({
  data,
  editButtonAction,
  isSummary = false,
  isFromOrderOverview,
}: Props) => {
  const { isMobileScreen } = useContext(MobileDisplayContext);

  const renderDeliveryContactSubComponent = (data: IDeliveryPreference) => {
    return (
      <Grid
        className="replacement-info-review-grid-item"
        item
        id="id_replacement-info-review-grid-item"
        xs={isMobileScreen ? 6 : 4}
      >
        <div className="show-title-and-value-div">
          <p
            className="show-title-and-value-title"
            data-testid="address-title"
            id="id_add-div"
          >
            Delivery Contact
          </p>
          <p
            className="show-title-and-value-value"
            data-testid="contactname-value"
            id="id_add_1-div"
          >
            {data.firstName.value && data.firstName.value
              ? makeCapitalEachWordInString(
                  data.firstName.value + " " + data.lastName.value
                )
              : "--"}
          </p>
          <p
            className="show-title-and-value-value"
            data-testid="phone-num-value"
            id="id_phone_num-div"
          >
            {data.phoneNumber ? (
              <a href={`tel:${formatPhoneNumber(data.phoneNumber.value)}`}>
                {formatPhoneNumber(data.phoneNumber.value)}
              </a>
            ) : (
              "--"
            )}
          </p>
        </div>
      </Grid>
    );
  };
  const getGridSize = (): GridSize => {
    let gridSize: GridSize = 0;
    if (isFromOrderOverview) {
      gridSize = 12;
    } else {
      if (isMobileScreen) {
        gridSize = 6;
      } else {
        gridSize = 4;
      }
    }
    return gridSize;
  };
  const showTitleAndValue = (
    title: string,
    value: string | null,
    testId: string
  ) => {
    return (
      <div
        className="show-title-and-value-div"
        id="id_show-title-and-value-div"
      >
        <p
          className="show-title-and-value-title"
          data-testid={`${testId}-title`}
          id={`${testId}-title-id`}
        >
          {title}
        </p>
        <p
          className="show-title-and-value-value"
          data-testid={`${testId}-value`}
          id={`${testId}-value-id`}
        >
          {value && value !== "" ? value.trim() : "--"}
        </p>
      </div>
    );
  };

  return (
    <div
      className="delivery-preference-review-component"
      data-testid="delivery-preference-review-component"
      id="id_delivery-preference-review-component"
    >
      <div
        className="delivery-preference-review-header"
        id="id_delivery-preference-review-header"
      >
        <p
          className="delivery-preference-review-title"
          data-testid="delivery-preference-review-title"
          id="delivery-preference-review-title-id"
        >
          Delivery Preferences
        </p>
        {!isSummary && (
          <button
            className="delivery-preference-review-edit-button"
            data-testid="delivery-preference-review-edit-button"
            id="delivery-preference-review-edit-button-id"
            onClick={() =>
              editButtonAction(
                "in-patient-order-delivery-preference-component",
                InpatientOrderPageSection.SECOND_PAGE
              )
            }
          >
            Edit
          </button>
        )}
      </div>
      <Grid
        className="delivery-preference-review-grid-item"
        item
        xs={isMobileScreen ? 6 : 4}
      />

      <div className="show-title-and-value-div">
        <p
          className="show-title-and-value-title"
          data-testid="Need-by-Date-and-Time-review-test"
          id="Need-by-Date-and-Time-review-test-id"
        >
          Need by Date and Time
        </p>
        <p
          className="show-title-and-value-value"
          data-testid="Need-by-Date-and-Time-review-test-value"
          id="Need-by-Date-and-Time-review-test-value-id"
        >
          {data.needByDate?.value && data.needByTime?.value
            ? data.needByDate.value + ", " + data.needByTime.value
            : ""}
        </p>
      </div>
      {!isFromOrderOverview ? (
        <>
          <Grid className="delivery-preference-review-grid-container" container>
            <Grid
              className="delivery-preference-review-grid-item"
              item
              xs={isMobileScreen ? 6 : 4}
            >
              {showTitleAndValue(
                "Call with ETA?",
                makeCapitalEachWordInString(
                  data.isCallOnEstimatedArrivalTime.value
                ),
                "call-with-ETA"
              )}
            </Grid>
            {data.isCallOnEstimatedArrivalTime.value.toLowerCase() === "yes" &&
              renderDeliveryContactSubComponent(data)}
          </Grid>
        </>
      ) : (
        renderDeliveryContactSubComponent(data)
      )}
      <Grid
        className="delivery-preference-review-grid-item"
        item
        xs={getGridSize()}
      >
        {showTitleAndValue(
          "Delivery Instructions",
          data.deliveryInstructions.value,
          "delivery-instructions"
        )}
      </Grid>
    </div>
  );
};
