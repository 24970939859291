import { createContext, useState } from "react";
import { IFacilitySupplyOrderFilterData } from "../components/facilitySupplyOrders/facilitySupplyOrderFilter/facilitySupplyOrdersFilter.interface";
import { defaultFacilitySupplyorderFilter } from "../components/facilitySupplyOrders/facilitySupplyOrderFilter/facilitySupplyOrdersFilter.model";
import { facilitySupplyOrderPageSections } from "../components/facilitySupplyOrders/facilitySupplyOrders.enum";
import { getDeepClone } from "../util/ObjectFunctions";

export type IFacilitysupplyOrdersContextType = {
  page: facilitySupplyOrderPageSections;
  setPage: React.Dispatch<
    React.SetStateAction<facilitySupplyOrderPageSections>
  >;
  data: IFacilitySupplyOrderFilterData;
  setData: React.Dispatch<React.SetStateAction<IFacilitySupplyOrderFilterData>>;
  resetData: () => void;
  isFromFacilitySupplyOrder: boolean;
  setIsFromFacilitySupplyOrder: React.Dispatch<React.SetStateAction<boolean>>;
};
type IFacilitysupplyOrdersContextProviderProps = {
  children: React.ReactNode;
};
export const FaciliitySupplyOrdersContext =
  createContext<IFacilitysupplyOrdersContextType | null>(null);

export const FacilitySupplyOrderContextProvider = ({
  children,
}: IFacilitysupplyOrdersContextProviderProps) => {
  const [page, setPage] = useState<facilitySupplyOrderPageSections>(
    facilitySupplyOrderPageSections.LIST_PAGE
  );
  const [data, setData] = useState<IFacilitySupplyOrderFilterData>(
    getDeepClone(defaultFacilitySupplyorderFilter)
  );
  const [isFromFacilitySupplyOrder, setIsFromFacilitySupplyOrder] =
    useState<boolean>(false);
  const resetData = () => {
    setData(getDeepClone(defaultFacilitySupplyorderFilter));
    setPage(facilitySupplyOrderPageSections.LIST_PAGE);
    setIsFromFacilitySupplyOrder(false);
  };
  return (
    <FaciliitySupplyOrdersContext.Provider
      value={{
        page,
        setPage,
        data,
        setData,
        resetData,
        isFromFacilitySupplyOrder,
        setIsFromFacilitySupplyOrder,
      }}
    >
      {children}
    </FaciliitySupplyOrdersContext.Provider>
  );
};
