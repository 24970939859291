import { Button, Tab, useMediaQuery } from "@mui/material";
import { default as Moment, default as moment } from "moment";
import { ReactNode, useContext, useEffect, useState } from "react";
import { useTabs } from "react-headless-tabs";
import { useHistory, useLocation } from "react-router-dom";
import { format } from "react-string-format";
import { ReactComponent as Back } from "../../../../assets/Arrow Down Med.svg";
import { ReactComponent as DropDownIcon } from "../../../../assets/Arrows Chevrons.svg";
import { ReactComponent as BackMobile } from "../../../../assets/Exit.svg";
import { ReactComponent as SelectIcon } from "../../../../assets/Rectangle_drop_down_icon.svg";
import { AuthContext, AuthContextType } from "../../../../context/AuthContext";
import {
  ConfirmPlacementContext,
  ConfirmPlacementContextType,
} from "../../../../context/ConfirmPlacementContext";
import {
  DischargeRequestContext,
  DischargeRequestContextType,
} from "../../../../context/DischargeRequestContext";
import {
  OrderDetailContext,
  OrderDetailContextType,
} from "../../../../context/OrderDetailsContext";
import {
  PickUpRequestContext,
  PickUpRequestContextType,
} from "../../../../context/PickUpRequestContext";
import {
  SendNoteContext,
  SendNoteContextType,
} from "../../../../context/SendNoteContext";
import {
  SupplyOrderContext,
  SupplyOrderContextType,
} from "../../../../context/SupplyOrderContext";
import {
  SubmitProofOfDeliveryContext,
  SubmitProofOfDeliveryContextType,
} from "../../../../context/submitProofOfDeliveryContext";
import {
  IInputField,
  ValidationStatus,
} from "../../../../core/interfaces/input.interface";
import { LoadingSpinner } from "../../../../core/loader/LoadingSpinner";
import { Popup } from "../../../../core/popup/popup.component";
import { ScrollableTabHeader } from "../../../../core/scrollableTabs/scrollableTabHeader.component";
import SnackBar from "../../../../core/snackBar/snackBar.component";
import { getTherapyStartDateWithOrderDetailsContext } from "../../../../signIn/deeplinkHandler";
import {
  fetchWoundList,
  generateFaxCoverSheet,
  getPatient,
  getPatientMenuAction,
  getVTIAFPDFContent,
  getVacDressingKitProducts,
} from "../../../../util/3meService";
import {
  createAlert,
  createMeasurementDueAlertRo,
  getMissingDocsAlert,
  getSupplyOrderDeliveredAlert,
  getSupplyOrderPendingAlert,
} from "../../../../util/alertFunctions";
import { getAlertsforRo } from "../../../../util/alertService";
import { getCMSContent } from "../../../../util/cmsService";
import { getdropDownContent } from "../../../../util/dropDownService";
import { getNPIPrescriber } from "../../../../util/npiService";
import {
  getAllDocuments,
  getOrderSupplies,
} from "../../../../util/orderOverViewService";
import { getAdditionalWoundQuestionaries } from "../../../../util/primaryWoundTypeService";
import {
  CMS_VAC_THERAPY_INFORMATION_CONTENT,
  DD_COMMON_DOCS_CONTENT,
  DD_UPLOAD_DOCUMENT_TYPE,
  HOLD_THERAPY_ERROR_MESSAGE,
  RESUME_THERAPY_ERROR_MESSAGE,
  GET_WOUND_LIST_ERROR_MESSAGE,
  HOLD_OR_RESUME_THERAPY_ERROR_MESSAGE,
  NO_WOUND_ERROR_MESSAGE_FOR_HOLD_THERAPY,
  CMS_ORDER_SOURCE_CONTENT,
} from "../../../../util/staticText";
import {
  formatedWoundValue,
  getPdfUrl,
  getSiteUseId,
  makeCapitalEachWordInString,
  getAcuteMenuActionPermission,
  getAddress,
  splitDateAndTime,
} from "../../../../util/utilityFunctions";
import {
  getOrderStatusDetails,
  getSupplyOrderDetailsapi,
  getVACOrderDetails,
} from "../../../../util/vacOrderService";
import { retrievePatientWoundDetails } from "../../../../util/woundAssessmentService";
import { IFacility } from "../../../manageProfile/facilityInformation/facility.interface";
import {
  Question,
  WoundQuestionaries,
} from "../../../newOrder/clinicalInformation/clinicalInfo.interface";
import { VacOrderSummaryData } from "../../../newOrder/mapper/VacOrderSummary/newOrderResponseMapper.interface";
import {
  ICanister,
  IDressingKit,
  INewOrder,
  IProductAccessory,
} from "../../../newOrder/newOrder.interface";
import { IPrescriberModal } from "../../../newOrder/prescriberInformation/prescriberSearch/prescriberSearch.model";
import { IPrintableDocumentsPdf } from "../../../newOrder/printCommonDocs/printCommonDocs.interface";
import { IVacTherapyInformation } from "../../../newOrder/woundBed/vacTherapyInformation.interface";
import { SendNoteFailure } from "../../../send3MNote/popUps/failurePopUp/sendNoteFailure.component";
import { ISuppyOrderAlertDataForRO } from "../../alert.interface";
import MissingRx from "../../allAlerts/missingRx/missingRx.component";
import {
  IAlertTypes,
  IMenuAction,
  IPatient,
  IPatientAlert,
} from "../../patient.interface";
import { CancelPatientOrder } from "../../patientActions/cancelPatientOrder/cancelPatientOrder.component";
import { HoldTherapy } from "../../patientActions/holdOrResumeTherapy/holdTherapy/holdTherapy.component";
import { WoundDetails } from "../../patientActions/holdOrResumeTherapy/main/holdOrResumeTherapy.interface";
import { ResumeTherapy } from "../../patientActions/holdOrResumeTherapy/resumeTherapy/resumeTherapy.component";
import { SuccessPopUp } from "../../patientActions/holdOrResumeTherapy/successPopUp/successPopUp.component";
import DocumentStepper from "../documentStepper/documentStepper.component";
import { IGetAllDocumentsRequest } from "../documentStepper/orderOverviewSubmittedDocument/submittedDocument.interface";
import OrderDetailsStepper from "../orderDetailsStepper/orderDetailsStepper.component";
import { IOrderDetailResponse } from "../orderDetailsTracking/orderDetailsTracking.interface";
import { IWoundDetails } from "../orderOverviewWoundProgressTab/WoundProgressTab.interface";
import WoundDetail from "../orderOverviewWoundProgressTab/orderOverviewWoundDetails/orderOverviewWoundDetail.component";
import WoundProgress from "../orderOverviewWoundProgressTab/orderOverviewWoundProgress/orderOverviewWoundProgress.component";
import OrderStepper from "../orderStepper/orderStepper.component";
import {
  IGetOrderSuppliesRequest,
  IOrderSupplies,
  ISupplyOrdersInfo,
} from "../orderSupplyDetail/orderSupplyDetails.interface";
import PatientFinancialInfoStepper from "../patientFinancialInfoStepper/patientFinancialInfoStepper.component";
import {
  ISupplyOrderAccessory,
  ISupplyOrderCanister,
  ISupplyOrderDressingKit,
  ISupplyOrderInfoMapper,
} from "../supplyOrderDetails/supplyOrderResponseInterface";
import { mapAccessoryCanistorDressingData } from "../supplyOrderDetails/supplyOrderResponseMapper";
import SupplyOrderStepper from "../supplyOrderStepper/supplyOrderStepper.component";
import { CustomSelect } from "./customSelect/customSelect.component";
import {
  NavigatePatientAction,
  getTherapyStartDate,
  getWoundList,
} from "./navigatePatientAction";
import "./orderOverview.css";
import {
  AcuteSummaryData,
  IWoundAssesments,
  IWoundAssesmentsMap,
} from "./orderOverview.interface";
import {
  OrderOverViewTabs,
  OrderOverViewTabsTitle,
} from "./orderOverviewContainer.enum";
import {
  deliveryPreferenceResponseMapper,
  getBillingAddress,
  getBlankForNullValue,
  inPatientWoundInfoResponseMapper,
  mapAccessoryData,
  mapCanisterData,
  mapClinicalInformationData,
  mapDeliveryInformationData,
  mapDressingKitData,
  mapProductInfoData,
  mapRequesterInfoData,
  mapSecondaryWoundIformationData,
  mapVacOrderOverviewResponse,
  mapWoundQuestionariesData,
  vacCannisterMapperData,
  vacPrimaryDressingKitMapperData,
  vacSecondaryDressingKitMapperData,
} from "./orderOverviewResponseMapper";
import {
  RolesPermissionContextType,
  RolesPermissionContext,
} from "../../../../context/RolesPermissionContext";
import {
  ExchangeVacUnitContext,
  ExchangeVacUnitContextType,
} from "../../../../context/ExchangeVacUnitContext";
import {
  NewOrderContext,
  NewOrderContextType,
} from "../../../../context/NewOrderContext";
import { mockAcuteROApiData } from "./orderOverviewMockData";
import ErrorPopup from "../../../../core/errorPopup/errorPopup.component";
import { ERROR_MSG_ACUTE_ORDER_VIEW } from "../../../../util/errorMsg";
import {
  getAcuteOrderRoDetails,
  getAcuteProductImageLink,
  getCustomerPlusAcuteOrderRoDetails,
} from "../../../../util/actueService";
import { woundAssessComorboditiesData } from "../woundProgress/addWoundAssessment/woundAssessComorbidities/woundAssessComorbodities.data";
import {
  WoundAssessmentContext,
  WoundAssessmentContextType,
} from "../../../../context/WoundAssessmentContext";
import { InternalSignOnContext } from "../../../../context/InternalSignOnContext";
import { InventoryPopUpContainer } from "../../../inventory/popUpContainer/inventoryPopUpContainer.component";
import {
  InventoryPopUp,
  InventoryPopUpFlow,
} from "../../../inventory/popUpContainer/inventoryPopUpContainer.enum";
import { ICustomerPlusListingDetails } from "../../../inventory/customerPlusInventory/customerPlusInventory.interface";
import { CustomerPlusMenuActionTypes } from "../../../inventory/inventoryTabContainer.enum";

import { IOrderOverviewProductInfo } from "../orderSummary/orderDetailsProductInformation/orderDetailsProductInformation.interface";
import { ORDER_OVERVIEW_DATA_MAPPING_ISSUE_FOR_CUSTOMERPLUS } from "../../../../util/errorCode";
import {
  IInpatientWoundInformation,
  IWoundInformation,
} from "../../../inpatientOrder/components/woundInformation/woundInformation.interface";
import { defaultInPatientPrimaryWoundInfo } from "../../../inpatientOrder/components/woundInformation/woundInformation.model";
import {
  AcuteOrderPickUpRequestContextType,
  AcuteOrderPickUpRequestContext,
} from "../../../../context/AcutePickUpRequestContext";
import { IReplacementInformationInterface } from "../../../inpatientOrder/components/replacementInformation/replacementInformation.interface";
import {
  CreateServiceRequestContextType,
  CreateServiceRequestContext,
} from "../../../../context/CreateServiceRequestContext";
import { InPatientSupplyOrderDetail } from "../../../facilitySupplyOrders/inpatientSupplyOrderDetails/inpatientSupplyOrderDetails.component";
import { IDropDownContent } from "../../../inpatientSupplyOrder/inpatientSupplyOrder.interface";

export const OrderOverview = ({}) => {
  const SupplyOrderObj = useContext<SupplyOrderContextType | null>(
    SupplyOrderContext
  );
  const sendNoteObj = useContext<SendNoteContextType | null>(SendNoteContext);
  const orderOverViewObj = useContext<OrderDetailContextType | null>(
    OrderDetailContext
  );
  const submitProofOfDeliveryObj =
    useContext<SubmitProofOfDeliveryContextType | null>(
      SubmitProofOfDeliveryContext
    );
  const woundAssesmentObj = useContext<WoundAssessmentContextType | null>(
    WoundAssessmentContext
  );
  const newOrderObj = useContext<NewOrderContextType | null>(NewOrderContext);
  const internalObj = useContext(InternalSignOnContext);
  const acuteOrderPickUpRequestObj =
    useContext<AcuteOrderPickUpRequestContextType | null>(
      AcuteOrderPickUpRequestContext
    );
  const createServiceRequestObj =
    useContext<CreateServiceRequestContextType | null>(
      CreateServiceRequestContext
    );
  const [woundAssessmentData, setWoundAssessmentData] = useState<any>();
  const [removePatientOpen, setRemovePatientOpen] = useState(false);
  const [removePatientMsg, setRemovePatientMsg] = useState("");
  const location: any = useLocation();
  const history: any = useHistory();
  const [emptyPopup, setEmptyPopup] = useState<boolean>(false);
  const [menuaActionArray, setMenuaActionArray] = useState<string[]>([]);
  const [patientData, setPatientData] = useState<IPatient>(
    location.state?.stateData
  );
  const [selectedTab, setSelectedTab] = useTabs([
    "Orders",
    "Documents",
    "Wound Progress",
    "Patient Financial Info",
  ]);
  const [insuranceTypes] = useState([]);
  const [insuranceTypesText] = useState([]);
  const [providerTypes] = useState([]);
  const [accidentTypes] = useState([]);
  const [therapyLengths] = useState([]);
  const [therapyGoals] = useState([]);
  const [vacTherapyInformationData, setVacTherapyInformationData] =
    useState<IVacTherapyInformation>();
  const [vacAllProducts, setAllVacProducts] = useState<null>(null);
  const [prodInfoTypes] = useState([]);
  const [deliverySites] = useState([]);
  const [reasonForTransition] = useState([]);
  const [states] = useState([]);
  const [statesText] = useState([]);
  const [orderDetailLoader, setOrderDetailLoader] = useState<boolean>(false);
  const isOrderFlow = orderOverViewObj?.isOrderFlow;
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const [orderSupplyDetail, setOrderSupplyDetail] = useState<IOrderSupplies>();
  const [orderSourceCode, setOrderSourceCode] = useState<IDropDownContent[]>(
    []
  );

  const [supplyOrderData, setSupplyOrderData] =
    useState<ISupplyOrderInfoMapper | null>(null);
  const [canister, setCanister] = useState<ISupplyOrderCanister | null>(null);
  const [accessory, setAccessory] = useState<ISupplyOrderAccessory | null>(
    null
  );
  const [dressing, setDressing] = useState<ISupplyOrderDressingKit | null>(
    null
  );
  const pickUpRequestObj = useContext<PickUpRequestContextType | null>(
    PickUpRequestContext
  );
  const dischargeRequestObj = useContext<DischargeRequestContextType | null>(
    DischargeRequestContext
  );
  const confirmPlacementObj = useContext<ConfirmPlacementContextType | null>(
    ConfirmPlacementContext
  );
  const exchangeVacUnitObj = useContext<ExchangeVacUnitContextType | null>(
    ExchangeVacUnitContext
  );
  const [documentHeaderCount, setDocumentHeaderCount] = useState<number>();
  const [measurementDueHeaderCount, setMeasurementDueHeaderCount] = useState<
    number | null
  >();
  const [printableDocumentsObj, setPrintableDocumentsObj] = useState<
    IPrintableDocumentsPdf | undefined
  >();
  const [commonDocs, setCommonDocs] = useState([]);
  const [commonDocsText, setCommonDocsText] = useState([]);
  const [pdfUrl, setPDFURL] = useState("");
  const setOrderOverviewData = orderOverViewObj?.setOrderOverviewData;
  const [isOrderOverviewDataLoaded, setIsOrderOverviewDataLoaded] =
    useState<boolean>(false);
  const [isCommonMethodsexecuted, setIsCommonMethodsexecuted] =
    useState<boolean>(false);
  const [isSupplyOrderLoaded, setIsSupplyOrderLoaded] =
    useState<boolean>(false);
  const [isWoundProgressTabLoaded, setIsWoundProgressTabLoaded] =
    useState<boolean>(false);
  const [isWoundDetailTabLoaded, setIsWoundDetailTabLoaded] =
    useState<boolean>(false);
  const [isDocTabLoaded, setIsDocTabLoaded] = useState<boolean>(false);
  //woundListGetter&Setter
  const [woundListData, setWoundListData] = useState<
    IWoundDetails | undefined
  >();
  const [documentTypeCode, setdocumentTypeCode] = useState([]);
  const [documentTypeText, setdocumentTypeText] = useState([]);
  const [assesmentList, setAssesmentList] = useState<IWoundAssesmentsMap[]>([]);
  const [interval, setInterval] = useState<number>(0);
  const [updatedAlertDataForRO, setUpdatedAlertDataForRO] = useState<any>();

  // Hold and Resume Therapy
  const [openTherapyPopUp, setOpenTherapyPopUp] = useState<boolean>(false);
  const [isHoldTherapy, setIsHoldTherapy] = useState<boolean>(true);
  const [wounds, setWounds] = useState<[WoundDetails] | undefined>(undefined);
  const [therapyStartDate, setTherapyStartDate] = useState<Date | undefined>(
    undefined
  );
  const [errorPopUp, setErrorPopUp] = useState<boolean>(false);
  const [showWarningPoppUp, setShowWarningPoppUp] = useState<boolean>(false);
  const [showSuccessPopUp, setShowSuccessPopUp] = useState<boolean>(false);

  // Send Prescription
  const [alertType, setAlertType] = useState<IAlertTypes>();
  const [patientActionAlertPopUp, setPatientActionAlertPopUp] = useState(false);
  const [selectedPatientChangePres, setSelectedPatientChangePres] =
    useState<IPatient | null>();

  // Cancel Patient Order
  const [isPatientCancelOrder, setIsPatientCancelOrder] =
    useState<boolean>(false);

  // Remove Patient
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const isMobileScreen = useMediaQuery("(max-width:920px)");
  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );

  // Error
  const [errorCode, setErrorCode] = useState("");
  const [showErrorPopUp, setShowErrorPopUp] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorPopUpFlag, setErrorPopUpFlag] = useState(false);
  const [openContainerPopupFlag, setOpenContainerPopupFlag] = useState(false);
  const [popUpFlow, setPopUpFlow] = useState<InventoryPopUpFlow>();
  const [selectedProductDetails, setSelectedProductDetails] =
    useState<ICustomerPlusListingDetails | null>(null);
  const [currentPopUp, setCurrentPopUp] = useState<InventoryPopUp>(
    InventoryPopUp.LOADER
  );
  const closePopUp = () => {
    setOpenContainerPopupFlag(false);
    setCurrentPopUp(InventoryPopUp.LOADER);
  };

  const handleClosePopUpAction = () => {
    if (currentPopUp === InventoryPopUp.END_THERAPY_SUCCESS) {
      handleSuccessAndCloseAction();
    } else {
      closePopUp();
    }
  };
  const handleSuccessAndCloseAction = () => {
    // Get latest customer plus listing products
    // Clear selected product
    setSelectedProductDetails(null);
    // Close pop up
    closePopUp();
    history.push("/inventory");
  };

  const checkValidFacility = (
    allFacilities: IFacility[],
    siteUseID: string
  ) => {
    let facilityFound: IFacility[] = allFacilities?.filter(
      (item: IFacility) => item.siteUseId === siteUseID
    );
    if (facilityFound.length === 0) {
      history.replace("/home");
    } else {
      if (
        patientData.isDeepLink &&
        authObj?.registeredFaciltyAddress?.siteUseId !== siteUseID
      ) {
        authObj?.setregisteredFaciltyAddress(facilityFound[0]);
      }
    }
  };

  const checkOrderOverViewDeeplink = () => {
    return patientData.hasOwnProperty("isDeepLink") && patientData.isDeepLink
      ? true
      : false;
  };

  useEffect(() => {
    if (!authObj?.isUnlinkedFacilityOrder) {
      setOrderDetailLoader(true);
      let deepLink =
        authObj?.deepLinkPath ?? sessionStorage.getItem("DeepLinkPath");
      if (!authObj?.deepLinkPath) {
        authObj?.setDeepLinkPath(deepLink);
      }
      if (
        authObj?.registeredFaciltyAddress !== undefined &&
        authObj?.allFacilities !== undefined &&
        authObj?.allFacilities.length > 0
      ) {
        if (patientData === undefined) {
          (async () => {
            if (checkOrderOverViewDeeplink()) {
              let woundAssessments: any;
              let menuActions: any;
              try {
                let urlData: any = authObj?.deepLinkPath?.split("/");
                let onbj: any = JSON.parse(atob(urlData[4]));
                checkValidFacility(authObj.allFacilities, onbj.siteUseId);
                const woundAssessmentResult = await getPatient(
                  onbj.ron,
                  onbj.dob,
                  onbj.siteUseId
                );
                if (
                  woundAssessmentResult.succeeded &&
                  woundAssessmentResult.data !== null
                ) {
                  woundAssessments = woundAssessmentResult.data;
                  menuActions = await getPatientMenuAction(
                    woundAssessments.type,
                    woundAssessments.isFromAllFacility,
                    onbj.ron,
                    onbj.dob
                  );
                  woundAssessments.color = menuActions?.data.color;
                  woundAssessments.menuActions = menuActions?.data.menuActions;
                  setPatientData(woundAssessments);
                  if (onbj.tabname === "Orders") {
                    orderOverViewObj?.resetData();
                    orderOverViewObj?.resetSeletedSupplyOrderData();
                    orderOverViewObj?.resetWoundData();
                    orderOverViewObj?.setSelectedOrderTab("Orders");
                  } else if (onbj.tabname === "Woundprogress") {
                    orderOverViewObj?.setSelectedOrderTab(
                      OrderOverViewTabsTitle.WOUND_PROGRESS_TAB_TITLE
                    );
                    orderOverViewObj?.setWoundId(onbj.woundId);
                  } else if (onbj.tabname === "Supplyorders") {
                    orderOverViewObj?.setSelectedOrderTab(
                      OrderOverViewTabsTitle.SUPPLY_ORDER_TAB_TITLE
                    );
                    const supplyOrderData: ISupplyOrdersInfo = {
                      createdDate: "",
                      shippedDate: "",
                      initiatedName: "",
                      product: "",
                      ropn: onbj?.ropn!,
                      status: "Delivered",
                      statusColor: "Green",
                      deliveredDate: "",
                    };
                    orderOverViewObj?.setSelectedSupplyOrder(supplyOrderData);
                    orderOverViewObj?.setSelectedOrderTab(
                      OrderOverViewTabsTitle.ORDER_TAB_TITLE
                    );
                    getTherapyStartDateWithOrderDetailsContext(
                      onbj?.ron,
                      onbj?.dob,
                      onbj?.userName,
                      onbj?.siteUseId,
                      orderOverViewObj!
                    );
                    orderOverViewObj?.setIsOrderFlow(true);
                    orderOverViewObj?.setOrderTabTitle("Supply Orders");
                  } else if (onbj.tabname === "Documents") {
                    orderOverViewObj?.setSelectedOrderTab(
                      OrderOverViewTabsTitle.DOCUMENTS_TAB_TITLE
                    );
                    return {
                      pathname: "/home/orderOverview",
                      state: {
                        stateData: woundAssessments,
                      },
                    };
                  } else {
                    history.replace("/home");
                  }
                } else {
                  history.replace("/home");
                }
              } catch (error) {
                history.replace("/home");
              }
            }
          })();
        } else if (patientData !== undefined && patientData.isDeepLink) {
          let urlData: any = authObj.deepLinkPath?.split("/");
          let onbj: any = JSON.parse(atob(urlData[4]));
          checkValidFacility(authObj?.allFacilities, onbj.siteUseId);
        } else {
          checkValidFacility(
            authObj?.allFacilities,
            authObj?.registeredFaciltyAddress?.siteUseId!
          );
        }
      }
    }
  }, [authObj?.registeredFaciltyAddress, authObj?.allFacilities]);

  useEffect(() => {
    if (patientData && !patientData.isCustomerPlus) {
      getAndSetTherapyDropDownData();
      if (!isCommonMethodsexecuted && !patientData?.isAcuteOrder)
        callCommonMethodsToDetermineTabTitle();
    }
  }, [patientData]);

  useEffect(() => {
    if (updatedAlertDataForRO && !patientData?.isAcuteOrder)
      getDocumentTabAlertCount(updatedAlertDataForRO);
  }, [updatedAlertDataForRO]);

  useEffect(() => {
    if (patientData && !patientData?.isAcuteOrder) {
      if (orderOverViewObj?.selectedOrderTab) {
        setOrderSelectedTab(orderOverViewObj?.selectedOrderTab);
      } else {
        setOrderSelectedTab(OrderOverViewTabs.ORDER_DETAILS);
      }

      if (
        !printableDocumentsObj &&
        orderOverViewObj?.selectedOrderTab === OrderOverViewTabs.DOCUMENTS
      ) {
        callAllDocTabApi();
      }
    }
  }, [orderOverViewObj?.selectedOrderTab, patientData]);

  useEffect(() => {
    if (
      patientData &&
      !patientData?.isAcuteOrder &&
      orderOverViewObj &&
      (!orderOverViewObj.selectedOrderTab ||
        orderOverViewObj.selectedOrderTab === "Orders") &&
      !orderOverViewObj.selectedSupplyOrder &&
      !isOrderOverviewDataLoaded &&
      authObj?.registeredFaciltyAddress &&
      checkForSearchHomeCareOrder(authObj.registeredFaciltyAddress)
    ) {
      if (patientData.hasOwnProperty("isDeepLink") && patientData.isDeepLink) {
        //checking isDeepLink because on page load if that is deeplink then it should execute below block
        let deepLink =
          authObj?.deepLinkPath ?? sessionStorage.getItem("DeepLinkPath");
        let urlData: any = deepLink?.split("/");
        let onbj: any = JSON.parse(atob(urlData[4]));
        let deeplinkSiteUseId: string = onbj.siteUseId;
        if (
          authObj?.registeredFaciltyAddress?.siteUseId === deeplinkSiteUseId
        ) {
          setOrderDetailLoader(true);
          getAllSupplyOrderAndVacOrderData();
          window.scroll(0, 0);
        }
      } else {
        setOrderDetailLoader(true);
        getAllSupplyOrderAndVacOrderData();
        window.scroll(0, 0);
      }
    }
  }, [
    orderOverViewObj?.selectedSupplyOrder,
    orderOverViewObj?.selectedOrderTab,
    patientData,
    authObj?.registeredFaciltyAddress,
  ]);

  useEffect(() => {
    if (
      orderOverViewObj?.selectedSupplyOrder &&
      patientData &&
      !patientData?.isAcuteOrder
    ) {
      setOrderDetailLoader(true);
      fetchSupplyOrderData();
    }
  }, [orderOverViewObj?.selectedSupplyOrder, patientData]);

  useEffect(() => {
    if (!patientData?.isAcuteOrder) {
      if (patientData) {
        setIsWoundDetailTabLoaded(false);
        if (
          orderOverViewObj?.woundProgressTabTitle ===
            OrderOverViewTabsTitle.WOUND_DETAIL_TAB_TITLE &&
          orderOverViewObj?.woundId &&
          updatedAlertDataForRO &&
          !isWoundDetailTabLoaded
        ) {
          setOrderDetailLoader(true);
          loadWoundAssessmentData();
        } else if (authObj?.isUnlinkedFacilityOrder) {
          if (orderOverViewObj?.woundId) {
            setOrderDetailLoader(true);
            loadWoundAssessmentData();
          }
        } else if (
          orderOverViewObj?.woundProgressTabTitle ===
          OrderOverViewTabsTitle.WOUND_PROGRESS_TAB_TITLE
        )
          setMeasurementDueHeaderCount(null);
      }
    }
  }, [
    orderOverViewObj?.woundProgressTabTitle,
    updatedAlertDataForRO,
    selectedTab,
    patientData,
  ]);

  useEffect(() => {
    if (!patientData?.isAcuteOrder) {
      if (isCommonMethodsexecuted && patientData) {
        if (
          orderOverViewObj?.selectedOrderTab ===
            OrderOverViewTabs?.ORDER_DETAILS &&
          orderOverViewObj?.orderTabTitle !==
            OrderOverViewTabsTitle.SUPPLY_ORDER_TAB_TITLE
        ) {
          if (isOrderOverviewDataLoaded) setOrderDetailLoader(false);
        }
        if (
          orderOverViewObj?.selectedOrderTab ===
          OrderOverViewTabs?.WOUND_PROGRESS
        ) {
          if (
            orderOverViewObj?.woundProgressTabTitle ===
            OrderOverViewTabs?.WOUND_PROGRESS
          ) {
            if (isWoundProgressTabLoaded) setOrderDetailLoader(false);
          } else {
            if (isWoundDetailTabLoaded) setOrderDetailLoader(false);
          }
        }
        if (
          orderOverViewObj?.selectedOrderTab === OrderOverViewTabs?.DOCUMENTS
        ) {
          if (isDocTabLoaded) setOrderDetailLoader(false);
        }
        if (
          orderOverViewObj?.selectedOrderTab ===
            OrderOverViewTabs?.ORDER_DETAILS &&
          orderOverViewObj?.orderTabTitle ===
            OrderOverViewTabsTitle.SUPPLY_ORDER_TAB_TITLE
        ) {
          if (isSupplyOrderLoaded) setOrderDetailLoader(false);
        }
      }
    }
  }, [
    isCommonMethodsexecuted,
    orderOverViewObj?.selectedOrderTab,
    isOrderOverviewDataLoaded,
    isSupplyOrderLoaded,
    isWoundDetailTabLoaded,
    isWoundProgressTabLoaded,
    isDocTabLoaded,
    selectedTab,
    patientData,
    orderDetailLoader,
  ]);

  useEffect(() => {
    if (!isMobileScreen) {
      orderOverViewObj?.setIsTabHeaderVisible(true);
      permissionObj?.setIsMobileViewVisible!(true);
    } else {
      if (
        (orderOverViewObj?.orderTabTitle ===
          OrderOverViewTabsTitle.ORDER_DETAIL_TAB_TITLE &&
          orderSupplyDetail &&
          orderSupplyDetail.supplyOrders &&
          orderSupplyDetail.supplyOrders.length > 0 &&
          selectedTab === "Orders") ||
        (orderOverViewObj?.orderTabTitle ===
          OrderOverViewTabsTitle.SUPPLY_ORDER_TAB_TITLE &&
          selectedTab === "Orders")
      ) {
        orderOverViewObj?.setIsTabHeaderVisible(false);
        permissionObj?.setIsMobileViewVisible!(false);
      } else {
        orderOverViewObj?.setIsTabHeaderVisible(true);
        permissionObj?.setIsMobileViewVisible!(true);
      }
    }
  }, [orderOverViewObj?.isTabHeaderVisible, isMobileScreen, selectedTab]);

  useEffect(() => {
    if (
      patientData?.isAcuteOrder &&
      authObj?.registeredFaciltyAddress &&
      orderOverViewObj?.orderDetailsTrackingData === null
    ) {
      getAndSetAcuteOrderDetails();
    }
  }, [authObj?.registeredFaciltyAddress]);

  const getAndSetTherapyDropDownData = async (
    patient: IPatient = patientData
  ) => {
    if (patient) {
      let menuActionsTextArray: Array<string> = [];
      patient?.menuActions
        ?.sort((a: any, b: any) => (a.sequence > b.sequence ? 1 : -1))
        .map((x: any) => {
          if (
            x.text !== "Add Wound Assessment" &&
            x.text !== "Upload Documents"
          ) {
            let menuText = x.text;
            menuActionsTextArray.push(menuText);
          }
        });
      setMenuaActionArray(menuActionsTextArray);
    }
  };
  useEffect(() => {
    if (location.pathname.includes("/unlinkOrderOverview")) {
      authObj?.setIsInternalUserFacilitySelected(false);
    } else authObj?.setIsInternalUserFacilitySelected(true);
  }, []);

  const callCommonMethodsToDetermineTabTitle = async () => {
    setOrderDetailLoader(true);
    if (!updatedAlertDataForRO) initiateGetAlertsForRo();
    let apiCollection = [
      fetchAllSupplyOrderForSelectedRO(),
      woundDetailsForSelectedRO(),
    ];
    const result = await Promise.all(apiCollection);
    if (result && result.length > 1) {
      setIsCommonMethodsexecuted(true);
    }
  };

  const getDocumentTabAlertCount = async (alertDataForRo: any) => {
    if (patientData) {
      let documentAlertData: IPatientAlert[] | undefined =
        alertDataForRo?.alerts?.filter(
          (x: any) => x.alertName === "Missing Docs"
        );
      if (documentAlertData) {
        var count =
          documentAlertData[0]?.statusDelayReason?.statusDelayReasons?.length;
        if (count) setDocumentHeaderCount(count);
      }
    }
  };

  const checkForSearchHomeCareOrder = (
    registeredFacility: IFacility
  ): boolean => {
    let isSiteUseIdAvailable: boolean = false;
    if (registeredFacility) {
      if (
        (internalObj?.isManualFacility &&
          registeredFacility.siteUseId === "") ||
        registeredFacility.siteUseId !== ""
      ) {
        isSiteUseIdAvailable = true;
      }
    }
    return isSiteUseIdAvailable;
  };

  const initiateGetAlertsForRo = async () => {
    if (
      (authObj &&
        authObj.registeredFaciltyAddress &&
        authObj.registeredFaciltyAddress.siteUseId) ||
      authObj?.isUnlinkedFacilityOrder
    ) {
      const reqParams = {
        order: {
          dob: moment(patientData.dob).format("yyyy-MM-DD"),
          orderID: patientData.orderID ?? "",
          rentalOrderNumber: patientData.roNumber,
        },
        siteUseId: authObj?.isUnlinkedFacilityOrder
          ? null
          : authObj?.registeredFaciltyAddress?.siteUseId,
        caregiverSiteUseId:
          authObj.registeredFaciltyAddress &&
          authObj.registeredFaciltyAddress.careGiverSiteUseId &&
          authObj.registeredFaciltyAddress.careGiverSiteUseId !== ""
            ? authObj.registeredFaciltyAddress.careGiverSiteUseId
            : "",
      };
      const allAertsForRO = await getAlertsforRo(reqParams);
      if (allAertsForRO) {
        const resp = await parseResponseForAllAlerts(allAertsForRO);
        return resp;
      }
    }
  };

  const parseResponseForAllAlerts = async (allAertsForRO: any) => {
    let allAlertsForRO: Array<IPatientAlert> = [];

    if (allAertsForRO.missinRxAlert !== null) {
      allAlertsForRO.push(createAlert(IAlertTypes.MISRX));
    }
    if (allAertsForRO.missingDocumentAlert !== null) {
      allAlertsForRO.push(
        getMissingDocsAlert(
          IAlertTypes.MSDOC,
          "",
          allAertsForRO.missingDocumentAlert
        )
      );
    }
    if (allAertsForRO.dischargePendingAlert !== null) {
      allAlertsForRO.push(createAlert(IAlertTypes.DISPEN));
    }
    if (allAertsForRO.confirmPlacementAlert !== null) {
      allAlertsForRO.push(createAlert(IAlertTypes.CONPL));
    }

    if (allAertsForRO.measurementDueAlert !== null) {
      let dueAlerts = createMeasurementDueAlertRo(
        allAertsForRO.measurementDueAlert.wounds,
        allAertsForRO.measurementDueAlert.alertId
      );
      dueAlerts.forEach((x) => {
        allAlertsForRO.push(x);
      });
    }
    if (allAertsForRO.proofOfDeliveryAlert !== null) {
      allAlertsForRO.push(createAlert(IAlertTypes.PODEL));
    }
    if (allAertsForRO.supplyOrderAlert != null) {
      const supplyOrderAlert = allAertsForRO.supplyOrderAlert
        .supplyOrderData as Array<ISuppyOrderAlertDataForRO>;
      supplyOrderAlert.forEach((x) => {
        if (x.type === "SHIPPED") {
          allAlertsForRO.push(
            getSupplyOrderDeliveredAlert(
              IAlertTypes.SUPSH,
              x.ropn,
              new Date(x.shippedOn),
              x.trackingNumber!
            )
          );
        } else if (x.type === "ORDERED") {
          allAlertsForRO.push(
            getSupplyOrderPendingAlert(IAlertTypes.PNDSO, x.displayText, x.ropn)
          );
        }
      });
    }
    let patientforAlerts: IPatient = {
      roNumber: Number(allAertsForRO.RON),
      orderID: allAertsForRO.OrderId,
      alerts: allAlertsForRO,
      firstName: "",
      lastName: "",
      dob: "",
      facilityName: "",
      isAcuteOrder: false,
      orderCreationDate: "",
      patientId: null,
      patientLocation: null,
      status: allAertsForRO.status,
    };
    setUpdatedAlertDataForRO(patientforAlerts);
    return patientforAlerts;
  };

  const callAllDocTabApi = async () => {
    setOrderDetailLoader(true);

    let apiCollection = [
      fetchPrintableDocumentsLinks(),
      fetchCommonDocsContent(),
      fetchCommonDocsContent(),
      downloadFaxCoverSheet(),
      fetchDropdownContentsForDocType(),
      getAllDocumentsDetail(),
    ];
    const result = await Promise.all(apiCollection);
    if (result && result.length > 4) {
      setIsDocTabLoaded(true);
    }
  };

  const loadWoundAssessmentData = async () => {
    await getMesurmentDueAlertCount();
    let reqParams = {
      rentalOrderNumber: patientData.roNumber,
      dob: moment(patientData.dob).format("yyyy-MM-DD"),
      woundID: orderOverViewObj?.woundId.toString(),
    };
    const woundAssessments = await retrievePatientWoundDetails(reqParams);
    if (woundAssessments.succeeded) {
      setWoundAssessmentData(woundAssessments.item);
      let defaultComorbidities: any = [];
      if (woundAssessments?.item.assessments.length > 0) {
        defaultComorbidities =
          woundAssessments?.item?.assessments[0]?.comorbidities.map(
            (x: string) => x.trim().toLowerCase()
          );
      }
      let autoPopulateComorbidites = woundAssessComorboditiesData.map((x) => {
        return {
          ...x,
          selected: defaultComorbidities.includes(x.code?.toLowerCase()),
        };
      });
      woundAssesmentObj?.setAutoPopulateComorbidities(autoPopulateComorbidites);
      let woundAssesmentsArray: IWoundAssesments[] =
        woundAssessments?.item?.assessments!;
      let assesmentListArray: Array<IWoundAssesmentsMap> = [];
      woundAssesmentsArray
        .sort((a: any, b: any) =>
          a.evaluationDate > b.evaluationDate ? 1 : a.volume > b.volume ? 1 : -1
        )
        .map((x: any) => {
          if (x.volume !== null) {
            let assesmentData: IWoundAssesmentsMap = {
              evaluationDate: x.evaluationDate.toString(),
              Volume: x.volume,
            };
            assesmentListArray.push(assesmentData);
          }
        });
      setAssesmentList(assesmentListArray);
      if (assesmentListArray.length > 10) {
        setInterval(Math.floor(assesmentListArray.length / 10));
      } else setInterval(0);
      setIsWoundDetailTabLoaded(true);
    } else setIsWoundDetailTabLoaded(true);
  };

  const getAllSupplyOrderAndVacOrderData = async () => {
    let apiCollection = [
      getOrderDetailsFromMS(patientData),
      fetchVACTherapyInformationData(),
      setQuestionnaireContent(),
    ];
    if (!vacAllProducts) {
      apiCollection.push(fetchVacDressingProducts());
    }
    if (
      !orderOverViewObj?.orderDetailsTrackingData &&
      !orderOverViewObj?.error
    ) {
      apiCollection.push(getorderDetailsTrackingData());
      apiCollection.push(getOrderDetailsPdfContent());
    }
    const result = await Promise.all(apiCollection);
    if (result && result.length > 3) {
      await getOrderOverviewDetails(result[0], result[2]);
    }
  };

  const fetchOrderSourceCapsuleType = async () => {
    const data = await getdropDownContent(CMS_ORDER_SOURCE_CONTENT);
    if (data.items.length > 0) {
      const orderSourceObject = data.items.filter(
        (item: { name: string }) => item.name === CMS_ORDER_SOURCE_CONTENT
      );
      const orderSourceData = orderSourceObject[0].data.sort(
        (a: { order: number }, b: { order: number }) =>
          a.order > b.order ? 1 : -1
      );
      setOrderSourceCode(orderSourceData);
    }
  };

  const fetchAllSupplyOrderForSelectedRO = async () => {
    const respObj = await getOrderSuppliesDetail();
    if (orderOverViewObj?.orderTabTitle === "Orders...") {
      if (respObj && respObj.supplyOrders.length > 0) {
        orderOverViewObj?.setOrderTabTitle(
          OrderOverViewTabsTitle.ORDER_TAB_TITLE
        );
      } else {
        orderOverViewObj?.setOrderTabTitle(
          OrderOverViewTabsTitle.ORDER_DETAIL_TAB_TITLE
        );
      }
    }
    return respObj;
  };

  const woundDetailsForSelectedRO = async () => {
    const params = {
      RentalOrderNumber: patientData.roNumber,
      DOB: moment(patientData.dob).format("MM/DD/yyyy"),
      isAcuteOrder: patientData.isAcuteOrder,
    };
    const responseObj = await fetchWoundList(params);
    if (responseObj && responseObj?.succeeded) {
      setWoundListData(responseObj?.item);
      if (responseObj?.item?.wounds) {
        if (!orderOverViewObj?.woundId) {
          if (responseObj?.item?.wounds?.length > 1) {
            orderOverViewObj?.setWoundProgressTabTitle(
              OrderOverViewTabsTitle.WOUND_PROGRESS_TAB_TITLE
            );
            setIsWoundProgressTabLoaded(true);
          } else {
            orderOverViewObj?.setWoundProgressTabTitle(
              OrderOverViewTabsTitle.WOUND_DETAIL_TAB_TITLE
            );
            orderOverViewObj?.setWoundId(responseObj?.item?.wounds[0]?.id);
          }
        } else {
          orderOverViewObj?.setWoundProgressTabTitle(
            OrderOverViewTabsTitle.WOUND_DETAIL_TAB_TITLE
          );
        }
      } else {
        orderOverViewObj?.setWoundProgressTabTitle(
          OrderOverViewTabsTitle.WOUND_DETAIL_TAB_TITLE
        );
        setIsWoundDetailTabLoaded(true);
        setWoundListData(responseObj);
      }
    } else {
      orderOverViewObj?.setWoundProgressTabTitle(
        OrderOverViewTabsTitle.WOUND_DETAIL_TAB_TITLE
      );
      setIsWoundDetailTabLoaded(true);
      setWoundListData(responseObj);
    }
    return responseObj;
  };

  const validateAndSetData = async (e: any) => {
    const selectedOption = e.target.value.toLowerCase();
    if (patientData) {
      if (
        selectedOption === "Hold Therapy".toLowerCase() ||
        selectedOption === "Resume Therapy".toLowerCase()
      ) {
        setWounds(undefined);
        setIsHoldTherapy(selectedOption === "Hold Therapy".toLowerCase());
        setOpenTherapyPopUp(true);
        setShowLoader(true);
        const result = await Promise.all([
          getWoundList(
            patientData.roNumber,
            patientData.dob,
            patientData.isAcuteOrder
          ),
          getTherapyStartDate(
            patientData,
            authObj?.userProfile?.userName,
            authObj?.registeredFaciltyAddress?.siteUseId
          ),
        ]);
        const woundListResponse = result[0];
        const therapyResponse = result[1];
        if (woundListResponse?.succeeded && therapyResponse.succeeded) {
          setShowLoader(false);
          setWounds(woundListResponse?.updatedWounds);
          setTherapyStartDate(therapyResponse.date);
        } else if (!woundListResponse.succeeded) {
          if (woundListResponse.error.errorCode.toString() === "917") {
            setErrorMessage(NO_WOUND_ERROR_MESSAGE_FOR_HOLD_THERAPY);
          } else {
            setErrorMessage(HOLD_OR_RESUME_THERAPY_ERROR_MESSAGE);
          }
          setErrorCode(
            woundListResponse?.error?.errorCode || woundListResponse?.status
          );
          setShowLoader(false);
          setErrorPopUpFlag(true);
        } else if (
          !therapyResponse.succeeded &&
          therapyResponse.error.errorCode
        ) {
          setErrorCode(
            therapyResponse?.error?.errorCode || therapyResponse?.status
          );
          setErrorMessage(HOLD_OR_RESUME_THERAPY_ERROR_MESSAGE);
          setShowLoader(false);
          setErrorPopUpFlag(true);
        }
      } else if (
        selectedOption === "Send Prescription".toLowerCase() &&
        patientData
      ) {
        setAlertTypeAndPatientData(IAlertTypes.MISRX, patientData);
      } else if (
        selectedOption === "Cancel Patient Order".toLowerCase() &&
        patientData
      ) {
        setIsPatientCancelOrder(true);
      } else if (
        selectedOption === "Create Transition Order".toLowerCase() &&
        patientData
      ) {
        newOrderObj?.resetNewOrderForm();
        history.push({
          pathname: "/orders/newOrder",
          state: {
            ...(patientData.isCustomerPlus
              ? { isTransitionFromCustomerPlus: true }
              : { createTransition: true }),
            roNumber: patientData?.roNumber,
            siteUseID: authObj?.registeredFaciltyAddress?.siteUseId,
            userName: authObj?.userProfile?.userName,
            serialNumber: patientData.isCustomerPlus
              ? patientData?.customerPlusProduct?.serialNumber
              : patientData?.productSerialNumber,
          },
        });
      } else if (
        selectedOption ===
          CustomerPlusMenuActionTypes.END_THERAPHY.toLowerCase() &&
        patientData
      ) {
        handleOpenPopupForEndTherapy(patientData.customerPlusProduct!);
      } else {
        NavigatePatientAction(
          patientData,
          selectedOption.toLowerCase(),
          setRemovePatientMsg,
          setEmptyPopup,
          setRemovePatientOpen,
          SupplyOrderObj,
          sendNoteObj,
          orderOverViewObj,
          history,
          location,
          () => {},
          pickUpRequestObj,
          dischargeRequestObj,
          confirmPlacementObj,
          submitProofOfDeliveryObj,
          exchangeVacUnitObj,
          acuteOrderPickUpRequestObj,
          createServiceRequestObj,
          setShowLoader,
          authObj,
          () => {},
          () => {}
        );
      }
    }
  };

  const handleOpenPopupForEndTherapy = (
    customerPlusObj: ICustomerPlusListingDetails | null
  ) => {
    if (customerPlusObj) {
      setSelectedProductDetails(customerPlusObj);
    }
    setOpenContainerPopupFlag(true);
    setPopUpFlow(InventoryPopUpFlow.END_THERAPY);
  };

  const getMesurmentDueAlertCount = async () => {
    let measurementDueAlertData: IPatientAlert[] | undefined = [];
    if (orderOverViewObj?.woundId) {
      measurementDueAlertData = updatedAlertDataForRO?.alerts?.filter(
        (x: any) =>
          x.alertName === "Measurements Due" &&
          x.woundOrderID === orderOverViewObj?.woundId.toString()
      );
    } else if (!orderOverViewObj?.woundId) {
      measurementDueAlertData = updatedAlertDataForRO?.alerts?.filter(
        (x: any) => x.alertName === "Measurements Due"
      );
    }
    if (measurementDueAlertData) {
      let filteredAlert = measurementDueAlertData?.filter(
        (alert) => alert.severity !== undefined && alert.severity !== 0
      );
      var alertCount = filteredAlert?.length;
      if (alertCount) setMeasurementDueHeaderCount(alertCount);
    }
  };

  const getOrderSuppliesDetail = async () => {
    let reqParams: IGetOrderSuppliesRequest = {
      RentalOrderNumber: patientData.roNumber,
      dob: patientData.dob
        ? moment(patientData.dob).format("yyyy-MM-DD")
        : null,
      isAcuteOrder: patientData?.isAcuteOrder,
    };
    try {
      const responseObj = await getOrderSupplies(reqParams);
      if (responseObj?.succeeded) {
        const data = responseObj;
        const supplyDetails: IOrderSupplies = data.item;
        setOrderSupplyDetail(supplyDetails);
        return supplyDetails;
      } else return false;
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleCloseRemovePatient = () => {
    setRemovePatientOpen(false);
    orderOverViewObj!.resetData();
    history.push("/home");
  };

  const getorderDetailsTrackingData = async () => {
    if (
      patientData &&
      patientData.roNumber &&
      patientData.dob &&
      patientData.dob !== "" &&
      authObj &&
      authObj.userProfile &&
      authObj.userProfile.userName &&
      authObj.userProfile.userName !== "" &&
      authObj.registeredFaciltyAddress &&
      checkForSearchHomeCareOrder(authObj.registeredFaciltyAddress)
    ) {
      let reqParams = {
        RentalOrderNumber: patientData.roNumber,
        DOB: patientData.dob,
        userName: authObj.userProfile.userName,
        siteUseId: authObj.registeredFaciltyAddress.siteUseId,
      };
      try {
        const result = await getOrderStatusDetails(reqParams);
        if (result.error) {
          if (
            result.error.errorCode === 5045 &&
            patientData.status === "Closed"
          ) {
            orderOverViewObj?.setError(true);
            let respObj: IOrderDetailResponse;
            respObj = {
              orderNumber: "",
              patientFirstName: "",
              patientLastName: "",
              salesRepName: "",
              orderDate: "",
              salesRepPhone: "",
              salesRepEmail: "",
              csrName: "",
              csrPhone: "",
              csrEmail: "",
              trackingLink: "",
              trackingNumber: "",
              receivedFlag: "",
              releaseToShipFlag: "",
              releaseDate: "",
              validatedFlag: "",
              benefitsComplDate: "",
              therapyFlag: "",
              therapyDate: "",
              deliveredDate: "",
              deliveredFlag: "",
              outForDeliveryFlag: "",
              outDeliveryDate: "",
              deliverySiteType: "",
              rentalProduct: "",
              orderClosedDate: "",
              pssName: "",
              pssEmail: "",
              pssPhone: "",
              orderSource: "",
            };
            orderOverViewObj?.setOrderDetailsTrackingData(respObj);
          } else {
            setOrderDetailLoader(false);
            setErrorPopUpFlag(true);
            setErrorCode(result.error?.errorCode);
            setErrorMessage(ERROR_MSG_ACUTE_ORDER_VIEW);
          }
        } else {
          let respObj: IOrderDetailResponse;
          respObj = {
            orderNumber: getBlankForNullValue(result.item.orderNumber),
            patientFirstName: getBlankForNullValue(
              result.item.patientDataFirstName
            ),
            patientLastName: getBlankForNullValue(
              result.item.patientDataLastName
            ),
            salesRepName: getBlankForNullValue(result.item.salesRepName),
            orderDate: getBlankForNullValue(result.item.orderDate),
            salesRepPhone: getBlankForNullValue(result.item.salesRepPhone),
            salesRepEmail: getBlankForNullValue(result.item.salesRepEmail),
            csrName: getBlankForNullValue(result.item.csrName),
            csrPhone: getBlankForNullValue(result.item.csrPhone),
            csrEmail: getBlankForNullValue(result.item.csrEmail),
            trackingLink: getBlankForNullValue(result.item.trackingLink),
            trackingNumber: getBlankForNullValue(result.item.trackingNumber),
            receivedFlag: result.item.receivedFlag,
            releaseToShipFlag: result.item.releaseToShipFlag,
            releaseDate: result.item.releaseDate,
            validatedFlag: getBlankForNullValue(result.item.validatedFlag),
            benefitsComplDate: getBlankForNullValue(
              result.item.benefitsComplDate
            ),
            therapyFlag: result.item.therapyFlag,
            therapyDate: result.item.therapyDate,
            orderClosedDate: result.item.orderClosedDate,
            deliveredDate: result.item.deliveredDate,
            deliveredFlag: result.item.deliveredFlag,
            outForDeliveryFlag: result.item.outForDeliveryFlag,
            outDeliveryDate: result.item.outDeliveryDate,
            deliverySiteType: result.item.deliverySiteType,
            rentalProduct: getBlankForNullValue(result.item.rentalProduct),
            pssName: getBlankForNullValue(
              result.item?.salesRepDetail &&
                result.item?.salesRepDetail[0]?.name
            ),
            pssPhone: getBlankForNullValue(
              result.item?.salesRepDetail &&
                result.item?.salesRepDetail[0]?.phoneNo
            ),
            pssEmail: getBlankForNullValue(
              result.item?.salesRepDetail &&
                result.item?.salesRepDetail[0]?.emailAddress
            ),
            orderSource: getBlankForNullValue(result.item.orderSource),
          };
          orderOverViewObj?.setOrderDetailsTrackingData(respObj);
          orderOverViewObj?.setSelectedSupplyTherapyStartDate(
            result.item.therapyDate === null ? "" : result.item.therapyDate
          );
        }
        return result;
      } catch (error) {
        orderOverViewObj?.setError(true);
        return false;
      }
    }
  };

  const getAcuteOrderDetails = async () => {
    if (
      patientData &&
      patientData.roNumber &&
      authObj &&
      authObj.userProfile &&
      authObj.userProfile.userName &&
      authObj.userProfile.userName !== "" &&
      authObj.registeredFaciltyAddress &&
      authObj.registeredFaciltyAddress.siteUseId &&
      authObj.registeredFaciltyAddress.siteUseId !== "" &&
      orderOverViewObj?.orderDetailsTrackingData === null
    ) {
      setOrderDetailLoader(true);
      fetchAllSupplyOrderForSelectedRO();
      let reqParams = {
        RentalOrderNumber: patientData.roNumber,
        userName: authObj.userProfile.userName,
        siteUseId: checkOrderOverViewDeeplink()
          ? patientData.siteUseId
          : authObj.registeredFaciltyAddress.siteUseId,
      };
      try {
        const result = await getAcuteOrderRoDetails(reqParams);
        let vacOrderSummaryDataResponseObject: AcuteSummaryData | null = null;
        if (result && !result.succeeded) {
          setOrderDetailLoader(false);
          setErrorPopUpFlag(true);
          setErrorCode(result.error?.errorCode);
          setErrorMessage(ERROR_MSG_ACUTE_ORDER_VIEW);
        } else {
          let respObj: IOrderDetailResponse;
          let prescriberResult;
          let isPrescriberDetailsLoaded: boolean = false;
          if (result && result.item && result.item.prescriberNPI) {
            let prescriberReqParams = {
              NPI: result.item.prescriberNPI,
            };
            prescriberResult = await getNPIPrescriber(prescriberReqParams);
            isPrescriberDetailsLoaded =
              prescriberResult != null &&
              prescriberResult.succeeded &&
              prescriberResult.items.length > 0
                ? true
                : false;
          }
          respObj = {
            orderNumber: getBlankForNullValue(result.item.orderNumber),
            //checked the api response and updated
            patientFirstName: getBlankForNullValue(
              result.item.patientFirstName
            ),
            patientLastName: getBlankForNullValue(result.item.patientLastName),
            salesRepName: getBlankForNullValue(
              result.item?.salesRepDetail &&
                result.item?.salesRepDetail[0]?.name
            ),
            orderDate: getBlankForNullValue(result.item.orderDate),
            salesRepPhone: getBlankForNullValue(
              result.item?.salesRepDetail &&
                result.item?.salesRepDetail[0]?.phoneNo
            ),
            salesRepEmail: getBlankForNullValue(
              result.item?.salesRepDetail &&
                result.item?.salesRepDetail[0]?.emailAddress
            ),
            csrName: getBlankForNullValue(
              result.item?.salesRepDetail &&
                result.item?.salesRepDetail[1]?.name
            ),
            csrPhone: getBlankForNullValue(
              result.item?.salesRepDetail &&
                result.item?.salesRepDetail[1]?.phoneNo
            ),
            csrEmail: getBlankForNullValue(
              result.item?.salesRepDetail &&
                result.item?.salesRepDetail[1]?.emailAddress
            ),
            trackingLink: getBlankForNullValue(result.item.trackingLink),
            trackingNumber: getBlankForNullValue(result.item.trackingNumber),
            receivedFlag: result.item.receivedFlag,
            releaseToShipFlag: result.item.releaseToShipFlag,
            releaseDate: result.item.releaseDate,
            validatedFlag: getBlankForNullValue(result.item.validatedFlag),
            benefitsComplDate: getBlankForNullValue(
              result.item.benefitsComplDate
            ),
            therapyFlag: result.item.therapyFlag,
            therapyDate: result.item.therapyDate,
            orderClosedDate: result.item.orderClosedDate,
            deliveredDate: result.item.deliveredDate,
            deliveredFlag: result.item.deliveredFlag,
            outForDeliveryFlag: result.item.outForDeliveryFlag,
            outDeliveryDate: result.item.outDeliveryDate,
            deliverySiteType: "In-Patient",
            rentalProduct: getBlankForNullValue(result.item.rentalProduct),
            brandName: getBlankForNullValue(result.item.brandName),
            imageLink: getBlankForNullValue(result.item.imageLink),
            purchaseOrderNo: getBlankForNullValue(result.item.purchaseOrderNo),
            orderSource: getBlankForNullValue(result.item.orderSource),
            billingAddress: getBillingAddress(result.item.billingAddress),
          };

          vacOrderSummaryDataResponseObject = {
            customerFirstName: getBlankForNullValue(
              result.item.patientFirstName
            ),
            customerLastName: getBlankForNullValue(result.item.patientLastName),
            customerDOB: getBlankForNullValue(result.item.patientDOB),
            prescriptionMethod: 0,
            prescriberFirstName: getBlankForNullValue(
              result.item.prescriberFirstname
            ),
            prescriberLastName: getBlankForNullValue(
              result.item.prescriberLastName
            ),
            prescriberEmailAddress: getBlankForNullValue(
              result.item.prescriberEmail
            ),
            prescriberNPI: getBlankForNullValue(result.item.prescriberNPI),
            prescriberPhoneNumber: isPrescriberDetailsLoaded
              ? getBlankForNullValue(prescriberResult.items[0].telephoneNumber)
              : "",
            prescriberAddress1: isPrescriberDetailsLoaded
              ? getBlankForNullValue(prescriberResult.items[0].address1)
              : "",
            prescriberAddress2: isPrescriberDetailsLoaded
              ? getBlankForNullValue(prescriberResult.items[0].address2)
              : "",
            prescriberFaxNumber: isPrescriberDetailsLoaded
              ? getBlankForNullValue(prescriberResult.items[0].faxNumber)
              : "",
            prescriberCity: isPrescriberDetailsLoaded
              ? getBlankForNullValue(prescriberResult.items[0].city)
              : "",
            prescriberState: isPrescriberDetailsLoaded
              ? getBlankForNullValue(prescriberResult.items[0].state)
              : "",
            prescriberZipCode: isPrescriberDetailsLoaded
              ? getBlankForNullValue(prescriberResult.items[0].zipCode)
              : "",
            serialNumber: getBlankForNullValue(result.item.serialNumber),
            vacUnit: getBlankForNullValue(result.item.brandName),
            deliveryDueDate: getBlankForNullValue(result.item.neededby),
            deliveryNeedByTime: getBlankForNullValue(result.item.neededby),
            deliverySiteType: 0,
            deliverySiteName: "",
            deliveryInstructions: getBlankForNullValue(
              result.item.deliveryAddress.deliveryInstructions
            ),
            deliveryFirstName: getBlankForNullValue(
              result.item.deliveryAddress.contactFirstName
            ),
            deliveryLastName: getBlankForNullValue(
              result.item.deliveryAddress.contactLastName
            ),
            deliveryPhoneNumber: getBlankForNullValue(
              result.item.deliveryAddress.phone
            ),
            patientId: getBlankForNullValue(result.item.patientId),
            patientLocation: getBlankForNullValue(result.item.patientLocation),
          };
          orderOverViewObj?.setOrderDetailsTrackingData(respObj);
          let placementDate: IInputField = {
            value: patientData?.orderCreationDate,
            valid: ValidationStatus.VALID,
          };

          let woundInfoData: IWoundInformation = {
            primaryWoundInformation: inPatientWoundInfoResponseMapper(
              result?.item?.wounds?.[0] ?? {}
            ),
            secondaryWoundInformation: inPatientWoundInfoResponseMapper(
              result?.item?.wounds?.[1] ?? {}
            ),
            woundInfoCount: {
              valid: ValidationStatus.UNTOUCHED,
              value: result?.item?.wounds?.length ?? 0,
            },
          };
          let replacementInformation: IReplacementInformationInterface = {
            deliveryLocation: { valid: ValidationStatus.VALID, value: "" },
            isCallOnEstimatedArrivalTime: {
              valid: ValidationStatus.VALID,
              value: "",
            },
            firstName: {
              valid: ValidationStatus.VALID,
              value: result?.item?.deliveryAddress?.contactFirstName ?? "",
            },
            lastName: {
              valid: ValidationStatus.VALID,
              value: result?.item?.deliveryAddress?.contactLastName ?? "",
            },
            phoneNumber: {
              valid: ValidationStatus.VALID,
              value: result?.item?.deliveryAddress?.phone ?? "",
            },
            department: { valid: ValidationStatus.VALID, value: "" },
            deliveryInstructions: {
              valid: ValidationStatus.VALID,
              value:
                result?.item?.deliveryAddress?.deliveryInstructions?.trim() ??
                "",
            },
          };

          orderOverViewObj.setInPatientWoundInformation(woundInfoData);
          orderOverViewObj.setInPatientReplacementInformation(
            replacementInformation
          );
          orderOverViewObj.setInPatientPlacementDate(placementDate);
          orderOverViewObj.setInPatientDeliveryPreferences(
            deliveryPreferenceResponseMapper(result)
          );
          return vacOrderSummaryDataResponseObject;
        }
        return result;
      } catch (error) {
        orderOverViewObj?.setError(true);
        setOrderDetailLoader(true);
        setErrorPopUpFlag(true);
        setErrorMessage(ERROR_MSG_ACUTE_ORDER_VIEW);
        return false;
      }
    } else {
      history.replace("/home");
    }
  };
  const getWoundInfoCount = (res: any): IInputField => {
    let count: number = 0;
    let woundInfoCount: IInputField = {
      valid: ValidationStatus.UNTOUCHED,
      value: "0",
    };
    if (res.hasOwnProperty("primaryWound") && res.primaryWound) {
      count++;
    }
    if (res.hasOwnProperty("secondaryWound") && res.secondaryWound) {
      count++;
    }

    return { ...woundInfoCount, value: String(count) };
  };

  const splitValues = (locationWritterString: string): string[] => {
    let directionAndOrientation: string[] = [];
    if (locationWritterString && locationWritterString !== "") {
      directionAndOrientation = locationWritterString.split(",");
    } else {
      directionAndOrientation = ["", ""];
    }
    return directionAndOrientation;
  };

  const getWoundVolume = (woundData: IInpatientWoundInformation): string => {
    const lengthValue = woundData.woundLength.value;
    const widthValue = woundData.woundWidth.value;
    const depthValue = woundData.woundDepth.value;
    if (
      isNaN(parseFloat(lengthValue)) ||
      isNaN(parseFloat(widthValue)) ||
      isNaN(parseFloat(depthValue))
    ) {
      return "--";
    }
    const length = parseFloat(lengthValue);
    const width = parseFloat(widthValue);
    const depth = parseFloat(depthValue);
    const volume = (length * width * depth).toFixed(1);
    return `${formatedWoundValue(volume.toString())} cm³`;
  };
  const woundInfoResponseMapper = (res: any): IInpatientWoundInformation => {
    let woundInformation: IInpatientWoundInformation = {
      woundLocation: {
        valid: ValidationStatus.UNTOUCHED,
        value: res.location ?? "",
      },
      woundDirection: {
        valid: ValidationStatus.UNTOUCHED,
        value: splitValues(res.locationWritten)[0],
      },
      woundType: {
        valid: ValidationStatus.UNTOUCHED,
        value: res.type ?? "",
      },
      woundOrientation: {
        valid: ValidationStatus.UNTOUCHED,
        value: splitValues(res.locationWritten)[1].trim(),
      },
      woundMeasurementDate: {
        valid: ValidationStatus.UNTOUCHED,
        value: res.measurementDate
          ? moment(res.measurementDate).format("yyyy-MM-DD")
          : "",
      },
      woundLength: {
        valid: ValidationStatus.UNTOUCHED,
        value: res.length ?? "",
      },
      woundWidth: {
        valid: ValidationStatus.UNTOUCHED,
        value: res.width ?? "",
      },
      woundDepth: {
        valid: ValidationStatus.UNTOUCHED,
        value: res.depth ?? "",
      },
      woundVolume: {
        valid: ValidationStatus.UNTOUCHED,
        value: "",
      },
    };
    woundInformation.woundVolume.value = getWoundVolume(woundInformation) ?? "";
    return woundInformation;
  };

  const getCustomerPlusAcuteOrderDetails = async () => {
    let imageLink = null;
    let productNameDesc = null;
    if (
      patientData &&
      patientData.roNumber &&
      patientData.isCustomerPlus &&
      authObj &&
      authObj.userProfile &&
      authObj.userProfile.userName &&
      authObj.userProfile.userName !== "" &&
      authObj.registeredFaciltyAddress &&
      authObj.registeredFaciltyAddress.siteUseId &&
      authObj.registeredFaciltyAddress.siteUseId !== "" &&
      orderOverViewObj?.orderDetailsTrackingData === null
    ) {
      orderOverViewObj?.setOrderTabTitle(
        OrderOverViewTabsTitle.ORDER_DETAIL_TAB_TITLE
      );
      setOrderDetailLoader(true);
      let imgLinkReqParams = {
        ProductName:
          patientData?.customerPlusProduct?.productName ??
          patientData.productName,
      };
      const imageLinkResult = await getAcuteProductImageLink(imgLinkReqParams);
      if (imageLinkResult && imageLinkResult.succeeded) {
        imageLink = imageLinkResult.item.imageLink;
        productNameDesc = imageLinkResult.item.brandName;
      } else {
        setOrderDetailLoader(false);
        setErrorPopUpFlag(true);
        setErrorCode(
          imageLinkResult.error?.errorCode ||
            imageLinkResult.error?.code ||
            imageLinkResult.status
        );
        setErrorMessage(ERROR_MSG_ACUTE_ORDER_VIEW);
      }
      let reqParams = {
        SiteUseId: authObj.registeredFaciltyAddress.siteUseId,
        SerialNumber: patientData.productSerialNumber,
        RoNumber: patientData.roNumber.toString(),
      };
      try {
        const result = await getCustomerPlusAcuteOrderRoDetails(reqParams);
        let vacOrderSummaryDataResponseObject: AcuteSummaryData | null = null;
        if (result && !result.succeeded) {
          setOrderDetailLoader(false);
          setErrorPopUpFlag(true);
          setErrorCode(
            result.error?.errorCode || result.error?.code || result.status
          );
          setErrorMessage(ERROR_MSG_ACUTE_ORDER_VIEW);
        } else {
          let prescriberReqParams = {
            NPI: result.data.prescriberNPI,
          };
          const presriberDataResult = await getNPIPrescriber(
            prescriberReqParams
          );
          const isPresriberDetails: boolean =
            presriberDataResult != null &&
            presriberDataResult.succeeded &&
            presriberDataResult.items.length > 0
              ? true
              : false;
          const menuActions = result.data.menuItems.map((action: any) => {
            return {
              text: action.sequenceText,
              sequence: action.sequenceNo,
            };
          });
          let patientResponseData: IPatient = {
            roNumber: patientData.roNumber,
            firstName: patientData.firstName,
            lastName: patientData.lastName,
            dob: result.data.patientDOB,
            facilityName: authObj?.registeredFaciltyAddress?.accountName!,
            orderCreationDate: patientData.orderCreationDate,
            alerts: [],
            isAcuteOrder: true,
            patientId: result.data.patientId,
            patientLocation: result.data.patientLocation,
            productName: patientData.productName,
            status: "Therapy",
            statusColor: "blueCircle",
            isCustomerPlus: true,
            menuActions: menuActions,
            customerPlusProduct: patientData.customerPlusProduct,
            type: patientData.type,
          };
          if (
            patientResponseData.isAcuteOrder &&
            patientResponseData.isCustomerPlus &&
            patientResponseData.menuActions &&
            patientResponseData.menuActions.length > 0
          ) {
            patientResponseData.menuActions = patientResponseData.menuActions
              .map((x) => {
                let menuItemPermission = getAcuteMenuActionPermission(
                  x.text,
                  permissionObj!.mappedRolesPermissionData
                );
                if (menuItemPermission) {
                  return x;
                } else return null;
              })
              .filter((x) => x !== null) as IMenuAction[];
          }
          setPatientData(patientResponseData);

          const productInfo: IOrderOverviewProductInfo = {
            isReadyCare: {
              valid: ValidationStatus.VALID,
              value: patientData.isReadyCare ?? false,
            },
            productValue: {
              valid: ValidationStatus.VALID,
              value: patientData.productNameDescription ?? "",
            },
            serialNumber: {
              valid: ValidationStatus.VALID,
              value: patientData.productSerialNumber ?? "",
            },
          };
          orderOverViewObj.setProductInfo(productInfo);

          let woundInfoData: IWoundInformation = {
            primaryWoundInformation: woundInfoResponseMapper(
              result.data.primaryWound ?? defaultInPatientPrimaryWoundInfo
            ),
            secondaryWoundInformation: woundInfoResponseMapper(
              result.data.secondaryWound ?? defaultInPatientPrimaryWoundInfo
            ),
            woundInfoCount: getWoundInfoCount(result.data),
          };
          let respObj: IOrderDetailResponse;
          respObj = {
            orderNumber: getBlankForNullValue(result.data.orderNumber),
            patientFirstName: getBlankForNullValue(
              result.data.patientFirstName
            ),
            patientLastName: getBlankForNullValue(result.data.patientLastName),
            salesRepName: getBlankForNullValue(
              result.data?.salesRepDetail &&
                result.data?.salesRepDetail[0]?.name
            ),
            orderDate: getBlankForNullValue(result.data.orderDate),
            salesRepPhone: getBlankForNullValue(
              result.data?.salesRepDetail &&
                result.data?.salesRepDetail[0]?.phoneNo
            ),
            salesRepEmail: getBlankForNullValue(
              result.data?.salesRepDetail &&
                result.data?.salesRepDetail[0]?.emailAddress
            ),
            csrName: getBlankForNullValue(
              result.data?.salesRepDetail &&
                result.data?.salesRepDetail[1]?.name
            ),
            csrPhone: getBlankForNullValue(
              result.data?.salesRepDetail &&
                result.data?.salesRepDetail[1]?.phoneNo
            ),
            csrEmail: getBlankForNullValue(
              result.data?.salesRepDetail &&
                result.data?.salesRepDetail[1]?.emailAddress
            ),
            trackingLink: getBlankForNullValue(result.data.trackingLink),
            trackingNumber: getBlankForNullValue(result.data.trackingNumber),
            receivedFlag: result.data.receivedFlag,
            releaseToShipFlag: result.data.releaseToShipFlag,
            releaseDate: result.data.releaseDate,
            validatedFlag: getBlankForNullValue(result.data.validatedFlag),
            benefitsComplDate: getBlankForNullValue(
              result.data.benefitsComplDate
            ),
            therapyFlag: result.data.therapyFlag,
            therapyDate: result.data.therapyDate,
            orderClosedDate: result.data.orderClosedDate,
            deliveredDate: result.data.deliveredDate,
            deliveredFlag: result.data.deliveredFlag,
            outForDeliveryFlag: result.data.outForDeliveryFlag,
            outDeliveryDate: result.data.outDeliveryDate,
            deliverySiteType: "In-Patient",
            rentalProduct: getBlankForNullValue(result.data.rentalProduct),
            brandName: getBlankForNullValue(productNameDesc),
            imageLink: getBlankForNullValue(imageLink),
            purchaseOrderNo: getBlankForNullValue(result.data.purchaseOrderNo),
            orderSource: getBlankForNullValue(result.data.orderSource),
          };

          vacOrderSummaryDataResponseObject = {
            customerFirstName: getBlankForNullValue(
              result.data.patientFirstName
            ),
            customerLastName: getBlankForNullValue(result.data.patientLastName),
            customerDOB: getBlankForNullValue(result.data.patientDOB),
            prescriptionMethod: 0,
            prescriberFirstName: getBlankForNullValue(
              result.data.prescriberFirstname
            ),
            prescriberLastName: getBlankForNullValue(
              result.data.prescriberLastName
            ),
            prescriberEmailAddress: getBlankForNullValue(
              result.data.prescriberEmail
            ),
            prescriberNPI: getBlankForNullValue(result.data.prescriberNPI),
            prescriberPhoneNumber: isPresriberDetails
              ? getBlankForNullValue(
                  presriberDataResult.items[0].telephoneNumber
                )
              : "",
            prescriberAddress1: isPresriberDetails
              ? getBlankForNullValue(presriberDataResult.items[0].address1)
              : "",
            prescriberAddress2: isPresriberDetails
              ? getBlankForNullValue(presriberDataResult.items[0].address2)
              : "",
            prescriberFaxNumber: isPresriberDetails
              ? getBlankForNullValue(presriberDataResult.items[0].faxNumber)
              : "",
            prescriberCity: isPresriberDetails
              ? getBlankForNullValue(presriberDataResult.items[0].city)
              : "",
            prescriberState: isPresriberDetails
              ? getBlankForNullValue(presriberDataResult.items[0].state)
              : "",
            prescriberZipCode: isPresriberDetails
              ? getBlankForNullValue(presriberDataResult.items[0].zipCode)
              : "",
            serialNumber: getBlankForNullValue(result.data.serialNumber),
            vacUnit: getBlankForNullValue(result.data.brandName),
            deliveryDueDate: getBlankForNullValue(result.data.neededby),
            deliveryNeedByTime: getBlankForNullValue(result.data.neededby),
            deliverySiteType: 0,
            deliverySiteName: "",
            deliveryInstructions: "",
            deliveryFirstName: "",
            deliveryLastName: "",
            deliveryPhoneNumber: "",
            patientId: getBlankForNullValue(result.data.patientId),
            patientLocation: getBlankForNullValue(result.data.patientLocation),
          };
          orderOverViewObj.setInPatientWoundInformation(woundInfoData);
          getAndSetTherapyDropDownData(patientResponseData);
          orderOverViewObj?.setOrderDetailsTrackingData(respObj);
          return vacOrderSummaryDataResponseObject;
        }
        return result;
      } catch (error) {
        orderOverViewObj?.setError(true);
        setOrderDetailLoader(true);
        setErrorPopUpFlag(true);
        setErrorMessage(ERROR_MSG_ACUTE_ORDER_VIEW);
        setErrorCode(ORDER_OVERVIEW_DATA_MAPPING_ISSUE_FOR_CUSTOMERPLUS);
        return false;
      }
    } else {
      history.replace("/home");
    }
  };

  const getOrderDetailsPdfContent = async () => {
    try {
      if (patientData?.roNumber) {
        const reqBody = {
          RentalOrderNumber: patientData.roNumber.toString(),
        };
        const responseObj = await getVTIAFPDFContent(reqBody);
        if (responseObj.succeeded) {
          const pdfContent = responseObj.item.documentContent;
          const url = getPdfUrl(pdfContent);
          orderOverViewObj?.setPdfUrl(url);
        } else {
          orderOverViewObj?.setPdfUrl("");
        }
      }
      return true;
    } catch (error) {
      console.log("error", error);
      return false;
    }
  };

  const fetchDropdownContentsForDocType = async () => {
    try {
      const data = await getdropDownContent(DD_UPLOAD_DOCUMENT_TYPE);
      if (data.items.length > 0) {
        const uploadDocumentmentObject = data.items.filter(
          (item: { name: string }) => item.name === DD_UPLOAD_DOCUMENT_TYPE
        );
        const uploadDocumentData = uploadDocumentmentObject[0].data.sort(
          (a: { order: number }, b: { order: number }) =>
            a.order > b.order ? 1 : -1
        );
        setdocumentTypeCode(uploadDocumentData);
        setdocumentTypeText(
          uploadDocumentData.map((x: { text: string }) => x.text)
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getAllDocumentsDetail = async () => {
    let requestParams: IGetAllDocumentsRequest = {
      RentalOrderNumber: patientData.roNumber,
    };
    try {
      const responseObj = await getAllDocuments(requestParams);
      if (responseObj.succeeded) {
        const data = responseObj;
        if (data && data.items.length > 0) {
          orderOverViewObj?.setDocumentDetails(data.items);
        }
      } else {
        setIsDocTabLoaded(true);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getOrderDetailsFromMS = async (patient: IPatient) => {
    try {
      if (
        authObj &&
        authObj.userProfile &&
        authObj.userProfile.userName &&
        authObj.userProfile.userName !== "" &&
        authObj.registeredFaciltyAddress &&
        checkForSearchHomeCareOrder(authObj.registeredFaciltyAddress)
      ) {
        let reqParams = {
          RentalOrderNumber: patient.roNumber,
          DOB: moment(patient.dob).format("DD-MMM-yyyy"),
          userName: authObj.userProfile.userName,
          siteUseId: authObj.registeredFaciltyAddress.siteUseId,
        };
        const response = await getVACOrderDetails(reqParams);
        if (response && response.succeeded) {
          const vacOrderSummary: VacOrderSummaryData = response.item;
          return vacOrderSummary;
        } else {
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const fetchVACTherapyInformationData = async () => {
    try {
      if (!vacTherapyInformationData) {
        const data = await getCMSContent(CMS_VAC_THERAPY_INFORMATION_CONTENT);
        if (data.item !== undefined) {
          setVacTherapyInformationData(data.item);
          return true;
        } else {
          return false;
        }
      }
    } catch (error) {
      console.log("error", error);
      return false;
    }
  };

  const fetchVacDressingProducts = async () => {
    try {
      const response = await getVacDressingKitProducts();
      if (response && response.items.length > 0) {
        setAllVacProducts(response.items);
      }
      return response;
    } catch (error) {
      console.log("error", error);
      return false;
    }
  };

  const setQuestionnaireContent = async () => {
    const questionsData = await getAdditionalWoundQuestionaries();
    const mapData = new Map<string, WoundQuestionaries>();
    questionsData.forEach((val: any) => {
      if (!mapData.has(val.woundType)) {
        mapData.set(val.woundType, {
          additionalQuestion: val.additionalQuestion.map((quest: Question) => ({
            ...quest,
            value: "",
            valid: ValidationStatus.UNTOUCHED,
          })),
          category: val.category,
          woundType: val.woundType,
        });
      }
    });
    orderOverViewObj?.setWoundQuestionnaries(mapData);
    return mapData;
  };

  const fetchNPIPrescriber = async (vacOrderSummary: VacOrderSummaryData) => {
    let prescriberData: IPrescriberModal;
    let reqParams = {
      NPI: vacOrderSummary.prescriberNPI,
    };
    const data = await getNPIPrescriber(reqParams);
    if (data != null && data.succeeded && data.items.length > 0) {
      orderOverViewObj?.setPrescriberAddedData({
        ...data.items[0],
        email: vacOrderSummary.prescriberEmailAddress,
      });
    } else {
      prescriberData = {
        firstName: vacOrderSummary.prescriberFirstName,
        lastName: vacOrderSummary.prescriberLastName,
        npi: vacOrderSummary.prescriberNPI,
        email: vacOrderSummary.prescriberEmailAddress,
        city: "",
        state: "",
        telephoneNumber: "",
        zipCode: "",
        address1: "",
        address2: "",
      };
      orderOverViewObj?.setPrescriberAddedData(prescriberData);
    }
  };

  const getOrderOverviewDetails = async (
    vacOrderSummary: any,
    woundQuestionaries: any
  ) => {
    if (vacOrderSummary) {
      let patientBasicDataMapperResponse: INewOrder =
        await mapVacOrderOverviewResponse(
          vacOrderSummary,
          insuranceTypes,
          orderOverViewObj!.setShowAdditionalObject,
          providerTypes
        );
      if (vacOrderSummary.prescriberFirstName) {
        await fetchNPIPrescriber(vacOrderSummary);
      }
      let dressingMapperRes: IDressingKit = mapDressingKitData();
      let canisterMapperRes: ICanister = mapCanisterData(vacOrderSummary);
      let accessoryMapperRes: IProductAccessory =
        mapAccessoryData(vacOrderSummary);
      if (vacOrderSummary.mainDressing && vacOrderSummary.mainDressing.sku) {
        dressingMapperRes = vacPrimaryDressingKitMapperData(
          vacOrderSummary,
          dressingMapperRes!,
          vacAllProducts,
          orderOverViewObj
        );
        if (
          vacOrderSummary.additionalDressing &&
          vacOrderSummary.additionalDressing.sku
        ) {
          dressingMapperRes = vacSecondaryDressingKitMapperData(
            vacOrderSummary,
            dressingMapperRes!,
            vacAllProducts,
            orderOverViewObj
          );
        }
      }
      vacCannisterMapperData(vacOrderSummary, orderOverViewObj);

      orderOverViewObj!.setIsGetOpenRoMSApiError(
        vacOrderSummary.getOpenRoMSAPIError
      );
      orderOverViewObj!.setIsGetPatientInfoMSApiError(
        vacOrderSummary.getPatientInfoMSAPIError
      );
      orderOverViewObj!.setIsGetClinicalInfoMSAPIError(
        vacOrderSummary.getClinicalInfoMSAPIError
      );
      orderOverViewObj!.setIsOrderStatusTrackingMSApiError(
        vacOrderSummary.getOrderStatusTrackingMSAPIError
      );

      orderOverViewObj!.setOrderOverviewData(patientBasicDataMapperResponse);
      orderOverViewObj?.setDressingKit(dressingMapperRes!);
      orderOverViewObj?.setCanister(canisterMapperRes!);
      orderOverViewObj?.setAccessory(accessoryMapperRes!);
      var requesterInfo = mapRequesterInfoData(
        vacOrderSummary,
        authObj?.userProfile?.firstName
      );
      orderOverViewObj?.setRequesterInfo(requesterInfo);
      var productInfo = mapProductInfoData(vacOrderSummary);
      orderOverViewObj?.setProductInfo(productInfo);
      const deliveryInformation = mapDeliveryInformationData(vacOrderSummary);
      orderOverViewObj?.setDeliveryInformation(deliveryInformation);
      var clinicalInfo = mapClinicalInformationData(vacOrderSummary);
      orderOverViewObj?.setWoundInfoData(clinicalInfo);
      var primaryQuestions = woundQuestionaries.get(
        clinicalInfo.woundType?.value
      );
      if (primaryQuestions) {
        var primaryWoundQuestionares = mapWoundQuestionariesData(
          vacOrderSummary.primaryWound,
          primaryQuestions!
        );
        orderOverViewObj?.setPrimaryAdditionalQuestions(
          primaryWoundQuestionares
        );
      }
      if (clinicalInfo.isShowSecondaryWoundInfo.value === "Yes") {
        var secondaryWoundInfo =
          mapSecondaryWoundIformationData(vacOrderSummary);
        orderOverViewObj?.setSecondaryWoundInfoData(secondaryWoundInfo);
        var secondaryQuestions = woundQuestionaries?.get(
          secondaryWoundInfo.woundType?.value
        );
        if (secondaryQuestions) {
          var secondaryWoundQuestionares = mapWoundQuestionariesData(
            vacOrderSummary.secondaryWound,
            secondaryQuestions!
          );
          orderOverViewObj?.setSecondaryAdditionalQuestions(
            secondaryWoundQuestionares
          );
        }
      }
      setIsOrderOverviewDataLoaded(true);
    } else {
      const mappres = orderOverViewObj?.orderOverviewData;
      mappres!.wasNPWTInitiated = {
        valid: ValidationStatus.VALID,
        value: "",
        isDefaultValid: true,
      };
      mappres!.wasNPWTUsedin60Days = {
        valid: ValidationStatus.VALID,
        value: "",
        isDefaultValid: true,
      };

      mappres!.contributingCause = {
        valid: ValidationStatus.UNTOUCHED,
        value: "",
      };
      orderOverViewObj?.setOrderOverviewData(mappres!);
      setIsOrderOverviewDataLoaded(true);
    }
  };

  const backPageNavigation = () => {
    if (selectedTab === OrderOverViewTabsTitle.ORDER_TAB_TITLE) {
      if (
        orderOverViewObj?.orderTabTitle ===
          OrderOverViewTabsTitle.ORDER_TAB_TITLE &&
        orderSupplyDetail &&
        orderSupplyDetail.supplyOrders &&
        orderSupplyDetail.supplyOrders.length > 0
      ) {
        history.push("/home");
      } else if (
        orderOverViewObj?.orderTabTitle ===
          OrderOverViewTabsTitle.ORDER_DETAIL_TAB_TITLE &&
        orderSupplyDetail &&
        orderSupplyDetail.supplyOrders &&
        orderSupplyDetail.supplyOrders.length <= 1
      ) {
        checkOrderOverViewDeeplink() || authObj?.isInternalUser
          ? history.push("/home")
          : history.goBack();
      } else if (
        orderOverViewObj?.orderTabTitle ===
          OrderOverViewTabsTitle.ORDER_DETAIL_TAB_TITLE &&
        orderSupplyDetail &&
        orderSupplyDetail.supplyOrders &&
        orderSupplyDetail.supplyOrders.length > 0
      ) {
        orderOverViewObj?.setOrderTabTitle(
          OrderOverViewTabsTitle.ORDER_TAB_TITLE
        );
      } else if (
        orderOverViewObj?.orderTabTitle ===
          OrderOverViewTabsTitle.ORDER_DETAIL_TAB_TITLE &&
        (orderSupplyDetail === undefined || orderSupplyDetail === null)
      ) {
        (checkOrderOverViewDeeplink() || authObj?.isInternalUser) &&
        !patientData?.isCustomerPlus
          ? history.push("/home")
          : history.goBack();
      } else if (
        orderOverViewObj?.orderTabTitle ===
        OrderOverViewTabsTitle.SUPPLY_ORDER_TAB_TITLE
      ) {
        orderOverViewObj?.setOrderTabTitle(
          OrderOverViewTabsTitle.ORDER_TAB_TITLE
        );
        orderOverViewObj?.resetSeletedSupplyOrderData();
      }
    } else if (
      selectedTab === OrderOverViewTabsTitle.WOUND_PROGRESS_TAB_TITLE
    ) {
      if (
        orderOverViewObj?.woundProgressTabTitle ===
          OrderOverViewTabsTitle.WOUND_DETAIL_TAB_TITLE &&
        orderOverViewObj?.isWoundProgressFlow === true
      ) {
        orderOverViewObj?.setWoundProgressTabTitle(
          OrderOverViewTabsTitle.WOUND_PROGRESS_TAB_TITLE
        );
        orderOverViewObj?.setIsWoundProgressFlow(false);
      } else if (
        orderOverViewObj?.woundProgressTabTitle ===
          OrderOverViewTabsTitle.WOUND_DETAIL_TAB_TITLE &&
        orderOverViewObj?.isWoundProgressFlow === false
      ) {
        checkOrderOverViewDeeplink() || authObj?.isInternalUser
          ? history.push("/home")
          : history.goBack();
      } else if (
        orderOverViewObj?.woundProgressTabTitle ===
          OrderOverViewTabsTitle.WOUND_PROGRESS_TAB_TITLE &&
        woundListData?.wounds &&
        woundListData?.wounds?.length > 1
      ) {
        checkOrderOverViewDeeplink() || authObj?.isInternalUser
          ? history.push("/home")
          : history.goBack();
      }
    } else
      checkOrderOverViewDeeplink() || authObj?.isInternalUser
        ? history.push("/home")
        : history.goBack();
  };

  const fetchSupplyOrderData = async () => {
    setIsSupplyOrderLoaded(false);
    orderOverViewObj?.setOrderTabTitle(
      OrderOverViewTabsTitle.SUPPLY_ORDER_TAB_TITLE
    );
    let supplyOrderData = await getSupplyOrderDetails();
    if (supplyOrderData) {
      mapSupplyOrderResponse(supplyOrderData);
    } else {
      setIsSupplyOrderLoaded(true);
    }
  };

  const getSupplyOrderDetails = async () => {
    try {
      if (patientData?.roNumber) {
        let reqParams = {
          RentalOrderNumber: patientData?.roNumber,
          DOB: patientData?.dob,
          ROPN: orderOverViewObj?.selectedSupplyOrder?.ropn,
        };
        const responseObj = await getSupplyOrderDetailsapi(reqParams);
        if (responseObj.succeeded) {
          const supplyOrderData: ISupplyOrderInfoMapper = responseObj.item;
          setSupplyOrderData(supplyOrderData);
          return supplyOrderData;
        } else return false;
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const mapSupplyOrderResponse = (supplyOrderData: any) => {
    let supplyCanisterMapperRes: any = mapAccessoryCanistorDressingData(
      supplyOrderData,
      "Canister"
    );
    let canister: ISupplyOrderCanister = {
      canister: supplyCanisterMapperRes,
    };
    setCanister(canister);
    let supplyAccessoriesMapperRes: any = mapAccessoryCanistorDressingData(
      supplyOrderData,
      "Accessories"
    );
    let accessory: ISupplyOrderAccessory = {
      accessory: supplyAccessoriesMapperRes,
    };
    setAccessory(accessory);
    let supplyDressingMapperRes: any = mapAccessoryCanistorDressingData(
      supplyOrderData,
      "Dressing"
    );
    let dressing: ISupplyOrderDressingKit = {
      dressing: supplyDressingMapperRes,
    };
    setDressing(dressing);
    orderOverViewObj?.setOrderTabTitle(
      OrderOverViewTabsTitle.SUPPLY_ORDER_TAB_TITLE
    );
    setIsSupplyOrderLoaded(true);
  };

  const fetchPrintableDocumentsLinks = async () => {
    try {
      const response = await getCMSContent(CMS_VAC_THERAPY_INFORMATION_CONTENT);
      if (response.succeeded) {
        const printableDocresponseObj: IPrintableDocumentsPdf = {
          VACTherapyOrderPad: response.item.orderPad.FileLink,

          VACTherapyInsuranceAuthorizationForm:
            response.item.authorizationForm.FileLink,
        };

        setPrintableDocumentsObj(printableDocresponseObj);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const fetchCommonDocsContent = async () => {
    try {
      const ddContent = format("{0}", DD_COMMON_DOCS_CONTENT);
      const data = await getdropDownContent(ddContent);
      if (data.items.length > 0) {
        const commonDocObject = data.items.filter(
          (item: { name: string }) => item.name === DD_COMMON_DOCS_CONTENT
        );
        const commonDocArray = commonDocObject[0].data.sort(
          (a: { order: number }, b: { order: number }) =>
            a.order > b.order ? 1 : -1
        );
        setCommonDocs(commonDocArray);
        setCommonDocsText(commonDocArray.map((x: { text: string }) => x.text));
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const downloadFaxCoverSheet = async () => {
    const patientFirstName = patientData?.firstName;
    const patientLastName = patientData?.lastName;
    const patientDob = moment(patientData?.dob).format("YYYY-MM-DD");
    const rentalOrderNumber = patientData?.roNumber;
    let reqParam = {
      FirstName: patientFirstName,
      LastName: patientLastName,
      DOB: patientDob,
      RON: rentalOrderNumber,
      SiteUseId: getSiteUseId(authObj),
      UserName: authObj?.userProfile?.userName,
    };
    try {
      const response = await generateFaxCoverSheet(reqParam);
      if (response.succeeded) {
        const url = getPdfUrl(response.item);
        setPDFURL(url);
      } else {
        setPDFURL("");
      }
      return true;
    } catch (error) {
      console.log("error", error);
      return false;
    }
  };

  const setOrderSelectedTab = async (selectedTabDetails: any) => {
    setSelectedTab(selectedTabDetails);
    orderOverViewObj?.setSelectedOrderTab(selectedTabDetails);
  };

  const closeAllTherapyPopups = () => {
    setOpenTherapyPopUp((x) => !x);
    setTimeout(() => {
      setWounds(undefined);
      setShowErrorPopUp(false);
      setShowWarningPoppUp(false);
      setShowSuccessPopUp(false);
    }, 500);
  };

  const closeTherapyPopUp = () => {
    setWounds(undefined);
    setShowSuccessPopUp(true);
  };

  const showWarningMessage = () => {
    setWounds(undefined);
    setShowWarningPoppUp(true);
  };

  const setAlertTypeAndPatientData = (
    alertType: IAlertTypes,
    selectedPatientData: any
  ) => {
    setSelectedPatientChangePres(selectedPatientData);
    setAlertType(alertType);
    setPatientActionAlertPopUp(true);
  };

  const getAndSetAcuteOrderDetails = async () => {
    let apiCollection;
    if (patientData && patientData.isCustomerPlus) {
      apiCollection = [getCustomerPlusAcuteOrderDetails()];
    } else {
      apiCollection = [getAcuteOrderDetails()];
    }
    const result = await Promise.all(apiCollection);
    let vacOrderSummary = result[0];
    if (vacOrderSummary) {
      let patientBasicDataMapperResponse: INewOrder =
        await mapVacOrderOverviewResponse(
          vacOrderSummary,
          insuranceTypes,
          orderOverViewObj!.setShowAdditionalObject,
          providerTypes,
          patientData.isAcuteOrder
        );
      if (vacOrderSummary.prescriberFirstName) {
        let prescriberData: IPrescriberModal;
        prescriberData = {
          firstName: vacOrderSummary.prescriberFirstName,
          lastName: vacOrderSummary.prescriberLastName,
          npi: vacOrderSummary.prescriberNPI,
          email: vacOrderSummary.prescriberEmailAddress,
          city: vacOrderSummary.prescriberCity,
          state: vacOrderSummary.prescriberState,
          telephoneNumber: vacOrderSummary.prescriberPhoneNumber,
          zipCode: vacOrderSummary.prescriberZipCode,
          address1: vacOrderSummary.prescriberAddress1,
          address2: vacOrderSummary.prescriberAddress2,
          faxNumber: vacOrderSummary.prescriberFaxNumber,
        };
        orderOverViewObj?.setPrescriberAddedData(prescriberData);
      }

      orderOverViewObj!.setOrderOverviewData(patientBasicDataMapperResponse);

      if (patientData && !patientData.isCustomerPlus) {
        var productInfo = mapProductInfoData(vacOrderSummary);
        orderOverViewObj?.setProductInfo(productInfo);
      }
      const deliveryInformation = mapDeliveryInformationData(vacOrderSummary);
      orderOverViewObj?.setDeliveryInformation(deliveryInformation);
      setIsOrderOverviewDataLoaded(true);
      setOrderDetailLoader(false);
    } else {
      setErrorPopUpFlag(true);
      setErrorMessage(ERROR_MSG_ACUTE_ORDER_VIEW);
      setOrderDetailLoader(false);
    }
  };

  useEffect(() => {
    if (
      patientData &&
      patientData?.isSubmitWoundFailed &&
      patientData?.alerts?.length === 1
    ) {
      setUpdatedAlertDataForRO(patientData);
    }
    fetchOrderSourceCapsuleType();
  }, []);

  const spinner = () => {
    return (
      <div>
        <div className="saveapi-header"></div>
        <div className="saveapi-spinner">
          <LoadingSpinner />
        </div>
      </div>
    );
  };

  const LoadSpinner = () => {
    return (
      <div className="therapy-loader">
        <LoadingSpinner />
      </div>
    );
  };

  const LoadTherapy = () => {
    if (wounds && therapyStartDate && !showSuccessPopUp) {
      return isHoldTherapy ? (
        <HoldTherapy
          closePopup={closeTherapyPopUp}
          patient={patientData}
          showWarningPopup={showWarningMessage}
          therapyStartDate={therapyStartDate}
          wounds={wounds}
        />
      ) : (
        <ResumeTherapy
          closePopup={closeTherapyPopUp}
          patient={patientData}
          showWarningPopup={showWarningMessage}
          therapyStartDate={therapyStartDate}
          wounds={wounds}
        />
      );
    } else if (showSuccessPopUp) {
      return isHoldTherapy ? (
        <SuccessPopUp
          title="Success!"
          description1="The hold request has been successfully applied to the patient order."
          description2="If a resumption date was not indicated, you will need to manually resume therapy by selecting “Resume Therapy after Hold” from the patient actions."
          buttonTitle="Done"
          btnAction={() => {
            setOpenTherapyPopUp(false);
            setTimeout(() => {
              setShowSuccessPopUp(false);
            }, 500);
          }}
        />
      ) : (
        <SuccessPopUp
          title="Success!"
          description1="The hold has been removed and therapy can resume for the patient indicated."
          description2=""
          buttonTitle="Done"
          btnAction={() => {
            setOpenTherapyPopUp(false);
            setTimeout(() => {
              setShowSuccessPopUp(false);
            }, 500);
          }}
        />
      );
    } else if (!wounds && showWarningPoppUp && !showSuccessPopUp) {
      return (
        <SendNoteFailure
          rootClass="warning-pop-up"
          message={
            isHoldTherapy
              ? HOLD_THERAPY_ERROR_MESSAGE
              : RESUME_THERAPY_ERROR_MESSAGE
          }
          backButtonAction={closeAllTherapyPopups}
        />
      );
    }
  };

  const renderPatientActionPopUp = () => {
    switch (alertType) {
      case IAlertTypes.MISRX:
        return (
          <MissingRx
            isSendPrescription={true}
            closePopUpAction={() => setPatientActionAlertPopUp(false)}
            patientData={selectedPatientChangePres!}
          />
        );
      default:
        return <div style={{ width: "200px", height: "56px" }}></div>;
    }
  };

  const sectionToDisplay = () => {
    let page: ReactNode;
    switch (selectedTab) {
      case OrderOverViewTabs.ORDER_DETAILS:
        if (
          orderOverViewObj?.orderTabTitle ===
            OrderOverViewTabsTitle.ORDER_DETAIL_TAB_TITLE &&
          orderOverViewObj?.selectedOrderTab !==
            OrderOverViewTabsTitle.DOCUMENTS_TAB_TITLE
        ) {
          page = (
            <OrderDetailsStepper
              isOrderFlow={orderOverViewObj?.isOrderFlow!}
              patientData={patientData}
              isOrderSummary={true}
              newOrderData={orderOverViewObj!.orderOverviewData}
              requesterInfo={orderOverViewObj?.requesterInfo}
              insuranceTypes={insuranceTypes}
              insuranceTypesText={insuranceTypesText}
              accidentTypes={accidentTypes}
              therapyLengths={therapyLengths}
              therapyGoals={therapyGoals}
              vacTherapyInformationData={vacTherapyInformationData!}
              dressingKit={orderOverViewObj?.dressingKit}
              canister={orderOverViewObj?.canister}
              accessory={orderOverViewObj?.accessory}
              productInfo={orderOverViewObj?.productInfo!}
              prodInfoTypes={prodInfoTypes!}
              secondaryWoundInfoData={orderOverViewObj?.secondaryWoundInfoData!}
              woundInfoData={orderOverViewObj?.woundInfoData!}
              deliveryInformation={orderOverViewObj?.deliveryInformation}
              deliverySites={deliverySites!}
              states={states}
              statesText={statesText}
              orderDetailLoaderCompleted={orderDetailLoader}
              selectedTab={selectedTab}
              alertsForRO={updatedAlertDataForRO}
              reasonForTransition={reasonForTransition}
              setUpdatedAlertDataForRO={setUpdatedAlertDataForRO}
              orderSourceCode={orderSourceCode}
            />
          );
        } else if (
          orderOverViewObj?.orderTabTitle ===
          OrderOverViewTabsTitle.ORDER_TAB_TITLE
        ) {
          page = (
            <OrderStepper
              patientData={patientData}
              selectedTab={selectedTab}
              isOrderFlow={isOrderFlow!}
              orderSupplyDetail={orderSupplyDetail}
              alertsForRO={updatedAlertDataForRO}
              setShowLoader={setShowLoader}
            />
          );
        } else if (
          orderOverViewObj?.orderTabTitle ===
          OrderOverViewTabsTitle.SUPPLY_ORDER_TAB_TITLE
        ) {
          if (patientData.isAcuteOrder) {
            page = <InPatientSupplyOrderDetail />;
          } else {
            page = (
              <SupplyOrderStepper
                patientData={patientData}
                supplyOrderData={supplyOrderData}
                selectedSupplyOrderData={orderOverViewObj?.selectedSupplyOrder!}
                canister={canister}
                accessory={accessory}
                dressing={dressing}
                therapyStartDate={
                  orderOverViewObj?.selectedSupplyTherapyStartDate!
                }
                alertsForRO={updatedAlertDataForRO}
              />
            );
          }
        }
        break;
      case OrderOverViewTabs.PATIENT_FINANCIAL_INFO:
        page = (
          <PatientFinancialInfoStepper
            patientData={patientData}
            newOrderData={orderOverViewObj!.orderOverviewData!}
          />
        );
        break;
      case OrderOverViewTabs.DOCUMENTS:
        page = (
          <DocumentStepper
            selectedTab={selectedTab}
            printableDocumentsLink={printableDocumentsObj}
            patientData={patientData}
            commonDocs={commonDocs}
            commonDocsText={commonDocsText}
            pdfUrl={pdfUrl}
            documentTypeCode={documentTypeCode}
            submittedDocumentsLoader={orderDetailLoader}
            documentTypeText={documentTypeText}
            alertsForRO={updatedAlertDataForRO}
            vacTherapyInformationData={vacTherapyInformationData}
          />
        );
        break;
      case OrderOverViewTabs.WOUND_PROGRESS:
        if (
          orderOverViewObj?.woundProgressTabTitle ===
          OrderOverViewTabsTitle.WOUND_PROGRESS_TAB_TITLE
        ) {
          page = <WoundProgress woundDetails={woundListData} />;
        } else if (
          orderOverViewObj?.woundProgressTabTitle ===
          OrderOverViewTabsTitle.WOUND_DETAIL_TAB_TITLE
        ) {
          page = (
            <WoundDetail
              woundDetails={woundListData}
              selectedPatientAlertData={patientData}
              selectedWoundId={orderOverViewObj?.woundId}
              woundIndex={orderOverViewObj?.woundIndex}
              woundAssessmentDetails={woundAssessmentData!}
              assesmentList={assesmentList}
              interval={interval}
              alertsForRO={updatedAlertDataForRO}
            />
          );
        }
        break;
    }
    return page;
  };
  const [scrollTabValue, setScrollTabValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setScrollTabValue(newValue);
  };

  useEffect(() => {
    switch (orderOverViewObj?.selectedOrderTab) {
      case OrderOverViewTabsTitle.ORDER_TAB_TITLE:
        setScrollTabValue(0);
        break;
      case OrderOverViewTabsTitle.ORDER_DETAIL_TAB_TITLE:
        setScrollTabValue(0);
        break;
      case OrderOverViewTabsTitle.SUPPLY_ORDER_TAB_TITLE:
        setScrollTabValue(0);
        break;
      case OrderOverViewTabsTitle.DOCUMENTS_TAB_TITLE:
        setScrollTabValue(1);
        break;
      case OrderOverViewTabsTitle.WOUND_DETAIL_TAB_TITLE:
        setScrollTabValue(2);
        break;
      case OrderOverViewTabsTitle.WOUND_PROGRESS_TAB_TITLE:
        setScrollTabValue(2);
        break;
      case OrderOverViewTabsTitle.PATIENT_FINANCIAL_INFO_TAB_TITLE:
        setScrollTabValue(3);
        break;
      default:
        setScrollTabValue(0);
        break;
    }
  }, [orderOverViewObj?.selectedOrderTab]);

  const customDropDownUI = () => {
    return (
      <CustomSelect
        handleChange={validateAndSetData}
        menuItem={menuaActionArray}
        name="therapyOptionss"
        placeHolder="Therapy Options"
        selectpropsClassName={"dropdown-info-select"}
        selectClassName={"dropdown-info-input"}
        value={""}
        isMobile={isMobileScreen}
        root="dropdown-info-input-root"
      />
    );
  };

  return (
    <>
      <div
        className={`order-overview-component ${
          authObj?.isUnlinkedFacilityOrder
            ? "order-overview-component-unlinked"
            : ""
        }`}
      >
        {orderOverViewObj?.isTabHeaderVisible && (
          <>
            <div className="header-first">
              <div className="header-back-icon">
                {!authObj?.isUnlinkedFacilityOrder && (
                  <>
                    {isMobileScreen ? (
                      <BackMobile
                        onClick={() => {
                          backPageNavigation();
                        }}
                        className="back-button"
                        data-testid="orderOverviewBckBtnMobile"
                      />
                    ) : (
                      <Back
                        onClick={() => {
                          backPageNavigation();
                        }}
                        className="back-button"
                        data-testid="orderOverviewBckBtn"
                      />
                    )}
                  </>
                )}
              </div>
              <div
                className={`header ${
                  authObj?.isUnlinkedFacilityOrder ? "header-unlinked" : ""
                }`}
                data-testid="header"
              >
                {patientData && (
                  <>
                    <h2 className="header-name">
                      {makeCapitalEachWordInString(patientData.firstName) +
                        " " +
                        makeCapitalEachWordInString(patientData.lastName)}
                    </h2>
                    <h4 className="header-dob">
                      DOB:{" "}
                      {patientData.dob
                        ? Moment(patientData.dob).format("L")
                        : null}
                    </h4>
                  </>
                )}
              </div>
              {!authObj?.isUnlinkedFacilityOrder && (
                <>
                  {isMobileScreen ? (
                    <div className="header-dropdown-div">
                      {customDropDownUI()}
                    </div>
                  ) : (
                    <div
                      className="dropdown-container"
                      data-testid="customeselect"
                    >
                      {customDropDownUI()}
                      <Button
                        startIcon={
                          isMobileScreen ? <DropDownIcon /> : <SelectIcon />
                        }
                        className="drop-down-button"
                      ></Button>
                    </div>
                  )}
                </>
              )}
            </div>

            <div className="scroll-tab-div">
              <ScrollableTabHeader
                scrollTabValue={scrollTabValue}
                handleChange={handleChange}
                tabsIndicator="scroll-tabs-indicator"
                tabsRoot="scroll-tabs"
                variant="scrollable"
              >
                <Tab
                  classes={{
                    textColorPrimary: "scroll-tabs-textlabel-order-overview",
                    selected: "scroll-tabs-textlabel-select-order-overview",
                    root: "scroll-tabs-height",
                  }}
                  data-testid="tab-orders"
                  disableRipple={true}
                  label={orderOverViewObj?.orderTabTitle!}
                  onClick={() =>
                    setOrderSelectedTab(OrderOverViewTabsTitle.ORDER_TAB_TITLE)
                  }
                />
                {!patientData?.isAcuteOrder && (
                  <Tab
                    classes={{
                      textColorPrimary: "scroll-tabs-textlabel-order-overview",
                      selected: "scroll-tabs-textlabel-select-order-overview",
                      root: "scroll-tabs-height",
                    }}
                    disableRipple={true}
                    icon={
                      documentHeaderCount ? (
                        <span className="notification-count">
                          {documentHeaderCount}
                        </span>
                      ) : (
                        ""
                      )
                    }
                    iconPosition="end"
                    label="Documents"
                    onClick={() => setOrderSelectedTab("Documents")}
                  />
                )}
                {!patientData?.isAcuteOrder && (
                  <Tab
                    classes={{
                      textColorPrimary: "scroll-tabs-textlabel-order-overview",
                      selected: "scroll-tabs-textlabel-select-order-overview",
                      root: "scroll-tabs-height",
                    }}
                    disableRipple={true}
                    icon={
                      measurementDueHeaderCount ? (
                        <span className="notification-count notification-wounddetail-style">
                          {measurementDueHeaderCount}
                        </span>
                      ) : (
                        ""
                      )
                    }
                    iconPosition="end"
                    label={orderOverViewObj?.woundProgressTabTitle!}
                    onClick={() => setOrderSelectedTab("Wound Progress")}
                  />
                )}
                {!patientData?.isAcuteOrder && (
                  <Tab
                    classes={{
                      textColorPrimary: "scroll-tabs-textlabel-order-overview",
                      selected: "scroll-tabs-textlabel-select-order-overview",
                      root: "scroll-tabs-height",
                    }}
                    data-testid="patient-financial-info-tab"
                    disableRipple={true}
                    label="Patient Financial Info"
                    onClick={() => setSelectedTab("Patient Financial Info")}
                  />
                )}
              </ScrollableTabHeader>
            </div>
          </>
        )}
        <div className="tabview-page" data-testid="tab-nav">
          {!errorPopUpFlag && !orderDetailLoader && sectionToDisplay()}
        </div>
      </div>
      <SnackBar
        toastStyle="removePatientToast"
        openFlag={removePatientOpen}
        msg={removePatientMsg}
        handleCloseAlert={handleCloseRemovePatient}
        autoClose={removePatientOpen}
      ></SnackBar>
      <Popup
        closeHandler={() => {
          setEmptyPopup((x) => !x);
        }}
        openFlag={emptyPopup}
      >
        <div className="emptyPopup"></div>
      </Popup>
      <Popup
        closeHandler={() => {
          setOpenTherapyPopUp((x) => !x);
          setTimeout(() => {
            setWounds(undefined);
            setShowErrorPopUp(false);
            setShowWarningPoppUp(false);
            setShowSuccessPopUp(false);
          }, 500);
        }}
        openFlag={openTherapyPopUp}
      >
        {LoadTherapy()}
      </Popup>
      <Popup
        openFlag={patientActionAlertPopUp}
        closeHandler={() => setPatientActionAlertPopUp(false)}
      >
        {renderPatientActionPopUp()}
      </Popup>
      <Popup
        closeHandler={() => {
          setIsPatientCancelOrder(false);
        }}
        openFlag={isPatientCancelOrder}
      >
        <CancelPatientOrder
          isComingFromMPD={false}
          isPatientCancelOrder={() => setIsPatientCancelOrder(false)}
          patient={patientData}
        />
      </Popup>
      {orderDetailLoader ? (
        <Popup
          hideCloseButton={true}
          openFlag={orderDetailLoader}
          closeHandler={() => {}}
        >
          {spinner()}
        </Popup>
      ) : (
        ""
      )}
      <Popup
        hideCloseButton={true}
        openFlag={showLoader}
        closeHandler={() => {}}
      >
        <LoadSpinner />
      </Popup>
      <ErrorPopup
        errorPopupFlag={errorPopUpFlag}
        handleBackButton={
          patientData?.isCustomerPlus
            ? () => history.goBack()
            : () => history.replace("/home")
        }
        popUpStyles="errorPopup"
        errorMessage={errorMessage}
        isSupportPhoneRequired={errorCode === "917" ? true : false}
        buttontitle="Done"
        showTitle={false}
        isShortSentence={false}
        errorCode={errorCode}
      />
      <Popup
        openFlag={openContainerPopupFlag}
        dialogParentClass="inventory-pop-up-container"
        closeHandler={handleClosePopUpAction}
        hideCloseButton={currentPopUp === InventoryPopUp.LOADER}
      >
        {popUpFlow && (
          <InventoryPopUpContainer
            closePopUp={handleClosePopUpAction}
            currentPopUp={currentPopUp}
            flow={popUpFlow}
            setCurrentPopUp={setCurrentPopUp}
            successAndClose={handleSuccessAndCloseAction}
            selectedCustomerPlusListingObj={selectedProductDetails!}
            setSelectedProductDetails={setSelectedProductDetails}
            patientData={patientData}
            isMPDFlow={patientData?.isDeepLink ?? false}
          />
        )}
      </Popup>
    </>
  );
};
