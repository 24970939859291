import { useContext } from "react";
import { IUserRolesPermission } from "../components/myPatients/userRolesPermission.interface";
import {
  ALERTMANAGER_FUNCTION_KEY,
  DOCUMENTMANAGER_FUNCTION_KEY,
  DURABLE_FUNCTION_KEY,
  FACILITY_MANAGER_FUNCTION_KEY,
  FUNCTION_SERVICE_KEY,
  INVENTORYMANAGER_FUNCTION_KEY,
  ORDERMANAGERFUNCTION_FUNCTION_KEY,
  PRODUCTMANAGER_FUNCTION_KEY,
  SITEACCESSMANAGER_FUNCTION_KEY,
  USERMANAGERFUNCTION_FUNCTION_KEY,
} from "../util/keyVaultNames";
import { getDeepClone } from "../util/ObjectFunctions";
import { defaultFunctionkeyDataModel } from "./functionKey.Model";

export const mapFunctionKeyData = async (keyVaultData: any) => {
  let permissionData: any;
  permissionData = getDeepClone(defaultFunctionkeyDataModel);
  const permission: any = {
    [ALERTMANAGER_FUNCTION_KEY]: "AlertManagerFunction",
    [SITEACCESSMANAGER_FUNCTION_KEY]: "SiteAccessFunction",
    [FUNCTION_SERVICE_KEY]: "FunctionKeyService",
    [DOCUMENTMANAGER_FUNCTION_KEY]: "DocumentManagerFunction",
    [ORDERMANAGERFUNCTION_FUNCTION_KEY]: "OrderManagerFunction",
    [USERMANAGERFUNCTION_FUNCTION_KEY]: "UserManagerFunction",
    [PRODUCTMANAGER_FUNCTION_KEY]: "ProductManagerFunction",
    [INVENTORYMANAGER_FUNCTION_KEY]: "InventoryManagerFunction",
    [DURABLE_FUNCTION_KEY]: "DurableFunction",
    [FACILITY_MANAGER_FUNCTION_KEY]: "FacilityManagerFunction",
  };

  if (keyVaultData) {
    if (keyVaultData.item.length > 0) {
      keyVaultData?.item.forEach((x: any) => {
        if (permission.hasOwnProperty(x.name)) {
          let val = permission[x.name];
          permissionData[val] = x.value;
        }
      });
    }
  }

  return permissionData;
};
