import { MultipleActionsProps } from "../../../../confirmPlacement/confirmPlacement.interface";

export const siteStatusDisplayAudienceData: MultipleActionsProps[] = [
  {
    id: "ALL",
    value: "All site visitors",
    label: "All site visitors",
    selected: false,
    disabled: false,
  },
  {
    id: "BASE",
    value: "Non-authenticated (public) users",
    label: "Non-authenticated (public) users",
    selected: false,
    disabled: false,
  },
  {
    id: "EXT",
    value: "Facility users",
    label: "Facility users",
    selected: false,
    disabled: false,
  },
  {
    id: "INT",
    value: "Internal 3M users",
    label: "Internal 3M users",
    selected: false,
    disabled: false,
  },
];
