import moment from "moment";
import { useDebounce } from "use-debounce";
import { useEffect, useState } from "react";
import "./woundAssessmentsReportFilter.css";
import SearchIcon from "@mui/icons-material/Search";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Grid, InputBase, TextField, useMediaQuery } from "@mui/material";
import { convertStringToDate } from "../../../../../util/utilityFunctions";
import { ValidationStatus } from "../../../../../core/interfaces/input.interface";
import { ReactComponent as CalendarIcon } from "../../../../../assets/calendar.svg";
import { InputWithLabel } from "../../../../../core/inputWithLabel/inputWithLabel.component";
import { IWoundAssessmentsReportFilterProps } from "./woundAssessmentsReportFilter.interface";

export const WoundAssessmentsReportFilter = ({
  data,
  setData,
}: IWoundAssessmentsReportFilterProps) => {
  const isMobileScreen = useMediaQuery("(max-width:600px)");

  const [searchInput, setSearchInput] = useState<string>("");
  const [searchedInput, setSearchedInput] = useState<string>("");
  const [debouncedText] = useDebounce(searchInput, 500);

  const [focusClasses, setFocusClasses] = useState({
    date: "",
  });

  const setClasses = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    classname: string
  ) => {
    setFocusClasses({
      ...focusClasses,
      [e.target.name]: classname,
    });
  };

  const updateData = (
    isValid: boolean,
    name: string,
    value: string,
    updateOther: boolean = false
  ) => {
    let tempData = {
      ...data,
      [name]: {
        ...Object(data)[name],
        valid: isValid ? ValidationStatus.VALID : ValidationStatus.INVALID,
        value: value,
        errorMessage: null,
      },
    };
    if (updateOther) {
      const otherName = name === "startDate" ? "endDate" : "startDate";
      tempData = {
        ...tempData,
        [otherName]: {
          ...Object(data)[otherName],
          valid: isValid ? ValidationStatus.VALID : ValidationStatus.INVALID,
          errorMessage: null,
        },
      };
    }
    if (tempData.startDate.valid === ValidationStatus.INVALID) {
      tempData.startDate.errorMessage =
        "Please enter a valid Start Date/End Date";
    }
    setData(tempData);
  };

  const validateAndSetData = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { value } = e.target;
    const regex = /^[^\s][\s\S]*$/;
    if (value === "" || regex.test(value)) {
      setSearchInput(value);
    }
  };

  const validateAndSetDate = (
    date1: string | null | undefined,
    name: string
  ) => {
    const formattedDate1 = date1 ? convertStringToDate(date1) : "";
    const date2: string =
      name === "endDate" ? data.startDate.value : data.endDate.value;
    const formattedDate2 = date2 ? convertStringToDate(date2) : "";
    let isValid: boolean = false;
    let updatedDate = formattedDate1;
    const currentDate = new Date();
    const start = new Date(
      name === "startDate" ? formattedDate1 : formattedDate2
    );
    const end = new Date(name === "endDate" ? formattedDate1 : formattedDate2);
    let isOtherRequiredToUpdate = false;
    if (formattedDate1 !== "" && formattedDate2 !== "") {
      isValid =
        start <= end &&
        ((name === "startDate" && start <= currentDate) ||
          (name === "endDate" && end <= currentDate));
      isOtherRequiredToUpdate =
        (name === "endDate" && start <= currentDate) ||
        (name === "startDate" && end <= currentDate);
    } else if (formattedDate1 !== "" || formattedDate2 !== "") {
      const selectedDate = new Date(formattedDate1);
      isValid = formattedDate1 !== "" && selectedDate <= currentDate;
    }
    updateData(isValid, name, updatedDate, isOtherRequiredToUpdate);
  };

  useEffect(() => {
    if (
      (debouncedText.length === 0 && searchedInput !== debouncedText) ||
      debouncedText.length >= 3
    ) {
      setSearchedInput(debouncedText);
      updateData(true, "searchInput", debouncedText);
    }
  }, [debouncedText]);

  return (
    <div
      className="wound-assements-report-filter-component"
      data-testid="wound-assements-report-filter-component"
      id="wound-assements-report-filter-component"
    >
      <Grid className="wound-assements-report-filter-grid-container" container>
        <Grid
          className="wound-assements-report-filter-grid-item"
          item
          xs={isMobileScreen ? 12 : 6}
        >
          <InputWithLabel
            label="Filter results"
            labelClassName="wound-assements-report-filter-input-field-title"
            testId="wound-assements-report-filter-input-field-title"
          >
            <div className="wound-assements-report-filter-input-field-searchbar">
              <div className="search-icon-div">
                <SearchIcon
                  className="search-icon"
                  data-testid="search-icon"
                  id="search-icon"
                />
              </div>
              <InputBase
                className="wound-assements-report-filter-input"
                data-testid="wound-assements-report-filter-input"
                name="searchInput"
                onChange={validateAndSetData}
                placeholder="Filter by Record ID, Rental Order #, Patient Name, Patient ID or Facility Acct #"
                value={searchInput}
              />
            </div>
          </InputWithLabel>
        </Grid>
        <Grid
          className="wound-assements-report-filter-grid-item"
          item
          xs={isMobileScreen ? 12 : 6}
        >
          <div className="wound-assements-report-filter-date-range">
            <InputWithLabel
              label={data.startDate.title!}
              labelClassName={focusClasses.date}
              testId="wound-assements-report-filter-start-date"
            >
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  components={{ OpenPickerIcon: CalendarIcon }}
                  InputAdornmentProps={{
                    classes: {
                      root: "adornedRoot",
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: `wound-assements-report-filter-date ${
                        data.startDate.valid === ValidationStatus.INVALID
                          ? "show-error"
                          : "no-error"
                      }`,
                      input: "input",
                      notchedOutline: "outline",
                    },
                  }}
                  maxDate={data.endDate.value}
                  onChange={(value) => validateAndSetDate(value, "startDate")}
                  renderInput={(params) => {
                    params.error = false;
                    params.inputProps!.placeholder = "__/__/____";
                    return (
                      <TextField
                        data-testid={data.startDate.id!}
                        id={data.startDate.id!}
                        name="startDate"
                        onFocus={(e) => setClasses(e, "Mui-focused")}
                        onBlur={(e) => setClasses(e, "")}
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          readOnly: true,
                        }}
                      />
                    );
                  }}
                  value={data.startDate.value}
                />
              </LocalizationProvider>
            </InputWithLabel>
            <InputWithLabel
              label={data.endDate.title!}
              labelClassName={focusClasses.date}
              testId="wound-assements-report-filter-end-date"
            >
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  components={{ OpenPickerIcon: CalendarIcon }}
                  InputAdornmentProps={{
                    classes: {
                      root: "adornedRoot",
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: `wound-assements-report-filter-date ${
                        data.endDate.valid === ValidationStatus.INVALID
                          ? "show-error"
                          : "no-error"
                      }`,
                      input: "input",
                      notchedOutline: "outline",
                    },
                  }}
                  maxDate={moment().toString()}
                  minDate={data.startDate.value}
                  onChange={(value) => validateAndSetDate(value, "endDate")}
                  renderInput={(params) => {
                    params.error = false;
                    params.inputProps!.placeholder = "__/__/____";
                    return (
                      <TextField
                        id={data.endDate.id!}
                        name="endDate"
                        onFocus={(e) => setClasses(e, "Mui-focused")}
                        onBlur={(e) => setClasses(e, "")}
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          readOnly: true,
                        }}
                      />
                    );
                  }}
                  value={data.endDate.value}
                />
              </LocalizationProvider>
            </InputWithLabel>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
