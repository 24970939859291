import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ReactComponent as RadioButtonIcon } from "../../../../../assets/radioButton.svg";
import { ReactComponent as SelectedRadioButtonIcon } from "../../../../../assets/selectedRadioButton.svg";
import {
  AuthContext,
  AuthContextType,
} from "../../../../../context/AuthContext";
import { CustomDropDown } from "../../../../../core/customDropdown/customDropdown.component";
import { ExpressButton } from "../../../../../core/expressButton/expressButton.component";
import { InputWithLabel } from "../../../../../core/inputWithLabel/inputWithLabel.component";
import { ValidationStatus } from "../../../../../core/interfaces/input.interface";
import { getDeepClone } from "../../../../../util/ObjectFunctions";
import { submitExchangeVac } from "../../../../../util/vacOrderService";
import { IStoredProdReturnInformation } from "../returnStoredProductUnit.interface";
import { defaultStoredProductReturnInformation } from "../returnStoredProductUnit.model";
import { ReturnStoredProductUnitValidator } from "../returnStoredProductUnit.validator";
import "./returnInformation.css";
import { InventoryPopUp } from "../../../popUpContainer/inventoryPopUpContainer.enum";
import { IInventoryProductDetails } from "../../../inventoryTabContainer.enum";
import { getSiteUseId } from "../../../../../util/utilityFunctions";
import { SUBMIT_RETURN_SERVICE_ERROR } from "../../../../../util/errorMsg";
import { submitReturnSolventumUnit } from "../../../../../util/inventoryMgrService";
import {
  RolesPermissionContextType,
  RolesPermissionContext,
} from "../../../../../context/RolesPermissionContext";
interface Props {
  returnStoredProductData: IInventoryProductDetails;
  Validator?: ReturnStoredProductUnitValidator;
  locationData: string[];
  closePopup?: Function;
  redirectHandler: Function;
  setError?: Function;
  returnInformationData: IStoredProdReturnInformation;
  setReturnInformationData: Function;
}
export const ReturnInformation = ({
  returnStoredProductData,
  Validator = new ReturnStoredProductUnitValidator(),
  locationData,
  closePopup,
  redirectHandler,
  setError,
  returnInformationData,
  setReturnInformationData,
}: Props) => {
  const [focusClasses, setFocusClasses] = useState({
    issueDescription: "",
    deliveryInstructions: "",
  });
  const setClasses = (e: any, classname: string) => {
    setFocusClasses(
      Object.assign({}, focusClasses, { [e.target.name]: classname })
    );
  };
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const [validator] = useState<ReturnStoredProductUnitValidator>(Validator!);

  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );
  const validateAndSetData = (e: any) => {
    const isValid = Validator.validate(e.target.value, e.target.name);
    setReturnInformationData((data: IStoredProdReturnInformation) => ({
      ...data,
      [e.target.name]: {
        value: e.target.value,
        valid: isValid?.status,
        required: true,
      },
    }));
  };
  const validateAndSetRadioGroupData = (e: any) => {
    const tempData = {
      ...returnInformationData,
      [e.target.name]: {
        ...Object(returnInformationData)[e.target.name],
        errorMessage: null,
        valid: ValidationStatus.VALID,
        value: e.target.value,
      },
    };
    setReturnInformationData(tempData);
  };

  useEffect(() => {
    if (returnInformationData.isReplacementNeeded.value === "yes") {
      const tempData = {
        ...returnInformationData,
        deliveryInstructions: {
          ...Object(returnInformationData)["deliveryInstructions"],
          required: true,
        },
      };
      setReturnInformationData(tempData);
    } else {
      const tempData = {
        ...returnInformationData,
        deliveryInstructions: {
          ...Object(returnInformationData)["deliveryInstructions"],
          required: false,
          valid: ValidationStatus.UNTOUCHED,
          value: "",
        },
      };
      setReturnInformationData(tempData);
    }
  }, [returnInformationData.isReplacementNeeded.value]);

  const submitReturnUnit = () => {
    let isValid;
    if (returnInformationData) {
      isValid = validator.validateAll(
        returnInformationData,
        setReturnInformationData
      );
      if (isValid) {
        callReturnUnitApi();
      }
    }
  };

  const callReturnUnitApi = async () => {
    if (authObj && authObj.userProfile && authObj.registeredFaciltyAddress) {
      try {
        redirectHandler(InventoryPopUp.LOADER, returnStoredProductData);
        let reqParams = {
          CustomerNumber: authObj.registeredFaciltyAddress.accountNumber,
          SerialNumber: returnStoredProductData.serialNumber,
          EmployeId: "INTERNET",
          CallerFirstName: authObj.userProfile.firstName,
          CallerLastName: authObj.userProfile.lastName,
          CallerEmailAddress: authObj.userProfile.emailAddress,
          CallerDepartment: authObj.userProfile.departmentName,
          CallerPhoneNumber: authObj.userProfile.phoneNumber
            ? authObj.userProfile.phoneNumber
            : authObj.userProfile.mobilePhoneNumber,
          facilityName: authObj?.registeredFaciltyAddress.accountName,
          SiteUseID: getSiteUseId(authObj),
          IssueDescription: returnInformationData.issueDescription.value,
          DeliveryInstructions:
            returnInformationData?.deliveryInstructions?.value,
          Version: process.env.REACT_APP_VERSION,
          IsReplacementNeeded:
            returnInformationData?.isReplacementNeeded.value === "yes"
              ? true
              : false,
          PickUpLocation: returnInformationData.pickUpLocation.value,
          ProductName: returnStoredProductData.product,
        };
        const response = await submitReturnSolventumUnit(reqParams);
        if (response && response?.succeeded) {
          redirectHandler(
            InventoryPopUp.RETURN_UNIT_TO_SOLVENTUM_SUCCESS,
            returnStoredProductData
          );
        } else {
          const errorForApiFailuer = {
            errorCode:
              response?.error?.errorCode ||
              response?.error?.code ||
              response?.status,
            errorFlag: true,
            errorMessage: SUBMIT_RETURN_SERVICE_ERROR,
          };
          setError!(errorForApiFailuer);
          redirectHandler(InventoryPopUp.ERROR_POPUP, returnStoredProductData);
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  useEffect(() => {
    setReturnInformationData((data: IStoredProdReturnInformation) => ({
      ...data,
      pickUpLocation: {
        value:
          locationData &&
          locationData.includes(
            returnStoredProductData.storageLocation.toUpperCase()
          )
            ? returnStoredProductData.storageLocation
            : "",
        valid:
          locationData &&
          locationData.includes(
            returnStoredProductData.storageLocation.toUpperCase()
          )
            ? ValidationStatus.VALID
            : ValidationStatus.UNTOUCHED,
        required: true,
      },
    }));
  }, []);

  return (
    <div className="return-stored-product-return-information-div">
      <h4
        className="return-information-div-title"
        data-testid="return-information-div-title"
      >
        Return Information
      </h4>
      <Grid className="return-information-grid-container" container spacing={2}>
        <Grid
          className="return-information-grid-item"
          item
          xs={12}
          data-testId="pickUpLocation-dropdown"
        >
          <InputWithLabel
            label="Pickup Location"
            isRequired={returnInformationData?.pickUpLocation.required}
            error={
              returnInformationData?.pickUpLocation.valid ===
              ValidationStatus.INVALID
            }
            isDropdown={true}
          >
            <CustomDropDown
              handleChange={validateAndSetData}
              menuItem={locationData}
              name="pickUpLocation"
              selectpropsClassName={
                returnInformationData?.pickUpLocation.value !== ""
                  ? "return-information-grid-item-select"
                  : "placeHolder"
              }
              selectClassName={
                returnInformationData?.pickUpLocation?.value !== ""
                  ? "return-information-grid-item-input"
                  : "placeHolder"
              }
              testId="pickUpLocation"
              value={
                returnInformationData?.pickUpLocation?.value !== ""
                  ? returnInformationData?.pickUpLocation?.value
                  : null
              }
            />
          </InputWithLabel>
        </Grid>
        <Grid
          className="return-information-grid-item"
          item
          xs={12}
          data-testId="issueDescription-textBox"
        >
          <InputWithLabel
            isRequired={returnInformationData.issueDescription.required}
            label="Issue Description"
            error={
              returnInformationData?.issueDescription.valid ===
              ValidationStatus.INVALID
            }
            labelClassName={focusClasses.issueDescription}
          >
            <TextField
              error={
                returnInformationData.issueDescription.valid ===
                ValidationStatus.INVALID
              }
              FormHelperTextProps={{ classes: { root: "helperText" } }}
              fullWidth
              InputProps={{
                inputProps: { maxLength: 200 },
                classes: {
                  root: "textarea-root",
                },
              }}
              multiline
              name="issueDescription"
              onBlur={(e) => setClasses(e, "")}
              onChange={validateAndSetData}
              onFocus={(e) => setClasses(e, "Mui-focused")}
              required={returnInformationData.issueDescription.required}
              rows={2}
              value={returnInformationData.issueDescription.value}
            />
          </InputWithLabel>
        </Grid>
        <Grid
          className="return-information-grid-item"
          item
          xs={12}
          data-TestId="replacementNeededRadioGroup"
        >
          <InputWithLabel
            error={
              returnInformationData.isReplacementNeeded.valid ===
              ValidationStatus.INVALID
            }
            isRequired={returnInformationData.isReplacementNeeded.required}
            label={"Is a Replacement Product Needed"}
          >
            <RadioGroup
              name="isReplacementNeeded"
              classes={{ root: "radioRoot" }}
              onChange={(e) => validateAndSetRadioGroupData(e)}
              value={returnInformationData.isReplacementNeeded.value}
            >
              <FormControlLabel
                classes={{
                  root:
                    returnInformationData.isReplacementNeeded.valid ===
                    ValidationStatus.INVALID
                      ? "optionRoot-error"
                      : returnInformationData.isReplacementNeeded.value ===
                        "yes"
                      ? "optionRoot-active"
                      : "optionRoot",
                }}
                componentsProps={{
                  typography: {
                    classes: {
                      root:
                        returnInformationData.isReplacementNeeded.value ===
                        "yes"
                          ? "optiontxtSelect"
                          : "optiontxt",
                    },
                  },
                }}
                control={
                  <Radio
                    icon={<RadioButtonIcon />}
                    checkedIcon={<SelectedRadioButtonIcon />}
                    required={
                      returnInformationData.isReplacementNeeded.required
                    }
                  />
                }
                label="Yes"
                value="yes"
              />
              <FormControlLabel
                classes={{
                  root:
                    returnInformationData.isReplacementNeeded.valid ===
                    ValidationStatus.INVALID
                      ? "optionRoot-error"
                      : returnInformationData.isReplacementNeeded.value === "no"
                      ? "optionRoot-active"
                      : "optionRoot",
                }}
                componentsProps={{
                  typography: {
                    classes: {
                      root:
                        returnInformationData.isReplacementNeeded.value === "no"
                          ? "optiontxtSelect"
                          : "optiontxt",
                    },
                  },
                }}
                control={
                  <Radio
                    icon={<RadioButtonIcon />}
                    checkedIcon={<SelectedRadioButtonIcon />}
                    required={
                      returnInformationData.isReplacementNeeded.required
                    }
                  />
                }
                label="No"
                value="no"
              />
            </RadioGroup>
          </InputWithLabel>
        </Grid>
        {returnInformationData.isReplacementNeeded.value === "yes" && (
          <Grid className="return-information-grid-item" item xs={12}>
            <InputWithLabel
              isRequired={returnInformationData.deliveryInstructions.required}
              label=" Delivery instructions"
              labelClassName={focusClasses.deliveryInstructions}
              error={
                returnInformationData.deliveryInstructions.valid ===
                ValidationStatus.INVALID
              }
            >
              <TextField
                error={
                  returnInformationData.deliveryInstructions.valid ===
                  ValidationStatus.INVALID
                }
                FormHelperTextProps={{ classes: { root: "helperText" } }}
                fullWidth
                InputProps={{
                  inputProps: { maxLength: 100 },
                  classes: {
                    root: "textarea-root",
                  },
                }}
                multiline
                name="deliveryInstructions"
                onBlur={(e) => setClasses(e, "")}
                onChange={validateAndSetData}
                onFocus={(e) => setClasses(e, "Mui-focused")}
                required={returnInformationData.deliveryInstructions.required}
                rows={1}
                value={returnInformationData.deliveryInstructions.value}
              />
            </InputWithLabel>
          </Grid>
        )}
        <Grid className="return-information-grid-item" item xs={12}>
          <div className="storedProductReturnBtnGroup">
            <ExpressButton
              testId="storedProductReturnBtnGroup-btnGroup"
              parentClass="cancelRequest"
              variant="outlined"
              clickHandler={() => {
                closePopup!();
              }}
            >
              Cancel
            </ExpressButton>

            <ExpressButton
              parentClass="submitRequest"
              variant="contained"
              disabled={permissionObj?.mappedRolesPermissionData.IsSupportRole}
              clickHandler={submitReturnUnit}
            >
              Submit Request
            </ExpressButton>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
