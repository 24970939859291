import { Grid, GridSize } from "@mui/material";
import "./replacementInformationReview.css";
import { MobileDisplayContext } from "../../../../../context/MobileDisplayContext";
import { useContext } from "react";
import { IInputField } from "../../../../../core/interfaces/input.interface";
import {
  formatPhoneNumber,
  makeCapitalEachWordInString,
} from "../../../../../util/utilityFunctions";
import { IReplacementInformationInterface } from "../replacementInformation.interface";
import { InpatientOrderPageSection } from "../../../inpatientOrder.enum";

interface Props {
  data: IReplacementInformationInterface;
  editButtonAction: any;
  isSummary: boolean;
  isFromOrderOverview?: boolean;
}

export const ReplacementInformationReview = ({
  data,
  editButtonAction,
  isSummary = false,
  isFromOrderOverview = false,
}: Props) => {
  const { isMobileScreen } = useContext(MobileDisplayContext);

  const renderDelivertContactSubComponent = (
    data: IReplacementInformationInterface
  ) => {
    return (
      <Grid
        className="replacement-info-review-grid-item"
        item
        id="id_replacement-info-review-grid-item"
        xs={isMobileScreen ? 6 : 4}
      >
        <div className="show-title-and-value-div">
          <p
            className="show-title-and-value-title"
            data-testid="address-title"
            id="id_add-div"
          >
            Delivery Contact
          </p>
          <p
            className="show-title-and-value-value"
            data-testid="contactname-value"
            id="id_add_1-div"
          >
            {data.firstName.value && data.firstName.value
              ? makeCapitalEachWordInString(
                  data.firstName.value + " " + data.lastName.value
                )
              : "--"}
          </p>
          {!isFromOrderOverview && (
            <p
              className="show-title-and-value-value"
              data-testid="department-value"
              id="id_department-div"
            >
              {makeCapitalEachWordInString(
                data.department && data.department.value
                  ? data.department.value
                  : "--"
              )}
            </p>
          )}
          <p
            className="show-title-and-value-value"
            data-testid="phone-num-value"
            id="id_phone_num-div"
          >
            {data.phoneNumber ? (
              <a href={`tel:${formatPhoneNumber(data.phoneNumber.value)}`}>
                {formatPhoneNumber(data.phoneNumber.value)}
              </a>
            ) : (
              "--"
            )}
          </p>
        </div>
      </Grid>
    );
  };

  const showTitleAndValue = (
    title: string,
    value: string | null,
    testId: string
  ) => {
    return (
      <div
        className="show-title-and-value-div"
        id="id_show-title-and-value-div"
      >
        <p
          className="show-title-and-value-title"
          data-testid={`${testId}-title`}
          id={`${testId}-title-id`}
        >
          {title}
        </p>
        <p
          className="show-title-and-value-value"
          data-testid={`${testId}-value`}
          id={`${testId}-value-id`}
        >
          {value && value !== "" ? value.trim() : "--"}
        </p>
      </div>
    );
  };

  const getGridSize = (): GridSize => {
    let gridSize: GridSize = 0;
    if (isFromOrderOverview) {
      gridSize = 12;
    } else {
      if (isMobileScreen) {
        gridSize = 6;
      } else {
        gridSize = 4;
      }
    }
    return gridSize;
  };

  return (
    <div
      className="replacement-info-review-component"
      data-testid="replacement-info-review-component"
      id="id_replacement-info-review-component"
    >
      <div
        className="replacement-info-review-header"
        id="id_replacement-info-review-header"
      >
        <p
          className="replacement-info-review-title"
          data-testid="replacement-info-review-title"
          id="replacement-info-review-title-id"
        >
          Replacement Information
        </p>
        {!isSummary && (
          <button
            className="replacement-info-review-edit-button"
            data-testid="replacement-info-review-edit-button"
            id="replacement-info-review-edit-button-id"
            onClick={() =>
              editButtonAction(
                "in-patient-order-replacement-information-component",
                InpatientOrderPageSection.SECOND_PAGE
              )
            }
          >
            Edit
          </button>
        )}
      </div>
      <Grid
        className="replacement-info-review-grid-item"
        id="id_replacement-info-review-grid-item"
        item
        xs={getGridSize()}
      >
        {!isFromOrderOverview ? (
          <>
            <Grid
              className="replacement-info-review-grid-item"
              item
              id="id_replacement-info-review-grid-item"
              xs={isMobileScreen ? 6 : 4}
            >
              {showTitleAndValue(
                "Delivery Location",
                data?.deliveryLocation?.value,
                "delivery-location"
              )}
            </Grid>
            <Grid
              className="replacement-info-review-grid-container"
              container
              id="id_replacement-info-review-grid-container"
            >
              <Grid
                className="replacement-info-review-grid-item"
                id="id_replacement-info-review-grid-item"
                item
                xs={isMobileScreen ? 6 : 4}
              >
                {showTitleAndValue(
                  "Call with ETA?",
                  makeCapitalEachWordInString(
                    data?.isCallOnEstimatedArrivalTime?.value
                  ),
                  "call-with-ETA"
                )}
              </Grid>
              {data?.isCallOnEstimatedArrivalTime?.value.toLowerCase() ===
                "yes" && renderDelivertContactSubComponent(data)}
            </Grid>
          </>
        ) : (
          renderDelivertContactSubComponent(data)
        )}

        <Grid
          className="replacement-info-review-grid-item"
          item
          id="id_replacement-info-review-grid-item"
          xs={getGridSize()}
        >
          {showTitleAndValue(
            "Delivery Instructions",
            data?.deliveryInstructions?.value,
            "delivery-instructions"
          )}
        </Grid>
      </Grid>
    </div>
  );
};
