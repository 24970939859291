import { Grid } from "@mui/material";
import "./deviceComplicationsReviewRequest.css";
import { ICreateServiceRequest } from "../../../createServiceRequest.interface";
import { makeCapitalEachWordInString } from "../../../../../../util/utilityFunctions";
import { IAcuteOrderPickUpRequest } from "../../../../../acutePickUpRequest/acuteOrderPickUpRequest.interface";

export interface IDeviceComplicationsReviewRequest {
  data: ICreateServiceRequest | IAcuteOrderPickUpRequest;
  editButtonAction?: any;
  isReviewSummary?: boolean;
}

export const DeviceComplicationsReviewRequest = ({
  data,
  editButtonAction,
  isReviewSummary = false,
}: IDeviceComplicationsReviewRequest) => {
  const showTitleAndValue = (
    title: string,
    value: string | null,
    testId: string
  ) => {
    return (
      <div className="show-title-and-value-div">
        <p
          className="show-title-and-value-title"
          data-testid={`${testId}-title`}
          id={`${testId}-title-id`}
        >
          {title}
        </p>
        <p
          className="show-title-and-value-value"
          data-testid={`${testId}-value`}
          id={`${testId}-value-id`}
        >
          {value && value !== "" ? value.trim() : "--"}
        </p>
      </div>
    );
  };

  return (
    <div className="device-complications-review-request-component">
      <div className="device-complications-review-request-header">
        <p
          className="device-complications-review-request-title"
          data-testid="device-complications-review-request-title"
          id="device-complications-review-request-title-id"
        >
          Device Complications
        </p>
        {!isReviewSummary && (
          <button
            className="device-complications-review-request-edit-button"
            data-testid="device-complications-review-request-edit-button"
            id="device-complications-review-request-edit-button-id"
            onClick={() => editButtonAction("device-complications-component")}
          >
            Edit
          </button>
        )}
      </div>
      <Grid
        className="device-complications-review-request-grid-container"
        container
      >
        <Grid
          className="device-complications-review-request-grid-item"
          item
          xs={6}
        >
          {showTitleAndValue(
            "Medical Injury",
            makeCapitalEachWordInString(data.injuryCauseBy3MDevice.value),
            "device-complications-medical-injury"
          )}
        </Grid>
        <Grid
          className="device-complications-review-request-grid-item"
          item
          xs={6}
        >
          {showTitleAndValue(
            "Product Problem",
            makeCapitalEachWordInString(data.problemWith3MDevice.value),
            "device-complications-product-problem"
          )}
        </Grid>
        {(data.injuryCauseBy3MDevice.value === "yes" ||
          data.problemWith3MDevice.value === "yes") && (
          <Grid
            className="device-complications-review-request-grid-item"
            item
            xs={12}
          >
            {showTitleAndValue(
              "Describe the injury/problem",
              data.describeTheProblem.value,
              "device-complications-describe-the-injury/problem"
            )}
          </Grid>
        )}
      </Grid>
    </div>
  );
};
