import * as CryptoJS from 'crypto-js'
import { defaultFunctionkeyDataModel, IFunctionKeyData } from '../functionKey/functionKey.Model'
import { getDeepClone } from './ObjectFunctions'

const secretKey = process.env.REACT_APP_SECRET_KEY ? process.env.REACT_APP_SECRET_KEY : 'CB36CD85C64E4A2E1395362F45D6F'
export const encrypt = ( plainText: string ) => {
    const cipherText = CryptoJS.AES.encrypt(plainText, secretKey).toString()
    return cipherText
}

export const decrypt = ( cipherText:string ) => {
    const bytes = CryptoJS.AES.decrypt(cipherText, secretKey )
    const plainText = bytes.toString(CryptoJS.enc.Utf8)
    return plainText
}

export const getKeyData = () =>{
    let funckeyParse = getDeepClone(defaultFunctionkeyDataModel);
    const encryptedKeyDetails = sessionStorage.getItem("keys");
    if(encryptedKeyDetails!== null){
        const decryptKeys = decrypt(encryptedKeyDetails!);
         funckeyParse = JSON.parse(decryptKeys ?? "") as IFunctionKeyData;
        return funckeyParse;
    }
    return funckeyParse;
}