import { ICreateServiceRequest } from "../../../createServiceRequest.interface";
import "./pickupLocationReview.css";

const showTitleAndValue = (
  title: string,
  value: string | null,
  testId: string
) => {
  return (
    <div className="show-title-and-value-div">
      <p
        className="show-title-and-value-title"
        data-testid={`${testId}-title`}
        id={`${testId}-title-id`}
      >
        {title}
      </p>
      <p
        className="show-title-and-value-value"
        data-testid={`${testId}-value`}
        id={`${testId}-value-id`}
      >
        {value && value !== "" ? value.trim() : "--"}
      </p>
    </div>
  );
};

interface IPickupLocationReviewProps {
  data: ICreateServiceRequest;
  editButtonAction: Function;
  isReviewSummary?: boolean;
}

const PickupLocationReview = ({
  data,
  editButtonAction,
  isReviewSummary = false,
}: IPickupLocationReviewProps) => {
  const getPickupLocationValue = (): string => {
    let locationValue: string = data.pickupLocation.value;
    if (locationValue.toLowerCase() === "other location") {
      locationValue = "Other Location - " + data.otherPickupLocation.value;
    }
    return locationValue;
  };
  return (
    <div className="pickup-location-review-request-component">
      <div className="pickup-location-review-request-header">
        <p
          className="pickup-location-review-request-title"
          data-testid="pickup-location-review-request-title"
        >
          Pickup Location
        </p>
        {!isReviewSummary && (
          <button
            className="pickup-location-review-request-edit-button"
            data-testid="pickup-location-review-request-edit-button"
            onClick={() => editButtonAction("pickup-location-component")}
          >
            Edit
          </button>
        )}
      </div>
      {showTitleAndValue(
        "Pickup Location",
        getPickupLocationValue(),
        "pickup-location-value"
      )}
    </div>
  );
};

export default PickupLocationReview;
