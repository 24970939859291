import {
  FormControlLabel,
  Grid,
  InputBase,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import "./problemDetails.css";
import { InputWithLabel } from "../../../../../core/inputWithLabel/inputWithLabel.component";
import { ValidationStatus } from "../../../../../core/interfaces/input.interface";
import { ERROR_TO_SELECT_ANY_OPTION } from "../../../../../util/errorMsg";
import { ISalesAssistance } from "../../salesAssistanceNeedHelp.interface";
import { ReactComponent as RadioButtonIcon } from "../../../../../assets/radioButton.svg";
import { ReactComponent as SelectedRadioButtonIcon } from "../../../../../assets/selectedRadioButton.svg";
import { useContext, useState } from "react";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { ReactComponent as CalendarIcon } from "../../../../../assets/calendar.svg";
import { convertStringToDate } from "../../../../../util/utilityFunctions";
import { SalesAssistanceNeedHelpValidator } from "../../salesAssistanceNeedhelp.validator";
import { CustomDropDown } from "../../../../../core/customDropdown/customDropdown.component";
import {
  IMobileDisplayContext,
  MobileDisplayContext,
} from "../../../../../context/MobileDisplayContext";

interface IProblemDetails {
  data: ISalesAssistance;
  setData: Function;
  validator?: SalesAssistanceNeedHelpValidator;
  affectedFeaturesText: string[];
}
export interface IDeviceProblemButtonGroup {
  raidoButtonLabel: string;
  radioButtonValue: DeviceProblemRadioSelection;
  radioButtonTestId: string;
}
export enum DeviceProblemRadioSelection {
  DESKTOP = "desktop",
  MOBILE = "mobile",
}
const helpNeededRadioOtions: IDeviceProblemButtonGroup[] = [
  {
    raidoButtonLabel: "Desktop",
    radioButtonValue: DeviceProblemRadioSelection.DESKTOP,
    radioButtonTestId: "device-problem-desktop",
  },
  {
    raidoButtonLabel: "Mobile",
    radioButtonValue: DeviceProblemRadioSelection.MOBILE,
    radioButtonTestId: "device-problem-mobile",
  },
];
export const ProblemDetails = ({
  data,
  setData,
  validator = new SalesAssistanceNeedHelpValidator(),
  affectedFeaturesText,
}: IProblemDetails) => {
  const [focusClasses, setFocusClasses] = useState({
    dateOfProblem: "",
  });
  const validateAndSetData = (event: any) => {
    let tempData: ISalesAssistance = data;
    const { value, name } = event.target;
    const isValid = validator.validate(value, name);
    const isOtherRequired = name === "featureAffected" && value === "Other";

    tempData = {
      ...tempData,
      problemDetails: {
        ...tempData.problemDetails,
        [name]: {
          ...Object(tempData.problemDetails)[name],
          errorMessage: null,
          valid: isValid!.status,
          value: value,
        },
      },
    };
    if (isOtherRequired) {
      tempData = {
        ...tempData,
        problemDetails: {
          ...tempData.problemDetails,
          otherFeatureAffected: {
            ...tempData.problemDetails.otherFeatureAffected,
            valid: isOtherRequired
              ? ValidationStatus.UNTOUCHED
              : tempData.problemDetails.otherFeatureAffected.valid,
            required: isOtherRequired,
            value: "",
          },
        },
      };
    }
    setData(tempData);
  };

  const validateAndSetDate = (date: string | null | undefined) => {
    const formatteddate = convertStringToDate(date);
    const isValid = validator.validate(formatteddate!, "dateOfProblem");
    let tempData: ISalesAssistance = data;
    tempData = {
      ...tempData,
      problemDetails: {
        ...tempData.problemDetails,
        dateOfProblem: {
          ...tempData.problemDetails.dateOfProblem,
          errorMessage: null,
          value: formatteddate,
          valid: isValid!.status!,
        },
      },
    };
    setData(tempData);
  };
  const getRadioButtonRootClassName = (
    deviceProblem: DeviceProblemRadioSelection
  ): string => {
    return data.problemDetails.deviceProblem.value === deviceProblem
      ? "deviceProblemRootActive"
      : "deviceProblemOptionRoot";
  };

  const setClasses = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    classname: string
  ) => {
    setFocusClasses({
      ...focusClasses,
      [e.target.name]: classname,
    });
  };

  return (
    <div
      className="problem-details-main-div"
      id="problem-details-main-div"
      data-testid="problem-details-main-div"
    >
      <div className="device-problem-div">
        <Typography
          className="problem-details-header"
          data-testid="problem-details-header"
          id="problem-details-header"
        >
          Problem details
        </Typography>
        <InputWithLabel
          error={
            data.problemDetails.deviceProblem.valid === ValidationStatus.INVALID
          }
          id={data.problemDetails.deviceProblem.componentId!}
          isRequired={data.problemDetails.deviceProblem.required}
          isShowWarning={true}
          label={data.problemDetails.deviceProblem.title}
          testId="problem-details-input-testId"
          warningMessage={
            data.problemDetails.deviceProblem.errorMessage
              ? ERROR_TO_SELECT_ANY_OPTION
              : ""
          }
        >
          <RadioGroup
            classes={{ root: "deviceProblemRadioRoot" }}
            onChange={validateAndSetData}
            name="deviceProblem"
            value={data.problemDetails.deviceProblem.value}
            id="device-problem-radio-button"
          >
            {helpNeededRadioOtions.map(
              ({
                raidoButtonLabel,
                radioButtonValue,
                radioButtonTestId,
              }: IDeviceProblemButtonGroup) => (
                <FormControlLabel
                  key={radioButtonValue}
                  classes={{
                    root: getRadioButtonRootClassName(radioButtonValue),
                  }}
                  componentsProps={{
                    typography: {
                      classes: { root: "deviceProblemOptiontxt" },
                    },
                  }}
                  control={
                    <Radio
                      icon={<RadioButtonIcon />}
                      checkedIcon={<SelectedRadioButtonIcon />}
                    />
                  }
                  data-testid={radioButtonTestId}
                  label={raidoButtonLabel}
                  value={radioButtonValue}
                />
              )
            )}
          </RadioGroup>
        </InputWithLabel>
      </div>
      <div className="device-problem-div device-problem-section">
        <div className="problem-details-input-label">
          <InputWithLabel
            error={
              data.problemDetails.errorCode.valid === ValidationStatus.INVALID
            }
            isRequired={data.problemDetails.errorCode.required}
            id={data.problemDetails.errorCode.componentId!}
            isShowWarning={true}
            label={data.problemDetails.errorCode.title}
            labelClassName="problem-details-error-code"
            testId="problem-details-error-code-test"
            warningMessage={data.problemDetails.errorCode.errorMessage}
          >
            <InputBase
              className="problem-details-error-code-input problem-details-input-base"
              id={data.problemDetails.errorCode.id!}
              name="errorCode"
              inputProps={{ "data-testid": "problem-details-error-code-input" }}
              onChange={validateAndSetData}
              value={data.problemDetails.errorCode.value}
            />
          </InputWithLabel>
        </div>
        <div className="problem-details-input-label date-of-problem-div">
          <InputWithLabel
            error={
              data.problemDetails.dateOfProblem.valid ===
              ValidationStatus.INVALID
            }
            id={data.problemDetails.dateOfProblem.componentId}
            isShowWarning={true}
            isRequired={data.problemDetails.dateOfProblem.required}
            label={data.problemDetails.dateOfProblem.title!}
            labelClassName={focusClasses.dateOfProblem}
            testId="date-of-problem-testId"
            warningMessage={data.problemDetails.dateOfProblem.errorMessage}
          >
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                components={{ OpenPickerIcon: CalendarIcon }}
                InputAdornmentProps={{
                  classes: {
                    root: "adornedRoot",
                  },
                }}
                InputProps={{
                  classes: {
                    root: `date-of-problems-date ${
                      data.problemDetails.dateOfProblem.valid ===
                      ValidationStatus.INVALID
                        ? "show-error"
                        : "no-error"
                    }`,
                    input: "input",
                    notchedOutline: "outline",
                  },
                }}
                maxDate={moment().toString()}
                onChange={(e) => validateAndSetDate(e)}
                renderInput={(params) => {
                  params.error = false;
                  params.inputProps!.placeholder = "__/__/____";
                  return (
                    <TextField
                      id={data.problemDetails.dateOfProblem.id!}
                      name="dateOfProblem"
                      onFocus={(e) => setClasses(e, "Mui-focused")}
                      onBlur={(e) => setClasses(e, "")}
                      {...params}
                    />
                  );
                }}
                value={data.problemDetails.dateOfProblem.value}
              />
            </LocalizationProvider>
          </InputWithLabel>
        </div>
      </div>
      <div className="device-problem-div device-problem-section">
        <div className="problem-details-input-label">
          <InputWithLabel
            error={
              data.problemDetails.facilityId.valid === ValidationStatus.INVALID
            }
            isRequired={data.problemDetails.facilityId.required}
            label={data.problemDetails.facilityId.title}
            labelClassName="problem-details-error-code"
            testId="problem-details-facility-id-test"
            warningMessage={data.problemDetails.facilityId.errorMessage}
            isShowWarning={true}
            id={data.problemDetails.facilityId.componentId!}
          >
            <InputBase
              className="facility-id-error-code-input problem-details-input-base"
              id={data.problemDetails.facilityId.id!}
              name="facilityId"
              inputProps={{ "data-testid": "facility-id-error-code-input" }}
              onChange={validateAndSetData}
              value={data.problemDetails.facilityId.value}
            />
          </InputWithLabel>
        </div>
        <div className="problem-details-input-label date-of-problem-div">
          <InputWithLabel
            error={
              data.problemDetails.roNumber.valid === ValidationStatus.INVALID
            }
            isRequired={data.problemDetails.roNumber.required}
            isShowWarning={true}
            id={data.problemDetails.roNumber.componentId!}
            label={data.problemDetails.roNumber.title}
            labelClassName="problem-details-error-code"
            testId="problem-details-ro-number-test"
            warningMessage={data.problemDetails.roNumber.errorMessage}
          >
            <InputBase
              className="problem-details-error-code-input problem-details-input-base"
              id={data.problemDetails.roNumber.id!}
              inputProps={{
                "data-testid": "problem-details-ro-number-test-input",
              }}
              name="roNumber"
              onChange={validateAndSetData}
              value={data.problemDetails.roNumber.value}
            />
          </InputWithLabel>
        </div>
      </div>
      <div className="device-problem-div date-of-problem-div">
        <div className="problem-details-input-affected-label ">
          <InputWithLabel
            error={
              data?.problemDetails.featureAffected.valid ===
              ValidationStatus.INVALID
            }
            isDropdown={true}
            isRequired={data?.problemDetails.featureAffected.required}
            id={data.problemDetails.featureAffected.componentId}
            isShowWarning={true}
            label={
              "What  " +
              data.problemDetails.featureAffected.title?.toLowerCase() +
              "?"
            }
            testId="problem-details-feature-affected-test"
            warningMessage={data.problemDetails.featureAffected.errorMessage}
          >
            <CustomDropDown
              handleChange={validateAndSetData}
              menuItem={affectedFeaturesText}
              name="featureAffected"
              placeHolder="Select a feature"
              selectpropsClassName={
                data?.problemDetails.featureAffected.value !== ""
                  ? "feature-affected-entry-select"
                  : "placeHolder"
              }
              selectClassName={
                data?.problemDetails.featureAffected?.value !== ""
                  ? "feature-affected-entry-input"
                  : "placeHolder"
              }
              testId="problem-details-feature-affected"
              value={
                data?.problemDetails.featureAffected?.value !== ""
                  ? data?.problemDetails.featureAffected?.value
                  : null
              }
            />
          </InputWithLabel>
        </div>
        <div className="feature-affected-empty-div"></div>
        {data.problemDetails.featureAffected.value === "Other" && (
          <div className="problem-details-input-affected-label">
            <InputWithLabel
              error={
                data.problemDetails.otherFeatureAffected.valid ===
                ValidationStatus.INVALID
              }
              isRequired={data.problemDetails.otherFeatureAffected.required}
              labelClassName="problem-details-error-code"
              testId="problem-details-other-feature-affected-test"
              warningMessage={
                data.problemDetails.otherFeatureAffected.errorMessage
              }
              isShowWarning={true}
              id={data.problemDetails.otherFeatureAffected.componentId!}
            >
              <InputBase
                className="problem-details-error-code-input problem-details-input-affected-base"
                id={data.problemDetails.otherFeatureAffected.id!}
                name="otherFeatureAffected"
                inputProps={{
                  "data-testid": "other-feature-affected-test-input",
                  maxLength: 100,
                }}
                onChange={validateAndSetData}
                value={data.problemDetails.otherFeatureAffected.value}
              />
            </InputWithLabel>
          </div>
        )}
      </div>
    </div>
  );
};
