import {
  IInputField,
  ValidationStatus,
} from "../../../../../core/interfaces/input.interface";

export interface IPrescriberDetailInterface {
  prescriberName: IInputField;
  updatedPrescriberEmail: IInputField;
  npi?: IInputField;
}

export let defaultPrescriberDetail: IPrescriberDetailInterface = {
  prescriberName: {
    value: "",
    valid: ValidationStatus.VALID,
    required: false,
  },
  updatedPrescriberEmail: {
    value: "",
    valid: ValidationStatus.VALID,
    required: false,
  },
  npi: {
    value: "",
    valid: ValidationStatus.VALID,
    required: false,
  },
};

export interface IUpadtePrescriberEmailReqBody {
  ron: string;
  email: string;
  npi: string;
  doctorName: string;
}
