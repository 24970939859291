import {
  IInputField,
  Validation,
  ValidationStatus,
} from "../../core/interfaces/input.interface";
import { getDeepClone } from "../../util/ObjectFunctions";
import { Validator } from "../../util/order.validations";
import { getValidObj, updateErrorMessage } from "../../util/utilityFunctions";
import { ShippingAddress } from "./component/shippingAddress/shippingAddress.component";

export class InpatientSupplyOrderValidator {
  private _validator;
  constructor(defValidator = new Validator()) {
    this._validator = defValidator;
  }

  private noValidation(): Validation {
    return getValidObj();
  }

  private fieldToMethodMapping(
    field: string
  ): ((txt: string) => Validation) | undefined {
    const mapping = new Map<string, (txt: string) => Validation>([
      // Patient Information
      ["firstName", this._validator.inPatientOrderNameValidation],
      ["needByDate", this._validator.deliveryDateValidation],
      ["deliveryMethod", this._validator.emptyCheck],
      [
        "shippingFacilityName",
        this._validator.inPatientSupplyOrderShippingDetailsValidation,
      ],
      [
        "shippingAddressLine1",
        this._validator.inPatientSupplyOrderShippingDetailsValidation,
      ],
      [
        "shippingAddressLine2",
        this._validator.inPatientSupplyOrderShippingDetailsValidation,
      ],
      [
        "shippingAddressCity",
        this._validator.inPatientSupplyOrderShippingDetailsValidation,
      ],
      ["shippingAddressState", this._validator.emptyCheck],
      ["shippingAddressZip", this._validator.facilityZipcodeValidation],
      ["salesPONumber", this._validator.salesPurchasePONumber],
    ]);
    const validator = mapping.get(field);
    return validator ? validator : this.noValidation;
  }

  public validate(input: string, field: string) {
    try {
      const validator = this.fieldToMethodMapping(field)!;
      return validator(input);
    } catch (error) {
      console.log(`validator method for field ${field} is not configured`);
    }
  }

  public validateAll(
    data: any,
    updateDataIfUntouchedAndValidated: Function
  ): [ValidationStatus, IInputField | null] {
    let tempData = getDeepClone(data);
    let smallestOrderWithInvalidStatus: IInputField | null = null;
    Object.keys(tempData).forEach((key: string) => {
      const subTempData = tempData[key];
      if (subTempData) {
        let subTempDataValue = tempData[key].value;
        if (tempData[key].hasChildsToValidate && subTempDataValue) {
          let isAllValid = ValidationStatus.VALID;
          Object.keys(subTempDataValue).forEach((subkey: string) => {
            let inputField = subTempDataValue[subkey];
            smallestOrderWithInvalidStatus = this.validateInputField(
              inputField,
              smallestOrderWithInvalidStatus
            );
            if (inputField.valid === ValidationStatus.INVALID) {
              if (isAllValid !== ValidationStatus.INVALID) {
                isAllValid = ValidationStatus.INVALID;
              }
            } else if (
              subTempDataValue[subkey].valid === ValidationStatus.UNTOUCHED
            ) {
              subTempDataValue[subkey].valid = ValidationStatus.INVALID;
            }
          });
          tempData = {
            ...tempData,
            [key]: {
              ...Object(tempData)[key],
              valid: isAllValid,
              value: subTempDataValue,
            },
          };
        } else {
          if (
            key === "shippingAddress" ||
            key === "deliveryInformation" ||
            key === "salesPurchaseOrderInformation"
          ) {
            Object.keys(tempData[key]).forEach((x: string) => {
              if (
                tempData[key][x].isOptional === true &&
                tempData[key][x].valid !== ValidationStatus.VALID
              ) {
                tempData[key][x].valid = ValidationStatus.VALID;
              } else if (
                tempData[key][x].required &&
                tempData[key][x].valid === ValidationStatus.UNTOUCHED
              ) {
                tempData[key][x].valid = ValidationStatus.INVALID;
              } else if (
                tempData[key][x].required === false &&
                tempData[key][x].valid === ValidationStatus.UNTOUCHED
              ) {
                tempData[key][x].valid = ValidationStatus.VALID;
              } else if (
                tempData[key][x].isDefaultValid &&
                tempData[key][x].valid !== ValidationStatus.VALID
              ) {
                tempData[key][x].valid = ValidationStatus.INVALID;
              }
              // Find smallest order number which has invalid status
              if (
                tempData[key][x].valid === ValidationStatus.INVALID &&
                tempData[key][x].order &&
                ((smallestOrderWithInvalidStatus &&
                  smallestOrderWithInvalidStatus.order! > 45) ||
                  !smallestOrderWithInvalidStatus)
              ) {
                if (
                  !smallestOrderWithInvalidStatus ||
                  (smallestOrderWithInvalidStatus.order &&
                    tempData[key][x].order &&
                    smallestOrderWithInvalidStatus.order >
                      tempData[key][x].order)
                ) {
                  smallestOrderWithInvalidStatus = tempData[key][x];
                }
              }
              updateErrorMessage(tempData[key][x]);
            });
          } else {
            let inputField = tempData[key];
            smallestOrderWithInvalidStatus = this.validateInputField(
              inputField,
              smallestOrderWithInvalidStatus
            );
          }
        }
      }
    });
    updateDataIfUntouchedAndValidated(tempData);
    const ifAllValid = Object.keys(tempData)
      .filter(
        (key: string) => tempData[key].isDefaultValid || tempData[key].required
      )
      .every((key: string) => tempData[key].valid === ValidationStatus.VALID);
    const shippingAddressTemp = getDeepClone(tempData.shippingAddress);
    const deliveryInformationTemp = getDeepClone(tempData.deliveryInformation);
    const salesPurchaseOrderInfoTemp = getDeepClone(
      tempData.salesPurchaseOrderInformation
    );
    const shippingAddressAllValid = Object.keys(shippingAddressTemp)
      .filter((key: string) => shippingAddressTemp[key].valid)
      .every(
        (key: string) =>
          shippingAddressTemp[key].valid === ValidationStatus.VALID
      );
    const deliveryInformationAllValid = Object.keys(deliveryInformationTemp)
      .filter((key: string) => deliveryInformationTemp[key].valid)
      .every(
        (key: string) =>
          deliveryInformationTemp[key].valid === ValidationStatus.VALID
      );
    const salesPoAllValid = Object.keys(salesPurchaseOrderInfoTemp)
      .filter((key: string) => salesPurchaseOrderInfoTemp[key].valid)
      .every(
        (key: string) =>
          salesPurchaseOrderInfoTemp[key].valid === ValidationStatus.VALID
      );
    return [
      ifAllValid &&
      shippingAddressAllValid &&
      deliveryInformationAllValid &&
      salesPoAllValid
        ? ValidationStatus.VALID
        : ValidationStatus.INVALID,
      smallestOrderWithInvalidStatus,
    ];
  }

  private validateInputField(
    input: IInputField,
    smallestOrderWithInvalidStatus: IInputField | null
  ) {
    if (input.isOptional === true && input.valid !== ValidationStatus.VALID) {
      input.valid = ValidationStatus.VALID;
    } else if (input.required && input.valid === ValidationStatus.UNTOUCHED) {
      input.valid = ValidationStatus.INVALID;
    } else if (input.isDefaultValid && input.valid !== ValidationStatus.VALID) {
      input.valid = ValidationStatus.INVALID;
    }
    if (input.valid === ValidationStatus.INVALID && input.order) {
      if (
        !smallestOrderWithInvalidStatus ||
        (smallestOrderWithInvalidStatus.order &&
          input.order &&
          smallestOrderWithInvalidStatus.order > input.order)
      ) {
        smallestOrderWithInvalidStatus = input;
      }
    }
    updateErrorMessage(input);
    return smallestOrderWithInvalidStatus;
  }
}
