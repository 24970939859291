import { IRouter } from "../helpAndSupport/Navigator/navigator.interface";
import { IButtonAction } from "./facilitySupplyOrders.interface";
import StockIcon from "../../assets/stockSupplies.svg";
import InPatientIcon from "../../assets/inPatientIcon.svg";
import DownloadIcon from "../../assets/download.svg";
import DownloadDisabled from "../../assets/downloadDisabled.svg";

export const facilitySupplyOrderNavigatorArray: IRouter[] = [
  {
    route: "/orders",
    pageName: "Start New Order",
  },
];

export const buttonAction: IButtonAction[] = [
  {
    label: "Download",
    id: "facility-supply-order-button-menu-order-download",
    path: "",
    variant: "text",
    className: "facility-supply-header-download-button",
    testId: "facility-supply-header-buttons-order-download-testId",
    startIcon: DownloadIcon,
    accessorKey: "IsShowInPatientDownloadButton",
    disabled: false,
    disabledIcon: DownloadDisabled,
  },
  {
    label: "Order Stock Supplies",
    id: "facility-supply-order-button-menu-order-stock",
    path: "/orders/inpatientStockSupplyOrder",
    variant: "outlined",
    className: "facility-supply-header-buttons",
    testId: "facility-supply-header-buttons-order-stock-supp-testId",
    startIcon: StockIcon,
    accessorKey: "IsShowInPatientStockSupplyOrderButton",
  },
  {
    label: "Order In-Patient Supplies",
    id: "facility-supply-order-button-menu-order-inpatients",
    path: "/orders/inpatientSupplyOrder",
    variant: "outlined",
    className: "facility-supply-header-buttons no-margin-right",
    testId: "facility-supply-header-buttons-order-inpatient-supp-testId ",
    startIcon: InPatientIcon,
    accessorKey: "IsShowInPatientSupplyOrderButton",
  },
];
