import "./serviceOptions.css";
import { useMemo, useState } from "react";
import {
  getCodeFromText,
  getTextFromCode,
} from "../../../../../util/utilityFunctions";
import AddIcon from "@mui/icons-material/Add";
import {
  IInputField,
  ValidationStatus,
} from "../../../../../core/interfaces/input.interface";
import { Button, IconButton, TextField } from "@mui/material";
import { ERROR_TO_SELECT_ANY_OPTION } from "../../../../../util/errorMsg";
import { ICreateServiceRequest } from "../../createServiceRequest.interface";
import { CreateServiceRequestValidator } from "../../createServiceRequest.validator";
import { ReactComponent as CloseIcon } from "../../../../../assets/popupcloseicon.svg";
import { CustomDropDown } from "../../../../../core/customDropdown/customDropdown.component";
import { InputWithLabel } from "../../../../../core/inputWithLabel/inputWithLabel.component";
import { ServiceOptionsReviewRequest } from "./reviewRequest/serviceOptionsReviewRequest.component";

export interface IDropdownValue {
  code: string;
  isHide?: boolean;
  order: number;
  text: string;
}

export interface IServiceOptions {
  availableOptions: IDropdownValue[];
  data: ICreateServiceRequest;
  editButtonAction?: any;
  isReviewRequest?: boolean;
  isReviewSummary?: boolean;
  setAvailableOptions: Function;
  setData: Function;
}

export const ServiceOptions = ({
  availableOptions,
  data,
  editButtonAction,
  isReviewRequest = false,
  isReviewSummary = false,
  setAvailableOptions,
  setData,
}: IServiceOptions) => {
  const [focusClasses, setFocusClasses] = useState({
    specialInstructions: "specialInstructions",
  });

  const setClasses = (e: any, classname: string) => {
    setFocusClasses(
      Object.assign({}, focusClasses, { [e.target.name]: classname })
    );
  };

  const isAddButtonDisabled = (): boolean => {
    return (
      data.serviceOptions.some(
        (option) => option.valid !== ValidationStatus.VALID
      ) || data.serviceOptions.length === 5
    );
  };

  const updatedAvailableOtionsOnPageLoad = () => {
    let isChangeInAvailableOptions = false;
    let tempOptions = [...availableOptions];
    data.serviceOptions.forEach((option: IInputField) => {
      if (option.value && option.value !== "") {
        if (!isChangeInAvailableOptions) {
          isChangeInAvailableOptions = true;
        }
        tempOptions = updatedAvailableOtions(true, option.value, tempOptions);
      }
    });
    if (isChangeInAvailableOptions) {
      setAvailableOptions(tempOptions);
    }
  };

  const addEmptyOptionToSelectedOptions = () => {
    const nextOrderId = data.serviceOptions.length + 1;
    const nextDropdownField: IInputField = {
      componentId: `create-service-request-service-option-${nextOrderId}`,
      order: nextOrderId,
      required: true,
      title: "Service Option",
      valid: ValidationStatus.UNTOUCHED,
      value: "",
    };
    let updatedOptions = [...data.serviceOptions];
    updatedOptions.push(nextDropdownField);
    setData({ ...data, serviceOptions: updatedOptions });
  };

  const updateOptionToSelectedOptions = (
    index: number,
    selectedOption: string
  ) => {
    if (index < data.serviceOptions.length) {
      const value = getCodeFromText(availableOptions, selectedOption) ?? "";
      let option = data.serviceOptions[index];
      // add and remove selected option from available options
      // If we are updating selected service then update previous select option to available option list to visible.
      // And new selected value shuld hide in available options.
      addOrRemoveOptionFromAvailableOptions(option.value, value);
      // Update the new value to selected option and update selected options array
      option.value = value;
      option.valid = ValidationStatus.VALID;
      option.errorMessage = null;
      let updatedOptions = [...data.serviceOptions];
      updatedOptions.splice(index, 1, option);
      setData({ ...data, serviceOptions: updatedOptions });
    }
  };

  const removeOptionFromSelectedOptions = (index: number) => {
    if (index < data.serviceOptions.length) {
      let option = data.serviceOptions[index];
      // add selected option to available options
      addOrRemoveOptionFromAvailableOptions(option.value);
      // Update selected option
      // First remove particular index option.
      // Second update order number for selected service options
      let updatedOptions = [...data.serviceOptions];
      updatedOptions.splice(index, 1);
      updatedOptions.forEach((option: IInputField, index: number) => {
        option.componentId = `create-service-request-service-option-${
          index + 1
        }`;
        option.order = index + 1;
      });
      setData({ ...data, serviceOptions: updatedOptions });
    }
  };

  const addOrRemoveOptionFromAvailableOptions = (
    addOption?: string | null,
    removeOption?: string | null
  ) => {
    let tempOptions = [...availableOptions];
    if (addOption && addOption !== "") {
      tempOptions = updatedAvailableOtions(false, addOption, tempOptions);
    }
    if (removeOption && removeOption !== "") {
      tempOptions = updatedAvailableOtions(true, removeOption, tempOptions);
    }
    setAvailableOptions(tempOptions);
  };

  const updatedAvailableOtions = (
    isHide: boolean,
    option: string,
    options: IDropdownValue[]
  ): IDropdownValue[] => {
    const index = options.findIndex(
      (availableOption: IDropdownValue) => availableOption.code === option
    );
    if (index > -1) {
      let updatableOtion = options[index];
      updatableOtion.isHide = isHide;
      options.splice(index, 1, updatableOtion);
      return options;
    }
    return options;
  };

  const validateAndSetData = (e: any, index: number) => {
    updateOptionToSelectedOptions(index, e.target.value);
  };

  const addServiceOptionAction = () => {
    addEmptyOptionToSelectedOptions();
  };

  const removeServiceOptionAction = (index: number) => {
    removeOptionFromSelectedOptions(index);
  };

  const validateAndSetDataForSpecialInstructions = (e: any) => {
    const { name, value } = e.target;
    const validator = new CreateServiceRequestValidator();
    const isValid = validator.validate(value, name);
    if (
      name === "specialInstrutions" &&
      isValid!.status === ValidationStatus.INVALID &&
      value.length > 200
    ) {
      return;
    }
    setData((data: ICreateServiceRequest) => ({
      ...data,
      [name]: {
        ...Object(data)[name],
        errorMessage: null,
        valid: isValid!.status,
        value: value,
      },
    }));
  };

  useMemo(() => {
    if (!isReviewRequest) {
      updatedAvailableOtionsOnPageLoad();
    }
  }, []);

  return (
    <div
      className="service-options-component"
      data-testid="service-options-component"
      id="service-options-component"
    >
      {isReviewRequest ? (
        <ServiceOptionsReviewRequest
          availableOptions={availableOptions}
          data={data}
          isReviewSummary={isReviewSummary}
          editButtonAction={editButtonAction}
        />
      ) : (
        <>
          <p
            className="service-options-title"
            data-testid="service-options-title"
          >
            Select Service Options / Enter Problem Description
          </p>
          <p
            className="service-options-description"
            data-testid="service-options-description"
          >
            You may select up to 5 service options.
          </p>
          {data.serviceOptions.map((option: IInputField, index: number) => {
            return (
              <div className="service-option-div">
                <div className="service-option-input-div">
                  <InputWithLabel
                    error={option.valid === ValidationStatus.INVALID}
                    id={`${option.componentId!}`}
                    isDropdown={true}
                    isRequired={option.required}
                    isShowWarning={true}
                    label={`${option.title} ${index + 1}`}
                    labelClassName="service-option-title"
                    testId={`service-options-${index + 1}`}
                    warningMessage={ERROR_TO_SELECT_ANY_OPTION}
                  >
                    <CustomDropDown
                      handleChange={(e: any) => {
                        validateAndSetData(e, index);
                      }}
                      menuItem={availableOptions
                        .filter(
                          (option: IDropdownValue) =>
                            option.isHide === undefined || !option.isHide
                        )
                        .map((option: IDropdownValue) => option.text)}
                      name={`service-option-name-${index + 1}`}
                      placeHolder="Select Option/Problem"
                      selectpropsClassName={
                        option.value ? "service-option-select" : "placeHolder"
                      }
                      selectClassName={"service-option-input"}
                      testId={`service-options-value-${index + 1}`}
                      value={getTextFromCode(availableOptions, option.value)}
                    />
                  </InputWithLabel>
                </div>
                <div className="close-icon-button-div">
                  {data.serviceOptions.length > 1 && (
                    <IconButton
                      aria-label="close"
                      className="close-icon-button"
                      data-testid={`close-icon-button-${index + 1}`}
                      disableFocusRipple={true}
                      id={`close-icon-button-id-${index + 1}`}
                      onClick={() => {
                        removeServiceOptionAction(index);
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                </div>
              </div>
            );
          })}
          <div className="add-service-option-button-div">
            <Button
              classes={{ root: "add-service-option-button" }}
              data-testid="add-service-option-button"
              disabled={isAddButtonDisabled()}
              onClick={addServiceOptionAction}
              startIcon={<AddIcon />}
            >
              Add Service Option/Problem
            </Button>
          </div>
          <div className="create-service-request-special-instructions">
            <InputWithLabel
              error={data.specialInstrutions.valid === ValidationStatus.INVALID}
              id={data.specialInstrutions.componentId!}
              isRequired={data.specialInstrutions.required}
              isShowWarning={true}
              label={data.specialInstrutions.title}
              testId="create-service-request-special-instructions-title"
              warningMessage={data.specialInstrutions.errorMessage}
            >
              <TextField
                data-testid="create-service-request-special-instructions-value"
                error={
                  data.specialInstrutions.valid === ValidationStatus.INVALID
                }
                fullWidth
                id={data.specialInstrutions.id!}
                InputProps={{
                  inputProps: { maxLength: 200 },
                  classes: {
                    root: "textarea-root",
                  },
                }}
                multiline
                name="specialInstrutions"
                onBlur={(e) => setClasses(e, "")}
                onChange={validateAndSetDataForSpecialInstructions}
                onFocus={(e) => setClasses(e, "Mui-focused")}
                placeholder=""
                required={data.specialInstrutions.required}
                rows={4}
                value={data.specialInstrutions.value}
              />
            </InputWithLabel>
          </div>
        </>
      )}
    </div>
  );
};
