export enum InventoryPopUp {
  ERROR_POPUP = "errorPopUp",
  LOADER = "updateLocationLoader",
  MOVE_TO_READY_CARE_PRODUCT = "moveUnitToReadyCareProduct",
  MOVE_TO_READY_CARE_PRODUCT_SUCCESS = "moveUnitToReadyCareProductSuccess",
  MOVE_TO_STORED_PRODUCT = "moveUnitToStoredProduct",
  MOVE_TO_STORE_PRODUCT_SUCCESS = "moveUnitToStoredProductSuccess",
  UPDATE_LOCATION = "updateLocation",
  RETURN_UNIT_TO_SOLVENTUM = "returnUnitToSolventum",
  RETURN_UNIT_TO_SOLVENTUM_SUCCESS = "returnUnittoSolventumSuccess",
  END_THERAPY = "endTherapy",
  END_THERAPY_SUCCESS = "endTherapySuccess",
  MAKE_AVAILABLE_FOR_PLACEMENT = "makeAvailableForPlacement",
  MAKE_AVAILABLE_FOR_PLACEMENT_SUCCESS = "makeAvailableForPlacementSuccess",
  CUSTOMER_PLUS_UPDATE_STORAGE_LOCATION = "updateStorageLocation",
}

export enum InventoryPopUpFlow {
  MOVE_TO_READY_CARE = "1",
  MOVE_TO_STORED_PRODUCT = "2",
  UPDATE_LOCATION = "3",
  RETURN_UNIT_TO_SOLVENTUM = "4",
  END_THERAPY = "5",
  END_THERAPY_SUCCESS = "6",
  MAKE_AVAILABLE_FOR_PLACEMENT = "7",
  MAKE_AVAILABLE_FOR_PLACEMENT_SUCCESS = "8",
  CUSTOMER_PLUS_UPDATE_STORAGE_LOCATION = "9",
}

export enum CustomerPlusFlow {
  CUSTOMER_PLUS_UPDATE_STORAGE_LOCATION = "1",
  END_THERAPY = 2,
  MAKE_UNIT_AVAILABLE_FOR_PLACEMENT = 3,
}
