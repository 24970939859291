import "./dataEntryAndDressings.css";
import {
  convertStringToDate,
  getCodeFromText,
} from "../../../util/utilityFunctions";
import { useContext, useState } from "react";
import {
  ConfirmPlacementContextType,
  ConfirmPlacementContext,
} from "../../../context/ConfirmPlacementContext";
import { Grid, TextField, useMediaQuery } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { ConfirmPlacementValidator } from "../confirmPlacement.validator";
import { ValidationStatus } from "../../../core/interfaces/input.interface";
import { ReactComponent as CalendarIcon } from "../../../assets/calendar.svg";
import { InputWithLabel } from "../../../core/inputWithLabel/inputWithLabel.component";
import { CustomDropDown } from "../../../core/customDropdown/customDropdown.component";
import { ICanister, IDressingKit } from "../../newOrder/newOrder.interface";

type IDataEntryAndDressingsSections = {
  canister: ICanister;
  dressingKit: IDressingKit;
  placementTimes: never[];
  placementTimeText: string[];
  serialNumbers: string[];
  serialNumbersText: string[];
};

export const DataEntryAndDressingsSections = ({
  canister,
  dressingKit,
  placementTimes,
  placementTimeText,
  serialNumbers,
  serialNumbersText,
}: IDataEntryAndDressingsSections) => {
  const isMobileScreen = useMediaQuery("(max-width:600px)");
  const confirmObj = useContext<ConfirmPlacementContextType | null>(
    ConfirmPlacementContext
  );
  const data = confirmObj?.confirmPlacementData!;
  const setData = confirmObj?.setConfirmPlacementData!;

  const validator = new ConfirmPlacementValidator();
  const [focusClasses, setFocusClasses] = useState({
    placementTime: "",
    placementDate: "",
  });

  const setClasses = (e: any, classname: string) => {
    setFocusClasses(
      Object.assign({}, focusClasses, { [e.target.name]: classname })
    );
  };

  const validateAndSetDate = (date: string | null | undefined) => {
    const formatteddate = convertStringToDate(date);
    const isValid = validator.validate(formatteddate!, "placementDate");
    setData({
      ...data,
      placementDate: {
        value: formatteddate,
        valid: isValid!.status,
        required: true,
      },
    });
  };

  const validateAndSetData = (e: any) => {
    let isValid = validator.validate(e.target.value, e.target.name);
    setData({
      ...data,
      [e.target.name]: {
        value: e.target.value,
        valid: isValid!.status,
        required: true,
      },
    });
  };

  return (
    <div className="data-entry-verification">
      <h4 className="data-entry-verification-heading" data-testid="data-header">
        Data Entry Verification
      </h4>
      <Grid
        className="data-entry-verification-grid-container"
        container
        spacing={2}
      >
        <Grid
          className="data-entry-verification-grid-item"
          item
          xs={isMobileScreen ? 12 : 8}
          data-testid="serial-number"
        >
          <InputWithLabel
            label="Serial Number"
            isRequired={data?.serialNumber.required}
            error={data?.serialNumber.valid === ValidationStatus.INVALID}
            isDropdown={true}
          >
            <CustomDropDown
              handleChange={validateAndSetData}
              menuItem={serialNumbersText}
              name="serialNumber"
              placeHolder="Select Serial Number"
              selectpropsClassName={
                data?.serialNumber.value !== ""
                  ? "data-entry-verification-select"
                  : "placeHolder"
              }
              selectClassName={
                data?.serialNumber?.value !== ""
                  ? "data-entry-verification-input"
                  : "placeHolder"
              }
              testId="searial-number"
              value={
                data?.serialNumber?.value !== ""
                  ? data?.serialNumber?.value
                  : null
              }
            />
          </InputWithLabel>
        </Grid>
        {!isMobileScreen && (
          <Grid
            className="data-entry-verification-grid-item"
            item
            xs={4}
          ></Grid>
        )}
        <Grid
          className="data-entry-verification-grid-item"
          item
          xs={isMobileScreen ? 12 : 4}
          data-testid="placement-date"
        >
          <InputWithLabel
            label="Placement Date"
            labelClassName={focusClasses.placementDate}
            isRequired={data?.placementDate?.required}
            error={data?.placementDate?.valid === ValidationStatus.INVALID}
          >
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                InputAdornmentProps={{
                  classes: {
                    root: "adornedRoot1",
                  },
                }}
                InputProps={{
                  classes: {
                    root: `placementDate ${
                      data?.placementDate?.valid === ValidationStatus.INVALID
                        ? "showError"
                        : "noError"
                    }`,
                    input: "input",
                    notchedOutline: "outline",
                  },
                }}
                components={{ OpenPickerIcon: CalendarIcon }}
                value={data?.placementDate?.value}
                onChange={(value) => validateAndSetDate(value)}
                renderInput={(params) => {
                  params.inputProps!.placeholder = "__/__/____";
                  return (
                    <TextField
                      name="placementDate"
                      onFocus={(e) => setClasses(e, "Mui-focused")}
                      onBlur={(e) => setClasses(e, "")}
                      {...params}
                    />
                  );
                }}
              />
            </LocalizationProvider>
          </InputWithLabel>
        </Grid>
        <Grid
          className="data-entry-verification-grid-item"
          item
          xs={isMobileScreen ? 12 : 4}
          data-testid="placement-time"
        >
          <InputWithLabel
            label="Time of Placement"
            isRequired={data?.placementTime?.required}
            error={data?.placementTime?.valid === ValidationStatus.INVALID}
            isDropdown={true}
          >
            <CustomDropDown
              handleChange={validateAndSetData}
              menuItem={placementTimeText}
              name="placementTime"
              placeHolder="Select Time"
              selectpropsClassName={
                data?.placementTime?.value !== ""
                  ? "data-entry-verification-select"
                  : "placeHolder"
              }
              selectClassName={
                data?.placementTime?.value !== ""
                  ? "data-entry-verification-input"
                  : "placeHolder"
              }
              testId="placement-time-number"
              value={
                data?.placementTime?.value !== ""
                  ? data?.placementTime?.value
                  : null
              }
            />
          </InputWithLabel>
        </Grid>
      </Grid>
      <h4
        className="dressings-and-supplies-heading"
        data-testid="dressings-and-supplies-heading"
      >
        Dressings/Supplies
      </h4>
      <Grid
        className="data-entry-verification-grid-container"
        container
        spacing={2}
      >
        <Grid className="data-entry-verification-grid-item" item xs={12}>
          <h4
            className="dressings-and-supplies-description"
            data-testid="sub-heading"
          >
            The V.A.C.® Ready Care Program Kit should contain a label for use
            when returning the product. This label can also be printed in the
            Order Detail screen after this placement has been confirmed.
          </h4>
        </Grid>
        <Grid className="data-entry-verification-grid-item" item xs={12}>
          <div className="dressings-and-supplies-div">
            <h5
              className="dressings-and-supplies-header"
              data-testid="dressings-and-supplies-dressing-kit"
            >
              3M™ V.A.C.® Dressing Kit
            </h5>
            {dressingKit &&
            (dressingKit.productName.value !== "" ||
              dressingKit.secProductName.value !== "") ? (
              <>
                {dressingKit.productName.value !== "" && (
                  <h5
                    className="dressings-and-supplies-value"
                    data-testid="primary-dressing-kit"
                  >
                    <span className="dot-with-space">&bull; </span>
                    {`${
                      dressingKit.productQuantityDesc
                        ? dressingKit.productQuantityDesc.value
                        : `${dressingKit.productQuantity.value} Case of ${
                            dressingKit.productCode.value &&
                            dressingKit.productCode.value !== ""
                              ? dressingKit.productCode.value.split("/")[1] ??
                                dressingKit.productCode.value
                                  .match(/\d/g)
                                  ?.join("")
                                  .replace(/^0+/, "")
                              : dressingKit.productSizeCode.value.split(
                                  "/"
                                )[1] ??
                                dressingKit.productSizeCode.value
                                  .match(/\d/g)
                                  ?.join("")
                                  .replace(/^0+/, "")
                          }`
                    } - ${dressingKit.productName.value}, ${
                      confirmObj && confirmObj?.dressingKit
                        ? dressingKit.productSizeName.value.split(" (")[0]
                        : ""
                    } (${
                      dressingKit.productCode.value &&
                      dressingKit.productCode.value !== ""
                        ? dressingKit.productCode.value
                        : dressingKit.productSizeCode.value
                    })`}
                  </h5>
                )}
                {dressingKit.secProductName.value !== "" && (
                  <h5
                    className="dressings-and-supplies-value"
                    data-testid="secondary-dressing-kit"
                  >
                    <span className="dot-with-space">&bull; </span>
                    {`${
                      dressingKit.secProductQuantityDesc
                        ? dressingKit.secProductQuantityDesc.value
                        : `${dressingKit.secProductQuantity.value} Case of ${
                            dressingKit.secProductCode.value &&
                            dressingKit.secProductCode.value !== ""
                              ? dressingKit.secProductCode.value.split(
                                  "/"
                                )[1] ??
                                dressingKit.secProductCode.value
                                  .match(/\d/g)
                                  ?.join("")
                                  .replace(/^0+/, "")
                              : dressingKit.secProductSizeCode.value.split(
                                  "/"
                                )[1] ??
                                dressingKit?.secProductSizeCode.value
                                  .match(/\d/g)
                                  ?.join("")
                                  .replace(/^0+/, "")
                          }`
                    } - ${dressingKit.secProductName.value}, ${
                      confirmObj && confirmObj?.canister
                        ? dressingKit.secProductSizeName.value.split(" (")[0]
                        : ""
                    } (${
                      dressingKit.secProductCode.value &&
                      dressingKit.secProductCode.value !== ""
                        ? dressingKit.secProductCode.value
                        : dressingKit.secProductSizeCode.value
                    })`}
                  </h5>
                )}
              </>
            ) : (
              <h5>--</h5>
            )}
          </div>
        </Grid>
        <Grid className="data-entry-verification-grid-item" item xs={12}>
          <div className="dressings-and-supplies-div">
            <h5
              className="dressings-and-supplies-header"
              data-testid="dressings-and-supplies-canister"
            >
              3M™ V.A.C.® Canister(s)
            </h5>
            {canister && canister.canisterProductName.value !== "" ? (
              <h5
                className="dressings-and-supplies-value"
                data-testid="canister-data"
              >
                <span className="dot-with-space">&bull; </span>
                {`${
                  canister.canisterProductQuantityDesc
                    ? canister.canisterProductQuantityDesc.value
                    : `${canister.canisterProductQuantity.value} Case of ${
                        canister.canisterProductCode.value &&
                        canister.canisterProductCode.value !== ""
                          ? canister.canisterProductCode.value.split("/")[1] ??
                            canister.canisterProductCode.value
                              .match(/\d/g)
                              ?.join("")
                              .replace(/^0+/, "")
                          : ""
                      }`
                } - ${canister.canisterProductName.value}, (${
                  canister.canisterProductCode.value &&
                  canister.canisterProductCode.value !== ""
                    ? canister.canisterProductCode.value
                    : ""
                })`}
              </h5>
            ) : (
              <h5>--</h5>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
