import React from "react";
import { useEffect, useState, useContext } from "react";
import { Button, Grid } from "@mui/material";
import {
  IFacilitySearch,
  IFacilitySearchProps,
} from "./caregiverFacilitySearchAndAdd.model";
import { ValidationStatus } from "../interfaces/input.interface";
import "./caregiverFacilitySearchAndAdd.css";
import { Validation } from "../interfaces/input.interface";
import { getInvalidObj } from "../../util/utilityFunctions";
import { SearchByID } from "../../components/manageProfile/facilityInformation/addFacility/searchByID/searchByID.component";
import { SearchByName } from "../../components/manageProfile/facilityInformation/addFacility/searchByName/searchByName.component";
import {
  IFacilitySearchRequest,
  ISearchFacilityByID,
  ISearchFacilityByName,
} from "../../components/manageProfile/facilityInformation/addFacility/searchFacility.interface";
import {
  defaultSearchDataForID,
  defaultSearchDataForName,
} from "../../components/manageProfile/facilityInformation/addFacility/searchFacility.model";
import { getDeepClone } from "../../util/ObjectFunctions";
import { AddFacilityContext } from "../../components/manageProfile/facilityInformation/addFacilityContainer/addFacilityContainer.context";

export const CaregiverFacilitySearch = ({
  data,
  setData,
  handleSearch,
  redirectToProviderSearch,
  statesText,
  states,
}: IFacilitySearchProps) => {
  const { facilitySearchValidator } = useContext(AddFacilityContext);
  const [facilitySearchForName, setFacilitySearchForName] =
    useState<ISearchFacilityByName>(getDeepClone(defaultSearchDataForName));
  const [facilitySearchForID, setFacilitySearchForID] =
    useState<ISearchFacilityByID>(getDeepClone(defaultSearchDataForID));
  const [isFormValid, setIsFormValid] = useState<ValidationStatus>(
    ValidationStatus.INVALID
  );
  const validate = () => {
    let isValid: Validation = getInvalidObj(null);
    if (facilitySearchForID.search.value === "true") {
      isValid =
        facilitySearchValidator.validateAllSearchFields(facilitySearchForID);
    } else if (facilitySearchForName.search.value === "true") {
      isValid = facilitySearchValidator.validateAllSearchFields(
        facilitySearchForName
      );
    }
    setData(Object.assign({}, facilitySearchForID, facilitySearchForName));
    setIsFormValid(isValid.status);
  };
  useEffect(() => {
    validate();
  }, [facilitySearchForName, facilitySearchForID]);

  return (
    <Grid container className="facility-search-container">
      <div className="hc-provider-title">Home Care Provider Search</div>
      <Grid container className="fs-title-container">
        <Grid item className="fs-title">
          Search Facilities Database
        </Grid>
        <Grid item>
          <Button
            variant="text"
            onClick={redirectToProviderSearch}
            className="search-provider-btn"
            classes={{ root: "search-provider-root" }}
            data-testid="search-provider-btn"
          >
            Search My Home Care Provider List instead
          </Button>
        </Grid>
      </Grid>
      <Grid>
        <SearchByName
          facilitySearchDataForID={facilitySearchForID}
          facilitySearchDataForName={facilitySearchForName}
          setfacilitySearchDataForID={setFacilitySearchForID}
          setfacilitySearchDataForName={setFacilitySearchForName}
          onSubmit={handleSearch}
        />
        <div className="name-id-separator">OR</div>
        <SearchByID
          facilitySearchDataForID={facilitySearchForID}
          facilitySearchDataForName={facilitySearchForName}
          setfacilitySearchDataForID={setFacilitySearchForID}
          setfacilitySearchDataForName={setFacilitySearchForName}
          onSubmit={handleSearch}
        />
      </Grid>
    </Grid>
  );
};
