import "./searchHomeCareOrder.css";
import { Typography, useMediaQuery } from "@mui/material";
import {
  Validation,
  ValidationStatus,
} from "../../../core/interfaces/input.interface";
import { useContext, useEffect, useState } from "react";
import { getDeepClone } from "../../../util/ObjectFunctions";
import { getInvalidObj } from "../../../util/utilityFunctions";
import {
  defaultPatientDataForNameSearch,
  defaultPatientDataForRental,
} from "../../../components/myPatients/addPatient/searchPatient.model";

import {
  ISearchPatientByName,
  ISearchPatientByRentalOrder,
} from "../../../components/myPatients/addPatient/searchPatient.interface";
import { ExpressButton } from "../../../core/expressButton/expressButton.component";
import { SearchByRO } from "../../../components/myPatients/addPatient/searchByRo/searchByRo.component";
import { SearchByName } from "../../../components/myPatients/addPatient/searchByName/searchByName.component";
import { AddPatientContext } from "../../../components/myPatients/addPatientContainer/addPatientContainer.context";
import moment from "moment";
import { PageSection } from "../searchHomeCareOrderContainer.enum";
import { homeCareOrderSearch } from "../../../util/3meService";
import { LoadingSpinner } from "../../../core/loader/LoadingSpinner";
import { AuthContextType, AuthContext } from "../../../context/AuthContext";
import { ISearchHomeCareOrder } from "./searchHomeCareOrder.interface";

export const SearchHomeCareOrder = ({
  redirectHandler,
}: ISearchHomeCareOrder) => {
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const { patientSearchValidator } = useContext(AddPatientContext);
  const [isValid, setIsValid] = useState<boolean>(false);
  const isMobileScreen = useMediaQuery("(max-width:600px)");
  const [searchOrderByName, setSearchOrderByName] =
    useState<ISearchPatientByName>(
      getDeepClone(defaultPatientDataForNameSearch)
    );
  const [searchOrderByRO, setSearchOrderByRO] =
    useState<ISearchPatientByRentalOrder>(
      getDeepClone(defaultPatientDataForRental)
    );

  const fetchHomeCareOrderSearchResults = async () => {
    redirectHandler(PageSection.SEARCH_HOME_CARE_ORDER_LOADER);
    const reqParams = {
      dob:
        searchOrderByRO.search.value === "true"
          ? moment(searchOrderByRO.dob.value).format("MM/DD/yyyy")
          : moment(searchOrderByName.dob1.value).format("MM/DD/yyyy"),
      lastName:
        searchOrderByName.search.value === "true"
          ? searchOrderByName.lastName.value?.trim()
          : "",
      ron:
        searchOrderByRO.search.value === "true" ? searchOrderByRO.ro.value : "",
      zipCode:
        searchOrderByName.search.value === "true"
          ? searchOrderByName.zip.value
          : "",
    };
    const response = await homeCareOrderSearch(reqParams);
    if (response && response.succeeded && response.item) {
      redirectHandler(PageSection.SEARCH_HOME_CARE_ORDER_FOUND, response.item);
    } else {
      redirectHandler(PageSection.SEARCH_HOME_CARE_ORDER_NOT_FOUND);
    }
  };

  const handleSubmitBtnAction = () => {
    fetchHomeCareOrderSearchResults();
  };

  const validateForm = () => {
    let isValid: Validation = getInvalidObj(null);
    if (searchOrderByName.search.value === "true") {
      isValid =
        patientSearchValidator.validateAllSearchFields(searchOrderByName);
    } else if (searchOrderByRO.search.value === "true") {
      isValid = patientSearchValidator.validateAllSearchFields(searchOrderByRO);
    }
    setIsValid(isValid!.status === ValidationStatus.VALID);
  };

  useEffect(() => {
    validateForm();
  }, [searchOrderByName, searchOrderByRO]);

  return (
    <div className="seach-home-care-order">
      <>
        <Typography
          className="seach-home-care-order-title"
          data-testid="seach-home-care-order-title"
        >
          Search for a Home Care Order
        </Typography>
        <Typography
          className="seach-home-care-order-description"
          data-testid="seach-home-care-order-description"
        >
          To find an order in any facility, search with either of the criteria
          below
        </Typography>
        <SearchByRO
          patientSearchDataForName={searchOrderByName}
          patientSearchDataForRo={searchOrderByRO}
          setPatientSearchDataForName={setSearchOrderByName}
          setPatientSearchDataForRo={setSearchOrderByRO}
          isMobileScreenDisplay={isMobileScreen ? true : false}
        />
        <Typography
          className="seach-home-care-order-or-title"
          data-testid="seach-home-care-order-or-title"
        >
          or
        </Typography>
        <SearchByName
          patientSearchDataForName={searchOrderByName}
          patientSearchDataForRo={searchOrderByRO}
          setPatientSearchDataForName={setSearchOrderByName}
          setPatientSearchDataForRo={setSearchOrderByRO}
          isMobileScreenDisplay={isMobileScreen ? true : false}
        />
        <ExpressButton
          clickHandler={handleSubmitBtnAction}
          disabled={!isValid}
          parentClass="seach-home-care-order-search-button"
          testId="seach-home-care-order-search-button"
          variant="contained"
        >
          Search
        </ExpressButton>
      </>
    </div>
  );
};
