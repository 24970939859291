import { Grid } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import {
  OrderDetailContext,
  OrderDetailContextType,
} from "../../../../../../context/OrderDetailsContext";
import { getDeepClone } from "../../../../../../util/ObjectFunctions";
import { getPatientWoundAssesmentPhotos } from "../../../../../../util/woundAssessmentService";
import { WoundTunnelingReviewOrder } from "../../../../../newOrder/woundTunneling/reviewOrder/woundTunnelingReviewOrder.component";
import { IWoundAssesments } from "../../../orderOverview/orderOverview.interface";
import { mapWoundDetailsToAddWoundAssesmentType } from "../../../orderOverview/orderOverviewResponseMapper";
import { IAddWoundAssessment } from "../../../woundProgress/addWoundAssessment/addWoundAssessment.interface";
import ReviewWoundExposedStructures from "../../../woundProgress/addWoundAssessment/exposedWoundStructures/reviewWoundExposedStructures/reviewWoundExposedStructures.component";
import ReviewHoldOrHospitalization from "../../../woundProgress/addWoundAssessment/holdOrHospitalization/reviewHoldOrHospitalization/reviewHoldOrHospitalization.component";
import ReviewWoundComorbidities from "../../../woundProgress/addWoundAssessment/woundAssessComorbidities/reviewWoundAssessmentComorbidities/reviewWoundAssessmentComorbidities.component";
import ReviewWoundAssessmentBed from "../../../woundProgress/addWoundAssessment/woundAssessmentBed/reviewWoundAssessmentBed/reviewWoundAssessmentBed.component";
import { WoundAssessmentType } from "../../../woundProgress/addWoundAssessment/woundAssessmentPageSection.enum";
import ReviewWoundAssessor from "../../../woundProgress/addWoundAssessment/woundAssessor/reviewWoundAssessor/reviewWoundAssessor.component";
import ReviewWoundDebridement from "../../../woundProgress/addWoundAssessment/woundDebridement/reviewWoundDebridement/reviewWoundDebridement.component";
import ReviewWoundExudate from "../../../woundProgress/addWoundAssessment/woundExudate/reviewWoundExudate/reviewWoundExudate.component";
import ReviewWoundMeasurement from "../../../woundProgress/addWoundAssessment/woundMeasurement/reviewWoundMeasurement/reviewWoundMeasurement.component";
import ReviewWoundMeasurementEschar from "../../../woundProgress/addWoundAssessment/woundMeasurementEschar/reviewWoundMeasurementEschar/reviewWoundMeasurementEschar.component";
import ReviewWoundTherapyStatus from "../../../woundProgress/addWoundAssessment/woundTherapyStatus/reviewWoundTherapyStatus/reviewWoundTherapyStatus.component";
import { ImageWindowViewer } from "../imageViewer.component";
import OrderOverviewReviewWoundUndermining from "./orderOverviewReviewWoundUndermining.component";
import "./orderOverviewWoundSummaryReview.css";
import {
  AuthContext,
  AuthContextType,
} from "../../../../../../context/AuthContext";

type Props = {
  assesmentDetails: IWoundAssesments;
  assesmentType: string;
  index: number;
  isFromAddWoundAssessment: boolean;
};

export const OrderOverviewWoundSummaryReview = ({
  assesmentDetails,
  assesmentType,
  index,
  isFromAddWoundAssessment = false,
}: Props) => {
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const [woundDebridementTypeCode, setWoundDebridementTypeCode] = useState([]);
  const [data, setData] = useState<IAddWoundAssessment>();
  const [loaderSpinner, setloaderSpinner] = useState(false);
  const [woundAssesmentImages, setWoundAssesmentImages] = useState([]);
  const orderOverviewObj = useContext<OrderDetailContextType | null>(
    OrderDetailContext
  );

  useEffect(() => {
    setloaderSpinner(true);
    callMapperToMapWoundData();
    getWoundAssessmentPhotos();
  }, []);

  const callMapperToMapWoundData = async () => {
    let mappedWoundObject: IAddWoundAssessment =
      await mapWoundDetailsToAddWoundAssesmentType(assesmentDetails);
    setData(getDeepClone(mappedWoundObject));
  };

  const getWoundAssessmentPhotos = async () => {
    let reqParam = {
      woundId: orderOverviewObj?.woundId.toString(),
      AssessmentDate: assesmentDetails.evaluationDate,
    };
    const respObj = await getPatientWoundAssesmentPhotos(reqParam);
    if (respObj && respObj.succeeded) {
      if (respObj.data.length > 0) setWoundAssesmentImages(respObj.data);
      setloaderSpinner(false);
    } else setloaderSpinner(false);
  };

  const assessmentTypeBasedFlow = () => {
    if (index === 0) {
      return (
        <Grid
          className="order-overview-wound-summary-review-grid-container"
          container
        >
          <Grid className="order-overview-wound-summary-review-grid-item" item>
            <ReviewWoundMeasurement
              data={data!}
              editButtonClicked={false}
              isWoundAssessmentSummary={true}
            />
          </Grid>
          <Grid className="order-overview-wound-summary-review-grid-item" item>
            <ReviewWoundMeasurementEschar
              data={data!}
              editButtonClicked={false}
              isWoundAssessmentSummary={true}
            />
          </Grid>
          <Grid
            className="order-overview-wound-summary-review-grid-item"
            item
            xs={6}
          >
            <ReviewWoundDebridement
              data={data!}
              editButtonClicked={false}
              woundDebridementTypeCode={woundDebridementTypeCode}
              isWoundAssessmentSummary={true}
            />
            <ReviewWoundExudate
              data={data!}
              exudateAppearanceData={data?.exudateAppearance}
              exudateAmountData={data?.exudateAmount}
              editButtonClicked={false}
              isWoundAssessmentSummary={true}
            />
            <OrderOverviewReviewWoundUndermining
              data={data!}
              editButtonClicked={false}
              isWoundAssessmentSummary={true}
            />
            <WoundTunnelingReviewOrder
              editButtonClicked={false}
              isOrderSummary={true}
              woundInfoData={data}
              isWoundAssessmentSummary={true}
              isComingFromOrderOverview={true}
            />
            <ReviewWoundExposedStructures
              data={data!}
              editButtonClicked={false}
              isWoundAssessmentSummary={true}
            />
            <ReviewWoundComorbidities
              data={data!}
              editButtonClicked={false}
              isWoundAssessmentSummary={true}
              isWoundDetailFlow={true}
            />
          </Grid>
        </Grid>
      );
    } else if (index > 0 && data && data?.woundTherapyStatus.value === "") {
      return (
        <Grid
          className="order-overview-wound-summary-review-grid-container"
          container
        >
          <Grid className="order-overview-wound-summary-review-grid-item" item>
            <ReviewWoundTherapyStatus
              data={data}
              editButtonClicked={false}
              isWoundAssessmentSummary={true}
            />
            <ReviewWoundMeasurementEschar
              data={data!}
              editButtonClicked={false}
              isWoundAssessmentSummary={true}
            />
          </Grid>
          <Grid
            className="order-overview-wound-summary-review-grid-item"
            item
            xs={6}
          >
            <ReviewWoundDebridement
              data={data!}
              editButtonClicked={false}
              woundDebridementTypeCode={woundDebridementTypeCode}
              isWoundAssessmentSummary={true}
            />
            <ReviewWoundExudate
              data={data!}
              exudateAppearanceData={data?.exudateAppearance}
              exudateAmountData={data?.exudateAmount}
              editButtonClicked={false}
              isWoundAssessmentSummary={true}
            />
            <OrderOverviewReviewWoundUndermining
              data={data!}
              editButtonClicked={false}
              isWoundAssessmentSummary={true}
            />
            <WoundTunnelingReviewOrder
              editButtonClicked={false}
              isOrderSummary={true}
              woundInfoData={data}
              isWoundAssessmentSummary={true}
              isComingFromOrderOverview={true}
            />
            <ReviewWoundExposedStructures
              data={data!}
              editButtonClicked={false}
              isWoundAssessmentSummary={true}
            />
            <ReviewWoundComorbidities
              data={data!}
              editButtonClicked={false}
              isWoundAssessmentSummary={true}
            />
          </Grid>
          {loaderSpinner ? LoadingSkeleton() : callImageviewer()}
        </Grid>
      );
    } else if (assesmentType === WoundAssessmentType.MWP) {
      return (
        <>
          {data && data.woundTherapyStatus.value !== "" && (
            <>
              <Grid
                className="order-overview-wound-summary-review-grid-container"
                container
              >
                <Grid
                  className="order-overview-wound-summary-review-grid-item"
                  item
                >
                  <ReviewWoundTherapyStatus
                    data={data}
                    editButtonClicked={false}
                    isWoundAssessmentSummary={true}
                  />
                </Grid>

                <Grid
                  className="order-overview-wound-summary-review-grid-item"
                  item
                >
                  <ReviewWoundMeasurement
                    data={data}
                    editButtonClicked={false}
                    isWoundAssessmentSummary={true}
                  />
                </Grid>
              </Grid>
              {data.woundTherapyStatus.value === "yes" && (
                <>
                  <Grid
                    className="order-overview-wound-summary-review-grid-item"
                    item
                    xs={6}
                  >
                    <ReviewWoundMeasurementEschar
                      data={data}
                      editButtonClicked={false}
                      isWoundAssessmentSummary={true}
                    />
                  </Grid>

                  <Grid
                    className="order-overview-wound-summary-review-grid-item"
                    item
                    xs={6}
                  >
                    <ReviewHoldOrHospitalization
                      data={data}
                      editButtonClicked={false}
                      isWoundAssessmentSummary={true}
                    />
                  </Grid>
                  {data.resumptionMeasureStatus.value === "yes" && (
                    <Grid
                      className="order-overview-wound-summary-review-grid-item"
                      item
                      xs={6}
                    >
                      <ReviewWoundDebridement
                        data={data}
                        editButtonClicked={false}
                        woundDebridementTypeCode={woundDebridementTypeCode}
                        isWoundAssessmentSummary={true}
                      />
                    </Grid>
                  )}
                  <Grid
                    className="order-overview-wound-summary-review-grid-item"
                    item
                    xs={6}
                  ></Grid>
                </>
              )}
              <Grid
                className="order-overview-wound-summary-review-grid-item"
                item
                xs={6}
              >
                <ReviewWoundAssessor
                  data={data}
                  editButtonClicked={false}
                  isWoundAssessmentSummary={true}
                  isFromAddWoundAssessment={
                    isFromAddWoundAssessment && authObj?.isInternalUser
                  }
                />
              </Grid>
              {loaderSpinner ? LoadingSkeleton() : callImageviewer()}
            </>
          )}
        </>
      );
    } else {
      return (
        <>
          {data && data?.woundTherapyStatus.value !== "" && (
            <>
              <ReviewWoundTherapyStatus
                data={data}
                editButtonClicked={false}
                isWoundAssessmentSummary={true}
              />

              {data?.woundTherapyStatus.value !== "yes" && (
                <ReviewWoundMeasurement
                  data={data!}
                  editButtonClicked={false}
                  isWoundAssessmentSummary={true}
                />
              )}
              {data?.woundTherapyStatus.value === "yes" && (
                <>
                  <ReviewWoundDebridement
                    data={data}
                    editButtonClicked={false}
                    woundDebridementTypeCode={woundDebridementTypeCode}
                    isWoundAssessmentSummary={true}
                  />

                  <ReviewWoundMeasurement
                    data={data}
                    editButtonClicked={false}
                    isWoundAssessmentSummary={true}
                  />
                  <ReviewWoundMeasurementEschar
                    data={data}
                    editButtonClicked={false}
                    isWoundAssessmentSummary={true}
                  />
                  <ReviewWoundAssessmentBed
                    data={data}
                    editButtonClicked={false}
                    isWoundAssessmentSummary={true}
                  />
                  <ReviewWoundExudate
                    data={data}
                    exudateAppearanceData={data.exudateAppearance}
                    exudateAmountData={data.exudateAmount}
                    editButtonClicked={false}
                    isWoundAssessmentSummary={true}
                  />
                  <OrderOverviewReviewWoundUndermining
                    data={data}
                    editButtonClicked={false}
                    isWoundAssessmentSummary={true}
                  />
                  <WoundTunnelingReviewOrder
                    editButtonClicked={false}
                    isOrderSummary={true}
                    woundInfoData={data}
                    isWoundAssessmentSummary={true}
                    isComingFromOrderOverview={true}
                  />
                  <ReviewWoundExposedStructures
                    data={data}
                    editButtonClicked={false}
                    isWoundAssessmentSummary={true}
                  />
                  <ReviewWoundComorbidities
                    data={data}
                    editButtonClicked={false}
                    isWoundAssessmentSummary={true}
                  />
                  <ReviewHoldOrHospitalization
                    data={data}
                    editButtonClicked={false}
                    isWoundAssessmentSummary={true}
                  />
                </>
              )}
              <ReviewWoundAssessor
                data={data!}
                editButtonClicked={false}
                isWoundAssessmentSummary={true}
                isFromAddWoundAssessment={
                  isFromAddWoundAssessment && authObj?.isInternalUser
                }
              />
              {loaderSpinner ? LoadingSkeleton() : callImageviewer()}
            </>
          )}
        </>
      );
    }
  };

  const callImageviewer = () => {
    return (
      <Grid className="order-overview-wound-summary-review-grid-item" item>
        <div
          className={
            woundAssesmentImages.length > 0
              ? "wound-image-title"
              : "wound-image-hyphen-title"
          }
        >
          Images
        </div>

        {woundAssesmentImages && woundAssesmentImages.length > 0 ? (
          <div className="wound-image-div" data-testid="wound-image-div">
            <ImageWindowViewer
              Images={woundAssesmentImages}
              woundAssessmentDate={assesmentDetails.evaluationDate}
            />
          </div>
        ) : (
          <div className="wound-image-hyphen">--</div>
        )}
      </Grid>
    );
  };

  const LoadingSkeleton = () => {
    return (
      <Grid className="order-overview-wound-summary-review-grid-item" item>
        <div className="wound-image-title" data-testid="wound-image-sec-div">
          Images
        </div>
        <div className="skelton-container">
          {[...Array(4)].map((_) => (
            <Skeleton
              width={"50px"}
              height={"50px"}
              className="wound-image-box"
            />
          ))}
        </div>
      </Grid>
    );
  };
  return <>{data && assessmentTypeBasedFlow()}</>;
};
