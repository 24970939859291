import { useContext, useEffect, useState } from "react";
import {
  RolesPermissionContext,
  RolesPermissionContextType,
} from "../../context/RolesPermissionContext";
import { getCMSContent } from "../../util/cmsService";
import { USER_ROLE_BASE } from "../../util/PermissionName";
import {
  CMS_TECHNICALSUPPORT_CONTENT,
  STATIC_DEVICE_SUPPORT_NUMBER,
} from "../../util/staticText";
import { PageContext } from "../page/page.context";
import "./needHelp.css";
import {
  IDeviceTroubleshootingSupport,
  ITechnicalSupport,
} from "./needHelp.interface";
import { getStaticContent } from "../../util/staticContentService";

export const NeedHelp = ({
  isLoggedIn,
  userRole,
  isFromHelpSupport,
}: {
  isLoggedIn: boolean | null;
  userRole: string | undefined;
  isFromHelpSupport: boolean | null;
}) => {
  const {
    salesRepContacts,
    techRepContacts,
    pssRepContacts,
    deviceSupportNumber,
  } = useContext(PageContext);
  const [techSupportInfo, setTechSupportInfo] =
    useState<ITechnicalSupport | null>();
  const [deviceSupportNumberInfo, setDeviceSupportNumberInfo] =
    useState<IDeviceTroubleshootingSupport>();
  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );
  const loadTechSupport = async () => {
    const { item } = (await getCMSContent(CMS_TECHNICALSUPPORT_CONTENT)) || {};
    const techSupportData: ITechnicalSupport = {
      emailAddress: item.emailAddress,
      phoneNo: item.phoneNo,
    };
    setTechSupportInfo(techSupportData);
  };

  useEffect(() => {
    if (!isLoggedIn) {
      loadTechSupport();
      loadDeviceSupport();
    } else {
      setTechSupportInfo(techRepContacts);
      setDeviceSupportNumberInfo(deviceSupportNumber!);
    }
  }, [techRepContacts, deviceSupportNumber]);

  const loadDeviceSupport = async () => {
    const data = await getStaticContent(STATIC_DEVICE_SUPPORT_NUMBER);
    if (data && data.succeeded) {
      const deviceData: IDeviceTroubleshootingSupport = {
        phoneNo: data.items[0]?.value,
      };
      setDeviceSupportNumberInfo(deviceData);
    } else {
      setDeviceSupportNumberInfo(undefined);
    }
  };

  const ContactInfo = ({ title, name, phoneNo, emailAddress }: any) => {
    return (
      <div className="section">
        <h5 className="sub-head" data-testid="post-acute-sales-rep">
          {title}
        </h5>
        <h6 className="sub-head-2" data-testid="user-name">
          {name}
        </h6>
        <div className="sub-section">
          <span className="contact-type" data-testid="phone2">
            Phone:&nbsp;
          </span>
          <a
            className="contact-value"
            data-testid="user-mobile-number"
            href={`tel:${phoneNo}`}
          >
            {phoneNo}
          </a>
        </div>
        <div className="sub-section">
          <span className="contact-type" data-testid="email2">
            Email:&nbsp;
          </span>
          <a
            className="contact-value"
            data-testid="c"
            href={`mailto:${emailAddress}`}
          >
            {emailAddress}
          </a>
        </div>
      </div>
    );
  };

  const SupportInfo = (
    title: string,
    phoneNo: string,
    emailAddress: string
  ) => {
    return (
      <div className="section">
        <h5 className="sub-head">{title}</h5>
        <div className="sub-section">
          <span className="contact-type" data-testid="phone1">
            Phone:&nbsp;
          </span>
          {phoneNo && (
            <>
              <a className="contact-value" href={`tel:${phoneNo}`}>
                {phoneNo!}
              </a>
            </>
          )}
        </div>
        {emailAddress !== "" && (
          <div className="sub-section">
            <span className="contact-type" data-testid="email1">
              Email:&nbsp;
            </span>

            <a className="contact-value" href={`mailto:${emailAddress!}`}>
              {emailAddress}
            </a>
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      className={
        userRole !== USER_ROLE_BASE
          ? "help-container"
          : userRole === USER_ROLE_BASE && !isFromHelpSupport
          ? "help-container baseRole"
          : "help-container"
      }
    >
      <h3 className="head">Need help?</h3>
      {SupportInfo(
        "Website Support",
        techSupportInfo?.phoneNo!,
        techSupportInfo?.emailAddress!
      )}
      {deviceSupportNumberInfo && (
        <>
          {SupportInfo(
            "Device Troubleshooting Support",
            deviceSupportNumberInfo?.phoneNo!,
            ""
          )}
        </>
      )}

      {isLoggedIn &&
        salesRepContacts?.name &&
        !permissionObj?.mappedRolesPermissionData.IsBaseRole &&
        permissionObj?.mappedRolesPermissionData.IsSalesRepDetails && (
          <ContactInfo
            title="3M Sales Representative"
            name={salesRepContacts.name}
            phoneNo={salesRepContacts.phoneNo}
            emailAddress={salesRepContacts.emailAddress}
          />
        )}
      {isLoggedIn &&
        pssRepContacts?.name &&
        !permissionObj?.mappedRolesPermissionData.IsBaseRole &&
        permissionObj?.mappedRolesPermissionData.IsSalesRepDetails && (
          <ContactInfo
            title="3M Patient Support Specialist"
            name={pssRepContacts.name}
            phoneNo={pssRepContacts.phoneNo}
            emailAddress={pssRepContacts.emailAddress}
          />
        )}
    </div>
  );
};
