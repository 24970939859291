import "./siteStatusesBanner.css";
import {
  ISiteStatusesBannerCSS,
  ISiteStatusesBannerProp,
  MessageType,
} from "./siteStatusesBanner.interfaces";
import CloseIcon from "@mui/icons-material/Close";
import { Alert, AlertTitle, IconButton } from "@mui/material";

export const SiteStatusBanner = ({
  handleCloseButton,
  handleUrlClickAction,
  index,
  siteStatus,
}: ISiteStatusesBannerProp) => {
  const getSiteStatusBannerCss = (
    messageType: MessageType
  ): ISiteStatusesBannerCSS => {
    switch (messageType) {
      case MessageType.ALERT:
        return {
          classname: "site-status-alert",
          severity: "error",
        };
      case MessageType.INFORMATION:
        return {
          classname: "site-status-information",
          severity: "info",
        };
      case MessageType.WARNING:
        return {
          classname: "site-status-warning",
          severity: "warning",
        };
      default:
        return {
          classname: "site-status-information",
          severity: "info",
        };
    }
  };

  const { classname, severity } = getSiteStatusBannerCss(
    siteStatus.messageType
  );

  const handleOnLinkClick = (link: string) => {
    window.open(link);
  };

  return (
    <div
      className="site-status-banner-component"
      data-testid="site-status-banner-component"
      key={index}
    >
      <Alert
        className={`site-status-banner-alert ${classname}`}
        classes={{ message: "site-status-alert-message" }}
        data-testid={`site-status-banner-alert-${index + 1}`}
        icon={false}
        key={`${severity} ${index}`}
        severity={severity}
      >
        <div className="site-status-title-and-description">
          <AlertTitle
            className={`site-status-title ${classname}`}
            data-testid={`site-status-title-${index + 1}`}
          >
            {siteStatus.title}
          </AlertTitle>
          {(siteStatus.detail || siteStatus.linkText) && (
            <p
              className="site-status-detail"
              data-testid={`site-status-detail-${index + 1}`}
            >
              {siteStatus.detail ? `${siteStatus.detail} ` : ""}
              {siteStatus.linkText && (
                <span
                  className={`site-status-url-link ${classname}`}
                  data-testid={`site-status-url-link-${index + 1}`}
                  key={index}
                  onClick={() =>
                    handleUrlClickAction
                      ? handleUrlClickAction()
                      : handleOnLinkClick(siteStatus.urlLink)
                  }
                >
                  {siteStatus.linkText}
                </span>
              )}
            </p>
          )}
        </div>
        <div className="site-status-close-button-div">
          {siteStatus.canBeCleared && (
            <IconButton
              className="site-status-close-button"
              data-testid={`site-status-close-button-${index + 1}`}
              disableRipple
              onClick={() => handleCloseButton(index)}
            >
              <CloseIcon className={classname} />
            </IconButton>
          )}
        </div>
      </Alert>
    </div>
  );
};
