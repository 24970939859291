import { ExpressButton } from "../../../../../../../core/expressButton/expressButton.component";
import "./missingRequiredFieldPopUp.css";
interface Props {
  missingRequiredFieldPrompt: Function;
}

export const MissingRequiredFieldPopup = ({
  missingRequiredFieldPrompt,
}: Props) => {
  return (
    <div
      className="showMissingRequiredField"
      data-testid="showMissingRequiredField"
    >
      <div
        className="showMissingRequiredFieldTitle"
        data-testid="missingFieldTitle"
      >
        Missing required fields
      </div>
      <div
        className="showMissingRequiredField-desp"
        data-testid="showMissingRequiredField-desp"
      >
        Please see the above red highlighted field(s) for additional required
        information.
      </div>
      <ExpressButton
        variant="contained"
        parentClass="cancelBtn"
        clickHandler={() => {
          missingRequiredFieldPrompt();
        }}
        testId="doneBtn"
      >
        Done
      </ExpressButton>
    </div>
  );
};
