import "./inpatientSupplyOrderSummary.css";
import { useHistory } from "react-router-dom";
import { ReactComponent as DownloadIcon } from "../../../assets/download.svg";
import { ExpressButton } from "../../../core/expressButton/expressButton.component";

import { SummaryHeader } from "../../inventory/requestService/serviceRequestSummary/header/summaryHeader.component";
import { IInpatientSupplyOrder } from "../inpatientSupplyOrder.interface";
import { ShippingAddressReview } from "../component/shippingAddress/reviewShippingAddress/shippingAddressReview.component";
import { DeliveryInformationReview } from "../component/supplyOrderDeliveryInformation/reviewDeliveryInformation/deliveryInformationReview.component";
import { PatientInformationReview } from "../firstPage/reviewSupplyOrderPatientInformation/supplyOrderPatienInformationReview.component";
import { SalesPoOrderInfoReview } from "../component/salesPurchaseOrder/reviewSalesPurchaseOrder/salesPurchaseOrderReview.component";
import { SelectedSuppliesReview } from "../component/selectedSupplies/reviewSelectedSupplies/selectedSuppliesReview.component";
import { CartState } from "../component/cart/cart.reducer";

interface IInPatientOrderSummary {
  data: IInpatientSupplyOrder;
  isSummaryOrDetail: boolean;
  cartItem: CartState;
  isFromStockSupplies?: boolean;
}

export const InpatientSuppyOrderSummaryPage = ({
  data,
  isSummaryOrDetail,
  cartItem,
  isFromStockSupplies = false,
}: IInPatientOrderSummary) => {
  const history = useHistory();
  const handleNavigation = () => {
    history.push("/home");
  };

  const handleClickPdfDownload = async () => {
    window.print();
  };

  return (
    <div
      className="in-patient-supply-order-summary-page-component"
      data-testid="in-patient-supply-order-summary-page-component-testId"
    >
      <SummaryHeader
        successMessage="Success!"
        description1={`Your ${
          isFromStockSupplies ? "Stock " : "In-Patient "
        }Supply Order has been submitted.`}
        description2="A 3M Medical Solutions representative will contact you if there are any questions about this order."
        backButton="Back to My Patients"
        handleBackButton={handleNavigation}
      />
      <div className="in-patient-supply-summary-header">
        <p
          className="in-patient-supply-summary-title"
          data-testid="in-patient-supply-summary-title-testId"
          id="in-patient-supply-summary-title"
        >
          Order Summary
        </p>
        <div className="in-patient-supply-summary-header-button">
          <ExpressButton
            clickHandler={() => handleClickPdfDownload()}
            id="in-patient-supply-summary-download-button"
            parentClass="in-patient-supply-summary-download-button"
            testId="download-button"
            variant="text"
            startIcon={<DownloadIcon />}
          >
            Save and Print Order Summary
          </ExpressButton>
        </div>
      </div>
      <div
        className="inPatient-order-summary-sections"
        data-testid="inPatient-order-summary-sections-testId"
      >
        {!isFromStockSupplies && (
          <PatientInformationReview
            data={data.patientAndProduct!}
            isSummaryOrDetail={isSummaryOrDetail}
          />
        )}
        <DeliveryInformationReview
          data={data.deliveryInformation}
          isSummaryOrDetail={isSummaryOrDetail}
          isFromStockSupplies={isFromStockSupplies}
        />
        <SelectedSuppliesReview
          isSummaryOrDetail={isSummaryOrDetail}
          cartItemsData={cartItem}
        />
        <SalesPoOrderInfoReview
          data={data.salesPurchaseOrderInformation}
          isSummaryOrDetail={isSummaryOrDetail}
        />
        <ShippingAddressReview
          data={data.shippingAddress!}
          isSummaryOrDetail={isSummaryOrDetail}
        />
      </div>
    </div>
  );
};
