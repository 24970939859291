export interface IManageSiteStatus {
  id: string;
  title: string;
  detail: string;
  urlLink: string;
  linkText: string;
  messageType: MessageType;
  statusType: StatusType;
  isActive: boolean;
  applyToLogin: number | null;
  applyToFull: number | null;
  showToExternalUsers: boolean;
  showToInternalUsers: boolean;
  showToUnAuthenticatedUsers: boolean;
  applyTo?: String[];
  showTo?: String[];
}

export enum MessageType {
  INFORMATION = "Information",
  WARNING = "Warning",
  ALERT = "Alert",
}

export enum StatusType {
  MESSAGE = "message",
}
