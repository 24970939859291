import "./siteStatusActivation.css";
import { defaultAddSiteStatusData } from "../addSiteStatus.model";
import { AddSiteStatusValidator } from "../addSiteStatus.validator";
import { Grid, useMediaQuery } from "@mui/material";
import { CustomCheckBox } from "../../../../../core/checkBox/checkBox.component";
import { InputWithLabel } from "../../../../../core/inputWithLabel/inputWithLabel.component";
import { ValidationStatus } from "../../../../../core/interfaces/input.interface";
import { IAddSiteStatus } from "../addSiteStatus.interface";

export interface ISiteStatusActivation {
  data: IAddSiteStatus;
  Validator?: AddSiteStatusValidator;
  setData: Function;
}

export const SiteStatusActivation = ({
  data,
  setData,
}: ISiteStatusActivation) => {
  const isMobileScreen = useMediaQuery("(max-width:920px)");

  const handleActivationcheckBoxChange = async (e: any) => {
    setData({
      ...data,
      activation: {
        valid: ValidationStatus.VALID,
        isDefaultValid: e.target.checked,
        required: true,
        value: e.target.checked ? "yes" : "no",
      },
    });
  };
  return (
    <div
      className="site-status-activation-component"
      data-testid="site-status-activation-component"
    >
      <p
        className="site-status-activation-header"
        data-testid="site-status-activation-header"
      >
        Activation
      </p>
      <Grid container className="site-status-activation-grid-container">
        <Grid
          className="site-status-activation-grid-item"
          item
          xs={isMobileScreen ? 12 : 6}
        >
          <InputWithLabel
            error={data.statusType.valid === ValidationStatus.INVALID}
            id={data.statusType.componentId!}
            isRequired={false}
            label="When would you like the site status to be in effect?"
            labelClassName="site-status-activation-label"
            testId="site-status-activation-label"
          >
            <div
              className="site-status-activation-checkbox-div"
              data-testid="site-status-activation-checkbox-div"
            >
              <CustomCheckBox
                value={data.activation.value}
                checked={
                  data.activation.value
                    ? data.activation.value === "yes"
                    : false
                }
                handleChange={handleActivationcheckBoxChange}
                selectClassName="activationCheckBox"
                selectpropsClassName="activationCheckBoxRoot"
                labelClassName="activation-chkBoxDescriptionLabel"
                labelText="Activate this site status now"
                name="activation"
                testId="site-access-activation-checkBox-test"
              />
            </div>
          </InputWithLabel>
        </Grid>
      </Grid>
    </div>
  );
};
