import { useContext, useState } from "react";
import "./territoryDetails.css";
import {
  ProfileFormContext,
  ProfileFormContextType,
} from "../../../../../context/ProfileFormContext";
import { ExpressButton } from "../../../../../core/expressButton/expressButton.component";
import { LoadingSpinner } from "../../../../../core/loader/LoadingSpinner";
import { Popup } from "../../../../../core/popup/popup.component";
import { getSalesUsersForRegionForTerritories } from "../../../../../util/3meService";
import { makeCapitalEachWordInString } from "../../../../../util/utilityFunctions";
import { Navigator } from "../../../../helpAndSupport/Navigator/navigator.component";
import { ISaleManageUserListTableData } from "../../../salesManageUsers/salesManageUserTable/saleManageUsersListTable.interface";
import {
  ISalesPerson,
  ITerritoryDetailList,
} from "../territoryListTable/territoryListTable.interface";
import {
  ISalesData,
  ISalesPersonAPIData,
} from "./addTerritorySalePerson/addSalePersonDetailList/addSalePersonDetailList.interface";
import { AddTerritorySalesPersonContainer } from "./addTerritorySalePerson/addTerritorySalePersonContainer/addTerritorySalePersonContainer.component";
import { TerritoryDetailFacilityListTable } from "./territoryDetailFacilityListTable/territoryDetailFacilityListTable.component";
import "./territoryDetails.css";
import { useHistory, useLocation } from "react-router-dom";
import {
  RolesPermissionContextType,
  RolesPermissionContext,
} from "../../../../../context/RolesPermissionContext";
import {
  AuthContextType,
  AuthContext,
} from "../../../../../context/AuthContext";
import { saveAssignTerritories } from "../../../../../util/userService";
import { getDeepClone } from "../../../../../util/ObjectFunctions";
import { addSalesPersonMock } from "./addTerritorySalePerson/addSalePersonDetail/addSalePersonDetailMockData.model";
import { IManualAddSalesPerson } from "./addTerritorySalePerson/addSalePersonDetail/addSalePersonDetail.interface";
import { defaultManualAddSalesPerson } from "./addTerritorySalePerson/addSalePersonDetail/addSalePersonDetail.model";

export const TerritoryDetails = () => {
  const location: any = useLocation();
  const AuthObj = useContext<AuthContextType | null>(AuthContext);
  const [errorPopUp, setErrorPopUp] = useState(false);
  const [openpopup, setOpenpopup] = useState(false);
  const [openLoaderPopUp, setOpenLoaderPopUp] = useState(false);
  const [salesperonListApiResponse, setSalesperonListApiResponse] = useState<
    ISalesPersonAPIData[] | null
  >();

  const [salesMngUserData, setSalesMngUserData] = useState<ISalesData>(
    getDeepClone(addSalesPersonMock)
  );
  const [newAddedSalesPersonNames, setNewAddedSalesPersonNames] = useState<
    ISalesPerson[]
  >([]);

  const history = useHistory();
  const selectedTerritory: ITerritoryDetailList =
    location.state.selectedTerritory;
  const profileForm = useContext<ProfileFormContextType | null>(
    ProfileFormContext
  );
  const [dateValue, setDateValue] = useState<IManualAddSalesPerson>(
    getDeepClone(defaultManualAddSalesPerson)
  );

  const openPopUp = () => {
    if (
      AuthObj?.userProfile?.area &&
      permissionObj?.mappedRolesPermissionData?.IsProdManagerRole &&
      selectedTerritory?.region
    ) {
      getSalesPersonListForTheTerritory(selectedTerritory?.region?.toString()!);
    }
    if (AuthObj?.userProfile?.region) {
      getSalesPersonListForTheTerritory(AuthObj?.userProfile?.region);
    }
  };

  const handleCloseEmpty = () => {
    setOpenpopup(false);
  };

  const callAddSalesPerson = async () => {
    setOpenpopup(false);
    let territoryList: any[] = [];
    let territoryObj: any = {
      StartDate: dateValue.startDate.value,
      EndDate: dateValue.endDate.value,
      Reason: dateValue.reasonForAssignmentChange.value,
      TerritoryCode: selectedTerritory.territoryCode,
      TerritoryName: selectedTerritory.territoryName,
    };
    territoryList.push(territoryObj);

    let reqParam = {
      salesManagerUserName: AuthObj?.userProfile?.userName,
      salesPersonUserId: salesMngUserData?.userID,
      onlyAddTerritory: true,
      Territories: territoryList,
    };
    try {
      const assignTerritoryResponseObj: any = await saveAssignTerritories(
        reqParam
      );
      if (assignTerritoryResponseObj.succeeded) {
        setDateValue(getDeepClone(defaultManualAddSalesPerson));
        const newAddedSalesPersonNamesVar: ISalesPerson = {
          firstName: salesMngUserData?.firstName,
          lastName: salesMngUserData?.lastName,
          userId: salesMngUserData?.userID,
        };
        setNewAddedSalesPersonNames([
          ...newAddedSalesPersonNames,
          newAddedSalesPersonNamesVar,
        ]);
        setErrorPopUp(false);
      } else {
        setNewAddedSalesPersonNames([]);
        setErrorPopUp(true);
      }
    } catch (error) {
      setErrorPopUp(true);
    }
  };

  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );

  const openSaleUserDetails = (
    salesPersonObj: ISalesPerson,
    data: ITerritoryDetailList
  ) => {
    let selectedSalePeople: ISaleManageUserListTableData = {
      userID: salesPersonObj.userId,
      firstName: salesPersonObj.firstName,
      lastName: salesPersonObj.lastName,
      roles: data.roles,
      region: data?.region!,
      territories: [data.territoryCode + "-" + data.territoryName],
    };
    history.push({
      pathname: "/administration/salesManageUsers/saleUserProfile",
      state: {
        selectedSalePeople: selectedSalePeople,
      },
    });
  };

  const getSalesPersonListForTheTerritory = async (regionCode: string) => {
    setOpenLoaderPopUp(true);
    const result: any = await Promise.all([
      getSalesUsersForRegionForTerritories(
        regionCode,
        selectedTerritory.roles[0],
        selectedTerritory.territoryCode
      ),
    ]);
    if (result[0].succeeded) {
      setOpenLoaderPopUp(false);
      setSalesperonListApiResponse(result[0].data);
      setOpenpopup(true);
    } else {
      setOpenLoaderPopUp(false);
      setSalesperonListApiResponse(null);
      setOpenpopup(true);
    }
  };

  return (
    <>
      <div className="territory-detail-component-container">
        <div
          className="territory-detail-component"
          data-testid="territory-detail-component"
        >
          <Navigator
            array={[
              {
                route: "/administration",
                pageName: "Administration",
              },
              {
                route: "/administration/manageTerritories",
                pageName: "View Territories",
              },
            ]}
            className="territory-detail-component-route-section"
            title="Territory Detail"
          />
          <div className="territory-detail-component-data">
            <span
              className="territory-detail-title"
              data-testid="territory-detail-titleId"
            >
              Territory Detail for{" "}
            </span>
            <span className="territory-detail-name-code">
              {selectedTerritory.territoryCode}
              {" - "} {selectedTerritory.territoryName}
            </span>
            <div className="territory-detail-add-block">
              <div>
                <div className="territory-detail-sale-profile">
                  {selectedTerritory.salesPeople.length > 0 ? (
                    selectedTerritory.salesPeople.map(
                      (salePerson: ISalesPerson) => {
                        return (
                          <>
                            <div className="territory-detail-salePerson">
                              Salesperson
                            </div>
                            <div className="territory-detail-sale-personName">
                              <div
                                className="click-sale-person"
                                onClick={() =>
                                  openSaleUserDetails(
                                    salePerson,
                                    selectedTerritory
                                  )
                                }
                              >
                                {makeCapitalEachWordInString(
                                  salePerson.lastName
                                )}
                                ,{" "}
                                {makeCapitalEachWordInString(
                                  salePerson.firstName
                                )}
                              </div>
                            </div>
                          </>
                        );
                      }
                    )
                  ) : (
                    <>
                      <div className="territory-detail-salePerson">
                        Salesperson
                      </div>
                      <div
                        data-testid="territory-detail-sale-personName-openId"
                        className="territory-detail-sale-personName-open"
                      >
                        OPEN
                      </div>
                    </>
                  )}
                </div>
                <div className="add-sales-container">
                  {newAddedSalesPersonNames?.map((person: ISalesPerson) => (
                    <>
                      <div className="territory-detail-add-salePerson">
                        Salesperson
                      </div>
                      <div
                        className="territory-detail-sale-personName"
                        key={person.userId}
                        onClick={() =>
                          openSaleUserDetails(person, selectedTerritory)
                        }
                      >
                        {makeCapitalEachWordInString(person.lastName)},{" "}
                        {makeCapitalEachWordInString(person.firstName)}
                      </div>
                    </>
                  ))}
                </div>
              </div>
              <div>
                <ExpressButton
                  disabled={
                    permissionObj?.mappedRolesPermissionData?.IsSupportRole!
                  }
                  children="Add Salesperson"
                  variant="outlined"
                  clickHandler={openPopUp}
                  testId="addSalesPerson"
                  parentClass="territory-detail-addSaleBtn"
                ></ExpressButton>
              </div>
            </div>
            <div className="territory-detail-fac-title">
              Facilities in this territory
            </div>
            <div className="territory-detail-users-table">
              <TerritoryDetailFacilityListTable
                selectedTerritory={selectedTerritory}
              />
            </div>
          </div>
        </div>
        <Popup
          openFlag={openpopup}
          closeHandler={handleCloseEmpty}
          dialogParentClass={"empty-pop-up"}
        >
          <AddTerritorySalesPersonContainer
            setOpenpopup={setOpenpopup}
            salesperonListApiResponse={salesperonListApiResponse!}
            callAddSalesPerson={callAddSalesPerson}
            salesMngUserData={salesMngUserData}
            setSalesMngUserData={setSalesMngUserData}
            dateValue={dateValue}
            setDateValue={setDateValue}
          />
        </Popup>
        <Popup
          openFlag={openLoaderPopUp}
          closeHandler={() => setOpenLoaderPopUp(false)}
          dialogParentClass={"territory-fetch-users-loader-pop-up"}
          data-testid="territory-detail-pop-up"
          hideCloseButton={true}
        >
          <div className="territory-fetch-users-loader">
            <LoadingSpinner />
          </div>
        </Popup>
      </div>
    </>
  );
};
