
import {
  defaultDeliveryInformation,
  defaultSalesPurchaseOrderNumberData,
  defaultShippingAddressData,
} from "../../inpatientSupplyOrder/inpatientSupplyOrder.data";
import { IPatient } from "../../myPatients/patient.interface";
import { ISupplyOrderInfoMapper } from "../../myPatients/patientAndTherapyDetails/supplyOrderDetails/supplyOrderResponseInterface";
import { IInpatientSupplyOrderDetails } from "../facilitySupplyOrders.interface";

export const defaultSupplyOrderInfoMapper: ISupplyOrderInfoMapper = {
  productList: [],
  orderedDate: "",
  shippedDate: "",
  deliveredDate: "",
  orderedFlag: "",
  shippedFlag: "",
  deliveredFlag: "",
  trackingNumber: "",
  trackingLink: "",
  dressingChangeFrequency: "",
  reSupplyJustification: "",
  shipAddress1: "",
  shipAddress2: "",
  shipCity: "",
  shipState: "",
  shipZipCode: "",
};

export const defaultPatientData: IPatient = {
  roNumber: 0,
  orderID: "",
  type: "",
  patientLocation: "",
  firstName: "",
  lastName: "",
  dob: "",
  facilityName: "",
  isAcuteOrder: false,
  orderCreationDate: "",
  patientId: null,
  alerts: [],
  status: "",
  statusColor: null,
  productName: "",
  placementDate: "",
  productSerialNumber: "",
  isAcuteOrderFailed: true,
  isSubmitWoundFailed: false,
};
export const inpatientSupplyOrderDetailsModelData: IInpatientSupplyOrderDetails = {
  orderInformation: {
    patientData: defaultPatientData,
    supplyOrderTrackingData: defaultSupplyOrderInfoMapper,
    selectedSupplyOrderData: {
      createdDate: "",
      shippedDate: "",
      initiatedName: "",
      product: "",
      ropn: "",
      status: "",
      statusColor: "",
      deliveredDate: "",
    },
  },
  deliveryInformation: defaultDeliveryInformation,
  shippingAddress: defaultShippingAddressData,
  salesInformation: defaultSalesPurchaseOrderNumberData,
  suppliesOrdered: {
    cartItems: [
      {
        categoryID: "",
        image: "",
        inventoryID: 0,
        maxAvailableQuantity: 0,
        packageType: "",
        price: 0,
        productFamily: [],
        productName: "",
        quantity: 0,
        size: "",
        sku: "",
        supplyType: "",
        totalPrice: 0,
      },
      {
        categoryID: "",
        image: "",
        inventoryID: 0,
        maxAvailableQuantity: 0,
        packageType: "",
        price: 0,
        productFamily: [],
        productName: "",
        quantity: 4,
        size: "",
        sku: "",
        supplyType: "",
        totalPrice: 0,
      },
    ],
    invalidQuantity: false,
    totalCartPrice: 0,
    totalQuantity: 0,
    shippingTotal: 0,
  },
};
