import { memo } from "react";
import { AgGridReact } from "ag-grid-react";
import { agGridGetRowStyle } from "../../../../util/utilityFunctions";
import { ColDef } from "ag-grid-enterprise";
import { IInPatientSupplyOrderProduct } from "../../inpatientSupplyOrder.interface";

const defaultColDef = {
  sortable: true,
  unSortIcon: true,
  suppressMenu: true,
  autoHeight: true,
};

interface IMemoedAgGridProps {
  filterProductsList: IInPatientSupplyOrderProduct[];
  columnDefs: ColDef[];
}

const MemoedAgGrid = ({
  filterProductsList,
  columnDefs,
}: IMemoedAgGridProps) => {
  return (
    <AgGridReact
      rowData={filterProductsList}
      columnDefs={columnDefs}
      getRowStyle={agGridGetRowStyle}
      defaultColDef={defaultColDef}
      domLayout={filterProductsList.length === 0 ? "normal" : "autoHeight"}
      suppressRowClickSelection={true}
      suppressDragLeaveHidesColumns={true}
      rowHeight={52}
    />
  );
};

export const MemoedAgGridTable = memo(MemoedAgGrid);
