import { useContext, useState } from "react";
import {
  AdminMyListsContextType,
  AdminMyListsContext,
} from "../../../../../context/AdministrationMyListsContext";
import "./myListAddHCPFound.css";
import {
  InternalSignOnContextType,
  InternalSignOnContext,
} from "../../../../../context/InternalSignOnContext";
import { MobileDisplayContext } from "../../../../../context/MobileDisplayContext";
import { ExpressButton } from "../../../../../core/expressButton/expressButton.component";
import { LoadingSpinner } from "../../../../../core/loader/LoadingSpinner";
import { makeCapitalEachWordInString } from "../../../../../util/utilityFunctions";
import { AddFacilityContext } from "../../../../manageProfile/facilityInformation/addFacilityContainer/addFacilityContainer.context";
import { SearchSection } from "../../../../manageProfile/facilityInformation/addFacilityContainer/addFacilityContainer.enum";
import { IFacility } from "../../../../manageProfile/facilityInformation/facility.interface";
import { IFacilityFound } from "../../../../manageProfile/facilityInformation/facilityFound/facilityFound.interface";
import { Typography } from "@mui/material";

export const MyListAddHcpFound = ({
  facilities,
  redirectHandler,
}: IFacilityFound) => {
  const { closePopup, addFacilityToList } = useContext(AddFacilityContext);
  const [loadSpinner, setLoadSpinner] = useState<boolean>(false);
  const internalObj = useContext<InternalSignOnContextType | null>(
    InternalSignOnContext
  );
  const { isMobileScreen } = useContext(MobileDisplayContext);
  const myListObj = useContext<AdminMyListsContextType | null>(
    AdminMyListsContext
  );

  const addFacilityHandler = async (e: any, facility: IFacility) => {
    addFacilityToList(facility);
  };

  const renderedFilterList = () => {
    const filteredData = facilities.filter((searchedFacility: IFacility) => {
      return myListObj?.hcpTabList.every(
        (tabItem: any) => tabItem.caregiverId !== searchedFacility.careGiverId
      );
    });
    return filteredData;
  };

  function renderFacilities() {
    return (
      Array.isArray(renderedFilterList()) &&
      renderedFilterList().map((item, index) => {
        return (
          <div
            key={index}
            className={
              index % 2 === 0 ? "facility-card-even" : "facility-card-odd"
            }
          >
            <div
              className="select-facility"
              data-testid="myList-hcp-select-facility"
              onClick={(e) => addFacilityHandler(e, item)}
            >
              Select
            </div>
            <div>
              <div
                className="facility-name"
                data-testid="myList-hcp-facility-name"
              >
                {makeCapitalEachWordInString(item.accountName)}
              </div>
              <div className="facility-details-view">
                <div
                  className="facility-details"
                  data-testid="myList-hcp-facility-address"
                >
                  {makeCapitalEachWordInString(item.address1)}
                  {item.address2 && item.address2 !== ""
                    ? `${", " + makeCapitalEachWordInString(item.address2)}`
                    : ""}
                </div>
                {!isMobileScreen && (
                  <div
                    className="facility-details-facility-id-label"
                    data-testid="myList-hcp-facility-phone"
                  >
                    Phone
                    <span className="facility-details-facility-Id">
                      {item.phoneNo && item.phoneNo !== "" ? item.phoneNo : ""}
                    </span>
                  </div>
                )}
              </div>
              <div className="facility-details-view">
                <div
                  className="facility-details"
                  data-testid="myList-hcp-facility-cityStateZip"
                >
                  {makeCapitalEachWordInString(item.city)}, {item.state}{" "}
                  {item.zip}
                </div>
                {!isMobileScreen && (
                  <div
                    className="facility-details-facility-id-label"
                    data-testid="myList-hcp-facility-type"
                  >
                    Type
                    <span className="facility-details-facility-Id">
                      {makeCapitalEachWordInString(
                        item.typeName && item.typeName !== ""
                          ? item.typeName
                          : ""
                      )}
                    </span>
                  </div>
                )}
                {isMobileScreen && (
                  <div className="myList-hcp-mobile-screen">
                    <div className="facility-details-facility-id-label">
                      Phone
                      <span className="facility-details-facility-Id">
                        {item.phoneNo && item.phoneNo !== ""
                          ? item.phoneNo
                          : ""}
                      </span>
                    </div>
                    <div className="facility-details-facility-id-label">
                      Type
                      <span className="facility-details-facility-Id">
                        {makeCapitalEachWordInString(
                          item.typeName && item.typeName !== ""
                            ? item.typeName
                            : ""
                        )}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      })
    );
  }
  return (
    <>
      {loadSpinner && (
        <div className="loader">
          <LoadingSpinner />
        </div>
      )}
      {!loadSpinner && (
        <div className="myList-hcp-search-list-component">
          <div
            className="search-results"
            data-testid="myList-hcp-facility-found"
          >
            Facilities Search Results
          </div>
          <div className="grid-container">
            {renderedFilterList().length === 0 ? (
              <div className="myList-hcp-already-added">
                <Typography>Home Care Provider Already Added</Typography>
              </div>
            ) : (
              <div className="grid-container-list">{renderFacilities()}</div>
            )}
          </div>

          <div className="back-to-search-btn-container">
            <ExpressButton
              variant="outlined"
              parentClass="back-to-search-btn"
              clickHandler={() => redirectHandler(SearchSection.SEARCH_FORM)}
            >
              Back to Search
            </ExpressButton>
          </div>
        </div>
      )}
    </>
  );
};
