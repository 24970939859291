import {
  Box,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import "./contributingCause.css";
import {
  convertStringToDate,
  getCodeFromText,
  getTextFromCode,
} from "../../../util/utilityFunctions";
import { format } from "react-string-format";
import React, { useContext, useEffect, useState } from "react";
import { NewOrderValidator } from "../newOrder.validator";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { IContributingCause } from "./contributingCause.interface";
import { getdropDownContent } from "../../../util/dropDownService";
import {
  DD_ACCIDENT_TYPE_CONTENT,
  DD_WOUND_LOCATION,
} from "../../../util/staticText";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { ValidationStatus } from "../../../core/interfaces/input.interface";
import { ReactComponent as CalendarIcon } from "../../../assets/calendar.svg";
import { ReactComponent as SelectIcon } from "../../../assets/selectIcon.svg";
import { ReactComponent as RadioButtonIcon } from "../../../assets/radioButton.svg";
import { InputWithLabel } from "../../../core/inputWithLabel/inputWithLabel.component";
import { ReactComponent as SelectedRadioButtonIcon } from "../../../assets/selectedRadioButton.svg";
import { ContributingCauseReviewOrder } from "./reviewOrder/contributingCauseReviewOrder.component";
import {
  NewOrderContext,
  NewOrderContextType,
} from "../../../context/NewOrderContext";
import { MobileDisplayContext } from "../../../context/MobileDisplayContext";
import { ERROR_TO_SELECT_ANY_OPTION } from "../../../util/errorMsg";
import { CustomDropDown } from "../../../core/customDropdown/customDropdown.component";

export const ContributingCause = ({
  data,
  Validator = new NewOrderValidator(),
  setData,
  isReviewOrder = false,
  editButtonClicked,
  isOrderSummary = false,
}: IContributingCause) => {
  const [validator] = React.useState<NewOrderValidator>(Validator!);
  const [focusClasses, setFocusClasses] = useState({ dateOfAccident: "" });
  const [accidentTypes, setAccidentTypes] = React.useState([]);
  const [accidentTypesText, setAccidentTypesText] = React.useState([]);
  const [active, setActive] = React.useState<boolean | null>(null);
  const { isMobileScreen } = useContext(MobileDisplayContext);
  const newOrderObj = useContext<NewOrderContextType | null>(NewOrderContext);
  const [woundLocations, setWoundLocations] = useState([]);
  const [woundLocationsText, setWoundLocationsText] = useState([]);
  const [pwtMenuItems, setPWTMenuItems] = useState<string[]>();

  useEffect(() => {
    fetchDropDownContent();
    const keys = newOrderObj?.woundQuestionaries?.keys();
    if (keys) {
      const menuItems: any = Array.from(keys).sort((a, b) => (a > b ? 1 : -1));
      setPWTMenuItems(menuItems);
    }
  }, [newOrderObj?.woundQuestionaries]);

  useEffect(() => {
    if (
      data &&
      (data.contributingCause.value === "yes" ||
        data.contributingCause.value === "no")
    ) {
      setActive(data.contributingCause.value === "yes" ? true : false);
    }
  }, [data.contributingCause]);

  const fetchDropDownContent = async () => {
    try {
      const ddContent = format(
        "{0},{1}",
        DD_ACCIDENT_TYPE_CONTENT,
        DD_WOUND_LOCATION
      );
      const data = await getdropDownContent(ddContent);
      if (data.items.length > 0) {
        const accidentTypeObject = data.items.filter(
          (item: { name: string }) => item.name === DD_ACCIDENT_TYPE_CONTENT
        );
        const accidentTypeArray = accidentTypeObject[0].data.sort(
          (a: { order: number }, b: { order: number }) =>
            a.order > b.order ? 1 : -1
        );
        setAccidentTypes(accidentTypeArray);
        setAccidentTypesText(
          accidentTypeArray.map((x: { text: string }) => x.text)
        );
        const woundLocationObject = data.items.filter(
          (item: { name: string }) => item.name === DD_WOUND_LOCATION
        );
        const woundLocationeData = woundLocationObject[0].data.sort(
          (a: { order: number }, b: { order: number }) =>
            a.order > b.order ? 1 : -1
        );
        setWoundLocations(woundLocationeData);
        setWoundLocationsText(
          woundLocationeData.map((x: { text: string }) => x.text)
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const validateAndSetData = (e: any) => {
    newOrderObj?.setIsHandleChangeTriggered(true);
    let { name, value } = e.target;
    if (
      name === "contributingCause" &&
      value !== data.contributingCause.value
    ) {
      data.dateOfAccident = {
        ...data.dateOfAccident,
        errorMessage: null,
        required: value === "yes",
        valid: ValidationStatus.UNTOUCHED,
        value: "",
      };
      data.accidentType = {
        ...data.accidentType,
        errorMessage: null,
        required: value === "yes",
        valid: ValidationStatus.UNTOUCHED,
        value: "",
      };
    }
    if (name === "contributingCause") {
      if (value === "yes") {
        setActive(true);
      } else if (value === "no") {
        setActive(false);
      } else {
        setActive(null);
      }
    }
    if (e.target.name === "woundLocation") {
      value = getCodeFromText(woundLocations, e.target.value);
      newOrderObj?.setWoundInfoData({
        ...newOrderObj.woundInfoData,
        woundLocation: {
          ...newOrderObj.woundInfoData.woundLocation,
          value: value,
          valid: ValidationStatus.VALID,
        },
      });
    }
    if (e.target.name === "woundType") {
      newOrderObj?.setUpdateWoundTypeAdditionalQuestions(true);
      newOrderObj?.setWoundInfoData({
        ...newOrderObj.woundInfoData,
        woundType: {
          ...newOrderObj.woundInfoData.woundType,
          value: e.target.value,
          valid: ValidationStatus.VALID,
        },
      });
    }
    const isValid = validator.validate(value, name);
    setData({
      ...data,
      [name]: {
        ...Object(data)[name],
        errorMessage: null,
        valid: isValid!.status,
        value: value,
      },
    });
  };

  const validateAndSetDate = (date: string | null | undefined) => {
    newOrderObj?.setIsHandleChangeTriggered(true);
    const formattedDate = date === null ? "" : convertStringToDate(date);
    const isValid = validator.validate(formattedDate!, "dateOfAccident");
    setData({
      ...data,
      dateOfAccident: {
        ...data.dateOfAccident,
        errorMessage: null,
        value: formattedDate,
        valid: isValid!.status,
      },
    });
  };

  const setClasses = (e: any, classname: string) => {
    setFocusClasses(
      Object.assign({}, focusClasses, { [e.target.name]: classname })
    );
  };

  return (
    <div className="contributing-cause-component">
      {!isReviewOrder && (
        <div className="contributing-cause">
          <h2
            className="contributing-cause-title"
            data-testid="contributing-cause-title"
          >
            Wound Information
          </h2>
          <Box
            className="contributing-cause-box-container"
            sx={{ flexGrow: 1 }}
          >
            <Grid
              className="contributing-cause-grid-container"
              container
              spacing={2}
            >
              <Grid
                className="contributing-cause-grid-item"
                item
                xs={isMobileScreen ? 12 : 12}
              >
                <InputWithLabel
                  label="Wound Location"
                  isRequired={data?.woundLocation?.required}
                  error={
                    data?.woundLocation?.valid === ValidationStatus.INVALID
                  }
                  testId="wound-information-wound-location"
                  warningMessage={data?.woundLocation?.errorMessage}
                  id={data?.woundLocation?.componentId}
                  isShowWarning={true}
                  isDropdown={true}
                >
                  <CustomDropDown
                    handleChange={validateAndSetData}
                    menuItem={woundLocationsText}
                    name="woundLocation"
                    placeHolder="Wound Location"
                    selectpropsClassName={
                      data?.woundLocation?.value
                        ? "primary-select"
                        : "primary-type-placeholder"
                    }
                    selectClassName={
                      data?.woundLocation?.value
                        ? "wound-information-input"
                        : "placeHolder"
                    }
                    testId="wound-information-wound-location-value"
                    value={data?.woundLocation?.value}
                  />
                </InputWithLabel>
              </Grid>
              <Grid
                className="contributing-cause-grid-item"
                item
                xs={isMobileScreen ? 12 : 12}
              >
                <InputWithLabel
                  label={"Primary Wound Type"}
                  isRequired={true}
                  error={data?.woundType?.valid === ValidationStatus.INVALID}
                  testId="wound-type"
                  warningMessage={data?.woundType?.errorMessage}
                  isShowWarning={true}
                  id={data?.woundType?.componentId!}
                  isDropdown={true}
                >
                  <CustomDropDown
                    handleChange={validateAndSetData}
                    menuItem={pwtMenuItems}
                    name="woundType"
                    placeHolder={"Patient’s Primary Wound Type"}
                    selectpropsClassName={
                      data?.woundType?.value
                        ? "primary-select"
                        : "primary-type-placeholder"
                    }
                    selectClassName={
                      data?.woundType?.value
                        ? "primary-type-input"
                        : "placeHolder"
                    }
                    testId="wound-information-wound-type-select"
                    value={data?.woundType?.value}
                  />
                </InputWithLabel>
              </Grid>
              <Grid
                className="contributing-cause-grid-item"
                item
                xs={isMobileScreen ? 12 : 6}
              >
                <InputWithLabel
                  error={
                    data.contributingCause.valid === ValidationStatus.INVALID
                  }
                  id={data.contributingCause.componentId!}
                  isRequired={true}
                  isShowWarning={true}
                  label="Is wound a direct result of an accident?"
                  testId="contributing-cause-is-wound-from-accident"
                  warningMessage={
                    data.contributingCause.errorMessage
                      ? ERROR_TO_SELECT_ANY_OPTION
                      : ""
                  }
                >
                  <RadioGroup
                    name="contributingCause"
                    classes={{ root: "radioRoot" }}
                    onChange={validateAndSetData}
                    value={data?.contributingCause.value}
                  >
                    <FormControlLabel
                      classes={{
                        root:
                          data.contributingCause.valid ===
                          ValidationStatus.INVALID
                            ? "optionRoot-error"
                            : active === true
                            ? "optionRoot-active"
                            : "optionRoot",
                      }}
                      componentsProps={{
                        typography: {
                          classes: {
                            root:
                              active === true ? "optiontxtSelect" : "optiontxt",
                          },
                        },
                      }}
                      control={
                        <Radio
                          icon={<RadioButtonIcon />}
                          checkedIcon={<SelectedRadioButtonIcon />}
                        />
                      }
                      data-testid="contributing-cause-is-wound-from-accident-yes"
                      label="Yes"
                      value="yes"
                    />
                    <FormControlLabel
                      classes={{
                        root:
                          data.contributingCause.valid ===
                          ValidationStatus.INVALID
                            ? "optionRoot-error"
                            : active === false
                            ? "optionRoot-active"
                            : "optionRoot",
                      }}
                      componentsProps={{
                        typography: {
                          classes: {
                            root:
                              active === false
                                ? "optiontxtSelect"
                                : "optiontxt",
                          },
                        },
                      }}
                      control={
                        <Radio
                          icon={<RadioButtonIcon />}
                          checkedIcon={<SelectedRadioButtonIcon />}
                        />
                      }
                      data-testid="contributing-cause-is-wound-from-accident-no"
                      label="No"
                      value="no"
                    />
                  </RadioGroup>
                </InputWithLabel>
              </Grid>
            </Grid>
          </Box>
          {active && active === true && (
            <Box
              className="contributing-cause-box-container"
              sx={{ flexGrow: 1 }}
            >
              <Grid
                className="contributing-cause-grid-container"
                container
                spacing={2}
              >
                <Grid
                  className="contributing-cause-grid-item"
                  item
                  xs={isMobileScreen ? 12 : 4}
                >
                  <InputWithLabel
                    error={
                      data.dateOfAccident.valid === ValidationStatus.INVALID
                    }
                    id={data.dateOfAccident.componentId!}
                    isRequired={true}
                    isShowWarning={true}
                    label={data.dateOfAccident.title!}
                    labelClassName={focusClasses.dateOfAccident}
                    testId="contributing-cause-date-of-accident"
                    warningMessage={data.dateOfAccident.errorMessage}
                  >
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        components={{ OpenPickerIcon: CalendarIcon }}
                        InputAdornmentProps={{
                          classes: {
                            root: "adornedRoot",
                          },
                        }}
                        InputProps={{
                          classes: {
                            root: `dateOfAccident ${
                              data?.dateOfAccident.valid ===
                              ValidationStatus.INVALID
                                ? "showError"
                                : "noError"
                            }`,
                            input: "input",
                            notchedOutline: "outline",
                          },
                        }}
                        onChange={(value) => validateAndSetDate(value)}
                        renderInput={(params) => {
                          params.error = false;
                          params.inputProps!.placeholder = "__/__/____";
                          return (
                            <TextField
                              id={data.dateOfAccident.id!}
                              name="dateOfAccident"
                              onFocus={(e) => setClasses(e, "Mui-focused")}
                              onBlur={(e) => setClasses(e, "")}
                              {...params}
                            />
                          );
                        }}
                        value={data.dateOfAccident.value}
                      />
                    </LocalizationProvider>
                  </InputWithLabel>
                </Grid>
                <Grid item xs={isMobileScreen ? 12 : 8}>
                  <InputWithLabel
                    error={
                      data?.accidentType.valid === ValidationStatus.INVALID
                    }
                    id={data.accidentType.componentId!}
                    isRequired={true}
                    isShowWarning={true}
                    label={data.accidentType.title!}
                    testId="contributing-cause-accident-type"
                    warningMessage={data.accidentType.errorMessage}
                  >
                    <Select
                      classes={{
                        select: data.accidentType.value
                          ? "selectAccidentType"
                          : "placeholder",
                        icon: "selectIcon",
                      }}
                      className={
                        data.accidentType.value
                          ? "selectRootAccidentType"
                          : "placeholder"
                      }
                      displayEmpty={true}
                      IconComponent={SelectIcon}
                      inputProps={{ "data-testid": "selectAccidentType" }}
                      name="accidentType"
                      onChange={validateAndSetData}
                      renderValue={(value) =>
                        value?.length ? value : "Select Type"
                      }
                      sx={{ width: "100%" }}
                      value={
                        data.accidentType.value
                          ? getTextFromCode(
                              accidentTypes,
                              data?.accidentType?.value
                            )
                          : ""
                      }
                      variant="outlined"
                    >
                      {accidentTypes &&
                        Array.isArray(accidentTypes) &&
                        accidentTypesText.map((x: string) => (
                          <MenuItem value={getCodeFromText(accidentTypes, x)}>
                            {x}
                          </MenuItem>
                        ))}
                    </Select>
                  </InputWithLabel>
                </Grid>
              </Grid>
            </Box>
          )}
        </div>
      )}
      {isReviewOrder && (
        <ContributingCauseReviewOrder
          accidentTypes={accidentTypes}
          data={data}
          editButtonClicked={editButtonClicked}
          isOrderSummary={isOrderSummary}
        />
      )}
    </div>
  );
};
