import "./billingAddressReviewOrder.css";
interface Props {
  address: string;
  editButtonAction: any;
  isSummaryOrder: boolean;
}

export const BillingAddressReviewOrder = ({
  address,
  editButtonAction,
  isSummaryOrder = false,
}: Props) => {
  const showTitleAndValue = (
    title: string,
    value: string | null,
    testId: string
  ) => {
    return (
      <div
        className="show-title-and-value-div"
        id="id_show-title-and-value-div"
      >
        <p
          className="show-title-and-value-title"
          data-testid={`${testId}-title`}
          id={`${testId}-title`}
        >
          {title}
        </p>
        <p
          className="show-title-and-value-value"
          data-testid={`${testId}-value`}
          id={`${testId}-value`}
        >
          {value && value !== "" ? value.trim() : "--"}
        </p>
      </div>
    );
  };

  return (
    <div className="billing-address-review-component">
      <div className="billing-address-review-header">
        <p
          className="billing-address-review-title"
          data-testid="billing-address-review-title"
          id="billing-address-review-title"
        >
          Billing Address
        </p>
        {!isSummaryOrder && (
          <button
            className="billing-address-review-edit-button"
            data-testid="billing-address-review-edit-button"
            id="billing-address-review-edit-button"
            onClick={() =>
              editButtonAction("in-patient-order-billing-address-component")
            }
          >
            Edit
          </button>
        )}
      </div>
      {showTitleAndValue("Billing Address", address, "billing-address")}
    </div>
  );
};
