import { Box, Button, Grid } from "@mui/material";
import { makeCapitalEachWordInString } from "../../util/utilityFunctions";
import { ExpressButton } from "../expressButton/expressButton.component";
import { IFacilityFoundList } from "./caregiverFacilitySearchAndAdd.model";
import { InputWithLabel } from "../inputWithLabel/inputWithLabel.component";
import quantityplusactive from "../../assets/quantityplusactive.svg";

export const CaregiverFacilityFoundList = ({
  facilities,
  handleSelect,
  handleBackToSearch,
  handleAddNewProvider,
}: IFacilityFoundList) => {
  function renderFacilities() {
    return facilities.map((item, index) => {
      return (
        <div
          key={index}
          className={index % 2 === 0 ? "fl-even-card" : "fl-odd-card"}
        >
          <div className="fl-select" onClick={() => handleSelect(item)}>
            Select
          </div>
          <div className="fl-facility-info">
            <div className="fl-facility-name" data-testid="fl-facility-name">
              {makeCapitalEachWordInString(item.customerName)}
            </div>
            <div className="fl-facility-details-container">
              <div className="fl-facility-details-block">
                <div className="fl-facility-details" data-testid="address1">
                  {makeCapitalEachWordInString(item.address1)}{" "}
                  {makeCapitalEachWordInString(item.address2)}
                </div>
                <div className="fl-facility-details" data-testid="address3">
                  {makeCapitalEachWordInString(item.city)}, {item.state},{" "}
                  {item.postalCode}
                </div>
              </div>
              <div className="fl-type-container">
                <div className="fl-phone-container">
                  <div className="fl-type-header">Phone</div>
                  <div className="fl-type-name" data-testid="facility-phone">
                    {item.phoneNo}
                  </div>
                </div>
                <div className="fl-phone-container">
                  <div className="fl-type-header">Type</div>
                  <div className="fl-type-name" data-testid="facility-type">
                    {makeCapitalEachWordInString(
                      item.marketingSegmentDescription
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  return (
    <div className="fl-list-container">
      <div className="fl-list-header" data-testid="fl-list-header">
        Facilities Search Results
      </div>
      {renderFacilities()}
      <ExpressButton
        variant="outlined"
        parentClass="fl-back-to-search-btn"
        clickHandler={handleBackToSearch}
      >
        <div className="fl-back-to-search-label">Back to Search</div>
      </ExpressButton>
      <div className="addnewhomecareprovider">
        <Box
          className="add-new-homecare-provider-box-container"
          sx={{ flexGrow: 1 }}
        >
          <Grid
            className="add-new-homecareprovider-grid-container"
            container
            spacing={2}
          >
            <Grid className="add-new-homecareprovider-item" item xs={12}>
              <InputWithLabel>
                <Button
                  classes={{ root: "add-new-homecareprovider-button" }}
                  data-testid="add-new-homecareprovider-button"
                  onClick={handleAddNewProvider}
                >
                  <img src={quantityplusactive} alt={quantityplusactive}></img>
                  <p className="txtAlign">Add New Home Care Provider</p>
                </Button>
              </InputWithLabel>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
};
