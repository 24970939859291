import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { ReactComponent as CalendarIcon } from "../../../assets/calendar.svg";
import { ReactComponent as RadioButtonIcon } from "../../../assets/radioButton.svg";
import { ReactComponent as SelectedRadioButtonIcon } from "../../../assets/selectedRadioButton.svg";
import { CustomDropDown } from "../../../core/customDropdown/customDropdown.component";
import { InputWithLabel } from "../../../core/inputWithLabel/inputWithLabel.component";
import { ValidationStatus } from "../../../core/interfaces/input.interface";
import { convertStringToDate } from "../../../util/utilityFunctions";
import { ExchangeVacValidator } from "../exchangeVac.validator";
import {
  IDeviceInformationExchangeVac,
  IExchangeVACProps,
} from "../exchangeVacUnit.interface";
import "./deviceInformationExchangeVac.css";
import { ExchangeVacRadioGroup } from "./exchangeVacRadioGroup.component";
import {
  ExchangeVacUnitContext,
  ExchangeVacUnitContextType,
} from "../../../context/ExchangeVacUnitContext";
export const DeviceInformationExchangeVac = ({
  data,
  setData,
  serviceTimes,
  serviceTimeText,
  serialNumbers,
  serialNumbersText,
  Validator = new ExchangeVacValidator(),
}: IDeviceInformationExchangeVac) => {
  const exchangeVacUnitObj = useContext<ExchangeVacUnitContextType | null>(
    ExchangeVacUnitContext
  );
  const isMobileScreen = useMediaQuery("(max-width:600px)");
  const [focusClasses, setFocusClasses] = useState({
    describeTheInjury: "describeTheInjury",
    describeTheProblem: "",
    timeOfService: "",
    serviceDate: "",
  });
  const isAcuteOrder = exchangeVacUnitObj?.patient?.isAcuteOrder;
  const placementDate = exchangeVacUnitObj?.patient?.placementDate;
  const setClasses = (e: any, classname: string) => {
    setFocusClasses(
      Object.assign({}, focusClasses, { [e.target.name]: classname })
    );
  };

  const validateAndSetData = (e: any) => {
    const isValid = Validator.validate(e.target.value, e.target.name);
    setData((data: IExchangeVACProps) => ({
      ...data,
      [e.target.name]: {
        value: e.target.value,
        valid: isValid?.status,
        required: true,
      },
    }));
  };

  const validateAndSetDate = (date: string | null | undefined) => {
    const formatteddate = convertStringToDate(date);
    const isValid = Validator.validate(formatteddate!, "serviceDate");
    setData({
      ...data,
      serviceDate: {
        value: formatteddate,
        valid: isValid!.status,
        required: true,
      },
    });
  };

  const validateAndSetRadioGroupData = (e: any) => {
    const isValid = Validator.validate(e.target.value, e.target.name);
    const tempData = {
      ...data,
      [e.target.name]: {
        ...Object(data)[e.target.name],
        errorMessage: null,
        valid: isValid!.status,
        value: e.target.value,
      },
    };
    setData(tempData);
  };

  useEffect(() => {
    if (
      data.injuryCauseBy3MDevice.value === "Y" ||
      data.problemWith3MDevice.value === "Y"
    ) {
      const tempData = {
        ...data,
        descriptionOfInjuryAndProblemWithTheDevice: {
          ...Object(data)["descriptionOfInjuryAndProblemWithTheDevice"],
          required: true,
        },
      };
      setData(tempData);
    } else if (
      data.injuryCauseBy3MDevice.value !== "Y" &&
      data.problemWith3MDevice.value !== "Y"
    ) {
      const tempData = {
        ...data,
        descriptionOfInjuryAndProblemWithTheDevice: {
          ...Object(data)["descriptionOfInjuryAndProblemWithTheDevice"],
          required: false,
          valid: ValidationStatus.UNTOUCHED,
          value: "",
        },
      };
      setData(tempData);
    }
  }, [data.injuryCauseBy3MDevice.value, data.problemWith3MDevice.value]);

  return (
    <div
      className="device-information-exchange-vac"
      data-testid="device-infor-exchange"
    >
      <h2
        className="device-information-exchange-vac-header"
        data-testid="device-infor-exchange-header"
      >
        Device Information
      </h2>
      <span
        className="device-information-exchange-vac-header-desc"
        data-testid="sub-sec-details"
      >
        Select New Serial Number and Service Date
      </span>
      <Grid
        className="device-informationdata-entry-grid-container"
        container
        spacing={2}
      >
        <Grid
          className="device-informationdata-entry-grid-item"
          item
          xs={isMobileScreen ? 12 : 8}
          data-testid="serial-number"
        >
          <InputWithLabel
            label="Serial Number"
            isRequired={data?.serialNumber.required}
            error={data?.serialNumber.valid === ValidationStatus.INVALID}
            isDropdown={true}
          >
            <CustomDropDown
              handleChange={validateAndSetData}
              menuItem={serialNumbersText}
              name="serialNumber"
              placeHolder="Select Serial Number"
              selectpropsClassName={
                data?.serialNumber.value !== ""
                  ? "device-informationdata-entry-select"
                  : "placeHolder"
              }
              selectClassName={
                data?.serialNumber?.value !== ""
                  ? "device-informationdata-entry-input"
                  : "placeHolder"
              }
              testId="searial-number"
              value={
                data?.serialNumber?.value !== ""
                  ? data?.serialNumber?.value
                  : null
              }
            />
          </InputWithLabel>
        </Grid>
        {!isMobileScreen && (
          <Grid
            className="device-informationdata-entry-grid-item"
            item
            xs={4}
            data-testid="service-time-tst"
          ></Grid>
        )}
        <Grid
          className="device-informationdata-entry-grid-item"
          item
          xs={isMobileScreen ? 6 : 4}
          data-testid="service-date"
        >
          <InputWithLabel
            label="Service Date"
            labelClassName={focusClasses.serviceDate}
            isRequired={data?.serviceDate?.required}
            error={data?.serviceDate?.valid === ValidationStatus.INVALID}
          >
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                minDate={
                  isAcuteOrder
                    ? moment(placementDate).toString()
                    : moment().subtract(366, "d").toString()
                }
                maxDate={
                  isAcuteOrder
                    ? moment().toString()
                    : moment().add(365, "d").toString()
                }
                InputAdornmentProps={{
                  classes: {
                    root: "adornedRoot1",
                  },
                }}
                InputProps={{
                  classes: {
                    root: `serviceDate ${
                      data?.serviceDate?.valid === ValidationStatus.INVALID
                        ? "showError"
                        : "noError"
                    }`,
                    input: "input",
                    notchedOutline: "outline",
                  },
                }}
                components={{ OpenPickerIcon: CalendarIcon }}
                value={data?.serviceDate?.value}
                onChange={(value) => validateAndSetDate(value)}
                renderInput={(params) => {
                  params.inputProps!.placeholder = "__/__/____";
                  return (
                    <TextField
                      name="serviceDate"
                      onFocus={(e) => setClasses(e, "Mui-focused")}
                      onBlur={(e) => setClasses(e, "")}
                      {...params}
                    />
                  );
                }}
              />
            </LocalizationProvider>
          </InputWithLabel>
        </Grid>
        <Grid
          className="device-informationdata-entry-grid-item"
          item
          xs={isMobileScreen ? 6 : 4}
          data-testid="service-time"
        >
          <InputWithLabel
            label="Time of Service"
            isRequired={true}
            error={data?.timeOfService?.valid === ValidationStatus.INVALID}
            isDropdown={true}
          >
            <CustomDropDown
              handleChange={validateAndSetData}
              menuItem={serviceTimeText}
              name="timeOfService"
              placeHolder="Select Time"
              selectpropsClassName={"device-informationdata-entry-select"}
              selectClassName={"device-informationdata-entry-input"}
              testId="service-time-number"
              value={
                data?.timeOfService?.value !== ""
                  ? data?.timeOfService?.value
                  : null
              }
            />
          </InputWithLabel>
        </Grid>
        <Grid
          className="device-information-exchange-vac-grid-item"
          item
          xs={12}
        >
          <InputWithLabel
            error={data.issueDesc.valid === ValidationStatus.INVALID}
            isRequired={true}
            label="Issue Description"
            labelClassName={focusClasses.describeTheInjury}
          >
            <TextField
              error={data.issueDesc.valid === ValidationStatus.INVALID}
              FormHelperTextProps={{ classes: { root: "helperText" } }}
              fullWidth
              InputProps={{
                inputProps: { maxLength: 125 },
                classes: {
                  root: "textarea-root",
                },
              }}
              multiline
              name="issueDesc"
              placeholder="Describe the issue with the device requiring the exchange"
              onBlur={(e) => setClasses(e, "")}
              onChange={validateAndSetData}
              onFocus={(e) => setClasses(e, "Mui-focused")}
              required={data.issueDesc.required}
              rows={3}
              value={data.issueDesc.value}
            />
          </InputWithLabel>
        </Grid>
      </Grid>

      <div className="device-information-exchange-vac-part-one">
        <Grid
          className="device-information-exchange-vac-grid-container"
          container
          spacing={2}
        >
          <Grid
            className="device-information-exchange-vac-grid-item"
            item
            xs={12}
          >
            <ExchangeVacRadioGroup
              fieldData={data.injuryCauseBy3MDevice}
              fieldKey={"injuryCauseBy3MDevice"}
              label={
                "Did the 3M device potentially cause or contribute to an injury?"
              }
              onChange={validateAndSetRadioGroupData}
            />
          </Grid>
          <Grid
            className="device-information-exchange-vac-grid-item"
            item
            xs={12}
          >
            <ExchangeVacRadioGroup
              fieldData={data.problemWith3MDevice}
              fieldKey={"problemWith3MDevice"}
              label={
                "Is there a problem with the functionality of the 3M device?"
              }
              onChange={validateAndSetRadioGroupData}
            />
          </Grid>
        </Grid>
      </div>
      <div className="device-information-exchange-vac-part-two">
        <Grid
          className="device-information-exchange-vac-grid-container"
          container
          spacing={2}
        >
          {(data.problemWith3MDevice.value === "Y" ||
            data.injuryCauseBy3MDevice.value === "Y") && (
            <Grid
              className="device-information-exchange-vac-grid-item"
              item
              xs={12}
            >
              <InputWithLabel
                error={
                  data.descriptionOfInjuryAndProblemWithTheDevice.valid ===
                  ValidationStatus.INVALID
                }
                isRequired={true}
                label="Describe the injury and/or the problem with the 3M device."
                labelClassName="device-info-header-title"
                testId="device-information-exchange-vac-describe-the-problem"
              >
                <TextField
                  error={
                    data.descriptionOfInjuryAndProblemWithTheDevice.valid ===
                    ValidationStatus.INVALID
                  }
                  FormHelperTextProps={{ classes: { root: "helperText" } }}
                  fullWidth
                  InputProps={{
                    inputProps: { maxLength: 125 },
                    classes: {
                      root: "textarea-problem-root",
                    },
                  }}
                  multiline
                  name="descriptionOfInjuryAndProblemWithTheDevice"
                  onBlur={(e) => setClasses(e, "")}
                  onChange={validateAndSetData}
                  onFocus={(e) => setClasses(e, "Mui-focused")}
                  required={
                    data.descriptionOfInjuryAndProblemWithTheDevice.required
                  }
                  rows={3}
                  value={data.descriptionOfInjuryAndProblemWithTheDevice.value}
                />
              </InputWithLabel>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
};
