import "./orderOption.css";
import parse from "html-react-parser";
import { IOrderOptionProps } from "./orderOption.interface";

export const OrderOption = ({onClick, option }: IOrderOptionProps) => {
  return (
    <div
      className={`order-option-component ${
        option.isDisabled ? "disabled" : ""
      }`}
      onClick={() => {
         if (!option.isDisabled) {
            onClick(option)
          }
        }
      }
    >
      <div className="order-option-title-div">
        <img className="order-option-icon" src={option.icon} alt={option.icon} />
        <p
          className={`order-option-title ${
            option.isDisabled ? "disabled" : ""
          }`}
          data-testid="order-option-title"
          id="order-option-title"
        >
          {option.title}
        </p>
      </div>
      <p className="order-option-description"  
         data-testid="order-option-description"
         id="order-option-description">
          {parse(option.description)}
      </p>
      <p className="new-feature" data-testid="new-feature" id="new-feature">
        {option.isNewFeature ? "coming in Fall 2024" : ""}
      </p>
    </div>
  );
};
