import "./manageSiteStatusesMobile.css";
import { IManageSiteStatus } from "../manageSiteStatuses.interface";
import { ReactComponent as RigthArrowIcon } from "../../../../assets/blueRigthArrow.svg";
import { makeCapitalEachWordInString } from "../../../../util/utilityFunctions";

interface Props {
  siteStatuses: IManageSiteStatus[];
  editButtonClick?: Function;
}

export const ManageSiteStatusesMobile = ({
  siteStatuses,
  editButtonClick,
}: Props) => {
  return (
    <div className="manage-site-statuses-for-mobile">
      {siteStatuses.length > 0 ? (
        siteStatuses.map((siteStatus, index) => {
          return (
            <div
              className={`site-status-card-container ${
                index % 2 === 0 ? "gray" : ""
              }`}
              key={index}
              onClick={() => {
                editButtonClick!(siteStatus);
              }}
            >
              <div className="site-status-card">
                <div className="site-status-detail-container">
                  <p
                    className="site-status-detail-message"
                    data-testid={`site-status-detail-title-${index}`}
                  >
                    {siteStatus.title}
                  </p>
                  <div className="site-status-detail-title-and-value">
                    <p
                      className="site-status-detail-title"
                      data-testid={`site-status-detail-title-apply-to-${index}`}
                    >
                      Apply to
                    </p>
                    <p
                      className="site-status-detail-value"
                      data-testid={`site-status-detail-value-apply-to-${index}`}
                    >
                      {siteStatus.applyTo ? siteStatus.applyTo : "-"}
                    </p>
                  </div>
                  <div className="site-status-detail-title-and-value">
                    <p
                      className="site-status-detail-title"
                      data-testid={`site-status-detail-title-show-to-${index}`}
                    >
                      Show to
                    </p>
                    <p
                      className="site-status-detail-value"
                      data-testid={`site-status-detail-value-show-to-${index}`}
                    >
                      {siteStatus.showTo ? siteStatus.showTo.join(", ") : "-"}
                    </p>
                  </div>
                  <div className="site-status-detail-title-and-value">
                    <p
                      className="site-status-detail-title"
                      data-testid={`site-status-detail-title-status-${index}`}
                    >
                      Status
                    </p>
                    <p
                      className="site-status-detail-value"
                      data-testid={`site-status-detail-value-status-${index}`}
                    >
                      {siteStatus.isActive ? "Active" : "Inactive"}
                    </p>
                  </div>
                  <div className="site-status-detail-title-and-value">
                    <p
                      className="site-status-detail-title"
                      data-testid={`site-status-detail-title-type-${index}`}
                    >
                      Type
                    </p>
                    <p
                      className="site-status-detail-value"
                      data-testid={`site-status-detail-value-type-${index}`}
                    >
                      {makeCapitalEachWordInString(siteStatus.statusType)}
                    </p>
                  </div>
                </div>
                <RigthArrowIcon
                  className="site-status-detail-container-arrow"
                  data-testid={`site-status-detail-container-arrow-${index}`}
                />
              </div>
            </div>
          );
        })
      ) : (
        <div className="no-site-status-div">
          <p
            className="no-site-status-message"
            data-testid="no-site-status-message"
          >
            No Site Statuses available
          </p>
        </div>
      )}
    </div>
  );
};
