import { useContext, useState } from "react";
import { ReactComponent as ThreeDotsIcon } from "../../../assets/patientAction.svg";
import { IconButton, Menu, MenuItem } from "@mui/material";
import {
  IInventoryMenuActionItem,
  IInventoryProductDetails,
  InventoryTabListingSection,
  SPMenuActionTypes,
  VacReadyCareMenuActionTypes,
} from "../inventoryTabContainer.enum";
import {
  RolesPermissionContext,
  RolesPermissionContextType,
} from "../../../context/RolesPermissionContext";
import { AuthContext, AuthContextType } from "../../../context/AuthContext";
import "../InventoryProductMenu/InventoryProductMenu.css";

interface IInventoryProductMenuProps {
  productData: IInventoryProductDetails;
  handleClickFunctionlity: Function;
  tabListingSection: InventoryTabListingSection;
}

const InventoryProductMenu = ({
  productData,
  handleClickFunctionlity,
  tabListingSection,
}: IInventoryProductMenuProps) => {
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );
  const [openMenuFlag, setOpenMenuFlag] = useState<boolean>(false);
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
  const menuOptions = productData!.menuItems ? productData.menuItems : [];

  const handleOpenActionMenu = (e: any) => {
    setOpenMenuFlag(!openMenuFlag);
    setAnchorElement(anchorElement ? null : e.currentTarget);
  };

  const handleCloseActionMenu = () => {
    setOpenMenuFlag(false);
    setAnchorElement(null);
  };

  const checkPermissionsForMoveUnit = (
    menuOption: IInventoryMenuActionItem
  ) => {
    let isEnableMenuOption: boolean = false;
    if (
      tabListingSection ===
        InventoryTabListingSection.STORED_PRODUCT_LISTING_SECTION ||
      tabListingSection ===
        InventoryTabListingSection.VAC_READY_CARE_PROGRAM_LISTING_SECTION
    ) {
      if (
        menuOption.sequenceText.toLowerCase() ===
          SPMenuActionTypes.MOVE_UNIT_VAC_READY_CARE.toLowerCase() ||
        menuOption.sequenceText.toLowerCase() ===
          VacReadyCareMenuActionTypes.MOVE_UNIT_TO_STORED_PRODUCT.toLowerCase()
      ) {
        if (
          (authObj?.isInternalUser &&
            permissionObj?.mappedRolesPermissionData.IsSupportRole) ||
          (!authObj?.isInternalUser &&
            !permissionObj?.mappedRolesPermissionData.IsFacilityAdminRole)
        ) {
          isEnableMenuOption = true;
        }
      }
    }
    return isEnableMenuOption;
  };

  return (
    <>
      {menuOptions.length > 0 && (
        <>
          <IconButton
            onClick={handleOpenActionMenu}
            data-testid="inventory-product-open-menu-button"
          >
            <ThreeDotsIcon />
          </IconButton>
          <Menu
            open={openMenuFlag}
            anchorEl={anchorElement}
            onClose={handleCloseActionMenu}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            data-testid="inventory-product-action-menu"
          >
            {menuOptions.map(
              (eachOption: IInventoryMenuActionItem) =>
                !checkPermissionsForMoveUnit(eachOption) && (
                  <MenuItem
                    className="inventory-menu-option"
                    key={eachOption.sequenceNo}
                    onClick={() => {
                      handleClickFunctionlity(
                        eachOption.sequenceText,
                        productData
                      );
                      handleCloseActionMenu();
                    }}
                    data-testid={"inventory-product-menu-item"}
                  >
                    {eachOption.sequenceText}
                  </MenuItem>
                )
            )}
          </Menu>
        </>
      )}
    </>
  );
};

export default InventoryProductMenu;
