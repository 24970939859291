import "./acuteOrderPickUpRequestFirstPage.css";
import { Navigator } from "../../helpAndSupport/Navigator/navigator.component";
import { IAcuteOrderPickUpRequestFirstPage } from "./acuteOrderPickUpRequestFirstPage.interface";
import { EnterPickupInformation } from "../components/enterPickupInformation/enterPickupInformation.component";
import { PatientDetails } from "../../pickUpAndDischargeRequest/pickUpRequest/patientDetails/patientDetails.component";
import { EstimatedArrivalTime } from "../../inventory/requestService/createServiceRequest/estimatedArrivalTime/estimatedArrivalTime.component";
import { DeviceComplications } from "../../inventory/requestService/createServiceRequest/deviceComplications/deviceComplications.component";
import moment from "moment";

export const AcuteOrderPickUpRequestFirstPage = ({
  data,
  patient,
  setData,
  storageLocations,
  isCustomerOwnedProduct = false,
}: IAcuteOrderPickUpRequestFirstPage) => {
  return (
    <div
      className="acute-pick-up-request-first-page-component"
      data-testId="acute-pick-up-request-first-page-component"
    >
      <div className="short-form">
        <Navigator
          array={[
            {
              route: "/home",
              pageName: "My Patients",
            },
          ]}
          className="acute-pick-up-request-route-section"
          title="Request Pickup/Discharge"
        />
        <p
          className="acute-pick-up-request-header"
          data-testid="acute-pick-up-request-header"
          id="acute-pick-up-request-header"
        >
          Request Pickup/Discharge
        </p>
        <PatientDetails
          isAcuteOrderFlow={patient?.isAcuteOrder!}
          patient={patient}
          isAcuteOrderPickUpDischargeFlow={true}
        />
        <EnterPickupInformation
          data={data}
          enterPickUpInformation={data.enterPickUpInformation.value}
          setData={setData}
          storageLocations={storageLocations}
          isCustomerOwnedProduct={isCustomerOwnedProduct}
          placementDate={moment(patient?.placementDate).toString()}
        />
        <EstimatedArrivalTime
          data={data}
          setData={setData}
          isFromAcuteOrderPickup={true}
          isCustomerOwnedProduct={isCustomerOwnedProduct}
        />
        <DeviceComplications data={data} setData={setData} />
      </div>
    </div>
  );
};
