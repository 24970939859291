import { useCallback, useEffect, useState } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import UploadIcon from "../../assets/upload.svg";
import OrderOverviewUploadDocument from "../../components/myPatients/patientAndTherapyDetails/orderOverviewDocumentUpload/orderOverviewUploadDocument.component";
import OrderUploadDocList from "../../components/newOrder/orderUploadDocument/orderUploadDocList.component";
import UploadPrescriptionList from "../../components/newOrder/submitPrescription/uploadPrescription/uploadPrescriptionList.component";
import { ExpressButton } from "../expressButton/expressButton.component";
import { customDocumentUploadError, getBase64 } from "./customDropZoneFunction";
import { ICustomDropZone, IScannedImageType } from "./dropZone.interface";
import { IDropZoneDocumentSelect } from "./dropZoneDocumentSelect.interface";
import CustomOrderUploadDocList from "../../components/newOrder/orderUploadDocument/customOrderUploadDocList/customOrderUploadDocList.component";
import { ValidationStatus } from "../interfaces/input.interface";
import { ScanBot } from "../../core/scanBot/scanBot.component";
import { Popup } from "../popup/popup.component";
import "./customDropZone.css";
import { arrayBufferToBase64, generateUUID } from "../../util/utilityFunctions";
import moment from "moment";
import { getKeyVaultContent } from "../../util/cmsService";
import { SCANBOT_LICENSE_NAME } from "../../util/keyVaultNames";
import NewOrderErrorPopupMessage from "../../components/newOrder/newOrderFooterGroup/newOrderErrorPopupMessage.component";
import { KEYVALUT_SERVICE_ERROR } from "../../util/errorMsg";
import Resizer from "react-image-file-resizer";

const CustomDropZone = ({
  data,
  setData,
  setDeletedData,
  dropZoneStyles,
  dragAndDropStyles,
  uploadWidgetCard,
  uploadIconStyle,
  buttonDropText,
  buttonDropClassName = "",
  dragDropText,
  listingType,
  maxFileSize,
  allowedFiles,
  singleFile,
  isTestingComponent = false,
  callUploadDocToIFace,
  uploadedData = [],
  documentTypeText,
  isScanBotVisible = false,
  additionalData = [],
  setAdditionalData,
  isToShowDocumentTypeDropDown = true,
}: ICustomDropZone) => {
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const [scanBotApp, setScanBotAPP] = useState<any>();

  const [openPopup, setOpenPopup] = useState(false);
  const [scannedImages, setScannedImages] = useState<IScannedImageType[] | []>(
    []
  );
  const [showerrorpopup, setShowerrorpopup] = useState(false);
  const [errorCode, setErrorCode] = useState("");
  const [scannedDocPageNumber, setScannedDocPageNumber] = useState<number>(0);
  const [savePdfClicked, setSavePDfClicked] = useState(false);
  let sdk: { dispose: () => void };

  const onDrop = useCallback(
    (accFiles: File[], rejFiles: FileRejection[]) => {
      const mappedAcc = accFiles.map((file: any) => ({ file, errors: [] }));
      setSelectedFiles([...mappedAcc, ...rejFiles]);
    },
    [data]
  );

  const fileNameValidator = (file: any) => {
    let doesFileAlreadyExists = null;
    if (
      (listingType === "newOrder" ||
        listingType === "orderOverview" ||
        listingType === "customUploadDoc") &&
      file.size !== 0
    ) {
      [...data, ...uploadedData].some((element: any) => {
        if (element.documentName === file.name) {
          return (doesFileAlreadyExists = {
            code: "duplicate-file",
            message: `File already exists`,
          });
        } else {
          return (doesFileAlreadyExists = null);
        }
      });
    } else if (file.size === 0) {
      return (doesFileAlreadyExists = {
        code: "empty-file",
        message: `File Has No Content`,
      });
    } else {
      return (doesFileAlreadyExists = null);
    }
    return doesFileAlreadyExists;
  };

  const fileNameValidatorWithMultiSection = (file: any) => {
    let doesFileAlreadyExists = null;
    if (file.size !== 0) {
      [...data, ...additionalData, ...uploadedData].some((element: any) => {
        if (element.documentName === file.name && element.succeeded) {
          return (doesFileAlreadyExists = {
            code: "duplicate-file",
            message: `File already exists`,
          });
        } else {
          return (doesFileAlreadyExists = null);
        }
      });
    } else if (file.size === 0) {
      return (doesFileAlreadyExists = {
        code: "empty-file",
        message: `File Has No Content`,
      });
    } else {
      return (doesFileAlreadyExists = null);
    }
    return doesFileAlreadyExists;
  };

  const { getRootProps, getInputProps, open, acceptedFiles, fileRejections } =
    useDropzone({
      accept: allowedFiles,
      maxSize: maxFileSize,
      onDrop,
      multiple: singleFile ? false : true,
      validator:
        listingType === "vacOrderDocuments" ||
        listingType === "vacOrderPrescriptionDocuments"
          ? fileNameValidatorWithMultiSection
          : fileNameValidator,
      maxFiles: singleFile ? 1 : 0,
      noClick: true,
      noKeyboard: true,
    });

  const vacOrderData = (element: any) => {
    return {
      documentName: element.file.name,
      documentBase64: "",
      succeeded: true,
      errorMessage: null,
      file: element.file,
      isFetchingBase64: false,
      documentType: {
        valid: ValidationStatus.UNTOUCHED,
        value: "",
        required: true,
      },
    };
  };

  useEffect(() => {
    let tempData: any = [];
    if (selectedFiles.length !== 0 && Array.isArray(selectedFiles)) {
      (async () => {
        await selectedFiles.forEach(async (element) => {
          if (element.errors.length === 0) {
            if (
              listingType === "customUploadDoc" ||
              listingType === "vacOrderDocuments"
            ) {
              tempData.push(vacOrderData(element));
            } else {
              tempData.push({
                documentName: element.file.name,
                documentBase64: "",
                succeeded: true,
                errorMessage: null,
                file: element.file,
                isFetchingBase64: false,
              });
            }
          } else {
            if (
              listingType === "customUploadDoc" ||
              listingType === "vacOrderDocuments"
            ) {
              tempData.push({
                documentName: element.file.name,
                documentBase64: "",
                succeeded: false,
                errorMessage: customDocumentUploadError(element.errors[0].code),
                file: element.file,
                isFetchingBase64: false,
                documentType: {
                  valid: ValidationStatus.UNTOUCHED,
                  value: "",
                  required: true,
                },
              });
            } else {
              tempData.push({
                documentName: element.file.name,
                documentBase64: "",
                succeeded: false,
                errorMessage: customDocumentUploadError(element.errors[0].code),
                file: element.file,
                isFetchingBase64: false,
              });
            }
          }
        });
      })();
      if (singleFile) {
        fetchBase64FoSelectedFiles(tempData);
        if (data.length === 0 && tempData.length === 1) {
          setData(tempData);
        }
      } else {
        setData((data: any) => [...data, ...tempData]);
      }
    }
  }, [selectedFiles]);

  useEffect(() => {
    fetchBase64FoSelectedFiles(data);
    if (!isTestingComponent) {
      setData(data);
    }
  }, [data]);

  const onDelete = (file: any, index: any) => {
    if (file.documentId && file.documentId !== "") {
      let selectFileForDeletion: any = [];
      selectFileForDeletion[0] = file.documentId;
      setDeletedData((deletedData: any) => [
        ...deletedData,
        ...selectFileForDeletion,
      ]);
    }
    setSelectedFiles((curr) =>
      curr.filter((fw) => selectedFiles.indexOf(fw) !== index)
    );
    setData((curr: any[]) => curr.filter((fw) => data.indexOf(fw) !== index));
  };

  // Used this for vac order flow where we have to compare multiple section images.
  const onDeleteWithFile = (file: any, index: any) => {
    if (file.documentId && file.documentId !== "") {
      let selectFileForDeletion: any = [];
      selectFileForDeletion[0] = file;
      setDeletedData((deletedData: any) => [
        ...deletedData,
        ...selectFileForDeletion,
      ]);
    }
    setSelectedFiles((curr) =>
      curr.filter((fw) => selectedFiles.indexOf(fw) !== index)
    );
    const mainArrayLength = data.length;
    const [tempData, addtionalTempData] = findDuplicateAndUpdateThemOnDelete(
      [...data, ...additionalData],
      file,
      index,
      mainArrayLength
    );
    setData(tempData);
    if (additionalData && setAdditionalData) {
      setAdditionalData(addtionalTempData);
    }
  };

  const displayDropZoneWidget = () => {
    let displayDZ = true;
    if (
      listingType === "newOrder" ||
      listingType === "orderOverview" ||
      listingType === "customUploadDoc" ||
      listingType === "vacOrderDocuments"
    ) {
      displayDZ = true;
    } else if (data.length !== 0) {
      displayDZ = false;
    }
    return displayDZ;
  };

  const fetchBase64FoSelectedFiles = (elements: IDropZoneDocumentSelect[]) => {
    if (Array.isArray(elements)) {
      elements.forEach((element: any) => {
        (async () => {
          if (
            !element.isFetchingBase64 &&
            element.succeeded &&
            element.documentBase64 === ""
          ) {
            element.isFetchingBase64 = true;
            element.documentBase64 = await getBase64(element.file);
            element.isFetchingBase64 = false;
          }
        })();
      });
    }
  };

  const displayUploadedDocument = (listingType: string | undefined) => {
    switch (listingType) {
      case "orderOverview":
        return (
          <OrderOverviewUploadDocument
            onDelete={onDelete}
            data={data}
            setData={setData}
            callUploadDocToIFace={callUploadDocToIFace!}
            documentTypeText={documentTypeText!}
          />
        );

      //We are using this listing type in WoundUpload and dischargeRequestUpload
      case "newOrder":
        return (
          <OrderUploadDocList
            onDelete={onDelete}
            data={data}
            setData={setData}
          />
        );

      case "prescriptionDoc":
        return <UploadPrescriptionList onDelete={onDelete} data={data} />;

      case "vacOrderPrescriptionDocuments":
        return (
          <UploadPrescriptionList onDelete={onDeleteWithFile} data={data} />
        );

      //We are using this listing type in VacOrder Upload document
      case "vacOrderDocuments":
        return (
          <CustomOrderUploadDocList
            data={data}
            documentTypeText={documentTypeText!}
            onDelete={onDeleteWithFile}
            setData={setData}
          />
        );

      case "customUploadDoc":
        return (
          <CustomOrderUploadDocList
            data={data}
            documentTypeText={documentTypeText!}
            onDelete={onDelete}
            setData={setData}
            isToShowDocumentTypeDropDown={isToShowDocumentTypeDropDown}
          />
        );

      default:
        break;
    }
  };

  const scanDocumentData = (element: any, pdfSize: number) => {
    let obj;
    //15728640
    if (pdfSize > 15728640) {
      obj = {
        documentName:
          "ScanBot_" +
          moment(new Date()).format("MMDDYYYY") +
          "_" +
          generateUUID() +
          ".pdf",
        documentBase64: "",
        succeeded: false,
        errorMessage: customDocumentUploadError("file-too-large"),
        file: null,
        isFetchingBase64: false,
        documentType: {
          valid: ValidationStatus.UNTOUCHED,
          value: "",
          required: true,
        },
      };
    } else {
      obj = {
        documentName:
          "ScanBot_" +
          moment(new Date()).format("MMDDYYYY") +
          "_" +
          generateUUID() +
          ".pdf",
        documentBase64: element,
        succeeded: true,
        errorMessage: null,
        file: null,
        isFetchingBase64: false,
        documentType: {
          valid: ValidationStatus.UNTOUCHED,
          value: "",
          required: true,
        },
      };
    }
    return obj;
  };

  let list: any = [];
  const documentDetected = async (resulta: any) => {
    if (resulta.success) {
      setScannedDocPageNumber([...scannedImages, ...list].length + 1);
      const bas64 = await scanBotApp?.toDataUrl(
        resulta.cropped ?? resulta.original
      );
      let scannedDoc: IScannedImageType = {
        page: [...scannedImages, ...list].length + 1,
        base64: bas64,
        original: resulta.cropped ?? resulta.original,
      };
      list.push(scannedDoc);
      const scannedImagesArray = [...scannedImages, ...list];
      if (scannedImages.length === 0) {
        setScannedImages(list);
      } else {
        setScannedImages(scannedImagesArray);
      }
    }
  };

  useEffect(() => {
    if (isScanBotVisible) {
      getlicense();
    }
  }, []);

  const getlicense = async () => {
    const body = {
      SecretNames: [SCANBOT_LICENSE_NAME],
    };
    const licenseResponse = await getKeyVaultContent(body);
    if (licenseResponse?.succeeded) {
      const licnese = await window.ScanbotSDK?.initialize({
        licenseKey: licenseResponse?.item[0].value,
      });
      setScanBotAPP(licnese);
    }
    //commenting for now since its appearing during loading the page
    // } else if (!licenseResponse || !licenseResponse?.succeeded) {
    //   setShowerrorpopup(false);
    //   setErrorCode(
    //     licenseResponse?.error?.errorCode || licenseResponse?.status
    //   );
    // }
  };

  const scanDocumentClick = async () => {
    setOpenPopup(true);
    setScannedImages([]);
    setScannedDocPageNumber(0);
  };

  const closePopup = () => {
    setOpenPopup(false);
  };

  const toArray = async (downscaledImage: Blob) =>
    new Uint8Array(await downscaledImage.arrayBuffer());

  async function generatePDF(pages: any[]) {
    const options = {
      standardPaperSize: "A4",
    };
    const generator = await scanBotApp?.beginPdf(options);
    for (const page of pages) {
      let image = page.original;
      await generator.addPage(image);
    }
    return await generator.complete();
  }

  //(BUG-39771-Pream)Commenting the downscale for now will use if required in later stages

  // const resizeFile = (file: Blob, width: number, height: number) =>
  //   new Promise((resolve) => {
  //     Resizer.imageFileResizer(
  //       file,
  //       width,
  //       height,
  //       "JPEG",
  //       80,
  //       0,
  //       (uri) => {
  //         resolve(uri);
  //       },
  //       "blob"
  //     );
  //   });

  // async function downscale(data: Uint8Array): Promise<any> {
  //   // In order to keep the aspect ratio, find the dimensions of the image we're saving
  //   const dimensions = await getDimensions(data);
  //   // Set 200 as a baseline for testing purposes. The image in the saved PDF should be proper potato quality
  //   const base_size = dimensions.width;
  //   // Find the width-based ratio
  //   const ratio = dimensions.width / dimensions.height;
  //   const blob = new Blob([data]);
  //   // Since we used width-based ratio, multiply max width by the ratio
  //   const image = await resizeFile(blob, base_size * ratio, base_size);
  //   return image;
  // }

  // async function getDimensions(data: Uint8Array): Promise<any> {
  //   return new Promise(async (resolve, reject) => {
  //     const image = new Image();
  //     image.onload = async () => {
  //       resolve({ width: image.width, height: image.height });
  //     };
  //     // The most accurate (albeit perhaps not most optimal)
  //     // way to get the actual width and height is to load it into an image object
  //     image.src = await scanBotApp?.toDataUrl(data);
  //   });
  // }

  const handleSavePdfClick = async () => {
    if (!savePdfClicked) {
      setSavePDfClicked(true);
      const buffer = await generatePDF(scannedImages);
      const bas64 = await arrayBufferToBase64(buffer);
      const pdfSize = new Blob([buffer]);
      const scanDocumentDataResponse = scanDocumentData(bas64, pdfSize.size);
      let doc: any = [];
      doc.push(scanDocumentDataResponse);
      if (data.length === 0) {
        setData(doc);
      } else {
        setData((data: any) => [...data, ...doc]);
      }
      sdk?.dispose();
      setOpenPopup(false);
    }
  };

  const closeErrorPopup = () => {
    setShowerrorpopup(false);
  };

  const findDuplicateAndUpdateThemOnDelete = (
    array: IDropZoneDocumentSelect[],
    deletingFile: any,
    deletingFileIndex: number | null = null,
    mainArrayLength: number
  ): [IDropZoneDocumentSelect[], IDropZoneDocumentSelect[]] => {
    const sameFileIndices: number[] = [];
    const mainResultArray: IDropZoneDocumentSelect[] = [];
    const additionResultArray: IDropZoneDocumentSelect[] = [];
    array.forEach((element: IDropZoneDocumentSelect, index: number) => {
      if (mainArrayLength > index && deletingFileIndex !== null) {
        if (array.indexOf(element) !== deletingFileIndex) {
          const updateElement = validateForDuplicateFileName(
            deletingFile,
            element,
            index,
            sameFileIndices
          );
          mainResultArray.push(updateElement);
        }
      } else {
        const updateElement = validateForDuplicateFileName(
          deletingFile,
          element,
          index,
          sameFileIndices
        );
        additionResultArray.push(updateElement);
      }
    });
    return [mainResultArray, additionResultArray];
  };

  const validateForDuplicateFileName = (
    deletingFile: any,
    element: IDropZoneDocumentSelect,
    index: number,
    sameFileIndices: number[]
  ): IDropZoneDocumentSelect => {
    if (element.documentName === deletingFile.documentName) {
      if (sameFileIndices.length === 0) {
        element.errorMessage = "";
        element.succeeded = true;
      } else {
        if (sameFileIndices.includes(index)) {
          element.errorMessage = "File Already Exists";
          element.succeeded = false;
        }
      }
      sameFileIndices.push(index);
    }
    return element;
  };

  return (
    <>
      {showerrorpopup && (
        <NewOrderErrorPopupMessage
          popUpStyles="scanbotErrorPopup"
          handleBackButton={closeErrorPopup}
          errorPopupFlag={true}
          errorMessage={KEYVALUT_SERVICE_ERROR}
          handleExitButton={closeErrorPopup}
          isSupportPhoneShow={true}
          errorCode={errorCode}
        />
      )}
      {displayDropZoneWidget() && (
        <div className={uploadWidgetCard}>
          <div {...getRootProps()} className={dropZoneStyles}>
            <input {...getInputProps()} />
            <div className={dragAndDropStyles} data-testid="drag-drop-id">
              <img
                className={uploadIconStyle}
                src={UploadIcon}
                alt="uploadIconAlt"
              />
              {dragDropText}
            </div>
            {isScanBotVisible ? (
              <div className="dropZone-Button-Main">
                <ExpressButton
                  parentClass={buttonDropClassName}
                  testId="drag-drop-button-id"
                  variant="outlined"
                  clickHandler={open}
                >
                  {buttonDropText}
                </ExpressButton>
                <ExpressButton
                  parentClass={buttonDropClassName}
                  testId="scan-button-id"
                  variant="outlined"
                  clickHandler={scanDocumentClick}
                >
                  Scan Document
                </ExpressButton>
              </div>
            ) : (
              <ExpressButton
                parentClass={buttonDropClassName}
                testId="drag-drop-button-id"
                variant="outlined"
                clickHandler={open}
              >
                {buttonDropText}
              </ExpressButton>
            )}
          </div>
        </div>
      )}

      <Popup
        openFlag={openPopup}
        closeHandler={closePopup}
        data-testid="manage-user-accounts--pop-up"
        fullScreen={true}
        hideCloseButton
      >
        <ScanBot
          documentDetected={documentDetected}
          scanBotApp={scanBotApp}
          scanDocumentClick={scanDocumentClick}
          pages={scannedDocPageNumber}
          list={scannedImages}
          handleSavePdfClick={handleSavePdfClick}
          closeHandler={closePopup}
          setScannedImages={setScannedImages}
          setScannedDocPageNumber={setScannedDocPageNumber}
          savePdfClicked={savePdfClicked}
          setSavePDfClicked={setSavePDfClicked}
        />
      </Popup>

      {displayUploadedDocument(listingType)}
    </>
  );
};

export default CustomDropZone;
