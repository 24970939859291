import {
  Validation,
  ValidationStatus,
} from "../../core/interfaces/input.interface";
import { getDeepClone } from "../../util/ObjectFunctions";
import { Validator } from "../../util/order.validations";
import { getValidObj } from "../../util/utilityFunctions";
import { IConfirmPlacement } from "./confirmPlacement.interface";

export class ConfirmPlacementValidator {
  private _validator;
  constructor(defValidator = new Validator()) {
    this._validator = defValidator;
  }

  private noValidation(txt: string): Validation {
    return getValidObj();
  }

  private fieldToMethodMapping(
    field: string
  ): ((txt: string) => Validation) | undefined {
    const mapping = new Map<string, (txt: string) => Validation>([
      ["serialNumber", this._validator.emptyCheck],
      ["placementTime", this._validator.emptyCheck],
      ["placementDate", this._validator.placementDateValidation],
    ]);
    const validator = mapping.get(field);
    return validator ? validator : this.noValidation;
  }

  public validate(input: string, field: string) {
    try {
      const validator = this.fieldToMethodMapping(field)!;
      return validator(input);
    } catch (error) {
      console.log(`validator method for field ${field} is not configured`);
    }
  }

  public validateAll(
    confirmPlacementData: IConfirmPlacement,
    updateConfirmPlacementIfUntouchedAndValidated: Function
  ) {
    let temp = getDeepClone(confirmPlacementData);
    Object.keys(temp).forEach((x: string) => {
      if (temp[x].required === false) {
        temp[x].valid = ValidationStatus.VALID;
      }
      if (temp[x].valid === ValidationStatus.UNTOUCHED) {
        temp[x].valid = ValidationStatus.INVALID;
      }
    });
    updateConfirmPlacementIfUntouchedAndValidated(temp);
    const ifAllValid = Object.keys(temp)
      .filter((x) => temp[x].valid)
      .every((x: string) => temp[x].valid === ValidationStatus.VALID);
    return ifAllValid ? ValidationStatus.VALID : ValidationStatus.INVALID;
  }

  public validateUserEnteredAnyDataOrNot = (
    confirmPlacementData: IConfirmPlacement
  ): boolean => {
    let temp = getDeepClone(confirmPlacementData);
    const isUserUpdatedForm = Object.keys(temp).some(
      (x: string) => temp[x].value !== ""
    );
    return isUserUpdatedForm;
  };
}
