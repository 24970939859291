import { AddSiteStatusContextType } from "../../../../../context/AddSiteStatusContext";
import { MultipleActionsProps } from "../../../../confirmPlacement/confirmPlacement.interface";
import { MultipleActionCheckBoxProps } from "../siteStatusLocation/siteStatusLocation.data";

export interface IAddSiteStatusRequestBody {
  id?: string | null;
  userName: string;
  title: string;
  detail: string;
  messageType: number;
  urlLink: string;
  linkText: string;
  statusType: number;
  isActive: boolean;
  applyToLogin: string;
  applyToLoginNotCleared: string;
  applyToFull: string;
  applyToFullNotCleared: string;
  displayAudience: string;
}

const mappedLocationPermissionForSiteStatus = (
  location: MultipleActionCheckBoxProps[]
) => {
  let locationObj = {
    applyToLogin: false,
    applyToLoginNotCleared: false,
    applyToFull: false,
    applyToFullNotCleared: false,
  };
  location.map((x) => {
    if (x.selected) {
      if (x.id === "Login") {
        locationObj.applyToLogin = true;
      } else if (x.id === "CLR1") {
        locationObj.applyToLoginNotCleared = true;
      } else if (x.id === "All") {
        locationObj.applyToFull = true;
      } else if (x.id === "CLR2") {
        locationObj.applyToFullNotCleared = true;
      }
    }
  });
  return locationObj;
};

const getAudienceStringFormat = (audienceArr: MultipleActionsProps[]) => {
  let audianceString: any[] = [];
  audienceArr.map((x) => {
    if (x.selected && !x.disabled) {
      if (x.id === "ALL") {
        audianceString.push("Internal,External,BaseUser");
      } else {
        if (x.id === "BASE") {
          audianceString.push("BaseUser");
        } else if (x.id === "EXT") {
          audianceString.push("External");
        } else if (x.id === "INT") {
          audianceString.push("Internal");
        }
      }
    }
  });
  return audianceString.join(",");
};

export const addSiteStatusRequestMapper = (
  siteStatusObj: AddSiteStatusContextType,
  userName: string
): IAddSiteStatusRequestBody => {
  let addSiteStatusRequestBody: any = {
    id: siteStatusObj.siteStatusDetails.id?.value
      ? siteStatusObj.siteStatusDetails.id?.value
      : null,
    userName: userName,
    title: siteStatusObj.siteStatusDetails.title.value,
    detail: siteStatusObj.siteStatusDetails.detail.value,
    messageType:
      siteStatusObj?.siteStatusDetails?.messageFormat?.value === "info"
        ? 0
        : siteStatusObj?.siteStatusDetails?.messageFormat?.value === "warning"
        ? 1
        : 2,
    urlLink: siteStatusObj.siteStatusDetails.urlLink.value,
    linkText: siteStatusObj.siteStatusDetails.linkText.value,
    statusType:
      siteStatusObj.siteStatusDetails.statusType.value === "message" ? 0 : 1,
    isActive:
      siteStatusObj.siteStatusDetails.activation.value === "yes" ? true : false,
    displayAudience: getAudienceStringFormat(
      siteStatusObj.siteStatusDetails.audience.value
    ),
    ...mappedLocationPermissionForSiteStatus(
      siteStatusObj.siteStatusDetails.location.value
    ),
  };
  return addSiteStatusRequestBody;
};
