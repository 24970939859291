import {
  makeCapitalEachOfWordInString,
  makeCapitalEachWordInString,
} from "../../../../../util/utilityFunctions";
import Table from "../../../../../core/customSortingTable/table.component";
import { CustomCheckBox } from "../../../../../core/checkBox/checkBox.component";
import { Radio } from "@mui/material";
import { ReactComponent as RadioButtonIcon } from "../../../../../assets/radioButton.svg";
import { ReactComponent as DisabledRadioButtonIcon } from "../../../../../assets/disabledRadioButton.svg";
import { ReactComponent as SelectedRadioButtonIcon } from "../../../../../assets/selectedRadioButton.svg";
import { ReactComponent as DisabledSelectedRadioButtonIcon } from "../../../../../assets/disabledSelectedRadioButton.svg";
import { useContext } from "react";
import "./myListHomeCareProviderTable.css";
import {
  RolesPermissionContext,
  RolesPermissionContextType,
} from "../../../../../context/RolesPermissionContext";

export const MyListHomeCareProviderTable = ({
  columns,
  handleSorting,
  sortedData,
  setSortedData,
  valdiateAndSetRadioButton,
  valdiateAndSetCheckBox,
}: any) => {
  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );
  return (
    <div
      className="myList-facility-table-container"
      data-testid="myList-hcp-table-list"
    >
      <Table
        tableClassName="myList-facility-table"
        tableColumns={columns}
        handleSorting={handleSorting}
      >
        {sortedData?.length > 0 ? (
          <tbody>
            {sortedData.map((data: any) => {
              return (
                <tr key={data.id}>
                  <td className="select-username-link">
                    <CustomCheckBox
                      name={"hcpCheckBox"}
                      selectClassName="wound-exposed-structures"
                      selectpropsClassName="wound-exposed-structures-props"
                      handleChange={(e: any) => valdiateAndSetCheckBox(e, data)}
                      labelClassName={"wound-exposed-structures-unchecked"}
                      checked={data.isEnabled}
                      value={data?.isEnabled?.toString()}
                      isDisabled={
                        permissionObj?.mappedRolesPermissionData.IsSupportRole
                      }
                      testId="myList-checkbox-hcp-list"
                    />
                  </td>
                  <td className="myListFacility-table-static-data">
                    <Radio
                      required={true}
                      checked={data.isDefault}
                      icon={
                        !data.isEnabled ||
                        permissionObj?.mappedRolesPermissionData
                          .IsSupportRole ? (
                          <DisabledRadioButtonIcon />
                        ) : (
                          <RadioButtonIcon />
                        )
                      }
                      checkedIcon={
                        !data.isEnabled ||
                        permissionObj?.mappedRolesPermissionData
                          .IsSupportRole ? (
                          <DisabledSelectedRadioButtonIcon />
                        ) : (
                          <SelectedRadioButtonIcon />
                        )
                      }
                      onChange={(e: any) => valdiateAndSetRadioButton(e, data)}
                      disabled={
                        !data.isEnabled ||
                        permissionObj?.mappedRolesPermissionData.IsSupportRole
                      }
                    />
                  </td>
                  <td className="myListFacility-table-static-data">
                    {makeCapitalEachWordInString(`${data.name}`)}
                    <br />
                    {makeCapitalEachWordInString(
                      data.address.addressLine1
                    )},{" "}
                    {data.address.addressLine2
                      ? makeCapitalEachWordInString(data.address.addressLine2) +
                        ","
                      : ""}{" "}
                    {makeCapitalEachWordInString(data.address.city)},{" "}
                    {makeCapitalEachOfWordInString(data.address.stateCode)},{" "}
                    {makeCapitalEachWordInString(data.address.postalCode)}
                  </td>
                  <td className="myListFacility-table-static-data">
                    {data.accountNumber}
                  </td>
                  <td className="myListFacility-table-static-data">
                    {makeCapitalEachWordInString(`${data.classification}`)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        ) : null}
      </Table>
    </div>
  );
};
