import { ValidationStatus } from "../../../core/interfaces/input.interface";
import { MAX_AVAILABLE_CART_QUANTITY } from "../../../util/staticText";
import { makeCapitalEachWordInString } from "../../../util/utilityFunctions";
import { ICartItem } from "../../inpatientSupplyOrder/component/cart/cartItem/cartItem.interface";
import { IInpatientSupplyOrderDetails } from "../facilitySupplyOrders.interface";
import { inpatientSupplyOrderDetailsMockData } from "./inpatientSupplyOrderMockData";

export const mapInpatientSupplyOrderDetails = (
  responseData: any,
  selectedData: any,
  facilityName: string,
  supplyOrderROPNumber: string,
  selectedDeliveryMethod: string
): IInpatientSupplyOrderDetails => {
  const inpatientSupplyOrderDetailsResponse: IInpatientSupplyOrderDetails = {
    orderInformation: {
      patientData: {
        roNumber: selectedData.roNumber ?? "",
        orderID: "",
        type: "",
        patientLocation: "",
        firstName: "",
        lastName: "",
        dob: "",
        facilityName: facilityName ?? "",
        isAcuteOrder: false,
        orderCreationDate: "",
        patientId: null,
        alerts: [],
        status: responseData.status ?? "",
        statusColor: responseData.status === "Received" ? "Blue" : "Green",
        productName: "",
        placementDate: "",
        productSerialNumber: "",
        isAcuteOrderFailed: false,
        isSubmitWoundFailed: false,
      },
      supplyOrderTrackingData: {
        productList: [],
        orderedDate: responseData.orderedDate! ?? "",
        shippedDate: responseData.shippedDate! ?? "",
        deliveredDate: responseData.deliveredDate! ?? "",
        orderedFlag: responseData.orderedFlag! ?? "",
        shippedFlag: responseData.shippedFlag! ?? "",
        deliveredFlag: responseData.deliveredFlag! ?? "",
        trackingNumber: responseData.trackingNumber! ?? "",
        trackingLink: responseData.trackingLink! ?? "",
        dressingChangeFrequency: "",
        reSupplyJustification: "",
        shipAddress1: responseData.shipAddress1! ?? "",
        shipAddress2: responseData.shipAddress2! ?? "",
        shipCity: responseData.shipCity! ?? "",
        shipState: responseData.shipState! ?? "",
        shipZipCode: responseData.shipZipCode! ?? "",
      },
      selectedSupplyOrderData: {
        createdDate: "",
        shippedDate: responseData.shippedDate,
        initiatedName: "",
        product: "",
        ropn: selectedData.ropNumber ?? supplyOrderROPNumber,
        status: responseData.status ?? "",
        statusColor: responseData.status === "Received" ? "Blue" : "Green",
        deliveredDate: responseData.deliveredDate ?? "",
      },
    },
    deliveryInformation: {
      needByDate: {
        valid: ValidationStatus.VALID,
        value: "",
      },
      deliveryMethod: {
        valid: ValidationStatus.VALID,
        value: selectedDeliveryMethod ?? "",
      },
    },
    shippingAddress: {
      isSameasFacilityAddress: {
        valid: ValidationStatus.VALID,
        value: "",
      },
      shippingFacilityName: {
        valid: ValidationStatus.VALID,
        value: facilityName ?? "",
      },
      shippingAddressLine1: {
        valid: ValidationStatus.VALID,
        value: responseData.shipAddress1 ?? "",
      },
      shippingAddressLine2: {
        valid: ValidationStatus.VALID,
        value: responseData.shipAddress2 ?? "",
      },
      shippingAddressCity: {
        valid: ValidationStatus.VALID,
        value: responseData.shipCity ?? "",
      },
      shippingAddressState: {
        valid: ValidationStatus.VALID,
        value: responseData.shipState ?? "",
      },
      shippingAddressZip: {
        valid: ValidationStatus.VALID,
        value: responseData.shipZipCode ?? "",
      },
    },
    salesInformation: {
      orderedBy: makeCapitalEachWordInString(responseData.orderedBy) ?? "",
      salesPONumber: {
        valid: ValidationStatus.VALID,
        value: responseData.salesPO ?? "",
      },
      isPoRequired: null,
    },
    suppliesOrdered: {
      cartItems: responseData.productList.map((supply: any) => ({
        packageType: supply.packSize ?? "",
        price: supply.pricePerUnit ?? "",
        productName: supply.productDescription ?? "",
        quantity: supply.quantity ?? "",
        size: supply.productSize ?? "",
        sku: supply.productNumber ?? "",
        supplyType: supply.productType ?? "",
        totalPrice: parseInt(supply.totalPrice) ?? "",
        estimatedArrivalDate: supply.estDelivery ?? "",
      })),
      invalidQuantity: false,
      totalCartPrice: parseInt(responseData.totalProductPrice ?? ""),
      totalQuantity: 0,
      shippingTotal: parseInt(responseData.shipTotal ?? ""),
    },
  };
  return inpatientSupplyOrderDetailsResponse;
};
