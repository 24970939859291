import moment from "moment";
import { useContext } from "react";
import "./placementDateReview.css";
import { Grid } from "@mui/material";
import { InpatientOrderPageSection } from "../../../inpatientOrder.enum";
import { IInputField } from "../../../../../core/interfaces/input.interface";
import { MobileDisplayContext } from "../../../../../context/MobileDisplayContext";

interface Props {
  data: IInputField;
  editButtonAction: any;
  isSummary: boolean;
}

export const PlacementDateReview = ({
  data,
  editButtonAction,
  isSummary = false,
}: Props) => {
  const { isMobileScreen } = useContext(MobileDisplayContext);

  const showTitleAndValue = (
    title: string,
    value: string | null,
    testId: string
  ) => {
    return (
      <div
        className="show-title-and-value-div"
        id="id_show-title-and-value-div"
      >
        <p
          className="show-title-and-value-title"
          data-testid={`${testId}-title`}
          id={`${testId}-title-id`}
        >
          {title}
        </p>
        <p
          className="show-title-and-value-value"
          data-testid={`${testId}-value`}
          id={`${testId}-value-id`}
        >
          {value && value !== "" ? value.trim() : "--"}
        </p>
      </div>
    );
  };

  return (
    <div
      className="placement-date-review-component"
      data-testid="placement-date-review-component"
      id="id_placement-date-review-component"
    >
      <div
        className="placement-date-review-header"
        id="id_placement-date-review-header"
      >
        <p
          className="placement-date-review-title"
          data-testid="placement-date-review-title"
          id="placement-date-review-title-id"
        >
          Placement Date
        </p>
        {!isSummary && (
          <button
            className="placement-date-review-edit-button"
            data-testid="placement-date-review-edit-button"
            id="placement-date-review-edit-button-id"
            onClick={() =>
              editButtonAction(
                "in-patient-order-placement-date-component",
                InpatientOrderPageSection.SECOND_PAGE
              )
            }
          >
            Edit
          </button>
        )}
      </div>
      <Grid
        className="placement-date-review-grid-item"
        id="id_placement-date-review-grid-item"
        item
        xs={isMobileScreen ? 6 : 4}
      >
        <Grid
          className="placement-date-review-grid-item"
          item
          id="id_placement-date-review-grid-item"
          xs={isMobileScreen ? 6 : 4}
        >
          {showTitleAndValue(
            "Placement Start Date",
            data.value !== "" ? moment(data.value).format("MM/DD/YYYY") : "",
            "placement-start-date"
          )}
        </Grid>
      </Grid>
    </div>
  );
};
