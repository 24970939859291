import React, { MouseEventHandler } from "react";
import { getAlertBgAndLabelColor } from "../../util/utilityFunctions";
import { IPatientAlert } from "../../components/myPatients/patient.interface";
import "./alertButton.css";
import moment from "moment";
import { Button, Grid, useMediaQuery } from "@mui/material";

type AlertArrowProps = {
  stroke: string;
};

export const AlertArrow = ({ stroke }: AlertArrowProps) => {
  return (
    <svg
      width="10"
      height="18"
      viewBox="0 0 10 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 17L9 9L1 1"
        stroke={stroke}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

type AlertButtonProps = {
  alertData: IPatientAlert;
  onClick: any;
  isAlertToolTip?: Boolean;
};

const AlertButton = ({
  alertData,
  onClick,
  isAlertToolTip = false,
}: AlertButtonProps) => {
  const isMobileScreen = useMediaQuery("(max-width:1200px)");
  return (
    <div
      className={
        isAlertToolTip
          ? `alert-box-tool-tip`
          : `${
              alertData.alertDate === null && alertData.alertSubLabel === ""
                ? `alert-box`
                : `alert-box alert-box-small-padding`
            }`
      }
      style={{
        background: getAlertBgAndLabelColor(
          alertData.alertType,
          alertData.severity
        )[0],
      }}
      onClick={onClick}
      key={alertData.alertID.toString()}
    >
      <div
        className={
          isAlertToolTip ? "label-arrow-box-tool-tip" : "label-arrow-box"
        }
      >
        <div
          className={isAlertToolTip ? "alert-name-tool-tip" : "alert-name"}
          style={{
            color: getAlertBgAndLabelColor(
              alertData.alertType,
              alertData.severity
            )[1],
          }}
        >
          {alertData.alertName}
          <div
            className={
              isAlertToolTip
                ? "alert-label-and-date-tool-tip"
                : "alert-label-and-date"
            }
            id={`${alertData.alertID}label`}
            data-testid={`${alertData.alertID}label`}
          >
            {alertData.alertSubLabel}
          </div>
          <div
            className={
              isAlertToolTip
                ? "alert-label-and-date-tool-tip"
                : "alert-label-and-date"
            }
            id={`${alertData.alertID}date`}
            data-testid={`${alertData.alertID}date`}
          >
            {alertData.trackingNumber}
          </div>
        </div>
        <AlertArrow
          stroke={
            getAlertBgAndLabelColor(alertData.alertType, alertData.severity)[1]
          }
        />
      </div>
    </div>
  );
};

export default AlertButton;
