import { IInputField } from "../../core/interfaces/input.interface";
import { IPatient } from "../myPatients/patient.interface";
import { IAdditionalInfoData } from "./updatePrescriber/additionalInformation/additionalInformation.component";

export const mapToRequestParams = (
  siteUseId: string,
  patient: IPatient,
  prescriberData: IInputField,
  additionalInfoData: IAdditionalInfoData
) => {
  return {
    siteUseId: siteUseId,
    RO: patient.roNumber,
    ContactReason: 4,
    NewPrescriberNPI: prescriberData?.value.npi,
    NewPrescriberFirstName: prescriberData?.value.firstName,
    NewPrescriberLastName: prescriberData?.value.lastName,
    NewPrescriberEscriptRequested:
      prescriberData?.value.eScript === "Yes" ? true : false,
    NewPrescriberEmail: prescriberData?.value.email
      ? prescriberData?.value.email
      : prescriberData.value.emails[0].value ?? "",
    DOB: patient.dob,
    NewPrescriberAdditionalInformation:
      additionalInfoData.additionalInformation.value,
  };
};
