import { IInsuranceInformation } from "./insuranceInformation.interface";
import { ValidationStatus } from "../../../../core/interfaces/input.interface";

export const defaultPrimaryInsuranceData: IInsuranceInformation = {
  insuranceType: {
    componentId: "primary-insurance-type",
    order: 21,
    required: true,
    title: "Insurance Type",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  insuranceTypeCode: {
    order: 21,
    required: true,
    title: "Insurance Type",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  medicare: {
    memberID: {
      componentId: "primary-insurance-member-id",
      id: "primary-insurance-member-id-input",
      isDefaultValid: true,
      isOptional: true,
      order: 22,
      required: true,
      title: "Member ID",
      valid: ValidationStatus.VALID,
      value: "",
    },
    relationShipInsured: {
      componentId: "primary-insurance-relationship-insured",
      isDefaultValid: true,
      isOptional: true,
      order: 23,
      required: false,
      title: "Relationship to Insured",
      valid: ValidationStatus.VALID,
      value: "",
    },
  },
  medicaid: {
    memberID: {
      componentId: "primary-insurance-member-id",
      id: "primary-insurance-member-id-input",
      isDefaultValid: true,
      isOptional: true,
      order: 22,
      required: true,
      title: "Member ID",
      valid: ValidationStatus.VALID,
      value: "",
    },
    relationShipInsured: {
      componentId: "primary-insurance-relationship-insured",
      isDefaultValid: true,
      isOptional: true,
      order: 23,
      required: false,
      title: "Relationship to Insured",
      valid: ValidationStatus.VALID,
      value: "",
    },
  },
  medicareAdvantage: {
    payerName: {
      componentId: "primary-insurance-payer-name",
      id: "primary-insurance-payer-name-input",
      isDefaultValid: true,
      isOptional: true,
      order: 22,
      required: true,
      title: "Payor Name",
      valid: ValidationStatus.VALID,
      value: "",
    },
    memberID: {
      componentId: "primary-insurance-member-id",
      id: "primary-insurance-member-id-input",
      isDefaultValid: true,
      isOptional: true,
      order: 23,
      required: true,
      title: "Member ID",
      valid: ValidationStatus.VALID,
      value: "",
    },
    groupID: {
      componentId: "primary-insurance-group-id",
      id: "primary-insurance-group-id-input",
      isDefaultValid: true,
      isOptional: true,
      required: true,
      order: 24,
      title: "Group ID",
      valid: ValidationStatus.VALID,
      value: "",
    },
    payerContactNumber: {
      componentId: "primary-insurance-payer-contact-number",
      id: "primary-insurance-payer-contact-numberinput",
      isDefaultValid: true,
      isOptional: true,
      order: 25,
      required: false,
      title: "Payor Contact Number",
      valid: ValidationStatus.VALID,
      value: "",
      isPhoneNumber: true,
    },
    extension: {
      componentId: "primary-insurance-payer-contact-number-extension",
      id: "primary-insurance-payer-contact-number-extension-input",
      isDefaultValid: true,
      isOptional: true,
      order: 26,
      required: true,
      title: "Extension",
      valid: ValidationStatus.VALID,
      value: "",
    },
    relationShipInsured: {
      componentId: "primary-insurance-relationship-insured",
      isDefaultValid: true,
      isOptional: true,
      order: 27,
      required: false,
      title: "Relationship to Insured",
      valid: ValidationStatus.VALID,
      value: "",
    },
  },
  managedMedicaid: {
    payerName: {
      componentId: "primary-insurance-payer-name",
      id: "primary-insurance-payer-name-input",
      isDefaultValid: true,
      isOptional: true,
      order: 22,
      required: true,
      title: "Payor Name",
      valid: ValidationStatus.VALID,
      value: "",
    },
    memberID: {
      componentId: "primary-insurance-member-id",
      id: "primary-insurance-member-id-input",
      isDefaultValid: true,
      isOptional: true,
      order: 23,
      required: true,
      title: "Member ID",
      valid: ValidationStatus.VALID,
      value: "",
    },
    groupID: {
      componentId: "primary-insurance-group-id",
      id: "primary-insurance-group-id-input",
      isDefaultValid: true,
      isOptional: true,
      required: true,
      order: 24,
      title: "Group ID",
      valid: ValidationStatus.VALID,
      value: "",
    },
    payerContactNumber: {
      componentId: "primary-insurance-payer-contact-number",
      id: "primary-insurance-payer-contact-numberinput",
      isDefaultValid: true,
      isOptional: true,
      order: 25,
      required: false,
      title: "Payor Contact Number",
      valid: ValidationStatus.VALID,
      value: "",
      isPhoneNumber: true,
    },
    extension: {
      componentId: "primary-insurance-payer-contact-number-extension",
      id: "primary-insurance-payer-contact-number-extension-input",
      isDefaultValid: true,
      isOptional: true,
      order: 26,
      required: true,
      title: "Extension",
      valid: ValidationStatus.VALID,
      value: "",
    },
    relationShipInsured: {
      componentId: "primary-insurance-relationship-insured",
      isDefaultValid: true,
      isOptional: true,
      order: 27,
      required: false,
      title: "Relationship to Insured",
      valid: ValidationStatus.VALID,
      value: "",
    },
  },
  commercialInsurance: {
    payerName: {
      componentId: "primary-insurance-payer-name",
      id: "primary-insurance-payer-name-input",
      isDefaultValid: true,
      isOptional: true,
      order: 22,
      required: true,
      title: "Payor Name",
      valid: ValidationStatus.VALID,
      value: "",
    },
    memberID: {
      componentId: "primary-insurance-member-id",
      id: "primary-insurance-member-id-input",
      isDefaultValid: true,
      isOptional: true,
      order: 23,
      required: true,
      title: "Member ID",
      valid: ValidationStatus.VALID,
      value: "",
    },
    groupID: {
      componentId: "primary-insurance-group-id",
      id: "primary-insurance-group-id-input",
      isDefaultValid: true,
      isOptional: true,
      required: true,
      order: 24,
      title: "Group ID",
      valid: ValidationStatus.VALID,
      value: "",
    },
    payerContactNumber: {
      componentId: "primary-insurance-payer-contact-number",
      id: "primary-insurance-payer-contact-numberinput",
      isDefaultValid: true,
      isOptional: true,
      order: 25,
      required: false,
      title: "Payor Contact Number",
      valid: ValidationStatus.VALID,
      value: "",
      isPhoneNumber: true,
    },
    extension: {
      componentId: "primary-insurance-payer-contact-number-extension",
      id: "primary-insurance-payer-contact-number-extension-input",
      isDefaultValid: true,
      isOptional: true,
      order: 26,
      required: true,
      title: "Extension",
      valid: ValidationStatus.VALID,
      value: "",
    },
    relationShipInsured: {
      componentId: "primary-insurance-relationship-insured",
      isDefaultValid: true,
      isOptional: true,
      order: 27,
      required: false,
      title: "Relationship to Insured",
      valid: ValidationStatus.VALID,
      value: "",
    },
  },
  privatePay: {
    componentId: "primary-insurance-private-pay",
    isDefaultValid: true,
    isOptional: true,
    order: 22,
    required: false,
    valid: ValidationStatus.VALID,
    value: "",
  },
  charityCare: {
    isDefaultValid: true,
    isOptional: true,
    required: false,
    valid: ValidationStatus.VALID,
    value: "",
  },
  facilityPay: {
    isDefaultValid: true,
    isOptional: true,
    required: false,
    valid: ValidationStatus.VALID,
    value: "",
  },
  otherAdditionalDetails: {
    componentId: "primary-insurance-additional-detail",
    id: "primary-insurance-additional-detail-input",
    isOptional: true,
    required: true,
    order: 22,
    title: "Additional detail",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
};

export const defaultSecondaryInsuranceData: IInsuranceInformation = {
  insuranceType: {
    componentId: "secondary-insurance-type",
    order: 28,
    required: true,
    title: "Insurance Type",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  insuranceTypeCode: {
    order: 28,
    required: true,
    title: "Insurance Type",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  medicare: {
    memberID: {
      componentId: "secondary-insurance-member-id",
      id: "secondary-insurance-member-id-input",
      isDefaultValid: true,
      isOptional: true,
      order: 29,
      required: true,
      title: "Member ID",
      valid: ValidationStatus.VALID,
      value: "",
    },
    relationShipInsured: {
      componentId: "secondary-insurance-relationship-insured",
      isDefaultValid: true,
      isOptional: true,
      order: 30,
      required: false,
      title: "Relationship to Insured",
      valid: ValidationStatus.VALID,
      value: "",
    },
  },
  medicaid: {
    memberID: {
      componentId: "secondary-insurance-member-id",
      id: "secondary-insurance-member-id-input",
      isDefaultValid: true,
      isOptional: true,
      order: 29,
      required: true,
      title: "Member ID",
      valid: ValidationStatus.VALID,
      value: "",
    },
    relationShipInsured: {
      componentId: "secondary-insurance-relationship-insured",
      isDefaultValid: true,
      isOptional: true,
      order: 30,
      required: false,
      title: "Relationship to Insured",
      valid: ValidationStatus.VALID,
      value: "",
    },
  },
  medicareAdvantage: {
    payerName: {
      componentId: "secondary-insurance-payer-name",
      id: "secondary-insurance-payer-name-input",
      isDefaultValid: true,
      isOptional: true,
      order: 29,
      required: true,
      title: "Payor Name",
      valid: ValidationStatus.VALID,
      value: "",
    },
    memberID: {
      componentId: "secondary-insurance-member-id",
      id: "secondary-insurance-member-id-input",
      isDefaultValid: true,
      isOptional: true,
      order: 30,
      required: true,
      title: "Member ID",
      valid: ValidationStatus.VALID,
      value: "",
    },
    groupID: {
      componentId: "secondary-insurance-group-id",
      id: "secondary-insurance-group-id-input",
      isDefaultValid: true,
      isOptional: true,
      required: true,
      order: 31,
      title: "Group ID",
      valid: ValidationStatus.VALID,
      value: "",
    },
    payerContactNumber: {
      componentId: "secondary-insurance-payer-contact-number",
      id: "secondary-insurance-payer-contact-numberinput",
      isDefaultValid: true,
      isOptional: true,
      order: 32,
      required: false,
      title: "Payor Contact Number",
      valid: ValidationStatus.VALID,
      value: "",
      isPhoneNumber: true,
    },
    extension: {
      componentId: "secondary-insurance-payer-contact-number-extension",
      id: "secondary-insurance-payer-contact-number-extension-input",
      isDefaultValid: true,
      isOptional: true,
      order: 33,
      required: true,
      title: "Extension",
      valid: ValidationStatus.VALID,
      value: "",
    },
    relationShipInsured: {
      componentId: "secondary-insurance-relationship-insured",
      isDefaultValid: true,
      isOptional: true,
      order: 34,
      required: false,
      title: "Relationship to Insured",
      valid: ValidationStatus.VALID,
      value: "",
    },
  },
  managedMedicaid: {
    payerName: {
      componentId: "secondary-insurance-payer-name",
      id: "secondary-insurance-payer-name-input",
      isDefaultValid: true,
      isOptional: true,
      order: 29,
      required: true,
      title: "Payor Name",
      valid: ValidationStatus.VALID,
      value: "",
    },
    memberID: {
      componentId: "secondary-insurance-member-id",
      id: "secondary-insurance-member-id-input",
      isDefaultValid: true,
      isOptional: true,
      order: 30,
      required: true,
      title: "Member ID",
      valid: ValidationStatus.VALID,
      value: "",
    },
    groupID: {
      componentId: "secondary-insurance-group-id",
      id: "secondary-insurance-group-id-input",
      isDefaultValid: true,
      isOptional: true,
      required: true,
      order: 31,
      title: "Group ID",
      valid: ValidationStatus.VALID,
      value: "",
    },
    payerContactNumber: {
      componentId: "secondary-insurance-payer-contact-number",
      id: "secondary-insurance-payer-contact-numberinput",
      isDefaultValid: true,
      isOptional: true,
      order: 32,
      required: false,
      title: "Payor Contact Number",
      valid: ValidationStatus.VALID,
      value: "",
      isPhoneNumber: true,
    },
    extension: {
      componentId: "secondary-insurance-payer-contact-number-extension",
      id: "secondary-insurance-payer-contact-number-extension-input",
      isDefaultValid: true,
      isOptional: true,
      order: 33,
      required: true,
      title: "Extension",
      valid: ValidationStatus.VALID,
      value: "",
    },
    relationShipInsured: {
      componentId: "secondary-insurance-relationship-insured",
      isDefaultValid: true,
      isOptional: true,
      order: 34,
      required: false,
      title: "Relationship to Insured",
      valid: ValidationStatus.VALID,
      value: "",
    },
  },
  commercialInsurance: {
    payerName: {
      componentId: "secondary-insurance-payer-name",
      id: "secondary-insurance-payer-name-input",
      isDefaultValid: true,
      isOptional: true,
      order: 29,
      required: true,
      title: "Payor Name",
      valid: ValidationStatus.VALID,
      value: "",
    },
    memberID: {
      componentId: "secondary-insurance-member-id",
      id: "secondary-insurance-member-id-input",
      isDefaultValid: true,
      isOptional: true,
      order: 30,
      required: true,
      title: "Member ID",
      valid: ValidationStatus.VALID,
      value: "",
    },
    groupID: {
      componentId: "secondary-insurance-group-id",
      id: "secondary-insurance-group-id-input",
      isDefaultValid: true,
      isOptional: true,
      required: true,
      order: 31,
      title: "Group ID",
      valid: ValidationStatus.VALID,
      value: "",
    },
    payerContactNumber: {
      componentId: "secondary-insurance-payer-contact-number",
      id: "secondary-insurance-payer-contact-numberinput",
      isDefaultValid: true,
      isOptional: true,
      order: 32,
      required: false,
      title: "Payor Contact Number",
      valid: ValidationStatus.VALID,
      value: "",
      isPhoneNumber: true,
    },
    extension: {
      componentId: "secondary-insurance-payer-contact-number-extension",
      id: "secondary-insurance-payer-contact-number-extension-input",
      isDefaultValid: true,
      isOptional: true,
      order: 33,
      required: true,
      title: "Extension",
      valid: ValidationStatus.VALID,
      value: "",
    },
    relationShipInsured: {
      componentId: "secondary-insurance-relationship-insured",
      isDefaultValid: true,
      isOptional: true,
      order: 34,
      required: false,
      title: "Relationship to Insured",
      valid: ValidationStatus.VALID,
      value: "",
    },
  },
  facilityPay: {
    isDefaultValid: true,
    isOptional: true,
    required: false,
    valid: ValidationStatus.VALID,
    value: "",
  },
  privatePay: {
    componentId: "secondary-insurance-private-pay",
    isDefaultValid: true,
    isOptional: true,
    order: 29,
    required: false,
    valid: ValidationStatus.VALID,
    value: "",
  },
  charityCare: {
    isDefaultValid: true,
    isOptional: true,
    required: false,
    valid: ValidationStatus.VALID,
    value: "",
  },
  otherAdditionalDetails: {
    componentId: "secondary-insurance-additional-detail",
    id: "secondary-insurance-additional-detail-input",
    isOptional: true,
    required: true,
    order: 29,
    title: "Additional detail",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
};

export type ShowAdditionalFields = {
  typePrimary: {
    medicare: boolean;
    medicareAdvantage: boolean;
    managedMedicaid: boolean;
    commercialInsurance: boolean;
    medicaid: boolean;
    charityCare: boolean;
    privatePay: boolean;
    otherAdditionalDetails: boolean;
    workerCompensation: boolean;
    facilityPay: boolean;
  };
  typeSecondary: {
    medicare: boolean;
    medicareAdvantage: boolean;
    managedMedicaid: boolean;
    commercialInsurance: boolean;
    medicaid: boolean;
    privatePay: boolean;
    otherAdditionalDetails: boolean;
    workerCompensation: boolean;
    facilityPay: boolean;
  };
};
