import { createContext, ReactNode, useContext, useReducer } from "react";
import {
  CartAction,
  cartReducer,
  CartState,
} from "../components/inpatientSupplyOrder/component/cart/cart.reducer";

// CartContext Type
export type CartContextType = {
  cartState: CartState;
  dispatch: React.Dispatch<CartAction>;
  resetCart: () => void;
};

// Create CartContext
export const CartContext = createContext<CartContextType | null>(null);

export const CartProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const initialCartState: CartState = {
    cartItems: [],
    invalidQuantity: false,
    totalCartPrice: 0,
    totalQuantity: 0,
  };
  const [cartState, dispatch] = useReducer(cartReducer, initialCartState);

  const resetCart = () => {
    dispatch({ type: "RESET_CART" });
  };

  return (
    <CartContext.Provider value={{ cartState, dispatch, resetCart }}>
      {children}
    </CartContext.Provider>
  );
};

// Custom hook for using CartContext
export const useCart = (): CartContextType => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error("useCart must be used within a CartProvider");
  }
  return context;
};
