import { ITerritoryDetailList } from "./territoryListTable.interface";

export const columns = [
  { label: "", accessor: "", sortable: false },
  { label: "Territory Code", accessor: "territoryCode", sortable: true },
  { label: "Territory Name", accessor: "territoryName", sortable: true },
  { label: "Role", accessor: "roles", sortable: true },
  { label: "Salesperson", accessor: "salesPeople", sortable: true },
];

export const territoryDetailListModel: ITerritoryDetailList = {
  roles: ["DEV"],
  salesPeople: [],
  territoryCode: "AAM",
  territoryName: "Hospice",
};
