import { Button, Grid } from "@mui/material";
import {
  IAddSalesTerritory,
  ITerritoryDetails,
} from "./addSalesTerritory.interface";
import "./addSalesTerritoryList.css";
import { useState } from "react";
import { ManualAddTerritories } from "./ManualAddSalesTerritory/manualAddTerritory.component";
import { mockManualAddTerritory } from "./ManualAddSalesTerritory/manualAddTerritory.model";

export const AddSalesTerritoryList = ({
  territories,
  data,
  setData,
  setSalesMngUserData,
  salesMngUserData,
  setOpenpopup,
}: IAddSalesTerritory) => {
  const [selectedTerritory, setSelectedTerritory] = useState(false);
  const [selectedTerritoryDetails, setSelectedTerritoryDetails] =
    useState<ITerritoryDetails | null>();
  const selectOpen = (territory: ITerritoryDetails | null) => {
    setData(mockManualAddTerritory);
    setSelectedTerritory(true);
    setSelectedTerritoryDetails(territory);
  };

  return (
    <Grid className="addTerritoryContainer">
      <p className="title" data-testid="add-territory-header">
        Add Sales Territory
      </p>
      <Grid className="addTerritoryContainerList">
        {!selectedTerritory ? (
          <>
            {territories && territories.length > 0 ? (
              territories.map((territory: ITerritoryDetails, index: number) => {
                return (
                  <Grid
                    className={`territoryList${index % 2 === 1 ? " gray" : ""}`}
                  >
                    <Button
                      className="selectButton"
                      onClick={() => {
                        selectOpen(territory);
                      }}
                      data-testid={"add-sales-territory"}
                    >
                      Select
                    </Button>
                    <Grid>
                      <Grid
                        className="territoryRegion"
                        data-testid={"territoryRegionTestId"}
                      >
                        Territory & Region
                      </Grid>
                      <Grid
                        className="territoriesDetails"
                        data-testid={"territoriesDetailsTestId"}
                      >
                        {territory.code} - {territory.name}
                        <Grid>{territory.regionDistrict}</Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })
            ) : (
              <div className="noRecords">No records to display. </div>
            )}
          </>
        ) : (
          <>
            <ManualAddTerritories
              selectedTerritoryDetails={selectedTerritoryDetails!}
              data={data}
              setData={setData}
              selectOpen={selectOpen}
              setSelectedTerritory={setSelectedTerritory}
              setSalesMngUserData={setSalesMngUserData}
              salesMngUserData={salesMngUserData!}
              setOpenpopup={setOpenpopup}
            />
          </>
        )}
      </Grid>
    </Grid>
  );
};
