import "./administration.css";
import { Grid } from "@mui/material";
import { ExpressButton } from "../../core/expressButton/expressButton.component";
import { AuthContext, AuthContextType } from "../../context/AuthContext";
import { useContext } from "react";

const AdministrationSettings = ({ buttonArray, handleBtnClick }: any) => {
  const authObj = useContext<AuthContextType | null>(AuthContext);

  const getUserAccount = (id: string) => {
    return (
      <div className="madminBtnMain" id={id}>
        User Accounts
        <span className="madminBtnStyle" id={id}>
          {` ${authObj?.unLinkedFacilitesCount} `}
        </span>
      </div>
    );
  };

  return (
    <div>
      <Grid>
        {buttonArray.length > 0 &&
          buttonArray.map((x: any, index: number) => {
            let value = x;
            const id = x.toString();
            if (x === "User Accounts") {
              value = getUserAccount(id);
            }
            return (
              <Grid item className="adminBtnGrid">
                <ExpressButton
                  variant="outlined"
                  parentClass="adminBtnMain"
                  clickHandler={handleBtnClick}
                  id={id}
                  testId={id + index}
                >
                  {value}
                </ExpressButton>
              </Grid>
            );
          })}
      </Grid>
    </div>
  );
};

export default AdministrationSettings;
