import {
  useCallback,
  useEffect,
  useState,
  useContext,
  SetStateAction,
  Dispatch,
} from "react";
import "./buttonGroup.css";
import {
  ProfileFormContext,
  ProfileFormContextType,
} from "../../../context/ProfileFormContext";
import { useHistory, useLocation } from "react-router-dom";
import { Popup } from "../../../core/popup/popup.component";
import { verifyCaptcha } from "../../../util/captchaService";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { generateAndSendCode } from "../../../util/codeService";
import SnackBar from "../../../core/snackBar/snackBar.component";
import { EXECUTE_CAPTCHA_ACTION } from "../../../util/staticText";
import { LoadingSpinner } from "../../../core/loader/LoadingSpinner";
import {
  formatPhoneNumber,
  getCodeValidateError,
} from "../../../util/utilityFunctions";
import { ProfileCancel } from "../cancelProfile/profileCancel.component";
import { AuthContext, AuthContextType } from "../../../context/AuthContext";
import { ValidationStatus } from "../../../core/interfaces/input.interface";
import { AuthenticationType } from "../saveProfile/authenticationType.model";
import { IManageProfile } from "../../manageProfile/manageProfile.interface";
import { ExpressButton } from "../../../core/expressButton/expressButton.component";
import { ManageProfileValidator } from "../../manageProfile/manageProfile.validator";
import { AuthenticateProfileSave } from "../saveProfile/authenticateProfileSave.component";
import { PersistentFooter } from "../../../core/persistentFooter/persistentFooter.Component";
import {
  authenticationChannel,
  checkUpdateUserStatus,
  registerUser,
  updateUser,
  validateCode,
} from "../../../util/userService";
import usePollingInterval from "../../../core/customHooks/useInterval";
import {
  EmailCommunicationPreference,
  IUser,
  IUserFacility,
  IUserUpdate,
} from "../../manageProfile/user.interface";
import {
  FacilityMode,
  IFacility,
} from "../../manageProfile/facilityInformation/facility.interface";
import {
  ERROR_MSG_CREATE_USER_DETAILS,
  ERROR_MSG_UPDATE_USER_DETAILS,
} from "../../../util/errorMsg";
import ErrorPopup from "../../../core/errorPopup/errorPopup.component";
import {
  IOTPReqBody,
  ModeForOTP,
  PathType,
} from "../saveProfile/authprofile.interface";

type Props = {
  button1Text: string;
  button2Text: string;
  data: IManageProfile | undefined;
  Validator?: ManageProfileValidator;
  toPath: string;
  setData: any;
  pathType: string;
  setProgbarVal: Dispatch<SetStateAction<number>>;
};

export const FooterButtonGroup = ({
  button1Text,
  button2Text,
  data,
  Validator,
  toPath,
  setData,
  pathType,
  setProgbarVal,
}: Props) => {
  const history = useHistory();
  const location = useLocation();
  const [validator] = useState(Validator);
  const [authType, setAuthType] = useState("");
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [codeAttempt, setCodeAttempt] = useState(true);
  const [codeSentError, setCodeSentError] = useState("");
  const [isSendingCode, setIsSendingCode] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [snackBarMsg, setSnackBarMsg] = useState<string>("");
  const [failureMessage, setFailureMessage] = useState<string>("");
  const [snackBarAlert, setSnackBarAlert] = useState<boolean>(false);
  const [openSaveDialog, setOpenSaveDialog] = useState<boolean>(false);
  const [openCancelDialog, setOpenCancelDialog] = useState<boolean>(false);

  const authObj = useContext<AuthContextType | null>(AuthContext);
  const registrationForm = useContext<ProfileFormContextType | null>(
    ProfileFormContext
  );

  // Duarable Function For Update User
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [openChooseOptionToSendOtp, setOpenChooseOptionToSendOtp] =
    useState<boolean>(false);
  const [openOtpVerificationPopUp, setOpenOtpVerificationPopUp] =
    useState<boolean>(false);

  const [currentStatusCode, setCurrentStatusCode] = useState<number | null>(
    null
  );
  const [currentLastUpdatedTime, setCurrentLastUpdatedTime] = useState<
    string | null
  >(null);
  const [timeInterval, setTimeInterval] = useState<number | null>(null);
  const [pollingUrl, setPollingUrl] = useState<any | undefined>();
  const [instanceId, setInstanceId] = useState<string | undefined>();

  const [isEmailPresent, setIsEmailPresent] = useState<boolean>(true);
  const [isMobilePresent, setIsMobilePresent] = useState<boolean>(true);

  // Error Pop up
  const [errorCode, setErrorCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorPopPpFlag, setErrorPopUpFlag] = useState(false);

  // Validate otp error
  const [codeValidateError, setCodeValidateError] = useState(false);
  const [codeValidateErrorDescription, setCodeValidateErrorDescription] =
    useState("");

  usePollingInterval(() => {
    checkStatus();
  }, timeInterval);

  setTimeout(() => {
    setSnackBarAlert(false);
    setFailureMessage("");
  }, 10000);

  const generateAndSendCodeService = async (authTypeValue: string) => {
    if (data) {
      const reqParams = {
        mode: authTypeValue === "email" ? 0 : 1,
        userName: data.userName.value,
        email: authTypeValue === "email" ? data.email.value : "",
        phoneNumber:
          authTypeValue !== "email"
            ? data.phone.value.replace(/\s/g, "").replace(/[()-]/g, "")
            : "",
        type:
          pathType === "manageprofile"
            ? AuthenticationType.UPDATE_PROFILE
            : AuthenticationType.REGISTERATION,
        firstName: data.firstName.value,
      };
      setIsSendingCode(true);
      const result = await generateAndSendCode(reqParams);
      if (result.succeeded) {
        setIsSendingCode(false);
        setOpenSaveDialog(false);
        setCodeValidateError(false);
        setCodeValidateErrorDescription("");
        setOpenOtpVerificationPopUp(true);
        setCodeAttempt(true);
      } else {
        setCodeSentError(getCodeValidateError(result.error.errorCode));
        setIsSendingCode(false);
      }
    }
  };

  const updateUserdetails = async () => {
    if (data) {
      const emailPreference =
        registrationForm?.profileDetails.emailPreference.value === "true" ??
        false;
      const smsPreference =
        registrationForm?.profileDetails.smsPreference.value === "true" ??
        false;
      const smsAccepted =
        registrationForm?.profileDetails.smsPreference.value === "true" ??
        false;
      const phoneType = data.phoneType.value === "mobile" ? 1 : 2;
      const extensionNumber = data.extension.value ? data.extension.value : "";
      const phoneNumber =
        data.phoneType.value === "phone" && data.phone.value
          ? data.phone.value
          : "";
      const mobileNumber =
        data.phoneType.value === "mobile" && data.phone.value
          ? data?.phone.value
          : "";
      let updateUserObj: IUserUpdate = {
        firstName: data.firstName.value,
        lastName: data.lastName.value,
        userName: data.userName.value,
        title: data.title.value,
        departmentName: data.department.value,
        licenceType: data.licenseType.value,
        emailContactPreference: emailPreference,
        smsContactPreference: smsPreference,
        updateSMSTnCAcceptedDate: (smsPreference && smsAccepted) ?? false,
        smsTnCAcceptedDate: registrationForm?.smsTnCAcceptedDate,
        keepMeUpdated: false,
        emailNotifications: [],
        facilities: [],
        phoneNumber: formatPhoneNumber(phoneNumber).split("-").join(""),
        extension: extensionNumber,
        phoneType: phoneType,
        mobilePhoneNumber: formatPhoneNumber(mobileNumber).split("-").join(""),
      };
      let facilities: IUserFacility[] = [];
      let emailNotifications: EmailCommunicationPreference[] = [];
      const pickupval =
        data.pickUpRequest && data.pickUpRequest.value === "no" ? false : true;
      const rentalactval =
        data.rentalActivity && data.rentalActivity.value === "no"
          ? false
          : true;
      const salesactval =
        data.salesActivity && data.salesActivity.value === "no" ? false : true;
      const pickupreques = {
        name: "RentalActivity: Home",
        value: rentalactval,
      };
      const rentalactivity = { name: "PickupRequest", value: pickupval };
      const salesActivity = { name: "SalesActivity", value: salesactval };
      emailNotifications.push(rentalactivity);
      emailNotifications.push(pickupreques);
      emailNotifications.push(salesActivity);
      updateUserObj.emailNotifications = emailNotifications;
      if (registrationForm && registrationForm.registeredFacility.length > 0) {
        facilities = registrationForm.registeredFacility.map(
          (facility: IFacility) => {
            const facilityMode = facility.addressId ? 0 : 1;
            const updatedFacility: IUserFacility = {
              number:
                facility.accountNumber !== null
                  ? facility.accountNumber?.toString()
                  : null,
              typeCode: facility.typeCode,
              typeName: facility.typeName,
              zipCode: facility.zip.toString(),
              mode: facility.addressId ? 0 : 1,
              address1:
                facilityMode === FacilityMode.MANUAL
                  ? facility && facility.address1 && facility.address1.trim()
                  : "",
              address2:
                facilityMode === FacilityMode.MANUAL
                  ? facility && facility.address2 && facility.address2.trim()
                  : "",

              city: facility.city,
              state: facility.state,
              facilityName:
                facilityMode === FacilityMode.MANUAL
                  ? facility.accountName
                  : "",
              siteUseId: facility.siteUseId ? facility.siteUseId : "",
              careGiverId: facility.careGiverId ?? null,
            };
            return updatedFacility;
          }
        );
        updateUserObj.facilities = facilities;
      }
      setShowLoader(true);
      const response = await updateUser(updateUserObj);
      if (response.id) {
        setInstanceId(response.id);
        setPollingUrl(response.statusQueryGetUri);
        setTimeInterval(1000);
        checkStatus();
      } else {
        // Show Error Pop Up.
        setErrorMessage(ERROR_MSG_UPDATE_USER_DETAILS);
        setErrorCode(response?.error?.code || response?.status);
        setErrorPopUpFlag(true);
        setShowLoader(false);
      }
    }
  };

  const createNewUser = async () => {
    //create user
    setShowLoader(true);
    if (data) {
      const phoneType = data?.phoneType.value === "mobile" ? 1 : 2;
      const extensionNumber = data?.extension.value
        ? data?.extension.value
        : "";
      const phoneNumber =
        data?.phoneType.value === "phone" && data?.phone.value
          ? data?.phone.value
          : "";
      const mobileNumber =
        data?.phoneType.value === "mobile" && data?.phone.value
          ? data?.phone.value
          : "";
      const emailPreference =
        registrationForm?.profileDetails.emailPreference.value === "true" ??
        false;
      const smsPreference =
        registrationForm?.profileDetails.smsPreference.value === "true" ??
        false;
      let newUser: IUser = {
        emailAddress: data?.email.value,
        departmentName: data?.department.value,
        firstName: data?.firstName.value,
        lastName: data?.lastName.value?.trim(),
        userName: data?.email.value,
        licenceType: data?.licenseType.value ?? "",
        messageToFacilityAdmin: data?.adminMessage?.value!,
        password: data?.newPassword.value,
        facilities: [],
        title: data?.title.value,
        phoneNumber: formatPhoneNumber(phoneNumber).split("-").join(""),
        extension: extensionNumber,
        phoneType: phoneType,
        mobilePhoneNumber: formatPhoneNumber(mobileNumber).split("-").join(""),
        emailContactPreference: emailPreference,
        smsContactPreference: smsPreference,
        keepMeUpdated: false,
        smsTnCAcceptedDate: registrationForm?.smsTnCAcceptedDate ?? null,
      };
      let facilities: IUserFacility[] = [];
      if (registrationForm && registrationForm.registeredFacility.length > 0) {
        registrationForm.registeredFacility.map(
          (rec: IFacility, index: number) => {
            const fac: IUserFacility = {
              number:
                rec.accountNumber !== null
                  ? rec.accountNumber?.toString()
                  : null,
              typeCode: rec.typeCode,
              typeName: rec.typeName,
              zipCode: rec.zip.toString(),
              mode: rec.facilityMode,
              address1:
                rec.facilityMode === FacilityMode.MANUAL ? rec.address1 : "",
              address2:
                rec.facilityMode === FacilityMode.MANUAL ? rec.address2 : "",
              city: rec.city,
              state: rec.state,
              facilityName:
                rec.facilityMode === FacilityMode.MANUAL ? rec.accountName : "",
              siteUseId: rec.siteUseId ? rec.siteUseId : "",
              careGiverId: rec.careGiverId ?? null,
            };
            facilities.push(fac);
          }
        );
        newUser.facilities = facilities;
      }

      let token: string = "";
      if (executeRecaptcha) {
        token = await executeRecaptcha(EXECUTE_CAPTCHA_ACTION);
      }
      const reqBody = { ...newUser, token: token };
      let serviceResponse = await registerUser(reqBody);
      if (serviceResponse && serviceResponse?.id) {
        setInstanceId(serviceResponse.id);
        setPollingUrl(serviceResponse.statusQueryGetUri);
        setTimeInterval(1000);
        checkStatus();
      } else {
        setErrorMessage(ERROR_MSG_CREATE_USER_DETAILS);
        setErrorCode(serviceResponse?.error?.code || serviceResponse?.status);
        setShowLoader(false);
        setErrorPopUpFlag(true);
      }
    }
  };

  const sendSelectedChannel = async (channel: number) => {
    setOpenChooseOptionToSendOtp(false);
    if (instanceId) {
      const params = {
        channel: channel,
      };
      const reqBody = JSON.stringify(params);
      setShowLoader(true);
      const response = await authenticationChannel(instanceId, reqBody);
      if (response) {
        setTimeInterval(1000);
      } else {
        resetAPIPollingDetails();
        setErrorMessage(ERROR_MSG_UPDATE_USER_DETAILS);
        setErrorCode("500");
        setErrorPopUpFlag(true);
        setShowLoader(false);
      }
    }
  };

  const validateOTP = async (code: string) => {
    setOpenOtpVerificationPopUp(false);
    setShowLoader(true);
    const params: IOTPReqBody = {
      Mode:
        pathType === PathType.REGISTER_PAGE
          ? ModeForOTP.registerpage
          : ModeForOTP.manageprofile,
      Code: code,
    };
    setShowLoader(true);
    const reqBody = JSON.stringify(params);
    const response = await validateCode(instanceId!, reqBody);
    if (response) {
      setTimeInterval(1000);
    } else {
      resetAPIPollingDetails();
      setErrorMessage(ERROR_MSG_UPDATE_USER_DETAILS);
      setErrorCode("500");
      setErrorPopUpFlag(true);
      setShowLoader(false);
    }
  };

  const checkAndUpdateUserAndEmailData = async (
    fieldName: string,
    data: IManageProfile | undefined
  ) => {
    let finalData: IManageProfile;
    let fieldVal = "";
    let valResp;
    if (fieldName === "userName") {
      if (data?.verifyEmail?.value) {
        fieldVal = data?.verifyEmail?.value;
        valResp = await validator?.validateWithDb(fieldVal, fieldName);
        finalData = Object.assign({}, data, {
          userName: {
            value: data?.verifyEmail?.value,
            valid: valResp?.status,
            required: data.userName.required,
            errorMessage: valResp?.message,
          },
        });
        return finalData;
      }
    }
    if (fieldName === "email") {
      if (data?.email.value) {
        fieldVal = data.email.value;
        valResp = await validator?.validateWithDb(fieldVal, fieldName);
        finalData = Object.assign({}, data, {
          email: {
            value: data.email.value,
            valid: valResp?.status,
            required: data.email.required,
            errorMessage: valResp?.message,
          },
        });
        return finalData;
      }
    }
  };

  const enableSaveButton = () => {
    if (submitDisabled === false && registrationForm?.editable === false) {
      return false;
    }
    return true;
  };

  const handleOptionClick = (authenticationType: string) => {
    setAuthType(authenticationType);
    sendSelectedChannel(authenticationType === "email" ? 0 : 1);
  };

  const handleCancelClick = () => {
    if (toPath === "/signUp") {
      history.push(toPath);
    } else {
      setOpenCancelDialog(true);
    }
  };

  const handleSaveClick = async () => {
    setCodeSentError("");
    let finalData = data;
    const formStatus = validator?.validateAll(
      finalData,
      setData,
      registrationForm
    );
    if (formStatus === ValidationStatus.VALID) {
      location.pathname !== "/manageProfile" && setProgbarVal(100);
      if (pathType === PathType.MANAGE_PROFILE) {
        authObj?.setProfileSaveAuthicated(true);
        updateUserdetails();
      } else if (pathType === PathType.REGISTER_PAGE) {
        createNewUser();
      }
    }
    <Popup
      dialogParentClass="save-registration-dialog"
      closeHandler={() => {
        location.pathname !== "/manageProfile" && setProgbarVal(50);
        setOpenSaveDialog((x) => !x);
      }}
      openFlag={openSaveDialog}
    >
      {isSendingCode ? spinner() : chooseOptionToSendOtp()}
    </Popup>;
  };

  const handleSnackBar = async () => {
    if (failureMessage) {
      setSnackBarAlert(true);
      setSnackBarMsg(failureMessage);
    } else {
      setSnackBarAlert(false);
    }
  };

  const handleCloseAlert = () => {
    setSnackBarAlert(false);
    setFailureMessage("");
  };

  const validateAllFields = useCallback(() => {
    if (validator?.isFormUntouched(data)) return;
    const allValid = validator?.allFieldsValid(data, true);
    const facilityAdded = registrationForm?.registeredFacility.length > 0;
    if (allValid && ((authObj && authObj.isInternalUser) || facilityAdded)) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [data, registrationForm?.registeredFacility]);

  const resetAPIPollingDetails = () => {
    setCurrentStatusCode(null);
    setCurrentLastUpdatedTime(null);
    setTimeInterval(null);
    setPollingUrl(undefined);
    setInstanceId(undefined);
  };

  const checkStatus = async () => {
    if (pollingUrl) {
      let response = await checkUpdateUserStatus(pollingUrl);
      switch (response.runtimeStatus) {
        case "Running":
          if (response.customStatus && response.customStatus.Code) {
            updateScreen(
              response.customStatus.Code,
              response.customStatus.Message,
              response.lastUpdatedTime
            );
          }
          break;
        case "Completed":
          setTimeInterval(null);
          if (response.customStatus && response.customStatus.Code) {
            updateScreen(
              response.customStatus.Code,
              response.customStatus.Message,
              response.lastUpdatedTime
            );
          }
          resetAPIPollingDetails();
          break;
        default:
          break;
      }
    }
  };

  const updateScreen = (
    status: number,
    message: any,
    lastUpdatedTime: string
  ) => {
    if (
      !currentLastUpdatedTime ||
      (currentStatusCode &&
        currentStatusCode !== status &&
        currentLastUpdatedTime &&
        currentLastUpdatedTime !== lastUpdatedTime)
    ) {
      let value = currentStatusCode;
      //path check
      const isRegisterPage = pathType === PathType.REGISTER_PAGE;
      let genericErrorMessage = isRegisterPage
        ? ERROR_MSG_CREATE_USER_DETAILS
        : ERROR_MSG_UPDATE_USER_DETAILS;
      setCurrentStatusCode(status);
      setCurrentLastUpdatedTime(lastUpdatedTime);
      switch (status) {
        case 104:
        case 122:
        case 123:
          // Show Generic
          setErrorMessage(genericErrorMessage);
          setErrorCode(status.toString());
          setShowLoader(false);
          setErrorPopUpFlag(true);
          break;

        case 105:
        case 106:
          // 105 - VerificationCodeHasSentViaEmail
          // 106 - VerificationCodeHasSentViaSMS
          setTimeInterval(null);
          setShowLoader(false);
          setCodeValidateError(false);
          setCodeValidateErrorDescription("");
          setOpenOtpVerificationPopUp(true);
          break;

        case 107:
          // TimeOut
          setOpenChooseOptionToSendOtp(false);
          setOpenOtpVerificationPopUp(false);
          setErrorMessage(ERROR_MSG_UPDATE_USER_DETAILS);
          setErrorCode(status.toString());
          setShowLoader(false);
          setErrorPopUpFlag(true);
          resetAPIPollingDetails();
          break;

        case 113:
          if (message) {
            const isEmailPresentValue = message.isEmailPresent;
            if (
              isEmailPresentValue !== null ||
              isEmailPresentValue !== undefined
            ) {
              setIsEmailPresent(isEmailPresentValue);
            }
            const isMobilePresentValue = message.isMobilePresent;
            if (
              isMobilePresentValue !== null ||
              isMobilePresentValue !== undefined
            ) {
              setIsMobilePresent(isMobilePresentValue);
            }
          }
          // Show Send OTP Pop Up
          setTimeInterval(null);
          setOpenChooseOptionToSendOtp(true);
          setShowLoader(false);
          break;

        case 124:
          // Profile Update Success
          setTimeInterval(null);
          registrationForm?.setOriginalProfileDetails(
            registrationForm.profileDetails
          );
          setShowLoader(false);
          const linkedFacility = registrationForm?.registeredFacility.filter(
            (x: IFacility) => x.addressId
          )[0];
          authObj?.setregisteredFaciltyAddress(linkedFacility);
          authObj?.setProfileSaveAuthicated(true);
          history.push("/success");
          break;

        case 125:
          registrationForm?.setProfileDetails(
            registrationForm.originalProfileDetails
          );
          setFailureMessage("Failed to update the profile");
          setShowLoader(false);
          break;
        case 126:
          // 126-verifycaptcah success
          setTimeInterval(null);
          setOpenChooseOptionToSendOtp(true);
          setShowLoader(false);
          break;
        case 128:
          //128-User created successfully
          authObj?.setProfileSaveAuthicated(true);
          history.push("/success");
          break;
        case 127:
        case 129:
        case 130:
          // 127-verifycaptcha not succeded
          // 129-Failed to create a new user
          // 130-Wrong Password
          setTimeInterval(null);
          setShowLoader(false);
          setErrorMessage(ERROR_MSG_CREATE_USER_DETAILS);
          setErrorCode(status.toString());
          setErrorPopUpFlag(true);
          break;

        case 1005:
        case 1006:
          // VerificationCodeNotValid
          setTimeInterval(null);
          setOpenOtpVerificationPopUp(true);
          setShowLoader(false);
          setCodeValidateError(true);
          setCodeValidateErrorDescription(
            "Please enter valid code and try again."
          );
          if (value === 105) {
            setCurrentStatusCode(105);
          } else if (value === 106) {
            setCurrentStatusCode(106);
          }
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    handleSnackBar();
  }, [failureMessage]);

  useEffect(() => {
    if (data !== null || data !== undefined) {
      validateAllFields();
    }
  }, [data, registrationForm?.registeredFacility]);

  const spinner = () => {
    return (
      <div className="manage-profile-save">
        <h3 className="header">Authentication</h3>
        <div className="authenticationCodeSent-loader">
          <LoadingSpinner />
        </div>
      </div>
    );
  };

  const chooseOptionToSendOtp = () => {
    return (
      <div className="manage-profile-save">
        <h3 className="header">Authentication</h3>
        <p className="sub-text">
          Verify you are the account holder using one of the authentication
          methods below
        </p>
        <ExpressButton
          clickHandler={() => handleOptionClick("email")}
          parentClass="sendEmail"
          variant="outlined"
          disabled={!isEmailPresent}
        >
          Send code to email
        </ExpressButton>
        <ExpressButton
          clickHandler={() => handleOptionClick("SMS")}
          parentClass="sendSms"
          variant="outlined"
          disabled={
            pathType === "manageprofile"
              ? !isMobilePresent
              : data?.phoneType?.value === "phone"
          }
        >
          Send code to SMS
        </ExpressButton>
        {codeSentError && (
          <span className="sendCodeError">{codeSentError}</span>
        )}
      </div>
    );
  };

  return (
    <>
      <PersistentFooter>
        <ExpressButton
          variant="outlined"
          clickHandler={handleCancelClick}
          parentClass={button1Text === "Cancel" ? "cancelBtn" : "backBtn"}
        >
          {button1Text}
        </ExpressButton>
        <ExpressButton
          variant="contained"
          clickHandler={handleSaveClick}
          parentClass="saveBtn"
          disabled={enableSaveButton()}
        >
          {button2Text}
        </ExpressButton>
      </PersistentFooter>
      <ErrorPopup
        errorPopupFlag={errorPopPpFlag}
        handleBackButton={() => setErrorPopUpFlag(false)}
        popUpStyles="errorPopup"
        errorMessage={errorMessage}
        buttontitle="Done"
        showTitle={false}
        isShortSentence={true}
        errorCode={errorCode}
      />
      <Popup
        dialogParentClass="save-registration-dialog"
        closeHandler={() => {
          location.pathname !== "/manageProfile" && setProgbarVal(50);
          setShowLoader(!showLoader);
        }}
        hideCloseButton={true}
        openFlag={showLoader}
      >
        {spinner()}
      </Popup>
      <Popup
        dialogParentClass="save-registration-dialog"
        closeHandler={() => {
          location.pathname !== "/manageProfile" && setProgbarVal(50);
          setOpenChooseOptionToSendOtp(false);
          resetAPIPollingDetails();
        }}
        openFlag={openChooseOptionToSendOtp}
      >
        {chooseOptionToSendOtp()}
      </Popup>
      <Popup
        dialogParentClass="auth-verify-dialog"
        closeHandler={() => {
          location.pathname !== "/manageProfile" && setProgbarVal(50);
          setOpenOtpVerificationPopUp(false);
          resetAPIPollingDetails();
        }}
        openFlag={openOtpVerificationPopUp}
      >
        <AuthenticateProfileSave
          authType={authType}
          codeAttempt={codeAttempt}
          data={data}
          failureMessage={setFailureMessage}
          pathType={pathType}
          setAttempt={setCodeAttempt}
          setOpenAuthVerifyDialog={setOpenOtpVerificationPopUp}
          validateCode={validateOTP}
          codeValidateError={codeValidateError}
          setCodeValidateError={setCodeValidateError}
          codeValidateErrorDescription={codeValidateErrorDescription}
          setCodeValidateErrorDescription={setCodeValidateErrorDescription}
        />
      </Popup>
      <Popup
        dialogParentClass="save-registration-dialog"
        closeHandler={() => {
          location.pathname !== "/manageProfile" && setProgbarVal(50);
          setOpenSaveDialog((x) => !x);
        }}
        openFlag={openSaveDialog}
      >
        {isSendingCode ? spinner() : chooseOptionToSendOtp()}
      </Popup>
      <Popup
        dialogParentClass="cancel-dialog"
        closeHandler={() => setOpenCancelDialog((x) => !x)}
        openFlag={openCancelDialog}
      >
        <ProfileCancel
          stayHandler={() => setOpenCancelDialog(false)}
          redirectTo={toPath}
        />
      </Popup>
      <SnackBar
        toastStyle="registerationSaveToast"
        openFlag={snackBarAlert}
        msg={snackBarMsg}
        handleCloseAlert={handleCloseAlert}
      ></SnackBar>
    </>
  );
};
