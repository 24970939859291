import "./supplyOrder.css";
import { any } from "underscore";
import { Grid } from "@mui/material";
import {
  SupplyOrderContext,
  SupplyOrderContextType,
} from "../../context/SupplyOrderContext";
import { ReactNode, useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ProgressBar from "../progressBar/progressBar.component";
import { SupplyOrderPageSection } from "./SupplyOrderPageSection.enum";
import SupplyOrderInfo from "./supplyOrderInfo/supplyOrderInfo.component";
import { SupplyOrderList } from "./supplyOrderList/supplyOrderList.component";
import SupplyOrderReview from "./supplyOrderReview/supplyOrderReview.component";
import SupplyOrderSummary from "./supplyOrderSummary/supplyOrderSummary.component";
import { LeaveSupplyOrder } from "./supplyOrderInfo/leaveSupplyOrder/leaveSupplyOrder.component";
import { AuthContext, AuthContextType } from "../../context/AuthContext";

const SupplyOrder = () => {
  const history = useHistory();
  const location: any = useLocation();
  const openSupplyOrderPageEdit = any;
  const [popUpTitle, setPopUpTitle] = useState("");
  const { isOrderOverviewFlow } = location.state || "";
  const [backBtnText, setbackBtnText] = useState("Back");
  const [popUpButtonTitle, setPopUpButtonTitle] = useState("");
  const [cancelLeaveOpen, setCancelLeaveOpen] = useState(false);
  const [cancelConfirmText, setcancelConfirmText] = useState("");
  const supplyOrderObj = useContext<SupplyOrderContextType | null>(
    SupplyOrderContext
  );
  const authObj = useContext<AuthContextType | null>(AuthContext);

  const handleCancelLeavePopUp = () => {
    setCancelLeaveOpen(false);
  };

  const sectionToDisplay = () => {
    let page: ReactNode;
    switch (supplyOrderObj?.supplyOrderPage) {
      case SupplyOrderPageSection.SUPPLYORDER_PATIENT_LIST:
        page = <SupplyOrderList supplyOrderContextObj={supplyOrderObj} />;
        break;
      case SupplyOrderPageSection.SUPPLYORDER_INFO:
        page = (
          <SupplyOrderInfo
            openSupplyOrderPageEdit={openSupplyOrderPageEdit}
            isOrderOverviewFlow={isOrderOverviewFlow}
          />
        );
        break;
      case SupplyOrderPageSection.SUPPLYORDER_REVIEW:
        page = (
          <SupplyOrderReview
            supplyOrderContextObj={supplyOrderObj}
            isOrderOverviewFlow={isOrderOverviewFlow}
          />
        );
        break;
      case SupplyOrderPageSection.SUPPLYORDER_SUMMARY:
        page = <SupplyOrderSummary supplyOrderContextObj={supplyOrderObj} />;
        break;
      case SupplyOrderPageSection.NONE:
        break;
    }
    return page;
  };

  const handleBackClick = () => {
    if (
      supplyOrderObj?.supplyOrderPage ===
      SupplyOrderPageSection.SUPPLYORDER_INFO
    ) {
      setPopUpTitle("Are you sure you want to leave this order?");
      setPopUpButtonTitle("Leave Order");
      setCancelLeaveOpen(true);
      setbackBtnText("Back");
      setcancelConfirmText("All changes will be lost");
    } else if (
      supplyOrderObj?.supplyOrderPage ===
      SupplyOrderPageSection.SUPPLYORDER_REVIEW
    ) {
      supplyOrderObj?.setIsBackFromReviewPage(true);
      supplyOrderObj?.setSupplyOrderPage(
        SupplyOrderPageSection.SUPPLYORDER_INFO
      );
    } else {
      history.goBack();
    }
  };

  useEffect(() => {
    history.replace(location.pathname, {});
    const patient = supplyOrderObj?.selectedPatient;
    if (
      !patient &&
      supplyOrderObj?.supplyOrderPage !==
        SupplyOrderPageSection.SUPPLYORDER_PATIENT_LIST
    ) {
      history.replace("/home", {});
    }
  }, []);

  return (
    <Grid
      className={`supplyOrder-flow ${
        authObj?.isUnlinkedFacilityOrder && "unlinked-facility-margin"
      }`}
      container
    >
      {supplyOrderObj?.supplyOrderPage !==
        SupplyOrderPageSection.SUPPLYORDER_SUMMARY && (
        <Grid item data-testid="SO-ProgressBar" className="progressGridMain">
          <ProgressBar
            pageTitle={supplyOrderObj?.supplyOrderPageTitle!}
            progressValue={supplyOrderObj?.supplyOrderProgress}
            backButtonAction={handleBackClick}
          />
        </Grid>
      )}
      <Grid className="supplyOrderGrid">{sectionToDisplay()}</Grid>
      <LeaveSupplyOrder
        title={popUpTitle}
        buttonTitle={popUpButtonTitle}
        handleCancelLeavePopUp={handleCancelLeavePopUp}
        cancelLeaveOpen={cancelLeaveOpen}
        cancelConfirmText={cancelConfirmText}
        backBtnText={backBtnText}
        isOrderOverviewFlow={isOrderOverviewFlow}
      />
    </Grid>
  );
};

export default SupplyOrder;
