import {
  IInputField,
  Validation,
  ValidationStatus,
} from "../../core/interfaces/input.interface";
import { getDeepClone } from "../../util/ObjectFunctions";
import { Validator } from "../../util/order.validations";
import { getValidObj, updateErrorMessage } from "../../util/utilityFunctions";
import { IAcuteOrderPickUpRequest } from "./acuteOrderPickUpRequest.interface";

export class AcuteOrderPickUpRequestValidator {
  private _validator;
  constructor(defValidator = new Validator()) {
    this._validator = defValidator;
  }

  private noValidation(): Validation {
    return getValidObj();
  }

  private fieldToMethodMapping(
    field: string
  ): ((txt: string) => Validation) | undefined {
    const mapping = new Map<string, (txt: string) => Validation>([
      ["pickupLocation", this._validator.emptyCheck],
      [
        "specialInstructions",
        this._validator.optionalFieldValidSpecialCharacters,
      ],
      ["therapyDischargeDate", this._validator.dateValidation],
      ["roomNo", this._validator.roomValidation],
    ]);
    const validator = mapping.get(field);
    return validator ? validator : this.noValidation;
  }

  public validate(input: string, field: string) {
    try {
      const validator = this.fieldToMethodMapping(field)!;
      return validator(input);
    } catch (error) {
      console.log(`validator method for field ${field} is not configured`);
    }
  }

  public validateAll(
    data: IAcuteOrderPickUpRequest,
    updateDataIfUntouchedAndValidated: Function
  ): [ValidationStatus, IInputField | null] {
    let tempData = getDeepClone(data);
    let smallestOrderWithInvalidStatus: IInputField | null = null;
    Object.keys(tempData).forEach((key: string) => {
      let subTempData = tempData[key].value;
      if (tempData[key].hasChildsToValidate && subTempData) {
        let isAllValid = ValidationStatus.VALID;
        Object.keys(subTempData).forEach((subkey: string) => {
          let inputField = subTempData[subkey];
          smallestOrderWithInvalidStatus = this.validateInputField(
            inputField,
            smallestOrderWithInvalidStatus
          );
          if (inputField.valid === ValidationStatus.INVALID) {
            if (isAllValid !== ValidationStatus.INVALID) {
              isAllValid = ValidationStatus.INVALID;
            }
          }
        });
        tempData = {
          ...tempData,
          [key]: {
            ...Object(tempData)[key],
            valid: isAllValid,
            value: subTempData,
          },
        };
      } else {
        let inputField = tempData[key];
        smallestOrderWithInvalidStatus = this.validateInputField(
          inputField,
          smallestOrderWithInvalidStatus
        );
      }
    });
    updateDataIfUntouchedAndValidated(tempData);
    const ifAllValid = Object.keys(tempData)
      .filter(
        (key: string) => tempData[key].isDefaultValid || tempData[key].required
      )
      .every((key: string) => tempData[key].valid === ValidationStatus.VALID);
    return [
      ifAllValid ? ValidationStatus.VALID : ValidationStatus.INVALID,
      smallestOrderWithInvalidStatus,
    ];
  }

  private validateInputField(
    input: IInputField,
    smallestOrderWithInvalidStatus: IInputField | null
  ) {
    if (input.isOptional === true && input.valid !== ValidationStatus.VALID) {
      input.valid = ValidationStatus.VALID;
    } else if (input.required && input.valid === ValidationStatus.UNTOUCHED) {
      input.valid = ValidationStatus.INVALID;
    } else if (input.isDefaultValid && input.valid !== ValidationStatus.VALID) {
      input.valid = ValidationStatus.INVALID;
    }
    if (input.valid === ValidationStatus.INVALID && input.order) {
      if (
        !smallestOrderWithInvalidStatus ||
        (smallestOrderWithInvalidStatus.order &&
          input.order &&
          smallestOrderWithInvalidStatus.order > input.order)
      ) {
        smallestOrderWithInvalidStatus = input;
      }
    }
    updateErrorMessage(input);
    return smallestOrderWithInvalidStatus;
  }
}
