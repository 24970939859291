import moment from "moment";
import {
  DD_DELIVERY_SITE_TYPE_CONTENT,
  DD_DELIVERY_SITE_TYPE_CONTENT_LANDINGPAGE,
  DD_NEED_BY_TIME_CONTENT,
} from "../../../util/staticText";
import "./deliveryInformation.css";
import {
  convertStringToDate,
  getCodeFromText,
  getTextFromCode,
  getValuePostUPSValidation,
  makeCapitalEachWordInString,
} from "../../../util/utilityFunctions";
import { format } from "react-string-format";
import { useContext, useEffect, useState } from "react";
import { NewOrderValidator } from "../newOrder.validator";
import { Button, Tooltip, Typography } from "@mui/material";
import { getDeepClone } from "../../../util/ObjectFunctions";
import { IDeliveryInformation } from "../newOrder.interface";
import { Box, Grid, InputBase, TextField } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { getdropDownContent } from "../../../util/dropDownService";
import { getCurrentServerDateTime } from "../../../util/3meService";
import { ERROR_TO_ADD_DELIVERY_ADDRESS } from "../../../util/errorMsg";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { ValidationStatus } from "../../../core/interfaces/input.interface";
import { AuthContext, AuthContextType } from "../../../context/AuthContext";
import { IDeliveryInformationProps } from "./deliveryInformation.interface";
import { validateUpsAddress } from "../../../util/validateUpsAddressService";
import { MobileDisplayContext } from "../../../context/MobileDisplayContext";
import { ReactComponent as CalendarIcon } from "../../../assets/calendar.svg";
import { IAddress } from "../patientInformation/patientInformation.interface";
import { ReactComponent as WarningIcon } from "../../../assets/WarningIndicator.svg";
import { IFacility } from "../../manageProfile/facilityInformation/facility.interface";
import { InputWithLabel } from "../../../core/inputWithLabel/inputWithLabel.component";
import { CustomDropDown } from "../../../core/customDropdown/customDropdown.component";
import { DeliveryInfoReviewOrder } from "./reviewOrder/deliveryInfoReviewOrder.component";
import { ValidateUPSAddressInfo } from "../patientInformation/validateUPSAddressInfo.component";
import { CustomAutoComplete } from "../../../core/autoCompleteDropdown/autoCompleteDropdown.component";
import {
  InternalSignOnContext,
  InternalSignOnContextType,
} from "../../../context/InternalSignOnContext";
import { ExpressButton } from "../../../core/expressButton/expressButton.component";
import {
  alpabetNumbericEmptySpaceAllow,
  alphabetSpaceHyphenAllow,
  alphaNumericSpaceAllow,
} from "../../../util/replaceRegex";

export const DeliveryInformation = ({
  data,
  Validator = new NewOrderValidator(),
  setData,
  states,
  statesText,
  nextOrderOpen,
  isReviewOrder = false,
  editButtonClicked,
  isOrderSummary = false,
  deliveryInformation,
  setDeliveryInformation,
  updatedDeliveryInformation,
  setUpdatedDeliveryInformation,
  isEditingDeliveryInformation,
  setIsEditingDeliveryInformation,
  isNextButtonClicked,
  productInfo,
  newOrderObj,
}: IDeliveryInformationProps) => {
  const [message, setMessage] = useState("");
  const [needTimes, setNeedTimes] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const [openAddress, setOpenAddress] = useState(false);
  const [needTimesText, setNeedTimesText] = useState([]);
  const [deliverySites, setDeliverySites] = useState([]);
  const [address, setAddress] = useState<IAddress | null>();
  const [noAddressFound, setNoAddressFound] = useState(false);
  const [validator] = useState<NewOrderValidator>(Validator!);
  const [facility, setFacility] = useState<IFacility | null>();
  const [deliverySitesText, setDeliverySitesText] = useState([]);
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const [selectedUPSAddress, setSelectedUPSAddress] = useState("");
  const [validateAddress, setValidateUPSAddress] = useState<any>([]);
  const { isMobileScreen } = useContext(MobileDisplayContext);
  const [isDeliverySiteTypeForDefault, setIsDeliverySiteTypeForDefault] =
    useState(false);
  const [focusClasses, setFocusClasses] = useState({
    deliveryProductNeedByDate: "",
  });
  const [loadUI, setLoadUI] = useState<boolean>(false);
  const maxDate = newOrderObj?.maxDate;
  const setMaxDate = newOrderObj?.setMaxDate;
  const [currentServerDate, setCurrentServerDate] = useState<any>();
  const internalObj = useContext<InternalSignOnContextType | null>(
    InternalSignOnContext
  );
  useEffect(() => {
    if (!nextOrderOpen) {
      fetchDropDownContent();
      const type = checkDeliverySiteType();
      const isDefaultType = checkDeliverySiteTypeValue(type);
      setIsDeliverySiteTypeForDefault(isDefaultType ?? false);
      if (isDefaultType === true) {
        setAddress(null);
      }
    }
    handleCurrentServerDateTime();
  }, []);

  useEffect(() => {
    if (!newOrderObj?.IsLoadCustomDeliveryDate && needTimes.length > 0) {
      setDatetime();
    }
  }, [needTimes, currentServerDate]);

  useEffect(() => {
    if (
      !loadUI &&
      deliveryInformation?.deliverySiteType.value !== "" &&
      !isReviewOrder
    ) {
      setLoadUI(true);
      const type = checkDeliverySiteType();
      const isDefaultType = checkDeliverySiteTypeValue(type);
      updateUIBasedOnDeliveryType(
        type,
        isDefaultType! && type !== "101" ? false : true
      );
      if (isDefaultType === true) {
        setIsDeliverySiteTypeForDefault(true);
        setAddress(null);
      }
    }
  }, [deliveryInformation?.deliverySiteType.value]);

  useEffect(() => {
    if (isDeliverySiteTypeForDefault) {
      if (!nextOrderOpen && address) {
        const type = checkDeliverySiteType();
        if (type === "102") {
          if (data.IsSamePermanentAddress.value === "true") {
            const isAddressChanged = checkParmanentAddressChanged();
            if (!isAddressChanged) {
              getAddressFromPatientPermanentAddress(true);
            }
          } else {
            const isAddressChanged = checkCurrentAddressChanged();
            if (!isAddressChanged) {
              getAddressFromPatientCurrentAddress(true);
            }
          }
        } else if (type === "103") {
          const isAddressChanged = checkParmanentAddressChanged();
          if (!isAddressChanged) {
            getAddressFromPatientPermanentAddress(true);
          }
        }
      }
    } else {
      const type = updatedDeliveryInformation.deliverySiteType.value;
      if (
        (type === "102" && data.IsSamePermanentAddress.value === "true") ||
        type === "103"
      ) {
        const updatedAddress = getPermanentAddressForDefaultMode();
        updateDeliveryAddressForDefaultMode(updatedAddress);
      }
    }
  }, [data.address1, data.address2, data.city, data.state, data.zip]);

  useEffect(() => {
    if (isEditingDeliveryInformation) {
      if (!nextOrderOpen && address) {
        const type = checkDeliverySiteType();
        if (type === "102") {
          const isAddressChanged = checkCurrentAddressChanged();
          if (!isAddressChanged) {
            getAddressFromPatientCurrentAddress(true);
          }
        }
      }
    } else {
      const type = updatedDeliveryInformation.deliverySiteType.value;
      if (type === "102") {
        const updatedAddress = getCurrentAddressForDefaultMode();
        updateDeliveryAddressForDefaultMode(updatedAddress);
      }
    }
  }, [
    data.patientCurrentAddress1,
    data.patientCurrentAddress2,
    data.patientCurrentAddressCity,
    data.patientCurrentAddressState,
    data.patientCurrentAddressZip,
  ]);

  useEffect(() => {
    if (!nextOrderOpen) {
      const type = checkDeliverySiteType();
      if (type === "102") {
        checkCurrentAddressChanged();
      }
    }
  }, [data.IsSamePermanentAddress]);

  useEffect(() => {
    if (!nextOrderOpen) {
      if (
        deliveryInformation?.deliverySiteType.valid === ValidationStatus.VALID
      ) {
        const type = checkDeliverySiteType();
        updateUIBasedOnDeliveryType(type, false);
      }
    }
  }, [deliverySites]);

  useEffect(() => {
    if (
      updatedDeliveryInformation.deliveryProductNeedByDate.value ===
      "Need to change"
    ) {
      setDatetime();
    }
  }, [updatedDeliveryInformation.deliveryProductNeedByDate]);

  useEffect(() => {
    if (internalObj?.isVACOrderSSO && !isReviewOrder) {
      const type = checkDeliverySiteType();
      if (type === "101") {
        let tempData: IDeliveryInformation = {
          ...deliveryInformation,
          deliveryFacilityName: validateAndSetValue(
            deliveryInformation,
            "deliveryFacilityName",
            data.requestingFacility?.value?.accountName
          ),
          deliveryAddressLine1: validateAndSetValue(
            deliveryInformation,
            "deliveryAddressLine1",
            data.requestingFacility?.value?.address1
          ),
          deliveryAddressLine2: validateAndSetValue(
            deliveryInformation,
            "deliveryAddressLine2",
            data.requestingFacility?.value?.address2
          ),
          deliveryCity: validateAndSetValue(
            deliveryInformation,
            "deliveryCity",
            data.requestingFacility?.value?.city
          ),
          deliveryState: validateAndSetValue(
            deliveryInformation,
            "deliveryState",
            data.requestingFacility?.value?.state
          ),
          deliveryZipCode: validateAndSetValue(
            deliveryInformation,
            "deliveryZipCode",
            data.requestingFacility?.value?.zip
          ),
        };
        setUpdatedDeliveryInformation(tempData);
      }
    }
  }, [JSON.stringify(data.requestingFacility?.value)]);

  const fetchDropDownContent = async () => {
    try {
      const ddContent = internalObj?.isVACOrderSSO
        ? format(
            "{0},{1}",
            DD_NEED_BY_TIME_CONTENT,
            DD_DELIVERY_SITE_TYPE_CONTENT_LANDINGPAGE
          )
        : format(
            "{0},{1}",
            DD_NEED_BY_TIME_CONTENT,
            DD_DELIVERY_SITE_TYPE_CONTENT
          );
      const data = await getdropDownContent(ddContent);
      if (data.items.length > 0) {
        const needTimeObject = data.items.filter(
          (item: { name: string }) => item.name === DD_NEED_BY_TIME_CONTENT
        );
        const needTimeData = needTimeObject[0].data.sort(
          (a: { order: number }, b: { order: number }) =>
            a.order > b.order ? 1 : -1
        );
        setNeedTimes(needTimeData);
        setNeedTimesText(needTimeData.map((x: { text: string }) => x.text));
        const content = internalObj?.isVACOrderSSO
          ? DD_DELIVERY_SITE_TYPE_CONTENT_LANDINGPAGE
          : DD_DELIVERY_SITE_TYPE_CONTENT;
        const deliverySiteObject = data.items.filter(
          (item: { name: string }) => item.name === content
        );

        const deliverySiteData = deliverySiteObject[0].data.sort(
          (a: { order: number }, b: { order: number }) =>
            a.order > b.order ? 1 : -1
        );
        setDeliverySites(deliverySiteData);
        setDeliverySitesText(
          deliverySiteData.map((x: { text: string }) => x.text)
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const editLocationClicked = async () => {
    setDeliveryInformation(updatedDeliveryInformation);
    const type = updatedDeliveryInformation.deliverySiteType.value;
    const isDefaultType = checkDeliverySiteTypeValue(type);
    setIsDeliverySiteTypeForDefault(isDefaultType ?? false);
    if (isDefaultType === true) {
      setFacility(null);
      setAddress(null);
    }
    updateUIBasedOnDeliveryType(
      type,
      isDefaultType! && type !== "101" ? false : true
    );
    setIsEditingDeliveryInformation(true);
  };

  const cancelEditModel = () => {
    setAddress(null);
    setFacility(null);
    resetDeliveryInformation();
    setIsEditingDeliveryInformation(false);
  };

  const updateEditModel = () => {
    setUpdatedDeliveryInformation(deliveryInformation);
    setIsEditingDeliveryInformation(false);
  };

  const setDatetime = async () => {
    if (currentServerDate) {
      const formatMaxDate = moment(currentServerDate.currentCstTime).add(
        7,
        "days"
      );
      newOrderObj?.setMaxDate(formatMaxDate);
      const dateTime = moment(currentServerDate.currentCstTime)
        .add(30, "hours")
        .toDate();
      const formattedDate = moment(dateTime).format("MM/DD/YYYY");
      var time = moment(roundToNearestMinutes(dateTime)).format("h:mm A");
      let updateTime: any;
      if (
        (dateTime.getHours() === 21 && dateTime.getMinutes() === 0) ||
        (dateTime.getHours() === 6 && dateTime.getMinutes() === 0)
      ) {
        updateTime = {
          ...updatedDeliveryInformation,
          deliveryProductNeedByDate: {
            ...updatedDeliveryInformation.deliveryProductNeedByDate,
            errorMessage: null,
            valid: ValidationStatus.VALID,
            value: formattedDate,
          },
          deliveryProductNeedByTime: {
            ...updatedDeliveryInformation.deliveryProductNeedByTime,
            errorMessage: null,
            valid: ValidationStatus.VALID,
            value: getCodeFromText(needTimes, time),
          },
        };
      } else if (dateTime.getHours() >= 21 || dateTime.getHours() < 6) {
        if (dateTime.getHours() <= 5) {
          updateTime = {
            ...updatedDeliveryInformation,
            deliveryProductNeedByDate: {
              ...updatedDeliveryInformation.deliveryProductNeedByDate,
              errorMessage: null,
              valid: ValidationStatus.VALID,
              value: formattedDate,
            },
            deliveryProductNeedByTime: {
              ...updatedDeliveryInformation.deliveryProductNeedByTime,
              errorMessage: null,
              valid: ValidationStatus.VALID,
              value: getCodeFromText(needTimes, needTimesText[0]),
            },
          };
        } else {
          //assign to next day first slot
          const addedExtraDay = moment(dateTime)
            .add(1, "days")
            .format("MM/DD/YYYY");
          updateTime = {
            ...updatedDeliveryInformation,
            deliveryProductNeedByDate: {
              ...updatedDeliveryInformation.deliveryProductNeedByDate,
              errorMessage: null,
              valid: ValidationStatus.VALID,
              value: addedExtraDay,
            },
            deliveryProductNeedByTime: {
              ...updatedDeliveryInformation.deliveryProductNeedByTime,
              errorMessage: null,
              valid: ValidationStatus.VALID,
              value: getCodeFromText(needTimes, needTimesText[0]),
            },
          };
        }
      } else {
        updateTime = {
          ...updatedDeliveryInformation,
          deliveryProductNeedByDate: {
            ...updatedDeliveryInformation.deliveryProductNeedByDate,
            errorMessage: null,
            valid: ValidationStatus.VALID,
            value: formattedDate,
          },
          deliveryProductNeedByTime: {
            ...updatedDeliveryInformation.deliveryProductNeedByTime,
            errorMessage: null,
            valid: ValidationStatus.VALID,
            value: getCodeFromText(needTimes, time),
          },
        };
      }
      setUpdatedDeliveryInformation(updateTime);
      if (!isReviewOrder) {
        setDeliveryInformation({
          ...deliveryInformation,
          deliveryProductNeedByDate: updateTime.deliveryProductNeedByDate,
          deliveryProductNeedByTime: updateTime.deliveryProductNeedByTime,
        });
      }
    }
  };

  const roundToNearestMinutes = (input: Date) => {
    if (input.getMinutes() >= 0 && input.getMinutes() <= 15) {
      return moment(input).add(-input.getMinutes(), "m");
    }
    if (input.getMinutes() >= 16 && input.getMinutes() <= 44) {
      if (input.getMinutes() < 30) {
        return moment(input).add(30 - input.getMinutes(), "m");
      } else if (input.getMinutes() > 30) {
        return moment(input).add(30 - input.getMinutes(), "m");
      } else {
        return input;
      }
    }
    if (input.getMinutes() >= 45 && input.getMinutes() <= 59) {
      return moment(input).add(60 - input.getMinutes(), "m");
    }
    return input;
  };

  const validateUPSAddress = async (obj: IDeliveryInformation) => {
    const reqParams = {
      AddressLine1: obj.deliveryAddressLine1.value,
      AddressLine2: obj.deliveryAddressLine2.value,
      City: obj.deliveryCity.value,
      State: obj.deliveryState.value,
      zipCode: obj.deliveryZipCode.value,
    };
    setOpenAddress(true);
    setIsLoading(true);
    const response = await validateUpsAddress(reqParams);
    if (response && response.item) {
      if (response.item.status !== "ExactMatch") {
        if (response.item.addresses.length > 0) {
          setNoAddressFound(false);
          setValidateUPSAddress(response.item.addresses);
          if (
            JSON.stringify(response.item.addresses[0]) ===
            JSON.stringify(reqParams)
          ) {
          } else {
            setSelectedUPSAddress(JSON.stringify(response.item.addresses[0]));
          }
        } else {
          setSelectedUPSAddress("");
          setMessage("No address found.");
          setNoAddressFound(true);
        }
        setIsLoading(false);
      } else {
        setOpenAddress(false);
      }
    } else {
      setMessage("Unable to process address validation. Please continue.");
      setIsLoading(false);
      setNoAddressFound(true);
    }
  };

  const updateUIBasedOnDeliveryType = (
    type: string | null,
    canReset: boolean
  ) => {
    if (type) {
      const isDefaultType = checkDeliverySiteTypeValue(type);
      setIsDeliverySiteTypeForDefault(isDefaultType ?? false);
      updateDeliveryAddress(type, canReset);
    }
  };

  const checkDeliverySiteType = (): string | null => {
    const input = updatedDeliveryInformation.deliverySiteType.value;
    return input;
  };

  const checkDeliverySiteTypeValue = (input: string | null): boolean | null => {
    if (input) {
      // "My Facility" - 101
      // "Patient’s Current Residence" - 102
      // "Patient’s Permanent Residence" - 103
      return !(input === "101" || input === "102" || input === "103");
    }
    return null;
  };

  const updateDeliveryAddress = (type: string, canReset: boolean) => {
    // "My Facility" - 101
    // "Patient’s Current Residence" - 102
    // "Patient’s Permanent Residence" - 103
    switch (type) {
      case "101":
        showFacilityDetails();
        break;
      case "102":
        getAddressFromPatientCurrentAddress(false);
        break;
      case "103":
        getAddressFromPatientPermanentAddress(false);
        break;
      default:
        if (canReset) {
          resetFacilityDetails();
        }
        setAddress(null);
        break;
    }
  };

  const updatedAuthObjFacilityVal = () => {
    if (authObj && authObj.registeredFaciltyAddress) {
      let facility = authObj.registeredFaciltyAddress;
      //replacing with " " because facility name-Aurora Vna Of Wisconsin/kci Express replacing with just "" will merge the words
      facility.accountName = facility.accountName
        ? facility.accountName.replace(alpabetNumbericEmptySpaceAllow, " ")
        : "";
      facility.address1 = facility.address1
        ? facility.address1.replace(alphaNumericSpaceAllow, " ")
        : "";
      facility.address2 = facility.address2
        ? facility.address2.replace(alphaNumericSpaceAllow, " ")
        : "";
      facility.city = facility.city
        ? facility.city.replace(alphabetSpaceHyphenAllow, " ")
        : "";
      return facility;
    }
    return null;
  };

  //this method is called only in case "My Facility" - 101 so replace method wont effect the existing flows.
  const showFacilityDetails = () => {
    setAddress(null);
    if (authObj && authObj.registeredFaciltyAddress) {
      const facility = updatedAuthObjFacilityVal();
      setFacility(facility);
      manualPopulateFacilityDetails(facility, null, true);
    } else if (internalObj?.isVACOrderSSO && data.requestingFacility?.value) {
      setFacility(data.requestingFacility?.value);
      manualPopulateFacilityDetails(data.requestingFacility?.value, null, true);
    } else {
      setFacility(null);
    }
  };

  const getAddressFromPatientCurrentAddress = (
    isUpdatingFromDataChange: boolean
  ) => {
    if (data.IsSamePermanentAddress.value === "true") {
      getAddressFromPatientPermanentAddress(isUpdatingFromDataChange);
    } else {
      if (
        data.patientCurrentAddress1.valid === ValidationStatus.VALID &&
        data.patientCurrentAddress1.value !== "" &&
        data.patientCurrentAddress2.valid === ValidationStatus.VALID &&
        data.patientCurrentAddressCity.valid === ValidationStatus.VALID &&
        data.patientCurrentAddressCity.value !== "" &&
        data.patientCurrentAddressState.valid === ValidationStatus.VALID &&
        data.patientCurrentAddressState.value !== "" &&
        data.patientCurrentAddressZip.valid === ValidationStatus.VALID &&
        data.patientCurrentAddressZip.value !== "" &&
        data.patientCurrentAddressZip.value.length === 5
      ) {
        const currentAddress: IAddress = {
          address1: data.patientCurrentAddress1.value,
          address2: data.patientCurrentAddress2.value,
          city: data.patientCurrentAddressCity.value,
          state: data.patientCurrentAddressState.value,
          zip: data.patientCurrentAddressZip.value,
        };
        setAddress(currentAddress);
        manualPopulateFacilityDetails(null, currentAddress!, false);
      } else {
        if (isUpdatingFromDataChange) {
          resetDeliverySiteType();
        }
        setAddress(null);
      }
    }
  };

  const getAddressFromPatientPermanentAddress = (
    isUpdatingFromDataChange: boolean
  ) => {
    if (
      data.address1.valid === ValidationStatus.VALID &&
      data.address1.value !== "" &&
      data.address2.valid === ValidationStatus.VALID &&
      data.city.valid === ValidationStatus.VALID &&
      data.city.value !== "" &&
      data.state.valid === ValidationStatus.VALID &&
      data.state.value !== "" &&
      data.zip.valid === ValidationStatus.VALID &&
      data.zip.value !== "" &&
      data.zip.value.length === 5
    ) {
      const permanentAddress: IAddress = {
        address1: data.address1.value,
        address2: data.address2.value,
        city: data.city.value,
        state: data.state.value,
        zip: data.zip.value,
      };
      setAddress(permanentAddress);
      manualPopulateFacilityDetails(null, permanentAddress!, false);
    } else {
      if (isUpdatingFromDataChange) {
        resetDeliverySiteType();
      }
      setAddress(null);
    }
  };

  const handleCurrentServerDateTime = async () => {
    const currentDateRes = await getCurrentServerDateTime();
    if (currentDateRes) {
      setCurrentServerDate(currentDateRes);
    }
  };

  const handleAddressConitnueButton = () => {
    let upsValidateAddr: any;
    if (selectedUPSAddress.includes("enteredAddress")) {
      if (selectedUPSAddress && selectedUPSAddress.length >= 2) {
        upsValidateAddr = JSON.parse(
          selectedUPSAddress.split("enteredAddress-")[1]
        );
      }
    } else if (selectedUPSAddress) {
      upsValidateAddr = JSON.parse(selectedUPSAddress);
    }
    if (upsValidateAddr) {
      const tempData = {
        ...deliveryInformation,
        deliveryAddressLine1: {
          valid: ValidationStatus.VALID,
          value: getValuePostUPSValidation(
            Validator,
            upsValidateAddr.addressLine1,
            "address1"
          ),
        },
        deliveryAddressLine2: {
          valid: ValidationStatus.VALID,
          value:
            upsValidateAddr.addressLine2 === null
              ? ""
              : getValuePostUPSValidation(
                  Validator,
                  upsValidateAddr.addressLine2,
                  "address2"
                ),
        },
        deliveryCity: {
          valid: ValidationStatus.VALID,
          value: getValuePostUPSValidation(
            Validator,
            upsValidateAddr.city,
            "cityValidation"
          ),
        },
        deliveryState: {
          valid: ValidationStatus.VALID,
          value: upsValidateAddr.state,
        },
        deliveryZipCode: {
          valid: ValidationStatus.VALID,
          value: upsValidateAddr.zipCode,
        },
      };
      setDeliveryInformation(tempData);
      setAddress(null);
    }
    setOpenAddress(false);
  };

  const resetFacilityDetails = () => {
    setDeliveryInformation((deliveryInformation: IDeliveryInformation) => ({
      ...deliveryInformation,
      deliveryFacilityName: {
        ...deliveryInformation.deliveryFacilityName,
        valid: ValidationStatus.UNTOUCHED,
        value: "",
      },
      deliveryAddressLine1: {
        ...deliveryInformation.deliveryAddressLine1,
        valid: ValidationStatus.UNTOUCHED,
        value: "",
      },
      deliveryAddressLine2: {
        ...deliveryInformation.deliveryAddressLine2,
        valid: ValidationStatus.VALID,
        value: "",
      },
      deliveryCity: {
        ...deliveryInformation.deliveryCity,
        valid: ValidationStatus.UNTOUCHED,
        value: "",
      },
      deliveryState: {
        ...deliveryInformation.deliveryState,
        valid: ValidationStatus.UNTOUCHED,
        value: "",
      },
      deliveryZipCode: {
        ...deliveryInformation.deliveryZipCode,
        valid: ValidationStatus.UNTOUCHED,
        value: "",
      },
    }));
  };

  const resetDeliveryInformation = () => {
    setDeliveryInformation((deliveryInformation: IDeliveryInformation) => ({
      ...deliveryInformation,
      deliveryFacilityName: {
        ...deliveryInformation.deliveryFacilityName,
        valid: ValidationStatus.UNTOUCHED,
        value: "",
      },
      deliveryAddressLine1: {
        ...deliveryInformation.deliveryAddressLine1,
        valid: ValidationStatus.UNTOUCHED,
        value: "",
      },
      deliveryAddressLine2: {
        ...deliveryInformation.deliveryAddressLine2,
        valid: ValidationStatus.VALID,
        value: "",
      },
      deliveryCity: {
        ...deliveryInformation.deliveryCity,
        valid: ValidationStatus.UNTOUCHED,
        value: "",
      },
      deliveryState: {
        ...deliveryInformation.deliveryState,
        valid: ValidationStatus.UNTOUCHED,
        value: "",
      },
      deliveryZipCode: {
        ...deliveryInformation.deliveryZipCode,
        valid: ValidationStatus.UNTOUCHED,
        value: "",
      },
      deliverySiteType: {
        ...deliveryInformation.deliverySiteType,
        valid: updatedDeliveryInformation.deliverySiteType.valid,
        value: updatedDeliveryInformation.deliverySiteType.value,
      },
    }));
  };

  const manualPopulateFacilityDetails = (
    facility: IFacility | null,
    address: IAddress | null,
    isMyFacility: boolean
  ) => {
    let tempData: IDeliveryInformation = {
      ...deliveryInformation,
      deliveryFacilityName: validateAndSetValue(
        deliveryInformation,
        "deliveryFacilityName",
        isMyFacility
          ? facility?.accountName
          : isDeliverySiteTypeForDefault
          ? deliveryInformation.deliveryFacilityName.value
          : `${data.firstName.value} ${data.lastName.value}`
      ),
      deliveryAddressLine1: validateAndSetValue(
        deliveryInformation,
        "deliveryAddressLine1",
        isMyFacility ? facility?.address1 : address?.address1
      ),
      deliveryAddressLine2: validateAndSetValue(
        deliveryInformation,
        "deliveryAddressLine2",
        isMyFacility ? facility?.address2 : address?.address2
      ),
      deliveryCity: validateAndSetValue(
        deliveryInformation,
        "deliveryCity",
        isMyFacility ? facility?.city : address?.city
      ),
      deliveryState: validateAndSetValue(
        deliveryInformation,
        "deliveryState",
        isMyFacility ? facility?.state : address?.state
      ),
      deliveryZipCode: validateAndSetValue(
        deliveryInformation,
        "deliveryZipCode",
        isMyFacility ? facility?.zip : address?.zip
      ),
    };
    setDeliveryInformation(tempData);
  };

  const validateAndSetValue = (
    deliveryInfo: IDeliveryInformation,
    name: string,
    value: any
  ) => {
    const isValid = validator.validate(value ?? "", name);
    return {
      ...Object(deliveryInfo)[name],
      valid: isValid?.status,
      value: value ? value : "",
    };
  };

  const checkCurrentAddressChanged = (): boolean => {
    if (data.IsSamePermanentAddress.value === "true") {
      return checkParmanentAddressChanged();
    } else {
      if (address && checkCurrentAddressValid()) {
        if (
          data.patientCurrentAddress1.value !== address.address1 ||
          data.patientCurrentAddress2.value !== address.address2 ||
          data.patientCurrentAddressCity.value !== address.city ||
          data.patientCurrentAddressState.value !== address.state ||
          data.patientCurrentAddressZip.value !== address.zip
        ) {
          resetDeliverySiteType();
          return true;
        }
      }
      return false;
    }
  };

  const checkCurrentAddressValid = (): boolean => {
    return (
      data.patientCurrentAddress1.valid === ValidationStatus.VALID &&
      data.patientCurrentAddress2.valid === ValidationStatus.VALID &&
      data.patientCurrentAddressCity.valid === ValidationStatus.VALID &&
      data.patientCurrentAddressState.valid === ValidationStatus.VALID &&
      data.patientCurrentAddressZip.valid === ValidationStatus.VALID
    );
  };

  const checkParmanentAddressChanged = (): boolean => {
    if (address && checkPermanentAddressValid()) {
      if (
        data.address1.value !== address.address1 ||
        data.address2.value !== address.address2 ||
        data.city.value !== address.city ||
        data.state.value !== address.state ||
        data.zip.value !== address.zip
      ) {
        resetDeliverySiteType();
        return true;
      }
    }
    return false;
  };

  const checkPermanentAddressValid = (): boolean => {
    return (
      data.address1.valid === ValidationStatus.VALID &&
      data.address2.valid === ValidationStatus.VALID &&
      data.city.valid === ValidationStatus.VALID &&
      data.state.valid === ValidationStatus.VALID &&
      data.zip.valid === ValidationStatus.VALID
    );
  };

  const resetDeliverySiteType = () => {
    setAddress(null);
    setDeliveryInformation({
      ...deliveryInformation,
      deliverySiteType: {
        ...deliveryInformation.deliverySiteType,
        valid: ValidationStatus.UNTOUCHED,
        value: "",
      },
    });
  };

  const validateAndSetData = (e: any) => {
    newOrderObj?.setIsHandleChangeTriggered(true);
    let value = e.target.value;
    if (e.target.name === "deliveryProductNeedByTime") {
      newOrderObj?.setIsLoadCustomDeliveryDate(true);
      value = getCodeFromText(needTimes, e.target.value);
      const isValid = validator.validate(value, e.target.name);
      const tempData = {
        ...updatedDeliveryInformation,
        [e.target.name]: {
          ...Object(updatedDeliveryInformation)[e.target.name],
          valid: isValid!.status,
          value: value,
        },
      };
      setUpdatedDeliveryInformation(tempData);
      setDeliveryInformation({
        ...deliveryInformation,
        deliveryProductNeedByTime: tempData.deliveryProductNeedByTime,
      });
      return;
    } else if (e.target.name === "deliverySiteType") {
      resetFacilityDetails();
      value = getCodeFromText(deliverySites, e.target.value);
      if (value !== "101") {
        setFacility(null);
      }
      const isDefaultType = checkDeliverySiteTypeValue(value);
      updateUIBasedOnDeliveryType(
        value,
        isDefaultType! && isDeliverySiteTypeForDefault && value !== "101"
          ? false
          : true
      );
    }
    const isValid = validator.validate(value, e.target.name);
    setDeliveryInformation((data: IDeliveryInformation) => ({
      ...data,
      [e.target.name]: {
        ...Object(data)[e.target.name],
        valid: isValid!.status,
        value: value,
      },
    }));
    const sa = Object.assign({}, deliveryInformation, {
      [e.target.name]: {
        ...Object(data)[e.target.name],
        valid: isValid!.status,
        value: value,
      },
    });
    const obj = getDeepClone(sa);
    if (e.target.name === "deliveryZipCode") {
      if (
        obj.deliveryAddressLine1.valid === ValidationStatus.VALID &&
        obj.deliveryAddressLine2.valid === ValidationStatus.VALID &&
        obj.deliveryCity.valid === ValidationStatus.VALID &&
        obj.deliveryState.valid === ValidationStatus.VALID &&
        obj.deliveryZipCode.valid === ValidationStatus.VALID
      ) {
        e.target.blur();
        const address: IAddress = {
          address1: obj.deliveryAddressLine1.value,
          address2: obj.deliveryAddressLine2.value,
          city: obj.deliveryCity.value,
          state: obj.deliveryState.value,
          zip: obj.deliveryZipCode.value,
        };
        setAddress(address);
        manualPopulateFacilityDetails(null, address!, false);
        validateUPSAddress(obj);
      }
    }
  };

  const validateAndSetStateData = async (e: any, val: string, name: string) => {
    newOrderObj?.setIsHandleChangeTriggered(true);
    let value = getCodeFromText(states, val);
    let isValid = validator.validate(value, name);
    setDeliveryInformation((data: IDeliveryInformation) => ({
      ...data,
      [name]: {
        ...Object(data)[name],
        valid: isValid!.status,
        value: value,
      },
    }));
  };

  function removeAllBefore(array: any, time: string) {
    const index = needTimesText.findIndex((x: string) => x === time);
    if (index !== -1) {
      return array.splice(index, array.length - 1);
    }
    return array;
  }

  const validateAndSetDate = async (date: string | null | undefined) => {
    newOrderObj?.setIsHandleChangeTriggered(true);
    newOrderObj?.setIsLoadCustomDeliveryDate(true);
    const formattedDate = date === null ? null : convertStringToDate(date);
    const isValid = validator.validate(
      formattedDate!,
      "deliveryProductNeedByDate"
    );
    const tempData = {
      ...updatedDeliveryInformation,
      deliveryProductNeedByDate: {
        ...updatedDeliveryInformation.deliveryProductNeedByDate,
        errorMessage: null,
        valid: isValid!.status,
        value: formattedDate,
      },
      deliveryProductNeedByTime: {
        ...updatedDeliveryInformation.deliveryProductNeedByTime,
        errorMessage: null,
        valid: ValidationStatus.UNTOUCHED,
        value: "",
      },
    };
    setUpdatedDeliveryInformation(tempData);
    setDeliveryInformation({
      ...deliveryInformation,
      deliveryProductNeedByDate: tempData.deliveryProductNeedByDate,
      deliveryProductNeedByTime: tempData.deliveryProductNeedByTime,
    });
    const currentDateRes = await getCurrentServerDateTime();
    if (currentDateRes) {
      if (
        formattedDate === convertStringToDate(currentDateRes.currentCstTime)
      ) {
        const dateTime = moment(currentDateRes.currentCstTime)
          .add(5, "hours")
          .toDate();
        const time = moment(roundToNearestMinutes(dateTime)).format("h:mm A");
        const arr = removeAllBefore(needTimesText, time);
        setNeedTimesText(arr);
      } else {
        const arr: any = needTimes.map((x: { text: string }) => x.text);
        setNeedTimesText(arr);
      }
    }
  };

  const setClasses = (e: any, classname: string) => {
    setFocusClasses(
      Object.assign({}, focusClasses, { [e.target.name]: classname })
    );
  };

  const checkUpdateDisabled = (): boolean => {
    const type = checkDeliverySiteType();
    const isDefaultType = checkDeliverySiteTypeValue(type);
    if (isDefaultType) {
      const isValid = Object.keys(deliveryInformation)
        .filter((x) => deliveryInformation[x].valid)
        .every(
          (x: string) =>
            deliveryInformation[x].valid === ValidationStatus.VALID ||
            (deliveryInformation[x].isDefaultValid &&
              (deliveryInformation[x].value === "" ||
                (deliveryInformation[x].value !== "" &&
                  deliveryInformation[x].valid === ValidationStatus.VALID)))
        );
      return !isValid;
    } else {
      if (type === "102" || type === "103") {
        const isValid = Object.keys(deliveryInformation)
          .filter((x) => deliveryInformation[x].valid)
          .every(
            (x: string) =>
              deliveryInformation[x].valid === ValidationStatus.VALID ||
              deliveryInformation[x].isDefaultValid
          );
        return !isValid;
      }
      return false;
    }
  };

  const updateDeliveryAddressForDefaultMode = (address: IAddress | null) => {
    const tempData = {
      ...updatedDeliveryInformation,
      deliveryAddressLine1: validateAndSetValue(
        updatedDeliveryInformation,
        "deliveryAddressLine1",
        address ? address.address1 : ""
      ),
      deliveryAddressLine2: validateAndSetValue(
        updatedDeliveryInformation,
        "deliveryAddressLine2",
        address ? address.address2 : ""
      ),
      deliveryCity: validateAndSetValue(
        updatedDeliveryInformation,
        "deliveryCity",
        address ? address.city : ""
      ),
      deliveryState: validateAndSetValue(
        updatedDeliveryInformation,
        "deliveryState",
        address ? address.state : ""
      ),
      deliveryZipCode: validateAndSetValue(
        updatedDeliveryInformation,
        "deliveryZipCode",
        address ? address.zip : ""
      ),
    };
    setUpdatedDeliveryInformation(tempData);
  };

  const getPermanentAddressForDefaultMode = (): IAddress | null => {
    if (
      data.address1.valid === ValidationStatus.VALID &&
      data.address1.value !== "" &&
      data.address2.valid === ValidationStatus.VALID &&
      data.city.valid === ValidationStatus.VALID &&
      data.city.value !== "" &&
      data.state.valid === ValidationStatus.VALID &&
      data.state.value !== "" &&
      data.zip.valid === ValidationStatus.VALID &&
      data.zip.value !== "" &&
      data.zip.value.length === 5
    ) {
      const permanentAddress: IAddress = {
        address1: data.address1.value,
        address2: data.address2.value,
        city: data.city.value,
        state: data.state.value,
        zip: data.zip.value,
      };
      return permanentAddress;
    }
    return null;
  };

  const getCurrentAddressForDefaultMode = (): IAddress | null => {
    if (data.IsSamePermanentAddress.value === "true") {
      return getPermanentAddressForDefaultMode();
    } else {
      if (
        data.patientCurrentAddress1.valid === ValidationStatus.VALID &&
        data.patientCurrentAddress1.value !== "" &&
        data.patientCurrentAddress2.valid === ValidationStatus.VALID &&
        data.patientCurrentAddressCity.valid === ValidationStatus.VALID &&
        data.patientCurrentAddressCity.value !== "" &&
        data.patientCurrentAddressState.valid === ValidationStatus.VALID &&
        data.patientCurrentAddressState.value !== "" &&
        data.patientCurrentAddressZip.valid === ValidationStatus.VALID &&
        data.patientCurrentAddressZip.value !== "" &&
        data.patientCurrentAddressZip.value.length === 5
      ) {
        const currentAddress: IAddress = {
          address1: data.patientCurrentAddress1.value,
          address2: data.patientCurrentAddress2.value,
          city: data.patientCurrentAddressCity.value,
          state: data.patientCurrentAddressState.value,
          zip: data.patientCurrentAddressZip.value,
        };
        return currentAddress;
      }
      return null;
    }
  };

  return (
    <div className="delivery-information-component">
      {!isReviewOrder && (
        <div className="delivery-information">
          <div className="delivery-info-header">
            <h2
              className="delivery-information-title"
              data-testid="delivery-information-title"
            >
              Delivery Information
            </h2>
            <Tooltip
              classes={{
                tooltip: "deliveryInfotooltip",
                popper: "deliveryInfopopper",
              }}
              title={
                <div className="tooltip-content">
                  <div className="tooltip-header">
                    Please note
                    <div className="tooltip-body">
                      Release times may vary based on payer requirements and
                      availability of necessary components needed for order
                      release.
                    </div>
                  </div>
                </div>
              }
              data-testid="deliveryInfoTooltipTest"
            >
              {isMobileScreen ? (
                <div className="informationToolTipIcon">
                  <InfoOutlinedIcon
                    color="primary"
                    classes={{ root: "tooltipRoot" }}
                  />
                </div>
              ) : (
                <InfoOutlinedIcon
                  color="primary"
                  classes={{ root: "tooltipRoot" }}
                />
              )}
            </Tooltip>
          </div>
          <Box
            className="delivery-information-box-container"
            sx={{ flexGrow: 1 }}
          >
            <Grid
              className="delivery-information-grid-container"
              container
              spacing={2}
            >
              <Grid
                className="delivery-information-grid-item"
                item
                xs={isMobileScreen ? 12 : 6}
              >
                <InputWithLabel
                  error={
                    updatedDeliveryInformation.deliveryProductNeedByDate
                      .valid === ValidationStatus.INVALID
                  }
                  id={
                    updatedDeliveryInformation.deliveryProductNeedByDate
                      .componentId!
                  }
                  isRequired={true}
                  isShowWarning={true}
                  label={
                    updatedDeliveryInformation.deliveryProductNeedByDate.title!
                  }
                  labelClassName={focusClasses.deliveryProductNeedByDate}
                  testId="delivery-information-product-need-date"
                  warningMessage={
                    updatedDeliveryInformation.deliveryProductNeedByDate
                      .errorMessage
                  }
                >
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      components={{ OpenPickerIcon: CalendarIcon }}
                      InputAdornmentProps={{
                        classes: {
                          root: "adornedRoot",
                        },
                      }}
                      InputProps={{
                        classes: {
                          root: `deliveryProductNeedByDate ${
                            updatedDeliveryInformation
                              ?.deliveryProductNeedByDate.valid ===
                            ValidationStatus.INVALID
                              ? "showError"
                              : "noError"
                          }`,
                          input: "input",
                          notchedOutline: "outline",
                        },
                      }}
                      onChange={(value: any) => validateAndSetDate(value)}
                      renderInput={(params: any) => {
                        params.error = false;
                        params.inputProps!.placeholder = "__/__/____";
                        return (
                          <TextField
                            id={
                              updatedDeliveryInformation
                                .deliveryProductNeedByDate.id!
                            }
                            name="deliveryProductNeedByDate"
                            onFocus={(e) => setClasses(e, "Mui-focused")}
                            onBlur={(e) => setClasses(e, "")}
                            {...params}
                          />
                        );
                      }}
                      value={
                        updatedDeliveryInformation?.deliveryProductNeedByDate
                          .value
                      }
                      maxDate={maxDate}
                    />
                  </LocalizationProvider>
                </InputWithLabel>
              </Grid>
              <Grid
                className="delivery-information-grid-item"
                item
                xs={isMobileScreen ? 12 : 6}
              >
                <InputWithLabel
                  error={
                    updatedDeliveryInformation.deliveryProductNeedByTime
                      .valid === ValidationStatus.INVALID
                  }
                  id={
                    updatedDeliveryInformation.deliveryProductNeedByTime
                      .componentId!
                  }
                  isRequired={true}
                  isShowWarning={true}
                  label={
                    updatedDeliveryInformation.deliveryProductNeedByTime.title!
                  }
                  testId="delivery-information-need-by-time"
                  warningMessage={
                    updatedDeliveryInformation.deliveryProductNeedByTime
                      .errorMessage
                  }
                  isDropdown={true}
                >
                  <CustomDropDown
                    handleChange={validateAndSetData}
                    menuItem={needTimesText}
                    name="deliveryProductNeedByTime"
                    placeHolder="Select Time"
                    selectpropsClassName={
                      updatedDeliveryInformation.deliveryProductNeedByTime.value
                        ? "delivery-information-select"
                        : "placeHolder"
                    }
                    selectClassName={
                      updatedDeliveryInformation.deliveryProductNeedByTime.value
                        ? "delivery-information-input"
                        : "placeHolder"
                    }
                    testId="delivery-information-deliveryProductNeedByTime"
                    value={
                      updatedDeliveryInformation?.deliveryProductNeedByTime
                        .value
                        ? getTextFromCode(
                            needTimes,
                            updatedDeliveryInformation.deliveryProductNeedByTime
                              .value
                          )
                        : null
                    }
                  />
                </InputWithLabel>
              </Grid>
            </Grid>
          </Box>
          <div
            className="delivery-address-div"
            data-testid="delivery-address-div"
          >
            <h2
              className={`delivery-address-title${
                authObj &&
                authObj.registeredFaciltyAddress &&
                authObj.registeredFaciltyAddress.readyCareFlag === "Y" &&
                productInfo.productInformation.value.toLowerCase() === "yes"
                  ? ""
                  : " bottom-margin"
              }`}
              data-testid="delivery-address-title"
              id={deliveryInformation.deliverySiteType.componentId!}
            >
              Delivery Address
            </h2>
            {authObj &&
              authObj.registeredFaciltyAddress &&
              authObj.registeredFaciltyAddress.readyCareFlag === "Y" &&
              productInfo.productInformation.value.toLowerCase() === "yes" && (
                <h5
                  className="delivery-address-note"
                  data-testid="delivery-address-note"
                >
                  <span className="delivery-address-note bold">Note:</span> The
                  delivery address is where all additional disposables will be
                  delivered
                </h5>
              )}
            {isEditingDeliveryInformation ? (
              <div
                className={
                  checkUpdateDisabled()
                    ? "delivery-address-edit-mode"
                    : "delivery-address-edit-update-mode"
                }
              >
                <Box
                  className="delivery-information-box-container"
                  sx={{ flexGrow: 1 }}
                >
                  <Grid
                    className="delivery-information-grid-container"
                    container
                    spacing={2}
                  >
                    <Grid
                      className="delivery-information-grid-item"
                      item
                      xs={isMobileScreen ? 12 : 6}
                    >
                      <InputWithLabel
                        label="Delivery Site Type"
                        isRequired={true}
                        error={
                          deliveryInformation?.deliverySiteType.valid ===
                          ValidationStatus.INVALID
                        }
                        testId="delivery-information-delivery-site-type"
                        isDropdown={true}
                      >
                        <CustomDropDown
                          handleChange={validateAndSetData}
                          menuItem={deliverySitesText}
                          name="deliverySiteType"
                          placeHolder="Select Address Type"
                          selectpropsClassName={
                            deliveryInformation.deliverySiteType.value
                              ? "delivery-information-select"
                              : "placeHolder"
                          }
                          selectClassName={
                            deliveryInformation.deliverySiteType.value
                              ? "delivery-information-input"
                              : "placeHolder"
                          }
                          testId="delivery-information-deliverySiteType"
                          value={
                            deliveryInformation.deliverySiteType.value
                              ? getTextFromCode(
                                  deliverySites,
                                  deliveryInformation.deliverySiteType.value
                                )
                              : null
                          }
                        />
                      </InputWithLabel>
                    </Grid>
                  </Grid>
                </Box>
                {isDeliverySiteTypeForDefault && (
                  <Box
                    className="delivery-information-box-container"
                    sx={{ flexGrow: 1 }}
                  >
                    <Grid
                      className="delivery-information-grid-container"
                      container
                      spacing={2}
                    >
                      <Grid
                        className="delivery-information-grid-item"
                        item
                        xs={12}
                      >
                        <InputWithLabel
                          label="Facility Name"
                          isRequired={true}
                          error={
                            deliveryInformation.deliveryFacilityName.valid ===
                            ValidationStatus.INVALID
                          }
                          testId="delivery-information-facilityName"
                        >
                          <InputBase
                            className="delivery-information-input"
                            inputProps={{
                              "data-testid":
                                "delivery-information-facilityName-value",
                            }}
                            name="deliveryFacilityName"
                            onChange={validateAndSetData}
                            value={
                              deliveryInformation.deliveryFacilityName.value
                            }
                          />
                        </InputWithLabel>
                      </Grid>
                      <Grid
                        className="delivery-information-grid-item"
                        item
                        xs={isMobileScreen ? 12 : 6}
                      >
                        <InputWithLabel
                          label="Address Line 1 (No P.O. Boxes)"
                          isRequired={true}
                          error={
                            deliveryInformation.deliveryAddressLine1.valid ===
                            ValidationStatus.INVALID
                          }
                          testId="delivery-information-addressline1"
                        >
                          <InputBase
                            className="delivery-information-input"
                            inputProps={{
                              "data-testid":
                                "delivery-information-addressline1-value",
                            }}
                            name="deliveryAddressLine1"
                            onChange={validateAndSetData}
                            value={
                              deliveryInformation.deliveryAddressLine1.value
                            }
                          />
                        </InputWithLabel>
                      </Grid>
                      <Grid
                        className="delivery-information-grid-item"
                        item
                        xs={isMobileScreen ? 12 : 6}
                      >
                        <InputWithLabel
                          label="Address Line 2"
                          isRequired={false}
                          error={
                            deliveryInformation.deliveryAddressLine2.valid ===
                            ValidationStatus.INVALID
                          }
                          testId="delivery-information-addressline2"
                        >
                          <InputBase
                            className="delivery-information-input"
                            inputProps={{
                              "data-testid":
                                "delivery-information-addressline2-value",
                            }}
                            name="deliveryAddressLine2"
                            onChange={validateAndSetData}
                            value={
                              deliveryInformation.deliveryAddressLine2.value
                            }
                          />
                        </InputWithLabel>
                      </Grid>
                      <Grid
                        className="delivery-information-grid-item"
                        item
                        xs={isMobileScreen ? 12 : 6}
                      >
                        <InputWithLabel
                          label="City"
                          isRequired={true}
                          error={
                            deliveryInformation.deliveryCity.valid ===
                            ValidationStatus.INVALID
                          }
                          testId="delivery-information-city"
                        >
                          <InputBase
                            className="delivery-information-input"
                            inputProps={{
                              "data-testid": "delivery-information-city-value",
                            }}
                            name="deliveryCity"
                            onChange={validateAndSetData}
                            value={deliveryInformation.deliveryCity.value}
                          />
                        </InputWithLabel>
                      </Grid>
                      <Grid
                        className="delivery-information-grid-item"
                        item
                        xs={isMobileScreen ? 6 : 3}
                      >
                        <InputWithLabel
                          label="State"
                          isRequired={true}
                          error={
                            deliveryInformation.deliveryState.valid ===
                            ValidationStatus.INVALID
                          }
                          testId="delivery-information-state"
                        >
                          <CustomAutoComplete
                            handleChange={(e: any, val: string) =>
                              validateAndSetStateData(e, val, "deliveryState")
                            }
                            menuItem={statesText}
                            name="deliveryState"
                            selectClassName={
                              deliveryInformation.deliveryState.valid ===
                              ValidationStatus.INVALID
                                ? "deliveryAddress-state-input deliveryAddress-state-error"
                                : "deliveryAddress-state-input"
                            }
                            selectpropsClassName="deliveryAddress-state-root"
                            testId="delivery-information-state-value"
                            value={
                              deliveryInformation.deliveryState.value
                                ? getTextFromCode(
                                    states,
                                    deliveryInformation.deliveryState.value
                                  )
                                : null
                            }
                          />
                        </InputWithLabel>
                      </Grid>
                      <Grid
                        className="delivery-information-grid-item"
                        item
                        xs={isMobileScreen ? 6 : 3}
                      >
                        <InputWithLabel
                          label="ZIP Code"
                          isRequired={true}
                          error={
                            deliveryInformation.deliveryZipCode.valid ===
                            ValidationStatus.INVALID
                          }
                          testId="delivery-information-zip-code"
                        >
                          <InputBase
                            className="delivery-information-input"
                            inputProps={{
                              "data-testid":
                                "delivery-information-zip-code-value",
                              maxLength: 5,
                              inputMode: "numeric",
                              pattern: "[0-9*]",
                            }}
                            name="deliveryZipCode"
                            onChange={validateAndSetData}
                            value={deliveryInformation.deliveryZipCode.value}
                            type="text"
                          />
                        </InputWithLabel>
                      </Grid>
                    </Grid>
                  </Box>
                )}
                {facility && (
                  <Box
                    className="delivery-information-box-container"
                    sx={{ flexGrow: 1 }}
                  >
                    <Grid
                      className="delivery-information-grid-container"
                      container
                      spacing={2}
                    >
                      <Grid
                        className="delivery-information-grid-item"
                        item
                        xs={12}
                      >
                        <div className="facilityAddress">
                          <Typography
                            className="facilitytitle"
                            data-testid="delivery-address"
                          >
                            Delivery Address
                          </Typography>
                          <Typography
                            className="facilityvalue"
                            data-testid="accountName"
                          >
                            {makeCapitalEachWordInString(facility.accountName)}
                          </Typography>
                          <Typography
                            className="facilityvalue"
                            data-testid="address1"
                          >
                            {makeCapitalEachWordInString(facility.address1)}
                            {","}
                          </Typography>
                          {facility.address2 && (
                            <Typography
                              className="facilityvalue"
                              data-testid="address2"
                            >
                              {makeCapitalEachWordInString(facility.address2)}
                              {facility.address2.length > 0 ? "," : ""}
                            </Typography>
                          )}
                          <Typography
                            className="facilityvalue"
                            data-testid="city-state-zip"
                          >
                            {makeCapitalEachWordInString(facility.city)},{" "}
                            {facility.state}
                            {", "}
                            {facility.zip}
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                )}
                {address && !isDeliverySiteTypeForDefault && (
                  <Box
                    className="delivery-information-box-container"
                    sx={{ flexGrow: 1 }}
                  >
                    <Grid
                      className="delivery-information-grid-container"
                      container
                      spacing={2}
                    >
                      <Grid
                        className="delivery-information-grid-item"
                        item
                        xs={isMobileScreen ? 14 : 12}
                      >
                        <div className="facilityAddress">
                          <Typography
                            className="facilitytitle"
                            data-testid="delivery-address"
                          >
                            Delivery Address
                          </Typography>
                          <Typography
                            className="facilityvalue"
                            data-testid="address1"
                          >
                            {makeCapitalEachWordInString(address.address1)}
                            {","}
                          </Typography>
                          <Typography
                            className="facilityvalue"
                            data-testid="address2"
                          >
                            {makeCapitalEachWordInString(address.address2)}
                            {address.address2.length > 0 ? "," : ""}
                          </Typography>
                          <Typography
                            className="facilityvalue"
                            data-testid="city-state-zip"
                          >
                            {makeCapitalEachWordInString(address.city)},{" "}
                            {address.state}
                            {", "}
                            {address.zip}
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                )}
                <div className="delivery-address-edit-mode-buttons">
                  <Button
                    classes={{
                      root: "delivery-address-edit-mode-cancel-button",
                    }}
                    data-testid={"delivery-address-edit-mode-cancel-button"}
                    onClick={cancelEditModel}
                  >
                    Cancel
                  </Button>
                  <ExpressButton
                    parentClass="delivery-address-edit-mode-update-button"
                    testId={"delivery-address-edit-mode-update-button"}
                    disabled={checkUpdateDisabled()}
                    clickHandler={updateEditModel}
                    variant="outlined"
                  >
                    Update
                  </ExpressButton>
                </div>
              </div>
            ) : (
              <>
                <div
                  className={`delivery-address-default-mode${
                    isNextButtonClicked &&
                    (updatedDeliveryInformation.deliverySiteType.value ===
                      "102" ||
                      updatedDeliveryInformation.deliverySiteType.value ===
                        "103") &&
                    (updatedDeliveryInformation.deliveryAddressLine1.value ===
                      "" ||
                      updatedDeliveryInformation.deliveryCity.value === "" ||
                      updatedDeliveryInformation.deliveryState.value === "" ||
                      updatedDeliveryInformation.deliveryZipCode.value === "")
                      ? " error"
                      : ""
                  }`}
                >
                  <Box
                    className="delivery-information-box-container"
                    sx={{ flexGrow: 1 }}
                  >
                    <Grid
                      className="delivery-information-grid-container"
                      container
                      spacing={2}
                    >
                      <Grid
                        className="delivery-information-grid-item"
                        item
                        xs={isMobileScreen ? 12 : 5}
                      >
                        <div className="delivery-address-default-mode-div">
                          <h4
                            className="delivery-address-default-mode-title"
                            data-testid="delivery-address-default-mode-sub-title"
                          >
                            Delivery Location
                          </h4>
                          <h4
                            className="delivery-address-default-mode-value"
                            data-testid="delivery-address-default-mode-sub-value"
                          >
                            {makeCapitalEachWordInString(
                              updatedDeliveryInformation?.deliverySiteType
                                .value &&
                                updatedDeliveryInformation?.deliverySiteType
                                  .value !== ""
                                ? getTextFromCode(
                                    deliverySites,
                                    updatedDeliveryInformation.deliverySiteType
                                      .value
                                  )
                                : "--"
                            )}
                          </h4>
                        </div>
                      </Grid>
                      <Grid
                        className="delivery-information-grid-item"
                        item
                        xs={isMobileScreen ? 12 : 5}
                      >
                        <div className="delivery-address-default-mode-div">
                          <h4
                            className="delivery-address-default-mode-title"
                            data-testid="delivery-address-default-mode-sub-title1"
                          >
                            Delivery Address
                          </h4>
                          {updatedDeliveryInformation.deliveryAddressLine1
                            .value &&
                          updatedDeliveryInformation.deliveryCity.value &&
                          updatedDeliveryInformation.deliveryState.value &&
                          updatedDeliveryInformation.deliveryZipCode.value ? (
                            <div
                              className="facilityAddress"
                              data-testid="delivery-default-mode-selected-address"
                            >
                              {updatedDeliveryInformation.deliverySiteType
                                .value !== "102" &&
                                updatedDeliveryInformation.deliverySiteType
                                  .value !== "103" && (
                                  <Typography
                                    className="facilityvalue"
                                    data-testid="facilityName"
                                  >
                                    {makeCapitalEachWordInString(
                                      updatedDeliveryInformation
                                        .deliveryFacilityName.value
                                    )}
                                  </Typography>
                                )}
                              <Typography
                                className="facilityvalue"
                                data-testid="address1"
                              >
                                {makeCapitalEachWordInString(
                                  updatedDeliveryInformation
                                    .deliveryAddressLine1.value
                                )}
                                {","}
                              </Typography>
                              <Typography
                                className="facilityvalue"
                                data-testid="address2"
                              >
                                {makeCapitalEachWordInString(
                                  updatedDeliveryInformation
                                    .deliveryAddressLine2.value
                                )}
                                {updatedDeliveryInformation.deliveryAddressLine2
                                  .value.length > 0
                                  ? ","
                                  : ""}
                              </Typography>
                              <Typography
                                className="facilityvalue"
                                data-testid="city-state-zip"
                              >
                                {makeCapitalEachWordInString(
                                  updatedDeliveryInformation.deliveryCity.value
                                )}
                                ,{" "}
                                {updatedDeliveryInformation.deliveryState.value}
                                {", "}
                                {
                                  updatedDeliveryInformation.deliveryZipCode
                                    .value
                                }
                              </Typography>
                            </div>
                          ) : (
                            <h4
                              className="delivery-address-default-mode-value"
                              data-testid="delivery-address-default-mode-value"
                            >
                              --
                            </h4>
                          )}
                        </div>
                      </Grid>
                      <Grid
                        className="delivery-information-grid-item"
                        item
                        xs={isMobileScreen ? 12 : 2}
                      >
                        <div className="delivery-address-default-mode-div edit-button">
                          <h4
                            className="delivery-address-default-mode-edit-button"
                            data-testid="delivery-address-default-mode-edit-button"
                            onClick={editLocationClicked}
                          >
                            edit location
                          </h4>
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                </div>
                {isNextButtonClicked &&
                  (updatedDeliveryInformation.deliverySiteType.value ===
                    "102" ||
                    updatedDeliveryInformation.deliverySiteType.value ===
                      "103") &&
                  (updatedDeliveryInformation.deliveryAddressLine1.value ===
                    "" ||
                    updatedDeliveryInformation.deliveryCity.value === "" ||
                    updatedDeliveryInformation.deliveryState.value === "" ||
                    updatedDeliveryInformation.deliveryZipCode.value ===
                      "") && (
                    <div className="delivery-address-default-mode-warning-div">
                      <span className="warning-icon">
                        <WarningIcon className="warning-icon-image" />
                      </span>
                      <p className="error-with-warning-icon">
                        {ERROR_TO_ADD_DELIVERY_ADDRESS}
                      </p>
                    </div>
                  )}
              </>
            )}
          </div>
          <ValidateUPSAddressInfo
            address={address!}
            openAddress={openAddress}
            setOpenAddress={setOpenAddress}
            loading={loading}
            setSelectedUPSAddress={setSelectedUPSAddress}
            selectedUPSAddress={selectedUPSAddress}
            validateAddress={validateAddress}
            handleAddressConitnueButton={handleAddressConitnueButton}
            noAddressFound={noAddressFound}
            message={message}
            title=" Please confirm facility address "
          />
        </div>
      )}
      {isReviewOrder && (
        <DeliveryInfoReviewOrder
          data={updatedDeliveryInformation}
          deliverySites={deliverySites}
          editButtonClicked={editButtonClicked}
          isOrderSummary={isOrderSummary}
        />
      )}
    </div>
  );
};
