export const MISRXBGCOLOR = "#FFF0F0";
export const MISRXCOLOR = "#CB0000";
export const MSDUEBGCOLOR = "#FFF0F0";
export const MSDUECOLOR = "#CB0000";
export const MSDUELOWBGCOLOR = "#E8F4FF";
export const MSDUELOWCOLOR = "#1E64D0";
export const MSDUEMEDBGCOLOR = "#FFF0D4";
export const MSDUEMEDCOLOR = "#915800";
export const PNDSOBGCOLOR = "#FFF0D4";
export const PNDSOCOLOR = "#915800";
export const SUPSHBGCOLOR = "#E3F7E8";
export const SUPSHCOLOR = "#007713";
export const MSDOCBGCOLOR = "#FFF0F0";
export const MSDOCCOLOR = "#CB0000";
export const SHODRBGCOLOR = "#F1F1FC";
export const SHODRCOLOR = "#5D5DBD";
export const PODELBGCOLOR = "#FFF0F0";
export const PODELCOLOR = "#CB0000";
export const CONPLBGCOLOR = "#FFF0F0";
export const CONPLCOLOR = "#CB0000";
export const DISPENBGCOLOR = "#FFF0F0";
export const DISPENCOLOR = "#CB0000";
export const APIERRORBGCOLOR = "#FFF0F0";
export const APIERRORCOLOR = "#CB0000";
