import { format } from "react-string-format";
import { SVC_GET_WOUND_ASSESSMENT_REPORTS } from "./staticText";

export const getWoundAssessmentReports = async (reqParams: any) => {
  try {
    const baseUrl = process.env.REACT_APP_3ME_SVC_BASE_URL ?? "";
    const getPrescriptionTypeUrl = format(
      "{0}{1}",
      baseUrl,
      SVC_GET_WOUND_ASSESSMENT_REPORTS
    );
    const AuthDetails = sessionStorage.getItem("okta-token-storage");
    const data = JSON.parse(AuthDetails ?? "");
    const accessToken = data.accessToken?.accessToken;
    const authorizationToken = format("Bearer {0}", accessToken);
    const response = await fetch(getPrescriptionTypeUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: authorizationToken,
      },
      body: JSON.stringify(reqParams),
    });
    return response.json();
  } catch (error) {
    console.log("error", error);
  }
};
