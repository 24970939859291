import "./facilityData.css";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import { IFacilityData } from "./facilityData.interface";
import {
  formatPhoneNumber,
  makeCapitalEachWordInString,
  showVacOrderMenuOption,
} from "../../../../util/utilityFunctions";
import { ExpressButton } from "../../../../core/expressButton/expressButton.component";

import {
  allFacilitySearch,
  caregiverFacilitySearch,
  checkPostAcuteFacility,
  getPatientMenuAction,
} from "../../../../util/3meService";
import { useContext } from "react";
import {
  RolesPermissionContext,
  RolesPermissionContextType,
} from "../../../../context/RolesPermissionContext";
import { AuthContext, AuthContextType } from "../../../../context/AuthContext";
import { useHistory } from "react-router";
import { ICheckPostAcuteFacility } from "../../../../components/manageProfile/facilityInformation/facilityFound/facilityFound.interface";
import { IFacility } from "../../../../components/manageProfile/facilityInformation/facility.interface";
import { IHomeCareProviderFacility } from "../../searchForHomeCareOrder/searchHomeCareOrder.interface";
import { selectFacility } from "../../../../util/userService";
import { mapUserRolesAndPermissionData } from "../../../../RolesPermission/RolesPermission.Mapper";
import moment from "moment";
import { PageSection } from "../../searchHomeCareOrderContainer.enum";
import { ReactComponent as BlueIndicator } from "../../../../assets/BlueIndicator.svg";
import {
  InternalSignOnContext,
  InternalSignOnContextType,
} from "../../../../context/InternalSignOnContext";

export const FacilityData = ({
  facilityData,
  facilityType,
  patientData,
  type,
  redirectHandler,
  orderFacilityType,
}: IFacilityData) => {
  const permissionsObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const internalSignOnObj = useContext<InternalSignOnContextType | null>(
    InternalSignOnContext
  );
  const history = useHistory();
  const isMobileScreen = useMediaQuery("(max-width:920px)");

  const checkPostAcuteFacilityFound = async (typeCode: string) => {
    const reqBody = { FacilityTypeCode: typeCode };
    const response: ICheckPostAcuteFacility = await checkPostAcuteFacility(
      reqBody
    );
    return response.postAcuteFound;
  };
  const checkForSalesRole =
    permissionsObj?.mappedRolesPermissionData.IsSalesRole ||
    permissionsObj?.mappedRolesPermissionData.IsSalesManagerRole;

  const validationToRenderNoAccessMessage =
    !facilityData.address.facilityAccess &&
    checkForSalesRole &&
    orderFacilityType !== "manual";

  const getCareGiverValue = async (facility: IFacility) => {
    let caregiverIdValue: string;
    const isCareGiver = facility.siteUseCode?.includes("CG");
    if (isCareGiver) {
      const careGiverIdRes = await caregiverFacilitySearch({
        customerAccountNumber: facility.accountNumber,
      });
      caregiverIdValue = careGiverIdRes.items[0].origSystemReference;
      return caregiverIdValue;
    } else {
      return "";
    }
  };

  const getFacilityInfo = async (
    facilities: IFacility[],
    orderFacilityRes: IHomeCareProviderFacility
  ): Promise<IFacility | null> => {
    authObj?.setAllFacilties(facilities);
    let facilityResObj;
    let facilityObject: IFacility;
    if (facilities.length === 1) {
      facilityResObj = facilities[0];
    } else if (facilities.length > 1) {
      const facilityResObjects = facilities.filter(
        (facility: IFacility) =>
          facility.siteUseId?.toString() ===
          orderFacilityRes?.siteUseID?.toString()
      );
      facilityResObj = facilityResObjects[0];
    }
    if (facilityResObj) {
      facilityObject = {
        ...facilityResObj,
        isPostAcute: await checkPostAcuteFacilityFound(facilityResObj.typeCode),
        careGiverId: await getCareGiverValue(facilityResObj),
      };
      return facilityObject;
    } else {
      return null;
    }
  };

  const validateRoleAndPermissions = async (facility: IFacility) => {
    const roleAndPermissionsResponse = await selectFacility({
      UserName: authObj?.userProfile?.userName,
      SiteUseId: facility.siteUseId,
    });
    const rolesPermissionRes = await mapUserRolesAndPermissionData(
      roleAndPermissionsResponse,
      facility.readyCareFlag
    );
    return rolesPermissionRes;
  };

  const getPatientInformation = async () => {
    const fDob = moment(patientData.dob).format("YYYY-MM-DDTHH:mm:ss");
    const menuActionsResponse = await getPatientMenuAction(
      type!,
      true,
      patientData.roNumber,
      fDob
    );
    if (menuActionsResponse.succeeded) {
      return {
        ...patientData,
        orderCreationDate: patientData.orderDate,
        statusColor: menuActionsResponse.data.color + "Circle",
        ...menuActionsResponse.data,
      };
    }
    return null;
  };

  const getMenuBuildPatientData = async (unLinked: boolean) => {
    const patientObj = await getPatientInformation();
    if (patientObj !== null) {
      redirectHandler(PageSection.SEARCH_HOME_CARE_ORDER_FORM);
      history.push({
        pathname: unLinked ? "/unlinkOrderOverview" : "/home/orderOverview",
        state: {
          stateData: patientObj,
        },
      });
    } else {
      redirectHandler(PageSection.SEARCH_HOME_CARE_ORDER_FORM);
    }
  };

  const getFacilityInfoList = async () => {
    const orderFacilityRes = facilityData;
    const facilityResponse: any = await allFacilitySearch({
      customerName: "",
      customerNumber: orderFacilityRes.facilityId,
      state: "",
    });
    if (facilityResponse && facilityResponse.succeeded) {
      return facilityResponse;
    }
    return undefined;
  };

  const handleSearchWithRO = async () => {
    redirectHandler(PageSection.SEARCH_HOME_CARE_ORDER_LOADER);
    const orderFacilityRes = facilityData;
    if (
      (facilityData.siteUseID || facilityData.careGiverId) &&
      (facilityData.address.facilityAccess || !checkForSalesRole)
    ) {
      const facilityResponse = await getFacilityInfoList();
      if (facilityResponse) {
        const facility = await getFacilityInfo(
          facilityResponse.items,
          orderFacilityRes
        );
        if (facility) {
          authObj?.setregisteredFaciltyAddress(facility);
          const roleAndPermissionReponse = await validateRoleAndPermissions(
            facility
          );
          permissionsObj?.setMappedRolesPermissionData(
            roleAndPermissionReponse
          );
          let adminText: any = "Administration";
          if (authObj && authObj.unLinkedFacilitesCount) {
            adminText = (
              <div className="sideNavmadminBtnMain">
                Administration
                <span className="sideNavmadminBtnStyle">
                  {authObj.unLinkedFacilitesCount}{" "}
                </span>{" "}
              </div>
            );
          }
          authObj?.setsideNavMenuOptionModelData({
            ...authObj?.sideNavMenuOptionModelData,
            orders: {
              labelText: "Start New Order",
              isVisible: showVacOrderMenuOption(roleAndPermissionReponse),
            },
            inventory: {
              labelText: "Inventory",
              isVisible: roleAndPermissionReponse.IsShowInventoryOption,
            },
            administration: {
              labelText: adminText,
              isVisible: roleAndPermissionReponse.IsShowAdminstrationOption,
            },
          });
          authObj?.setuserRolesAndPermissionLoaded(true);
          authObj?.setIsInternalUserFacilitySelected(true);
          internalSignOnObj?.setIsManualFacility(false);
          getMenuBuildPatientData(false);
        } else {
          redirectHandler(PageSection.SEARCH_HOME_CARE_ORDER_FORM);
        }
      } else {
        redirectHandler(PageSection.SEARCH_HOME_CARE_ORDER_FORM);
      }
    } else {
      localStorage.setItem("isComingFromSSO", "false");
      authObj?.setIsUnlinkedFacilityOrder(true);
      if (orderFacilityType?.toLowerCase() !== "manual".toLowerCase()) {
        const facilityResponse = await getFacilityInfoList();
        if (facilityResponse && facilityResponse.items.length > 0) {
          internalSignOnObj?.setIsManualFacility(false);
          const facility = await getFacilityInfo(
            facilityResponse.items,
            orderFacilityRes
          );
          if (facility) {
            authObj?.setregisteredFaciltyAddress(facility!);
          } else {
            redirectHandler(PageSection.SEARCH_HOME_CARE_ORDER_FORM);
          }
        } else {
          redirectHandler(PageSection.SEARCH_HOME_CARE_ORDER_FORM);
        }
      } else {
        const facilityData: IFacility = {
          accountId: "",
          accountName: orderFacilityRes.facilityName,
          typeName: "",
          addressId: orderFacilityRes.addressId ?? "",
          address1: orderFacilityRes.address.addressLine1 ?? "",
          address2: orderFacilityRes.address.addressLine2 ?? "",
          city: orderFacilityRes.address.city ?? "",
          state: orderFacilityRes.address.state ?? "",
          zip: parseInt(orderFacilityRes.address.zipCode ?? 0),
          accountNumber: parseInt(orderFacilityRes.facilityId) ?? "",
          siteUseId: orderFacilityRes.siteUseID ?? "",
          typeCode: "",
        };
        authObj?.setregisteredFaciltyAddress(facilityData);
        if (orderFacilityType?.toLowerCase() === "manual".toLowerCase()) {
          internalSignOnObj?.setIsManualFacility(true);
        } else {
          internalSignOnObj?.setIsManualFacility(false);
        }
      }
      authObj?.setuserRolesAndPermissionLoaded(true);
      getMenuBuildPatientData(true);
    }
  };

  const viewOrderButton = () => {
    return (
      <Grid
        className="view-order"
        data-testid="view-order"
        item
        xs={isMobileScreen ? 12 : 2}
      >
        <ExpressButton
          variant="text"
          parentClass="view-order-btn"
          clickHandler={handleSearchWithRO}
        >
          View Order
        </ExpressButton>
      </Grid>
    );
  };

  const getPhoneNumber = (phoneNumber: string) => {
    if (phoneNumber.includes("x")) {
      const splittedNumbers = facilityData.phoneNumber.split("x");
      const formatedPhone = formatPhoneNumber(splittedNumbers[0]);
      if (splittedNumbers.length > 1) {
        const formatExtension = splittedNumbers[1];
        return formatedPhone + " ext " + formatExtension;
      }
      return formatedPhone;
    } else {
      return formatPhoneNumber(phoneNumber);
    }
  };

  return (
    <div className="facility-data-main-div">
      <Grid container className="facility-container">
        {!isMobileScreen && viewOrderButton()}
        <Grid item xs={isMobileScreen ? 12 : 10} className="facility-name-grid">
          <Typography className="facility-name" data-testid="facility-name">
            {facilityData.facilityName
              ? makeCapitalEachWordInString(`${facilityData.facilityName}`) +
                " "
              : "--"}
            <Typography className="facility-type">
              {facilityType === "requestor" && "(Requesting Facility)"}
              {facilityType === "hcp" && "(Home Care Provider)"}
              {facilityType === "manualEntry" && ""}
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={2} display={isMobileScreen ? "none" : "block"}></Grid>
        {facilityData && facilityData.facilityId && (
          <Grid
            item
            xs={isMobileScreen ? 12 : 5}
            className="home-care-order-facility-address"
            data-testid="facility-address"
          >
            <Typography className="facility-adressLine">
              {makeCapitalEachWordInString(
                `${facilityData.address.addressLine1}`
              )}
            </Typography>{" "}
            <Typography className="facility-adressLine">
              {facilityData.address.addressLine2
                ? makeCapitalEachWordInString(
                    `${facilityData.address.addressLine2}`
                  )
                : ""}
            </Typography>
            <Typography className="facility-adressLine">
              {makeCapitalEachWordInString(
                `${facilityData.address.city + ", "}`
              )}
              {facilityData.address.state + " "}
              {makeCapitalEachWordInString(`${facilityData.address.zipCode}`)}
            </Typography>
          </Grid>
        )}
        <Grid
          item
          xs={
            isMobileScreen
              ? 12
              : (facilityData && facilityData?.siteUseID) ||
                facilityData?.careGiverId
              ? 5
              : 10
          }
          className={
            (facilityData && facilityData?.siteUseID) ||
            facilityData?.careGiverId
              ? "facility-id-and-phone"
              : "facility-not-connected-facilityId-phone"
          }
        >
          <Grid
            container
            xs={12}
            className={
              facilityData?.siteUseID || facilityData?.careGiverId
                ? "facility-id"
                : "facility-id-div"
            }
          >
            <Grid item xs={12} className="facility-deatils-grid">
              <span
                className="facility-id-label"
                data-testid="facility-id-label"
              >
                Facility ID{" "}
              </span>
              <span
                className="facility-id-value"
                data-testid="facility-id-value"
              >
                {facilityData.facilityId ? facilityData.facilityId : "--"}
              </span>
            </Grid>
            <Grid
              item
              xs={12}
              className={
                facilityData?.siteUseID || facilityData?.careGiverId
                  ? "facility-id"
                  : "facility-id-div"
              }
            >
              <span
                className="facility-phone-label"
                data-testid="facility-phone-label"
              >
                Phone{" "}
              </span>
              <span
                className="facility-phone-value"
                data-testid="facility-phone-value"
              >
                {facilityData.phoneNumber
                  ? getPhoneNumber(facilityData.phoneNumber)
                  : "--"}
              </span>
            </Grid>
          </Grid>
        </Grid>
        {isMobileScreen && viewOrderButton()}
        <Grid xs={2} item display={isMobileScreen ? "none" : "block"}></Grid>
        {validationToRenderNoAccessMessage && (
          <Grid
            item
            xs={isMobileScreen ? 12 : 10}
            className="facility-with-no-access"
          >
            <div className="facility-blue-indicator-icon">
              <BlueIndicator />
            </div>
            <Typography className="facility-indicator-text">
              This facility is outside of your territory. You can view the order
              but will be unable to access other information in that facility
            </Typography>
          </Grid>
        )}
      </Grid>
    </div>
  );
};
