import { useContext } from "react";
import "./supplyProductReviewOrder.css";
import {
  SpecialProduct,
  checkSelectedProdcutForType,
} from "../../../../util/utilityFunctions";
import {
  ICanister,
  IDressingKit,
  IProductAccessory,
} from "../../../newOrder/newOrder.interface";
import {
  SupplyOrderContext,
  SupplyOrderContextType,
} from "../../../../context/SupplyOrderContext";
import Table from "../../../../core/customSortingTable/table.component";

type Props = {
  columns: any;
  dressing: IDressingKit;
  canister: ICanister;
  accessories: IProductAccessory;
};

const SupplyProductReviewTable = ({
  columns,
  dressing,
  canister,
  accessories,
}: Props) => {
  const supplyOrderObj = useContext<SupplyOrderContextType | null>(
    SupplyOrderContext
  );
  const vacAllProducts = supplyOrderObj!.vacAllProducts;

  const isPrimaryDressingIsPeelAndPlaceWithMoreThan1Quantity = (
    product: any
  ) => {
    const productName = product.productName.split(", ")[0];
    if (
      checkSelectedProdcutForType(
        vacAllProducts.items,
        SpecialProduct.PEELANDPLACE,
        productName
      ) &&
      product.qty &&
      parseInt(product.qty) > 1
    ) {
      return true;
    }
    return false;
  };

  const getProductQuntity = (product: any): string => {
    let quantity = product.qty;
    if (isPrimaryDressingIsPeelAndPlaceWithMoreThan1Quantity(product)) {
      quantity = "1";
    }
    return quantity;
  };

  const createSelectedProductList = () => {
    let productList = [];
    if (dressing.productName.value !== "") {
      let productSizeName =
        dressing.productSizeName.value !== ""
          ? `, ${dressing.productSizeName.value.split("(")[0]}`
          : "";
      productList.push({
        qty: dressing.productQuantity.value,
        productName: `${
          dressing.productName.value
        }${productSizeName} (Case of ${
          dressing.productCode.value && dressing.productCode.value !== ""
            ? dressing.productCode.value.split("/")[1] ??
              dressing.productCode.value
                .match(/\d/g)
                ?.join("")
                .replace(/^0+/, "")
            : dressing.productSizeCode.value.split("/")[1] ??
              dressing.productSizeCode.value
                .match(/\d/g)
                ?.join("")
                .replace(/^0+/, "")
        })`,
      });
    }
    if (dressing.secProductName.value !== "") {
      let secProductSizeName =
        dressing.secProductSizeName.value !== ""
          ? `, ${dressing.secProductSizeName.value.split("(")[0]}`
          : "";
      productList.push({
        qty: dressing.secProductQuantity.value,
        productName: `${
          dressing.secProductName.value
        }${secProductSizeName} (Case of ${
          dressing.secProductCode.value && dressing.secProductCode.value !== ""
            ? dressing.secProductCode.value.split("/")[1] ??
              dressing.secProductCode.value
                .match(/\d/g)
                ?.join("")
                .replace(/^0+/, "")
            : dressing.secProductSizeCode.value.split("/")[1] ??
              dressing.secProductSizeCode.value
                .match(/\d/g)
                ?.join("")
                .replace(/^0+/, "")
        })`,
      });
    }
    if (canister.canisterProductName.value !== "") {
      productList.push({
        qty: canister.canisterProductQuantity.value,
        productName:
          canister.canisterProductName.value +
          ` (${canister.canisterProductCode.value})` +
          ` (Case of ${
            canister.canisterProductCode.value.split("/")[1] ??
            canister.canisterProductCode.value.match(/\d/g)?.join("")
          })`,
      });
    }
    accessories.accessories.forEach((x: any) => {
      productList.push({
        qty: "1",
        productName:
          x.value +
          ` (Pack of ${x.code.split("/")[1] ?? x.code.match(/\d/g)?.join("")})`,
      });
    });
    return productList.map((productDetails: any) => (
      <tr>
        <td className="table-static-review-data-quantity">
          <div className="quantity-review">
            {getProductQuntity(productDetails)}
          </div>
        </td>
        <td className="table-static-review-data">
          {productDetails.productName}
        </td>
      </tr>
    ));
  };

  return (
    <div>
      <Table
        tableClassName="table"
        tableColumns={columns}
        handleSorting={() => {}}
      >
        <tbody>{createSelectedProductList()}</tbody>
      </Table>
    </div>
  );
};

export default SupplyProductReviewTable;
