import moment from "moment";
import "./supplyOrderInfo.css";
import {
  SupplyOrderContext,
  SupplyOrderContextType,
} from "../../../context/SupplyOrderContext";
import { useContext, useEffect, useState } from "react";
import { SupplyOrderValidator } from "../supplyOrder.validator";
import { LoadingSpinner } from "../../../core/loader/LoadingSpinner";
import { retrieveVACProductInfo } from "../../../util/vacOrderService";
import { PatientVACDetail } from "../patientVACDetail/patientVACDetail.component";
import { DressingSuppliesLink } from "../../newOrder/dressingSupplies/dressingSuppliesLink/dressingSuppliesLink";
import { CurrentSuppliesOnHand } from "../currentSuppliesOnHand/currentSuppliesOnHand";
import SupplyOrderAdditionalInfo from "../supplyOrderAdditionalInformation/SupplyOrderAdditionalInfo.component";
import { DeliveryAddress } from "../deliveryAddress/deliveryAddress.component";
import { ReSupplyJustification } from "../reSupplyJustification/reSupplyJustification.component";
import { SupplyOrderVacDressing } from "../suppliesOrderDressing/SupplyOrderDressing.component";
import SupplyOrderFooterButtonGroup from "./supplyOrderFooterButtonGroup/supplyOrderFooterButtonGroup.component";
import { Popup } from "../../../core/popup/popup.component";
import { getCMSContent } from "../../../util/cmsService";
import { CMS_VAC_THERAPY_INFORMATION_CONTENT } from "../../../util/staticText";
import { IVacTherapyInformation } from "../../newOrder/woundBed/vacTherapyInformation.interface";
import ErrorPopup from "../../../core/errorPopup/errorPopup.component";
import { ERROR_MSG_INITIAL_SUPPLY_ORDER_LOAD } from "../../../util/errorMsg";
import { SupplyOrderPageSection } from "../SupplyOrderPageSection.enum";

type Props = { openSupplyOrderPageEdit: any; isOrderOverviewFlow?: boolean };

const SupplyOrderInfo = ({
  openSupplyOrderPageEdit,
  isOrderOverviewFlow,
}: Props) => {
  const [validator] = useState<SupplyOrderValidator>(
    new SupplyOrderValidator()
  );
  const supplyOrderObj = useContext<SupplyOrderContextType | null>(
    SupplyOrderContext
  );
  const data = supplyOrderObj!.supplyOrderData;
  const patient = supplyOrderObj!.selectedPatient;
  const setData = supplyOrderObj!.setSupplyOrderData;

  const dressing = supplyOrderObj!.dressingKit;
  const setDressing = supplyOrderObj!.setDressingKit;
  const canister = supplyOrderObj!.canister;
  const setCanister = supplyOrderObj!.setCanister;

  const vacProductInfo = supplyOrderObj!.vacProductInfo;

  const [error, setError] = useState(false);
  const [errorCode, setErrorCode] = useState("");
  const [vacTherapyInformationData, setvacTherapyInformationData] =
    useState<IVacTherapyInformation>();

  const [initialLoader, setInitialLoader] = useState(
    supplyOrderObj?.isBackFromReviewPage ? false : true
  );

  supplyOrderObj?.setSupplyOrderPageTitle("Home Patient Supply Order");
  supplyOrderObj?.setSupplyOrderProgress(10);

  const isShowCurrentAddress = supplyOrderObj!.isShowCurrentAddress;
  const setIsShowCurrentAddress = supplyOrderObj!.setIsShowCurrentAddress;

  const loadVACProductInfo = async (roNumber: string, dob: string) => {
    let reqParams = {
      RentalOrderNumber: roNumber,
      DOB: moment(dob).format("yyyy-MM-DD"),
    };
    try {
      const data = await retrieveVACProductInfo(reqParams);
      setInitialLoader(false);
      if (data.error) {
        setError(true);
        setErrorCode(data?.error?.errorCode || data?.status);
      } else {
        supplyOrderObj?.setVacProductInfo(data);
      }
    } catch (error) {
      supplyOrderObj?.setVacProductInfo(null);
    }
  };

  const initialLoadSpinner = () => {
    return (
      <div>
        <div className="saveapi-header"></div>
        <div className="saveapi-spinner">
          <LoadingSpinner />
        </div>
      </div>
    );
  };

  const fetchVACTherapyInformationData = async () => {
    try {
      const data = await getCMSContent(CMS_VAC_THERAPY_INFORMATION_CONTENT);
      if (data.item !== undefined) {
        setvacTherapyInformationData(data.item);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (supplyOrderObj?.isBackFromReviewPage !== true) {
      window.scrollTo(0, 0);
      setInitialLoader(true);
      setError(false);
      if (patient && patient.roNumber.toString() !== "" && patient.dob !== "") {
        loadVACProductInfo(patient.roNumber.toString(), patient.dob);
      }
    }
    fetchVACTherapyInformationData();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (supplyOrderObj && supplyOrderObj.scrollableComponentClassName) {
        let scrollableComponent = document.getElementsByClassName(
          supplyOrderObj.scrollableComponentClassName
        )[0];
        if (scrollableComponent) {
          scrollableComponent.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "start",
          });
        }
        supplyOrderObj.setScrollableComponentClassName(undefined);
      }
    }, 300);
  }, [supplyOrderObj?.scrollableComponentClassName]);

  return (
    <div className="supplyOrder-container">
      {error && (
        <ErrorPopup
          popUpStyles="error-popup-design"
          errorMessage={ERROR_MSG_INITIAL_SUPPLY_ORDER_LOAD}
          errorPopupFlag={error}
          handleBackButton={() => {
            setError(false);
            supplyOrderObj?.setSupplyOrderPage(
              SupplyOrderPageSection.SUPPLYORDER_PATIENT_LIST
            );
          }}
          buttontitle="Done"
          showTitle={false}
          isShortSentence={true}
          errorCode={errorCode}
        />
      )}
      {!error && patient && (
        <div className="supply-order-page">
          <PatientVACDetail
            data={data}
            patient={patient}
            vacProductInfo={vacProductInfo!}
            isReviewOrder={false}
          />
          <div className="choose-supplyMain">
            <div className="choose-supply">Choose Your Supplies</div>
            <DressingSuppliesLink
              vacTherapyInformationData={vacTherapyInformationData}
            />
            <SupplyOrderVacDressing
              canister={canister}
              data={data}
              dressing={dressing}
              primaryInsurance={vacProductInfo?.primaryInsurance}
              setCanister={setCanister}
              setData={setData}
              setDressing={setDressing}
              patient={patient}
              vacProductInfo={vacProductInfo!}
              Validator={validator}
              isReviewOrder={false}
              setInitialLoader={setInitialLoader}
            />
          </div>
          <div className="currentSupply-main">
            <CurrentSuppliesOnHand
              data={data}
              setData={setData}
              Validator={validator}
              isReviewOrder={false}
              openSupplyOrderPageEdit={openSupplyOrderPageEdit}
            />
          </div>
          <div className="reSupply-main">
            <ReSupplyJustification
              data={data}
              setData={setData}
              Validator={validator}
              isReviewOrder={false}
              openSupplyOrderPageEdit={openSupplyOrderPageEdit}
            />
          </div>
          <div className="deliveryAddress-main">
            <DeliveryAddress
              data={data}
              setData={setData}
              Validator={validator}
              isReviewOrder={false}
              openSupplyOrderPageEdit={openSupplyOrderPageEdit}
              isShowCurrentAddress={isShowCurrentAddress}
              setIsShowCurrentAddress={setIsShowCurrentAddress}
            />
          </div>
          <div className="additonalInfo-main">
            <SupplyOrderAdditionalInfo
              data={data}
              setData={setData}
              Validator={validator}
              isReviewOrder={false}
              openSupplyOrderPageEdit={openSupplyOrderPageEdit}
            />
          </div>
        </div>
      )}
      {initialLoader ? (
        <Popup
          hideCloseButton={true}
          openFlag={initialLoader}
          closeHandler={() => {}}
        >
          {initialLoadSpinner()}
        </Popup>
      ) : (
        ""
      )}
      {!error && (
        <SupplyOrderFooterButtonGroup
          handlePlaceOrder={() => {}}
          isOrderOverviewFlow={isOrderOverviewFlow}
        />
      )}
    </div>
  );
};

export default SupplyOrderInfo;
