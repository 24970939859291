import "./reviewServiceRequest.css";
import {
  IDropdownValue,
  ServiceOptions,
} from "../createServiceRequest/serviceOptions/serviceOptions.component";
import { ICreateServiceRequest } from "../createServiceRequest.interface";
import { Navigator } from "../../../helpAndSupport/Navigator/navigator.component";
import { VacTherapyUnit } from "../createServiceRequest/vacTherapyUnit/vacTherapyUnit.component";
import { ICustomerPlusListingDetails } from "../../customerPlusInventory/customerPlusInventory.interface";
import { DeviceComplications } from "../createServiceRequest/deviceComplications/deviceComplications.component";
import { EstimatedArrivalTime } from "../createServiceRequest/estimatedArrivalTime/estimatedArrivalTime.component";
import { IRouter } from "../../../helpAndSupport/Navigator/navigator.interface";
import PickupLocation from "../createServiceRequest/pickupLocation/pickupLocation.component";
import { PatientDetails } from "../../../pickUpAndDischargeRequest/pickUpRequest/patientDetails/patientDetails.component";
import { IPatient } from "../../../myPatients/patient.interface";

export interface IReviewServiceRequestProps {
  availableOptions: IDropdownValue[];
  data: ICreateServiceRequest;
  editButtonAction: any;
  isReviewRequest: boolean;
  setAvailableOptions: Function;
  setData: Function;
  selectedProduct: ICustomerPlusListingDetails;
  navigators: IRouter[];
  isSolventumOrder?: boolean;
  patientData?: IPatient;
}

export const ReviewServiceRequest = ({
  availableOptions,
  data,
  editButtonAction,
  isReviewRequest,
  setAvailableOptions,
  setData,
  selectedProduct,
  navigators,
  isSolventumOrder = false,
  patientData,
}: IReviewServiceRequestProps) => {
  return (
    <div className="review-service-request-component">
      <div className="short-form">
        <Navigator
          array={navigators}
          className="review-service-request-route-section"
          title="Create Service Request"
        />
        <p
          className="review-service-request-title"
          data-testid="review-service-request-title"
        >
          Review Service Request
        </p>
        <p
          className="review-service-request-description"
          data-testid="review-service-request-description"
        >
          Please verify the data provided below is accurate before placing your
          order.
        </p>
        {isSolventumOrder ? (
          <>
            <PatientDetails
              isAcuteOrderFlow={patientData!.isAcuteOrder ?? false}
              patient={patientData!}
              excangeVacFlow={true}
            />
            <PickupLocation
              data={data}
              setData={setData}
              isReviewOrder={isReviewRequest}
              editButtonAction={editButtonAction}
              storageLocations={[]}
            />
          </>
        ) : (
          <VacTherapyUnit
            isReviewRequest={isReviewRequest}
            selectedProduct={selectedProduct}
          />
        )}
        <ServiceOptions
          availableOptions={availableOptions}
          data={data}
          editButtonAction={editButtonAction}
          isReviewRequest={isReviewRequest}
          setAvailableOptions={setAvailableOptions}
          setData={setData}
        />
        <EstimatedArrivalTime
          data={data}
          editButtonAction={editButtonAction}
          isReviewRequest={isReviewRequest}
          setData={setData}
        />
        <DeviceComplications
          data={data}
          editButtonAction={editButtonAction}
          isReviewRequest={isReviewRequest}
          setData={setData}
        />
      </div>
    </div>
  );
};
