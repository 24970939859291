import { getKeyData } from "./encryptDecrypt";
import {
  SVC_ADD_STORAGE_LOCATIONS,
  SVC_ENABLE_OR_DISABLE_FACILITY_STORAGE_LOCATIONS,
  SVC_GET_FACILITY_STORAGE_LOCATION_LIST,
  SVC_GET_INVENTORY_LIST,
  SVC_INVENTORY_MOVE_PRODUCT,
  SVC_UPDATE_INVENTORY_LOCATION,
  SVC_UPDATE_STORAGE_LOCATION_NAME,
  SVC_RETURN_SOLVENTUM_UNIT,
  SVC_GET_CUSTOMER_PLUS_LIST,
  SVC_ADD_OR_UPDATE_CUSTOMER_PLUS,
  SVC_SUBMIT_CREATE_SERVICE_REQUEST,
  SVC_SUBMIT_INVENTORY_ADJUSTMENT,
  SVC_GET_SOLVENTUM_PRODUCTS,
} from "./staticText";
import { format } from "react-string-format";

export const getInventoryInfoList = async (reqParams: any) => {
  try {
    const baseUrl = process.env.REACT_APP_INVENTORY_MGR_FUNC_BASE_URL ?? "";
    const getInventoryInfoListUrl = format(
      "{0}{1}",
      baseUrl,
      SVC_GET_INVENTORY_LIST
    );
    const AuthDetails = sessionStorage.getItem("okta-token-storage");
    const data = JSON.parse(AuthDetails ?? "");
    const accessToken = data.accessToken?.accessToken;
    const authorizationToken = format("Bearer {0}", accessToken);
    const funckeyParse = getKeyData();
    const response = await fetch(getInventoryInfoListUrl, {
      method: "POST",
      headers: {
        "x-functions-key": funckeyParse.InventoryManagerFunction ?? "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: authorizationToken,
      },
      body: JSON.stringify(reqParams),
    });
    if (response.status === 200) {
      const data = response.json();
      return data;
    } else {
      return response;
    }
  } catch (error) {
    console.log("error", error);
  }
};

export const getFacilityStorageLocation = async (reqParams: any) => {
  try {
    const baseUrl = process.env.REACT_APP_INVENTORY_MGR_FUNC_BASE_URL ?? "";
    const getStorageLocationListUrl = format(
      "{0}{1}",
      baseUrl,
      SVC_GET_FACILITY_STORAGE_LOCATION_LIST
    );
    const AuthDetails = sessionStorage.getItem("okta-token-storage");
    const data = JSON.parse(AuthDetails ?? "");
    const accessToken = data.accessToken?.accessToken;
    const authorizationToken = format("Bearer {0}", accessToken);
    const funckeyParse = getKeyData();
    const response = await fetch(getStorageLocationListUrl, {
      method: "POST",
      headers: {
        "x-functions-key": funckeyParse.InventoryManagerFunction ?? "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: authorizationToken,
      },
      body: JSON.stringify(reqParams),
    });
    if (response.status === 200) {
      const data = response.json();
      return data;
    } else {
      return response;
    }
  } catch (error) {
    console.log("error", error);
  }
};

export const enableOrDsiableFacilityStorageLocation = async (
  reqParams: any
) => {
  try {
    const baseUrl = process.env.REACT_APP_INVENTORY_MGR_FUNC_BASE_URL ?? "";
    const updateStorageLocationUrl = format(
      "{0}{1}",
      baseUrl,
      SVC_ENABLE_OR_DISABLE_FACILITY_STORAGE_LOCATIONS
    );
    const AuthDetails = sessionStorage.getItem("okta-token-storage");
    const data = JSON.parse(AuthDetails ?? "");
    const accessToken = data.accessToken?.accessToken;
    const authorizationToken = format("Bearer {0}", accessToken);
    const funckeyParse = getKeyData();
    const response = await fetch(updateStorageLocationUrl, {
      method: "POST",
      headers: {
        "x-functions-key": funckeyParse.InventoryManagerFunction ?? "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: authorizationToken,
      },
      body: JSON.stringify(reqParams),
    });
    if (response.status === 200) {
      const data = response.json();
      return data;
    } else {
      return response;
    }
  } catch (error) {
    console.log("error", error);
  }
};

export const addStorageLocation = async (reqParams: any) => {
  try {
    const baseUrl = process.env.REACT_APP_INVENTORY_MGR_FUNC_BASE_URL ?? "";
    const addStorageLocationUrl = format(
      "{0}{1}",
      baseUrl,
      SVC_ADD_STORAGE_LOCATIONS
    );
    const AuthDetails = sessionStorage.getItem("okta-token-storage");
    const data = JSON.parse(AuthDetails ?? "");
    const accessToken = data.accessToken?.accessToken;
    const authorizationToken = format("Bearer {0}", accessToken);
    const funckeyParse = getKeyData();
    const response = await fetch(addStorageLocationUrl, {
      method: "POST",
      headers: {
        "x-functions-key": funckeyParse.InventoryManagerFunction ?? "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: authorizationToken,
      },
      body: JSON.stringify(reqParams),
    });
    if (response.status === 200) {
      const data = response.json();
      return data;
    } else {
      return response;
    }
  } catch (error) {
    console.log("error", error);
  }
};

export const updateStorageLocationName = async (reqParams: any) => {
  try {
    const baseUrl = process.env.REACT_APP_INVENTORY_MGR_FUNC_BASE_URL ?? "";
    const updateStorageLocationUrl = format(
      "{0}{1}",
      baseUrl,
      SVC_UPDATE_STORAGE_LOCATION_NAME
    );
    const AuthDetails = sessionStorage.getItem("okta-token-storage");
    const data = JSON.parse(AuthDetails ?? "");
    const accessToken = data.accessToken?.accessToken;
    const authorizationToken = format("Bearer {0}", accessToken);
    const funckeyParse = getKeyData();
    const response = await fetch(updateStorageLocationUrl, {
      method: "POST",
      headers: {
        "x-functions-key": funckeyParse.InventoryManagerFunction ?? "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: authorizationToken,
      },
      body: JSON.stringify(reqParams),
    });
    if (response.status === 200) {
      const data = response.json();
      return data;
    } else {
      return response;
    }
  } catch (error) {
    console.log("error", error);
  }
};

export const moveInventoryProduct = async (reqParams: any) => {
  try {
    const baseUrl = process.env.REACT_APP_INVENTORY_MGR_FUNC_BASE_URL ?? "";
    const updateStorageLocationUrl = format(
      "{0}{1}",
      baseUrl,
      SVC_INVENTORY_MOVE_PRODUCT
    );
    const AuthDetails = sessionStorage.getItem("okta-token-storage");
    const data = JSON.parse(AuthDetails ?? "");
    const accessToken = data.accessToken?.accessToken;
    const authorizationToken = format("Bearer {0}", accessToken);
    const funckeyParse = getKeyData();
    const response = await fetch(updateStorageLocationUrl, {
      method: "POST",
      headers: {
        "x-functions-key": funckeyParse.InventoryManagerFunction ?? "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: authorizationToken,
      },
      body: JSON.stringify(reqParams),
    });
    if (response.status === 200) {
      const data = response.json();
      return data;
    } else {
      return response;
    }
  } catch (error) {
    console.log("error", error);
  }
};

export const updateInventoryStorageLocation = async (reqParams: any) => {
  try {
    const baseUrl = process.env.REACT_APP_INVENTORY_MGR_FUNC_BASE_URL ?? "";
    const getStorageLocationListUrl = format(
      "{0}{1}",
      baseUrl,
      SVC_UPDATE_INVENTORY_LOCATION
    );
    const AuthDetails = sessionStorage.getItem("okta-token-storage");
    const data = JSON.parse(AuthDetails ?? "");
    const accessToken = data.accessToken?.accessToken;
    const authorizationToken = format("Bearer {0}", accessToken);
    const funckeyParse = getKeyData();
    const response = await fetch(getStorageLocationListUrl, {
      method: "POST",
      headers: {
        "x-functions-key": funckeyParse.InventoryManagerFunction ?? "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: authorizationToken,
      },
      body: JSON.stringify(reqParams),
    });
    if (response.status === 200) {
      const data = response.json();
      return data;
    } else {
      return response.json();
    }
  } catch (error) {
    console.log("error", error);
  }
};

export const submitReturnSolventumUnit = async (reqParams: any) => {
  try {
    const baseUrl = process.env.REACT_APP_INVENTORY_MGR_FUNC_BASE_URL ?? "";
    const updateStorageLocationUrl = format(
      "{0}{1}",
      baseUrl,
      SVC_RETURN_SOLVENTUM_UNIT
    );
    const AuthDetails = sessionStorage.getItem("okta-token-storage");
    const data = JSON.parse(AuthDetails ?? "");
    const accessToken = data.accessToken?.accessToken;
    const authorizationToken = format("Bearer {0}", accessToken);
    const funckeyParse = getKeyData();
    const response = await fetch(updateStorageLocationUrl, {
      method: "POST",
      headers: {
        "x-functions-key": funckeyParse.InventoryManagerFunction ?? "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: authorizationToken,
      },
      body: JSON.stringify(reqParams),
    });
    if (response.status === 200) {
      const data = response.json();
      return data;
    } else {
      return response;
    }
  } catch (error) {
    console.log("error", error);
  }
};

export const getCustomerPlusListData = async (reqParams: any) => {
  try {
    const baseUrl = process.env.REACT_APP_INVENTORY_MGR_FUNC_BASE_URL ?? "";
    const updateStorageLocationUrl = format(
      "{0}{1}",
      baseUrl,
      SVC_GET_CUSTOMER_PLUS_LIST
    );
    const AuthDetails = sessionStorage.getItem("okta-token-storage");
    const data = JSON.parse(AuthDetails ?? "");
    const accessToken = data.accessToken?.accessToken;
    const authorizationToken = format("Bearer {0}", accessToken);
    const funckeyParse = getKeyData();
    const response = await fetch(updateStorageLocationUrl, {
      method: "POST",
      headers: {
        "x-functions-key": funckeyParse.InventoryManagerFunction ?? "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: authorizationToken,
      },
      body: JSON.stringify(reqParams),
    });
    if (response.status === 200) {
      const data = response.json();
      return data;
    } else {
      return response;
    }
  } catch (error) {
    console.log("error", error);
  }
};

export const addOrUpdateCustomerPlusDetails = async (reqParams: any) => {
  try {
    const baseUrl = process.env.REACT_APP_INVENTORY_MGR_FUNC_BASE_URL ?? "";
    const getCustomerPlusUpdateData = format(
      "{0}{1}",
      baseUrl,
      SVC_ADD_OR_UPDATE_CUSTOMER_PLUS
    );
    const AuthDetails = sessionStorage.getItem("okta-token-storage");
    const data = JSON.parse(AuthDetails ?? "");
    const accessToken = data.accessToken?.accessToken;
    const authorizationToken = format("Bearer {0}", accessToken);
    const funckeyParse = getKeyData();
    const response = await fetch(getCustomerPlusUpdateData, {
      method: "POST",
      headers: {
        "x-functions-key": funckeyParse.InventoryManagerFunction ?? "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: authorizationToken,
      },
      body: JSON.stringify(reqParams),
    });
    if (response.status === 200) {
      const data = response.json();
      return data;
    } else {
      return response;
    }
  } catch (error) {
    console.log("error", error);
  }
};

export const submitCreateServiceRequest = async (reqParams: any) => {
  try {
    const baseUrl = process.env.REACT_APP_INVENTORY_MGR_FUNC_BASE_URL ?? "";
    const url = format("{0}{1}", baseUrl, SVC_SUBMIT_CREATE_SERVICE_REQUEST);
    const AuthDetails = sessionStorage.getItem("okta-token-storage");
    const data = JSON.parse(AuthDetails ?? "");
    const accessToken = data.accessToken?.accessToken;
    const authorizationToken = format("Bearer {0}", accessToken);
    const funckeyParse = getKeyData();
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "x-functions-key": funckeyParse.InventoryManagerFunction ?? "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: authorizationToken,
      },
      body: JSON.stringify(reqParams),
    });
    if (response.status === 200) {
      const data = response.json();
      return data;
    } else {
      return response;
    }
  } catch (error) {
    console.log("error", error);
  }
};

export const submitInventoryAdjustment = async (reqParams: any) => {
  try {
    const AuthDetails = sessionStorage.getItem("okta-token-storage");
    const data = JSON.parse(AuthDetails ?? "");
    const accessToken = data.accessToken?.accessToken;
    const baseUrl = process.env.REACT_APP_3ME_SVC_BASE_URL ?? "";
    const submitInventoryAdjustmentUrl = format(
      "{0}{1}",
      baseUrl,
      SVC_SUBMIT_INVENTORY_ADJUSTMENT
    );
    const authorizationToken = format("Bearer {0}", accessToken);
    const response = await fetch(submitInventoryAdjustmentUrl, {
      method: "POST",
      headers: {
        AuthorizationKey: process.env.REACT_APP_3ME_SVC_API_KEY ?? "",
        Authorization: authorizationToken,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(reqParams),
    });
    if (response.status === 200) {
      const data = response.json();
      return data;
    } else {
      return response;
    }
  } catch (error) {
    console.log("error", error);
  }
};
export const getOrderWithSolventumProduct = async (reqParams: any) => {
  try {
    const baseUrl = process.env.REACT_APP_FACILITY_MANAGER_FUNC_BASE_URL ?? "";
    const getSolventumProductUrl = format(
      "{0}{1}",
      baseUrl,
      SVC_GET_SOLVENTUM_PRODUCTS
    );
    const AuthDetails = sessionStorage.getItem("okta-token-storage");
    const data = JSON.parse(AuthDetails ?? "");
    const accessToken = data.accessToken?.accessToken;
    const authorizationToken = format("Bearer {0}", accessToken);
    const funckeyParse = getKeyData();
    const response = await fetch(getSolventumProductUrl, {
      method: "POST",
      headers: {
        "x-functions-key": funckeyParse.FacilityManagerFunction ?? "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: authorizationToken,
      },
      body: JSON.stringify(reqParams),
    });
    if (response.status === 200) {
      const data = response.json();
      return data;
    } else {
      return response;
    }
  } catch (error) {
    console.log("error", error);
  }
};
