import { useState, useEffect, useRef, useCallback, useContext } from "react";
import { AgGridReact } from "ag-grid-react";
import {
  agGridGetRowStyle,
  getCSV,
  makeCapitalEachWordInString,
  showVacOrderMenuOption,
} from "../../../util/utilityFunctions";
import "./inProcessOrder.css";
import { ReactComponent as CheckIcon } from "../../../assets/checkMark.svg";
import { useMediaQuery } from "@mui/material";
import { LoadingSpinner } from "../../../core/loader/LoadingSpinner";
import "../../../core/loader/spinner.css";
import ReactDomServer from "react-dom/server";
import ErrorPopup from "../../../core/errorPopup/errorPopup.component";
import {
  ERROR_MSG_ORDER_DETAIL_FAILED,
  ERROR_MSG_VIEW_REPORT_IN_PROCESS,
  ERROR_MSG_DOWNLOAD_REPORT_IN_PROCESS,
} from "../../../util/errorMsg";
import { ExpressButton } from "../../../core/expressButton/expressButton.component";
import { ReactComponent as DownloadDisabled } from "../../../assets/downloadDisabled.svg";
import { ReactComponent as DownloadIcon } from "../../../assets/download.svg";
import { AlertToolTip } from "../../../core/alertToolTip/alertToolTip.component";

import moment from "moment";
import { IInProcessOrders } from "./inProcessOrder.Interface";
import {
  allFacilitySearch,
  caregiverFacilitySearch,
  checkPostAcuteFacility,
  getInProcessOrderByTerritory,
  getInProcessOrderByTerritoryCSV,
  getPatientMenuAction,
} from "../../../util/3meService";
import { AuthContext, AuthContextType } from "../../../context/AuthContext";
import { useHistory } from "react-router-dom";
import { mapUserRolesAndPermissionData } from "../../../RolesPermission/RolesPermission.Mapper";
import { IFacility } from "../../../components/manageProfile/facilityInformation/facility.interface";
import { ICheckPostAcuteFacility } from "../../../components/manageProfile/facilityInformation/facilityFound/facilityFound.interface";
import { selectFacility } from "../../../util/userService";
import {
  InternalSignOnContextType,
  InternalSignOnContext,
} from "../../../context/InternalSignOnContext";
import {
  RolesPermissionContextType,
  RolesPermissionContext,
} from "../../../context/RolesPermissionContext";
import { Popup } from "../../../core/popup/popup.component";
import { IAlertToolTip } from "../../../core/alertToolTip/alertToolTip.interface";
import { IAlertTypes } from "../../../components/myPatients/patient.interface";
import {
  OrderDetailContext,
  OrderDetailContextType,
} from "../../../context/OrderDetailsContext";
import { OrderOverViewTabsTitle } from "../../../components/myPatients/patientAndTherapyDetails/orderOverview/orderOverviewContainer.enum";
import {
  IGetOrderSuppliesRequest,
  ISupplyOrdersInfo,
} from "../../../components/myPatients/patientAndTherapyDetails/orderSupplyDetail/orderSupplyDetails.interface";
import { getOrderSupplies } from "../../../util/orderOverViewService";
import { getOrderStatusDetails } from "../../../util/vacOrderService";

type Props = {
  selectedTerritory: string | undefined;
};

const InProcessOrder = ({ selectedTerritory }: Props) => {
  const gridRef: any = useRef(null);
  const isMobileScreen = useMediaQuery("(max-width:920px)");
  const [currentPosts, setCurrentPost] = useState<IInProcessOrders[] | null>(
    null
  );
  const [errorPopUpFlag, setErrorPopUpFlag] = useState(false);
  const [goBackOnErrorBtn, setGoBackOnErrorBtn] = useState(true);
  const [errorCode, setErrorCode] = useState();
  const [openLoaderPopUp, setOpenLoaderPopUp] = useState<boolean>(false);
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const [disableDownloadBtn, setDisableDownloadBtn] = useState(true);
  const history = useHistory();
  const permissionsObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );
  const internalSignOnObj = useContext<InternalSignOnContextType | null>(
    InternalSignOnContext
  );
  const [errorMessage, setErrorMessage] = useState("");
  const orderOverviewObj = useContext<OrderDetailContextType | null>(
    OrderDetailContext
  );

  const defaultColDef = {
    suppressMovable: true,
    sortable: true,
    unSortIcon: true,
    suppressMenu: true,
    autoHeight: true,
  };

  const uid = () => Math.floor(Math.random() * Date.now()).toString();

  const getAlertTypeCode = (alertName: String) => {
    switch (alertName) {
      case "Missing Rx":
        return IAlertTypes.MISRX;
      case "Missing Docs":
        return IAlertTypes.MSDOC;
      case "Confirm Placement":
        return IAlertTypes.CONPL;
      case "Pending Supply Order":
        return IAlertTypes.PNDSO;
      case "Proof of Delivery Needed":
        return IAlertTypes.PODEL;
      case "Supplies Shipped":
        return IAlertTypes.SUPSH;
    }
  };

  const getAlertContent = (alert: string[]) => {
    let alertObj: any = [];
    alert.map((alert) => {
      let obj = {
        alertID: uid(),
        alertName: alert,
        alertSubLabel: "",
        alertDate: null,
        alertType: getAlertTypeCode(alert),
        severity: null,
      };
      alertObj.push(obj);
    });
    return alertObj;
  };

  const getMappedALertData = (
    alert: string[],
    count: string,
    children: any,
    data: IInProcessOrders
  ) => {
    if (count !== "") {
      const obj: IAlertToolTip = {
        alertCount: count,
        alertData: getAlertContent(alert),
        ropn: data?.supplyOrders[0]?.ropn!,
        children: children,
        tableData: data,
      };
      return obj;
    }
  };

  const columnDefs: any = [
    {
      headerName: "Patient Name",
      field: "patientLastName",
      wrapText: true,
      pinned: isMobileScreen ? null : "left",
      lockPinned: true,
      wrapHeaderText: true,
      cellRenderer: function (params: any) {
        return (
          <>
            {makeCapitalEachWordInString(params.data.patientLastName)}
            {", "} {makeCapitalEachWordInString(params.data.patientFirstName)}
          </>
        );
      },
    },
    {
      field: "roNumber",
      headerName: "RO #",
      width: isMobileScreen ? 90 : 100,
      pinned: "left",
      lockPinned: true,
      cellRenderer: function (params: any) {
        return (
          <>
            <span
              className={"inprocess-order-ro"}
              id={params.data.roNumber}
              onClick={(e) => navigateToOrderOverview(params.data)}
            >
              {params.data.roNumber}
            </span>
          </>
        );
      },
    },
    {
      field: "patientDOB",
      headerName: "DOB",
    },
    {
      field: "status",
      headerName: "Status",
      cellRenderer: function (params: any) {
        return (
          <>
            <span
              className={`circle ${getColor(params.data.statusColor)}`}
            ></span>
            {params.data.status}
          </>
        );
      },
    },
    {
      field: "alerts",
      headerName: "Alerts",
      tooltipField: "Alerts",
      cellClass: "tableAlertData",
      headerClass: "tableData",
      cellRenderer: function (params: any) {
        const mappedAlertData = getMappedALertData(
          params?.data?.alerts,
          params.data.alerts.length !== 0 ? params.data.alerts.length : "",
          "",
          params?.data
        );
        return (
          <>
            <AlertToolTip
              alertCount={mappedAlertData?.alertCount!}
              alertData={mappedAlertData?.alertData!}
              tableData={mappedAlertData?.tableData}
              ropn={mappedAlertData?.ropn}
              navigateToOrderOverview={navigateToOrderOverview}
            >
              <></>
            </AlertToolTip>
          </>
        );
      },
    },
    {
      field: "readyCare",
      headerName: "Ready Care",
      wrapHeaderText: true,
      cellClass: "tableData",
      cellRenderer: function (params: any) {
        return <>{params.data.readyCare ? <CheckIcon /> : <></>}</>;
      },
    },
    {
      field: "transitionOrder",
      headerName: "Transition Order?",
      wrapHeaderText: true,
      cellClass: "tableData",
      cellRenderer: function (params: any) {
        return <>{params.data.transitionOrder ? <CheckIcon /> : <></>}</>;
      },
    },
    {
      headerName: "Requesting Facility",
      field: "requestingFacility",
      wrapText: true,
      autoHeight: true,
      cellRenderer: function (params: any) {
        return (
          <>{makeCapitalEachWordInString(params.data.requestingFacility)}</>
        );
      },
    },
    {
      headerName: "Home Care Facility",
      field: "homeCareFacility",
      wrapText: true,
      autoHeight: true,
      cellRenderer: function (params: any) {
        return <>{makeCapitalEachWordInString(params.data.homeCareFacility)}</>;
      },
    },
    {
      headerName: "Need By",
      field: "needby",
      cellRenderer: function (params: any) {
        return (
          <>
            {moment(params.data.needby).format("MM/DD/YYYY")}
            {", "}
            {moment(params.data.needby).format("hh:mma")}
          </>
        );
      },
    },
    {
      headerName: "Created",
      field: "created",
      cellRenderer: function (params: any) {
        return <>{moment(params.data.created).format("MM/DD/YYYY")}</>;
      },
    },
  ];

  const getColor = (statusColor: string) => {
    let className = "greenCircle";
    switch (statusColor) {
      case "red":
        className = "redCircle";
        break;
      case "green":
        className = "greenCircle";
        break;
      case "yellow":
        className = "yellowCircle";
        break;
      default:
        break;
    }
    return className;
  };
  const turnOnNoRecordOverlay = useCallback(() => {
    gridRef?.current?.api?.showNoRowsOverlay();
  }, []);

  const clearData = useCallback(() => {
    const rowData: any = [];
    gridRef?.current?.api?.forEachNode(function (node: any) {
      rowData.push(node.data);
    });
    gridRef?.current?.api?.applyTransaction({
      remove: rowData,
    });
  }, []);

  const callGetOrderApi = async () => {
    setOpenLoaderPopUp(true);
    const reqBody = {
      LoggedInUserName: authObj?.userProfile?.userName,
      TerritoryCode: selectedTerritory,
    };
    const inProcessOrderRes: any = await getInProcessOrderByTerritory(reqBody);
    if (inProcessOrderRes && !inProcessOrderRes?.succeeded) {
      setErrorPopUpFlag(true);
      setErrorMessage(ERROR_MSG_VIEW_REPORT_IN_PROCESS);
      setErrorCode(
        inProcessOrderRes?.error?.errorCode || inProcessOrderRes?.status
      );
      setCurrentPost([]);
      turnOnNoRecordOverlay();
    } else if (inProcessOrderRes?.items?.length > 0) {
      setCurrentPost(inProcessOrderRes?.items);
      setDisableDownloadBtn(false);
    } else {
      turnOnNoRecordOverlay();
      setCurrentPost([]);
    }
    setOpenLoaderPopUp(false);
  };
  useEffect(() => {
    setCurrentPost(null);
    clearData();
    setCurrentPost(null);
    setDisableDownloadBtn(true);
    if (selectedTerritory) {
      callGetOrderApi();
    }
  }, [selectedTerritory]);

  const NoRecordsFoundForAggrid = () => {
    return <div>No Orders Found</div>;
  };

  const getNodesAndCallAPI = async () => {
    setGoBackOnErrorBtn(false);
    setOpenLoaderPopUp(true);
    let rowData: any[] = [];
    gridRef?.current?.api?.forEachNode((node: any) => rowData.push(node.data));
    let sendToCSV = rowData.map((item: any) => {
      return {
        patientFirstName: makeCapitalEachWordInString(item.patientFirstName),
        patientLastName: makeCapitalEachWordInString(item.patientLastName),
        roNumber: item.roNumber,
        patientDOB: item.patientDOB,
        status: makeCapitalEachWordInString(item.status),
        alerts: item.alerts,
        readyCare: item.readyCare,
        homeCareFacility: makeCapitalEachWordInString(item.homeCareFacility),
        requestingFacility: makeCapitalEachWordInString(
          item.requestingFacility
        ),
        needby: ` ${moment(item.needby).format("MM/DD/YYYY")}, ${moment(
          item.needby
        ).format("hh:mm a")}`,
        created: moment(item.created).format("MM/DD/YYYY"),
        transitionOrder: item.transitionOrder,
      };
    });
    const reqBody = {
      reports: sendToCSV,
    };
    const inProcessOrderCSVRes: any = await getInProcessOrderByTerritoryCSV(
      reqBody
    );
    if (!inProcessOrderCSVRes?.succeeded) {
      setErrorPopUpFlag(true);
      setErrorMessage(ERROR_MSG_DOWNLOAD_REPORT_IN_PROCESS);
      setErrorCode(
        inProcessOrderCSVRes?.error?.errorCode || inProcessOrderCSVRes?.status
      );
      setOpenLoaderPopUp(false);
    } else if (inProcessOrderCSVRes.item) {
      await getCSV(inProcessOrderCSVRes.item, "IN_PROCESS_ORDER_");
      setOpenLoaderPopUp(false);
    }
  };

  const navigateToOrderOverview = async (
    data: IInProcessOrders,
    selectedAlert?: any,
    ropn?: any
  ) => {
    if (data.requestingFacilitySiteUseId) {
      setOpenLoaderPopUp(true);
      const facilityResponse = await getFacilityInfoList(data);
      if (facilityResponse && facilityResponse.succeeded) {
        const facility = await getFacilityInfo(facilityResponse.items);
        if (facility) {
          authObj?.setregisteredFaciltyAddress(facility);
          const roleAndPermissionReponse = await validateRoleAndPermissions(
            facility
          );
          permissionsObj?.setMappedRolesPermissionData(
            roleAndPermissionReponse
          );
          let adminText: any = "Administration";
          if (authObj && authObj.unLinkedFacilitesCount) {
            adminText = (
              <div className="sideNavmadminBtnMain">
                Administration
                <span className="sideNavmadminBtnStyle">
                  {authObj.unLinkedFacilitesCount}{" "}
                </span>{" "}
              </div>
            );
          }
          authObj?.setsideNavMenuOptionModelData({
            ...authObj?.sideNavMenuOptionModelData,
            orders: {
              labelText: "Start New Order",
              isVisible: showVacOrderMenuOption(roleAndPermissionReponse),
            },
            inventory: {
              labelText: "Inventory",
              isVisible: roleAndPermissionReponse.IsShowInventoryOption,
            },
            administration: {
              labelText: adminText,
              isVisible: roleAndPermissionReponse.IsShowAdminstrationOption,
            },
          });
          internalSignOnObj?.setIsManualFacility(false);

          getMenuBuildPatientData(data, selectedAlert, ropn);
        } else {
          setOpenLoaderPopUp(false);
        }
      } else {
        setOpenLoaderPopUp(false);
        setErrorPopUpFlag(true);
        setErrorCode(
          facilityResponse?.error?.errorCode || facilityResponse?.status
        );
      }
    }
  };

  const getFacilityInfoList = async (data: IInProcessOrders) => {
    const facilityResponse: any = await allFacilitySearch({
      customerName: "",
      customerNumber: "",
      siteUseId: data.requestingFacilitySiteUseId,
      state: "",
    });
    if (facilityResponse && facilityResponse.succeeded) {
      return facilityResponse;
    } else {
      setOpenLoaderPopUp(false);
      setErrorPopUpFlag(true);
      setErrorCode(
        facilityResponse?.error?.errorCode || facilityResponse?.status
      );
      return undefined;
    }
  };

  const getFacilityInfo = async (facilities: IFacility[]) => {
    authObj?.setAllFacilties(facilities);
    let facilityResObj;
    let facilityObject: IFacility;
    if (facilities.length === 1) {
      facilityResObj = facilities[0];
    }
    if (facilityResObj) {
      facilityObject = {
        ...facilityResObj,
        isPostAcute: await checkPostAcuteFacilityFound(facilityResObj.typeCode),
        careGiverId: await getCareGiverValue(facilityResObj),
      };
      return facilityObject;
    } else {
      return null;
    }
  };

  const checkPostAcuteFacilityFound = async (typeCode: string) => {
    const reqBody = { FacilityTypeCode: typeCode };
    const response: ICheckPostAcuteFacility = await checkPostAcuteFacility(
      reqBody
    );
    return response.postAcuteFound;
  };

  const getCareGiverValue = async (facility: IFacility) => {
    let caregiverIdValue: string;
    const isCareGiver = facility.siteUseCode?.includes("CG");
    if (isCareGiver) {
      const careGiverIdRes = await caregiverFacilitySearch({
        customerAccountNumber: facility.accountNumber,
      });
      caregiverIdValue = careGiverIdRes.items[0].origSystemReference;
      return caregiverIdValue;
    } else {
      return "";
    }
  };

  const getMenuBuildPatientData = async (
    data: IInProcessOrders,
    selectedAlert: any,
    ropn: any
  ) => {
    setOpenLoaderPopUp(true);
    const patientObj = await getPatientInformation(data);
    const alertType = getAlertTypeCode(selectedAlert?.alertName);
    switch (alertType) {
      case IAlertTypes.PNDSO:
        orderOverviewObj?.setSelectedOrderTab(
          OrderOverViewTabsTitle.SUPPLY_ORDER_TAB_TITLE
        );
        const supplyOrderData: ISupplyOrdersInfo = {
          createdDate: "",
          shippedDate: "",
          initiatedName: "",
          product: "",
          ropn: ropn,
          status: "",
          statusColor: "",
          deliveredDate: "",
        };
        getOrderSuppliesDetail(
          JSON.parse(data?.roNumber!),
          data.patientDOB!,
          ropn,
          data
        );
        orderOverviewObj?.setSelectedSupplyOrder(supplyOrderData);
        orderOverviewObj?.setSelectedOrderTab(
          OrderOverViewTabsTitle.ORDER_TAB_TITLE
        );
        getTherapyStartDate(JSON.parse(data?.roNumber!), data?.patientDOB!);
        orderOverviewObj?.setIsOrderFlow(true);
        orderOverviewObj?.setOrderTabTitle("Supply Orders");
        if (patientObj !== null) {
          authObj?.setuserRolesAndPermissionLoaded(true);
          authObj?.setIsInternalUserFacilitySelected(true);
          authObj?.setUpdateFacilityBannerFacility(true);
          history.push({
            pathname: "/home/orderOverview",
            state: {
              stateData: patientObj,
            },
          });
          setOpenLoaderPopUp(false);
        } else {
          setOpenLoaderPopUp(false);
        }
        return null;
      case IAlertTypes.MSDOC:
        window.scrollTo(0, 0);
        orderOverviewObj?.setSelectedOrderTab(
          OrderOverViewTabsTitle.DOCUMENTS_TAB_TITLE
        );
        if (patientObj !== null) {
          authObj?.setuserRolesAndPermissionLoaded(true);
          authObj?.setIsInternalUserFacilitySelected(true);
          authObj?.setUpdateFacilityBannerFacility(true);
          history.push({
            pathname: "/home/orderOverview",
            state: {
              stateData: patientObj,
            },
          });
          setOpenLoaderPopUp(false);
        } else {
          setOpenLoaderPopUp(false);
        }
        return null;
      default:
        if (patientObj !== null) {
          authObj?.setuserRolesAndPermissionLoaded(true);
          authObj?.setIsInternalUserFacilitySelected(true);
          authObj?.setUpdateFacilityBannerFacility(true);
          history.push({
            pathname: "/home/orderOverview",
            state: {
              stateData: patientObj,
            },
          });
          setOpenLoaderPopUp(false);
        } else {
          setOpenLoaderPopUp(false);
        }
        return null;
    }
  };

  const getTherapyStartDate = async (ron: number, dob: string) => {
    if (
      authObj &&
      authObj.userProfile &&
      authObj.userProfile.userName &&
      authObj.userProfile.userName !== "" &&
      authObj.registeredFaciltyAddress &&
      authObj.registeredFaciltyAddress.siteUseId &&
      authObj.registeredFaciltyAddress.siteUseId !== ""
    ) {
      const reqParam = {
        RentalOrderNumber: ron.toString(),
        DOB: dob,
        userName: authObj.userProfile.userName,
        siteUseId: authObj.registeredFaciltyAddress.siteUseId,
      };
      try {
        const result = await getOrderStatusDetails(reqParam);
        if (result.succeeded) {
          const dateStr = result.item.therapyDate;
          const date = dateStr
            ? moment(dateStr, "YYYY-MM-DDTHH:mm:ss").toDate().toString()!
            : null;
          orderOverviewObj?.setSelectedSupplyTherapyStartDate(date);
        }
        return true;
      } catch {
        return false;
      }
    }
  };

  const getOrderSuppliesDetail = async (
    ro: number,
    dob: string,
    ropn?: string | null,
    patient?: IInProcessOrders
  ) => {
    let reqParams: IGetOrderSuppliesRequest = {
      RentalOrderNumber: ro,
      dob: moment(dob).format("DD-MMM-yyyy").toString(),
      isAcuteOrder:false
    };
    try {
      const responseObj = await getOrderSupplies(reqParams);

      if (responseObj?.succeeded) {
        const data = responseObj;
        const supplyDetails: ISupplyOrdersInfo = data.item.supplyOrders.find(
          (x: ISupplyOrdersInfo) => x.ropn === ropn
        );
        const supplyOrderData: ISupplyOrdersInfo = {
          createdDate: "",
          shippedDate: "",
          initiatedName: "",
          product: "",
          ropn: supplyDetails?.ropn!,
          status: supplyDetails.status,
          statusColor: supplyDetails.statusColor,
          deliveredDate: "",
        };
        orderOverviewObj?.setSelectedSupplyOrder(supplyOrderData);
        getTherapyStartDate(data?.roNumber!, data?.patientDOB!);
        orderOverviewObj?.setIsOrderFlow(true);
        orderOverviewObj?.setOrderTabTitle("Supply Orders");
        history.push({
          pathname: "/home/orderOverview",
          state: {
            stateData: patient,
          },
        });
      } else return false;
    } catch (error) {
      console.log("error", error);
    }
  };

  const getPatientInformation = async (data: IInProcessOrders) => {
    const fDob = moment(data.patientDOB).format("YYYY-MM-DDTHH:mm:ss");
    const menuActionsResponse = await getPatientMenuAction(
      data.readyCare === true ? "ReadyCare" : "Standard",
      true,
      data.roNumber,
      fDob
    );
    if (menuActionsResponse.succeeded) {
      return {
        ...menuActionsResponse.data,
        roNumber: data.roNumber,
        firstName: data.patientFirstName,
        lastName: data.patientLastName,
        status: data.status,
        statusColor: data.statusColor + "Circle",
        dob: fDob,
        orderCreationDate: data.orderedDate,
      };
    } else {
      setOpenLoaderPopUp(false);
      setErrorPopUpFlag(true);
      setErrorMessage(ERROR_MSG_ORDER_DETAIL_FAILED);
      setErrorCode(
        menuActionsResponse?.error?.errorCode || menuActionsResponse?.status
      );
      return null;
    }
  };

  const validateRoleAndPermissions = async (facility: IFacility) => {
    const roleAndPermissionsResponse = await selectFacility({
      UserName: authObj?.userProfile?.userName,
      SiteUseId: facility.siteUseId,
    });
    const rolesPermissionRes = await mapUserRolesAndPermissionData(
      roleAndPermissionsResponse,
      facility.readyCareFlag
    );
    return rolesPermissionRes;
  };

  return (
    <>
      <div className="orderDetailAndExportBtn">
        <div className="orderRecordCount" data-testid="orderRecordCount">
          {currentPosts ? currentPosts.length : "0"} orders returned
        </div>
        <div className="export-coversheet-div">
          <ExpressButton
            parentClass="export-title"
            variant="text"
            clickHandler={() => getNodesAndCallAPI()}
            startIcon={
              disableDownloadBtn ? <DownloadDisabled /> : <DownloadIcon />
            }
            testId="export-cover-sheet"
            disabled={disableDownloadBtn}
          >
            Download Report
          </ExpressButton>
        </div>
      </div>
      <div
        data-testid="ag-theme-quartz-table"
        className="ag-theme-quartz"
        style={{ height: 500 }}
      >
        <AgGridReact
          ref={gridRef}
          getRowStyle={agGridGetRowStyle}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowData={currentPosts}
          suppressRowClickSelection={true}
          suppressDragLeaveHidesColumns={true}
          suppressContextMenu={true}
          onBodyScroll={() => {
            internalSignOnObj?.setIsViewReportsScrolled(
              internalSignOnObj.isViewReportsScrolled++
            );
          }}
          overlayNoRowsTemplate={ReactDomServer.renderToString(
            NoRecordsFoundForAggrid()
          )}
          autoSizeStrategy={{
            type: "fitGridWidth",
            defaultMinWidth: 170,
          }}
        />
      </div>
      <ErrorPopup
        errorPopupFlag={errorPopUpFlag}
        handleBackButton={() =>
          goBackOnErrorBtn ? history.goBack() : setErrorPopUpFlag(false)
        }
        popUpStyles="errorPopup"
        errorMessage={errorMessage}
        buttontitle="Done"
        showTitle={false}
        isShortSentence={true}
        errorCode={errorCode}
      />
      <Popup
        openFlag={openLoaderPopUp}
        closeHandler={() => setOpenLoaderPopUp(false)}
        dialogParentClass={"update-facility-type-loader-pop-up"}
        data-testid="loader-pop-up"
        hideCloseButton={true}
      >
        <div className="update-facility-type-loader">
          <LoadingSpinner />
        </div>
      </Popup>
    </>
  );
};

export default InProcessOrder;
