import moment from "moment";
import "./requestedSites.css";
import {
  IRequestedSitesProps,
  IUserUnlinkedFacilityData,
} from "../userProfile.interface";
import { useContext } from "react";
import { Button, Typography } from "@mui/material";
import {
  RolesPermissionContext,
  RolesPermissionContextType,
} from "../../../../../context/RolesPermissionContext";
import Table from "../../../../../core/customSortingTable/table.component";
import { makeCapitalEachWordInString } from "../../../../../util/utilityFunctions";

export const RequestedSites = ({
  linkToFacilityBtnAction,
  unlinkedFacilities,
}: IRequestedSitesProps) => {
  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );

  const columns = [
    {
      accessor: "facilityName",
      label: "Facility Site Name",
      sortable: false,
      sortID: 1,
    },
    {
      accessor: "dateRequested",
      label: "Date Requested",
      sortable: false,
      sortID: 2,
    },
    { label: "", accessor: "", sortable: false, sortID: 3 },
  ];

  return (
    <div
      className="requested-sites-component"
      data-testid="requested-sites-component"
    >
      <div
        className="requested-sites-component-header"
        data-testid="requested-sites-component-header"
      >
        <p
          className="requested-sites-component-header-title"
          data-testid="requested-sites-component-header-title"
        >
          Requested sites
        </p>
        <p
          className="requested-sites-component-header-description"
          data-testid="requested-sites-component-header-description"
        >
          The user has requested access to the following sites. Confirm the
          connection by choosing “Link to facility” next to the facility. You
          can then adjust their privileges in the
          <span className="bold"> Site access </span>table below.
        </p>
      </div>
      <div className="unlinked-facilities-table-div">
        <Table
          tableClassName="unlinked-facilities-table"
          tableColumns={columns}
          handleSorting={null}
        >
          {columns && unlinkedFacilities && unlinkedFacilities.length > 0 ? (
            <tbody>
              {unlinkedFacilities.map(
                (data: IUserUnlinkedFacilityData, index: number) => {
                  return (
                    <tr>
                      <td
                        className="unlinked-facilities-tables-data"
                        data-testid={`facility-details-${index + 1}`}
                      >
                        <div className="facilityDetails">
                          <Typography
                            className="facilityName"
                            data-testid="facility-name"
                          >
                            {makeCapitalEachWordInString(data.facilityName)}
                          </Typography>
                          <Typography
                            className="facilityAddress"
                            data-testid={`address-${index + 1}`}
                          >
                            {`${makeCapitalEachWordInString(data.address1)}, ${
                              data.address2 && data.address2 !== ""
                                ? `${makeCapitalEachWordInString(
                                    data.address2
                                  )},`
                                : ""
                            } ${makeCapitalEachWordInString(data.city)}, ${
                              data.state
                            } ${data.zipCode}`}
                          </Typography>
                        </div>
                      </td>
                      <td
                        className="unlinked-facilities-tables-data"
                        data-testid={`requested-${index + 1}`}
                      >
                        {moment
                          .utc(data.requestedDate)
                          .local()
                          .format("MM/DD/YY")}
                      </td>
                      <td>
                        <Button
                          className={`unlinked-facilities-tables-link-to-facility ${
                            permissionObj?.mappedRolesPermissionData
                              ?.IsSupportRole
                              ? `disabled`
                              : ""
                          }`}
                          data-testid={`link-to-facility-${index + 1}`}
                          disabled={
                            permissionObj?.mappedRolesPermissionData
                              .IsSupportRole
                          }
                          onClick={() => {
                            linkToFacilityBtnAction(data);
                          }}
                        >
                          Link to Facility
                        </Button>
                      </td>
                    </tr>
                  );
                }
              )}
            </tbody>
          ) : (
            <tbody></tbody>
          )}
        </Table>
      </div>
    </div>
  );
};
