import {
  FormControlLabel,
  Grid,
  InputBase,
  Radio,
  RadioGroup,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import parse from "html-react-parser";
import { useContext, useEffect, useState } from "react";
import { format } from "react-string-format";
import { ReactComponent as RadioButtonIcon } from "../../../assets/radioButton.svg";
import { ReactComponent as SelectedRadioButtonIcon } from "../../../assets/selectedRadioButton.svg";
import { MobileDisplayContext } from "../../../context/MobileDisplayContext";
import {
  NewOrderContext,
  NewOrderContextType,
} from "../../../context/NewOrderContext";
import { CustomDropDown } from "../../../core/customDropdown/customDropdown.component";
import { InputWithLabel } from "../../../core/inputWithLabel/inputWithLabel.component";
import { ValidationStatus } from "../../../core/interfaces/input.interface";
import { getdropDownContent } from "../../../util/dropDownService";
import {
  DD_WOUND_AGE_TYPE,
  DD_WOUND_DIRECTION,
  DD_WOUND_LOCATION,
  DD_WOUND_ORIENTATION,
} from "../../../util/staticText";
import {
  convertStringToDate,
  getCodeFromText,
  getTextFromCode,
} from "../../../util/utilityFunctions";
import { NewOrderValidator } from "../newOrder.validator";
import {
  Question,
  QuestionTypes,
  WoundQuestionaries,
} from "./clinicalInfo.interface";
import "./clinicalInformation.css";
import { IClinicalInformation } from "./clinicalInformation.interface";
import { DateQuestion } from "./questionComponents/dateQuestion.component";
import { RadioQuestion } from "./questionComponents/radioQuestion.components";
import { TextQuestion } from "./questionComponents/textQQuestion.component";
import { ClinicalInformationReviewOrder } from "./reviewOrder/clinicalInformationReviewOrder.component";

export const ClinicalInformation = ({
  editButtonClicked,
  isOrderSummary = false,
  isReviewOrder = false,
  isSecondaryWoundInfo = false,
  newValidator = new NewOrderValidator(),
  woundInfoData,
  setWoundInfoData,
  newOrderObj,
}: IClinicalInformation) => {
  const [validator] = useState<NewOrderValidator>(newValidator!);
  const NewOrderObj = useContext<NewOrderContextType | null>(NewOrderContext);
  const [woundLocations, setWoundLocations] = useState([]);
  const [woundLocationsText, setWoundLocationsText] = useState([]);
  const [woundDirections, setWoundDirections] = useState([]);
  const [woundDirectionsText, setWoundDirectionsText] = useState([]);
  const [woundOrientations, setWoundOreientations] = useState([]);
  const [woundOrientationsText, setWoundOreientationsText] = useState([]);
  const [active, setActive] = useState<boolean | null>(null);
  const [pwtMenuItems, setPWTMenuItems] = useState<string[]>();
  const { isMobileScreen } = useContext(MobileDisplayContext);
  const [focusClasses, setFocusClasses] = useState({
    shortNarrativeOfPossibleConsequences: "",
  });
  const [woundAgeType, setwoundAgeType] = useState([]);
  const [woundAgeTypeText, setwoundAgeTypeText] = useState([]);

  const fetchDropDownContent = async () => {
    try {
      const ddContent = format(
        "{0},{1},{2},{3}",
        DD_WOUND_DIRECTION,
        DD_WOUND_LOCATION,
        DD_WOUND_ORIENTATION,
        DD_WOUND_AGE_TYPE
      );
      const data = await getdropDownContent(ddContent);
      if (data.items.length > 0) {
        const woundDirectionObject = data.items.filter(
          (item: { name: string }) => item.name === DD_WOUND_DIRECTION
        );
        const woundDirectionData = woundDirectionObject[0].data.sort(
          (a: { order: number }, b: { order: number }) =>
            a.order > b.order ? 1 : -1
        );
        setWoundDirections(woundDirectionData);
        setWoundDirectionsText(
          woundDirectionData.map((x: { text: string }) => x.text)
        );
        const woundLocationObject = data.items.filter(
          (item: { name: string }) => item.name === DD_WOUND_LOCATION
        );
        const woundLocationeData = woundLocationObject[0].data.sort(
          (a: { order: number }, b: { order: number }) =>
            a.order > b.order ? 1 : -1
        );
        setWoundLocations(woundLocationeData);
        setWoundLocationsText(
          woundLocationeData.map((x: { text: string }) => x.text)
        );
        const woundOrientationObject = data.items.filter(
          (item: { name: string }) => item.name === DD_WOUND_ORIENTATION
        );
        const woundOrientationData = woundOrientationObject[0].data.sort(
          (a: { order: number }, b: { order: number }) =>
            a.order > b.order ? 1 : -1
        );
        setWoundOreientations(woundOrientationData);
        setWoundOreientationsText(
          woundOrientationData.map((x: { text: string }) => x.text)
        );
        const woundAgeTypeObject = data.items.filter(
          (item: { name: string }) => item.name === DD_WOUND_AGE_TYPE
        );
        const woundAgeTypeData = woundAgeTypeObject[0].data.sort(
          (a: { order: number }, b: { order: number }) =>
            a.order > b.order ? 1 : -1
        );
        setwoundAgeType(woundAgeTypeData);
        setwoundAgeTypeText(
          woundAgeTypeData.map((x: { text: string }) => x.text)
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const setClasses = (e: any, classname: string) => {
    setFocusClasses(
      Object.assign({}, focusClasses, { [e.target.name]: classname })
    );
  };

  const validateAndSetData = (e: any) => {
    NewOrderObj?.setIsHandleChangeTriggered(true);
    let value = e.target.value;
    let required = e.target.required;
    let name = e.target.name;
    let isValid = validator.validate(e.target.value, e.target.name);
    if (e.target.name === "woundAge" && value.length > 0) {
      if (
        Array.from(value)[0] === "0" ||
        value.length === 3 ||
        isValid!.status === ValidationStatus.INVALID
      ) {
        return;
      }
    } else if (e.target.name === "woundLocation") {
      value = getCodeFromText(woundLocations, e.target.value);
      required = true;
      if (!isSecondaryWoundInfo) {
        newOrderObj?.setNewOrderData({
          ...newOrderObj.newOrderData,
          woundLocation: {
            ...newOrderObj.newOrderData.woundLocation,
            value: value,
            valid: ValidationStatus.VALID,
          },
        });
      }
    } else if (e.target.name === "woundDirection") {
      value = e.target.value;
      required = false;
    } else if (e.target.name === "woundOrientation") {
      value = e.target.value;
      required = false;
    } else if (e.target.name === "woundAgeType") {
      value = getCodeFromText(woundAgeType, e.target.value);
      required = true;
    }
    if (e.target.name === "isTissuePresent") {
      if (e.target.value === "Yes") {
        setActive(true);
      } else if (e.target.value === "No") {
        setActive(false);
      } else {
        setActive(null);
      }
      required = true;
    }
    if (e.target.name === "woundType") {
      updateAdditionalQuestions(e.target.value);
      if (!isSecondaryWoundInfo) {
        newOrderObj?.setNewOrderData({
          ...newOrderObj.newOrderData,
          woundType: {
            ...newOrderObj.newOrderData.woundType,
            value: e.target.value,
            valid: ValidationStatus.VALID,
          },
        });
      }
    }
    if (e.target.name === "shortNarrativeOfPossibleConsequences") {
      required = value.length > 0;
    }

    if (value !== e.target.value) {
      isValid = validator.validate(value, e.target.name);
      required = true;
    }
    setWoundInfoData({
      ...woundInfoData,
      [name]: {
        ...Object(woundInfoData)[name],
        value: value,
        valid: isValid?.status,
        required: required,
        errorMessage: null,
      },
    });
  };

  const validateAndSetDate = (
    date: string | null | undefined,
    text: string
  ) => {
    let origDate = date;
    const formatteddate = date === null ? "" : convertStringToDate(date);
    NewOrderObj?.setIsHandleChangeTriggered(true);
    const questions: Question[] | undefined =
      getQuestionsAdditionalQuestions().map((x, index) => {
        if (x.text === text) {
          const valStatus = validator.validateAdditionalQuestions(
            index,
            { ...x, value: formatteddate! },
            x.type,
            isSecondaryWoundInfo ? true : false
          );
          return {
            ...x,
            value: formatteddate,
            valid:
              origDate && origDate.toString().trim().length > 0
                ? valStatus
                : ValidationStatus.UNTOUCHED,
            errorMessage: null,
          } as Question;
        } else {
          return x as Question;
        }
      });
    if (questions) {
      addAdditionalQuestions(questions);
    }
  };

  const validateAndSetCustomControlData = (e: any, text: string) => {
    NewOrderObj?.setIsHandleChangeTriggered(true);
    const { value } = e.target;
    const additionalQuestions = getQuestionsAdditionalQuestions();
    updateAdditionalQuestionsWithSurgicalCheck(
      getQuestionsCategoty(),
      text,
      value,
      additionalQuestions,
      undefined
    );
  };

  const renderComponent = (question: Question, index: number) => {
    let jsx;
    switch (question.type) {
      case QuestionTypes.RADIO:
        jsx = (
          <RadioQuestion
            key={`wtQuestion${index.toString()}`}
            name={`wtQuestion${index.toString()}`}
            error={question.valid === ValidationStatus.INVALID}
            label={question.text}
            onChange={(e: any) =>
              validateAndSetCustomControlData(e, question.text)
            }
            required={Boolean(question.required)}
            value={question.value}
            labelClassName="radio-question-label"
            errorMessage={"Above Question is required."}
            id={`questions-${index + 1}-id`}
          />
        );
        break;
      case QuestionTypes.TEXT:
        if (
          question.isRequiredToShow === undefined ||
          question.isRequiredToShow === true
        ) {
          jsx = (
            <TextQuestion
              key={`wtQuestion${index.toString()}`}
              name={`wtQuestion${index.toString()}`}
              error={question.valid === ValidationStatus.INVALID}
              label={question.text}
              onChange={(e: any) =>
                validateAndSetCustomControlData(e, question.text)
              }
              required={Boolean(question.required)}
              value={question.value}
              errorMessage={question.errorMessage!}
              componentId={`questions-${index + 1}-id`}
              id={`questions-${index + 1}-id-input`}
            />
          );
        }
        break;
      case QuestionTypes.DATE:
        if (
          question.isRequiredToShow === undefined ||
          question.isRequiredToShow === true
        ) {
          jsx = (
            <DateQuestion
              key={`wtQuestion${index.toString()}`}
              name={`wtQuestion${index.toString()}`}
              error={question.valid === ValidationStatus.INVALID}
              label={question.text}
              onChange={(date) => validateAndSetDate(date, question.text)}
              required={Boolean(question.required)}
              value={question.value}
              errorMessage={question.errorMessage!}
              componentId={`questions-${index + 1}-id`}
              id={`questions-${index + 1}-id-input`}
            />
          );
        }
        break;
    }
    return jsx;
  };

  const addAdditionalQuestions = (questions: Question[]) => {
    if (isSecondaryWoundInfo) {
      newOrderObj?.setSecondaryAdditionalQuestions((data: any) => ({
        ...data,
        additionalQuestion: questions!,
      }));
    } else {
      newOrderObj?.setPrimaryAdditionalQuestions((data: any) => ({
        ...data,
        additionalQuestion: questions!,
      }));
    }
  };

  const updateAdditionalQuestions = (pwtValue: string) => {
    const woundQuestionaries: WoundQuestionaries | undefined =
      newOrderObj?.woundQuestionaries?.get(pwtValue);
    const category = woundQuestionaries?.category ?? "";
    if (pwtValue === "Surgical (Non-Dehisced)") {
      updateAdditionalQuestionsWithSurgicalCheck(
        category,
        pwtValue,
        "",
        undefined,
        woundQuestionaries
      );
    } else {
      if (isSecondaryWoundInfo) {
        newOrderObj?.setSecondaryAdditionalQuestions(woundQuestionaries);
      } else {
        newOrderObj?.setPrimaryAdditionalQuestions(woundQuestionaries);
      }
    }
  };

  const showAdditionalQuestions = (): boolean => {
    const questions = isSecondaryWoundInfo
      ? newOrderObj?.secondaryAdditionalQuestions
      : newOrderObj?.primaryAdditionalQuestions;
    if (questions) {
      return true;
    } else {
      return false;
    }
  };

  const getQuestionsCategoty = (): string => {
    return isSecondaryWoundInfo
      ? newOrderObj?.secondaryAdditionalQuestions?.category ?? ""
      : newOrderObj?.primaryAdditionalQuestions?.category ?? "";
  };

  const getQuestionsAdditionalQuestions = (): Question[] => {
    return isSecondaryWoundInfo
      ? newOrderObj?.secondaryAdditionalQuestions?.additionalQuestion ?? []
      : newOrderObj?.primaryAdditionalQuestions?.additionalQuestion ?? [];
  };

  const updateAdditionalQuestionsWithSurgicalCheck = (
    category: string,
    text: string,
    value: string,
    allQuestions?: Question[] | undefined,
    woundQuestionaries?: WoundQuestionaries | undefined
  ) => {
    const additionalQuestions = allQuestions
      ? allQuestions
      : woundQuestionaries!.additionalQuestion;
    let radioBtnOldValue: string = "";
    const isRadioBtnChanged = additionalQuestions
      .map((x: Question) => {
        if (x.type === QuestionTypes.RADIO) {
          radioBtnOldValue = x.value;
          return x.text === text || text === "Surgical (Non-Dehisced)";
        }
      })
      .filter((x) => x !== undefined)[0];
    const radioButtonValue: string = isRadioBtnChanged
      ? value
      : radioBtnOldValue;
    const isRequiredToShow: boolean | undefined =
      isRadioBtnChanged && value !== "" ? value === "Yes" : undefined;

    const questions: Question[] | undefined = additionalQuestions.map(
      (x, index) => {
        const valStatus =
          radioButtonValue === "No"
            ? ValidationStatus.VALID
            : validator.validateAdditionalQuestions(
                index,
                { ...x, value: value },
                x.type,
                isSecondaryWoundInfo ? true : false
              );
        const isRequiredToShowValue =
          category === "Surgical"
            ? isRequiredToShow === undefined
              ? x.isRequiredToShow
                ? x.isRequiredToShow
                : false
              : isRequiredToShow
            : undefined;
        const isRequired =
          category === "Surgical"
            ? isRequiredToShow === undefined
              ? x.required
                ? x.required
                : "False"
              : x.type === QuestionTypes.RADIO && x.text === text
              ? "True"
              : radioButtonValue === "Yes"
              ? "True"
              : "False"
            : x.required;
        if (isRadioBtnChanged && value !== "Yes") {
          return {
            ...x,
            errorMessage: null,
            isRequiredToShow:
              x.type === QuestionTypes.RADIO && x.text === text ? true : false,
            order: index + 1,
            required: isRequired,
            valid:
              x.type === QuestionTypes.RADIO && x.text === text
                ? valStatus
                : ValidationStatus.UNTOUCHED,
            value: x.text === text ? value : "",
          } as Question;
        } else if (x.text === text) {
          return {
            ...x,
            isRequiredToShow: isRequiredToShowValue,
            required: isRequired,
            valid: valStatus,
            value: value,
            errorMessage: null,
          } as Question;
        } else {
          return {
            ...x,
            isRequiredToShow: isRequiredToShowValue,
            required: isRequired,
            valid:
              x.type === QuestionTypes.RADIO && x.text === text
                ? valStatus
                : x.valid,
            errorMessage: x.errorMessage,
          } as Question;
        }
      }
    );
    if (woundQuestionaries) {
      woundQuestionaries.additionalQuestion = questions;
      if (isSecondaryWoundInfo) {
        newOrderObj?.setSecondaryAdditionalQuestions(woundQuestionaries);
      } else {
        newOrderObj?.setPrimaryAdditionalQuestions(woundQuestionaries);
      }
    } else {
      if (questions) {
        addAdditionalQuestions(questions);
      }
    }
  };

  useEffect(() => {
    fetchDropDownContent();
    if (
      woundInfoData !== null &&
      (woundInfoData!.isTissuePresent.value === "Yes" ||
        woundInfoData!.isTissuePresent.value === "No")
    ) {
      setActive(woundInfoData!.isTissuePresent.value === "Yes" ? true : false);
    }
    const keys: string[] = newOrderObj?.woundQuestionaries?.keys();
    if (keys) {
      const menuItems: any = Array.from(keys).sort((a, b) => (a > b ? 1 : -1));
      setPWTMenuItems(menuItems);
    }
    if (newOrderObj?.updateWoundTypeAdditionalQuestions) {
      if (!isSecondaryWoundInfo) {
        updateAdditionalQuestions(woundInfoData.woundType.value);
        newOrderObj?.setUpdateWoundTypeAdditionalQuestions(false);
      }
    }
  }, []);

  return (
    <div
      className={
        isSecondaryWoundInfo
          ? "clinical-information-main-container-sec"
          : "clinical-information-main-container"
      }
    >
      {!isReviewOrder && (
        <div
          className={`clinical-information${
            isSecondaryWoundInfo ? " secondary" : ""
          }`}
          data-testid="clinical-information"
        >
          <h2
            className="clinical-information-title"
            data-testid="clinical-information-title"
          >
            {isSecondaryWoundInfo ? "Secondary Wound" : "Clinical Information"}
          </h2>
          <Box
            className="clinical-information-box-container"
            sx={{ flexGrow: 1 }}
          >
            <Grid
              className="clinical-information-grid-container"
              container
              spacing={2}
            >
              {!isSecondaryWoundInfo && (
                <Grid
                  className="clinical-information-grid-item"
                  item
                  xs={isMobileScreen ? 12 : 12}
                >
                  <InputWithLabel
                    isRequired={false}
                    label={
                      parse(
                        "Please provide a short narrative of possible consequences if V.A.C.<sup>®</sup> Therapy is not used. (Please include/attach any clinical data such as H&P)"
                      ) as string
                    }
                    error={
                      woundInfoData?.shortNarrativeOfPossibleConsequences
                        .valid === ValidationStatus.INVALID
                    }
                    id={
                      woundInfoData?.shortNarrativeOfPossibleConsequences
                        .componentId!
                    }
                    labelClassName={"shortNarrativeOfPossibleConsequences"}
                    testId="shortNarrativeOfPossibleConsequences-lable"
                    warningMessage={
                      woundInfoData.shortNarrativeOfPossibleConsequences
                        .errorMessage
                    }
                    isShowWarning={true}
                  >
                    <div className="textView">
                      <TextField
                        data-testid="shortNarrativeOfPossibleConsequences-textField"
                        error={
                          woundInfoData?.shortNarrativeOfPossibleConsequences
                            .valid === ValidationStatus.INVALID
                        }
                        FormHelperTextProps={{
                          classes: { root: "helperText" },
                        }}
                        fullWidth
                        InputProps={{ classes: { root: "textarea-root" } }}
                        multiline
                        name="shortNarrativeOfPossibleConsequences"
                        rows={4}
                        onChange={validateAndSetData}
                        onFocus={(e) => setClasses(e, "Mui-focused")}
                        onBlur={(e) => setClasses(e, "")}
                        required={
                          woundInfoData?.shortNarrativeOfPossibleConsequences
                            .required
                        }
                        value={
                          woundInfoData?.shortNarrativeOfPossibleConsequences
                            .value
                        }
                        inputProps={{
                          maxLength: 255,
                        }}
                        id={
                          woundInfoData?.shortNarrativeOfPossibleConsequences
                            .id!
                        }
                      />
                    </div>
                  </InputWithLabel>
                </Grid>
              )}
              <Grid className="clinical-information-grid-item" item>
                <InputWithLabel
                  label={
                    isSecondaryWoundInfo
                      ? "Secondary Wound Type"
                      : "Primary Wound Type"
                  }
                  isRequired={true}
                  error={
                    woundInfoData.woundType.valid === ValidationStatus.INVALID
                  }
                  testId="wound-type"
                  warningMessage={woundInfoData.woundType.errorMessage}
                  isShowWarning={true}
                  id={woundInfoData.woundType.componentId!}
                  isDropdown={true}
                >
                  <CustomDropDown
                    handleChange={validateAndSetData}
                    menuItem={pwtMenuItems!}
                    name="woundType"
                    placeHolder={
                      isSecondaryWoundInfo
                        ? "Patient’s Secondary Wound Type"
                        : "Patient’s Primary Wound Type"
                    }
                    selectpropsClassName={
                      woundInfoData.woundType.value
                        ? "primary-select"
                        : "primary-type-placeholder"
                    }
                    selectClassName={
                      woundInfoData.woundType.value
                        ? "primary-type-input"
                        : "primary-type-placeholder"
                    }
                    testId="wound-type-select"
                    value={woundInfoData?.woundType.value}
                  />
                </InputWithLabel>
              </Grid>
              <Grid className="clinical-information-grid-item" item>
                {showAdditionalQuestions() && (
                  <div className="bordered-div">
                    <div>
                      <div className="wound-category">Wound Category</div>
                      <div className="category">{getQuestionsCategoty()}</div>
                    </div>
                    {getQuestionsAdditionalQuestions().map((x, index) => {
                      let orderid = isSecondaryWoundInfo ? 60 : 16;
                      return renderComponent(x, index + orderid);
                    })}
                  </div>
                )}
              </Grid>
              <div className="wound-age-items">
                <InputWithLabel
                  label="Wound Age"
                  isRequired={woundInfoData?.woundAge.required}
                  error={
                    woundInfoData?.woundAge.valid === ValidationStatus.INVALID
                  }
                  sx={{
                    marginTop: showAdditionalQuestions() ? "1px" : "-16px",
                  }}
                  testId="clinical-information-woundAge"
                  id={woundInfoData?.woundAge.componentId!}
                  warningMessage={woundInfoData.woundAge.errorMessage}
                  isShowWarning={true}
                  warning_icon_div_custom_class="custom-icon-div-error-warning"
                >
                  <div className="clinical-information-woud-age-div">
                    <InputBase
                      className="clinical-information-input-wound-age"
                      inputProps={{
                        "data-testid": "clinical-information-woundAge-value",
                        inputMode: "numeric",
                        pattern: "[0-9*]",
                      }}
                      name="woundAge"
                      placeholder="0"
                      onChange={validateAndSetData}
                      value={woundInfoData.woundAge.value}
                      type="text"
                      id={woundInfoData?.woundAge.id!}
                      required={woundInfoData?.woundAge.required}
                    />
                  </div>
                </InputWithLabel>
                <InputWithLabel
                  error={
                    woundInfoData?.woundAgeType.valid ===
                    ValidationStatus.INVALID
                  }
                  sx={{
                    marginTop: showAdditionalQuestions() ? "0px" : "-16px",
                  }}
                >
                  <div className="WoundageType" data-testid="WoundageType">
                    <CustomDropDown
                      handleChange={validateAndSetData}
                      menuItem={woundAgeTypeText}
                      name="woundAgeType"
                      selectpropsClassName={
                        woundInfoData.woundAgeType.value
                          ? "clinical-information-woundAgeType-select"
                          : "placeHolder"
                      }
                      selectClassName={
                        woundInfoData.woundAgeType.value
                          ? "clinical-information-woundAgeType-input"
                          : "placeHolder"
                      }
                      paperPropsClassName={
                        "clinical-information-woundAgeType-props"
                      }
                      menuOptionClassName={
                        "clinical-information-wound-ageType-value"
                      }
                      testId="clinical-information-wound-ageType-value"
                      value={
                        woundInfoData.woundAgeType.value
                          ? getTextFromCode(
                              woundAgeType,
                              woundInfoData.woundAgeType.value
                            )
                          : null
                      }
                    />
                  </div>
                </InputWithLabel>
              </div>
              <Grid
                className="clinical-information-grid-item"
                item
                xs={10}
              ></Grid>
              <Grid
                className="clinical-information-grid-item"
                item
                xs={isMobileScreen ? 12 : 12}
              >
                <InputWithLabel
                  label="Wound Location"
                  isRequired={woundInfoData.woundLocation.required}
                  error={
                    woundInfoData?.woundLocation.valid ===
                    ValidationStatus.INVALID
                  }
                  sx={{ marginTop: "-8px" }}
                  testId="clinical-information-wound-location"
                  warningMessage={woundInfoData.woundLocation.errorMessage}
                  id={woundInfoData.woundLocation.componentId}
                  isShowWarning={true}
                  isDropdown={true}
                >
                  <CustomDropDown
                    handleChange={validateAndSetData}
                    menuItem={woundLocationsText}
                    name="woundLocation"
                    placeHolder="Wound Location"
                    selectpropsClassName={
                      woundInfoData.woundLocation.value
                        ? "clinical-information-select"
                        : "placeHolder"
                    }
                    selectClassName={
                      woundInfoData.woundLocation.value
                        ? "clinical-information-input"
                        : "placeHolder"
                    }
                    testId="clinical-information-wound-location-value"
                    value={woundInfoData.woundLocation.value}
                  />
                </InputWithLabel>
              </Grid>
              <Grid
                className="clinical-information-grid-item"
                item
                xs={isMobileScreen ? 12 : 6}
              >
                <InputWithLabel
                  label="Wound Direction"
                  isRequired={woundInfoData?.woundDirection.required}
                  error={
                    woundInfoData?.woundDirection.valid ===
                    ValidationStatus.INVALID
                  }
                  testId="clinical-information-wound-direction"
                  warningMessage={woundInfoData.woundDirection.errorMessage}
                  isShowWarning={true}
                  isDropdown={true}
                >
                  <CustomDropDown
                    handleChange={validateAndSetData}
                    menuItem={woundDirectionsText}
                    name="woundDirection"
                    placeHolder="Wound Direction"
                    selectpropsClassName={
                      woundInfoData.woundDirection.value
                        ? "clinical-information-select"
                        : "placeHolder"
                    }
                    selectClassName={
                      woundInfoData.woundDirection.value
                        ? "clinical-information-input"
                        : "placeHolder"
                    }
                    testId="clinical-information-wound-direction-value"
                    value={woundInfoData.woundDirection.value}
                  />
                </InputWithLabel>
              </Grid>
              <Grid
                className="clinical-information-grid-item"
                item
                xs={isMobileScreen ? 12 : 6}
              >
                <InputWithLabel
                  label="Wound Orientation"
                  isRequired={woundInfoData?.woundOrientation.required}
                  error={
                    woundInfoData?.woundOrientation.valid ===
                    ValidationStatus.INVALID
                  }
                  testId="clinical-information-wound-orientation"
                  warningMessage={woundInfoData.woundOrientation.errorMessage}
                  isShowWarning={true}
                  isDropdown={true}
                >
                  <CustomDropDown
                    handleChange={validateAndSetData}
                    menuItem={woundOrientationsText}
                    name="woundOrientation"
                    placeHolder="Wound Orientation"
                    selectpropsClassName={
                      woundInfoData.woundOrientation.value
                        ? "clinical-information-select"
                        : "placeHolder"
                    }
                    selectClassName={
                      woundInfoData.woundLocation.value
                        ? "clinical-information-input"
                        : "placeHolder"
                    }
                    testId="clinical-information-wound-orientation-value"
                    value={
                      woundInfoData.woundOrientation.value
                        ? getTextFromCode(
                            woundOrientations,
                            woundInfoData.woundOrientation.value
                          )
                        : null
                    }
                  />
                </InputWithLabel>
              </Grid>
              <Grid
                className="clinical-information-grid-item"
                item
                xs={isMobileScreen ? 12 : 6}
              >
                <InputWithLabel
                  label="Is there eschar tissue present in the wound?"
                  isRequired={woundInfoData?.isTissuePresent.required}
                  error={
                    woundInfoData?.isTissuePresent.valid ===
                    ValidationStatus.INVALID
                  }
                  testId="clinical-information-isTissuePresent"
                  id={woundInfoData.isTissuePresent.componentId!}
                  warningMessage={woundInfoData.isTissuePresent.errorMessage}
                  isShowWarning={true}
                >
                  <RadioGroup
                    name="isTissuePresent"
                    classes={{ root: "radioRoot" }}
                    onChange={validateAndSetData}
                    value={woundInfoData.isTissuePresent.value}
                  >
                    <FormControlLabel
                      classes={{
                        root:
                          woundInfoData.isTissuePresent.valid ===
                          ValidationStatus.INVALID
                            ? "optionRoot-error"
                            : active === true
                            ? "optionRoot-active"
                            : "optionRoot",
                      }}
                      componentsProps={{
                        typography: {
                          classes: {
                            root:
                              active === true ? "optiontxtSelect" : "optiontxt",
                          },
                        },
                      }}
                      control={
                        <Radio
                          icon={<RadioButtonIcon />}
                          checkedIcon={<SelectedRadioButtonIcon />}
                        />
                      }
                      data-testid="clinical-information-isTissuePresent-Yes"
                      label="Yes"
                      value="Yes"
                    />
                    <FormControlLabel
                      classes={{
                        root:
                          woundInfoData.isTissuePresent.valid ===
                          ValidationStatus.INVALID
                            ? "optionRoot-error"
                            : active === false
                            ? "optionRoot-active"
                            : "optionRoot",
                      }}
                      componentsProps={{
                        typography: {
                          classes: {
                            root:
                              active === false
                                ? "optiontxtSelect"
                                : "optiontxt",
                          },
                        },
                      }}
                      control={
                        <Radio
                          icon={<RadioButtonIcon />}
                          checkedIcon={<SelectedRadioButtonIcon />}
                        />
                      }
                      data-testid="clinical-information-isTissuePresent-No"
                      label="No"
                      value="No"
                    />
                  </RadioGroup>
                </InputWithLabel>
              </Grid>
            </Grid>
          </Box>
        </div>
      )}
      {isReviewOrder && (
        <ClinicalInformationReviewOrder
          editButtonClicked={editButtonClicked}
          isOrderSummary={isOrderSummary}
          isSecondaryWoundInfo={isSecondaryWoundInfo}
          woundInfoData={woundInfoData}
          woundLocations={woundLocations}
          woundDirections={woundDirections}
          woundOrientations={woundOrientations}
          newOrderObj={newOrderObj}
          woundAgeType={woundAgeType}
        />
      )}
    </div>
  );
};
