import "./nationalRegistryResult.css";
import { InputBase } from "@mui/material";
import { useState, useEffect } from "react";
import { useSortableTable } from "../../../../../../../util/utilityFunctions";
import { NewOrderValidator } from "../../../../../../newOrder/newOrder.validator";
import { IPrescriberInformation } from "../../../prescriberInformation.interface";
import { NationalRegistryResultTable } from "./table/nationalRegistryResultTable.component";
import { ReactComponent as SearchIconSvg } from "./../../../../../../../assets/blackSearchIcon.svg";
import {
  IFilterNationalRegistry,
  defaultNationalRegistryFilterBox,
} from "../../../../../../newOrder/prescriberInformation/nationalRegistrySearch/nationalRegistryResult/filterNationalRegistry.model";

export interface INationalRegistryResultProps {
  nationalRegistries: IPrescriberInformation[];
  selectButtonAction: Function;
  selectedNpi?: string;
}

export const NationalRegistryResult = ({
  nationalRegistries,
  selectButtonAction,
  selectedNpi,
}: INationalRegistryResultProps) => {
  const [filterNPI, setFilterNPI] = useState<IFilterNationalRegistry>(
    defaultNationalRegistryFilterBox
  );
  const columns = [
    { label: "", accessor: "orderID", sortable: false },
    { label: "Prescriber Name", accessor: "prescriberName", sortable: true },
    { label: "NPI #", accessor: "npi", sortable: true },
    { label: "City", accessor: "city", sortable: true },
    { label: "State", accessor: "state", sortable: true },
  ];

  const [sortedData, setSortedData, handleSorting] = useSortableTable(
    [],
    columns
  );

  useEffect(() => {
    if (nationalRegistries.length > 0) {
      setSortedData(nationalRegistries);
    }
  }, []);

  const handleFilterNPI = (e: any) => {
    const { value } = e.target;
    const validator = new NewOrderValidator();
    const isvalid = validator.validate(value, e.target.name);
    setFilterNPI((filterNPI) => ({
      ...filterNPI,
      [e.target.name]: { value: value, valid: isvalid!.status },
    }));
    if (value.length > 2) {
      const filteredList = nationalRegistries.filter(
        (prescriber: IPrescriberInformation) => {
          return (
            prescriber.city.toLowerCase().includes(value.toLowerCase()) ||
            prescriber.npi.toLowerCase().includes(value.toLowerCase()) ||
            prescriber.firstName.toLowerCase().includes(value.toLowerCase()) ||
            prescriber.lastName.toLowerCase().includes(value.toLowerCase())
          );
        }
      );
      setSortedData(filteredList);
    } else if (value.length === 0) {
      setSortedData(nationalRegistries);
    }
  };

  return (
    <div
      className="national-registry-result-component"
      data-testid="national-registry-result-component"
    >
      <p
        className="national-registry-result-header"
        data-testid="national-registry-result-header"
        id="national-registry-result-header"
      >
        National Registry Search Results
      </p>
      <div
        className="filter-prescriber-div"
        data-testid="filter-prescriber-div"
      >
        <SearchIconSvg className="search-icon" data-testid="search-icon" />
        <InputBase
          autoFocus
          className="filter-prescriber-input"
          data-testid="filter-prescriber-input"
          id="filter-prescriber-input"
          name="filterNPIList"
          onChange={handleFilterNPI}
          placeholder="Filter Prescriber List by Name, City, or NPI number"
          value={filterNPI.filterNPIList.value}
        />
      </div>
      <NationalRegistryResultTable
        handleSorting={handleSorting}
        columns={columns}
        nationalRegistries={sortedData}
        selectButtonAction={selectButtonAction}
        selectedNpi={selectedNpi}
      />
    </div>
  );
};
