export interface IOrderOptionProps {
  onClick: Function;
  option: IOrderOption;
}

export interface IOrderOption {
  description: string;
  icon: string;
  isDisabled?: boolean;
  isNewFeature?: boolean;
  order: number;
  secion: OrderOptionSection;
  title: string;
  type: OrderOptionType;
}

export enum OrderOptionSection {
  VAC_THERAPY = "vac-therapy",
  SUPPLIES = "supplies",
}

export enum OrderOptionType {
  IN_PATIENT_ORDER =  "in-patient-order",
  HOME_ORDER = "home-order",
  STOCK_SUPPLIES = "stock-supplies",
  IN_PATIENT_SUPPLIES = "in-patient-supplies",
  FOR_HOME_PATIENTS = "for_home_patients",
}