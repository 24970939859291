import "./vacDressingKit.css";
import parse from "html-react-parser";
import { Grid, useMediaQuery } from "@mui/material";
import closeIcon from "../../../../assets/cross.svg";
import { ERROR_TO_SELECT_ANY_OPTION } from "../../../../util/errorMsg";
import {
  IInputField,
  ValidationStatus,
} from "../../../../core/interfaces/input.interface";
import QuantityButton from "../../../../core/quantityButton/quantityButton.component";
import { CustomDropDown } from "../../../../core/customDropdown/customDropdown.component";
import { InputWithLabel } from "../../../../core/inputWithLabel/inputWithLabel.component";
import {
  IPeelAndPlace,
  PeelAndPlace,
} from "./peelAndPlace/peelAndPlace.component";
interface IVacDressingSub {
  validateAndSetData: any;
  vacProducts: any;
  handleCloseIcon: any;
  validateAndSetSize: any;
  showSize: any;
  vacProductSize: any;
  showQunatity: any;
  handleVacKitDressingPlusClick: any;
  handleVacKitDressingMinusClick: any;
  isVacKitDressingPlusDisabled: any;
  isVacKitDressingMinusDisabled: any;
  productName: IInputField;
  productCode: IInputField;
  productSizeName: IInputField;
  productQuantityCode: IInputField;
  productLableName: any;
  productSizeLabelName: any;
  disableDropdown?: boolean;
  isPrimary: boolean;
  isPrimaryCanClose?: boolean;
  isReadyCareFlag?: boolean | null;
  isPeelAndPlaceDressing?: boolean;
  isShowWarningForSecondaryDressing?: boolean;
  instruction?: IPeelAndPlace | null;
  note?: IPeelAndPlace | null;
  isPeelAndPlaceEdgeCase?: boolean;
}

export const VacDressing = ({
  validateAndSetData,
  vacProducts,
  handleCloseIcon,
  validateAndSetSize,
  showSize,
  vacProductSize,
  showQunatity,
  handleVacKitDressingPlusClick,
  handleVacKitDressingMinusClick,
  isVacKitDressingPlusDisabled,
  isVacKitDressingMinusDisabled,
  productName,
  productCode,
  productSizeName,
  productQuantityCode,
  productLableName,
  productSizeLabelName,
  disableDropdown = false,
  isPrimary,
  isPrimaryCanClose = false,
  isReadyCareFlag,
  isPeelAndPlaceDressing,
  isShowWarningForSecondaryDressing,
  instruction,
  note,
  isPeelAndPlaceEdgeCase = false,
}: IVacDressingSub) => {
  const isMobileScreen = useMediaQuery("(max-width:600px)");
  const isSmallerMobileScreen = useMediaQuery("(max-width:450px)");

  const getCaseSize = (): string => {
    return productCode.value && productCode.value !== ""
      ? productCode.value.split("/").length >= 2
        ? productCode.value.split("/")[1]
        : productCode.value.match(/\d/g)!.join("").replace(/^0+/, "")
      : "";
  };
  const checkIfPrimaryORReadyCare = () => {
    if (isPrimary && !isPrimaryCanClose) {
      if (isReadyCareFlag) {
        return false;
      }
      return true;
    }
  };

  return (
    <div>
      <Grid
        container
        display="flex"
        flexDirection="row"
        className="mainGridDressing"
      >
        <Grid
          item
          xs={!disableDropdown ? 11 : 12}
          className="vacDressingDropdown"
        >
          <InputWithLabel
            error={productName.valid === ValidationStatus.INVALID}
            id={productName.componentId!}
            isShowWarning={true}
            isRequired={true}
            label="Dressing Kit"
            testId="DressingKitComponentTest"
            warningMessage={
              productName.errorMessage ? ERROR_TO_SELECT_ANY_OPTION : ""
            }
            isDropdown={true}
          >
            <CustomDropDown
              disabled={disableDropdown}
              handleChange={(e: any, param: any) =>
                validateAndSetData(e, param)
              }
              menuItem={[]}
              name={productLableName}
              placeHolder="Select a dressing"
              selectClassName={
                productName.value
                  ? "dressing-kitdropdown-input"
                  : "dressingplaceHolder"
              }
              selectpropsClassName={
                productName.value
                  ? "dressingKit-type-select"
                  : "dressingplaceHolder"
              }
              paperPropsClassName="dressingKitPaperProps"
              value={productName.value}
              dropDownMenuObj={vacProducts}
              hasBothCodeValue={true}
            />
          </InputWithLabel>
        </Grid>
        {!disableDropdown && (
          <Grid item xs={1}>
            <img
              onClick={handleCloseIcon}
              data-testid="close-Icon-Image-Test"
              src={closeIcon}
              alt={closeIcon}
              className={`dressingKitCloseBtn ${
                checkIfPrimaryORReadyCare() ? "dressingKitCloseBtnHide" : ""
              }`}
            />
          </Grid>
        )}
      </Grid>
      {isPeelAndPlaceDressing && (
        <Grid
          item
          xs={!disableDropdown ? 11 : 12}
          className="vacDressingDropdown"
        >
          <PeelAndPlace instruction={instruction} />
        </Grid>
      )}
      {isShowWarningForSecondaryDressing && (
        <Grid
          item
          xs={!disableDropdown ? 11 : 12}
          className="vacDressingDropdown"
        >
          <PeelAndPlace note={note} />
        </Grid>
      )}
      {showSize && (
        <Grid container className="dressingSizeKit">
          <Grid item xs={!disableDropdown ? 11 : 12}>
            <InputWithLabel
              error={productSizeName.valid === ValidationStatus.INVALID}
              id={productSizeName.componentId!}
              isRequired={true}
              isShowWarning={true}
              label="Dressing Size"
              testId="dressingKitSizeDropdownTest"
              warningMessage={
                productSizeName.errorMessage ? ERROR_TO_SELECT_ANY_OPTION : ""
              }
              isDropdown={true}
            >
              <CustomDropDown
                disabled={disableDropdown}
                handleChange={(e: any, param: any) =>
                  validateAndSetSize(e, param)
                }
                menuItem={[]}
                name={productSizeLabelName}
                placeHolder="Select a size"
                selectClassName={
                  productSizeName.value
                    ? "dressing-kitdropdown-input"
                    : "dressingplaceHolder"
                }
                selectpropsClassName={
                  productSizeName.value
                    ? "dressingKit-type-select"
                    : "dressingplaceHolder"
                }
                value={productSizeName.value}
                dropDownMenuObj={vacProductSize}
                hasBothCodeValue={true}
              />
            </InputWithLabel>
          </Grid>
        </Grid>
      )}
      {showQunatity && (
        <>
          <Grid className="currentMainGrid">
            <Grid
              item
              xs={isSmallerMobileScreen ? 7 : isMobileScreen ? 7 : 8.5}
              className="dressingLabel"
            >
              <div>
                <label
                  className="dressingKitLabel"
                  data-testid="dressingKitTestQunatityLabel"
                >
                  {`Quantity ${
                    getCaseSize() !== ""
                      ? `(${getCaseSize()} units per case)`
                      : ""
                  }`}
                </label>
              </div>
              <p className="dressKitSubLabel">
                {`Max ${
                  isPeelAndPlaceDressing ? "1" : isReadyCareFlag ? "2" : "3"
                } cases`}
              </p>
            </Grid>
            <Grid
              item
              xs={isSmallerMobileScreen ? 5 : isMobileScreen ? 5 : 3}
              className="dressingLabel"
            >
              <QuantityButton
                isMinusDisabled={isVacKitDressingMinusDisabled}
                isPeelAndPlaceDressing={isPrimary && isPeelAndPlaceDressing}
                isPlusDisabled={isVacKitDressingPlusDisabled}
                onPlusClick={handleVacKitDressingPlusClick}
                onMinusClick={handleVacKitDressingMinusClick}
                showLabel={false}
                value={productQuantityCode.value}
              />
            </Grid>
          </Grid>
          <Grid className="currentMainGrid">
            <Grid item xs={12} className="dressingLabel message">
              {isPrimary &&
                isPeelAndPlaceDressing &&
                isPeelAndPlaceEdgeCase && (
                  <p className="peel-and-place-quantity-error">
                    <span>
                      {parse(
                        "Only 1 case of <b>Peel and Place Dressing</b> can be ordered. Please call the Express Support team "
                      )}
                    </span>
                    <a
                      className="phone-value"
                      data-testid="phone-value"
                      href={`tel:+18002754524`}
                    >
                      1-800-275-4524 ext 41858
                    </a>
                    <span>{" if additional cases are needed."}</span>
                  </p>
                )}
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};
