import { useContext } from "react";
import "./additionalPermissionPopUp.css";
import { PageContext } from "../../page/page.context";
import { ExpressButton } from "../../../core/expressButton/expressButton.component";

type Props = {
  closeModal: Function;
};
const AdditionalPermissionPopUp = ({ closeModal }: Props) => {
  const techSupport = useContext(PageContext);
  return (
    <>
      <div
        className="additional-permission-div"
        data-testid="additional-permission-div"
      >
        <p
          className="additional-permission-desc"
          data-testid="additional-permission-desc"
        >
          Additional permission is required to access the Saved order. Please
          contact the 3M™ Express Therapy Portal support team at{" "}
          <a
            className="technicalContact"
            href={`tel:${techSupport.techRepContacts?.phoneNo}`}
          >
            {techSupport.techRepContacts?.phoneNo}
          </a>
          {"."}
        </p>
        <ExpressButton
          clickHandler={() => closeModal()}
          parentClass="doneBtn"
          testId="doneBtnTest"
          variant="contained"
        >
          Done
        </ExpressButton>
      </div>
    </>
  );
};

export default AdditionalPermissionPopUp;
