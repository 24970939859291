import { IInventoryRequest } from "./inventoryRequest.interface";
import { ValidationStatus } from "../../../core/interfaces/input.interface";

export let defaultInventoryRequestData: IInventoryRequest = {
  requestType: {
    order: 1,
    componentId: "request-type",
    id: "request-type-id",
    valid: ValidationStatus.VALID,
    value: "",
    required: true,
    title: "Request Type",
  },
  quantity: {
    order: 2,
    componentId: "add-inventory-quantity",
    id: "add-inventory-quantity-id",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
    title: "Quantity to add",
  },
  deliveryInstructions: {
    order: 3,
    componentId: "add-inventory-deliveryInstructions",
    id: "add-inventory-deliveryInstruction-id",
    valid: ValidationStatus.VALID,
    value: "",
    required: false,
    isDefaultValid: true,
    title: "Delivery Instructions",
  },
  therapyDevice: {
    order: 4,
    componentId: "therapy-device",
    id: "therapy-device-id",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: true,
    title: "Which V.A.C.<sup>®</sup> Therapy device would you like to remove?",
  },
  reasonForRemoval: {
    order: 5,
    componentId: "reason-for-removal",
    id: "reason-for-removal-id",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
    title: "Reason for removal",
  },
  otherReasonForRemoval: {
    order: 6,
    componentId: "other-reason-for-removal",
    id: "other-reason-for-removal-id",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
    title: "Other",
  },
  contactFirstName: {
    order: 7,
    componentId: "contact-first-name",
    id: "contact-first-name-id",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: true,
    title: "Contact First Name",
  },
  contactLastName: {
    order: 8,
    componentId: "contact-last-name",
    id: "contact-last-name-id",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: true,
    title: "Contact Last Name",
    errorMessage: null,
  },
  phone: {
    order: 9,
    componentId: "phone-number",
    id: "phone-number-id",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: true,
    title: "Contact Phone",
  },
  email: {
    order: 10,
    componentId: "email-id",
    id: "email-id-input",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: true,
    title: "Email Address",
  },
  department: {
    order: 11,
    componentId: "department",
    id: "department-id",
    valid: ValidationStatus.VALID,
    value: "",
    required: false,
    title: "Department",
    isDefaultValid: true,
  },
};
