import { Grid, useMediaQuery } from "@mui/material";
import { makeCapitalEachWordInString } from "../../../../util/utilityFunctions";
import "./orderDetail3MContacts.css";
import { IOrderDetails3MContacts } from "./orderDetails3MContats.interface";
import { useEffect, useState } from "react";

export const OrderDetails3MContacts = ({
  orderDetailsTrackingData,
  error,
  patientData,
}: IOrderDetails3MContacts) => {
  const isMobileScreen = useMediaQuery("(max-width:920px)");

  return (
    <>
      {(orderDetailsTrackingData || error) && (
        <div className="contact-3m-order">
          <div className="contact-3m-title">
            <h2 className="contact-3m-order-title" data-testid="contact-title">
              3M Contacts
            </h2>
          </div>

          <div className="all-content-div">
            <div className="content-div">
              <Grid className="grid-container" container spacing={2}>
                {!patientData?.isAcuteOrder && (
                  <Grid
                    className="grid-item"
                    item
                    xs={isMobileScreen ? 12 : 4.4}
                  >
                    <div className="sub-content-div">
                      <h5
                        className="contact-3m-order-content-title"
                        data-testid="pssName"
                      >
                        3M Patient Support Specialist
                      </h5>
                      {error === false ? (
                        <>
                          <h5
                            className="contact-3m-order-content-value"
                            data-testid="pss-name-value-salesrep"
                          >{`${makeCapitalEachWordInString(
                            orderDetailsTrackingData.pssName === null ||
                              orderDetailsTrackingData.pssName === ""
                              ? "--"
                              : orderDetailsTrackingData.pssName!
                          )} 
            `}</h5>
                          {orderDetailsTrackingData?.pssEmail &&
                          orderDetailsTrackingData?.pssEmail !== "" ? (
                            <a
                              className="contact-value-email-phone"
                              href={`mailto:${makeCapitalEachWordInString(
                                orderDetailsTrackingData?.pssEmail
                              )}`}
                            >
                              {makeCapitalEachWordInString(
                                orderDetailsTrackingData?.pssEmail
                              )}
                            </a>
                          ) : (
                            "--"
                          )}
                          {orderDetailsTrackingData?.pssPhone &&
                          orderDetailsTrackingData?.pssPhone !== "" ? (
                            <a
                              className="contact-value-email-phone"
                              data-testid="pss-phone-value-salesrep"
                              href={`tel:${orderDetailsTrackingData.pssPhone.replace(
                                "x",
                                " x"
                              )}`}
                            >
                              {orderDetailsTrackingData.pssPhone.includes("ext")
                                ? orderDetailsTrackingData.pssPhone
                                : orderDetailsTrackingData.pssPhone.replace(
                                    "x",
                                    " x"
                                  )}
                            </a>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        "--"
                      )}
                    </div>
                  </Grid>
                )}

                <Grid
                  className="grid-item"
                  item
                  xs={
                    isMobileScreen ? 12 : patientData?.isAcuteOrder ? 4.4 : 3.5
                  }
                >
                  <div className="sub-content-div">
                    <h5
                      className="contact-3m-order-content-title"
                      data-testid="name"
                    >
                      {patientData?.isAcuteOrder
                        ? "Acute Sales Representative"
                        : "Post Acute Sales Representative"}
                    </h5>
                    {error === false ? (
                      <>
                        <h5
                          className="contact-3m-order-content-value"
                          data-testid="name-value-salesrep"
                        >{`${makeCapitalEachWordInString(
                          orderDetailsTrackingData.salesRepName === null ||
                            orderDetailsTrackingData.salesRepName === ""
                            ? "--"
                            : orderDetailsTrackingData.salesRepName
                        )} 
            `}</h5>
                        {orderDetailsTrackingData?.salesRepEmail &&
                        orderDetailsTrackingData?.salesRepEmail !== "" ? (
                          <a
                            className="contact-value-email-phone"
                            href={`mailto:${makeCapitalEachWordInString(
                              orderDetailsTrackingData?.salesRepEmail
                            )}`}
                          >
                            {makeCapitalEachWordInString(
                              orderDetailsTrackingData?.salesRepEmail
                            )}
                          </a>
                        ) : (
                          "--"
                        )}
                        {orderDetailsTrackingData?.salesRepPhone &&
                        orderDetailsTrackingData?.salesRepPhone !== "" ? (
                          <a
                            className="contact-value-email-phone"
                            data-testid="phone-value-salesrep"
                            href={`tel:${orderDetailsTrackingData.salesRepPhone.replace(
                              "x",
                              " x"
                            )}`}
                          >
                            {orderDetailsTrackingData.salesRepPhone.includes(
                              "ext"
                            )
                              ? orderDetailsTrackingData.salesRepPhone
                              : orderDetailsTrackingData.salesRepPhone.replace(
                                  "x",
                                  " x"
                                )}
                          </a>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      "--"
                    )}
                  </div>
                </Grid>
                <Grid className="grid-item" item xs={isMobileScreen ? 6 : 4}>
                  <div className="sub-content-div">
                    <h5
                      className="contact-3m-order-content-title"
                      data-testid="cust-serv-rep"
                    >
                      {patientData?.isAcuteOrder
                        ? "Patient Support Specialist"
                        : " Customer Service Representative"}
                    </h5>
                    {error === false ? (
                      <>
                        <h5
                          className="contact-3m-order-content-value"
                          data-testid="name-value-csr-name"
                        >{`${makeCapitalEachWordInString(
                          orderDetailsTrackingData.csrName === null ||
                            orderDetailsTrackingData.csrName === ""
                            ? "--"
                            : orderDetailsTrackingData.csrName
                        )} 
                   `}</h5>
                        {orderDetailsTrackingData?.csrEmail &&
                        orderDetailsTrackingData?.csrEmail !== "" ? (
                          <a
                            className="contact-value-email-phone"
                            href={`mailto:${makeCapitalEachWordInString(
                              orderDetailsTrackingData?.csrEmail
                            )}`}
                          >
                            {makeCapitalEachWordInString(
                              orderDetailsTrackingData?.csrEmail
                            )}
                          </a>
                        ) : (
                          <h5
                            className="contact-3m-order-content-value"
                            data-testid="phone-value-csr"
                          >{`${makeCapitalEachWordInString("--")} 
                   `}</h5>
                        )}
                        {orderDetailsTrackingData?.csrPhone &&
                        orderDetailsTrackingData?.csrPhone !== "" ? (
                          <a
                            className="contact-value-email-phone"
                            href={`tel:${orderDetailsTrackingData.csrPhone.replace(
                              "x",
                              " x"
                            )}`}
                          >
                            {orderDetailsTrackingData.csrPhone.includes("ext")
                              ? orderDetailsTrackingData.csrPhone
                              : orderDetailsTrackingData.csrPhone.replace(
                                  "x",
                                  " x"
                                )}
                          </a>
                        ) : (
                          <h5
                            className="contact-3m-order-content-value"
                            data-testid="no-content-value"
                          >{`${makeCapitalEachWordInString("")} 
                   `}</h5>
                        )}
                      </>
                    ) : (
                      "--"
                    )}
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
