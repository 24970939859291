export const INTERNAL_SALES_MGR_ROLE_TEXT =
  "usac-ms-express-salesmgr-" + process.env.REACT_APP_OKTA_ENV;
export const INTERNAL_SALES_ROLE_TEXT =
  "usac-ms-express-sales-" + process.env.REACT_APP_OKTA_ENV;
export const INTERNAL_PRODMGR_ROLE_TEXT =
  "usac-ms-express-prdmgr-" + process.env.REACT_APP_OKTA_ENV;
export const INTERNAL_SUPPORT_ROLE_TEXT =
  "usac-ms-express-support-" + process.env.REACT_APP_OKTA_ENV;
export const INTERNAL_3MADMIN_ROLE_TEXT =
  "usac-ms-express-admin-" + process.env.REACT_APP_OKTA_ENV;
export const INTERNAL_NATIONAL_ROLE_TEXT =
  "usac-ms-express-national-" + process.env.REACT_APP_OKTA_ENV;
