import "./addSiteStatus.css";
import { useContext, useEffect, useState } from "react";
import {
  InternalSignOnContextType,
  InternalSignOnContext,
} from "../../../../context/InternalSignOnContext";
import { Navigator } from "../../../helpAndSupport/Navigator/navigator.component";
import { SiteStatusFooterButtonGroup } from "./siteStatusFooterButton/siteStatusFooterButton.component";
import { SiteStatusType } from "./siteStatusType/siteStatusType.componenet";
import { SiteStatusActivation } from "./siteStatusActivation/siteStatusActivation.component";
import { SiteStatusLocation } from "./siteStatusLocation/siteStatusLocation.component";
import { SiteStatusDisplayAudience } from "./siteStatusDisplayAudience/siteStatusDisplayAudience.component";
import {
  AddSiteStatusContext,
  AddSiteStatusContextType,
} from "../../../../context/AddSiteStatusContext";
import { AddSiteStatusValidator } from "./addSiteStatus.validator";
import { ValidationStatus } from "../../../../core/interfaces/input.interface";
import { SiteStatusContent } from "./siteStatusContent/siteStatusContent.component";
import { addSiteStatusRequestMapper } from "./mapper/addSiteStatusRequestMapper";
import {
  addSiteStatus,
  updateSiteStatus,
} from "../../../../util/siteStatusesService";
import { AuthContextType, AuthContext } from "../../../../context/AuthContext";
import { Popup } from "../../../../core/popup/popup.component";
import { LoadingSpinner } from "../../../../core/loader/LoadingSpinner";
import ErrorPopup from "../../../../core/errorPopup/errorPopup.component";
import { ERROR_MSG_ADD_SITE_STATUS } from "../../../../util/errorMsg";
import { useHistory } from "react-router-dom";

export const AddSiteStatus = () => {
  const internalObj = useContext<InternalSignOnContextType | null>(
    InternalSignOnContext
  );
  const siteStatusObj = useContext<AddSiteStatusContextType | null>(
    AddSiteStatusContext
  );
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const history = useHistory();
  const siteStatusData = siteStatusObj!.siteStatusDetails;
  const setSiteStatusData = siteStatusObj!.setSiteStatusDetails;
  const [showLoader, setShowLoader] = useState(false);
  const [errorCode, setErrorCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorPopUpFlag, setErrorPopUpFlag] = useState(false);
  const userName = authObj?.userProfile?.userName;

  const checkErroredFieldAndFocusOnInputField = async (scrollId: string) => {
    await setTimeout(() => {
      if (scrollId) {
        const scrollableComponent = document.getElementById(scrollId);
        if (scrollableComponent) {
          scrollableComponent.scrollIntoView({
            behavior: "smooth",
          });
        }
      }
    }, 500);
  };

  const goBackToManageSiteStatuses = () => {
    siteStatusObj?.resetSiteStatusDetails();
    history.goBack();
  };

  const cancelBtnAction = async () => {
    goBackToManageSiteStatuses();
  };

  const addSiteStatusBtnAction = async () => {
    const validator = new AddSiteStatusValidator();
    const [isAllValid, scrollId] = validator.validateAll(
      siteStatusData,
      setSiteStatusData
    );
    if (scrollId) {
      checkErroredFieldAndFocusOnInputField(scrollId!);
    }
    if (isAllValid === ValidationStatus.VALID && siteStatusObj && userName) {
      const reqBody = await addSiteStatusRequestMapper(siteStatusObj, userName);
      setShowLoader(true);
      const response = await addSiteStatus(reqBody);
      if (response && response.succeeded) {
        goBackToManageSiteStatuses();
      } else {
        setErrorMessage(ERROR_MSG_ADD_SITE_STATUS);
        setErrorCode(response?.error?.errorCode || response?.status);
        setErrorPopUpFlag(true);
      }
      setShowLoader(false);
    }
  };

  const updateSiteStatusBtnAction = async () => {
    const validator = new AddSiteStatusValidator();
    const [isAllValid, scrollId] = validator.validateAll(
      siteStatusData,
      setSiteStatusData
    );
    if (scrollId) {
      checkErroredFieldAndFocusOnInputField(scrollId!);
    }
    if (isAllValid === ValidationStatus.VALID && siteStatusObj && userName) {
      const reqBody = await addSiteStatusRequestMapper(siteStatusObj, userName);
      setShowLoader(true);
      const response = await updateSiteStatus(reqBody);
      if (response && response.succeeded) {
        goBackToManageSiteStatuses();
      } else {
        setErrorMessage(ERROR_MSG_ADD_SITE_STATUS);
        setErrorCode(response?.error?.errorCode || response?.status);
        setErrorPopUpFlag(true);
      }
      setShowLoader(false);
    }
  };

  return (
    <div
      className="add-site-status-component"
      id="add-site-status-component"
      data-testid="add-site-status-component"
    >
      <div className="add-site-status-container">
        <Navigator
          array={[
            {
              route: internalObj?.isInternalAdministration
                ? "/internalAdministration"
                : "/administration",
              pageName: "Administration",
            },
            {
              route: "/administration/manageSiteStatuses",
              pageName: "Site status",
            },
          ]}
          className="add-site-status-component-route-section"
          title="Site status detail"
        />
        <div className="add-site-status-form">
          <div
            className="add-site-status-hearder"
            data-testid="add-site-status-hearder"
          >
            <p className="add-site-status-text">Add site status</p>
          </div>
          <SiteStatusType data={siteStatusData} setData={setSiteStatusData} />
          <SiteStatusActivation
            data={siteStatusData}
            setData={setSiteStatusData}
          />
          <SiteStatusContent
            data={siteStatusData}
            setData={setSiteStatusData}
          />
          <SiteStatusLocation
            data={siteStatusData}
            setData={setSiteStatusData}
          />
          <SiteStatusDisplayAudience
            data={siteStatusData}
            setData={setSiteStatusData}
          />
        </div>
        <SiteStatusFooterButtonGroup
          firstButtonTitle="Cancel"
          firstButtonAction={cancelBtnAction}
          firstButtonDisabled={false}
          secondButtonTitle={
            siteStatusObj?.isAddButtonClick
              ? "Add site status"
              : "Update site status"
          }
          secondButtonAction={
            siteStatusObj?.isAddButtonClick
              ? addSiteStatusBtnAction
              : updateSiteStatusBtnAction
          }
          secondButtonDisabled={false}
        />
      </div>
      <Popup
        openFlag={showLoader}
        closeHandler={() => setShowLoader(false)}
        dialogParentClass={"add-site-status-loader-pop-up"}
        data-testid="manage-site-statuses-pop-up"
        hideCloseButton={true}
      >
        <div
          className="add-site-status-loader"
          data-testid="manage-site-status-loader"
        >
          <LoadingSpinner />
        </div>
      </Popup>
      <ErrorPopup
        errorPopupFlag={errorPopUpFlag}
        handleBackButton={() => {
          setErrorPopUpFlag(false);
        }}
        popUpStyles="errorPopup"
        errorMessage={errorMessage}
        buttontitle="Done"
        showTitle={false}
        isShortSentence={true}
        isSupportPhoneRequired={false}
        errorCode={errorCode}
      />
    </div>
  );
};
