import { styled } from "@mui/system";
import { Link } from "react-router-dom";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";

export const NavigationBarDiv = styled("div")(({ theme }) => ({
  backgroundColor: "#fff",
  float: "right",
  boxSizing: "border-box",
  fontWeight: "400",
  color: "#4C4C4F",
  fontSize: "16px",
  boxShadow: "0px 3px 6px 0px rgba(0, 0, 0, 0.10)",
}));

export const OptionLink = styled(Link)(({ theme }) => ({
  display: "flex",
  padding: "21px 16px 21px 24px",
  textDecoration: "none",
  textSize: "16px",
  color: "#000",
  textAlign: "left",
  fontWeight: "400",
  ":hover": {
    color: "#1E64D0",
  },
}));

export const OptionLinkPopup = styled("div")(({ theme }) => ({
  display: "flex",
  textDecoration: "none",
  textSize: "16px",
  color: "#000",
  textAlign: "left",
  fontWeight: "400",
  cursor: "pointer",
  padding: "21px 16px 21px 24px",
  ":hover": {
    color: "#1E64D0",
  },
}));

export const OptionLinkActive = styled(Link)(({ theme }) => ({
  display: "block",
  padding: "21px 16px 21px 24px",
  textDecoration: "none",
  color: "#0049BD",
  textAlign: "left",
  fontWeight: "400",
  fontSize: "16px",
  height: "23px",
}));
export const OptionLinkActivePopup = styled("div")(({ theme }) => ({
  display: "block",
  padding: "21px 16px 21px 24px",
  textDecoration: "none",
  color: "#0049BD",
  textAlign: "left",
  fontWeight: "400",
  fontSize: "16px",
  height: "23px",
  cursor: "pointer",
}));

export const OptionLinkWrapper = styled("div")(({ theme }) => ({
  borderBottom: "0.5px solid #CCCCD0",
  width: "200px",
  ":hover": {
    backgroundColor: "#E8F4FF",
  },
  [theme.breakpoints.between(920, 1025)]: {
    width: "auto",
  },
}));
