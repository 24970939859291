import { useContext, useState } from "react";
import { Button, Grid, Box, useMediaQuery, Typography } from "@mui/material";
import "./nationalRegistry.css";
import { getDeepClone } from "../../../../util/ObjectFunctions";
import {
  defaultNationalRegistrySearchBox,
  defaultNationalRegistrySearchByDetails,
  INationalRegistrySearch,
  INationalRegistrySearchByDetails,
} from "./nationRegistrySearch.model";
import { NewOrderValidator } from "../../newOrder.validator";
import { ExpressButton } from "../../../../core/expressButton/expressButton.component";
import { InputWithLabel } from "../../../../core/inputWithLabel/inputWithLabel.component";
import { ValidationStatus } from "../../../../core/interfaces/input.interface";
import InputBase from "@mui/material/InputBase";
import { SearchPrescriberModal } from "../searchPrescriber.enum";
import NationalRegistrySearchByDetails from "./nationalRegistrySearchByDetails/nationalRegistrySearchByDetails.component";
import { getNPIPrescriber } from "../../../../util/npiService";
import { LoadingSpinner } from "../../../../core/loader/LoadingSpinner";
import {
  NewOrderContext,
  NewOrderContextType,
} from "../../../../context/NewOrderContext";
import NewOrderErrorPopupMessage from "../../newOrderFooterGroup/newOrderErrorPopupMessage.component";
import { PRESCRIBER_SEARCH_NPI_SERVICE_ERROR } from "../../../../util/errorMsg";
import {
  AdminMyListsContext,
  AdminMyListsContextType,
} from "../../../../context/AdministrationMyListsContext";

interface Props {
  handlePrescriberSearchType: Function;
  states: never[];
  statesText: never[];
  handleNpiPrescriberSearchResult: Function;
}

export const NationalRegistrySearch = ({
  handlePrescriberSearchType,
  states,
  statesText,
  handleNpiPrescriberSearchResult,
}: Props) => {
  const NewOrderObj = useContext<NewOrderContextType | null>(NewOrderContext);
  const myListObj = useContext<AdminMyListsContextType | null>(
    AdminMyListsContext
  );
  const isMobileScreen = useMediaQuery("(max-width:600px)");
  const [nationalRegistrySearchInputs, setNationalRegistrySearchInputs] =
    useState<INationalRegistrySearch>(
      getDeepClone(defaultNationalRegistrySearchBox)
    );
  const [nationRegistrySearchByDetails, setNationRegistrySearchByDetails] =
    useState<INationalRegistrySearchByDetails>(
      getDeepClone(defaultNationalRegistrySearchByDetails)
    );
  const validateAndSetData = (e: any) => {
    NewOrderObj?.setIsHandleChangeTriggered(true);
    setNationRegistrySearchByDetails(
      getDeepClone(defaultNationalRegistrySearchByDetails)
    );
    const validator = new NewOrderValidator();
    const isvalid = validator.validate(e.target.value, e.target.name);
    setNationalRegistrySearchInputs((nationalRegistrySearchInputs) => ({
      ...nationalRegistrySearchInputs,
      [e.target.name]: { value: e.target.value, valid: isvalid?.status },
    }));
  };

  const checkValidationStatus = () => {
    return nationalRegistrySearchInputs.nationalRegistryNumber.valid !==
      ValidationStatus.VALID
      ? true
      : false;
  };

  const [showSpinner, setShowSpinner] = useState(false);
  const [showerrorpopup, setShowerrorpopup] = useState(false);
  const [errorCode, setErrorCode] = useState("");

  const spinner = () => {
    return (
      <div className="nr-spinner">
        <LoadingSpinner />
      </div>
    );
  };

  const npiPrescriberSearch = async (reqParams: any) => {
    setShowSpinner(true);
    const data = await getNPIPrescriber(reqParams);
    setShowSpinner(false);
    if (data != null && data.succeeded) {
      handlePrescriberSearchType(
        SearchPrescriberModal.NATIONAL_REGISTRY_RESULT
      );
      const updatedList = data.items.map((x: any) => {
        return { ...x, prescriberName: x.firstName + "," + x.lastName };
      });
      handleNpiPrescriberSearchResult(updatedList);
    } else if (!data?.succeeded) {
      setShowerrorpopup(true);
      setErrorCode(data?.error?.errorCode || data?.status);
    } else {
      handlePrescriberSearchType(
        SearchPrescriberModal.NATIONAL_REGISTRY_NO_RESULT
      );
    }
  };

  const handleSearchNPI = () => {
    //reseting the list before the service call
    myListObj?.setNpiPrescriberTabList([]);
    let reqParams = {
      NPI: nationalRegistrySearchInputs.nationalRegistryNumber.value,
      FirstName: nationRegistrySearchByDetails.NPIFirstName.value,
      LastName: nationRegistrySearchByDetails.NPILastName.value,
      State: nationRegistrySearchByDetails.state.value,
    };
    npiPrescriberSearch(reqParams);
  };
  const closeErrorPopup = () => {
    setShowerrorpopup(false);
  };

  return (
    <>
      {showerrorpopup && (
        <NewOrderErrorPopupMessage
          popUpStyles="nrPrescriberErrorPopup"
          handleBackButton={closeErrorPopup}
          errorPopupFlag={true}
          errorMessage={PRESCRIBER_SEARCH_NPI_SERVICE_ERROR}
          handleExitButton={closeErrorPopup}
          isSupportPhoneShow={true}
          errorCode={errorCode}
        />
      )}
      <div className="nrprescriber-search">
        <div className="nrheader" data-testid="nrprescriberSearchTitle">
          {myListObj?.isFromMyList
            ? "Add New Prescriber: Search in National Registry"
            : "Prescriber Search"}
        </div>
        {!myListObj?.isFromMyList && (
          <Grid container className="nrprescriberSearchLabel" spacing={2}>
            <Grid
              className="nrprescriber-informantion-item"
              item
              xs={isMobileScreen ? 12 : 6}
            >
              <b
                className="nrsearchPrescriberLabelSec"
                data-testid="nrprescriberSearchTitleSec"
              >
                Search in National Registry
              </b>
            </Grid>
            <Grid
              className="nrprescriber-informantion-item"
              item
              xs={isMobileScreen ? 12 : 6}
            >
              <Typography
                className={
                  isMobileScreen ? "nrbutton-align-sm" : "nrbutton-align-lg"
                }
                onClick={() =>
                  handlePrescriberSearchType(
                    SearchPrescriberModal.SEARCH_PRESCRIBER
                  )
                }
                classes={{
                  root: "nrprescriber-informantion-addOrRemove-button",
                }}
                data-testid="nrprescriber-informantion-addOrRemove-button"
              >
                Search My Prescriber List instead
              </Typography>
            </Grid>
          </Grid>
        )}
        {showSpinner ? (
          spinner()
        ) : (
          <>
            <Grid className="nrprescriberSearchInputs" container spacing={2}>
              <Grid className="searchField" item xs={isMobileScreen ? 12 : 9}>
                <InputWithLabel
                  labelClassName="searchlabelname"
                  testId="newOrder-First-Name"
                  label="Prescriber NPI Number"
                  isRequired={true}
                  error={
                    nationalRegistrySearchInputs.nationalRegistryNumber
                      .valid === ValidationStatus.INVALID
                  }
                >
                  <InputBase
                    style={{ width: "100%" }}
                    autoFocus
                    name="nationalRegistryNumber"
                    onChange={validateAndSetData}
                    value={
                      nationalRegistrySearchInputs.nationalRegistryNumber.value
                    }
                    type="text"
                    inputProps={{ inputMode: "numeric", pattern: "[0-9*]" }}
                  />
                </InputWithLabel>
              </Grid>
              <Grid className="searchButton" item xs={isMobileScreen ? 12 : 3}>
                <ExpressButton
                  testId="searchnpibutton"
                  clickHandler={handleSearchNPI}
                  parentClass="searchNR"
                  variant="contained"
                  disabled={checkValidationStatus()}
                >
                  Search
                </ExpressButton>
              </Grid>
            </Grid>
            <div className="orDiv">OR</div>
            <NationalRegistrySearchByDetails
              nationRegistrySearchByDetails={nationRegistrySearchByDetails}
              setNationRegistrySearchByDetails={
                setNationRegistrySearchByDetails
              }
              states={states}
              statesText={statesText}
              setNationalRegistrySearchInputs={setNationalRegistrySearchInputs}
              handleSearchNPI={handleSearchNPI}
            />
          </>
        )}
      </div>
    </>
  );
};
