import "./moveUnitToStoredProduct.css";
import { Grid } from "@mui/material";
import { InputWithLabel } from "../../../../../core/inputWithLabel/inputWithLabel.component";
import { CustomDropDown } from "../../../../../core/customDropdown/customDropdown.component";
import { InventoryPopUp } from "../../inventoryPopUpContainer.enum";
import { useState } from "react";
import {
  IInputField,
  ValidationStatus,
} from "../../../../../core/interfaces/input.interface";
import { IInventoryProductDetails } from "../../../inventoryTabContainer.enum";
import { ExpressButton } from "../../../../../core/expressButton/expressButton.component";
import parse from "html-react-parser";

interface IMoveUnitToStoredProductProps {
  confirmMove: Function;
  closePopUp: Function;
  isMoveStoredProduct: boolean;
  selectedProduct: IInventoryProductDetails;
  storageLocations: string[];
}

export const MoveUnitToStoredProduct = ({
  closePopUp,
  confirmMove,
  isMoveStoredProduct,
  selectedProduct,
  storageLocations,
}: IMoveUnitToStoredProductProps) => {
  const [data, setData] = useState<IInputField>({
    valid:
      selectedProduct.storageLocation !== ""
        ? ValidationStatus.VALID
        : ValidationStatus.UNTOUCHED,
    value: selectedProduct.storageLocation,
  });

  const validateAndSetDropDownData = async (e: any) => {
    setData({
      value: e.target.value,
      valid: ValidationStatus.VALID,
    });
  };

  const checkNextBtnDisablity = (): boolean => {
    if (data.value === "") {
      return true;
    }
    return false;
  };

  const nextButtonAction = () => {
    selectedProduct.storageLocation = data.value;
    confirmMove(
      isMoveStoredProduct ? "2" : "1",
      selectedProduct,
      isMoveStoredProduct
        ? InventoryPopUp.MOVE_TO_STORE_PRODUCT_SUCCESS
        : InventoryPopUp.MOVE_TO_READY_CARE_PRODUCT_SUCCESS
    );
  };

  const cancelButtonAction = () => {
    closePopUp();
  };

  return (
    <div className="move-unit-to-stored-product-component">
      <p className="title" data-testid="move-loc-title">
        <span>
          {parse(
            `${
              isMoveStoredProduct
                ? "Move Unit to Stored Product Listing"
                : "Move Unit to V.A.C.<sup>®</sup> Ready Care Listing"
            }`
          )}
        </span>
      </p>
      <Grid className="move-unit-grid-container" container>
        <Grid className="move-unit-grid-item" xs={6}>
          <div className="product-details">
            <p
              className="product-detail-title"
              data-testid="product-detail-title-test"
            >
              Product
            </p>
            <p
              className="product-detail-value"
              data-testid="product-detail-title-value-test"
            >
              {selectedProduct.product}
            </p>
          </div>
        </Grid>
        <Grid className="move-unit-grid-item" xs={6}>
          <div className="product-details">
            <p
              className="product-detail-title"
              data-testid="serial-number-title-test"
            >
              Serial Number
            </p>
            <p
              className="product-detail-value"
              data-testid="serial-number-title-value-test"
            >
              {selectedProduct.serialNumber}
            </p>
          </div>
        </Grid>
        <Grid className="move-unit-grid-item" xs={12}>
          <p
            className="update-store-location-title"
            data-testid="move-description-test"
          >
            Would you also like to update the storage location?
          </p>
        </Grid>
        <Grid className="move-unit-grid-item" xs={12}>
          <InputWithLabel
            isDropdown={true}
            label="Storage Location"
            labelClassName="storage-location-title"
            testId="storage-location-drop-down"
          >
            <CustomDropDown
              handleChange={validateAndSetDropDownData}
              menuItem={storageLocations}
              name="storageLocation"
              placeHolder="Select Storage Location"
              selectpropsClassName="storage-location-select"
              selectClassName="storage-location-input storage-location"
              testId="storage-location-dropdown"
              value={data.value}
            />
          </InputWithLabel>
        </Grid>
        <Grid xs={12} className="move-buttons-container">
          <Grid item xs={6} className="button-container">
            <ExpressButton
              testId="move-unit-cancel-button"
              clickHandler={cancelButtonAction}
              variant="outlined"
              parentClass="move-unit-cancel-button"
            >
              Cancel
            </ExpressButton>
          </Grid>
          <Grid item xs={6} className="button-container">
            <ExpressButton
              testId="move-unit-confirmMove-button"
              variant="contained"
              disabled={checkNextBtnDisablity()}
              clickHandler={nextButtonAction}
              parentClass="move-unit-confirmMove-button"
            >
              Confirm Move
            </ExpressButton>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
