import { createContext, useState } from "react";
import { IPatient } from "../components/myPatients/patient.interface";
import React from "react";
import { IDropZoneDocumentSelect } from "../core/customDropZone/dropZoneDocumentSelect.interface";
import {
  IProofOfDeliverESign,
  SubmitESignPodStatus,
} from "../components/myPatients/submitProofOfDelivery/proofOfDeliveryESign/proofOfDeliveryESign.interface";
import { defaultESignPatientDetails } from "../components/myPatients/submitProofOfDelivery/proofOfDeliveryESign/proofOfDeliveryESign.data";

export type SubmitProofOfDeliveryContextType = {
  patient: IPatient | null;
  setPatient: React.Dispatch<React.SetStateAction<IPatient | null>>;
  proofOfDeliveryUploadDocs: IDropZoneDocumentSelect[];
  setProofOfDeliveryUploadDocs: React.Dispatch<
    React.SetStateAction<IDropZoneDocumentSelect[]>
  >;
  fileStatus: String;
  setFileStatus: Function;
  isUploadActive: string[];
  setIsUploadActive: React.Dispatch<React.SetStateAction<string[]>>;
  eSignOriginalPatientDetails: IProofOfDeliverESign;
  setESignOriginalPatientDetails: React.Dispatch<
    React.SetStateAction<IProofOfDeliverESign>
  >;
  eSignPatientDetails: IProofOfDeliverESign;
  setESignPatientDetails: React.Dispatch<
    React.SetStateAction<IProofOfDeliverESign>
  >;
  podESignSuccess: boolean;
  setPodESignSuccess: Function;
  podESignFailure: boolean;
  setPodESignFailure: Function;
  podESignStatus: SubmitESignPodStatus;
  setPodESignStatus: Function;
  resetData: () => void;

  isPODLoaded: boolean | null;
  setIsPODLoaded: React.Dispatch<React.SetStateAction<boolean | null>>;
};

type SubmitProofOfDeliveryContextProviderProps = {
  children: React.ReactNode;
};

export const SubmitProofOfDeliveryContext =
  createContext<SubmitProofOfDeliveryContextType | null>(null);

export const SubmitProofOfDeliveryContextProvider = ({
  children,
}: SubmitProofOfDeliveryContextProviderProps) => {
  const resetData = () => {
    setPatient(null);
    setProofOfDeliveryUploadDocs([]);
    setFileStatus("");
    setIsUploadActive([]);
    setESignOriginalPatientDetails(defaultESignPatientDetails);
    setESignPatientDetails(defaultESignPatientDetails);
    setPodESignSuccess(false);
    setPodESignFailure(false);
    setPodESignStatus(SubmitESignPodStatus.NONE);
    setIsPODLoaded(false);
  };

  const [patient, setPatient] = useState<IPatient | null>(null);
  const [proofOfDeliveryUploadDocs, setProofOfDeliveryUploadDocs] = useState<
    IDropZoneDocumentSelect[]
  >([]);
  const [fileStatus, setFileStatus] = useState<string>("Upload successful!");
  const [isUploadActive, setIsUploadActive] = useState<string[]>([]);
  const [eSignPatientDetails, setESignPatientDetails] =
    useState<IProofOfDeliverESign>(defaultESignPatientDetails);
  const [eSignOriginalPatientDetails, setESignOriginalPatientDetails] =
    useState<IProofOfDeliverESign>(defaultESignPatientDetails);
  const [podESignSuccess, setPodESignSuccess] = useState(false);
  const [podESignFailure, setPodESignFailure] = useState(false);
  const [podESignStatus, setPodESignStatus] = useState(
    SubmitESignPodStatus.NONE
  );
  const [isPODLoaded, setIsPODLoaded] = useState<boolean | null>(null);

  return (
    <SubmitProofOfDeliveryContext.Provider
      value={{
        patient,
        setPatient,
        resetData,
        proofOfDeliveryUploadDocs,
        setProofOfDeliveryUploadDocs,
        fileStatus,
        setFileStatus,
        isUploadActive,
        setIsUploadActive,
        eSignOriginalPatientDetails,
        setESignOriginalPatientDetails,
        eSignPatientDetails,
        setESignPatientDetails,
        podESignSuccess,
        setPodESignSuccess,
        podESignFailure,
        setPodESignFailure,
        podESignStatus,
        setPodESignStatus,
        isPODLoaded,
        setIsPODLoaded,
      }}
    >
      {children}
    </SubmitProofOfDeliveryContext.Provider>
  );
};
