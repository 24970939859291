import { IDropZoneDocumentSelect } from "../../../core/customDropZone/dropZoneDocumentSelect.interface";
import {
  Validation,
  ValidationStatus,
  IInputField,
} from "../../../core/interfaces/input.interface";
import { getDeepClone } from "../../../util/ObjectFunctions";
import { Validator } from "../../../util/order.validations";
import {
  getValidObj,
  updateErrorMessage,
} from "../../../util/utilityFunctions";
import { ISalesAssistance } from "./salesAssistanceNeedHelp.interface";

export class SalesAssistanceNeedHelpValidator {
  private _validator;
  constructor(defValidator = new Validator()) {
    this._validator = defValidator;
  }

  private noValidation(): Validation {
    return getValidObj();
  }

  private fieldToMethodMapping(
    field: string
  ): ((txt: string) => Validation) | undefined {
    const mapping = new Map<string, (txt: string) => Validation>([
      ["errorCode", this._validator.optionalErrorCodeValidation],
      ["dateOfProblem", this._validator.dateValidation],
      ["facilityId", this._validator.facilityIDValidationWithEmptyUntouched],
      ["roNumber", this._validator.optionalROValidation],
      ["otherFeatureAffected", this._validator.otherReasonForRemovalInventory],
      ["additionalInformation", this._validator.otherReasonForRemovalInventory],
    ]);
    const validator = mapping.get(field);
    return validator ? validator : this.noValidation;
  }

  public validate(input: string, field: string) {
    try {
      const validator = this.fieldToMethodMapping(field)!;
      return validator(input);
    } catch (error) {
      console.log(`validator method for field ${field} is not configured`);
    }
  }

  public validateAll(
    data: ISalesAssistance,
    updateDataIfUntouchedAndValidated: Function,
    uploadDocuments: any,
    updateUploadDocumentsDataIfUntouchedAndValidated: Function
  ): [ValidationStatus, IInputField | null] {
    let tempData = getDeepClone(data);
    const uploadDocumentsTemp = getDeepClone(uploadDocuments);
    let smallestOrderWithInvalidStatus: any | null = null;
    Object.keys(tempData).forEach((key: string) => {
      const subTempData = tempData[key];
      if (subTempData) {
        let subTempDataValue = tempData[key].value;
        if (tempData[key].hasChildsToValidate && subTempDataValue) {
          let isAllValid = ValidationStatus.VALID;
          Object.keys(subTempDataValue).forEach((subkey: string) => {
            let inputField = subTempDataValue[subkey];
            smallestOrderWithInvalidStatus = this.validateInputField(
              inputField,
              smallestOrderWithInvalidStatus
            );
            if (inputField.valid === ValidationStatus.INVALID) {
              if (isAllValid !== ValidationStatus.INVALID) {
                isAllValid = ValidationStatus.INVALID;
              }
            } else if (
              subTempDataValue[subkey].valid === ValidationStatus.UNTOUCHED
            ) {
              subTempDataValue[subkey].valid = ValidationStatus.INVALID;
            }
          });
          tempData = {
            ...tempData,
            [key]: {
              ...Object(tempData)[key],
              valid: isAllValid,
              value: subTempDataValue,
            },
          };
        } else {
          if (key === "problemDetails") {
            Object.keys(tempData[key]).forEach((x: string) => {
              if (
                tempData[key][x].isOptional === true &&
                tempData[key][x].valid !== ValidationStatus.VALID
              ) {
                tempData[key][x].valid = ValidationStatus.VALID;
              } else if (
                tempData[key][x].required &&
                tempData[key][x].valid === ValidationStatus.UNTOUCHED
              ) {
                tempData[key][x].valid = ValidationStatus.INVALID;
              } else if (
                tempData[key][x].required === false &&
                tempData[key][x].valid === ValidationStatus.UNTOUCHED
              ) {
                tempData[key][x].valid = ValidationStatus.VALID;
              } else if (
                tempData[key][x].isDefaultValid &&
                tempData[key][x].valid !== ValidationStatus.VALID
              ) {
                tempData[key][x].valid = ValidationStatus.INVALID;
              }
              // Find smallest order number which has invalid status
              if (
                tempData[key][x].valid === ValidationStatus.INVALID &&
                tempData[key][x].order &&
                ((smallestOrderWithInvalidStatus &&
                  smallestOrderWithInvalidStatus.order! > 45) ||
                  !smallestOrderWithInvalidStatus)
              ) {
                if (
                  !smallestOrderWithInvalidStatus ||
                  (smallestOrderWithInvalidStatus.order &&
                    tempData[key][x].order &&
                    smallestOrderWithInvalidStatus.order >
                      tempData[key][x].order)
                ) {
                  smallestOrderWithInvalidStatus = tempData[key][x];
                }
              }
              updateErrorMessage(tempData[key][x]);
            });
          } else {
            let inputField = tempData[key];
            smallestOrderWithInvalidStatus = this.validateInputField(
              inputField,
              smallestOrderWithInvalidStatus
            );
          }
        }
      }
    });
    uploadDocumentsTemp.forEach(
      (document: IDropZoneDocumentSelect, index: number) => {
        if (!document.succeeded && document["documentType"]) {
          if (
            !smallestOrderWithInvalidStatus ||
            (smallestOrderWithInvalidStatus.order &&
              document["documentType"].order &&
              smallestOrderWithInvalidStatus.order >
                document["documentType"].order)
          ) {
            document["documentType"].componentId = `upload-documents-${
              index + 1
            }`;
            smallestOrderWithInvalidStatus = document["documentType"];
          }
        }
      }
    );
    updateDataIfUntouchedAndValidated(tempData);
    updateUploadDocumentsDataIfUntouchedAndValidated(uploadDocumentsTemp);
    const problemDetailsTemp = tempData.problemDetails;
    const isProblemFieldsAreValid = Object.keys(problemDetailsTemp)
      .filter((key: string) => problemDetailsTemp[key].valid)
      .every(
        (key: string) =>
          problemDetailsTemp[key].valid === ValidationStatus.VALID
      );
    let ifUploadDocumentValid = false;
    if (uploadDocumentsTemp.length > 0) {
      let arr: any = [];
      uploadDocumentsTemp.forEach((document: IDropZoneDocumentSelect) => {
        arr.push(document.succeeded);
      });
      ifUploadDocumentValid = arr.every((x: boolean) => x === true);
    } else {
      ifUploadDocumentValid = true;
    }
    const ifAllValid = Object.keys(tempData)
      .filter(
        (key: string) => tempData[key].isDefaultValid || tempData[key].required
      )
      .every((key: string) => tempData[key].valid === ValidationStatus.VALID);

    return [
      ifAllValid && isProblemFieldsAreValid && ifUploadDocumentValid
        ? ValidationStatus.VALID
        : ValidationStatus.INVALID,
      smallestOrderWithInvalidStatus,
    ];
  }

  private validateInputField(
    input: IInputField,
    smallestOrderWithInvalidStatus: IInputField | null
  ) {
    if (input.isOptional === true && input.valid !== ValidationStatus.VALID) {
      input.valid = ValidationStatus.VALID;
    } else if (input.required && input.valid === ValidationStatus.UNTOUCHED) {
      input.valid = ValidationStatus.INVALID;
    } else if (input.isDefaultValid && input.valid !== ValidationStatus.VALID) {
      input.valid = ValidationStatus.INVALID;
    }
    if (input.valid === ValidationStatus.INVALID && input.order) {
      if (
        !smallestOrderWithInvalidStatus ||
        (smallestOrderWithInvalidStatus.order &&
          input.order &&
          smallestOrderWithInvalidStatus.order > input.order)
      ) {
        smallestOrderWithInvalidStatus = input;
      }
    }
    updateErrorMessage(input);
    return smallestOrderWithInvalidStatus;
  }
}
