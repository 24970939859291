import { Grid } from "@mui/material";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import OrderSummarySuccess from "../../../assets/OrderSummarySuccess.svg";
import { MobileDisplayContext } from "../../../context/MobileDisplayContext";
import { ExpressButton } from "../../../core/expressButton/expressButton.component";
import { ControlSpan } from "../../../core/radioDropdown/radioDropdown.style";
import { SUPPLYORDER_SUMMARY_HEADERTEXT } from "../../../util/staticText";
import { AuthContext, AuthContextType } from "../../../context/AuthContext";

const SupplyOrderSummaryHeader = () => {
  const history = useHistory();
  const { isMobileScreen } = useContext(MobileDisplayContext);
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const handleNavigation = () => {
    if (authObj?.isUnlinkedFacilityOrder) {
      history.push("/ssoRedirect");
    } else {
      history.push("/home");
    }
  };
  return (
    <Grid
      className="orderSummaryForm"
      container
      display="flex"
      flexDirection="row"
    >
      <Grid xs={isMobileScreen ? 3.5 : 1.5}>
        <div data-testid="successimgTest">
          <img src={OrderSummarySuccess} alt={OrderSummarySuccess} />
        </div>
      </Grid>
      <Grid xs={isMobileScreen ? 8.5 : 9}>
        <Grid display="flex" flexDirection="column">
          <Grid>
            <p className="orderSuccessPara" data-testid="successTextTest">
              Thank you!
            </p>
          </Grid>
          <Grid>
            <p className="orderSuccessPara2" data-testid="successText2Test">
              Your Patient Supply Order has been submitted.
            </p>
          </Grid>
          <Grid>
            <p className="orderSuccessPara3" data-testid="successText3Test">
              {SUPPLYORDER_SUMMARY_HEADERTEXT}
            </p>
          </Grid>
          <Grid className="orderSummaryBtn">
            <ExpressButton
              clickHandler={handleNavigation}
              parentClass="backtoPatientBtn"
              testId="OrderSucessTest"
              variant="contained"
            >
              Back to My Patients
            </ExpressButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SupplyOrderSummaryHeader;
