import React from "react";
import { ExpressButton } from "../../../core/expressButton/expressButton.component";
import { Popup } from "../../../core/popup/popup.component";
import "./newOrderFooterBtnGroup.css";
import parse from "html-react-parser";
import { ErrorInfo } from "../../../core/errorInfo/errorInfo.component";

type Props = {
  popUpStyles?: any;
  handleBackButton: any;
  errorPopupFlag: any;
  errorMessage: any;
  isSaveExitFlow?: boolean;
  handleExitButton: any;
  isSupportPhoneShow: boolean;
  isShortSentence?: boolean;
  errorTitle?: boolean;
  errorCode?: string;
};

const NewOrderErrorPopupMessage = ({
  popUpStyles = "popUpStyles",
  handleBackButton,
  errorPopupFlag,
  errorMessage,
  isSaveExitFlow,
  handleExitButton,
  isSupportPhoneShow = true,
  isShortSentence = false,
  errorTitle,
  errorCode,
}: Props) => {
  return (
    <Popup
      openFlag={errorPopupFlag}
      dialogParentClass={popUpStyles}
      closeHandler={handleBackButton}
    >
      <div style={{ padding: "16px", display: "grid" }}>
        {errorTitle ? (
          <div className="errorTitle-neworderpopupmsg">Error</div>
        ) : (
          <div className="errorTitle-neworderpopupmsg"></div>
        )}

        <span
          className={
            errorCode && errorCode !== ""
              ? "errorDetail-neworderpopupmsg errorCode-neworderpopupmsg"
              : "errorDetail-neworderpopupmsg"
          }
        >
          {parse(errorMessage)} <span> </span>
          {isShortSentence && <span> </span>}
          {!isShortSentence && (
            <span>
              {" "}
              <br></br>
            </span>
          )}
          {isSupportPhoneShow && (
            <a
              className="phone-value"
              data-testid="phone-value"
              href={`tel:+8002754524`}
            >
              (800) 275-4524 ext 41858.
            </a>
          )}
        </span>
        {errorCode && errorCode !== "" && <ErrorInfo errorCode={errorCode} />}

        <div className={isSaveExitFlow ? "btn-back" : ""}>
          <ExpressButton
            variant={isSaveExitFlow ? "outlined" : "contained"}
            clickHandler={handleBackButton}
            parentClass="deleteOrderDOptBtnNo"
          >
            {isSaveExitFlow ? "Back" : "Done"}
          </ExpressButton>
        </div>
        {isSaveExitFlow && (
          <ExpressButton
            parentClass="deleteOrderDYesBtn"
            variant="contained"
            clickHandler={() => handleExitButton()}
            testId="DeleteOrderTest"
          >
            Exit
          </ExpressButton>
        )}
      </div>
    </Popup>
  );
};

export default NewOrderErrorPopupMessage;
