import {
  IInputField,
  ValidationStatus,
} from "../../../../core/interfaces/input.interface";

export interface IReplacementInformationInterface {
  deliveryLocation: IInputField;
  isCallOnEstimatedArrivalTime: IInputField;
  firstName: IInputField;
  lastName: IInputField;
  phoneNumber: IInputField;
  department: IInputField;
  deliveryInstructions: IInputField;
}

export let defaultReplacementInformationData: IReplacementInformationInterface =
  {
    deliveryLocation: {
      componentId: "replacement-information-delivery-location",
      id: "replacement-information-delivery-location-input",
      order: 2,
      required: true,
      title: "Delivery Location",
      valid: ValidationStatus.UNTOUCHED,
      value: "",
    },
    isCallOnEstimatedArrivalTime: {
      componentId: "replacement-information-is-call-on-estimated-arrival-time",
      id: "replacement-information-is-call-on-estimated-arrival-time-input",
      required: true,
      order: 3,
      title: "Would you like a call with an estimated time of arrival?",
      valid: ValidationStatus.UNTOUCHED,
      value: "",
    },
    firstName: {
      componentId: "replacement-information-first-name",
      id: "replacement-information-first-name-input",
      order: 4,
      required: false,
      title: "Contact First Name",
      valid: ValidationStatus.UNTOUCHED,
      value: "",
    },
    lastName: {
      componentId: "replacement-information-request-last-name",
      id: "replacement-information-last-name-input",
      order: 5,
      required: false,
      title: "Contact Last Name",
      valid: ValidationStatus.UNTOUCHED,
      value: "",
    },
    department: {
      componentId: "replacement-information-request-department",
      id: "replacement-information-department-input",
      order: 6,
      required: false,
      title: "Department",
      valid: ValidationStatus.UNTOUCHED,
      value: "",
    },

    phoneNumber: {
      componentId: "replacement-information-phone-number",
      id: "replacement-information-phone-number-input",
      order: 7,
      required: false,
      title: "Phone number",
      valid: ValidationStatus.UNTOUCHED,
      value: "",
      isPhoneNumber: true,
    },
    deliveryInstructions: {
      componentId: "replacement-information-phone-number",
      id: "replacement-information-phone-number-input",
      order: 8,
      required: false,
      title: "Delivery Instruction",
      valid: ValidationStatus.UNTOUCHED,
      value: "",
    },
  };
