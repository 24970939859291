import "./createServiceRequest.css";
import {
  IDropdownValue,
  ServiceOptions,
} from "./serviceOptions/serviceOptions.component";
import { ICreateServiceRequest } from "../createServiceRequest.interface";
import { VacTherapyUnit } from "./vacTherapyUnit/vacTherapyUnit.component";
import { Navigator } from "../../../helpAndSupport/Navigator/navigator.component";
import { DeviceComplications } from "./deviceComplications/deviceComplications.component";
import { EstimatedArrivalTime } from "./estimatedArrivalTime/estimatedArrivalTime.component";
import { ICustomerPlusListingDetails } from "../../customerPlusInventory/customerPlusInventory.interface";
import { IRouter } from "../../../helpAndSupport/Navigator/navigator.interface";
import PickupLocation from "./pickupLocation/pickupLocation.component";
import { PatientDetails } from "../../../pickUpAndDischargeRequest/pickUpRequest/patientDetails/patientDetails.component";
import { IPatient } from "../../../myPatients/patient.interface";
import { CreateServiceRequestPageSection } from "../createServiceRequest.enum";

export interface ICreateServiceRequestProps {
  availableOptions: IDropdownValue[];
  data: ICreateServiceRequest;
  setAvailableOptions: Function;
  setData: Function;
  selectedProduct: ICustomerPlusListingDetails;
  navigators: IRouter[];
  storageLocations: string[];
  isSolventumOrder?: boolean;
  patientData?: IPatient;
  pageSection: CreateServiceRequestPageSection;
}
export const CreateServiceRequest = ({
  availableOptions,
  data,
  setAvailableOptions,
  setData,
  selectedProduct,
  navigators,
  storageLocations,
  isSolventumOrder = false,
  patientData,
  pageSection,
}: ICreateServiceRequestProps) => {
  return (
    <div className="create-service-request-component">
      <div className="short-form">
        <Navigator
          array={navigators}
          className="create-service-request-route-section"
          title="Create Service Request"
        />
        <p
          className="create-service-request-title"
          data-testid="create-service-request-title"
        >
          Create Service Request
        </p>
        <p
          className="create-service-request-description"
          data-testid="create-service-request-description"
        >
          {`3M provides 24/7 technical support by phone. If you are available to
          troubleshoot a product, please call `}
          <a
            className="phone-value"
            data-testid="phone-value"
            href={`tel:+8002754524`}
          >
            1-800-275-4524
          </a>
          {` option 3 before entering a service request. Troubleshooting
          information is also available for most products online.`}
        </p>
        {isSolventumOrder ? (
          <>
            <PatientDetails
              isAcuteOrderFlow={patientData!.isAcuteOrder ?? false}
              patient={patientData!}
              excangeVacFlow={true}
            />
            <PickupLocation
              data={data}
              setData={setData}
              storageLocations={storageLocations}
              pageSection={pageSection}
              isSolventumOrder={isSolventumOrder}
            />
          </>
        ) : (
          <VacTherapyUnit
            selectedProduct={selectedProduct}
            isSolventumOrder={isSolventumOrder}
          />
        )}
        <ServiceOptions
          availableOptions={availableOptions}
          data={data}
          setAvailableOptions={setAvailableOptions}
          setData={setData}
        />
        <EstimatedArrivalTime data={data} setData={setData} />
        <DeviceComplications data={data} setData={setData} />
      </div>
    </div>
  );
};
