import React from "react";
import "../productName/productName.css";
import { IInPatientSupplyOrderProduct } from "../../inpatientSupplyOrder.interface";

interface IProductName {
  productData: IInPatientSupplyOrderProduct;
  isSmallMobile: boolean;
  isComingFromReview?: boolean;
}

const ProductName: React.FC<IProductName> = ({
  productData,
  isSmallMobile,
}) => {
  return (
    <div className="product-name-section">
      {isSmallMobile ? (
        <>
          <span data-testid="product-name">{productData.productName}</span>
          <div className="product-sub-section">
            <span data-testid="product-part-code" className="product-part-code">
              # {productData.sku}
            </span>
            {productData.packageType && (
              <span
                data-testid="product-package-quantity"
                className="product-package-quantity"
              >
                {`(${productData.packageType} )`}
              </span>
            )}
          </div>
        </>
      ) : (
        <span data-testid="product-name">{productData.productName}</span>
      )}
    </div>
  );
};

export default ProductName;
