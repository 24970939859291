import "./siteStatusesBanners.css";
import { SiteStatusBanner } from "./siteStatusesBanner/siteStatusesBanner.component";
import { ISiteStatusesBannersProps } from "./siteStatusesBanners.interfaces";
import {
  SiteStatusesContext,
  SiteStatusesContextType,
} from "../../context/SiteStatusesContext";
import { useContext } from "react";

export const SiteStatusBanners = ({
  siteStatuses,
  handleCloseButtonAction,
}: ISiteStatusesBannersProps) => {
  const siteStatusesObj = useContext<SiteStatusesContextType | null>(
    SiteStatusesContext
  );

  const handleCloseButton = (index: number) => {
    if (siteStatusesObj) {
      let loginStatuses = [...siteStatusesObj.loginSiteStatues];
      loginStatuses.splice(index, 1);
      siteStatusesObj.setLoginSiteStatues(loginStatuses);
    }
  };

  return (
    <div
      className="site-status-banners-component"
      data-testid="site-status-banners-component"
    >
      {siteStatuses.map((siteStatus, index) => {
        return (
          <SiteStatusBanner
            siteStatus={siteStatus}
            index={index}
            handleCloseButton={
              handleCloseButtonAction
                ? handleCloseButtonAction
                : handleCloseButton
            }
          />
        );
      })}
    </div>
  );
};
