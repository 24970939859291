export const regexAlphaNumericHyphenSpace = /^[A-Za-z -\s]*$/;
export const emailValidationRegex = /^[a-zA-Z0-9+-._@]+$/;
export const emailFormatRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const alphaNumericSpaceRegex = /^[a-zA-Z0-9\s-]+$/i;
export const zipCodeRegex = /^[0-9]{5}$/;
export const regexAlphaNumericSpaceHyphen = /^[A-Za-z0-9 -\s]*$/;
export const regexAlphaNumericWithSpaceHypenAndApostrophe =
  /^[A-Za-z0-9 '\s-]*$/;
export const regexForRentalPONum = /^[a-zA-Z0-9\s\-@\/#:.(),]{0,30}$/;
