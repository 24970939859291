import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { ReactComponent as RadioButtonIcon } from "../../../assets/radioButton.svg";
import { ReactComponent as SelectedRadioButtonIcon } from "../../../assets/selectedRadioButton.svg";
import { InputWithLabel } from "../../../core/inputWithLabel/inputWithLabel.component";
import { ValidationStatus } from "../../../core/interfaces/input.interface";

type Props = {
  fieldData: any;
  fieldKey: string;
  label: string;
  onChange: Function;
};
export const ExchangeVacRadioGroup = ({
  fieldData,
  fieldKey,
  label,
  onChange,
}: Props) => {
  return (
    <InputWithLabel
      error={fieldData.valid === ValidationStatus.INVALID}
      isRequired={fieldData.required}
      label={label}
      labelClassName="device-info-header-title"
      testId={`device-information-exchange-vac-${fieldKey}`}
    >
      <RadioGroup
        name={fieldKey}
        classes={{ root: "radioRoot" }}
        onChange={(e) => onChange(e, fieldKey)}
        value={fieldData.value}
      >
        <FormControlLabel
          classes={{
            root: fieldData.value === "Y" ? "optionRoot-active" : "optionRoot",
          }}
          componentsProps={{
            typography: {
              classes: {
                root: fieldData.value === "Y" ? "optiontxtSelect" : "optiontxt",
              },
            },
          }}
          control={
            <Radio
              icon={<RadioButtonIcon />}
              checkedIcon={<SelectedRadioButtonIcon />}
              required={fieldData.required}
            />
          }
          data-testid={`device-information-exchange-vac-${fieldKey}-yes`}
          label="Yes"
          value="Y"
        />
        <FormControlLabel
          classes={{
            root: fieldData.value === "N" ? "optionRoot-active" : "optionRoot",
          }}
          componentsProps={{
            typography: {
              classes: {
                root: fieldData.value === "N" ? "optiontxtSelect" : "optiontxt",
              },
            },
          }}
          control={
            <Radio
              icon={<RadioButtonIcon />}
              checkedIcon={<SelectedRadioButtonIcon />}
              required={fieldData.required}
            />
          }
          data-testid={`device-information-exchange-vac-${fieldKey}-no`}
          label="No"
          value="N"
        />
        <FormControlLabel
          classes={{
            root: fieldData.value === "U" ? "optionRoot-active" : "optionRoot",
          }}
          componentsProps={{
            typography: {
              classes: {
                root: fieldData.value === "U" ? "optiontxtSelect" : "optiontxt",
              },
            },
          }}
          control={
            <Radio
              icon={<RadioButtonIcon />}
              checkedIcon={<SelectedRadioButtonIcon />}
              required={fieldData.required}
            />
          }
          data-testid={`device-information-exchange-vac-${fieldKey}-unknown`}
          label="Unknown"
          value="U"
        />
      </RadioGroup>
    </InputWithLabel>
  );
};
