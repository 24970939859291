import "./inpatientOrderSecondPage.css";
import { PlacementDate } from "../components/placementDate/placementDate.component";
import { IInpatientOrderSecondPageProps } from "./inpatientOrderSecondPage.interface";
import { PatientAndProductDetail } from "../components/patientAndProductDetail/patientAndProductDetail.component";
import { ReplacementInformation } from "../components/replacementInformation/replacementInformation.component";
import { BillingInformation } from "../components/billingInformation/billingInformation.component";
import { DeliveryPreference } from "../components/deliveryPreference/deliveryPreference.component";

export const InpatientOrderSecondPage = ({
  data,
  patient,
  product,
  setData,
  isFromSolventum = false,
  isSelectionSuppliesFlow=false
}: IInpatientOrderSecondPageProps) => {
  return (
    <div className="in-patient-order-second-page-component">
      <div className="short-form">
        <PatientAndProductDetail patient={patient} product={product} />
        {isFromSolventum ? (
          <DeliveryPreference data={data} setData={setData} />
        ) : (
          <>
            <PlacementDate data={data} setData={setData} />
            <ReplacementInformation data={data} setData={setData} />
          </>
        )}
        <BillingInformation
          data={data}
          setData={setData}
          billingInformation={data.billingInformation.value}
          isSelectionSuppliesFlow={isSelectionSuppliesFlow}
        />
      </div>
    </div>
  );
};
