export enum InpatientOrderPageSection {
  FIRST_PAGE = "firstPage",
  SECOND_PAGE = "secondPage",
  REVIEW_PAGE = "reviewPage",
  SUMMARY_PAGE = "summaryPage",
  SUPPLY_PAGE = "supplyPage",
}

export const InpatientOrderSubmission = {
  SOURCE: {
    CUSTOMER_PLUS: "4",
  },
  TYPE: {
    VAC_ORDER: "1",
    SUPPLY_ORDER: "2",
    STORED_PRODUCT: "3",
    CUSTOMER_PLUS: "4",
    SOLVENTUM_ORDER: "5",
  },
} as const;
