import "./peelAndPlace.css";
import parse from "html-react-parser";
import Skeleton from "react-loading-skeleton";
import InfoIcon from "@mui/icons-material/Info";

export interface IPeelAndPlace {
  message: string;
  linkText: string;
  link: string;
  image: string;
}

type Props = {
  instruction?: IPeelAndPlace | null;
  note?: IPeelAndPlace | null;
  clickableLinkAction?: Function;
};

export const PeelAndPlace = ({
  instruction,
  note,
  clickableLinkAction,
}: Props) => {
  const openLink = (url: string) => {
    window.open(url);
  };

  return (
    <div
      className="peel-and-place-dressing-component"
      data-testid="peel-and-place-dressing-component"
    >
      {instruction ? (
        <>
          <div
            className="peel-and-place-dressing"
            data-testid="peel-and-place-dressing"
          >
            <InfoIcon className="warning-icon" data-testid="warning-icon" />
            <p
              className="peel-and-place-message"
              data-testid="peel-and-place-message"
            >
              {parse(instruction.message)}
              <span> </span>
              <span
                className="peel-and-place-message-link-text"
                data-testid="peel-and-place-message-link-text"
                onClick={() =>
                  clickableLinkAction
                    ? clickableLinkAction()
                    : openLink(instruction.link)
                }
              >
                {parse(instruction.linkText)}
              </span>
            </p>
          </div>
          <div className="peel-and-place-instruction-div">
            <img
              aria-hidden="true"
              alt={instruction.image}
              className="peel-and-place-instruction-image"
              data-testid={`peel-and-place-instruction-image`}
              src={instruction.image}
            />
          </div>
        </>
      ) : note ? (
        <div
          className="peel-and-place-dressing warning"
          data-testid="peel-and-place-dressing-warning"
        >
          <InfoIcon
            className="warning-icon red"
            data-testid="warning-icon-red"
          />
          <p
            className="peel-and-place-message red"
            data-testid="peel-and-place-message-red"
          >
            {parse(note.message)}
            <span> </span>
            <span
              className="peel-and-place-message-link-text"
              data-testid="peel-and-place-message-link-text"
              onClick={() =>
                clickableLinkAction
                  ? clickableLinkAction()
                  : openLink(note.link)
              }
            >
              {parse(note.linkText)}
            </span>
          </p>
        </div>
      ) : (
        <div
          className="peel-and-place-skeleton-loader-div"
          data-testid="peel-and-place-skeleton-loader-div"
        >
          <Skeleton className="peel-and-place-skeleton-loader" />
          <Skeleton className="peel-and-place-skeleton-loader" />
          <Skeleton className="peel-and-place-skeleton-loader" />
        </div>
      )}
    </div>
  );
};
