import moment from "moment";
import { ReactNode } from "react";
import "./woundAssessmentReportTable.css";
import { Circle } from "@mui/icons-material";
import {
  makeCapitalEachWordInString,
  statusColor,
} from "../../../../../util/utilityFunctions";
import Skeleton from "react-loading-skeleton";
import { Grid, useMediaQuery } from "@mui/material";
import { IoMdArrowDropleft, IoMdArrowDropright } from "react-icons/io";
import Table from "../../../../../core/customSortingTable/table.component";
import { IWoundAssessmentReport } from "../../woundAssessmentReport.interface";
import { ReactComponent as Attachment } from "../../../../../assets/attachment.svg";
import { IWoundAssessmentReportTable } from "./woundAssessmentReportTable.interface";
import { ExpressButton } from "../../../../../core/expressButton/expressButton.component";
import { CustomDropDown } from "../../../../../core/customDropdown/customDropdown.component";

export const WoundAssessmentReportTable = ({
  allPageNumbers,
  handleNextClick,
  handlePageNumberClick,
  handlePreviousClick,
  handleSelectChange,
  handleSorting,
  isLoading,
  openAssessment,
  openOrderOverview,
  numberOfPages,
  perPageCount,
  reports,
  selectedPageNumber,
  totalReportsCount,
}: IWoundAssessmentReportTable) => {
  const isMobileScreen = useMediaQuery("(max-width:600px)");

  const isPreviousDisabled = selectedPageNumber === 1;
  const isNextDisabled =
    selectedPageNumber === numberOfPages.length || numberOfPages.length === 0;
  // Table
  const columns = [
    { label: "Record ID", accessor: 1, sortable: true },
    { label: "RO #", accessor: 2, sortable: true },
    { label: "Therapy Status", accessor: 3, sortable: false },
    { label: "Patient Name", accessor: 4, sortable: true },
    { label: "Patient ID", accessor: 5, sortable: true },
    { label: "Facility Name", accessor: 6, sortable: true },
    { label: "Facility Acct #", accessor: 7, sortable: true },
    { label: "Submitter", accessor: 8, sortable: true },
    { label: "Scan/Docs", accessor: 9, sortable: true },
    { label: "Assessment Type", accessor: 10, sortable: true },
    { label: "Assessment Range", accessor: 11, sortable: true },
    { label: "Created On", accessor: 12, sortable: true },
    { label: "Assessment", accessor: 13, sortable: false },
    { label: "FileNet upload", accessor: 14, sortable: true },
  ];

  const getTherapyStatus = (therapyStatus: string): ReactNode => {
    return (
      <div className="therapy-status-detail">
        <Circle className={`statusIconMain ${statusColor(therapyStatus)}`} />
        <p className="order-status">{therapyStatus}</p>
      </div>
    );
  };

  return (
    <div
      className="wound-assessment-report-table-component"
      data-testid="wound-assessment-report-table-component"
    >
      <div
        className="wound-assessment-report-table-container"
        data-testid="wound-assessment-report-table-container"
      >
        <Table
          tableClassName="wound-assessment-report-table"
          tableColumns={columns}
          handleSorting={handleSorting}
        >
          {isLoading ? (
            [...Array(5)].map((_, index) => (
              <tr key={index}>
                {columns.map((_, columnIndex) => (
                  <td
                    key={columnIndex}
                    className="skeleton"
                    data-testid={`skeleton-${
                      (index + 1000) * (columnIndex + 1)
                    }`}
                  >
                    <Skeleton />
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tbody>
              {columns && reports && reports.length > 0 ? (
                <>
                  {reports.map(
                    (data: IWoundAssessmentReport, index: number) => {
                      return (
                        <tr className="wound-assessment-report-row">
                          <td
                            className="wound-assessment-report-basic-detail"
                            data-testid={`wound-assessment-report-record-id-${
                              index + 1
                            }`}
                          >
                            {data.recordID}
                          </td>
                          <td
                            className="wound-assessment-report-ro-number"
                            data-testid={`wound-assessment-report-ro-number-${
                              index + 1
                            }`}
                            onClick={() => {
                              openOrderOverview(data);
                            }}
                          >
                            {data.roNumber}
                          </td>
                          <td
                            className="wound-assessment-report-therapy-status"
                            data-testid={`wound-assessment-report-therapy-status-${
                              index + 1
                            }`}
                          >
                            {getTherapyStatus(data.therapyStatus)}
                          </td>
                          <td
                            className="wound-assessment-report-basic-detail"
                            data-testid={`wound-assessment-report-patient-name-${
                              index + 1
                            }`}
                          >
                            {makeCapitalEachWordInString(data.patientName)}
                          </td>
                          <td
                            className="wound-assessment-report-basic-detail"
                            data-testid={`wound-assessment-report-patient-id-${
                              index + 1
                            }`}
                          >
                            {data.patientID ?? "--"}
                          </td>
                          <td
                            className="wound-assessment-report-basic-detail"
                            data-testid={`wound-assessment-report-facility-name-${
                              index + 1
                            }`}
                          >
                            {data.facilityName
                              ? makeCapitalEachWordInString(data.facilityName)
                              : "--"}
                          </td>
                          <td
                            className="wound-assessment-report-basic-detail"
                            data-testid={`wound-assessment-report-facility-account-${
                              index + 1
                            }`}
                          >
                            {data.facilityAccountNumber ?? "--"}
                          </td>
                          <td
                            className="wound-assessment-report-basic-detail"
                            data-testid={`wound-assessment-report-submitter-${
                              index + 1
                            }`}
                          >
                            {data.submitter
                              ? makeCapitalEachWordInString(data.submitter)
                              : "--"}
                          </td>
                          <td
                            className="wound-assessment-report-basic-detail"
                            data-testid={`wound-assessment-report-wound-document-${
                              index + 1
                            }`}
                          >
                            {data.woundDocScan}
                          </td>
                          <td
                            className="wound-assessment-report-basic-detail"
                            data-testid={`wound-assessment-report-assessment-type-${
                              index + 1
                            }`}
                          >
                            {data.assessmentType ?? "--"}
                          </td>
                          <td
                            className="wound-assessment-report-assessment-range"
                            data-testid={`wound-assessment-report-assessment-range-${
                              index + 1
                            }`}
                          >
                            {`${
                              data.cycleStartDate && data.cycleStartDate !== ""
                                ? moment(data.cycleStartDate).format("MM/DD/YY")
                                : "-"
                            } - ${
                              data.cycleStartDate && data.cycleStartDate !== ""
                                ? moment(data.cycleEndDate).format("MM/DD/YY")
                                : "-"
                            }`}
                          </td>
                          <td
                            className="wound-assessment-report-basic-detail"
                            data-testid={`wound-assessment-report-created-date-${
                              index + 1
                            }`}
                          >
                            {moment(data.assessmentCreatedOn).format(
                              "MM/DD/YY"
                            )}
                          </td>
                          <td
                            className="wound-assessment-report-assessment"
                            data-testid={`wound-assessment-report-assessment-${
                              index + 1
                            }`}
                          >
                            <div
                              className="attachment-detail"
                              onClick={() => {
                                openAssessment(data.recordID);
                              }}
                            >
                              <Attachment />
                            </div>
                          </td>
                          <td
                            className={`wound-assessment-report-basic-detail ${
                              data.fileNetUpload?.toLowerCase() === "failed"
                                ? "failed"
                                : ""
                            }`}
                            data-testid={`wound-assessment-report-filenet-upload-${
                              index + 1
                            }`}
                          >
                            {data.fileNetUpload}
                          </td>
                        </tr>
                      );
                    }
                  )}
                </>
              ) : (
                <tr>
                  <td colSpan={columns.length}>
                    <div className="no-data">
                      <p className="no-data-message">No Data Avaialble</p>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          )}
        </Table>
      </div>
      <Grid className="pagination-grid-container" container>
        <Grid
          className="pagination-grid-item"
          item
          order={{ xs: isMobileScreen ? 2 : 1 }}
          xs={isMobileScreen ? 7 : 4}
        >
          <div className="items-per-page-dropdown" data-testid="itemsPerPage">
            <p
              className="items-per-page-title"
              data-testid="items-per-page-title"
            >
              Items per page:
            </p>
            <CustomDropDown
              value={`${perPageCount}`}
              handleChange={handleSelectChange}
              menuItem={["25", "50", "75", "100"]}
              selectClassName="items-per-page-input"
              selectpropsClassName="items-per-page-select"
            />
          </div>
        </Grid>
        <Grid
          className="pagination-grid-item"
          item
          order={{ xs: isMobileScreen ? 1 : 2 }}
          xs={isMobileScreen ? 12 : 4}
        >
          <div className="pagination-number-list">
            <ExpressButton
              clickHandler={(e: any) => handlePreviousClick(e)}
              disabled={isPreviousDisabled}
              variant="text"
              parentClass={`${isPreviousDisabled ? "disabled" : ""}`}
              testId="previous-button"
            >
              <IoMdArrowDropleft size={20} />
            </ExpressButton>
            {allPageNumbers &&
              allPageNumbers.map((item) => {
                return (
                  <li
                    key={item}
                    className={`${
                      selectedPageNumber === item ? "active" : ""
                    } list`}
                    onClick={() => handlePageNumberClick(item)}
                  >
                    {item}
                  </li>
                );
              })}
            <ExpressButton
              clickHandler={(e: any) => handleNextClick(e)}
              disabled={isNextDisabled}
              id="buttonClass"
              parentClass={`${isNextDisabled ? "disabled" : ""}`}
              testId="next-button"
              variant="text"
            >
              <IoMdArrowDropright size={20} />
            </ExpressButton>
          </div>
        </Grid>
        <Grid
          className="pagination-grid-item"
          item
          order={{ xs: 3 }}
          xs={isMobileScreen ? 5 : 4}
        >
          <div
            className="pagination-total-counts"
            data-testid="pagination-total-counts"
          >
            <p
              className="last-section-right"
              data-testid="last-section-right"
            >{`${
              totalReportsCount === 0
                ? 0
                : (selectedPageNumber - 1) * perPageCount + 1
            }-${
              selectedPageNumber * perPageCount < totalReportsCount
                ? selectedPageNumber * perPageCount
                : totalReportsCount
            } of ${totalReportsCount} items`}</p>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
