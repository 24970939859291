import { Validation, ValidationStatus } from "../../../../../core/interfaces/input.interface";
import { getDeepClone } from "../../../../../util/ObjectFunctions";
import { Validator } from "../../../../../util/order.validations";
import { getValidObj } from "../../../../../util/utilityFunctions";

export class MakeUnitAvailableValidator {
    private _validator;
    constructor(defValidator = new Validator()) {
      this._validator = defValidator;
    }
  
    private noValidation(txt: string): Validation {
      return getValidObj();
    }
  
    private fieldToMethodMapping(
      field: string
    ): ((txt: string) => Validation) | undefined {
      const mapping = new Map<string, (txt: string) => Validation>([
        // Patient information
        ["dateAvailableForPlacement", this._validator.availableForplacmentDateValidation],
        ["storageLocations", this._validator.emptyCheck],
      ]);
      const validator = mapping.get(field);
      return validator ? validator : this.noValidation;
    }
  
    public validate(input: string, field: string) {
      try {
        const validator = this.fieldToMethodMapping(field)!;
        return validator(input);
      } catch (error) {
        console.log(`validator method for field ${field} is not configured`);
      }
    }
   
    public validateAll(data : any, setData : any) {
        const tempObj = getDeepClone(data)
          Object.keys(tempObj).forEach((x : string) => {
            if (tempObj[x].valid === ValidationStatus.UNTOUCHED) {
                tempObj[x].valid = ValidationStatus.INVALID;
              }
          })
        setData(tempObj);
        const ifAllValid = Object.keys(tempObj)
        .filter((x) => tempObj[x].valid)
        .every((x: string) => tempObj[x].valid === ValidationStatus.VALID);
      return ifAllValid ; 
  }
}
  