import { Grid } from "@mui/material";
import { IBillingInformation } from "../billingInformation.interface";
import "./billingInformationReview.css";

import { MobileDisplayContext } from "../../../../../context/MobileDisplayContext";
import { useContext } from "react";
import { InpatientOrderPageSection } from "../../../inpatientOrder.enum";

interface Props {
  data: IBillingInformation;
  editButtonAction: any;
  isSummary: boolean;
  isSelectionSuppliesFlow: boolean;
}

export const BillingInformationReview = ({
  data,
  editButtonAction,
  isSummary = false,
  isSelectionSuppliesFlow = false,
}: Props) => {
  const { isMobileScreen } = useContext(MobileDisplayContext);

  const showTitleAndValue = (
    title: string,
    value: string | null,
    testId: string
  ) => {
    return (
      <div
        className="show-title-and-value-div"
        id="id_show-title-and-value-div"
      >
        <p
          className="show-title-and-value-title"
          data-testid={`${testId}-title`}
          id={`${testId}-title-id`}
        >
          {title}
        </p>
        <p
          className="show-title-and-value-value"
          data-testid={`${testId}-value`}
          id={`${testId}-value-id`}
        >
          {value && value !== "" ? value.trim() : "--"}
        </p>
      </div>
    );
  };

  return (
    <div
      className="billing-information-review-component"
      data-testid="billing-information-review-component"
      id="id_billing-information-review-component"
    >
      <div
        className="billing-information-review-header"
        id="id_billing-information-review-header"
      >
        <p
          className="billing-information-review-title"
          data-testid="billing-information-review-title"
          id="billing-information-review-title-id"
        >
          Billing Information
        </p>
        {!isSummary && (
          <button
            className="billing-information-review-edit-button"
            data-testid="billing-information-review-edit-button"
            id="billing-information-review-edit-button-id"
            onClick={() =>
              editButtonAction(
                "in-patient-order-billing-information-component",
                InpatientOrderPageSection.SECOND_PAGE
              )
            }
          >
            Edit
          </button>
        )}
      </div>
      <Grid container id="ipatient-order-billing-info-review-id">
        {isSelectionSuppliesFlow && (
          <Grid
            className="sales-information-review-grid-item"
            id="sales-information-review-grid-item"
            item
            xs={isMobileScreen ? 6 : 4}
          >
            {showTitleAndValue(
              "Sales Purchase Order #",
              data.salesPONumber.value,
              "Sales-Purchase-Order-#"
            )}
          </Grid>
        )}
        <Grid
          className="billing-information-review-grid-item"
          id="id_billing-information-review-grid-item"
          item
          xs={isMobileScreen ? 6 : 4}
        >
          {showTitleAndValue(
            "Purchase Order #",
            data.rentalPONumber.value,
            "rental-po-number"
          )}
        </Grid>
      </Grid>
    </div>
  );
};
