import {
  IInpatientOrder,
  IInpatientOrderSecondPage,
} from "./../inpatientOrder.interface";
import { ValidationStatus } from "../../../core/interfaces/input.interface";
import { IWoundInformation } from "../components/woundInformation/woundInformation.interface";
import { IPrescriberInformation } from "../components/prescriberInformation/prescriberInformation.interface";
import { mockPatientInformationData } from "../components/patientInformation/__test__/patientInformation.test.data";
import { defaultBillingInformationData } from "../components/billingInformation/billingInformation.model";
import { defaultReplacementInformationData } from "../components/replacementInformation/replacementInformation.interface";
import { defaultDevicePlacementInformation } from "../components/devicePlacement/devicePlacement.model";
import { PlacementTypeSelection } from "../components/devicePlacement/devicePlacemet.enums";
import { defaultDeliveryPreferenceData } from "../components/deliveryPreference/deliveryPreference.model";

export let defaultPrescriberInformationdata: IPrescriberInformation = {
  firstName: "",
  lastName: "",
  npi: "",
  city: "",
  state: "",
  telephoneNumber: "",
  zipCode: "",
  address1: "",
  address2: "",
  emails: [],
};

const defaultWoundData: IWoundInformation = {
  woundInfoCount: {
    isDefaultValid: true,
    required: false,
    title: "",
    valid: ValidationStatus.VALID,
    value: "0",
  },
  primaryWoundInformation: {
    woundLocation: {
      valid: ValidationStatus.UNTOUCHED,
      title: "Wound Location",
      required: true,
      value: "",
    },
    woundType: {
      valid: ValidationStatus.UNTOUCHED,
      title: "Primary Wound Type",
      required: true,
      value: "",
    },
    woundDirection: {
      valid: ValidationStatus.UNTOUCHED,
      title: "Wound Direction",
      required: false,
      isOptional: true,
      value: "",
    },
    woundOrientation: {
      valid: ValidationStatus.UNTOUCHED,
      title: "Wound Orientation",
      required: false,
      isOptional: true,
      value: "",
    },
    woundMeasurementDate: {
      valid: ValidationStatus.UNTOUCHED,
      title: "Wound Measurment Date",
      required: false,
      isOptional: true,
      value: "",
    },
    woundLength: {
      valid: ValidationStatus.UNTOUCHED,
      title: "Length",
      required: false,
      isOptional: true,
      value: "0",
    },
    woundWidth: {
      valid: ValidationStatus.UNTOUCHED,
      title: "Width",
      required: false,
      isOptional: true,
      value: "0",
    },
    woundDepth: {
      valid: ValidationStatus.UNTOUCHED,
      title: "Depth",
      required: false,
      isOptional: true,
      value: "0",
    },
    woundVolume: {
      valid: ValidationStatus.VALID,
      title: "Volume",
      required: false,
      isOptional: true,
      value: "",
    },
  },
  secondaryWoundInformation: {
    woundLocation: {
      valid: ValidationStatus.UNTOUCHED,
      title: "Wound Location",
      required: true,
      value: "",
    },
    woundType: {
      valid: ValidationStatus.UNTOUCHED,
      title: "Secondary Wound Type",
      required: true,
      value: "",
    },
    woundDirection: {
      valid: ValidationStatus.UNTOUCHED,
      title: "Wound Direction",
      required: false,
      isOptional: true,
      value: "",
    },
    woundOrientation: {
      valid: ValidationStatus.UNTOUCHED,
      title: "Wound Orientation",
      required: false,
      isOptional: true,
      value: "",
    },
    woundMeasurementDate: {
      valid: ValidationStatus.UNTOUCHED,
      title: "Wound Measurment Date",
      required: false,
      isOptional: true,
      value: "",
    },
    woundLength: {
      valid: ValidationStatus.UNTOUCHED,
      title: "Length",
      required: false,
      isOptional: true,
      value: "0",
    },
    woundWidth: {
      valid: ValidationStatus.UNTOUCHED,
      title: "Width",
      required: false,
      isOptional: true,
      value: "0",
    },
    woundDepth: {
      valid: ValidationStatus.UNTOUCHED,
      title: "Depth",
      required: false,
      isOptional: true,
      value: "0",
    },
    woundVolume: {
      valid: ValidationStatus.VALID,
      title: "Volume",
      required: false,
      isOptional: true,
      value: "",
    },
  },
};

export let mockInpatientOrderData: IInpatientOrder = {
  patientInformation: {
    hasChildsToValidate: true,
    required: true,
    valid: ValidationStatus.UNTOUCHED,
    value: mockPatientInformationData,
  },
  prescriberInformation: {
    componentId: "in-patient-order-prescriber-information",
    hasChildsToValidate: false,
    order: 6,
    required: false,
    title: "",
    valid: ValidationStatus.VALID,
    value: null,
  },
  devicePlacement: {
    hasChildsToValidate: true,
    required: true,
    valid: ValidationStatus.UNTOUCHED,
    value: defaultDevicePlacementInformation,
  },
  therapyStartDate: {
    componentId: "in-patient-order-therapy-start-date",
    id: "in-patient-order-therapy-start-date-input",
    order: 8,
    required: false,
    title: "Therapy Start Date",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  billingAddress: {
    componentId: "in-patient-order-billing-address",
    id: "in-patient-order-billing-address-input",
    order: 8,
    required: false,
    title: "With which bill-to address should this order be associated?",
    valid: ValidationStatus.UNTOUCHED,
    value: null,
  },
  patientWoundInformation: {
    hasChildsToValidate: true,
    required: true,
    valid: ValidationStatus.UNTOUCHED,
    value: defaultWoundData,
  },
  orderWithSolventum: {
    componentId: "inpatient-product-selection-main-div",
    id: "inpatient-product-selection-main-div",
    order: 9,
    required: false,
    title: "Which product will you be placing?",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  selectionSupplies: {
    componentId: "inpatient-product-selection-supplies",
    order: 12,
    required: false,
    valid: ValidationStatus.UNTOUCHED,
    value: null,
    title: "Will you be ordering supplies with this order?",
    isShowWarning: false,
  },
};

export let mockInpatientOrderSecondPageData: IInpatientOrderSecondPage = {
  deliveryPreference: {
    componentId: "in-patient-order-delivery-preference",
    id: "in-patient-order-delivery-preference",
    isDefaultValid: true,
    order: 13,
    title: "Delivery Preference",
    valid: ValidationStatus.VALID,
    value: defaultDeliveryPreferenceData,
  },
  placementDate: {
    componentId: "in-patient-order-placement-date",
    id: "in-patient-order-placement-date-input",
    order: 1,
    required: true,
    title: "Placement Start Date",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  replacementInformation: {
    componentId: "in-patient-order-repalcement-information",
    id: "in-patient-order-repalcement-information-input",
    order: 2,
    required: true,
    title: "Replacement Information",
    valid: ValidationStatus.UNTOUCHED,
    value: defaultReplacementInformationData,
  },
  billingInformation: {
    componentId: "in-patient-order-Billing-information",
    id: "in-patient-order-Billing-information",
    isDefaultValid: true,
    order: 20,
    title: "Billing Information",
    valid: ValidationStatus.VALID,
    value: defaultBillingInformationData,
  },
};

export const getMockInPatientOrderData = (
  placementType: PlacementTypeSelection
): IInpatientOrder => {
  return {
    ...mockInpatientOrderData,
    devicePlacement: {
      ...mockInpatientOrderData.devicePlacement,
      value: {
        ...mockInpatientOrderData.devicePlacement.value,
        placementType: {
          ...mockInpatientOrderData.devicePlacement.value.placementType,
          value: placementType,
        },
      },
    },
  };
};
