import InPatientIcon from "../../../assets/inPatientIcon.svg";
import { IOrdersMenuBarSupplyOrder } from "./ordersMenuBar.interface";
import PostAcuteOrderIcon from "../../../assets/homeOrderIcon.svg";
import StockIcon from "../../../assets/stockSupplies.svg";

export const ordersMenuBarNewOrderModel: IOrdersMenuBarSupplyOrder[] = [
  {
    labelText: "In-Patient Order",
    path: "/orders/inpatientOrder",
    icon: InPatientIcon,
    accessorKey: "IsShowInPatientOrderButton",
    isPostAcute: false,
  },
  {
    labelText: "Home Order",
    path: "/orders/newOrder",
    icon: PostAcuteOrderIcon,
    accessorKey: "IsShowVacOrderButton",
    isPostAcute: false,
  },
];

export const ordersMenuBarSupplyOrderModel: IOrdersMenuBarSupplyOrder[] = [
  {
    labelText: "Stock Supplies",
    path: "/orders/inpatientStockSupplyOrder",
    icon: StockIcon,
    accessorKey: "IsShowInPatientStockSupplyOrderButton",
    isPostAcute: false,
  },
  {
    labelText: "In-Patient Supplies",
    path: "/orders/inpatientSupplyOrder",
    icon: InPatientIcon,
    accessorKey: "IsShowInPatientSupplyOrderButton",
    isPostAcute: false,
  },
  {
    labelText: "For Home Patients",
    path: "/orders/supplyOrderList",
    icon: PostAcuteOrderIcon,
    accessorKey: "IsShowSupplyOrderButton",
    isPostAcute: true,
  },
];
