import "./changeEPrescription.css";
import React, { useContext, useState } from "react";
import { Button, IconButton, Typography, useMediaQuery } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import RedCheck from "../../../../../../assets/redCheck.svg";
import { NewOrderValidator } from "../../../../../newOrder/newOrder.validator";
import {
  IInputField,
  ValidationStatus,
} from "../../../../../../core/interfaces/input.interface";
import { ReactComponent as CloseIcon } from "../../../../../../assets/popupcloseicon.svg";
import { ReactComponent as BlueIndicator } from "../../../../../../assets/BlueIndicator.svg";
import { ReactComponent as YellowWarning } from "../../../../../../assets/WarningYellow.svg";
import { ExpressButton } from "../../../../../../core/expressButton/expressButton.component";
import { InputWithLabel } from "../../../../../../core/inputWithLabel/inputWithLabel.component";
import {
  IPrescriberDetailInterface,
  IUpadtePrescriberEmailReqBody,
} from "../../missingRxEPrescription/prescriberDetail.interface";
import { makeCapitalEachWordInString } from "../../../../../../util/utilityFunctions";
import { MissingRxSection } from "../../missingRx.enum";
import {
  RolesPermissionContext,
  RolesPermissionContextType,
} from "../../../../../../context/RolesPermissionContext";
import { IPatient } from "../../../../patient.interface";
import { updatePrescriberEmailInfo } from "../../../../../../util/3meService";
import { ERROR_MSG_UPDATE_PRESCRIBER_EMAIL } from "../../../../../../util/errorMsg";
import { IErrorObj } from "../../missingRx.component";

type Props = {
  changePopUpSection: Function;
  closeEmailUpdateClicked?: any;
  editEmailClicked?: any;
  editPrescriberMode: boolean;
  isComingFromChangePrescription?: boolean;
  prescriberDetails: IPrescriberDetailInterface;
  resendPrescriptionClicked?: any;
  showErrorMessage: boolean;
  setPrescriberDetails: React.Dispatch<
    React.SetStateAction<IPrescriberDetailInterface>
  >;
  setEditPrescriberMode: Function;
  updatePrescriptionClicked?: any;
  patientData?: IPatient;
  closePopUp?: Function;
  errorObj?: IErrorObj;
  setErrorObj?: Function;
};

const ChangeEPrescription = ({
  prescriberDetails,
  setPrescriberDetails,
  editPrescriberMode,
  setEditPrescriberMode,
  editEmailClicked = null,
  resendPrescriptionClicked = null,
  updatePrescriptionClicked = null,
  closeEmailUpdateClicked = null,
  changePopUpSection,
  isComingFromChangePrescription = false,
  showErrorMessage,
  patientData,
  closePopUp,
  setErrorObj,
}: Props) => {
  const [previousEmail, setPreviousEmail] = useState<string>("");
  const [isSameEmail, setIsSameEmail] = useState<boolean>(false);
  const isMobileScreen = useMediaQuery("(max-width:600px)");
  const [validator] = useState<NewOrderValidator>(new NewOrderValidator()!);
  const [backedUpEmailID, setBackedUpEmailID] = useState<IInputField>();
  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );

  const editEmailAction = () => {
    setEditPrescriberMode(true);
    setBackedUpEmailID(prescriberDetails.updatedPrescriberEmail);
    setPreviousEmail(prescriberDetails.updatedPrescriberEmail.value);
  };

  const resendPrescriptionAction = () => {
    setEditPrescriberMode(false);
  };

  const updatePrescriptionAction = async () => {
    const updatedPrescriberEmailReqBody: IUpadtePrescriberEmailReqBody = {
      ron: patientData?.roNumber?.toString()!,
      email: prescriberDetails.updatedPrescriberEmail.value,
      npi: prescriberDetails?.npi!.value.toString(),
      doctorName: prescriberDetails.prescriberName.value,
    };
    changePopUpSection(MissingRxSection.MISSING_RX_LOADER);
    let updateEmailResponse = await updatePrescriberEmailInfo(
      updatedPrescriberEmailReqBody
    );
    changePopUpSection(MissingRxSection.E_PRESBRIBER);
    if (updateEmailResponse && updateEmailResponse?.succeeded) {
      setEditPrescriberMode(false);
      changePopUpSection(MissingRxSection.E_PRESBRIBER_SENT);
    } else {
      let errorObj: IErrorObj = {
        errorFlag: true,
        errorCode:
          updateEmailResponse?.error?.errorCode || updateEmailResponse?.status,
        errorMessage: ERROR_MSG_UPDATE_PRESCRIBER_EMAIL,
      };
      setErrorObj!(errorObj);
    }
  };

  const closeEmailUpdateAction = () => {
    setEditPrescriberMode(false);
    setIsSameEmail(false);
    prescriberDetails.updatedPrescriberEmail.value = backedUpEmailID!.value;
    prescriberDetails.updatedPrescriberEmail.valid = backedUpEmailID!.valid;
  };

  const ValidateAndSetData = (e: any) => {
    setIsSameEmail(false);
    const isValid = validator.validate(e.target.value, e.target.name);
    setPrescriberDetails(
      Object.assign({}, prescriberDetails, {
        [e.target.name]: {
          value: e.target.value,
          valid: isValid!.status,
        },
      })
    );
  };

  const handleBlurForPrevEmailCheck = (e: any) => {
    if (
      prescriberDetails.updatedPrescriberEmail.value.toLowerCase() ===
        previousEmail.toLowerCase() &&
      previousEmail !== ""
    ) {
      setIsSameEmail(true);
    } else setIsSameEmail(false);
  };

  const prescriberReviewMode = () => {
    return (
      <div className="ct-prescriberBox" data-testid="prescriber-review-mode">
        <div>{showErrorMessage && showError()}</div>
        <div className="ct-prescriberBox-detail">
          <div className="ct-prescriber-name">
            <div
              className="ct-headerdetail"
              data-testid="prescriber-name-title"
            >
              Prescriber Name
            </div>
            <div
              className="ct-prescriberValues"
              data-testid="prescriber-name-value"
            >
              {prescriberDetails.prescriberName.value !== ""
                ? makeCapitalEachWordInString(
                    prescriberDetails.prescriberName.value
                  )
                : "--"}
            </div>
          </div>
          <div className="ct-prescriber-email">
            <div
              className="ct-headerdetail"
              data-testid="prescriber-email-title"
            >
              Prescriber Email Address
            </div>
            <div
              className="ct-prescriberValues email"
              data-testid="prescriber-email-value"
            >
              {prescriberDetails.updatedPrescriberEmail.value &&
              prescriberDetails.updatedPrescriberEmail.value !== ""
                ? prescriberDetails.updatedPrescriberEmail.value
                : "--"}
            </div>
          </div>
          {!showErrorMessage && (
            <div className="ct-prescriber-buttons">
              <Button
                className="ct-editPrescriber"
                onClick={editEmailClicked ? editEmailClicked : editEmailAction}
                disabled={
                  permissionObj?.mappedRolesPermissionData.IsSupportRole
                }
                data-testid={"prescriber-edit-button"}
              >
                edit
              </Button>
              <ExpressButton
                clickHandler={updatePrescriptionAction}
                disabled={
                  permissionObj?.mappedRolesPermissionData.IsSupportRole
                }
                parentClass="resendButton"
                testId="prescriber-resend-button"
                variant="contained"
              >
                resend
              </ExpressButton>
            </div>
          )}
        </div>
      </div>
    );
  };
  const checkForEmailSameAsPrev = () => {
    let isEnabled: boolean = false;
    if (
      prescriberDetails.updatedPrescriberEmail.value.toLowerCase() ===
        previousEmail.toLowerCase() &&
      previousEmail !== ""
    ) {
      isEnabled = true;
    } else isEnabled = false;
    return isEnabled;
  };

  const EPrescriptionInformationText = () => {
    return (
      <div
        className="ct-information-indicator-div"
        data-testid="ct-information-indicator-div"
      >
        <div className="ct-information-indicator">
          <div>
            <BlueIndicator />
          </div>
          <Typography
            className="ct-information-text"
            data-testid="ct-information-text"
          >
            When changing an email address, ensure that it belongs to the same
            prescriber as listed.
          </Typography>
        </div>
        {!isMobileScreen && (
          <IconButton
            aria-label="close"
            onClick={
              closeEmailUpdateClicked
                ? closeEmailUpdateClicked
                : closeEmailUpdateAction
            }
            data-testid="popupCloseIcon"
            className="edit-email-close-btn"
          >
            <CloseIcon />
          </IconButton>
        )}
      </div>
    );
  };

  const SameAsPrevEmailPrompt = () => {
    return (
      <>
        {isSameEmail && (
          <div className="ct-same-email-warning">
            <Typography
              className="ct-same-email-warning-text"
              data-testid="ct-same-email-warning-text"
            >
              <div>
                <YellowWarning />
              </div>
              You are using the previous email. <br></br>
              Please change to a new one.
            </Typography>
          </div>
        )}
      </>
    );
  };

  const prescriberEditMode = () => {
    return (
      <>
        {isMobileScreen ? (
          <div
            className="ct-prescriberEditBox"
            data-testid="prescriber-edit-mode"
          >
            <EPrescriptionInformationText />
            <div className="ct-prescriberEditBox-detail">
              <div className="ct-prescriber-name-edit">
                <div className="close-icon">
                  <div
                    className="ct-headerdetail"
                    data-testid="prescriber-name-title"
                  >
                    Prescriber Name
                  </div>
                  <div className="close-icon-btn">
                    <IconButton
                      aria-label="close"
                      onClick={
                        closeEmailUpdateClicked
                          ? closeEmailUpdateClicked
                          : closeEmailUpdateAction
                      }
                      sx={{ padding: 0 }}
                      data-testid="popupCloseIcon"
                      className="closeBtn"
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                </div>
                <div
                  className="ct-prescriberValues"
                  data-testid="prescriber-name-value"
                >
                  {prescriberDetails.prescriberName.value !== ""
                    ? makeCapitalEachWordInString(
                        prescriberDetails.prescriberName.value
                      )
                    : "--"}
                </div>
              </div>
              <div className="ct-prescriber-email-edit">
                <InputWithLabel
                  label="Prescriber Email Address"
                  isRequired={true}
                  error={
                    prescriberDetails.updatedPrescriberEmail.valid ===
                    ValidationStatus.INVALID
                  }
                  testId="prescriber-email-title"
                >
                  <InputBase
                    onBlur={handleBlurForPrevEmailCheck}
                    className="ct-pescriber-email-input"
                    id="pescriber-email-input"
                    name="updatedPrescriberEmail"
                    onChange={ValidateAndSetData}
                    inputProps={{
                      "data-testid": "prescriber-email-value",
                    }}
                    value={prescriberDetails.updatedPrescriberEmail.value}
                  />
                </InputWithLabel>
              </div>
              <div className="ct-prescriber-edit-buttons">
                <ExpressButton
                  parentClass="ct-updateButton"
                  clickHandler={updatePrescriptionAction}
                  variant="contained"
                  disabled={
                    prescriberDetails.updatedPrescriberEmail.valid ===
                      ValidationStatus.INVALID ||
                    prescriberDetails.updatedPrescriberEmail.value === "" ||
                    checkForEmailSameAsPrev()
                  }
                  testId="prescriber-update-button"
                >
                  update & send
                </ExpressButton>
              </div>
              <div className="ct-prev-email-prompt-mobile">
                <SameAsPrevEmailPrompt />
              </div>
            </div>
          </div>
        ) : (
          <div
            className="ct-prescriberEditBox"
            data-testid="prescriber-edit-mode"
          >
            <EPrescriptionInformationText />
            <div className="ct-prescriberEditBox-detail">
              <div className="ct-prescriber-name-edit">
                <div
                  className="ct-headerdetail"
                  data-testid="prescriber-name-title"
                >
                  Prescriber Name
                </div>
                <div
                  className="ct-prescriberValues"
                  data-testid="prescriber-name-value"
                >
                  {prescriberDetails.prescriberName.value !== ""
                    ? makeCapitalEachWordInString(
                        prescriberDetails.prescriberName.value
                      )
                    : "--"}
                </div>
              </div>
              <div className="ct-prescriber-email-edit">
                <InputWithLabel
                  label="Prescriber Email Address"
                  isRequired={true}
                  error={
                    prescriberDetails.updatedPrescriberEmail.valid ===
                    ValidationStatus.INVALID
                  }
                  testId="prescriber-email-title"
                >
                  <InputBase
                    onBlur={handleBlurForPrevEmailCheck}
                    className="ct-pescriber-email-input"
                    id="pescriber-email-input"
                    name="updatedPrescriberEmail"
                    onChange={ValidateAndSetData}
                    inputProps={{
                      "data-testid": "prescriber-email-value",
                    }}
                    value={prescriberDetails.updatedPrescriberEmail.value}
                  />
                </InputWithLabel>
              </div>
              <div className="ct-prescriber-edit-buttons">
                <ExpressButton
                  parentClass="ct-updateButton"
                  clickHandler={
                    updatePrescriptionClicked
                      ? updatePrescriptionClicked
                      : updatePrescriptionAction
                  }
                  variant="contained"
                  disabled={
                    prescriberDetails.updatedPrescriberEmail.valid ===
                      ValidationStatus.INVALID ||
                    prescriberDetails.updatedPrescriberEmail.value === "" ||
                    checkForEmailSameAsPrev()
                  }
                  testId="prescriber-update-button"
                >
                  update & send
                </ExpressButton>
              </div>
            </div>
            <SameAsPrevEmailPrompt />
          </div>
        )}
      </>
    );
  };

  const showError = () => {
    return (
      <div className="error-message-div">
        <img
          style={{ verticalAlign: "text-bottom", marginRight: "5px" }}
          src={RedCheck}
          alt={RedCheck}
        />
        <h4 className="error-message" data-testid="error-message">
          This email address has been rejected. Please change the prescription
          type or call{" "}
          <span className="error-message-phone">1-800-275-4524 ext 41858</span>{" "}
          for help.
        </h4>
      </div>
    );
  };

  return (
    <div className="change-e-prescription-component">
      {editPrescriberMode ? prescriberEditMode() : prescriberReviewMode()}
    </div>
  );
};

export default ChangeEPrescription;
