import { STATIC_TEXT_INVENTORY_RETURN_TO_SOLVENTUM } from "../../../../util/staticText";
import { IInventoryProductDetails } from "../../inventoryTabContainer.enum";
import { ReturnStoredProductDetails } from "./returnStoredProductDetails/returnStoredProductDetails.component";
import { ReturnInformation } from "./returnStoredProductReturnInformation/returnInformation.component";
import "./returnStoredProductUnit.css";
import { IStoredProdReturnInformation } from "./returnStoredProductUnit.interface";

interface Props {
  returnStoredProductData: IInventoryProductDetails;
  locationData: string[];
  redirectHandler: Function;
  closePopUp: Function;
  setError?: Function;
  returnInformationData: IStoredProdReturnInformation;
  setReturnInformationData: Function;
}
export const ReturnStoredProductUnit = ({
  returnStoredProductData,
  locationData,
  redirectHandler,
  closePopUp,
  setError,
  returnInformationData,
  setReturnInformationData,
}: Props) => {
  return (
    <div
      className="return-stored-product-popup"
      data-testId="return-stored-product-popup"
    >
      <div className="return-stored-product-popup-popup-div">
        <h4 className="return-stored-product-div-title" data-testid="title">
          Return Unit to Solventum
        </h4>
        <span className="return-stored-product-div-desc">
          {STATIC_TEXT_INVENTORY_RETURN_TO_SOLVENTUM}
        </span>
        <ReturnStoredProductDetails
          returnStoredProductData={returnStoredProductData}
        />
        <div
          className="return-info-div"
          data-testId="return-information-component"
        >
          <ReturnInformation
            returnStoredProductData={returnStoredProductData}
            locationData={locationData}
            closePopup={closePopUp}
            redirectHandler={redirectHandler}
            setError={setError}
            returnInformationData={returnInformationData}
            setReturnInformationData={setReturnInformationData}
          />
        </div>
      </div>
    </div>
  );
};
