import {
  Validation,
  ValidationStatus,
} from "../../../../core/interfaces/input.interface";
import { getDeepClone } from "../../../../util/ObjectFunctions";
import { Validator } from "../../../../util/order.validations";
import {
  makeCapitalEachWordInString,
  updateErrorMessage,
} from "../../../../util/utilityFunctions";
import { IAddSiteStatus } from "./addSiteStatus.interface";

export class AddSiteStatusValidator {
  private _validator;
  constructor(defValidator = new Validator()) {
    this._validator = defValidator;
  }

  private fieldToMethodMapping(
    field: string
  ): ((txt: string) => Validation) | undefined {
    const mapping = new Map<string, (txt: string) => Validation>([
      ["statusType", this._validator.emptyCheck],
      ["activation", this._validator.noValidation],
      ["location", this._validator.previousTherapies],
      ["audience", this._validator.previousTherapies],
      ["title", this._validator.siteStatusContentValidation],
      ["detail", this._validator.siteStatusContentDetailValidationNotMandatory],
      ["urlLink", this._validator.siteStatusContentValidationNotMandatory],
      ["linkText", this._validator.siteStatusContentValidationNotMandatory],
      ["messageFormat", this._validator.emptyCheck],
    ]);
    const validator = mapping.get(field);
    return validator;
  }

  public validate(input: string, field: string) {
    try {
      const validator = this.fieldToMethodMapping(field)!;
      return validator(input);
    } catch (error) {
      console.log(`validator method for field ${field} is not configured`);
    }
  }

  public validateAll(
    data: IAddSiteStatus,
    updateDataIfUntouchedAndValidated: Function
  ): [ValidationStatus, string | null] {
    let temp = getDeepClone(data);
    let smallestvalueWithInvalidStatus: any = null;

    Object.keys(temp).forEach((x: string) => {
      if (temp[x].required) {
        if (
          temp[x].valid === ValidationStatus.UNTOUCHED ||
          ValidationStatus.INVALID
        ) {
          if (temp[x].valid === ValidationStatus.UNTOUCHED) {
            temp[x].valid = ValidationStatus.INVALID;
          }

          if (temp[x].isCheckBox) {
            temp[x].errorMessage =
              makeCapitalEachWordInString(temp[x]?.title) + " is required";
          }
        }
        if (
          (temp[x].valid === ValidationStatus.INVALID && temp[x].order) ||
          !temp[x].required
        ) {
          if (
            !smallestvalueWithInvalidStatus ||
            (smallestvalueWithInvalidStatus.order &&
              temp[x].order &&
              smallestvalueWithInvalidStatus.order > temp[x].order)
          ) {
            smallestvalueWithInvalidStatus = temp[x];
          }
        }
      }
      if (!temp[x].isCheckBox) {
        updateErrorMessage(temp[x], true);
      }
    });
    updateDataIfUntouchedAndValidated(temp);
    const ifAllValid = Object.keys(temp)
      .filter((x) => x !== "id" && temp[x].required)
      .every((x: string) => temp[x].valid === ValidationStatus.VALID);
    return [
      ifAllValid ? ValidationStatus.VALID : ValidationStatus.INVALID,
      smallestvalueWithInvalidStatus?.componentId,
    ];
  }
}
