import {
  IInputField,
  ValidationStatus,
} from "../../../../core/interfaces/input.interface";
import { IMyListStorageLocation } from "./myListStorageLocations.interface";

export const myListStorageLocation: IInputField = {
  valid: ValidationStatus.UNTOUCHED,
  value: "",
  required: true,
};

export const myListStorageLocationsMockData: IMyListStorageLocation[] = [
  {
    locationName: "OTH - BIOMED",
    isEnable: true,
    storageId: 1,
    isCustom: false,
    hasLocationAssigned: false,
  },
  {
    locationName: "NURSING/DONS OFFICE",
    isEnable: false,
    storageId: 2,
    isCustom: false,
    hasLocationAssigned: true,
  },
  {
    locationName: "OTH - CENTRAL SUPPLY",
    isEnable: false,
    storageId: 3,
    isCustom: false,
    hasLocationAssigned: false,
  },
  {
    locationName: "SPD - STERILE PROC DPT",
    isEnable: true,
    storageId: 5,
    isCustom: false,
    hasLocationAssigned: true,
  },
  {
    locationName: "OTH - EQUIPMENT ROOM",
    isEnable: false,
    storageId: 8,
    isCustom: false,
    hasLocationAssigned: false,
  },
  {
    locationName: "STERILE CENTRAL",
    isEnable: false,
    storageId: 9,
    isCustom: false,
    hasLocationAssigned: false,
  },
];
