import React, { useContext, useState } from "react";
import { SupplyOrderContextType } from "../../../context/SupplyOrderContext";
import ErrorPopup from "../../../core/errorPopup/errorPopup.component";
import { LoadingSpinner } from "../../../core/loader/LoadingSpinner";
import { Popup } from "../../../core/popup/popup.component";
import { callSaveSupplyOrder } from "../mapper/supplyOrderRequestMapper";
import SupplyOrderFooterButtonGroup from "../supplyOrderInfo/supplyOrderFooterButtonGroup/supplyOrderFooterButtonGroup.component";
import "./supplyOrderReview.css";
import { PatientVACDetail } from "../patientVACDetail/patientVACDetail.component";
import { SupplyOrderValidator } from "../supplyOrder.validator";
import SupplyProductReviewOrder from "../suppliesOrderDressing/supplyProductReviewOrder/supplyProductReviewOrder.component";
import { SupplyOrderPageSection } from "../SupplyOrderPageSection.enum";
import CurrentSuppliesOnHandReviewOrder from "../currentSuppliesOnHand/reviewOrder/currentSuppliesOnHandReviewOrder.component";
import ReSupplyJustificationReviewOrder from "../reSupplyJustification/reviewOrder/reSupplyJustificationReviewOrder.component";
import DeliveryAddressReviewOrder from "../deliveryAddress/reviewOrder/deliveryAddressReviewOrder.component";
import AdditionalInformationReviewOrder from "../supplyOrderAdditionalInformation/reviewOrder/additonalInformationReviewOrder";
import { AuthContext, AuthContextType } from "../../../context/AuthContext";
import { useHistory } from "react-router-dom";
import { ERROR_MSG_SUPPLY_ORDER } from "../../../util/errorMsg";

type Props = {
  supplyOrderContextObj: SupplyOrderContextType | null;
  isOrderOverviewFlow?: boolean;
};
const SupplyOrderReview = ({
  supplyOrderContextObj,
  isOrderOverviewFlow,
}: Props) => {
  supplyOrderContextObj?.setSupplyOrderPageTitle("Home Patient Supply Order");
  supplyOrderContextObj?.setSupplyOrderProgress(60);

  const [saveAPILoader, setsaveAPILoader] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const AuthObj = useContext<AuthContextType | null>(AuthContext);
  const [errorPopPpFlag, setErrorPopUpFlag] = useState(false);
  const data = supplyOrderContextObj!.supplyOrderData;
  const [errorCode, setErrorCode] = useState("");
  const patient = supplyOrderContextObj!.selectedPatient;
  const setData = supplyOrderContextObj!.setSupplyOrderData;
  const vacProductInfo = supplyOrderContextObj!.vacProductInfo;

  const handlePlaceOrder = () => {
    setsaveAPILoader(true);
    try {
      initiateSaveSupplyOrder();
    } catch (error) {
      setErrorPopUpFlag(true);
      seterrorMessage(ERROR_MSG_SUPPLY_ORDER);
      setsaveAPILoader(false);
    }
  };

  const initiateSaveSupplyOrder = async () => {
    setsaveAPILoader(true);
    const result = await callSaveSupplyOrder(
      supplyOrderContextObj?.supplyOrderData!,
      supplyOrderContextObj,
      AuthObj?.registeredFaciltyAddress?.accountName,
      AuthObj?.registeredFaciltyAddress?.siteUseId
    );
    if (!result || !result.succeeded) {
      setsaveAPILoader(false);
      setErrorPopUpFlag(true);
      seterrorMessage(ERROR_MSG_SUPPLY_ORDER);
      setErrorCode(result?.error?.code || result?.status);
    } else {
      setsaveAPILoader(false);
      supplyOrderContextObj?.setIsBackFromReviewPage(false);
      supplyOrderContextObj?.setSupplyOrderPage(
        SupplyOrderPageSection.SUPPLYORDER_SUMMARY
      );
    }
  };
  const spinner = () => {
    return (
      <div>
        <div className="saveapi-header">Saving Order</div>
        <div className="saveapi-spinner">
          <LoadingSpinner />
        </div>
      </div>
    );
  };

  const openSupplyOrderPageEdit = (ref: any) => {
    window.scrollTo(0, 0);
    supplyOrderContextObj?.setSupplyOrderProgress(10);
    supplyOrderContextObj?.setIsBackFromReviewPage(true);
    supplyOrderContextObj?.setSupplyOrderPage(
      SupplyOrderPageSection.SUPPLYORDER_INFO
    );
    supplyOrderContextObj?.setScrollableComponentClassName(ref);
  };

  return (
    <>
      <div className="supplyOrderReview-container">
        <div className="supply-order-page-review">
          <div className="supply-order-review-subMainContainer">
            <div className="supplyOrderReview-title">Review Supply Order</div>
            <div className="supplyOrderReview-subtitle">
              Please verify the data provided below is accurate before placing
              your order.
            </div>
            <PatientVACDetail
              data={data}
              patient={patient!}
              vacProductInfo={vacProductInfo!}
              isReviewOrder={true}
            />
            <SupplyProductReviewOrder
              isReviewOrder={true}
              openSupplyOrderPageEdit={() =>
                openSupplyOrderPageEdit("choose-supplyMain")
              }
              data={data}
            />
            <CurrentSuppliesOnHandReviewOrder
              data={data}
              openSupplyOrderPageEdit={() =>
                openSupplyOrderPageEdit("currentSupply-main")
              }
            />
            <ReSupplyJustificationReviewOrder
              data={data}
              openSupplyOrderPageEdit={() =>
                openSupplyOrderPageEdit("reSupply-main")
              }
            />
            <DeliveryAddressReviewOrder
              data={data}
              openSupplyOrderPageEdit={() =>
                openSupplyOrderPageEdit("deliveryAddress-main")
              }
              isShowCurrentAddress={supplyOrderContextObj!.isShowCurrentAddress}
            />
            <AdditionalInformationReviewOrder
              data={data}
              openSupplyOrderPageEdit={() =>
                openSupplyOrderPageEdit("additonalInfo-main")
              }
            />
          </div>
        </div>
      </div>
      {saveAPILoader ? (
        <Popup
          hideCloseButton={true}
          openFlag={saveAPILoader}
          closeHandler={() => {}}
        >
          {spinner()}
        </Popup>
      ) : (
        ""
      )}
      <ErrorPopup
        errorPopupFlag={errorPopPpFlag}
        handleBackButton={() => setErrorPopUpFlag(false)}
        popUpStyles="errorPopup"
        errorMessage={errorMessage}
        buttontitle="Done"
        showTitle={false}
        isShortSentence={true}
        errorCode={errorCode}
      />
      <SupplyOrderFooterButtonGroup
        handlePlaceOrder={handlePlaceOrder}
        isOrderOverviewFlow={isOrderOverviewFlow}
      />
    </>
  );
};

export default SupplyOrderReview;
