import { ISalesPersonAPIData } from "../addSalePersonDetailList/addSalePersonDetailList.interface";
import { IAddSalePersonDetail } from "./addSalePersonDetail.interface";

export const addSalesPersonMock: IAddSalePersonDetail = {
  firstName: "Carmel",
  lastName: "Megha",
  territories: ["AAM4048 - Houston N TX"],
  roles: "AAM",
  assignedFrom: "",
  assignedTo: "",
  reason: "",
};

export const salesPersonDetailListMock: ISalesPersonAPIData[] = [
  {
    userID: "134",
    firstName: "Carmel",
    lastName: "Megha",
    territories: ["AAM4048 - Houston N TX"],
    roles: ["AAM"],
    isCurrentlyAssigned: true,
  },
  {
    userID: "13443",
    firstName: "Jolly",
    lastName: "Good",
    territories: ["AAM4048 - Houston N TX"],
    roles: ["AAM"],
    isCurrentlyAssigned: true,
  },
];
