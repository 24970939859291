import { useHistory } from "react-router-dom";
import "./summaryAcuteOrderPickUpRequest.css";
import { IPatient } from "../../myPatients/patient.interface";
import { ReactComponent as DownloadIcon } from "../../../assets/download.svg";
import { IAcuteOrderPickUpRequest } from "../acuteOrderPickUpRequest.interface";
import { EnterPickupInformationReview } from "../components/enterPickupInformation/reviewPage/enterPickupInformationReview.component";
import { EstimatedArrivalTime } from "../../inventory/requestService/createServiceRequest/estimatedArrivalTime/estimatedArrivalTime.component";
import { DeviceComplications } from "../../inventory/requestService/createServiceRequest/deviceComplications/deviceComplications.component";
import { PatientInformationReviewOrder } from "../components/patientInformation/reviewOrder/patientInformationReviewOrder.component";
import { SummaryHeader } from "../../inventory/requestService/serviceRequestSummary/header/summaryHeader.component";
import { ExpressButton } from "../../../core/expressButton/expressButton.component";
import { ShowTitleAndValue } from "../../../core/showTitleAndValue/showTitleAndValue.component";

interface IAcuteOrderPickUpRequestSummaryPage {
  data: IAcuteOrderPickUpRequest;
  editButtonAction: Function;
  isReviewOrder: boolean;
  isSummaryOrder: boolean;
  patient: IPatient;
  setData: Function;
  workOrderNumber: string;
  isCustomerOwnedProduct?: boolean;
}

export const AcuteOrderPickUpRequestSummaryPage = ({
  data,
  editButtonAction,
  isReviewOrder,
  isSummaryOrder,
  patient,
  setData,
  workOrderNumber,
  isCustomerOwnedProduct = false,
}: IAcuteOrderPickUpRequestSummaryPage) => {
  const history = useHistory();

  const handleNavigation = () => {
    history.push("/home");
  };

  const handleClickPdfDownload = async () => {
    window.print();
  };

  return (
    <div
      className="acute-order-pickUp-request-summary-page-component"
      data-testid="acute-order-pickUp-request-summary-page-component"
    >
      <div className="short-form">
        <SummaryHeader
          successMessage="Success!"
          description1="Your Pickup Request/End Therapy  has been submitted."
          description2="A 3M Medical Solutions representative will contact you if there are any questions around this request."
          backButton="Back to My Patients"
          handleBackButton={handleNavigation}
        />
        <div className="acute-order-pickUp-request-summary-header">
          <p
            className="acute-order-pickUp-request-summary-title"
            data-testid="acute-order-pickUp-request-summary-title"
            id="acute-order-pickUp-request-summary-title"
          >
            Pickup Request/End Therapy Summary
          </p>
          <div className="acute-order-pickUp-request-summary-header-button">
            <ExpressButton
              clickHandler={() => handleClickPdfDownload()}
              id="acute-order-pickUp-request-summary-download-button"
              parentClass="acute-order-pickUp-request-summary-download-button"
              testId="download-button"
              variant="text"
              startIcon={<DownloadIcon />}
            >
              Save and Print Request Summary
            </ExpressButton>
          </div>
        </div>
        <div className="acute-order-pickUp-request-work-order-number">
          <p
            className="acute-order-pickUp-request-work-order-number-title"
            data-testid="acute-order-pickUp-request-work-order-number-title"
            id="acute-order-pickUp-request-work-order-number-title-id"
          >
            Work Order Number
          </p>
          <ShowTitleAndValue
            title="Work Order Number"
            value={`#${workOrderNumber}`}
            testId="work-order-number"
          />
        </div>
        <PatientInformationReviewOrder patient={patient} />
        <EnterPickupInformationReview
          data={data.enterPickUpInformation.value}
          editButtonAction={editButtonAction}
          isSummaryOrder={isSummaryOrder}
          isCustomerOwnedProduct={isCustomerOwnedProduct}
        />
        <EstimatedArrivalTime
          data={data}
          editButtonAction={editButtonAction}
          isReviewRequest={isReviewOrder}
          isReviewSummary={isSummaryOrder}
          setData={setData}
          isCustomerOwnedProduct={isCustomerOwnedProduct}
        />
        <DeviceComplications
          data={data}
          editButtonAction={editButtonAction}
          isReviewRequest={isReviewOrder}
          isReviewSummary={isSummaryOrder}
          setData={setData}
        />
      </div>
    </div>
  );
};
