import { InputBase, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useOutsideClick } from "rooks";
import { ReactComponent as SearchIcon } from "../../../../assets/magnifier.svg";
import { useSortableTable } from "../../../../util/utilityFunctions";
import { IHomeCareProviderDetail } from "../homeCareProvider.interface";

import { IHomeCareProviderSearch } from "./homeCareProviderSearch.model";
import "./homeCareSearch.css";
import HomeCareProviderList from "../homeCareProviderList/homeCareProviderList.component";
import { useDebounce } from "use-debounce";

interface Props {
  homeCareProviderTextBox: IHomeCareProviderSearch;
  showHomeCareProviderCrossIcon: boolean;
  setShowHomeCareProviderCrossIcon: Function;
  showNoResults: any;
  showHomeCareProviderOption: any;
  setShowHomeCareProviderOption: Function;
  homeCareProviderList: IHomeCareProviderDetail[];
  validateAndSetData: any;
  handleSelectOption: any;
  displayAddManualHomeCare: any;
  clearSearchData: React.MouseEventHandler<SVGSVGElement> | undefined;
  enabledHCPFromMyList?: any;
}
const HomeCareProviderSearchDropDown = ({
  clearSearchData,
  displayAddManualHomeCare,
  homeCareProviderTextBox,
  showHomeCareProviderCrossIcon,
  showNoResults,
  showHomeCareProviderOption,
  setShowHomeCareProviderOption,
  homeCareProviderList,
  validateAndSetData,
  handleSelectOption,
  enabledHCPFromMyList,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [searchInput, setSearchInput] = useState<string>("");
  const [debouncedText] = useDebounce(searchInput, 500);
  const [searchedInput, setSearchedInput] = useState<string>("");
  const [originalData, setOriginalData] =
    useState<IHomeCareProviderDetail[]>(enabledHCPFromMyList);
  useOutsideClick(ref, () => setShowHomeCareProviderOption(false));
  const columns = [
    { label: "", accessor: "", sortable: false },
    {
      label: "Home Care Providers",
      accessor: "name",
      sortable: true,
    },
    { label: "Facility ID", accessor: "accountNumber", sortable: true },
    {
      label: "Classification",
      accessor: "facilityType",
      sortable: true,
    },
  ];
  const [sortedData, setSortedData, handleSorting] = useSortableTable(
    [],
    columns
  );
  useEffect(() => {
    setSortedData(enabledHCPFromMyList);
  }, []);

  const handleSearch = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const re = /^[a-zA-Z0-9- ]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setSearchInput(e.target.value);
    }
  };

  const filteredHCP = (searchParam: string) => {
    setSearchedInput(searchParam);
    if (originalData && originalData.length > 0) {
      let filteredHCP = originalData;
      if (searchParam.length > 0) {
        filteredHCP = filteredHCP.filter(
          (hcpObj: IHomeCareProviderDetail) =>
            hcpObj.name
              .toLocaleLowerCase()
              .includes(searchParam.toLowerCase()) ||
            hcpObj.facilityType
              .toLocaleLowerCase()
              .includes(searchParam.toLowerCase()) ||
            hcpObj.accountNumber
              .toLocaleLowerCase()
              .includes(searchParam.toLowerCase())
        );
      }
      setSortedData(filteredHCP);
    }
  };

  useEffect(() => {
    if (
      (debouncedText.length === 0 && searchedInput !== debouncedText) ||
      debouncedText.length > 0
    ) {
      filteredHCP(debouncedText);
    }
  }, [debouncedText]);

  return (
    <div className="search-homecareprovider-inputs" ref={containerRef}>
      <div
        className="hcp-search-icon-div"
        data-testId="homeCareProviderSearchInputTest"
      >
        <SearchIcon className="hcp-search-icon" />
        <InputBase
          className="hcp-user-search-input"
          name="search-input"
          onChange={handleSearch}
          placeholder="Filter Home Care Provider List by Name, Number or Classification"
          value={searchInput}
        />
      </div>

      <HomeCareProviderList
        columns={columns}
        homeCareProviderList={sortedData}
        handleSorting={handleSorting}
        displayAddManualHomeCare={displayAddManualHomeCare}
        handleSelectOption={handleSelectOption}
      />
    </div>
  );
};

export default HomeCareProviderSearchDropDown;
