import moment from "moment";
import "./suppliesShipped.css";
import { useHistory } from "react-router";
import { useContext, useState } from "react";
import {
  OrderDetailContextType,
  OrderDetailContext,
} from "../../../../context/OrderDetailsContext";
import { Popup } from "../../../../core/popup/popup.component";
import { ISuppliesShipped } from "./suppliesShipped.interface";
import { AlertDetails } from "../alertDetails/alertDetails.component";
import { getOrderStatusDetails } from "../../../../util/vacOrderService";
import { closeSuppliesDeliveredAlert } from "../../../../util/alertService";
import { OrderOverViewTabsTitle } from "../../patientAndTherapyDetails/orderOverview/orderOverviewContainer.enum";
import { ISupplyOrdersInfo } from "../../patientAndTherapyDetails/orderSupplyDetail/orderSupplyDetails.interface";
import { AuthContext, AuthContextType } from "../../../../context/AuthContext";

export function SuppliesShipped({
  data,
  date = new Date(),
  patient,
}: ISuppliesShipped) {
  const history = useHistory();
  const dateString = moment(date).format("L");
  const orderOverviewObj = useContext<OrderDetailContextType | null>(
    OrderDetailContext
  );
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const handleButtonClick = async () => {
    if (patient && data) {
      const reqBody = {
        RentalOrderNumber: patient.roNumber,
        ROPN: data.ropn,
      };
      closeSuppliesDeliveredAlert(reqBody);
      orderOverviewObj?.setSelectedOrderTab(
        OrderOverViewTabsTitle.SUPPLY_ORDER_TAB_TITLE
      );
      const supplyOrderData: ISupplyOrdersInfo = {
        createdDate: "",
        shippedDate: "",
        initiatedName: "",
        product: "",
        ropn: data?.ropn!,
        status: "Shipped",
        statusColor: "Green",
        deliveredDate: "",
      };
      orderOverviewObj?.setSelectedSupplyOrder(supplyOrderData);
      orderOverviewObj?.setSelectedOrderTab(
        OrderOverViewTabsTitle.ORDER_TAB_TITLE
      );
      // getTherapyStartDate(patient.roNumber, patient.dob);
      orderOverviewObj?.setIsOrderFlow(true);
      orderOverviewObj?.setOrderTabTitle("Supply Orders");
      history.push({
        pathname: "/home/orderOverview",
        state: {
          stateData: patient,
        },
      });
    }
  };

  const getTherapyStartDate = async (ron: number, dob: string) => {
    if (
      authObj &&
      authObj.userProfile &&
      authObj.userProfile.userName &&
      authObj.userProfile.userName !== "" &&
      authObj.registeredFaciltyAddress &&
      authObj.registeredFaciltyAddress.siteUseId &&
      authObj.registeredFaciltyAddress.siteUseId !== ""
    ) {
      const reqParam = {
        RentalOrderNumber: ron.toString(),
        DOB: dob,
        userName: authObj.userProfile.userName,
        siteUseId: authObj.registeredFaciltyAddress.siteUseId,
      };
      try {
        const result = await getOrderStatusDetails(reqParam);
        if (result.succeeded) {
          const dateStr = result.item.therapyDate;
          const date = moment(dateStr, "YYYY-MM-DDTHH:mm:ss").toDate();
          orderOverviewObj?.setSelectedSupplyTherapyStartDate(date.toString()!);
          return true;
        }
      } catch {
        return false;
      }
    }
  };

  return (
    <>
      <AlertDetails
        body={
          "Supplies were shipped for the tracking number " +
          data.trackingNumber +
          "."
        }
        buttonOnClick={handleButtonClick}
        footer="View Supply Order"
        showCallForAssistance={false}
        showContactToSupport={true}
        title="Supplies Shipped"
        titleClassName="supplies-delivered-header-title"
      />
    </>
  );
}
