import { Button } from "@mui/material";
import "./dressingSuppliesCondensed.css";

import parse from "html-react-parser";
import {
  ICanister,
  IDressingKit,
  IProductAccessory,
} from "../../newOrder.interface";

interface IDressingSuppliesCondensed {
  dressing: IDressingKit;
  canister: ICanister;
  accesssory: IProductAccessory;
  editButtonClicked: any;
  isOrderSummary?: boolean;
  newOrderObj?: any;
  isFromReadyCare?: boolean;
  isComingFromOrderOverView?: boolean;
}

export const DressingSuppliesCondensed = ({
  dressing,
  canister,
  accesssory,
  editButtonClicked,
  newOrderObj,
  isFromReadyCare = false,
  isComingFromOrderOverView = false,
}: IDressingSuppliesCondensed) => {
  const renderCanisterReview = () => {
    let cannisterData: any = "--";
    if (
      canister.canisterProductCode.value !== "" &&
      canister.canisterProductCode.value !== "--" &&
      !isFromReadyCare
    ) {
      cannisterData = (
        <h5
          className="dressing-supplies-condensed-content-value"
          data-testid="canister-kit-value"
        >
          <span className="dot-with-space">&bull; </span>
          {`${canister.canisterProductQuantity.value} case of ${
            canister.canisterProductCode.value.split("/")[1] ??
            canister.canisterProductCode.value.match(/\d/g)?.join("")
          } - ${canister.canisterProductName.value}, (${
            canister.canisterProductCode.value
          })`}
        </h5>
      );
    }
    if (
      canister.canisterProductCode.value !== "" &&
      canister.canisterProductCode.value !== "--" &&
      canister.canisterProductQuantity.value === "1" &&
      isFromReadyCare &&
      !isComingFromOrderOverView
    ) {
      cannisterData = (
        <h5
          className="dressing-supplies-condensed-content-value"
          data-testid="canister-kit-value"
        >
          <span className="dot-with-space">&bull; </span>
          {`1 case of ${
            canister.canisterProductCode.value.split("/")[1] ??
            canister.canisterProductCode.value.match(/\d/g)?.join("")
          } - ${canister.canisterProductName.value}, (${
            canister.canisterProductCode.value
          })`}
        </h5>
      );
    }
    return cannisterData;
  };

  return (
    <div className="dressing-supplies-condensed">
      <div className="dressing-supplies-component-title">
        <h2
          className="dressing-supplies-condensed-title"
          data-testid="dressing-supplies-condensed-title"
        >
          Selected Dressings & Supplies
        </h2>
        <Button
          classes={{ root: "dressing-supplies-condensed-edit-button" }}
          data-testid="dressing-supplies-condensed-edit-button"
          onClick={editButtonClicked}
        >
          edit selection
        </Button>
      </div>
      <div className="all-content-div">
        <div className="content-div">
          <div className="sub-content-div">
            <h5
              className="dressing-supplies-condensed-content-title"
              data-testid="dressing-kit"
            >
              {parse("3M™ V.A.C.<sup>®</sup> Dressing Kit")}
            </h5>
            {((!isComingFromOrderOverView || !isFromReadyCare) &&
              (dressing.productName.value !== "" ||
                dressing.secProductName.value !== "")) ||
            (isComingFromOrderOverView &&
              dressing.productName.value !== "" &&
              dressing.secProductName.value !== "" &&
              isFromReadyCare) ? (
              <>
                {dressing.productName.value !== "" && (
                  <h5
                    className="dressing-supplies-condensed-content-value"
                    data-testid="dressing-kit-value-primary"
                  >
                    <span className="dot-with-space">&bull; </span>
                    {`${dressing.productQuantity.value} case of ${
                      dressing.productCode.value &&
                      dressing.productCode.value !== ""
                        ? dressing.productCode.value.split("/")[1] ??
                          dressing.productCode.value
                            .match(/\d/g)
                            ?.join("")
                            .replace(/^0+/, "")
                        : dressing.productSizeCode.value.split("/")[1] ??
                          dressing.productSizeCode.value
                            .match(/\d/g)
                            ?.join("")
                            .replace(/^0+/, "")
                    } - ${dressing.productName.value}, ${
                      newOrderObj && newOrderObj.showSize
                        ? dressing.productSizeName.value.split(" (")[0]
                        : ""
                    } (${
                      dressing.productCode.value &&
                      dressing.productCode.value !== ""
                        ? dressing.productCode.value
                        : dressing.productSizeCode.value
                    })`}
                  </h5>
                )}
                {dressing.secProductName.value !== "" && (
                  <h5
                    className="dressing-supplies-condensed-content-value"
                    data-testid="dressing-kit-value-secondary"
                  >
                    <span className="dot-with-space">&bull; </span>
                    {`${dressing.secProductQuantity.value} case of ${
                      dressing.secProductCode.value &&
                      dressing.secProductCode.value !== ""
                        ? dressing.secProductCode.value.split("/")[1] ??
                          dressing.secProductCode.value
                            .match(/\d/g)
                            ?.join("")
                            .replace(/^0+/, "")
                        : dressing.secProductSizeCode.value.split("/")[1] ??
                          dressing.secProductSizeCode.value
                            .match(/\d/g)
                            ?.join("")
                            .replace(/^0+/, "")
                    } - ${dressing.secProductName.value}, ${
                      newOrderObj && newOrderObj.showSecSize
                        ? dressing.secProductSizeName.value.split(" (")[0]
                        : ""
                    } (${
                      dressing.secProductCode.value &&
                      dressing.secProductCode.value !== ""
                        ? dressing.secProductCode.value
                        : dressing.secProductSizeCode.value
                    })`}
                  </h5>
                )}
              </>
            ) : (
              "--"
            )}
          </div>
        </div>
        <div className={"content-div"}>
          <div className="sub-content-div">
            <h5
              className="dressing-supplies-condensed-content-title"
              data-testid="canister-kit"
            >
              {parse("3M™ V.A.C.<sup>®</sup> Canister(s)")}
            </h5>
            {renderCanisterReview()}
          </div>
        </div>
        <div className="content-div-last">
          <div className="sub-content-div">
            <h5
              className="dressing-supplies-condensed-content-title"
              data-testid="accessories-kit"
            >
              {parse("3M™ V.A.C.<sup>®</sup> Therapy Accessories")}
            </h5>
            {accesssory.accessories && accesssory.accessories.length > 0
              ? accesssory.accessories.map(
                  (
                    accessory: {
                      code: string;
                      value: string;
                    },
                    index
                  ) => {
                    return (
                      <h5
                        className="dressing-supplies-condensed-content-value"
                        data-testid={`accessories-kit-value-${index + 1}`}
                        key={index}
                      >
                        <span className="dot-with-space">&bull; </span>
                        {`1 Pack of ${
                          accessory.code.split("/")[1] ??
                          accessory.code.match(/\d/g)?.join("")
                        } - ${accessory.value}, (${accessory.code})`}
                      </h5>
                    );
                  }
                )
              : "--"}
          </div>
        </div>
      </div>
    </div>
  );
};
