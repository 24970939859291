import "./inventoryRequestSuccess.css";
import { ExpressButton } from "../../../../../core/expressButton/expressButton.component";
import { IInventoryRequestSucuessProps } from "./inventoryRequestSuccess.interface";
import { STATIC_TEXT_INVENTORY_ADJUSTMENT_SUCCESS_MSG } from "../../../../../util/staticText";
import parse from "html-react-parser";

export const InventoryRequestSucuess = ({
  backButtonAction,
}: IInventoryRequestSucuessProps) => {
  return (
    <div className="inventory-success-pop-up">
      <h2 className="title" data-testid="title">
        Inventory Adjustment Request Sent
      </h2>
      <h4 className="description1" data-testid="description1">
        {parse(STATIC_TEXT_INVENTORY_ADJUSTMENT_SUCCESS_MSG)}
      </h4>
      <h4 className="description2" data-testid="description2">
        If you have questions or issues, please contact our 3M™ Express Therapy
        Portal support Team at{" "}
        <a
          className="phone-value"
          data-testid="phone-value"
          href={`tel:+8002754524`}
        >
          1-800-275-4524 Ext. 41858.
        </a>{" "}
      </h4>
      <ExpressButton
        clickHandler={() => {
          backButtonAction();
        }}
        parentClass="back-button"
        testId="back-button"
        variant="contained"
      >
        Return to Inventory
      </ExpressButton>
    </div>
  );
};
