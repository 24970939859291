import "./prescriberResult.css";
import { useState, useEffect } from "react";
import { Button, Grid, InputBase, useMediaQuery } from "@mui/material";
import { useSortableTable } from "../../../../../../../../util/utilityFunctions";
import { PrescriberPopUp } from "../../../prescriberInformationPopUpContainer.enum";
import { NewOrderValidator } from "../../../../../../../newOrder/newOrder.validator";
import { IPrescriberInformation } from "../../../../prescriberInformation.interface";
import { ReactComponent as SearchIconSvg } from "../../../../../../../../assets/blackSearchIcon.svg";
import { NationalRegistryResultTable } from "../../nationalRegistryResult/table/nationalRegistryResultTable.component";
import {
  IFilterNationalRegistry,
  defaultNationalRegistryFilterBox,
} from "../../../../../../../newOrder/prescriberInformation/nationalRegistrySearch/nationalRegistryResult/filterNationalRegistry.model";

export interface IPrescriberResultProps {
  changeSearchType: Function;
  nationalRegistries: IPrescriberInformation[];
  selectButtonAction: Function;
  selectedNpi?: string;
}

export const PrescriberResult = ({
  changeSearchType,
  nationalRegistries,
  selectButtonAction,
  selectedNpi,
}: IPrescriberResultProps) => {
  const [filterNPI, setFilterNPI] = useState<IFilterNationalRegistry>(
    defaultNationalRegistryFilterBox
  );
  const columns = [
    { label: "", accessor: "orderID", sortable: false },
    { label: "Prescriber Name", accessor: "prescriberName", sortable: true },
    { label: "NPI #", accessor: "npi", sortable: true },
    { label: "City", accessor: "city", sortable: true },
    { label: "State", accessor: "state", sortable: true },
  ];
  const [sortedData, setSortedData, handleSorting] = useSortableTable(
    [],
    columns
  );
  const isMobileScreen = useMediaQuery("(max-width:480px)");
  const [showPrescriberNoDataError, setShowPrescriberNoDataError] =
    useState(false);

  useEffect(() => {
    if (nationalRegistries.length > 0) {
      setSortedData(nationalRegistries);
    }
  }, []);

  useEffect(() => {
    setShowPrescriberNoDataError(sortedData.length === 0);
  }, [sortedData]);

  const handleFilterNPI = (e: any) => {
    const { value } = e.target;
    const validator = new NewOrderValidator();
    const isvalid = validator.validate(value, e.target.name);
    setFilterNPI((filterNPI) => ({
      ...filterNPI,
      [e.target.name]: { value: value, valid: isvalid!.status },
    }));
    if (value.length > 2) {
      const filteredList = nationalRegistries.filter(
        (prescriber: IPrescriberInformation) => {
          return (
            prescriber.city.toLowerCase().includes(value.toLowerCase()) ||
            prescriber.npi.toLowerCase().includes(value.toLowerCase()) ||
            prescriber.firstName.toLowerCase().includes(value.toLowerCase()) ||
            prescriber.lastName.toLowerCase().includes(value.toLowerCase())
          );
        }
      );
      setSortedData(filteredList);
    } else if (value.length === 0) {
      setSortedData(nationalRegistries);
    }
  };

  return (
    <div
      className="prescriber-search-result-component"
      data-testid="prescriber-search-result-component"
    >
      {(nationalRegistries.length === 0 || showPrescriberNoDataError) && (
        <p
          className="prescriber-search-result-no-prescribers-present"
          data-testid="prescriber-search-result-no-prescribers-present"
          id="prescriber-search-result-no-prescribers-present"
        >
          <span className="no-match-text">No data found. </span>
          {`Would you like to `}
          <span
            className="prescriber-national-serach-button"
            data-testid="prescriber-national-serach-button"
            onClick={() =>
              changeSearchType(PrescriberPopUp.NATIONAL_REGISTRY_SEARCH)
            }
          >
            Search in National Registry instead
          </span>
        </p>
      )}
      <Grid className="my-prescriber-list-grid-container" container spacing={2}>
        <Grid
          className="my-prescriber-list-grid-item"
          item
          xs={isMobileScreen ? 12 : 5}
        >
          <p
            className="my-prescriber-list-title"
            data-testid="my-prescriber-list-title"
            id="my-prescriber-list-title"
          >
            My Prescriber List
          </p>
        </Grid>
        {nationalRegistries.length > 0 && sortedData.length !== 0 && (
          <Grid
            className="my-prescriber-list-grid-item"
            item
            xs={isMobileScreen ? 12 : 7}
          >
            <Button
              classes={{
                root: "search-in-national-registry-instead-button",
              }}
              data-testid="search-in-national-registry-instead-button"
              id="search-in-national-registry-instead-button"
              onClick={() =>
                changeSearchType(PrescriberPopUp.NATIONAL_REGISTRY_SEARCH)
              }
            >
              Search in National Registry instead
            </Button>
          </Grid>
        )}
      </Grid>
      <div
        className="filter-prescriber-div"
        data-testid="filter-prescriber-div"
      >
        <SearchIconSvg className="search-icon" data-testid="search-icon" />
        <InputBase
          autoFocus
          className="filter-prescriber-input"
          data-testid="filter-prescriber-input"
          id="filter-prescriber-input"
          name="filterNPIList"
          onChange={handleFilterNPI}
          placeholder="Filter Prescriber List by Name, City, or NPI number"
          value={filterNPI.filterNPIList.value}
        />
      </div>
      <NationalRegistryResultTable
        handleSorting={handleSorting}
        columns={columns}
        nationalRegistries={sortedData}
        selectButtonAction={selectButtonAction}
        selectedNpi={selectedNpi}
      />
    </div>
  );
};
