import moment from "moment";
import {
  IInputField,
  ValidationStatus,
} from "../../core/interfaces/input.interface";
import { IExchangeVACProps } from "./exchangeVacUnit.interface";

export let defaultExchangeVacData: IExchangeVACProps = {
  serialNumber: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: true,
  },
  serviceDate: {
    valid: ValidationStatus.VALID,
    value: moment().format("MM/DD/YYYY").toString(),
    required: true,
  },
  timeOfService: {
    valid: ValidationStatus.VALID,
    value: "12:00 AM",
    required: true,
  },
  issueDesc: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: true,
  },
  injuryCauseBy3MDevice: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: true,
  },
  problemWith3MDevice: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: true,
  },
  descriptionOfInjuryAndProblemWithTheDevice: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
  },
};
const mockInputFields = (): IInputField => ({
  valid: ValidationStatus.UNTOUCHED,
  value: "",
  required: true,
  errorMessage: "",
  isDefaultValid: false,
});
export let defaultMockExchangeVacData: IExchangeVACProps = {
  serialNumber: mockInputFields(),
  serviceDate: mockInputFields(),
  timeOfService: mockInputFields(),
  issueDesc: mockInputFields(),
  injuryCauseBy3MDevice: mockInputFields(),
  problemWith3MDevice: mockInputFields(),
  descriptionOfInjuryAndProblemWithTheDevice: mockInputFields(),
};
