import { InputBase, Typography } from "@mui/material";
import "../pickupLocation/pickupLocation.css";
import { InputWithLabel } from "../../../../../core/inputWithLabel/inputWithLabel.component";
import { ICreateServiceRequest } from "../../createServiceRequest.interface";
import { CustomDropDown } from "../../../../../core/customDropdown/customDropdown.component";
import { ValidationStatus } from "../../../../../core/interfaces/input.interface";
import PickupLocationReview from "./reviewPickupLocation/pickupLocationReview.component";
import parse from "html-react-parser";
import { CreateServiceRequestPageSection } from "../../createServiceRequest.enum";
import { CreateServiceRequestValidator } from "../../createServiceRequest.validator";

interface IPickupLocationProps {
  data: ICreateServiceRequest;
  setData: Function;
  storageLocations: string[];
  pageSection?: CreateServiceRequestPageSection;
  isReviewOrder?: boolean;
  isReviewSummary?: boolean;
  editButtonAction?: Function;
  isSolventumOrder?: boolean;
}

const PickupLocation = ({
  data,
  setData,
  storageLocations,
  isReviewOrder = false,
  isReviewSummary = false,
  editButtonAction,
  pageSection,
  isSolventumOrder = false,
}: IPickupLocationProps) => {
  const validateAndSetData = (e: any) => {
    const { name, value } = e.target;
    const validator = new CreateServiceRequestValidator();
    const isValid = validator.validate(value, name);
    if (name === "otherPickupLocation" && value.length > 30) {
      return;
    }
    let tempData: ICreateServiceRequest = data;
    tempData = {
      ...tempData,
      [name]: {
        ...Object(tempData)[name],
        value: value,
        valid: isValid?.status,
        errorMessage: null,
      },
    };
    if (name === "pickupLocation") {
      tempData.otherPickupLocation = {
        ...tempData.otherPickupLocation,
        required: value.toLowerCase() === "other location",
        valid: ValidationStatus.UNTOUCHED,
        value:
          value.toLowerCase() === "other location"
            ? ""
            : tempData.otherPickupLocation.value,
      };
    }
    setData(tempData);
  };

  return (
    <div
      id="pickup-location-component"
      className="pickup-location-component"
      data-testid="pickup-location-component"
    >
      {isReviewOrder ? (
        <PickupLocationReview
          data={data}
          editButtonAction={editButtonAction!}
          isReviewSummary={isReviewSummary}
        />
      ) : (
        <>
          <Typography
            data-testid="pickup-location-title"
            className="pickup-location-title"
          >
            Pickup Location
            {pageSection ===
              CreateServiceRequestPageSection.CREATE_SERVICE_REQUEST &&
              isSolventumOrder &&
              parse(" for V.A.C.<sup>®</sup> Therapy Unit")}
          </Typography>
          <div className="pickup-location-div">
            <InputWithLabel
              error={data.pickupLocation.valid === ValidationStatus.INVALID}
              id={data.pickupLocation.componentId}
              isDropdown={true}
              isRequired={data.pickupLocation.required}
              isShowWarning={true}
              label={data.pickupLocation.title}
              testId={data.pickupLocation.componentId!}
              warningMessage={data.pickupLocation.errorMessage}
            >
              <CustomDropDown
                handleChange={validateAndSetData}
                menuItem={storageLocations}
                name="pickupLocation"
                placeHolder="Select Pickup Location"
                selectpropsClassName={
                  data.pickupLocation.value
                    ? "pickup-location-select"
                    : "placeHolder"
                }
                selectClassName="pickup-location-input-div"
                testId={`pickup-location-drop-down-value`}
                value={data.pickupLocation.value}
              />
            </InputWithLabel>
          </div>
          {data.pickupLocation.value === "Other Location" && (
            <div className="pickup-location-div">
              <InputWithLabel
                error={
                  data.otherPickupLocation.valid === ValidationStatus.INVALID
                }
                id={data.otherPickupLocation.componentId}
                isRequired={data.otherPickupLocation.required}
                isShowWarning={true}
                label={data.otherPickupLocation.title}
                testId={data.otherPickupLocation.componentId!}
                warningMessage={data.otherPickupLocation.errorMessage}
              >
                <InputBase
                  data-testid={data.otherPickupLocation.id!}
                  id={data.otherPickupLocation.id!}
                  name="otherPickupLocation"
                  onChange={validateAndSetData}
                  className="other-location-input"
                  value={data.otherPickupLocation.value}
                />
              </InputWithLabel>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PickupLocation;
