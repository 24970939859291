import React from "react";
import { IInPatientSupplyOrderProduct } from "../../inpatientSupplyOrder.interface";
import "../supplyOrderAddButton/supplyOrderAddButton.css";

interface ISupplyOrderAddButton {
  productData: IInPatientSupplyOrderProduct;
  handleAddProduct: Function;
}

const SupplyOrderAddButton: React.FC<ISupplyOrderAddButton> = ({
  productData,
  handleAddProduct,
}) => {
  return (
    <div className="in-patient-supply-order-add-btn-div">
      <span
        className="in-patient-supply-order-add-button"
        data-testid="supply-order-add-button"
        onClick={() => handleAddProduct(productData)}
      >
        Add
      </span>
    </div>
  );
};

export default SupplyOrderAddButton;
