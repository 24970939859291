import { Location } from "history";
import React, { useContext, useEffect, useState } from "react";
import {
  UserProfileContext,
  UserProfileContextType,
} from "../../../../../context/UserProfileContext";
import { Prompt } from "react-router-dom";
import { Popup } from "../../../../../core/popup/popup.component";
import UserProfileUpdateConfirmationPopup from "./userProfileUpdateConfirmationPopup";

interface Props {
  when?: boolean | undefined;
  navigate?: (path: string) => void;
  shouldBlockNavigation: (location: Location) => boolean;
}
const UserProfileUpdateConfirmation = ({
  when,
  navigate,
  shouldBlockNavigation,
}: Props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const userProfileObj = useContext<UserProfileContextType | null>(
    UserProfileContext
  );
  const closeModal = () => {
    setModalVisible(false);
  };
  const handleConfirmNavigationClick = async () => {
    setModalVisible(false);
    userProfileObj?.setUserProfileGotChanged(false);
    userProfileObj?.resetUserProfile();
    setConfirmedNavigation(true);
  };

  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate!(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <Popup
        closeIconClass="closeIcon"
        closeHandler={() => closeModal()}
        openFlag={modalVisible}
      >
        <UserProfileUpdateConfirmationPopup
          closeModal={closeModal}
          handleExitButton={handleConfirmNavigationClick}
        />
      </Popup>
    </>
  );
};

export default UserProfileUpdateConfirmation;
