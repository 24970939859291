import { ExpressButton } from "../../../../../core/expressButton/expressButton.component";
import { makeCapitalEachWordInString } from "../../../../../util/utilityFunctions";
import { IUnlinkedUserLinkedSuccessPopUp } from "../../manageUserAccounts.interface";
import "./unlinkedUserLinkedSuccess.css";
import parse from "html-react-parser";

export const UnlinkedUserLinkedSuccess = ({
  buttonAction,
  buttonTitle,
  description,
  title,
  requestedFacility,
}: IUnlinkedUserLinkedSuccessPopUp) => {
  return (
    <div className="requested-facility-select-success">
      <h2 className="title" data-testid="title">
        {title}
      </h2>
      <h4 className="description" data-testid="description">
        {parse(description)}
      </h4>
      <div className="facility-card-gray-success">
        <div className="facility-details-all-title">
          {makeCapitalEachWordInString(requestedFacility.facilityName)}
        </div>
        <div className="facility-details-all">
          {makeCapitalEachWordInString(requestedFacility.address1)}
          {requestedFacility.address2 && requestedFacility.address2 !== ""
            ? `${
                ", " + makeCapitalEachWordInString(requestedFacility.address2)
              }`
            : ""}
        </div>
        <div className="facility-details-all">
          {`${makeCapitalEachWordInString(requestedFacility.city)}, ${
            requestedFacility.state
          } ${requestedFacility.zipCode}`}
        </div>
      </div>
      <ExpressButton
        clickHandler={() => {
          buttonAction();
        }}
        parentClass="back-button"
        testId="buttonTitle"
        variant="contained"
      >
        {buttonTitle}
      </ExpressButton>
    </div>
  );
};
