import { ExpressButton } from "../../../../../../core/expressButton/expressButton.component";
import "./resubmissionOfOrderOrWoundSuccessPopup.css";
import GreenCheck from "../../../../../../assets/greenCheck.svg";
import Grid from "@mui/material/Grid";
import { useMediaQuery } from "@mui/material";

interface Props {
  btnAction: Function;
  description1: string;
}
export const ResubmissionOfOrderOrWoundSuccessPopup = ({
  btnAction,
  description1,
}: Props) => {
  const isMobileScreen = useMediaQuery("(max-width:400px)");
  return (
    <div
      className="resubmission-success-pop-up"
      data-testid="resubmission-success-pop-up"
    >
      <h4
        className="resubmission-success-pop-up-title"
        data-testid="resubmission-success-pop-up-title"
        id="id_resubmission-success-pop-up-title"
      >
        Success!
      </h4>
      <Grid container className="resubmission-success-pop-up-description">
        <Grid item xs={isMobileScreen ? 1.5 : 0.8}>
          <div className="resubmission-check-mark-img">
            <img src={GreenCheck} alt={GreenCheck} />
          </div>
        </Grid>
        <Grid item xs={isMobileScreen ? 10 : 11}>
          <p
            className="resubmission-success-pop-up-description1"
            data-testid="resubmission-success-pop-up-description1"
            id="id_resubmission-success-pop-up-description1"
          >
            {description1}
          </p>
        </Grid>
      </Grid>
      <Grid container>
        <ExpressButton
          clickHandler={() => btnAction()}
          parentClass="submitButton"
          testId="doneButton"
          variant="outlined"
        >
          Done
        </ExpressButton>
      </Grid>
    </div>
  );
};
