import {
  Button,
  Checkbox,
  Grid,
  InputBase,
  useMediaQuery,
} from "@mui/material";
import "./myListPrescriber.css";
import {
  ERROR_MSG_ADD_PRESCRIBER,
  ERROR_MSG_GET_PRESCRIBER_DETAILS,
  ERROR_MSG_MYLIST_PRESCRIBERS,
  ERROR_MSG_REMOVE_PRESCRIBER,
  ERROR_MSG_UPDATE_PRESCRIBER,
} from "../../../../util/errorMsg";
import {
  addPrescriber,
  prescriberSearch,
  updatePrescriberInfo,
} from "../../../../util/3meService";
import {
  IAddPrescriberReqBody,
  IMyListPrescriber,
  IPrescriberEmails,
  IRemovePrescriberReqBody,
} from "./myListPrescriber.interface";
import { useDebounce } from "use-debounce";
import {
  makeCapitalEachWordInString,
  useSortableTable,
} from "../../../../util/utilityFunctions";
import {
  RolesPermissionContextType,
  RolesPermissionContext,
} from "../../../../context/RolesPermissionContext";
import { useContext, useEffect, useState } from "react";
import {
  AdminMyListsContext,
  AdminMyListsContextType,
} from "../../../../context/AdministrationMyListsContext";
import AddIcon from "@mui/icons-material/AddCircleOutline";
import { Popup } from "../../../../core/popup/popup.component";
import { getNPIPrescriber } from "../../../../util/npiService";
import { getDeepClone } from "../../../../util/ObjectFunctions";
import { LoadingSpinner } from "../../../../core/loader/LoadingSpinner";
import Table from "../../../../core/customSortingTable/table.component";
import ErrorPopup from "../../../../core/errorPopup/errorPopup.component";
import AddPrescriberContainer from "./addPrescriber/addPrescriber.container";
import { AuthContextType, AuthContext } from "../../../../context/AuthContext";
import { ReusablePopUp } from "../../../../core/resuablePopup/reusablePopUp.component";
import { ExpressButton } from "../../../../core/expressButton/expressButton.component";
import { ReactComponent as CheckedIcon } from "../../../../assets/checkedcheckbox.svg";
import { ReactComponent as UncheckIcon } from "../../../../assets/uncheckedcheckbox.svg";
import { ReactComponent as SearchIconSvg } from "../../../../assets/blackSearchIcon.svg";
import { EditPrescriberEmail } from "./editPrescriberEmail/editPrescriberEmail.component";
import { SearchPrescriberModal } from "../../../newOrder/prescriberInformation/searchPrescriber.enum";
import { ReactComponent as DisabledCheckedIcon } from "../../../../assets/disabled-checked-checkbox.svg";
import { IPrescriberModal } from "../../../newOrder/prescriberInformation/prescriberSearch/prescriberSearch.model";
import { INationalRegistry } from "../../../newOrder/prescriberInformation/nationalRegistrySearch/nationalRegistryResult/nationalRegistry.interface";

export const MyListPrescriber = () => {
  const columns = [
    { label: "Enabled", accessor: "", sortable: false },
    { label: "Prescriber", accessor: "lastName", sortable: true },
    { label: "NPI #", accessor: "npi", sortable: true },
    { label: "eScript?", accessor: "eScript", sortable: true },
    { label: "City", accessor: "city", sortable: true },
    { label: "State", accessor: "state", sortable: false },
    { label: "", accessor: "", sortable: false },
  ];

  // Prescriber Data
  const [prescribersListData, setPrescribersListData, handleSorting] =
    useSortableTable([], columns);

  // Error
  const [errorCode, setErrorCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorPopUpFlag, setErrorPopUpFlag] = useState(false);

  // Spinner
  const [showSpinner, setShowSpinner] = useState(false);

  // Search Prescriber
  const [data, setData] = useState([]);
  const [searchInput, setSearchInput] = useState<string>("");
  const [searchedInput, setSearchedInput] = useState<string>("");
  const [originalPrescriberData, setOriginalPrescriberData] = useState<
    IMyListPrescriber[]
  >([]);

  // Edit Prescriber Email
  const [openEditPrescriberEmailPopUp, setOpenEditPrescriberEmailPopUp] =
    useState<boolean>(false);
  const [selectedPrescriber, setSelectedPrescriber] =
    useState<IPrescriberModal | null>(null);

  // Remove Prescriber
  const [removeConfirmationPopup, setRemoveConfirmationPopup] =
    useState<boolean>(false);
  const [selectedRemovablePrescriber, setSelectedRemovablePrescriber] =
    useState<IMyListPrescriber | null>(null);

  // Debounce
  const [debouncedText] = useDebounce(searchInput, 500);

  // Screensize
  const isMobileScreen = useMediaQuery("(max-width:920px)");

  // Context objects
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const myListObj = useContext<AdminMyListsContextType | null>(
    AdminMyListsContext
  );
  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );

  const searchPrescriber = async () => {
    setShowSpinner(true);
    let reqParams = {
      searchinput: null,
      siteUseId: authObj?.registeredFaciltyAddress?.siteUseId!,
      showAllRecords: true,
    };
    const prescribers = await prescriberSearch(reqParams);
    if (prescribers?.data?.length > 0) {
      const updatedData = prescribers?.data?.sort(
        (a: IMyListPrescriber, b: IMyListPrescriber) => {
          const lastNameA = a.lastName.toLowerCase();
          const lastNameB = b.lastName.toLowerCase();
          if (lastNameA < lastNameB) {
            return -1;
          }
          if (lastNameA > lastNameB) {
            return 1;
          }
          return 0;
        }
      );
      setPrescribersListData(updatedData);
      setOriginalPrescriberData(updatedData);
      setData(updatedData);
    } else if (!prescribers?.succeeded) {
      setErrorPopUpFlag(true);
      setErrorMessage(ERROR_MSG_MYLIST_PRESCRIBERS);
      setErrorCode(prescribers?.error?.code || prescribers?.status);
    }
    setShowSpinner(false);
  };

  const handleSearch = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const re = /^[a-zA-Z0-9-]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setSearchInput(e.target.value);
    }
  };

  const filteredPrescriberList = (searchParam: string, value: string) => {
    setSearchedInput(searchParam);
    if (value.length > 2) {
      const filteredList = originalPrescriberData.filter((x: any) => {
        return (
          x.npi.toLowerCase().includes(value.toLowerCase()) ||
          x.firstName.toLowerCase().includes(value.toLowerCase()) ||
          x.lastName.toLowerCase().includes(value.toLowerCase()) ||
          x.city.toLowerCase().includes(value.toLowerCase())
        );
      });
      setPrescribersListData(filteredList);
    } else if (value.length === 0) {
      setPrescribersListData(originalPrescriberData);
    }
  };

  const handleAddPrescriber = () => {
    myListObj?.setIsFromMyList(true);
    myListObj?.setAddPrescriberPopup(true);
    myListObj?.setAddPrescriberPopupSection(
      SearchPrescriberModal.NATIONAL_REGISTRY_SEARCH
    );
  };

  const handleFilteredPrescribers = (data: IMyListPrescriber[]) => {
    const npisOfPrescribers = originalPrescriberData.map(
      //returns npis array of exisiting prescriber
      (eachMyPrescrbier: IMyListPrescriber) => {
        return eachMyPrescrbier.npi;
      }
    );
    if (data.length > 0) {
      const nonSelectedPrescriberList = data.filter(
        (eachPrescrbier: IMyListPrescriber) => {
          if (!npisOfPrescribers.includes(eachPrescrbier.npi)) {
            return eachPrescrbier;
          }
        }
      );
      myListObj?.setNpiPrescriberTabList(nonSelectedPrescriberList);
      if (nonSelectedPrescriberList.length === 0) {
        myListObj?.setIsPrescriberAddedAlready(true);
      } else myListObj?.setIsPrescriberAddedAlready(false);
    } else {
      myListObj?.setIsPrescriberAddedAlready(false);
    }
  };

  const handleAddPrescriberToDB = async (data: INationalRegistry) => {
    setShowSpinner(true);
    const reqParams: IAddPrescriberReqBody = {
      siteUseId: authObj?.registeredFaciltyAddress?.siteUseId!,
      firstName: data.firstName,
      lastName: data.lastName,
      npi: data.npi,
      city: data.city,
      state: data.state,
    };
    const response = await addPrescriber(reqParams);
    setShowSpinner(false);
    myListObj?.setAddPrescriberPopup(false);
    if (response && response?.succeeded) {
      const newPrescriber = response.data;
      const listUpdatedWithRes = [...originalPrescriberData, newPrescriber];
      const updatedOriginalData = listUpdatedWithRes?.sort(
        (a: IMyListPrescriber, b: IMyListPrescriber) => {
          const lastNameA = a.lastName.toLowerCase();
          const lastNameB = b.lastName.toLowerCase();
          if (lastNameA < lastNameB) {
            return -1;
          }
          if (lastNameA > lastNameB) {
            return 1;
          }
          return 0;
        }
      );
      setSearchInput("");
      setPrescribersListData(updatedOriginalData);
      setOriginalPrescriberData(updatedOriginalData);
    } else {
      setErrorPopUpFlag(true);
      setErrorMessage(ERROR_MSG_ADD_PRESCRIBER);
      setErrorCode(response.error.code);
    }
    myListObj?.setIsFromMyList(false);
  };

  const handleAddPrescriberPopupClose = () => {
    myListObj?.setAddPrescriberPopup(false);
    myListObj?.setIsFromMyList(false);
  };

  // Remove Prescriber
  const handleChangePrescriberCheckBoxValue = async (
    e: any,
    data: IMyListPrescriber
  ) => {
    const value = e.target.checked;
    updateListWithCheckBoxValue(data, value);
    const reqParams: IRemovePrescriberReqBody = {
      siteUseId: authObj?.registeredFaciltyAddress?.siteUseId!,
      npi: data.npi,
      isActive: value,
    };
    const response = await updatePrescriberInfo(reqParams);
    if (response && !response?.succeeded) {
      setErrorPopUpFlag(true);
      setErrorMessage(ERROR_MSG_UPDATE_PRESCRIBER);
      setErrorCode(response.error.code);
      updateListWithCheckBoxValue(data, !value);
    }
  };

  const updateListWithCheckBoxValue = (
    data: IMyListPrescriber,
    value: boolean
  ) => {
    const updatedPrescribersList = prescribersListData.map(
      (eachPrescriber: IMyListPrescriber) => {
        if (data.npi === eachPrescriber.npi) {
          return {
            ...eachPrescriber,
            isActive: value,
          };
        }
        return eachPrescriber;
      }
    );
    const updtedOriginalPrescribersList = originalPrescriberData.map(
      (eachMyPrescrbier: IMyListPrescriber) => {
        if (data.npi === eachMyPrescrbier.npi) {
          return {
            ...eachMyPrescrbier,
            isActive: value,
          };
        } else return eachMyPrescrbier;
      }
    );
    setPrescribersListData(updatedPrescribersList);
    setOriginalPrescriberData(updtedOriginalPrescribersList);
  };

  const handleRemovePrescriber = async () => {
    setShowSpinner(true);
    setRemoveConfirmationPopup(false);
    const reqParams: IRemovePrescriberReqBody = {
      siteUseId: authObj?.registeredFaciltyAddress?.siteUseId!,
      npi: selectedRemovablePrescriber?.npi!,
      isRemoved: true,
    };
    const response = await updatePrescriberInfo(reqParams);
    setShowSpinner(false);
    if (response && response?.succeeded) {
      const filteredList = prescribersListData.filter(
        (eachPrescriber: IMyListPrescriber) => {
          if (eachPrescriber.npi !== selectedRemovablePrescriber?.npi) {
            return eachPrescriber;
          }
        }
      );
      const filteredOriginalPrescriberList = originalPrescriberData.filter(
        (eachPrescriber: IMyListPrescriber) => {
          if (eachPrescriber.npi !== selectedRemovablePrescriber?.npi) {
            return eachPrescriber;
          }
        }
      );
      setPrescribersListData(filteredList);
      setOriginalPrescriberData(filteredOriginalPrescriberList);
    } else {
      setErrorPopUpFlag(true);
      setErrorMessage(ERROR_MSG_REMOVE_PRESCRIBER);
      setErrorCode(response.status);
    }
    setSelectedRemovablePrescriber(null);
  };

  const handleRemoveButtonClick = (data: IMyListPrescriber) => {
    setSelectedRemovablePrescriber(data);
    setRemoveConfirmationPopup(true);
  };

  // Edit Prescriber Email Address
  const getPrescriberDetails = async (prescriber: IMyListPrescriber) => {
    let reqParams = {
      NPI: prescriber.npi,
    };
    const response = await getNPIPrescriber(reqParams);
    setShowSpinner(false);
    if (response.succeeded) {
      if (response.items.length > 0) {
        const prescriberData: IPrescriberModal = response.items[0];
        if (prescriber.emails) {
          prescriberData.emails = prescriber.emails.map(
            (email: IPrescriberEmails) => {
              email.type = 0;
              return email;
            }
          );
        }
        prescriberData.eScript = prescriber.eScript;
        setSelectedPrescriber(getDeepClone(prescriberData));
        setOpenEditPrescriberEmailPopUp(true);
      } else {
        // Show error message for no prescriber found.
      }
    } else {
      // Show Error Pop Up.
      setErrorPopUpFlag(true);
      setErrorMessage(ERROR_MSG_GET_PRESCRIBER_DETAILS);
      setErrorCode(response.error.errorCode || response.status);
    }
  };

  const openEditPrescriberEmail = (prescriber: IMyListPrescriber) => {
    setShowSpinner(true);
    getPrescriberDetails(prescriber);
  };

  const closeEditPrescriberEmail = (isSuccess: boolean = false) => {
    setOpenEditPrescriberEmailPopUp(false);
    if (isSuccess) {
      setSearchInput("");
      searchPrescriber();
    }
  };

  useEffect(() => {
    if (
      (debouncedText.length === 0 && searchedInput !== debouncedText) ||
      debouncedText.length > 0
    ) {
      filteredPrescriberList(debouncedText, searchInput);
    }
  }, [debouncedText]);

  useEffect(() => {
    searchPrescriber();
  }, []);

  const spinner = () => {
    return (
      <div className="myList-prescriber-spinner">
        <LoadingSpinner />
      </div>
    );
  };

  return (
    <Grid container className="myList-prescriber-main-container">
      <Grid
        item
        xs={isMobileScreen ? 11 : 8}
        className="myList-user-searchbar"
        data-testid="myList-user-searchbar"
      >
        <div className="myList-prescriber-searchbar">
          <div className="myList-prescriber-icon-div">
            <SearchIconSvg
              className="prescriber-search-icon"
              data-testid="prescriber-search-icon-test"
            />
          </div>
          <InputBase
            autoFocus={true}
            className="myList-prescriber-search-input"
            data-testid="prescriber-search-text-field"
            name="prescriberInputSearch"
            onChange={handleSearch}
            placeholder="Filter Prescriber List by Name, City, or NPI number"
            value={searchInput}
          />
        </div>
      </Grid>
      <Grid
        item
        xs={isMobileScreen ? 12 : 3}
        className="myList-user-add-button"
      >
        <Button
          classes={{ root: "myList-add-new-hcp-btn" }}
          startIcon={<AddIcon />}
          onClick={handleAddPrescriber}
          disabled={permissionObj?.mappedRolesPermissionData.IsSupportRole}
          data-testid="add-prescriber-button"
        >
          Add New Prescriber
        </Button>
      </Grid>
      <Grid
        item
        xs={12}
        className="myList-prescribers-table-grid"
        data-testid="myList-prescribers-table-grid"
      >
        <Table
          tableClassName="myList-prescribers-table"
          tableColumns={columns}
          handleSorting={handleSorting}
        >
          {prescribersListData.length > 0 && (
            <tbody>
              {prescribersListData.map((data: IMyListPrescriber) => {
                return (
                  <tr>
                    <td>
                      <Checkbox
                        checkedIcon={
                          permissionObj?.mappedRolesPermissionData
                            .IsSupportRole ? (
                            <DisabledCheckedIcon />
                          ) : (
                            <CheckedIcon />
                          )
                        }
                        checked={data.isActive}
                        disabled={
                          permissionObj?.mappedRolesPermissionData.IsSupportRole
                        }
                        icon={<UncheckIcon />}
                        onClick={(e: any) =>
                          handleChangePrescriberCheckBoxValue(e, data)
                        }
                        value={data.isActive}
                        required={false}
                      />
                    </td>
                    <td
                      className="myList-prescribers-name"
                      onClick={() => openEditPrescriberEmail(data)}
                    >
                      {`${makeCapitalEachWordInString(
                        data.lastName
                      )}, ${makeCapitalEachWordInString(data.firstName)}`}
                    </td>
                    <td className="myList-prescribers-table-static-data">
                      {data.npi}
                    </td>
                    <td className="myList-prescribers-table-static-data">
                      {data.eScript}
                    </td>
                    <td className="myList-prescribers-table-static-data">
                      {makeCapitalEachWordInString(data.city)}
                    </td>
                    <td className="myList-prescribers-table-static-data">
                      {data.state}
                    </td>
                    <td>
                      <ExpressButton
                        variant="text"
                        parentClass="myList-remove-link"
                        clickHandler={() => handleRemoveButtonClick(data)}
                        disabled={
                          permissionObj?.mappedRolesPermissionData.IsSupportRole
                        }
                      >
                        Remove
                      </ExpressButton>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          )}
        </Table>
      </Grid>
      {showSpinner && (
        <Popup
          hideCloseButton={true}
          openFlag={showSpinner}
          closeHandler={() => {}}
        >
          {spinner()}
        </Popup>
      )}
      <ErrorPopup
        errorPopupFlag={errorPopUpFlag}
        handleBackButton={() => setErrorPopUpFlag(false)}
        popUpStyles="errorPopup"
        errorMessage={errorMessage}
        buttontitle="Done"
        showTitle={false}
        isShortSentence={true}
        errorCode={errorCode}
      />
      <Popup
        closeHandler={() => closeEditPrescriberEmail()}
        dialogParentClass="edit-prescriber-email-address-pop-up"
        openFlag={openEditPrescriberEmailPopUp}
      >
        <EditPrescriberEmail
          prescriberData={selectedPrescriber!}
          setPrescriberData={setSelectedPrescriber}
          closeEditPrescriberEmailUpdate={closeEditPrescriberEmail}
        />
      </Popup>
      <Popup
        openFlag={myListObj?.addPrescriberPopup}
        closeHandler={handleAddPrescriberPopupClose}
        dialogParentClass={myListObj?.addPrescriberPopupSection}
      >
        <AddPrescriberContainer
          handleFilteredPrescribers={handleFilteredPrescribers}
          handleAddPrescriberToDB={handleAddPrescriberToDB}
        />
      </Popup>
      <ReusablePopUp
        openFlag={removeConfirmationPopup}
        closeHandler={() => {
          setRemoveConfirmationPopup(false);
        }}
        firstButtonHandler={() => {
          setRemoveConfirmationPopup(false);
        }}
        secondButtonHandler={handleRemovePrescriber}
        firstButtonText="Cancel"
        secondButtonText="Remove Prescriber"
        title="Confirm Prescriber Removal"
        rowAlignment={true}
      >
        <Grid container className="remove-popup-content">
          <Grid item xs={12}>
            <div className="remove-popup-header margin-bottom">
              Removing{" "}
              <span className="remove-popup-header-prescriber">
                {selectedRemovablePrescriber?.lastName +
                  " " +
                  selectedRemovablePrescriber?.firstName}{" "}
                {`(NPI# ${selectedRemovablePrescriber?.npi}) `}
              </span>
              will only remove them from your Prescribers list. Any orders
              associated with them will not be altered.
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="remove-popup-header">
              You can re-add this prescriber by choosing the Add New Prescriber
              button or when placing a new order.
            </div>
          </Grid>
        </Grid>
      </ReusablePopUp>
    </Grid>
  );
};
