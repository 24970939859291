export enum InventoryContainerTabs {
  VAC_READY_CARE_PROGRAM_LISTING = "vac-ready-care-program-listing",
  STORED_PRODUCT_LISTING = "stored-product-listing",
  CUSTOMER_PLUS_LISTING = "customer-plus-listing",
}

export interface IInventoryProductDetails {
  product: string;
  serialNumber: string;
  storageLocation: string;
  storageDays: number;
  status: string;
  workOrderNumber: number;
  storeAndClean: string;
  menuItems?: IInventoryMenuActionItem[];
  unitTagId?: string;
  id?: number;
  isPickupPending?: boolean | null;
  errorCode?: number;
  productName: string;
}

export interface IInventoryMenuActionItem {
  sequenceNo: number;
  sequenceText: string;
}

export enum SPMenuActionTypes {
  MOVE_UNIT_VAC_READY_CARE = "Move Unit To VAC Ready Care",
  PLACE_ON_PATIENT = "Place on Patient",
  RETURN_UNIT_TO_SOLVENTUM = "Return Unit to Solventum",
  UPDATE_STORAGE_LOCATION = "Update Storage Location",
}

export enum InventoryTabListingSection {
  STORED_PRODUCT_LISTING_SECTION = "stored-product-listing",
  VAC_READY_CARE_PROGRAM_LISTING_SECTION = "vac-ready-care-product-listing",
  CUSTOMER_PLUS_LISTING = "customer-plus-listing",
}

export enum VacReadyCareMenuActionTypes {
  UPDATE_STORAGE_LOCATION = "Update Storage Location",
  MOVE_UNIT_TO_STORED_PRODUCT = "Move Unit To Stored Product",
  REMOVE_FROM_INVENTORY = "Remove from Inventory",
}

export enum CustomerPlusMenuActionTypes {
  PLACE_ON_PATIENT = "Place on Patient",
  UPDATE_STORAGE_LOCATION = "Update Storage Location",
  REQUEST_SERVICE = "Request Service",
  CREATE_TRANSITION_ORDER = "Create Transition Order",
  END_THERAPHY = "End Therapy",
  MAKE_AVAILABLE_FOR_PLACEMENT = "Make Available for Placement",
}

export interface ICustomerPlusListingReqBody {
  siteUseId: string;
  customerPlusFlag: boolean;
}

export type ISelectedInventoryTab =
  | "stored-product-listing"
  | "vac-ready-care-program-listing"
  | "customer-plus-listing"
  | undefined
  | null;

export interface ISolventumProduct {
  productDesc: string;
  uniqueKey: string;
  priceListId: number;
  inventoryId: number;
  rentalTerm: string;
  listPrice: number;
  discountPrice: number;
  discountId: number;
  serviceLevel: string;
  sku: string;
  productImage: string;
  productCompleteDescrption: string;
  serialNumber?: string;
}
