import { useContext, useEffect, useState } from "react";
import Table from "../../../core/customSortingTable/table.component";
import {
  agGridGetRowStyle,
  determineReadyCareFlag,
  getCodeFromText,
  makeCapitalEachOfWordInString,
  makeCapitalEachWordInString,
  showVacOrderMenuOption,
} from "../../../util/utilityFunctions";
import { selectFacility } from "../../../util/userService";

import { IoMdArrowDropright, IoMdArrowDropleft } from "react-icons/io";
import { updateFavouriteFacility } from "../../../util/userService";
import Favorite from "../../../assets/Favorite.png";
import NotFavourite from "../../../assets/NotFavourite.svg";

import { AuthContextType, AuthContext } from "../../../context/AuthContext";
import { searchFacility } from "../../../components/manageProfile/facilityInformation/addFacility/addFacility.service";
import { mapUserRolesAndPermissionData } from "../../../RolesPermission/RolesPermission.Mapper";
import {
  RolesPermissionContext,
  RolesPermissionContextType,
} from "../../../context/RolesPermissionContext";
import { ISideNav } from "../../../components/sideNav/sideNavMenuOption.interface";
import { useHistory, useLocation } from "react-router-dom";
import "./searchFacilityResult.css";
import "react-loading-skeleton/dist/skeleton.css";
import Skeleton from "react-loading-skeleton";
import { SearchFacilityTableFilter } from "../../sales/searchFacilityTableFilter.component";
import { format } from "react-string-format";
import {
  DD_CARE_SETTING_CONTENT,
  DD_CLASSIFICATION_CONTENT,
} from "../../../util/staticText";
import { getdropDownContent } from "../../../util/dropDownService";
import { defaultSalesRoleTestData } from "../../sales/salesRole.test.data";
import { ISalesRole } from "../../sales/salesRole.interface";
import { getTextFromCode } from "../../../util/utilityFunctions";
import { ExpressButton } from "../../../core/expressButton/expressButton.component";
import { ReactComponent as CheckIcon } from "../../../assets/checkMark.svg";

import { CustomDropDown } from "../../../core/customDropdown/customDropdown.component";
import {
  caregiverFacilitySearch,
  checkPostAcuteFacility,
  getCareGiverId,
} from "../../../util/3meService";
import { LoadingSpinner } from "../../../core/loader/LoadingSpinner";
import { Popup } from "../../../core/popup/popup.component";
import ErrorPopup from "../../../core/errorPopup/errorPopup.component";
import { useMediaQuery } from "@mui/material";
import { AgGridReact } from "ag-grid-react";

interface searchFacilityResultInterface {
  sortedData: any;
  setSortedData: any;
  handleSorting: any;
  columns: any;
  isLoading: any;
  data: any;
  salesRole: any;
  isSearchSelected: any;
  setIsSearchSelected: any;
  setData?: any;
}

const SearchFacilityResult = ({
  sortedData,
  setSortedData,
  handleSorting,
  columns,
  isLoading,
  data,
  salesRole,
  isSearchSelected,
  setIsSearchSelected,
  setData,
}: searchFacilityResultInterface) => {
  const isMobileScreen = useMediaQuery("(max-width:920px)");
  const filteredColumns = () => {
    if (isMobileScreen) {
      return columns.filter((column: any) => column.label !== "");
    }
    return columns;
  };
  const AuthObj = useContext<AuthContextType | null>(AuthContext);
  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );
  const checkForSalesRole =
    permissionObj?.mappedRolesPermissionData.IsSalesManagerRole ||
    permissionObj?.mappedRolesPermissionData.IsSalesRole;

  const [classification, setClassification] = useState([]);
  const [classificationText, setClassificationText] = useState([]);
  const [careSetting, setCareSetting] = useState([]);
  const [careSettingText, setCareSettingText] = useState([]);
  const [searchInput, setSearchInput] = useState<string>("");
  const [salesRolesData, setSalesRolesData] = useState(
    defaultSalesRoleTestData
  );
  const history = useHistory();
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState<string | null>(null);
  const [loader, setLoader] = useState(false);
  const [currentPage2, setCurrentPage2] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState<any>(25);
  const [currentPosts, setCurrentPost] = useState<any>();
  const [numberOfPages, setNumberOfPages] = useState<any[]>([]);
  const [currentButton, setCurrentButton] = useState(1);
  const [arrOfCurrButtons, setArrOfCurrButtons] = useState<any[]>([]);
  const [totalLength, setTotalLenght] = useState<any[]>([]);
  const [openErrorPopup, setOpenErrorPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  //For AG-Grid Mobile
  const defaultColDef = {
    flex: 1,
    minWidth: 130,
    filter: false,
    sortable: true,
    unSortIcon: true,
  };
  const autoGroupColumnDef = {
    minWidth: 200,
    cellRenderer: "agGroupCellRenderer",
    cellRendererParams: {
      suppressCount: true,
      checkbox: true,
    },
  };

  const columnDefs: any = [
    {
      headerName: "",
      field: "orderID",
      sortable: false,
      hide: true,
      suppressMovable: true,
    },
    {
      field: "accountName",
      headerName: "Facility Site Name",
      sortable: true,
      pinned: "left",
      lockPinned: true,
      width: 200,
      wrapText: true,
      autoHeight: true,
      suppressMovable: true,
      cellRenderer: function (params: any) {
        return (
          <>
            <span
              className={"fav-facility-name-mobile-screen"}
              id={params.data.siteUseId}
              onClick={(e) => handleSelectClick(e, params.data.isFavourite)}
            >
              {makeCapitalEachWordInString(params.data.accountName)}
            </span>
            <br />
            <span>
              {makeCapitalEachWordInString(params.data.address1)},{" "}
              {params.data.address2
                ? makeCapitalEachWordInString(params.data.address2) + ","
                : ""}{" "}
              {makeCapitalEachWordInString(params.data.city)},{" "}
              {makeCapitalEachOfWordInString(params.data.state)},{" "}
              {makeCapitalEachWordInString(params.data.zip)}
            </span>
          </>
        );
      },
    },
    {
      field: "isFavourite",
      headerName: "Favorite",
      sortable: true,
      filter: false,
      suppressMovable: true,
      cellRenderer: function (params: any) {
        return (
          <div
            className="search-result-imgclick"
            data-testid="selectFacilityFavouritetest"
          >
            {params.data.isFavourite ? (
              <img
                src={Favorite}
                alt={Favorite}
                onClick={() => handleFavLink(params.data, true)}
              ></img>
            ) : (
              <img
                src={NotFavourite}
                alt={NotFavourite}
                onClick={() => handleFavLink(params.data, false)}
              ></img>
            )}
          </div>
        );
      },
    },
    {
      field: "siteUseCode",
      headerName: "Site Use",
      sortable: true,
      filter: false,
      suppressMovable: true,
      hide: salesRole ? true : false,
    },
    {
      field: "accountNumber",
      headerName: "Facility No.",
      sortable: true,
      filter: false,
      suppressMovable: true,
    },
    {
      field: "readyCareFlag",
      headerName: "Ready Care",
      sortable: true,
      filter: false,
      suppressMovable: true,
      cellRenderer: function (params: any) {
        return (
          <>
            {determineReadyCareFlag(params.data.readyCareFlag) === "Yes" ? (
              <CheckIcon />
            ) : (
              ""
            )}
          </>
        );
      },
    },
    {
      field: "typeName",
      headerName: "Classification",
      sortable: true,
      filter: false,
      width: 200,
      wrapText: true,
      autoHeight: true,
      suppressMovable: true,
      cellRenderer: function (params: any) {
        return (
          <span className="classificationText">
            {makeCapitalEachWordInString(`${params.data.typeName}`)}
          </span>
        );
      },
    },
    {
      headerName: "Setting",
      field: "careSetting",
      sortable: true,
      suppressMovable: true,
      cellRenderer: function (params: any) {
        return (
          <span className="classificationText">
            {makeCapitalEachWordInString(`${params.data.careSetting}`)}
          </span>
        );
      },
    },
  ];

  const handleErrorPopup = () => {
    setOpenErrorPopup(false);
  };

  const fetchDropDownContent = async () => {
    //async and await
    try {
      const ddContent = format(
        "{0},{1}",

        DD_CLASSIFICATION_CONTENT,
        DD_CARE_SETTING_CONTENT ?? ""
      );
      const data = await getdropDownContent(ddContent);
      if (data.items.length > 0) {
        const classificationObject = data.items.filter(
          (item: { name: string }) => item.name === DD_CLASSIFICATION_CONTENT
        );
        const classificationData = classificationObject[0].data.sort(
          (a: { order: number }, b: { order: number }) =>
            a.order > b.order ? 1 : -1
        );
        setClassification(classificationData);
        setClassificationText(
          classificationData.map((x: { text: string }) => x.text)
        );
        const careSettingObject = data.items.filter(
          (item: { name: string }) => item.name === DD_CARE_SETTING_CONTENT
        );
        const careSettingData = careSettingObject[0].data.sort(
          (a: { order: number }, b: { order: number }) =>
            a.order > b.order ? 1 : -1
        );
        setCareSetting(careSettingData);
        setCareSettingText(
          careSettingData.map((x: { text: string }) => x.text)
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const updateFavFacility = async (selectedData: any, isFavourite: boolean) => {
    const updateFacilities = await updateFavouriteFacility(
      AuthObj?.userProfile?.userName,
      selectedData.siteUseId,
      isFavourite ? FavoriteType.REMOVEFAVORITE : FavoriteType.ADDFAVORITE
    );
    if (updateFacilities) {
      const newArr = sortedData.map((obj: any) => {
        if (obj.siteUseId === selectedData.siteUseId) {
          return { ...obj, isFavourite: !isFavourite };
        }
        return obj;
      });
      const updatedArray = data.map((obj: any) => {
        newArr.map((x: any) => {
          if (x.siteUseId === obj.siteUseId) {
            obj.isFavourite = x.isFavourite;
          }
        });
        return obj;
      });
      setData(updatedArray);
      setSortedData(newArr);
    }
  };

  const handleFavLink = (data: any, isFavourite: boolean) => {
    updateFavFacility(data, isFavourite);
  };

  const handleSelectClick = async (e: any, isFavourite: boolean) => {
    AuthObj?.setUpdateFacilityBannerFacility(true);
    const reqBody = {
      UserName: AuthObj?.userProfile?.userName,
      SiteUseId: e.target.id,
    };
    if (!loader) {
      setLoader(true);
    }
    const userPermissions = await selectFacility(reqBody);
    const data = sortedData.filter((x: any) => x.siteUseId === e.target.id)[0];
    AuthObj?.setuserRolePermissionData(userPermissions);
    await searchFacilityAddr(data, userPermissions, isFavourite);
    setLoader(false);
  };

  const searchFacilityAddr = async (
    data: any,
    userPermissions: any,
    isFavourite: boolean
  ) => {
    let tempSideNavMenuOption: ISideNav;
    let readyCareFlag = "N";

    var facilitySearchRequest = {
      customerNumber: data.accountNumber,
      customerName: "",
      postalCode: "",
    };
    const searchedFacilities = await searchFacility(facilitySearchRequest);
    if (searchedFacilities.length > 0) {
      const facilityRes = searchedFacilities.filter((x) => {
        if (x.siteUseId === data.siteUseId.toString()) {
          return x;
        }
      })[0];

      readyCareFlag = facilityRes.readyCareFlag!;
      facilityRes.isFavourite = isFavourite;
      // Set CareGiverId
      if (facilityRes.siteUseCode && facilityRes.siteUseCode?.includes("CG")) {
        const reqBody = {
          customerAccountNumber: facilityRes.accountNumber?.toString(),
        };
        const response = await caregiverFacilitySearch(reqBody);
        if (response && response.items && response.items.length > 0) {
          let homeCareGiverDetails = response.items;
          const homeCareGiverDetail = homeCareGiverDetails[0];
          if (facilityRes.siteUseCode?.includes("ST/CG")) {
            facilityRes.careGiverSiteUseId = homeCareGiverDetail.siteUseId;
            facilityRes.careGiverId = homeCareGiverDetail.origSystemReference;
          } else {
            facilityRes.careGiverId = homeCareGiverDetail.origSystemReference;
          }
        }
      }
      facilityRes.isPostAcute = (
        await checkPostAcuteFacility({
          FacilityTypeCode: facilityRes.typeCode,
        })
      ).postAcuteFound;
      AuthObj?.setregisteredFaciltyAddress(facilityRes);

      const rolesPermissionRes = await mapUserRolesAndPermissionData(
        userPermissions,
        readyCareFlag
      );

      permissionObj?.setMappedRolesPermissionData(rolesPermissionRes);
      let adminText: any = "Administration";
      if (
        (AuthObj?.unLinkedFacilitesCount && rolesPermissionRes.Is3MAdminRole) ||
        (AuthObj?.unLinkedFacilitesCount &&
          rolesPermissionRes.IsProdManagerRole) ||
        (AuthObj?.unLinkedFacilitesCount && rolesPermissionRes.IsSupportRole)
      ) {
        adminText = (
          <div className="sideNavmadminBtnMain">
            Administration
            <span className="sideNavmadminBtnStyle">
              {AuthObj?.unLinkedFacilitesCount}{" "}
            </span>{" "}
          </div>
        );
      }
      tempSideNavMenuOption = {
        ...AuthObj?.sideNavMenuOptionModelData!,
        orders: {
          labelText: "Start New Order",
          isVisible: showVacOrderMenuOption(rolesPermissionRes),
        },
        inventory: {
          labelText: "Inventory",
          isVisible: rolesPermissionRes.IsShowInventoryOption,
        },
        administration: {
          labelText: adminText,
          isVisible: rolesPermissionRes.IsShowAdminstrationOption,
        },
      };
      if (currentPath === "/ssoRedirect") {
        localStorage.setItem("isComingFromSSO", "false");
      }
      AuthObj?.setsideNavMenuOptionModelData(tempSideNavMenuOption!);
      AuthObj?.setuserRolesAndPermissionLoaded(true);
      AuthObj?.setIsInternalUserFacilitySelected(true);
      history.push("/home");
    } else {
      setLoader(false);
      setOpenErrorPopup(true);
      setErrorMessage(
        "Your request to search for facility has failed since it is classified as a Caregiver. We are working to complete the required functionality so that you can soon view these facilities and the associated orders."
      );
    }
  };

  const validateAndSetData = (e: any) => {
    let filteredData = data;
    let { value, name } = e.target;
    if (name === "FacilityClassification") {
      value = getCodeFromText(classification, value);
      if (value !== "0") {
        if (
          salesRolesData.CareSetting.value !== "" &&
          salesRolesData.CareSetting.value !== "0"
        ) {
          filteredData = data.filter(
            (x: any) =>
              x.typeCode === value &&
              x.careSetting.toLowerCase() ===
                getTextFromCode(
                  careSetting,
                  salesRolesData.CareSetting.value
                ).toLowerCase()
          );
        } else {
          filteredData = data.filter((x: any) => x.typeCode === value);
        }
      } else {
        if (
          salesRolesData.CareSetting.value !== "" &&
          salesRolesData.CareSetting.value !== "0"
        ) {
          filteredData = data.filter(
            (x: any) =>
              x.careSetting.toLowerCase() ===
              getTextFromCode(
                careSetting,
                salesRolesData.CareSetting.value
              ).toLowerCase()
          );
        } else {
          filteredData = data;
        }
      }
    } else {
      if (value !== "All care settings") {
        if (
          salesRolesData.FacilityClassification.value !== "" &&
          salesRolesData.FacilityClassification.value !== "0"
        ) {
          filteredData = data.filter(
            (x: any) =>
              x.careSetting.toLowerCase() === value.toLowerCase() &&
              x.typeCode === salesRolesData.FacilityClassification.value
          );
        } else {
          filteredData = data.filter(
            (x: any) => x.careSetting.toLowerCase() === value.toLowerCase()
          );
        }
      } else {
        if (
          salesRolesData.FacilityClassification.value !== "" &&
          salesRolesData.FacilityClassification.value !== "0"
        ) {
          filteredData = data.filter(
            (x: any) =>
              x.typeCode === salesRolesData.FacilityClassification.value
          );
        } else {
          filteredData = data;
        }
      }
      value = getCodeFromText(careSetting, value);
    }
    setSortedData(filteredData);
    setSalesRolesData((salesRolesData: ISalesRole) => ({
      ...salesRolesData,
      [name]: {
        value: value,
        valid: true,
        required: true,
      },
    }));
  };

  const handleFacilitySearch = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const re = checkForSalesRole ? /^[a-zA-Z0-9-\s]+$/ : /^[a-zA-Z0-9-]+$/;
    const { value } = e.target;
    if (value === "" || re.test(value)) {
      if (value === " " && searchInput.length === 0) {
        setSearchInput("");
      } else setSearchInput(e.target.value);

      if (e.target.value.length > 2) {
        const filteredList = data.filter((x: any) => {
          return (
            x.accountName.toLowerCase().includes(value.toLowerCase()) ||
            x.accountNumber.toLowerCase().includes(value.toLowerCase())
          );
        });
        setSortedData(filteredList);
      } else if (e.target.value.length === 0) {
        setSortedData(data);
      }
    }
  };

  const getFacilityForCurrentPage = () => {
    const indexOfLastPost = currentButton * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    if (sortedData.slice(indexOfFirstPost, indexOfLastPost).length === 0) {
      setCurrentButton(1);
      const indexOfLastPost = currentPage2 * postsPerPage;
      const indexOfFirstPost = indexOfLastPost - postsPerPage;
      const temp = sortedData.slice(indexOfFirstPost, indexOfLastPost);
      setCurrentPost(temp);
      setTotalLenght(temp ? temp : 0);
    } else if (
      sortedData.slice(indexOfFirstPost, indexOfLastPost).length !== 0
    ) {
      const temp = sortedData.slice(indexOfFirstPost, indexOfLastPost);
      setCurrentPost(temp);
      setTotalLenght(temp ? temp : 0);
    }
  };

  const getPageNumbers = (pageNumber: any) => {
    const howManyPages = Math.ceil(sortedData.length / postsPerPage);
    const pages: any[] = [];
    for (let i = 1; i <= howManyPages; i++) {
      pages.push(i);
    }
    setNumberOfPages(pages);
    var tempNumberOfPages: any[] = [];
    tempNumberOfPages = [...arrOfCurrButtons];
    let dotsInitial = "...";
    let dotsLeft = "... ";
    let dotsRight = " ...";
    if (pages.length < 6) {
      tempNumberOfPages = pages;
    } else if (pageNumber >= 1 && pageNumber <= 3) {
      tempNumberOfPages = [1, 2, 3, 4, dotsInitial, pages.length];
    } else if (pageNumber === 4) {
      const sliced = pages.slice(0, 4);
      tempNumberOfPages = [...sliced, dotsInitial, pages.length];
    } else if (pageNumber > 4 && pageNumber < pages.length - 2) {
      // from 5 to 8 -> (10 - 2)
      const sliced1 = pages.slice(pageNumber - 2, pageNumber);
      // sliced1 (5-2, 5) -> [4,5]
      const sliced2 = pages.slice(pageNumber, pageNumber + 1);
      // sliced1 (5, 5+1) -> [6]
      tempNumberOfPages = [
        1,
        dotsLeft,
        ...sliced1,
        ...sliced2,
        dotsRight,
        pages.length,
      ];
      // [1, '...', 4, 5, 6, '...', 10]
    } else if (pageNumber > pages.length - 3) {
      // > 7
      const sliced = pages.slice(pages.length - 4);
      // slice(10-4)
      tempNumberOfPages = [1, dotsLeft, ...sliced];
    } else if (pageNumber === dotsInitial) {
      // [1, 2, 3, 4, "...", 10].length = 6 - 3  = 3
      // arrOfCurrButtons[3] = 4 + 1 = 5
      // or
      // [1, 2, 3, 4, 5, "...", 10].length = 7 - 3 = 4
      // [1, 2, 3, 4, 5, "...", 10][4] = 5 + 1 = 6
      setCurrentButton(arrOfCurrButtons[arrOfCurrButtons.length - 3] + 1);
    } else if (pageNumber === dotsRight) {
      setCurrentButton(arrOfCurrButtons[3] + 2);
    } else if (pageNumber === dotsLeft) {
      setCurrentButton(arrOfCurrButtons[3] - 2);
    }
    setArrOfCurrButtons(tempNumberOfPages);
  };

  const handleSelectChange = (e: any) => {
    e.preventDefault();
    setPostsPerPage(e.target.value);
  };

  const handlePrevClick = (prev: any) => {
    setCurrentButton((prev) => (prev <= 1 ? prev : prev - 1));
  };

  const handleNextClick = (prev: any) => {
    setCurrentButton((prev) =>
      prev >= numberOfPages.length ? prev : prev + 1
    );
  };

  useEffect(() => {
    fetchDropDownContent();
    if (location.pathname !== currentPath) {
      setCurrentPath(location.pathname);
    }
  }, []);

  useEffect(() => {
    if (isSearchSelected) {
      setSalesRolesData(defaultSalesRoleTestData);
      setSearchInput("");
    }
  }, [isLoading]);

  useEffect(() => {
    getFacilityForCurrentPage();
    getPageNumbers(currentButton);
  }, [
    currentButton,
    postsPerPage,
    JSON.stringify(arrOfCurrButtons),
    JSON.stringify(sortedData),
  ]);

  const Spinner = () => {
    return (
      <div className="facility-spinner">
        <LoadingSpinner />
      </div>
    );
  };
  return (
    <div className="searchFacilityResult-subMain">
      <div
        className="search-facility-result"
        data-testid="search-facility-result"
      >
        {`Search Results (${sortedData.length})`}
      </div>
      <Popup closeHandler={Spinner} openFlag={loader} hideCloseButton={true}>
        <div className="facility-spinner">
          <LoadingSpinner />
        </div>
      </Popup>
      <SearchFacilityTableFilter
        validateAndSetData={validateAndSetData}
        classification={classification}
        careSetting={careSetting}
        classificationText={classificationText}
        careSettingText={careSettingText}
        searchInput={searchInput}
        salesRolesData={salesRolesData}
        handleFacilitySearch={handleFacilitySearch}
        salesRole={salesRole}
      />

      {isMobileScreen && !isLoading ? (
        <div className="ag-theme-quartz" style={{ height: 500 }}>
          <AgGridReact
            getRowStyle={agGridGetRowStyle}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            autoGroupColumnDef={autoGroupColumnDef}
            rowData={currentPosts}
            suppressRowClickSelection={true}
            suppressDragLeaveHidesColumns={true}
          />
        </div>
      ) : (
        <div
          className="tables_container"
          data-testid="search-facility-result-test"
        >
          <Table
            tableClassName="tables"
            tableColumns={filteredColumns()}
            handleSorting={handleSorting}
          >
            {isLoading ? (
              [...Array(10)].map((_) => (
                <tr>
                  <td className="select-result-facility">
                    <Skeleton />
                  </td>
                  <td className="search-result-tables-static-data">
                    <Skeleton />
                  </td>
                  <td className="search-result-tables-static-data">
                    <Skeleton />
                  </td>
                  <td className="search-result-tables-static-data">
                    <Skeleton />
                  </td>
                  <td className="search-result-tables-static-data">
                    <Skeleton />
                  </td>
                  <td className="search-result-tables-static-data">
                    <Skeleton />
                  </td>
                  <td className="search-result-tables-static-data">
                    <Skeleton />
                  </td>
                </tr>
              ))
            ) : (
              <>
                {columns && currentPosts && currentPosts.length > 0 ? (
                  <tbody>
                    {currentPosts.map((data: any) => {
                      return (
                        <tr key={data.id}>
                          {!isMobileScreen && (
                            <td
                              className="select-result-facility"
                              id={data.siteUseId}
                              onClick={(e) =>
                                handleSelectClick(e, data.isFavourite)
                              }
                              data-testid="selectLinktest"
                            >
                              Select
                            </td>
                          )}

                          <td
                            className={
                              isMobileScreen
                                ? "facility-name-mobileScreen"
                                : "search-result-tables-static-data"
                            }
                            data-testid="selectFacilityAccountNametest"
                          >
                            <div
                              className={
                                isMobileScreen
                                  ? "facilityName-mobileScreen"
                                  : ""
                              }
                            >
                              <span
                                className={
                                  isMobileScreen
                                    ? "search-facility-name-mobile-screen"
                                    : ""
                                }
                                id={data.siteUseId}
                                onClick={
                                  isMobileScreen
                                    ? (e) =>
                                        handleSelectClick(e, data.isFavourite)
                                    : () => {}
                                }
                              >
                                {makeCapitalEachWordInString(data.accountName)}
                              </span>
                              <br />
                              <span>
                                {data.address1
                                  ? makeCapitalEachWordInString(data.address1) +
                                    ","
                                  : ""}{" "}
                                {data.address2
                                  ? makeCapitalEachWordInString(data.address2) +
                                    ","
                                  : ""}{" "}
                                {makeCapitalEachWordInString(data.city)},{" "}
                                {makeCapitalEachOfWordInString(data.state)},{" "}
                                {makeCapitalEachWordInString(data.zip)}
                              </span>
                            </div>
                          </td>

                          <td className="search-result-tables-static-data">
                            <div
                              className="search-result-imgclick"
                              data-testid="selectFacilityFavouritetest"
                            >
                              {data.isFavourite ? (
                                <img
                                  src={Favorite}
                                  alt={Favorite}
                                  onClick={() => handleFavLink(data, true)}
                                ></img>
                              ) : (
                                <img
                                  src={NotFavourite}
                                  alt={NotFavourite}
                                  onClick={() => handleFavLink(data, false)}
                                ></img>
                              )}
                            </div>
                          </td>
                          {!salesRole && (
                            <td
                              className="search-result-tables-static-data"
                              data-testid="selectFacilitySiteUseCodeTest"
                            >
                              {makeCapitalEachOfWordInString(
                                `${data.siteUseCode}`
                              )}
                            </td>
                          )}
                          <td
                            className="search-result-tables-static-data"
                            data-testid="selectFacilityAccountNumbertest"
                          >
                            {makeCapitalEachWordInString(
                              `${data.accountNumber}`
                            )}
                          </td>
                          <td
                            className="search-result-tables-static-data"
                            data-testid="selectFacilityReadyCareTest"
                          >
                            {determineReadyCareFlag(data.readyCareFlag) ===
                            "Yes" ? (
                              <CheckIcon />
                            ) : (
                              ""
                            )}
                          </td>
                          <td
                            className="search-result-tables-static-data"
                            data-testid="selectFacilityTypeNametest"
                          >
                            {makeCapitalEachWordInString(`${data.typeName}`)}
                          </td>
                          <td
                            className="search-result-tables-static-data"
                            data-testid="selectFacilityCareSettingtest"
                          >
                            {makeCapitalEachWordInString(`${data.careSetting}`)}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                ) : (
                  <tbody></tbody>
                )}
              </>
            )}
          </Table>
          {!isLoading && currentPosts && currentPosts.length === 0 && (
            <p className="no-data" data-testid="no-data">
              No Rows To Show.
            </p>
          )}
        </div>
      )}

      <br />

      <div className="parentDiv">
        <div className="paginationTable" data-testid="paginationTablePresent">
          <ExpressButton
            clickHandler={(e) => handlePrevClick(e)}
            disabled={currentButton === 1 ? true : false}
            variant="text"
            parentClass={`${currentButton === 1 ? "disabled" : ""}`}
          >
            <IoMdArrowDropleft size={20} />
          </ExpressButton>

          {arrOfCurrButtons.map((item, index) => {
            return (
              <li
                key={index}
                className={`${currentButton === item ? "active" : ""} list`}
                onClick={() => setCurrentButton(item)}
              >
                {item}
              </li>
            );
          })}

          <ExpressButton
            clickHandler={handleNextClick}
            disabled={
              numberOfPages.length === 0 ||
              currentButton === numberOfPages.length
                ? true
                : false
            }
            parentClass={`${
              numberOfPages.length === 0 ||
              currentButton === numberOfPages.length
                ? "disabled"
                : ""
            } prevbutton`}
            variant="text"
            id="buttonClass"
          >
            <IoMdArrowDropright size={20} />
          </ExpressButton>
        </div>
        <div className="itemsPerPage" data-testid="itemsPerPage">
          <p className="ptag">Items per page:</p>
          <CustomDropDown
            value={`${postsPerPage}`}
            handleChange={handleSelectChange}
            menuItem={["25", "50", "75", "100"]}
            selectClassName="dropdown"
            selectpropsClassName="dropDown"
          />
        </div>
        <div className="totalCounts" data-testid="totalCounts">
          <p className="last-section-right">
            {`${
              sortedData.length === 0
                ? 0
                : (currentButton - 1) * postsPerPage + 1
            }-${
              currentButton * postsPerPage < sortedData.length
                ? currentButton * postsPerPage
                : sortedData.length
            } of ${sortedData.length} items`}
          </p>
        </div>
      </div>
      <ErrorPopup
        handleBackButton={handleErrorPopup}
        errorMessage={errorMessage}
        errorPopupFlag={openErrorPopup}
        popUpStyles={"facilitySearchResultErrorPopup"}
        showTitle={false}
        buttontitle="Done"
        isSupportPhoneRequired={false}
      />
      {isMobileScreen && (
        <Popup
          closeHandler={() => {}}
          openFlag={isLoading}
          hideCloseButton={true}
        >
          <div className="facility-spinner">
            <LoadingSpinner />
          </div>
        </Popup>
      )}
    </div>
  );
};

export default SearchFacilityResult;

export enum FavoriteType {
  ADDFAVORITE = 1,
  REMOVEFAVORITE = 2,
}
