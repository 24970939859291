import { ValidationStatus } from "../../../../core/interfaces/input.interface";
import { ISecondaryWoundInfo } from "./secondaryWoundInfo.interface";

export let defaultNewOrdeSecondaryWoundInfoData: ISecondaryWoundInfo = {
  // Debridement
  debridementAttempted: {
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
    title: "Debridement Attempted",
  },
  serialDebridementRequired: {
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: false,
    title: "Serial Debridement",
  },
  debridementDate: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
    title: "Debridement Date",
    componentId: "wound-debridement-date-secondary-title",
    order: 114,
    id: "wound-debridement-date-secondary-input",
  },
  debridementType: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
    title: "Debridement Type",
    componentId: "wound-debridement-type-secondary-dropdown",
    order: 113,
  },
  woundTunneling: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
    title: "Wound Tunneling",
    componentId: "wound-tunneling-secondary-radiobutton",
    order: 127,
  },
  location1Depth: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
    title: "Location Depth",
    componentId: "wound-tunneling-location1-depth-secondary-title",
    order: 128,
    id: "wound-tunneling-location1-depth-secondary-input",
  },
  location1Position: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
    title: "Clock Position",
    componentId: "wound-tunneling-location1-position-secondary-title",
    order: 129,
    id: "wound-tunneling-location1-position-secondary-input",
  },
  location2Depth: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
    title: "Location Depth",
    componentId: "wound-tunneling-location2-depth-secondary-title",
    order: 130,
    id: "wound-tunneling-location2-depth-secondary-input",
  },
  location2Position: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
    title: "Clock Position",
    componentId: "wound-tunneling-location2-position-secondary-title",
    order: 131,
    id: "wound-tunneling-location2-position-secondary-input",
  },
  woundMeasurementDate: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
    title: "wound Measurement Date",
    componentId: "wound-measurement-date-secondary-title",
    order: 115,
    id: "wound-measurement-date-secondary-input",
  },
  woundLength: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
    title: "Length",
    componentId: "wound-length-date-secondary-title",
    order: 116,
    id: "wound-length-date-secondary-input",
  },
  woundWidth: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
    title: "Width",
    componentId: "wound-width-secondary-title",
    order: 117,
    id: "wound-width-secondary-input",
  },
  woundDepth: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
    title: "Depth",
    componentId: "wound-depth-secondary-title",
    order: 118,
    id: "wound-depth-secondary-input",
  },
  woundThickness: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
    title: "Wound Thickness",
    componentId: "wound-thickness-secondary-radiobutton",
    order: 119,
  },
  // wound exudate
  exudateAmount: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
    title: "Exudate Amount",
    componentId: "wound-exudate-secondary-title",
  },
  exudateAppearance: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
    title: "Exudate Apperance",
    componentId: "wound-exudate-apperance-secondary-title",
    order: 132,
  },

  // exposed tissues
  exposedTissue: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: true,
    title: "Exposed Tissue",
    order: 133,
    componentId: "exposed-tissue-seconadary-wound",
    id: "exposed-tissue-seconadary-wound",
  },

  // woundbed
  granulationValue: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
    title: "Granulation Value",
  },
  epthilizationValue: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
    title: "Epithelialization Value",
  },
  sloughValue: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
    title: "Slough Value",
  },
  escharValue: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
    title: "Eschar Value",
  },
  woundBedTotal: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
    title: "Wound Bed Total",
    componentId: "wound-bed-secondary-title",
    order: 130,
  },

  // Clinical Information
  shortNarrativeOfPossibleConsequences: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
    title: "Short Narrative",
  },
  woundType: {
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: true,
    title: "Wound Type",
    componentId: "wound-type-secondary-dropdown",
    order: 84,
  },
  woundAge: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: true,
    title: "Wound Age",
    componentId: "wound-age-secondary-title",
    order: 110,
    id: "wound-age-secondary-input",
    isDefaultValid: true,
  },
  woundAgeType: {
    valid: ValidationStatus.VALID,
    value: "2",
    required: true,
    title: "wound Age Type",
    isDefaultValid: true,
  },
  woundLocation: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: true,
    title: "Wound Location",
    componentId: "wound-location-secondary-dropdown",
    order: 111,
  },
  woundDirection: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
    title: "Wound Direction",
  },
  woundOrientation: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
    title: "Wound Orientation",
  },
  isTissuePresent: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: true,
    title: "Eschar tissue present",
    componentId: "wound-tissue-secondary-radiobutton",
    order: 112,
  },
  woundUndermining: {
    componentId: "secondary-wound-undermining",
    order: 120,
    required: false,
    title: "Wound Undermining",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  underminingLocation1Depth: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
    title: "Location Depth",
    componentId: "wound-undermining-depth-secondary-title",
    order: 121,
    id: "wound-underminig-depth-secondary-input",
  },
  underminingLocation1PositionFrom: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
    title: "Starting Position",
    componentId: "wound-undermining-location1-position-from-secondary-title",
    order: 122,
  },
  underminingLocation1PositionTo: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
    title: "Ending Position",
    componentId: "wound-undermining-location1-position-to-secondary-title",
    order: 123,
  },
  underminingLocation2Depth: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
    title: "Location Depth",
    componentId: "wound-undermining-location2-depth-secondary-title",
    order: 124,
    id: "wound-underminig-location2-depth-secondary-input",
  },
  underminingLocation2PositionFrom: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
    title: "Starting Position",
    componentId: "wound-undermining-location2-position-from-secondary-title",
    order: 125,
  },
  underminingLocation2PositionTo: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
    title: "Ending Position",
    componentId: "wound-undermining-location2-position-to-secondary-title",
    order: 126,
  },
};
