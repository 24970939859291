import { Box, Toolbar } from "@mui/material";
import { useHistory } from "react-router-dom";
import { ExpressButton } from "../../../../../core/expressButton/expressButton.component";
import { PersistentFooter } from "../../../../../core/persistentFooter/persistentFooter.Component";
import "./salesManageUserFotterbtnGroup.css";
import { useContext } from "react";
import {
  RolesPermissionContext,
  RolesPermissionContextType,
} from "../../../../../context/RolesPermissionContext";

const SalesManageUserFooterButtonGroup = ({ handleSave }: any) => {
  const history = useHistory();
  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );
  return (
    <>
      <PersistentFooter footerButtonClass="salesMngFooter">
        <Toolbar className="salesManageToolBar">
          <Box className="salesManageUserFooterMaincontainer">
            <Box className="manageSalesLeftContainer" flexWrap="wrap">
              <Box className="boxmanageSupply" p={1}>
                <ExpressButton
                  clickHandler={() => {
                    history.goBack();
                  }}
                  parentClass="cancelclass"
                  testId="cancelclass"
                  variant="text"
                >
                  cancel
                </ExpressButton>
              </Box>
            </Box>
            <Box className="manageSalesRightContainer" flexWrap="wrap">
              <Box className="boxmanageSupply" p={1}>
                <ExpressButton
                  clickHandler={handleSave}
                  parentClass="savechangesbutton"
                  testId="savechangesbutton"
                  variant="contained"
                  disabled={
                    permissionObj?.mappedRolesPermissionData?.IsSupportRole!
                  }
                >
                  Save Changes
                </ExpressButton>
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </PersistentFooter>
    </>
  );
};
export default SalesManageUserFooterButtonGroup;
