import "./users.css";
import { useContext, useEffect, useState } from "react";
import {
  RolesPermissionContext,
  RolesPermissionContextType,
} from "../../../../context/RolesPermissionContext";
import {
  ManageUserAccountsContext,
  ManageUserAccountsContextType,
} from "../../../../context/ManageUserAccountsContext";
import AddIcon from "@mui/icons-material/AddCircleOutline";
import { Button, Grid, InputBase, useMediaQuery } from "@mui/material";
import { ManageUserAccountsValidator } from "../manageUserAccounts.validator";
import { ValidationStatus } from "../../../../core/interfaces/input.interface";
import { InputWithLabel } from "../../../../core/inputWithLabel/inputWithLabel.component";
import UsersSearchResult from "./UsersSearchResults/usersSearchResult.component";
import { filterSearchUser } from "../../../../util/3meService";
import { LoadingSpinner } from "../../../../core/loader/LoadingSpinner";
import { Popup } from "../../../../core/popup/popup.component";
import { IUserSearch, IUserSearchData } from "../manageUserAccounts.interface";
import ErrorPopup from "../../../../core/errorPopup/errorPopup.component";
import { ERROR_MSG_SEARCH_USERS } from "../../../../util/errorMsg";

type Props = {
  addNewUserBtnAction: Function;
  openSelectedUserProfile: Function;
};
export const Users = ({
  addNewUserBtnAction,
  openSelectedUserProfile,
}: Props) => {
  const isSmallerMobileScreen = useMediaQuery("(max-width:450px)");
  const isMobileScreen = useMediaQuery("(max-width:600px)");
  const isIPadScreen = useMediaQuery("(max-width:768px)");

  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );
  const manageUserAccountsObj =
    useContext<ManageUserAccountsContextType | null>(ManageUserAccountsContext);
  const data = manageUserAccountsObj!.data;
  const setData = manageUserAccountsObj!.setData;
  const [searchApplied, setSearchApplied] = useState(false);
  const [skeletonLoading, setSkeletonLoading] = useState(false);
  const [selectedSortField, setSelectedSortField] = useState<any>(0);
  const [selectedSortOrder, setSelectedSortOrder] = useState<string>("");
  const [openErrorPopup, setOpenErrorPopup] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [errorCode, setErrorCode] = useState<string>("");
  // Loader
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // Full Data
  const [allUsers, setAllUsers] = useState<IUserSearchData[] | null>(null);
  const [users, setUsers] = useState<IUserSearch[] | null>(null);
  const [totalUsersCount, setTotalUsersCount] = useState<number>(0);
  // Pagination
  const [perPageCount, setPerPageCount] = useState<any>(25);
  const [selectedPageNumber, setSelectedPageNumber] = useState<any>(1);
  const [numberOfPages, setNumberOfPages] = useState<any[]>([]);
  const [allPageNumbers, setAllPageNumbers] = useState<any[]>([]);

  const validateAndSetData = (e: any) => {
    const validator = new ManageUserAccountsValidator();
    const valResp = validator.validate(e.target.value, e.target.name);
    setData({
      ...data,
      [e.target.name]: {
        value: e.target.value,
        valid: valResp?.status,
        required: e.target.required,
      },
    });
  };

  const checkSearchBtnDisablity = (): boolean => {
    const searchFieldValidationForInValid = Object.values(data).some(
      (el) => el.valid === ValidationStatus.INVALID
    );
    const searchFieldValidationForUnTouched = Object.values(data).every(
      (el) => el.valid === ValidationStatus.UNTOUCHED
    );
    return searchFieldValidationForInValid || searchFieldValidationForUnTouched
      ? true
      : false;
  };

  const callFilterSearchUser = async () => {
    setSkeletonLoading(true);
    const reqBody = {
      FirstName: data.firstName.value,
      LastName: data.lastName.value?.trim(),
      EmailAddress: data.email.value,
      FacilityId: data.facilityId.value,
      Sort: selectedSortField,
      SortOrder: selectedSortOrder,
      PageNumber: selectedPageNumber,
      PageSize: perPageCount,
    };
    setUsers([]);
    const response = await filterSearchUser(reqBody);
    if (response && response.succeeded && response.data !== null) {
      const result = {
        index: selectedPageNumber,
        items: response.data.users,
      };
      if (allUsers) {
        let updateAllUsers = [...allUsers];
        updateAllUsers.push(result);
        setAllUsers(updateAllUsers);
      } else {
        setAllUsers([result]);
      }
      setTotalUsersCount(response.data.totalCount);
      setUsers(response.data.users);
      setIsLoading(false);
      setSearchApplied(true);
      setSkeletonLoading(false);
    } else {
      setIsLoading(false);
      setOpenErrorPopup(true);
      setErrorCode(
        response?.error?.code || response?.status || response?.error?.errorCode
      );
      setErrorMsg(ERROR_MSG_SEARCH_USERS);
      console.log("No data Available");
    }
  };

  // Pagination Methods
  const handleSelectChange = (e: any) => {
    e.preventDefault();
    clearResult();
    let count = parseInt(e.target.value);
    setPerPageCount(count);
  };

  const handlePreviousClick = () => {
    setSelectedPageNumber((prev: number) => (prev <= 1 ? prev : prev - 1));
  };

  const handleNextClick = () => {
    setSelectedPageNumber((next: number) =>
      next >= numberOfPages.length ? next : next + 1
    );
  };

  const handlePageNumberClick = (item: any) => {
    if (typeof item === "string" && item.includes("...")) {
      getPageNumbers(item);
    } else {
      setSelectedPageNumber(parseInt(item));
    }
  };

  const clearResult = () => {
    setAllUsers([]);
    setSelectedPageNumber(1);
  };

  const getPageNumbers = (pageNumber: any) => {
    if (users && users.length > 0) {
      const howManyPages = Math.ceil(totalUsersCount / perPageCount);
      const pages: any[] = [];
      for (let i = 1; i <= howManyPages; i++) {
        pages.push(i);
      }
      setNumberOfPages(pages);
      var tempNumberOfPages: any[] = [];
      tempNumberOfPages = [...allPageNumbers];
      let dotsInitial = "...";
      let dotsLeft = "... ";
      let dotsRight = " ...";
      if (pages.length < 6) {
        tempNumberOfPages = pages;
      } else if (pageNumber >= 1 && pageNumber <= 3) {
        tempNumberOfPages = [1, 2, 3, 4, dotsInitial, pages.length];
      } else if (pageNumber === 4) {
        const sliced = pages.slice(0, 4);
        tempNumberOfPages = [...sliced, dotsInitial, pages.length];
      } else if (pageNumber > 4 && pageNumber < pages.length - 2) {
        // from 5 to 8 -> (10 - 2)
        const sliced1 = pages.slice(pageNumber - 2, pageNumber);
        // sliced1 (5-2, 5) -> [4,5]
        const sliced2 = pages.slice(pageNumber, pageNumber + 1);
        // sliced1 (5, 5+1) -> [6]
        tempNumberOfPages = [
          1,
          dotsLeft,
          ...sliced1,
          ...sliced2,
          dotsRight,
          pages.length,
        ];
        // [1, '...', 4, 5, 6, '...', 10]
      } else if (pageNumber > pages.length - 3) {
        // > 7
        const sliced = pages.slice(pages.length - 4);
        // slice(10-4)
        tempNumberOfPages = [1, dotsLeft, ...sliced];
      } else if (pageNumber === dotsInitial) {
        // [1, 2, 3, 4, "...", 10].length = 6 - 3  = 3
        // arrOfCurrButtons[3] = 4 + 1 = 5
        // or
        // [1, 2, 3, 4, 5, "...", 10].length = 7 - 3 = 4
        // [1, 2, 3, 4, 5, "...", 10][4] = 5 + 1 = 6
        setSelectedPageNumber(allPageNumbers[allPageNumbers.length - 3] + 1);
      } else if (pageNumber === dotsRight) {
        setSelectedPageNumber(allPageNumbers[3] + 2);
      } else if (pageNumber === dotsLeft) {
        setSelectedPageNumber(allPageNumbers[3] - 2);
      }
      setAllPageNumbers(tempNumberOfPages);
    } else {
      if (!skeletonLoading) {
        setAllPageNumbers([]);
        setNumberOfPages([]);
        setSelectedPageNumber(1);
      }
    }
  };

  const searchBtnAction = () => {
    setIsLoading(true);
    if (!checkSearchBtnDisablity()) {
      clearResult();
      callFilterSearchUser();
    }
  };

  const addNewUserBtnClicked = () => {
    addNewUserBtnAction();
  };

  useEffect(() => {
    if (!checkSearchBtnDisablity()) {
      const index = allUsers
        ? allUsers.findIndex(
            (data: IUserSearchData) => data.index === selectedPageNumber
          )
        : -1;
      if (index > -1) {
        const result = allUsers![index];
        setUsers(result.items);
      } else {
        callFilterSearchUser();
      }
    }
  }, [perPageCount, selectedPageNumber, selectedSortField, selectedSortOrder]);

  useEffect(() => {
    const allUntouched = Object.values(data).every(
      (el) => el.valid === ValidationStatus.UNTOUCHED
    );
    if (allUntouched) {
      setSearchApplied(false);
    }
  }, [data]);

  useEffect(() => {
    getPageNumbers(selectedPageNumber);
  }, [users, totalUsersCount]);

  const handleErrorPopup = () => {
    setOpenErrorPopup(false);
  };

  return (
    <div
      className="manage-user-accounts-users-component"
      data-testid="manage-user-accounts-users-component"
    >
      <Grid
        className="manage-user-accounts-grid-search-container"
        container
        spacing={2}
      >
        <Grid className="manage-user-accounts-grid-item" item xs={12}>
          <p
            className="manage-user-accounts-subtitle"
            data-testid="manage-user-accounts-subtitle"
          >
            Search the 3M™ Express Therapy Portal User Database
          </p>
        </Grid>
        <Grid className="manage-user-accounts-grid-item" item xs={12}>
          <p
            className="manage-user-accounts-subtitle-description"
            data-testid="manage-user-accounts-subtitle-description"
          >
            Enter Last name, First name, Email address and/or Customer Account
            Number to search from the list of external users.
          </p>
        </Grid>
        <Grid
          className="manage-user-accounts-grid-input-item"
          item
          xs={
            isSmallerMobileScreen
              ? 12
              : isMobileScreen
              ? 6
              : isIPadScreen
              ? 4
              : 3
          }
        >
          <InputWithLabel
            error={data?.lastName.valid === ValidationStatus.INVALID}
            isRequired={false}
            label="Last name"
            testId="last-name-label"
          >
            <InputBase
              autoFocus
              className="manage-user-accounts-input"
              inputProps={{ "data-testid": "manage-user-accounts-last-name" }}
              onChange={validateAndSetData}
              name="lastName"
              required={false}
              value={data?.lastName.value}
            />
          </InputWithLabel>
        </Grid>
        <Grid
          className="manage-user-accounts-grid-input-item"
          item
          xs={
            isSmallerMobileScreen
              ? 12
              : isMobileScreen
              ? 6
              : isIPadScreen
              ? 4
              : 3
          }
        >
          <InputWithLabel
            error={data?.firstName.valid === ValidationStatus.INVALID}
            isRequired={false}
            label="First name"
            testId="first-name-label"
          >
            <InputBase
              className="manage-user-accounts-input"
              inputProps={{ "data-testid": "manage-user-accounts-first-name" }}
              onChange={validateAndSetData}
              name="firstName"
              required={false}
              value={data?.firstName.value}
            />
          </InputWithLabel>
        </Grid>
        <Grid
          className="manage-user-accounts-grid-input-item"
          item
          xs={
            isSmallerMobileScreen
              ? 12
              : isMobileScreen
              ? 6
              : isIPadScreen
              ? 4
              : 3
          }
        >
          <InputWithLabel
            error={data?.facilityId.valid === ValidationStatus.INVALID}
            isRequired={false}
            label="Facility ID"
            testId="facility-id-label"
          >
            <InputBase
              className="manage-user-accounts-input"
              inputProps={{ "data-testid": "manage-user-accounts-facility-id" }}
              onChange={validateAndSetData}
              name="facilityId"
              required={false}
              value={data?.facilityId.value}
            />
          </InputWithLabel>
        </Grid>
        <Grid
          className="manage-user-accounts-grid-input-item"
          item
          xs={
            isSmallerMobileScreen
              ? 12
              : isMobileScreen
              ? 6
              : isIPadScreen
              ? 4
              : 3
          }
        >
          <InputWithLabel
            error={data?.email.valid === ValidationStatus.INVALID}
            isRequired={false}
            label="Email Address"
            testId="email-label"
          >
            <InputBase
              className="manage-user-accounts-input"
              inputProps={{ "data-testid": "manage-user-accounts-email" }}
              onChange={validateAndSetData}
              name="email"
              required={false}
              value={data?.email.value}
            />
          </InputWithLabel>
        </Grid>
        <Grid
          className="manage-user-accounts-grid-item"
          item
          xs={
            isSmallerMobileScreen
              ? 12
              : isMobileScreen
              ? 6
              : isIPadScreen
              ? 4
              : 9
          }
        ></Grid>
        <Grid
          className="manage-user-accounts-grid-input-item"
          item
          xs={
            isSmallerMobileScreen
              ? 12
              : isMobileScreen
              ? 6
              : isIPadScreen
              ? 4
              : 3
          }
        >
          <Button
            classes={{ root: "search-button" }}
            data-testid="search-button"
            disabled={checkSearchBtnDisablity()}
            onClick={searchBtnAction}
            variant="contained"
          >
            Search
          </Button>
        </Grid>
      </Grid>
      <Grid
        className="manage-user-accounts-grid-result-container"
        container
        spacing={2}
      >
        <Grid
          className="manage-user-accounts-grid-item"
          item
          order={{ xs: isSmallerMobileScreen ? 2 : 1 }}
          xs={isSmallerMobileScreen ? 12 : 6}
        >
          <p className="search-result" data-testid="search-result">
            Search Results {searchApplied ? `(${totalUsersCount})` : ""}
          </p>
        </Grid>
        <Grid
          className="manage-user-accounts-grid-item"
          item
          order={{ xs: isSmallerMobileScreen ? 1 : 2 }}
          xs={isSmallerMobileScreen ? 12 : 6}
        >
          <div className="button-div">
            <Button
              classes={{ root: "add-new-user-button" }}
              data-testid="add-new-user-button"
              disabled={permissionObj?.mappedRolesPermissionData.IsSupportRole}
              disableFocusRipple={true}
              disableElevation={true}
              startIcon={<AddIcon className="add-new-user-icon" />}
              onClick={addNewUserBtnClicked}
              variant="text"
            >
              Add New User
            </Button>
          </div>
        </Grid>
        {!searchApplied && (
          <Grid
            className="manage-user-accounts-grid-item"
            item
            order={{ xs: 3 }}
            xs={12}
          >
            <p
              className="search-result-description"
              data-testid="search-result-description"
            >
              Begin your search by entering values into at least one of the
              search fields above. If no results are returned, try entering only
              part of a value; for instance, try “jenn” instead of “jennifer”
              (capitalization is ignored).
            </p>
          </Grid>
        )}

        {searchApplied && totalUsersCount > 0 && (
          <Grid
            item
            order={{ xs: 3 }}
            xs={12}
            className="manage-user-accounts-grid-item"
          >
            <UsersSearchResult
              allPageNumbers={allPageNumbers}
              clearResult={clearResult}
              users={users ?? []}
              numberOfPages={numberOfPages}
              perPageCount={perPageCount}
              selectedPageNumber={selectedPageNumber}
              totalUsersCount={totalUsersCount}
              handleSelectChange={handleSelectChange}
              handlePreviousClick={handlePreviousClick}
              handleNextClick={handleNextClick}
              handlePageNumberClick={handlePageNumberClick}
              setSelectedSortField={setSelectedSortField}
              setSelectedSortOrder={setSelectedSortOrder}
              skeletonLoading={skeletonLoading}
              openSelectedUserProfile={openSelectedUserProfile}
            />
          </Grid>
        )}
        {searchApplied && totalUsersCount === 0 && (
          <Grid
            item
            order={{ xs: 3 }}
            xs={12}
            className="manage-user-accounts-grid-item"
          >
            <p
              className="search-noresult-description"
              data-testid="search-noresult-description"
            >
              <span className="search-noresult-description-span">
                No results
              </span>
              <br /> Try expanding your search by entering a value into only one
              search field or by entering only part of a value; for instance,
              try “jenn” instead of “jennifer” (capitalization is ignored).
            </p>
          </Grid>
        )}
      </Grid>
      <Popup
        closeHandler={() => {
          setIsLoading(false);
        }}
        openFlag={isLoading}
        hideCloseButton={true}
      >
        <div className="facility-spinner">
          <LoadingSpinner />
        </div>
      </Popup>
      <ErrorPopup
        handleBackButton={handleErrorPopup}
        errorMessage={errorMsg}
        errorCode={errorCode}
        errorPopupFlag={openErrorPopup}
        popUpStyles={"error-popup-design"}
        showTitle={false}
        buttontitle="Done"
        isSupportPhoneRequired={false}
      />
    </div>
  );
};
