import { createContext, useState } from "react";
import { getDeepClone } from "../util/ObjectFunctions";
import { IPatient } from "../components/myPatients/patient.interface";
import { ISendNote } from "../components/send3MNote/sendNote.interface";
import { defaultSendNoteData } from "../components/send3MNote/sendNote.model";
import {
  IChangeAddress,
  PatientAddress,
} from "../components/send3MNote/changePatientAddress/changeAddress.interface";
import { IAskQuestion } from "../components/send3MNote/askQuestion/askQuestion.interface";
import { defaultAskQuestionData } from "../components/send3MNote/askQuestion/askQuestion.model";
import { ITransferPatient } from "../components/send3MNote/transferPatient/transferPatient.interface";
import { defaultAddressData } from "../components/send3MNote/changePatientAddress/changeAddress.model";
import { defaultTransferPatientData } from "../components/send3MNote/transferPatient/transferPatient.model";
import { IPrescriberModal } from "../components/newOrder/prescriberInformation/prescriberSearch/prescriberSearch.model";
import {
  defaultHcpData,
  IHomeCareProviderData,
} from "../components/send3MNote/homeCareProvider/homeCareProvider.interface";
import { IInputField } from "../core/interfaces/input.interface";
import {
  defaultAdditionalInfoData,
  defaultNewPrescriberData,
} from "../components/send3MNote/updatePrescriber/addPrescriberSection/addedPrescriber.model";
import { IAdditionalInfoData } from "../components/send3MNote/updatePrescriber/additionalInformation/additionalInformation.component";
import { IPrescriberInformation } from "../components/newOrder/prescriberInformation/prescriberInformation.interface";

export type SendNoteContextType = {
  patient: IPatient | null;
  setPatient: React.Dispatch<React.SetStateAction<IPatient | null>>;
  contactReasons: [];
  setContactReasons: React.Dispatch<React.SetStateAction<[]>>;
  contactReasonsText: [];
  setContactReasonsText: React.Dispatch<React.SetStateAction<[]>>;
  data: ISendNote;
  setData: React.Dispatch<React.SetStateAction<ISendNote>>;

  changeAddressData: IChangeAddress;
  setChangeAddressData: React.Dispatch<React.SetStateAction<IChangeAddress>>;
  patientCurrentAddress: PatientAddress | null;
  setPatientCurrentAddress: React.Dispatch<
    React.SetStateAction<PatientAddress | null>
  >;
  patientPermanentAddress: PatientAddress | null;
  setPatientPermanentAddress: React.Dispatch<
    React.SetStateAction<PatientAddress | null>
  >;
  transferPatientData: ITransferPatient;
  setTransferPatientData: React.Dispatch<
    React.SetStateAction<ITransferPatient>
  >;
  askQuestionData: IAskQuestion;
  setAskQuestionData: React.Dispatch<React.SetStateAction<IAskQuestion>>;

  currentPrescriberData: IPrescriberModal | null;
  setCurrentPrescriberData: React.Dispatch<
    React.SetStateAction<IPrescriberModal | null>
  >;
  currentHcp: IHomeCareProviderData;
  setCurrentHcp: React.Dispatch<React.SetStateAction<IHomeCareProviderData>>;
  selectHcp: IHomeCareProviderData;
  setSelectedHcp: React.Dispatch<React.SetStateAction<IHomeCareProviderData>>;

  addedPrescriberData: IInputField;
  setAddedPrescriberData: React.Dispatch<React.SetStateAction<IInputField>>;

  additionalInfoData: IAdditionalInfoData;
  setAdditionalInfoData: React.Dispatch<
    React.SetStateAction<IAdditionalInfoData>
  >;

  resetSendNoteData: () => void;
};

type SendNoteContextProviderProps = {
  children: React.ReactNode;
};
export const SendNoteContext = createContext<SendNoteContextType | null>(null);

export const SendNoteContextProvider = ({
  children,
}: SendNoteContextProviderProps) => {
  const [patient, setPatient] = useState<IPatient | null>(null);
  const [contactReasons, setContactReasons] = useState<[]>([]);
  const [contactReasonsText, setContactReasonsText] = useState<[]>([]);
  const [data, setData] = useState<ISendNote>(
    getDeepClone(defaultSendNoteData)
  );
  const [changeAddressData, setChangeAddressData] = useState<IChangeAddress>(
    getDeepClone(defaultAddressData)
  );
  const [patientCurrentAddress, setPatientCurrentAddress] =
    useState<PatientAddress | null>(null);
  const [patientPermanentAddress, setPatientPermanentAddress] =
    useState<PatientAddress | null>(null);

  const [transferPatientData, setTransferPatientData] =
    useState<ITransferPatient>(getDeepClone(defaultTransferPatientData));
  const [askQuestionData, setAskQuestionData] = useState<IAskQuestion>(
    getDeepClone(defaultAskQuestionData)
  );
  const [currentPrescriberData, setCurrentPrescriberData] =
    useState<IPrescriberModal | null>(null);
  const [currentHcp, setCurrentHcp] = useState<IHomeCareProviderData>(
    getDeepClone(defaultHcpData)
  );
  const [selectHcp, setSelectedHcp] = useState<IHomeCareProviderData>(
    getDeepClone(defaultHcpData)
  );

  const [addedPrescriberData, setAddedPrescriberData] = useState<IInputField>(
    getDeepClone(defaultNewPrescriberData)
  );

  const [additionalInfoData, setAdditionalInfoData] =
    useState<IAdditionalInfoData>(getDeepClone(defaultAdditionalInfoData));

  const resetSendNoteData = () => {
    setPatient(null);
    setData(getDeepClone(defaultSendNoteData));
    setPatientCurrentAddress(null);
    setPatientPermanentAddress(null);
    setChangeAddressData(getDeepClone(defaultAddressData));
    setTransferPatientData(getDeepClone(defaultTransferPatientData));
    setAskQuestionData(getDeepClone(defaultAskQuestionData));
    setCurrentPrescriberData(null);
    setCurrentHcp(getDeepClone(defaultHcpData));
    setSelectedHcp(getDeepClone(defaultHcpData));
    setAddedPrescriberData(getDeepClone(defaultNewPrescriberData));
    setAdditionalInfoData(defaultAdditionalInfoData);
  };

  return (
    <SendNoteContext.Provider
      value={{
        patient,
        setPatient,

        contactReasons,
        setContactReasons,
        contactReasonsText,
        setContactReasonsText,

        data,
        setData,

        changeAddressData,
        setChangeAddressData,
        patientCurrentAddress,
        setPatientCurrentAddress,
        patientPermanentAddress,
        setPatientPermanentAddress,

        transferPatientData,
        setTransferPatientData,

        askQuestionData,
        setAskQuestionData,

        currentPrescriberData,
        setCurrentPrescriberData,
        currentHcp,
        setCurrentHcp,
        selectHcp,
        setSelectedHcp,
        addedPrescriberData,
        setAddedPrescriberData,

        resetSendNoteData,
        additionalInfoData,
        setAdditionalInfoData,
      }}
    >
      {children}
    </SendNoteContext.Provider>
  );
};
