import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import AppWithRouterAccess from "./AppWithRouterAccess";
import Footer from "./components/footer/footer.component";
import { AuthContextProvider } from "./context/AuthContext";
import { DischargeRequestContextProvider } from "./context/DischargeRequestContext";
import { FacilitySettingsProvider } from "./context/FacilitySettingsContext";
import { MobileDisplayContextProvider } from "./context/MobileDisplayContext";
import { NewOrderContextProvider } from "./context/NewOrderContext";
import { PickUpRequestContextProvider } from "./context/PickUpRequestContext";
import { ProfileFormContextProvider } from "./context/ProfileFormContext";
import { RolesPermissionContextProvider } from "./context/RolesPermissionContext";
import { SendNoteContextProvider } from "./context/SendNoteContext";
import { SupplyOrderContextProvider } from "./context/SupplyOrderContext";
import { WoundAssessmentContextProvider } from "./context/WoundAssessmentContext";
import { InventoryContextProvider } from "./context/InventoryContext";
import { OrderDetailContextProvider } from "./context/OrderDetailsContext";
import { AdminRolesPermissionsContextProvider } from "./context/AdminRolesPermissionsContext";
import { SubmitProofOfDeliveryContextProvider } from "./context/submitProofOfDeliveryContext";
import { useEffect } from "react";
import { UserProfileContextProvider } from "./context/UserProfileContext";
import { ConfirmPlacementContextProvider } from "./context/ConfirmPlacementContext";
import { SalesManagerRoleContextProvider } from "./context/SalesManagerRoleContext";
import { ManageUserAccountsContextProvider } from "./context/ManageUserAccountsContext";
import { InternalSignOnProvider } from "./context/InternalSignOnContext";
import { AdminMyListsContextProvider } from "./context/AdministrationMyListsContext";
import { ExchangeVacUnitContextProvider } from "./context/ExchangeVacUnitContext";
import { MyPatientContextProvider } from "./context/MyPatientContext";
import CacheBuster from "react-cache-buster";
import version from "../package.json";
import { QueryClientProvider, QueryClient } from "react-query";
import { AddSiteStatusContextProvider } from "./context/AddSiteStatusContext";
import { SiteStatusesContextProvider } from "./context/SiteStatusesContext";
import {
  AGGRID_LICENSE_NAME,
  ALERTMANAGER_FUNCTION_KEY,
  FUNCTION_KEY_REQUESTBODY,
  FUNCTION_SERVICE_KEY,
} from "./util/keyVaultNames";
import { LicenseManager } from "ag-grid-enterprise";
import { mapFunctionKeyData } from "./functionKey/functionKey.Mapper";
import { getKeyVaultContent } from "./util/cmsService";
import { encrypt } from "./util/encryptDecrypt";
import { CreateServiceRequestContextProvider } from "./context/CreateServiceRequestContext";
import { InpatientOrderContextProvider } from "./context/InpatientOrderContext";
import { AcuteOrderPickUpRequestContextProvider } from "./context/AcutePickUpRequestContext";
import { InpatientSupplyOrderContextProvider } from "./context/InpatientSupplyOrderContext";
import { CartProvider } from "./context/CartContext";
import { FacilitySupplyOrderContextProvider } from "./context/FacilitySupplyOrdersContext";

const newTheme = createTheme({
  typography: {
    fontFamily: "3MCircularTT",
  },
});

function App() {
  const handleBeforeUnload = (e: any) => {
    localStorage.clear();
  };
  useEffect(() => {
    Getlicense();
    window.addEventListener("beforeunload", handleBeforeUnload);
  }, []);

  const Getlicense = async () => {
    const body = {
      SecretNames: FUNCTION_KEY_REQUESTBODY,
    };
    if (sessionStorage.getItem("keys") === null) {
      const licenseResponse = await getKeyVaultContent(body);
      if (licenseResponse?.succeeded) {
        const mapper = await mapFunctionKeyData(licenseResponse);
        const encryptedData = encrypt(JSON.stringify(mapper));
        sessionStorage.setItem("keys", encryptedData);
        LicenseManager.setLicenseKey(licenseResponse?.item[0].value);
      }
    }
  };

  const queryClient = new QueryClient();
  return (
    <CacheBuster
      currentVersion={version}
      isEnabled={true} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
    >
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={newTheme}>
          <MobileDisplayContextProvider>
            <div className="App">
              <SiteStatusesContextProvider>
                <AuthContextProvider>
                  <MyPatientContextProvider>
                    <RolesPermissionContextProvider>
                      <InternalSignOnProvider>
                        <SubmitProofOfDeliveryContextProvider>
                          <ProfileFormContextProvider>
                            <PickUpRequestContextProvider>
                              <OrderDetailContextProvider>
                                <ConfirmPlacementContextProvider>
                                  <ExchangeVacUnitContextProvider>
                                    <DischargeRequestContextProvider>
                                      <SalesManagerRoleContextProvider>
                                        <SupplyOrderContextProvider>
                                          <NewOrderContextProvider>
                                            <CartProvider>
                                              <InpatientOrderContextProvider>
                                                <InpatientSupplyOrderContextProvider>
                                                  <AcuteOrderPickUpRequestContextProvider>
                                                    <FacilitySettingsProvider>
                                                      <AdminRolesPermissionsContextProvider>
                                                        <SendNoteContextProvider>
                                                          <UserProfileContextProvider>
                                                            <WoundAssessmentContextProvider>
                                                              <InventoryContextProvider>
                                                                <ManageUserAccountsContextProvider>
                                                                  <AdminMyListsContextProvider>
                                                                    <AddSiteStatusContextProvider>
                                                                      <CreateServiceRequestContextProvider>
                                                                        <FacilitySupplyOrderContextProvider>
                                                                          <Router>
                                                                            <AppWithRouterAccess />
                                                                            <Footer />
                                                                          </Router>
                                                                        </FacilitySupplyOrderContextProvider>
                                                                      </CreateServiceRequestContextProvider>
                                                                    </AddSiteStatusContextProvider>
                                                                  </AdminMyListsContextProvider>
                                                                </ManageUserAccountsContextProvider>
                                                              </InventoryContextProvider>
                                                            </WoundAssessmentContextProvider>
                                                          </UserProfileContextProvider>
                                                        </SendNoteContextProvider>
                                                      </AdminRolesPermissionsContextProvider>
                                                    </FacilitySettingsProvider>
                                                  </AcuteOrderPickUpRequestContextProvider>
                                                </InpatientSupplyOrderContextProvider>
                                              </InpatientOrderContextProvider>
                                            </CartProvider>
                                          </NewOrderContextProvider>
                                        </SupplyOrderContextProvider>
                                      </SalesManagerRoleContextProvider>
                                    </DischargeRequestContextProvider>
                                  </ExchangeVacUnitContextProvider>
                                </ConfirmPlacementContextProvider>
                              </OrderDetailContextProvider>
                            </PickUpRequestContextProvider>
                          </ProfileFormContextProvider>
                        </SubmitProofOfDeliveryContextProvider>
                      </InternalSignOnProvider>
                    </RolesPermissionContextProvider>
                  </MyPatientContextProvider>
                </AuthContextProvider>
              </SiteStatusesContextProvider>
            </div>
          </MobileDisplayContextProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </CacheBuster>
  );
}

export default App;
