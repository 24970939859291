import { Grid } from "@mui/material";
import "./devicePlacementReview.css";
import { ICustomerPlusListingDetails } from "../../../../inventory/customerPlusInventory/customerPlusInventory.interface";
import { useContext } from "react";
import { MobileDisplayContext } from "../../../../../context/MobileDisplayContext";
import {
  IInventoryProductDetails,
  ISolventumProduct,
} from "../../../../inventory/inventoryTabContainer.enum";

interface Props {
  data:
    | ICustomerPlusListingDetails
    | IInventoryProductDetails
    | ISolventumProduct
    | null
    | undefined;
  editButtonAction?: any;
  isSummary?: boolean;
  title: string;
  isSolventumProduct?: boolean;
}

export const DevicePlacementReview = ({
  data,
  editButtonAction,
  isSummary = false,
  title,
  isSolventumProduct = false,
}: Props) => {
  const { isMobileScreen } = useContext(MobileDisplayContext);

  const showTitleAndValue = (
    title: string,
    value: string | null,
    testId: string
  ) => {
    return (
      <div className="show-title-and-value-div">
        <p
          className="show-title-and-value-title"
          data-testid={`${testId}-title`}
          id={`${testId}-title-id`}
        >
          {title}
        </p>
        <p
          className="show-title-and-value-value"
          data-testid={`${testId}-value`}
          id={`${testId}-value-id`}
        >
          {value && value !== "" ? value.trim() : "--"}
        </p>
      </div>
    );
  };

  const getProductName = (): string => {
    if (data) {
      if (
        (data as ICustomerPlusListingDetails).productNameDescription !==
        undefined
      ) {
        return (
          (data as ICustomerPlusListingDetails).productNameDescription ?? ""
        );
      }
      if ((data as IInventoryProductDetails).product !== undefined) {
        return (data as IInventoryProductDetails).product ?? "";
      }
      if ((data as ISolventumProduct).productCompleteDescrption !== undefined) {
        return (data as ISolventumProduct).productCompleteDescrption ?? "";
      }
    }
    return "";
  };

  return (
    <div className="inpatient-device-placment-review-component">
      <div className="inpatient-device-placment-review-header">
        <p
          className="inpatient-device-placment-review-title"
          data-testid="inpatient-device-placment-review-title"
          id="inpatient-device-placment-review-title-id"
        >
          Device Placement
        </p>
        {!isSummary && (
          <button
            className="inpatient-device-placment-review-edit-button"
            data-testid="inpatient-device-placment-review-edit-button"
            id="inpatient-device-placment-review-edit-button-id"
            onClick={() =>
              editButtonAction("inpatient-device-placement-main-div")
            }
          >
            Edit
          </button>
        )}
      </div>
      <Grid
        className="inpatient-device-placment-review-grid-container"
        container
      >
        <Grid
          className="inpatient-device-placment-review-grid-item"
          item
          xs={isMobileScreen ? 12 : 4}
        >
          {showTitleAndValue("Placement Type", title, "device-placement-type")}
        </Grid>
        <Grid
          className="inpatient-device-placment-review-grid-item"
          item
          xs={isMobileScreen ? 6 : 4}
        >
          {showTitleAndValue(
            "Product",
            getProductName(),
            "device-placement-product-desc"
          )}
        </Grid>
        <Grid
          className="inpatient-device-placment-review-grid-item"
          item
          xs={isMobileScreen ? 6 : 4}
        >
          {showTitleAndValue(
            "Serial Number",
            isSolventumProduct ? "--" : data?.serialNumber ?? null,
            "device-placement-serial-number"
          )}
        </Grid>
      </Grid>
    </div>
  );
};
