import {
  Button,
  Grid,
  IconButton,
  InputBase,
  useMediaQuery,
} from "@mui/material";
import "./editPrescriberEmail.css";
import {
  AuthContext,
  AuthContextType,
} from "../../../../../context/AuthContext";
import {
  formatNumber,
  makeCapitalEachWordInString,
} from "../../../../../util/utilityFunctions";
import {
  RolesPermissionContext,
  RolesPermissionContextType,
} from "../../../../../context/RolesPermissionContext";
import {
  IInputField,
  ValidationStatus,
} from "../../../../../core/interfaces/input.interface";
import { useContext, useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/AddCircleOutline";
import { Validator } from "../../../../../util/order.validations";
import { updatePrescriber } from "../../../../../util/npiService";
import { Popup } from "../../../../../core/popup/popup.component";
import { IPrescriberEmails } from "../myListPrescriber.interface";
import { IEditPrescriberEmail } from "./editPrescriberEmail.interface";
import Table from "../../../../../core/customSortingTable/table.component";
import { LoadingSpinner } from "../../../../../core/loader/LoadingSpinner";
import ErrorPopup from "../../../../../core/errorPopup/errorPopup.component";
import { ReactComponent as InfoIcon } from "../../../../../assets/InfoIcon.svg";
import { CustomCheckBox } from "../../../../../core/checkBox/checkBox.component";
import { ReactComponent as SuccessIcon } from "../../../../../assets/success.svg";
import { ERROR_MSG_UPDATE_PRESCRIBER_DETAILS } from "../../../../../util/errorMsg";
import { ReactComponent as CloseIcon } from "../../../../../assets/popupcloseicon.svg";
import { ReactComponent as RadioButtonIcon } from "../../../../../assets/radioButton.svg";
import { InputWithLabel } from "../../../../../core/inputWithLabel/inputWithLabel.component";
import { ReactComponent as DisabledRadioButtonIcon } from "../../../../../assets/disabledRadioButton.svg";
import { ReactComponent as SelectedRadioButtonIcon } from "../../../../../assets/selectedRadioButton.svg";
import { ReactComponent as DisabledSelectedRadioButtonIcon } from "../../../../../assets/disabledSelectedRadioButton.svg";

export const EditPrescriberEmail = ({
  prescriberData,
  setPrescriberData,
  closeEditPrescriberEmailUpdate,
  onClickCheckbox,
  onClickAddEmailButton,
  onClickUpdateButton,
}: IEditPrescriberEmail) => {
  const defaultData: IInputField = {
    errorMessage: "",
    required: true,
    title: "Prescriber Email Address",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  };
  const columns = [
    { label: "Default", accessor: "isDefault", sortable: false },
    { label: "Email Address", accessor: "value", sortable: false },
    { label: "", accessor: "", sortable: false },
  ];

  // Context Objects
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );

  // Update Button
  const [isUpdateBtnEnable, setIsUpdateBtnEnable] = useState<boolean>(false);

  // eScript
  const [isEScriptChecked, setIsEScriptChecked] = useState<boolean>(false);

  // Add Email
  const [showAddEmailInput, setShowAddEmailInput] = useState<boolean>(false);
  const [newEmailInput, setNewEmailInput] = useState<IInputField>(defaultData);

  // Loader
  const [showLoader, setShowLoader] = useState(false);

  // Error
  const [errorCode, setErrorCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorPopUpFlag, setErrorPopUpFlag] = useState(false);

  // Success (add/delete)
  const [showSuccessMessage, setShowSuccessMessage] = useState<number>(0);

  // Media Query Variable
  const isMobileScreen = useMediaQuery("(max-width:414px)");

  // Local Variable
  const isSupportRole =
    permissionObj?.mappedRolesPermissionData.IsSupportRole ?? false;

  // API Integration
  const updatePrescriberDetails = async () => {
    if (
      authObj &&
      authObj.registeredFaciltyAddress &&
      authObj.registeredFaciltyAddress.siteUseId
    ) {
      let reqParams = {
        npi: prescriberData.npi,
        siteUseId: authObj.registeredFaciltyAddress.siteUseId,
        escript: isEScriptChecked,
        emails: prescriberData.emails,
      };
      setShowLoader(true);
      const response = await updatePrescriber(reqParams);
      setShowLoader(false);
      if (response.succeeded) {
        closeEditPrescriberEmailUpdate(true);
      } else {
        // Show Error Pop Up.
        setErrorPopUpFlag(true);
        setErrorMessage(ERROR_MSG_UPDATE_PRESCRIBER_DETAILS);
        setErrorCode(response.error.code || response.status);
      }
    }
  };

  const changeDefaultEmail = (email: IPrescriberEmails) => {
    if (prescriberData.emails) {
      const index = prescriberData.emails.findIndex(
        (x: IPrescriberEmails) => x.value === email.value
      );
      if (index > -1) {
        const oldIndex = prescriberData.emails.findIndex(
          (x: IPrescriberEmails) => x.isDefault === true
        );
        if (oldIndex > -1) {
          prescriberData.emails[oldIndex].isDefault = false;
          prescriberData.emails[oldIndex].type =
            prescriberData.emails[oldIndex].type === 1 ? 1 : 2;
        }
        prescriberData.emails[index].type =
          prescriberData.emails[index].type === 1 ? 1 : 2;
        prescriberData.emails[index].isDefault = true;
        setPrescriberData({
          ...prescriberData,
          emails: [...prescriberData.emails],
        });
      }
    }
    if (!showAddEmailInput) {
      setIsUpdateBtnEnable(true);
    }
  };

  const deletePrescriberEmail = (email: IPrescriberEmails) => {
    if (prescriberData.emails) {
      const index = prescriberData.emails.findIndex(
        (x: IPrescriberEmails) => x.value === email.value
      );
      if (index > -1) {
        let isRemovableEmailDefault = false;
        if (prescriberData.emails[index].isDefault) {
          isRemovableEmailDefault = true;
        }
        // If emails are newly added then delete them
        if (prescriberData.emails[index].type === 1) {
          prescriberData.emails.splice(index, 1);
        }
        // For existing emails update type to 3
        else {
          prescriberData.emails[index].isDefault = false;
          prescriberData.emails[index].type = 3;
        }
        const updatedEmails = prescriberData.emails.filter(
          (email: IPrescriberEmails) => email.type !== 3
        );
        if (updatedEmails.length > 0 && isRemovableEmailDefault) {
          updatedEmails[0].isDefault = true;
          updatedEmails[0].type = updatedEmails[0].type === 1 ? 1 : 2;
        }
        if (updatedEmails.length === 0) {
          setIsEScriptChecked(false);
        }
        setPrescriberData({
          ...prescriberData,
          emails: [...prescriberData.emails],
        });
        setShowSuccessMessage(2);
      }
    }
    setIsUpdateBtnEnable(true);
  };

  const handleCheckboxAction = () => {
    setIsUpdateBtnEnable(true);
    setIsEScriptChecked(!isEScriptChecked);
  };

  const handleAddNewEmail = () => {
    setIsUpdateBtnEnable(false);
    setNewEmailInput(defaultData);
    setShowAddEmailInput(true);
  };

  const handleNewEmail = () => {
    if (prescriberData.emails) {
      let updatedEmails = [...prescriberData.emails];
      const newEmail = newEmailInput.value;
      const isAlreadyPresent = updatedEmails.some(
        (email: IPrescriberEmails) =>
          email.value.toLowerCase() === newEmail.toLowerCase() &&
          email.type !== 3
      );
      if (!isAlreadyPresent) {
        const isAlreadyDefaultIndex = updatedEmails.findIndex(
          (email: IPrescriberEmails) => email.isDefault === true
        );
        if (isAlreadyDefaultIndex < -1) {
          updatedEmails[isAlreadyDefaultIndex].isDefault = false;
        }
        const index = updatedEmails.findIndex(
          (email: IPrescriberEmails) =>
            email.value.toLowerCase() === newEmail.toLowerCase() &&
            email.type === 3
        );
        if (index > -1) {
          updatedEmails.splice(index, 1);
        }
        const isValidEmails = updatedEmails.filter(
          (email: IPrescriberEmails) => email.type !== 3
        );
        const email = {
          isDefault: isValidEmails.length === 0,
          type: index > -1 ? 2 : 1,
          value: newEmail,
        };
        updatedEmails.push(email);
        let isUpdateEScript = isEScriptChecked;
        if (isValidEmails.length === 0 && prescriberData.eScript) {
          isUpdateEScript = prescriberData.eScript === "Yes";
          setIsEScriptChecked(isUpdateEScript);
        }
        setPrescriberData({
          ...prescriberData,
          emails: updatedEmails,
          eScript: isUpdateEScript ? "Yes" : "No",
        });
        handleRemoveNewEmail();
        setShowSuccessMessage(1);
      }
    }
  };

  const handleRemoveNewEmail = () => {
    setIsUpdateBtnEnable(true);
    setNewEmailInput(defaultData);
    setShowAddEmailInput(false);
  };

  const validateNewEmailAddress = (e: any) => {
    const { value } = e.target;
    const validator = new Validator();
    let isValid = validator.prescriberEmailValidation(value, false);
    let errorMessage = null;
    if (isValid.status === ValidationStatus.VALID) {
      const isAlreadyPresent =
        prescriberData.emails &&
        prescriberData.emails.some(
          (email: IPrescriberEmails) =>
            email.value.toLowerCase() === value.toLowerCase() &&
            email.type !== 3
        );
      if (isAlreadyPresent) {
        isValid.status = ValidationStatus.INVALID;
        errorMessage = "Email already exist. Please try with new email";
      }
    }
    const tempData = {
      ...newEmailInput,
      errorMessage: errorMessage,
      valid: isValid!.status,
      value: value,
    };
    setNewEmailInput(tempData);
  };

  const handleCancelButtonAction = () => {
    closeEditPrescriberEmailUpdate();
  };

  const handleUpdatePrescriberButtonAction = () => {
    updatePrescriberDetails();
  };

  useEffect(() => {
    setTimeout(() => {
      setShowSuccessMessage(0);
    }, 2000);
  }, [showSuccessMessage]);

  useEffect(() => {
    if (prescriberData.eScript) {
      setIsEScriptChecked(prescriberData.eScript === "Yes");
    }
  }, []);

  return (
    <div className="edit-prescriber-email-component">
      <p
        className="edit-prescriber-email-title"
        data-testid="edit-prescriber-email-title"
      >
        Edit Prescriber Email Address
      </p>
      <p
        className="edit-prescriber-email-sub-title"
        data-testid="edit-prescriber-email-sub-title-1"
      >
        Prescriber Information
      </p>
      <Grid container className="prescriber-details-grid-container">
        <Grid className="prescriber-details-grid-item" item xs={12}>
          <p className="prescriber-name" data-testid="prescriber-name">
            {`${makeCapitalEachWordInString(prescriberData.firstName)} 
            ${makeCapitalEachWordInString(prescriberData.lastName)}`}
          </p>
        </Grid>
        <Grid
          className="prescriber-details-grid-item"
          item
          xs={isMobileScreen ? 12 : 6}
        >
          <div className="prescriber-details-div">
            <p
              className="prescriber-details-value"
              data-testid="prescriber-npi"
            >
              <span className="prescriber-details-sub-header">NPI #</span>
              {prescriberData.npi}
            </p>
            <p
              className="prescriber-details-value"
              data-testid="prescriber-phone"
            >
              <span className="prescriber-details-sub-header">Phone</span>
              {formatNumber(prescriberData.telephoneNumber)}
            </p>
            <p
              className="prescriber-details-value"
              data-testid="prescriber-fax"
            >
              <span className="prescriber-details-sub-header"> Fax</span>
              {prescriberData.faxNumber === "" ||
              prescriberData.faxNumber === null
                ? "-"
                : formatNumber(prescriberData.faxNumber)}
            </p>
          </div>
        </Grid>
        <Grid
          className="prescriber-details-grid-item"
          item
          xs={isMobileScreen ? 12 : 6}
        >
          <div className="prescriber-details-div">
            <div
              className="prescriber-details-address-value"
              data-testid="prescriber-address-1"
            >
              {makeCapitalEachWordInString(prescriberData.address1)}
            </div>
            <div
              className="prescriber-details-address-value"
              data-testid="prescriber-address-2"
            >
              {makeCapitalEachWordInString(prescriberData.address2)}
            </div>
            <div
              className="prescriber-details-address-value"
              data-testid="prescriber-address-3"
            >
              {makeCapitalEachWordInString(prescriberData.city)},{" "}
              {prescriberData.state} {prescriberData.zipCode}
            </div>
          </div>
        </Grid>
      </Grid>
      <p
        className="edit-prescriber-email-sub-title"
        data-testid="edit-prescriber-email-sub-title-2"
      >
        Prescriber emails
      </p>
      <Table
        handleSorting={() => {}}
        tableClassName="edit-prescriber-emails-table"
        tableColumns={columns}
      >
        {prescriberData.emails &&
          prescriberData.emails.filter(
            (email: IPrescriberEmails) => email.type !== 3
          ).length > 0 && (
            <tbody>
              {prescriberData.emails
                .filter((email: IPrescriberEmails) => email.type !== 3)
                .map((email: IPrescriberEmails, index: number) => {
                  return (
                    <tr>
                      <td
                        className={`edit-prescriber-email-isDefault${
                          isSupportRole ? " disabled" : ""
                        }`}
                      >
                        <div
                          className="edit-prescriber-email-isDefault-div"
                          data-testid={`edit-prescriber-isDefault-${index + 1}`}
                          onClick={() =>
                            !isSupportRole ? changeDefaultEmail(email) : {}
                          }
                        >
                          {email.isDefault ? (
                            isSupportRole ? (
                              <DisabledSelectedRadioButtonIcon />
                            ) : (
                              <SelectedRadioButtonIcon />
                            )
                          ) : isSupportRole ? (
                            <DisabledRadioButtonIcon />
                          ) : (
                            <RadioButtonIcon />
                          )}
                        </div>
                      </td>
                      <td
                        className="edit-prescriber-email"
                        data-testid={`edit-prescriber-email-${index + 1}`}
                      >
                        {email.value}
                      </td>
                      <td
                        aria-disabled={
                          isSupportRole ||
                          showSuccessMessage !== 0 ||
                          showAddEmailInput
                        }
                        className={`edit-prescriber-email-delete${
                          isSupportRole ||
                          showSuccessMessage !== 0 ||
                          showAddEmailInput
                            ? " disabled"
                            : ""
                        }`}
                        data-testid={`edit-prescriber-delete-${index + 1}`}
                        onClick={() =>
                          isSupportRole ||
                          showSuccessMessage !== 0 ||
                          showAddEmailInput
                            ? {}
                            : deletePrescriberEmail(email)
                        }
                      >
                        Delete
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          )}
      </Table>
      {prescriberData.emails &&
        prescriberData.emails.filter(
          (email: IPrescriberEmails) => email.type !== 3
        ).length === 0 && (
          <div className="edit-prescriber-no-email-message-div">
            <p
              className="edit-prescriber-no-email-message"
              data-testid="edit-prescriber-no-email-message"
            >
              This prescriber has no associated email addresses. Please add one
              now.
            </p>
          </div>
        )}
      {!showAddEmailInput ? (
        <Grid container className="add-prescriber-email-grid-container">
          <Grid
            className="add-prescriber-email-grid-item"
            item
            xs={isMobileScreen ? 12 : 8}
          >
            {showSuccessMessage !== 0 ? (
              <div className="success-message-div">
                <div className="success-icon">
                  {showSuccessMessage === 1 ? <SuccessIcon /> : <InfoIcon />}
                </div>
                <p
                  className={`success-message${
                    showSuccessMessage !== 1 ? " delete" : ""
                  }`}
                >
                  {showSuccessMessage === 1
                    ? "The email address has been added!"
                    : "The email address has been deleted"}
                </p>
              </div>
            ) : (
              <CustomCheckBox
                checked={isEScriptChecked}
                handleChange={
                  onClickCheckbox ? onClickCheckbox : handleCheckboxAction
                }
                isDisabled={
                  isSupportRole ||
                  (prescriberData.emails &&
                    prescriberData.emails.filter(
                      (email: IPrescriberEmails) => email.type !== 3
                    ).length === 0)
                }
                labelText="This prescriber accepts E-Prescription requests"
                labelClassName={"eScript-checkbox-description-text"}
                name="eScript-checkbox"
                selectClassName="eScript-checkbox"
                selectpropsClassName="eScript-checkbox-root"
                testId="eScript-checkbox"
                value={isEScriptChecked}
              />
            )}
          </Grid>
          <Grid
            className="add-prescriber-email-grid-item"
            item
            xs={isMobileScreen ? 12 : 4}
          >
            <div
              className={`add-email-address-button-div ${
                isMobileScreen ? " mobile" : ""
              }`}
            >
              <Button
                classes={{ root: "add-email-address-button" }}
                data-testid="add-email-address-button"
                disabled={
                  isSupportRole ||
                  showSuccessMessage !== 0 ||
                  (prescriberData.emails &&
                    prescriberData.emails.filter(
                      (email: IPrescriberEmails) => email.type !== 3
                    ).length === 5)
                }
                disableFocusRipple={true}
                disableElevation={true}
                startIcon={<AddIcon className="add-email-address-icon" />}
                onClick={() =>
                  onClickAddEmailButton
                    ? onClickAddEmailButton()
                    : handleAddNewEmail()
                }
                variant="text"
              >
                Add Email Address
              </Button>
            </div>
          </Grid>
        </Grid>
      ) : (
        <Grid container className="add-prescriber-email-grid-container">
          <Grid
            className="add-prescriber-email-grid-item"
            item
            xs={isMobileScreen ? 12 : 8}
          >
            <div className="new-email-input-div">
              <InputWithLabel
                error={newEmailInput.valid === ValidationStatus.INVALID}
                isRequired={true}
                isShowWarning={true}
                label={newEmailInput.title!}
                labelClassName="new-email"
                testId="new-email"
                warningMessage={newEmailInput.errorMessage}
              >
                <InputBase
                  autoFocus={true}
                  className="new-email-input"
                  name="new-email-input"
                  onChange={validateNewEmailAddress}
                  value={newEmailInput.value}
                />
              </InputWithLabel>
            </div>
          </Grid>
          <Grid
            className="add-prescriber-email-grid-item"
            item
            xs={isMobileScreen ? 12 : 4}
          >
            <div className="add-prescriber-email-buttons-div">
              <div className="add-email-button-div">
                <Button
                  classes={{ root: "add-email-button" }}
                  data-testid="add-email-button"
                  disabled={newEmailInput.valid !== ValidationStatus.VALID}
                  disableFocusRipple={true}
                  disableElevation={true}
                  onClick={handleNewEmail}
                  variant="contained"
                >
                  Add Email
                </Button>
              </div>
              <div className="close-icon-button-div">
                <IconButton
                  aria-label="close"
                  onClick={handleRemoveNewEmail}
                  sx={{ padding: 0 }}
                  data-testid="close-icon-button"
                  className="close-icon-button"
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
          </Grid>
        </Grid>
      )}
      <Grid
        className="edit-prescriber-email-buttons-grid-container"
        container
        spacing={2}
      >
        <Grid
          className="edit-prescriber-email-buttons-grid-item"
          item
          xs={isMobileScreen ? 12 : 6}
        >
          <Button
            className={`cancel-btn ${isMobileScreen ? " mobile" : ""}`}
            data-testid="cancel-btn"
            onClick={handleCancelButtonAction}
            variant="outlined"
          >
            Cancel
          </Button>
        </Grid>
        <Grid
          className="edit-prescriber-email-buttons-grid-item"
          item
          xs={isMobileScreen ? 12 : 6}
        >
          <Button
            className={`update-prescriber-btn ${
              isMobileScreen ? " mobile" : ""
            }`}
            data-testid="update-prescriber-btn"
            disabled={
              isSupportRole ||
              !isUpdateBtnEnable ||
              (prescriberData.emails &&
                prescriberData.emails.some(
                  (email: IPrescriberEmails) => email.type !== 3
                ) &&
                prescriberData.emails.filter(
                  (email: IPrescriberEmails) =>
                    email.type !== 3 && email.isDefault === true
                ).length === 0)
            }
            onClick={() =>
              onClickUpdateButton
                ? onClickUpdateButton()
                : handleUpdatePrescriberButtonAction()
            }
            variant="contained"
          >
            Update Prescriber
          </Button>
        </Grid>
      </Grid>
      <ErrorPopup
        errorPopupFlag={errorPopUpFlag}
        handleBackButton={() => setErrorPopUpFlag(false)}
        popUpStyles="errorPopup"
        errorMessage={errorMessage}
        buttontitle="Done"
        showTitle={false}
        isShortSentence={true}
        errorCode={errorCode}
      />
      {showLoader && (
        <Popup
          hideCloseButton={true}
          openFlag={showLoader}
          closeHandler={() => {}}
        >
          <div className="edit-prescriber-email-address-loader">
            <LoadingSpinner />
          </div>
        </Popup>
      )}
    </div>
  );
};
