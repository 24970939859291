import { Box, Grid, InputBase, useMediaQuery } from "@mui/material";
import React, { useContext, useEffect } from "react";
import {
  NewOrderContext,
  NewOrderContextType,
} from "../../../../context/NewOrderContext";
import { CustomDropDown } from "../../../../core/customDropdown/customDropdown.component";
import { InputWithLabel } from "../../../../core/inputWithLabel/inputWithLabel.component";
import { ValidationStatus } from "../../../../core/interfaces/input.interface";
import { formatedWoundValue } from "../../../../util/utilityFunctions";
import { ISecondaryWoundInfo } from "../../clinicalInformation/secondaryWoundInfo/secondaryWoundInfo.interface";
import { NewOrderValidator } from "../../newOrder.validator";
import { INewOrderWoundInfo } from "../../newOrderWoundInfoStepper/newOrderWoundInfo.interface";
import { woundUnderminingProps } from "../woundUndermining.interface";

const UnderminingDetails = ({
  isSavedOrder = false,
  positionDropDownData,
  setWoundInfoData,
  woundInfoData,
  Validator = new NewOrderValidator(),
}: woundUnderminingProps) => {
  const [validator] = React.useState<NewOrderValidator>(Validator!);
  const isSmallDevice = useMediaQuery("(width:320px)");
  const isMobileScreen = useMediaQuery("(max-width:599px)");
  const isSmalledMobileScreen = useMediaQuery("(max-width:414px)");

  const NewOrderObj = useContext<NewOrderContextType | null>(NewOrderContext);

  const formatWoundZeros = async (e: any) => {
    let { value, name, required } = e.target;
    let key: keyof typeof woundInfoData = name;
    let formatedValue = formatedWoundValue(woundInfoData[key].value);
    setWoundInfoData({
      ...woundInfoData,
      [name]: {
        ...Object(woundInfoData)[name],
        value: formatedValue,
        valid:
          value.length === 0 && woundInfoData[key].required
            ? ValidationStatus.INVALID
            : ValidationStatus.VALID,
        required: required,
        errorMessage: null,
      },
    });
    validateDepthValueNotZero(formatedValue, key);
  };

  const validateAndSetData = (e: any) => {
    NewOrderObj?.setIsHandleChangeTriggered(true);
    let { value, name, required } = e.target;
    let isValid = validator.validate(value, name);
    let key: keyof typeof woundInfoData = name;
    Object(woundInfoData)[name].errorMessage = null;
    setWoundInfoData({
      ...woundInfoData,
      [name]: {
        ...Object(woundInfoData)[name],
        value: value,
        valid: isValid?.status,
        required: required,
        errorMessage: null,
      },
    });
    Object(woundInfoData)[name].value = value;
    Object(woundInfoData)[name].errorMessage = null;
    Object(woundInfoData)["underminingLocation1PositionFrom"].errorMessage =
      null;
    Object(woundInfoData)["underminingLocation1PositionTo"].errorMessage = null;
    Object(woundInfoData)["underminingLocation2PositionFrom"].errorMessage =
      null;
    Object(woundInfoData)["underminingLocation2PositionTo"].errorMessage = null;
    checkAdjacentClockPositionNotSame(
      "underminingLocation1PositionFrom",
      Object(woundInfoData)["underminingLocation1PositionFrom"].value,
      Object(woundInfoData)["underminingLocation1PositionTo"].value,
      Object(woundInfoData)["underminingLocation2PositionFrom"].value
    );
    checkAdjacentClockPositionNotSame(
      "underminingLocation1PositionTo",
      Object(woundInfoData)["underminingLocation1PositionTo"].value,
      Object(woundInfoData)["underminingLocation1PositionFrom"].value,
      Object(woundInfoData)["underminingLocation2PositionTo"].value
    );
    checkAdjacentClockPositionNotSame(
      "underminingLocation2PositionFrom",
      Object(woundInfoData)["underminingLocation2PositionFrom"].value,
      Object(woundInfoData)["underminingLocation1PositionFrom"].value,
      Object(woundInfoData)["underminingLocation2PositionTo"].value
    );
    checkAdjacentClockPositionNotSame(
      "underminingLocation2PositionTo",
      Object(woundInfoData)["underminingLocation2PositionTo"].value,
      Object(woundInfoData)["underminingLocation1PositionTo"].value,
      Object(woundInfoData)["underminingLocation2PositionFrom"].value
    );
  };

  const validateAndSetDepthData = (e: any) => {
    NewOrderObj?.setIsHandleChangeTriggered(true);
    let { value, name } = e.target;
    let key: keyof typeof woundInfoData = name;
    let isValid = validator.validate(value, name);
    if (isValid?.status === ValidationStatus.VALID) {
      setWoundInfoData({
        ...woundInfoData,
        [name]: {
          ...Object(woundInfoData)[name],
          value: value,
          valid:
            value.length === 0 && woundInfoData[key].required
              ? ValidationStatus.INVALID
              : isValid?.status,
          errorMessage: null,
        },
      });
    }
  };

  const checkAdjacentClockPositionNotSame = (
    name: keyof typeof woundInfoData,
    boxValue: string,
    oppositeBox1: string,
    oppositeBox2: string
  ) => {
    if (boxValue === "" || (oppositeBox1 === "" && oppositeBox2 === "")) {
      Object(woundInfoData)[name].valid = ValidationStatus.VALID;
    } else if (boxValue !== oppositeBox1 && boxValue !== oppositeBox2) {
      Object(woundInfoData)[name].valid = ValidationStatus.VALID;
    } else {
      Object(woundInfoData)[name].valid = ValidationStatus.INVALID;
    }
    setWoundInfoData(Object.assign({}, woundInfoData));
  };

  const validateClockPositionNotSame = (
    value: string,
    name: keyof typeof woundInfoData,
    isSavedOrder?: boolean
  ) => {
    if (
      value ===
        woundInfoData[
          name === "underminingLocation1PositionFrom"
            ? "underminingLocation2PositionFrom"
            : "underminingLocation1PositionFrom"
        ].value &&
      value !== ""
    ) {
      setDefaultClockPositionValue(
        isSavedOrder ? ValidationStatus.UNTOUCHED : ValidationStatus.INVALID,
        value,
        name
      );
    } else if (
      value ===
        woundInfoData[
          name === "underminingLocation1PositionFrom"
            ? "underminingLocation1PositionTo"
            : "underminingLocation2PositionTo"
        ].value &&
      value !== ""
    ) {
      if (name === "underminingLocation1PositionFrom") {
        setDefaultFromClockPosition1FromToValue(
          isSavedOrder ? ValidationStatus.UNTOUCHED : ValidationStatus.INVALID,
          value,
          name
        );
      } else {
        setDefaultFromClockPosition2FromToValue(
          isSavedOrder ? ValidationStatus.UNTOUCHED : ValidationStatus.INVALID,
          value,
          name
        );
      }
    } else {
      if (woundInfoData[name].value) {
        setDefaultClockPositionValue(ValidationStatus.VALID, value, name);
      }
      if (
        value !==
          woundInfoData[
            name === "underminingLocation1PositionFrom"
              ? "underminingLocation1PositionTo"
              : "underminingLocation2PositionTo"
          ].value &&
        value !== "" &&
        woundInfoData[
          name === "underminingLocation1PositionFrom"
            ? "underminingLocation1PositionTo"
            : "underminingLocation2PositionTo"
        ].value !== ""
      ) {
        if (name === "underminingLocation1PositionFrom") {
          setDefaultFromClockPosition1FromToValue(
            ValidationStatus.VALID,
            value,
            name
          );
        } else {
          setDefaultFromClockPosition2FromToValue(
            ValidationStatus.VALID,
            value,
            name
          );
        }
      }
    }
  };

  const validateClockEndingPositionNotSame = (
    value: string,
    name: keyof typeof woundInfoData,
    isSavedOrder?: boolean
  ) => {
    if (
      value ===
        woundInfoData[
          name === "underminingLocation1PositionTo"
            ? "underminingLocation2PositionTo"
            : "underminingLocation1PositionTo"
        ].value &&
      value !== ""
    ) {
      setDefaultClockEndingPositionValue(
        isSavedOrder ? ValidationStatus.UNTOUCHED : ValidationStatus.INVALID,
        value,
        name
      );
    } else if (
      value ===
        woundInfoData[
          name === "underminingLocation1PositionTo"
            ? "underminingLocation1PositionFrom"
            : "underminingLocation2PositionFrom"
        ].value &&
      value !== ""
    ) {
      if (name === "underminingLocation1PositionTo") {
        setDefaultFromClockPosition1FromToValue(
          isSavedOrder ? ValidationStatus.UNTOUCHED : ValidationStatus.INVALID,
          value,
          name
        );
      } else {
        setDefaultFromClockPosition2FromToValue(
          isSavedOrder ? ValidationStatus.UNTOUCHED : ValidationStatus.INVALID,
          value,
          name
        );
      }
    } else {
      if (woundInfoData[name].value) {
        setDefaultClockEndingPositionValue(ValidationStatus.VALID, value, name);
      }
      if (
        value !==
          woundInfoData[
            name === "underminingLocation1PositionTo"
              ? "underminingLocation2PositionTo"
              : "underminingLocation1PositionTo"
          ].value &&
        woundInfoData[
          name === "underminingLocation1PositionTo"
            ? "underminingLocation2PositionTo"
            : "underminingLocation1PositionTo"
        ].value !== ""
      ) {
        if (name === "underminingLocation1PositionTo") {
          setDefaultFromClockPosition1FromToValue(
            ValidationStatus.VALID,
            value,
            name
          );
        } else {
          setDefaultFromClockPosition2FromToValue(
            ValidationStatus.VALID,
            value,
            name
          );
        }
      }

      if (
        value !==
          woundInfoData[
            name === "underminingLocation1PositionTo"
              ? "underminingLocation2PositionFrom"
              : "underminingLocation1PositionFrom"
          ].value &&
        woundInfoData[
          name === "underminingLocation1PositionTo"
            ? "underminingLocation2PositionFrom"
            : "underminingLocation1PositionFrom"
        ].value === ""
      ) {
        if (name === "underminingLocation1PositionTo") {
          setDefaultFromClockPosition1FromToValue(
            ValidationStatus.VALID,
            value,
            name
          );
        }
      }
    }
  };

  const setDefaultFromClockPosition1FromToValue = (
    ValidationStatus: ValidationStatus,
    value: string,
    name: keyof typeof woundInfoData
  ) => {
    woundInfoData.underminingLocation1PositionFrom.valid = ValidationStatus;
    woundInfoData.underminingLocation1PositionTo.valid = ValidationStatus;
    woundInfoData[name].value = value;
    setWoundInfoData(Object.assign({}, woundInfoData));
  };

  const setDefaultFromClockPosition2FromToValue = (
    ValidationStatus: ValidationStatus,
    value: string,
    name: keyof typeof woundInfoData
  ) => {
    woundInfoData.underminingLocation2PositionFrom.valid = ValidationStatus;
    woundInfoData.underminingLocation2PositionTo.valid = ValidationStatus;
    woundInfoData[name].value = value;
    setWoundInfoData(Object.assign({}, woundInfoData));
  };

  const setDefaultClockPositionValue = (
    ValidationStatus: ValidationStatus,
    value: string,
    name: keyof typeof woundInfoData
  ) => {
    if (name)
      woundInfoData.underminingLocation1PositionFrom.valid = ValidationStatus;
    woundInfoData.underminingLocation2PositionFrom.valid = ValidationStatus;
    woundInfoData[name].value = value;
    setWoundInfoData(Object.assign({}, woundInfoData));
  };

  const setDefaultClockEndingPositionValue = (
    ValidationStatus: ValidationStatus,
    value: string,
    name: keyof typeof woundInfoData
  ) => {
    woundInfoData.underminingLocation1PositionTo.valid = ValidationStatus;
    woundInfoData.underminingLocation2PositionTo.valid = ValidationStatus;
    woundInfoData[name].value = value;
    setWoundInfoData(Object.assign({}, woundInfoData));
  };

  const validateDepthValueNotZero = (
    value: string,
    key: keyof (INewOrderWoundInfo | ISecondaryWoundInfo)
  ) => {
    let convertValue = parseFloat(value);
    if (convertValue === 0) {
      woundInfoData[key].value = value;
      woundInfoData[key].valid = ValidationStatus.INVALID;
      setWoundInfoData(Object.assign({}, woundInfoData));
    }
  };

  useEffect(() => {
    if (isSavedOrder) {
      if (
        woundInfoData.underminingLocation1PositionFrom.value &&
        woundInfoData.underminingLocation1PositionFrom.value !== ""
      ) {
        let name: keyof typeof woundInfoData =
          "underminingLocation1PositionFrom";
        let value = woundInfoData.underminingLocation1PositionFrom.value;
        validateClockPositionNotSame(value, name, isSavedOrder);
      }
      if (
        woundInfoData.underminingLocation1PositionTo.value &&
        woundInfoData.underminingLocation1PositionTo.value !== ""
      ) {
        let name: keyof typeof woundInfoData = "underminingLocation1PositionTo";
        let value = woundInfoData.underminingLocation1PositionTo.value;
        validateClockEndingPositionNotSame(value, name, isSavedOrder);
      }
      if (
        woundInfoData.underminingLocation2PositionFrom.value &&
        woundInfoData.underminingLocation2PositionFrom.value !== ""
      ) {
        let name: keyof typeof woundInfoData =
          "underminingLocation2PositionFrom";
        let value = woundInfoData.underminingLocation2PositionFrom.value;
        validateClockPositionNotSame(value, name, isSavedOrder);
      }
      if (
        woundInfoData.underminingLocation2PositionTo.value &&
        woundInfoData.underminingLocation2PositionTo.value !== ""
      ) {
        let name: keyof typeof woundInfoData = "underminingLocation2PositionTo";
        let value = woundInfoData.underminingLocation2PositionTo.value;
        validateClockEndingPositionNotSame(value, name, isSavedOrder);
      }
    }
  }, []);

  return (
    <div>
      <Box className="undermining-box-container" sx={{ flexGrow: 1 }}>
        <Grid className="undermining-grid-container" container spacing={2}>
          <Grid
            className="undermining-grid-item"
            item
            xs={isSmalledMobileScreen ? 8 : isMobileScreen ? 9 : 3.2}
          >
            <InputWithLabel
              label="Location 1 Depth (cm)"
              isRequired={woundInfoData?.underminingLocation1Depth.required}
              error={
                woundInfoData?.underminingLocation1Depth.valid ===
                ValidationStatus.INVALID
              }
              id={woundInfoData?.underminingLocation1Depth.componentId!}
              isShowWarning={true}
              warningMessage={
                woundInfoData?.underminingLocation1Depth.errorMessage
              }
            >
              <InputBase
                className="depth-input"
                name="underminingLocation1Depth"
                value={woundInfoData?.underminingLocation1Depth.value}
                onChange={validateAndSetDepthData}
                onBlur={formatWoundZeros}
                required={woundInfoData?.underminingLocation1Depth.required}
                data-testid="underminingLocation1Depth"
                type="text"
                id={woundInfoData?.underminingLocation1Depth.id!}
                inputProps={{
                  inputMode: "decimal",
                  pattern: "^[0-9.]+$",
                }}
              />
            </InputWithLabel>
          </Grid>
          <Grid
            className="undermining-grid-item"
            item
            xs={isSmalledMobileScreen ? 2 : isMobileScreen ? 2 : 0.8}
          >
            <div className="text-div" data-testid="undermining-fromtest-1">
              <p className="text">from</p>
            </div>
          </Grid>
          <Grid
            className="undermining-grid-item"
            item
            xs={
              isSmallDevice
                ? 4.5
                : isSmalledMobileScreen
                ? 4
                : isMobileScreen
                ? 3.5
                : 2.5
            }
          >
            <InputWithLabel
              label="Starting Position"
              isRequired={false}
              error={
                woundInfoData?.underminingLocation1PositionFrom.valid ===
                ValidationStatus.INVALID
              }
              testId="test-location1Position"
              isShowWarning={true}
              warningMessage={
                woundInfoData?.underminingLocation1PositionFrom.errorMessage
              }
              id={woundInfoData?.underminingLocation1PositionFrom.componentId!}
              isDropdown={true}
            >
              <CustomDropDown
                handleChange={validateAndSetData}
                menuItem={positionDropDownData}
                name="underminingLocation1PositionFrom"
                placeHolder="00:00"
                selectpropsClassName={
                  woundInfoData.underminingLocation1PositionFrom.value
                    ? "clockPosition-select"
                    : "placeHolder"
                }
                selectClassName={
                  woundInfoData.underminingLocation1PositionFrom.value
                    ? "clockPosition-input"
                    : "placeHolder"
                }
                testId="test-location1Position-dropdown"
                value={woundInfoData?.underminingLocation1PositionFrom.value}
              />
            </InputWithLabel>
          </Grid>
          <Grid
            className="undermining-grid-item"
            item
            xs={isSmalledMobileScreen ? 1 : isMobileScreen ? 2 : 0.5}
          >
            <div className="text-div" data-testid="undermining-totest-1">
              <p className="text">to</p>
            </div>
          </Grid>
          <Grid
            className="undermining-grid-item"
            item
            xs={
              isSmallDevice
                ? 4.5
                : isSmalledMobileScreen
                ? 4
                : isMobileScreen
                ? 3.5
                : 2.5
            }
          >
            <InputWithLabel
              label="Ending Position"
              isRequired={false}
              error={
                woundInfoData?.underminingLocation1PositionTo.valid ===
                ValidationStatus.INVALID
              }
              testId="test-location1Position"
              isShowWarning={true}
              warningMessage={
                woundInfoData?.underminingLocation1PositionTo.errorMessage
              }
              id={woundInfoData?.underminingLocation1PositionTo.componentId!}
              isDropdown={true}
            >
              <CustomDropDown
                handleChange={validateAndSetData}
                menuItem={positionDropDownData}
                name="underminingLocation1PositionTo"
                placeHolder="00:00"
                selectpropsClassName={
                  woundInfoData.underminingLocation1PositionTo.value
                    ? "clockPosition-select"
                    : "placeHolder"
                }
                selectClassName={
                  woundInfoData.underminingLocation1PositionTo.value
                    ? "clockPosition-input"
                    : "placeHolder"
                }
                testId="test-underminingLocation1PositionTo-dropdown"
                value={woundInfoData?.underminingLocation1PositionTo.value}
              />
            </InputWithLabel>
          </Grid>
          <Grid
            className="undermining-grid-item"
            item
            xs={isSmalledMobileScreen ? 1 : isMobileScreen ? 2 : 0.5}
          >
            <div className="text-div" data-testid="underminingoclock-1">
              <p className="text">o’clock</p>
            </div>
          </Grid>
        </Grid>
      </Box>
      <Box className="undermining-box-container" sx={{ flexGrow: 1 }}>
        <Grid className="undermining-grid-container" container spacing={2}>
          <Grid
            className="undermining-grid-item"
            item
            xs={isSmalledMobileScreen ? 8 : isMobileScreen ? 9 : 3.2}
          >
            <InputWithLabel
              label="Location 2 Depth (cm)"
              id={woundInfoData?.underminingLocation2Depth.componentId!}
              isRequired={woundInfoData?.underminingLocation2Depth.required}
              error={
                woundInfoData?.underminingLocation2Depth.valid ===
                ValidationStatus.INVALID
              }
              isShowWarning={true}
              warningMessage={
                woundInfoData?.underminingLocation2Depth.errorMessage
              }
            >
              <InputBase
                className="depth-input"
                name="underminingLocation2Depth"
                id={woundInfoData?.underminingLocation2Depth.id!}
                value={woundInfoData?.underminingLocation2Depth.value}
                onChange={validateAndSetDepthData}
                onBlur={formatWoundZeros}
                required={woundInfoData?.underminingLocation2Depth.required}
                data-testid="underminingLocation2Depth"
                type="text"
                inputProps={{
                  inputMode: "decimal",
                  pattern: "^[0-9.]+$",
                }}
              />
            </InputWithLabel>
          </Grid>
          <Grid
            className="undermining-grid-item"
            item
            xs={isSmalledMobileScreen ? 1 : isMobileScreen ? 2 : 0.8}
          >
            <div className="text-div" data-testid="undermining-fromtest-2">
              <p className="text">from</p>
            </div>
          </Grid>
          <Grid
            className="undermining-grid-item"
            item
            xs={
              isSmallDevice
                ? 4.5
                : isSmalledMobileScreen
                ? 4
                : isMobileScreen
                ? 3.5
                : 2.5
            }
          >
            <InputWithLabel
              label="Starting Position"
              id={woundInfoData?.underminingLocation2PositionFrom.componentId!}
              isRequired={false}
              error={
                woundInfoData?.underminingLocation2PositionFrom.valid ===
                ValidationStatus.INVALID
              }
              testId="test-underminingLocation2PositionFrom"
              isShowWarning={true}
              warningMessage={
                woundInfoData?.underminingLocation2PositionFrom.errorMessage
              }
              isDropdown={true}
            >
              <CustomDropDown
                handleChange={validateAndSetData}
                menuItem={positionDropDownData}
                name="underminingLocation2PositionFrom"
                placeHolder="00:00"
                selectpropsClassName={
                  woundInfoData.underminingLocation2PositionFrom.value
                    ? "clockPosition-select"
                    : "placeHolder"
                }
                selectClassName={
                  woundInfoData.underminingLocation2PositionFrom.value
                    ? "clockPosition-input"
                    : "placeHolder"
                }
                testId="test-location2Position-dropdown"
                value={woundInfoData?.underminingLocation2PositionFrom.value}
              />
            </InputWithLabel>
          </Grid>
          <Grid
            className="undermining-grid-item"
            item
            xs={isSmalledMobileScreen ? 1 : isMobileScreen ? 2 : 0.5}
          >
            <div className="text-div" data-testid="undermining-totest-2">
              <p className="text">to</p>
            </div>
          </Grid>
          <Grid
            className="undermining-grid-item"
            item
            xs={
              isSmallDevice
                ? 4.5
                : isSmalledMobileScreen
                ? 4
                : isMobileScreen
                ? 3.5
                : 2.5
            }
          >
            <InputWithLabel
              label="Ending Position"
              id={woundInfoData?.underminingLocation2PositionTo.componentId!}
              isRequired={false}
              error={
                woundInfoData?.underminingLocation2PositionTo.valid ===
                ValidationStatus.INVALID
              }
              testId="test-underminingLocation2PositionTo"
              isShowWarning={true}
              warningMessage={
                woundInfoData?.underminingLocation2PositionTo.errorMessage
              }
              isDropdown={true}
            >
              <CustomDropDown
                handleChange={validateAndSetData}
                menuItem={positionDropDownData}
                name="underminingLocation2PositionTo"
                placeHolder="00:00"
                selectpropsClassName={
                  woundInfoData.underminingLocation2PositionTo.value
                    ? "clockPosition-select"
                    : "placeHolder"
                }
                selectClassName={
                  woundInfoData.underminingLocation2PositionTo.value
                    ? "clockPosition-input"
                    : "placeHolder"
                }
                testId="test-underminingLocation2PositionTo-dropdown"
                value={woundInfoData?.underminingLocation2PositionTo.value}
              />
            </InputWithLabel>
          </Grid>
          <Grid
            className="undermining-grid-item"
            item
            xs={isSmalledMobileScreen ? 1 : isMobileScreen ? 2 : 0.5}
          >
            <div className="text-div" data-testid="underminingoclock-2">
              <p className="text">o’clock</p>
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default UnderminingDetails;
