import "./summaryHeader.css";
import { useMediaQuery } from "@mui/material";
import { ExpressButton } from "../../../../../core/expressButton/expressButton.component";
import { ReactComponent as SuccessIcon } from "../../../../../assets/OrderSummarySuccess.svg";
import { ISummaryHeader } from "./summaryHeader.interface";

export const SummaryHeader = ({
  successMessage,
  description1,
  description2,
  backButton,
  handleBackButton,
}: ISummaryHeader) => {
  const isMobileScreen = useMediaQuery("(max-width:475px)");
  return (
    <div
      className="summary-header-component"
      data-testid="summary-header-component"
    >
      <div className="summary-header-part-1">
        <div
          className="success-image"
          data-testid="success-image"
          id="success-image"
        >
          <SuccessIcon />
        </div>
        <div className="summary-header-part-1-description">
          <p
            className="summary-header-part-1-description-success"
            data-testid="summary-header-success-description-success"
            id="summary-header-success-description-success"
          >
            {successMessage}
          </p>
          <p
            className="summary-header-part-1-description-message"
            data-testid="summary-header-success-description-message"
            id="summary-header-success-description-message"
          >
            {description1}
          </p>
          {!isMobileScreen && (
            <div className="summary-header-part-1-description-part-2">
              <p
                className="summary-header-part-1-description-note"
                data-testid="summary-header-success-description-note"
                id="summary-header-success-description-note"
              >
                {description2}
              </p>
              <ExpressButton
                clickHandler={() => {
                  handleBackButton();
                }}
                id="back-to-inventory-button"
                parentClass="back-to-inventory-button"
                testId="back-to-inventory-button"
                variant="contained"
              >
                {backButton}
              </ExpressButton>
            </div>
          )}
        </div>
      </div>
      {isMobileScreen && (
        <div className="summary-header-part-2">
          <p
            className="summary-header-part-2-description-note"
            data-testid="summary-header-success-description-note"
            id="summary-header-success-description-note"
          >
            {description2}
          </p>
          <ExpressButton
            clickHandler={() => {
              handleBackButton();
            }}
            id="back-to-inventory-button"
            parentClass="back-to-inventory-button"
            testId="back-to-inventory-button"
            variant="contained"
          >
            {backButton}
          </ExpressButton>
        </div>
      )}
      <div className="separator-line"></div>
    </div>
  );
};
