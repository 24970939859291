import { Button, Grid, useMediaQuery } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ReactComponent as SearchIcon } from "../../../assets/searchPayer.svg";
import {
  InternalSignOnContext,
  InternalSignOnContextType,
} from "../../../context/InternalSignOnContext";
import { MobileDisplayContext } from "../../../context/MobileDisplayContext";
import { InputWithLabel } from "../../../core/inputWithLabel/inputWithLabel.component";
import { ValidationStatus } from "../../../core/interfaces/input.interface";
import { Popup } from "../../../core/popup/popup.component";
import { Validator } from "../../../util/order.validations";
import { AddFacilityContainer } from "../../manageProfile/facilityInformation/addFacilityContainer/addFacilityContainer.component";
import { AddFacilityContext } from "../../manageProfile/facilityInformation/addFacilityContainer/addFacilityContainer.context";
import { IFacility } from "../../manageProfile/facilityInformation/facility.interface";
import { FacilityInfoDetail } from "../../manageProfile/facilityInformation/facilityInfoDetail.component";
import "./requestingFacility.css";
import { IRequestingFacility } from "./requestingFacility.interface";
import { RequestingFacilityReviewOrder } from "./reviewOrder/requestingFacilityReviewOrder.component";
import {
  NewOrderContext,
  NewOrderContextType,
} from "../../../context/NewOrderContext";

export const RequestingFacility = ({
  data,
  setData,
  isReviewOrder = false,
  editButtonClicked,
  isOrderSummary = false,
}: IRequestingFacility) => {
  const [facility, setFacility] = useState<IFacility>();
  const [open, setOpen] = useState<boolean>(false);
  const internalObj = useContext<InternalSignOnContextType | null>(
    InternalSignOnContext
  );
  const newOrderObj = useContext<NewOrderContextType | null>(NewOrderContext);
  const isMobileScreen = useMediaQuery("(max-width:920px)");
  const searchRequestingFacility = () => {
    setOpen(true);
    setData({
      ...data,
      requestingFacility: {
        ...data.requestingFacility,
        errorMessage: null,
        required: true,
        valid: ValidationStatus.UNTOUCHED,
      },
    });
  };

  const changeFacilityButtonClick = () => {
    setOpen(true);
  };

  const addNewFacility = (newFacility: IFacility) => {
    newOrderObj?.setIsHandleChangeTriggered(true);
    setFacility(newFacility);
    setData({
      ...data,
      requestingFacility: {
        ...data.requestingFacility,
        errorMessage: null,
        valid: ValidationStatus.VALID,
        value: newFacility,
        required: true,
        isOptional: false,
      },
    });
    setOpen(false);
  };
  useEffect(() => {
    if (data.requestingFacility?.value)
      setFacility(data.requestingFacility.value);
  }, []);

  return (
    <div className="requesting-facility-component">
      {!isReviewOrder && (
        <div className="requesting-facility">
          <h2
            className="requesting-facility-title"
            data-testid="requesting-facility-title"
          >
            Requesting Facility
          </h2>

          <InputWithLabel
            isRequired={internalObj?.isVACOrderSSO}
            label={"What facility this order should be aligned to?"}
            error={data?.requestingFacility?.valid === ValidationStatus.INVALID}
            id={data?.requestingFacility?.componentId!}
            isShowWarning={true}
            warningMessage={data?.requestingFacility?.errorMessage}
          >
            {facility !== undefined ? (
              <>
                <Grid
                  className="requestingfacility-grid-container"
                  container
                  spacing={2}
                >
                  <Grid
                    className="requestingfacility-grid-item"
                    item
                    xs={isMobileScreen ? 8 : 9}
                  >
                    <div className="requestingfacilityDetail">
                      {facility && (
                        <FacilityInfoDetail
                          facilityInfo={facility}
                          index={0}
                          key={0}
                          openConfirmationBox={null}
                          showTrash={false}
                          hideFacilityID={false}
                          showManualFacilityNote={false}
                        />
                      )}
                    </div>
                  </Grid>

                  <Grid
                    className="requestingfacility-grid-item"
                    item
                    xs={isMobileScreen ? 4 : 3}
                  >
                    <h4
                      className="button-change-requesting-facility"
                      data-testid="button-change-requesting-facility"
                      onClick={changeFacilityButtonClick}
                    >
                      Change Facility
                    </h4>
                  </Grid>
                </Grid>
              </>
            ) : (
              <Grid
                className="requesting-facility-grid-container"
                container
                spacing={2}
              >
                <Grid className="requesting-facility-item" item xs={12}>
                  <InputWithLabel label="" isRequired={false}>
                    <Button
                      classes={{
                        root: "requesting-facility-button",
                      }}
                      data-testid="requesting-facility-button"
                      onClick={searchRequestingFacility}
                      startIcon={<SearchIcon />}
                      variant="outlined"
                    >
                      Search for Requesting Facility
                    </Button>
                  </InputWithLabel>
                </Grid>
              </Grid>
            )}
          </InputWithLabel>
        </div>
      )}
      <AddFacilityContext.Provider
        value={{
          closePopup: () => setOpen(false),
          facilitySearchValidator: new Validator(),
          addFacilityToList: addNewFacility,
        }}
      >
        <Popup
          dialogParentClass="add-facility-popup"
          openFlag={open}
          closeHandler={() => setOpen(false)}
        >
          <AddFacilityContainer />
        </Popup>
      </AddFacilityContext.Provider>
      {isReviewOrder && (
        <RequestingFacilityReviewOrder
          facility={data.requestingFacility?.value!}
          editButtonClicked={editButtonClicked}
          isOrderSummary={isOrderSummary}
        />
      )}
    </div>
  );
};
