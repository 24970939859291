import { ReactNode } from "react";
import { IInPatientOrderPopupSection } from "./inpatientOrderPopup.enum";
import ReSubmitAcuteOrderErrorPopup from "./reSubmitErrorPopup/ReSubmitErrorPopup.component";
import {
  acuteOrderFailurePopupStaticText,
  reSubmitWoundInfoFailurePopupStaticText,
} from "./inpatientOrderPopup.staticText";
import { ResubmissionOfOrderOrWoundSuccessPopup } from "../../../myPatients/allAlerts/acuteOrderOrWoundApiResubmission/component/resubmissionOfOrderOrWoundSuccess/resubmissionOfOrderOrWoundSuccessPopup.component";

export interface InPatientOrderContainer {
  popupSection: IInPatientOrderPopupSection;
  handleResubmitAcuteOrder: Function;
  handleResubmitWoundInfoAcuteOrder: Function;
  closePopup: Function;
}

const InpatientOrderPopup = ({
  popupSection,
  handleResubmitAcuteOrder,
  handleResubmitWoundInfoAcuteOrder,
  closePopup,
}: InPatientOrderContainer) => {
  const sectionsToDisplay = (): ReactNode => {
    let section: ReactNode;
    switch (popupSection) {
      case IInPatientOrderPopupSection.RE_SUBMIT_ACUTE_ORDER:
        section = (
          <ReSubmitAcuteOrderErrorPopup
            popupContent={acuteOrderFailurePopupStaticText}
            handleButtonAction={handleResubmitAcuteOrder}
          />
        );
        break;
      case IInPatientOrderPopupSection.RE_SUBMIT_WOUND_IFNO_ACUTE_ORDER:
        section = (
          <ReSubmitAcuteOrderErrorPopup
            popupContent={reSubmitWoundInfoFailurePopupStaticText}
            handleButtonAction={handleResubmitWoundInfoAcuteOrder}
          />
        );
        break;
      case IInPatientOrderPopupSection.SUCCESS_ACUTE_ORDER:
        section = (
          <ResubmissionOfOrderOrWoundSuccessPopup
            btnAction={closePopup}
            description1={
              "Success! Your wound information has been added to your order."
            }
          />
        );
        break;
    }
    return section;
  };

  return <>{sectionsToDisplay()}</>;
};

export default InpatientOrderPopup;
