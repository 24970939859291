import moment from "moment";
import "./unlinkedUsersTable.css";
import {
  IUnlinkedUser,
  IUnlinkedUsersTable,
} from "../../manageUserAccounts.interface";
import Skeleton from "react-loading-skeleton";
import { Button, Typography } from "@mui/material";
import Table from "../../../../../core/customSortingTable/table.component";
import { makeCapitalEachWordInString } from "../../../../../util/utilityFunctions";
import { useContext } from "react";
import {
  RolesPermissionContextType,
  RolesPermissionContext,
} from "../../../../../context/RolesPermissionContext";

export const UnlinkedUsersTable = ({
  openFacilityPopUp,
  openSelectedUserProfile,
  setUnlinkedUsers,
  skeletonLoading,
  unlinkedUsers,
}: IUnlinkedUsersTable) => {
  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );
  const columns = [
    {
      label: "Action",
      accessor: "",
      sortable: false,
      sortID: 1,
    },
    { label: "Email", accessor: "email", sortable: true, sortID: 2 },
    { label: "Last Name", accessor: "lastName", sortable: true, sortID: 3 },
    {
      label: "First Name",
      accessor: "firstName",
      sortable: true,
      sortID: 4,
    },
    {
      label: "Requested",
      accessor: "dateOfRegistration",
      sortable: true,
      sortID: 5,
    },
    {
      label: "Requested Facility",
      accessor: "facilityName",
      sortable: true,
      sortID: 6,
    },
  ];

  const handleSorting = (sortField: any, sortOrder: any) => {
    if (sortField) {
      const sorted = [...unlinkedUsers].sort((a: any, b: any) => {
        if (a[sortField] === null) return 1;
        if (b[sortField] === null) return -1;
        if (a[sortField] === null && b[sortField] === null) return 0;
        return (
          a[sortField].toString().localeCompare(b[sortField].toString(), "en", {
            numeric: true,
          }) * (sortOrder === "asc" ? 1 : -1)
        );
      });
      setUnlinkedUsers(sorted);
    }
  };

  return (
    <div
      className="unlinked-user-table-container"
      data-testid="unlinked-user-table-table-container"
    >
      <Table
        tableClassName="unlinked-users-table"
        tableColumns={columns}
        handleSorting={handleSorting}
      >
        {skeletonLoading ? (
          [...Array(10)].map((_) => (
            <tr>
              <td className="unlinked-users-table-users-static-data">
                <Skeleton />
              </td>
              <td className="unlinked-users-table-users-static-data">
                <Skeleton />
              </td>
              <td className="unlinked-users-table-users-static-data">
                <Skeleton />
              </td>
              <td className="unlinked-users-table-users-static-data">
                <Skeleton />
              </td>
              <td className="unlinked-users-table-users-static-data">
                <Skeleton />
              </td>
              <td className="unlinked-users-table-users-static-data">
                <Skeleton />
              </td>
            </tr>
          ))
        ) : (
          <>
            {columns && unlinkedUsers && unlinkedUsers.length > 0 ? (
              <tbody>
                {unlinkedUsers.map((data: IUnlinkedUser, index: number) => {
                  return (
                    <tr>
                      <td>
                        <Button
                          className={
                            permissionObj?.mappedRolesPermissionData
                              ?.IsSupportRole!
                              ? `unlinked-user-tables-link-to-facility-disabled`
                              : `unlinked-user-tables-link-to-facility`
                          }
                          data-testid={`link-to-facility-${index + 1}`}
                          onClick={() => {
                            openFacilityPopUp(data);
                          }}
                          disabled={
                            permissionObj?.mappedRolesPermissionData
                              .IsSupportRole
                          }
                        >
                          Link to Facility
                        </Button>
                      </td>
                      <td
                        className="unlinked-user-tables-email"
                        data-testid={`email-${index + 1}`}
                        onClick={() => {
                          openSelectedUserProfile(data.email);
                        }}
                      >
                        {data.email}
                      </td>
                      <td
                        className="unlinked-user-tables-data"
                        data-testid={`last-name-${index + 1}`}
                      >
                        {makeCapitalEachWordInString(`${data.lastName}`)}
                      </td>
                      <td
                        className="unlinked-user-tables-data"
                        data-testid={`first-name-${index + 1}`}
                      >
                        {makeCapitalEachWordInString(`${data.firstName}`)}
                      </td>
                      <td
                        className="unlinked-user-tables-data"
                        data-testid={`requested-${index + 1}`}
                      >
                        {moment
                          .utc(data.dateOfRegistration)
                          .local()
                          .format("MM/DD/YY")}
                      </td>
                      <td
                        className="unlinked-user-tables-data"
                        data-testid={`facility-details-${index + 1}`}
                      >
                        <div className="facilityDetails">
                          <Typography
                            className="facilityName"
                            data-testid="delivery-address"
                          >
                            {makeCapitalEachWordInString(data.facilityName)}
                          </Typography>
                          <Typography
                            className="facilityAddress"
                            data-testid="address1"
                          >
                            {`${makeCapitalEachWordInString(
                              data.facilityAddress1
                            )}, ${
                              data.facilityAddress2 &&
                              data.facilityAddress2 !== ""
                                ? `${makeCapitalEachWordInString(
                                    data.facilityAddress2
                                  )},`
                                : ""
                            } ${makeCapitalEachWordInString(
                              data.facilityCity
                            )}, ${data.facilityState} ${data.facilityZipCode}`}
                          </Typography>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            ) : (
              <tbody></tbody>
            )}
          </>
        )}
      </Table>
      {!skeletonLoading && unlinkedUsers.length === 0 && (
        <p className="no-data" data-testid="no-data">
          No unlinked users are available.
        </p>
      )}
    </div>
  );
};
