import { useState } from "react";
import "./confirmCancelPopup.css";
import { useHistory, useLocation } from "react-router-dom";
import { Popup } from "../../../core/popup/popup.component";
import { ExpressButton } from "../../../core/expressButton/expressButton.component";

type CancelPopupProps = {
  handleClose: () => void;
};

export const CancelPopupForConfirmPlacement = ({
  handleClose,
}: CancelPopupProps) => {
  const [open, setOpen] = useState(true);
  const history = useHistory();

  const handleCancel = () => {
    setOpen(false);
    handleClose();
  };

  const cancelButtonHandler = () => {
    setOpen(false);
    handleClose();
    history.push("/home");
  };

  return (
    <div className="confirm-placement-popup">
      {open && (
        <Popup
          openFlag={open}
          closeHandler={handleCancel}
          dialogParentClass="empty-pop-up-pop"
        >
          <h2
            className="confirm-placement-heading"
            data-testid="confirm-header"
          >
            Are you sure you want to cancel placement & proof of delivery?
          </h2>
          <p className="confirm-placement-subheading" data-testid="sub-heading">
            Please remember that failure to complete the Proof of Delivery (POD)
            prior to placement will cause the patient to not be covered for
            therapy by insurance.
          </p>
          <div className="confirm-placement-back-button">
            <ExpressButton
              parentClass="confirm-page-backbutton"
              variant="outlined"
              clickHandler={handleCancel}
              testId="back-button"
            >
              Back to Confirm Placement
            </ExpressButton>
          </div>
          <div className="confirm-cancel-container">
            <ExpressButton
              parentClass="confirm-cancel"
              variant="contained"
              clickHandler={cancelButtonHandler}
              testId="Cancel-Confirmation"
            >
              Cancel Confirmation
            </ExpressButton>
          </div>
        </Popup>
      )}
    </div>
  );
};
