import { ValidationStatus } from "../../../../core/interfaces/input.interface";
import { getDeepClone } from "../../../../util/ObjectFunctions";
import { IAddSiteStatus } from "./addSiteStatus.interface";
import { siteStatusDisplayAudienceData } from "./siteStatusDisplayAudience/siteStatusDisplayAudience.data";
import { siteStatusLocationData } from "./siteStatusLocation/siteStatusLocation.data";

export let defaultAddSiteStatusData: IAddSiteStatus = {
  id: {
    valid: ValidationStatus.VALID,
    value: "",
  },
  statusType: {
    componentId: "sitestatus-type-radio",
    isDefaultValid: true,
    order: 1,
    required: true,
    title: "Status Type",
    valid: ValidationStatus.VALID,
    value: "message",
  },
  activation: {
    isDefaultValid: true,
    componentId: "sitestatus-activation-checkbox",
    required: false,
    title: "Activation",
    valid: ValidationStatus.VALID,
    value: "yes",
  },
  location: {
    componentId: "site-status-location-multi-checkbox",
    order: 7,
    valid: ValidationStatus.UNTOUCHED,
    value: getDeepClone(siteStatusLocationData),
    required: true,
    title: "Locations",
    errorMessage: null,
    isCheckBox: true,
  },
  audience: {
    componentId: "site-status-audience-multi-checkbox",
    order: 8,
    valid: ValidationStatus.UNTOUCHED,
    value: getDeepClone(siteStatusDisplayAudienceData),
    required: true,
    title: "Display Audience",
    errorMessage: null,
    isCheckBox: true,
  },
  title: {
    componentId: "siteStatus-content-title",
    id: "siteStatus-content-input-title",
    order: 2,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: true,
    errorMessage: null,
    title: "Title - First line of message",
  },
  detail: {
    componentId: "siteStatus-content-detail",
    id: "siteStatus-content-input-detail",
    order: 3,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
    errorMessage: null,
    title: "Detail - Second line of message",
  },
  urlLink: {
    componentId: "siteStatus-content-urlLink",
    id: "siteStatus-content-input-urlLink",
    order: 4,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
    errorMessage: null,
    title: "URL link for additional information",
  },
  linkText: {
    componentId: "siteStatus-content-linkText",
    id: "siteStatus-content-input-linkText",
    order: 5,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
    errorMessage: null,
    title: "Link text",
  },
  messageFormat: {
    componentId: "siteStatus-content-messageFormat",
    id: "siteStatus-content-input-messageFormat",
    order: 6,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: true,
    errorMessage: null,
    title: "How should the message be formatted?",
  },
};
