import TextField from "@mui/material/TextField";
import React, { useState, useEffect, useContext } from "react";
import { ExpressButton } from "../../../../../core/expressButton/expressButton.component";
import { LoadingSpinner } from "../../../../../core/loader/LoadingSpinner";
import { SearchPrescriberModal } from "../../searchPrescriber.enum";
import "./nationalRegistryResult.css";
import NationalRegistryResultList from "./nationalRegistryResultList.component";
import { ReactComponent as SearchIconSvg } from "../../../../../assets/blackSearchIcon.svg";
import { useSortableTable } from "../../../../../util/utilityFunctions";
import {
  defaultNationalRegistryFilterBox,
  IFilterNationalRegistry,
} from "./filterNationalRegistry.model";
import { NewOrderValidator } from "../../../newOrder.validator";
import { INationalRegistry } from "./nationalRegistry.interface";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import {
  AdminMyListsContext,
  AdminMyListsContextType,
} from "../../../../../context/AdministrationMyListsContext";

type Props = {
  handlePrescriberSearchType: any;
  handleselectOption?: any;
  isLocalPrescriberSearchList?: boolean;
  nationalRegistryList: any;
  handleAddPrescriberToDB?: any;
};

const columns = [
  { label: "", accessor: "orderID", sortable: false },
  { label: "Prescriber Name", accessor: "prescriberName", sortable: true },
  { label: "NPI #", accessor: "npi", sortable: true },
  { label: "City", accessor: "city", sortable: true },
  { label: "State", accessor: "state", sortable: true },
];

const NationalRegistryResult = ({
  handlePrescriberSearchType,
  handleselectOption,
  isLocalPrescriberSearchList,
  nationalRegistryList,
  handleAddPrescriberToDB,
}: Props) => {
  const [showNoData, setShowNoData] = useState(true);
  const [filterNPI, setFilterNPI] = useState<IFilterNationalRegistry>(
    defaultNationalRegistryFilterBox
  );
  const [sortedData, setSortedData, handleSorting] = useSortableTable(
    [],
    columns
  );
  const myListobj = useContext<AdminMyListsContextType | null>(
    AdminMyListsContext
  );
  const isMobileScreen = useMediaQuery("(max-width:600px)");
  const [showPrescriberNoDataError, setShowPrescriberNoDataError] =
    useState(false);
  useEffect(() => {
    if (nationalRegistryList.length > 0) {
      setSortedData(nationalRegistryList);
      setShowNoData(false);
    }
  }, []);

  useEffect(() => {
    setShowPrescriberNoDataError(sortedData.length === 0);
  }, [sortedData]);

  const noData = () => {
    return (
      <div className="nr-no-data">
        {myListobj?.isPrescriberAddedAlready
          ? "Prescriber Already Added"
          : "No data found. Please search again."}
      </div>
    );
  };

  const handleFilterNPI = (e: any) => {
    const validator = new NewOrderValidator();
    const { value } = e.target;
    const isvalid = validator.validate(value, e.target.name);
    setFilterNPI((filterNPI) => ({
      ...filterNPI,
      [e.target.name]: { value: value, valid: isvalid?.status },
    }));
    if (value.length > 2) {
      const filteredList = nationalRegistryList.filter(
        (x: INationalRegistry) => {
          return (
            x.city.toLowerCase().includes(value.toLowerCase()) ||
            x.npi.toLowerCase().includes(value.toLowerCase()) ||
            x.firstName.toLowerCase().includes(value.toLowerCase()) ||
            x.lastName.toLowerCase().includes(value.toLowerCase())
          );
        }
      );
      setSortedData(filteredList);
    } else if (value.length === 0) {
      setSortedData(nationalRegistryList);
    }
  };

  return (
    <div
      className={
        !isLocalPrescriberSearchList ? "nr-result" : "prescriber-serach-result"
      }
    >
      {isLocalPrescriberSearchList ? (
        <>
          {(nationalRegistryList.length === 0 || showPrescriberNoDataError) && (
            <div
              className="no-prescriber-list-error"
              data-testid="no-prescriber-list-error"
            >
              <p>
                <span className="no-match-text">No data found. </span>
                Would you like to{" "}
                <Typography
                  className=""
                  onClick={() =>
                    handlePrescriberSearchType(
                      SearchPrescriberModal.NATIONAL_REGISTRY_SEARCH
                    )
                  }
                  classes={{
                    root: "prescriber-national-serach-button",
                  }}
                  data-testid="prescriber-national-serach-button"
                >
                  Search in National Registry instead
                </Typography>
              </p>
            </div>
          )}
          <div className="prescriberSearchLabel">
            <Grid container spacing={2}>
              <Grid item xs={isMobileScreen ? 12 : 6}>
                <b
                  className="searchPrescriberLabelSec"
                  data-testid="prescriberSearchTitleSec"
                >
                  My Prescriber List
                </b>
              </Grid>
              {nationalRegistryList.length > 0 && sortedData.length !== 0 && (
                <Grid item xs={isMobileScreen ? 12 : 6}>
                  <Typography
                    className="prescriber-search-typography"
                    onClick={() =>
                      handlePrescriberSearchType(
                        SearchPrescriberModal.NATIONAL_REGISTRY_SEARCH
                      )
                    }
                    classes={{
                      root: "prescriber-informantion-addOrRemove-button",
                    }}
                    data-testid="prescriber-informantion-addOrRemove-button"
                  >
                    Search in National Registry instead
                  </Typography>
                </Grid>
              )}
            </Grid>
          </div>
        </>
      ) : (
        <div className="nr-header" data-testid="nr-result-header">
          National Registry Search Results
        </div>
      )}
      {showNoData && !isLocalPrescriberSearchList ? (
        noData()
      ) : (
        <>
          <div
            style={
              !isLocalPrescriberSearchList
                ? { marginTop: "20px", marginBottom: "5px" }
                : { marginBottom: "16px" }
            }
          >
            <TextField
              autoComplete="off"
              data-testid="nr-filter-npi"
              autoFocus={true}
              className="filter-npi-list"
              inputProps={{ style: { fontSize: 14 } }}
              placeholder="Filter Prescriber List by Name, City, or NPI number"
              InputProps={{
                startAdornment: (
                  <SearchIconSvg style={{ marginRight: "5px" }} />
                ),
              }}
              value={filterNPI.filterNPIList.value}
              onChange={handleFilterNPI}
              variant="outlined"
              name="filterNPIList"
            />
          </div>
          <NationalRegistryResultList
            handleSorting={handleSorting}
            columns={columns}
            nationalRegistryResultList={sortedData}
            handlePrescriberSearchType={handlePrescriberSearchType}
            isLocalPrescriberSearchList={isLocalPrescriberSearchList}
            handleselectOption={handleselectOption}
            handleAddPrescriberToDB={handleAddPrescriberToDB}
          />
        </>
      )}
      {!isLocalPrescriberSearchList && (
        <ExpressButton
          testId="back2SearchButton"
          clickHandler={() => {
            handlePrescriberSearchType(
              SearchPrescriberModal.NATIONAL_REGISTRY_SEARCH
            );
          }}
          parentClass="nr-backButton"
          variant="outlined"
        >
          Back to Search
        </ExpressButton>
      )}
    </div>
  );
};

export default NationalRegistryResult;
