import { useContext } from "react";
import { Grid } from "@mui/material";
import "./shippingAddressReview.css";
import { MobileDisplayContext } from "../../../../../context/MobileDisplayContext";
import { makeCapitalEachWordInString } from "../../../../../util/utilityFunctions";
import { InpatientSupplyOrderPageSection } from "../../../inpatientSupplyOrder.enum";
import { IShippingAddressInfo } from "../../../inpatientSupplyOrder.interface";

interface Props {
  data: IShippingAddressInfo;
  editButtonAction?: any;
  isSummaryOrDetail?: boolean;
}

export const ShippingAddressReview = ({
  data,
  editButtonAction,
  isSummaryOrDetail = false,
}: Props) => {
  const { isMobileScreen } = useContext(MobileDisplayContext);

  const showTitleAndValue = (value: string | null, testId: string) => {
    return (
      <div
        className="show-title-and-value-div"
        id="id_show-title-and-value-div"
      >
        <p
          className="show-title-and-value-value"
          data-testid={`${testId}-value`}
          id={`${testId}-value-id`}
        >
          {value && value !== "" ? value.trim() : "--"}
        </p>
      </div>
    );
  };

  return (
    <div
      className="shipping-address-review-component"
      data-testid="shipping-address-review-component"
      id="id_shipping-address-review-component"
    >
      <div
        className="shipping-address-review-header"
        id="id_shipping-address-review-header"
      >
        <p
          className="shipping-address-review-title"
          data-testid="shipping-address-review-title"
          id="shipping-address-review-title-id"
        >
          Shipping Address
        </p>
        {!isSummaryOrDetail && (
          <button
            className="shipping-address-review-edit-button"
            data-testid="shipping-address-review-edit-button"
            id="shipping-address-review-edit-button-id"
            onClick={() =>
              editButtonAction(
                "inpatient-supplyorder-shippingaddress-main-div",
                InpatientSupplyOrderPageSection.SECOND_PAGE
              )
            }
          >
            Edit
          </button>
        )}
      </div>
      <Grid
        className="shipping-address-review-grid-container"
        container
        id="id_shipping-address-review-grid-container"
      >
        <Grid
          className="shipping-address-review-grid-item"
          id="id_shipping-address-review-grid-item"
          item
          xs={isMobileScreen ? 12 : 8}
        >
          {" "}
          {showTitleAndValue(
            makeCapitalEachWordInString(
              data.shippingFacilityName.value
                ? data.shippingFacilityName.value
                : ""
            ),
            "recipent"
          )}
        </Grid>
      </Grid>
      <Grid
        className="shipping-address-review-grid-container"
        container
        id="id_shipping-address-review-grid-container"
      >
        <Grid
          className="shipping-address-review-grid-item"
          item
          id="id_shipping-address-review-grid-item"
          xs={isMobileScreen ? 12 : 8}
        >
          {showTitleAndValue(
            data.shippingAddressLine1.value
              ? makeCapitalEachWordInString(
                  data.shippingAddressLine1.value +
                    (data.shippingAddressLine2.value
                      ? " " + data.shippingAddressLine2.value
                      : "")
                )
              : "",
            "address1-address2"
          )}
        </Grid>
      </Grid>
      <Grid
        className="shipping-address-review-grid-container"
        container
        id="id_shipping-address-review-grid-container"
      >
        <Grid
          className="shipping-address-review-grid-item"
          item
          id="id_shipping-address-review-grid-item"
          xs={isMobileScreen ? 12 : 8}
        >
          {showTitleAndValue(
            data.shippingAddressState.value
              ? makeCapitalEachWordInString(
                  data.shippingAddressCity.value + ", "
                ) +
                  data.shippingAddressState.value +
                  " " +
                  data.shippingAddressZip.value
              : "",
            "state-city-zipCode"
          )}
        </Grid>
      </Grid>
    </div>
  );
};
