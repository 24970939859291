import { Box, Grid } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import React, { useContext } from "react";
import { MobileDisplayContext } from "../../../../context/MobileDisplayContext";
import { InputWithLabel } from "../../../../core/inputWithLabel/inputWithLabel.component";
import { ValidationStatus } from "../../../../core/interfaces/input.interface";
import { formatedWoundValue } from "../../../../util/utilityFunctions";
import { ISecondaryWoundInfo } from "../../clinicalInformation/secondaryWoundInfo/secondaryWoundInfo.interface";
import { NewOrderValidator } from "../../newOrder.validator";
import { INewOrderWoundInfo } from "../../newOrderWoundInfoStepper/newOrderWoundInfo.interface";
import { woundDimensionProps } from "../woundDimension.interfaces";
import "./../woundDimension.css";

const WoundMeasurement = ({
  woundInfoData,
  setWoundInfoData,
  Validator = new NewOrderValidator(),
}: woundDimensionProps) => {
  const [validator] = React.useState<NewOrderValidator>(Validator!);
  const { isMobileScreen } = useContext(MobileDisplayContext);

  const formatWoundZeros = async (e: any) => {
    let { value, name, required } = e.target;
    let key: keyof typeof woundInfoData = name;
    let formatedValue = formatedWoundValue(woundInfoData[key].value);

    setWoundInfoData({
      ...woundInfoData,
      [name]: {
        ...Object(woundInfoData)[name],
        value: formatedValue,
        valid:
          value.length === 0 && woundInfoData[key].required
            ? ValidationStatus.INVALID
            : ValidationStatus.VALID,
        required: required,
        errorMessage: null,
        componentId: woundInfoData[key].componentId,
        order: woundInfoData[key].order,
      },
    });

    validateDepthValueNotZero(formatedValue, key);
  };

  const validateAndSetDepthData = (e: any) => {
    let { value, name, required } = e.target;

    let key: keyof typeof woundInfoData = name;
    let isValid = validator.validate(value, name);

    if (isValid?.status === ValidationStatus.VALID) {
      setWoundInfoData({
        ...woundInfoData,
        [name]: {
          ...Object(woundInfoData)[name],
          value: value,
          valid:
            value.length === 0 && woundInfoData[key].required
              ? ValidationStatus.INVALID
              : isValid?.status,
          required: required,
          errorMessage: null,
        },
      });
    }
  };

  const validateDepthValueNotZero = (
    value: string,
    key: keyof (INewOrderWoundInfo | ISecondaryWoundInfo)
  ) => {
    let convertValue = parseFloat(value);
    if (convertValue === 0) {
      woundInfoData[key].value = value;
      woundInfoData[key].valid = ValidationStatus.INVALID;
      setWoundInfoData(Object.assign({}, woundInfoData));
    }
  };

  return (
    <Box className="dimension-box-container" sx={{ flexGrow: 1 }}>
      <Grid className="dimension-grid-container" container spacing={2}>
        <Grid item xs={isMobileScreen ? 4 : 3}>
          <InputWithLabel
            label="Length (cm)"
            isRequired={false}
            error={
              woundInfoData?.woundLength.valid === ValidationStatus.INVALID
            }
            testId="woundLength-title"
            id={woundInfoData?.woundLength.componentId!}
            isShowWarning={true}
            warningMessage={woundInfoData?.woundLength.errorMessage}
          >
            <InputBase
              placeholder="0"
              className="depth-input"
              name="woundLength"
              value={woundInfoData?.woundLength.value}
              onChange={validateAndSetDepthData}
              onBlur={formatWoundZeros}
              required={false}
              id={woundInfoData?.woundLength.id!}
              data-testid="woundLength"
              type="text"
              inputProps={{
                inputMode: "decimal",
                pattern: "^[0-9.]+$",
              }}
            />
          </InputWithLabel>
        </Grid>
        <Grid item xs={isMobileScreen ? 4 : 3}>
          <InputWithLabel
            label="Width (cm)"
            isRequired={false}
            error={woundInfoData?.woundWidth.valid === ValidationStatus.INVALID}
            testId="woundWidth-title"
            id={woundInfoData?.woundWidth.componentId!}
            isShowWarning={true}
            warningMessage={woundInfoData?.woundWidth.errorMessage}
          >
            <InputBase
              placeholder="0"
              className="depth-input"
              name="woundWidth"
              value={woundInfoData?.woundWidth.value}
              onChange={validateAndSetDepthData}
              onBlur={formatWoundZeros}
              required={false}
              id={woundInfoData?.woundWidth.id!}
              data-testid="woundWidth"
              type="text"
              inputProps={{
                inputMode: "decimal",
                pattern: "^[0-9.]+$",
              }}
            />
          </InputWithLabel>
        </Grid>
        <Grid item xs={isMobileScreen ? 4 : 3}>
          <InputWithLabel
            label="Depth (cm)"
            isRequired={false}
            error={woundInfoData?.woundDepth.valid === ValidationStatus.INVALID}
            testId="woundDepth-title"
            id={woundInfoData?.woundDepth.componentId!}
            isShowWarning={true}
            warningMessage={woundInfoData?.woundDepth.errorMessage}
          >
            <InputBase
              placeholder="0"
              className="depth-input"
              name="woundDepth"
              value={woundInfoData?.woundDepth.value}
              onChange={validateAndSetDepthData}
              onBlur={formatWoundZeros}
              required={false}
              id={woundInfoData?.woundDepth.id!}
              data-testid="woundDepth"
              type="text"
              inputProps={{
                inputMode: "decimal",
                pattern: "^[0-9.]+$",
              }}
            />
          </InputWithLabel>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WoundMeasurement;
