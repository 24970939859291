import { Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import "./myListStorageLocationsCustomHeader.css";
import { STATIC_TEXT_MY_LISTS_INFO_TOOLTIP } from "../../../../../util/staticText";

export const MyListStorageLocationsCustomHeader = () => {
  return (
    <div className="myLists-custom-header-div">
      <div
        className="myLists-customHeaderLabel"
        id="myLists-customHeaderLabel-id"
        data-testid="myLists-customHeaderLabel"
      >
        Enabled
      </div>
      <Tooltip
        classes={{
          popper: "myLists-customHeader-popper",
          tooltip: "myLists-customHeader-tooltip",
        }}
        title={
          <div
            className="myLists-customHeader-tooltip-div"
            id="myLists-customHeader-tooltip-div-id"
          >
            <div
              className="myLists-customHeader-tooltip-subTitle"
              id="myLists-customHeader-tooltip-subTitle-id"
            >
              Please Note
            </div>
            <div
              className="myLists-customHeader-tooltip-content"
              id="myLists-customHeader-tooltip-content-id"
            >
              <p>{STATIC_TEXT_MY_LISTS_INFO_TOOLTIP}</p>
            </div>
          </div>
        }
      >
        <InfoOutlinedIcon classes={{ root: "myLists-infoIcon-tooltipRoot" }} />
      </Tooltip>
    </div>
  );
};
