import { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { ExpressButton } from "../../../../core/expressButton/expressButton.component";
import { LoadingSpinner } from "../../../../core/loader/LoadingSpinner";
import {
  adminCheckPostAcuteFacility,
  caregiverFacilitySearchWithError,
} from "../../../../util/3meService";
import { makeCapitalEachWordInString } from "../../../../util/utilityFunctions";
import { AddFacilityContext } from "../addFacilityContainer/addFacilityContainer.context";
import { SearchSection } from "../addFacilityContainer/addFacilityContainer.enum";
import { FacilityMode, IFacility } from "../facility.interface";
import "./facilityFound.css";
import { IFacilityFound } from "./facilityFound.interface";
import { UnlinkedUsersRequestedFacility } from "../../../administration/manageUserAccounts/UnlinkedUsers/UnlinkedUsersRequestedFacility/unlinkedUsersRequestedFacility.component";
import {
  InternalSignOnContextType,
  InternalSignOnContext,
} from "../../../../context/InternalSignOnContext";
import { MobileDisplayContext } from "../../../../context/MobileDisplayContext";
import ErrorPopup from "../../../../core/errorPopup/errorPopup.component";
import { ERROR_LINK_TO_FACILITY_SELECT } from "../../../../util/errorMsg";

export const FacilityFound = ({
  facilities,
  redirectHandler,
  requestedFacility,
  isUnlinkedUserFlow = false,
}: IFacilityFound) => {
  const { addFacilityToList } = useContext(AddFacilityContext);
  const [loadSpinner, setLoadSpinner] = useState<boolean>(false);
  const location = useLocation();
  const internalObj = useContext<InternalSignOnContextType | null>(
    InternalSignOnContext
  );
  const [openErrorPopup, setOpenErrorPopup] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [errorCode, setErrorCode] = useState<string>("");
  const { isMobileScreen } = useContext(MobileDisplayContext);

  const addFacilityHandler = async (e: any, facility: IFacility) => {
    let postAcuteValue;
    if (
      location.pathname === "/registration" ||
      location.pathname === "/manageProfile" ||
      location.pathname === "/administration/manageUserAccounts" ||
      (location.pathname === "/administration/manageUsers/userProfile" &&
        isUnlinkedUserFlow)
    ) {
      setLoadSpinner(true);
      let response = await adminCheckPostAcuteFacility({
        FacilityTypeCode: facility.typeCode,
      });
      if (response && response.succeeded) {
        postAcuteValue = response.data.postAcuteFound;
        const reqParam = {
          customerAccountNumber: facility.accountNumber,
          customerName: facility.accountName,
          state: facility.state,
        };
        if (facility.siteUseCode?.includes("CG")) {
          response = await caregiverFacilitySearchWithError(reqParam);
          if (response && response.succeeded) {
            let homeCareGiverDetails = response.items.sort(
              (a: { customerName: string }, b: { customerName: string }) =>
                a.customerName > b.customerName ? 1 : -1
            );
            if (homeCareGiverDetails?.length > 0) {
              const homeCareGiverDetail = homeCareGiverDetails[0];
              facility.careGiverId = homeCareGiverDetail.origSystemReference;
            }
          } else {
            setLoadSpinner(false);
            setOpenErrorPopup(true);
            setErrorCode(
              response?.error?.code ||
                response?.status ||
                response?.error?.errorCode
            );
            setErrorMsg(ERROR_LINK_TO_FACILITY_SELECT);
            return;
          }
        }
      } else {
        setLoadSpinner(false);
        setOpenErrorPopup(true);
        setErrorCode(
          response?.error?.code ||
            response?.status ||
            response?.error?.errorCode
        );
        setErrorMsg(ERROR_LINK_TO_FACILITY_SELECT);
        return;
      }
    }

    const newFacility = {
      accountId: facility.accountId,
      accountName: facility.accountName,
      typeName: facility.typeName,
      addressId: facility.addressId,
      address1: facility.address1,
      address2: facility.address2,
      city: facility.city,
      state: facility.state,
      zip: facility.zip,
      accountNumber: facility.accountNumber,
      typeCode: facility.typeCode,
      facilityMode: FacilityMode.LINKED,
      siteUseId: facility.siteUseId,
      careGiverId: facility.careGiverId ?? null,
      siteUseCode: facility.siteUseCode,
      isPostAcute: postAcuteValue,
      readyCareFlag: facility.readyCareFlag ?? "",
    };
    addFacilityToList(newFacility);
  };

  function renderFacilities() {
    return (
      Array.isArray(facilities) &&
      facilities.map((item, index) => {
        return (
          <div
            key={index}
            className={
              index % 2 === 0 ? "facility-card-even" : "facility-card-odd"
            }
          >
            <div
              className="select-facility"
              onClick={(e) => addFacilityHandler(e, item)}
            >
              Select
            </div>
            <div>
              <div className="facility-name" data-testid="facility-name">
                {makeCapitalEachWordInString(item.accountName)}
              </div>
              <div className="facility-details-view">
                <div className="facility-details">
                  {makeCapitalEachWordInString(item.address1)}
                  {item.address2 && item.address2 !== ""
                    ? `${", " + makeCapitalEachWordInString(item.address2)}`
                    : ""}
                </div>
                {!isMobileScreen && (
                  <div className="facility-details-facility-id-label">
                    Facility ID
                    <span className="facility-details-facility-Id">
                      {item.accountNumber}
                    </span>
                  </div>
                )}
              </div>
              <div className="facility-details">
                {makeCapitalEachWordInString(item.city)}, {item.state}{" "}
                {item.zip}
              </div>
              {isMobileScreen && (
                <div className="facility-details-facility-id-label">
                  Facility ID
                  <span className="facility-details-facility-Id">
                    {item.accountNumber}
                  </span>
                </div>
              )}
            </div>
          </div>
        );
      })
    );
  }
  const handleErrorPopup = () => {
    setOpenErrorPopup(false);
  };
  return (
    <>
      {loadSpinner && (
        <div className="loader">
          <LoadingSpinner />
        </div>
      )}
      {!loadSpinner && (
        <div className="search-list-component">
          <div className="search-results" data-testid="facility-found">
            Facility Results
          </div>
          {isUnlinkedUserFlow && (
            <UnlinkedUsersRequestedFacility
              requestedFacility={requestedFacility!}
              facilityFound={true}
            />
          )}
          <div className="grid-container">{renderFacilities()}</div>

          <div className="back-to-search-btn-container">
            {internalObj?.isVACOrderSSO === true && (
              <ExpressButton
                variant="outlined"
                parentClass="add-manual-facility-btn"
                clickHandler={() => redirectHandler(SearchSection.MANUAL_ADD)}
              >
                <div className="add-manual-facility-btn-label">
                  Enter Facility Manually
                </div>
              </ExpressButton>
            )}
            <ExpressButton
              variant={
                internalObj?.isVACOrderSSO === true ? "contained" : "outlined"
              }
              parentClass="back-to-search-btn"
              clickHandler={() => redirectHandler(SearchSection.SEARCH_FORM)}
            >
              <div
                className={
                  internalObj?.isVACOrderSSO === true
                    ? "back-to-search-label"
                    : "back-to-search-label-contained"
                }
              >
                Search Again
              </div>
            </ExpressButton>
          </div>
          <ErrorPopup
            handleBackButton={handleErrorPopup}
            errorMessage={errorMsg}
            errorCode={errorCode}
            errorPopupFlag={openErrorPopup}
            popUpStyles={"error-popup-design"}
            showTitle={false}
            buttontitle="Done"
            isSupportPhoneRequired={false}
          />
        </div>
      )}
    </>
  );
};
