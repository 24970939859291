import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import "./facilityPermissions.css";
import { useHistory } from "react-router-dom";
import { Category } from "./category/category.component";
import {
  AdminRolesPermissionsContext,
  AdminRolesPermissionsContextType,
} from "../../../../context/AdminRolesPermissionsContext";
import {
  permissionsReducer,
  TOGGLE_PERMISSION,
  TOGGLE_SUB_PERMISSION,
} from "./category/permissionCheckbox/permissionsReducer";
import { categoryOrder } from "../../../../util/staticText";
import { Popup } from "../../../../core/popup/popup.component";
import { IFacilityPermissions } from "./facilityPermissions.interface";
import { IFacilitySettingPermission } from "../facilitySettings.interface";

export const FacilityPermissions = ({
  originalPermissions,
  setIsPermissionChanged,
  setPermissions,
}: IFacilityPermissions) => {
  const history = useHistory();
  const [permissions, dispatch] = useReducer(
    permissionsReducer,
    originalPermissions
  );
  const [openEmptyPopUp, setOpenEmptyPopUp] = useState<boolean>(false);
  const adminRolePermissionsObj =
    useContext<AdminRolesPermissionsContextType | null>(
      AdminRolesPermissionsContext
    );

  const categories = useMemo(() => {
    const categorizedPermissions = permissions.reduce(
      (acc: { [key: string]: IFacilitySettingPermission[] }, item) => {
        if (!item.isHide) {
          if (!acc[item.category]) {
            acc[item.category] = [];
          }
          acc[item.category].push(item);
        }
        return acc;
      },
      {}
    );
    return categoryOrder.reduce(
      (acc: { [key: string]: IFacilitySettingPermission[] }, category) => {
        if (categorizedPermissions[category]) {
          acc[category] = categorizedPermissions[category];
        }
        return acc;
      },
      {}
    );
  }, [permissions]);

  const handleTogglePermission = useCallback((permissionName: string) => {
    dispatch({ type: TOGGLE_PERMISSION, permissionName });
  }, []);

  const handleToggleSubPermission = useCallback(
    (parentPermissionName: string, subPermissionName: string) => {
      dispatch({
        type: TOGGLE_SUB_PERMISSION,
        parentPermissionName,
        subPermissionName,
      });
    },
    []
  );

  const redirectToRolePermissions = () => {
    adminRolePermissionsObj?.resetRoleSettings();
    history.push("/administration/rolesPermissions");
  };

  // Compare initial and current permissions to determine if modified
  useEffect(() => {
    const isDifferent =
      JSON.stringify(originalPermissions) !== JSON.stringify(permissions);
    setIsPermissionChanged(isDifferent);
    setPermissions(permissions);
  }, [originalPermissions, permissions]);

  return (
    <>
      <div className="facility-permission" data-testid="facility-permission">
        <h2
          className="facility-permission-header"
          data-testid="facility-permission-header"
        >
          User Access Permissions
        </h2>
        <h5
          className="facility-permission-description"
          data-testid="facility-permission-description"
        >
          Which of the following features should be enabled for this facility?
        </h5>
        <div
          className="facility-permissions"
          data-testid="facility-permissions"
        >
          {Object.keys(categories).map((category) => (
            <Category
              key={category}
              category={category}
              permissions={categories[category]}
              onTogglePermission={handleTogglePermission}
              onToggleSubPermission={handleToggleSubPermission}
            />
          ))}
        </div>
        <div className="facility-permission-description-detail-div">
          <h6
            className="facility-permission-description-detail"
            data-testid="facility-permission-description-detail"
          >
            These settings do not change which features are enabled by default
            for new facility users. That information can be changed by going to
            the specific facility’s{" "}
            <b className="administration">Administration</b> functionality and
            changing{" "}
            <b className="role-privileges" onClick={redirectToRolePermissions}>
              {" Role Permissions"}
            </b>
            {"."}
          </h6>
        </div>
      </div>
      <Popup
        openFlag={openEmptyPopUp}
        closeHandler={() => setOpenEmptyPopUp(false)}
        dialogParentClass={"facility-permission-empty-pop-up"}
      >
        <div></div>
      </Popup>
    </>
  );
};
