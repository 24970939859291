import { useContext } from "react";
import { Grid, useMediaQuery } from "@mui/material";
import "./supplyOrderPatienInformationReview.css";
import parse from "html-react-parser";
import {
  makeCapitalEachWordInString,
  convertStringToDate,
} from "../../../../util/utilityFunctions";
import { IPatientAndProductDetail } from "../../../inpatientOrder/components/patientAndProductDetail/patientAndProductDetail.interface";

interface Props {
  data: IPatientAndProductDetail;
  editButtonAction?: any;
  isSummaryOrDetail?: boolean;
}

export const PatientInformationReview = ({ data }: Props) => {
  const isMobileScreen = useMediaQuery("(max-width:600px)");

  const showTitleAndValue = (
    title: string,
    value: string | null,
    testId: string
  ) => {
    return (
      <div
        className="show-title-and-value-div"
        id="id_show-title-and-value-div"
      >
        <p
          className="show-title-and-value-title"
          data-testid={`${testId}-title`}
          id={`${testId}-title-id`}
        >
          {title}
        </p>
        <p
          className="show-title-and-value-value"
          data-testid={`${testId}-value`}
          id={`${testId}-value-id`}
        >
          {value && value !== "" ? value.trim() : "--"}
        </p>
      </div>
    );
  };

  return (
    <div
      className="patient-information-review-component"
      data-testid="patient-information-review-component"
      id="id_patient-information-review-component"
    >
      <div
        className="patient-information-review-header"
        id="id_patient-information-review-header"
      >
        <p
          className="patient-information-review-title"
          data-testid="patient-information-review-title"
          id="patient-information-review-title-id"
        >
          Patient Information
        </p>
      </div>
      <Grid
        className="patient-information-review-grid-container"
        container
        id="id_patient-information-review-grid-container"
      >
        <Grid
          className="patient-information-review-grid-item"
          id="id_patient-information-review-grid-item"
          item
          xs={isMobileScreen ? 12 : 5}
        >
          {showTitleAndValue(
            "Full Name",
            data.patient.firstName && data.patient.lastName
              ? makeCapitalEachWordInString(
                  data.patient.firstName.trim() +
                    " " +
                    data.patient.lastName.trim()
                )
              : "",
            "patient-full-name"
          )}
        </Grid>
        <Grid
          className="patient-information-review-grid-item"
          item
          id="id_patient-information-review-grid-item"
          xs={isMobileScreen ? 12 : 5}
        >
          {showTitleAndValue(
            "Date of Birth",
            data.patient.dateOfBirth
              ? convertStringToDate(data.patient.dateOfBirth)
              : "",
            "dob"
          )}
        </Grid>
        <Grid
          className="patient-information-review-grid-item"
          item
          id="id_patient-information-review-grid-item"
          xs={isMobileScreen ? 12 : 5}
        ></Grid>
      </Grid>
      <Grid className="patient-information-review-grid-container" container>
        <Grid
          className="patient-information-review-grid-item"
          item
          xs={isMobileScreen ? 12 : 5}
        >
          <div className="show-title-and-value-div">
            <p
              className="show-title-and-value-title"
              data-testid="product-title"
              id="Need-by-Date-and-Time-review-test-id"
            >
              Product
            </p>
            <p
              className="show-title-and-value-value"
              data-testid="product-value"
              id="Need-by-Date-and-Time-review-test-value-id"
            >
              {data.product && data.product.name
                ? parse(data.product.name)
                : ""}
            </p>
          </div>
        </Grid>
        <Grid
          className="patient-information-review-grid-item"
          item
          xs={isMobileScreen ? 12 : 5}
        >
          {showTitleAndValue(
            "Rental Order #",
            data.patient && data.patient.roNumber?.toString()
              ? data.patient.roNumber.toString()
              : "",
            "rental-order-number"
          )}
        </Grid>
      </Grid>
    </div>
  );
};
