import { Button, Grid } from "@mui/material";
import React, { ReactNode, useContext, useEffect, useState } from "react";
import { ReactComponent as SearchIcon } from "../../../assets/searchPayer.svg";
import { AuthContext, AuthContextType } from "../../../context/AuthContext";
import {
  InternalSignOnContext,
  InternalSignOnContextType,
} from "../../../context/InternalSignOnContext";
import {
  NewOrderContext,
  NewOrderContextType,
} from "../../../context/NewOrderContext";
import { CaregiverFacilityFoundList } from "../../../core/caregiverSearchAndAdd/caregiverFacilityFoundList.component";
import { CaregiverFacilityNotFound } from "../../../core/caregiverSearchAndAdd/caregiverFacilityNotFound.component";
import { CaregiverFacilitySearch } from "../../../core/caregiverSearchAndAdd/caregiverFacilitySearch.component";
import {
  ICaregiverFacility,
  ICaregiverFacilitySearchRequest,
} from "../../../core/caregiverSearchAndAdd/caregiverFacilitySearchAndAdd.model";
import { defaultFacilityData } from "../../../core/caregiverSearchAndAdd/caregiverFacilitySearchDefaultData";
import { InputWithLabel } from "../../../core/inputWithLabel/inputWithLabel.component";
import { ValidationStatus } from "../../../core/interfaces/input.interface";
import { LoadingSpinner } from "../../../core/loader/LoadingSpinner";
import { Popup } from "../../../core/popup/popup.component";
import {
  caregiverFacilitySearch,
  getHomeCareProviderFacilityList,
} from "../../../util/3meService";
import { getDeepClone } from "../../../util/ObjectFunctions";
import { HOME_CARE_PROVIDER_SEARCH_MS_SERVICE_ERROR } from "../../../util/errorMsg";
import { getSiteUseId } from "../../../util/utilityFunctions";
import { IHomeCareProviderDetail } from "../../newOrder/homeCareProvider/homeCareProvider.interface";
import { SearchHomeCareProviderModal } from "../../newOrder/homeCareProvider/homeCareSearch/searchHomeCare.enum";
import SearchHomeCareProvider from "../../newOrder/homeCareProvider/homeCareSearch/homeCareSearch.component";
import { HomeCareProviderFound } from "../../newOrder/homeCareProvider/homeCareProviderFound/homeCareProviderFound.component";
import AddManualHomeCare from "../../newOrder/homeCareProvider/addManualHomeCareProvider/addManualHomeCare";
import NewOrderErrorPopupMessage from "../../newOrder/newOrderFooterGroup/newOrderErrorPopupMessage.component";
import { IHomeCareProviderData } from "./homeCareProvider.interface";
import { SelectedHomeCareProvider } from "./selectedHomeCareProvider/selectedHomeCareProvider.component";
import "./sendNoteHomeCareProvider.css";

interface ISendNoteHomeCareProvider {
  data: IHomeCareProviderData;
  setData: any;
  states: any;
  statesText: any;
}
export const HomeCareProvider = ({
  data,
  setData,
  states,
  statesText,
}: ISendNoteHomeCareProvider) => {
  const newOrderObj = useContext<NewOrderContextType | null>(NewOrderContext);
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const facilitySearchData = getDeepClone(defaultFacilityData);
  const [fData, setFData] = React.useState(facilitySearchData);
  const [enabledHCPFromMyList, setEnabledHCPFromMyList] = React.useState<
    IHomeCareProviderDetail[]
  >([]);
  const [defaultHCPInMyList, setDefaultHCPInMyList] =
    useState<IHomeCareProviderDetail | null>();
  const [facilities, setFacilities] = React.useState<ICaregiverFacility[]>([]);
  const [selectedHomeCareProvider, setSelectedHomeCareProvider] =
    useState<IHomeCareProviderDetail | null>();
  const [showerrorpopup, setShowerrorpopup] = useState(false);
  const [errorCode, setErrorCode] = useState("");
  const internalObj = useContext<InternalSignOnContextType | null>(
    InternalSignOnContext
  );
  const clearSearchData = () => {
    setFData((dt: any) => ({
      facilityName: {
        required: true,
        valid: ValidationStatus.UNTOUCHED,
        value: "",
      },
      facilityState: {
        required: true,
        valid: ValidationStatus.UNTOUCHED,
        value: "",
      },
      facilityID: {
        required: true,
        valid: ValidationStatus.UNTOUCHED,
        value: "",
      },
    }));
    newOrderObj?.setManualHomeCareProviderFrom(null);
  };

  const handleCaregiverFacilitySearch = async () => {
    var facilitySearchRequest: ICaregiverFacilitySearchRequest = {
      customerAccountNumber: fData.facilityID.value,
      customerName: fData.facilityName.value,
      state: fData.facilityState.value ? fData.facilityState.value : "",
    };
    newOrderObj?.setHomecareproviderSearchAddPopUpSection(
      SearchHomeCareProviderModal.LOADER
    );
    clearSearchData();
    const data = await caregiverFacilitySearch(facilitySearchRequest);
    if (!data?.succeeded) {
      setErrorCode(data?.error?.errorCode);
      newOrderObj?.setHomecareproviderSearchAddPopUpSection(
        SearchHomeCareProviderModal.SEARCH_HOMECAREPROVIDER
      );
      newOrderObj?.setSearchHomeCareProviderPopup(false);
      setShowerrorpopup(true);
    } else if (data.items === null) {
      newOrderObj?.setHomecareproviderSearchAddPopUpSection(
        SearchHomeCareProviderModal.FACILITY_NOTFOUND
      );
    } else if (data.items.length > 0) {
      const hcFacility = data.items.sort(
        (a: { customerName: string }, b: { customerName: string }) =>
          a.customerName > b.customerName ? 1 : -1
      ) as ICaregiverFacility[];
      setFacilities(hcFacility);
      newOrderObj?.setHomecareproviderSearchAddPopUpSection(
        SearchHomeCareProviderModal.FACILITY_LIST
      );
    } else {
      newOrderObj?.setHomecareproviderSearchAddPopUpSection(
        SearchHomeCareProviderModal.FACILITY_NOTFOUND
      );
    }
  };

  const providerSearch = () => {
    clearSearchData();
    newOrderObj?.setHomecareproviderSearchAddPopUpSection(
      SearchHomeCareProviderModal.SEARCH_HOMECAREPROVIDER
    );
  };

  const handleSelectHomeCareProvider = (data: IHomeCareProviderDetail) => {
    handleSelectedHomeCareProvider(
      `${data.name}`,
      data.accountNumber,
      `${data.address1 ?? ""}`,
      `${data.address2 ?? ""}`,
      data.city,
      data.state,
      data.zipCode ?? "",
      data.phone,
      data.facilityType,
      data.extension,
      data.marketingSegmentCode!,
      data.siteUseId!,
      data.caregiverID!
    );
  };

  const handleFacilitySelect = (facility: ICaregiverFacility) => {
    handleSelectedHomeCareProvider(
      facility.customerName,
      facility.customerAccountNumber!,
      facility.address1,
      facility.address2,
      facility.city,
      facility.state,
      facility.postalCode,
      facility.phoneNo,
      facility.marketingSegmentDescription,
      facility.extension,
      facility.marketingSegmentCode!,
      facility.siteUseId!,
      facility.origSystemReference!
    );
  };

  const handleSelectedHomeCareProvider = async (
    name: string,
    accountNumber: string,
    address: string,
    address2: string,
    city: string,
    state: string,
    zipCode: string,
    phone: string,
    type: string,
    extension: string,
    marketingSegmentCode: string,
    siteUseId: string,
    caregiverId: string
  ) => {
    newOrderObj?.setHomecareproviderSearchAddPopUpSection(
      SearchHomeCareProviderModal.LOADER
    );
    siteUseId = siteUseId
      ? siteUseId
      : caregiverId && (await fetchSiteUseId(caregiverId));
    setData((dt: IHomeCareProviderData) => ({
      ...dt,
      homeCareProvider: {
        ...dt.homeCareProvider,
        required: true,
        valid: ValidationStatus.VALID,
        value: "",
      },
      addedCaregiverName: {
        ...dt.addedCaregiverName,
        valid: ValidationStatus.VALID,
        value: name,
        required: true,
      },
      addedCaregiverAddress1: {
        ...dt.addedCaregiverAddress1,
        valid: ValidationStatus.VALID,
        value: address,
        required: true,
      },
      addedCaregiverAddress2: {
        ...dt.addedCaregiverAddress2,
        valid: ValidationStatus.VALID,
        value: address2,
        required: true,
      },
      addedCaregiverCity: {
        ...dt.addedCaregiverCity,
        valid: ValidationStatus.VALID,
        value: city,
        required: true,
      },
      addedCaregiverState: {
        ...dt.addedCaregiverState,
        valid: ValidationStatus.VALID,
        value: state,
        required: true,
      },
      addedCaregiverZip: {
        ...dt.addedCaregiverZip,
        valid: ValidationStatus.VALID,
        value: zipCode,
        required: true,
      },
      addedCaregiverPhone: {
        ...dt.addedCaregiverPhone,
        valid: ValidationStatus.VALID,
        value: phone,
        required: true,
      },
      addedCaregiverFacilityType: {
        ...dt.addedCaregiverPhone,
        valid: ValidationStatus.VALID,
        value: type,
        required: true,
      },
      addedCaregiverPhoneExtension: {
        ...dt.addedCaregiverPhoneExtension,
        valid: ValidationStatus.VALID,
        value: extension,
        required: true,
      },
      addedCaregiverFacilityTypeCode: {
        ...dt.addedCaregiverPhoneExtension,
        valid: ValidationStatus.VALID,
        value: marketingSegmentCode,
        required: true,
      },
      addedCaregiverSiteUseID: {
        ...dt.addedCaregiverPhoneExtension,
        valid: ValidationStatus.VALID,
        value: siteUseId,
        required: true,
      },
      addedCaregiverID: {
        ...dt.addedCaregiverPhoneExtension,
        valid: ValidationStatus.VALID,
        value: caregiverId,
        required: true,
      },
      addedCaregiverAccountNumber: {
        ...dt.addedCaregiverPhoneExtension,
        valid: ValidationStatus.VALID,
        value: accountNumber,
        required: true,
      },
    }));
    newOrderObj?.setCaregiverSelected(true);
    newOrderObj?.setSearchHomeCareProviderPopup(false);
  };

  const handleBackToSearch = () => {
    newOrderObj?.setHomecareproviderSearchAddPopUpSection(
      SearchHomeCareProviderModal.FACILITY_DATABASE_SEARCH
    );
  };

  const handleAddNewProvider = () => {
    newOrderObj?.setHomecareproviderSearchAddPopUpSection(
      SearchHomeCareProviderModal.ADD_MANUAL_HOMECAREPROVIDER
    );
  };

  const searchHomeCareProviderButtonAction = () => {
    newOrderObj?.setHomecareproviderSearchAddPopUpSection(
      SearchHomeCareProviderModal.SEARCH_HOMECAREPROVIDER
    );
    newOrderObj?.setSearchHomeCareProviderPopup(true);
  };

  const handleHCProviderSearchType = (section: SearchHomeCareProviderModal) => {
    newOrderObj?.setHomecareproviderSearchAddPopUpSection(section);
  };

  const searchHomeCareProviderPopupSection = () => {
    let page: ReactNode;
    switch (newOrderObj?.homecareproviderSearchAddPopUpSection) {
      case SearchHomeCareProviderModal.SEARCH_HOMECAREPROVIDER:
        page = (
          <SearchHomeCareProvider
            handleHomeCareProviderSearchType={handleHCProviderSearchType}
            setSelectedHomeCareProvider={setSelectedHomeCareProvider}
            enabledHCPFromMyList={enabledHCPFromMyList}
            handleSelectedHomeCareProvider={handleSelectHomeCareProvider}
          />
        );
        break;
      case SearchHomeCareProviderModal.HOMECAREPROVIDER_FOUND:
        page = (
          <HomeCareProviderFound
            data={selectedHomeCareProvider!}
            handleSelectedHomeCareProvider={handleSelectHomeCareProvider}
            handleHomeCareProviderSearchType={handleHCProviderSearchType}
          />
        );
        break;
      case SearchHomeCareProviderModal.ADD_MANUAL_HOMECAREPROVIDER:
        page = (
          <AddManualHomeCare
            states={states}
            statesText={statesText}
            handleFacilitySelect={handleFacilitySelect}
          />
        );
        break;
      case SearchHomeCareProviderModal.FACILITY_DATABASE_SEARCH:
        page = (
          <CaregiverFacilitySearch
            data={fData}
            setData={setFData}
            handleSearch={handleCaregiverFacilitySearch}
            redirectToProviderSearch={providerSearch}
            statesText={statesText}
            states={states}
          />
        );
        break;
      case SearchHomeCareProviderModal.FACILITY_LIST:
        page = (
          <CaregiverFacilityFoundList
            facilities={facilities}
            handleSelect={handleFacilitySelect}
            handleBackToSearch={handleBackToSearch}
            handleAddNewProvider={handleAddNewProvider}
          />
        );
        break;
      case SearchHomeCareProviderModal.FACILITY_NOTFOUND:
        page = (
          <CaregiverFacilityNotFound
            handleAddNewProvider={handleAddNewProvider}
            handleSearchAgain={handleBackToSearch}
          />
        );
        break;
      case SearchHomeCareProviderModal.LOADER:
        page = (
          <div className="homecareprovider-spinner">
            <LoadingSpinner />
          </div>
        );
        break;
    }
    return page;
  };

  const closeErrorPopup = () => {
    setShowerrorpopup(false);
  };

  useEffect(() => {
    setEnabledHCPFromMyList([]);
    setDefaultHCPInMyList(null);
    getHcpFacilityList();
  }, [
    JSON.stringify(newOrderObj?.newOrderData.requestingFacility?.value),
    authObj?.registeredFaciltyAddress,
  ]);

  const getHcpFacilityList = async () => {
    let siteUseIDValue = internalObj?.isVACOrderSSO
      ? newOrderObj?.newOrderData.requestingFacility?.value?.siteUseId!
      : getSiteUseId(authObj);
    if (siteUseIDValue) {
      const body = {
        SiteUseId: siteUseIDValue,
        ShowAllRecords: true,
      };
      const response = await getHomeCareProviderFacilityList(body);
      if (response && response?.succeeded) {
        let tempHCPList: IHomeCareProviderDetail[] = [];
        response.data.forEach((TempHCPObj: any) => {
          if (TempHCPObj.isEnabled) {
            const hcpData: IHomeCareProviderDetail = {
              name: TempHCPObj.name,
              accountNumber: TempHCPObj.accountNumber,
              address1: TempHCPObj?.address?.addressLine1,
              address2: TempHCPObj?.address?.addressLine2,
              city: TempHCPObj?.address?.city,
              state: TempHCPObj?.address?.stateCode,
              zipCode: TempHCPObj?.address?.postalCode,
              phone: "",
              facilityType: TempHCPObj.classification,
              extension: "",
              marketingSegmentCode: TempHCPObj.classificationCode,
              siteUseId: null,
              caregiverID: TempHCPObj.caregiverId,
            };
            tempHCPList.push(hcpData);
          }
        });
        setEnabledHCPFromMyList(tempHCPList);
        let filteredDefaultHCPData = response.data.filter(
          (x: any) => x.isDefault
        )[0];
        if (filteredDefaultHCPData)
          setDefaultHCPInMyList({
            ...defaultHCPInMyList,
            name: filteredDefaultHCPData.name,
            accountNumber: filteredDefaultHCPData.accountNumber,
            address1: filteredDefaultHCPData?.address?.addressLine1,
            address2: filteredDefaultHCPData?.address?.addressLine2,
            city: filteredDefaultHCPData?.address?.city,
            state: filteredDefaultHCPData?.address?.stateCode,
            zipCode: filteredDefaultHCPData?.address?.postalCode,
            phone: "",
            facilityType: filteredDefaultHCPData.classification,
            extension: "",
            marketingSegmentCode: filteredDefaultHCPData.classificationCode,
            siteUseId: await fetchSiteUseId(filteredDefaultHCPData.caregiverId),

            caregiverID: filteredDefaultHCPData.caregiverId,
          });
      }
    }
  };

  const fetchSiteUseId = async (careGiverId: string) => {
    const reqBody = {
      OrigSystemReference: careGiverId,
    };
    const response = await caregiverFacilitySearch(reqBody);
    if (response && response.succeeded && response.items.length > 0) {
      return response.items[0].siteUseId;
    } else return null;
  };

  return (
    <>
      {showerrorpopup && (
        <NewOrderErrorPopupMessage
          popUpStyles="homeCareProviderErrorPopup"
          handleBackButton={closeErrorPopup}
          errorPopupFlag={true}
          errorMessage={HOME_CARE_PROVIDER_SEARCH_MS_SERVICE_ERROR}
          handleExitButton={closeErrorPopup}
          isSupportPhoneShow={true}
          errorCode={errorCode}
        />
      )}
      <div className="sendnote-homecareprovider-component">
        <div className="sendnote-homecareprovider">
          <InputWithLabel
            error={data.homeCareProvider!.valid === ValidationStatus.INVALID}
            id={data.homeCareProvider!.componentId!}
            isRequired={true}
            isShowWarning={true}
            label="New home care provider"
            labelClassName="sendnote-homecare-provider-label-item"
            testId="sendnote-homecareprovider-administering-dress"
          >
            {data.addedCaregiverAddress1.value === "" && (
              <div className="sendnote-homecareprovider-main">
                <Grid
                  className="sendnote-homecareprovider-grid-container"
                  container
                  spacing={2}
                >
                  <Grid className="sendnote-homecareprovider-item" item xs={12}>
                    <InputWithLabel label="">
                      <Button
                        classes={{ root: "sendnote-homecareprovider-button" }}
                        data-testid="sendnote-homecareprovider-button"
                        onClick={searchHomeCareProviderButtonAction}
                        startIcon={<SearchIcon />}
                        variant="outlined"
                      >
                        Search for Home Care Provider
                      </Button>
                    </InputWithLabel>
                  </Grid>
                </Grid>
              </div>
            )}
            <div className="sendnote-homecare-provider-box-container">
              <Grid
                className="sendnote-homecare-provider-grid-container"
                container
                spacing={2}
              >
                {/* selected HCP section */}
                {data?.addedCaregiverAddress1?.value !== "" && (
                  <SelectedHomeCareProvider
                    data={data}
                    isNewHcpSelected={true}
                  />
                )}
              </Grid>
            </div>
          </InputWithLabel>
          <Popup
            openFlag={newOrderObj?.searchHomeCareProviderPopup}
            closeHandler={() => {
              clearSearchData();
              newOrderObj?.setSearchHomeCareProviderPopup(false);
            }}
            dialogParentClass={
              newOrderObj?.homecareproviderSearchAddPopUpSection
            }
          >
            {searchHomeCareProviderPopupSection()}
          </Popup>
        </div>
      </div>
    </>
  );
};
