import {
  Validation,
  ValidationStatus,
} from "../../../core/interfaces/input.interface";
import { getDeepClone } from "../../../util/ObjectFunctions";
import { Validator } from "../../../util/order.validations";

export class ManageUserAccountsValidator {
  private _validator;
  constructor(defValidator = new Validator()) {
    this._validator = defValidator;
  }

  private noValidation(txt: string): Validation {
    return { status: ValidationStatus.VALID, message: null };
  }

  private fieldToMethodMapping(
    field: string
  ): ((txt: string) => Validation) | undefined {
    const mapping = new Map<string, (txt: string) => Validation>([
      ["lastName", this._validator.lastNameValidationWithEmptyUntouched],
      ["firstName", this._validator.nameValidationWithEmptyUntouched],
      ["facilityId", this._validator.facilityIDValidationWithEmptyUntouched],
      ["email", this._validator.mandatoryEmailValidationWithEmptyUntouched],
    ]);
    const validator = mapping.get(field);
    return validator ? validator : this.noValidation;
  }

  public validate(input: string, field: string) {
    try {
      const validator = this.fieldToMethodMapping(field)!;
      return validator(input);
    } catch (error) {
      console.log(`validator method for field ${field} is not configured`);
    }
  }

  public validateAll(data: any, updateDataIfUntouchedAndValidated: Function) {
    let temp = getDeepClone(data);
    Object.keys(temp).forEach((x: string) => {
      if (temp[x].value !== "") {
        if (temp[x].valid === ValidationStatus.UNTOUCHED) {
          temp[x].valid = ValidationStatus.INVALID;
        }
        temp[x].value = temp[x].value.trim();
      }
    });
    updateDataIfUntouchedAndValidated(temp);
    const ifAllValid = Object.keys(temp)
      .filter((x) => temp[x].value !== "")
      .every((x: string) => temp[x].valid === ValidationStatus.VALID);
    return ifAllValid ? ValidationStatus.VALID : ValidationStatus.INVALID;
  }

  public validateRequiredFields(data: any) {
    let temp = getDeepClone(data);
    const valid = Object.keys(temp)
      .filter((x) => temp[x].required)
      .every((x: string) => temp[x].valid === ValidationStatus.VALID);
    return valid;
  }
}
