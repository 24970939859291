import React, { useContext } from "react";
import "./comorbodities.css";
import {
  Box,
  FormControlLabel,
  Grid,
  InputBase,
  Radio,
  RadioGroup,
} from "@mui/material";
import { NewOrderValidator } from "../newOrder.validator";
import { CustomCheckBox } from "../../../core/checkBox/checkBox.component";
import { ValidationStatus } from "../../../core/interfaces/input.interface";
import { InputWithLabel } from "../../../core/inputWithLabel/inputWithLabel.component";
import { ComorboditiesReviewOrder } from "./reviewOrder/comorboditiesReviewOrder.component";
import { INewOrderWoundInfo } from "../newOrderWoundInfoStepper/newOrderWoundInfo.interface";
import {
  NewOrderContext,
  NewOrderContextType,
} from "../../../context/NewOrderContext";
import { ReactComponent as SelectedRadioButtonIcon } from "../../../assets/selectedRadioButton.svg";
import { ReactComponent as RadioButtonIcon } from "../../../assets/radioButton.svg";
import { MobileDisplayContext } from "../../../context/MobileDisplayContext";
interface Props {
  editButtonClicked?: any;
  isOrderSummary?: boolean;
  isReviewOrder?: boolean;
  setWoundInfoData: Function;
  woundInfoData: INewOrderWoundInfo;
  Validator?: NewOrderValidator;
}
const Comorbodities = ({
  isReviewOrder = false,
  editButtonClicked,
  isOrderSummary = false,
  setWoundInfoData,
  woundInfoData,
  Validator = new NewOrderValidator(),
}: Props) => {
  const [validator] = React.useState<NewOrderValidator>(Validator!);
  const NewOrderObj = useContext<NewOrderContextType | null>(NewOrderContext);

  const validatecomorbodities = (e: any) => {
    NewOrderObj?.setIsHandleChangeTriggered(true);
    woundInfoData.wndInfoComorbidities.value.map((item: any) => {
      if (item.value === e.target.name) {
        item.selected = e.target.checked;
      }

      if (e.target.name === "Diabetes" && e.target.checked) {
        woundInfoData.wndInfoComorbiditiesDiabetese.required = true;
        woundInfoData.wndInfoComorbiditiesDiabetese.value = "";
        woundInfoData.wndInfoComorbiditiesDiabetese.valid =
          ValidationStatus.UNTOUCHED;
      } else if (e.target.name === "Diabetes" && !e.target.checked) {
        woundInfoData.wndInfoComorbiditiesDiabetese.required = false;
        woundInfoData.wndInfoComorbiditiesDiabetese.value = "";
        woundInfoData.wndInfoComorbiditiesDiabetese.valid =
          ValidationStatus.UNTOUCHED;
      }
    });
    if (e.target.name === "Not Applicable" && e.target.checked) {
      woundInfoData.wndInfoComorbidities.value
        .filter((item: any) => item.value !== "Not Applicable")
        .map((item: any) => {
          item.selected = false;
        });
      woundInfoData.wndInfoComorbiditiesOther.required = false;
      woundInfoData.wndInfoComorbiditiesOther.title = "Other";
      woundInfoData.wndInfoComorbiditiesDiabetese.required = false;
      woundInfoData.wndInfoComorbiditiesDiabetese.value = "";
      woundInfoData.wndInfoComorbiditiesDiabetese.valid =
        ValidationStatus.UNTOUCHED;
      woundInfoData.wndInfoComorbiditiesOther.value = "";
      woundInfoData.wndInfoComorbiditiesOther.valid =
        ValidationStatus.UNTOUCHED;
    } else if (e.target.name !== "Not Applicable") {
      woundInfoData.wndInfoComorbidities.value
        .filter((item: any) => item.value === "Not Applicable")
        .map((item: any) => {
          item.selected = false;
        });
    }
    const isValid = validator.validate(
      woundInfoData.wndInfoComorbidities.value,
      "wndInfocomorbodities"
    );
    if (isValid?.status !== ValidationStatus.UNTOUCHED)
      woundInfoData.wndInfoComorbidities.errorMessage = null;
    woundInfoData.wndInfoComorbidities.valid = isValid!.status!;
    setWoundInfoData(Object.assign({}, woundInfoData));
  };

  const validateAndSetData = (e: any) => {
    NewOrderObj?.setIsHandleChangeTriggered(true);
    let value = e.target.value;
    let isValid = validator.validate(e.target.value, e.target.name);
    let title = isValid?.status === ValidationStatus.INVALID ? "Other" : "";
    setWoundInfoData(
      Object.assign({}, woundInfoData, {
        [e.target.name]: {
          ...Object(woundInfoData)[e.target.name],
          value: value,
          valid: isValid?.status,
          required: true,
          title: title,
          errorMessage: null,
        },
      })
    );
  };

  const validateAndSetDataForRadioSelection = (e: any) => {
    NewOrderObj?.setIsHandleChangeTriggered(true);

    setWoundInfoData({
      ...woundInfoData,
      wndInfoComorbiditiesDiabetese: {
        ...woundInfoData?.wndInfoComorbiditiesDiabetese,
        value: e.target.value,
        valid: ValidationStatus.VALID,
        required: true,
      },
    });
  };

  return (
    <div className="comorbodities-main-container">
      {!isReviewOrder && (
        <div className="comMaincContainer">
          <span className="woundinfocom-title">Comorbidities</span>
          <div className="comorbodities-data">
            <InputWithLabel
              isRequired={true}
              label="Which of the following comorbidities apply?"
              id={woundInfoData.wndInfoComorbidities.componentId!}
              error={
                woundInfoData.wndInfoComorbidities.valid ===
                ValidationStatus.INVALID
              }
              warningMessage={woundInfoData.wndInfoComorbidities.errorMessage}
              isShowWarning={true}
            >
              <div className="comorbodities-data">
                {woundInfoData.wndInfoComorbidities.value.map(
                  (x: any, index: any) => (
                    <>
                      <CustomCheckBox
                        name={x.value}
                        selectClassName="comorboditiesCheckbox"
                        selectpropsClassName="comorboditiesCheckboxRoot"
                        handleChange={validatecomorbodities}
                        labelClassName={
                          x.selected
                            ? "comorboditieschkBoxDescriptionText-active"
                            : "comorboditieschkBoxDescriptionText"
                        }
                        checked={x.selected}
                        value={x.value}
                        key={index}
                        required={
                          woundInfoData.wndInfoComorbiditiesOther.required &&
                          x.value === "Other"
                        }
                        labelText={x.label}
                        testId={x.value}
                        requiredStarClass="comorbodities-chkBoxRequiredStar"
                      />
                      {x.value === "Diabetes" &&
                        woundInfoData.wndInfoComorbiditiesDiabetese
                          .required && (
                          <InputWithLabel
                            label="Is the patient on a Comprehensive Diabetic Management Program?"
                            isRequired={true}
                            error={
                              woundInfoData?.wndInfoComorbiditiesDiabetese
                                .valid === ValidationStatus.INVALID
                            }
                            labelClassName="comorbodities-iscomorboditiesPresent"
                            id={
                              woundInfoData?.wndInfoComorbiditiesDiabetese
                                .componentId!
                            }
                            testId=""
                            warningMessage={
                              woundInfoData.wndInfoComorbiditiesDiabetese
                                .errorMessage
                            }
                            isShowWarning={true}
                            warning_icon_div_custom_class="warning-error-message-div"
                          >
                            <RadioGroup
                              name="isComorbiditiesDiabeticProgram"
                              classes={{ root: "radioRoot" }}
                              onChange={validateAndSetDataForRadioSelection}
                              value={
                                woundInfoData?.wndInfoComorbiditiesDiabetese
                                  .value
                              }
                            >
                              <FormControlLabel
                                classes={{
                                  root:
                                    woundInfoData.wndInfoComorbiditiesDiabetese
                                      .valid === ValidationStatus.INVALID
                                      ? "optionRoot-error"
                                      : woundInfoData
                                          .wndInfoComorbiditiesDiabetese
                                          .value === "1"
                                      ? "optionRoot-active"
                                      : "optionRoot",
                                }}
                                componentsProps={{
                                  typography: {
                                    classes: {
                                      root:
                                        woundInfoData
                                          .wndInfoComorbiditiesDiabetese
                                          .value === "1"
                                          ? "optiontxtSelect"
                                          : "optiontxt",
                                    },
                                  },
                                }}
                                control={
                                  <Radio
                                    icon={<RadioButtonIcon />}
                                    checkedIcon={<SelectedRadioButtonIcon />}
                                  />
                                }
                                data-testid=""
                                label="Yes"
                                value="1"
                              />
                              <FormControlLabel
                                classes={{
                                  root:
                                    woundInfoData.wndInfoComorbiditiesDiabetese
                                      .valid === ValidationStatus.INVALID
                                      ? "optionRoot-error"
                                      : woundInfoData
                                          .wndInfoComorbiditiesDiabetese
                                          .value === "0"
                                      ? "optionRoot-active"
                                      : "optionRoot",
                                }}
                                componentsProps={{
                                  typography: {
                                    classes: {
                                      root:
                                        woundInfoData
                                          .wndInfoComorbiditiesDiabetese
                                          .value === "0"
                                          ? "optiontxtSelect"
                                          : "optiontxt",
                                    },
                                  },
                                }}
                                control={
                                  <Radio
                                    icon={<RadioButtonIcon />}
                                    checkedIcon={<SelectedRadioButtonIcon />}
                                  />
                                }
                                data-testid=""
                                label="No"
                                value="0"
                              />
                              <FormControlLabel
                                classes={{
                                  root:
                                    woundInfoData.wndInfoComorbiditiesDiabetese
                                      .valid === ValidationStatus.INVALID
                                      ? "optionRoot-error"
                                      : woundInfoData
                                          .wndInfoComorbiditiesDiabetese
                                          .value === "2"
                                      ? "optionRoot-active"
                                      : "optionRoot",
                                }}
                                componentsProps={{
                                  typography: {
                                    classes: {
                                      root:
                                        woundInfoData
                                          .wndInfoComorbiditiesDiabetese
                                          .value === "2"
                                          ? "optiontxtSelect"
                                          : "optiontxt",
                                    },
                                  },
                                }}
                                control={
                                  <Radio
                                    icon={<RadioButtonIcon />}
                                    checkedIcon={<SelectedRadioButtonIcon />}
                                  />
                                }
                                data-testid=""
                                label="N/A"
                                value="2"
                              />
                            </RadioGroup>
                          </InputWithLabel>
                        )}
                    </>
                  )
                )}
              </div>
            </InputWithLabel>
          </div>
        </div>
      )}
      {isReviewOrder && (
        <ComorboditiesReviewOrder
          editButtonClicked={editButtonClicked}
          isOrderSummary={isOrderSummary}
          woundInfoData={woundInfoData}
        />
      )}
    </div>
  );
};

export default Comorbodities;
