import { ValidationStatus } from "../../core/interfaces/input.interface";
import { IAcuteOrderPickUpRequest } from "./acuteOrderPickUpRequest.interface";
import { defaultPickUpInformation } from "./components/enterPickupInformation/enterPickUpInformation.model";

export let defaultAcutePickUpRequestData: IAcuteOrderPickUpRequest = {
  enterPickUpInformation: {
    hasChildsToValidate: true,
    required: true,
    valid: ValidationStatus.UNTOUCHED,
    value: defaultPickUpInformation,
  },
  isCallOnEstimatedArrivalTime: {
    componentId:
      "create-service-request-special-is-call-on-estimated-arrival-time",
    id: "create-service-request-special-is-call-on-estimated-arrival-time-input",
    required: true,
    order: 5,
    title: "Would you like a call with an estimated time of arrival?",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  firstName: {
    componentId: "create-service-request-first-name",
    id: "create-service-request-first-name-input",
    order: 6,
    required: false,
    title: "Contact First Name",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  lastName: {
    componentId: "create-service-request-last-name",
    id: "create-service-request-last-name-input",
    order: 7,
    required: false,
    title: "Contact Last Name",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  department: {
    componentId: "create-service-request-department",
    id: "create-service-request-department-input",
    order: 8,
    required: false,
    title: "Department",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  phoneNumber: {
    componentId: "create-service-request-phone-number",
    id: "create-service-request-phone-number-input",
    order: 9,
    required: false,
    title: "Phone number",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    isPhoneNumber: true,
  },
  injuryCauseBy3MDevice: {
    componentId: "create-service-request-is-cause-for-injury",
    id: "create-service-request-is-cause-for-injury-input",
    order: 10,
    required: true,
    title: "Did the 3M device potentially cause or contribute to an injury?",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  problemWith3MDevice: {
    componentId: "create-service-request-is-problem-with-device",
    id: "create-service-request-is-problem-with-device-input",
    order: 11,
    required: true,
    title: "Is there a problem with the functionality of the 3M device?",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  describeTheProblem: {
    componentId: "create-service-request-describe-injury-or-problem",
    id: "create-service-request-describe-injury-or-problem-input",
    order: 12,
    required: false,
    title: "Description",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
};
