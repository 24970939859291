import { Grid } from "@mui/material";
import {
  NewOrderContext,
  NewOrderContextType,
} from "../../../context/NewOrderContext";
import { INewOrder } from "../newOrder.interface";
import {
  Question,
  WoundQuestionaries,
} from "../clinicalInformation/clinicalInfo.interface";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { ValidationStatus } from "../../../core/interfaces/input.interface";
import { DeliveryContact } from "../deliveryContact/deliveryContact.component";
import { PrintCommonDocs } from "../printCommonDocs/printCommonDocs.component";
import { VerifyRequesterInfo } from "../verifyRequesterInfo/verifyRequesterInfo";
import { HomeCareProvider } from "../homeCareProvider/homeCareProvider.component";
import { DressingSupplies } from "../dressingSupplies/dressingSupplies.component";
import { IVacTherapyInformation } from "../woundBed/vacTherapyInformation.interface";
import { ContributingCause } from "../contributingCause/contributingCause.component";
import { IPrintableDocumentsPdf } from "../printCommonDocs/printCommonDocs.interface";
import OrderUploadDocument from "../orderUploadDocument/orderUploadDocument.component";
import { InsuranceMainComponent } from "../insuranceInformation/insuranceMainComponent";
import { PatientInformation } from "../patientInformation/patientInformation.component";
import { ProductInformation } from "../productInformation/productInformation.component";
import { SubmitPrescription } from "../submitPrescription/submitPrescription.component";
import { TherapyInformation } from "../therapyInformation/therapyInformation.component";
import { getAdditionalWoundQuestionaries } from "../../../util/primaryWoundTypeService";
import { InpatientTransition } from "../inpatientTransition/inpatientTransition.component";
import { DeliveryInformation } from "../deliveryInformation/deliveryInformation.component";
import { EmergencyContactInfo } from "../emergencyContactInfo/emergencyContactInfo.component";
import { PatientCurrentAddress } from "../patientCurrentAddress/patientCurrentAddress.component";
import { PrescriberInformation } from "../prescriberInformation/prescriberInformation.component";
import { defaultSecondaryInsuranceData } from "../insuranceInformation/insuranceInformation/insuranceInformation.model";
import { RequestingFacility } from "../requestingFacility/requestingFacility.component";
import {
  InternalSignOnContextType,
  InternalSignOnContext,
} from "../../../context/InternalSignOnContext";
import { VACTherapyLocation } from "../VACTherapyLocation/VACTherapyLocation.component";
import {
  PRODUCT_TYPE_ACCESSORIES,
  PRODUCT_TYPE_CANISTER,
  PRODUCT_TYPE_DRESSING,
  DEFAULT_DRESSING_KIT_KEY,
} from "../../../util/staticText";
import { getVacDressingKitProducts } from "../../../util/3meService";
import { VacDressingKitModel } from "../dressingSupplies/vacDressingKit/vacDressingKit.interface";
import { getStaticContent } from "../../../util/staticContentService";
import { IDeviceTroubleshootingSupport } from "../../needHelp/needHelp.interface";

interface Props {
  orderId: string;
  newOrderData: any;
  setNewOrderData: any;
  nextOrderOpen: boolean;
  vacTherapyInformationData: IVacTherapyInformation;
  states: any;
  statesText: any;
  insuranceTypes: any;
  insuranceTypesText: any;
  sharedOrderText: any;
  requesterInfo: any;
  setRequesterInfo: any;
  productInfo: any;
  setProductInfo: any;
  dressingKit: any;
  setDressingKit: any;
  canister: any;
  setCanister: any;
  accessory: any;
  setAccessory: any;
  deliveryInformation: any;
  setDeliveryInformation: any;
  updatedDeliveryInformation: any;
  setUpdatedDeliveryInformation: any;
  isEditingDeliveryInformation: any;
  setIsEditingDeliveryInformation: any;
  isNextButtonClicked: any;
  documentTypeText: any;
  documentTypeCodeList: any;
  isSharedOrder?: boolean;
  isSharedOrderNeedToBeDisable?: boolean;
  isVacOrderFromSSO?: boolean;
  isCreateTransition?: boolean;
}

const PatientInfoStepper = ({
  orderId,
  newOrderData,
  setNewOrderData,
  nextOrderOpen,
  vacTherapyInformationData,
  states,
  statesText,
  insuranceTypes,
  insuranceTypesText,
  sharedOrderText,
  requesterInfo,
  setRequesterInfo,
  productInfo,
  setProductInfo,
  dressingKit,
  setDressingKit,
  canister,
  setCanister,
  accessory,
  setAccessory,
  deliveryInformation,
  setDeliveryInformation,
  updatedDeliveryInformation,
  setUpdatedDeliveryInformation,
  isEditingDeliveryInformation,
  setIsEditingDeliveryInformation,
  isNextButtonClicked,
  documentTypeText,
  documentTypeCodeList,
  isSharedOrder,
  isSharedOrderNeedToBeDisable,
  isVacOrderFromSSO,
  isCreateTransition,
}: Props) => {
  const newOrderObj = useContext<NewOrderContextType | null>(NewOrderContext);
  const authObj = useContext(AuthContext);
  const internalObj = useContext<InternalSignOnContextType | null>(
    InternalSignOnContext
  );
  const [printableDocumentsObj, setPrintableDocumentsObj] = useState<
    IPrintableDocumentsPdf | undefined
  >();

  // Dressing Supplies input params
  const [vacProducts, setVacProducts] = useState([]);
  const [defaultVacdressingKit, setDefaultVacdressingKit] = useState("");
  const [accessories, setAccessories] = useState([]);
  const [vacAllProducts, setAllVacProducts] =
    useState<VacDressingKitModel | null>(null);
  const [cannisterProducts, setCannisterProducts] = useState([]);

  const checkForAcuteValue = () => {
    let acuteFlag = true;
    if (
      (authObj &&
        authObj.registeredFaciltyAddress &&
        authObj?.registeredFaciltyAddress?.isPostAcute) ||
      newOrderObj?.newOrderData.inpatientFacility?.isPostAcute
    ) {
      acuteFlag = false;
    }
    return acuteFlag;
  };

  const updateWasNPWTForAcute = () => {
    if (!newOrderObj?.isComingFromPrev && checkForAcuteValue()) {
      setNewOrderData((prev: INewOrder) => ({
        ...prev,
        wasNPWTInitiated: {
          ...prev.wasNPWTInitiated,
          valid: ValidationStatus.VALID,
          value: "yes",
          required: true,
        },
        dateInitiated: {
          ...prev.dateInitiated,
          value: null,
          valid: ValidationStatus.UNTOUCHED,
          required: true,
        },
        reasonForTransition: {
          ...prev.reasonForTransition,
          required: true,
          value: "1",
        },
        wasNPWTUsedin60Days: {
          ...prev.wasNPWTUsedin60Days,
          value: "no",
          required: true,
        },
      }));
    } else {
      setNewOrderData((prev: INewOrder) => ({
        ...prev,
        wasNPWTInitiated: {
          ...prev.wasNPWTInitiated,
          value: "no",
          required: true,
        },
        wasNPWTUsedin60Days: {
          ...prev.wasNPWTUsedin60Days,
          value: "no",
          required: true,
        },
      }));
    }
  };

  const showSecondaryInsuranceInformation = () => {
    newOrderObj?.setIsSecondaryOpen(!newOrderObj?.isSecondaryOpen);
    if (!newOrderObj?.isComingFromPrev) {
      setDefaultSecondaryInsuranceInformation();
    }
  };

  const setDefaultSecondaryInsuranceInformation = () => {
    setNewOrderData((dt: any) => ({
      ...dt,
      secondaryInsurance: defaultSecondaryInsuranceData,
      isSecondaryInsurancePresent: {
        ...dt.isSecondaryInsurancePresent,
        value: newOrderObj?.isSecondaryOpen,
      },
    }));
    newOrderObj?.setShowAdditionalObject((dt: any) => ({
      ...dt,
      typeSecondary: {
        medicare: false,
        medicareAdvantage: false,
        managedMedicaid: false,
        commercialInsurance: false,
        medicaid: false,
        charityCare: false,
        privatePay: false,
        otherAdditionalDetails: false,
      },
    }));
  };

  const setQuestionnaireContent = async () => {
    const questionsData = await getAdditionalWoundQuestionaries();
    const mapData = new Map<string, WoundQuestionaries>();
    questionsData.forEach((val: any) => {
      if (!mapData.has(val.woundType)) {
        mapData.set(val.woundType, {
          additionalQuestion: val.additionalQuestion.map((quest: Question) => ({
            ...quest,
            value: "",
            valid: ValidationStatus.UNTOUCHED,
          })),
          category: val.category,
          woundType: val.woundType,
        });
      }
    });
    newOrderObj?.setWoundQuestionnaries(mapData);
  };

  const fetchPrintableDocumentsLinks = async () => {
    if (vacTherapyInformationData) {
      const printableDocresponseObj: IPrintableDocumentsPdf = {
        VACTherapyOrderPad: vacTherapyInformationData.orderPad?.FileLink!,
        VACTherapyInsuranceAuthorizationForm:
          vacTherapyInformationData.authorizationForm?.FileLink!,
      };
      setPrintableDocumentsObj(printableDocresponseObj);
    }
  };

  const fetchVacDressingProducts = async () => {
    try {
      const response = await getVacDressingKitProducts();
      if (response.items.length > 0) {
        const vacProduct = response.items
          .filter(
            (product: any) => product.productType === PRODUCT_TYPE_DRESSING
          )
          .map(
            (x: {
              productID: any;
              productName: any;
              productCode: any;
              sku: any;
            }) => ({
              id: x.productID,
              code: x.sku,
              text: x.productName,
            })
          );
        const accessories = response.items
          .filter(
            (product: any) => product.productType === PRODUCT_TYPE_ACCESSORIES
          )
          .map(
            (x: {
              productCode: string;
              productName: string;
              productSequence: number;
              productID: number;
              sku: any;
            }) => ({
              code: x.sku,
              order: x.productSequence,
              text: x.productName,
              id: x.productID,
            })
          );
        const cannisterProduct = response.items.filter(
          (product: any) => product.productType === PRODUCT_TYPE_CANISTER
        );
        setVacProducts(vacProduct);
        setAccessories(accessories);
        setCannisterProducts(cannisterProduct);
        setAllVacProducts(response);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const fetchDefaultVacDressingKit = async () => {
    const data = await getStaticContent(DEFAULT_DRESSING_KIT_KEY);
    if (data && data.succeeded) {
      setDefaultVacdressingKit(data.items[0]?.value);
    } else {
      setDefaultVacdressingKit("");
    }
  };
  useEffect(() => {
    fetchDefaultVacDressingKit();
    setQuestionnaireContent();
    updateWasNPWTForAcute();
    fetchVacDressingProducts();
  }, []);

  useEffect(() => {
    updateWasNPWTForAcute();
  }, [authObj?.registeredFaciltyAddress?.siteUseCode]);

  useEffect(() => {
    if (newOrderObj?.isComingFromPrev === true) {
      newOrderObj?.setisComingFromPrev(false);
    } else {
      setDefaultSecondaryInsuranceInformation();
    }
  }, [newOrderObj?.isSecondaryOpen]);

  useEffect(() => {
    fetchPrintableDocumentsLinks();
  }, [vacTherapyInformationData]);

  useEffect(() => {
    setTimeout(() => {
      if (newOrderObj && newOrderObj.scrollableComponentClassName) {
        let scrollableComponent = document.getElementsByClassName(
          newOrderObj.scrollableComponentClassName
        )[0];
        if (scrollableComponent) {
          scrollableComponent.scrollIntoView({
            behavior: "smooth",
          });
        }
        newOrderObj.setScrollableComponentClassName(undefined);
      }
    }, 300);
  }, [newOrderObj?.scrollableComponentClassName]);

  useEffect(() => {
    if (internalObj?.isVACOrderSSO) {
      setNewOrderData({
        ...newOrderData,
        requestingFacility: {
          ...newOrderData.requestingFacility,
          errorMessage: null,
          valid: ValidationStatus.UNTOUCHED,
          isOptional: false,
          required: true,
        },
      });
    }
  }, []);

  return (
    <Grid
      className={
        isVacOrderFromSSO
          ? "newOrder-container newOrder-LandingPageContainer"
          : "newOrder-container"
      }
    >
      <div
        className={
          isVacOrderFromSSO
            ? "patientForm patientForm-LandingPage"
            : "patientForm"
        }
      >
        {isSharedOrder && !isSharedOrderNeedToBeDisable && (
          <div className="sharedOrderalert"> {sharedOrderText} </div>
        )}
        <div className="short-form">
          <PatientInformation
            data={newOrderData!}
            setData={setNewOrderData!}
            states={states}
            statesText={statesText}
            vacTherapyInformationData={vacTherapyInformationData}
          />
          <PatientCurrentAddress
            data={newOrderData!}
            setData={setNewOrderData!}
            states={states}
            statesText={statesText}
          />
          <EmergencyContactInfo
            data={newOrderData!}
            setData={setNewOrderData!}
          />
          <InsuranceMainComponent
            data={newOrderData}
            setData={setNewOrderData}
            hideAddButton={newOrderData.isSecondaryInsurancePresent.value}
            dropDownDataArray={insuranceTypes}
            dropDownTextArray={insuranceTypesText}
            addOrRemoveButtonAction={showSecondaryInsuranceInformation}
            vacTherapyInformationData={vacTherapyInformationData}
            showAdditionalFields={newOrderObj?.showAddtionalObject!}
            isNextButtonClicked={isNextButtonClicked}
            primaryDressingName={dressingKit.productName}
            vacAllProducts={vacAllProducts}
          />
          <ContributingCause data={newOrderData!} setData={setNewOrderData!} />
          <TherapyInformation data={newOrderData!} setData={setNewOrderData!} />
          <PrescriberInformation states={states} statesText={statesText} />
          <SubmitPrescription
            data={newOrderData!}
            setData={setNewOrderData!}
            printableDocumentsLink={printableDocumentsObj}
          />
          {internalObj?.isVACOrderSSO && (
            <RequestingFacility
              data={newOrderData!}
              setData={setNewOrderData!}
            />
          )}
          <ProductInformation
            productInfo={productInfo!}
            setProductInfo={setProductInfo!}
            data={newOrderData!}
            setData={setNewOrderData!}
            vacAllProducts={vacAllProducts}
          />
          <DressingSupplies
            dressingKit={dressingKit!}
            canister={canister!}
            accessory={accessory!}
            productInfo={productInfo!}
            orderId={orderId}
            primaryInsurance={newOrderData.primaryInsurance}
            setDressingKit={setDressingKit!}
            setCanister={setCanister!}
            setAccessory={setAccessory!}
            vacTherapyInformationData={vacTherapyInformationData}
            vacAllProducts={vacAllProducts}
            vacProducts={vacProducts}
            cannisterProducts={cannisterProducts}
            accessories={accessories}
            defaultVacdressingKit={defaultVacdressingKit}
          />
          <InpatientTransition
            data={newOrderData!}
            setData={setNewOrderData!}
            isTransitionOrder={isCreateTransition!}
          />
          <VerifyRequesterInfo
            requesterInfo={requesterInfo!}
            setRequesterInfo={setRequesterInfo!}
            isVacOrderFromSSO={isVacOrderFromSSO}
            data={newOrderData!}
            setData={setNewOrderData!}
          />
          <HomeCareProvider
            data={newOrderData!}
            setData={setNewOrderData!}
            states={states}
            statesText={statesText}
          />
          <VACTherapyLocation data={newOrderData!} setData={setNewOrderData!} />
          <DeliveryInformation
            data={newOrderData!}
            setData={setNewOrderData!}
            states={states}
            statesText={statesText}
            nextOrderOpen={nextOrderOpen}
            deliveryInformation={deliveryInformation}
            setDeliveryInformation={setDeliveryInformation}
            updatedDeliveryInformation={updatedDeliveryInformation}
            setUpdatedDeliveryInformation={setUpdatedDeliveryInformation}
            isEditingDeliveryInformation={isEditingDeliveryInformation}
            setIsEditingDeliveryInformation={setIsEditingDeliveryInformation}
            isNextButtonClicked={isNextButtonClicked}
            productInfo={productInfo}
            newOrderObj={newOrderObj}
          />
          <DeliveryContact
            isNextButtonClicked={isNextButtonClicked}
            data={newOrderData!}
            setData={setNewOrderData!}
            tempData={newOrderObj!.previousSavedDeliveryContactData}
            setTempData={newOrderObj!.setPreviousSavedDeliveryContactData}
          />
          <PrintCommonDocs
            data={newOrderData!}
            setData={setNewOrderData!}
            printableDocumentsLink={printableDocumentsObj}
          />
          <OrderUploadDocument
            documentTypeText={documentTypeText}
            documentTypeCodeList={documentTypeCodeList}
            dropdownListingType="vacOrderDocuments"
            vacTherapyInformationData={vacTherapyInformationData}
          />
        </div>
      </div>
    </Grid>
  );
};

export default PatientInfoStepper;
