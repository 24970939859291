import {
  IInputField,
  Validation,
  ValidationStatus,
} from "../../../core/interfaces/input.interface";
import { getDeepClone } from "../../../util/ObjectFunctions";
import { Validator } from "../../../util/order.validations";
import {
  getValidObj,
  updateErrorMessage,
} from "../../../util/utilityFunctions";
import { IInventoryRequest } from "./inventoryRequest.interface";

export class InventoryRequestValidator {
  private _validator;
  constructor(defValidator = new Validator()) {
    this._validator = defValidator;
  }

  private noValidation(txt: string): Validation {
    return getValidObj();
  }

  private fieldToMethodMapping(
    field: string
  ): ((txt: string) => Validation) | undefined {
    const mapping = new Map<string, (txt: string) => Validation>([
      ["requestType", this._validator.emptyCheck],
      [
        "deliveryInstructions",
        this._validator.optionalFieldValidSpecialCharacters,
      ],
      ["otherReasonForRemoval", this._validator.otherReasonForRemovalInventory],
      ["contactFirstName", this._validator.requesterNameValidation],
      ["contactLastName", this._validator.requesterNameValidation],
      ["phone", this._validator.phoneValidation],
      ["email", this._validator.mandatoryEmailValidation],
      ["department", this._validator.departmentValidation],
    ]);
    const validator = mapping.get(field);
    return validator ? validator : this.noValidation;
  }

  public validate(input: string, field: string) {
    try {
      const validator = this.fieldToMethodMapping(field)!;
      return validator(input);
    } catch (error) {
      console.log(`validator method for field ${field} is not configured`);
    }
  }

  public validateAll(
    data: IInventoryRequest,
    updateDataIfUntouchedAndValidated: Function
  ): [ValidationStatus, IInputField | null] {
    let temp = getDeepClone(data);
    let smallestOrderWithInvalidStatus: IInputField | null = null;
    Object.keys(temp).forEach((x: string) => {
      if (
        temp[x].isOptional === true &&
        temp[x].valid !== ValidationStatus.VALID
      ) {
        temp[x].valid = ValidationStatus.VALID;
      } else if (
        (temp[x].required && temp[x].valid) === ValidationStatus.UNTOUCHED
      ) {
        temp[x].valid = ValidationStatus.INVALID;
      }
      // Find smallest order number which has invalid status
      if (temp[x].valid === ValidationStatus.INVALID && temp[x].order) {
        if (
          !smallestOrderWithInvalidStatus ||
          (smallestOrderWithInvalidStatus.order &&
            temp[x].order &&
            smallestOrderWithInvalidStatus.order > temp[x].order)
        ) {
          smallestOrderWithInvalidStatus = temp[x];
        }
      }
      updateErrorMessage(temp[x]);
    });
    updateDataIfUntouchedAndValidated(temp);
    const ifAllValid = Object.keys(temp)
      .filter((x) => temp[x].required || temp[x].isDefaultValid)
      .every((x: string) => temp[x].valid === ValidationStatus.VALID);
    return [
      ifAllValid ? ValidationStatus.VALID : ValidationStatus.INVALID,
      smallestOrderWithInvalidStatus,
    ];
  }
}
