import {
  IInpatientOrder,
  IInpatientOrderSecondPage,
} from "../inpatientOrder.interface";
import "./inpatientOrderReviewPage.css";
import { IInventoryProductDetails } from "../../inventory/inventoryTabContainer.enum";
import { BillingAddress } from "../components/billingAddress/billingAddress.component";
import { IBillingAddress } from "../components/billingAddress/billingAddress.interface";
import { DevicePlacement } from "../components/devicePlacement/devicePlacement.component";
import { TherapyStartDate } from "../components/therapyStartDate/therapyStartDate.component";
import { PatientInformationReview } from "../components/patientInformation/reviewPatientInformation/patientInformationReview.component";
import { PrescriberInformationReview } from "../components/prescriberInformation/reviewprescriberInformation/prescriberInformationReview.component";
import WoundInformationSectionReview from "../components/woundInformation/reviewWoundInformation/woundInformatoinReview.component";
import { BillingInformationReview } from "../components/billingInformation/reviewBillingInformation/billingInformationReview.component";
import { ICustomerPlusListingDetails } from "../../inventory/customerPlusInventory/customerPlusInventory.interface";
import { PlacementDateReview } from "../components/placementDate/reviewPlacementDate/placementDateReview.component";
import { ReplacementInformationReview } from "../components/replacementInformation/reviewReplacementInformation/replacementInformationReview.component";
import { defaultDevicePlacementTableData } from "../container/inpatientOrderContainer.component";
import { DeliveryPreferenceReview } from "../components/deliveryPreference/reviewDeliveryPreference/deliveryPreferenceReview.component";
import { CartState } from "../../inpatientSupplyOrder/component/cart/cart.reducer";
import { SelectedSuppliesReview } from "../../inpatientSupplyOrder/component/selectedSupplies/reviewSelectedSupplies/selectedSuppliesReview.component";

interface IInPatientOrderReviewPage {
  addresses: IBillingAddress[] | null;
  allProducts: ICustomerPlusListingDetails[] | IInventoryProductDetails[];
  data: IInpatientOrder;
  isReviewOrder?: boolean;
  editButtonAction: Function;
  isCustomerPlusProduct: boolean | null;
  isSummary?: boolean;
  setData: Function;
  secondPageData: IInpatientOrderSecondPage;
  isFromSolventum?: boolean;
  isSelectionSuppliesFlow: boolean;
  cartData: CartState;
}

export const InpatientOrderReviewPage = ({
  addresses,
  allProducts,
  data,
  editButtonAction,
  isCustomerPlusProduct,
  isReviewOrder = true,
  isSummary = false,
  setData,
  secondPageData,
  isFromSolventum = false,
  isSelectionSuppliesFlow = false,
  cartData,
}: IInPatientOrderReviewPage) => {
  return (
    <div className="in-patient-order-review-page-component">
      <div className="short-form">
        <p
          className="in-patient-order-review-page-title"
          data-testid="in-patient-order-review-page-title"
        >
          Review Order
        </p>
        <p
          className="in-patient-order-review-page-description"
          data-testid="in-patient-order-review-page-description"
        >
          Please verify the data provided below is accurate before placing your
          order.
        </p>
        <PatientInformationReview
          data={data.patientInformation.value}
          isSummary={isSummary}
          editButtonAction={editButtonAction}
        />
        <PrescriberInformationReview
          data={data.prescriberInformation.value}
          isSummary={isSummary}
          editButtonAction={editButtonAction}
        />
        <DevicePlacement
          addresses={addresses ?? []}
          allProducts={allProducts}
          data={data}
          isReviewOrder={isReviewOrder}
          isCustomerPlusProduct={isCustomerPlusProduct}
          setIsCustomerPlusProduct={() => {}}
          isSummary={isSummary}
          editButtonAction={editButtonAction}
          setData={setData}
          devicePlacementTableData={defaultDevicePlacementTableData}
          updateSolventumProduct={() => {}}
        />
        {isCustomerPlusProduct ? (
          <TherapyStartDate
            data={data}
            editButtonAction={editButtonAction}
            isReviewOrder={true}
            isSummary={false}
            setData={setData}
          />
        ) : (
          <>
            {addresses && addresses.length > 1 && (
              <BillingAddress
                addresses={addresses}
                data={data}
                editButtonAction={editButtonAction}
                isReviewOrder={true}
                setData={setData}
                updateSolventumProduct={() => {}}
              />
            )}
          </>
        )}
        <WoundInformationSectionReview
          data={data.patientWoundInformation.value}
          editButtonAction={editButtonAction}
          isSummary={isSummary}
        />
        {!isCustomerPlusProduct && (
          <div>
            {isFromSolventum ? (
              <DeliveryPreferenceReview
                data={secondPageData.deliveryPreference.value}
                editButtonAction={editButtonAction}
                isSummary={false}
              />
            ) : (
              <>
                <PlacementDateReview
                  data={secondPageData.placementDate}
                  editButtonAction={editButtonAction}
                  isSummary={false}
                />
                <ReplacementInformationReview
                  data={secondPageData.replacementInformation.value}
                  editButtonAction={editButtonAction}
                  isSummary={false}
                />
              </>
            )}
            <BillingInformationReview
              data={secondPageData.billingInformation.value}
              editButtonAction={editButtonAction}
              isSummary={false}
              isSelectionSuppliesFlow={isSelectionSuppliesFlow}
            />
            {isSelectionSuppliesFlow && cartData.cartItems.length > 0 && (
              <SelectedSuppliesReview
                editButtonAction={editButtonAction}
                cartItemsData={cartData}
                isSummaryOrDetail={false}
                isFromInpatientOrderFlow={isSelectionSuppliesFlow}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};
