import { IFacilitySearchRequest } from "./searchFacility.interface";
import { allFacilitySearch } from "../../../../util/3meService";
import { IFacility } from "../facility.interface";

export const searchFacility = (request: IFacilitySearchRequest) => {
  return new Promise<IFacility[]>(async (resolve, reject) => {
    try {
      const serverResponse: any = await allFacilitySearch(request);
      if (serverResponse && serverResponse.items) {
        const facilities = serverResponse.items.sort(
          (a: { accountName: string }, b: { accountName: string }) =>
            a.accountName > b.accountName ? 1 : -1
        );
        resolve(facilities);
      } else if (serverResponse && !serverResponse.succeeded) {
        reject(serverResponse?.error);
      } else {
        const facilities: IFacility[] | PromiseLike<IFacility[]> = [];
        resolve(facilities);
      }
    } catch (error) {
      console.log("error", error);
    }
    reject("not found");
  });
};
