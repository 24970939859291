import { useContext, useEffect, useState } from "react";
import { getDeepClone } from "../../../../util/ObjectFunctions";
import { NewOrderValidator } from "../../newOrder.validator";
import {
  defaultHomeCareProviderSearchBox,
  IHomeCareProviderSearch,
} from "./homeCareProviderSearch.model";
import {
  NewOrderContext,
  NewOrderContextType,
} from "../../../../context/NewOrderContext";
import "./homeCareSearch.css";
import { SearchHomeCareProviderModal } from "./searchHomeCare.enum";
import { IHomeCareProviderDetail } from "../homeCareProvider.interface";
import { AuthContext, AuthContextType } from "../../../../context/AuthContext";
import {
  caregiverFacilitySearch,
  homeCareProviderSearch,
} from "../../../../util/3meService";
import { LoadingSpinner } from "../../../../core/loader/LoadingSpinner";
import { ICaregiverFacility } from "../../../../core/caregiverSearchAndAdd/caregiverFacilitySearchAndAdd.model";
import SearchHomeCareProviderChild from "./homeCareSearchChild.component";
import NewOrderErrorPopupMessage from "../../newOrderFooterGroup/newOrderErrorPopupMessage.component";
import { HOME_CARE_PROVIDER_SEARCH_DB_SERVICE_ERROR } from "../../../../util/errorMsg";
import {
  InternalSignOnContext,
  InternalSignOnContextType,
} from "../../../../context/InternalSignOnContext";

interface Props {
  handleHomeCareProviderSearchType: any;
  setSelectedHomeCareProvider: any;
  enabledHCPFromMyList?: any;
  handleSelectedHomeCareProvider?: any;
}

const SearchHomeCareProvider = ({
  handleHomeCareProviderSearchType,
  setSelectedHomeCareProvider,
  enabledHCPFromMyList,
  handleSelectedHomeCareProvider,
}: Props) => {
  const validator = new NewOrderValidator();
  const NewOrderObj = useContext<NewOrderContextType | null>(NewOrderContext);

  const [showHomeCareProviderOption, setShowHomeCareProviderOption] =
    useState(false);
  const [showNoResults, setShowNoResults] = useState(false);
  const [homeCareProviderList, setHomeCareProviderList] = useState<any>([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [serverHCPList, setServerHCPList] = useState<IHomeCareProviderDetail[]>(
    []
  );
  const [showerrorpopup, setShowerrorpopup] = useState(false);
  const [errorCode, setErrorCode] = useState("");

  const spinner = () => {
    return (
      <div className="nr-spinner">
        <LoadingSpinner />
      </div>
    );
  };
  const [homeCareProviderTextBox, setHomeCareProviderTextBox] =
    useState<IHomeCareProviderSearch>(
      getDeepClone(defaultHomeCareProviderSearchBox)
    );

  const [showHomeCareProviderCrossIcon, setShowHomeCareProviderCrossIcon] =
    useState(false);

  const validateAndSetData = (e: any) => {
    const { value, name } = e.target;
    const isvalid = validator.validate(value, name);
    setHomeCareProviderTextBox((homeCareProviderTextBox) => ({
      ...homeCareProviderTextBox,
      [name]: { value: value, valid: isvalid?.status },
    }));
    if (value.length > 2) {
      const filteredList = serverHCPList.filter(
        (x: IHomeCareProviderDetail) => {
          return x.name.toLowerCase().includes(value.toLowerCase());
        }
      );
      setShowHomeCareProviderCrossIcon(true);
      setShowHomeCareProviderOption(true);
      if (filteredList.length === 0) {
        setShowNoResults(true);
      } else {
        setShowNoResults(false);
        setHomeCareProviderList(filteredList);
      }
    } else {
      setShowHomeCareProviderCrossIcon(false);
      setShowHomeCareProviderOption(false);
    }
  };

  const clearSearchData = (e: any) => {
    setHomeCareProviderTextBox(() => defaultHomeCareProviderSearchBox);
  };

  const displayAddManualHomeCare = () => {
    NewOrderObj?.setManualHomeCareProviderFrom(
      SearchHomeCareProviderModal.SEARCH_HOMECAREPROVIDER
    );
    NewOrderObj?.setHomecareproviderSearchAddPopUpSection(
      SearchHomeCareProviderModal.ADD_MANUAL_HOMECAREPROVIDER
    );
  };
  const closeErrorPopup = () => {
    setShowerrorpopup(false);
  };

  return (
    <>
      {showerrorpopup && (
        <NewOrderErrorPopupMessage
          popUpStyles="homecareErrorPopup"
          handleBackButton={closeErrorPopup}
          errorPopupFlag={true}
          errorMessage={HOME_CARE_PROVIDER_SEARCH_DB_SERVICE_ERROR}
          handleExitButton={closeErrorPopup}
          isSupportPhoneShow={true}
          errorCode={errorCode}
        />
      )}
      <div className="homecareprovider-search">
        <div
          className="homeCareProviderHeader"
          data-testid="homecareproviderSearchTitle"
        >
          Home Care Provider Search
        </div>
        {showSpinner ? (
          spinner()
        ) : (
          <>
            <SearchHomeCareProviderChild
              handleHomeCareProviderSearchType={
                handleHomeCareProviderSearchType
              }
              clearSearchData={clearSearchData}
              displayAddManualHomeCare={displayAddManualHomeCare}
              homeCareProviderTextBox={homeCareProviderTextBox}
              showHomeCareProviderCrossIcon={showHomeCareProviderCrossIcon}
              setShowHomeCareProviderCrossIcon={
                setShowHomeCareProviderCrossIcon
              }
              showNoResults={showNoResults}
              showHomeCareProviderOption={showHomeCareProviderOption}
              setShowHomeCareProviderOption={setShowHomeCareProviderOption}
              homeCareProviderList={homeCareProviderList}
              validateAndSetData={validateAndSetData}
              handleSelectOption={handleSelectedHomeCareProvider}
              enabledHCPFromMyList={enabledHCPFromMyList}
            />
          </>
        )}
      </div>
    </>
  );
};

export default SearchHomeCareProvider;
