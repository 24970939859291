import {
  IInputField,
  ValidationStatus,
} from "../../../core/interfaces/input.interface";

export interface IHomeCareProviderData {
  homeCareProvider?: IInputField;
  addedCaregiverName: IInputField;
  addedCaregiverAddress1: IInputField;
  addedCaregiverAddress2: IInputField;
  addedCaregiverCity: IInputField;
  addedCaregiverState: IInputField;
  addedCaregiverZip: IInputField;
  addedCaregiverPhone: IInputField;
  addedCaregiverFacilityType: IInputField;
  addedCaregiverFacilityTypeCode: IInputField;
  addedCaregiverPhoneExtension?: IInputField;
  addedCaregiverSiteUseID?: IInputField;
  addedCaregiverID?: IInputField;
  addedCaregiverAccountNumber?: IInputField;
}
export interface ISelectedHomeCareProvider {
  data: IHomeCareProviderData;
  isNewHcpSelected: boolean;
}
export let defaultHcpData: IHomeCareProviderData = {
  homeCareProvider: {
    order: 54,
    required: true,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  addedCaregiverName: {
    required: false,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  addedCaregiverAddress1: {
    required: false,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  addedCaregiverAddress2: {
    required: false,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  addedCaregiverCity: {
    required: false,
    title: "",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  addedCaregiverState: {
    required: false,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  addedCaregiverPhone: {
    required: false,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  addedCaregiverZip: {
    required: false,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  addedCaregiverFacilityType: {
    required: false,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  addedCaregiverPhoneExtension: {
    isDefaultValid: true,
    required: false,
    valid: ValidationStatus.VALID,
    value: "",
  },
  addedCaregiverFacilityTypeCode: {
    isDefaultValid: true,
    required: false,
    valid: ValidationStatus.VALID,
    value: "",
  },
  addedCaregiverSiteUseID: {
    isDefaultValid: true,
    required: false,
    valid: ValidationStatus.VALID,
    value: "",
  },
  addedCaregiverID: {
    isDefaultValid: true,
    required: false,
    valid: ValidationStatus.VALID,
    value: "",
  },
  addedCaregiverAccountNumber: {
    isDefaultValid: true,
    required: false,
    valid: ValidationStatus.VALID,
    value: "",
  },
};
