import Moment from "moment";
import { useContext } from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { MobileDisplayContext } from "../../../../context/MobileDisplayContext";
import { IWoundAssesmentsMap } from "../orderOverview/orderOverview.interface";
import "./lineCharts.css";

type Props = {
  assesmentList: IWoundAssesmentsMap[];
  interval: number;
};

const LineCharts = ({ assesmentList, interval }: Props) => {
  const { isMobileScreen } = useContext(MobileDisplayContext);

  function formatXAxis(tickItem: any) {
    return Moment.utc(tickItem).format("MM/DD/YYYY").valueOf();
  }

  return (
    <>
      {assesmentList && (
        <ResponsiveContainer
          width={isMobileScreen ? "90%" : "70%"}
          aspect={isMobileScreen ? 1 : 3}
        >
          <LineChart
            data={assesmentList}
            margin={
              isMobileScreen
                ? { top: 25, right: 10, left: 10, bottom: 60 }
                : {
                    top: 10,
                    right: 50,
                    left: 20,
                    bottom: 65,
                  }
            }
          >
            <CartesianGrid horizontal={true} vertical={false} />
            <XAxis
              dataKey="evaluationDate"
              name="Measurment Date"
              domain={["auto", "auto"]}
              textAnchor="end"
              angle={-30}
              tick={{ fontSize: "12px" }}
              minTickGap={-2}
              interval={interval}
              dy={5}
              tickFormatter={formatXAxis}
              label={{
                value: `Date of Measurement`,
                fill: "#323234",
                style: {
                  textAnchor: "middle",
                  fontStyle: "normal",
                  fontWeight: "700",
                  fontSize: "14px",
                  lineHeight: "16px",
                },
                angle: -360,
                position: "bottom",
                offset: 45,
              }}
            />
            <YAxis
              allowDecimals={false}
              interval={"preserveEnd"}
              name="Wound Volumne"
              tickSize={0}
              tick={{ fontSize: "12px" }}
              dx={-5}
              label={{
                value: "Wound Volume (cm³)",
                fill: "#323234",
                style: {
                  textAnchor: "middle",
                  fontStyle: "normal",
                  fontWeight: "700",
                  fontSize: "14px",
                  lineHeight: "16px",
                },
                angle: -90,
                position: "left",
                offset: -10,
              }}
            ></YAxis>

            <Tooltip
              cursor={false}
              formatter={(value, label) => value + " cm³"}
              separator="       "
              label={""}
              labelFormatter={(t) =>
                Moment.utc(t).format("MM/DD/YYYY").valueOf()
              }
            />
            <Line
              dataKey="Volume"
              stroke="#0049BD"
              fill="#1E64D0"
              strokeWidth={1.5}
              dot={{
                stroke: "#1E64D0",
                r: 5,
              }}
            />
          </LineChart>
        </ResponsiveContainer>
      )}
    </>
  );
};

export default LineCharts;
