import { Button, InputBase } from "@mui/material";
import { CustomDropDown } from "../../../../core/customDropdown/customDropdown.component";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/AddCircleOutline";
import { useContext, useEffect, useState } from "react";
import {
  RolesPermissionContext,
  RolesPermissionContextType,
} from "../../../../context/RolesPermissionContext";
import "./myListHomeCareProvider.css";
import { MyListHomeCareProviderTable } from "./myListHomeCareProviderTable/myListHomeCareProviderTable.component";
import { useSortableTable } from "../../../../util/utilityFunctions";
import { DD_CLASSIFICATION_CONTENT } from "../../../../util/staticText";
import { getdropDownContent } from "../../../../util/dropDownService";
import { format } from "react-string-format";
import {
  addHomeCareProvider,
  getHomeCareProviderFacilityList,
  updateHomeCareProviderFacilityStatus,
} from "../../../../util/3meService";
import { AuthContext, AuthContextType } from "../../../../context/AuthContext";
import { P } from "../../../newOrder/newOrder.style";
import { useDebounce } from "use-debounce";
import { ExpressButton } from "../../../../core/expressButton/expressButton.component";
import { Popup } from "../../../../core/popup/popup.component";
import ErrorPopup from "../../../../core/errorPopup/errorPopup.component";
import { LoadingSpinner } from "../../../../core/loader/LoadingSpinner";
import {
  ERROR_MSG_ADD_HOME_CARE_PROVIDER,
  HCP_FACILITY_LOAD_SERVICE_ERROR,
  HCP_FACILITY_UPDATE_SERVICE_ERROR,
} from "../../../../util/errorMsg";
import { Validator } from "../../../../util/order.validations";
import { AddFacilityContainer } from "../../../manageProfile/facilityInformation/addFacilityContainer/addFacilityContainer.component";
import { AddFacilityContext } from "../../../manageProfile/facilityInformation/addFacilityContainer/addFacilityContainer.context";
import {
  AdminMyListsContext,
  AdminMyListsContextType,
} from "../../../../context/AdministrationMyListsContext";
import { ICaregiverFacility } from "../../../../core/caregiverSearchAndAdd/caregiverFacilitySearchAndAdd.model";

interface HcpFacilityData {
  caregiverId: string;
  accountNumber: string;
  name: string;
  address: HcoFacilityAddress;
  classificationCode: string;
  classification: string;
  isEnabled: boolean;
  isDefault: boolean;
}

interface HcoFacilityAddress {
  addressLine1: string;
  addressLine2: string;
  city: string;
  stateCode: string;
  postalCode: string;
}

export const MyListHomeCareProvider = () => {
  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const myListObj = useContext<AdminMyListsContextType | null>(
    AdminMyListsContext
  );
  const [open, setOpen] = useState<boolean>(false);
  const [errorPopPpFlag, setErrorPopUpFlag] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [errorCode, setErrorCode] = useState("");
  const [originalData, setOriginalData] = useState([]);

  const columns = [
    { label: "Enabled", accessor: "isEnabled", sortable: false },
    { label: "Default", accessor: "isDefault", sortable: false },
    { label: "Facility Name", accessor: "name", sortable: true },
    { label: "Customer #", accessor: "accountNumber", sortable: true },
    { label: "Classification", accessor: "classification", sortable: true },
  ];
  const [sortData, setSortData, handleSortedData] = useSortableTable(
    [],
    columns
  );
  const [classification, setClassification] = useState([]);
  const [classificationText, setClassificationText] = useState([]);
  const [selectedClassification, setSelectedClassification] =
    useState<string>("");
  const [searchInput, setSearchInput] = useState<string>("");
  const [searchedInput, setSearchedInput] = useState<string>("");
  const [debouncedText] = useDebounce(searchInput, 500);

  const fetchDropDownContent = async () => {
    //async and await
    try {
      const ddContent = format(
        "{0}",

        DD_CLASSIFICATION_CONTENT
      );
      const data = await getdropDownContent(ddContent);
      if (data.items.length > 0) {
        const classificationObject = data.items.filter(
          (item: { name: string }) => item.name === DD_CLASSIFICATION_CONTENT
        );
        const classificationData = classificationObject[0].data.sort(
          (a: { order: number }, b: { order: number }) =>
            a.order > b.order ? 1 : -1
        );
        setClassification(classificationData);
        setClassificationText(
          classificationData.map((x: { text: string }) => x.text)
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getHcpFacilityList = async () => {
    setShowSpinner(true);
    const body = {
      SiteUseId: authObj?.registeredFaciltyAddress?.siteUseId!,
      ShowAllRecords: true,
    };
    const response = await getHomeCareProviderFacilityList(body);
    if (response && response?.succeeded) {
      setShowSpinner(false);
      setSortData(response.data);
      myListObj?.setHcpTabList(response.data);
    } else {
      seterrorMessage(HCP_FACILITY_LOAD_SERVICE_ERROR);
      setErrorCode(response?.error?.code || response?.status);
      setShowSpinner(false);
      setErrorPopUpFlag(true);
    }
  };
  const handleSearch = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const re = /^[a-zA-Z0-9\s.,-]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setSearchInput(e.target.value);
    }
  };

  const validateAndSetData = (e: any) => {
    setSelectedClassification(e.target.value);
    filterUsers(debouncedText, e.target.value);
  };

  const filterUsers = (searchParam: string, status: string) => {
    setSearchedInput(searchParam);
    let searchStatus = "";
    if (status !== "All classifications") {
      searchStatus = status;
    }
    if (myListObj && myListObj.hcpTabList.length > 0) {
      let filtedUsers = myListObj.hcpTabList;
      if (searchStatus.length > 0) {
        filtedUsers = filtedUsers.filter(
          (data: HcpFacilityData) =>
            data.classification.toLocaleLowerCase() ===
            searchStatus.toLowerCase()
        );
      }
      if (searchParam.length > 0) {
        filtedUsers = filtedUsers.filter(
          (data: HcpFacilityData) =>
            data.name.toLocaleLowerCase().includes(searchParam.toLowerCase()) ||
            data.address.addressLine1
              .toLocaleLowerCase()
              .includes(searchParam.toLowerCase()) ||
            (data?.address?.addressLine2 !== null &&
              data?.address?.addressLine2
                .toLocaleLowerCase()
                .includes(searchParam.toLowerCase())) ||
            data.address.stateCode
              .toLocaleLowerCase()
              .includes(searchParam.toLowerCase()) ||
            data.address.postalCode
              .toLocaleLowerCase()
              .includes(searchParam.toLowerCase()) ||
            data.address.city
              .toLocaleLowerCase()
              .includes(searchParam.toLowerCase()) ||
            data.accountNumber
              .toLocaleLowerCase()
              .includes(searchParam.toLowerCase())
        );
      }
      setSortData(filtedUsers);
    }
  };

  const updateHcpFacilitystatus = async (data: any) => {
    setShowSpinner(true);
    const body = {
      LoggedInUserName: authObj?.userProfile?.userName,
      SiteUseId: authObj?.registeredFaciltyAddress?.siteUseId,
      CareGiverId: data.caregiverId,
      IsEnable: data.isEnabled,
      IsDefault: data.isDefault,
    };

    const response = await updateHomeCareProviderFacilityStatus(body);
    return response;
  };
  const handleClearDefaultCareGiver = async () => {
    const defaultRecord =
      myListObj &&
      myListObj.hcpTabList.filter((x: HcpFacilityData) => x.isDefault === true);
    if (defaultRecord.length > 0) {
      defaultRecord[0].isDefault = false;
      const res = await updateHcpFacilitystatus(defaultRecord[0]);
      if (res && res?.succeeded) {
        let sa = sortData.map((x: any) => {
          if (x.caregiverId === defaultRecord[0].caregiverId) {
            x.isDefault = defaultRecord[0].isDefault;
          } else {
            x.isDefault = false;
          }
          return x;
        });
        setSortData(sa);
        //update  original record
        let originalRecord = myListObj?.hcpTabList.map((x: any) => {
          if (x.caregiverId === defaultRecord[0].caregiverId) {
            x.isDefault = defaultRecord[0].isDefault;
          } else {
            x.isDefault = false;
          }
          return x;
        });

        myListObj?.setHcpTabList(originalRecord);
        setShowSpinner(false);
      } else {
        seterrorMessage(HCP_FACILITY_UPDATE_SERVICE_ERROR);
        setErrorCode(res?.error?.code || res?.status);
        setShowSpinner(false);
        setErrorPopUpFlag(true);
        setShowSpinner(false);
      }
    }
  };
  const valdiateAndSetRadioButton = async (
    event: any,
    data: HcpFacilityData
  ) => {
    data.isDefault = event.target.checked;
    let response = await updateHcpFacilitystatus(data);
    if (response && response?.succeeded) {
      let sa = sortData.map((x: any) => {
        if (x.caregiverId === data.caregiverId) {
          x.isDefault = data.isDefault;
        } else {
          x.isDefault = false;
        }
        return x;
      });
      setSortData(sa);
      let originalRecord = myListObj?.hcpTabList.map((x: any) => {
        if (x.caregiverId === data.caregiverId) {
          x.isDefault = data.isDefault;
        } else {
          x.isDefault = false;
        }
        return x;
      });
      myListObj?.setHcpTabList(originalRecord);

      setShowSpinner(false);
    } else {
      seterrorMessage(HCP_FACILITY_UPDATE_SERVICE_ERROR);
      setErrorCode(response?.error?.code || response?.status);
      setShowSpinner(false);
      setErrorPopUpFlag(true);
      setShowSpinner(false);
    }
  };
  const valdiateAndSetCheckBox = async (event: any, data: any) => {
    data.isEnabled = event.target.checked;
    data.isDefault = !data.isEnabled ? false : data.isDefault;
    let res = await updateHcpFacilitystatus(data);
    if (res && res?.succeeded) {
      let sa = sortData.map((x: HcpFacilityData) => {
        if (x.caregiverId === data.caregiverId) {
          return {
            ...x,
            isEnabled: data.isEnabled,
            isDefault: data.isEnabled === false ? false : x.isDefault,
          };
        }
        return x;
      });
      setSortData(sa);
      let originalRecord = myListObj?.hcpTabList.map((x: HcpFacilityData) => {
        if (x.caregiverId === data.caregiverId) {
          return {
            ...x,
            isEnabled: data.isEnabled,
            isDefault: data.isEnabled === false ? false : x.isDefault,
          };
        }
        return x;
      });
      myListObj?.setHcpTabList(originalRecord);
      setShowSpinner(false);
    } else {
      seterrorMessage(HCP_FACILITY_UPDATE_SERVICE_ERROR);
      setErrorCode(res?.error?.code || res?.status);
      setShowSpinner(false);
      setErrorPopUpFlag(true);
      setShowSpinner(false);
    }
  };

  useEffect(() => {
    getHcpFacilityList();
    fetchDropDownContent();
  }, []);
  useEffect(() => {
    if (
      (debouncedText.length === 0 && searchedInput !== debouncedText) ||
      debouncedText.length > 0
    ) {
      filterUsers(debouncedText, selectedClassification);
    }
  }, [debouncedText]);

  const spinner = () => {
    return (
      <div className="myList-hcpFacility-spinner">
        <LoadingSpinner />
      </div>
    );
  };

  const handleAddNewProvider = () => {
    myListObj?.setIsFromMyListHcp(true);
    setOpen(true);
  };

  const addNewFacility = async (facility: any) => {
    setOpen(false);
    setShowSpinner(true);
    const reqParams = {
      SiteUseId: authObj?.registeredFaciltyAddress?.siteUseId,
      CareGiverId: facility?.careGiverId!,
      CustomerAccountNumber: facility.accountNumber,
      FacilityName: facility?.accountName,
    };
    const res = await addHomeCareProvider(reqParams);
    setShowSpinner(false);
    if (res && res.succeeded) {
      setSearchInput("");
      setSelectedClassification("");
      getHcpFacilityList();
    } else {
      seterrorMessage(ERROR_MSG_ADD_HOME_CARE_PROVIDER);
      setErrorCode(res?.error?.code || res?.status);
      setShowSpinner(false);
      setErrorPopUpFlag(true);
    }
  };

  const handleCloseAndResetPopUp = () => {
    setOpen(false);
    myListObj?.setIsFromMyListHcp(false);
  };

  return (
    <div className="myList-hcp-main-component">
      <div className="myList-hcp-main-sub-component">
        <div
          className="myList-hcp-user-searchbar"
          data-testid="myList-Hcp-search-input"
        >
          <div className="myList-hcp-search-icon-div">
            <SearchIcon className="search-icon" />
          </div>
          <InputBase
            className="myList-hcp-user-search-input"
            name="search-input"
            onChange={handleSearch}
            placeholder="Filter by Facility Name, Location or Number"
            value={searchInput}
          />
        </div>
        <CustomDropDown
          handleChange={validateAndSetData}
          menuItem={classificationText}
          name="hcp-facilityCalssification"
          placeHolder="All classifications"
          selectpropsClassName={"hcp-facilityCalssification-select"}
          selectClassName={"hcp-facilityCalssification-input"}
          testId="myList-Hcp-dropdown"
          value={selectedClassification}
        />
        <Button
          classes={{ root: "myList-add-new-hcp-btn" }}
          startIcon={<AddIcon />}
          onClick={handleAddNewProvider}
          disabled={permissionObj?.mappedRolesPermissionData.IsSupportRole}
          data-testid={"my-hcpList-add-usr-btn"}
        >
          Add New Provider
        </Button>
      </div>
      <div className="users-table">
        <MyListHomeCareProviderTable
          columns={columns}
          handleSorting={handleSortedData}
          sortedData={sortData}
          setSortedData={setSortData}
          valdiateAndSetRadioButton={valdiateAndSetRadioButton}
          valdiateAndSetCheckBox={valdiateAndSetCheckBox}
        />
      </div>
      <div className="myListHcpClearButton">
        <ExpressButton
          variant="outlined"
          clickHandler={handleClearDefaultCareGiver}
          testId="my-hcpList-clear-usr-btn"
          disabled={permissionObj?.mappedRolesPermissionData?.IsSupportRole}
        >
          Clear Default Caregiver
        </ExpressButton>
      </div>
      {showSpinner && (
        <Popup
          hideCloseButton={true}
          openFlag={showSpinner}
          closeHandler={() => {}}
        >
          {spinner()}
        </Popup>
      )}
      <ErrorPopup
        errorPopupFlag={errorPopPpFlag}
        handleBackButton={() => setErrorPopUpFlag(false)}
        popUpStyles="errorPopup"
        errorMessage={errorMessage}
        buttontitle="Done"
        showTitle={false}
        isShortSentence={false}
        errorCode={errorCode}
      />
      <AddFacilityContext.Provider
        value={{
          closePopup: handleCloseAndResetPopUp,
          facilitySearchValidator: new Validator(),
          addFacilityToList: addNewFacility,
        }}
      >
        <Popup
          dialogParentClass="add-facility-popup"
          openFlag={open}
          closeHandler={handleCloseAndResetPopUp}
        >
          <AddFacilityContainer />
        </Popup>
      </AddFacilityContext.Provider>
    </div>
  );
};
