import { useEffect, useState } from "react";
import { ExpressButton } from "../../core/expressButton/expressButton.component";
import "./scanBot.css";
import { Done } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { ReactComponent as ScanBotButtonIcon } from "../../assets/ScanBotButtonIcon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/DeleteIcon.svg";
import { PersistentFooter } from "../persistentFooter/persistentFooter.Component";
import SnackBar from "../snackBar/snackBar.component";
import { Popup } from "../popup/popup.component";
import ErrorPopup from "../errorPopup/errorPopup.component";
export const ScanBot = ({
  documentDetected,
  scanBotApp,
  scanDocumentClick,
  pages,
  list,
  handleSavePdfClick,
  closeHandler,
  isTesting = false, //scanbot is not providing test case for now so bypassing it
  setScannedImages,
  setScannedDocPageNumber,
  savePdfClicked,
  setSavePDfClicked,
}: any) => {
  const [scanbotSdk, setScanbotSdk] = useState<any>();
  let sdk: any;
  const [openScanner, setOpenScanner] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  useEffect(() => {
    if (!isTesting) {
      setSavePDfClicked(false);
      createDocumentScanner();
      return () => {
        disposeScanner();
      };
    }
  }, [openScanner]);

  useEffect(() => {
    if (pages === 20) {
      setOpenScanner(false);
      setIsPageclicked(true);
      scanbotSdk?.dispose();
      setShowImageResult(true);
    }
  }, [pages]);

  const disposeScanner = async () => {
    await sdk?.dispose();
  };

  const [showImageResult, setShowImageResult] = useState(false);
  const [showImageDeleteResult, setShowImageDeleteResult] = useState(false);
  const [isPageClicked, setIsPageclicked] = useState(false);
  const [selectedImage, setSelectedImage] = useState<any>();

  const createDocumentScanner = async () => {
    const config = {
      onDocumentDetected: (result: any) => documentDetected(result),
      containerId: "document-scanner-view",
      text: {
        hint: {
          OK: "Capturing your document...",
          OK_SmallSize: "The document is too small. Try moving closer.",
          OK_BadAngles:
            "This is a bad camera angle. Hold the device straight over the document.",
          OK_BadAspectRatio:
            "Rotate the device sideways, so that the document fits better into the screen.",
          OK_OffCenter: "Try holding the device at the center of the document.",
          Error_NothingDetected:
            "Please hold the device over a document to start scanning.",
          Error_Brightness: "It is too dark. Try turning on a light.",
          Error_Noise: "Please move the document to a clear surface.",
        },
      },
      onError: () => {},
      preferredCamera: "camera2 0, facing back",
    };
    if (!isTesting) {
      sdk = await scanBotApp?.createDocumentScanner(config);
      setScanbotSdk(sdk);
    }
  };
  const handleDoneClick = async () => {
    setIsPageclicked(false);
    await scanbotSdk?.dispose();
    setShowImageResult(true);
  };
  const handlePageClick = async () => {
    if (list.length > 0) {
      setOpenScanner(false);
      setIsPageclicked(true);
      await scanbotSdk?.dispose();
      setShowImageResult(true);
    }
  };
  const imageOnclick = (image: any) => {
    setSelectedImage(image);
    setShowImageDeleteResult(true);
    setShowImageResult(false);
  };
  const imageOncloseClick = () => {
    setShowImageDeleteResult(false);
    setShowImageResult(true);
  };
  const handleDeleteClick = () => {
    const filteredImages = list.filter(
      (x: any) => x.page !== selectedImage.page
    );
    if (filteredImages.length > 0) {
      setScannedDocPageNumber(filteredImages.length);
      setScannedImages(filteredImages);
      setShowImageDeleteResult(false);
      setShowImageResult(true);
    } else {
      setScannedDocPageNumber(0);
      closeHandler();
    }
  };
  const handleImageDetailBackClick = async () => {
    if (isPageClicked) {
      if (pages === 20) {
        setOpenSnack(true);
      } else {
        setOpenScanner(true);
        setShowImageResult(false);
      }
    } else {
      closeHandler();
    }
  };
  const handleCloseAlert = async () => {
    setOpenSnack(false);
  };
  return (
    <>
      {scanBotApp &&
        !showImageResult &&
        !showImageDeleteResult &&
        window?.innerHeight && (
          <div className="scanBotMainContainer">
            <div className="scanBotMainDiv">
              <ScanBotButtonIcon onClick={closeHandler} />
              <p className="scanBotTitle" data-testid="scanBotTitle-testId">
                Document Scanner
              </p>
              <p></p>
            </div>
            <div
              className="imageContainer"
              data-testid="scanBot-ImageContainer"
            >
              <div
                id="document-scanner-view"
                style={{
                  height: window?.innerHeight - 2 * 40 ?? 0,
                  width: "100%",
                }}
              ></div>
            </div>
            <PersistentFooter footerButtonClass="scanBotFooterDiv">
              <p
                className="scanBotPageDetail"
                data-testid="scanBot-pages-count"
                onClick={handlePageClick}
              >
                {" "}
                {pages} Pages
              </p>
              <ExpressButton
                testId="scanbot-done-button"
                parentClass="scanBot-Done-Button"
                variant="contained"
                clickHandler={handleDoneClick}
                children={"Done"}
                disabled={list?.length === 0}
              ></ExpressButton>
            </PersistentFooter>
          </div>
        )}
      {showImageResult && !showImageDeleteResult && (
        <>
          {" "}
          <div
            className="scanBot-ImageResult-Container"
            style={{
              height: window.innerHeight - 2 * 40 ?? 0,
              width: "100%",
            }}
          >
            <div className="scanBotMainDiv">
              <ScanBotButtonIcon onClick={handleImageDetailBackClick} />
              <p className="scanBotTitle" data-testid="scanbot-image-details">
                Image Details
              </p>
              <p></p>
            </div>
            <div className="scanBot-ImageResult-Main">
              {list.map((image: any) => {
                return (
                  <div
                    className="scanBot-ImageDetail-Main"
                    onClick={() => imageOnclick(image)}
                  >
                    <img
                      src={image.base64}
                      alt={"."}
                      className="scanBot-ImageDetail"
                    />
                  </div>
                );
              })}
            </div>
            <PersistentFooter footerButtonClass="scanBot-Image-FooterDiv">
              <ExpressButton
                testId="scanbot-savepdf-button"
                variant="outlined"
                clickHandler={handleSavePdfClick}
                children={"Save as PDF"}
                disabled={savePdfClicked}
              ></ExpressButton>
            </PersistentFooter>
          </div>
        </>
      )}
      {!showImageResult && showImageDeleteResult && selectedImage && (
        <>
          {" "}
          <div
            className="scanBot-ImageResult-Delete-Container"
            style={{
              height: window.innerHeight - 2 * 40 ?? 0,
            }}
          >
            <div className="scanBotMainDiv">
              <ScanBotButtonIcon onClick={imageOncloseClick} />
              <p className="scanBotTitle" data-testid="scanbot-image-details">
                Image Details
              </p>
              <p></p>
            </div>
            <div className="scanBot-ImageResult-Main">
              <div className="scanBot-ImageDetail-Main">
                <img
                  src={selectedImage.base64}
                  alt={"."}
                  className="scanBot-ImageDetail"
                />
              </div>
            </div>
            <PersistentFooter footerButtonClass="scanBot-Image-delete-FooterDiv">
              <ExpressButton
                testId="scanbot-savepdf-button"
                variant="outlined"
                parentClass="scanBot-Delete-Button"
                clickHandler={handleDeleteClick}
                children={"Delete"}
                startIcon={<DeleteIcon />}
              ></ExpressButton>
            </PersistentFooter>
          </div>
        </>
      )}

      <ErrorPopup
        popUpStyles={"error-popup-design"}
        handleBackButton={() => handleCloseAlert()}
        showTitle={false}
        errorMessage={"Scan limit of 20 documents achieved"}
        errorPopupFlag={openSnack}
        isSupportPhoneRequired={false}
        isShortSentence={false}
        buttontitle="OK"
      ></ErrorPopup>
    </>
  );
};
