import "./sendNoteFailure.css";
import { ExpressButton } from "../../../../core/expressButton/expressButton.component";
import { ISendNoteFailureProps } from "./sendNoteFailure.interface";

export const SendNoteFailure = ({
  rootClass = null,
  message,
  backButtonAction,
  showTwoLineMessage = false,
  message1 = "",
  message2 = "",
}: ISendNoteFailureProps) => {
  return (
    <div className={rootClass ?? "failure-pop-up"}>
      {showTwoLineMessage ? (
        <div className="showTwoLineMessage-div">
          <h4
            className="failure-pop-up-description-message1"
            data-testid="failure-pop-up-description-message1"
          >
            {message1}
          </h4>
          <h4
            className="failure-pop-up-description-message2"
            data-testid="failure-pop-up-description-message2"
          >
            {message2}
          </h4>
        </div>
      ) : (
        <h4 className="failure-pop-up-description" data-testid="description">
          {message}
        </h4>
      )}
      <ExpressButton
        clickHandler={() => {
          backButtonAction();
        }}
        parentClass="failure-pop-up-back-button"
        testId="back-button"
        variant="contained"
      >
        Done
      </ExpressButton>
    </div>
  );
};
