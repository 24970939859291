import { IPatientInformation } from "./../patientInformation.interface";
import { ValidationStatus } from "../../../../../core/interfaces/input.interface";

export const mockPatientInformationData: IPatientInformation = {
  firstName: {
    componentId: "in-patient-order-patient-information-first-name",
    id: "in-patient-order-patient-information-first-name-input",
    order: 1,
    required: true,
    title: "Patient First Name",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  lastName: {
    componentId: "in-patient-order-patient-information-last-name",
    id: "in-patient-order-patient-information-last-name-input",
    required: true,
    order: 2,
    title: "Patient Last Name",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  dateOfBirth: {
    componentId: "in-patient-order-patient-information-date-of-birth",
    id: "in-patient-order-patient-information-date-of-birth-input",
    order: 3,
    required: true,
    title: "Date of Birth",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  room: {
    componentId: "in-patient-order-patient-information-room",
    id: "in-patient-order-patient-information-room-input",
    required: true,
    order: 4,
    title: "Room #",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  patientId: {
    componentId: "in-patient-order-patient-information-patient-id",
    id: "in-patient-order-patient-information-patient-id-input",
    isDefaultValid: true,
    order: 5,
    title: "Patient Id",
    valid: ValidationStatus.VALID,
    value: "",
  },
};
