import { Box, Toolbar } from "@mui/material";
import "./createServiceRequestFooterButtonGroup.css";
import { ExpressButton } from "../../../../core/expressButton/expressButton.component";
import { PersistentFooter } from "../../../../core/persistentFooter/persistentFooter.Component";

export interface ICreateServiceRequestFooterButtonProps {
  firstButtonAction: Function;
  firstButtonDisabled?: boolean;
  firstButtonTitle: string;

  secondButtonAction: Function;
  secondButtonDisabled?: boolean;
  secondButtonTitle: string;

  thirdButtonAction: Function;
  thirdButtonDisabled?: boolean;
  thirdButtonTitle: string;
}

export const CreateServiceRequestFooterButtonGroup = ({
  firstButtonAction,
  firstButtonDisabled = false,
  firstButtonTitle,
  secondButtonAction,
  secondButtonDisabled = false,
  secondButtonTitle,
  thirdButtonAction,
  thirdButtonDisabled = false,
  thirdButtonTitle,
}: ICreateServiceRequestFooterButtonProps) => {
  return (
    <PersistentFooter>
      <Toolbar className="create-service-request-tool-bar">
        <Box className="create-service-request-main-container">
          <Box
            className="create-service-request-left-container"
            flexWrap="wrap"
          >
            {firstButtonTitle !== "" && (
              <Box className="boxOrderStyle" p={1}>
                <ExpressButton
                  clickHandler={() => firstButtonAction()}
                  disabled={firstButtonDisabled}
                  parentClass="firstButton"
                  testId="firstButton-test"
                  variant="text"
                >
                  {firstButtonTitle}
                </ExpressButton>
              </Box>
            )}
          </Box>
          <Box
            className="create-service-request-right-container"
            flexWrap="wrap"
          >
            {secondButtonTitle !== "" && (
              <Box className="boxOrderStyle" p={1}>
                <ExpressButton
                  clickHandler={() => secondButtonAction()}
                  disabled={secondButtonDisabled}
                  parentClass="secondButton"
                  testId="secondButton-test"
                  variant="text"
                >
                  {secondButtonTitle}
                </ExpressButton>
              </Box>
            )}
            {thirdButtonTitle !== "" && (
              <Box className="boxOrderStyle" p={1}>
                <ExpressButton
                  clickHandler={() => thirdButtonAction()}
                  disabled={thirdButtonDisabled}
                  parentClass="thirdButton"
                  testId="thirdButton-test"
                  variant="contained"
                >
                  {thirdButtonTitle}
                </ExpressButton>
              </Box>
            )}
          </Box>
        </Box>
      </Toolbar>
    </PersistentFooter>
  );
};
