import { useContext } from "react";
import { Grid } from "@mui/material";
import "./patientInformationReview.css";
import { IPatientInformation } from "../patientInformation.interface";
import { makeCapitalEachWordInString } from "../../../../../util/utilityFunctions";
import { MobileDisplayContext } from "../../../../../context/MobileDisplayContext";

interface Props {
  data: IPatientInformation;
  editButtonAction?: any;
  isSummary?: boolean;
}

export const PatientInformationReview = ({
  data,
  editButtonAction,
  isSummary = false,
}: Props) => {
  const { isMobileScreen } = useContext(MobileDisplayContext);

  const showTitleAndValue = (
    title: string,
    value: string | null,
    testId: string
  ) => {
    return (
      <div
        className="show-title-and-value-div"
        id="id_show-title-and-value-div"
      >
        <p
          className="show-title-and-value-title"
          data-testid={`${testId}-title`}
          id={`${testId}-title-id`}
        >
          {title}
        </p>
        <p
          className="show-title-and-value-value"
          data-testid={`${testId}-value`}
          id={`${testId}-value-id`}
        >
          {value && value !== "" ? value.trim() : "--"}
        </p>
      </div>
    );
  };

  return (
    <div
      className="patient-information-review-component"
      data-testid="patient-information-review-component"
      id="id_patient-information-review-component"
    >
      <div
        className="patient-information-review-header"
        id="id_patient-information-review-header"
      >
        <p
          className="patient-information-review-title"
          data-testid="patient-information-review-title"
          id="patient-information-review-title-id"
        >
          Patient Information
        </p>
        {!isSummary && (
          <button
            className="patient-information-review-edit-button"
            data-testid="patient-information-review-edit-button"
            id="patient-information-review-edit-button-id"
            onClick={() =>
              editButtonAction("in-patient-order-patient-information-component")
            }
          >
            Edit
          </button>
        )}
      </div>
      <Grid
        className="patient-information-review-grid-container"
        container
        id="id_patient-information-review-grid-container"
      >
        <Grid
          className="patient-information-review-grid-item"
          id="id_patient-information-review-grid-item"
          item
          xs={isMobileScreen ? 6 : 4}
        >
          {showTitleAndValue(
            "Full Name",
            data.firstName.value
              ? makeCapitalEachWordInString(
                  data.firstName.value.trim() + " " + data.lastName.value.trim()
                )
              : "",
            "patient-full-name"
          )}
        </Grid>
        <Grid
          className="patient-information-review-grid-item"
          item
          id="id_patient-information-review-grid-item"
          xs={isMobileScreen ? 6 : 4}
        >
          {showTitleAndValue("Date of Birth", data.dateOfBirth.value, "dob")}
        </Grid>
        <Grid
          className="patient-information-review-grid-item"
          item
          id="id_patient-information-review-grid-item"
          xs={isMobileScreen ? 0 : 4}
        ></Grid>
      </Grid>
      <Grid className="patient-information-review-grid-container" container>
        <Grid
          className="patient-information-review-grid-item"
          item
          xs={isMobileScreen ? 6 : 4}
        >
          {showTitleAndValue(
            "Patient Location",
            data.room.value,
            "patient-location"
          )}
        </Grid>
        <Grid
          className="patient-information-review-grid-item"
          item
          xs={isMobileScreen ? 6 : 4}
        >
          {showTitleAndValue("Patient ID", data.patientId.value, "patient-id")}
        </Grid>
      </Grid>
    </div>
  );
};
