import { makeCapitalEachWordInString } from "../../../../util/utilityFunctions";
import { ISelectedHomeCareProvider } from "../homeCareProvider.interface";
import ".././sendNoteHomeCareProvider.css";
import { Button, Grid, useMediaQuery } from "@mui/material";
import { SearchHomeCareProviderModal } from "../../../newOrder/homeCareProvider/homeCareSearch/searchHomeCare.enum";
import { useContext } from "react";
import {
  NewOrderContext,
  NewOrderContextType,
} from "../../../../context/NewOrderContext";

export const SelectedHomeCareProvider = ({
  data,
  isNewHcpSelected,
}: ISelectedHomeCareProvider) => {
  const isMobileScreen = useMediaQuery("(max-width:900px)");
  const newOrderObj = useContext<NewOrderContextType | null>(NewOrderContext);
  const handleChangeHomeCareProviderBtn = () => {
    newOrderObj?.setHomecareproviderSearchAddPopUpSection(
      SearchHomeCareProviderModal.SEARCH_HOMECAREPROVIDER
    );
    newOrderObj?.setSearchHomeCareProviderPopup(true);
  };
  return (
    <div className="sendnote-selected-main-container">
      <div className="sendnote-sub-caregiver-container">
        {data?.addedCaregiverName.value !== "" ? (
          <>
            <Grid
              container
              className="sendnote-sub-caregiver-gridMain-container"
            >
              <Grid item xs={isNewHcpSelected ? 6 : 12}>
                <div
                  className="sendnote-caregiver-name"
                  data-testid="sendnote-caregiver-name-test"
                  id="sendnote-caregiver-name"
                >
                  {makeCapitalEachWordInString(
                    data?.addedCaregiverName.value.toLowerCase()
                  )}
                </div>
              </Grid>
              {isNewHcpSelected && (
                <Grid item xs={6}>
                  <div className="sendnote-change-home-care-provider-btn">
                    <Button
                      onClick={handleChangeHomeCareProviderBtn}
                      classes={{
                        root: "sendnote-hcp-change-provider-btn",
                      }}
                      data-testid="sendnote-change-caregiver-btn-test"
                      id="sendnote-change-caregiver-btn"
                    >
                      Change Home care provider
                    </Button>
                  </div>
                </Grid>
              )}
            </Grid>
            <Grid
              className="sendnote-caregiver-subsection-main"
              container
              gap={isMobileScreen ? "4px" : 0}
            >
              <Grid
                className="sendnote-caregiver-name-address-part sendnote-caregiver-name-address-div"
                item
                xs={isMobileScreen ? 6 : 4}
              >
                <div className="sendnote-caregiver-name-address-main">
                  <div
                    className="sendnote-caregiver-name-address-header"
                    data-testid="sendnote-caregiver-name-address-header-test"
                    id="sendnote-caregiver-name-address-header"
                  >
                    Address
                  </div>
                  <div className="sendnote-caregiver-address-full">
                    <div
                      className="sendnote-subtext-caregiver"
                      data-testid="sendnote-subtext-caregiver-address1-test"
                      id="sendnote-subtext-caregiver-address1"
                    >
                      {makeCapitalEachWordInString(
                        data?.addedCaregiverAddress1?.value
                          ? data?.addedCaregiverAddress1?.value.toLowerCase()
                          : ""
                      )}
                    </div>
                    <div
                      className="sendnote-subtext-caregiver"
                      data-testid="sendnote-subtext-caregiver-address2-test"
                      id="sendnote-subtext-caregiver-address2"
                    >
                      {makeCapitalEachWordInString(
                        data?.addedCaregiverAddress2?.value
                          ? data?.addedCaregiverAddress2?.value.toLowerCase()
                          : ""
                      )}
                    </div>
                    <div
                      className="sendnote-subtext-caregiver"
                      data-testid="sendnote-subtext-caregiver-city-test"
                      id="sendnote-subtext-caregiver-city"
                    >
                      {`${`${makeCapitalEachWordInString(
                        data?.addedCaregiverCity?.value?.toLowerCase()
                      )}, ${
                        data?.addedCaregiverState?.value ?? ""
                      }, ${data?.addedCaregiverZip?.value?.toLowerCase()}`}`}
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid
                className="sendnote-address-part phone-div"
                item
                xs={isMobileScreen ? 6 : 4}
              >
                <div
                  className="sendnote-caregiver-name-address-header sendnote-caregiver-name-phone-header-mobile"
                  data-testid="sendnote-subtext-caregiver-phoneHeader-test"
                  id="sendnote-subtext-phone-header"
                >
                  Phone
                </div>
                <div
                  className="sendnote-subtext-caregiver sendnote-caregiver-name-phone-header-mobile"
                  data-testid="sendnote-subtext-caregiver-phone-test"
                  id="sendnote-subtext-phone"
                >
                  {makeCapitalEachWordInString(
                    data?.addedCaregiverPhone.value
                      ? data?.addedCaregiverPhone.value
                      : "--"
                  )}
                </div>
              </Grid>
              <Grid
                className="sendnote-address-part phone-div"
                item
                xs={isMobileScreen ? 6 : 4}
              >
                <div
                  className="sendnote-caregiver-name-address-header sendnote-caregiver-name-address-header-mobile"
                  data-testid="sendnote-subtext-caregiver-facilityType-header-test"
                  id="sendnote-subtext-facilityType-header"
                >
                  Facility Type
                </div>
                <div
                  className="sendnote-subtext-caregiver"
                  data-testid="sendnote-subtext-caregiver-facilityType-test"
                  id="sendnote-subtext-facilityType"
                >
                  {makeCapitalEachWordInString(
                    data?.addedCaregiverFacilityType.value
                      ? data?.addedCaregiverFacilityType.value.toLowerCase()
                      : "--"
                  )}
                </div>
              </Grid>
            </Grid>
          </>
        ) : (
          <div>
            <p
              className="sendnote-subtext-caregiver"
              data-testid="sendnote-subtext-caregiver-nofacility-test"
              id="sendnote-subtext-nofacility"
            >
              No Home Care Provider has been associated to this order
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
