import { useMediaQuery } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  InternalSignOnContext,
  InternalSignOnContextType,
} from "../../../context/InternalSignOnContext";
import {
  MyPatientContext,
  MyPatientContextType,
} from "../../../context/MyPatientContext";
import { AlertToolTip } from "../../../core/alertToolTip/alertToolTip.component";
import {
  agGridGetRowStyle,
  handleListHeight,
  makeCapitalEachWordInString,
  statusColor,
} from "../../../util/utilityFunctions";
import { IPatient } from "../patient.interface";
import "./myPatientTable.css";

type Props = {
  patients: IPatient[];
  handleOnPatientClick: (rec: IPatient) => void;
  patientAnalytics: any;
};

const MyPatientTable = ({
  patients,
  handleOnPatientClick,
  patientAnalytics,
}: Props) => {
  const gridRef: any = useRef(null);
  const isMobileScreen = useMediaQuery("(max-width:920px)");
  const myPatientObj = useContext<MyPatientContextType | null>(
    MyPatientContext
  );
  const filterData = myPatientObj!.orderFilterData;
  const [currentPost, setCurrentPost] = useState<IPatient[]>([]);
  const internalSignOnObj = useContext<InternalSignOnContextType | null>(
    InternalSignOnContext
  );

  const defaultColDef = {
    sortable: true,
    unSortIcon: true,
    suppressMenu: true,
    autoHeight: true,
  };

  const [columnDefs, setColumnDefs] = useState<any>([
    {
      headerName: "RO #",
      field: "roNumber",
      width: isMobileScreen ? 100 : 110,
      wrapText: true,
      pinned: "left",
      lockPinned: true,
      wrapHeaderText: true,
      cellClass: "tableDataCentered",
      suppressMovable: true,
      cellRenderer: function (params: any) {
        return (
          <>
            <span
              className={"inprocess-order-ro tableDataCentered"}
              id={params.data.roNumber}
              onClick={(e) => handleOnPatientClick(params.data)}
            >
              {params.data.status.toLowerCase() === "saved"
                ? "continue order"
                : params.data.roNumber}
            </span>
          </>
        );
      },
    },
    {
      headerName: "Patient Name",
      minWidth: isMobileScreen ? 150 : 200,
      pinned: isMobileScreen ? "none" : "left",
      lockPinned: isMobileScreen ? false : true,
      wrapText: true,
      wrapHeaderText: true,
      suppressMovable: true,
      valueGetter: (params: any) =>
        `${params.data.lastName}, ${params.data.firstName}`,
      comparator: (valueA: string, valueB: string) => {
        return valueA.localeCompare(valueB);
      },
      cellRenderer: function (params: any) {
        return (
          <>
            {makeCapitalEachWordInString(
              `${params.data.lastName}, ${params.data.firstName}`
            )}
          </>
        );
      },
    },
    {
      field: "dob",
      headerName: "DOB",
      lockPinned: false,
      suppressMovable: true,
      cellRenderer: function (params: any) {
        return (
          <>
            {params.data.dob && params.data.dob !== ""
              ? moment(params.data.dob).format("MM/DD/YYYY")
              : ""}
          </>
        );
      },
    },
    {
      field: "alerts",
      headerName: "Alerts",
      tooltipField: "Alerts",
      cellClass: cellClass,
      suppressMovable: true,
      cellRenderer: function (params: any) {
        return (
          <AlertToolTip
            patientAnalytics={patientAnalytics}
            alertCount={
              params.data.alerts.length !== 0 ? params.data.alerts.length : ""
            }
            alertData={params?.data.alerts!}
            isMPD={true}
            patientData={params?.data}
          />
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 180,
      suppressMovable: true,
      cellRenderer: function (params: any) {
        return (
          <>
            <span
              className={`circleStatus ${statusColor(params.data.status)}`}
            />
            {makeCapitalEachWordInString(params.data.status)}
          </>
        );
      },
    },
    {
      cellClass: "tableDataCentered",
      field: "isAcuteOrder",
      headerName: "Order Type",
      minWidth: 120,
      suppressMovable: true,
      cellRenderer: function (params: any) {
        return (
          <>
            {makeCapitalEachWordInString(
              params.data.isAcuteOrder ? "Acute" : "Post Acute"
            )}
          </>
        );
      },
    },
    {
      field: "patientLocation",
      headerName: "Location",
      cellClass: "tableDataCentered",
      suppressMovable: true,
      cellRenderer: function (params: any) {
        return (
          <>
            {makeCapitalEachWordInString(
              params.data.isAcuteOrder
                ? params.data.patientLocation &&
                  params.data.patientLocation !== ""
                  ? params.data.patientLocation
                  : ""
                : "Home"
            )}
          </>
        );
      },
    },
    {
      field: "productName",
      headerName: "Product",
      minWidth: 150,
      maxWidth: 300,
      wrapText: true,
      suppressMovable: true,
      cellRenderer: function (params: any) {
        return <>{params.data.productName}</>;
      },
    },
    {
      field: "productSerialNumber",
      headerName: "Serial #",
      minWidth: 150,
      wrapHeaderText: true,
      suppressMovable: true,
      cellRenderer: function (params: any) {
        return <>{params.data.productSerialNumber}</>;
      },
    },
    {
      headerName: "Care type",
      field: "type",
      wrapText: false,
      suppressMovable: true,
      cellRenderer: function (params: any) {
        return (
          <>
            {params.data.type.toLowerCase() === "readycare"
              ? makeCapitalEachWordInString("Ready Care")
              : "Standard"}
          </>
        );
      },
    },
    {
      headerName: "Facility",
      field: "facilityName",
      minWidth: 200,
      maxWidth: 300,
      wrapText: true,
      suppressMovable: true,
      cellRenderer: function (params: any) {
        return <>{makeCapitalEachWordInString(params.data.facilityName)}</>;
      },
    },
    {
      cellClass: "tableDataCentered",
      field: "patientId",
      headerName: "Patient ID",
      suppressMovable: true,
      cellRenderer: function (params: any) {
        return (
          <>
            {params.data.isAcuteOrder &&
            params.data.patientId &&
            params.data.patientId !== ""
              ? params.data.patientId
              : ""}
          </>
        );
      },
    },
    {
      headerName: "Created",
      field: "orderCreationDate",
      suppressMovable: true,
      cellRenderer: function (params: any) {
        return (
          <>
            {params.data.orderCreationDate &&
            params.data.orderCreationDate !== ""
              ? moment(params.data.orderCreationDate).format("MM/DD/YYYY")
              : ""}
          </>
        );
      },
    },
  ]);

  function cellClass(params: any) {
    return params.data.alerts.length !== 0
      ? "tableAlertData"
      : "tableAlertDataNoCursor";
  }

  const updateAlertColumn = useCallback(() => {
    setCurrentPost(patients);
  }, []);

  useEffect(() => {
    if (filterData.isAlertFilterEnabled) {
      updateAlertColumn();
    }
  }, [filterData.isAlertFilterEnabled]);

  useEffect(() => {
    setCurrentPost(patients);
  }, []);

  useEffect(() => {
    if (patients) {
      if (currentPost?.length !== patients.length) {
        setCurrentPost(patients);
      }
    }
  }, [patients]);

  return (
    <div className={`my-patient-table`} data-testid="my-patient-table-id">
      <div
        data-testid="ag-theme-quartz-table"
        className="ag-theme-quartz"
        style={{
          height: handleListHeight(currentPost?.length <= 5)
            ? 350
            : window.innerHeight - 2 * 130,
        }}
      >
        <AgGridReact
          autoSizeStrategy={{
            type: "fitGridWidth",
            defaultMinWidth: 100,
          }}
          ref={gridRef}
          getRowStyle={agGridGetRowStyle}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowData={currentPost}
          suppressRowClickSelection={true}
          suppressDragLeaveHidesColumns={true}
          suppressContextMenu={true}
          onBodyScroll={() => {
            internalSignOnObj?.setIsViewReportsScrolled(
              internalSignOnObj.isViewReportsScrolled++
            );
          }}
        />
      </div>
    </div>
  );
};

export default MyPatientTable;
