import parse from "html-react-parser";
import "./vacTherapyUnitReviewRequest.css";
import { Grid, useMediaQuery } from "@mui/material";
import { IVacTherapyUnit } from "../vacTherapyUnit.component";
import { makeCapitalEachWordInString } from "../../../../../../util/utilityFunctions";

export const VacTherapyUnitReviewRequest = ({
  selectedProduct,
}: IVacTherapyUnit) => {
  const isMobileScreen = useMediaQuery("(max-width:475px)");
  const isBiggerMobileScreen = useMediaQuery("(max-width:600px)");

  const showTitleAndValue = (
    title: string,
    value: string | null | undefined,
    testId: string
  ) => {
    return (
      <div className="show-title-and-value-div">
        <p
          className="show-title-and-value-title"
          data-testid={`${testId}-title`}
        >
          {title}
        </p>
        <p
          className="show-title-and-value-value"
          data-testid={`${testId}-value`}
        >
          {value && value.length > 0 ? value : "--"}
        </p>
      </div>
    );
  };

  return (
    <div
      className="vac-therapy-unit-review-request-component"
      data-testid="vac-therapy-unit-review-request-component"
    >
      <div className="vac-therapy-unit-review-request-header">
        <p
          className="vac-therapy-unit-review-request-title"
          data-testid="vac-therapy-unit-review-request-title"
        >
          {parse("V.A.C.<sup>®</sup> Therapy Unit")}
        </p>
      </div>
      <Grid className="vac-therapy-unit-grid-container" container>
        <Grid
          className="vac-therapy-unit-grid-item"
          item
          xs={isMobileScreen ? 12 : isBiggerMobileScreen ? 6 : 4}
        >
          {showTitleAndValue(
            "Unit Tag ID",
            makeCapitalEachWordInString(selectedProduct.unitTagId),
            "unit-tag"
          )}
        </Grid>
        <Grid
          className="vac-therapy-unit-grid-item"
          item
          xs={isMobileScreen ? 6 : isBiggerMobileScreen ? 3 : 4}
        >
          {showTitleAndValue(
            "Product",
            selectedProduct.productNameDescription,
            "product-name"
          )}
        </Grid>
        <Grid
          className="vac-therapy-unit-grid-item"
          item
          xs={isMobileScreen ? 6 : isBiggerMobileScreen ? 3 : 4}
        >
          {showTitleAndValue(
            "Serial Number",
            selectedProduct.serialNumber,
            "serial-number"
          )}
        </Grid>
        <Grid className="vac-therapy-unit-grid-item" item xs={12}>
          {showTitleAndValue(
            "Location",
            selectedProduct.currentLocation,
            "location"
          )}
        </Grid>
      </Grid>
    </div>
  );
};
