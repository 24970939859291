import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  useMediaQuery,
} from "@mui/material";
import React, { useCallback, useContext, useEffect } from "react";
import { ReactComponent as RadioButtonIcon } from "../../../assets/radioButton.svg";
import { ReactComponent as SelectedRadioButtonIcon } from "../../../assets/selectedRadioButton.svg";
import {
  NewOrderContext,
  NewOrderContextType,
} from "../../../context/NewOrderContext";
import { CustomCheckBox } from "../../../core/checkBox/checkBox.component";
import { InputWithLabel } from "../../../core/inputWithLabel/inputWithLabel.component";
import { ValidationStatus } from "../../../core/interfaces/input.interface";
import { NewOrderValidator } from "../newOrder.validator";
import { MultipleActionsProps } from "../newOrderWoundInfoStepper/newOrderWoundInfo.interface";
import "./nutrition.css";
import { NutrionProps } from "./nutrition.interfaces";
import { nutriActionData } from "./nutritionAction.data";
import { NutritionReviewOrder } from "./reviewOrder/nutritionReviewOrder.component";

export const Nutrition = ({
  isReviewOrder = false,
  editButtonClicked,
  isOrderSummary = false,
  setWoundInfoData,
  woundInfoData,
  Validator = new NewOrderValidator(),
}: NutrionProps) => {
  const isMobileScreen = useMediaQuery("(max-width:920px)");
  const [showActions, setShowActions] = React.useState(false);
  const [validator] = React.useState<NewOrderValidator>(Validator!);
  const NewOrderObj = useContext<NewOrderContextType | null>(NewOrderContext);

  const validateAndSetData = (e: any) => {
    NewOrderObj?.setIsHandleChangeTriggered(true);
    let { value, name, required } = e.target;
    let isValid;
    let title: any;
    if (name === "nutritionActions") {
      const updatedData = woundInfoData.nutritionActions.value.map(
        (nd: MultipleActionsProps) =>
          nd.value === value
            ? { ...nd, selected: e.target.checked }
            : { ...nd, selected: nd.selected }
      );
      value = [...updatedData];
      required = true;
      isValid = validator.validateArray(updatedData, e.target.name);
      title = "Nutrition Action";
    } else {
      isValid = validator.validate(e.target.value, e.target.name);
      required = true;
      title = "Nutrition";
    }
    setWoundInfoData(
      Object.assign({}, woundInfoData, {
        [e.target.name]: {
          value: value,
          valid: isValid?.status,
          required: required,
          errorMessage: null,
          title: title,
          componentId: woundInfoData.nutritionActions.componentId,
          order: woundInfoData.nutritionActions.order,
        },
      })
    );
  };

  const toggleNutriAction = useCallback(() => {
    const showActionsValue =
      woundInfoData.nutriStatusCompromized.value === "Yes";
    setShowActions(showActionsValue);
    setWoundInfoData({
      ...woundInfoData,
      nutritionActions: {
        ...woundInfoData.nutritionActions,
        required: showActionsValue,
        valid: ValidationStatus.UNTOUCHED,
        value: showActionsValue
          ? woundInfoData.nutritionActions.value
          : nutriActionData,
      },
    });
  }, [woundInfoData.nutriStatusCompromized.value]);

  useEffect(() => {
    toggleNutriAction();
  }, [woundInfoData.nutriStatusCompromized.value]);

  return (
    <div className="nutrition-main-container">
      {!isReviewOrder && (
        <div className="nutrition-container">
          <div className="nutri-label" data-testid="nutri-label">
            Nutrition
          </div>
          <Grid
            className="nutrition-action-options-container"
            container
            spacing={2}
          >
            <Grid
              className="nutrition-action-options-item"
              item
              xs={isMobileScreen ? 12 : 6}
            >
              <InputWithLabel
                error={
                  woundInfoData.nutriStatusCompromized.valid ===
                  ValidationStatus.INVALID
                }
                id={woundInfoData.nutriStatusCompromized.componentId!}
                isRequired={woundInfoData.nutriStatusCompromized.required}
                isShowWarning={true}
                label="Is the patient’s nutritional status compromised"
                labelClassName="nutri-status-label"
                testId="nutri-status"
                warningMessage={
                  woundInfoData.nutriStatusCompromized.errorMessage
                }
              >
                <RadioGroup
                  name="nutriStatusCompromized"
                  classes={{ root: "radioRoot" }}
                  onChange={validateAndSetData}
                  value={woundInfoData.nutriStatusCompromized.value}
                >
                  <FormControlLabel
                    classes={{
                      root:
                        woundInfoData.nutriStatusCompromized.valid ===
                        ValidationStatus.INVALID
                          ? "optionRoot-error"
                          : woundInfoData.nutriStatusCompromized.value === "Yes"
                          ? "optionRoot-active"
                          : "optionRoot",
                    }}
                    componentsProps={{
                      typography: {
                        classes: {
                          root:
                            woundInfoData.nutriStatusCompromized.value === "Yes"
                              ? "optiontxtSelect"
                              : "optiontxt",
                        },
                      },
                    }}
                    value="Yes"
                    control={
                      <Radio
                        icon={<RadioButtonIcon />}
                        checkedIcon={<SelectedRadioButtonIcon />}
                      />
                    }
                    label="Yes"
                  />
                  <FormControlLabel
                    classes={{
                      root:
                        woundInfoData.nutriStatusCompromized.valid ===
                        ValidationStatus.INVALID
                          ? "optionRoot-error"
                          : woundInfoData.nutriStatusCompromized.value === "No"
                          ? "optionRoot-active"
                          : "optionRoot",
                    }}
                    componentsProps={{
                      typography: {
                        classes: {
                          root:
                            woundInfoData.nutriStatusCompromized.value === "No"
                              ? "optiontxtSelect"
                              : "optiontxt",
                        },
                      },
                    }}
                    value="No"
                    control={
                      <Radio
                        icon={<RadioButtonIcon />}
                        checkedIcon={<SelectedRadioButtonIcon />}
                      />
                    }
                    label="No"
                  />
                </RadioGroup>
              </InputWithLabel>
            </Grid>
          </Grid>
          {showActions && (
            <div className="nutrition-actions-container">
              <InputWithLabel
                error={
                  woundInfoData.nutritionActions.valid ===
                  ValidationStatus.INVALID
                }
                id={woundInfoData.nutritionActions.componentId!}
                isShowWarning={true}
                isRequired={woundInfoData.nutritionActions.required}
                label="Action taken to address nutritional status"
                labelClassName="nutri-status-label"
                testId="action-checkboxes"
                warningMessage={woundInfoData.nutritionActions.errorMessage}
                warning_icon_div_custom_class="custom-waring-icon-div"
              >
                <Grid container className="nutrition-action-options-container">
                  {woundInfoData.nutritionActions.value.map(
                    (act: MultipleActionsProps) => (
                      <Grid item xs={isMobileScreen ? 12 : 6}>
                        <CustomCheckBox
                          handleChange={validateAndSetData}
                          labelClassName="nutri-action-label"
                          value={act.value}
                          defaultValue={act.value}
                          checked={act.selected}
                          labelText={act.label}
                          key={act.value}
                          name="nutritionActions"
                        />
                      </Grid>
                    )
                  )}
                </Grid>
              </InputWithLabel>
            </div>
          )}
        </div>
      )}
      {isReviewOrder && (
        <NutritionReviewOrder
          editButtonClicked={editButtonClicked}
          isOrderSummary={isOrderSummary}
          woundInfoData={woundInfoData}
        />
      )}
    </div>
  );
};
