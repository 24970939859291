import "./ePrescription.css";
import ".././submitPrescription.css";
import {
  NewOrderContext,
  NewOrderContextType,
} from "../../../../context/NewOrderContext";
import React, { useContext, useEffect, useState } from "react";
import { INewOrder } from "../../newOrder.interface";
import { Button, Grid, InputBase, useMediaQuery } from "@mui/material";
import { NewOrderValidator } from "../../newOrder.validator";
import redimportant from "../../../../assets/redimportant.svg";
import { ReactComponent as CrossIcon } from "../../../../assets/cross.svg";
import { ValidationStatus } from "../../../../core/interfaces/input.interface";
import {
  getSiteUseId,
  getSiteUseIdFromNewOrderContext,
  makeCapitalEachWordInString,
} from "../../../../util/utilityFunctions";
import { MobileDisplayContext } from "../../../../context/MobileDisplayContext";
import { ReactComponent as WarningIcon } from "../../../../assets/WarningIndicator.svg";
import { InputWithLabel } from "../../../../core/inputWithLabel/inputWithLabel.component";
import { SearchPrescriberModal } from "../../prescriberInformation/searchPrescriber.enum";
import { CustomAutoComplete } from "../../../../core/autoCompleteDropdown/autoCompleteDropdown.component";
import { AuthContext, AuthContextType } from "../../../../context/AuthContext";
import { prescriberSearch } from "../../../../util/3meService";
import {
  InternalSignOnContext,
  InternalSignOnContextType,
} from "../../../../context/InternalSignOnContext";

interface IEPrescription {
  data: INewOrder;
  Validator?: NewOrderValidator;
  setData: Function;
}

export const EPrescription = ({
  data,
  Validator = new NewOrderValidator(),
  setData,
}: IEPrescription) => {
  const [validator] = React.useState<NewOrderValidator>(Validator!);
  const NewOrderObj = useContext<NewOrderContextType | null>(NewOrderContext);
  const isMobileScreen = useMediaQuery("(max-width:600px)");
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const internalObj = useContext<InternalSignOnContextType | null>(
    InternalSignOnContext
  );
  const [isEmailUpdatebuttonDisable, setIsEmailUpdatebuttonDisable] =
    useState(true);
  useEffect(() => {
    setData({
      ...data,
      updatedPrescriberEmail: {
        ...data.updatedPrescriberEmail,
        value:
          NewOrderObj?.prescriberAddedData?.email === undefined
            ? ""
            : NewOrderObj?.prescriberAddedData?.email,
        valid: ValidationStatus.VALID,
      },
    });
  }, [NewOrderObj?.prescriberAddedData?.email]);

  const handleEditEmailOnclick = () => {
    NewOrderObj?.setIsPrescriberUpdateEmail(true);
    const email = data.updatedPrescriberEmail.value;
    const isValid = validator.validate(email, "updatedPrescriberEmail");
    setData({
      ...data,
      updatedPrescriberEmail: {
        ...data.updatedPrescriberEmail,
        value: email,
        valid: email !== "" ? isValid!.status : ValidationStatus.UNTOUCHED,
      },
    });
  };

  const handleUpdateEmail = () => {
    NewOrderObj?.setIsPrescriberUpdateEmail(false);
    NewOrderObj?.setPrescriberAddedData({
      ...NewOrderObj?.prescriberAddedData!,
      email: data.updatedPrescriberEmail.value,
    });
    setIsEmailUpdatebuttonDisable(true);
  };

  const handlePrescriberSearchType = (section: SearchPrescriberModal) => {
    NewOrderObj?.setprescribeSearchAddPopUpSection(section);
  };

  const validateAndSetData = (e: any) => {
    const { value } = e.target;
    const isValid = validator.validate(value, e.target.name);
    setData({
      ...data,
      updatedPrescriberEmail: {
        ...data.updatedPrescriberEmail,
        valid: isValid!.status,
        value: value,
      },
    });
    isValid?.status === ValidationStatus.VALID
      ? setIsEmailUpdatebuttonDisable(false)
      : setIsEmailUpdatebuttonDisable(true);
  };

  const handleCancelClick = () => {
    NewOrderObj?.setIsPrescriberUpdateEmail(false);
    setIsEmailUpdatebuttonDisable(true);
    setData({
      ...data,
      updatedPrescriberEmail: {
        ...data.updatedPrescriberEmail,
        value:
          NewOrderObj?.prescriberAddedData?.email === undefined
            ? ""
            : NewOrderObj?.prescriberAddedData?.email,
        valid: ValidationStatus.VALID,
      },
    });
  };
  const validateAndSetEmailData = async (e: any, val: any) => {
    NewOrderObj?.setIsHandleChangeTriggered(true);
    const value = val;
    const isValid = validator.validate(value, "updatedPrescriberEmail");
    setData({
      ...data,
      updatedPrescriberEmail: {
        ...data.updatedPrescriberEmail,
        errorMessage: null,
        valid: isValid!.status,
        value: value,
      },
    });
    isValid?.status === ValidationStatus.VALID
      ? setIsEmailUpdatebuttonDisable(false)
      : setIsEmailUpdatebuttonDisable(true);
  };

  const getPrescriberEmailList = async () => {
    const siteUseId =
      internalObj && internalObj.isVACOrderSSO
        ? getSiteUseIdFromNewOrderContext(NewOrderObj)
        : getSiteUseId(authObj);
    let reqParams = {
      searchinput: NewOrderObj?.prescriberAddedData?.npi.toString(),
      siteUseId: siteUseId,
    };
    const prescriberEmailList = await prescriberSearch(reqParams);
    let emailList = [];
    if (prescriberEmailList?.data?.length > 0) {
      emailList =
        prescriberEmailList.data[0].emails.length > 0
          ? prescriberEmailList.data[0].emails.map(
              (email: { value: string }) => email.value
            )
          : [];
    } else {
      emailList = [];
    }
    NewOrderObj?.setPrescriberEmailList(emailList);
  };
  useEffect(() => {
    if (
      NewOrderObj?.prescriberAddedData?.npi !== "--" &&
      NewOrderObj?.prescriberAddedData?.npi !== "" &&
      NewOrderObj?.prescriberAddedData?.npi !== undefined
    ) {
      getPrescriberEmailList();
    }
  }, [NewOrderObj?.prescriberAddedData?.npi]);
  return (
    <div>
      {NewOrderObj?.isPrescriberAddedOpenInfo && (
        <div
          className={
            NewOrderObj?.prescriberList?.eScript === "No"
              ? "prescriberDivErrorinfo"
              : "prescriberNoErrorinfo"
          }
        >
          <Grid container>
            <Grid item xs={12}>
              {NewOrderObj?.isPrescriberAddedOpenInfo &&
                NewOrderObj?.prescriberList?.eScript == "No" && (
                  <>
                    <div
                      className="prescriberErrorDesc2"
                      id="submit-valid-prescription"
                    >
                      <div className="imgalert">
                        <img
                          src={redimportant}
                          alt={redimportant}
                          height={25}
                          width={25}
                        ></img>
                      </div>
                      <div
                        className="prescriberErrorDesc2Label"
                        data-testid="prescriberErrorDesc2LabelTest"
                      >
                        This prescriber does not accept E-Prescription requests.
                        Select another prescriber or choose a different method.
                      </div>
                    </div>
                  </>
                )}
            </Grid>
          </Grid>
          <Grid
            container
            className={
              NewOrderObj?.prescriberList?.eScript === "No"
                ? "prescriberNoErrorinfo"
                : isEmailUpdatebuttonDisable
                ? "prescriberInfo"
                : "prescriberUpdateInfo"
            }
          >
            <Grid
              item
              xs={isMobileScreen ? 12 : 4}
              className="prescriberNameGrid"
            >
              <div
                className={
                  NewOrderObj?.showPrescriberUpdateEmail
                    ? "prescriberUpdateInfoDiv"
                    : "prescriberInfoDiv"
                }
              >
                <div
                  className="prescriberInfoLabel"
                  data-testid="prescriberInfoLabelSelectedTest"
                >
                  Prescriber Name
                </div>
                <div className="prescriberInfoDesc">
                  {makeCapitalEachWordInString(
                    NewOrderObj?.prescriberAddedData?.firstName ?? ""
                  ) +
                    " " +
                    makeCapitalEachWordInString(
                      NewOrderObj?.prescriberAddedData?.lastName ?? ""
                    )}
                </div>
              </div>
            </Grid>
            {NewOrderObj?.prescriberList?.eScript !== "No" ? (
              NewOrderObj?.showPrescriberUpdateEmail ? (
                <Grid item xs={isMobileScreen ? 12 : 4}>
                  <InputWithLabel
                    error={
                      data.updatedPrescriberEmail.valid ===
                      ValidationStatus.INVALID
                    }
                    id={data.updatedPrescriberEmail.componentId}
                    isRequired={true}
                    label="Prescriber Email Address"
                  >
                    {NewOrderObj.prescriberEmailList.length <= 1 ? (
                      <InputBase
                        className="update-prescriberemail-input"
                        data-testid="update-prescriberemail-input-test"
                        id={data.updatedPrescriberEmail.id!}
                        name="updatedPrescriberEmail"
                        onChange={validateAndSetData}
                        value={data.updatedPrescriberEmail.value}
                      />
                    ) : (
                      <CustomAutoComplete
                        handleChange={(e: any, val: any) =>
                          validateAndSetEmailData(e, val)
                        }
                        id={data.updatedPrescriberEmail.id!}
                        menuItem={NewOrderObj.prescriberEmailList}
                        selectClassName={
                          data?.updatedPrescriberEmail.valid ===
                          ValidationStatus.INVALID
                            ? "prescriber-info-email-input patient-info-error"
                            : "prescriber-info-email-input"
                        }
                        selectpropsClassName="prescriber-info-email-root"
                        value={data.updatedPrescriberEmail.value}
                        isWordBreakEnable={true}
                      />
                    )}
                  </InputWithLabel>
                </Grid>
              ) : (
                <Grid item xs={isMobileScreen ? 12 : 4}>
                  <div className="prescriberInfoDiv">
                    <div
                      className="prescriberInfoLabel"
                      data-testid="prescriberInfoLabelSelectedEmailTest"
                    >
                      Prescriber Email Address
                    </div>
                    <div className="prescriberInfoDesc-email">
                      {data.updatedPrescriberEmail.value === "" ||
                      data.updatedPrescriberEmail.value === undefined
                        ? "--"
                        : data.updatedPrescriberEmail.value}
                    </div>
                  </div>
                </Grid>
              )
            ) : null}
            {NewOrderObj?.prescriberList?.eScript !== "No" &&
              (NewOrderObj?.showPrescriberUpdateEmail ? (
                <>
                  <Grid
                    item
                    xs={isMobileScreen ? 6 : 2}
                    className="prescriber-info-Selected-UpdateBtn"
                  >
                    <Button
                      classes={{ root: "button-update-email" }}
                      data-testid="button-update-email-test"
                      variant="outlined"
                      onClick={handleUpdateEmail}
                      disabled={isEmailUpdatebuttonDisable}
                    >
                      Update
                    </Button>
                  </Grid>
                  <Grid item xs={isMobileScreen ? 6 : 1}>
                    <CrossIcon
                      data-testid="crossIconTest"
                      onClick={handleCancelClick}
                      className="crossIcon"
                    />
                  </Grid>
                </>
              ) : (
                <Grid
                  item
                  xs={isMobileScreen ? 12 : 2}
                  className="prescriber-info-Selected-PresBtn"
                >
                  <Button
                    classes={{
                      root: "prescriber-info-email-addOrRemove-button",
                    }}
                    data-testid="prescriber-selected-addOrRemove-button-email-test"
                    onClick={handleEditEmailOnclick}
                  >
                    edit email
                  </Button>
                </Grid>
              ))}
            {NewOrderObj?.prescriberList?.eScript === "No" && (
              <Grid item xs={8} className="prescriber-info-Selected-PresBtn">
                <Button
                  classes={{
                    root: "prescriber-info-email-addOrRemove-button",
                  }}
                  data-testid="prescriber-selected-addOrRemove-button-email-test"
                  onClick={() => {
                    handlePrescriberSearchType(
                      SearchPrescriberModal.SEARCH_PRESCRIBER
                    );
                    NewOrderObj?.setSearchPrescriberPopup(true);
                  }}
                >
                  select a different prescriber
                </Button>
              </Grid>
            )}
          </Grid>
        </div>
      )}
      {NewOrderObj?.isPrescriberAddedOpenErrorInfo && (
        <Grid
          className={
            NewOrderObj?.prescriberList?.eScript === "No"
              ? "prescriberNoErrorinfo"
              : "prescriberErrorinfo"
          }
        >
          <Grid item xs={8}>
            <div className="prescriberErrorDesc">
              <div>
                <img src={redimportant} alt={redimportant}></img>
              </div>
              <div
                className="prescriberErrorDescLabel"
                data-testid="prescriberErrorDescLabelTest"
              >
                To use E-Prescription, you need to select a prescriber
              </div>
            </div>
          </Grid>
          <Grid item xs={4} className="prescriberSelectedChangePresBtn">
            <Button
              onClick={() => {
                NewOrderObj?.setprescribeSearchAddPopUpSection(
                  SearchPrescriberModal.SEARCH_PRESCRIBER
                );
                NewOrderObj?.setSearchPrescriberPopup(true);
              }}
              classes={{
                root: "prescriber-selected-addOrRemove-button",
              }}
              data-testid="prescriber-selected-addOrRemove-button-prescribersearch-test"
            >
              search for prescriber
            </Button>
          </Grid>
        </Grid>
      )}
      {NewOrderObj?.isPrescriberAddedOpenInfo
        ? data.updatedPrescriberEmail.valid === ValidationStatus.INVALID &&
          NewOrderObj?.prescriberList?.eScript !== "No" && (
            <div
              className="prescriberEmailError"
              data-testid="prescriberEmailError"
            >
              <span
                className="warning-icon"
                data-testid="submit-prescription-warning-icon"
              >
                <WarningIcon className="warning-icon-image" />
              </span>
              <p
                className="error-with-warning-icon"
                data-testid="submit-prescription-error-message"
              >
                Prescriber email address required
              </p>
            </div>
          )
        : null}
    </div>
  );
};
