import { useContext, useEffect, useState } from "react";
import "./submitProofOfDelivery.css";
import { Grid } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import { PatientDetails } from "../../pickUpAndDischargeRequest/pickUpRequest/patientDetails/patientDetails.component";
import { TopDivStyle, PerButtonStyle } from "../../cmsContent/cmsContent.style";
import { ReactComponent as VectorIcon } from "../../../assets/Vector.svg";
import { retrievePatientDetails } from "../../../util/pickUpOrDischargeService";
import moment from "moment";
import { LoadingSpinner } from "../../../core/loader/LoadingSpinner";
import {
  SubmitProofOfDeliveryContext,
  SubmitProofOfDeliveryContextType,
} from "../../../context/submitProofOfDeliveryContext";
import { IPatient } from "../patient.interface";
import { Popup } from "../../../core/popup/popup.component";
import { SendNoteFailure } from "../../send3MNote/popUps/failurePopUp/sendNoteFailure.component";
import { SuccessPopUp } from "../../administration/facilitySettings/popUps/successPopUp/successPopUp.component";
import { ProofOfDeliveryOptions } from "./proofOfDeliveryOptions/proofOfDeliveryOptions.component";
import { proofOfDeliveryESignValidator } from "./proofOfDeliveryESign/proofOfDeliveryESign.validator";
import {
  getUntouchedObj,
  makeCapitalEachWordInString,
} from "../../../util/utilityFunctions";
import { Validation } from "../../../core/interfaces/input.interface";
import { IProofOfDeliverESign } from "./proofOfDeliveryESign/proofOfDeliveryESign.interface";
import parse from "html-react-parser";
import ErrorPopup from "../../../core/errorPopup/errorPopup.component";
import { ERROR_MSG_PROOF_OF_DELIVERY } from "../../../util/errorMsg";

export const SubmitProofOfDelivery = () => {
  const history = useHistory();
  const submitPodObj = useContext<SubmitProofOfDeliveryContextType | null>(
    SubmitProofOfDeliveryContext
  );
  const location: any = useLocation();
  const patientStateData: any = location.state;
  const [patientData] = useState<IPatient>(patientStateData?.stateData);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [openSuccessPopUp, setOpenSuccessPopUp] = useState<boolean>(false);
  const [openFailurePopUp, setOpenFailurePopUp] = useState<boolean>(false);
  const [isUploadSuccess, setIsUploadSuccess] = useState(false);
  const [openLoaderPopUp, setOpenLoaderPopUp] = useState<boolean>(false);
  //errorPop
  const [errorPopupFlag, setErrorPopupFlag] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [errorCode, setErrorCode] = useState<string>("");

  const setOriginalData = submitPodObj?.setESignOriginalPatientDetails!;
  const data = submitPodObj?.eSignPatientDetails!;
  const setData = submitPodObj?.setESignPatientDetails!;

  const failureMessage =
    "Your request to upload the patient's POD/AOB has failed. Please try again or contact 3M for assistance with this order 1-800-275-4524.";

  const closeUploadDocButtonAction = (isSuccess: boolean) => {
    if (isSuccess) {
      setOpenSuccessPopUp(true);
    } else {
      setOpenFailurePopUp(true);
    }
    goBackToMyPatients();
  };

  const goBackToMyPatients = () => {
    history.push("/home");
  };

  const updatePatientDetails = (patientData: IPatient) => {
    const validator = new proofOfDeliveryESignValidator();
    const patientName = makeCapitalEachWordInString(
      `${patientData.firstName} ${patientData.lastName}`
    );
    const isPatientNameValid = validator.validate(patientName, "patientName");
    const updatedPatientEmail = patientData?.emailAddress?.toLowerCase();
    let isUpdatedEmailValid: Validation = getUntouchedObj();
    if (patientData.emailAddress && patientData.emailAddress !== "") {
      isUpdatedEmailValid = validator.validate(
        updatedPatientEmail ?? "",
        "updatedPatientEmail"
      )!;
    }
    const tempData: IProofOfDeliverESign = {
      ...data,
      patientName: {
        value: patientName,
        valid: isPatientNameValid!.status,
      },
      updatedPatientEmail: {
        value: updatedPatientEmail ?? "",
        valid: isUpdatedEmailValid!.status,
      },
    };
    setOriginalData(tempData);
    setData(tempData);
  };

  useEffect(() => {
    if (submitPodObj && submitPodObj.isPODLoaded === null) {
      goBackToMyPatients();
    }
  }, []);

  useEffect(() => {
    if (isLoading === false && isUploadSuccess === true) {
      setOpenSuccessPopUp(true);
    } else {
      setOpenFailurePopUp(true);
    }
  }, [isLoading, isUploadSuccess]);

  useEffect(() => {
    loadPatientProductInfo();
    window.scroll(0, 0);
  }, []);

  const loadPatientProductInfo = async () => {
    let reqParams = {
      RentalOrderNumber: patientData?.roNumber,
      DOB: patientData?.dob
        ? moment(patientData?.dob).format("yyyy-MM-DD")
        : null,
      isAcuteOrder: patientData?.isAcuteOrder ?? false,
    };
    try {
      const data = await retrievePatientDetails(reqParams);
      setIsLoading(false);
      if (data.succeeded) {
        const updatedPatient = {
          ...patientData,
          productName: data.item?.productName,
          placementDate: data.item?.placemetDate,
          productSerialNumber: data.item?.productSerialNumber,
          emailAddress: data.item?.patientEmailAddress,
        };
        updatePatientDetails(updatedPatient);
        submitPodObj?.setPatient(updatedPatient);
      } else {
        setError(true);
        setErrorPopupFlag(true);
        setErrorCode(data.error.errorCode);
        setErrorMessage(ERROR_MSG_PROOF_OF_DELIVERY);
      }
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
      setError(true);
    }
  };

  const options = [
    {
      optionTitle: "Electronic signature (E-Sign)",
      optionDescription: parse(
        "A DocuSign<sup>®</sup> email will be sent requesting an electronically signed confirmation"
      ) as string,
    },
    {
      optionTitle: "Document Upload",
      optionDescription:
        "Print, sign and upload Proof of Delivery and Acceptance of Benefits form.",
    },
    {
      optionTitle: "Fax to 3M Medical Solutions",
      optionDescription:
        "Print, sign and upload Proof of Delivery and Acceptance of Benefits form.",
    },
  ];

  const handleClosePopup = () => {
    setErrorPopupFlag(false);
    history.push("/home");
  };

  return (
    <div className="proofOfDelivery-main" data-testid="proofOfDelivery-main">
      {isLoading && (
        <div className="submit-pod-loader">
          <LoadingSpinner />
        </div>
      )}
      {!isLoading && !error && submitPodObj && submitPodObj.patient && (
        <>
          <Grid container className="proofOfDelivery-container-main">
            <Grid item className="pod-heading" data-testid="pod-heading">
              Complete and Submit: Proof of Delivery and Acceptance of Benefits
            </Grid>
            <Grid item className="patient-details">
              {submitPodObj.patient && (
                <PatientDetails
                  isAcuteOrderFlow={patientData!.isAcuteOrder ?? false}
                  patient={submitPodObj.patient}
                />
              )}
            </Grid>
            <Grid item className="pod-alert-text" data-testid="pod-alert-text">
              <p className="paragraph">
                Proof of Delivery and Acceptance of Benefits form must be
                completed with the patient signature and date and returned to 3M
                prior to or on the same day as product delivery at bedside.
              </p>
            </Grid>

            <Grid item className="pod-subTitle" data-testid="pod-subTitle">
              <p className="subtitle">
                How would you like to provide the Proof of Delivery & Acceptance
                of Benefits to 3M?
              </p>
            </Grid>
            <Grid item xs={12}>
              <ProofOfDeliveryOptions
                options={options}
                setIsUploadSuccess={setIsUploadSuccess}
                setIsLoading={setIsLoading}
                setOpenLoaderPopUp={setOpenLoaderPopUp}
              />
            </Grid>
            <Grid item className="pod-paragragh" data-testid="pod-paragragh">
              <p className="pod-paragraph-mobile">
                Please remember that
                <span className="paragraph-bold">
                  failure to complete the Proof of Delivery and Acceptance of
                  Benefits form prior to placement
                </span>{" "}
                will cause patient to
                <span className="paragraph-bold">
                  not be covered for therapy by insurance
                </span>
                due to non-compliance with payor guidelines, including CMS.
              </p>
              <p className="pod-paragraph-response">
                Authorized agents include: Family Member, Designated Friend,
                Legal Guardian, Attending Nurse or other Caretaker/
                <span className="caretaker-space">Representative</span> of
                institution providing care (excluding prescribing physician)
              </p>
            </Grid>
            <Grid item xs={12}>
              <TopDivStyle>
                <PerButtonStyle
                  data-testid="previousButton-test"
                  startIcon={<VectorIcon />}
                  className="backToMyPatients-btn"
                  variant="text"
                  onClick={() => {
                    history.push("/home");
                  }}
                >
                  Back to My Patients
                </PerButtonStyle>
              </TopDivStyle>
            </Grid>
          </Grid>
          {!isLoading && isUploadSuccess && (
            <>
              <Popup
                closeHandler={() => closeUploadDocButtonAction(true)}
                dialogParentClass={"pod-success-pop-up"}
                openFlag={openSuccessPopUp}
              >
                <div className="pod-success-pop-up-div">
                  <SuccessPopUp
                    buttonAction={goBackToMyPatients}
                    buttonTitle="Done"
                    description="The POHD/AOB has been uploaded & will be reviewed."
                    title="Upload complete"
                  />
                </div>
              </Popup>
              <Popup
                closeHandler={() => closeUploadDocButtonAction(true)}
                dialogParentClass={"pod-failure-pop-up"}
                openFlag={openFailurePopUp}
              >
                <div className="pod-failure-pop-up-div">
                  <SendNoteFailure
                    rootClass="pod-failure-pop-up"
                    backButtonAction={goBackToMyPatients}
                    message={failureMessage}
                  />
                </div>
              </Popup>
            </>
          )}
          <Popup
            openFlag={openLoaderPopUp}
            closeHandler={() => setOpenLoaderPopUp(false)}
            dialogParentClass={"pod-loader-pop-up"}
            data-testid="loader-pop-up"
            hideCloseButton={true}
          >
            <div className="pod-loader">
              <LoadingSpinner />
            </div>
          </Popup>
        </>
      )}
      <ErrorPopup
        errorPopupFlag={errorPopupFlag}
        handleBackButton={handleClosePopup}
        popUpStyles="errorPopup"
        errorMessage={errorMessage}
        buttontitle="Done"
        showTitle={false}
        isShortSentence={true}
        errorCode={errorCode}
      />
    </div>
  );
};
