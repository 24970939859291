import {
  IInputField,
  Validation,
  ValidationStatus,
} from "../../../core/interfaces/input.interface";
import { getDeepClone } from "../../../util/ObjectFunctions";
import { Validator } from "../../../util/order.validations";
import {
  getValidObj,
  updateErrorMessage,
} from "../../../util/utilityFunctions";
import { ICreateServiceRequest } from "./createServiceRequest.interface";

export class CreateServiceRequestValidator {
  private _validator;
  constructor(defValidator = new Validator()) {
    this._validator = defValidator;
  }
  private noValidation(): Validation {
    return getValidObj();
  }

  private fieldToMethodMapping(
    field: string
  ): ((txt: string) => Validation) | undefined {
    const mapping = new Map<string, (txt: string) => Validation>([
      ["specialInstrutions", this._validator.genericInputTextValidation],
      ["isCallOnEstimatedArrivalTime", this._validator.emptyCheck],
      ["firstName", this._validator.requesterNameValidation],
      ["lastName", this._validator.requesterNameValidation],
      ["department", this._validator.departmentValidationForMandatory],
      ["email", this._validator.mandatoryEmailValidation],
      ["phoneNumber", this._validator.phoneValidation],
      ["extension", this._validator.extensionValidation],
      ["injuryCauseBy3MDevice", this._validator.emptyCheck],
      ["problemWith3MDevice", this._validator.emptyCheck],
      ["describeTheProblem", this._validator.genericInputTextValidation],
      [
        "deliveryInstructions",
        this._validator.optionalFieldValidSpecialCharacters,
      ],
      ["pickupLocation", this._validator.emptyCheck],
      [
        "otherPickupLocation",
        this._validator.optionalFieldValidSpecialCharacters,
      ],
    ]);
    const validator = mapping.get(field);
    return validator ? validator : this.noValidation;
  }

  public validate(input: string, field: string) {
    try {
      const validator = this.fieldToMethodMapping(field)!;
      return validator(input);
    } catch (error) {
      console.log(`validator method for field ${field} is not configured`);
    }
  }

  public validateAll(
    data: ICreateServiceRequest,
    updateDataIfUntouchedAndValidated: Function
  ): [ValidationStatus, IInputField | null] {
    let tempData = getDeepClone(data);
    let smallestOrderWithInvalidStatus: IInputField | null = null;
    Object.keys(tempData).forEach((key: string) => {
      if (key === "serviceOptions") {
        const serviceOptions = tempData[key];
        const updatedServiceOptions = serviceOptions.map(
          (option: IInputField) => {
            if (option.valid === ValidationStatus.UNTOUCHED) {
              option.valid = ValidationStatus.INVALID;
            }
            if (option.valid === ValidationStatus.INVALID) {
              // Find smallest order number which has invalid status
              if (option.order) {
                if (
                  !smallestOrderWithInvalidStatus ||
                  (smallestOrderWithInvalidStatus.order &&
                    option.order &&
                    smallestOrderWithInvalidStatus.order > option.order)
                ) {
                  smallestOrderWithInvalidStatus = option;
                }
              }
            }
            updateErrorMessage(option);
            return option;
          }
        );
        tempData[key] = [...updatedServiceOptions];
      } else {
        if (
          tempData[key].isOptional === true &&
          tempData[key].valid !== ValidationStatus.VALID
        ) {
          tempData[key].valid = ValidationStatus.VALID;
        } else if (
          tempData[key].required &&
          tempData[key].valid === ValidationStatus.UNTOUCHED
        ) {
          tempData[key].valid = ValidationStatus.INVALID;
        }
        // Find smallest order number which has invalid status
        if (
          tempData[key].valid === ValidationStatus.INVALID &&
          tempData[key].order
        ) {
          if (
            !smallestOrderWithInvalidStatus ||
            (smallestOrderWithInvalidStatus.order &&
              tempData[key].order &&
              smallestOrderWithInvalidStatus.order > tempData[key].order)
          ) {
            smallestOrderWithInvalidStatus = tempData[key];
          }
        }
        updateErrorMessage(tempData[key]);
      }
    });
    updateDataIfUntouchedAndValidated(tempData);
    const serviceOptions = tempData["serviceOptions"];
    let ifAllValid = serviceOptions.every(
      (option: IInputField) => option.valid === ValidationStatus.VALID
    );
    // If ifAllValid is true
    // then only we can check for other required fileds
    // else we will return as false
    if (ifAllValid) {
      ifAllValid = Object.keys(tempData)
        .filter(
          (key: string) =>
            key !== "serviceOptions" &&
            (tempData[key].isDefaultValid || tempData[key].required)
        )
        .every((key: string) => tempData[key].valid === ValidationStatus.VALID);
    }
    return [
      ifAllValid ? ValidationStatus.VALID : ValidationStatus.INVALID,
      smallestOrderWithInvalidStatus,
    ];
  }

  public validatePartialFields(
    data: ICreateServiceRequest,
    updateDataIfUntouchedAndValidated: Function
  ): [ValidationStatus, IInputField | null] {
    let tempData = getDeepClone(data);
    let smallestOrderWithInvalidStatus: IInputField | null = null;
    Object.keys(tempData).forEach((key: string) => {
      if (key === "serviceOptions") {
        const serviceOptions = tempData[key];
        const updatedServiceOptions = serviceOptions.map(
          (option: IInputField) => {
            if (option.valid === ValidationStatus.UNTOUCHED) {
              option.valid = ValidationStatus.INVALID;
            }
            if (option.valid === ValidationStatus.INVALID) {
              // Find smallest order number which has invalid status
              if (option.order) {
                if (
                  !smallestOrderWithInvalidStatus ||
                  (smallestOrderWithInvalidStatus.order &&
                    option.order &&
                    smallestOrderWithInvalidStatus.order > option.order)
                ) {
                  smallestOrderWithInvalidStatus = option;
                }
              }
            }
            updateErrorMessage(option);
            return option;
          }
        );
        tempData[key] = [...updatedServiceOptions];
      } else if (key === "specialInstrutions") {
        if (
          tempData[key].isOptional === true &&
          tempData[key].valid !== ValidationStatus.VALID
        ) {
          tempData[key].valid = ValidationStatus.VALID;
        } else if (
          tempData[key].required &&
          tempData[key].valid === ValidationStatus.UNTOUCHED
        ) {
          tempData[key].valid = ValidationStatus.INVALID;
        }
        // Find smallest order number which has invalid status
        if (
          tempData[key].valid === ValidationStatus.INVALID &&
          tempData[key].order
        ) {
          if (
            !smallestOrderWithInvalidStatus ||
            (smallestOrderWithInvalidStatus.order &&
              tempData[key].order &&
              smallestOrderWithInvalidStatus.order > tempData[key].order)
          ) {
            smallestOrderWithInvalidStatus = tempData[key];
          }
        }
        updateErrorMessage(tempData[key]);
      }
    });
    updateDataIfUntouchedAndValidated(tempData);
    const serviceOptions = tempData["serviceOptions"];
    const ifServiceOptionsValid = serviceOptions.every(
      (option: IInputField) => option.valid === ValidationStatus.VALID
    );
    const ifSpecialInstrutionsvalid =
      tempData["specialInstrutions"].valid === ValidationStatus.VALID;
    return [
      ifServiceOptionsValid && ifSpecialInstrutionsvalid
        ? ValidationStatus.VALID
        : ValidationStatus.INVALID,
      smallestOrderWithInvalidStatus,
    ];
  }
}
