import {
  ISSOSideNav,
  SSOSideNavPageType,
} from "./ssoSideNavMenuOption.interface";

export const ssoSideNavMenuOptionModel: ISSOSideNav = {
  searchforhomecare: {
    labelText: "Search for Home Care Order",
    isVisible: true,
    pageType: SSOSideNavPageType.POPUP,
    path: "searchforhomecare",
  },
  orderVACTherapy: {
    labelText: "Order V.A.C.® Therapy",
    isVisible: true,
    pageType: SSOSideNavPageType.PATH,
    path: "orders/newOrder",
  },
  viewReports: {
    labelText: "View Reports",
    isVisible: true,
    pageType: SSOSideNavPageType.PATH,
    path: "viewReports",
  },
  internalAdministration: {
    labelText: "Administration",
    isVisible: true,
    pageType: SSOSideNavPageType.PATH,
    path: "internalAdministration",
  },
};
