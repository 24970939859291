import { createContext, useState } from "react";
import { IAddSiteStatus } from "../components/administration/manageSiteStatuses/addSiteStatus/addSiteStatus.interface";
import { getDeepClone } from "../util/ObjectFunctions";
import { defaultAddSiteStatusData } from "../components/administration/manageSiteStatuses/addSiteStatus/addSiteStatus.model";
import { MessageTypeContent } from "../components/administration/manageSiteStatuses/addSiteStatus/siteStatusContent/messageType.enum";

export type AddSiteStatusContextType = {
  siteStatusDetails: IAddSiteStatus;
  setSiteStatusDetails: React.Dispatch<React.SetStateAction<IAddSiteStatus>>;
  isAddButtonClick: boolean;
  setIsAddButtonClick: React.Dispatch<React.SetStateAction<boolean>>;
  resetSiteStatusDetails: () => void;
};

type AddSiteStatusContextProviderProps = {
  children: React.ReactNode;
};

export const AddSiteStatusContext =
  createContext<AddSiteStatusContextType | null>(null);

export const AddSiteStatusContextProvider = ({
  children,
}: AddSiteStatusContextProviderProps) => {
  const [siteStatusDetails, setSiteStatusDetails] = useState<IAddSiteStatus>(
    getDeepClone(defaultAddSiteStatusData)
  );

  const [isAddButtonClick, setIsAddButtonClick] = useState<boolean>(true);

  const resetSiteStatusDetails = () => {
    setSiteStatusDetails(getDeepClone(defaultAddSiteStatusData));
    setIsAddButtonClick(true);
  };
  return (
    <AddSiteStatusContext.Provider
      value={{
        siteStatusDetails,
        setSiteStatusDetails,
        resetSiteStatusDetails,
        isAddButtonClick,
        setIsAddButtonClick,
      }}
    >
      {children}
    </AddSiteStatusContext.Provider>
  );
};
