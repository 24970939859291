export interface IMyPatientOrderFilter {
  isOrderFilterCardShown: boolean;
  isAlertFilterEnabled: boolean;
  selectedFilters: string[];
  orderTypeFilters: string[];
}

export enum FilterType {
  statusType = "STATUS_TYPE",
  orderType = "ORDER_TYPE",
}
