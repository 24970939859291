import "./autoCompleteDropdown.css";
import { ReactComponent as SelectIcon } from "../../assets/Accinfo.svg";
import { Autocomplete, TextField, createFilterOptions } from "@mui/material";

type Props = {
  disabled?: boolean;
  disablePortal?: boolean;
  handleChange: any; // onchange function
  id?: string; //dropdown element id
  menuItem?: Array<string>; // dropdown list in array
  name?: string; //dropdown name element
  placeHolder?: string;
  selectClassName?: string; // dropdown classname for style
  selectpropsClassName?: string; // dropdown input props style element classname
  testId?: string; // testid for easily trageting while writing unittestcases
  value: any; // dropdown selected value
  isWordBreakEnable?: boolean;
};

export const CustomAutoComplete = ({
  disabled = false,
  disablePortal = false,
  id,
  menuItem,
  name,
  handleChange,
  placeHolder,
  selectClassName = "autoCompleteMain",
  selectpropsClassName = "autoCompleteRoot",
  testId,
  value,
  isWordBreakEnable = false,
}: Props) => {
  const filterOptions = createFilterOptions({
    matchFrom: "start",
    ignoreCase: true,
    stringify: (option: string) => option,
  });

  return (
    <Autocomplete
      autoHighlight
      autoSelect
      classes={{
        input: selectpropsClassName,
        inputRoot: selectClassName,
      }}
      data-testid={testId}
      disableClearable
      disabled={disabled}
      disablePortal={disablePortal}
      filterOptions={filterOptions}
      fullWidth
      id={id ?? ""}
      onChange={(e: any, val: any) => handleChange(e, val)}
      onInputChange={(e: any, val: any) => handleChange(e, val)}
      options={menuItem!}
      popupIcon={<SelectIcon />}
      renderInput={(params) => (
        <TextField {...params} placeholder={placeHolder} />
      )}
      value={value}
      ListboxProps={{
        sx: { wordBreak: isWordBreakEnable ? "break-all" : "normal" },
      }}
    />
  );
};

export interface DropDownValue {
  code: string;
  text: string;
}
