import { Typography, Grid, TextField } from "@mui/material";
import React, { useContext } from "react";
import moment from "moment";
import "./supplyOrderDeliveryInformation.css";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { InputWithLabel } from "../../../../core/inputWithLabel/inputWithLabel.component";
import { ReactComponent as CalendarIcon } from "../../../../assets/calendar.svg";
import { CustomDropDown } from "../../../../core/customDropdown/customDropdown.component";
import {
  IMobileDisplayContext,
  MobileDisplayContext,
} from "../../../../context/MobileDisplayContext";
import { ValidationStatus } from "../../../../core/interfaces/input.interface";
import { IInpatientSupplyOrder } from "../../inpatientSupplyOrder.interface";
import { InpatientSupplyOrderValidator } from "../../inpatientSupplyOrder.validator";
import { convertStringToDate } from "../../../../util/utilityFunctions";

interface ISupplyOrderDeliveryInformation {
  data: IInpatientSupplyOrder;
  setData: Function;
  maxDate: string;
  validator?: InpatientSupplyOrderValidator;
  deliveryMethodTypes: string[];
}

const SupplyOrderDeliveryInformation: React.FC<
  ISupplyOrderDeliveryInformation
> = ({
  data,
  setData,
  maxDate,
  validator = new InpatientSupplyOrderValidator(),
  deliveryMethodTypes,
}) => {
  const { isMobileScreen } =
    useContext<IMobileDisplayContext>(MobileDisplayContext);

  const validateAndSetDate = (date: string | null | undefined) => {
    const formattedDate = date === null ? "" : convertStringToDate(date);
    const isValid = validator.validate(formattedDate, "needByDate");
    const isDateAfterMaxDate = moment(date).isAfter(maxDate);
    let tempData = {
      ...data,
      deliveryInformation: {
        ...data.deliveryInformation,
        needByDate: {
          ...data.deliveryInformation.needByDate,
          value: formattedDate,
          valid: isDateAfterMaxDate
            ? ValidationStatus.INVALID
            : isValid!.status,
        },
        deliveryMethod: {
          ...data.deliveryInformation.deliveryMethod,
          value: "",
          valid: ValidationStatus.UNTOUCHED,
        },
      },
    };
    setData(tempData);
  };

  const validateAndSetData = (e: any) => {
    const { name, value } = e.target;
    const isValid = validator.validate(value, name);
    let tempData = {
      ...data,
      deliveryInformation: {
        ...data.deliveryInformation,
        [name]: {
          ...Object(data.deliveryInformation)[name],
          value: value,
          valid: isValid!.status,
        },
      },
    };
    setData(tempData);
  };

  return (
    <div className="inpatient-supply-order-delivery-info-main-section">
      <Grid container className="inpatient-supply-order-delivery-info-main-div">
        <Typography
          data-testid="inpatient-supply-order-delivery-info-card-header"
          className="inpatient-supply-order-delivery-info-card-header"
        >
          Delivery Information
        </Typography>
        <Grid xs={12}>
          <Typography
            className="inpatient-supply-order-delivery-info-card-sub-header"
            data-testid="inpatient-supply-order-delivery-info-card-sub-header"
          >
            Note: Estimated Arrival Date is based on the shipping method
            selected, your delivery location, and the date/time of your order.
            <br /> Same day requests or requests for delivery from the local
            Service Center should be called in to the National Contact Center at
            1-800-275-4524. It is not guaranteed that these types of deliveries
            are available in your area.
          </Typography>
        </Grid>
        <div className="inpatient-supply-order-input-divs">
          <Grid xs={isMobileScreen ? 12 : 5}>
            <InputWithLabel
              error={
                data.deliveryInformation.needByDate.valid ===
                ValidationStatus.INVALID
              }
              id={data.deliveryInformation.needByDate.id!}
              isRequired={data.deliveryInformation.needByDate.required}
              isShowWarning={data.deliveryInformation.needByDate.required}
              label={data.deliveryInformation.needByDate.title}
              testId={data.deliveryInformation.needByDate.componentId!}
              warningMessage={data.deliveryInformation.needByDate.errorMessage}
            >
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  components={{ OpenPickerIcon: CalendarIcon }}
                  InputAdornmentProps={{
                    classes: {
                      root: "adornedRoot",
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: `dateOfAccident ${
                        data.deliveryInformation.needByDate.valid ===
                        ValidationStatus.INVALID
                          ? "showError"
                          : "noError"
                      }`,
                      input: "input",
                      notchedOutline: "outline",
                    },
                  }}
                  onChange={(value: any) => validateAndSetDate(value)}
                  renderInput={(params: any) => {
                    params.error = false;
                    params.inputProps!.placeholder = "__/__/____";
                    return (
                      <TextField
                        id={"id"}
                        name="deliveryProductNeedByDate"
                        {...params}
                      />
                    );
                  }}
                  value={data.deliveryInformation.needByDate.value}
                  maxDate={maxDate}
                  minDate={moment(new Date())
                    .add(1, "days")
                    .format("MM/DD/YYYY")}
                />
              </LocalizationProvider>
            </InputWithLabel>
          </Grid>
          <Grid xs={12}>
            <Typography
              className="inpatient-supply-order-delivery-info-card-sub-header"
              data-testid="inpatient-supply-order-delivery-info-card-date-sub-header"
            >
              Note: Saturday delivery is only available if the Need By Date
              falls on a Saturday
            </Typography>
          </Grid>
        </div>
        <div className="inpatient-supply-order-input-divs">
          <Grid
            xs={isMobileScreen ? 12 : 6}
            className="inpatient-supply-order-delivery-info-methods-drop-down"
          >
            <InputWithLabel
              error={
                data.deliveryInformation.deliveryMethod.valid ===
                ValidationStatus.INVALID
              }
              id={data.deliveryInformation.deliveryMethod.id!}
              isRequired={data.deliveryInformation.deliveryMethod.required}
              isShowWarning={data.deliveryInformation.deliveryMethod.required}
              isDropdown={true}
              label={data.deliveryInformation.deliveryMethod.title}
              testId={data.deliveryInformation.deliveryMethod.componentId!}
              warningMessage={
                data.deliveryInformation.deliveryMethod.errorMessage
              }
            >
              <CustomDropDown
                handleChange={validateAndSetData}
                menuItem={deliveryMethodTypes}
                name="deliveryMethod"
                placeHolder="Select Delivery Method"
                selectClassName={
                  data.deliveryInformation.deliveryMethod.value !== ""
                    ? "status-input"
                    : "placeHolder"
                }
                testId="in-patient-supply-order-delivery-info-method-types"
                value={data.deliveryInformation.deliveryMethod.value}
              />
            </InputWithLabel>
          </Grid>
          <Grid xs={12}>
            <Typography
              className="inpatient-supply-order-delivery-info-card-sub-header"
              data-testid="inpatient-supply-order-delivery-info-card-delivery-method-sub-header"
            >
              Note: The delivery method list includes the delivery method with
              the estimated arrival date (EAD)
            </Typography>
          </Grid>
        </div>
      </Grid>
    </div>
  );
};

export default SupplyOrderDeliveryInformation;
