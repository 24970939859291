import "./selectedSuppliesReview.css";
import SelectedSupplies from "../selectedSupplies.component";
import { InpatientSupplyOrderPageSection } from "../../../inpatientSupplyOrder.enum";
import { CartState } from "../../cart/cart.reducer";
import { InpatientOrderPageSection } from "../../../../inpatientOrder/inpatientOrder.enum";

interface Props {
  editButtonAction?: any;
  isSummaryOrDetail?: boolean;
  isOrderDetails?: boolean;
  cartItemsData: CartState;
  isFromInpatientOrderFlow?: boolean;
}

export const SelectedSuppliesReview = ({
  editButtonAction,
  isSummaryOrDetail = false,
  isOrderDetails = false,
  cartItemsData,
  isFromInpatientOrderFlow = false,
}: Props) => {
  return (
    <div
      className="selected-supplies-review-component"
      data-testid="selected-supplies-review-component"
      id="id_selected-supplies-review-component"
    >
      <div
        className="selected-supplies-review-header"
        id="id_selected-supplies-review-header"
      >
        <p
          className="selected-supplies-review-title"
          data-testid="selected-supplies-review-title"
          id="selected-supplies-review-title-id"
        >
          {isOrderDetails ? "Supplies Ordered" : "Selected Supplies"}
        </p>
        {!isSummaryOrDetail && (
          <button
            className="selected-supplies-review-edit-button"
            data-testid="selected-supplies-review-edit-button"
            id="selected-supplies-review-edit-button-id"
            onClick={() => {
              editButtonAction(
                "in-patient-supply-order-first-page-component",
                isFromInpatientOrderFlow
                  ? InpatientOrderPageSection.SUPPLY_PAGE
                  : InpatientSupplyOrderPageSection.FIRST_PAGE
              );
            }}
          >
            Edit
          </button>
        )}
      </div>
      <SelectedSupplies
        isComingFromReview={true}
        cartItemsData={cartItemsData}
        isFromInpatientOrderFlow={isFromInpatientOrderFlow}
      />
    </div>
  );
};
