import { MultipleActionsProps } from "../../../../confirmPlacement/confirmPlacement.interface";

export interface MultipleActionCheckBoxProps extends MultipleActionsProps {
  parentId: string;
  childId?: string;
}

export const siteStatusLocationData: MultipleActionCheckBoxProps[] = [
  {
    id: "Login",
    value: "Login Page",
    label: "Login Page",
    selected: false,
    disabled: false,
    parentId: "1",
  },
  {
    id: "CLR1",
    value: "Cannot be cleared-LP",
    label: "Cannot be cleared",
    selected: false,
    disabled: true,
    parentId: "1",
    childId: "1",
  },
  {
    id: "All",
    value: "Full Site",
    label: "Full Site",
    selected: false,
    disabled: false,
    parentId: "2",
  },
  {
    id: "CLR2",
    value: "Cannot be cleared-FS",
    label: "Cannot be cleared",
    selected: false,
    disabled: true,
    parentId: "2",
    childId: "2",
  },
];
