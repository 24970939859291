import { CustomHeaderProps } from "ag-grid-react";
import { useEffect, useState } from "react";
import { ReactComponent as DefaultIcon } from "../../../../assets/default.svg";
import { ReactComponent as UpArrowIcon } from "../../../../assets/up_arrow.svg";
import { ReactComponent as DownArrow } from "../../../../assets/down_arrow.svg";
import { Tooltip, useMediaQuery } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import "./customHeader.css";

export const CustomHeader = (props: CustomHeaderProps) => {
  const [ascSortIcon, setAscSortIcon] = useState("inactive");
  const [descSortIcon, setDescSortIcon] = useState("inactive");
  const [noSort, setNoSort] = useState("inactive");
  const [count, setCount] = useState(0);
  const isMobileScreen = useMediaQuery("(max-width:600px)");

  const onSortChanged = () => {
    setAscSortIcon(props.column.isSortAscending() ? "active" : "inactive");
    setDescSortIcon(props.column.isSortDescending() ? "active" : "inactive");
    setNoSort(
      !props.column.isSortAscending() && !props.column.isSortDescending()
        ? "active"
        : "inactive"
    );
  };

  const onSortRequested = (order: "asc" | "desc" | null, event: any) => {
    setCount(count + 1);
    props.setSort(order, event.shiftKey);
  };

  useEffect(() => {
    props.column.addEventListener("sortChanged", onSortChanged);
    onSortChanged();
  }, []);

  useEffect(() => {
    if (count === 3) {
      props.setSort(null);
      setCount(0);
    }
  }, [count]);

  let sort = null;
  if (props.enableSorting) {
    sort = (
      <div className="inventory-sp-sort-div">
        <UpArrowIcon
          onClick={(event) =>
            isMobileScreen ? null : onSortRequested("desc", event)
          }
          onTouchEnd={(event) => onSortRequested("desc", event)}
          className={`customSortDownLabel ${ascSortIcon}`}
        />
        <DownArrow
          onClick={(event) =>
            isMobileScreen ? null : onSortRequested("asc", event)
          }
          onTouchEnd={(event) => onSortRequested("asc", event)}
          className={`customSortUpLabel ${descSortIcon}`}
        />
        <DefaultIcon
          onClick={(event) =>
            isMobileScreen ? null : onSortRequested("asc", event)
          }
          onTouchEnd={(event) => onSortRequested("asc", event)}
          className={`customSortRemoveLabel ${noSort}`}
        />
      </div>
    );
  }

  return (
    <div className="inventory-custom-header-div">
      <div className="inventory-sp-customHeaderLabel">{props.displayName}</div>
      <Tooltip
        classes={{
          popper: "inventory-sp-popper",
          tooltip: "inventory-sp-tooltip",
        }}
        title={
          <div className="inventory-sp-div">
            <div className="tooltip-content">Status Key</div>
            <div className="inventory-tooltip-header">
              <p className="tooltip-heading">
                {`Available - `}
                <span className="tooltip-heading-data">
                  Unit is eligible for placement.
                </span>
              </p>
            </div>
            <div className="inventory-tooltip-header">
              <p className="tooltip-heading">
                {`QC Pending - `}
                <span className="tooltip-heading-data">
                  A Quality Control check for the unit is almost due. It is
                  recommended the unit not be placed on a patient unless no
                  other units are available.
                </span>
              </p>
            </div>
            <div className="inventory-tooltip-header">
              <p className="tooltip-heading">
                {`QC Due - `}
                <span className="tooltip-heading-data">
                  A Quality Control check for the unit is due. It is recommended
                  the unit not be placed on a patient unless no other units are
                  available.
                </span>
              </p>
            </div>
            <div className="inventory-tooltip-header">
              <p className="tooltip-heading">
                {`Reported Broken - `}
                <span className="tooltip-heading-data">
                  A user at your facility has reported the unit is broken and
                  requested the unit be picked up. The unit is not eligible for
                  placement.
                </span>
              </p>
            </div>
          </div>
        }
      >
        <InfoOutlinedIcon
          color="disabled"
          classes={{ root: "inventory-tooltipRoot" }}
        />
      </Tooltip>
      {sort}
    </div>
  );
};
