import { useContext } from "react";
import { useHistory } from "react-router-dom";
import {
  ConfirmPlacementContext,
  ConfirmPlacementContextType,
} from "../../../../context/ConfirmPlacementContext";
import { AlertDetails } from "../alertDetails/alertDetails.component";
import "./confirmPlacement.css";
import { IConfirmPlacementInterface } from "./confirmPlacement.interface";
import parse from "html-react-parser";

export const ConfirmPlacement = ({
  closePopUpAction,
  patient,
}: IConfirmPlacementInterface) => {
  const history = useHistory();
  const confirmPlacementObj = useContext<ConfirmPlacementContextType | null>(
    ConfirmPlacementContext
  );

  const openConfirmPlacement = () => {
    confirmPlacementObj?.resetData();
    confirmPlacementObj?.setPatient(patient);
    history.push({
      pathname: "/home/confirmPlacement",
      state: {
        stateData: patient,
      },
    });
  };

  return (
    <>
      <AlertDetails
        title="Confirm Placement"
        titleClassName="confirm-placement-header-title"
        body={
          parse(
            "Complete the Confirm Placement process to assign a 3M™ V.A.C.<sup>®</sup> Ready Care Program Unit to the patient."
          ) as string
        }
        confirmPlacementText={true}
        footer="Confirm Placement"
        buttonOnClick={openConfirmPlacement}
        showCallForAssistance={false}
      />
    </>
  );
};
