import {
  FormControlLabel,
  Grid,
  InputBase,
  Radio,
  RadioGroup,
} from "@mui/material";
import "./inventoryRequest.css";
import {
  IAddInventoryRequest,
  IInventoryRequest,
  IInventoryRequestProps,
  IRemoveInventoryRequest,
} from "./inventoryRequest.interface";
import InputMask from "react-input-mask";
import {
  InventoryContext,
  InventoryContextType,
} from "../../../context/InventoryContext";
import { useHistory, useLocation } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { Popup } from "../../../core/popup/popup.component";
import { LoadingSpinner } from "../../../core/loader/LoadingSpinner";
import { InventoryRequestValidator } from "./inventoryRequest.validator";
import { AuthContext, AuthContextType } from "../../../context/AuthContext";
import {
  IInputField,
  ValidationStatus,
} from "../../../core/interfaces/input.interface";
import { Navigator } from "../../helpAndSupport/Navigator/navigator.component";
import QuantityButton from "../../../core/quantityButton/quantityButton.component";
import { ReactComponent as RadioButtonIcon } from "../../../assets/radioButton.svg";
import { InputWithLabel } from "../../../core/inputWithLabel/inputWithLabel.component";
import { InventoryRequestSucuess } from "./popUps/successPopUp/inventoryRequestSuccess.component";
import { FooterButtonGroup } from "../../send3MNote/footerButtonGroup/footerButtonGroup.component";
import { ReactComponent as SelectedRadioButtonIcon } from "../../../assets/selectedRadioButton.svg";
import { MobileDisplayContext } from "../../../context/MobileDisplayContext";
import { CustomDropDown } from "../../../core/customDropdown/customDropdown.component";
import {
  ERROR_TO_SELECT_ANY_OPTION,
  SVC_INVENTORY_ADJUSTMENT_ERROR_MESSAGE,
} from "../../../util/errorMsg";
import {
  submitInventoryAdjustment,
  submitReturnSolventumUnit,
} from "../../../util/inventoryMgrService";
import {
  formatPhoneNumberWithoutSpecialChar,
  getSiteUseId,
} from "../../../util/utilityFunctions";
import { IInventoryProductDetails } from "../inventoryTabContainer.enum";
import ErrorPopup from "../../../core/errorPopup/errorPopup.component";
import parse from "html-react-parser";
import {
  RolesPermissionContext,
  RolesPermissionContextType,
} from "../../../context/RolesPermissionContext";
import {
  STATIC_TEXT_INVENTORY_ADJUSTMENT_DESCRIPTION_ONE_MSG,
  STATIC_TEXT_INVENTORY_ADJUSTMENT_DESCRIPTION_TWO_MSG,
} from "../../../util/staticText";
import {
  REGISTERED_FACILITY_OBJ_FROM_AUTH_CONTEXT_FAILURE,
  SITEUSEID_FROM_REGISTERED_FACILITY_OBJ_FAILURE,
  USER_PROFILE_OBJ_FROM_AUTH_CONTEXT_FAILURE,
} from "../../../util/errorCode";

export const InventoryRequest = ({
  cancelBtnAction,
  decrementButtonDisabled = true,
  incrementButtonDisabled = false,
  submitBtnAction,
  Validator = new InventoryRequestValidator(),
}: IInventoryRequestProps) => {
  const history = useHistory();
  const location: any = useLocation();
  const [active, setActive] = useState<boolean | null>(null);
  const [validator] = useState<InventoryRequestValidator>(Validator!);
  const [openSuccessPopUp, setOpenSuccessPopUp] = useState<boolean>(false);
  const [focusClasses, setFocusClasses] = useState({
    phone: "",
  });
  const [isDecrementButtonDisabled, setIsDecrementButtonDisabled] =
    useState<boolean>(decrementButtonDisabled);
  const [isIncrementButtonDisabled, setIsIncrementButtonDisabled] =
    useState<boolean>(incrementButtonDisabled);
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const inventoryObj = useContext<InventoryContextType | null>(
    InventoryContext
  );
  const [openLoaderPopUp, setOpenLoaderPopUp] = useState<boolean>(false);
  const [openFailurePopUp, setOpenFailurePopUp] = useState<boolean>(false);
  const [storedProductLocation] = useState<IInventoryProductDetails[]>(
    location?.state?.stateData
  );
  const product = location?.state?.selectedProduct;
  let therapyDevice = "";
  if (product) {
    therapyDevice = `${product.product} - ${product.serialNumber}`;
  }
  const [errorCode, setErrorCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const data = inventoryObj!.data;
  const setData = inventoryObj!.setData;

  const { isMobileScreen } = useContext(MobileDisplayContext);
  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );
  const productAndSerialNumber =
    storedProductLocation &&
    storedProductLocation.map(
      (x: IInventoryProductDetails) => `${x.product} - ${x.serialNumber}`
    );
  const [productVal, serialNumberVal] = data.therapyDevice.value
    ? data.therapyDevice.value.split(" - ")
    : ["", ""];

  const getStorageLocation = (
    productName: string,
    serialNumber: string
  ): string => {
    const product = storedProductLocation.find(
      (product: IInventoryProductDetails) =>
        product.product === productName && product.serialNumber === serialNumber
    );
    return product?.storageLocation ?? "";
  };

  const setClasses = (e: any, classname: string) => {
    setFocusClasses(
      Object.assign({}, focusClasses, { [e.target.name]: classname })
    );
  };

  const checkErroredFieldAndFocusOnInputField = async (
    errorField: IInputField
  ) => {
    if (errorField.componentId && errorField.componentId !== "") {
      const scrollableComponent = document.getElementById(
        errorField.componentId
      );
      await setTimeout(() => {
        if (scrollableComponent) {
          scrollableComponent.scrollIntoView({
            behavior: "smooth",
          });
        }
      }, 500);
      if (errorField.id && errorField.id !== "") {
        const autoCursorComponentId = document.getElementById(errorField.id);
        await setTimeout(() => {
          if (autoCursorComponentId) {
            autoCursorComponentId.focus();
          }
        }, 300);
      }
    }
  };

  const callSubmitInventoryAPI = async (
    params: IRemoveInventoryRequest | IAddInventoryRequest
  ) => {
    setOpenLoaderPopUp(true);
    let response;
    if (data.requestType.value.toUpperCase() === "ADD INVENTORY") {
      response = await submitInventoryAdjustment(params);
    } else {
      response = await submitReturnSolventumUnit(params);
    }
    setOpenLoaderPopUp(false);
    if (response && response.succeeded) {
      setOpenSuccessPopUp(true);
    } else {
      setErrorMessage(SVC_INVENTORY_ADJUSTMENT_ERROR_MESSAGE);
      setErrorCode(
        response?.error?.code || response?.error?.errorCode || response?.status
      );
      setOpenFailurePopUp(true);
    }
  };

  const validateAndSetData = (e: any) => {
    let { name, value } = e.target;
    if (
      name === "phone" &&
      data.phone.valid === ValidationStatus.UNTOUCHED &&
      (value === "(___) ___-____" || value === "")
    ) {
      return;
    }
    let isValid = validator.validate(value, name);
    if (
      name === "deliveryInstructions" &&
      isValid!.status === ValidationStatus.INVALID &&
      value.length > 200
    ) {
      return;
    } else if (
      name === "otherReasonForRemoval" &&
      isValid!.status === ValidationStatus.INVALID &&
      value.length > 100
    ) {
      return;
    }
    let errorMessage: string | null = null;
    if (name === "phone") {
      errorMessage =
        isValid!.updatedVal?.trim() === ""
          ? Object(data)[name].errorMessage
          : null;
    }
    let tempData = {
      ...data,
      [name]: {
        ...Object(data)[name],
        errorMessage: errorMessage,
        valid: isValid!.status,
        value: value,
      },
    };
    switch (name) {
      case "reasonForRemoval":
        tempData = {
          ...tempData,
          otherReasonForRemoval: {
            ...tempData.otherReasonForRemoval,
            errorMessage: null,
            required: value.toUpperCase() === "OTHER",
            valid: ValidationStatus.UNTOUCHED,
            value: "",
          },
        };
        break;

      case "requestType":
        therapyDevice = "";
        tempData = addOrRemoveInventorySelected(
          tempData,
          value === "Add Inventory"
        );
        if (value === "Add Inventory") {
          setActive(true);
        } else if (value === "Remove Inventory") {
          updateQuantityButtons(tempData);
          setActive(false);
        } else {
          setActive(null);
        }
        break;

      case "therapyDevice":
        if (tempData.reasonForRemoval.valid !== ValidationStatus.UNTOUCHED) {
          tempData = {
            ...tempData,
            reasonForRemoval: {
              ...tempData.reasonForRemoval,
              errorMessage: null,
              valid: ValidationStatus.UNTOUCHED,
              value: "",
            },
            otherReasonForRemoval: {
              ...tempData.otherReasonForRemoval,
              errorMessage: null,
              required: false,
              valid: ValidationStatus.UNTOUCHED,
              value: "",
            },
          };
        }
        break;
      default:
        break;
    }
    setData(tempData);
  };

  const incrementButtonClicked = () => {
    const oldQuantity =
      data?.quantity.value.length > 0 ? data?.quantity.value : "0";
    const qunatityPlus = parseInt(oldQuantity) + 1;
    if (qunatityPlus === 2) {
      setIsIncrementButtonDisabled(true);
    } else if (qunatityPlus > 0) {
      setIsDecrementButtonDisabled(false);
    }
    setData((dt: IInventoryRequest) => ({
      ...dt,
      quantity: {
        ...dt.quantity,
        value: `${qunatityPlus}`,
        valid:
          qunatityPlus === 0
            ? ValidationStatus.INVALID
            : ValidationStatus.VALID,
        required: true,
      },
    }));
  };

  const decrementButtonClicked = () => {
    const oldQuantity =
      data?.quantity.value.length > 0 ? data?.quantity.value : "0";
    const qunatityMinus = parseInt(oldQuantity) - 1;
    if (qunatityMinus === 0) {
      setIsDecrementButtonDisabled(true);
    } else if (qunatityMinus < 2) {
      setIsIncrementButtonDisabled(false);
    }
    setData((dt: IInventoryRequest) => ({
      ...dt,
      quantity: {
        ...dt.quantity,
        value: `${qunatityMinus}`,
        valid:
          qunatityMinus === 0
            ? ValidationStatus.INVALID
            : ValidationStatus.VALID,
        required: true,
      },
    }));
  };

  const addOrRemoveInventorySelected = (
    tempData: IInventoryRequest,
    isAdding: boolean
  ): IInventoryRequest => {
    tempData = {
      ...tempData,
      reasonForRemoval: {
        ...tempData.reasonForRemoval,
        required: !isAdding,
        valid: ValidationStatus.UNTOUCHED,
        value: "",
      },
      otherReasonForRemoval: {
        ...tempData.otherReasonForRemoval,
        required: false,
        valid: ValidationStatus.UNTOUCHED,
        value: "",
      },
      quantity: {
        ...tempData.quantity,
        required: isAdding,
        valid: ValidationStatus.UNTOUCHED,
        value: "",
      },
      deliveryInstructions: {
        ...tempData.deliveryInstructions,
        valid: ValidationStatus.VALID,
        value: "",
      },
      therapyDevice: {
        ...tempData.therapyDevice,
        required: !isAdding,
        valid: isAdding
          ? ValidationStatus.UNTOUCHED
          : therapyDevice !== ""
          ? ValidationStatus.VALID
          : ValidationStatus.UNTOUCHED,
        value: isAdding ? "" : therapyDevice,
      },
    };
    return tempData;
  };

  const updateQuantityButtons = (tempData: IInventoryRequest) => {
    const quantityStr =
      tempData.quantity.value.length > 0 ? tempData.quantity.value : "0";
    const quantity = parseInt(quantityStr);
    setIsDecrementButtonDisabled(quantity === 0);
    setIsIncrementButtonDisabled(quantity === 2);
  };

  const cancelInventoryRequest = () => {
    history.goBack();
  };

  const submitRequest = async () => {
    const validator = new InventoryRequestValidator();
    const [isAllValid, errorField] = validator.validateAll(data, setData);
    if (errorField) {
      checkErroredFieldAndFocusOnInputField(errorField);
    }
    if (isAllValid === ValidationStatus.VALID) {
      submitRequestAPICall();
    }
  };

  const submitRequestAPICall = () => {
    const siteUseId = getSiteUseId(authObj);
    if (
      authObj &&
      authObj.userProfile &&
      authObj.registeredFaciltyAddress &&
      siteUseId !== ""
    ) {
      if (data.requestType.value.toUpperCase() === "ADD INVENTORY") {
        const submitInventoryRequestForAddInventory: IAddInventoryRequest = {
          userName: authObj.userProfile.userName,
          templateCode: "ADD_INVENTORY_ADJUSTMENT_REQUEST",
          emailParameters: {
            RequestType: data.requestType.value,
            SiteUseId: siteUseId,
            Quantity: data.quantity.value,
            CustomerName: `${data.contactFirstName.value.trim()} ${data.contactLastName.value.trim()}`,
            FacilityAddress: `${authObj.registeredFaciltyAddress.address1}, ${
              authObj.registeredFaciltyAddress.address2 &&
              authObj.registeredFaciltyAddress.address2.trim() !== ""
                ? authObj.registeredFaciltyAddress.address2 + ", "
                : ""
            }${authObj.registeredFaciltyAddress.city}, ${
              authObj.registeredFaciltyAddress.state
            }, ${authObj.registeredFaciltyAddress.zip}`,

            FacilityName: authObj.registeredFaciltyAddress.accountName,
            FacilityID: authObj.registeredFaciltyAddress.accountNumber,
          },
        };
        callSubmitInventoryAPI(submitInventoryRequestForAddInventory);
      } else {
        const submitInventoryRequestForRemoveInventory: IRemoveInventoryRequest =
          {
            customerNumber: authObj.registeredFaciltyAddress.accountNumber,
            serialNumber: serialNumberVal,
            employeId: authObj.userProfile.employeeID
              ? authObj.userProfile.employeeID
              : "INTERNET",
            callerFirstName: data.contactFirstName.value.trim(),
            callerLastName: data.contactLastName.value.trim(),
            callerEmailAddress: data.email.value,
            callerPhoneNumber: formatPhoneNumberWithoutSpecialChar(
              data?.phone.value
            ),
            facilityName: authObj.registeredFaciltyAddress.accountName,
            siteUseID: siteUseId,
            deliveryInstructions: `${getReasonForRemovalValue()} Location: ${getStorageLocation(
              productVal,
              serialNumberVal
            )}`,
            version: process.env.REACT_APP_VERSION!,
            productName: productVal,
            programType: 1,
            isForRemoveInventory:
              data.requestType.value.toUpperCase() === "REMOVE INVENTORY",
            callerDepartment:
              data && data.department && data.department.value
                ? data.department.value.trim()
                : "",
            pickUpLocation: getStorageLocation(productVal, serialNumberVal),
            productProblem: getReasonForRemovalValue(),
          };
        callSubmitInventoryAPI(submitInventoryRequestForRemoveInventory);
      }
    } else {
      let code;
      if (!authObj || (authObj && !authObj.registeredFaciltyAddress)) {
        code = REGISTERED_FACILITY_OBJ_FROM_AUTH_CONTEXT_FAILURE;
      } else if (siteUseId === "") {
        code = SITEUSEID_FROM_REGISTERED_FACILITY_OBJ_FAILURE;
      } else {
        code = USER_PROFILE_OBJ_FROM_AUTH_CONTEXT_FAILURE;
      }
      setErrorMessage(SVC_INVENTORY_ADJUSTMENT_ERROR_MESSAGE);
      setErrorCode(code);
      setOpenFailurePopUp(true);
    }
  };
  const getReasonForRemovalValue = (): string => {
    const reason = data.reasonForRemoval.value.toUpperCase();
    return reason !== "OTHER"
      ? data.reasonForRemoval.value
      : data.otherReasonForRemoval.value.trim();
  };

  const updateBasicDetails = () => {
    const requestType = location?.state?.requestType ?? false;
    let tempData = {
      ...data,
      requestType: {
        ...data.requestType,
        value: requestType ? "Add Inventory" : "Remove Inventory",
      },
    };
    tempData = addOrRemoveInventorySelected(tempData, requestType);
    setActive(requestType);
    if (tempData.requestType.value === "Add Inventory") {
      updateQuantityButtons(tempData);
    }
    if (authObj && authObj.userProfile) {
      const profile = authObj.userProfile;
      const firstNameValid = validator.validate(
        profile.firstName,
        "contactFirstName"
      )!.status;
      const lastNameValid = validator.validate(
        profile.lastName,
        "contactLastName"
      )!.status;
      const phoneValue =
        profile.phoneNumber && profile.phoneNumber !== ""
          ? profile.phoneNumber.slice(-10)
          : profile.mobilePhoneNumber.slice(-10);
      const phoneValid = validator.validate(phoneValue, "phone")!.status;
      const emailValue = profile.emailAddress;
      const emailValid = validator.validate(emailValue, "email")!.status;
      const departmentValue = profile.departmentName;
      const departmentValid = validator.validate(
        departmentValue,
        "department"
      )!.status;

      tempData = {
        ...tempData,
        contactFirstName: {
          ...tempData.contactFirstName,
          valid: firstNameValid,
          value: profile.firstName,
        },
        contactLastName: {
          ...tempData.contactLastName,
          valid: lastNameValid,
          value: profile.lastName,
        },
        phone: {
          ...tempData.phone,
          value: phoneValue,
          valid: phoneValid,
        },
        email: {
          ...tempData.email,
          value: emailValue,
          valid:
            emailValid === ValidationStatus.INVALID
              ? ValidationStatus.UNTOUCHED
              : ValidationStatus.VALID,
        },
        department: {
          ...tempData.department,
          value: departmentValue,
          valid: departmentValid,
        },
      };
      setData(tempData);
    }
  };
  const otherFieldRequired = (): React.ReactNode => {
    return (
      <>
        Other{" "}
        {data.reasonForRemoval.required &&
          data.reasonForRemoval.value.toUpperCase() === "OTHER" && (
            <span className="other-field-requied">*</span>
          )}
      </>
    );
  };
  useEffect(() => {
    if (authObj && !authObj.userProfile) {
      history.replace("/home");
    } else if (data.requestType.value === "") {
      updateBasicDetails();
    }
    const quantityStr =
      data.quantity.value.length > 0 ? data.quantity.value : "0";
    const quantity = parseInt(quantityStr);
    setIsDecrementButtonDisabled(quantity === 0);
    setIsIncrementButtonDisabled(quantity === 2);
  }, []);

  return (
    <div
      className="inventory-request-component"
      id="inventory-request-component"
    >
      <div className="short-form">
        <Navigator
          array={[
            {
              route: "/inventory",
              pageName: "Inventory",
            },
          ]}
          className="inventory-adjustment-route-section"
          title="Inventory Adjustment"
        />
        <div className="inventory-request-header">
          <h2
            className="inventory-request-header-title"
            data-testid="inventory-request-header-title"
          >
            Inventory Adjustment Request
          </h2>
          <h5
            className="inventory-request-header-description-1"
            data-testid="inventory-request-header-description-1"
          >
            {parse(STATIC_TEXT_INVENTORY_ADJUSTMENT_DESCRIPTION_ONE_MSG)}
          </h5>
          <h5
            className="inventory-request-header-description-2"
            data-testid="inventory-request-header-description-2"
          >
            {STATIC_TEXT_INVENTORY_ADJUSTMENT_DESCRIPTION_TWO_MSG}
            <a
              className="phone-value"
              data-testid="phone-value"
              href={`tel:+8002754524`}
            >
              1-800-275-4524 Ext. 41858
            </a>
            {` or `}
            <a
              className="email-value"
              data-testid="email-value"
              href={`mailto:expressdigitalsupport@solventum.com`}
            >
              expressdigitalsupport@solventum.com
            </a>
          </h5>
        </div>
        <div className="inventory-request-type">
          <Grid
            className="inventory-request-grid-container"
            container
            spacing={2}
          >
            <Grid
              className="inventory-request-grid-item"
              item
              xs={isMobileScreen ? 12 : 8}
            >
              <InputWithLabel
                error={data?.requestType.valid === ValidationStatus.INVALID}
                id={data.requestType.componentId}
                isRequired={data.requestType.required}
                isShowWarning={true}
                label={data.requestType.title}
                testId="inventory-request-type"
                warningMessage={data.requestType.errorMessage}
              >
                <RadioGroup
                  classes={{ root: "radioRoot" }}
                  name="requestType"
                  onChange={validateAndSetData}
                  value={data?.requestType.value}
                >
                  <FormControlLabel
                    classes={{
                      root:
                        active === true ? "optionRoot-active" : "optionRoot",
                    }}
                    componentsProps={{
                      typography: {
                        classes: {
                          root:
                            active === true ? "optiontxtSelect" : "optiontxt",
                        },
                      },
                    }}
                    control={
                      <Radio
                        data-testid="radio-yes"
                        icon={<RadioButtonIcon />}
                        checkedIcon={<SelectedRadioButtonIcon />}
                      />
                    }
                    data-testid="inventory-request-type-yes"
                    label="Add Inventory"
                    value="Add Inventory"
                  />
                  <FormControlLabel
                    classes={{
                      root:
                        active === false ? "optionRoot-active" : "optionRoot",
                    }}
                    componentsProps={{
                      typography: {
                        classes: {
                          root:
                            active === false ? "optiontxtSelect" : "optiontxt",
                        },
                      },
                    }}
                    control={
                      <Radio
                        data-testid="radio-no"
                        icon={<RadioButtonIcon />}
                        checkedIcon={<SelectedRadioButtonIcon />}
                      />
                    }
                    data-testid="inventory-request-type-no"
                    label="Remove Inventory"
                    value="Remove Inventory"
                  />
                </RadioGroup>
              </InputWithLabel>
            </Grid>
          </Grid>
        </div>
        {data.requestType.value === "Add Inventory" && (
          <div>
            <div className="inventory-request-add-quantity">
              <InputWithLabel
                error={data.quantity.valid === ValidationStatus.INVALID}
                id={data.quantity.componentId}
                isRequired={data.quantity.required}
                isShowWarning={true}
                label={data.quantity.title}
                testId="inventory-request-quantity"
                warningMessage={data.quantity.errorMessage}
              >
                <div className="inventory-request-quantity-div">
                  <QuantityButton
                    value={data.quantity.value}
                    isPlusDisabled={isIncrementButtonDisabled}
                    onPlusClick={incrementButtonClicked}
                    onMinusClick={decrementButtonClicked}
                    isMinusDisabled={isDecrementButtonDisabled}
                    showLabel={false}
                    isPeelAndPlaceDressing={false}
                  />
                </div>
              </InputWithLabel>
            </div>
            <div className="add-inventory-delivery-instructions-div">
              <InputWithLabel
                error={
                  data.deliveryInstructions.valid === ValidationStatus.INVALID
                }
                id={data.deliveryInstructions.componentId!}
                isRequired={false}
                isShowWarning={true}
                label={data.deliveryInstructions.title}
                testId={"inventory-delivery-instructions-field-test"}
                labelClassName="add-inventory-deliveryInstruction-label"
                warningMessage={data.deliveryInstructions.errorMessage}
              >
                <InputBase
                  className="inventory-delivery-instructions-textBox"
                  data-testid="inventory-delivery-instructions-value"
                  error={
                    data.deliveryInstructions.valid === ValidationStatus.INVALID
                  }
                  inputProps={{
                    maxLength: 200,
                    className:
                      data.deliveryInstructions.valid ===
                      ValidationStatus.INVALID
                        ? "showDeliveryInstructionError"
                        : "noDeliveryInstructionError",
                  }}
                  multiline={true}
                  id={data.deliveryInstructions?.id!}
                  name="deliveryInstructions"
                  onChange={validateAndSetData}
                  value={data.deliveryInstructions.value}
                />
              </InputWithLabel>
            </div>
          </div>
        )}
        {data.requestType.value === "Remove Inventory" && (
          <Grid
            className="inventory-request-grid-container"
            container
            spacing={2}
          >
            <Grid
              className="inventory-request-grid-item"
              item
              xs={isMobileScreen ? 11 : 8}
            >
              <InputWithLabel
                error={data.therapyDevice.valid === ValidationStatus.INVALID}
                id={data.therapyDevice.componentId!}
                isRequired={data.therapyDevice.required}
                isShowWarning={true}
                label={parse(data.therapyDevice.title!)}
                testId={"inventory-adjustment-therapy-device"}
                warningMessage={
                  data.therapyDevice.errorMessage
                    ? ERROR_TO_SELECT_ANY_OPTION
                    : ""
                }
                isDropdown={true}
                labelClassName="therapy-drop-down-label"
              >
                <CustomDropDown
                  handleChange={validateAndSetData}
                  menuItem={productAndSerialNumber}
                  name="therapyDevice"
                  placeHolder="Select device to remove"
                  selectpropsClassName={
                    data.therapyDevice.value
                      ? "therapy-device-select"
                      : "placeHolder"
                  }
                  selectClassName={"therapy-device-input"}
                  testId="therapy-device-drop-down-test"
                  value={data.therapyDevice.value}
                />
              </InputWithLabel>
            </Grid>
            {data.therapyDevice.value !== "" && (
              <>
                <Grid
                  className="inventory-request-grid-item radioOptions"
                  item
                  xs={isMobileScreen ? 11 : 8}
                >
                  <InputWithLabel
                    error={
                      data?.reasonForRemoval.valid === ValidationStatus.INVALID
                    }
                    id={data.reasonForRemoval.componentId}
                    isRequired={data.reasonForRemoval.required}
                    isShowWarning={true}
                    label={data.reasonForRemoval.title}
                    labelClassName="radio-title-label"
                    warningMessage={
                      data.reasonForRemoval.errorMessage
                        ? ERROR_TO_SELECT_ANY_OPTION
                        : ""
                    }
                  >
                    <RadioGroup
                      classes={{ root: "radioRoot radio-btn" }}
                      name="reasonForRemoval"
                      onChange={validateAndSetData}
                      value={data.reasonForRemoval.value}
                    >
                      <FormControlLabel
                        classes={{
                          root:
                            active === true
                              ? "optionRoot-active"
                              : "optionRoot",
                        }}
                        componentsProps={{
                          typography: { classes: { root: "optiontxt" } },
                        }}
                        value="Unit malfunction"
                        control={
                          <Radio
                            data-testid="radio-unit-malfunction"
                            required={true}
                            icon={<RadioButtonIcon />}
                            checkedIcon={<SelectedRadioButtonIcon />}
                          />
                        }
                        data-testid="inventory-unit-malfunction"
                        label="Unit malfunction"
                      />
                      <FormControlLabel
                        classes={{ root: "optionRoot" }}
                        componentsProps={{
                          typography: {
                            classes: {
                              root:
                                active === true
                                  ? "optiontxtSelect"
                                  : "optiontxt",
                            },
                          },
                        }}
                        value="Parts or equipment missing"
                        control={
                          <Radio
                            data-testid="radio-equipment-missing"
                            required={data.reasonForRemoval.required}
                            icon={<RadioButtonIcon />}
                            checkedIcon={<SelectedRadioButtonIcon />}
                          />
                        }
                        data-testid="inventory-equipment-missing"
                        label="Parts or equipment missing"
                      />
                      <FormControlLabel
                        classes={{ root: "optionRoot" }}
                        componentsProps={{
                          typography: {
                            classes: {
                              root:
                                active === true
                                  ? "optiontxtSelect"
                                  : "optiontxt",
                            },
                          },
                        }}
                        value="Unit missing"
                        control={
                          <Radio
                            data-testid="radio-unit-missing"
                            required={data.reasonForRemoval.required}
                            icon={<RadioButtonIcon />}
                            checkedIcon={<SelectedRadioButtonIcon />}
                          />
                        }
                        data-testid="inventory-unit-missing"
                        label="Unit missing"
                      />
                      <FormControlLabel
                        required={
                          data.reasonForRemoval.value.toUpperCase() === "OTHER"
                        }
                        classes={{ root: "optionRoot" }}
                        componentsProps={{
                          typography: {
                            classes: {
                              root:
                                active === true
                                  ? "optiontxtSelect"
                                  : "optiontxt",
                            },
                          },
                        }}
                        value="Other"
                        control={
                          <Radio
                            data-testid="radio-other"
                            required={data.reasonForRemoval.required}
                            icon={<RadioButtonIcon />}
                            checkedIcon={<SelectedRadioButtonIcon />}
                          />
                        }
                        data-testid="inventory-other-reason-for-removal"
                        label={otherFieldRequired()}
                      />
                    </RadioGroup>
                  </InputWithLabel>
                </Grid>
                {data.reasonForRemoval.value.toUpperCase() === "OTHER" && (
                  <Grid
                    className="inventory-request-grid-item other"
                    item
                    xs={isMobileScreen ? 11 : 8}
                  >
                    <InputWithLabel
                      error={
                        data.otherReasonForRemoval.valid ===
                        ValidationStatus.INVALID
                      }
                      id={data.otherReasonForRemoval.componentId}
                      isShowWarning={true}
                      showLabel={false}
                      testId="inventory-other-reasonForRemoval"
                      warningMessage={data.otherReasonForRemoval.errorMessage}
                    >
                      <InputBase
                        className="reason-for-removal-other-input"
                        data-testid="inventory-other-reason-for-removal-textBox"
                        error={
                          data.otherReasonForRemoval?.valid ===
                          ValidationStatus.INVALID
                        }
                        inputProps={{ maxLength: 100 }}
                        id={data.otherReasonForRemoval.id!}
                        name="otherReasonForRemoval"
                        onChange={validateAndSetData}
                        placeholder="Please describe"
                        required={data.otherReasonForRemoval.required}
                        value={data.otherReasonForRemoval.value}
                      />
                    </InputWithLabel>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        )}
        <div className="inventory-requester-information">
          <h2
            className="inventory-requester-information-title"
            data-testid="inventory-requester-information-title"
          >
            Requester Information
          </h2>
          <h4
            className="inventory-requester-information-description"
            data-testid="inventory-requester-information-description"
          >
            The person indicated below will be contacted within 2 business days.
          </h4>
          <Grid
            className="inventory-request-grid-container"
            container
            spacing={2}
          >
            <Grid
              className="inventory-request-grid-item"
              item
              xs={isMobileScreen ? 11 : 4}
            >
              <InputWithLabel
                error={data.contactFirstName.valid === ValidationStatus.INVALID}
                id={data.contactFirstName.componentId}
                isShowWarning={true}
                isRequired={data.contactFirstName.required}
                label={data.contactFirstName.title}
                testId="inventory-requester-information-firstName"
                warningMessage={data.contactFirstName.errorMessage}
              >
                <InputBase
                  className="inventory-requester-information-input"
                  inputProps={{
                    "data-testid":
                      "inventory-requester-information-firstName-value",
                  }}
                  name="contactFirstName"
                  onChange={validateAndSetData}
                  required={data.contactFirstName.required}
                  value={data.contactFirstName.value}
                  id={data.contactFirstName.id!}
                />
              </InputWithLabel>
            </Grid>
            <Grid
              className="inventory-request-grid-item"
              item
              xs={isMobileScreen ? 11 : 4}
            >
              <InputWithLabel
                error={data.contactLastName.valid === ValidationStatus.INVALID}
                isRequired={data.contactLastName.required}
                isShowWarning={true}
                id={data.contactLastName.componentId}
                label={data.contactLastName.title}
                testId="inventory-requester-information-lastName"
                warningMessage={data.contactLastName.errorMessage}
              >
                <InputBase
                  className="inventory-requester-information-input"
                  inputProps={{
                    "data-testid":
                      "inventory-requester-information-lastName-value",
                  }}
                  name="contactLastName"
                  onChange={validateAndSetData}
                  required={data.contactLastName.required}
                  value={data.contactLastName.value}
                  id={data.contactLastName.id!}
                />
              </InputWithLabel>
            </Grid>
            <Grid
              className="inventory-request-grid-item"
              item
              xs={isMobileScreen ? 11 : 8}
            >
              <InputWithLabel
                error={data.phone.valid === ValidationStatus.INVALID}
                id={data.phone.componentId}
                isRequired={data.phone.required}
                isShowWarning={true}
                label={data.phone.title}
                labelClassName={focusClasses.phone}
                testId="inventory-requester-information-phone"
                warningMessage={data.phone.errorMessage}
              >
                <InputMask
                  className="phone"
                  data-testid="inventory-requester-information-phone-value"
                  id={data.phone.id!}
                  mask="(999) 999-9999"
                  name="phone"
                  onChange={validateAndSetData}
                  onFocus={(e) => setClasses(e, "Mui-focused")}
                  onBlur={(e) => setClasses(e, "")}
                  placeholder="(___) ___-____"
                  required={data.phone.required}
                  value={data.phone.value}
                />
              </InputWithLabel>
            </Grid>
            <Grid
              className="inventory-request-grid-item"
              item
              xs={isMobileScreen ? 11 : 8}
            >
              <InputWithLabel
                error={data.email.valid === ValidationStatus.INVALID}
                id={data.email.componentId}
                isRequired={data.email.required}
                isShowWarning={true}
                label={data.email.title}
                testId="inventory-requester-information-email"
                warningMessage={data.email.errorMessage}
              >
                <InputBase
                  className="inventory-requester-information-input"
                  inputProps={{
                    "data-testid":
                      "inventory-requester-information-email-value",
                  }}
                  id={data.email.id!}
                  name="email"
                  onChange={validateAndSetData}
                  required={data.email.required}
                  value={data.email.value}
                />
              </InputWithLabel>
            </Grid>
            <Grid
              className="inventory-request-grid-item"
              item
              xs={isMobileScreen ? 11 : 8}
            >
              <InputWithLabel
                error={data.department.valid === ValidationStatus.INVALID}
                isRequired={data.department.required}
                id={data.department.componentId}
                isShowWarning={true}
                label={data.department.title}
                testId="inventory-requester-information-department"
                warningMessage={data.department.errorMessage}
              >
                <InputBase
                  className="inventory-requester-information-input"
                  inputProps={{
                    "data-testid":
                      "inventory-requester-information-department-value",
                  }}
                  id={data.department.id!}
                  name="department"
                  onChange={validateAndSetData}
                  value={data.department.value}
                />
              </InputWithLabel>
            </Grid>
          </Grid>
        </div>
      </div>
      <FooterButtonGroup
        firstButtonTitle="Cancel"
        firstButtonAction={
          cancelBtnAction ? cancelBtnAction : cancelInventoryRequest
        }
        secondButtonTitle="Submit Request"
        secondButtonDisabled={
          permissionObj?.mappedRolesPermissionData.IsSupportRole
        }
        secondButtonAction={submitBtnAction ? submitBtnAction : submitRequest}
      />
      <Popup
        openFlag={openLoaderPopUp}
        closeHandler={() => setOpenLoaderPopUp(false)}
        dialogParentClass={"send-note-loader-pop-up"}
        data-testid="loader-pop-up"
        hideCloseButton={true}
      >
        <div className="send-note-loader">
          <LoadingSpinner />
        </div>
      </Popup>
      <Popup
        openFlag={openSuccessPopUp}
        closeHandler={() => setOpenSuccessPopUp(false)}
        dialogParentClass={"inventory-request-success-pop-up"}
        data-testid="success-pop-up"
        hideCloseButton={true}
      >
        <InventoryRequestSucuess
          backButtonAction={() => {
            history.goBack();
          }}
        />
      </Popup>
      <ErrorPopup
        errorPopupFlag={openFailurePopUp}
        handleBackButton={() => setOpenFailurePopUp(false)}
        popUpStyles="errorPopup"
        errorMessage={errorMessage}
        buttontitle="Done"
        showTitle={false}
        isShortSentence={false}
        isSupportPhoneRequired={true}
        errorCode={errorCode}
      />
    </div>
  );
};
