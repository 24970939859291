import { Button, useMediaQuery } from "@mui/material";
import { IPatientAlert } from "../../components/myPatients/patient.interface";
import { getAlertBgAndLabelColor } from "../../util/utilityFunctions";
import { AlertArrow } from "./alertButton.component";
import "./alertButtonToolTip.css";

type AlertButtonToolTipProps = {
  alertData: IPatientAlert;
  onClick: any;
  isAlertToolTip?: Boolean;
};
const AlertButtonToolTip = ({
  alertData,
  onClick,
  isAlertToolTip = false,
}: AlertButtonToolTipProps) => {
  const isMobileScreen = useMediaQuery("(max-width:1200px)");

  return (
    <div
      className={`alert-box-tool-tip`}
      style={{
        background: getAlertBgAndLabelColor(
          alertData.alertType,
          alertData.severity
        )[0],
      }}
      key={alertData.alertID.toString()}
    >
      <div>
        <div>
          <Button
            variant="text"
            className={"name-tool-tip"}
            classes={{ endIcon: "arrow-icon-alert-tool-tip-component" }}
            style={{
              color: getAlertBgAndLabelColor(
                alertData.alertType,
                alertData.severity
              )[1],
            }}
            onClick={onClick}
            endIcon={
              <AlertArrow
                stroke={
                  getAlertBgAndLabelColor(
                    alertData.alertType,
                    alertData.severity
                  )[1]
                }
              />
            }
          >
            {alertData.alertName}
          </Button>
          <div
            className={
              isAlertToolTip
                ? "alert-label-and-date-tool-tip"
                : "alert-label-and-date"
            }
            id={`${alertData.alertID}label`}
            data-testid={`${alertData.alertID}label`}
          >
            {alertData.alertSubLabel}
          </div>
          <div
            className={"alert-label-and-date-tool-tip"}
            id={`${alertData.alertID}date`}
            data-testid={`${alertData.alertID}date`}
          >
            {alertData.trackingNumber}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertButtonToolTip;
