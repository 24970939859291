import { Grid } from "@mui/material";
import React, { useContext } from "react";
import {
  AuthContext,
  AuthContextType,
} from "../../../../../context/AuthContext";
import {
  formatNumberSalesManager,
  makeCapitalEachWordInString,
} from "../../../../../util/utilityFunctions";
import { ISaleManageUsersEditProps } from "../salesUserProfile.interface";
import "./salesManageUserContactinfo.css";

const SalesManagerUserContactinfo = ({
  salesManagerUserData,
}: ISaleManageUsersEditProps) => {
  const authObj = useContext<AuthContextType | null>(AuthContext);

  return (
    <Grid
      className="contactinfo-main-container"
      data-testid="contactinfo-main-container"
    >
      {salesManagerUserData && (
        <Grid>
          <Grid className="salesmngusr-data">
            <Grid
              className="salesManagerUser-Name-Lable"
              data-testid="salesmanageruser-name-lable"
            >
              Name
              {authObj && (
                <div
                  className="saleManageUserAccountName"
                  data-testid="saleManageUserAccountName"
                >
                  {makeCapitalEachWordInString(salesManagerUserData?.name)}
                </div>
              )}
            </Grid>
            <Grid
              className="salesManagerUser-email-lable"
              data-testid="salesmanageruser-email-lable"
            >
              Email Address
              <div
                className="saleManageUserAccountEmailName"
                data-testid="saleManageUserAccountEmailName"
              >
                {salesManagerUserData?.emailAddress}
              </div>
            </Grid>
          </Grid>
          <Grid
            className="salesManagerUser-contact-lable"
            data-testid="salesmanageruser-contact-lable"
          >
            Contact Phone Number
            <div
              className="saleManageUserAccountContactPhone"
              data-testid="saleManageUserAccountContactPhone"
            >
              {formatNumberSalesManager(salesManagerUserData?.contactPhoneNo)}
            </div>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
export default SalesManagerUserContactinfo;
