import { useContext, useEffect, useState } from "react";
import {
  MyPatientContext,
  MyPatientContextType,
} from "../../../../context/MyPatientContext";
import { ExpressButton } from "../../../../core/expressButton/expressButton.component";
import { Popup } from "../../../../core/popup/popup.component";
import {
  Confirm_Placement_Alert_Reason_Text,
  Incomplete_Wound_Alert_Reason_Text,
  MissingRx_Alert_Reason_Text,
  Proof_of_Delivery_Needed_Alert_Reason_Text,
} from "../../../../util/staticText";
import { IPatient, IPatientAlert } from "../../patient.interface";
import { Alerts } from "../../patientAlerts/alerts/alerts.component";
import "./orderDetailsAlertBanner.css";
import { useHistory } from "react-router-dom";

type Props = {
  alertData: IPatientAlert;
  patientData: IPatient;
  alertsForRO?: IPatient;
  setUpdatedAlertDataForRO?: Function;
};

const OrderDetailAlertBanner = ({
  alertData,
  patientData,
  alertsForRO,
  setUpdatedAlertDataForRO,
}: Props) => {
  const [alertReasonText, setAlertReasonText] = useState("");
  const [alertButtonText, setAlertButtonText] = useState("");
  const [isButtonVisible, setIsButtonVisible] = useState<boolean>(true);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const MyPatientObj = useContext<MyPatientContextType | null>(
    MyPatientContext
  );
  const history: any = useHistory();
  const buttonClicked = () => {
    setAlertOpen(true);
  };
  const closeAlertPopupReload = (reloadPatiantList: boolean = false) => {
    setAlertOpen(false);
    MyPatientObj?.setReloadMyPatient(reloadPatiantList);
  };
  const successAndCloseIndicatorPopup = () => {
    setUpdatedAlertDataForRO!(null);
    patientData.alerts = [];
    MyPatientObj?.setReloadMyPatient(true);
    history.replace({
      pathname: "/home/orderOverview",
      state: {
        stateData: patientData,
      },
    });
  };
  const buttonTexts = alertButtonText;

  useEffect(() => {
    if (alertData.alertName === "Missing Rx") {
      setAlertReasonText(MissingRx_Alert_Reason_Text);
      setAlertButtonText("Submit Prescription");
    } else if (alertData.alertName === "Proof of Delivery Needed") {
      setAlertReasonText(Proof_of_Delivery_Needed_Alert_Reason_Text);
      setAlertButtonText("Provide Proof of Delivery");
    } else if (alertData.alertName === "Confirm Placement") {
      setAlertReasonText(Confirm_Placement_Alert_Reason_Text);
      setAlertButtonText(alertData?.alertName);
    } else if (alertData.alertName === "Incomplete Wound Details") {
      setAlertReasonText(Incomplete_Wound_Alert_Reason_Text);
      setAlertButtonText("Resubmit Wound Info");
    } else if (
      alertData.alertName === "This order was sourced through Healogics WoundQ"
    ) {
      setAlertReasonText(alertData.alertSubLabel);
      setIsButtonVisible(false);
    }
    if (
      alertData.alertName.toLowerCase() ===
        "Pending Supply Order".toLowerCase() ||
      alertData.alertName.toLowerCase() === "Excessive Supply".toLowerCase()
    ) {
      setAlertReasonText(alertData.alertSubLabel);
      setAlertButtonText("Print Excessive Supply Form");
      setIsButtonVisible(false);
    }
  }, [alertData?.alertName, alertsForRO]);
  return (
    <>
      <div className={`alertText ${alertData?.alertName.replace(/ +/g, "")}`}>
        <div className="alertHeading">
          <h5
            className={`alertReasonParentClass ${alertData?.alertName.replace(
              / +/g,
              ""
            )}`}
            data-testid="alertBanner-header"
          >
            {alertData?.alertName}
          </h5>
          <h5 className="alertReasonText" data-testid="alertReason">
            {alertReasonText}
          </h5>
        </div>
        {(isButtonVisible ||
          alertData.alertName.toLowerCase() === "excessive supply") && (
          <div className="alertBannerButton">
            <ExpressButton
              clickHandler={buttonClicked}
              parentClass={`alertNavigationButton ${alertData?.alertName
                .concat("Btn")
                .replace(/ +/g, "")}`}
              variant="outlined"
              testId="alertAction-button"
            >
              {buttonTexts}
            </ExpressButton>
          </div>
        )}
      </div>
      {alertOpen && (
        <Popup
          openFlag={alertOpen}
          dialogParentClass={alertData.alertName.replace(/\s+/g, "") + "alert"}
          closeHandler={() => setAlertOpen(false)}
        >
          {
            <Alerts
              closePopUpAction={closeAlertPopupReload}
              data={alertData}
              patient={patientData}
              successAndCloseIndicatorPopup={successAndCloseIndicatorPopup}
              closeAlertPopupNoReload={closeAlertPopupReload}
            />
          }
        </Popup>
      )}
    </>
  );
};
export default OrderDetailAlertBanner;
