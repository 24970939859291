import moment from "moment";
import { ValidationStatus } from "../../../../core/interfaces/input.interface";
import { getDeepClone } from "../../../../util/ObjectFunctions";
import { makeCapitalEachWordInString } from "../../../../util/utilityFunctions";
import { defaultNewOrdeSecondaryWoundInfoData } from "../../clinicalInformation/secondaryWoundInfo/secondaryWoundInfo.model";
import { INewOrder } from "../../newOrder.interface";
import { defaultNewOrderData } from "../../newOrder.model";
import { NewOrderValidator } from "../../newOrder.validator";
import { IAcuteOrderDetail } from "../../newOrderAcuteTransition/newOrderAcuteTransition.interface";
import { defaultNewOrderWoundInfoData } from "../../newOrderWoundInfoStepper/newOrderWoundInfo.model";
import {
  mapWoundDimensionLength,
  mapWoundDimensionWidth,
  mapWoundDimensionDepth,
} from "../VacOrderSummary/newOrderResponseMapper";
import { IPrescriberModal } from "../../prescriberInformation/prescriberSearch/prescriberSearch.model";

export const mapTransitionOrderResponse = async (
  transitionOrderResponserInfo: IAcuteOrderDetail,
  Validator = new NewOrderValidator()
) => {
  let defaultOrderData: INewOrder = getDeepClone(defaultNewOrderData);
  let primaryWound = transitionOrderResponserInfo.wounds[0];
  let newOrder: INewOrder = {
    ...defaultOrderData,
    firstName: {
      ...defaultOrderData.firstName,
      valid: validate(
        Validator,
        "patientinfofirstName",
        transitionOrderResponserInfo.patientFirstName
      )!,
      value: makeCapitalEachWordInString(
        transitionOrderResponserInfo.patientFirstName
      ),
    },
    lastName: {
      ...defaultOrderData.lastName,
      valid: validate(
        Validator,
        "patientinfolastName",
        transitionOrderResponserInfo.patientLastName
      )!,
      value: makeCapitalEachWordInString(
        transitionOrderResponserInfo.patientLastName
      ),
    },
    dob: {
      ...defaultOrderData.dob,
      valid: validate(
        Validator,
        "dob",
        transitionOrderResponserInfo.patientDOB
      )!,
      value: transitionOrderResponserInfo.patientDOB,
    },
    wasNPWTInitiated: {
      ...defaultOrderData.wasNPWTInitiated,
      valid: ValidationStatus.VALID,
      value: "yes",
    },
    dateInitiated: {
      ...defaultOrderData.dateInitiated,
      required: true,
      valid: true
        ? validate(
            Validator,
            "dateInitiated",
            transitionOrderResponserInfo?.therapyDate
          )!
        : ValidationStatus.VALID,
      value: transitionOrderResponserInfo?.therapyDate,
    },
    previousRo: {
      ...defaultOrderData.previousRo,
      valid: ValidationStatus.VALID,
      value: transitionOrderResponserInfo?.orderNumber,
    },
    woundType: {
      ...defaultOrderData.woundType,
      valid: validate(
        Validator,
        "woundType",
        primaryWound && primaryWound.type === null
          ? ""
          : primaryWound && primaryWound.type?.toString()
      )!,
      value:
        primaryWound && primaryWound.type === null
          ? ""
          : primaryWound && primaryWound.type?.toString(),
      required: true,
    },
    woundLocation: {
      ...defaultOrderData.woundLocation,
      valid: validate(
        Validator,
        "woundLocation",
        primaryWound && primaryWound.location === null
          ? ""
          : primaryWound && primaryWound.location?.toString()
      )!,
      value:
        primaryWound && primaryWound.location === null
          ? ""
          : primaryWound && primaryWound.location?.toString(),
      required: true,
    },
  };
  return newOrder;
};
const validate = (
  validator: NewOrderValidator,
  Name: string,
  Value: string
) => {
  let notNullValue = Value === null ? "" : Value;
  const Status = validator?.validate(notNullValue, Name)?.status;
  return Status === ValidationStatus.INVALID
    ? ValidationStatus.UNTOUCHED
    : Status;
};

export const mapCreateTransitionOrderStoredWoundResponse = (
  actureOrderDetails: IAcuteOrderDetail,
  validator = new NewOrderValidator()
) => {
  let woundData = getDeepClone(defaultNewOrderWoundInfoData);
  let primaryWound = actureOrderDetails.wounds[0];
  let secondaryWound = actureOrderDetails.wounds[1];
  let transitionWoundData: any = {
    ...woundData,
    woundType: {
      ...woundData.woundType,
      valid: validate(
        validator,
        "woundType",
        primaryWound && primaryWound.type === null ? "" : primaryWound.type
      )!,
      value:
        primaryWound && primaryWound.type === null ? "" : primaryWound.type,
      required: true,
    },
    woundLocation: {
      ...woundData.woundLocation,
      valid: validate(
        validator,
        "woundLocation",
        primaryWound && primaryWound.location === null
          ? ""
          : primaryWound.location
      )!,
      value:
        primaryWound && primaryWound.location === null
          ? ""
          : primaryWound.location,
      required: true,
    },
    woundOrientation: {
      ...woundData.woundOrientation,
      valid: validate(
        validator,
        "woundOrientation",
        primaryWound && primaryWound.orientation === null
          ? ""
          : primaryWound.orientation
      )!,
      value:
        primaryWound && primaryWound.orientation === null
          ? ""
          : primaryWound.orientation,
      required: false,
    },
    woundDirection: {
      ...woundData.woundDirection,
      valid: validate(
        validator,
        "woundDirection",
        primaryWound && primaryWound.direction === null
          ? ""
          : primaryWound.direction
      )!,
      value:
        primaryWound && primaryWound.direction === null
          ? ""
          : primaryWound.direction,
      required: false,
    },
    woundMeasurementDate: {
      ...woundData.woundMeasurementDate,
      valid: validate(
        validator,
        "woundMeasurementDate",
        primaryWound && primaryWound.evaluationDate === null
          ? ""
          : primaryWound.evaluationDate
      )!,
      value: moment(primaryWound?.evaluationDate ?? "").format(
        "YYYY-MM-DDTHH:mm:ss"
      ),
      required:
        primaryWound && primaryWound.evaluationDate === null ? false : true,
    },
    woundLength: mapWoundDimensionLength(primaryWound, validator, woundData),
    woundWidth: mapWoundDimensionWidth(primaryWound, validator, woundData),
    woundDepth: mapWoundDimensionDepth(primaryWound, validator, woundData),
    isShowSecondaryWoundInfo: {
      valid: ValidationStatus.UNTOUCHED,
      value: secondaryWound && secondaryWound.type !== null ? "Yes" : "No",
      title: "Secondary Wound Info",
    },
  };
  return transitionWoundData;
};

export const mapCreateTransitionSecondaryStoredWoundIformation = (
  data: IAcuteOrderDetail,
  validator = new NewOrderValidator()
) => {
  let secondaryWound = data.wounds[1];
  let secondaryWoundDetails: any = getDeepClone(
    defaultNewOrdeSecondaryWoundInfoData
  );
  secondaryWoundDetails = {
    ...secondaryWoundDetails,
    woundType: {
      ...secondaryWoundDetails.woundType,
      valid: validate(
        validator,
        "woundType",
        secondaryWound && secondaryWound.type === null
          ? ""
          : secondaryWound.type
      )!,
      value:
        secondaryWound && secondaryWound.type === null
          ? ""
          : secondaryWound.type,
      required: true,
    },
    woundLocation: {
      ...secondaryWoundDetails.woundLocation,
      valid: validate(
        validator,
        "woundLocation",
        secondaryWound && secondaryWound.location === null
          ? ""
          : secondaryWound.location
      )!,
      value:
        secondaryWound && secondaryWound.location === null
          ? ""
          : secondaryWound.location,
      required: true,
    },
    woundOrientation: {
      ...secondaryWoundDetails.woundOrientation,
      valid: validate(
        validator,
        "woundOrientation",
        secondaryWound && secondaryWound.orientation === null
          ? ""
          : secondaryWound.orientation
      )!,
      value:
        secondaryWound && secondaryWound.orientation === null
          ? ""
          : secondaryWound.orientation,
      required: false,
    },
    woundDirection: {
      ...secondaryWoundDetails.woundDirection,
      valid: validate(
        validator,
        "woundDirection",
        secondaryWound && secondaryWound.direction === null
          ? ""
          : secondaryWound.direction
      )!,
      value:
        secondaryWound && secondaryWound.direction === null
          ? ""
          : secondaryWound.direction,
      required: false,
    },
    woundMeasurementDate: {
      ...secondaryWoundDetails.woundMeasurementDate,
      valid: validate(
        validator,
        "woundMeasurementDate",
        secondaryWound && secondaryWound.evaluationDate === null
          ? ""
          : secondaryWound.evaluationDate
      )!,
      value: moment(secondaryWound?.evaluationDate ?? "").format(
        "YYYY-MM-DDTHH:mm:ss"
      ),
      required:
        secondaryWound && secondaryWound.evaluationDate === null ? false : true,
    },
    woundLength: mapWoundDimensionLength(
      secondaryWound,
      validator,
      secondaryWound
    ),
    woundWidth: mapWoundDimensionWidth(
      secondaryWound,
      validator,
      secondaryWoundDetails
    ),
    woundDepth: mapWoundDimensionDepth(
      secondaryWound,
      validator,
      secondaryWoundDetails
    ),
  };
  return secondaryWoundDetails;
};

export const mappedPrescriberInformation = (
  prescriberNPIResponse: any
): IPrescriberModal => {
  let prescriberData: IPrescriberModal = {
    firstName: prescriberNPIResponse?.items[0].firstName ?? "",
    lastName: prescriberNPIResponse?.items[0].lastName ?? "",
    npi: prescriberNPIResponse?.items[0].npi ?? "",
    city: prescriberNPIResponse?.items[0].city ?? "",
    state: prescriberNPIResponse?.items[0].state ?? "",
    telephoneNumber: prescriberNPIResponse?.items[0].telephoneNumber ?? "",
    zipCode: prescriberNPIResponse?.items[0].zipCode ?? "",
    address1: prescriberNPIResponse?.items[0].address1 ?? "",
    address2: prescriberNPIResponse?.items[0].address2 ?? "",
    faxNumber: prescriberNPIResponse?.items[0].faxNumber ?? "",
  };
  return prescriberData;
};
