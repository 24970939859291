import { Button, Grid, Typography, useMediaQuery } from "@mui/material";
import { IVACTherapyLocationReview } from "./VACTherapyLocationReview.interface";
import "./VACTherapyLocationReview.css";

export const VacTherapyLocationReview = ({
  data,
  isOrderSummary = false,
  editButtonClicked,
}: IVACTherapyLocationReview) => {
  const isMobileScreen = useMediaQuery("(max-width:600px)");
  return (
    <div className="vac-therapy-location-container">
      <div className="vac-location-component-title">
        <h2
          className="vac-location-review-order-title"
          data-testid="vac-location-review-order-title"
        >
          Location of V.A.C.<sup>®</sup> Therapy Use
        </h2>
        {!isOrderSummary && (
          <Button
            classes={{ root: "vac-location-review-order-edit-button" }}
            data-testid="vac-location-review-order-edit-button"
            onClick={editButtonClicked}
          >
            Edit
          </Button>
        )}
      </div>
      <Grid container xs={12} className="conatiner-vac-location-review">
        <Grid item xs={isMobileScreen ? 12 : 6}>
          <Typography
            className="vac-location-review-order-content-title"
            data-testid="vac-location-review-heading"
          >
            V.A.C.<sup>®</sup> Therapy Location Type
          </Typography>
          <Typography
            className="vac-therapy-loc-value"
            data-testid="vac-therapy-loc-drop-down-value"
          >
            {data.vacTherapyLocation && data.vacTherapyLocation.value}
          </Typography>
        </Grid>
        <Grid item xs={isMobileScreen ? 12 : 6}>
          {data.vacTherapyLocation &&
            data.vacTherapyLocation.value === "Other" && (
              <div className="vac-location-review-content">
                <Typography className="additional-review-order-content-title">
                  Additional Details
                </Typography>

                <Typography
                  className="vac-therapy-loc-value"
                  data-testid="vac-therapy-loc-additional-value"
                >
                  {data.otherVacUseLocation && data.otherVacUseLocation.value}
                </Typography>
              </div>
            )}
        </Grid>
      </Grid>
    </div>
  );
};
