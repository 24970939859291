import "./inputWithLabel.css";
import {
  AsteriskSpan,
  BootstrapInput,
  BootstrapInputDropdown,
  StyledLabel,
} from "./inputWithLabel.style";
import { FormControl, Tooltip } from "@mui/material";
import { IInputWithLabel } from "./inputWithLabel.interface";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { ReactComponent as WarningIcon } from "../../assets/WarningIndicator.svg";

export const InputWithLabel = ({
  children,
  componentClassName = "labelRoot",
  error,
  errorMessage,
  id,
  isRequired,
  isShowTooltip = false,
  isShowWarning = false,
  label,
  labelClassName,
  showLabel = true,
  sx,
  testId,
  tooltipHeader,
  tooltipContent,
  warningMessage,
  warning_icon_div_custom_class,
  isDropdown = false,
}: IInputWithLabel) => {
  const requiredAsterisk =
    isRequired && label && label !== "" ? <AsteriskSpan>*</AsteriskSpan> : null;

  const toolTip = isShowTooltip ? (
    <Tooltip
      classes={{ tooltip: "tooltip", popper: "popper" }}
      title={
        <div className="tooltip-content">
          <div className="tooltip-header">{tooltipHeader}</div>
          <div className="tooltip-body">{tooltipContent}</div>
        </div>
      }
    >
      <InfoOutlinedIcon
        color="primary"
        classes={{ root: "tooltipAddressIcon" }}
      />
    </Tooltip>
  ) : null;

  return (
    <FormControl
      className={componentClassName}
      data-testid={"formControl-" + testId}
      error={error}
      id={id ?? ""}
      sx={Object.assign({}, { width: "100%" }, sx)}
      variant="standard"
    >
      {showLabel && (
        <StyledLabel
          shrink={true}
          margin={"dense"}
          className={labelClassName}
          data-testid={testId}
        >
          {label}
          {requiredAsterisk}
          {toolTip}
        </StyledLabel>
      )}
      {isDropdown && (
        <BootstrapInputDropdown> {children} </BootstrapInputDropdown>
      )}
      {!isDropdown && <BootstrapInput> {children} </BootstrapInput>}
      {errorMessage && (
        <span
          style={{
            color: "#d32f2f",
            fontSize: "12px",
            lineHeight: "14px",
            marginTop: "5px",
          }}
        >
          {errorMessage}
        </span>
      )}
      {error && isShowWarning && warningMessage && (
        <div
          className={`error-with-warning-icon-div ${warning_icon_div_custom_class}`}
        >
          <span className="warning-icon ">
            <WarningIcon className="warning-icon-image" />
          </span>
          <p className="error-with-warning-icon">{warningMessage}</p>
        </div>
      )}
    </FormControl>
  );
};
