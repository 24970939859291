export enum UpdateContactInfoPopUpSection {
  NONE = "none",
  LOADER = "loader",
  AUTHENTICATE_VIA_EMAIL = "authenticateviaemail",
  AUTHENTICATE_VIA_SMS = "authenticateviasms",
  CONFIRM_LEAVE = "confirmleave",
  SUCCESS = "success",
  FAILURE = "failure",
  LANDLINEWARNINGMODAL = "landlinewarn",
}
