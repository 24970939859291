import "./billingAddress.css";
import { ReactNode } from "react";
import {
  IBillingAddress,
  IBillingAddressProps,
} from "./billingAddress.interface";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { ERROR_TO_SELECT_ANY_OPTION } from "../../../../util/errorMsg";
import { ValidationStatus } from "../../../../core/interfaces/input.interface";
import {
  getAddress,
  makeCapitalEachWordInString,
} from "../../../../util/utilityFunctions";
import { ReactComponent as RadioButtonIcon } from "../../../../assets/radioButton.svg";
import { InputWithLabel } from "../../../../core/inputWithLabel/inputWithLabel.component";
import { ReactComponent as SelectedRadioButtonIcon } from "../../../../assets/selectedRadioButton.svg";
import { BillingAddressReviewOrder } from "./reviewOrder/billingAddressReviewOrder.component";

export const BillingAddress = ({
  addresses,
  data,
  editButtonAction,
  isReviewOrder = false,
  isSummaryOrder = false,
  updateSolventumProduct,
}: IBillingAddressProps) => {
  const getAddressForReviewOrder = (): string => {
    const index = addresses.findIndex(
      (address: IBillingAddress) =>
        address.billToSiteId.toString() === data.billingAddress.value
    );
    if (index > -1) {
      const selectedBillingAddress = addresses[index];
      const fullAddress = getAddress(selectedBillingAddress);
      return `${makeCapitalEachWordInString(
        selectedBillingAddress.name
      )}, ${fullAddress}`;
    }
    return "";
  };

  const validateAndSetData = (e: any) => {
    let { value } = e.target;
    updateSolventumProduct(data, value);
  };

  const getBillingAddress = (billingAddress: IBillingAddress): ReactNode => {
    let address = getAddress(billingAddress);
    return (
      <div className="billing-address-title">
        <p
          className="billing-address-name"
          data-testid="billing-address-name"
          id="billing-address-name"
        >
          {makeCapitalEachWordInString(`${billingAddress.name}, `)}
          <span
            className="billing-address-details"
            data-testid="billing-address-details"
            id="billing-address-details"
          >
            {address}
          </span>
        </p>
      </div>
    );
  };

  return (
    <div
      className="in-patient-order-billing-address-component"
      data-testid="in-patient-order-billing-address-component"
    >
      {isReviewOrder ? (
        <BillingAddressReviewOrder
          address={getAddressForReviewOrder()}
          editButtonAction={editButtonAction}
          isSummaryOrder={isSummaryOrder}
        />
      ) : (
        <>
          <p
            className="in-patient-order-billing-address-title"
            data-testid="in-patient-order-billing-address-title"
            id="in-patient-order-billing-address-title"
          >
            Billing Address
          </p>
          <div
            className="in-patient-order-billing-address-div"
            data-testid="in-patient-order-billing-address-div"
          >
            <InputWithLabel
              error={data.billingAddress.valid === ValidationStatus.INVALID}
              id={data.billingAddress.componentId!}
              isRequired={true}
              isShowWarning={true}
              label={data.billingAddress.title}
              labelClassName={`billing-address-header-title${
                data.billingAddress.valid === ValidationStatus.INVALID
                  ? " error-label"
                  : ""
              }`}
              testId="billing-address-radio-button"
              warningMessage={
                data.billingAddress.errorMessage
                  ? ERROR_TO_SELECT_ANY_OPTION
                  : ""
              }
            >
              <div
                className="in-patient-order-billing-address-radio-div"
                data-testid="in-patient-order-billing-address-radio-div"
              >
                <RadioGroup
                  classes={{ root: "radioRoot" }}
                  onChange={validateAndSetData}
                  name="billingAddress"
                  value={data.billingAddress.value}
                >
                  {addresses.map((address: IBillingAddress, index: number) => {
                    return (
                      <FormControlLabel
                        classes={{
                          root: "optionRoot",
                        }}
                        componentsProps={{
                          typography: {
                            classes: {
                              root: "optiontxt",
                            },
                          },
                        }}
                        control={
                          <Radio
                            icon={<RadioButtonIcon />}
                            checkedIcon={<SelectedRadioButtonIcon />}
                          />
                        }
                        data-testid={`billing-address-radio-option-${
                          index + 1
                        }`}
                        key={address.billToSiteId}
                        label={getBillingAddress(address)}
                        value={address.billToSiteId}
                      />
                    );
                  })}
                </RadioGroup>
              </div>
            </InputWithLabel>
          </div>
        </>
      )}
    </div>
  );
};
