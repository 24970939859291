import { AuthContext, AuthContextType } from "../../context/AuthContext";
import { useContext, useEffect, useState } from "react";
import "./facilityBanner.css";
import { IFacility } from "../manageProfile/facilityInformation/facility.interface";
import { fetchUserLinkedFacilities } from "../../util/userService";
import { FacilityBannerAdmin } from "./facilityBannerAdmin/facilityBannerAdmin.component";
import {
  RolesPermissionContext,
  RolesPermissionContextType,
} from "../../context/RolesPermissionContext";
import {
  InternalSignOnContext,
  InternalSignOnContextType,
} from "../../context/InternalSignOnContext";

type Props = {
  isComponentVisibleTest?: boolean;
  isLoadUI?: boolean;
};
export const FacilityBanner = ({
  isComponentVisibleTest = false,
  isLoadUI = false,
}: Props) => {
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );
  const [userLinkedFacilityData, setUserLinkedFacilityData] =
    useState<IFacility[]>();
  const [loadUI, setLoadUI] = useState<boolean>(isLoadUI);
  const internalObj = useContext<InternalSignOnContextType | null>(
    InternalSignOnContext
  );

  const loadUserLinkedFacilities = async () => {
    if (authObj && authObj.isLoggedIn) {
      if (authObj.isInternalUser) {
        if (authObj.registeredFaciltyAddress) {
          setUserLinkedFacilityData([authObj.registeredFaciltyAddress]);
          authObj.setAllFacilties([authObj.registeredFaciltyAddress]);
          setLoadUI(true);
        }
      } else {
        const responseObj = await fetchUserLinkedFacilities();
        setUserLinkedFacilityData(
          responseObj.sort((a: any, b: any) =>
            a.accountName.localeCompare(b.accountName)
          )
        );
        authObj?.setAllFacilties(responseObj);
        setLoadUI(true);
      }
    }
  };
  useEffect(() => {
    loadUserLinkedFacilities();
  }, [authObj?.registeredFaciltyAddress]);

  return (
    <>
      {loadUI &&
      !(
        permissionObj &&
        permissionObj.mappedRolesPermissionData.IsClinicianRole &&
        userLinkedFacilityData &&
        userLinkedFacilityData.length <= 1
      )
        ? !internalObj?.isVACOrderSSO && (
            <div className="facilityBanner" data-testid="facility-Banner">
              {authObj?.userRolesAndPermissionLoaded &&
                userLinkedFacilityData &&
                userLinkedFacilityData.length > 0 && (
                  <FacilityBannerAdmin
                    userLinkedFacilityData={userLinkedFacilityData}
                  />
                )}
            </div>
          )
        : null}
    </>
  );
};
