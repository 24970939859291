import { useContext } from "react";
import { ExpressButton } from "../../../core/expressButton/expressButton.component";
import "./userConfirmation.css";
import {
  RolesPermissionContext,
  RolesPermissionContextType,
} from "../../../context/RolesPermissionContext";
import {
  InternalSignOnContext,
  InternalSignOnContextType,
} from "../../../context/InternalSignOnContext";

interface Props {
  closeModal: any;
  handleConfirmNavigationClick: any;
  handleSaveExitNavigationClick: any;
}
const UserConfirmationDialogue = ({
  closeModal,
  handleConfirmNavigationClick,
  handleSaveExitNavigationClick,
}: Props) => {
  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );
  const internalObj = useContext<InternalSignOnContextType | null>(
    InternalSignOnContext
  );
  return (
    <>
      <div
        className={
          internalObj?.isVACOrderSSO
            ? "saveOrderConfirmPopup saveOrderConfirmLandingPage"
            : "saveOrderConfirmPopup"
        }
        data-testid="save-order-confirm-div-child"
      >
        <h2
          className={
            internalObj?.isVACOrderSSO
              ? "header-title header-title-LandingPage"
              : "header-title"
          }
          data-testid="header-title"
        >
          {internalObj?.isVACOrderSSO
            ? "Are you sure you want to exit this order?"
            : "You haven't saved your order"}
        </h2>
        <h5
          className={
            internalObj?.isVACOrderSSO
              ? "header-sub-title header-sub-title-LandingPage"
              : "header-sub-title"
          }
          data-testid="header-sub-title"
        >
          {internalObj?.isVACOrderSSO
            ? " If you exit, any information you have entered for this order will be lost."
            : " If you exit without saving, any changes you have made to the order will be lost."}
        </h5>
        <div className="return-to-order-entry">
          <ExpressButton
            parentClass={
              internalObj?.isVACOrderSSO
                ? "return-to-order-entry-button-LandingPage"
                : "return-to-order-entry-button"
            }
            variant={internalObj?.isVACOrderSSO ? "contained" : "outlined"}
            clickHandler={() => closeModal()}
            testId="button-1"
            sx={{
              textTransform: "none", // Override the textTransform property
            }}
          >
            Return to Order Entry
          </ExpressButton>
        </div>
        <div className="exit-without-saving">
          <ExpressButton
            parentClass="exit-without-saving-button"
            variant="outlined"
            testId="button-2"
            clickHandler={() => handleConfirmNavigationClick()}
          >
            {internalObj?.isVACOrderSSO
              ? "Exit and Delete Order"
              : "Exit Without Saving"}
          </ExpressButton>
        </div>
        {!internalObj?.isVACOrderSSO && (
          <div className="save-and-exit">
            <ExpressButton
              parentClass="save-and-exit-button"
              variant="contained"
              testId="button-3"
              clickHandler={() => handleSaveExitNavigationClick()}
              disabled={permissionObj?.mappedRolesPermissionData.IsSupportRole}
              sx={{
                textTransform: "none", // Override the textTransform property
              }}
            >
              Save and Exit
            </ExpressButton>
          </div>
        )}
      </div>
    </>
  );
};
export default UserConfirmationDialogue;
