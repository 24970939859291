import "./customTag.css";

interface Props {
  tagText: string;
  tagClassName: string;
}

export const CustomTag = ({ tagText, tagClassName }: Props) => {
  return (
    <div className={`${tagClassName}_tag_bg`}>
      <div className={`${tagClassName}_tag`}>{tagText}</div>
    </div>
  );
};
