import { ReactNode, useState } from "react";
import { IPatient } from "../patient.interface";
import { PageSection } from "./addPatientContainer.enum";
import { AddPatient } from "../addPatient/addPatient.component";
import { PatientFound } from "../patientFound/patientFound.component";
import { IAddPatientContainer } from "./addPatientContainer.interface";
import { PatientNotFound } from "../patientNotFound/patientNotFound.component";
import { ChoosePatientType } from "../choosePatientType/choosePatientType.component";
import { PatientErrorInSearchAdd } from "../patientErrorInSearchAdd/patientErrorInSearchAdd.component";

export const AddPatientContainer = ({
  defaultPageSection = PageSection.SEARCH_FORM,
}: IAddPatientContainer) => {
  const [currentSection, setCurrentSection] =
    useState<PageSection>(defaultPageSection);
  const [foundPatients, setFoundPatients] = useState<IPatient[] | null>(null);
  const [errorCode, setErrorCode] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isShowPhoneNumber, setIsShowPhoneNumber] = useState<boolean>(false);

  const redirectHandler = (
    changeSectionTo: PageSection,
    foundPatientList?: IPatient[] | null,
    errorCode?: string,
    errorMessage?: string,
    isShowPhoneNumber?: boolean
  ): void => {
    if (foundPatientList) {
      setFoundPatients(foundPatientList);
    }
    if (errorCode) {
      setErrorCode(errorCode);
    }
    if (errorMessage) {
      setErrorMessage(errorMessage);
    }
    if (isShowPhoneNumber) {
      setIsShowPhoneNumber(isShowPhoneNumber);
    }
    if (
      (changeSectionTo === PageSection.FOUND && foundPatientList) ||
      changeSectionTo !== PageSection.FOUND
    ) {
      setCurrentSection(changeSectionTo);
    }
  };

  const sectionToDisplay = () => {
    let page: ReactNode;
    switch (currentSection) {
      case PageSection.PATIIENT_TYPE:
        page = <ChoosePatientType redirectHandler={redirectHandler} />;
        break;
      case PageSection.FOUND:
        page = (
          <PatientFound
            isAcuteFlow={defaultPageSection === PageSection.PATIIENT_TYPE}
            patients={foundPatients!}
            redirectHandler={redirectHandler}
          />
        );
        break;
      case PageSection.NOT_FOUND:
        page = (
          <PatientNotFound
            isAcuteFlow={defaultPageSection === PageSection.PATIIENT_TYPE}
            redirectHandler={redirectHandler}
          />
        );
        break;
      case PageSection.SEARCH_FORM:
        page = <AddPatient redirectHandler={redirectHandler} />;
        break;
      case PageSection.ERROR_FORM:
        page = (
          <PatientErrorInSearchAdd
            redirectHandler={redirectHandler}
            errorCode={errorCode}
            errorMessage={errorMessage}
            isShowPhoneNumber={isShowPhoneNumber}
          />
        );
        break;
    }
    return page;
  };
  return sectionToDisplay();
};
