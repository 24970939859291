import { Grid, TextField, useMediaQuery } from "@mui/material";
import "../endTherapyPopUp/endTherapyPopup.css";
import { InputWithLabel } from "../../../../../core/inputWithLabel/inputWithLabel.component";
import { CustomDropDown } from "../../../../../core/customDropdown/customDropdown.component";
import { useContext, useState } from "react";
import {
  IInputField,
  ValidationStatus,
} from "../../../../../core/interfaces/input.interface";
import { ExpressButton } from "../../../../../core/expressButton/expressButton.component";
import { STATIC_TEXT_END_THERAPY_POPUP_DESC } from "../../../../../util/staticText";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import React from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { ICustomerPlusListingDetails } from "../../../customerPlusInventory/customerPlusInventory.interface";
import moment from "moment";
import { EndTherapyStaticData } from "../endTherapyPopUpStaticData/endTherapyPopUpStaticData.component";
import { ReactComponent as CalendarIcon } from "../../../../../assets/calendar.svg";
import "./makeUnitAvailable.css";
import {
  CustomerPlusFlow,
  InventoryPopUpFlow,
} from "../../inventoryPopUpContainer.enum";
import {
  changeDateFormat,
  convertStringToDate,
} from "../../../../../util/utilityFunctions";
import { MakeUnitAvailableValidator } from "./makeUnitAvailableValidator";
import {
  RolesPermissionContextType,
  RolesPermissionContext,
} from "../../../../../context/RolesPermissionContext";

interface IInventoryMakeUnitAvailableProps {
  selectedProduct: ICustomerPlusListingDetails;
  storageLocations: string[];
  closePopUp: Function;
  ChangeStatusBtn: any;
  makeUnitAvailabledata: any;
  makeUnitAvailableSetData: any;
  Validator?: MakeUnitAvailableValidator;
}

const MakeAvailableForPlacement = ({
  selectedProduct,
  storageLocations,
  closePopUp,
  ChangeStatusBtn,
  makeUnitAvailabledata,
  makeUnitAvailableSetData,
  Validator = new MakeUnitAvailableValidator(),
}: IInventoryMakeUnitAvailableProps) => {
  const [validator] = useState<MakeUnitAvailableValidator>(Validator);
  const isMobileScreen = useMediaQuery("(max-width:374px)");
  const [focusClasses, setFocusClasses] = useState({
    dateAvailableForPlacement: "",
  });
  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );
  const cancelButtonAction = () => {
    closePopUp();
  };
  const setClasses = (e: any, classname: string) => {
    setFocusClasses(
      Object.assign({}, focusClasses, { [e.target.name]: classname })
    );
  };
  const validateAndSetDate = (value: any) => {
    const formattedDate = value === null ? null : convertStringToDate(value);
    const isValid = validator?.validate(
      formattedDate!,
      "dateAvailableForPlacement"
    );
    makeUnitAvailableSetData({
      ...makeUnitAvailabledata,
      dateAvailableForPlacement: {
        ...makeUnitAvailabledata.dateAvailableForPlacement,
        value: formattedDate,
        valid: isValid?.status!,
      },
    });
  };

  const validateAndSetDropDownData = (event: any) => {
    const isValid = validator?.validate(event.target.value, "storageLocations");
    makeUnitAvailableSetData({
      ...makeUnitAvailabledata,
      storageLocations: {
        ...makeUnitAvailabledata.storageLocations,
        value: event.target.value,
        valid: isValid?.status!,
      },
    });
  };

  return (
    <div className="makeUnitAvailable-main-component">
      <p
        className="makeUnitAvailable-title"
        data-testid="makeUnitAvailable-title-test"
        id="makeUnitAvailable-title-id"
      >
        Make Unit Available for Placement
      </p>
      <div className="makeUnitAvailable-sub-component">
        <Grid
          className="makeUnitAvailable-grid-container"
          container
          spacing={1}
        >
          <Grid xs={12}>
            <span
              className="makeUnitAvailable-div-desc"
              data-testid="makeUnitAvailable-desc-test"
              id="makeUnitAvailable-desc-id"
            >
              The Make Unit Available for Placement process will place the
              prepared unit back in your list of available units.
            </span>
          </Grid>
          <Grid xs={12}>
            <EndTherapyStaticData
              endTherapyData={selectedProduct}
              isComingFromMakeUnitAvailable={true}
            />
          </Grid>
          <Grid xs={6} className="makeUnitAvailable-date-container">
            <div className="makeUnitAvailable-date-main-div">
              <InputWithLabel
                labelClassName={focusClasses.dateAvailableForPlacement}
                label="Date Available for Placement"
                isRequired={true}
                error={
                  makeUnitAvailabledata?.dateAvailableForPlacement?.valid ===
                  ValidationStatus.INVALID
                }
              >
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    components={{ OpenPickerIcon: CalendarIcon }}
                    InputAdornmentProps={{
                      classes: {
                        root: "adornedRoot",
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: `dateAvailableForPlacement ${
                          makeUnitAvailabledata?.dateAvailableForPlacement
                            .valid === ValidationStatus.INVALID
                            ? "showError"
                            : "noError"
                        }`,
                        input: "input",
                        notchedOutline: "outline",
                      },
                    }}
                    onChange={(value: any) => validateAndSetDate(value)}
                    renderInput={(params: any) => {
                      params.error = false;
                      params.inputProps!.placeholder = "__/__/____";
                      return (
                        <TextField
                          id={
                            makeUnitAvailabledata?.dateAvailableForPlacement.id!
                          }
                          data-testid="makeUnitAvailable-date-test"
                          name="dateAvailableForPlacement"
                          onFocus={(e) => setClasses(e, "Mui-focused")}
                          onBlur={(e) => setClasses(e, "")}
                          {...params}
                        />
                      );
                    }}
                    value={
                      makeUnitAvailabledata?.dateAvailableForPlacement?.value
                    }
                    maxDate={moment()}
                  />
                </LocalizationProvider>
              </InputWithLabel>
            </div>
          </Grid>
          <Grid className="makeunitAvailable-grid-item" xs={12}>
            <InputWithLabel
              isDropdown={true}
              label="Storage Location"
              labelClassName="storage-location-title"
              isRequired={true}
              testId="makeUnitAvailable-dropdown"
              error={
                makeUnitAvailabledata?.storageLocations.valid ===
                ValidationStatus.INVALID
              }
            >
              <CustomDropDown
                handleChange={(e: any) => validateAndSetDropDownData(e)}
                menuItem={storageLocations}
                placeHolder="Select Storage Location"
                name="storageLocation"
                selectpropsClassName={
                  makeUnitAvailabledata?.storageLocations.value
                    ? "makeUnitAvailable-information-select"
                    : "placeHolder"
                }
                selectClassName={
                  makeUnitAvailabledata?.storageLocations.value
                    ? "makeUnitAvailable-information-input"
                    : "placeHolder"
                }
                testId="MakeunitAvailable-storage-location-dropdown-test"
                value={makeUnitAvailabledata?.storageLocations?.value}
              />
            </InputWithLabel>
          </Grid>
          <Grid xs={12} className="MakeunitAvailable-buttons-container">
            <Grid item xs={isMobileScreen ? 12 : 6}>
              <ExpressButton
                testId="MakeunitAvailable-cancel-button-test"
                clickHandler={cancelButtonAction}
                variant="outlined"
                sx={{ width: "100%" }}
              >
                Cancel
              </ExpressButton>
            </Grid>
            <Grid item xs={isMobileScreen ? 12 : 6}>
              <ExpressButton
                testId="MakeunitAvailable-changeStatus-button-test"
                variant="contained"
                sx={{ width: "100%" }}
                clickHandler={ChangeStatusBtn}
                disabled={
                  permissionObj?.mappedRolesPermissionData.IsSupportRole
                }
              >
                Change Status
              </ExpressButton>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default MakeAvailableForPlacement;
