import { Grid, useMediaQuery } from "@mui/material";
import "./estimatedArrivalTimeReviewRequest.css";
import { ICreateServiceRequest } from "../../../createServiceRequest.interface";
import {
  formatPhoneNumber,
  makeCapitalEachWordInString,
} from "../../../../../../util/utilityFunctions";
import { IAcuteOrderPickUpRequest } from "../../../../../acutePickUpRequest/acuteOrderPickUpRequest.interface";

export interface IEstimatedArrivalTimeReviewRequest {
  data: ICreateServiceRequest | IAcuteOrderPickUpRequest;
  editButtonAction?: any;
  isReviewSummary?: boolean;
  isCustomerOwnedProduct?: boolean;
}

export const EstimatedArrivalTimeReviewRequest = ({
  data,
  editButtonAction,
  isReviewSummary = false,
  isCustomerOwnedProduct = false,
}: IEstimatedArrivalTimeReviewRequest) => {
  const isSmallerMobileScreen = useMediaQuery("(max-width:430px)");

  const showTitleAndValue = (
    title: string,
    value: string | null,
    testId: string
  ) => {
    return (
      <div className="show-title-and-value-div">
        <p
          className="show-title-and-value-title"
          data-testid={`${testId}-title`}
          id={`${testId}-title-id`}
        >
          {title}
        </p>
        <p
          className="show-title-and-value-value"
          data-testid={`${testId}-value`}
          id={`${testId}-value-id`}
        >
          {value && value !== "" ? value.trim() : "--"}
        </p>
      </div>
    );
  };
  return (
    <>
      {!isCustomerOwnedProduct && (
        <div className="estimated-arrival-time-review-request-component">
          <div className="estimated-arrival-time-review-request-header">
            <p
              className="estimated-arrival-time-review-request-title"
              data-testid="estimated-arrival-time-review-request-title"
              id="estimated-arrival-time-review-request-title-id"
            >
              Estimated Arrival Time
            </p>
            {!isReviewSummary && (
              <button
                className="estimated-arrival-time-review-request-edit-button"
                data-testid="estimated-arrival-time-review-request-edit-button"
                id="estimated-arrival-time-review-request-edit-button-id"
                onClick={() =>
                  editButtonAction("estimated-arrival-time-component")
                }
              >
                Edit
              </button>
            )}
          </div>
          <Grid
            className="estimated-arrival-time-review-request-grid-container"
            container
          >
            <Grid
              className="estimated-arrival-time-review-request-grid-item"
              item
              xs={12}
            >
              {showTitleAndValue(
                "ETA Requested",
                makeCapitalEachWordInString(
                  data.isCallOnEstimatedArrivalTime.value
                ),
                "estimated-arrival-time-is-call-on-estimated-arrival-time"
              )}
            </Grid>
            {data.isCallOnEstimatedArrivalTime.value === "yes" && (
              <>
                <Grid
                  className="estimated-arrival-time-review-request-grid-item"
                  item
                  xs={isSmallerMobileScreen ? 12 : 6}
                >
                  {showTitleAndValue(
                    "Contact",
                    `${data.firstName.value.trim()} ${data.lastName.value.trim()}`,
                    "estimated-arrival-time-first-name"
                  )}
                </Grid>
                <Grid
                  className="estimated-arrival-time-review-request-grid-item"
                  item
                  xs={isSmallerMobileScreen ? 12 : 6}
                >
                  {showTitleAndValue(
                    data.department.title!,
                    data.department.value,
                    "estimated-arrival-time-department"
                  )}
                </Grid>
                {data.email && (
                  <Grid
                    className="estimated-arrival-time-review-request-grid-item"
                    item
                    xs={isSmallerMobileScreen ? 12 : 6}
                  >
                    {showTitleAndValue(
                      data.email?.title!,
                      data.email?.value,
                      "estimated-arrival-time-email"
                    )}
                  </Grid>
                )}
                <Grid
                  className="estimated-arrival-time-review-request-grid-item"
                  item
                  xs={isSmallerMobileScreen ? 12 : 6}
                >
                  {showTitleAndValue(
                    data.phoneNumber.title!,
                    formatPhoneNumber(data.phoneNumber.value),
                    "estimated-arrival-time-phone-number"
                  )}
                </Grid>
              </>
            )}
          </Grid>
        </div>
      )}
    </>
  );
};
