import {
  IPatient,
  IPatientAlert,
  ISeverityTypes,
} from "../../../patient.interface";
import { useHistory } from "react-router-dom";
import "./orderOverviewWoundProgressAlert.css";
import {
  WoundAssessmentContext,
  WoundAssessmentContextType,
} from "../../../../../context/WoundAssessmentContext";
import { useContext, useEffect, useState } from "react";
import { ExpressButton } from "../../../../../core/expressButton/expressButton.component";
import { WoundAssessmentPageSection } from "../../woundProgress/addWoundAssessment/woundAssessmentPageSection.enum";

interface Props {
  selectedPatientData: IPatient;
  selectedWoundId?: string | null;
  alertsForRO?: IPatient;
}

export const WoundProgressAlert = ({
  selectedPatientData,
  selectedWoundId,
  alertsForRO,
}: Props) => {
  const history = useHistory();
  const [measurementDue, setMeasurementDue] = useState<Array<IPatientAlert>>(
    []
  );
  const woundAssessmentObj = useContext<WoundAssessmentContextType | null>(
    WoundAssessmentContext
  );

  const handleClick = (
    woundOrderID: any,
    assessmentToDate: Date | null,
    assessmentFromDate: Date | null,
    alertID: string | null
  ) => {
    woundAssessmentObj?.resetAddWoundAssessmentData();
    history.push({
      pathname: "/addWoundAssessment",
      state: {
        ron: selectedPatientData?.roNumber,
        dob: selectedPatientData?.dob,
        woundOrderId: woundOrderID,
        assessmentToDate: assessmentToDate,
        assessmentFromDate: assessmentFromDate,
        selectedPatientData: selectedPatientData,
        alertID: alertID,
      },
    });
    woundAssessmentObj!.setWoundAssessmentPageSection(
      WoundAssessmentPageSection.WOUND_ASSESSMENT_FORM
    );
  };

  const getSeverityClassName = (severity: ISeverityTypes | null): string => {
    return severity != null
      ? severity === 0
        ? "low"
        : severity === 1
        ? "medium"
        : severity === 2
        ? "high"
        : ""
      : "";
  };

  useEffect(() => {
    let result: any;
    if (selectedWoundId) {
      result = alertsForRO?.alerts.filter(
        (item: any) =>
          item.alertName === "Measurements Due" &&
          item.woundOrderID === selectedWoundId?.toString()
      );
    } else {
      result = alertsForRO?.alerts.filter(
        (item: any) => item.alertName === "Measurements Due"
      );
    }
    if (result) {
      result.map((item: any) => {
        return (item.showAssessmentButton =
          item.assessmentFromDate <= new Date() ? true : false);
      });
      setMeasurementDue(
        sortAlertBanner(
          result.filter((item: any) => item.severity === 2)
        ).concat(
          sortAlertBanner(result.filter((item: any) => item.severity === 1)),
          sortAlertBanner(result.filter((item: any) => item.severity === 0))
        )
      );
    }
  }, [alertsForRO]);

  const sortAlertBanner = (severityAlert: any) => {
    severityAlert.sort((a: any, b: any) => {
      const today = new Date();
      const acurrentYear = new Date(a.assessmentToDate).getFullYear();
      const bcurrentYear = new Date(b.assessmentToDate).getFullYear();

      const aLabel = a.alertSubLabel.includes("Today")
        ? today.toISOString().substr(5, 5)
        : a.alertSubLabel.split("and")[1]
        ? a.alertSubLabel.split("and")[1].trim()
        : a.alertSubLabel.split("and")[1];
      const bLabel = b.alertSubLabel.includes("Today")
        ? today.toISOString().substr(5, 5)
        : b.alertSubLabel.split("and")[1]
        ? b.alertSubLabel.split("and")[1].trim()
        : b.alertSubLabel.split("and")[1];
      const aDate = new Date(`${acurrentYear}-${aLabel}`).getTime();
      const bDate = new Date(`${bcurrentYear}-${bLabel}`).getTime();
      return aDate - bDate;
    });
    return severityAlert;
  };

  return (
    <>
      {measurementDue
        ?.filter((alert) => alert.severity !== 0)
        .map((alert, index) => (
          <>
            <div
              key={index}
              className={`assessmentDueAlertText ${getSeverityClassName(
                alert.severity
              )}`}
            >
              <div
                className="assessmentDueAlertHeading"
                data-testid="assessment-due-heading"
              >
                <p
                  className={`assessmentDueAlertReasonParentClass ${getSeverityClassName(
                    alert.severity
                  )}`}
                >
                  Measurements Due
                </p>
                <p
                  className="assessmentDueAlertReasonText"
                  data-testid="assessment-due-text"
                >
                  {alert.alertSubLabel.toLowerCase().includes("today")
                    ? "Assessment is due today"
                    : "Assessment due for measurements taken between " +
                      alert.alertSubLabel +
                      "."}
                </p>
              </div>
              <div
                className="assessmentDueAlertBannerButton"
                data-testid="assessment-due-button"
              >
                {alert.showAssessmentButton && (
                  <ExpressButton
                    clickHandler={() =>
                      handleClick(
                        alert.woundOrderID,
                        alert.assessmentToDate!,
                        alert.assessmentFromDate!,
                        alert.alertID
                      )
                    }
                    parentClass={`assessmentDueAlertNavigationButton ${getSeverityClassName(
                      alert.severity
                    )}`}
                    variant="outlined"
                  >
                    Add Assessment
                  </ExpressButton>
                )}
              </div>
            </div>
          </>
        ))}
    </>
  );
};
export default WoundProgressAlert;
