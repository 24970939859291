import { IProofOfDeliverESign } from "./proofOfDeliveryESign.interface";
import { ValidationStatus } from "../../../../core/interfaces/input.interface";

export let defaultESignPatientDetails: IProofOfDeliverESign = {
  patientName: {
    value: "",
    valid: ValidationStatus.VALID,
    required: true,
  },
  updatedPatientEmail: {
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: true,
  },
};
