import { AuthContextType } from "../../../../../context/AuthContext";
import { IDropZoneDocumentSelect } from "../../../../../core/customDropZone/dropZoneDocumentSelect.interface";
import { formatISODate } from "../../../../../util/utilityFunctions";
import { ISalesAssistance } from "../../salesAssistanceNeedHelp.interface";
import { HelpNeededRadioSelection } from "../helpNeeded/helpNeeded.component";
import { ISalesAssistanceRequest } from "./salesAssistanceRequestor.interface";

export const mapSalesAssistanceRequest = async (
  authObj: AuthContextType,
  data: ISalesAssistance,
  documents: IDropZoneDocumentSelect[]
) => {
  let salesAssistanceRequest: ISalesAssistanceRequest = {
    RequestorName:
      authObj?.userProfile?.firstName + " " + authObj?.userProfile?.lastName,
    RequestorEmail: authObj.userProfile?.emailAddress!,
    RequestorPhone:
      authObj.userProfile && authObj.userProfile.phoneNumber
        ? authObj.userProfile!.phoneNumber
        : authObj.userProfile!.mobilePhoneNumber,
    Reason: getReasonType(data.helpNeeded.value),
    Description: data.additionalInformation.value,
    DeviceType: data.problemDetails.deviceProblem.value
      ? data.problemDetails.deviceProblem.value
      : "",
    ErrorCode: data.problemDetails.errorCode.value
      ? data.problemDetails.errorCode.value
      : "",
    DateOfProblem:
      data.helpNeeded.value === HelpNeededRadioSelection.PROBLEM
        ? formatISODate(
            data.problemDetails.dateOfProblem.value,
            data.problemDetails.dateOfProblem.valid
          )
        : null,
    FacilityId: authObj.registeredFaciltyAddress!.accountNumber!.toString(),
    RentalOrderNumber: data.problemDetails.roNumber.value
      ? data.problemDetails.roNumber.value
      : "",
    AffectedFeature:
      data.problemDetails.featureAffected.value.toLowerCase() === "other"
        ? data.problemDetails.otherFeatureAffected.value
        : data.problemDetails.featureAffected.value,
    Attachments: documents.map((doc: IDropZoneDocumentSelect) => {
      return {
        Actions: 1,
        Content: doc.documentBase64,
        FileName: doc.documentName,
      };
    }),
    Username: authObj.userProfile?.userName ?? "",
  };
  return salesAssistanceRequest;
};

const getReasonType = (type: string): number => {
  let reason = 0;
  if (type === HelpNeededRadioSelection.PROBLEM) {
    reason = 0;
  } else if (type === HelpNeededRadioSelection.QUESTION) {
    reason = 1;
  } else if (type === HelpNeededRadioSelection.ENHANCEMENT) {
    reason = 2;
  }
  return reason;
};
