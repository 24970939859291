import React, { Fragment, useEffect, useState } from "react";
import "./faq.css";
import { FaqCard, ExpandCollapseImg } from "./faq.style";
import expandIcon from "../../../assets/expand.svg";
import collapseIcon from "../../../assets/collapse.svg";
import VideoDialog from "./VideoDialog";
import { useHistory } from "react-router-dom";
import { Grid, useMediaQuery } from "@mui/material";
import { IVideoContent } from "./videoContent.interface";
import { CMS_FAQ_VIDEO_CONTENT } from "../../../util/staticText";
import { getCMSContent } from "../../../util/cmsService";
import { IBannerContent, IMainSection } from "./mainSection.interface";
import { IVideoSection } from "./videoSection.interface";
import defaultImage from "../../../assets/grey_background.jpg";
import { IFAQContent } from "./faqContent.interface";
import { IFAQSection } from "./faqSection.interface";
import { getWoundProducts } from "../../../util/productsManagerService";
import parse from "html-react-parser";

type Props = {};

function FAQ(Props: Props) {
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [faqList, setFaqList] = React.useState<IFAQContent[]>();
  const [faqSection, setFaqSection] = React.useState<IFAQSection>();
  const [videoTutorialSection, setVideoTutorialSection] =
    React.useState<IVideoSection>();
  const [mainSection, setMainSection] = React.useState<IMainSection>();
  const [video, setVideo] = React.useState<IVideoContent | IBannerContent>();
  const isMobileScreen = useMediaQuery("(max-width:912px)");
  const [getProductDetailLoader, setGetProductDetailLoader] = useState(false);

  useEffect(() => {
    fetchFAQVideoContent();
  }, []);

  const handleClickVideoOpen = (video: any) => {
    setVideo(video);
    setOpen(true);
  };

  const handleClickOpen = async (item: IMainSection | undefined) => {
    if (item?.banner?.ctaLaunch === "2") {
      window.open(item?.banner?.ctaLink);
    }
    if (item?.banner?.ctaLaunch === "1") {
      try {
        setGetProductDetailLoader(true);
        const data = await getWoundProducts(item?.banner?.productId.toString());
        if (data.succeeded) {
          const bannerDetails = data.item.products;
          if (bannerDetails[0].productCode === item?.banner?.productId) {
            let selectedProduct = {
              code: bannerDetails[0].productCode,
              id: bannerDetails[0].productID,
              name: "",
              imageUrl: "",
              productUrl: bannerDetails[0].productURL,
              sku: "",
              allowOrder: "",
              allowSample: "",
              productType: "",
            };
            history.push({
              pathname: "/productsAndSolutions/productDetail",
              state: {
                product: selectedProduct,
              },
            });
          }
          setGetProductDetailLoader(false);
        } else {
          setGetProductDetailLoader(false);
        }
      } catch (error) {
        setGetProductDetailLoader(false);
        console.log("error", error);
        return [];
      }
    }
    if (item?.banner?.ctaLaunch === "3") {
      setVideo(item?.banner);
      setOpen(true);
    }
  };

  const fetchFAQVideoContent = async () => {
    //async and await
    try {
      const data = await getCMSContent(CMS_FAQ_VIDEO_CONTENT);
      if (data && data.item !== undefined) {
        setMainSection(data.item.main);
        setVideoTutorialSection(data.item.videoTutorial);
        setFaqList(data.item.faq.resources);
        setFaqSection(data.item.faq);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleQAClick = (index: any) => {
    if (faqList) {
      const updatelist = faqList.map((faq, ix) =>
        ix === index ? { ...faq, active: !faq.active } : { ...faq }
      );
      setFaqList(updatelist);
    }
  };

  const handleContactUsClick = () => {
    history.push("/helpAndSupport/ContactUs");
  };

  return (
    <Fragment>
      <FaqCard className="mainFaq">
        <div className="mainbanner">
          <div className="mainbanner-image">
            {mainSection && mainSection?.banner !== null && (
              <img
                className="mainbanner-poster"
                src={mainSection?.banner?.imageLink}
                alt={mainSection?.banner?.imageLink}
                onClick={() => handleClickOpen(mainSection)}
              />
            )}
          </div>
          <div className="mainbanner-fullframe"></div>
          <div className="mainbanner-partialframe"></div>
          <div className="mainbanner-watch">
            <label className="mainbanner-watch-text">
              {mainSection?.title}
            </label>
          </div>
        </div>
        <div className="faq">
          <label className="title" data-testid="faqTitle">
            {faqSection?.title}
          </label>
          <label className="desc">
            We’ve collected a list of common questions about 3M™ Express Therapy
            Portal. You can{" "}
            <span onClick={handleContactUsClick} className="contactus">
              contact us
            </span>{" "}
            if your question is not addressed here.
          </label>
        </div>
        <div className="faqList">
          {faqList?.map((rec, index) => {
            return (
              <div className="question-answer" key={index}>
                <div className="questionblock">
                  {!rec.active ? (
                    <ExpandCollapseImg
                      src={expandIcon}
                      onClick={() => {
                        handleQAClick(index);
                      }}
                    />
                  ) : (
                    <ExpandCollapseImg
                      src={collapseIcon}
                      onClick={() => {
                        handleQAClick(index);
                      }}
                    />
                  )}
                  <span
                    onClick={() => {
                      handleQAClick(index);
                    }}
                    className="question"
                    data-testId="questionTest"
                  >
                    {parse(rec.text)}
                  </span>
                </div>
                <p
                  className={`answer ${
                    rec.active ? `answer-active ` : ` answer-inactive`
                  }`}
                >
                  {parse(rec.response)}
                </p>
                <div
                  className={`collapse ${
                    rec.active ? `answer-active ` : ` answer-inactive`
                  }`}
                  onClick={() => {
                    handleQAClick(index);
                  }}
                >
                  Collapse
                </div>
              </div>
            );
          })}
        </div>
        <label
          className="video-tutorials-header"
          data-testid="video-header-text"
        >
          {videoTutorialSection?.title}
        </label>
        <Grid className="faq-video-container" container spacing={2}>
          {videoTutorialSection?.videos.map((video, index) => {
            return (
              <Grid
                item
                className="faq-video-grid-item"
                data-testid={video.videoId.toString()}
                key={video.videoId.toString()}
                onClick={() => handleClickVideoOpen(video)}
                xs={isMobileScreen ? 12 : 4}
              >
                <div
                  className={`faq-video-box ${
                    (index + 1) % 3 === 1
                      ? "left"
                      : (index + 1) % 3 === 2
                      ? "center"
                      : "right"
                  } faqVideoBoxMobile`}
                >
                  <img
                    alt=""
                    className="faq-video-poster"
                    data-testid={`faq-video-poster-${index}`}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = defaultImage;
                    }}
                    src={video.poster}
                  />
                  <div className="faq-video-text">
                    <h4>
                      <label className="faq-video-name">
                        {parse(video.text)} ({video.duration})
                      </label>
                    </h4>
                  </div>
                </div>
              </Grid>
            );
          })}
        </Grid>
      </FaqCard>
      {open && video && (
        <VideoDialog
          accountId={video.accountId}
          duration={video.duration}
          open={open}
          setOpen={setOpen}
          text={parse(video.text)}
          videoId={video.videoId}
        />
      )}
    </Fragment>
  );
}

export default FAQ;
