import { Grid } from "@mui/material";
import { IcharityCareDetails } from "../../../woundBed/vacTherapyInformation.interface";
import "./charityCare.css";
type Props = {
  charitycareData: IcharityCareDetails[];
};
export const CharityCare = ({ charitycareData }: Props) => {
  return (
    <Grid container className="charity-care-container" flexDirection="row">
      <div className="charity-desc" data-testid="charity-desc">
        Please complete the charity care loaner program application to the
        V.A.C.® Therapy Order to expedite processing.
      </div>
      <>
        {charitycareData.map((res: IcharityCareDetails, index: number) => {
          return (
            <>
              <div className="charityCarePdfLink">
                <span className="bullet-with-space">&bull; </span>
                <a
                  href={res.fileLink}
                  className="charity-pdf-link"
                  data-testid={`charity-pdf-link-${index + 1}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {res.labelText}
                </a>
                <span className="pdf-size">{`- PDF, ${res.fileSize}`}</span>
              </div>
            </>
          );
        })}
      </>
      <div className="charity-instruction">
        Include the completed application using{" "}
        <div className="upload-doc">Upload Documents</div> below
      </div>
    </Grid>
  );
};
