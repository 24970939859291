import { ValidationStatus } from "../../../../core/interfaces/input.interface";
import { IStoredProdReturnInformation } from "./returnStoredProductUnit.interface";

export let defaultStoredProductReturnInformation: IStoredProdReturnInformation =
  {
    pickUpLocation: {
      valid: ValidationStatus.UNTOUCHED,
      value: "",
      required: true,
    },
    issueDescription: {
      valid: ValidationStatus.UNTOUCHED,
      value: "",
      required: true,
    },
    isReplacementNeeded: {
      valid: ValidationStatus.VALID,
      value: "yes",
      required: true,
    },
    deliveryInstructions: {
      valid: ValidationStatus.UNTOUCHED,
      value: "",
      required: true,
    },
  };
