import "./pickUpRequestFooterGroup.css";
import { Grid, Toolbar, useMediaQuery } from "@mui/material";
import { ExpressButton } from "../../../../core/expressButton/expressButton.component";
import { PersistentFooter } from "../../../../core/persistentFooter/persistentFooter.Component";

type IPickUpRequestFooterProps = {
  firstButtonTitle: string;
  firstButtonAction: any;
  firstButtonDisabled?: boolean;

  secondButtonTitle: string;
  secondButtonAction: any;
  secondButtonDisabled?: boolean;

  thirdButtonTitle: string;
  thirdButtonAction: any;
  thirdButtonDisabled?: boolean;
};

export const PickUpRequestFooterButtonGroup = ({
  firstButtonTitle,
  firstButtonAction,
  firstButtonDisabled = false,
  secondButtonTitle,
  secondButtonAction,
  secondButtonDisabled = false,
  thirdButtonTitle,
  thirdButtonAction,
  thirdButtonDisabled = false,
}: IPickUpRequestFooterProps) => {
  const isMobileScreen = useMediaQuery("(max-width:445px)");

  return (
    <PersistentFooter footerButtonClass={`pickup-footer`}>
      <Toolbar className="pickup-request-tool-bar">
        <Grid container className="pickup-request-grid-container">
          <Grid
            className="pickup-request-grid-item"
            item
            xs={isMobileScreen ? 12 : 4}
            order={{ xs: isMobileScreen ? 2 : 1 }}
            direction={isMobileScreen ? "column" : "row"}
          >
            {firstButtonTitle !== "" && (
              <div className="left-div">
                <ExpressButton
                  clickHandler={firstButtonAction}
                  disabled={firstButtonDisabled}
                  parentClass="firstButton"
                  testId="firstButton-test"
                  variant="text"
                >
                  {firstButtonTitle}
                </ExpressButton>
              </div>
            )}
          </Grid>
          <Grid
            className="pickup-request-grid-item"
            item
            xs={isMobileScreen ? 12 : 8}
            order={{ xs: isMobileScreen ? 1 : 2 }}
            direction={isMobileScreen ? "column" : "row"}
          >
            <div className="right-div">
              {secondButtonTitle !== "" && (
                <ExpressButton
                  clickHandler={secondButtonAction}
                  disabled={secondButtonDisabled}
                  parentClass="secondButton"
                  testId="secondButton-test"
                  variant="outlined"
                >
                  {secondButtonTitle}
                </ExpressButton>
              )}
              {thirdButtonTitle !== "" && (
                <ExpressButton
                  clickHandler={thirdButtonAction}
                  disabled={thirdButtonDisabled}
                  parentClass={
                    thirdButtonDisabled ? "thirdButton-disabled" : "thirdButton"
                  }
                  testId="thirdButton-test"
                  variant="contained"
                >
                  {thirdButtonTitle}
                </ExpressButton>
              )}
            </div>
          </Grid>
        </Grid>
      </Toolbar>
    </PersistentFooter>
  );
};
