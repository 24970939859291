import moment from "moment";
import "./proofOfDeliveryFax.css";
import { useContext, useState } from "react";
import { Button, Grid } from "@mui/material";
import {
  SubmitProofOfDeliveryContext,
  SubmitProofOfDeliveryContextType,
} from "../../../../context/submitProofOfDeliveryContext";
import { generatePODPDF } from "../../../../util/podService";
import { ReactComponent as PrintIcon } from "../../../../assets/print.svg";
import { ReactComponent as InfoIcon } from "../../../../assets/InfoIcon.svg";
import {
  checkPopUpBlocker,
  getPdfUrl,
} from "../../../../util/utilityFunctions";
import { WindowService } from "../../../../util/window.service";
import { Popup } from "../../../../core/popup/popup.component";
import { SendNoteFailure } from "../../../send3MNote/popUps/failurePopUp/sendNoteFailure.component";
import ErrorPopup from "../../../../core/errorPopup/errorPopup.component";
import { ERROR_MSG_POPUP_BLOCKER } from "../../../../util/errorMsg";
import { OPEN_PDF_POP_UP_BLOCKED } from "../../../../util/errorCode";
import { ERROR_MESSAGE_POD_PDF, ERROR_MESSAGE_POD_PDF_NO_PAYOR_DETAILS } from "../../../../util/staticText";

type Props = {
  setIsLoading: Function;
};

export const ProofOfDeliveryFax = ({ setIsLoading }: Props) => {
  const submitPodObj = useContext<SubmitProofOfDeliveryContextType | null>(
    SubmitProofOfDeliveryContext
  );
  const patientData = submitPodObj?.patient;
  const [openFailurePopUp, setOpenFailurePopUp] = useState<boolean>(false);
  const [failureMessage, setFailureMessage] = useState<string>(ERROR_MESSAGE_POD_PDF);
  const [errorCode,setErrorCode] = useState<number|null>(null);
  const [isPopUpBlocked, setIsPopUpBlocked] = useState<boolean>(false);

  const generateProofOfDelieveyPDF = async () => {
    if (patientData) {
      setIsLoading(true);
      const reqBody = {
        DOB: moment(patientData.dob).format("DD-MMM-yyyy"),
        FirstName: patientData.firstName,
        LastName: patientData.lastName,
        RentalOrderNumber: patientData.roNumber,
      };
      const response = await generatePODPDF(reqBody);
      setIsLoading(false);
      if (response && response.succeeded) {
        const url = getPdfUrl(response.item);
        const windowObject = window.open(url, "_blank");
        const result = checkPopUpBlocker(windowObject);
        setIsPopUpBlocked(result);
      } else {
        if(response?.error?.errorCode === 5179) {
          setFailureMessage(ERROR_MESSAGE_POD_PDF_NO_PAYOR_DETAILS);
          setErrorCode(response.error.errorCode);
        }
        setOpenFailurePopUp(true);
        setErrorCode(response.error.errorCode);
      }
    }
  };

  const openProofOfDeliveryPdf = () => {
    generateProofOfDelieveyPDF();
  };

  const closeButtonAction = () => {
    setOpenFailurePopUp(false);
  };

  return (
    <div className="pod-fax-main" data-testid="pod-fax-main">
      <Grid container className="pod-fax-container">
        <Grid item xs={6} className="pod-fax-msg" data-testid="pod-fax-msg">
          <p className="fax-msg">
            The Proof of Delivery and Acceptance of Benefits form is required to
            be signed and dated by the patient or an authorized agent to receive
            care.
          </p>
        </Grid>
        <Grid item xs={6}>
          <Button
            classes={{ root: "borderButton" }}
            data-testid="downLoad-print-pod"
            variant="outlined"
            onClick={openProofOfDeliveryPdf}
            startIcon={<PrintIcon />}
          >
            Download and Print
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            classes={{ root: "borderButton-static-text" }}
            variant="outlined"
            startIcon={<InfoIcon />}
          >
            <span className="static-text" data-testid="pod-static-txt-btn">
              Please fax the signed Proof of Delivery and Acceptance of Benefits
              form to 1-888-245-2295.
            </span>
          </Button>
        </Grid>
      </Grid>
      <ErrorPopup
        errorPopupFlag={openFailurePopUp}
        popUpStyles="errorPopup"
        showTitle={false}
        handleBackButton={closeButtonAction}
        isSupportPhoneRequired={true}
        errorMessage={failureMessage}
        errorCode={errorCode}
      />
      <ErrorPopup
        errorPopupFlag={isPopUpBlocked}
        popUpStyles="errorPopup"
        showTitle={false}
        handleBackButton={() => setIsPopUpBlocked(false)}
        isSupportPhoneRequired={false}
        errorMessage={ERROR_MSG_POPUP_BLOCKER}
        errorCode={OPEN_PDF_POP_UP_BLOCKED}
      />
    </div>
  );
};
