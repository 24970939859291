import { ICustomerPlusListingDetails } from "../inventory/customerPlusInventory/customerPlusInventory.interface";

export enum IAlertTypes {
  MISRX,
  MSDUE,
  PNDSO,
  SUPSH,
  SHODR,
  MSDOC,
  PODEL,
  CONPL,
  DISPEN,
  APIERROR,
  ORDSRC,
}
export enum ISeverityTypes {
  LOW,
  MEDIUM,
  HIGH,
}
export type IPatientAlert = {
  alertID: string;
  alertName: string;
  alertSubLabel: string;
  alertDate: Date | null;
  alertType: IAlertTypes;
  severity: ISeverityTypes | null;
  sharedData?: ISharedOrderData | null;
  woundOrderID?: number | null;
  ropn?: string | null;
  statusDelayReason?: statusDelayReason | null;
  assessmentToDate?: Date | null;
  assessmentFromDate?: Date | null;
  showAssessmentButton?: boolean;
  trackingNumber?: string;
  peelAndPlace?: boolean;
};

export type statusDelayReason = {
  RON: number;
  statusDelayReasons: Array<IMissingDocsAlert>;
};

export type IMissingDocsAlert = {
  delayReason: string;
  delayDetail: string;
};

export type ISharedOrderData = {
  OrderId: string;
  From: string;
  To: string;
  Notes: string;
  FromRecipientName: string;
  ToRecipientName: string;
  CreatedOn: string;
};

export type IMenuAction = {
  text: string;
  sequence: number;
};

export interface IPatient {
  roNumber: number;
  firstName: string;
  lastName: string;
  dob: string;
  facilityName: string;
  orderCreationDate: string;
  alerts: Array<IPatientAlert>;
  status?: string;
  color?: string;
  statusColor?: any;
  orderID?: string;
  productName?: string;
  productNameDescription?: string;
  placementDate?: string;
  productSerialNumber?: string;
  menuActions?: Array<IMenuAction>;
  sharedStatus?: string;
  woundOrderID?: number | null;
  workOrderNumber?: string | null;
  siteUseId?: string;
  careGiverId?: string;
  rentalOrderProductNumber?: string;
  isDeepLink?: boolean;
  emailAddress?: string | null;
  isReadyCare?: boolean;
  dischargeDate?: string;
  setIsRentalPermission?: any;
  type?: string;
  customerNumber?: string | null;
  isAcuteOrder: boolean;
  patientId: string | null;
  patientLocation: string | null;
  peelAndPlace?: boolean;
  isExchangeVacSubmitted?: boolean | null;
  isCustomerPlus?: boolean;
  customerPlusProduct?: ICustomerPlusListingDetails;
  isAcuteOrderFailed?: boolean;
  isSubmitWoundFailed?: boolean;
  productBrandCode?: string;
  orderSource?: string;
  isFromAllFacility?: boolean;
  ropNumber?: string;
}
export interface IExchangeVacValues extends IPatient {
  oldSerialNumber: string;
  serviceDate: string;
  exchangeWONumber: string;
}

export interface IStopSaringOrder {
  orderID: string | null;
  sharedBy: string | null;
  stopSharingOrder: boolean;
}

export interface ICSVReqBody {
  columns: string[];
  data: IPatient[];
}

export interface IUpdatePopupFlagReqBody {
  username: string;
  screen: UpdatesPopupScreenTypes;
}

export enum UpdatesPopupScreenTypes {
  featuresPopupShownInSSO = 1,
  featuresPopupShownInMPD = 2,
}

export enum AcutePatientMenuActionTypes {
  CREATE_TRANSISTION_ORDER = "create transition order",
  END_THERAPHY = "end therapy",
  PLACE_ON_PATIENT = "place on patient",
}
