import { createContext, useState } from "react";
import { InpatientOrderPageSection } from "../components/inpatientOrder/inpatientOrder.enum";
import {
  IInpatientOrder,
  IInpatientOrderSecondPage,
} from "../components/inpatientOrder/inpatientOrder.interface";
import {
  defaultInpatientOrderData,
  defaultInpatientOrderSecondPageData,
} from "../components/inpatientOrder/inpatientOrder.model";
import { getDeepClone } from "../util/ObjectFunctions";
import {
  defaultDevicePlacementTableData,
  IDevicePlacementTableData,
} from "../components/inpatientOrder/container/inpatientOrderContainer.component";
import {
  IPatientDetail,
  IProductDetail,
} from "../components/inpatientOrder/components/patientAndProductDetail/patientAndProductDetail.interface";
import { ISolventumProduct } from "../components/inventory/inventoryTabContainer.enum";
import { IBillingAddress } from "../components/inpatientOrder/components/billingAddress/billingAddress.interface";
import { IWoundInfoDropDownValues } from "../components/inpatientOrder/components/woundInformation/woundInformation.interface";
import { defaultWoundInfoDropDownValues } from "../components/inpatientOrder/components/woundInformation/woundInformation.model";
import { useCart } from "./CartContext";
import { IBillingInformation } from "../components/inpatientOrder/components/billingInformation/billingInformation.interface";
import { defaultBillingInformationData } from "../components/inpatientOrder/components/billingInformation/billingInformation.model";

export type InpatientOrderContextType = {
  data: IInpatientOrder;
  orderProgress: number;
  page: InpatientOrderPageSection;
  resetContext: () => void;
  secondPageData: IInpatientOrderSecondPage;
  setData: React.Dispatch<React.SetStateAction<IInpatientOrder>>;
  setOrderProgress: React.Dispatch<React.SetStateAction<number>>;
  setPage: React.Dispatch<React.SetStateAction<InpatientOrderPageSection>>;
  setSecondPageData: React.Dispatch<
    React.SetStateAction<IInpatientOrderSecondPage>
  >;

  isFromStartNewOrder: boolean;
  setIsFromStartNewOrder: React.Dispatch<React.SetStateAction<boolean>>;
  isInitialLoadTriggered: boolean;
  setIsInitialLoadTriggered: React.Dispatch<React.SetStateAction<boolean>>;

  inPatientOrderID: string;
  setInPatientOrderID: React.Dispatch<React.SetStateAction<string>>;

  addresses: IBillingAddress[] | null;
  setAddresses: React.Dispatch<React.SetStateAction<IBillingAddress[] | null>>;
  patient: IPatientDetail | null;
  setPatient: React.Dispatch<React.SetStateAction<IPatientDetail | null>>;

  devicePlacementTableData: IDevicePlacementTableData;
  setDevicePlacementTableData: React.Dispatch<
    React.SetStateAction<IDevicePlacementTableData>
  >;
  devicePlacementScrollableTab: number;
  setDevicePlacementScrollableTab: React.Dispatch<React.SetStateAction<number>>;

  product: IProductDetail | null;
  setProduct: React.Dispatch<React.SetStateAction<IProductDetail | null>>;
  solventumProducts: ISolventumProduct[] | null;
  setSolventumProducts: React.Dispatch<
    React.SetStateAction<ISolventumProduct[] | null>
  >;

  needTimesText: string[];
  setNeedTimesText: React.Dispatch<React.SetStateAction<string[]>>;
  needTimes: any;
  setNeedTimes: React.Dispatch<React.SetStateAction<any>>;
  currentServerDate: any;
  setCurrentServerDate: React.Dispatch<React.SetStateAction<any>>;

  woundInfoDropDownValues: IWoundInfoDropDownValues;
  setWoundInfoDropDownValues: React.Dispatch<
    React.SetStateAction<IWoundInfoDropDownValues>
  >;
  submitClickCount: number;
  setSubmitClickCount: React.Dispatch<React.SetStateAction<number>>;
  errorCode: number | null;
  setErrorCode: React.Dispatch<React.SetStateAction<number | null>>;
  isSuppliesDataAvailable: boolean;
  setIsSuppliesDataAvailable: React.Dispatch<React.SetStateAction<boolean>>;
  salesPOValue: IBillingInformation;
  setSalesPOValue: React.Dispatch<React.SetStateAction<IBillingInformation>>;
};

type InpatientOrderContextProviderProps = {
  children: React.ReactNode;
};

export const InpatientOrderContext =
  createContext<InpatientOrderContextType | null>(null);

export const InpatientOrderContextProvider = ({
  children,
}: InpatientOrderContextProviderProps) => {
  const [data, setData] = useState<IInpatientOrder>(
    getDeepClone(defaultInpatientOrderData)
  );
  const [orderProgress, setOrderProgress] = useState<number>(20);
  const [page, setPage] = useState<InpatientOrderPageSection>(
    InpatientOrderPageSection.FIRST_PAGE
  );
  const [secondPageData, setSecondPageData] =
    useState<IInpatientOrderSecondPage>(
      getDeepClone(defaultInpatientOrderSecondPageData)
    );
  const [isFromStartNewOrder, setIsFromStartNewOrder] =
    useState<boolean>(false);
  const [inPatientOrderID, setInPatientOrderID] = useState<string>("");

  const [addresses, setAddresses] = useState<IBillingAddress[] | null>(null);
  const [patient, setPatient] = useState<IPatientDetail | null>(null);
  const [product, setProduct] = useState<IProductDetail | null>(null);
  const [solventumProducts, setSolventumProducts] = useState<
    ISolventumProduct[] | null
  >(null);

  const [isInitialLoadTriggered, setIsInitialLoadTriggered] =
    useState<boolean>(true);

  const [devicePlacementTableData, setDevicePlacementTableData] =
    useState<IDevicePlacementTableData>(defaultDevicePlacementTableData);
  const [devicePlacementScrollableTab, setDevicePlacementScrollableTab] =
    useState<number>(0);

  const [needTimesText, setNeedTimesText] = useState<string[]>([]);
  const [needTimes, setNeedTimes] = useState([]);
  const [currentServerDate, setCurrentServerDate] = useState<any>();

  const [woundInfoDropDownValues, setWoundInfoDropDownValues] =
    useState<IWoundInfoDropDownValues>(defaultWoundInfoDropDownValues);

  const [acuteOrderId, setAcuteOrderId] = useState<string | null>(null);
  const [errorCode, setErrorCode] = useState<number | null>(null);
  const [submitClickCount, setSubmitClickCount] = useState<number>(0);
  const [salesPOValue, setSalesPOValue] = useState<IBillingInformation>(
    defaultBillingInformationData
  );
  const { resetCart } = useCart();
  const [isSuppliesDataAvailable, setIsSuppliesDataAvailable] =
    useState<boolean>(true);

  const resetContext = () => {
    setData(getDeepClone(defaultInpatientOrderData));
    setOrderProgress(20);
    setPage(InpatientOrderPageSection.FIRST_PAGE);
    setSecondPageData(getDeepClone(defaultInpatientOrderSecondPageData));

    setIsFromStartNewOrder(false);
    setInPatientOrderID("");

    setPatient(null);
    setProduct(null);
    setSolventumProducts(null);

    setAddresses(null);
    setPatient(null);
    setProduct(null);
    setSolventumProducts(null);

    setIsInitialLoadTriggered(true);
    setDevicePlacementScrollableTab(0);
    setDevicePlacementTableData(defaultDevicePlacementTableData);

    setWoundInfoDropDownValues(defaultWoundInfoDropDownValues);
    setSubmitClickCount(0);
    setErrorCode(null);
    setSalesPOValue(defaultBillingInformationData);
    resetCart();
    setIsSuppliesDataAvailable(false);
  };

  return (
    <InpatientOrderContext.Provider
      value={{
        addresses,
        currentServerDate,
        data,
        devicePlacementTableData,
        devicePlacementScrollableTab,
        inPatientOrderID,
        isFromStartNewOrder,
        isInitialLoadTriggered,
        orderProgress,
        needTimes,
        needTimesText,
        page,
        patient,
        product,
        resetContext,
        secondPageData,
        solventumProducts,
        setAddresses,
        setCurrentServerDate,
        setData,
        setDevicePlacementTableData,
        setDevicePlacementScrollableTab,
        setInPatientOrderID,
        setIsFromStartNewOrder,
        setIsInitialLoadTriggered,
        setOrderProgress,
        setNeedTimes,
        setNeedTimesText,
        setPage,
        setPatient,
        setProduct,
        setSecondPageData,
        setSolventumProducts,
        setWoundInfoDropDownValues,
        submitClickCount,
        setSubmitClickCount,
        errorCode,
        setErrorCode,
        woundInfoDropDownValues,
        isSuppliesDataAvailable,
        setIsSuppliesDataAvailable,
        salesPOValue,
        setSalesPOValue,
      }}
    >
      {children}
    </InpatientOrderContext.Provider>
  );
};
