import moment from "moment";
import "./patientFound.css";
import {
  MyPatientContext,
  MyPatientContextType,
} from "../../../context/MyPatientContext";
import { format } from "react-string-format";
import { useContext, MouseEvent } from "react";
import { IPatient } from "../patient.interface";
import {
  RolesPermissionContext,
  RolesPermissionContextType,
} from "../../../context/RolesPermissionContext";

import { IPatientFound } from "./patientFound.interface";
import { addPatientSearch } from "../../../util/3meService";
import { Button, Grid, useMediaQuery } from "@mui/material";
import { ERROR_MSG_SEARCH_AND_ADD_PATIENT } from "../../../util/errorMsg";
import { AuthContext, AuthContextType } from "../../../context/AuthContext";
import { makeCapitalEachWordInString } from "../../../util/utilityFunctions";
import { PageSection } from "../addPatientContainer/addPatientContainer.enum";
import { ExpressButton } from "../../../core/expressButton/expressButton.component";
import { AddPatientContext } from "../addPatientContainer/addPatientContainer.context";

export const PatientFound = ({
  isAcuteFlow = false,
  patients,
  redirectHandler,
}: IPatientFound) => {
  const { closePopup, addPatientToList } = useContext(AddPatientContext);
  const isMobileScreen = useMediaQuery("(max-width:768px)");

  const authObj = useContext<AuthContextType | null>(AuthContext);
  const myPatientObj = useContext<MyPatientContextType | null>(
    MyPatientContext
  );
  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );

  const addPatientHandler = (
    patient: IPatient,
    event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => {
    const formatDOB =
      patient.dob !== "" ? moment(patient.dob).format("YYYY-MM-DD") : null;
    let reqParams = {
      FirstName: patient.firstName,
      LastName: patient.lastName,
      RentalOrderNumber: patient.roNumber,
      DOB: formatDOB,
      FacilityName: patient.facilityName,
      OrderCreationDate: patient.orderCreationDate,
      OrderStatus: patient.status,
      SiteUseId: authObj?.registeredFaciltyAddress?.siteUseId,
      CareGiverId: patient.careGiverId,
      IsReadyCare: patient.isReadyCare ?? false,
      DischargeDate: patient.dischargeDate,
      ProductName: patient.productName,
      ProductSerialNumber: patient.productSerialNumber,
      isAcuteOrder: patient.isAcuteOrder,
      patientId: patient.patientId,
      patientLocation: patient.patientLocation,
      type: patient.type,
    };
    addPatientSearchToMyPatients(event, patient, reqParams);
  };

  const addPatientSearchToMyPatients = async (
    event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    patient: IPatient,
    reqParams: any
  ) => {
    const data = await addPatientSearch(reqParams);
    if (data.succeeded) {
      myPatientObj?.setReloadMyPatient(true);
      closePopup(event);
      addPatientToList(patient);
    } else {
      redirectHandler(
        PageSection.ERROR_FORM,
        null,
        data.error.code,
        ERROR_MSG_SEARCH_AND_ADD_PATIENT
      );
    }
  };

  return (
    <div className="patient-found-component">
      <h3 className="patient-found-header" data-testid="header">
        Patient Found
      </h3>
      <div className="patients">
        {patients &&
          patients.map((patient) => {
            return (
              <Grid className="patient-found-grid-container" container>
                <Grid
                  className="patient-found-grid-item"
                  item
                  xs={isMobileScreen ? 4 : 3}
                ></Grid>
                <Grid
                  className="patient-found-grid-item"
                  item
                  xs={isMobileScreen ? 8 : 9}
                >
                  <p className="patient-name" data-testid="patient-name">
                    {makeCapitalEachWordInString(
                      format("{0}, {1}", patient.lastName, patient.firstName)
                    )}
                  </p>
                </Grid>
                <Grid
                  className="patient-found-grid-item"
                  item
                  xs={isMobileScreen ? 4 : 3}
                >
                  <Button
                    classes={{
                      root: `add-patient-button`,
                    }}
                    data-testid="favourite-button"
                    onClick={(event) => addPatientHandler(patient, event)}
                  >
                    Add Patient
                  </Button>
                </Grid>
                {patient.isAcuteOrder && (
                  <Grid
                    className="patient-found-grid-item"
                    item
                    xs={isMobileScreen ? 8 : 4}
                  >
                    <div className="patient-details">
                      <div className="patient-detail">
                        <p className="patient-detail-title for-acute">
                          Location
                        </p>
                        <p className="patient-detail-value for-acute">
                          {patient.patientLocation &&
                          patient.patientLocation !== ""
                            ? patient.patientLocation
                            : "--"}
                        </p>
                      </div>
                      <div className="patient-detail">
                        <p className="patient-detail-title for-acute">
                          Patient ID
                        </p>
                        <p className="patient-detail-value for-acute">
                          {patient.patientId && patient.patientId !== ""
                            ? patient.patientId
                            : ""}
                        </p>
                      </div>
                    </div>
                  </Grid>
                )}
                {patient.isAcuteOrder && isMobileScreen && (
                  <Grid className="patient-found-grid-item" item xs={4}></Grid>
                )}
                <Grid
                  className="patient-found-grid-item"
                  item
                  xs={patient.isAcuteOrder && isMobileScreen ? 8 : 4}
                >
                  <div className="patient-details">
                    <div className="patient-detail">
                      <p className="patient-detail-title">DOB</p>
                      <p
                        className="patient-detail-value"
                        data-testid="patient-dob"
                      >
                        {patient.dob && patient.dob !== ""
                          ? moment(patient.dob).format("MM/DD/YYYY")
                          : "--"}
                      </p>
                    </div>
                    <div className="patient-detail">
                      <p className="patient-detail-title">RO#</p>
                      <p className="patient-detail-value">{patient.roNumber}</p>
                    </div>
                  </div>
                </Grid>
              </Grid>
            );
          })}
      </div>
      <div className="button-section">
        <ExpressButton
          clickHandler={() =>
            redirectHandler(
              isAcuteFlow ? PageSection.PATIIENT_TYPE : PageSection.SEARCH_FORM
            )
          }
          parentClass="search-again-button"
          variant="outlined"
          testId="search-again-button"
        >
          Search Again
        </ExpressButton>
      </div>
    </div>
  );
};
