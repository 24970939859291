import {
  Button,
  Grid,
  IconButton,
  InputBase,
  useMediaQuery,
} from "@mui/material";
import { useContext, useState } from "react";
import GreenCheck from "../../../../assets/greenCheck.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/popupcloseicon.svg";
import RedCheck from "../../../../assets/redCheck.svg";
import {
  RolesPermissionContext,
  RolesPermissionContextType,
} from "../../../../context/RolesPermissionContext";
import {
  SubmitProofOfDeliveryContext,
  SubmitProofOfDeliveryContextType,
} from "../../../../context/submitProofOfDeliveryContext";
import { ExpressButton } from "../../../../core/expressButton/expressButton.component";
import { InputWithLabel } from "../../../../core/inputWithLabel/inputWithLabel.component";
import { ValidationStatus } from "../../../../core/interfaces/input.interface";
import { podSendESignDetails } from "../../../../util/podService";
import { IPatient } from "../../patient.interface";
import "./proofOfDeliveryESign.css";
import { SubmitESignPodStatus } from "./proofOfDeliveryESign.interface";
import { proofOfDeliveryESignValidator } from "./proofOfDeliveryESign.validator";

type Props = {
  patientData: IPatient;
  editESignatureMode: boolean;
  setEditESignatureMode: Function;
  setIsLoading: Function;
};
export const ProofOfDeliveryESignature = ({
  patientData,
  setIsLoading,
  editESignatureMode = false,
  setEditESignatureMode,
}: Props) => {
  const submitPodObj = useContext<SubmitProofOfDeliveryContextType | null>(
    SubmitProofOfDeliveryContext
  );
  const [validator] = useState<proofOfDeliveryESignValidator>(
    new proofOfDeliveryESignValidator()
  );
  const isMobileScreen = useMediaQuery("(max-width:710px)");
  const originalData = submitPodObj?.eSignOriginalPatientDetails!;
  const setOriginalData = submitPodObj?.setESignOriginalPatientDetails!;
  const data = submitPodObj?.eSignPatientDetails!;
  const setData = submitPodObj?.setESignPatientDetails!;
  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );
  const onEditBtnClick = () => {
    setEditESignatureMode(true);
  };

  const sendESignAPI = async () => {
    setIsLoading(true);
    const reqBody = {
      rentalOrderNumber: patientData?.roNumber,
      name: submitPodObj?.eSignPatientDetails?.patientName.value,
      email: submitPodObj?.eSignPatientDetails?.updatedPatientEmail.value,
      dob: patientData?.dob,
    };
    const response = await podSendESignDetails(reqBody);
    setEditESignatureMode(false);
    if (response && response.succeeded) {
      setOriginalData(data);
      submitPodObj?.setPodESignStatus(SubmitESignPodStatus.SUCCESS);
    } else {
      submitPodObj?.setPodESignStatus(SubmitESignPodStatus.FAILURE);
    }
    setIsLoading(false);
  };

  const closeEmailUpdateAction = () => {
    setData(originalData);
    setEditESignatureMode(false);
  };

  const validateAndSetData = (e: any) => {
    const isValid = validator.validate(e.target.value, e.target.name);
    submitPodObj?.setESignPatientDetails(
      Object.assign({}, submitPodObj.eSignPatientDetails, {
        [e.target.name]: {
          value: e.target.value,
          valid: isValid!.status,
        },
      })
    );
  };

  const sendESignatureBtnAction = async () => {
    const isAllValid = await validator.validateAll(data, setData);
    if (isAllValid === ValidationStatus.VALID) {
      sendESignAPI();
    }
  };

  const checkForEditButtonEnability = (): boolean => {
    if (!editESignatureMode) {
      return (
        (data.patientName.valid === ValidationStatus.VALID &&
          data.updatedPatientEmail.valid) === ValidationStatus.VALID
      );
    }
    return false;
  };

  const eSignatureEditMode = () => {
    return (
      <div
        className="pod-esign-edit-component"
        data-testid="pod-esign-edit-component"
      >
        <Grid
          className="pod-esign-patient-edit-details-container"
          container
          spacing={2}
        >
          <Grid
            className="pod-esign-patient-grid-item"
            item
            order={{ xs: isMobileScreen ? 2 : 1 }}
            xs={isMobileScreen ? 12 : 5}
          >
            <div className="pod-esign-edit-patient-div">
              <InputWithLabel
                label="Patient/Authorized Agent"
                isRequired={true}
                error={
                  submitPodObj?.eSignPatientDetails?.patientName.valid ===
                  ValidationStatus.INVALID
                }
                testId="pod-esign-edit-patient-label"
              >
                <InputBase
                  className="pod-esign-edit-patient-input"
                  name="patientName"
                  onChange={validateAndSetData}
                  inputProps={{
                    "data-testid": "pod-esign-edit-patient-name-input",
                  }}
                  value={submitPodObj?.eSignPatientDetails?.patientName.value}
                  readOnly={true}
                />
              </InputWithLabel>
            </div>
          </Grid>
          <Grid
            className="pod-esign-patient-grid-item"
            item
            order={{ xs: isMobileScreen ? 3 : 2 }}
            xs={isMobileScreen ? 12 : 5}
          >
            <div className="pod-esign-edit-patient-div">
              <InputWithLabel
                label="Email Address"
                isRequired={true}
                error={
                  submitPodObj?.eSignPatientDetails?.updatedPatientEmail
                    .valid === ValidationStatus.INVALID
                }
                testId="pod-esign-edit-email-label"
              >
                <InputBase
                  className="pod-esign-edit-patient-input"
                  name="updatedPatientEmail"
                  onChange={validateAndSetData}
                  inputProps={{
                    "data-testid": "pod-esign-edit-email-input",
                  }}
                  value={
                    submitPodObj?.eSignPatientDetails?.updatedPatientEmail.value
                  }
                />
              </InputWithLabel>
            </div>
          </Grid>
          <Grid
            className="pod-esign-patient-grid-item"
            item
            xs={isMobileScreen ? 12 : 1}
            order={{ xs: isMobileScreen ? 1 : 3 }}
          >
            <div className="pod-esign-edit-close-icon">
              <IconButton
                aria-label="close"
                data-testid="pod-esign-popupCloseIcon"
                onClick={closeEmailUpdateAction}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </Grid>
          <Grid
            className="pod-send-esign-button-grid"
            item
            xs={12}
            order={{ xs: 4 }}
          >
            <ExpressButton
              clickHandler={sendESignatureBtnAction}
              parentClass="pod-send-esign-button"
              testId="pod-send-esign-button"
              variant="contained"
              disabled={
                submitPodObj &&
                submitPodObj.eSignPatientDetails &&
                submitPodObj.eSignPatientDetails.patientName.valid ===
                  ValidationStatus.VALID &&
                submitPodObj.eSignPatientDetails.updatedPatientEmail.valid ===
                  ValidationStatus.VALID
                  ? false
                  : true
              }
            >
              Send E-Signature
            </ExpressButton>
          </Grid>
        </Grid>
      </div>
    );
  };

  const eSignatureReviewMode = () => {
    return (
      <div className="pod-esign-component" data-testid="pod-esign-component">
        <Grid
          container
          className={
            checkForEditButtonEnability()
              ? "pod-esign-patient-details-container"
              : "pod-esign-patient-details-no-email-container"
          }
        >
          <Grid
            className="pod-esign-patient-grid-item"
            item
            xs={isMobileScreen ? 12 : 5}
          >
            <p className="pod-esign-label" data-testid="pod-esign-name-label">
              Patient/Authorized Agent
            </p>
            <p className="pod-esign-value" data-testid="pod-esign-name-value">
              {data.patientName.value}
            </p>
          </Grid>
          <Grid
            item
            xs={isMobileScreen ? 12 : 5}
            className="pod-esign-patient-email-grid-item"
          >
            <div className="pod-esign-email-div">
              <p
                className="pod-esign-label"
                data-testid="pod-esign-email-label"
              >
                Email Address
              </p>
              <p
                className={
                  data.updatedPatientEmail.valid !== ValidationStatus.VALID
                    ? "pod-esign-value-no-email"
                    : "pod-esign-value"
                }
                data-testid="pod-esign-email-value"
              >
                {data.updatedPatientEmail.value !== ""
                  ? data.updatedPatientEmail.value
                  : "no email listed"}
              </p>
            </div>
          </Grid>
          <Grid
            className="pod-esign-edit-grid-item"
            item
            xs={isMobileScreen ? 11 : 1}
          >
            <Button
              classes={{ root: "pod-esign-edit-button" }}
              data-testid="pod-esign-edit-button"
              onClick={onEditBtnClick}
              disabled={permissionObj?.mappedRolesPermissionData.IsSupportRole}
            >
              edit
            </Button>
          </Grid>
          {submitPodObj &&
            submitPodObj.podESignStatus === SubmitESignPodStatus.SUCCESS && (
              <Grid
                xs={isMobileScreen ? 12 : 7}
                className="pod-esign-success-grid"
              >
                <p className="pod-success">
                  <span className="pod-success-icon">
                    <img
                      src={GreenCheck}
                      alt={GreenCheck}
                      data-testid="pod-success-icon"
                    />
                  </span>
                  <p
                    className="pod-success-text"
                    data-testid="pod-success-text"
                  >
                    The electronic signature request has been sent!
                  </p>
                </p>
              </Grid>
            )}
          {submitPodObj &&
            submitPodObj.podESignStatus === SubmitESignPodStatus.FAILURE && (
              <Grid xs={12} className="pod-esign-failure-grid">
                <span
                  className="pod-failure-icon"
                  data-testid="pod-failure-icon"
                >
                  <img src={RedCheck} alt={RedCheck} />
                </span>
                <span
                  className="pod-failure-text"
                  data-testid="pod-failure-text"
                >
                  Your electronic signature request has failed. Please select
                  another POD/AOB option or contact 3M for assistance{" "}
                  <span className="pod-failure-contact-text">
                    <b>1-800-275-4524 ext 41858. </b>
                  </span>
                </span>
              </Grid>
            )}
          {submitPodObj &&
            submitPodObj.podESignStatus !== SubmitESignPodStatus.FAILURE && (
              <Grid
                item
                xs={
                  isMobileScreen ||
                  (submitPodObj &&
                    submitPodObj.podESignStatus !==
                      SubmitESignPodStatus.SUCCESS)
                    ? 12
                    : 4.5
                }
                className="pod-send-esign-button-grid"
              >
                <ExpressButton
                  clickHandler={sendESignatureBtnAction}
                  parentClass="pod-send-esign-button"
                  testId="pod-send-esign-button"
                  variant="contained"
                  disabled={!checkForEditButtonEnability()}
                >
                  Send E-Signature
                </ExpressButton>
              </Grid>
            )}
        </Grid>
      </div>
    );
  };

  return (
    <Grid
      className="pod-esign-component-main"
      data-testid="pod-esign-component-main"
      xs={12}
    >
      {editESignatureMode ? eSignatureEditMode() : eSignatureReviewMode()}
    </Grid>
  );
};
