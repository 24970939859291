import { Grid, TextField } from "@mui/material";
import { InputWithLabel } from "../../../../../core/inputWithLabel/inputWithLabel.component";
import "./otherDesc.css";

export type OtherDescriptionProps = {
  componentId: string;
  error: boolean;
  errorMessage?: string | null;
  inputId: string;
  onChange: Function;
  primary: boolean;
  required: boolean;
  value: string;
};

export const OtherDescription = ({
  componentId,
  error,
  errorMessage,
  inputId,
  onChange,
  primary,
  required,
  value,
}: OtherDescriptionProps) => {
  return (
    <Grid container className="other-detail-container">
      <InputWithLabel
        error={error}
        id={componentId}
        isRequired={required}
        isShowWarning={true}
        label="Please provide additional detail"
        testId="insurance-additional-detail"
        warningMessage={errorMessage}
      >
        <TextField
          error={error}
          FormHelperTextProps={{ classes: { root: "helperText" } }}
          fullWidth
          id={inputId}
          InputProps={{
            inputProps: { maxLength: 200 },
            classes: {
              root: "textarea-root",
            },
          }}
          multiline
          name="otherAdditionalDetails"
          onChange={(e: any) => onChange(e, primary)}
          required={required}
          rows={4}
          value={value}
        />
      </InputWithLabel>
    </Grid>
  );
};
