import { IInputField } from "../../../core/interfaces/input.interface";

export interface IAuthProfile {
  verifycode: IInputField;
}
export enum ModeForOTP {
  registerpage = 4,
  manageprofile = 3,
}
export enum PathType {
  REGISTER_PAGE = "registerpage",
  MANAGE_PROFILE = "manageprofile",
}
export interface IOTPReqBody {
  Mode: ModeForOTP;
  Code: string;
}
