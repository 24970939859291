import "./dropdownWithMutliCheckbox.css";
import MenuItem from "@mui/material/MenuItem";
import { Button, Checkbox, Select, useMediaQuery } from "@mui/material";
import { ReactComponent as SelectIcon } from "../../assets/Accinfo.svg";
import { ReactComponent as CheckedIcon } from "../../assets/checkedcheckbox.svg";
import { ReactComponent as UncheckIcon } from "../../assets/uncheckedcheckbox.svg";
import { ReactComponent as PartialCheckedIcon } from "../../assets/partial-checked-checkbox.svg";

type Props = {
  deselectAllBtnAction: any;
  disabled?: boolean;
  handleChange: any; // onchange function
  menuOptionClassName?: string;
  name: string; //dropdown name element
  open?: boolean;
  setOpen?: Function;
  options: Array<DropDownMultiCheckboxValue>;
  paperPropsClassName?: string;
  placeHolder?: string;
  selectAllBtnAction: any;
  selectClassName?: string; // dropdown classname for style
  selectPropsClassName?: string;
  showFooterButton?: boolean;
  testId?: string; // testid for easily trageting while writing unittestcases
  value: string[]; // dropdown selected value
};

export const DropDownWithMultiCheckbox = ({
  deselectAllBtnAction,
  disabled = false,
  handleChange,
  menuOptionClassName = "menuItemDrop",
  name,
  open,
  setOpen,
  options,
  paperPropsClassName = "paperpropsClass",
  placeHolder,
  showFooterButton = false,
  selectAllBtnAction,
  selectClassName,
  selectPropsClassName,
  testId,
  value,
}: Props) => {
  const isMobileScreen = useMediaQuery("(max-width:920px)");
  const handleClose = () => {
    if (setOpen) {
      setOpen(false);
    }
  };
  const handleOpen = () => {
    if (setOpen) {
      setOpen(true);
    }
  };

  const checkboxMenuItem = (
    element: DropDownMultiCheckboxValue,
    index: number,
    parentIndex?: number | null
  ) => {
    return (
      <MenuItem
        className={menuOptionClassName}
        key={`menu-option-${element.code}`}
      >
        <div className="menu-option">
          <Checkbox
            checked={element.isSelected}
            checkedIcon={
              element.isPartialSelected ? (
                <PartialCheckedIcon />
              ) : (
                <CheckedIcon />
              )
            }
            className={selectClassName}
            data-testid={`menu-option-testId-${element.code}`}
            disabled={false}
            icon={<UncheckIcon />}
            name={element.text}
            onClick={() => handleChange(element, index, parentIndex)}
            value={element.isSelected}
          />
          <p
            className="checkbox-description-text"
            data-testid="checkbox-description-text"
            onClick={() => handleChange(element, index, parentIndex)}
          >
            {element.text}
          </p>
        </div>
      </MenuItem>
    );
  };

  return (
    <Select
      classes={{
        select: selectPropsClassName,
      }}
      className={selectClassName}
      data-testid={testId}
      disabled={disabled}
      displayEmpty={false}
      IconComponent={SelectIcon}
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        PaperProps: {
          className: paperPropsClassName,
          onMouseLeave: !isMobileScreen ? handleClose : () => {},
        },
      }}
      multiple
      name={name}
      onChange={(e) => {
        e.stopPropagation();
      }}
      onClose={handleClose}
      onOpen={handleOpen}
      open={open}
      renderValue={(selectedValues) =>
        selectedValues.length > 0 ? selectedValues.join(", ") : placeHolder
      }
      variant="outlined"
      value={value}
    >
      {options &&
        options.map((element, parentIndex): any => {
          let checkbox = checkboxMenuItem(element, parentIndex, null);
          let childCheckboxs = null;
          if (element.options && element.options.length > 0) {
            childCheckboxs = element.options.map((childElement, index): any => {
              return checkboxMenuItem(childElement, index, parentIndex);
            });
          }
          return (
            <div className="parent-option">
              {checkbox}
              {childCheckboxs && (
                <div className="child-options">{childCheckboxs}</div>
              )}
            </div>
          );
        })}
      {showFooterButton && (
        <div className="drop-down-footer-buttons">
          <Button
            className="drop-down-footer-button"
            onClick={deselectAllBtnAction}
          >
            Clear all
          </Button>
          <Button
            className="drop-down-footer-button"
            onClick={selectAllBtnAction}
          >
            Select all
          </Button>
        </div>
      )}
    </Select>
  );
};

export interface DropDownMultiCheckboxValue {
  code: string;
  isSelected?: boolean;
  isPartialSelected?: boolean;
  options?: DropDownMultiCheckboxValue[];
  order: number;
  text: string;
}
