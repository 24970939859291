import {
  IWounds,
  IWoundsForRO,
} from "../components/myPatients/alert.interface";
import {
  IAlertTypes,
  IPatientAlert,
  ISeverityTypes,
  ISharedOrderData,
  statusDelayReason,
} from "../components/myPatients/patient.interface";
import { convertStringToDate, convertToMmmDDFormat } from "./utilityFunctions";

export const uid = () => Math.floor(Math.random() * Date.now()).toString();

export const getAlertName = (alertType: IAlertTypes) => {
  switch (alertType) {
    case IAlertTypes.MISRX:
      return "Missing Rx";
    case IAlertTypes.MSDOC:
      return "Missing Docs";
    case IAlertTypes.MSDUE:
      return "Measurements Due";
    case IAlertTypes.CONPL:
      return "Confirm Placement";
    case IAlertTypes.DISPEN:
      return "Discharge Pending";
    case IAlertTypes.PNDSO:
      return "Pending Supply Order";
    case IAlertTypes.PODEL:
      return "Proof of Delivery Needed";
    case IAlertTypes.SHODR:
      return "Shared Order";
    case IAlertTypes.SUPSH:
      return "Supplies Shipped";
    case IAlertTypes.APIERROR:
      return "Api Error";
    case IAlertTypes.ORDSRC:
      return "Order Source";
  }
};

export const getAlertTypeCode = (alertName: String) => {
  switch (alertName) {
    case "Missing Rx":
      return IAlertTypes.MISRX;
    case "Missing Docs":
      return IAlertTypes.MSDOC;
    case "Confirm Placement":
      return IAlertTypes.CONPL;
    case "Pending Supply Order":
      return IAlertTypes.PNDSO;
    case "Proof of Delivery Needed":
      return IAlertTypes.PODEL;
    case "Supplies Shipped":
      return IAlertTypes.SUPSH;
    case "Measurements Due":
      return IAlertTypes.MSDUE;
  }
};

export function createAlert(
  alertType: IAlertTypes,
  label: string = "",
  alertDt: Date | null = null,
  sharedData: ISharedOrderData | null = null
): IPatientAlert {
  let alert: IPatientAlert = {
    alertID: uid(),
    alertName: getAlertName(alertType),
    alertSubLabel: label,
    alertDate: alertDt,
    alertType: alertType,
    severity: null,
    sharedData: sharedData,
  };
  return alert;
}

export function createMeasurementDueAlert(
  wounds: Array<IWounds>,
  alertID: string,
  patientAlerts: Array<IPatientAlert>
) {
  wounds.forEach((wound) => {
    wound.PendingCycles.forEach((cycle) => {
      const from = new Date(cycle.From);
      const to = new Date(cycle.To);
      const severity = getSeverity(from, to);
      if (severity !== 0) {
        let alert: IPatientAlert = {
          alertID: alertID ? alertID : "",
          alertName: getAlertName(IAlertTypes.MSDUE),
          alertSubLabel: getMeasurementDueAlertTitle(from, to),
          alertDate: null,
          alertType: IAlertTypes.MSDUE,
          severity: severity,
          woundOrderID: wound.Id,
          assessmentFromDate: from,
          assessmentToDate: to,
        };
        patientAlerts.push(alert);
      }
    });
  });
}

export function createMeasurementDueAlertRo(
  wounds: Array<IWoundsForRO>,
  alertID: string
): Array<IPatientAlert> {
  let patientAlerts: Array<IPatientAlert> = [];
  wounds?.forEach((y) => {
    y.pendingCycles?.forEach((x) => {
      const from = new Date(x.from);
      const to = new Date(x.to);

      let alert: IPatientAlert = {
        alertID: alertID ? alertID : "",
        alertName: getAlertName(IAlertTypes.MSDUE),
        alertSubLabel: getMeasurementDueAlertTitle(from, to, true),
        alertDate: null,
        alertType: IAlertTypes.MSDUE,
        severity: getSeverity(from, to),
        woundOrderID: y.id,
        assessmentFromDate: from,
        assessmentToDate: to,
      };
      patientAlerts.push(alert);
    });
  });

  return patientAlerts;
}

export function getSeverity(from: Date, to: Date): ISeverityTypes {
  const today = new Date();
  let severity = ISeverityTypes.LOW;
  let inForteenDays = new Date();
  inForteenDays.setDate(today.getDate() + 14);
  const todayDate = today ? convertStringToDate(today.toString()) : "";
  const toDate = to ? convertStringToDate(to.toString()) : "";
  const fromDate = from ? convertStringToDate(from.toString()) : "";
  if (todayDate > toDate) {
    severity = ISeverityTypes.HIGH;
  } else if (todayDate >= fromDate && todayDate <= toDate) {
    severity = ISeverityTypes.MEDIUM;
  } else if (from <= inForteenDays) {
    severity = ISeverityTypes.LOW;
  }
  return severity;
}

export function getMeasurementDueAlertTitle(
  from: Date,
  to: Date,
  excludePrefix: boolean = false
): string {
  if (excludePrefix) {
    return `${convertToMmmDDFormat(from)} and ${convertToMmmDDFormat(to)}`;
  } else {
    return `For ${convertToMmmDDFormat(from)}-${convertToMmmDDFormat(to)}`;
  }
}

export function getSupplyOrderPendingAlert(
  alertType: IAlertTypes,
  label: string = "",
  ropn: string | null
): IPatientAlert {
  let pendingAlert: IPatientAlert = {
    alertID: uid(),
    alertName: label
      .toLowerCase()
      .includes("Missing Excessive Supply Form".toLowerCase())
      ? "Excessive Supply"
      : getAlertName(alertType),
    alertSubLabel: label,
    alertDate: null,
    alertType: alertType,
    severity: null,
    ropn: ropn,
  };
  return pendingAlert;
}

export function getMissingDocsAlert(
  alertType: IAlertTypes,
  label: string = "",
  statusDelayReason?: statusDelayReason
): IPatientAlert {
  let missingDocAlert: IPatientAlert = {
    alertID: uid(),
    alertName: getAlertName(alertType),
    alertSubLabel: label,
    alertDate: null,
    alertType: alertType,
    severity: null,
    statusDelayReason: statusDelayReason,
  };
  return missingDocAlert;
}

export function getSupplyOrderDeliveredAlert(
  alertType: IAlertTypes,
  ropn: string | null,
  alertDt: Date | null = null,
  trackingNumber: string
): IPatientAlert {
  let suppliesDeliveredAlert: IPatientAlert = {
    alertID: uid(),
    alertName: getAlertName(alertType),
    alertSubLabel: "",
    alertDate: alertDt,
    alertType: alertType,
    severity: null,
    ropn: ropn,
    trackingNumber: trackingNumber,
  };
  return suppliesDeliveredAlert;
}
