import "./manageSiteStatuses.css";
import {
  DD_SITE_STATUS_NOTE_TYPE_CONTENT,
  DD_SITE_STATUS_STATUSES_CONTENT,
} from "../../../util/staticText";
import {
  agGridGetRowStyle,
  getCodeFromText,
  getTextFromCode,
  makeCapitalEachWordInString,
} from "../../../util/utilityFunctions";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-enterprise";
import { format } from "react-string-format";
import {
  InternalSignOnContext,
  InternalSignOnContextType,
} from "../../../context/InternalSignOnContext";
import { useContext, useMemo, useRef, useState } from "react";
import AddIcon from "@mui/icons-material/AddCircleOutline";
import { Popup } from "../../../core/popup/popup.component";
import { Button, Grid, useMediaQuery } from "@mui/material";
import { IManageSiteStatus } from "./manageSiteStatuses.interface";
import { getdropDownContent } from "../../../util/dropDownService";
import { getSiteStatuses } from "../../../util/siteStatusesService";
import { ERROR_MSG_GET_SITE_STATUSES } from "../../../util/errorMsg";
import { LoadingSpinner } from "../../../core/loader/LoadingSpinner";
import ErrorPopup from "../../../core/errorPopup/errorPopup.component";
import { Navigator } from "../../helpAndSupport/Navigator/navigator.component";
import { CustomDropDown } from "../../../core/customDropdown/customDropdown.component";
import { ManageSiteStatusesMobile } from "./manageSiteStatusesMobile/manageSiteStatusesMobile.component";
import { useHistory } from "react-router-dom";
import {
  AddSiteStatusContext,
  AddSiteStatusContextType,
} from "../../../context/AddSiteStatusContext";
import { IAddSiteStatus } from "./addSiteStatus/addSiteStatus.interface";
import { addSiteStatusResponseMapper } from "./addSiteStatus/mapper/addSiteStatusResponseMapper";

interface Props {
  testData?: IManageSiteStatus[];
}

export const ManageSiteStatuses = ({ testData }: Props) => {
  const [showLoader, setShowLoader] = useState<boolean>(false);

  const [ddNoteType, setDDNoteType] = useState("");
  const [ddStatuse, setDDStatuse] = useState("");

  const [originalSiteStatuses, setOriginalSiteStatuses] = useState<
    IManageSiteStatus[]
  >([]);
  const [siteStatuses, setSiteStatuses] = useState<IManageSiteStatus[]>([]);

  const [noteTypes, setNoteTypes] = useState([]);
  const [noteTypesText, setNoteTypesText] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [statusesText, setStatusesText] = useState([]);

  // Error Pop up
  const [errorCode, setErrorCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorPopUpFlag, setErrorPopUpFlag] = useState(false);

  const isMobileScreen = useMediaQuery("(max-width:600px)");
  const isSmallIPad = useMediaQuery("(max-width:768px)");
  const history = useHistory();

  const internalObj = useContext<InternalSignOnContextType | null>(
    InternalSignOnContext
  );

  const siteStatusObj = useContext<AddSiteStatusContextType | null>(
    AddSiteStatusContext
  );

  const fetchDropDownContent = async () => {
    try {
      const ddContent = format(
        "{0},{1}",
        DD_SITE_STATUS_NOTE_TYPE_CONTENT,
        DD_SITE_STATUS_STATUSES_CONTENT
      );
      const data = await getdropDownContent(ddContent);
      if (data.items.length > 0) {
        const noteTypesObject = data.items.filter(
          (item: { name: string }) =>
            item.name === DD_SITE_STATUS_NOTE_TYPE_CONTENT
        );
        const noteTypesArray = noteTypesObject[0].data.sort(
          (a: { order: number }, b: { order: number }) =>
            a.order > b.order ? 1 : -1
        );
        setNoteTypes(noteTypesArray);
        setNoteTypesText(noteTypesArray.map((x: { text: string }) => x.text));
        const statusesObject = data.items.filter(
          (item: { name: string }) =>
            item.name === DD_SITE_STATUS_STATUSES_CONTENT
        );
        const statusesArray = statusesObject[0].data.sort(
          (a: { order: number }, b: { order: number }) =>
            a.order > b.order ? 1 : -1
        );
        setStatuses(statusesArray);
        setStatusesText(statusesArray.map((x: { text: string }) => x.text));
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getSiteStatusesAPI = async () => {
    setShowLoader(true);
    const response = await getSiteStatuses();
    setShowLoader(false);
    if (response && response.succeeded) {
      if (response.items && response.items.length > 0) {
        updateApplyAndShowTo(response.items);
      } else {
        setSiteStatuses([]);
      }
    } else {
      setErrorMessage(ERROR_MSG_GET_SITE_STATUSES);
      setErrorCode(response?.error?.code ||  response?.error?.errorCode || response?.status);
      setErrorPopUpFlag(true);
    }
  };

  const editButtonClick = async (siteStatusDetails: IManageSiteStatus) => {
    siteStatusObj?.setIsAddButtonClick(false);
    let mapperRes: IAddSiteStatus = await addSiteStatusResponseMapper(
      siteStatusDetails
    );
    siteStatusObj?.setSiteStatusDetails(mapperRes);
    window.scrollTo(0, 0);
    history.push("/administration/manageSiteStatuses/siteStatusDetail");
  };

  const defaultColDef = {
    autoHeight: true,
    flex: 1,
    filter: false,
    sortable: true,
    unSortIcon: true,
    suppressSizeToFit: false,
    suppressMovable: true,
    suppressMenu: true,
    wrapText: true,
  };

  const columnDefs: ColDef[] = [
    {
      field: "id",
      filter: false,
      headerName: "",
      sortable: false,
      width: 70,
      cellRenderer: function (params: any) {
        return (
          <Button
            className="site-status-edit"
            data-testid="site-status-edit"
            onClick={() => {
              editButtonClick(params.data);
            }}
          >
            Edit
          </Button>
        );
      },
    },
    {
      flex: 2,
      field: "title",
      headerName: "Site Status Message",
      wrapText: true,
      cellRenderer: function (params: any) {
        return <p data-testid="site-status-title">{params.data.title}</p>;
      },
    },
    {
      field: "statusType",
      headerName: "Type",
      width: 100,
      cellRenderer: function (params: any) {
        return (
          <p data-testid="site-status-type">
            {makeCapitalEachWordInString(params.data.statusType)}
          </p>
        );
      },
    },
    {
      field: "isActive",
      headerName: "Status",
      width: 100,
      cellRenderer: function (params: any) {
        return (
          <p className="site-status-title" data-testid="site-status-status">
            {params.data.isActive ? "Active" : "Inactive"}
          </p>
        );
      },
    },
    {
      field: "applyTo",
      headerName: "Apply to",
      width: 100,
      cellRenderer: function (params: any) {
        return <p data-testid="site-status-apply-to">{params.data.applyTo}</p>;
      },
    },
    {
      field: "showTo",
      headerName: "Show to",
      width: 100,
      wrapText: true,
      cellRenderer: function (params: any) {
        return (
          <p data-testid="site-status-show-to">
            {params.data.showTo.join(", ")}
          </p>
        );
      },
    },
  ];

  const updateApplyAndShowTo = (statuses: IManageSiteStatus[]) => {
    let updatedStatuses = statuses.map(
      (status: IManageSiteStatus, index: number) => {
        let applyTo = [];
        if (
          status.applyToLogin !== null &&
          status.applyToLogin > 0 &&
          status.applyToFull !== null &&
          status.applyToFull > 0
        ) {
          applyTo.push("All");
        } else if (status.applyToLogin !== null && status.applyToLogin > 0) {
          applyTo.push("Login");
        } else if (status.applyToFull !== null && status.applyToFull > 0) {
          applyTo.push("Full");
        } else {
          applyTo.push("-");
        }
        status.applyTo = applyTo;
        let showTo = [];
        if (
          status.showToExternalUsers &&
          status.showToInternalUsers &&
          status.showToUnAuthenticatedUsers
        ) {
          showTo.push("All");
        } else {
          if (status.showToExternalUsers) {
            showTo.push("Facility");
          }
          if (status.showToInternalUsers) {
            showTo.push("Internal");
          }
          if (status.showToUnAuthenticatedUsers) {
            showTo.push("Base");
          }
        }
        if (showTo.length === 0) {
          showTo.push("-");
        }
        status.showTo = showTo;
        return status;
      }
    );
    setOriginalSiteStatuses(updatedStatuses);
    setSiteStatuses(updatedStatuses);
  };

  const validateAndSetData = (e: any) => {
    let { name, value } = e.target;
    switch (name) {
      case "noteTypes":
        value = getCodeFromText(noteTypes, value) ?? "";
        setDDNoteType(value);
        doFilterBasedOnDropdownForStatuses(value, ddStatuse);
        break;
      case "statuses":
        value = getCodeFromText(statuses, value) ?? "";
        setDDStatuse(value);
        doFilterBasedOnDropdownForStatuses(ddNoteType, value);
        break;
      default:
        break;
    }
  };

  const doFilterBasedOnDropdownForStatuses = (note: string, status: string) => {
    let filteredStatuses = originalSiteStatuses;
    filteredStatuses = originalSiteStatuses.filter(
      (manageStatus: IManageSiteStatus) =>
        ((note.length > 0 &&
          manageStatus.statusType &&
          manageStatus.statusType.toLowerCase() === note.toLowerCase()) ||
          note.length === 0) &&
        ((status.length > 0 &&
          Number(manageStatus.isActive) === Number(status)) ||
          status.length === 0)
    );
    setSiteStatuses(filteredStatuses);
  };

  const addNewSiteStatusAction = (e: any) => {
    siteStatusObj?.resetSiteStatusDetails();
    history.push("/administration/manageSiteStatuses/siteStatusDetail");
  };

  useMemo(() => {
    if (testData) {
      updateApplyAndShowTo(testData);
    } else {
      fetchDropDownContent();
      getSiteStatusesAPI();
    }
  }, []);

  return (
    <div className="manage-site-statuses-component-container">
      <div
        className="manage-site-statuses-component"
        data-testid="manage-site-statuses-component"
      >
        <Navigator
          array={[
            {
              route: internalObj?.isInternalAdministration
                ? "/internalAdministration"
                : "/administration",
              pageName: "Administration",
            },
          ]}
          className="manage-site-statuses-component-route-section"
          title="Site status"
        />
        <p
          className="manage-site-statuses-header"
          data-testid="manage-site-statuses-header"
        >
          Manage site statuses
        </p>
        <div className="short-form">
          <Grid
            className="manage-site-statuses-header-grid-container"
            container
          >
            <Grid
              className="manage-site-statuses-header-grid-item"
              item
              xs={isMobileScreen ? 0 : isSmallIPad ? 2 : 4}
            ></Grid>
            <Grid
              className="manage-site-statuses-header-grid-item"
              item
              xs={isMobileScreen ? 12 : isSmallIPad ? 10 : 8}
            >
              <div className="site-status-filters-and-add-new">
                <div className="site-status-filters">
                  <CustomDropDown
                    handleChange={validateAndSetData}
                    menuItem={noteTypesText}
                    name="noteTypes"
                    placeHolder="All note types"
                    selectpropsClassName={
                      ddNoteType !== "" ? "site-statuses-select" : "placeholder"
                    }
                    selectClassName={
                      ddNoteType !== "" ? "site-statuses-input" : "placeholder"
                    }
                    testId="site-statuses-note-type-value"
                    value={getTextFromCode(noteTypes, ddNoteType)}
                  />
                </div>
                <div className="site-status-filters">
                  <CustomDropDown
                    handleChange={validateAndSetData}
                    menuItem={statusesText}
                    name="statuses"
                    placeHolder="All statuses"
                    selectpropsClassName={
                      ddStatuse !== "" ? "site-statuses-select" : "placeholder"
                    }
                    selectClassName={
                      ddStatuse !== "" ? "site-statuses-input" : "placeholder"
                    }
                    testId="site-statuses-statuses-value"
                    value={getTextFromCode(statuses, ddStatuse)}
                  />
                </div>
                <Button
                  classes={{ root: "add-new-site-status-button" }}
                  data-testid="add-new-site-status-button"
                  onClick={addNewSiteStatusAction}
                  disableFocusRipple={true}
                  disableElevation={true}
                  startIcon={<AddIcon className="add-new-user-icon" />}
                  variant="text"
                >
                  {isMobileScreen ? "Add" : "Add new site status"}
                </Button>
              </div>
            </Grid>
          </Grid>
          {!showLoader && (
            <>
              {isMobileScreen ? (
                <ManageSiteStatusesMobile
                  siteStatuses={siteStatuses}
                  editButtonClick={editButtonClick}
                />
              ) : (
                <div
                  className="ag-theme-quartz"
                  data-testid="site-status-detail-table"
                  style={{ height: 500 }}
                >
                  <AgGridReact
                    autoSizeStrategy={{
                      type: "fitGridWidth",
                    }}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    getRowStyle={agGridGetRowStyle}
                    rowData={siteStatuses}
                    suppressRowClickSelection={true}
                    suppressDragLeaveHidesColumns={true}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <Popup
        openFlag={showLoader}
        closeHandler={() => setShowLoader(false)}
        dialogParentClass={"manage-site-statuses-loader-pop-up"}
        data-testid="manage-site-statuses-pop-up"
        hideCloseButton={true}
      >
        <div
          className="manage-site-statuses-loader"
          data-testid="manage-site-statuses-loader"
        >
          <LoadingSpinner />
        </div>
      </Popup>
      <ErrorPopup
        errorPopupFlag={errorPopUpFlag}
        handleBackButton={() => {
          setErrorPopUpFlag(false);
        }}
        popUpStyles="errorPopup"
        errorMessage={errorMessage}
        buttontitle="Done"
        showTitle={false}
        isShortSentence={true}
        errorCode={errorCode}
      />
    </div>
  );
};
