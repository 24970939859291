import { Grid } from "@mui/material";
import { useContext } from "react";
import { MobileDisplayContext } from "../../../../../context/MobileDisplayContext";
import {
  formatPhoneNumber,
  makeCapitalEachWordInString,
} from "../../../../../util/utilityFunctions";
import { IPrescriberInformation } from "../prescriberInformation.interface";
import "./prescriberInformationReview.css";

interface Props {
  data: IPrescriberInformation;
  editButtonAction?: any;
  isSummary?: boolean;
}

export const PrescriberInformationReview = ({
  data,
  editButtonAction,
  isSummary = false,
}: Props) => {
  const { isMobileScreen } = useContext(MobileDisplayContext);
  const showTitleAndValue = (
    title: string,
    value: string | null,
    testId: string
  ) => {
    return (
      <div className="show-title-and-value-div">
        <p
          className="show-title-and-value-title"
          data-testid={`${testId}-title`}
          id={`${testId}-title-id`}
        >
          {title}
        </p>
        <p
          className="show-title-and-value-value"
          data-testid={`${testId}-value`}
          id={`${testId}-value-id`}
        >
          {value && value !== "" ? value.trim() : "--"}
        </p>
      </div>
    );
  };

  return (
    <div
      className="prescriber-information-review-component"
      data-testid="prescriber-information-review-component"
    >
      <div className="prescriber-information-review-header">
        <p
          className="prescriber-information-review-title"
          data-testid="prescriber-information-review-title"
          id="prescriber-information-review-title-id"
        >
          Prescriber Information
        </p>
        {!isSummary && (
          <button
            className="prescriber-information-review-edit-button"
            data-testid="prescriber-information-review-edit-button"
            id="prescriber-information-review-edit-button-id"
            onClick={() =>
              editButtonAction(
                "in-patient-order-prescriber-informantion-component"
              )
            }
          >
            Edit
          </button>
        )}
      </div>
      <Grid
        className="prescriber-information-review-grid-container"
        container
        id="id_patient-information-review-grid-container"
      >
        <Grid
          className="prescriber-information-review-grid-item"
          id="id_patient-information-review-grid-item"
          item
          xs={isMobileScreen ? 6 : 4}
        >
          {showTitleAndValue(
            "Prescriber Name",
            data.firstName
              ? makeCapitalEachWordInString(
                  data.firstName + " " + data.lastName
                )
              : "",
            "prescriber-name"
          )}
        </Grid>
        <Grid
          className="prescriber-information-review-grid-item"
          id="id_patient-information-review-grid-item"
          item
          xs={isMobileScreen ? 6 : 4}
        >
          {showTitleAndValue("NPI", data.npi, "prescriber-npi")}
        </Grid>
        <Grid
          className="prescriber-information-review-grid-item"
          id="id_patient-information-review-grid-item"
          item
          xs={isMobileScreen ? 6 : 4}
        >
          <div
            className="show-title-and-value-div"
            id="id_show-title-and-value-div"
          >
            <p
              className="show-title-and-value-title"
              data-testid="phone"
              id="id_show-title-and-value-title"
            >
              Phone Number
            </p>
            <p
              className="show-title-and-value-value"
              data-testid="phone-value"
              id="id_show-phone-and-value-title"
            >
              {data?.telephoneNumber ? (
                <a href={`tel:${formatPhoneNumber(data.telephoneNumber)}`}>
                  {formatPhoneNumber(data.telephoneNumber)}
                </a>
              ) : (
                "--"
              )}
            </p>
          </div>
        </Grid>

        <Grid
          className="prescriber-information-review-grid-item"
          item
          xs={isMobileScreen ? 6 : 4}
        >
          {showTitleAndValue(
            "Fax Number",
            data.faxNumber!,
            "device-placement-product-desc"
          )}
        </Grid>
        <Grid
          className="prescriber-information-review-grid-item"
          item
          id="id_prescriber-information-review-grid-item"
          xs={isMobileScreen ? 6 : 4}
        >
          <div className="show-title-and-value-div" id="id_email">
            <p
              className="show-title-and-value-title"
              data-testid="email-title"
              id="id_email-div"
            >
              Email
            </p>
            <p
              className="show-title-and-value-value"
              data-testid="email-value"
              id="id_email-div-value"
            >
              {data && data.email ? (
                <a
                  className="contact-value-email-phone"
                  href={`mailto:${data?.email!}`}
                >
                  {data?.email!}
                </a>
              ) : (
                "--"
              )}
            </p>
          </div>
        </Grid>
        <Grid
          className="prescriber-information-review-grid-item"
          item
          xs={isMobileScreen ? 6 : 4}
        >
          <div className="show-title-and-value-div">
            <p
              className="show-title-and-value-title"
              data-testid="address-title"
              id="id_add-div"
            >
              Address
            </p>
            <p
              className="show-title-and-value-value"
              data-testid="address1-value"
              id="id_add_1-div"
            >
              {data && data.address1 && data.address1 !== ""
                ? makeCapitalEachWordInString(data.address1)
                : "--"}
            </p>
            {data && data.address2 && data.address2 !== "" && (
              <p
                className="show-title-and-value-value"
                data-testid="address2-value"
                id="id_add_2-div"
              >
                {makeCapitalEachWordInString(data.address2)}
              </p>
            )}
            <p
              className="show-title-and-value-value"
              data-testid="city-value"
              id="id_city-div"
            >
              {makeCapitalEachWordInString(data?.city ?? "")}
              {data?.city ? ", " : ""}
              {data?.state} {data?.zipCode}
            </p>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
