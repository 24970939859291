import "./deliveryContact.css";
import {
  formatNumberBrackets,
  getCodeFromText,
  getTextFromCode,
  makeCapitalEachWordInString,
} from "../../../util/utilityFunctions";
import InputMask from "react-input-mask";
import {
  NewOrderContext,
  NewOrderContextType,
} from "../../../context/NewOrderContext";
import { format } from "react-string-format";
import { NewOrderValidator } from "../newOrder.validator";
import { getDeepClone } from "../../../util/ObjectFunctions";
import React, { useContext, useEffect, useState } from "react";
import { DD_DELIVERY_CONTACT } from "../../../util/staticText";
import { Box, Grid, InputBase, Typography } from "@mui/material";
import { IDeliveryContactInfo } from "./deliveryContact.interface";
import { getdropDownContent } from "../../../util/dropDownService";
import { ERROR_TO_ADD_CONTACT_DETAIALS } from "../../../util/errorMsg";
import { AuthContext, AuthContextType } from "../../../context/AuthContext";
import {
  IInputField,
  ValidationStatus,
} from "../../../core/interfaces/input.interface";
import { MobileDisplayContext } from "../../../context/MobileDisplayContext";
import { ExpressButton } from "../../../core/expressButton/expressButton.component";
import { ReactComponent as WarningIcon } from "../../../assets/WarningIndicator.svg";
import { InputWithLabel } from "../../../core/inputWithLabel/inputWithLabel.component";
import { CustomDropDown } from "../../../core/customDropdown/customDropdown.component";
import { DeliveryContactReviewOrder } from "./reviewOrder/deliveryContactReviewOrder.component";

export const DeliveryContact = ({
  data,
  Validator = new NewOrderValidator(),
  setData,
  isReviewOrder = false,
  editButtonClicked,
  isOrderSummary = false,
  tempData,
  setTempData,
  isNextButtonClicked,
}: IDeliveryContactInfo) => {
  const [validator] = useState<NewOrderValidator>(Validator!);
  const { isMobileScreen } = useContext(MobileDisplayContext);
  const [focusClasses, setFocusClasses] = useState({
    deliveryContactPhone: "",
  });
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const newOrderObj = useContext<NewOrderContextType | null>(NewOrderContext);
  const [deliveryContactQuickFill, setDeliveryContactQuickFill] =
    React.useState([]);
  const [deliveryContactQuickFillText, setDeliveryContactQuickFillText] =
    React.useState([]);

  useEffect(() => {
    fetchDeliveryQuickFillOptions();
  }, []);

  useEffect(() => {
    if (!newOrderObj?.isComingFromPrev && !isReviewOrder) {
      const value = data.deliveryContactQuickFillType?.value;
      switch (value) {
        case "1":
          handleSameAsPatient(value);
          break;
        case "2":
          handleSameAsRequestor(value);
          break;
        case "3":
          handleSameAsSubmitter(value);
          break;
      }
    }
  }, [
    data.firstName,
    data.lastName,
    data.phone,
    newOrderObj?.requesterInfo.requesterFirstName.value,
    newOrderObj?.requesterInfo.requesterLastName.value,
    newOrderObj?.requesterInfo.requesterPhoneNumber.value,
  ]);

  const validateAndSetData = (e: any) => {
    let { value, name } = e.target;
    newOrderObj?.setIsHandleChangeTriggered(true);
    if (
      name === "deliveryContactPhone" &&
      data.deliveryContactPhone.valid === ValidationStatus.UNTOUCHED &&
      (value === "(___) ___-____" || value === "")
    ) {
      return;
    }
    if (name === "deliveryContactQuickFillType") {
      value = getCodeFromText(deliveryContactQuickFill, value);
      switch (value) {
        case "1":
          handleSameAsPatient(value);
          break;
        case "2":
          handleSameAsRequestor(value);
          break;
        case "3":
          handleSameAsSubmitter(value);
          break;
        case "4":
          handleResetDeliveryContact(value);
          break;
      }
    } else {
      const isValid = validator.validate(value, name);
      setData({
        ...data,
        [name]: {
          ...Object(data)[name],
          valid: isValid!.status,
          value: value,
          errorMessage: null,
        },
      });
    }
  };

  const setClasses = (e: any, classname: string) => {
    setFocusClasses(
      Object.assign({}, focusClasses, { [e.target.name]: classname })
    );
  };

  const fetchDeliveryQuickFillOptions = async () => {
    try {
      const ddContent = format("{0}", DD_DELIVERY_CONTACT);
      const dropDowndata = await getdropDownContent(ddContent);
      if (dropDowndata.items.length > 0) {
        const deliveryContactTypeArray = dropDowndata.items[0].data.sort(
          (a: { order: number }, b: { order: number }) =>
            a.order > b.order ? 1 : -1
        );
        setDeliveryContactQuickFill(deliveryContactTypeArray);
        setDeliveryContactQuickFillText(
          deliveryContactTypeArray.map((x: { text: string }) => x.text)
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleSameAsPatient = (value: string) => {
    const tempData = {
      ...data,
      deliveryContactFirstName: {
        ...data.deliveryContactFirstName,
        valid: data.firstName.valid,
        value: data.firstName.value,
      },
      deliveryContactLastName: {
        ...data.deliveryContactLastName,
        valid: data.lastName.valid,
        value: data.lastName.value,
      },
      deliveryContactPhone: {
        ...data.deliveryContactPhone,
        valid: data.phone.valid,
        value: data.phone.value.replace(/[^+\d]+/g, ""),
      },
      deliveryContactQuickFillType: {
        ...data.deliveryContactQuickFillType,
        valid: ValidationStatus.VALID,
        value: value,
      },
    };
    setData(tempData);
  };

  const handleSameAsRequestor = (value: string) => {
    setData({
      ...data,
      deliveryContactFirstName: {
        ...data.deliveryContactFirstName,
        valid: newOrderObj?.requesterInfo.requesterFirstName.valid,
        value: newOrderObj?.requesterInfo.requesterFirstName.value,
      },
      deliveryContactLastName: {
        ...data.deliveryContactLastName,
        valid: newOrderObj?.requesterInfo.requesterLastName.valid,
        value: newOrderObj?.requesterInfo.requesterLastName.value,
      },
      deliveryContactPhone: {
        ...data.deliveryContactPhone,
        valid: newOrderObj?.requesterInfo.requesterPhoneNumber.valid,
        value: newOrderObj?.requesterInfo.requesterPhoneNumber.value,
      },
      deliveryContactQuickFillType: {
        ...data.deliveryContactQuickFillType,
        valid: ValidationStatus.VALID,
        value: value,
      },
    });
  };

  const handleSameAsSubmitter = (value: string) => {
    if (authObj && authObj.userProfile) {
      const firstName = authObj.userProfile.firstName;
      const lastName = authObj.userProfile.lastName;
      const phoneNumber =
        authObj.userProfile.phoneNumber || authObj.userProfile.mobilePhoneNumber
          ? authObj.userProfile.phoneNumber
            ? authObj.userProfile.phoneNumber
            : authObj.userProfile.mobilePhoneNumber
          : "";
      setData({
        ...data,
        deliveryContactFirstName: {
          ...data.deliveryContactFirstName,
          valid:
            firstName !== ""
              ? ValidationStatus.VALID
              : ValidationStatus.UNTOUCHED,
          value: firstName,
        },
        deliveryContactLastName: {
          ...data.deliveryContactLastName,
          valid:
            lastName !== ""
              ? ValidationStatus.VALID
              : ValidationStatus.UNTOUCHED,
          value: lastName,
        },
        deliveryContactPhone: {
          ...data.deliveryContactPhone,
          valid:
            phoneNumber && phoneNumber !== ""
              ? ValidationStatus.VALID
              : ValidationStatus.UNTOUCHED,
          value: phoneNumber,
        },
        deliveryContactQuickFillType: {
          ...data.deliveryContactQuickFillType,
          valid: ValidationStatus.VALID,
          value: value,
        },
      });
    }
  };

  const handleResetDeliveryContact = (value: string) => {
    setData({
      ...data,
      deliveryContactFirstName: {
        ...data.deliveryContactFirstName,
        required: true,
        valid: ValidationStatus.UNTOUCHED,
        value: "",
      },
      deliveryContactLastName: {
        ...data.deliveryContactLastName,
        required: true,
        valid: ValidationStatus.UNTOUCHED,
        value: "",
      },
      deliveryContactPhone: {
        ...data.deliveryContactPhone,
        required: true,
        valid: ValidationStatus.UNTOUCHED,
        value: "",
      },
      deliveryContactQuickFillType: {
        ...data.deliveryContactQuickFillType,
        valid: ValidationStatus.VALID,
        value: value,
      },
    });
  };

  const handleEditContact = () => {
    newOrderObj?.setEditMode(true);
    setTempData(getDeepClone(data));
  };

  const handleCancelEditContact = () => {
    newOrderObj?.setEditMode(false);
    setData({
      ...data,
      deliveryContactFirstName: tempData.deliveryContactFirstName,
      deliveryContactLastName: tempData.deliveryContactLastName,
      deliveryContactPhone: tempData.deliveryContactPhone,
      deliveryContactQuickFillType: tempData.deliveryContactQuickFillType,
    });
  };

  const validateFields = () => {
    if (
      data.deliveryContactFirstName.valid === ValidationStatus.INVALID ||
      data.deliveryContactLastName.valid === ValidationStatus.INVALID ||
      data.deliveryContactPhone.valid === ValidationStatus.INVALID
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handlePhoneNumberBlurValidation = (e: any) => {
    const { name, value } = e.target;
    const isValid = validator.validate(value, name);
    const inputField: IInputField = {
      ...data.deliveryContactPhone,
      errorMessage: isValid?.message,
    };
    setData({ ...data, deliveryContactPhone: inputField });
  };

  return (
    <div className="deliveryContactMain-component">
      {!isReviewOrder && (
        <div className="deliveryContactMain">
          <h2
            className="deliveryContactHeader"
            data-testid="deliveryContactHeaderTest"
            id={data.deliveryContactFirstName.componentId!}
          >
            Delivery Contact
          </h2>
          {!newOrderObj?.editMode ? (
            <>
              <Box
                className={`non-edit-deliveryContact ${
                  validateFields() ? `non-edit-deliveryContact-error` : ``
                } `}
              >
                <Grid
                  className="non-edit-deliveryContact-container"
                  container
                  spacing={1}
                >
                  <Grid
                    className="non-edit-deliveryContact-grid-item"
                    item
                    xs={5}
                  >
                    <div className="delivery-contact-default-mode-div">
                      <Typography
                        className="deliveryContactTitle"
                        data-testid="deliveryContactNameTitle"
                      >
                        Contact Name
                      </Typography>
                      <Typography className="deliveryContactValue">
                        {data.deliveryContactFirstName.valid ===
                          ValidationStatus.VALID &&
                        data.deliveryContactLastName.valid ===
                          ValidationStatus.VALID &&
                        (data.deliveryContactFirstName.value ||
                          data.deliveryContactLastName.value)
                          ? `${makeCapitalEachWordInString(
                              data.deliveryContactFirstName.value
                            )} ${makeCapitalEachWordInString(
                              data.deliveryContactLastName.value
                            )}`
                          : "--"}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid
                    className="non-edit-deliveryContact-grid-item"
                    item
                    xs={5}
                  >
                    <div className="delivery-contact-default-mode-div">
                      <Typography
                        className="deliveryContactTitle"
                        data-testid="deliveryContactPhoneTitle"
                      >
                        Contact Phone Number
                      </Typography>
                      <Typography className="deliveryContactValue">
                        {data.deliveryContactPhone.value !== "" &&
                        data.deliveryContactPhone.valid ===
                          ValidationStatus.VALID
                          ? `${formatNumberBrackets(
                              data.deliveryContactPhone.value
                            )}`
                          : "--"}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid
                    className="non-edit-deliveryContact-grid-item"
                    item
                    xs={12}
                  >
                    <div className="delivery-contact-default-mode-div edit-button">
                      <h4 onClick={handleEditContact} className="editContact">
                        edit contact
                      </h4>
                    </div>
                  </Grid>
                </Grid>
              </Box>
              {isNextButtonClicked && validateFields() && (
                <div className="delivery-contact-warning-div">
                  <span className="warning-icon">
                    <WarningIcon className="warning-icon-image" />
                  </span>
                  <p className="error-with-warning-icon">
                    {ERROR_TO_ADD_CONTACT_DETAIALS}
                  </p>
                </div>
              )}
            </>
          ) : (
            <Box className="edit-deliveryContact">
              <InputWithLabel
                testId="prod-title"
                isRequired={true}
                label="Delivery Contact"
                error={
                  data.deliveryContactQuickFillType?.valid ===
                  ValidationStatus.INVALID
                }
                isDropdown={true}
              >
                <CustomDropDown
                  handleChange={validateAndSetData}
                  menuItem={deliveryContactQuickFillText}
                  name="deliveryContactQuickFillType"
                  selectpropsClassName="prod-info-select1"
                  selectClassName="prod-info-input prod-info1"
                  testId="prod-info-type"
                  value={
                    data.deliveryContactQuickFillType?.value
                      ? getTextFromCode(
                          deliveryContactQuickFill,
                          data.deliveryContactQuickFillType?.value
                        )
                      : null
                  }
                />
              </InputWithLabel>
              {data.deliveryContactQuickFillType?.value === "4" ? (
                <div className="delivery-contact-form">
                  <Grid
                    className="delivery-contact-component-grid"
                    container
                    spacing={2}
                  >
                    <Grid
                      className="delivery-contact-component-grid-item"
                      item
                      xs={isMobileScreen ? 12 : 6}
                    >
                      <InputWithLabel
                        label="First Name"
                        isRequired={true}
                        error={
                          data.deliveryContactFirstName.valid ===
                          ValidationStatus.INVALID
                        }
                      >
                        <InputBase
                          className="delivery-information-input"
                          data-testid="deliveryContactFirstNameTest"
                          name="deliveryContactFirstName"
                          onChange={validateAndSetData}
                          value={data.deliveryContactFirstName.value}
                        />
                      </InputWithLabel>
                    </Grid>
                    <Grid
                      className="delivery-contact-component-grid-item"
                      item
                      xs={isMobileScreen ? 12 : 6}
                    >
                      <InputWithLabel
                        error={
                          data.deliveryContactLastName.valid ===
                          ValidationStatus.INVALID
                        }
                        isRequired={true}
                        label="Last Name"
                      >
                        <InputBase
                          className="delivery-information-input"
                          data-testid="deliveryContactLastNameTest"
                          name="deliveryContactLastName"
                          onChange={validateAndSetData}
                          value={data.deliveryContactLastName.value}
                        />
                      </InputWithLabel>
                    </Grid>
                    <Grid
                      className="delivery-contact-component-grid-item"
                      item
                      xs={isMobileScreen ? 12 : 6}
                    >
                      <InputWithLabel
                        error={
                          data.deliveryContactPhone.valid ===
                          ValidationStatus.INVALID
                        }
                        isRequired={true}
                        label="Phone Number"
                        labelClassName={focusClasses.deliveryContactPhone}
                        isShowWarning={true}
                        warningMessage={data.deliveryContactPhone.errorMessage}
                      >
                        <InputMask
                          className="phone"
                          data-testid="deliveryContactPhoneNumberTest"
                          mask="(999) 999-9999"
                          name="deliveryContactPhone"
                          onChange={validateAndSetData}
                          onBlur={(e) => {
                            setClasses(e, "");
                            handlePhoneNumberBlurValidation(e);
                          }}
                          onFocus={(e) => setClasses(e, "Mui-focused")}
                          pattern="[0–9]*"
                          placeholder="(___) ___-____"
                          type="tel"
                          value={data.deliveryContactPhone.value}
                        />
                      </InputWithLabel>
                    </Grid>
                  </Grid>
                </div>
              ) : (
                <div className="non-edit-deliveryContact-container-view">
                  <Grid
                    item
                    className="non-edit-deliveryContact-container-view-item"
                  >
                    <Typography className="deliveryContactTitle-view">
                      Contact Name
                    </Typography>
                    <Typography className="deliveryContactValue-view">
                      {data.deliveryContactFirstName.valid ===
                        ValidationStatus.VALID &&
                      data.deliveryContactLastName.valid ===
                        ValidationStatus.VALID &&
                      (data.deliveryContactFirstName.value ||
                        data.deliveryContactLastName.value)
                        ? `${makeCapitalEachWordInString(
                            data.deliveryContactFirstName.value
                          )} ${makeCapitalEachWordInString(
                            data.deliveryContactLastName.value
                          )}`
                        : "--"}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    className="non-edit-deliveryContact-container-view-item"
                  >
                    <Typography className="deliveryContactTitle-view">
                      Contact Phone Number
                    </Typography>
                    <Typography className="deliveryContactValue-view">
                      {data.deliveryContactPhone.value !== "" &&
                      data.deliveryContactPhone.valid === ValidationStatus.VALID
                        ? `${formatNumberBrackets(
                            data.deliveryContactPhone.value
                          )}`
                        : "--"}
                    </Typography>
                  </Grid>
                </div>
              )}
              <Grid container spacing={1} className="updateCancelContact">
                <Grid item className="cancelContactGrid">
                  <div
                    onClick={handleCancelEditContact}
                    className="cancelContact"
                  >
                    Cancel
                  </div>
                </Grid>
                <Grid item>
                  <ExpressButton
                    parentClass="deliveryContactUpdateButton"
                    children="Update"
                    clickHandler={() => {
                      newOrderObj?.setEditMode(false);
                    }}
                    disabled={
                      data.deliveryContactFirstName.valid !==
                        ValidationStatus.VALID ||
                      data.deliveryContactLastName.valid !==
                        ValidationStatus.VALID ||
                      data.deliveryContactPhone.valid !== ValidationStatus.VALID
                    }
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Box>
          )}
          <Grid
            container
            spacing={2}
            classes={{ root: "delivery-contact-component" }}
          >
            <Grid item xs={isMobileScreen ? 12 : 10}>
              <InputWithLabel
                error={
                  data.deliveryInstructions.valid === ValidationStatus.INVALID
                }
                id={data.deliveryInstructions.componentId!}
                isRequired={false}
                isShowWarning={true}
                label={data.deliveryInstructions.title!}
                labelClassName="deliveryInstructionLabel"
                warningMessage={data.deliveryInstructions.errorMessage}
              >
                <InputBase
                  className="deliveryInstructions-input"
                  data-testid="deliveryinstructionTest"
                  error={
                    data.deliveryInstructions.valid === ValidationStatus.INVALID
                  }
                  id={data.deliveryInstructions.id!}
                  inputProps={{
                    className:
                      data.deliveryInstructions.valid ===
                      ValidationStatus.INVALID
                        ? "showDeliveryInstructionError"
                        : "noDeliveryInstructionError",
                  }}
                  multiline={true}
                  name="deliveryInstructions"
                  onChange={validateAndSetData}
                  rows={2}
                  value={data.deliveryInstructions.value}
                />
              </InputWithLabel>
            </Grid>
          </Grid>
        </div>
      )}
      {isReviewOrder && (
        <DeliveryContactReviewOrder
          data={data}
          editButtonClicked={editButtonClicked}
          isOrderSummary={isOrderSummary}
        />
      )}
    </div>
  );
};
