import { useHistory } from "react-router-dom";
import "./submitProofOfService.css";
import { useContext, useState } from "react";
import { LoadingSpinner } from "../../../core/loader/LoadingSpinner";
import { Grid } from "@mui/material";
import { PatientDetails } from "../../pickUpAndDischargeRequest/pickUpRequest/patientDetails/patientDetails.component";
import { PerButtonStyle, TopDivStyle } from "../../cmsContent/cmsContent.style";
import { ReactComponent as VectorIcon } from "../../../assets/Vector.svg";
import { ProofOfServiceOptions } from "./submitProofOfServiceOptions/proofOfServiceOptions.component";
import {
  ExchangeVacUnitContextType,
  ExchangeVacUnitContext,
} from "../../../context/ExchangeVacUnitContext";
import { Popup } from "../../../core/popup/popup.component";
import { convertStringToDate } from "../../../util/utilityFunctions";

//using pickUpWorkOrder to access work order nu from exchange vac api response. keot it optional as its been used nly for acute and not post acute.
interface ISubmitProofOfService {
  pickUpWorkOrder?: string;
}

export const SubmitProofOfService = ({
  pickUpWorkOrder,
}: ISubmitProofOfService) => {
  const history = useHistory();
  const [openLoaderPopUp, setOpenLoaderPopUp] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const exchangeVacUnitObj = useContext<ExchangeVacUnitContextType | null>(
    ExchangeVacUnitContext
  );
  const patient = exchangeVacUnitObj?.patient;
  const data = exchangeVacUnitObj!.data;
  const options = [
    {
      optionTitle: "Document Upload",
      optionDescription: "Print, sign and upload Proof of Service.",
    },
    {
      optionTitle: "Fax to 3M",
      optionDescription: "Print, sign and upload Proof of Service",
    },
  ];

  //all values accessed from data are field inputs and the one accessed from patient are from pickupOrDischarge api response
  const date = patient && patient.serviceDate;
  const serviceDate =
    patient && patient.isExchangeVacSubmitted
      ? convertStringToDate(date)
      : data.serviceDate.value;
  const newSerialNumber =
    patient && patient.isExchangeVacSubmitted
      ? patient && patient.productSerialNumber
      : data.serialNumber.value
      ? data.serialNumber.value.split("-")[0].trim()
      : "--";
  const workOrderNumber = pickUpWorkOrder
    ? pickUpWorkOrder
    : patient && patient.exchangeWONumber
    ? patient.exchangeWONumber
    : "--";
  const oldSerialNumber =
    patient && patient.isExchangeVacSubmitted
      ? patient && patient.oldSerialNumber
      : patient && patient.productSerialNumber
      ? patient.productSerialNumber
      : "--";

  return (
    <div
      className="submit-proof-of-service-component"
      data-testid="submit-proof-of-service-test"
    >
      {loading && (
        <div className="submit-pod-loader">
          <LoadingSpinner />
        </div>
      )}

      {!loading && (
        <>
          <Grid container className="proofOfService-container-main">
            <Grid item className="pos-heading" data-testid="pos-heading">
              {patient && !patient.isAcuteOrder
                ? "Complete and Submit Proof of Service"
                : "Stored Product Exchange Successful"}
            </Grid>
            <Grid
              item
              className="pos-patient-details"
              data-testid="pos-patient-details"
            >
              <PatientDetails
                isAcuteOrderFlow={patient!.isAcuteOrder ?? false}
                patient={patient!}
              />
            </Grid>
            {patient && !patient.isAcuteOrder ? (
              <>
                <Grid
                  item
                  className="pos-alert-text"
                  data-testid="pos-alert-text"
                >
                  <span className="pos-alert-text-paragraph">
                    Proof of service must be completed with the patient
                    signature and date and returned to 3M prior to or on the
                    same day as product delivery at bedside.
                  </span>
                </Grid>
                <Grid item className="pos-subTitle" data-testid="pos-subTitle">
                  <p className="pos-subtitle-text">
                    How would you like to provide the Proof of Service 3M?
                  </p>
                </Grid>
                <Grid item xs={12}>
                  <ProofOfServiceOptions
                    options={options}
                    setIsLoading={setLoading}
                    setOpenLoaderPopUp={setOpenLoaderPopUp}
                  />
                </Grid>
                <Grid
                  item
                  className="pos-paragragh"
                  data-testid="pos-paragragh"
                >
                  <p className="pos-paragraph-mobile">
                    Please remember that
                    <span className="pos-paragraph-bold">
                      failure to complete the Proof of Service prior to
                      placement
                    </span>{" "}
                    will cause patient to
                    <span className="pos-paragraph-bold">
                      not be covered for therapy by insurance
                    </span>
                    due to non-compliance with payor guidelines, including CMS.
                  </p>
                  <p className="pos-paragraph-response">
                    Authorized agents include: Family Member, Designated Friend,
                    Legal Guardian, Attending Nurse or other
                    Caretaker/Representative of institution providing care
                    (excluding prescribing physician)
                  </p>
                </Grid>
              </>
            ) : (
              <Grid className="acute_success">
                <p
                  className="acute_success_text"
                  data-testId="acute_success_text-testId"
                >
                  {
                    "Thank you for updating your order, the following changes have been recorded under "
                  }
                  <span className="acute_success_text_bold ">
                    {workOrderNumber + "."}
                  </span>
                </p>
                <p
                  className="acute_success_text"
                  data-testId="acute_success_text-testId-2"
                >
                  {"Unit "}
                  <span className="acute_success_text_bold ">
                    {newSerialNumber}
                  </span>
                  {" replaced the broken unit on "}
                  <span className="acute_success_text_bold ">
                    {serviceDate + "."}
                  </span>
                </p>
                <p
                  className="acute_success_text"
                  data-testId="acute_success_text-testId-3"
                >
                  {"A pickup request was generated for the broken unit "}
                  <span className="acute_success_text_bold ">
                    {oldSerialNumber + "."}
                  </span>
                </p>
              </Grid>
            )}
            <Grid item xs={12}>
              <TopDivStyle>
                <PerButtonStyle
                  data-testid="pos-previousButton-test"
                  startIcon={<VectorIcon />}
                  className="pos-backToMyPatients-btn"
                  variant="text"
                  onClick={() => {
                    history.push("/home");
                  }}
                >
                  Back to My Patients
                </PerButtonStyle>
              </TopDivStyle>
            </Grid>
          </Grid>
          <Popup
            openFlag={openLoaderPopUp}
            closeHandler={() => setOpenLoaderPopUp(false)}
            dialogParentClass={"pos-loader-pop-up"}
            data-testid="loader-pop-up"
            hideCloseButton={true}
          >
            <div className="pos-loader">
              <LoadingSpinner />
            </div>
          </Popup>
        </>
      )}
    </div>
  );
};
