import moment from "moment";
import "./patientVACDetail.css";
import {
  SupplyOrderContext,
  SupplyOrderContextType,
} from "../../../context/SupplyOrderContext";
import { useContext, useEffect } from "react";
import { IPatientVACDetail } from "./patientVACDetail.interface";
import { ValidationStatus } from "../../../core/interfaces/input.interface";
import PatientDetailReviewOrder from "./patientVACDetailReviewOrder/patientDetailReviewOrder.component";
import parse from "html-react-parser";
export const PatientVACDetail = ({
  data,
  patient,
  vacProductInfo,
  isReviewOrder,
}: IPatientVACDetail) => {
  const SupplyOrderObj = useContext<SupplyOrderContextType | null>(
    SupplyOrderContext
  );

  useEffect(() => {
    if (SupplyOrderObj && SupplyOrderObj.product) {
      data.typeOfOrder = {
        valid: ValidationStatus.VALID,
        value: "No",
      };
    }
  }, []);

  return (
    <>
      {!isReviewOrder && (
        <div className="patient-vac-details">
          <h3 className="patient-name" data-testid="patient-name">
            {`${patient.lastName}, ${patient.firstName}`}
          </h3>
          <h3 className="patient-dob" data-testid="patient-dob">
            {`DOB: ${moment(patient.dob).format("L")}`}
          </h3>
          <div className="device-details">
            <img
              className="device-image"
              data-testid="device-image"
              src={vacProductInfo.imageLink}
              alt=""
            />
            <h3
              className="device-descriptiopn"
              data-testid="device-descriptiopn"
            >
              {parse(vacProductInfo.brandName || "")}
            </h3>
          </div>
        </div>
      )}
      {isReviewOrder && (
        <PatientDetailReviewOrder
          patient={patient}
          vacProductInfo={vacProductInfo}
        />
      )}
    </>
  );
};
