import { useContext } from "react";
import { Grid } from "@mui/material";
import "./deliveryInformationReview.css";
import { MobileDisplayContext } from "../../../../../context/MobileDisplayContext";
import { InpatientSupplyOrderPageSection } from "../../../inpatientSupplyOrder.enum";
import { ISupplyOrderDeliveryInformation } from "../../../inpatientSupplyOrder.interface";

interface Props {
  data: ISupplyOrderDeliveryInformation;
  editButtonAction?: any;
  isSummaryOrDetail?: boolean;
  isOrderDetails?: boolean;
  isFromStockSupplies?: boolean;
}

export const DeliveryInformationReview = ({
  data,
  editButtonAction,
  isSummaryOrDetail = false,
  isOrderDetails = false,
  isFromStockSupplies = false,
}: Props) => {
  const { isMobileScreen } = useContext(MobileDisplayContext);

  const showTitleAndValue = (
    title: string,
    value: string | null,
    testId: string
  ) => {
    return (
      <div
        className="show-title-and-value-div"
        id="id_show-title-and-value-div"
      >
        <p
          className="show-title-and-value-title"
          data-testid={`${testId}-title`}
          id={`${testId}-title-id`}
        >
          {title}
        </p>
        <p
          className="show-title-and-value-value"
          data-testid={`${testId}-value`}
          id={`${testId}-value-id`}
        >
          {value && value !== "" ? value.trim() : "--"}
        </p>
      </div>
    );
  };

  return (
    <div
      className={`delivery-information-review-component ${
        !isFromStockSupplies && "margin-top"
      }`}
      data-testid="delivery-information-review-component"
      id="id_delivery-information-review-component"
    >
      <div
        className="delivery-information-review-header"
        id="id_delivery-information-review-header"
      >
        <p
          className="delivery-information-review-title"
          data-testid="delivery-information-review-title"
          id="delivery-information-review-title-id"
        >
          Delivery Information
        </p>
        {!isSummaryOrDetail && (
          <button
            className="delivery-information-review-edit-button"
            data-testid="delivery-information-review-edit-button"
            id="delivery-information-review-edit-button-id"
            onClick={() =>
              editButtonAction(
                "inpatient-supply-order-delivery-info-main-section",
                InpatientSupplyOrderPageSection.SECOND_PAGE
              )
            }
          >
            Edit
          </button>
        )}
      </div>
      {!isOrderDetails && (
        <Grid
          className="delivery-information-review-grid-container"
          container
          id="id_delivery-information-review-grid-container"
        >
          <Grid
            className="delivery-information-review-grid-item"
            id="id_delivery-information-review-grid-item"
            item
            xs={isMobileScreen ? 12 : 8}
          >
            {showTitleAndValue(
              "Need by Date",
              data.needByDate.value,
              "need-By-Date"
            )}
          </Grid>
        </Grid>
      )}
      <Grid className="delivery-information-review-grid-container" container>
        <Grid
          className="delivery-information-review-grid-item"
          item
          xs={isMobileScreen ? 12 : 8}
        >
          {showTitleAndValue(
            "Delivery Method",
            data.deliveryMethod.value,
            "delivery-method"
          )}
        </Grid>
      </Grid>
    </div>
  );
};
