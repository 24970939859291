import "./confirmPlacementFooter.css";
import { Box, Toolbar } from "@mui/material";
import { useContext, useState } from "react";
import {
  ConfirmPlacementContext,
  ConfirmPlacementContextType,
} from "../../../context/ConfirmPlacementContext";
import { ConfirmPlacementValidator } from "../confirmPlacement.validator";
import { ExpressButton } from "../../../core/expressButton/expressButton.component";
import { PersistentFooter } from "../../../core/persistentFooter/persistentFooter.Component";
import { CancelPopupForConfirmPlacement } from "../cancelConfirmPlacementPopup/cancelConfirmPlacementPopup.component";
import {
  RolesPermissionContextType,
  RolesPermissionContext,
} from "../../../context/RolesPermissionContext";
import { ValidationStatus } from "../../../core/interfaces/input.interface";

type IConfirmPlacementFooterProps = {
  cancelButtonAction?: any;
  cancelButtonDisabled?: boolean;
  confirmButtonAction: any;
  confirmButtonDisabled?: boolean;
};

export const ConfirmPlacementFooter = ({
  cancelButtonAction,
  cancelButtonDisabled = false,
  confirmButtonAction,
  confirmButtonDisabled = false,
}: IConfirmPlacementFooterProps) => {
  const [openPopup, setOpenPopup] = useState(false);
  const confirmPlacementObj = useContext<ConfirmPlacementContextType | null>(
    ConfirmPlacementContext
  );
  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );

  const checkIsConfirmBtnShouldDisable = (): boolean => {
    if (confirmPlacementObj) {
      const isSerialNumberValid =
        confirmPlacementObj.confirmPlacementData.serialNumber.valid ===
        ValidationStatus.VALID;
      const isPlacementDateValid =
        confirmPlacementObj.confirmPlacementData.placementDate.valid ===
        ValidationStatus.VALID;
      const isPlacementTimeValid =                                                                                          
        confirmPlacementObj.confirmPlacementData.placementTime.valid ===
        ValidationStatus.VALID;
      return (
        !(
          isSerialNumberValid &&
          isPlacementDateValid &&
          isPlacementTimeValid
        ) || confirmButtonDisabled
      );
    }
    return true;
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  const handleCancelClick = () => {
    const confirmPlacement = confirmPlacementObj?.confirmPlacementData!;
    const validator = new ConfirmPlacementValidator();
    const isUserUpdatedForm =
      validator.validateUserEnteredAnyDataOrNot(confirmPlacement);
    if (isUserUpdatedForm) {
      setOpenPopup(true);
    } else {
      cancelButtonAction();
    }
  };

  const handleConfirmClick = () => {
    const confirmPlacement = confirmPlacementObj?.confirmPlacementData!;
    const setConfirmPlacement = confirmPlacementObj?.setConfirmPlacementData!;
    const validator = new ConfirmPlacementValidator();
    const isAllVaid = validator.validateAll(
      confirmPlacement,
      setConfirmPlacement
    );
    if (isAllVaid === ValidationStatus.VALID) {
      confirmButtonAction();
    }
  };

  return (
    <PersistentFooter>
      <Toolbar className="confirm-tool-bar">
        <Box className="confirm-main-container">
          <Box className="confirm-left-container" flexWrap="wrap">
            <Box className="boxOrderStyle-confirm" p={1}>
              <ExpressButton
                clickHandler={handleCancelClick}
                parentClass="firstButton"
                testId="cancel-test"
                variant="text"
              >
                Cancel
              </ExpressButton>
            </Box>
          </Box>
          <Box className="confirm-right-container" flexWrap="wrap">
            <Box className="boxOrderStyle-confirm" p={1}>
              <ExpressButton
                clickHandler={handleConfirmClick}
                parentClass="secondButton"
                testId="confirm-button"
                variant="contained"
                disabled={
                  checkIsConfirmBtnShouldDisable() ||
                  permissionObj?.mappedRolesPermissionData.IsSupportRole
                }
              >
                Confirm Placement
              </ExpressButton>
            </Box>
          </Box>
        </Box>
      </Toolbar>
      {openPopup && (
        <CancelPopupForConfirmPlacement handleClose={handleClosePopup} />
      )}
    </PersistentFooter>
  );
};
