import React from "react";
import {
  ISaleManageUserListTableData,
  ISaleManageUsersListTableProps,
} from "./saleManageUsersListTable.interface";
import Table from "../../../../core/customSortingTable/table.component";
import { makeCapitalEachWordInString } from "../../../../util/utilityFunctions";
import "./saleManageUsersListTable.css";

const SalesManageUserTable = ({
  columns,
  handleSorting,
  openUserDetails,
  sortedData,
}: ISaleManageUsersListTableProps) => {
  return (
    <div
      className="sale-manage-user-table_container"
      data-testid="sale-patient-list"
    >
      <Table
        tableClassName="sale-manageUser_table"
        tableColumns={columns}
        handleSorting={handleSorting}
      >
        {sortedData.length > 0 ? (
          <tbody>
            {sortedData.map((data: ISaleManageUserListTableData) => {
              return (
                <tr key={data.userID}>
                  <td
                    className="sale-select-username-link"
                    onClick={() => openUserDetails(data)}
                  >
                    Edit
                  </td>
                  <td className="sale-table-static-data">
                    {makeCapitalEachWordInString(`${data.lastName}`)}
                  </td>
                  <td className="sale-table-static-data">
                    {makeCapitalEachWordInString(`${data.firstName}`)}
                  </td>
                  <td className="sale-table-static-data">
                    {data.roles.map((role: any) => {
                      return <tr>{role}</tr>;
                    })}
                  </td>
                  <td className="sale-table-static-data">
                    {Array.isArray(data.territories) &&
                      data.territories.map((territory: any) => {
                        return <tr>{territory}</tr>;
                      })}
                  </td>
                </tr>
              );
            })}
          </tbody>
        ) : null}
      </Table>
    </div>
  );
};

export default SalesManageUserTable;
