import { createContext } from "react";
import {
  IDeviceTroubleshootingSupport,
  ISalesRep,
  ITechnicalSupport,
} from "../needHelp/needHelp.interface";

interface IPageContextContext {
  salesRepContacts: ISalesRep | null;
  techRepContacts: ITechnicalSupport | null;
  pssRepContacts: ISalesRep | null;
  deviceSupportNumber: IDeviceTroubleshootingSupport | null;
}

export const PageContext = createContext<IPageContextContext>({
  salesRepContacts: null,
  techRepContacts: null,
  pssRepContacts: null,
  deviceSupportNumber: null,
});
