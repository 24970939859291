import moment from "moment";
import "./pickUpRequest.css";
import { useHistory } from "react-router-dom";
import {
  PickUpRequestContext,
  PickUpRequestContextType,
} from "../../../context/PickUpRequestContext";
import { useContext, useEffect, useState } from "react";
import {
  IPickUpRequest,
  IPickUpRequestProps,
  ISubmitPickupRequest,
} from "./pickUpRequest.interface";
import { PickUpRequestValidator } from "./pickUpRequest.validator";
import { LoadingSpinner } from "../../../core/loader/LoadingSpinner";
import { PickUpDetails } from "./pickUpDetails/pickUpDetails.component";
import { PickUpRequestPageSection } from "./pickUpRequestPageSection.enum";
import { PatientDetails } from "./patientDetails/patientDetails.component";
import { ValidationStatus } from "../../../core/interfaces/input.interface";
import { Navigator } from "../../helpAndSupport/Navigator/navigator.component";
import {
  retrievePatientDetails,
  savePickupRequest,
} from "../../../util/pickUpOrDischargeService";
import { ReasonForDischarge } from "./reasonForDischarge/reasonForDischarge.component";
import { PickUpRequestFooterButtonGroup } from "./pickUpRequestFooterGroup/pickUpRequestFooterGroup.component";
import { DeviceInformation } from "./deviceInformation/deviceInformation.component";
import {
  DischargeRequestContext,
  DischargeRequestContextType,
} from "../../../context/DischargeRequestContext";
import PatientTransferDetails from "./patientTransferDetails/patientTransferDetails.component";
import { Popup } from "../../../core/popup/popup.component";
import { ExpressButton } from "../../../core/expressButton/expressButton.component";
import { AuthContext, AuthContextType } from "../../../context/AuthContext";
import { send3MNote } from "../../../util/3meService";
import { SendNoteFailure } from "../../send3MNote/popUps/failurePopUp/sendNoteFailure.component";
import PickUpRequestConfirm from "./pickupRequestConfirm/pickupRequestConfirm.component";
import {
  RolesPermissionContextType,
  RolesPermissionContext,
} from "../../../context/RolesPermissionContext";
import ErrorPopup from "../../../core/errorPopup/errorPopup.component";
import {
  ERROR_MSG_PICKUP_REQUEST_LOAD,
  ERROR_MSG_SEND3M_NOTE,
} from "../../../util/errorMsg";
import {
  formatPhoneNumberWithoutSpecialChar,
  getSiteUseId,
} from "../../../util/utilityFunctions";
import {
  REGISTERED_FACILITY_OBJ_FROM_AUTH_CONTEXT_FAILURE,
  SITEUSEID_FROM_REGISTERED_FACILITY_OBJ_FAILURE,
} from "../../../util/errorCode";

export const PickUpRequest = ({
  Validator = new PickUpRequestValidator(),
}: IPickUpRequestProps) => {
  const history = useHistory();
  const [validator] = useState<PickUpRequestValidator>(Validator!);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [continueButtonDisbaled, setContinueButtonDisbaled] = useState(true);
  const [submitButtonDisbaled, setSubmitButtonDisbaled] = useState(true);
  const pickUpRequestObj = useContext<PickUpRequestContextType | null>(
    PickUpRequestContext
  );
  const dischargeReqObj = useContext<DischargeRequestContextType | null>(
    DischargeRequestContext
  );
  const [errorMsg, setErrorMsg] = useState<string>("");
  const transferPatientDetail = pickUpRequestObj!.transferPatientDetail;
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const data = pickUpRequestObj!.data;
  const setData = pickUpRequestObj!.setData;
  const patient = pickUpRequestObj!.patient;
  const [openLoaderPopUp, setOpenLoaderPopUp] = useState<boolean>(false);
  const [openSuccessPopUp, setOpenSuccessPopUp] = useState<boolean>(false);
  const [openFailurePopUp, setOpenFailurePopUp] = useState<boolean>(false);
  const [errorCode, setErrorCode] = useState("");
  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );
  const loadPatientProductInfo = async (
    roNumber: string,
    dob: string,
    isAcuteOrder: boolean
  ) => {
    let reqParams = {
      RentalOrderNumber: roNumber,
      DOB: dob ? moment(dob).format("yyyy-MM-DD") : null,
      isAcuteOrder: isAcuteOrder,
    };
    setIsLoading(true);
    try {
      const data = await retrievePatientDetails(reqParams);
      setIsLoading(false);
      if (data.error) {
        setError(true);
        setErrorCode(data?.error?.errorCode || data?.status);
      } else {
        pickUpRequestObj?.setPatient((dt: any) => ({
          ...dt,
          productName: data.item.productName,
          placementDate: data.item.placemetDate,
          productSerialNumber: data.item.productSerialNumber,
          workOrderNumber: data.item.deliveryWorkOrderNumber,
          rentalOrderProductNumber: data.item.rentalOrderProductNumber,
          customerNumber: data.item.customerNumber,
        }));
        if (data.item.placemetDate) {
          const pickUpData: IPickUpRequest = {
            ...pickUpRequestObj!.data,
            placementDate: {
              ...pickUpRequestObj!.data.placementDate,
              value: data.item.placemetDate,
            },
            pickupRequestDate: {
              ...pickUpRequestObj!.data.pickupRequestDate,
              value: data.item.stopBillDate,
            },
            returnMethod: {
              ...pickUpRequestObj!.data.returnMethod,
              value: data.item.returnMethod,
            },
            specialInstructions: {
              ...pickUpRequestObj!.data.specialInstructions,
              value: data.item.specialInstruction,
            },
            injuryCauseBy3MDevice: {
              ...pickUpRequestObj!.data.injuryCauseBy3MDevice,
              value: data.item.medicalInjury,
            },
            describeTheInjury: {
              ...pickUpRequestObj!.data.describeTheInjury,
              value: data.item.describeTheInjuryorProblem,
            },
            problemWith3MDevice: {
              ...pickUpRequestObj!.data.problemWith3MDevice,
              value: data.item.productProblem,
            },
            describeTheProblem: {
              ...pickUpRequestObj!.data.describeTheProblem,
              value: data.item.problemDescription,
            },
          };
          pickUpRequestObj?.setData(pickUpData);
        }
        if (pickUpRequestObj && pickUpRequestObj.data.isComingFromAlert) {
          pickUpRequestObj?.setPickUpRequestPage(
            PickUpRequestPageSection.PICK_UP_REQUEST_CONFIRM_FORM
          );
        } else {
          if (
            data.item.isPickupInitiated &&
            data.item.isPickupInitiated === true
          ) {
            dischargeReqObj?.resetData();
            pickUpRequestObj?.setPickUpRequestPage(
              PickUpRequestPageSection.DISCHARGE_REQUEST_START_FORM
            );
            history.replace("/home/dischargeRequest");
          }
        }
      }
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
      setError(true);
    }
  };

  const continuePickUpRequest = () => {
    pickUpRequestObj?.setPickUpRequestPage(
      PickUpRequestPageSection.PICK_UP_REQUEST_SUBMIT_FORM
    );
    window.scrollTo(0, 0);
  };

  const cancelPickUpRequest = () => {
    pickUpRequestObj?.resetData();
    dischargeReqObj?.resetData();
    history.goBack();
  };

  const submitPickUpRequest = () => {
    transferPatient();
  };

  const previousPickUpRequest = () => {
    pickUpRequestObj?.setPickUpRequestPage(
      PickUpRequestPageSection.PICK_UP_REQUEST_START_FORM
    );
    updateContinueButton();
    window.scrollTo(0, 0);
  };

  const updateContinueButton = () => {
    if (
      pickUpRequestObj!.pickUpRequestPage ===
      PickUpRequestPageSection.PICK_UP_REQUEST_START_FORM
    ) {
      setContinueButtonDisbaled(
        data.reasonForDischarge.valid !== ValidationStatus.VALID
      );
    }
  };

  const pickUpRequestOnClick = (event: any) => {
    event.preventDefault();
    previousPickUpRequest();
  };

  const transferPatient = async () => {
    const roNumber = patient!.roNumber.toString();
    const loggedInUserSiteUseID = authObj?.registeredFaciltyAddress?.siteUseId!;
    const dob = patient!.dob;
    let lastVisitDateString =
      transferPatientDetail.lastVisitDate.value !== ""
        ? moment(transferPatientDetail.lastVisitDate.value).format("YYYY-MM-DD")
        : null;
    let sendNoteRequest = {
      siteUseID: loggedInUserSiteUseID,
      RO: roNumber,
      ContactReason: 1,
      LastVisitDateForPatient: lastVisitDateString,
      NewFacilityName: transferPatientDetail.facilityName.value,
      CaregiverOrPhysicianNowResponsible:
        transferPatientDetail.careGiverName.value,
      FacilityPhoneNumber: formatPhoneNumberWithoutSpecialChar(
        transferPatientDetail.phone.value
      ),
      Comments: transferPatientDetail.comment.value,
      dob: dob,
    };
    callSendNoteAPI(sendNoteRequest);
  };

  const callSendNoteAPI = async (params: any) => {
    setOpenLoaderPopUp(true);
    const sendNoteResult = await send3MNote(params);
    setOpenLoaderPopUp(false);
    if (sendNoteResult && sendNoteResult.succeeded) {
      setOpenSuccessPopUp(true);
    } else {
      setOpenFailurePopUp(true);
      setErrorMsg(ERROR_MSG_SEND3M_NOTE);
      setErrorCode(sendNoteResult?.error?.code || sendNoteResult?.status);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const selectedPatient = pickUpRequestObj!.patient;
    const roNumber = selectedPatient?.roNumber.toString();
    const dob = selectedPatient?.dob;
    const rentalOrderProductNumber = selectedPatient?.rentalOrderProductNumber;
    if (selectedPatient) {
      if (roNumber && roNumber !== "" && !rentalOrderProductNumber) {
        loadPatientProductInfo(roNumber, dob!, selectedPatient?.isAcuteOrder);
      } else {
        setIsLoading(false);
      }
    } else {
      history.push("/home");
    }
  }, []);

  useEffect(() => {
    updateContinueButton();
  }, [data.reasonForDischarge]);

  useEffect(() => {
    if (data.reasonForDischarge.value === "yes") {
      const isValid = validator.validateAll(data, setData);
      setSubmitButtonDisbaled(!isValid);
    } else if (data.reasonForDischarge.value === "no") {
      const isValid = validator.validateAll(
        pickUpRequestObj?.transferPatientDetail,
        pickUpRequestObj?.setTransferPatientDetail!
      );
      setSubmitButtonDisbaled(!isValid);
    }
  }, [data, pickUpRequestObj?.transferPatientDetail]);

  const backButtonAction = () => {
    if (data.reasonForDischarge.value === "no") {
      history.push("/home");
    }
  };

  const callSubmitPickupRequest = async () => {
    setOpenLoaderPopUp(true);
    if (
      authObj &&
      patient &&
      authObj.userProfile &&
      authObj.registeredFaciltyAddress &&
      authObj.registeredFaciltyAddress.siteUseId &&
      authObj.registeredFaciltyAddress.accountNumber
    ) {
      const params: ISubmitPickupRequest = {
        RON: patient?.roNumber,
        ROPN: patient?.rentalOrderProductNumber,
        WON: patient?.workOrderNumber,
        DischargeReason: data.reasonForDischarge.value,
        PlacementDate: moment(data.placementDate.value).format("yyyy-MM-DD"),
        returnToUPS:
          pickUpRequestObj?.data.returnMethod.value ===
          "I will drop off at a UPS store"
            ? true
            : null,
        patientContactUPS:
          pickUpRequestObj?.data.returnMethod.value ===
          "I will contact UPS to pick up"
            ? true
            : null,
        ContactPatientClient:
          pickUpRequestObj?.data.returnMethod.value ===
          "3M to contact patient with return instructions"
            ? true
            : null,
        IsInjuredFromDevice:
          data.injuryCauseBy3MDevice.value === "yes" ? true : false,
        InjuryDescription: data.describeTheInjury.value,
        DeviceProblemDescription: data.describeTheProblem.value,
        DOB: moment(patient?.dob).format("yyyy-MM-DD"),
        SiteUseId: getSiteUseId(authObj),
        PatientTransferComments: transferPatientDetail.comment.value,
        theraphyDischargeDate: moment(Date()).format("yyyy-MM-DD"),
        productCode: patient.productName ?? "",
        productSerialNo: patient.productSerialNumber ?? "",
        storageLocation: "",
        stopBillDate: moment(Date()).format("yyyy-MM-DD"),
        pickupDateTime: moment(Date()).format("yyyy-MM-DD"),
        contactFirstName: authObj.userProfile.firstName,
        contactLastName: authObj.userProfile.lastName,
        contactPhone: authObj.userProfile.phoneNumber,
        patientFirstName: patient.firstName,
        patientLastName: patient.lastName,
        patientLocation: patient.patientLocation ?? "",
        loggedInUserPhone: authObj.userProfile.phoneNumber
          ? authObj.userProfile.phoneNumber
          : authObj.userProfile.mobilePhoneNumber,
        loggedInUserFacility: authObj.registeredFaciltyAddress.accountName,
        loggedInUserFacilityAccNo:
          authObj.registeredFaciltyAddress.accountNumber.toString(),
        loggedInUserFacilityAddress: {
          addressLine1: authObj.registeredFaciltyAddress.address1,
          addressLine2: authObj.registeredFaciltyAddress.address2,
          city: authObj.registeredFaciltyAddress.city,
          state: authObj.registeredFaciltyAddress.state,
          zipCode: authObj.registeredFaciltyAddress.zip.toString(),
        },
        appVersion: process.env.REACT_APP_VERSION ?? "",
      };
      const savePickupRequestResult = await savePickupRequest(params);
      if (savePickupRequestResult && savePickupRequestResult.succeeded) {
        pickUpRequestObj?.setPickUpRequestPage(
          PickUpRequestPageSection.PICK_UP_REQUEST_CONFIRM_FORM
        );
        window.scrollTo(0, 0);
        setOpenLoaderPopUp(false);
      } else {
        setErrorMsg("Work Order for Pickup already exists");
        setOpenLoaderPopUp(false);
        setOpenFailurePopUp(true);
        setErrorCode(
          savePickupRequestResult?.error?.errorCode ||
            savePickupRequestResult?.status
        );
      }
    } else {
      let errorCode = "";
      if (!authObj || (authObj && !authObj.registeredFaciltyAddress)) {
        errorCode = REGISTERED_FACILITY_OBJ_FROM_AUTH_CONTEXT_FAILURE;
      } else if (authObj && !authObj.registeredFaciltyAddress?.siteUseId) {
        errorCode = SITEUSEID_FROM_REGISTERED_FACILITY_OBJ_FAILURE;
      }
      setErrorMsg("Work Order for Pickup already exists");
      setOpenLoaderPopUp(false);
      setOpenFailurePopUp(true);
      setErrorCode(errorCode);
    }
  };

  const closePopup = () => {
    setOpenFailurePopUp(false);
    history.goBack();
  };

  return (
    <div className="pick-up-main-component">
      <div className="pick-up-main-component-container">
        <div className="pickup-request-component">
          {isLoading && (
            <div className="pickup-request-loader">
              <LoadingSpinner />
            </div>
          )}
          {!isLoading && error && (
            <>
              <Navigator
                array={[
                  {
                    route: "/home",
                    pageName: "My Patients",
                  },
                ]}
                className="pickup-request-route-section"
                title="Pickup/Discharge Request"
              />
              <ErrorPopup
                popUpStyles="error-popup-design"
                errorMessage={ERROR_MSG_PICKUP_REQUEST_LOAD}
                errorPopupFlag={error}
                errorCode={errorCode}
                handleBackButton={() => {
                  setError(false);
                }}
                buttontitle="Done"
                showTitle={false}
              />
            </>
          )}
          {!isLoading && !error && (
            <>
              {pickUpRequestObj!.pickUpRequestPage ===
                PickUpRequestPageSection.PICK_UP_REQUEST_START_FORM && (
                <Navigator
                  array={[
                    {
                      route: "/home",
                      pageName: "My Patients",
                    },
                  ]}
                  className="pickup-request-route-section"
                  title="Pickup Request"
                />
              )}
              {pickUpRequestObj!.pickUpRequestPage ===
                PickUpRequestPageSection.PICK_UP_REQUEST_SUBMIT_FORM &&
                data.reasonForDischarge.value === "yes" && (
                  <Navigator
                    array={[
                      {
                        route: "/home",
                        pageName: "My Patients",
                      },
                      {
                        onLinkClick: pickUpRequestOnClick,
                        route: "/",
                        pageName: "Pickup Request",
                      },
                    ]}
                    className="pickup-request-route-section"
                    title="Pickup"
                  />
                )}
              {pickUpRequestObj!.pickUpRequestPage ===
                PickUpRequestPageSection.PICK_UP_REQUEST_SUBMIT_FORM &&
                data.reasonForDischarge.value === "no" && (
                  <Navigator
                    array={[
                      {
                        route: "/home",
                        pageName: "My Patients",
                      },
                      {
                        onLinkClick: pickUpRequestOnClick,
                        route: "/",
                        pageName: "Pickup Request",
                      },
                    ]}
                    className="pickup-request-route-section"
                    title="Transfer Patient"
                  />
                )}
              <div className="short-form">
                {pickUpRequestObj!.pickUpRequestPage !==
                  PickUpRequestPageSection.PICK_UP_REQUEST_CONFIRM_FORM && (
                  <>
                    <h2
                      className="pickup-request-component-header"
                      data-testid="pickup-request-component-header"
                    >
                      Pickup Request
                    </h2>
                    <PatientDetails
                      isAcuteOrderFlow={patient!.isAcuteOrder ?? false}
                      patient={patient!}
                    />
                  </>
                )}
                {pickUpRequestObj!.pickUpRequestPage ===
                  PickUpRequestPageSection.PICK_UP_REQUEST_START_FORM && (
                  <ReasonForDischarge data={data} setData={setData} />
                )}
                {pickUpRequestObj!.pickUpRequestPage ===
                  PickUpRequestPageSection.PICK_UP_REQUEST_SUBMIT_FORM &&
                  data.reasonForDischarge.value === "yes" && (
                    <>
                      <PickUpDetails
                        data={data}
                        setData={setData}
                        patient={patient!}
                      />
                      <DeviceInformation data={data} setData={setData} />
                    </>
                  )}
              </div>
              {pickUpRequestObj!.pickUpRequestPage ===
                PickUpRequestPageSection.PICK_UP_REQUEST_CONFIRM_FORM && (
                <>
                  <PickUpRequestConfirm
                    data={data}
                    setData={setData}
                    patient={patient!}
                  />
                </>
              )}
              {pickUpRequestObj!.pickUpRequestPage ===
                PickUpRequestPageSection.PICK_UP_REQUEST_SUBMIT_FORM &&
                data.reasonForDischarge.value === "no" && (
                  <PatientTransferDetails />
                )}
              <div>
                {pickUpRequestObj!.pickUpRequestPage ===
                  PickUpRequestPageSection.PICK_UP_REQUEST_START_FORM && (
                  <PickUpRequestFooterButtonGroup
                    firstButtonTitle="Cancel"
                    firstButtonAction={cancelPickUpRequest}
                    secondButtonTitle=""
                    secondButtonAction={() => {}}
                    thirdButtonTitle="Continue"
                    thirdButtonAction={continuePickUpRequest}
                    thirdButtonDisabled={continueButtonDisbaled}
                  />
                )}
                {pickUpRequestObj!.pickUpRequestPage ===
                  PickUpRequestPageSection.PICK_UP_REQUEST_SUBMIT_FORM &&
                  data.reasonForDischarge.value === "yes" && (
                    <PickUpRequestFooterButtonGroup
                      firstButtonTitle="Cancel"
                      firstButtonAction={cancelPickUpRequest}
                      secondButtonTitle="Previous"
                      secondButtonAction={previousPickUpRequest}
                      thirdButtonTitle="Submit pickup"
                      thirdButtonAction={callSubmitPickupRequest}
                      thirdButtonDisabled={
                        permissionObj?.mappedRolesPermissionData
                          .IsSupportRole || submitButtonDisbaled
                      }
                    />
                  )}
                {pickUpRequestObj!.pickUpRequestPage ===
                  PickUpRequestPageSection.PICK_UP_REQUEST_SUBMIT_FORM &&
                  data.reasonForDischarge.value === "no" && (
                    <PickUpRequestFooterButtonGroup
                      firstButtonTitle="Cancel"
                      firstButtonAction={cancelPickUpRequest}
                      secondButtonTitle="Previous"
                      secondButtonAction={previousPickUpRequest}
                      thirdButtonTitle="Submit transfer"
                      thirdButtonAction={submitPickUpRequest}
                      thirdButtonDisabled={
                        permissionObj?.mappedRolesPermissionData
                          .IsSupportRole || submitButtonDisbaled
                      }
                    />
                  )}
                {pickUpRequestObj!.pickUpRequestPage ===
                  PickUpRequestPageSection.PICK_UP_REQUEST_CONFIRM_FORM && (
                  <PickUpRequestFooterButtonGroup
                    firstButtonTitle=""
                    firstButtonAction={() => {}}
                    secondButtonTitle="Exit To My Patients "
                    secondButtonAction={() => {
                      pickUpRequestObj?.resetData();
                      dischargeReqObj?.resetData();
                      history.replace("/home");
                    }}
                    thirdButtonTitle="Continue To Discharge Measurements"
                    thirdButtonAction={() => {
                      pickUpRequestObj?.setPickUpRequestPage(
                        PickUpRequestPageSection.DISCHARGE_REQUEST_START_FORM
                      );
                      dischargeReqObj?.resetData();
                      history.push("/home/dischargeRequest");
                      window.scrollTo(0, 0);
                    }}
                    thirdButtonDisabled={false}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <Popup
        openFlag={openSuccessPopUp}
        closeHandler={() => setOpenSuccessPopUp(false)}
        hideCloseButton={true}
      >
        <div className="confirmation-pop-up">
          <h2 className="title-main" data-testid="title">
            Note Confirmation
          </h2>
          <h4 className="title-desc" data-testid="description">
            Thank you for submitting your request or comment. Requests and
            comments are processed Monday through Friday (except for Holidays)
            from 7 a.m. to 7 p.m. CST.
          </h4>
          <ExpressButton
            clickHandler={() => {
              backButtonAction();
            }}
            parentClass="back-btn"
            testId="back-button"
            variant="contained"
          >
            Back to Dashboard
          </ExpressButton>
        </div>
      </Popup>
      <ErrorPopup
        popUpStyles="error-popup-design"
        errorMessage={errorMsg}
        errorPopupFlag={openFailurePopUp}
        errorCode={errorCode}
        phone={false}
        handleBackButton={() => {
          closePopup();
        }}
        buttontitle="Done"
        showTitle={false}
      />

      <Popup
        openFlag={openLoaderPopUp}
        closeHandler={() => setOpenLoaderPopUp(false)}
        dialogParentClass={"send-note-loader-pop-up"}
        data-testid="loader-pop-up"
        hideCloseButton={true}
      >
        <div className="send-note-loader">
          <LoadingSpinner />
        </div>
      </Popup>
    </div>
  );
};
