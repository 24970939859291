import { HubConnection } from "@microsoft/signalr";
import React, { createContext, useState } from "react";
import { MyPatientModalSection } from "../components/myPatients/patientOrdersDetails/patientOrdersDetails.component";
import { IDropZoneDocumentSelect } from "../core/customDropZone/dropZoneDocumentSelect.interface";
import { IMyPatientOrderFilter } from "../components/myPatients/myPatientOrderFilter/myPatientOrderFilter.interface";
import { getDeepClone } from "../util/ObjectFunctions";
import { defaultOrderFilterData } from "../components/myPatients/myPatientOrderFilter/myPatientOrderFilter.model";
import { INoPatients } from "../components/myPatients/noPatients/noPatients.component.interface";
import { defaultNoPatientData } from "../components/myPatients/noPatients/noPatients.model";
import { ISalesAssistance } from "../components/myPatients/salesAssisstanceNeedHelp/salesAssistanceNeedHelp.interface";
import { defaultSalesAssistanceData } from "../components/myPatients/salesAssisstanceNeedHelp/salesAssisstanceNeedHelp.model";

export type MyPatientContextType = {
  reloadMyPatient: boolean | null;
  setReloadMyPatient: React.Dispatch<React.SetStateAction<boolean | null>>;
  openPatientOrderAndDetail: boolean;
  setOpenPatientOrderAndDetail: React.Dispatch<React.SetStateAction<boolean>>;
  myPatientClickModalSection: MyPatientModalSection;
  setMyPatientClickModalSection: React.Dispatch<
    React.SetStateAction<MyPatientModalSection>
  >;
  hubConnection: HubConnection | undefined;
  setHubConnection: React.Dispatch<
    React.SetStateAction<HubConnection | undefined>
  >;
  patientOrderStatus: string | undefined;
  setPatientOrderStatus: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  uploadDocuments: IDropZoneDocumentSelect[];
  setUploadDocuments: React.Dispatch<
    React.SetStateAction<IDropZoneDocumentSelect[]>
  >;
  orderId: string | null | undefined;
  setorderId: React.Dispatch<React.SetStateAction<string | null | undefined>>;
  orderLockedByFullName: string | null | undefined;
  setorderLockedByFullName: React.Dispatch<
    React.SetStateAction<string | null | undefined>
  >;
  isPatientLocked: boolean;
  setIsPatientLocked: React.Dispatch<React.SetStateAction<boolean>>;
  isPatientLockedChecked: boolean;
  setIsPatientLockedChecked: React.Dispatch<React.SetStateAction<boolean>>;
  orderFilterData: IMyPatientOrderFilter;
  setOrderFilterData: React.Dispatch<
    React.SetStateAction<IMyPatientOrderFilter>
  >;
  switchToListView: boolean;
  setSwitchToListView: React.Dispatch<React.SetStateAction<boolean>>;
  facilityAdminData: INoPatients;
  setFacilityAdminData: React.Dispatch<React.SetStateAction<INoPatients>>;
  resetSearchFilterData: () => void;
  isPatientDataLoaded: boolean;
  setIsPatientDataLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  needHelpData: ISalesAssistance;
  setNeedHelpData: React.Dispatch<React.SetStateAction<ISalesAssistance>>;
  salesAssistanceUploadDocuments: IDropZoneDocumentSelect[];
  setSalesAssistanceUploadDocuments: React.Dispatch<
    React.SetStateAction<IDropZoneDocumentSelect[]>
  >;
};

type MyPatientContextProviderProps = {
  children: React.ReactNode;
};

export const MyPatientContext = createContext<MyPatientContextType | null>(
  null
);

export const MyPatientContextProvider = ({
  children,
}: MyPatientContextProviderProps) => {
  const [reloadMyPatient, setReloadMyPatient] = useState<boolean | null>(false);
  const [openPatientOrderAndDetail, setOpenPatientOrderAndDetail] =
    useState<boolean>(false);
  const [myPatientClickModalSection, setMyPatientClickModalSection] =
    useState<MyPatientModalSection>(MyPatientModalSection.LOAD_PATIENT);
  const [hubConnection, setHubConnection] = useState<
    HubConnection | undefined
  >();
  const [patientOrderStatus, setPatientOrderStatus] = useState<
    string | undefined
  >("");
  const [uploadDocuments, setUploadDocuments] = useState<
    IDropZoneDocumentSelect[]
  >([]);
  const [orderId, setorderId] = useState<string | null | undefined>();
  const [orderLockedByFullName, setorderLockedByFullName] = useState<
    string | null | undefined
  >();
  const [isPatientLocked, setIsPatientLocked] = useState(false);
  const [isPatientLockedChecked, setIsPatientLockedChecked] = useState(false);
  const [orderFliter, setOrderFliter] = useState<string[]>([]);
  const [orderFilterData, setOrderFilterData] = useState<IMyPatientOrderFilter>(
    getDeepClone(defaultOrderFilterData)
  );
  const [switchToListView, setSwitchToListView] = useState(true);
  const [facilityAdminData, setFacilityAdminData] = useState<INoPatients>(
    getDeepClone(defaultNoPatientData)
  );
  const [isPatientDataLoaded, setIsPatientDataLoaded] = useState(false);
  const [needHelpData, setNeedHelpData] = useState<ISalesAssistance>(
    defaultSalesAssistanceData
  );
  const [salesAssistanceUploadDocuments, setSalesAssistanceUploadDocuments] =
    useState<IDropZoneDocumentSelect[]>([]);
  const resetSearchFilterData = () => {
    setOrderFilterData(getDeepClone(defaultOrderFilterData));
    setSwitchToListView(true);
  };
  return (
    <MyPatientContext.Provider
      value={{
        reloadMyPatient,
        setReloadMyPatient,
        openPatientOrderAndDetail,
        setOpenPatientOrderAndDetail,
        myPatientClickModalSection,
        setMyPatientClickModalSection,
        hubConnection,
        setHubConnection,
        patientOrderStatus,
        setPatientOrderStatus,
        uploadDocuments,
        setUploadDocuments,
        orderId,
        setorderId,
        orderLockedByFullName,
        setorderLockedByFullName,
        isPatientLocked,
        setIsPatientLocked,
        isPatientLockedChecked,
        setIsPatientLockedChecked,
        orderFilterData,
        setOrderFilterData,
        switchToListView,
        setSwitchToListView,
        facilityAdminData,
        setFacilityAdminData,
        resetSearchFilterData,
        isPatientDataLoaded,
        setIsPatientDataLoaded,
        needHelpData,
        setNeedHelpData,
        salesAssistanceUploadDocuments,
        setSalesAssistanceUploadDocuments,
      }}
    >
      {children}
    </MyPatientContext.Provider>
  );
};
