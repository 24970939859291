import {
  Checkbox,
  FormControlLabel,
  Grid,
  InputBase,
  useMediaQuery,
} from "@mui/material";
import "./passwordAdministration.css";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { IManageProfile } from "../manageProfile.interface";
import { ManageProfileValidator } from "../manageProfile.validator";
import {
  Validation,
  ValidationStatus,
} from "../../../core/interfaces/input.interface";
import { ReactComponent as CheckBoxIcon } from "../../../assets/checkBox.svg";
import { IPasswordAdministrationInterface } from "./passwordAdministration.interface";
import { InputWithLabel } from "../../../core/inputWithLabel/inputWithLabel.component";
import { ReactComponent as SelectedCheckBoxIcon } from "../../../assets/selectedCheckBox.svg";
import { passwordAdministrationConfigForManageProfile } from "../../../constants/passwordAdministrationConfig";
import { isStringNullOrEmpty } from "../../../util/utilityFunctions";
import { UNMATCHED_PASSWORD } from "../../../util/staticText";
import { MobileDisplayContext } from "../../../context/MobileDisplayContext";

export const PasswordAdministration = ({
  data,
  configurationData = passwordAdministrationConfigForManageProfile,
  Validator,
  setData,
}: IPasswordAdministrationInterface) => {
  const [validator] = React.useState<ManageProfileValidator>(Validator!);
  const resetData: IManageProfile = data!;
  const isMobileScreen = useMediaQuery("(max-width:600px)");
  const [isChangePassword, setIsChangePassword] = useState(false);

  const validateAndSetData = (e: any) => {
    let isValid = validator.validate(e.target.value, e.target.name);
    let val = e.target.value;
    if (
      (e.target.name === "newPassword" ||
        e.target.name === "confirmPassword") &&
      val !== "" &&
      data?.firstName.value !== "" &&
      data?.lastName.value !== "" &&
      data?.email.value !== ""
    ) {
      let completeValid = passwordValidation(val);
      let valid =
        isValid?.status === ValidationStatus.VALID && !completeValid
          ? { ...isValid, status: ValidationStatus.VALID }
          : {
              ...isValid,
              status: ValidationStatus.INVALID,
            };

      setData({
        ...data,
        [e.target.name]: {
          value: e.target.value,
          valid: valid?.status,
          required: e.target.required,
          errorMessage: valid?.message,
        },
      });
    } else {
      setData(
        Object.assign({}, data, {
          [e.target.name]: {
            value: e.target.value,
            valid: isValid?.status,
            required: e.target.required,
            errorMessage: isValid?.message,
          },
        })
      );
    }
  };

  const handleChangePasswordCheckbox = () => {
    setIsChangePassword(!isChangePassword);
    if (isChangePassword) {
      setData(resetData);
    }
  };

  const isShowTitle = (): boolean => {
    return configurationData.isTitleRequired!;
  };

  const isShowCurrentPassword = (): boolean => {
    return !isChangePassword && configurationData.isShowPassword;
  };

  const isShowChangePasswordWithCheckbox = (): boolean => {
    return (
      configurationData.isChangePasswordRequired &&
      configurationData.isChangePasswordRequired
    );
  };

  const isShowCurrentPasswordInputField = (): boolean => {
    return configurationData.isCurrentPasswordRequired && isChangePassword;
  };

  const isShowNewPasswordSetUpInputFields = (): boolean => {
    return (
      configurationData.isNewPasswordSetUpRequired &&
      ((configurationData.isChangePasswordRequired && isChangePassword) ||
        !configurationData.isChangePasswordRequired)
    );
  };

  const isShowPasswordRule = (): boolean => {
    return (
      configurationData.isChangePasswordRequired ||
      configurationData.isNewPasswordSetUpRequired
    );
  };

  const validatePasswords = useCallback(() => {
    const isValid = data?.newPassword?.value === data?.confirmPassword?.value;
    const isUntouched =
      data?.confirmPassword?.valid === ValidationStatus.UNTOUCHED;
    const newPasswordValid = data?.newPassword.valid === ValidationStatus.VALID;
    if (!isUntouched) {
      const isConfValid = validator.validate(
        data?.confirmPassword.value!,
        "confirmPassword"
      );
      let isPasswordInValid = passwordValidation(data?.confirmPassword.value!);
      if (
        newPasswordValid &&
        isConfValid?.status === ValidationStatus.VALID &&
        !isValid 
      ) {
        setData({
          ...data,
          confirmPassword: {
            value: data?.confirmPassword?.value,
            valid: ValidationStatus.INVALID,
            required: true,
            errorMessage: UNMATCHED_PASSWORD,
          },
        });
      }
      else if (isPasswordInValid){
        setData({
          ...data,
          confirmPassword: {
            value: data?.confirmPassword?.value,
            valid: ValidationStatus.INVALID,
            required: true,
          },
        });
      }
      else {
        setData({
          ...data,
          confirmPassword: {
            value: data?.confirmPassword?.value,
            valid: isConfValid?.status,
            required: true,
            errorMessage: null,
          },
        });
      }
    }
  }, [data?.confirmPassword?.value, data?.newPassword?.value]);

  const passwordValidation = (value : string) => {
    let emailPart1 = false;
    let emailPart2 = false;
    if (data?.email?.value !== "") {
      let emailpart = data?.email.value?.toLowerCase().split("@")!;
      emailPart1 =
        emailpart[0]?.toLowerCase()?.includes(value?.toLowerCase()) ||
        value?.toLowerCase().includes(emailpart[0]?.toLowerCase());
      let domainName = emailpart[1]?.toLowerCase().split(".")[0];
      emailPart2 =
        domainName?.includes(value.toLowerCase()) ||
        value?.toLowerCase().includes(domainName);
    }

    let nameValidation =
      data?.firstName.value.toLowerCase().includes(value.toLowerCase()) ||
      data?.lastName.value.toLowerCase().includes(value.toLowerCase()) ||
      value.toLowerCase().includes(data?.firstName?.value.toLowerCase()!) ||
      value.toLowerCase().includes(data?.lastName.value.toLowerCase()!);

    let completeValid = emailPart1 || emailPart2 || nameValidation;
    return completeValid;
  }

  useEffect(() => {
    validatePasswords();
  }, [data?.confirmPassword?.value, data?.newPassword?.value]);

  useEffect(() => {
    if(data?.newPassword?.value !== ""){
      let isPasswordInValid = passwordValidation(data?.newPassword?.value!);
      setData({
        ...data,
        newPassword: {
          value: data?.newPassword?.value,
          valid: isPasswordInValid === true ? ValidationStatus.INVALID : ValidationStatus.VALID,
          required: true,
        },
      });
      }
  }, [data?.firstName?.value, data?.lastName?.value, data?.email?.value]);

  return (
    <div
      className="password-administation"
      data-testid="password-administation"
    >
      <form autoComplete="off">
        {isShowTitle() && (
          <h2
            className="manage-profile-password-header-more-margin"
            data-testid="manage-profile-header-password-administration"
          >
            {configurationData.title}
          </h2>
        )}
        <div className="manage-profile-password">
          {isShowCurrentPassword() && (
            <div className="password">
              <h5
                className="manage-profile-password-title"
                data-testid="manage-profile-password-title"
              >
                Password
              </h5>
              <h5
                className="manage-profile-password-value"
                data-testid="manage-profile-password-value"
              >
                {data?.password?.value.split("").map(() => {
                  return "*";
                })}
              </h5>
            </div>
          )}
          <div className="password-input">
            {isShowChangePasswordWithCheckbox() && (
              <div className="change-password">
                <FormControlLabel
                  classes={{
                    root: "checkbox-option-txt",
                  }}
                  data-testid="change-password-header"
                  value="changePassword"
                  label={configurationData.changePasswordTitle!}
                  control={
                    <Checkbox
                      className="left-padding-checkbox"
                      checkedIcon={<SelectedCheckBoxIcon />}
                      data-testid="change-password-checkbox"
                      icon={<CheckBoxIcon />}
                      onChange={handleChangePasswordCheckbox}
                      value={isChangePassword}
                    />
                  }
                />
              </div>
            )}
            {isShowCurrentPasswordInputField() && (
              <div className="current-password-input">
                <Grid
                  container
                  spacing={1}
                  classes={{ root: "password-component" }}
                >
                  <Grid item xs={12}>
                    <InputWithLabel
                      label={configurationData.currentPasswordFieldTitle!}
                      isRequired={true}
                      error={
                        data?.currentPassword?.valid ===
                        ValidationStatus.INVALID
                      }
                      testId="current-password"
                    >
                      <InputBase
                        autoFocus
                        className="manage-profile-password-input"
                        inputProps={{
                          "data-testid": "current-password-value",
                        }}
                        name="currentPassword"
                        onChange={validateAndSetData}
                        value={data?.currentPassword?.value}
                        type="password"
                      />
                    </InputWithLabel>
                  </Grid>
                </Grid>
              </div>
            )}
            {isShowNewPasswordSetUpInputFields() && (
              <div className="new-password-input">
                <Grid
                  container
                  spacing={1}
                  classes={{
                    root:
                      configurationData.isCurrentPasswordRequired &&
                      isChangePassword
                        ? "password-component-profile"
                        : "password-component",
                  }}
                >
                  <Grid item xs={isMobileScreen ? 12 : 6}>
                    <InputWithLabel
                      label={configurationData.newPasswordFieldTitle!}
                      isRequired={data?.newPassword.required}
                      error={
                        data?.newPassword.valid === ValidationStatus.INVALID
                      }
                      testId="new-password"
                      errorMessage={data?.newPassword?.errorMessage}
                    >
                      <InputBase
                        required={data?.newPassword.required}
                        className="manage-profile-password-input"
                        inputProps={{
                          "data-testid": "new-password-value",
                        }}
                        onChange={validateAndSetData}
                        name="newPassword"
                        value={data?.newPassword.value}
                        type="password"
                      />
                    </InputWithLabel>
                  </Grid>
                  <Grid item xs={isMobileScreen ? 12 : 6}>
                    <InputWithLabel
                      label={configurationData.confirmPasswordFieldTitle!}
                      isRequired={data?.confirmPassword.required}
                      error={
                        data?.confirmPassword.valid === ValidationStatus.INVALID
                      }
                      testId="confirm-password"
                      errorMessage={data?.confirmPassword?.errorMessage}
                    >
                      <InputBase
                        required={data?.confirmPassword.required}
                        className="manage-profile-password-input"
                        inputProps={{
                          "data-testid": "confirm-password-value",
                        }}
                        onChange={validateAndSetData}
                        name="confirmPassword"
                        value={data?.confirmPassword.value}
                        type="password"
                      />
                    </InputWithLabel>
                  </Grid>
                </Grid>
              </div>
            )}
            {isShowPasswordRule() && (
              <h5
                className="password-rule-description"
                data-testid="password-rule-description"
              >
                Password must be at least 12 characters and contain an uppercase
                letter, a lowercase letter, a number, and a special character
                +-_.@!#$,;: and does not contain first name, last name, a part
                of email address.
              </h5>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};
