import {
  Button,
  FormControlLabel,
  Grid,
  InputBase,
  Radio,
  RadioGroup,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import {
  ERROR_MSG_ADD_ACUTE_PATIENT,
  ERROR_MSG_SEARCH_PATIENT,
} from "../../../util/errorMsg";
import {
  IInPatientSearchData,
  defaultInPatientSearchData,
} from "./inpatientSearch.model";
import "./choosePatientType.css";
import {
  getAcutePatientSearch,
  getPatientSearch,
} from "../../../util/3meService";
import {
  defaultPatientDataForNameSearch,
  defaultPatientDataForRental,
} from "../addPatient/searchPatient.model";
import { useContext, useState } from "react";
import {
  ISearchPatientByName,
  ISearchPatientByRentalOrder,
} from "../addPatient/searchPatient.interface";
import { Validator } from "../../../util/order.validations";
import { Popup } from "../../../core/popup/popup.component";
import { getDeepClone } from "../../../util/ObjectFunctions";
import { getSiteUseId } from "../../../util/utilityFunctions";
import { IAddPatient } from "../addPatient/addPatient.interface";
import { LoadingSpinner } from "../../../core/loader/LoadingSpinner";
import { SearchByRO } from "../addPatient/searchByRo/searchByRo.component";
import { AuthContext, AuthContextType } from "../../../context/AuthContext";
import { ValidationStatus } from "../../../core/interfaces/input.interface";
import { PageSection } from "../addPatientContainer/addPatientContainer.enum";
import { SearchByName } from "../addPatient/searchByName/searchByName.component";
import { ReactComponent as RadioButtonIcon } from "../../../assets/radioButton.svg";
import { AddPatientContext } from "../addPatientContainer/addPatientContainer.context";
import { InputWithLabel } from "../../../core/inputWithLabel/inputWithLabel.component";
import { ReactComponent as SelectedRadioButtonIcon } from "../../../assets/selectedRadioButton.svg";
import { REGISTERED_FACILITY_OBJ_FROM_AUTH_CONTEXT_FAILURE } from "../../../util/errorCode";

export enum PatientType {
  IN_PATIENT = "inPatinet",
  HOME_CARE = "homeCare",
}

export const ChoosePatientType = ({ redirectHandler }: IAddPatient) => {
  // Loader
  const [showLoader, setShowLoader] = useState(false);
  // Patient Type
  const [active, setActive] = useState<boolean | null>(null);
  const [patientType, setPatientType] = useState<PatientType | null>(null);
  // In-Patient
  const [data, setData] = useState<IInPatientSearchData>(
    getDeepClone(defaultInPatientSearchData)
  );
  // Home Care
  const [patientSearchForRental, setPatientSearchForRental] =
    useState<ISearchPatientByRentalOrder>(
      getDeepClone(defaultPatientDataForRental)
    );
  const [patientSearchForName, setPatientSearchForName] =
    useState<ISearchPatientByName>(
      getDeepClone(defaultPatientDataForNameSearch)
    );
  // Validator
  const { patientSearchValidator } = useContext(AddPatientContext);
  // Context
  const authObj = useContext<AuthContextType | null>(AuthContext);
  // MediaQuery
  const isMobileScreen = useMediaQuery("(max-width: 662px)");

  const validateAndSetDataForRadioButton = (e: any) => {
    const { value } = e.target;
    let selectedPatientType = null;
    if (value === "yes") {
      setActive(true);
      selectedPatientType = PatientType.IN_PATIENT;
    } else if (value === "no") {
      setActive(false);
      selectedPatientType = PatientType.HOME_CARE;
    } else {
      setActive(null);
    }
    setPatientType(selectedPatientType);
  };

  const validateAndSetData = (e: any) => {
    const { name, value } = e.target;
    let tempData = data;
    const defaultData = {
      valid: ValidationStatus.UNTOUCHED,
      value: "",
      required: false,
    };
    if (name === "roNumber") {
      if (tempData.firstName.valid !== ValidationStatus.UNTOUCHED) {
        tempData.firstName = defaultData;
      }
      if (tempData.lastName.valid !== ValidationStatus.UNTOUCHED) {
        tempData.lastName = defaultData;
      }
    } else {
      if (tempData.roNumber.valid !== ValidationStatus.UNTOUCHED) {
        tempData.roNumber = defaultData;
      }
    }
    const isValid = new Validator().validate(value, name);
    tempData = {
      ...tempData,
      [name]: {
        value: value,
        valid: isValid!.status,
      },
    };
    setData(tempData);
  };

  const checkSearchBtnDisablity = (): boolean => {
    if (patientType) {
      switch (patientType) {
        case PatientType.IN_PATIENT: {
          if (data.roNumber.valid !== ValidationStatus.UNTOUCHED) {
            return data.roNumber.valid === ValidationStatus.INVALID;
          } else {
            return (
              data.firstName.valid !== ValidationStatus.VALID ||
              data.lastName.valid !== ValidationStatus.VALID
            );
          }
        }
        case PatientType.HOME_CARE: {
          if (patientSearchForName.search.value === "true") {
            return (
              patientSearchValidator.validateAllSearchFields(
                patientSearchForName
              ).status !== ValidationStatus.VALID
            );
          } else if (patientSearchForRental.search.value === "true") {
            return (
              patientSearchValidator.validateAllSearchFields(
                patientSearchForRental
              ).status !== ValidationStatus.VALID
            );
          }
          return true;
        }
      }
    }
    return patientType === null;
  };

  // API Calls
  const fetchPatientSearchResult = async () => {
    setShowLoader(true);
    const reqParams = {
      ron:
        patientSearchForRental.search.value === "true"
          ? patientSearchForRental.ro.value
          : "",
      dob:
        patientSearchForRental.search.value === "true"
          ? moment(patientSearchForRental.dob.value).format("DD-MMM-yyyy")
          : moment(patientSearchForName.dob1.value).format("DD-MMM-yyyy"),
      lastName:
        patientSearchForName.search.value === "true"
          ? patientSearchForName.lastName.value?.trim()
          : "",
      zipCode:
        patientSearchForName.search.value === "true"
          ? patientSearchForName.zip.value
          : "",
    };
    const response = await getPatientSearch(reqParams);
    setShowLoader(false);
    if (response.succeeded && response.data !== null) {
      const data = response.data;
      const searchedPatientResult = {
        dob: moment(reqParams.dob, "DD-MMM-yyyy").format("MM/DD/yyyy"),
        roNumber: data.ron,
        firstName: data.firstName.toLowerCase(),
        lastName: data.lastName.toLowerCase(),
        facilityName: data.homecareFacilityname,
        siteUseId: data.siteUseId,
        careGiverId: data.careGiverId,
        alerts: [],
        status: data.orderStatus,
        isReadyCare: data.isReadyCare,
        dischargeDate: data.dischargeDate,
        productName: data.productName,
        productSerialNumber: data.productSerialNumber,
        isAcuteOrder: data.isAcuteOrder,
        orderCreationDate: data.orderDate,
        patientId: data.patientId,
        patientLocation: data.patientLocation,
      };
      redirectHandler(PageSection.FOUND, [searchedPatientResult]);
    } else if (response.succeeded && response.data === null) {
      redirectHandler(PageSection.NOT_FOUND);
    } else {
      redirectHandler(
        PageSection.ERROR_FORM,
        null,
        response?.error?.code || response?.status,
        ERROR_MSG_SEARCH_PATIENT
      );
    }
  };

  const fetchAcutePatientSearchResult = async () => {
    const siteUseId = getSiteUseId(authObj);
    if (siteUseId !== "") {
      setShowLoader(true);
      const reqParams = {
        RONumber: data.roNumber.value ?? "",
        FirstName: data.firstName.value?.trim() ?? "",
        LastName: data.lastName.value?.trim() ?? "",
        SiteUseId: siteUseId,
      };
      const response = await getAcutePatientSearch(reqParams);
      setShowLoader(false);
      if (response.succeeded && response.items !== null) {
        const searchedPatients = response.items.map((data: any) => {
          const searchedPatient = {
            dob:
              data.dob && data.dob !== ""
                ? moment(data.dob).format("MM/DD/yyyy")
                : "",
            roNumber: data.roNumber,
            firstName: data.firstName.toLowerCase(),
            lastName: data.lastName.toLowerCase(),
            facilityName: data.facilityName,
            orderCreationDate: data.orderCreationDate,
            siteUseId: data.siteUseID,
            alerts: [],
            status: data.orderStatus,
            isReadyCare: false,
            dischargeDate: data.orderDischargeDate,
            productName: data.productName,
            productSerialNumber: data.productSerialNumber,
            isAcuteOrder: true,
            patientLocation: data.patientLocation,
            patientId: data.patientId,
            type: data.type,
          };
          return searchedPatient;
        });
        redirectHandler(PageSection.FOUND, searchedPatients);
      } else if (response.succeeded && response.items === null) {
        redirectHandler(PageSection.NOT_FOUND);
      } else {
        redirectHandler(
          PageSection.ERROR_FORM,
          null,
          response?.error?.errorCode || response?.status,
          ERROR_MSG_ADD_ACUTE_PATIENT,
          true
        );
      }
    } else {
      redirectHandler(
        PageSection.ERROR_FORM,
        null,
        "902",
        REGISTERED_FACILITY_OBJ_FROM_AUTH_CONTEXT_FAILURE,
        true
      );
    }
  };

  // Button Action
  const searchBtnAction = () => {
    if (patientType) {
      switch (patientType) {
        case PatientType.IN_PATIENT:
          fetchAcutePatientSearchResult();
          break;
        case PatientType.HOME_CARE:
          fetchPatientSearchResult();
          break;
      }
    }
  };

  return (
    <>
      <div className="choose-patient-type">
        <p
          className="choose-patient-type-header"
          data-testid="choose-patient-type-header"
        >
          Add Patient
        </p>
        <InputWithLabel
          isRequired={true}
          label="In what care setting is the patient located?"
          labelClassName="choose-patient-type-what-care-setting"
          testId="choose-patient-type-what-care-setting"
        >
          <RadioGroup
            classes={{ root: "radioRoot" }}
            name="choose-patient-type"
            onChange={validateAndSetDataForRadioButton}
            value={
              patientType === PatientType.IN_PATIENT
                ? "yes"
                : patientType === PatientType.HOME_CARE
                ? "no"
                : ""
            }
          >
            <FormControlLabel
              classes={{
                root: active === true ? "optionRoot-active" : "optionRoot",
              }}
              componentsProps={{
                typography: {
                  classes: {
                    root: active === true ? "optiontxtSelect" : "optiontxt",
                  },
                },
              }}
              control={
                <Radio
                  icon={<RadioButtonIcon />}
                  checkedIcon={<SelectedRadioButtonIcon />}
                />
              }
              data-testid="choose-patient-type-is-care-setting-yes"
              label="In-Patient"
              value="yes"
            />
            <FormControlLabel
              classes={{
                root: active === false ? "optionRoot-active" : "optionRoot",
              }}
              componentsProps={{
                typography: {
                  classes: {
                    root: active === false ? "optiontxtSelect" : "optiontxt",
                  },
                },
              }}
              control={
                <Radio
                  icon={<RadioButtonIcon />}
                  checkedIcon={<SelectedRadioButtonIcon />}
                />
              }
              data-testid="choose-patient-type-is-care-setting-no"
              label="Home Care"
              value="no"
            />
          </RadioGroup>
        </InputWithLabel>
        {patientType === PatientType.IN_PATIENT && (
          <div className="serach-view">
            <p className="serach-view-header" data-testid="serach-view-header">
              To add an in-patient order to your list, search with either of the
              criteria sets below
            </p>
            <Grid className="serach-view-grid-container" container>
              <Grid
                className={`serach-view-grid-item ${
                  isMobileScreen ? "" : "left"
                }`}
                item
                xs={isMobileScreen ? 12 : 6}
              >
                <InputWithLabel
                  error={data.firstName.valid === ValidationStatus.INVALID}
                  isRequired={false}
                  label="First Name"
                  testId="first-name"
                >
                  <InputBase
                    className="serach-view-input"
                    inputProps={{ "data-testid": "first-name-value" }}
                    name="firstName"
                    onChange={validateAndSetData}
                    value={data.firstName.value}
                  />
                </InputWithLabel>
              </Grid>
              <Grid
                className={`serach-view-grid-item ${
                  isMobileScreen ? "top" : "right"
                }`}
                item
                xs={isMobileScreen ? 12 : 6}
              >
                <InputWithLabel
                  error={data.lastName.valid === ValidationStatus.INVALID}
                  isRequired={false}
                  label="Last Name"
                  testId="last-name"
                >
                  <InputBase
                    className="serach-view-input"
                    inputProps={{ "data-testid": "last-name-value" }}
                    name="lastName"
                    onChange={validateAndSetData}
                    value={data.lastName.value}
                  />
                </InputWithLabel>
              </Grid>
              <Grid className="serach-view-grid-item" item xs={12}>
                <p className="serach-view-or">or</p>
              </Grid>
              <Grid className="serach-view-grid-item" item xs={12}>
                <InputWithLabel
                  error={data.roNumber.valid === ValidationStatus.INVALID}
                  isRequired={false}
                  label="Rental Order Number"
                  testId="ro-number"
                >
                  <InputBase
                    className="serach-view-input"
                    inputProps={{ "data-testid": "ro-number-value" }}
                    name="roNumber"
                    onChange={validateAndSetData}
                    value={data.roNumber.value}
                  />
                </InputWithLabel>
              </Grid>
            </Grid>
          </div>
        )}
        {patientType === PatientType.HOME_CARE && (
          <div className="serach-view">
            <p className="serach-view-header" data-testid="serach-view-header">
              To add a home care order to your list, search with either of the
              criteria sets below
            </p>
            <div className="home-care-order-search">
              <SearchByRO
                patientSearchDataForName={patientSearchForName}
                patientSearchDataForRo={patientSearchForRental}
                setPatientSearchDataForName={setPatientSearchForName}
                setPatientSearchDataForRo={setPatientSearchForRental}
              />
              <div className="serach-view-or">or</div>
              <SearchByName
                patientSearchDataForName={patientSearchForName}
                patientSearchDataForRo={patientSearchForRental}
                setPatientSearchDataForName={setPatientSearchForName}
                setPatientSearchDataForRo={setPatientSearchForRental}
              />
            </div>
          </div>
        )}
        <Button
          classes={{ root: "search-button" }}
          data-testid="search-button"
          disabled={checkSearchBtnDisablity()}
          onClick={searchBtnAction}
          variant="contained"
        >
          Search
        </Button>
      </div>
      <Popup
        closeHandler={() => setShowLoader(false)}
        dialogParentClass="search-view-loader-pop-up"
        hideCloseButton={true}
        openFlag={showLoader}
      >
        <div className="search-view-loader-pop-up-div">
          <LoadingSpinner />
        </div>
      </Popup>
    </>
  );
};
