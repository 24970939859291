import {
  ISubmitAcuteOrderRequest,
  ISuppliesRequest,
  IWoundDetail,
} from "./inpatientOrder.request.interface";
import {
  IInpatientWoundInformation,
  IWoundInformation,
} from "../woundInformation/woundInformation.interface";
import {
  IInpatientOrder,
  IInpatientOrderSecondPage,
  IReSubmitAcuteOrderRequest,
} from "../../inpatientOrder.interface";
import { AuthContextType } from "../../../../context/AuthContext";
import {
  formatISODate,
  formatPhoneNumberWithoutSpecialChar,
  getSiteUseId,
} from "../../../../util/utilityFunctions";
import { IBillingAddress } from "../billingAddress/billingAddress.interface";
import { IPatientInformation } from "../patientInformation/patientInformation.interface";
import { IPrescriberInformation } from "../prescriberInformation/prescriberInformation.interface";
import { IReplacementInformationInterface } from "../replacementInformation/replacementInformation.interface";
import { IBillingInformation } from "../billingInformation/billingInformation.interface";
import { PlacementTypeSelection } from "../devicePlacement/devicePlacemet.enums";
import { InpatientOrderSubmission } from "../../inpatientOrder.enum";
import { IDeliveryPreference } from "../deliveryPreference/delivveryPreference.interface";
import { CartState } from "../../../inpatientSupplyOrder/component/cart/cart.reducer";

export const mapAcuteOrderRequest = async (
  addresses: IBillingAddress[],
  authObj: AuthContextType,
  data: IInpatientOrder,
  isFromCustomerPlus: boolean,
  orderType: string,
  patientDateOfBirth: string,
  secondPageData: IInpatientOrderSecondPage,
  inPatientOrderID: string,
  cartState: CartState
) => {
  const patientInformation: IPatientInformation = data.patientInformation.value;
  const prescriberInformation: IPrescriberInformation =
    data.prescriberInformation.value;
  const replacementInformation: IReplacementInformationInterface =
    secondPageData.replacementInformation.value;
  const selectedProduct = data.devicePlacement.value.selectedProduct.value;
  const billingInformation: IBillingInformation =
    secondPageData.billingInformation.value;
  const isOrderFromSolventum = orderType === "5";
  const deliveryPreference: IDeliveryPreference =
    secondPageData.deliveryPreference.value;
  const isCallOnEstimatedArrivalTime = isOrderFromSolventum
    ? deliveryPreference.isCallOnEstimatedArrivalTime.value === "yes"
    : replacementInformation.isCallOnEstimatedArrivalTime.value === "yes";
  const isSelectionSuppliesSelected: boolean =
    data.selectionSupplies.value &&
    data.selectionSupplies.value.toLowerCase() === "yes" &&
    cartState.cartItems.length > 0
      ? true
      : false;
  const salesPONumber = billingInformation.salesPONumber.value;
  const today = new Date();
  const departmentName = replacementInformation.department.value;
  let requestBody: ISubmitAcuteOrderRequest = {
    orderID: inPatientOrderID,
    appVersion: process.env.REACT_APP_VERSION ?? "",
    billingAddress: getBillingAddress(
      addresses,
      authObj,
      data.billingAddress.value
    ),
    customerClassName: getCustomerClassName(authObj),
    dateNeedBy: getDateNeedBy(orderType, secondPageData),
    needByTime: secondPageData.deliveryPreference.value?.needByTime.value
      ? secondPageData.deliveryPreference.value?.needByTime.value
      : "",
    deliveryFirstName: isCallOnEstimatedArrivalTime
      ? isOrderFromSolventum
        ? deliveryPreference.firstName.value
        : replacementInformation.firstName.value
      : "",
    deliveryLastName: isCallOnEstimatedArrivalTime
      ? isOrderFromSolventum
        ? deliveryPreference.lastName.value
        : replacementInformation.lastName.value
      : "",
    deliveryPhoneNumber: isCallOnEstimatedArrivalTime
      ? isOrderFromSolventum
        ? formatPhoneNumberWithoutSpecialChar(
            deliveryPreference.phoneNumber.value
          )
        : formatPhoneNumberWithoutSpecialChar(
            replacementInformation.phoneNumber.value
          )
      : "",
    employeeId: authObj.userProfile!.employeeID
      ? authObj.userProfile!.employeeID
      : "INTERNET",
    id: isFromCustomerPlus ? selectedProduct.id.toString() : "",
    isCallRequiredForETA: isCallOnEstimatedArrivalTime ? "true" : "false",
    isFromReadyCare: authObj.registeredFaciltyAddress!.readyCareFlag === "Y",
    inventoryType: getInventoryType(orderType),
    location: !isOrderFromSolventum
      ? replacementInformation.deliveryLocation.value
      : "",
    orderType: orderType,
    patientDOB: patientDateOfBirth,
    patientId: patientInformation.patientId.value,
    patientFirstName: patientInformation.firstName.value,
    patientLastName: patientInformation.lastName.value,
    patientRoomNo: patientInformation.room.value,
    prescriber: getPrescriberData(prescriberInformation),
    productCode:
      data.devicePlacement.value.placementType.value !==
      PlacementTypeSelection.PLACE_ORDER_WITH_SOLVENTUM
        ? selectedProduct.productName
        : data?.orderWithSolventum.value.sku,
    productUsed:
      data.devicePlacement.value.placementType.value ===
      PlacementTypeSelection.PLACE_ORDER_WITH_SOLVENTUM
        ? data.orderWithSolventum.value.productDesc
        : isFromCustomerPlus
        ? selectedProduct.productNameDescription
        : selectedProduct.product,
    purchaseOrderNumber: billingInformation.rentalPONumber.value
      ? billingInformation.rentalPONumber.value
      : "NOT REQUIRED",
    replaceQuantity: isFromCustomerPlus ? 0 : 1,
    replaceStoragePlacement: !isFromCustomerPlus,
    requestorAddress1: authObj.registeredFaciltyAddress!.address1,
    requestorAddress2: authObj.registeredFaciltyAddress!.address2,
    requestorCity: authObj.registeredFaciltyAddress!.city,
    requestorDepartment:
      departmentName !== ""
        ? departmentName
        : authObj.userProfile!.departmentName,
    requestorEmail: authObj.userProfile!.userName,
    requestorFacilityName: authObj.registeredFaciltyAddress!.accountName,
    requestorFirstName: authObj.userProfile!.firstName,
    requestorLastName: authObj.userProfile!.lastName,
    requestorPhone: authObj.userProfile!.phoneNumber
      ? authObj.userProfile!.phoneNumber
      : authObj.userProfile!.mobilePhoneNumber,
    requestorSiteUseId: getSiteUseId(authObj),
    requestorState: authObj.registeredFaciltyAddress!.state,
    requestorTitle: authObj.userProfile!.title,
    requestorUserName: authObj.userProfile!.userName,
    requestorZipCode: authObj.registeredFaciltyAddress!.zip.toString(),
    requestorAccountNumber: authObj?.registeredFaciltyAddress?.accountNumber!,
    roNumber: isFromCustomerPlus ? selectedProduct.roNumber : null,
    serialNumber:
      data.devicePlacement.value.placementType.value !==
      PlacementTypeSelection.PLACE_ORDER_WITH_SOLVENTUM
        ? selectedProduct.serialNumber
        : null,
    serviceCenter: authObj.registeredFaciltyAddress!.customerServiceCode ?? "",
    siteUseId: getSiteUseId(authObj),
    specialInstruction: isOrderFromSolventum
      ? deliveryPreference.deliveryInstructions.value
      : replacementInformation.deliveryInstructions.value,
    supplies: getInPatientSuppliesRequest(
      cartState,
      isSelectionSuppliesSelected
    ),
    therapyStartDate:
      formatISODate(data.therapyStartDate.value, data.therapyStartDate.valid) ??
      today,
    woundDetail: mapWoundDetails(data.patientWoundInformation.value),
    salesPurchaseOrderNo: salesPONumber === "" ? null : salesPONumber,
    isSuppliesOrdered: isSelectionSuppliesSelected,
  };
  return requestBody;
};

export const mapResubmitAcuteOrderRequest = async (
  orderId: string,
  authObj: AuthContextType,
  isSalesRole: boolean,
  isFromCustomerPlus: boolean
) => {
  let requestBody: IReSubmitAcuteOrderRequest = {
    orderId: orderId,
    employeeId: authObj.userProfile!.employeeID
      ? authObj.userProfile!.employeeID
      : "INTERNET",
    requestorSiteUseId: getSiteUseId(authObj),
    requestorFirstName: authObj.userProfile!.firstName,
    requestorLastName: authObj.userProfile!.lastName,
    requestorDepartment: authObj.userProfile!.departmentName,
    requestorTitle: authObj.userProfile!.title,
    requestorPhone: authObj.userProfile!.phoneNumber
      ? authObj.userProfile!.phoneNumber
      : authObj.userProfile!.mobilePhoneNumber,
    requestorAddress1: authObj.registeredFaciltyAddress!.address1,
    requestorAddress2: authObj.registeredFaciltyAddress!.address2,
    requestorCity: authObj.registeredFaciltyAddress!.city,
    requestorState: authObj.registeredFaciltyAddress!.city,
    requestorZipCode: authObj.registeredFaciltyAddress!.zip.toString(),
    requestorFacilityName: authObj.registeredFaciltyAddress!.accountName,
    requestorAccountNumber:
      authObj.registeredFaciltyAddress!.accountNumber!.toString(),
    requestorUserName: authObj.userProfile!.userName,
    customerGroup: getCustomerClassName(authObj),
    serviceCenter: authObj.registeredFaciltyAddress!.customerServiceCode ?? "",
    appVersion: process.env.REACT_APP_VERSION ?? "",
    inventoryType: isFromCustomerPlus
      ? InpatientOrderSubmission.TYPE.STORED_PRODUCT
      : InpatientOrderSubmission.TYPE.VAC_ORDER,
    spReplaceQuantity: isFromCustomerPlus ? 0 : 1,
    replaceStoragePlacement: !isFromCustomerPlus,
  };
  return requestBody;
};

const getInventoryType = (orderType: string) => {
  let inventoryType = "";
  switch (orderType) {
    case "3":
      inventoryType = "1";
      break;
    case "4":
      inventoryType = "3";
      break;
    case "5":
      inventoryType = "2";
      break;
  }
  return inventoryType;
};

const getPrescriberData = (prescriberInformation: IPrescriberInformation) => {
  return {
    npi: prescriberInformation.npi,
    firstName: prescriberInformation.firstName,
    lastName: prescriberInformation.lastName,
    emailAddress: prescriberInformation.email ?? "",
    city: prescriberInformation.city,
    state: prescriberInformation.state,
    zipCode: prescriberInformation.zipCode,
  };
};

const mapWoundDetails = (wounds: IWoundInformation): IWoundDetail[] => {
  switch (wounds.woundInfoCount.value) {
    case "1":
      return [formatWound(wounds.primaryWoundInformation, true)];
    case "2":
      const primaryFormatted = formatWound(
        wounds.primaryWoundInformation,
        true
      );
      const secondaryFormatted = wounds.secondaryWoundInformation
        ? formatWound(wounds.secondaryWoundInformation, false)
        : null;
      return secondaryFormatted
        ? [primaryFormatted, secondaryFormatted]
        : [primaryFormatted];
    default:
      return [];
  }
};

const formatWound = (
  wound: IInpatientWoundInformation,
  isPrimary: boolean
): IWoundDetail => {
  return {
    isPrimary: isPrimary,
    type: wound.woundType.value,
    length: parseFloat(wound.woundLength.value),
    width: parseFloat(wound.woundWidth.value),
    depth: parseFloat(wound.woundDepth.value),
    orientation: wound.woundOrientation.value,
    direction: wound.woundDirection.value,
    location: wound.woundLocation.value,
    measurementDate: wound.woundMeasurementDate.value
      ? formatISODate(
          wound.woundMeasurementDate.value,
          wound.woundMeasurementDate.valid
        )
      : null,
  };
};

const getBillingAddress = (
  addresses: IBillingAddress[],
  authObj: AuthContextType,
  selectedBiilingId: string
) => {
  if (selectedBiilingId && selectedBiilingId !== "") {
    const index = addresses.findIndex(
      (address: IBillingAddress) =>
        address.billToSiteId.toString() === selectedBiilingId.toString()
    );
    if (index > -1) {
      const billingAddress = addresses[index];
      return {
        facilityName: billingAddress.name,
        addressLine1: `${
          billingAddress.addressLine1 ? `${billingAddress.addressLine1}` : ""
        }${
          billingAddress.addressLine2 ? `, ${billingAddress.addressLine2}` : ""
        }`,
        addressLine2: `${
          billingAddress.addressLine3 ? `${billingAddress.addressLine3}` : ""
        }${
          billingAddress.addressLine4 ? `, ${billingAddress.addressLine4}` : ""
        }`,
        city: billingAddress.city,
        state: billingAddress.state,
        zipCode: billingAddress.zip,
        siteUseId: billingAddress.billToSiteId.toString(),
      };
    }
  }
  return { facilityName: authObj.registeredFaciltyAddress!.accountName };
};

export const getCustomerClassName = (authObj: AuthContextType): string => {
  return authObj.registeredFaciltyAddress!.customerClassCode === "006"
    ? "Hospital"
    : "Nursing Home";
};

const getDateNeedBy = (
  orderType: string,
  secondPageData: IInpatientOrderSecondPage
): string | null => {
  switch (orderType) {
    case "3":
      return formatISODate(
        secondPageData.placementDate.value,
        secondPageData.placementDate.valid
      );
    case "5":
      return formatISODate(
        secondPageData.deliveryPreference.value.needByDate.value,
        secondPageData.deliveryPreference.value.needByDate.valid
      );
    default:
      return null;
  }
};

const getInPatientSuppliesRequest = (
  cartData: CartState,
  isSelectionSupplies: boolean
): ISuppliesRequest[] => {
  let mappedProducts: ISuppliesRequest[] = [];
  if (cartData.cartItems.length > 0 && isSelectionSupplies) {
    mappedProducts = cartData.cartItems.map((eachCartItem) => {
      return {
        productCode: eachCartItem.sku,
        qty: eachCartItem.quantity.toString(),
      };
    });
  }
  return mappedProducts;
};
