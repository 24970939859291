import { Key, useContext, useEffect, useState } from "react";
import {
  NewOrderContext,
  NewOrderContextType,
} from "../../../../context/NewOrderContext";
import "./prescriberSearch.css";
import { SearchPrescriberModal } from "../searchPrescriber.enum";
import { INationalRegistry } from "../nationalRegistrySearch/nationalRegistryResult/nationalRegistry.interface";
import { LoadingSpinner } from "../../../../core/loader/LoadingSpinner";
import { prescriberSearch } from "../../../../util/3meService";
import { AuthContext, AuthContextType } from "../../../../context/AuthContext";
import { getNPIPrescriber } from "../../../../util/npiService";
import { Grid } from "@mui/material";
import NationalRegistryResult from "../nationalRegistrySearch/nationalRegistryResult/nationalRegistryResult.component";
import NewOrderErrorPopupMessage from "../../newOrderFooterGroup/newOrderErrorPopupMessage.component";
import { PRESCRIBER_SEARCH_DB_SERVICE_ERROR } from "../../../../util/errorMsg";
import {
  InternalSignOnContext,
  InternalSignOnContextType,
} from "../../../../context/InternalSignOnContext";

interface Props {
  handlePrescriberSearchType: any;
}
const SearchPrescriber = ({ handlePrescriberSearchType }: Props) => {
  const internalObj = useContext<InternalSignOnContextType | null>(
    InternalSignOnContext
  );
  const NewOrderObj = useContext<NewOrderContextType | null>(NewOrderContext);
  const [showSpinner, setShowSpinner] = useState(true);
  const [serverPrescriberList, setServerPrescriberList] = useState<
    INationalRegistry[]
  >([]);
  const [showerrorpopup, setShowerrorpopup] = useState(false);
  const [errorCode, setErrorCode] = useState("");

  useEffect(() => {
    if (internalObj?.isVACOrderSSO) {
      if (NewOrderObj?.newOrderData.requestingFacility?.value?.siteUseId) {
        searchPrescriber();
      } else {
        setShowSpinner(false);
      }
    } else {
      searchPrescriber();
    }
  }, []);
  const AuthObj = useContext<AuthContextType | null>(AuthContext);

  const searchPrescriber = async () => {
    let reqParams = {
      searchinput: null,
      siteUseId: internalObj?.isVACOrderSSO
        ? NewOrderObj?.newOrderData?.requestingFacility?.value?.siteUseId
        : AuthObj?.registeredFaciltyAddress?.siteUseId!,
    };

    const prescribers = await prescriberSearch(reqParams);
    if (prescribers?.data?.length > 0) {
      const updatedList = prescribers.data.map((x: any) => {
        return { ...x, prescriberName: x.firstName + "," + x.lastName };
      });
      setServerPrescriberList(updatedList);
    } else if (!prescribers?.succeeded) {
      setErrorCode(prescribers?.error?.errorCode || prescribers?.status);
      setShowSpinner(false);
      setShowerrorpopup(true);
    }
    setShowSpinner(false);
  };

  const spinner = () => {
    return (
      <div className="nr-spinner">
        <LoadingSpinner />
      </div>
    );
  };

  const handleselectOption = async (x: any) => {
    let reqParams = {
      NPI: x.npi,
    };
    setShowSpinner(true);
    const data = await getNPIPrescriber(reqParams);
    if (data != null && data.succeeded && data.items.length > 0) {
      const defaultEmail =
        x.emails.length > 0
          ? x.emails.find((y: { isDefault: any }) => y.isDefault)
          : "";
      NewOrderObj?.setPrescriberList({
        ...data.items[0],
        email: defaultEmail ? defaultEmail.value : "",
        eScript: x.eScript,
      });
      const emailList =
        x.emails.length > 0
          ? x.emails.map((email: { value: string }, index: Key) => email.value)
          : [];
      NewOrderObj?.setPrescriberEmailList(emailList);
    } else {
      NewOrderObj?.setPrescriberList(x);
    }
    handlePrescriberSearchType(SearchPrescriberModal.PRESCRIPBER_FOUND);
  };
  const closeErrorPopup = () => {
    setShowerrorpopup(false);
  };
  return (
    <>
      {showerrorpopup && (
        <NewOrderErrorPopupMessage
          popUpStyles="prescribersearchErrorPopup"
          handleBackButton={closeErrorPopup}
          errorPopupFlag={true}
          errorMessage={PRESCRIBER_SEARCH_DB_SERVICE_ERROR}
          handleExitButton={closeErrorPopup}
          isSupportPhoneShow={true}
          isShortSentence={true}
          errorCode={errorCode}
        />
      )}
      <div className="prescriber-search">
        <div className="prc-header" data-testid="prescriberSearchTitle">
          Prescriber Search
        </div>
        {showSpinner ? (
          spinner()
        ) : (
          <>
            <Grid xs={12} item>
              <NationalRegistryResult
                isLocalPrescriberSearchList={true}
                handlePrescriberSearchType={handlePrescriberSearchType}
                handleselectOption={handleselectOption}
                nationalRegistryList={serverPrescriberList}
              />
            </Grid>
          </>
        )}
      </div>
    </>
  );
};

export default SearchPrescriber;
