import { DevicePlacement } from "../components/devicePlacement/devicePlacement.component";
import { PatientInformation } from "../components/patientInformation/patientInformation.component";
import { PrescriberInformation } from "../components/prescriberInformation/prescriberInformation.component";
import { TherapyStartDate } from "../components/therapyStartDate/therapyStartDate.component";
import "./inpatientOrderFirstPage.css";
import { IInpatientOrderFirstPage } from "./inpatientOrderFirstPage.interface";
import WoundInformationSection from "../components/woundInformation/woundInformation.component";
import { BillingAddress } from "../components/billingAddress/billingAddress.component";
import ProductSelection from "../components/productSelection/productSelection.component";
import { PlacementTypeSelection } from "../components/devicePlacement/devicePlacemet.enums";
import { InPatientOrderSupply } from "../components/inPatientOrderSupply/inPatientOrderSupply.component";

export const InpatientOrderFirstPage = ({
  addresses,
  allProducts,
  data,
  editButtonAction,
  isAutoFocusEnabled,
  isCustomerPlusProduct,
  setIsCustomerPlusProduct,
  setData,
  woundInfoDropDownValues,
  devicePlacementTableData,
  isFromStartNewOrder,
  devicePlacementScrollableTab,
  devicePlacementSelectedTab,
  setDevicePlacementScrollableTab,
  setDevicePlacementSelectedTab,
  updateSolventumProduct,
  setFilterValues,
  resetCart,
}: IInpatientOrderFirstPage) => {
  return (
    <div className="in-patient-order-first-page-component">
      <div className="short-form">
        <PatientInformation
          data={data}
          patientInformation={data.patientInformation.value}
          setData={setData}
          isAutoFocusEnabled={isAutoFocusEnabled}
        />
        <PrescriberInformation
          data={data}
          prescriberInformation={data.prescriberInformation.value}
          setData={setData}
        />
        <DevicePlacement
          addresses={addresses ?? []}
          allProducts={allProducts}
          data={data}
          editButtonAction={editButtonAction}
          isCustomerPlusProduct={isCustomerPlusProduct}
          setIsCustomerPlusProduct={setIsCustomerPlusProduct}
          setData={setData}
          devicePlacementTableData={devicePlacementTableData}
          isFromStartNewOrder={isFromStartNewOrder}
          devicePlacementScrollableTab={devicePlacementScrollableTab}
          setDevicePlacementScrollableTab={setDevicePlacementScrollableTab}
          devicePlacementSelectedTab={devicePlacementSelectedTab}
          setDevicePlacementSelectedTab={setDevicePlacementSelectedTab}
          updateSolventumProduct={updateSolventumProduct}
        />
        {isCustomerPlusProduct ? (
          <TherapyStartDate
            data={data}
            editButtonAction={editButtonAction}
            setData={setData}
          />
        ) : (
          <>
            {addresses && addresses.length > 1 && (
              <BillingAddress
                addresses={addresses}
                data={data}
                setData={setData}
                updateSolventumProduct={updateSolventumProduct}
              />
            )}
          </>
        )}
        {data.devicePlacement.value.placementType.value ===
          PlacementTypeSelection.PLACE_ORDER_WITH_SOLVENTUM &&
          data.billingAddress.value !== null && (
            <ProductSelection
              data={data}
              setData={setData}
              setFilterValues={setFilterValues}
              resetCart={resetCart}
            />
          )}
        {data.devicePlacement.value.placementType.value ===
          PlacementTypeSelection.PLACE_ORDER_WITH_SOLVENTUM && (
          <InPatientOrderSupply
            data={data}
            setData={setData}
            setFilterValues={setFilterValues}
            resetCart={resetCart}
          />
        )}
        <WoundInformationSection
          data={data}
          editButtonAction={editButtonAction}
          setData={setData}
          woundInformation={data.patientWoundInformation.value}
          woundInfoDropDownValues={woundInfoDropDownValues}
        />
      </div>
    </div>
  );
};
