import parse from "html-react-parser";
import "./patientAndProductDetail.css";
import { Grid, useMediaQuery } from "@mui/material";
import { IPatientAndProductDetail } from "./patientAndProductDetail.interface";
import { formatDateOfBirth } from "../../../../util/utilityFunctions";

export const PatientAndProductDetail = ({
  patient,
  product,
}: IPatientAndProductDetail) => {
  const isMobileScreen = useMediaQuery("(max-width:600px)");

  return (
    <div className="in-patient-order-patient-and-product-detail-component">
      <Grid
        className="in-patient-order-patient-detail-grid-container"
        container
      >
        <Grid className="in-patient-order-patient-detail-grid-item" item xs={6}>
          <div className="in-patient-order-patient-or-product-detail">
            <p
              className="in-patient-order-patient-detail-patient-name"
              data-testid="in-patient-order-patient-detail-patient-name"
              id="in-patient-order-patient-detail-patient-name"
            >{`${patient.firstName} ${patient.lastName}`}</p>
            <p
              className="in-patient-order-patient-detail-patient-date-of-birth"
              data-testid="in-patient-order-patient-detail-patient-date-of-birth"
              id="in-patient-order-patient-detail-patient-date-of-birth"
            >
              {formatDateOfBirth(patient.dateOfBirth)}
            </p>
          </div>
        </Grid>
        <Grid className="in-patient-order-patient-detail-grid-item" item xs={6}>
          <div className="in-patient-order-product-detail">
            {product && product.imageLink !== "" && (
              <img
                alt={product.name}
                className="in-patient-order-product-image"
                data-testid="in-patient-order-product-image"
                id="in-patient-order-product-image"
                src={product.imageLink}
              />
            )}
            <div className="in-patient-order-patient-or-product-detail">
              <p
                className="in-patient-order-product-detail-product-to-place"
                data-testid="in-patient-order-product-detail-product-to-place"
                id="in-patient-order-product-detail-patient-product-to-place"
              >
                {isMobileScreen ? "Product" : "Product to Place"}
              </p>
              <p
                className="in-patient-order-product-detail-product-name-and-serial-number"
                data-testid="in-patient-order-product-detail-product-name-and-serial-number"
                id="in-patient-order-product-detail-product-name-and-serial-number"
              >
                {product
                  ? parse(
                      `${product.name} ${
                        product.serialNumber !== ""
                          ? `- ${product.serialNumber}`
                          : ``
                      }`
                    )
                  : "--"}
              </p>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
