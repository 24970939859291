import React, { useContext } from "react";
import CustomDropZone from "../../../../core/customDropZone/customDropZone.component";
import "./uploadPrescription.css";
import {
  NewOrderContext,
  NewOrderContextType,
} from "../../../../context/NewOrderContext";

type Props = {
  data: any;
  setData: any;
  buttonDropClassName?: string;
  buttonDropText?: string;
  dropdownListingType?: string;
  isRxPrescriptionUploaded?: boolean;
  removeTitleWhenFilesSelected?: boolean;
};

const UploadPrescription = ({
  data,
  setData,
  buttonDropClassName,
  buttonDropText = "Select prescription to upload",
  dropdownListingType = "prescriptionDoc",
  isRxPrescriptionUploaded = false,
  removeTitleWhenFilesSelected = false,
}: Props) => {
  const allowedFiles = {
    "image/png": [".jpeg", ".gif", ".jpg", ".png", ".tiff"],
    "text/pdf": [".pdf"],
  };
  const newOrderObj = useContext<NewOrderContextType | null>(NewOrderContext);

  return (
    <div className="prescriptionDoc">
      {removeTitleWhenFilesSelected && data.length !== 0 ? null : (
        <div className="rxUploadDesp">
          Files must be in one of the following formats: JPG, GIF, JPEG, PNG,
          TIFF or PDF. Each file cannot exceed 15 MB (15360 KB) in size. Only
          one file can be uploaded in the Rx Upload.
        </div>
      )}
      <CustomDropZone
        allowedFiles={allowedFiles}
        setData={setData}
        data={data}
        setDeletedData={newOrderObj!.setDeletedPrescriptionDocuments}
        dragDropText="Drag and drop prescription here to upload"
        buttonDropClassName={buttonDropClassName}
        buttonDropText={buttonDropText}
        dropZoneStyles="dropZoneStyles"
        dragAndDropStyles="dragAndDrop"
        uploadWidgetCard={`uploadWidgetCard${
          !isRxPrescriptionUploaded ? " error" : ""
        }`}
        uploadIconStyle="uploadIconStyle"
        listingType={dropdownListingType}
        maxFileSize={15728640}
        singleFile={true}
        additionalData={newOrderObj?.newOrderDocuments}
        setAdditionalData={newOrderObj?.setNewOrderDocuments}
      />
    </div>
  );
};

export default UploadPrescription;
