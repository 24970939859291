import { ReactNode, useState } from "react";
import { PageSection } from "./searchHomeCareOrderContainer.enum";
import { SearchHomeCareOrder } from "./searchForHomeCareOrder/searchHomeCareOrder.component";
import { ISearchHomeCareOrderContainer } from "./searchHomeCareOrderContainer.interface";
import { ISearchedHomeCareData } from "./searchForHomeCareOrder/searchHomeCareOrder.interface";
import { LoadingSpinner } from "../../core/loader/LoadingSpinner";
import { HomeCareOrderFound } from "./homeCareOrderFound/homeCareOrderFound.component";
import HomeCareOrderNotFound from "./HomeCareOrderNotFound/HomeCareOrderNotFound.component";

export const SearchHomeCareOrderContainer = ({
  currentSection,
  setCurrentSection,
}: ISearchHomeCareOrderContainer) => {
  const [searchOrderData, setSearchOrderData] =
    useState<ISearchedHomeCareData>();

  const redirectHandler = (
    changeSectionTo: PageSection,
    orderSearchData?: ISearchedHomeCareData
  ): void => {
    setCurrentSection(changeSectionTo);
    if (orderSearchData) {
      setSearchOrderData(orderSearchData);
    }
  };

  const sectionToDisplay = () => {
    let page: ReactNode;
    switch (currentSection) {
      case PageSection.SEARCH_HOME_CARE_ORDER_FORM:
        page = <SearchHomeCareOrder redirectHandler={redirectHandler} />;
        break;

      case PageSection.SEARCH_HOME_CARE_ORDER_FOUND:
        page = (
          <HomeCareOrderFound
            homeCareOrderData={searchOrderData!}
            redirectHandler={redirectHandler}
          />
        );
        break;
      case PageSection.SEARCH_HOME_CARE_ORDER_NOT_FOUND:
        page = <HomeCareOrderNotFound redirectHandler={redirectHandler} />;
        break;
      case PageSection.SEARCH_HOME_CARE_ORDER_LOADER:
        page = (
          <div className="searchHomeCareLoader">
            <LoadingSpinner />
          </div>
        );
        break;
    }
    return page;
  };
  return <>{sectionToDisplay()}</>;
};
