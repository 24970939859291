import { useCart } from "../../../context/CartContext";
import { CartState } from "../component/cart/cart.reducer";
import { SalesPoOrderInfoReview } from "../component/salesPurchaseOrder/reviewSalesPurchaseOrder/salesPurchaseOrderReview.component";
import { SelectedSuppliesReview } from "../component/selectedSupplies/reviewSelectedSupplies/selectedSuppliesReview.component";
import { ShippingAddressReview } from "../component/shippingAddress/reviewShippingAddress/shippingAddressReview.component";
import { DeliveryInformationReview } from "../component/supplyOrderDeliveryInformation/reviewDeliveryInformation/deliveryInformationReview.component";
import { PatientInformationReview } from "../firstPage/reviewSupplyOrderPatientInformation/supplyOrderPatienInformationReview.component";
import { IInpatientSupplyOrder } from "../inpatientSupplyOrder.interface";
import "./inpatientSupplyOrderReview.css";

interface IInPatientSupplyOrderReviewPage {
  data: IInpatientSupplyOrder;
  editButtonAction?: Function;
  isSummaryOrDetail?: boolean;
  cartItemsData: CartState;
  isFromStockSupplies?: boolean;
}

export const InPatientSupplyOrderReviewPage = ({
  data,
  editButtonAction,
  isSummaryOrDetail = false,
  isFromStockSupplies = false,
}: IInPatientSupplyOrderReviewPage) => {
  const { cartState } = useCart();
  return (
    <div
      className="in-patient-supply-order-review-page-component"
      data-testid="in-patient-supply-order-review-page-component-testId"
    >
      <div className="short-form">
        <p
          className="in-patient-supply-order-review-page-title"
          data-testid="in-patient-supply-order-review-page-title"
        >
          Review Supply Order
        </p>
        <p
          className="in-patient-supply-order-review-page-description"
          data-testid="in-patient-supply-order-review-page-description"
        >
          Please verify the data provided below is accurate before placing your
          order.
        </p>
        {!isFromStockSupplies && (
          <PatientInformationReview
            data={data.patientAndProduct!}
            isSummaryOrDetail={isSummaryOrDetail}
          />
        )}
        <DeliveryInformationReview
          data={data.deliveryInformation}
          editButtonAction={editButtonAction}
          isSummaryOrDetail={isSummaryOrDetail}
        />
        <SelectedSuppliesReview
          editButtonAction={editButtonAction}
          isSummaryOrDetail={isSummaryOrDetail}
          cartItemsData={cartState}
        />
        <SalesPoOrderInfoReview
          data={data.salesPurchaseOrderInformation}
          editButtonAction={editButtonAction}
          isSummaryOrDetail={isSummaryOrDetail}
        />
        <ShippingAddressReview
          data={data.shippingAddress!}
          editButtonAction={editButtonAction}
          isSummaryOrDetail={isSummaryOrDetail}
        />
      </div>
    </div>
  );
};
