import "./customTab.css";

export const TabSelector = ({
  isActive,
  onClick,
  notificationCount,
  tabType,
  testId,
  title,
  titleClassName,
}: {
  isActive: boolean;
  title: string;
  notificationCount?: any;
  tabType?: any;
  onClick: () => void;
  testId?: string;
  titleClassName?: string;
}) => (
  <button
    data-testid={testId}
    className={
      isActive
        ? `selected-tab-style ${titleClassName ? titleClassName : ""}`
        : `tab-style ${titleClassName ? titleClassName : ""}`
    }
    onClick={onClick}
  >
    {title + " "}
    {notificationCount && (
      <span
        className={`notification-count ${
          tabType === "Document"
            ? "notification-count"
            : tabType === "Wound"
            ? "notification-wounddetail-style"
            : tabType === "Unlinked User"
            ? "notification-unlinked-user-style"
            : ""
        }`}
      >
        <span
          className={`notification-text-style ${
            tabType === "Unlinked User" ? "unlinked-user" : ""
          }`}
        >
          {notificationCount}
        </span>
      </span>
    )}
  </button>
);
