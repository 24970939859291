import { Typography } from "@mui/material";
import { ExpressButton } from "../../../core/expressButton/expressButton.component";
import { ReactComponent as NoPatientIcon } from "../../../assets/noPatientIcon.svg";
import "./noPatient.css";
import { useContext } from "react";
import { AddPatientButton } from "../addPatientButton/addPatientButton.component";
import {
  MyPatientContextType,
  MyPatientContext,
} from "../../../context/MyPatientContext";
import {
  formatPhoneNumber,
  makeCapitalEachWordInString,
} from "../../../util/utilityFunctions";
import {
  RolesPermissionContextType,
  RolesPermissionContext,
} from "../../../context/RolesPermissionContext";
import { LoadingSpinner } from "../../../core/loader/LoadingSpinner";

export const NoPatients = ({ setAddPatientPopUp, loader }: any) => {
  const myPatientObj = useContext<MyPatientContextType | null>(
    MyPatientContext
  );
  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );

  const getPhoneNumberWithExtension = (
    phoneNumberWithExtension: string | undefined | null
  ) => {
    if (
      (phoneNumberWithExtension !== undefined ||
        phoneNumberWithExtension !== null) &&
      phoneNumberWithExtension !== ""
    ) {
      const phoneStringArray = phoneNumberWithExtension!.split(" ext: ");
      let phone = formatPhoneNumber(phoneStringArray[0]);
      if (phoneStringArray.length > 1) {
        const extension = phoneStringArray[1];
        if (extension !== "") {
          phone = `${phone} ext. ${extension}`;
        }
      }
      return phone;
    }
    return "";
  };

  const LoadSpinner = () => {
    return (
      <div className="myPatientListSpinner">
        <LoadingSpinner />
      </div>
    );
  };

  return (
    <>
      {loader ? (
        LoadSpinner()
      ) : (
        <div
          className={
            myPatientObj?.orderFilterData.isOrderFilterCardShown
              ? "no-patient-main-div filter-card-opened"
              : "no-patient-main-div"
          }
        >
          <NoPatientIcon />
          <Typography
            className="no-patient-heading1"
            data-testid="no-patient-heading1"
          >
            Get Started!
          </Typography>
          <Typography
            className="no-patient-heading2"
            data-testid="no-patient-heading2"
          >
            Add a patient order to your My Patients list by providing
            identifying information.
          </Typography>
          <div className="no-patient-add-patient-btn">
            <ExpressButton variant="outlined" parentClass="no-patient-add-btn">
              <AddPatientButton
                isBtnVisible={true}
                onClickHandler={() => {
                  setAddPatientPopUp(true);
                }}
                testId="no-patient-add-btn-test"
              />
            </ExpressButton>
          </div>

          {!permissionObj?.mappedRolesPermissionData.IsFacilityAdminRole && (
            <div className="no-patient-content-div">
              <div className="no-patient-content">
                <Typography
                  className="no-patient-content-1"
                  data-testid="no-patient-content-1"
                >
                  Looking for permission to view all patients?
                </Typography>
                {myPatientObj &&
                myPatientObj.facilityAdminData.facilityAdminEmail === "" ? (
                  <Typography
                    className="no-patient-content-2"
                    data-testid="no-patient-content-2-admin-notAssigned"
                  >
                    Contact your 3M Sales Representative
                  </Typography>
                ) : (
                  <Typography
                    className="no-patient-content-2"
                    data-testid="no-patient-content-2-admin-assigned"
                  >
                    Contact your 3M Express Therapy Portal facility
                    administrator:
                  </Typography>
                )}
              </div>

              <>
                {myPatientObj &&
                  myPatientObj.facilityAdminData &&
                  myPatientObj.facilityAdminData !== null && (
                    <div className="facility-data-div">
                      <Typography
                        className="no-patient-admin-name"
                        data-testid="no-patient-admin-name"
                      >
                        {makeCapitalEachWordInString(
                          myPatientObj?.facilityAdminData
                            ?.facilityAdminFirstName!
                        ) +
                          " " +
                          makeCapitalEachWordInString(
                            myPatientObj?.facilityAdminData
                              ?.facilityAdminLastName!
                          )}
                      </Typography>
                      <a
                        className="no-patient-admin-emailId"
                        data-testid="no-patient-admin-emailId"
                        href={`mailto:${myPatientObj?.facilityAdminData?.facilityAdminEmail}`}
                      >
                        {myPatientObj?.facilityAdminData?.facilityAdminEmail}
                      </a>
                      <a
                        className="no-patient-admin-phone"
                        data-testid="no-patient-admin-phone"
                        href={`tel:${getPhoneNumberWithExtension(
                          myPatientObj?.facilityAdminData?.facilityAdminPhone
                        )}`}
                      >
                        {getPhoneNumberWithExtension(
                          myPatientObj?.facilityAdminData?.facilityAdminPhone
                        )}
                      </a>
                    </div>
                  )}
              </>
            </div>
          )}
        </div>
      )}
    </>
  );
};
