import { useContext } from "react";
import "./patientErrorInSearchAdd.css";
import { IPatientNotFound } from "./patientErrorInSearchAdd.interface";
import { ExpressButton } from "../../../core/expressButton/expressButton.component";
import { AddPatientContext } from "../addPatientContainer/addPatientContainer.context";

export const PatientErrorInSearchAdd = ({
  errorCode,
  errorMessage,
  isShowPhoneNumber,
}: IPatientNotFound) => {
  const { closePopup } = useContext(AddPatientContext);

  return (
    <div className="patient-not-found-component">
      <div className="error-message-div">
        <p className="error-message-text">
          {errorMessage}
          {isShowPhoneNumber && (
            <a
              className="phone-number-with-extension"
              href={`tel:(800) 275-4524 ext. 41858`}
            >
              (800) 275-4524 ext. 41858.
            </a>
          )}
        </p>
        <p className="error-code-info">Error Code : {errorCode}</p>
      </div>
      <ExpressButton
        parentClass="search-again"
        variant="contained"
        clickHandler={(e) => closePopup(e)}
      >
        Done
      </ExpressButton>
    </div>
  );
};
