import { createContext, useState } from "react";
import {
  defaultDressingKit,
  defaultCanister,
} from "../components/newOrder/newOrder.model";
import {
  IDressingKit,
  ICanister,
} from "../components/newOrder/newOrder.interface";
import { getDeepClone } from "../util/ObjectFunctions";
import { IPatient } from "../components/myPatients/patient.interface";
import { IConfirmPlacement } from "../components/confirmPlacement/confirmPlacement.interface";
import { defaultConfirmPlacementData } from "../components/confirmPlacement/confirmPlacement.model";

export type ConfirmPlacementContextType = {
  confirmPlacementData: IConfirmPlacement;
  setConfirmPlacementData: React.Dispatch<
    React.SetStateAction<IConfirmPlacement>
  >;
  patient: IPatient | null;
  setPatient: React.Dispatch<React.SetStateAction<IPatient | null>>;
  resetData: () => void;

  dressingKit: IDressingKit;
  setDressingKit: React.Dispatch<React.SetStateAction<IDressingKit>>;

  canister: ICanister;
  setCanister: React.Dispatch<React.SetStateAction<ICanister>>;
};
type ConfirmPlacementContextProviderProps = {
  children: React.ReactNode;
};
export const ConfirmPlacementContext =
  createContext<ConfirmPlacementContextType | null>(null);
export const ConfirmPlacementContextProvider = ({
  children,
}: ConfirmPlacementContextProviderProps) => {
  const [patient, setPatient] = useState<IPatient | null>(null);
  const [canister, setCanister] = useState<ICanister>(
    getDeepClone(defaultCanister)
  );
  const [dressingKit, setDressingKit] = useState<IDressingKit>(
    getDeepClone(defaultDressingKit)
  );
  const [confirmPlacementData, setConfirmPlacementData] =
    useState<IConfirmPlacement>(getDeepClone(defaultConfirmPlacementData));

  const resetData = () => {
    setPatient(null);
    setCanister(getDeepClone(defaultCanister));
    setDressingKit(getDeepClone(defaultDressingKit));
    setConfirmPlacementData(getDeepClone(defaultConfirmPlacementData));
  };

  return (
    <ConfirmPlacementContext.Provider
      value={{
        resetData,
        patient,
        setPatient,
        dressingKit,
        setDressingKit,
        canister,
        setCanister,
        confirmPlacementData,
        setConfirmPlacementData,
      }}
    >
      {children}
    </ConfirmPlacementContext.Provider>
  );
};
