import SearchIcon from "@mui/icons-material/Search";
import { Grid, TextField, useMediaQuery } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import Moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  OrderDetailContext,
  OrderDetailContextType,
} from "../../../context/OrderDetailsContext";
import { CustomDropDown } from "../../../core/customDropdown/customDropdown.component";
import {
  agGridGetRowStyle,
  getSiteUseId,
  makeCapitalEachWordInString,
} from "../../../util/utilityFunctions";
import { IPatient } from "../../myPatients/patient.interface";
import "./inpatientSupplyOrderListPage.css";
import {
  InpatientSupplyOrderContext,
  InpatientSupplyOrderContextType,
} from "../../../context/InpatientSupplyOrderContext";
import { InpatientSupplyOrderPageSection } from "../inpatientSupplyOrder.enum";
import ErrorPopup, {
  IErrorPopUp,
} from "../../../core/errorPopup/errorPopup.component";
import { ERROR_MSG_ACUTE_ORDER_DETAIL } from "../../../util/errorMsg";
import { AuthContextType, AuthContext } from "../../../context/AuthContext";
import { getAcuteOrderRoDetails } from "../../../util/actueService";
import { getBlankForNullValue } from "../../myPatients/patientAndTherapyDetails/orderOverview/orderOverviewResponseMapper";
import { useCart } from "../../../context/CartContext";
import { defaultFilterValues } from "../container/inpatientSupplyOrderContainer.component";
import { defaultDeliveryInformation } from "../inpatientSupplyOrder.data";

type Props = {
  setFilterValues: Function;
  dropDownList: string[];
  error: IErrorPopUp;
  patientList: IPatient[];
  showLoader: boolean;
  setError: Function;
  setShowLoader: Function;
  setSortedData: Function;
  sortedData: IPatient[];
};

export const InpatientSupplyOrderListPage = ({
  setFilterValues,
  dropDownList,
  error,
  patientList,
  showLoader,
  setError,
  setShowLoader,
  setSortedData,
  sortedData,
}: Props) => {
  const { resetCart } = useCart();

  const gridRef: any = useRef(null);
  const isMobileScreen = useMediaQuery("(max-width:926px)");
  const isExtraSmallMobile = useMediaQuery("(max-width:370px)");
  const isIPadScreen = useMediaQuery(
    "(min-width: 927px) and (max-width: 1200px)"
  );
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const orderOverViewObj = useContext<OrderDetailContextType | null>(
    OrderDetailContext
  );
  const inpatientSupplyOrderObj =
    useContext<InpatientSupplyOrderContextType | null>(
      InpatientSupplyOrderContext
    );
  const [searchInput, setSearchInput] = useState<string>("");
  const [selectedProductType, setSelectedProductType] = useState<string>("");
  const [originalData, setOriginalData] = useState<IPatient[]>(sortedData);

  const history = useHistory();
  const defaultColDef = {
    sortable: true,
    unSortIcon: true,
    suppressMenu: true,
    autoHeight: true,
  };

  const loadPatientProductInfo = async (selectedPatient: IPatient) => {
    const prevRO =
      inpatientSupplyOrderObj?.data.patientAndProduct &&
      inpatientSupplyOrderObj?.data.patientAndProduct.patient.roNumber;
    if (
      prevRO === null ||
      (prevRO && selectedPatient.roNumber.toString() !== prevRO.toString())
    ) {
      resetCart();
      inpatientSupplyOrderObj?.setInPatientSuppliesData(null);
      let reqParams = {
        RentalOrderNumber: selectedPatient?.roNumber,
        UserName: authObj!.userProfile?.userName,
        SiteUseId: getSiteUseId(authObj),
      };
      setShowLoader(true);
      const response = await getAcuteOrderRoDetails(reqParams);
      setShowLoader(false);
      if (!response || response.error) {
        const errorFailure = {
          errorCode:
            response?.error?.code ||
            response?.error?.errorCode ||
            response?.status,
          errorFlag: true,
          errorMessage: ERROR_MSG_ACUTE_ORDER_DETAIL,
        };
        setError(errorFailure);
      } else {
        let tempData = {
          ...inpatientSupplyOrderObj!.data,
          patientAndProduct: {
            patient: {
              firstName: selectedPatient.firstName,
              lastName: selectedPatient.lastName,
              dateOfBirth: selectedPatient.dob,
              roNumber: selectedPatient.roNumber,
              patientLocation: selectedPatient.patientLocation,
            },
            product: {
              imageLink: getBlankForNullValue(response.item.imageLink),
              name: getBlankForNullValue(response.item.brandName),
              serialNumber: response.item.serialNumber,
              productCode: response.item.rentalProduct,
            },
          },
          deliveryInformation: defaultDeliveryInformation,
        };
        inpatientSupplyOrderObj!.setData(tempData!);
        window.scrollTo(0, 0);
        inpatientSupplyOrderObj!.setOrderProgress(40);
        inpatientSupplyOrderObj!.setPage(
          InpatientSupplyOrderPageSection.FIRST_PAGE
        );
        setFilterValues(defaultFilterValues);
      }
    } else {
      window.scrollTo(0, 0);
      inpatientSupplyOrderObj?.setOrderProgress(40);
      inpatientSupplyOrderObj?.setPage(
        InpatientSupplyOrderPageSection.FIRST_PAGE
      );
      setFilterValues(defaultFilterValues);
    }
  };

  const handleSelectLink = (patient: IPatient) => {
    setTimeout(() => {
      loadPatientProductInfo(patient);
    }, 100);
  };

  const closeErrorPopUpButtonAction = () => {
    history.goBack();
  };

  const [columnDefs] = useState<any>([
    {
      headerName: "",
      field: "Select",
      maxWidth: isMobileScreen ? 70 : 100,
      wrapText: true,
      sortable: false,
      pinned: isMobileScreen ? "left" : "none",
      lockPinned: isMobileScreen ? true : false,
      wrapHeaderText: true,
      cellClass: "tableDataCentered",
      suppressMovable: true,
      cellRenderer: function (params: any) {
        return (
          <>
            <span
              className={"inprocess-order-ro tableDataCentered"}
              id={params.data.roNumber}
              onClick={(e) => handleSelectLink(params.data)}
            >
              Select
            </span>
          </>
        );
      },
    },
    {
      headerName: "Name",
      width: isMobileScreen ? 140 : 250,
      pinned: !isMobileScreen ? "none" : "left",
      lockPinned: !isMobileScreen ? false : true,
      wrapText: true,
      wrapHeaderText: true,
      suppressMovable: true,
      cellRenderer: function (params: any) {
        return (
          <>
            {makeCapitalEachWordInString(
              `${params.data.lastName}, ${params.data.firstName}`
            )}
          </>
        );
      },
      valueGetter: (params: any) =>
        `${params.data.lastName}, ${params.data.firstName}`,
      comparator: (valueA: string, valueB: string) => {
        return valueA.localeCompare(valueB);
      },
    },
    {
      field: "dob",
      headerName: "Date of Birth",
      width: isMobileScreen ? 150 : 200,
      wrapHeaderText: true,
      lockPinned: false,
      suppressMovable: true,
      cellRenderer: function (params: any) {
        return (
          <>
            {params.data.dob && params.data.dob !== ""
              ? Moment(params.data.dob).format("MM/DD/YYYY")
              : "--"}
          </>
        );
      },
    },
    {
      field: "productNameDescription",
      headerName: "Product",
      lockPinned: false,
      wrapText: true,
      width: 250,
      suppressMovable: true,
      cellRenderer: function (params: any) {
        return (
          <>
            {params.data.productNameDescription &&
            params.data.productNameDescription !== ""
              ? params.data.productNameDescription
              : "--"}
          </>
        );
      },
    },
    {
      field: "productSerialNumber",
      headerName: "Serial #",
      lockPinned: false,
      width: 150,
      suppressMovable: true,
      cellRenderer: function (params: any) {
        return (
          <>
            {params.data.productSerialNumber &&
            params.data.productSerialNumber !== ""
              ? params.data.productSerialNumber
              : "--"}
          </>
        );
      },
    },
    {
      headerName: "RO #",
      field: "roNumber",
      wrapText: true,
      minWidth: 100,
      flex: 1,
      wrapHeaderText: true,
      cellClass: "tableDataCentered",
      suppressMovable: true,
      cellRenderer: function (params: any) {
        return (
          <>
            <span
              className={"inprocess-order-ro tableDataCentered"}
              id={params.data.roNumber}
              onClick={(e) => handleOnPatientClick(params.data)}
            >
              {params.data.roNumber}
            </span>
          </>
        );
      },
    },
  ]);

  const handleOnPatientClick = (data: IPatient) => {
    orderOverViewObj?.resetSeletedSupplyOrderData();
    orderOverViewObj?.resetWoundData();
    orderOverViewObj?.setSelectedOrderTab("Orders");
    if (data) {
      data.alerts = [];
      history.push({
        pathname: "/home/orderOverview",
        state: {
          stateData: data,
        },
      });
    }
  };

  const handleSearch = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const re = /^[a-zA-Z0-9- ]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setSearchInput(e.target.value);
    }
  };

  const validateAndSetProcuctSearchData = (e: any) => {
    setSelectedProductType(e.target.value);
  };

  const filteredData = (searchInputParam: string, selectedProduct: string) => {
    let filterSupplyPatientData: IPatient[] = patientList;
    if (filterSupplyPatientData && filterSupplyPatientData.length > 0) {
      if (selectedProduct !== "All products" && selectedProduct !== "") {
        filterSupplyPatientData = filterSupplyPatientData.filter(
          (supplyPatientOrder: IPatient) =>
            supplyPatientOrder.productNameDescription &&
            supplyPatientOrder.productNameDescription.includes(selectedProduct)
        );
      }
      if (searchInputParam.length > 0) {
        filterSupplyPatientData = filterSupplyPatientData.filter(
          (patientData: IPatient) =>
            patientData.firstName
              .toLocaleLowerCase()
              .includes(searchInputParam.toLowerCase()) ||
            patientData.lastName
              .toLocaleLowerCase()
              .includes(searchInputParam.toLowerCase()) ||
            patientData.roNumber
              .toString()
              .includes(searchInputParam.toLowerCase())
        );
      }
      setSortedData(filterSupplyPatientData);
    }
  };

  const getTextFieldGridXs = () => {
    return isExtraSmallMobile
      ? 5.9
      : isMobileScreen
      ? 7
      : isIPadScreen
      ? 9
      : 10;
  };

  const getCustomDropDownGridXs = () => {
    return isExtraSmallMobile
      ? 5.8
      : isMobileScreen
      ? 4.8
      : isIPadScreen
      ? 2.8
      : 1.9;
  };

  useEffect(() => {
    if (
      searchInput.trim().length > 0 ||
      selectedProductType !== "All products"
    ) {
      filteredData(searchInput, selectedProductType);
    } else {
      setSortedData(patientList);
    }
  }, [searchInput, selectedProductType]);

  useEffect(() => {
    if (patientList) {
      setOriginalData(sortedData);
    }
  }, [patientList]);

  return (
    <div
      className="in-patient-supply-order-list-page-component"
      id="id_in-patient-supply-order-list"
      data-testid="in-patient-supply-order-list"
    >
      <div className="short-form">
        <h1
          className="in-patient-supply-header"
          id="id_in-patient-supply-header"
        >
          Who are you ordering supplies for?
        </h1>
        <Grid container className="in-patient-supply-searchbar">
          <Grid item xs={getTextFieldGridXs()}>
            <TextField
              autoComplete="off"
              autoFocus={false}
              data-testid="filter-patients"
              className="filter-In-patientso-list"
              inputProps={{ style: { fontSize: 14 } }}
              placeholder="Filter by Name or RO#"
              InputProps={{
                startAdornment: <SearchIcon style={{ marginRight: "5px" }} />,
              }}
              value={searchInput}
              onChange={handleSearch}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={getCustomDropDownGridXs()}>
            <CustomDropDown
              handleChange={validateAndSetProcuctSearchData}
              menuItem={["All products"].concat(dropDownList)}
              name="in-patient-supply-order-product"
              placeHolder="All products"
              paperPropsClassName="inpatientsupply-orderdrop-down-paper-props "
              selectpropsClassName={
                selectedProductType ? "status-select" : "placeHolder"
              }
              selectClassName={
                selectedProductType ? "status-input" : "placeHolder"
              }
              testId="in-patient-supply-order-status"
              value={selectedProductType ? selectedProductType : null}
            />
          </Grid>
        </Grid>
        <div className="ag-grid-div" id="id_ag_grid_div">
          <div
            data-testId="inpatient-supply-order-ag-grid"
            className="ag-theme-quartz"
            style={{
              width: "100%",
              height: isMobileScreen ? 500 : 400,
            }}
          >
            <AgGridReact
              ref={gridRef}
              getRowStyle={agGridGetRowStyle}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              rowData={sortedData}
              suppressRowClickSelection={true}
              suppressDragLeaveHidesColumns={true}
              rowHeight={52}
            />
          </div>
        </div>
      </div>
      <ErrorPopup
        buttontitle={"Done"}
        errorCode={error.errorCode}
        errorPopupFlag={error.errorFlag}
        errorMessage={error.errorMessage}
        isShortSentence={true}
        handleBackButton={() => {
          closeErrorPopUpButtonAction();
        }}
        popUpStyles="errorPopup"
        isSupportPhoneRequired={true}
        showTitle={false}
      />
    </div>
  );
};
