import "./inventoryUpdateLocation.css";
import { Grid, useMediaQuery } from "@mui/material";
import { InputWithLabel } from "../../../../../core/inputWithLabel/inputWithLabel.component";
import { CustomDropDown } from "../../../../../core/customDropdown/customDropdown.component";
import { useContext, useState } from "react";
import {
  IInputField,
  ValidationStatus,
} from "../../../../../core/interfaces/input.interface";
import { ExpressButton } from "../../../../../core/expressButton/expressButton.component";
import { IInventoryProductDetails } from "../../../inventoryTabContainer.enum";
import {
  RolesPermissionContext,
  RolesPermissionContextType,
} from "../../../../../context/RolesPermissionContext";
import { makeCapitalEachWordInString } from "../../../../../util/utilityFunctions";

interface IInventoryUpdateLocationProps {
  redirectHandler: Function;
  updateStoredProductLocation: Function;
  selectedProduct: IInventoryProductDetails | null;
  storageLocations: string[];
  closePopUp: Function;
}
const InventoryUpdateLocation = ({
  updateStoredProductLocation,
  selectedProduct,
  storageLocations,
  closePopUp,
}: IInventoryUpdateLocationProps) => {
  const [data, setData] = useState<IInputField>({
    valid: selectedProduct?.storageLocation
      ? ValidationStatus.VALID
      : ValidationStatus.UNTOUCHED,
    value: selectedProduct?.storageLocation ?? "",
  });
  const isMobileScreen = useMediaQuery("(max-width:374px)");
  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );
  const validateAndSetDropDownData = async (e: any) => {
    setData({
      value: e.target.value,
      valid: ValidationStatus.VALID,
    });
  };

  const checkUpdateBtnDisablity = (): boolean => {
    if (
      data.value === "" ||
      (selectedProduct && selectedProduct.storageLocation === data.value) ||
      permissionObj?.mappedRolesPermissionData.IsSupportRole
    ) {
      return true;
    }
    return false;
  };

  const cancelButtonAction = () => {
    closePopUp();
  };

  return (
    <div className="update-storage-location-component">
      <p
        className="update-storage-location-title"
        data-testid="update-storage-location-title"
      >
        Update Storage Location
      </p>
      {selectedProduct && (
        <Grid className="update-storage-location-grid-container" container>
          <Grid item className="grid-item" xs={6}>
            <div className="product-details" data-testId="product-details">
              <p className="product-title" data-testId="product-title">
                Product
              </p>
              <p className="unit-id" data-testId="unit-id">
                {selectedProduct.unitTagId
                  ? makeCapitalEachWordInString(selectedProduct.unitTagId)
                  : ""}
              </p>
              <p className="product-value" data-testId="product-value">
                {selectedProduct.product}
              </p>
            </div>
          </Grid>
          <Grid item className="grid-item" xs={6}>
            <div className="serialNum-details" data-testId="serialNum-details">
              <p className="serialNum-title" data-testId="serialNum-title">
                Serial Number
              </p>
              <p className="serialNum-value" data-testId="serialNum-value">
                {selectedProduct.serialNumber}
              </p>
            </div>
          </Grid>
          <Grid className="grid-item" item xs={12}>
            <InputWithLabel
              isDropdown={true}
              label="Storage Location"
              labelClassName="storage-location-title"
              testId="update-storage-location-dropdown"
            >
              <CustomDropDown
                handleChange={validateAndSetDropDownData}
                menuItem={storageLocations}
                placeHolder="Select Storage Location"
                name="storageLocation"
                selectpropsClassName="storage-location-select"
                selectClassName="storage-location-input storage-location"
                testId="storage-location-dropdown"
                value={data.value}
              />
            </InputWithLabel>
          </Grid>
          <Grid container className="update-buttons-container">
            <Grid
              item
              xs={isMobileScreen ? 12 : 6}
              className="inventoryUpdate-button-item"
            >
              <ExpressButton
                testId="cancel-button"
                clickHandler={cancelButtonAction}
                variant="outlined"
                parentClass="inventoryCancelButton"
              >
                Cancel
              </ExpressButton>
            </Grid>
            <Grid
              item
              xs={isMobileScreen ? 12 : 6}
              className="inventoryUpdate-button-item"
            >
              <ExpressButton
                testId="next-button"
                variant="contained"
                parentClass="inventoryNextButton"
                disabled={checkUpdateBtnDisablity()}
                clickHandler={() =>
                  updateStoredProductLocation({
                    ...selectedProduct,
                    storageLocation: data.value,
                  })
                }
              >
                Update Location
              </ExpressButton>
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default InventoryUpdateLocation;
