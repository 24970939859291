import { ValidationStatus } from "../../../../../../core/interfaces/input.interface";
import { IManualAddTerritory } from "./manualAddTerritory.interface";

export let mockManualAddTerritory: IManualAddTerritory = {
  startDate: {
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: true,
  },
  endDate: {
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: true,
  },
  reasonForAssignmentChange: {
    value: "",
    valid: ValidationStatus.UNTOUCHED,
    required: true,
  },
};
