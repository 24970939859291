import React, { useContext, useState } from "react";
import { Navigator } from "../../helpAndSupport/Navigator/navigator.component";
import ViewTerritories from "./viewTerritories/viewTerritories.component";
import "./viewTerritories/viewTerritories.css";
import { Popup } from "../../../core/popup/popup.component";
import { LoadingSpinner } from "../../../core/loader/LoadingSpinner";
import { InternalSignOnContextType, InternalSignOnContext } from "../../../context/InternalSignOnContext";
interface Props {}

export const ManageTerritoriesContainer = (props: Props) => {
  const [openLoaderPopUp, setOpenLoaderPopUp] = useState<boolean>(false);
  const internalObj = useContext<InternalSignOnContextType | null>(
    InternalSignOnContext
  );

  return (
    <>
      <div className="manage-territory-component-container">
        <div
          className="manage-territory-component"
          data-testid="manage-territory-component"
        >
          <Navigator
            array={[
              {
                route: internalObj?.isInternalAdministration ? "/internalAdministration" : "/administration",
                pageName: "Administration",
              },
            ]}
            className="manage-territory-component-route-section"
            title="View Territories"
          />
          <ViewTerritories setLoaderFlag={setOpenLoaderPopUp} />
        </div>
      </div>
      <Popup
        openFlag={openLoaderPopUp}
        closeHandler={() => setOpenLoaderPopUp(false)}
        dialogParentClass={"territory-fetch-users-loader-pop-up"}
        data-testid="territory-fetch-users-pop-up"
        hideCloseButton={true}
      >
        <div className="territory-fetch-users-loader">
          <LoadingSpinner />
        </div>
      </Popup>
    </>
  );
};
