import "./inpatientOrderFooter.css";
import { Toolbar } from "@mui/material";
import { ExpressButton } from "../../../../core/expressButton/expressButton.component";
import { PersistentFooter } from "../../../../core/persistentFooter/persistentFooter.Component";

export interface IInpatientOrderFooterProps {
  firstButtonAction: Function;
  firstButtonDisabled?: boolean;
  firstButtonTitle: string;

  secondButtonAction: Function;
  secondButtonDisabled?: boolean;
  secondButtonTitle: string;

  thirdButtonAction: Function;
  thirdButtonDisabled?: boolean;
  thirdButtonTitle: string;
}

export const InpatientOrderFooter = ({
  firstButtonAction,
  firstButtonDisabled = false,
  firstButtonTitle,
  secondButtonAction,
  secondButtonDisabled = false,
  secondButtonTitle,
  thirdButtonAction,
  thirdButtonDisabled = false,
  thirdButtonTitle,
}: IInpatientOrderFooterProps) => {
  return (
    <PersistentFooter>
      <Toolbar className="in-patient-order-tool-bar">
        <div className="in-patient-order-main-container">
          <div className="in-patient-order-left-container">
            {firstButtonTitle !== "" && (
              <ExpressButton
                clickHandler={() => firstButtonAction()}
                disabled={firstButtonDisabled}
                parentClass="firstButton"
                testId="firstButton-test"
                variant="text"
              >
                {firstButtonTitle}
              </ExpressButton>
            )}
          </div>
          <div className="in-patient-order-right-container">
            {secondButtonTitle !== "" && (
              <ExpressButton
                clickHandler={() => secondButtonAction()}
                disabled={secondButtonDisabled}
                parentClass="secondButton"
                testId="secondButton-test"
                variant="text"
              >
                {secondButtonTitle}
              </ExpressButton>
            )}
            {thirdButtonTitle !== "" && (
              <ExpressButton
                clickHandler={() => thirdButtonAction()}
                disabled={thirdButtonDisabled}
                parentClass="thirdButton"
                testId="thirdButton-test"
                variant="contained"
              >
                {thirdButtonTitle}
              </ExpressButton>
            )}
          </div>
        </div>
      </Toolbar>
    </PersistentFooter>
  );
};
