import "./submitPrescription.css";
import {
  NewOrderContext,
  NewOrderContextType,
} from "../../../context/NewOrderContext";
import { Button, Grid } from "@mui/material";
import { NewOrderValidator } from "../newOrder.validator";
import { WindowService } from "../../../util/window.service";
import {
  ERROR_MSG_POPUP_BLOCKER,
  ERROR_TO_SELECT_ANY_OPTION,
} from "../../../util/errorMsg";
import { ISubmitPrescription } from "./submitPrescription.validator";
import { EPrescription } from "./ePrescription/ePrescription.component";
import { ReactComponent as PrintIcon } from "../../../assets/print.svg";
import React, { MouseEvent, useContext, useEffect, useState } from "react";
import { ValidationStatus } from "../../../core/interfaces/input.interface";
import UploadPrescription from "./uploadPrescription/uploadPrescription.component";
import { ReactComponent as WarningIcon } from "../../../assets/WarningIndicator.svg";
import { PrescriptionOption } from "./prescriptionOption/prescriptionOption.component";
import { IDropZoneDocumentSelect } from "../../../core/customDropZone/dropZoneDocumentSelect.interface";

export const SubmitPrescription = ({
  data,
  Validator = new NewOrderValidator(),
  setData,
  openPDF,
  printableDocumentsLink,
}: ISubmitPrescription) => {
  const [validator] = React.useState<NewOrderValidator>(Validator!);
  const newOrderObj = useContext<NewOrderContextType | null>(NewOrderContext);

  const [isRxPrescriptionUploaded, setIsRxPrescriptionUploaded] =
    useState<boolean>(false);

  const radioButtonAction = (e: MouseEvent<HTMLElement>) => {
    newOrderObj?.setIsHandleChangeTriggered(true);
    const { id } = e.currentTarget;
    if (id !== "RxImage") {
      if (
        newOrderObj &&
        newOrderObj.prescriptionDocuments &&
        newOrderObj.prescriptionDocuments.length > 0
      ) {
        const documents = [...newOrderObj.prescriptionDocuments];
        const tempDocuments = documents.map((doc: IDropZoneDocumentSelect) => {
          let updateDoc = doc;
          updateDoc.isRemoved = true;
          return updateDoc;
        });
        newOrderObj?.setPrescriptionDocuments(tempDocuments);
        newOrderObj?.setDeletedPrescriptionDocuments(tempDocuments);
      }
    } else {
      if (
        newOrderObj &&
        newOrderObj.prescriptionDocuments &&
        newOrderObj.prescriptionDocuments.length > 0
      ) {
        const documents = [...newOrderObj.prescriptionDocuments];
        newOrderObj?.setPrescriptionDocuments(
          documents.map((doc: IDropZoneDocumentSelect) => {
            let updateDoc = doc;
            updateDoc.isRemoved = false;
            return updateDoc;
          })
        );
        newOrderObj?.setDeletedPrescriptionDocuments([]);
      }
    }
    const isValid = validator.validate(id, "submitPrescription");
    if (id === "EPrescription") {
      const isPrescriberAddedData =
        newOrderObj?.prescriberAddedData !== undefined;
      if (isPrescriberAddedData) {
        newOrderObj?.setIsPrescriberAddedOpenInfo(true);
      } else {
        newOrderObj?.setIsPrescriberAddedOpenErrorInfo(true);
      }
      setPrescriberData(
        isPrescriberAddedData
          ? ValidationStatus.VALID
          : ValidationStatus.INVALID,
        id
      );
    } else {
      newOrderObj?.setIsPrescriberAddedOpenInfo(false);
      newOrderObj?.setIsPrescriberAddedOpenErrorInfo(false);
      setPrescriberData(
        id === "RxImage" ? ValidationStatus.UNTOUCHED : isValid!.status,
        id
      );
    }
  };

  function openPDFLink() {
    const windowService = new WindowService();
    windowService.openPdf(printableDocumentsLink!.VACTherapyOrderPad);
  }

  const setPrescriberData = (validationStatus: any, value: string) => {
    setData({
      ...data,
      submitPrescription: {
        ...data.submitPrescription,
        valid: validationStatus,
        value: value,
      },
    });
  };

  const checkAnyRxPrescriptionImageAdded = () => {
    // Check if any image uploaded in Rx Upload section
    if (
      newOrderObj &&
      newOrderObj.newOrderData.submitPrescription.value === "RxImage" &&
      ((newOrderObj.prescriptionDocuments.length > 0 &&
        newOrderObj.prescriptionDocuments[0].succeeded) ||
        newOrderObj?.newOrderDocuments.some(
          (file) =>
            file.documentType &&
            file.documentType.value === "Signed Prescription"
        ))
    ) {
      setIsRxPrescriptionUploaded(true);
      setPrescriberData(ValidationStatus.VALID, "RxImage");
    } else if (
      newOrderObj &&
      newOrderObj.newOrderData.submitPrescription.value === "RxImage" &&
      newOrderObj.prescriptionDocuments.length > 0 &&
      newOrderObj.prescriptionDocuments[0].succeeded !== true
    ) {
      setIsRxPrescriptionUploaded(false);
      setPrescriberData(ValidationStatus.INVALID, "RxImage");
    } else {
      if (isRxPrescriptionUploaded !== false) {
        setIsRxPrescriptionUploaded(false);
      }
      if (
        newOrderObj &&
        newOrderObj.newOrderData.submitPrescription.value === "RxImage"
      ) {
        setPrescriberData(ValidationStatus.INVALID, "RxImage");
      }
    }
  };

  useEffect(() => {
    if (newOrderObj?.prescriberAddedData !== undefined)
      if (data.submitPrescription.value === "EPrescription") {
        const email = newOrderObj?.prescriberAddedData?.email!;
        setData({
          ...data,
          submitPrescription: {
            ...data.submitPrescription,
            valid: ValidationStatus.VALID,
            value: "EPrescription",
          },
          updatedPrescriberEmail: {
            ...data.submitPrescription,
            valid: ValidationStatus.VALID,
            value: email,
          },
        });
        newOrderObj?.setIsPrescriberAddedOpenInfo(true);
        newOrderObj?.setIsPrescriberAddedOpenErrorInfo(false);
      }
  }, [newOrderObj?.prescriberAddedData]);

  useEffect(() => {
    checkAnyRxPrescriptionImageAdded();
  }, [
    newOrderObj?.newOrderDocuments,
    newOrderObj?.prescriptionDocuments,
    newOrderObj?.newOrderData.submitPrescription.value,
  ]);

  return (
    <div className="submit-prescription">
      <div className="submit-prescription-header">
        <h2
          className="submit-prescription-title"
          data-testid="submit-prescription-title"
        >
          Submit a Valid Prescription
        </h2>
        <div className="submit-prescription-subTitle-div">
          <h3
            className={
              data.submitPrescription.valid === ValidationStatus.INVALID
                ? "submit-prescription-subTitle-error"
                : "submit-prescription-subTitle"
            }
            id={data.submitPrescription.componentId!}
          >
            Prescription Submission
          </h3>
          <h3 className="submit-prescription-subTitle-astrick"> *</h3>
        </div>
        <h5
          className="submit-prescription-description"
          data-testid="submit-prescription-description"
        >
          A prescription signed and dated by the prescriber is required for all
          orders
        </h5>
        <Button
          classes={{ root: "button-print-rx" }}
          data-testid="button-print-rx"
          variant="outlined"
          onClick={openPDF ? openPDF : openPDFLink}
          startIcon={<PrintIcon />}
        >
          Print Rx
        </Button>
      </div>
      <Grid
        className="submit-prescription-grid-container"
        container
        spacing={2}
      >
        <Grid className="submit-prescription-grid-item" item xs={12}>
          <div className="submit-prescription-div">
            <PrescriptionOption
              id="EPrescription"
              title="E-Prescription"
              prescriptionClassName="prescription-option"
              description="A DocuSign<sup>®</sup> email will be sent to the prescriber requesting an electronically signed prescription"
              isOptionSelected={
                data.submitPrescription.value === "EPrescription"
              }
              buttonOnClick={radioButtonAction}
            />
            <EPrescription
              data={data}
              Validator={Validator}
              setData={setData}
            />
            <PrescriptionOption
              id="RxImage"
              title="Rx Upload"
              prescriptionClassName="prescription-option"
              description="Attach the prescription to this order by using the scan icon (additional documents may be included)"
              isOptionSelected={data.submitPrescription.value === "RxImage"}
              buttonOnClick={radioButtonAction}
            />
            {data.submitPrescription.value === "RxImage" && (
              <UploadPrescription
                data={newOrderObj!.prescriptionDocuments}
                isRxPrescriptionUploaded={isRxPrescriptionUploaded}
                setData={newOrderObj!.setPrescriptionDocuments}
                dropdownListingType="vacOrderPrescriptionDocuments"
              />
            )}
            <PrescriptionOption
              id="Fax"
              title="Fax in Later"
              prescriptionClassName="prescription-option changePrescriptionTypesTitle"
              description="After submitting this order, please fax the prescription and other clinical documents to "
              isOptionSelected={data.submitPrescription.value === "Fax"}
              buttonOnClick={radioButtonAction}
              isFaxLater={true}
              phoneNumber="1-888-245-2295."
            />
          </div>
        </Grid>
      </Grid>
      {data.submitPrescription.value === "" &&
        data.submitPrescription.valid === ValidationStatus.INVALID && (
          <div className="submit-prescription-header">
            <div
              className="submit-prescription-subTitle-div"
              data-testid="submit-prescription-error-div"
            >
              <span
                className="warning-icon"
                data-testid="submit-prescription-warning-icon"
              >
                <WarningIcon className="warning-icon-image" />
              </span>
              <p
                className="error-with-warning-icon"
                data-testid="submit-prescription-error-message"
              >
                {ERROR_TO_SELECT_ANY_OPTION}
              </p>
            </div>
          </div>
        )}
    </div>
  );
};
