import moment from "moment";
import "./dischargeRequest.css";
import { useHistory } from "react-router-dom";
import {
  PickUpRequestContext,
  PickUpRequestContextType,
} from "../../../context/PickUpRequestContext";
import { retrievePatientDetails } from "../../../util/pickUpOrDischargeService";
import {
  RolesPermissionContext,
  RolesPermissionContextType,
} from "../../../context/RolesPermissionContext";
import {
  DischargeRequestContext,
  DischargeRequestContextType,
} from "../../../context/DischargeRequestContext";
import { SetStateAction, useContext, useEffect, useState } from "react";
import { Popup } from "../../../core/popup/popup.component";
import { LoadingSpinner } from "../../../core/loader/LoadingSpinner";
import { IDischargeRequestProps } from "./dischargeRequest.interface";
import { DischargeRequestValidator } from "./dischargeRequest.validator";
import { ValidationStatus } from "../../../core/interfaces/input.interface";
import { AuthContext, AuthContextType } from "../../../context/AuthContext";
import { TherapyOutcomes } from "./therapyOutcomes/therapyOutcomes.component";
import { Navigator } from "../../helpAndSupport/Navigator/navigator.component";
import { DischargeRequestionPageSection } from "./dischargeRequestPageSection.enum";
import { ExitDischargePopUp } from "./exitDischargePopUp/exitDischargePopUp.commonent";
import { PatientDetails } from "../pickUpRequest/patientDetails/patientDetails.component";
import { PickUpRequestPageSection } from "../pickUpRequest/pickUpRequestPageSection.enum";
import { PatientAdmissionType } from "./patientAdmissionType/patientAdmissionType.component";
import SummaryDischargeRequest from "./summaryDischargeRequest/summaryDischargeRequest.component";
import { ReviewDischargeRequest } from "./reviewDischargeRequest/reviewDischargeRequest.component";
import DischargeReqUploadDoc from "./dischargeRequestUploadDocuments/dischargeReqUploadDoc.component";
import WoundInformationDischargeRequest from "./woundInformationDischargeRequest/woundInformationDischargeRequest.component";
import { PickUpRequestFooterButtonGroup } from "../pickUpRequest/pickUpRequestFooterGroup/pickUpRequestFooterGroup.component";
import { fetchWoundList } from "../../../util/3meService";
import ErrorPopup from "../../../core/errorPopup/errorPopup.component";
import { ERROR_MSG_DIS_REQ_LOAD } from "../../../util/errorMsg";
import WoundAssessor from "../../myPatients/patientAndTherapyDetails/woundProgress/addWoundAssessment/woundAssessor/woundAssessor.component";
import { convertStringToDate } from "../../../util/utilityFunctions";
import { WOUND_LIST_EMPTY_ERROR_CODE } from "../../../util/errorCode";
import { NO_WOUND_ERROR_MESSAGE_FOR_DISCHARGE } from "../../../util/staticText";

export const DischargeRequest = ({
  Validator = new DischargeRequestValidator(),
}: IDischargeRequestProps) => {
  const history = useHistory();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [errorCode, setErrorCode] = useState("");
  const [iswoundListEmpty, setIsWoundListEmpty] = useState(false);

  const [validator] = useState<DischargeRequestValidator>(Validator);

  const authObj = useContext<AuthContextType | null>(AuthContext);
  const dischargeReqObj = useContext<DischargeRequestContextType | null>(
    DischargeRequestContext
  );
  const pickUpRequestObj = useContext<PickUpRequestContextType | null>(
    PickUpRequestContext
  );
  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );
  const patient = pickUpRequestObj!.patient;
  const setPatient = pickUpRequestObj!.setPatient;
  const registeredFacility = authObj?.registeredFaciltyAddress;
  const dischargeData = dischargeReqObj?.dischargeRequestData!;
  const dischargeRequestDocuments = dischargeReqObj?.dischargeRequestDocuments!;
  const setDischargeData = dischargeReqObj?.setDischargeRequestData!;

  const validateAll = () => {
    let isValid = validator.validateAll(dischargeData, setDischargeData!);
    if (isValid === ValidationStatus.VALID) {
      dischargeReqObj?.setDischargeRequestPageSection(
        DischargeRequestionPageSection.REVIEW_DISCHARGE_REQUEST_FORM
      );
    }
  };

  const reviewDischargeRequest = () => {
    dischargeReqObj?.setIsPreviousClicked(true);
    validateAll();
  };

  const openCancelDischargeRequestPopUp = () => {
    const isUserEditted = validator.validateUserEnteredAnyDataOrNot(
      dischargeData,
      registeredFacility,
      dischargeRequestDocuments
    );
    if (isUserEditted) {
      setOpenPopUp(true);
    } else {
      cancelDischargeRequest();
    }
  };

  const cancelDischargeRequest = () => {
    // Check if it is coming from MPD
    if (history.action === "REPLACE") {
      pickUpRequestObj?.resetData();
    }
    // Check if it is coming from PickUp Confrimation Screen
    if (history.action === "PUSH") {
      pickUpRequestObj?.setPickUpRequestPage(
        PickUpRequestPageSection.PICK_UP_REQUEST_CONFIRM_FORM
      );
    }
    dischargeReqObj?.resetData();
    history.goBack();
  };
  const getWoundListApiCall = async () => {
    let reqParams = {
      RentalOrderNumber: pickUpRequestObj!.patient?.roNumber.toString(),
      DOB: moment(pickUpRequestObj!.patient?.dob).format("MM/DD/yyyy"),
      isAcuteOrder: pickUpRequestObj!.patient?.isAcuteOrder ?? false,
    };
    try {
      const result = await fetchWoundList(reqParams);
      return result;
    } catch (error) {
      console.log("error", error);
    }
  };

  const getPatientProductDataApiCall = async (
    roNumber: string,
    dob: string,
    isAcuteOrder: boolean
  ) => {
    let reqParams = {
      // RentalOrderNumber: roNumber,
      DOB: dob ? moment(dob).format("yyyy-MM-DD") : null,
      isAcuteOrder: isAcuteOrder,
    };
    try {
      const result = await retrievePatientDetails(reqParams);
      return result;
    } catch (error) {
      console.log("error", error);
      setError(true);
    }
  };

  const apiCollection = async () => {
    const selectedPatient = pickUpRequestObj!.patient;
    const roNumber = selectedPatient?.roNumber.toString();
    const dob = selectedPatient?.dob;
    const placementDate = selectedPatient?.placementDate;
    let apiCollection = [getWoundListApiCall()];
    if (selectedPatient) {
      if (roNumber && roNumber !== "" && !placementDate) {
        apiCollection.push(
          getPatientProductDataApiCall(
            roNumber,
            dob!,
            selectedPatient?.isAcuteOrder
          )
        );
      }
    } else {
      history.goBack();
    }
    setIsLoading(true);
    const result = await Promise.all(apiCollection);
    setIsLoading(false);
    if (result.length > 0) {
      const woundListResponse = result[0];
      if (woundListResponse && woundListResponse.succeeded) {
        dischargeReqObj?.setWoundInfoDetails(woundListResponse.item);
        if (result.length === 2) {
          const patientProductResponse = result[1];
          if (patientProductResponse?.succeeded) {
            setPatient({
              ...patient!,
              productName: patientProductResponse.item.productName,
              placementDate: patientProductResponse.item.placemetDate,
              productSerialNumber:
                patientProductResponse.item.productSerialNumber,
              workOrderNumber:
                patientProductResponse.item.deliveryWorkOrderNumber,
            });
          } else {
            setErrorCode(
              patientProductResponse?.error?.errorCode ||
                patientProductResponse?.error?.code ||
                patientProductResponse?.status
            );
            setErrorMsg(ERROR_MSG_DIS_REQ_LOAD);
            setError(true);
          }
        }
        if (
          woundListResponse.item?.wounds?.length > 1 &&
          !dischargeReqObj?.isPreviousClicked
        ) {
          const validationData = {
            valid: ValidationStatus.UNTOUCHED,
            value: "",
            required: true,
          };
          setDischargeData({
            ...dischargeData,
            woundFinalMeasurementDate2: validationData,
            woundMeasurementDepth2: validationData,
            woundMeasurementLenght2: validationData,
            woundMeasurementWidth2: validationData,
          });
        } else if (woundListResponse.item?.wounds.length === 0) {
          setErrorCode(WOUND_LIST_EMPTY_ERROR_CODE);
          setErrorMsg(NO_WOUND_ERROR_MESSAGE_FOR_DISCHARGE);
          setError(true);
          setIsWoundListEmpty(true);
        }
      } else {
        setErrorCode(
          woundListResponse?.error?.errorCode ||
            woundListResponse?.error?.code ||
            woundListResponse?.status
        );
        setErrorMsg(ERROR_MSG_DIS_REQ_LOAD);
        setError(true);
      }
    }
  };

  useEffect(() => {
    if (!dischargeReqObj?.isPreviousClicked) {
      dischargeReqObj?.setDischargeRequestPageSection(
        DischargeRequestionPageSection.DISCHARGE_REQUEST_FORM
      );
    }
    apiCollection();
    setTherapyDischargeDateVal();
  }, []);

  const setTherapyDischargeDateVal = () => {
    setDischargeData({
      ...dischargeData,
      therapyDischargeDate: {
        value: convertStringToDate(Date()),
        valid: ValidationStatus.VALID,
        required: true,
      },
      stopBillDate: {
        value: convertStringToDate(Date()),
        valid: ValidationStatus.VALID,
        required: true,
      },
    });
  };

  useEffect(() => {
    setTimeout(() => {
      if (
        dischargeReqObj &&
        dischargeReqObj.scrollableDischargeComponentClassName
      ) {
        let scrollableComponent = document.getElementsByClassName(
          dischargeReqObj.scrollableDischargeComponentClassName
        )[0];
        if (scrollableComponent) {
          scrollableComponent.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "start",
          });
        }
        dischargeReqObj.setScrollableDischargeComponentClassName(undefined);
      }
    }, 300);
  }, [dischargeReqObj?.scrollableDischargeComponentClassName]);

  const closePopup = () => {
    if (iswoundListEmpty) {
      history.push("/home");
    } else {
      setError(false);
    }
  };

  const displayDischargeSection = () => {
    if (
      dischargeReqObj?.dischargeRequestPageSection ===
      DischargeRequestionPageSection.DISCHARGE_REQUEST_FORM
    ) {
      return (
        <div
          className="discharge-main-component"
          data-testid="discharge-main-component"
        >
          <div
            className="discharge-main-component-container"
            data-testid="discharge-main-component-container"
          >
            <div
              className="discharge-request-component"
              data-testid="discharge-request-component"
            >
              {isLoading && (
                <div className="discharge-request-loader">
                  <LoadingSpinner />
                </div>
              )}
              {!isLoading && error && (
                <>
                  <Navigator
                    array={[
                      {
                        route: "/home",
                        pageName: "My Patients",
                      },
                    ]}
                    className="discharge-request-route-section"
                    title="Discharge Request"
                  />
                  <ErrorPopup
                    popUpStyles="error-popup-design"
                    errorMessage={errorMsg}
                    errorPopupFlag={error}
                    errorCode={errorCode}
                    handleBackButton={closePopup}
                    buttontitle="Done"
                    showTitle={false}
                  />
                </>
              )}
              {patient && (
                <>
                  {pickUpRequestObj!.pickUpRequestPage ===
                    PickUpRequestPageSection.DISCHARGE_REQUEST_START_FORM && (
                    <Navigator
                      array={[
                        {
                          route: "/home",
                          pageName: "My Patients",
                        },
                      ]}
                      className="discharge-request-route-section"
                      title="Discharge Request"
                    />
                  )}
                  <div className="short-form">
                    <h2
                      className="discharge-request-component-header"
                      data-testid="discharge-request-component-header"
                    >
                      Discharge Request
                    </h2>
                    <PatientDetails
                      isAcuteOrderFlow={patient!.isAcuteOrder ?? false}
                      patient={patient!}
                    />
                    {dischargeReqObj?.woundInfoDetails &&
                      dischargeReqObj.woundInfoDetails.wounds.length > 0 && (
                        <WoundInformationDischargeRequest
                          dischargeData={dischargeData}
                          placementDate={patient.placementDate ?? ""}
                          setDischargeData={setDischargeData}
                          woundInfoDetails={dischargeReqObj?.woundInfoDetails}
                        />
                      )}
                  </div>
                  <div>
                    <WoundAssessor
                      data={dischargeData}
                      setData={setDischargeData}
                    />
                  </div>
                  <div>
                    <TherapyOutcomes
                      dischargeData={dischargeData}
                      setDischargeData={setDischargeData}
                    />
                  </div>
                  <div>
                    <PatientAdmissionType
                      dischargeData={dischargeData}
                      setDischargeData={setDischargeData}
                    />
                  </div>
                  <DischargeReqUploadDoc />
                  <div>
                    {pickUpRequestObj!.pickUpRequestPage ===
                      PickUpRequestPageSection.DISCHARGE_REQUEST_START_FORM && (
                      <>
                        <PickUpRequestFooterButtonGroup
                          firstButtonTitle="Cancel"
                          firstButtonAction={openCancelDischargeRequestPopUp}
                          secondButtonTitle=""
                          secondButtonAction={() => {}}
                          thirdButtonTitle="Review Discharge"
                          thirdButtonAction={reviewDischargeRequest}
                          thirdButtonDisabled={
                            permissionObj?.mappedRolesPermissionData
                              ?.IsSupportRole!
                              ? permissionObj?.mappedRolesPermissionData
                                  ?.IsSupportRole!
                              : dischargeReqObj
                              ? dischargeReqObj?.errorInUploadFiles
                              : false
                          }
                        />
                        <Popup
                          dialogParentClass="exit-discharge-pop-up-container"
                          openFlag={openPopUp}
                          closeHandler={() => setOpenPopUp(false)}
                        >
                          <ExitDischargePopUp
                            cancelBtnAction={cancelDischargeRequest}
                            returnBtnAction={() => setOpenPopUp(false)}
                          />
                        </Popup>
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      );
    } else if (
      dischargeReqObj?.dischargeRequestPageSection ===
      DischargeRequestionPageSection.REVIEW_DISCHARGE_REQUEST_FORM
    ) {
      return (
        <ReviewDischargeRequest
          woundInfoDetails={dischargeReqObj?.woundInfoDetails}
        />
      );
    } else if (
      dischargeReqObj?.dischargeRequestPageSection ===
      DischargeRequestionPageSection.SUMMARY_DISCHARGE_REQUEST_FORM
    ) {
      window.scrollTo(0, 0);
      return (
        <SummaryDischargeRequest
          pdfUrls={dischargeReqObj.pdfUrls}
          patient={pickUpRequestObj?.patient!}
          woundInfoDetails={dischargeReqObj?.woundInfoDetails}
        />
      );
    }
  };

  return <>{displayDischargeSection()}</>;
};
