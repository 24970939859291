import {
  ICanister,
  IDeliveryInformation,
  IDressingKit,
  INewOrder,
  IProductAccessory,
  IProductInfo,
  IRequesterInfo,
} from "./newOrder.interface";
import { ValidationStatus } from "../../core/interfaces/input.interface";
import {
  defaultPrimaryInsuranceData,
  defaultSecondaryInsuranceData,
} from "./insuranceInformation/insuranceInformation/insuranceInformation.model";
import { IDropZoneDocumentSelect } from "../../core/customDropZone/dropZoneDocumentSelect.interface";

export let defaultNewOrderData: INewOrder = {
  // Patient Information
  firstName: {
    componentId: "patient-information-first-name",
    id: "patient-information-first-name-input",
    minimumRequired: true,
    order: 1,
    title: "Patient First Name",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  lastName: {
    componentId: "patient-information-last-name",
    id: "patient-information-last-name-input",
    minimumRequired: true,
    order: 2,
    title: "Patient Last Name",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  dob: {
    componentId: "patient-information-date-of-birth",
    id: "patient-information-date-of-birth-input",
    minimumRequired: true,
    order: 3,
    title: "Date of Birth",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  phone: {
    componentId: "patient-information-phone-number",
    id: "patient-information-phone-number-input",
    order: 4,
    required: true,
    title: "Phone Number",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    isPhoneNumber: true,
  },
  address1: {
    componentId: "patient-information-address-line-1",
    id: "patient-information-address-line-1-input",
    order: 5,
    required: true,
    title: "Address Line 1",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  address2: {
    componentId: "patient-information-address-line-2",
    id: "patient-information-address-line-2-input",
    isDefaultValid: true,
    order: 6,
    title: "Address Line 2",
    valid: ValidationStatus.VALID,
    value: "",
  },
  city: {
    componentId: "patient-information-city",
    id: "patient-information-city-input",
    order: 7,
    required: true,
    title: "City",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  state: {
    componentId: "patient-information-state",
    id: "patient-information-state-input",
    order: 8,
    required: true,
    title: "State",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  zip: {
    componentId: "patient-information-zip",
    id: "patient-information-zip-input",
    order: 9,
    required: true,
    title: "ZIP Code",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  email: {
    componentId: "patient-information-email-address",
    id: "patient-information-email-address-input",
    order: 10,
    isDefaultValid: true,
    title: "Patient Email Address",
    valid: ValidationStatus.VALID,
    value: "",
  },

  // Patient’s Current Address
  IsSamePermanentAddress: {
    isDefaultValid: true,
    order: 11,
    valid: ValidationStatus.VALID,
    value: "true",
  },
  patientCurrentAddress1: {
    componentId: "patient-current-address-address-line-1",
    id: "patient-current-address-address-line-1-input",
    order: 12,
    title: "Address Line 1 (No P.O.Boxes)",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  patientCurrentAddress2: {
    componentId: "patient-current-address-address-line-2",
    id: "patient-current-address-address-line-2-input",
    order: 13,
    isDefaultValid: true,
    title: "Address Line 2",
    valid: ValidationStatus.VALID,
    value: "",
  },
  patientCurrentAddressCity: {
    componentId: "patient-current-address-city",
    id: "patient-current-address-city-input",
    order: 14,
    title: "City",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  patientCurrentAddressState: {
    componentId: "patient-current-address-state",
    id: "patient-current-address-state-input",
    order: 15,
    title: "State",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  patientCurrentAddressZip: {
    componentId: "patient-current-address-zip",
    id: "patient-current-address-zip-input",
    order: 16,
    title: "ZIP Code",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  patientCurrentAddressPhone: {
    componentId: "patient-current-address-phone-number",
    id: "patient-current-address-phone-number-input",
    order: 17,
    required: true,
    title: "Phone Number",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    isPhoneNumber: true,
  },

  // Emergency Contact Info (recommended)
  emergencyContactFirstName: {
    componentId: "emergency-contact-first-name",
    id: "emergency-contact-first-name-input",
    isDefaultValid: true,
    order: 18,
    title: "First Name",
    valid: ValidationStatus.VALID,
    value: "",
  },
  emergencyContactLastName: {
    componentId: "emergency-contact-last-name",
    id: "emergency-contact-last-name-input",
    isDefaultValid: true,
    order: 19,
    title: "Last Name",
    valid: ValidationStatus.VALID,
    value: "",
  },
  emergencyContactPhoneNumber: {
    componentId: "emergency-contact-phone-number",
    id: "emergency-contact-phone-number-input",
    isDefaultValid: true,
    order: 20,
    title: "Phone Number",
    valid: ValidationStatus.VALID,
    value: "",
    isPhoneNumber: true,
  },

  // Primary Insurance Information
  primaryInsurance: defaultPrimaryInsuranceData,

  // Secondary Insurance Information
  isSecondaryInsurancePresent: {
    valid: ValidationStatus.VALID,
    value: false,
    isDefaultValid: true,
  },
  secondaryInsurance: defaultSecondaryInsuranceData,

  // Contributing Cause
  woundLocation: {
    componentId: "wound-information-location",
    order: 35,
    required: true,
    title: "Wound Location",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  woundType: {
    componentId: "wound-information-type",
    order: 36,
    required: true,
    title: "Wound Type",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  contributingCause: {
    componentId: "contributing-cause-radio",
    order: 37,
    required: true,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  dateOfAccident: {
    componentId: "contributing-cause-date-of-accident",
    id: "contributing-cause-date-of-accident-input",
    order: 38,
    title: "Date of Accident",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  accidentType: {
    componentId: "contributing-cause-accident-type",
    order: 39,
    title: "Accident Type",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },

  // Therapy Information
  lengthOfTherapy: {
    componentId: "therapy-information-length-of-therapy",
    order: 40,
    required: true,
    title: "Length of Therapy",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  goalOfTherapy: {
    componentId: "therapy-information-goal-of-therapy",
    order: 41,
    required: true,
    title: "Goal of Therapy",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },

  // VAC Therapy Location
  vacTherapyLocation: {
    componentId: "vac-therapy-location-componentId",
    order: 55,
    required: true,
    title: "V.A.C.<sup>®</sup> Therapy Location Type",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  otherVacUseLocation: {
    componentId: "vac-therapy-location-otherField",
    id: "vac-therapy-location-otherField-input",
    order: 56,
    required: false,
    title: "Type of Other Location",
    valid: ValidationStatus.VALID,
    value: "",
    isOptional: true,
  },

  // Submit a valid prescription
  submitPrescription: {
    componentId: "submit-valid-prescription",
    order: 42,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },

  //Requesting Facility for updated VAC Order Flow
  requestingFacility: {
    componentId: "selecting-requesting-facility",
    order: 43,
    valid: ValidationStatus.UNTOUCHED,
    value: null,
    required: false,
    title: "Requesting Facility",
    isOptional: true,
  },

  // Prescriber Information
  updatedPrescriberEmail: {
    componentId: "submit-valid-prescription",
    isDefaultValid: true,
    order: 42,
    valid: ValidationStatus.VALID,
    value: "",
  },

  prescriptionDoc: [],

  // Inpatient Transition
  wasNPWTInitiated: {
    isDefaultValid: true,
    required: true,
    valid: ValidationStatus.VALID,
    value: "no",
  },
  wasNPWTUsedin60Days: {
    isDefaultValid: true,
    required: true,
    title: "",
    valid: ValidationStatus.VALID,
    value: "no",
  },
  dateInitiated: {
    componentId: "inpatient-transition-date-initiated",
    id: "inpatient-transition-date-initiated-input",
    order: 48,
    required: false,
    title: "Date Initiated",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  reasonForTransition: {
    required: false,
    title: "Reason for Transition",
    valid: ValidationStatus.VALID,
    value: "1",
  },
  previousRo: {
    title: "Previous RO#",
    value: "",
    required: false,
    valid: ValidationStatus.VALID,
  },

  // Home Care Provider
  homeCareProvider: {
    componentId: "home-care-provider-radio-button",
    order: 54,
    required: true,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  addedCaregiverName: {
    required: false,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  addedCaregiverAddress1: {
    required: false,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  addedCaregiverAddress2: {
    required: false,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  addedCaregiverCity: {
    required: false,
    title: "",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  addedCaregiverState: {
    required: false,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  addedCaregiverPhone: {
    required: false,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  addedCaregiverZip: {
    required: false,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  addedCaregiverFacilityType: {
    required: false,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  addedCaregiverPhoneExtension: {
    isDefaultValid: true,
    required: false,
    valid: ValidationStatus.VALID,
    value: "",
  },
  addedCaregiverFacilityTypeCode: {
    isDefaultValid: true,
    required: false,
    valid: ValidationStatus.VALID,
    value: "",
  },
  addedCaregiverSiteUseID: {
    isDefaultValid: true,
    required: false,
    valid: ValidationStatus.VALID,
    value: "",
  },
  addedCaregiverID: {
    isDefaultValid: true,
    required: false,
    valid: ValidationStatus.VALID,
    value: "",
  },
  addedCaregiverAccountNumber: {
    isDefaultValid: true,
    required: false,
    valid: ValidationStatus.VALID,
    value: "",
  },

  // Delivery Contact
  deliveryContactQuickFillType: {
    isDefaultValid: true,
    required: false,
    valid: ValidationStatus.VALID,
    value: "1",
  },
  deliveryContactFirstName: {
    componentId: "delivery-information-contact-detail",
    order: 59,
    required: true,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  deliveryContactLastName: {
    componentId: "delivery-information-contact-detail",
    order: 59,
    required: true,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  deliveryContactPhone: {
    componentId: "delivery-information-contact-detail",
    order: 59,
    required: true,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    isPhoneNumber: true,
  },
  deliveryInstructions: {
    componentId: "delivery-information-delivery-instruction",
    id: "delivery-information-delivery-instruction-input",
    isDefaultValid: true,
    order: 61,
    required: false,
    title: "Delivery Instructions",
    valid: ValidationStatus.VALID,
    value: "",
  },

  // Print Common Docs
  commonDocs: {
    valid: ValidationStatus.VALID,
    value: "",
    isDefaultValid: true,
  },

  // Upload Documents
  uploadDocument: [],

  loggedInUserSiteUseID: {
    valid: ValidationStatus.VALID,
    value: "",
  },
};

export let defaultProductInfo: IProductInfo = {
  productInformation: {
    isDefaultValid: true,
    title: "",
    valid: ValidationStatus.VALID,
    value: "",
  },
  productValue: {
    isDefaultValid: true,
    title: "",
    valid: ValidationStatus.VALID,
    value: "",
  },
};

export let defaultDressingKit: IDressingKit = {
  // primary Vac Dressing KIT
  productName: {
    componentId: "primary-dressing-kit-product-name",
    isOptional: true,
    order: 44,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  productSizeName: {
    componentId: "primary-dressing-kit-product-size-name",
    isOptional: true,
    order: 45,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  productId: {
    isDefaultValid: true,
    valid: ValidationStatus.VALID,
    value: "",
  },
  productCode: {
    isDefaultValid: true,
    isOptional: true,
    valid: ValidationStatus.VALID,
    value: "",
  },
  productSizeID: {
    isDefaultValid: true,
    valid: ValidationStatus.VALID,
    value: "",
  },
  productSizeCode: {
    isDefaultValid: true,
    isOptional: true,
    valid: ValidationStatus.VALID,
    value: "",
  },
  productQuantity: {
    isDefaultValid: true,
    isOptional: true,
    valid: ValidationStatus.VALID,
    value: "",
  },

  //secondary Vac Dressing KIT
  secProductName: {
    componentId: "secondary-dressing-kit-product-name",
    isOptional: true,
    order: 46,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  secProductSizeName: {
    componentId: "secondary-dressing-kit-product-size-name",
    isOptional: true,
    order: 47,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  secProductId: {
    isDefaultValid: true,
    valid: ValidationStatus.VALID,
    value: "",
  },
  secProductCode: {
    isDefaultValid: true,
    isOptional: true,
    valid: ValidationStatus.VALID,
    value: "",
  },
  secProductSizeID: {
    isDefaultValid: true,
    valid: ValidationStatus.VALID,
    value: "",
  },
  secProductSizeCode: {
    isDefaultValid: true,
    isOptional: true,
    valid: ValidationStatus.VALID,
    value: "",
  },
  secProductQuantity: {
    isDefaultValid: true,
    isOptional: true,
    valid: ValidationStatus.VALID,
    value: "",
  },
};

export let defaultCanister: ICanister = {
  // Canister
  canisterProductName: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  canisterProductCode: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  canisterProductQuantity: {
    valid: ValidationStatus.VALID,
    value: "1",
    isOptional: true,
    isDefaultValid: true,
  },
  canisterProductID: {
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
};

export let defaultAccessories: IProductAccessory = {
  // Accessory
  accessories: [],
};

export let defaultRequesterInfo: IRequesterInfo = {
  // Verify Requester Info
  IsRequesterSameasSubmitter: {
    isDefaultValid: true,
    required: true,
    title: "",
    valid: ValidationStatus.VALID,
    value: "yes",
  },
  requesterFirstName: {
    componentId: "verify-requestor-info-first-name",
    id: "verify-requestor-info-first-name-input",
    order: 49,
    required: true,
    title: "Requester First Name",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  requesterLastName: {
    componentId: "verify-requestor-info-last-name",
    id: "verify-requestor-info-last-name-input",
    order: 50,
    required: true,
    title: "Requester Last Name",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  requesterEmail: {
    componentId: "verify-requestor-info-email",
    id: "verify-requestor-info-email-input",
    order: 51,
    required: true,
    title: "Requester Email",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  requesterPhoneNumber: {
    componentId: "verify-requestor-info-phone-number",
    id: "verify-requestor-info-phone-number-input",
    order: 52,
    required: true,
    title: "Requester Phone Number",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    isPhoneNumber: true,
  },
  requesterTitle: {
    componentId: "verify-requestor-info-title",
    id: "verify-requestor-info-title-input",
    order: 53,
    required: true,
    title: "Title",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
};

export let defaultDeliveryInformation: IDeliveryInformation = {
  // Delivery Information
  deliveryProductNeedByDate: {
    componentId: "delivery-information-product-need-by-date",
    id: "delivery-information-product-need-by-date-input",
    order: 57,
    required: true,
    title: "Product Need By Date",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  deliveryProductNeedByTime: {
    componentId: "delivery-information-product-need-by-time",
    order: 58,
    required: true,
    title: "Need by Time",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  deliverySiteType: {
    componentId: "delivery-information-address",
    valid: ValidationStatus.VALID,
    value: "102",
  },
  deliveryFacilityName: {
    componentId: "delivery-information-address",
    order: 59,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  deliveryAddressLine1: {
    componentId: "delivery-information-address",
    order: 59,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  deliveryAddressLine2: {
    componentId: "delivery-information-address",
    order: 59,
    valid: ValidationStatus.VALID,
    value: "",
    isDefaultValid: true,
  },
  deliveryCity: {
    componentId: "delivery-information-address",
    order: 59,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  deliveryState: {
    componentId: "delivery-information-address",
    order: 60,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  deliveryZipCode: {
    componentId: "delivery-information-address",
    order: 59,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
};

export let defaultCustomUploadDocumentData: IDropZoneDocumentSelect[] = [
  {
    documentType: {
      valid: ValidationStatus.UNTOUCHED,
      value: "",
      required: true,
    },
    documentName: "",
    documentBase64: "",
    succeeded: false,
    errorMessage: null,
    isFetchingBase64: false,
  },
];

export const autoSaveOrderTimeInterval: number = 5 * 60000;
