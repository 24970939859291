import { AlertColor } from "@mui/material";

export interface ISiteStatusesBannerProp {
  handleCloseButton: Function;
  handleUrlClickAction?: Function;
  index: number;
  siteStatus: ISiteStatusesBanner;
}

export interface ISiteStatusesBanner {
  title: string;
  detail: string;
  urlLink: string;
  linkText: string;
  messageType: MessageType;
  statusType: StatusType;
  canBeCleared: boolean;
  showToInternalUsers?: boolean;
  showToExternalUsers?: boolean;
  showToUnAuthenticatedUsers?: boolean;
}

export enum MessageType {
  ALERT = "Alert",
  WARNING = "Warning",
  INFORMATION = "Information",
}

export enum StatusType {
  MESSAGE = 1,
}

export interface ISiteStatusesBannerCSS {
  classname: string;
  severity: AlertColor;
}
