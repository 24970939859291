import "./addPatient.css";
import moment from "moment";
import {
  defaultPatientDataForRental,
  defaultPatientDataForNameSearch,
} from "./searchPatient.model";
import {
  ISearchPatientByRentalOrder,
  ISearchPatientByName,
} from "./searchPatient.interface";
import { Box } from "@mui/material";
import {
  Validation,
  ValidationStatus,
} from "../../../core/interfaces/input.interface";
import { IAddPatient } from "./addPatient.interface";
import { useContext, useEffect, useState } from "react";
import { getPatientSearch } from "../../../util/3meService";
import { getDeepClone } from "../../../util/ObjectFunctions";
import { SearchByRO } from "./searchByRo/searchByRo.component";
import { getInvalidObj } from "../../../util/utilityFunctions";
import { ERROR_MSG_SEARCH_PATIENT } from "../../../util/errorMsg";
import { LoadingSpinner } from "../../../core/loader/LoadingSpinner";
import { SearchByName } from "./searchByName/searchByName.component";
import { PageSection } from "../addPatientContainer/addPatientContainer.enum";
import { ExpressButton } from "../../../core/expressButton/expressButton.component";
import { AddPatientContext } from "../addPatientContainer/addPatientContainer.context";

export const AddPatient = ({ redirectHandler }: IAddPatient) => {
  const { patientSearchValidator } = useContext(AddPatientContext);
  const [patientSearchForRental, setPatientSearchForRental] =
    useState<ISearchPatientByRentalOrder>(
      getDeepClone(defaultPatientDataForRental)
    );
  const [patientSearchForName, setPatientSearchForName] =
    useState<ISearchPatientByName>(
      getDeepClone(defaultPatientDataForNameSearch)
    );
  const [isFormValid, setIsFormValid] = useState<ValidationStatus>(
    ValidationStatus.INVALID
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    validate();
  }, [patientSearchForRental, patientSearchForName]);

  const validate = () => {
    let isValid: Validation = getInvalidObj(null);
    if (patientSearchForName.search.value === "true") {
      isValid =
        patientSearchValidator.validateAllSearchFields(patientSearchForName);
    } else if (patientSearchForRental.search.value === "true") {
      isValid = patientSearchValidator.validateAllSearchFields(
        patientSearchForRental
      );
    }
    setIsFormValid(isValid.status);
  };

  const onSubmit = () => {
    setIsLoading(true);
    let reqParams = {
      ron:
        patientSearchForRental.search.value === "true"
          ? patientSearchForRental.ro.value
          : "",
      dob:
        patientSearchForRental.search.value === "true"
          ? moment(patientSearchForRental.dob.value).format("DD-MMM-yyyy")
          : moment(patientSearchForName.dob1.value).format("DD-MMM-yyyy"),
      lastName:
        patientSearchForName.search.value === "true"
          ? patientSearchForName.lastName.value?.trim()
          : "",
      zipCode:
        patientSearchForName.search.value === "true"
          ? patientSearchForName.zip.value
          : "",
    };
    fetchPatientSearchResult(reqParams);
  };

  const fetchPatientSearchResult = async (reqParams: any) => {
    try {
      const response = await getPatientSearch(reqParams);
      if (response.succeeded && response.data !== null) {
        const data = response.data;
        let searchedPatientResult = {
          dob: moment(reqParams.dob, "DD-MMM-yyyy").format("MM/DD/yyyy"),
          roNumber: data.ron,
          firstName: data.firstName.toLowerCase(),
          lastName: data.lastName.toLowerCase(),
          facilityName: data.homecareFacilityname,
          siteUseId: data.siteUseId,
          careGiverId: data.careGiverId,
          alerts: [],
          status: data.orderStatus,
          isReadyCare: data.isReadyCare,
          dischargeDate: data.dischargeDate,
          productName: data.productName,
          productSerialNumber: data.productSerialNumber,
          isAcuteOrder: data.isAcuteOrder,
          orderCreationDate: data.orderDate,
          patientId: data.patientId,
          patientLocation: data.patientLocation,
        };
        redirectHandler(PageSection.FOUND, [searchedPatientResult]);
      } else if (response.succeeded && response.data === null) {
        setIsLoading(false);
        redirectHandler(PageSection.NOT_FOUND);
      } else {
        setIsLoading(false);
        redirectHandler(
          PageSection.ERROR_FORM,
          null,
          response.error.code,
          ERROR_MSG_SEARCH_PATIENT
        );
      }
    } catch (error) {}
  };

  return (
    <div className="dialogSpace">
      <div className="addPatient">Add Patient</div>
      <div className="addPatientIntro">
        To add patient to your list, search with either of the criteria below
      </div>
      {isLoading ? (
        <div className="search-facility-loader">
          <LoadingSpinner />
        </div>
      ) : (
        <Box sx={{ flexGrow: 1, margin: "0 -4px" }}>
          <SearchByRO
            patientSearchDataForName={patientSearchForName}
            patientSearchDataForRo={patientSearchForRental}
            setPatientSearchDataForName={setPatientSearchForName}
            setPatientSearchDataForRo={setPatientSearchForRental}
          />
          <div className="or">or</div>
          <SearchByName
            patientSearchDataForName={patientSearchForName}
            patientSearchDataForRo={patientSearchForRental}
            setPatientSearchDataForName={setPatientSearchForName}
            setPatientSearchDataForRo={setPatientSearchForRental}
          />
        </Box>
      )}
      <div>
        <ExpressButton
          disabled={isFormValid === ValidationStatus.VALID ? false : true}
          parentClass="search-patient"
          variant="contained"
          clickHandler={onSubmit}
        >
          Search
        </ExpressButton>
      </div>
    </div>
  );
};
