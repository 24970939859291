import { format } from "react-string-format";
import { SVC_GET_FACILITY_SUPPLYORDER } from "./staticText";
import { getKeyData } from "./encryptDecrypt";

export const getFacilitySupplyOrders = async (reqBody: any) => {
  try {
    const AuthDetails = sessionStorage.getItem("okta-token-storage");
    const data = JSON.parse(AuthDetails ?? "");
    const accessToken = data.accessToken?.accessToken;
    const baseUrl = process.env.REACT_APP_FACILITY_MANAGER_FUNC_BASE_URL ?? "";
    const addUserDetailsUrl = format(
      "{0}{1}",
      baseUrl,
      SVC_GET_FACILITY_SUPPLYORDER
    );
    const authorizationToken = format("Bearer {0}", accessToken);
    const funckeyParse = getKeyData();
    const response = await fetch(addUserDetailsUrl, {
      method: "POST",
      headers: {
        Authorization: authorizationToken,
        "x-functions-key": funckeyParse.FacilityManagerFunction ?? "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(reqBody),
    });
    if (response.status === 200) {
      const data = response.json();
      return data;
    }
    return response;
  } catch (error) {
    console.log("error", error);
  }
  return null;
};
