import { Grid } from "@mui/material";
import "./endTherapyPopUpStaticData.css";
import { ICustomerPlusListingDetails } from "../../../customerPlusInventory/customerPlusInventory.interface";
import {
  convertStringToDate,
  makeCapitalEachWordInString,
} from "../../../../../util/utilityFunctions";

interface Props {
  endTherapyData: ICustomerPlusListingDetails;
  isComingFromMakeUnitAvailable?: boolean;
}
export const EndTherapyStaticData = ({
  endTherapyData,
  isComingFromMakeUnitAvailable = false,
}: Props) => {
  const firstName =
    endTherapyData.patientFirstName !== null ||
    endTherapyData.patientFirstName !== ""
      ? endTherapyData.patientFirstName
      : "--";
  const lastName =
    endTherapyData.patientLastName !== null ||
    endTherapyData.patientLastName !== ""
      ? endTherapyData.patientLastName
      : "--";
  const patientFullName = `${lastName} ${firstName}`;
  const productNameDesc = `${endTherapyData.productNameDescription} - ${endTherapyData.serialNumber}`;

  return (
    <>
      <div
        className="end-therapy-details-component"
        data-testid="end-therapy-details-component"
      >
        <Grid
          container
          classes={{ root: "end-therapy-details-grid-container" }}
          spacing={2}
        >
          <Grid item xs={6} className="end-therapy-details-grid-item">
            <div className="end-therapy-name" data-testid="end-therapy-name">
              <p
                className="end-therapy-details-title"
                data-testid="end-therapy-details-title-product-name"
              >
                Unit Tag ID
              </p>
              <p
                className="end-therapy-details-value"
                data-testid="end-therapy-details-value-product-name"
              >
                {makeCapitalEachWordInString(endTherapyData.unitTagId)}
              </p>
            </div>
          </Grid>
          <Grid item xs={6} className="end-therapy-details-grid-item">
            <div
              className="end-therapy-serial"
              data-testid="end-therapy-serial"
            >
              <p
                className="end-therapy-details-title"
                data-testid="end-therapy-details-title-product-sn"
              >
                Product
              </p>
              <p
                className="end-therapy-details-value"
                data-testid="end-therapy-details-value-product-sn"
              >
                {productNameDesc}
              </p>
            </div>
          </Grid>
          {isComingFromMakeUnitAvailable && (
            <Grid item xs={6} className="end-therapy-details-grid-item">
              <div
                className="end-therapy-serial"
                data-testid="end-therapy-serial"
              >
                <p
                  className="end-therapy-details-title"
                  data-testid="end-therapy-details-title-product-sn"
                >
                  Therapy End Date
                </p>
                <p
                  className="end-therapy-details-value"
                  data-testid="end-therapy-details-value-product-sn"
                >
                  {endTherapyData.therapyEndDate &&
                  endTherapyData.therapyEndDate !== ""
                    ? convertStringToDate(endTherapyData.therapyEndDate)
                    : "--"}
                </p>
              </div>
            </Grid>
          )}
          <Grid item xs={6} className="end-therapy-details-grid-item">
            <div
              className="end-therapy-serial"
              data-testid="end-therapy-serial"
            >
              <p
                className="end-therapy-details-title"
                data-testid="end-therapy-details-title-product-sn"
              >
                Current Location
              </p>
              <p
                className="end-therapy-details-value"
                data-testid="end-therapy-details-value-product-sn"
              >
                {endTherapyData.currentLocation &&
                endTherapyData.currentLocation !== ""
                  ? endTherapyData.currentLocation
                  : "--"}
              </p>
            </div>
          </Grid>
          {!isComingFromMakeUnitAvailable && (
            <Grid item xs={6} className="end-therapy-details-grid-item">
              <div
                className="end-therapy-serial"
                data-testid="end-therapy-serial"
              >
                <p
                  className="end-therapy-details-title"
                  data-testid="end-therapy-details-title-product-sn"
                >
                  Patient
                </p>
                <p
                  className="end-therapy-details-value"
                  data-testid="end-therapy-details-value-product-sn"
                >
                  {patientFullName}
                </p>
              </div>
            </Grid>
          )}
        </Grid>
      </div>
    </>
  );
};
