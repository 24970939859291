import moment from "moment";
import { useContext } from "react";
import { MouseEventHandler } from "react";
import "./successConfirmPlacementPopup.css";
import { useHistory } from "react-router-dom";
import { Grid, useMediaQuery } from "@mui/material";
import {
  SubmitProofOfDeliveryContext,
  SubmitProofOfDeliveryContextType,
} from "../../../context/submitProofOfDeliveryContext";
import { IPatient } from "../../myPatients/patient.interface";
import { IInventoryProduct } from "../confirmPlacement.interface";
import {
  makeCapitalEachWordInString,
  maskProductInUse,
} from "../../../util/utilityFunctions";
import { ExpressButton } from "../../../core/expressButton/expressButton.component";
import {
  DischargeRequestContext,
  DischargeRequestContextType,
} from "../../../context/DischargeRequestContext";

type SuccessPopupProps = {
  inventoryProduct: IInventoryProduct;
  patient: IPatient;
  placementDateAndTime: string;
  returnToMPD?: MouseEventHandler<HTMLButtonElement>;
  completePOD?: MouseEventHandler<HTMLButtonElement>;
};

export const SuccessPopupForConfirmPlacement = ({
  inventoryProduct,
  patient,
  placementDateAndTime,
  returnToMPD,
  completePOD,
}: SuccessPopupProps) => {
  const history = useHistory();
  const isMobileScreen = useMediaQuery("(max-width:450px)");
  const submitProofOfDeliveryObj =
    useContext<SubmitProofOfDeliveryContextType | null>(
      SubmitProofOfDeliveryContext
    );
  const dischargeReqObj = useContext<DischargeRequestContextType | null>(
    DischargeRequestContext
  );

  const returnToMyPatientsBtnAction = () => {
    history.replace("/home");
  };

  const completeProofOfDeliveryBtnAction = () => {
    submitProofOfDeliveryObj?.resetData();
    history.push({
      pathname: "/home/proofOfDelivery",
      state: {
        stateData: patient,
      },
    });
  };

  const openOrderDetail = () => {
    dischargeReqObj?.setIsPreviousClicked(true);
    history.push({
      pathname: "/home/orderOverview",
      state: {
        stateData: patient,
      },
    });
  };

  return (
    <div className="success-confirm-placement-popup-div">
      <p
        className="confirm-placement-success-title"
        data-testid="confirm-placement-success-title"
      >
        Placement Confirmed
      </p>
      <ul
        className="confirm-placement-success-description"
        data-testid="confirm-placement-success-description"
      >
        <li>Product is ready to be placed upon completion of the Proof of Delivery and Acceptance of Benefits form which will be found on the next page.</li>
        <li>During placement, please have the patient sign the Proof of Delivery and Assignment of Benefits form and submit it to 3M Medical Solutions as soon as possible and provide the patient with a copy.</li>
      </ul>
      <Grid
        className="confirmation-information-grid-container"
        container
        spacing={2}
      >
        <Grid className="confirmation-information-grid-item" item xs={12}>
          <p
            className="confirmation-information-heading"
            data-testid="confirmation-information-heading"
          >
            Confirmation Information
          </p>
        </Grid>
        <Grid
          className="confirmation-information-grid-item"
          item
          xs={isMobileScreen ? 12 : 6}
        >
          <div className="confirmation-information-grid-item-div">
            <p
              className="confirmation-information-title"
              data-testid="confirmation-information-patient-name-title"
            >
              Patient Name
            </p>
            <p
              className="confirmation-information-title-value"
              data-testid="confirmation-information-patient-name-value"
            >
              {`${makeCapitalEachWordInString(
                patient.firstName
              )} ${makeCapitalEachWordInString(patient.lastName)}`}
            </p>
          </div>
        </Grid>
        <Grid
          className="confirmation-information-grid-item"
          item
          xs={isMobileScreen ? 12 : 6}
        >
          <div className="confirmation-information-grid-item-div">
            <p
              className="confirmation-information-title"
              data-testid="confirmation-information-patient-dob-title"
            >
              Date of Birth
            </p>
            <p
              className="confirmation-information-title-value"
              data-testid="confirmation-information-patient-dob-value"
            >
              {patient.dob !== ""
                ? `${moment(patient.dob).format("MM/DD/YYYY")}`
                : "--"}
            </p>
          </div>
        </Grid>
        <Grid
          className="confirmation-information-grid-item"
          item
          xs={isMobileScreen ? 12 : 6}
        >
          <div className="confirmation-information-grid-item-div">
            <p
              className="confirmation-information-title"
              data-testid="confirmation-information-product-name-title"
            >
              Product Name
            </p>
            {inventoryProduct.product && inventoryProduct.product !== "" ? (
              <p
                className="confirmation-information-title-value"
                data-testid="confirmation-information-product-name-value"
              >
                {maskProductInUse(inventoryProduct.product)}
              </p>
            ) : (
              <p
                className="confirmation-information-title-value"
                data-testid="confirmation-information-product-name-value"
              >
                {"--"}
              </p>
            )}
          </div>
        </Grid>
        <Grid
          className="confirmation-information-grid-item"
          item
          xs={isMobileScreen ? 12 : 6}
        >
          <div className="confirmation-information-grid-item-div">
            <p
              className="confirmation-information-title"
              data-testid="confirmation-information-product-serial-title"
            >
              Product Serial #
            </p>
            <p
              className="confirmation-information-title-value"
              data-testid="confirmation-information-product-serial-value"
            >
              {inventoryProduct.serialNumber ?? "--"}
            </p>
          </div>
        </Grid>
        <Grid
          className="confirmation-information-grid-item"
          item
          xs={isMobileScreen ? 12 : 6}
        >
          <div className="confirmation-information-grid-item-div">
            <p
              className="confirmation-information-title"
              data-testid="confirmation-information-rental-order-title"
            >
              Rental Order #
            </p>
            <p
              className="confirmation-information-title-value rental-order-number"
              data-testid="confirmation-information-rental-order-value"
              onClick={openOrderDetail}
            >
              {patient.roNumber.toString()}
            </p>
          </div>
        </Grid>
        <Grid
          className="confirmation-information-grid-item"
          item
          xs={isMobileScreen ? 12 : 6}
        >
          <div className="confirmation-information-grid-item-div">
            <p
              className="confirmation-information-title"
              data-testid="confirmation-information-placement-date-time-title"
            >
              Placement Date & Time
            </p>
            <p
              className="confirmation-information-title-value"
              data-testid="confirmation-information-placement-date-time-value"
            >
              {placementDateAndTime}
            </p>
          </div>
        </Grid>
      </Grid>
      <p
        className="confirm-placement-success-note"
        data-testid="confirm-placement-success-note"
      >
        <span className="bold-note">Please note:</span> Supplies ordered for
        delivery to the patient's current address will be shipped.
      </p>
      <Grid className="success-buttons-grid-container" container spacing={2}>
        <Grid
          className="success-buttons-grid-item"
          item
          xs={isMobileScreen ? 12 : 6}
        >
          <ExpressButton
            clickHandler={
              returnToMPD ? returnToMPD : returnToMyPatientsBtnAction
            }
            parentClass="return-to-my-patients"
            testId="return-to-my-patients"
            variant="outlined"
          >
            Return to My Patients
          </ExpressButton>
        </Grid>
        <Grid
          className="success-buttons-grid-item"
          item
          xs={isMobileScreen ? 12 : 6}
        >
          <ExpressButton
            clickHandler={
              completePOD ? completePOD : completeProofOfDeliveryBtnAction
            }
            parentClass="complete-proof-of-delivery"
            testId="complete-proof-of-delivery"
            variant="contained"
          >
            Complete Proof of Delivery
          </ExpressButton>
        </Grid>
      </Grid>
    </div>
  );
};
