import { Button, Grid } from "@mui/material";
import { useContext, useState } from "react";
import { ReactComponent as SearchIcon } from "../../../../assets/searchPayer.svg";
import { ExpressButton } from "../../../../core/expressButton/expressButton.component";
import { InputWithLabel } from "../../../../core/inputWithLabel/inputWithLabel.component";
import {
  IInputField,
  ValidationStatus,
} from "../../../../core/interfaces/input.interface";
import { Popup } from "../../../../core/popup/popup.component";
import {
  makeCapitalEachWordInString,
  formatPhoneNumber,
} from "../../../../util/utilityFunctions";
import { PrescriberInformationPopUpContainer } from "../../../inpatientOrder/components/prescriberInformation/popUpContainer/prescriberInformationPopUpContainer.component";
import { PrescriberPopUp } from "../../../inpatientOrder/components/prescriberInformation/popUpContainer/prescriberInformationPopUpContainer.enum";
import { IPrescriberInformation } from "../../../inpatientOrder/components/prescriberInformation/prescriberInformation.interface";
import "../addPrescriberSection/addPrescriber.css";
import EditPrescriberEmail from "./editPrescriberEmail/editPrescriberEmail.component";
import {
  IMobileDisplayContext,
  MobileDisplayContext,
} from "../../../../context/MobileDisplayContext";
import { AdditionalInformation } from "../additionalInformation/additionalInformation.component";
import {
  SendNoteContext,
  SendNoteContextType,
} from "../../../../context/SendNoteContext";
import {
  defaultNewEmailData,
  defaultNewEmailDataMultiple,
} from "./addedPrescriber.model";

interface IAddPrescriberInformation {
  data: IInputField;
  prescriberInformation: IPrescriberInformation;
  setData: Function;
  newPrescriberEmail: IInputField;
  setNewPrescriberEmail: Function;
  newPrescriberEmailMultiple: IInputField;
  setNewPrescriberEmailMultiple: Function;
  isEditButtonClicked: boolean;
  setIsEditButtonClicked: Function;
}

export const AddPrescriberInformation = ({
  data,
  prescriberInformation,
  setData,
  newPrescriberEmail,
  setNewPrescriberEmail,
  newPrescriberEmailMultiple,
  setNewPrescriberEmailMultiple,
  isEditButtonClicked,
  setIsEditButtonClicked,
}: IAddPrescriberInformation) => {
  const [currentPopUp, setCurrentPopUp] = useState<PrescriberPopUp>(
    PrescriberPopUp.LOADER
  );
  const [openContainerPopupFlag, setOpenContainerPopupFlag] = useState(false);

  const { isMobileScreen } =
    useContext<IMobileDisplayContext>(MobileDisplayContext);

  const sendNoteObj = useContext<SendNoteContextType | null>(SendNoteContext);
  const additionalInfoData = sendNoteObj!.additionalInfoData;
  const setAdditionalInfoData = sendNoteObj!.setAdditionalInfoData;
  const closePopUp = () => {
    setOpenContainerPopupFlag(false);
    setTimeout(() => {
      setCurrentPopUp(PrescriberPopUp.LOADER);
    }, 500);
  };

  const searchPrescriberButtonAction = () => {
    setOpenContainerPopupFlag(true);
    setIsEditButtonClicked(false);
  };

  const handleClosePopUpAction = () => {
    closePopUp();
  };

  const handleSuccessAndClose = (prescriber: IPrescriberInformation) => {
    let tempPrescriber = prescriber;
    setNewPrescriberEmail(defaultNewEmailData);
    setNewPrescriberEmailMultiple(defaultNewEmailDataMultiple);
    if (tempPrescriber.email === "") {
      if (tempPrescriber.emails.length !== 0) {
        tempPrescriber.email = tempPrescriber.emails[0].value;
      }
    }
    const tempData = {
      ...data,
      valid: ValidationStatus.VALID,
      value: tempPrescriber,
    };
    setData(tempData);
    closePopUp();
  };

  const showTitleAndValue = (
    title: string,
    value: string | null,
    testId: string
  ) => {
    return (
      <div className="show-title-and-value-div">
        <p
          className="show-title-and-value-title"
          data-testid={`${testId}-title`}
          id={`${testId}-title-id`}
        >
          {title}
        </p>
        <p
          className="show-title-and-value-value"
          data-testid={`${testId}-value`}
          id={`${testId}-value-id`}
        >
          {value && value !== "" ? value.trim() : "--"}
        </p>
      </div>
    );
  };

  return (
    <div
      className="send-note-prescriber-informantion-component"
      data-testid="send-note-prescriber-informantion-component"
    >
      <div className="short-form">
        <p
          className={`send-note-prescriber-informantion-title ${
            data.valid === ValidationStatus.INVALID && "error"
          }`}
          data-testid="send-note-prescriber-informantion-title"
          id="send-note-prescriber-informantion-title"
        >
          New prescriber information<span className="error">*</span>
        </p>
        {prescriberInformation &&
        prescriberInformation.firstName &&
        prescriberInformation.firstName.length > 0 ? (
          <>
            <Grid
              className="prescriber-selected-details-grid-container"
              container
            >
              <Grid
                className="prescriber-selected-details-grid-item"
                item
                xs={6}
              >
                <p
                  className="prescriber-name"
                  data-testid="prescriber-name"
                  id="prescriber-name"
                >
                  {makeCapitalEachWordInString(
                    `${prescriberInformation.firstName} ${prescriberInformation.lastName}`
                  )}
                </p>
              </Grid>
              <Grid
                className="prescriber-selected-details-grid-item"
                item
                xs={6}
              >
                <Button
                  classes={{
                    root: "change-prescriber-button",
                  }}
                  data-testid="change-prescriber-button"
                  id="change-prescriber-button"
                  onClick={() => {
                    searchPrescriberButtonAction();
                  }}
                >
                  Change prescriber
                </Button>
              </Grid>
              <Grid
                className="prescriber-selected-details-grid-item"
                item
                xs={!isMobileScreen ? 4 : 6}
              >
                {showTitleAndValue(
                  "NPI",
                  prescriberInformation.npi,
                  "prescriber-npi"
                )}
              </Grid>
              <Grid
                className="prescriber-selected-details-grid-item"
                item
                xs={!isMobileScreen ? 4 : 6}
              >
                {showTitleAndValue(
                  "Phone",
                  formatPhoneNumber(prescriberInformation.telephoneNumber),
                  "prescriber-phone"
                )}
              </Grid>
              <Grid
                className="prescriber-selected-details-grid-item"
                item
                xs={!isMobileScreen ? 4 : 6}
              >
                {showTitleAndValue(
                  "Fax",
                  prescriberInformation.faxNumber === "" ||
                    prescriberInformation.faxNumber === null
                    ? "--"
                    : formatPhoneNumber(prescriberInformation.faxNumber!),
                  "prescriber-fax"
                )}
              </Grid>
              <Grid
                className="prescriber-selected-details-grid-item"
                item
                xs={!isMobileScreen ? 4 : 6}
              >
                {showTitleAndValue(
                  "Address",
                  `${makeCapitalEachWordInString(
                    prescriberInformation.address1
                  )}\n${
                    prescriberInformation.address2 &&
                    prescriberInformation.address2 !== ""
                      ? `${makeCapitalEachWordInString(
                          prescriberInformation.address2
                        )}\n`
                      : ""
                  }${makeCapitalEachWordInString(
                    prescriberInformation.city
                  )}, ${prescriberInformation.state} ${
                    prescriberInformation.zipCode
                  }`,
                  "prescriber-address"
                )}
              </Grid>
              <Grid
                xs={isMobileScreen ? 12 : 8}
                mt={isMobileScreen && !isEditButtonClicked ? 0 : 1}
              >
                <EditPrescriberEmail
                  data={data}
                  setData={setData}
                  prescriberInformation={prescriberInformation}
                  isEditButtonClicked={isEditButtonClicked}
                  setIsEditButtonClicked={setIsEditButtonClicked}
                  newPrescriberEmail={newPrescriberEmail}
                  setNewPrescriberEmail={setNewPrescriberEmail}
                  newPrescriberEmailMultiple={newPrescriberEmailMultiple}
                  setNewPrescriberEmailMultiple={setNewPrescriberEmailMultiple}
                />
              </Grid>
            </Grid>
            <AdditionalInformation
              data={additionalInfoData}
              setData={setAdditionalInfoData}
            />
          </>
        ) : (
          <div className="send-note-prescriber-search-prescriber-button-div">
            <InputWithLabel
              error={data.valid === ValidationStatus.INVALID}
              id={data.componentId!}
              isShowWarning={true}
            >
              <ExpressButton
                id="search-prescriber-informantion-button"
                clickHandler={searchPrescriberButtonAction}
                parentClass="search-prescriber-informantion-button"
                startIcon={<SearchIcon />}
                testId="search-prescriber-informantion-button"
                variant="outlined"
              >
                Search for Prescriber
              </ExpressButton>
            </InputWithLabel>
          </div>
        )}
        <Popup
          openFlag={openContainerPopupFlag}
          dialogParentClass="send-note-prescriber-pop-up-container"
          closeHandler={handleClosePopUpAction}
          hideCloseButton={currentPopUp === PrescriberPopUp.LOADER}
        >
          <PrescriberInformationPopUpContainer
            closePopUp={handleClosePopUpAction}
            currentPopUp={currentPopUp}
            setCurrentPopUp={setCurrentPopUp}
            successAndClose={handleSuccessAndClose}
            selectedNpi={
              prescriberInformation && prescriberInformation.npi
                ? prescriberInformation.npi
                : ""
            }
          />
        </Popup>
      </div>
    </div>
  );
};
