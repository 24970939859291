import { Box, Grid, InputBase, useMediaQuery } from "@mui/material";
import React, { useContext, useEffect } from "react";
import {
  NewOrderContext,
  NewOrderContextType,
} from "../../../../context/NewOrderContext";
import { CustomDropDown } from "../../../../core/customDropdown/customDropdown.component";
import { InputWithLabel } from "../../../../core/inputWithLabel/inputWithLabel.component";
import { ValidationStatus } from "../../../../core/interfaces/input.interface";
import {
  formatedWoundValue,
  getCodeFromText,
  getTextFromCode,
} from "../../../../util/utilityFunctions";
import { IAddWoundAssessment } from "../../../myPatients/patientAndTherapyDetails/woundProgress/addWoundAssessment/addWoundAssessment.interface";
import { ISecondaryWoundInfo } from "../../clinicalInformation/secondaryWoundInfo/secondaryWoundInfo.interface";
import { NewOrderValidator } from "../../newOrder.validator";
import { INewOrderWoundInfo } from "../../newOrderWoundInfoStepper/newOrderWoundInfo.interface";
import { woundTunnelingProps } from "../woundTunneling.interfaces";

const TunnelingDetails = ({
  isSavedOrder = false,
  woundInfoData,
  setWoundInfoData,
  positionDropDownData,
  positionDropDownDataText,
  Validator = new NewOrderValidator(),
}: woundTunnelingProps) => {
  const [validator] = React.useState<NewOrderValidator>(Validator!);
  const isSmallerMobileScreen = useMediaQuery("(max-width:350px)");
  const isMobileScreen = useMediaQuery("(max-width:600px)");
  const NewOrderObj = useContext<NewOrderContextType | null>(NewOrderContext);

  const formatWoundZeros = async (e: any) => {
    let { value, name, required } = e.target;
    let key: keyof typeof woundInfoData = name;
    let formatedValue = formatedWoundValue(woundInfoData[key].value);
    setWoundInfoData({
      ...woundInfoData,
      [name]: {
        ...Object(woundInfoData)[name],
        value: formatedValue,
        valid:
          value.length === 0 && woundInfoData[key].required
            ? ValidationStatus.INVALID
            : ValidationStatus.VALID,
        required: required,
        errorMessage: null,
      },
    });
    validateDepthValueNotZero(formatedValue, key);
  };

  const validateAndSetData = (e: any) => {
    NewOrderObj?.setIsHandleChangeTriggered(true);
    let { value, name } = e.target;
    let isValid = validator.validate(value, name);
    let key: keyof typeof woundInfoData = name;
    value = getCodeFromText(positionDropDownData, e.target.value);
    setWoundInfoData({
      ...woundInfoData,
      [name]: {
        ...Object(woundInfoData)[name],
        value: value,
        valid: isValid?.status,
        required: woundInfoData[key].required,
        title: "Clock Position",
        errorMessage: null,
      },
    });
    Object(woundInfoData)[name].errorMessage = null;
    validateClockPositionNotSame(value, key);
  };

  const validateAndSetDepthData = (e: any) => {
    NewOrderObj?.setIsHandleChangeTriggered(true);
    let { value, name, required } = e.target;
    let key: keyof typeof woundInfoData = name;
    let isValid = validator.validate(value, name);
    if (isValid?.status === ValidationStatus.VALID) {
      setWoundInfoData({
        ...woundInfoData,
        [name]: {
          ...Object(woundInfoData)[name],
          value: value,
          valid:
            value.length === 0 && woundInfoData[key].required
              ? ValidationStatus.INVALID
              : isValid?.status,
          required: required,
          errorMessage: null,
          title: "Location Depth",
        },
      });
    }
  };

  const validateClockPositionNotSame = (
    value: string,
    name: keyof typeof woundInfoData,
    isSavedOrder?: boolean
  ) => {
    if (
      value ===
        woundInfoData[
          name === "location1Position"
            ? "location2Position"
            : "location1Position"
        ].value &&
      value !== ""
    ) {
      setDefaultClockPositionValue(
        isSavedOrder ? ValidationStatus.UNTOUCHED : ValidationStatus.INVALID,
        value,
        name
      );
    } else {
      if (woundInfoData[name].value) {
        setDefaultClockPositionValue(ValidationStatus.VALID, value, name);
      }
    }
  };

  const setDefaultClockPositionValue = (
    ValidationStatus: ValidationStatus,
    value: string,
    name: keyof typeof woundInfoData
  ) => {
    woundInfoData.location1Position.valid = ValidationStatus;
    woundInfoData.location2Position.valid = ValidationStatus;
    woundInfoData[name].value = value;
    setWoundInfoData(Object.assign({}, woundInfoData));
  };

  const validateDepthValueNotZero = (
    value: string,
    key: keyof (INewOrderWoundInfo | ISecondaryWoundInfo | IAddWoundAssessment)
  ) => {
    let convertValue = parseFloat(value);
    if (convertValue === 0) {
      woundInfoData[key].value = value;
      woundInfoData[key].valid = ValidationStatus.INVALID;
      setWoundInfoData(Object.assign({}, woundInfoData));
    }
  };

  useEffect(() => {
    if (isSavedOrder) {
      if (
        woundInfoData.location1Position.value &&
        woundInfoData.location1Position.value !== ""
      ) {
        let name: keyof typeof woundInfoData = "location1Position";
        let value = woundInfoData.location1Position.value;
        validateClockPositionNotSame(value, name, isSavedOrder);
      }
      if (
        woundInfoData.location2Position.value &&
        woundInfoData.location2Position.value !== ""
      ) {
        let name: keyof typeof woundInfoData = "location2Position";
        let value = woundInfoData.location2Position.value;
        validateClockPositionNotSame(value, name, isSavedOrder);
      }
    }
  }, []);

  return (
    <div>
      <Box className="tunneling-box-container" sx={{ flexGrow: 1 }}>
        <Grid className="tunneling-grid-container" container spacing={2}>
          <Grid
            className="tunneling-grid-item"
            item
            xs={isSmallerMobileScreen ? 12 : isMobileScreen ? 5 : 3.2}
          >
            <InputWithLabel
              label="Location 1 Depth (cm)"
              isRequired={woundInfoData?.location1Depth.required}
              error={
                woundInfoData?.location1Depth.valid === ValidationStatus.INVALID
              }
              id={woundInfoData?.location1Depth.componentId!}
              isShowWarning={true}
              warningMessage={woundInfoData?.location1Depth.errorMessage}
            >
              <InputBase
                className="depth-input"
                name="location1Depth"
                value={woundInfoData?.location1Depth.value}
                onChange={validateAndSetDepthData}
                onBlur={formatWoundZeros}
                required={woundInfoData?.location1Depth.required}
                data-testid="location1Depth"
                type="text"
                inputProps={{
                  inputMode: "decimal",
                  pattern: "^[0-9.]+$",
                }}
                id={woundInfoData?.location1Depth.id!}
              />
            </InputWithLabel>
          </Grid>
          <Grid item xs={isSmallerMobileScreen ? 6 : isMobileScreen ? 4 : 2.8}>
            <InputWithLabel
              label="Clock Position"
              isRequired={woundInfoData?.location1Position.required}
              error={
                woundInfoData?.location1Position.valid ===
                ValidationStatus.INVALID
              }
              testId="test-location1Position"
              isShowWarning={true}
              id={woundInfoData?.location1Position.componentId!}
              warningMessage={woundInfoData?.location1Position.errorMessage}
              isDropdown={true}
            >
              <CustomDropDown
                handleChange={validateAndSetData}
                menuItem={positionDropDownDataText}
                name="location1Position"
                placeHolder="00:00"
                selectpropsClassName={
                  woundInfoData.location1Position.value
                    ? "clockPosition-select"
                    : "placeHolder"
                }
                selectClassName={
                  woundInfoData.location1Position.value
                    ? "clockPosition-input"
                    : "placeHolder"
                }
                testId="test-location1Position-dropdown"
                value={
                  woundInfoData?.location1Position.value
                    ? getTextFromCode(
                        positionDropDownData,
                        woundInfoData?.location1Position.value
                      )
                    : null
                }
              />
            </InputWithLabel>
          </Grid>

          <Grid item xs={isMobileScreen ? 2 : 0.5}>
            <div className="clockText" data-testid="oclock-first">
              <p className="text">o’clock</p>
            </div>
          </Grid>
        </Grid>
      </Box>
      <Box className="tunneling-box-container" sx={{ flexGrow: 1 }}>
        <Grid className="tunneling-grid-container" container spacing={2}>
          <Grid item xs={isSmallerMobileScreen ? 12 : isMobileScreen ? 5 : 3.2}>
            <InputWithLabel
              label="Location 2 Depth (cm)"
              isRequired={woundInfoData?.location2Depth.required}
              error={
                woundInfoData?.location2Depth.valid === ValidationStatus.INVALID
              }
              id={woundInfoData?.location2Depth.componentId!}
              isShowWarning={true}
              warningMessage={woundInfoData?.location2Depth.errorMessage}
            >
              <InputBase
                className="depth-input"
                name="location2Depth"
                value={woundInfoData?.location2Depth.value}
                onChange={validateAndSetDepthData}
                onBlur={formatWoundZeros}
                required={woundInfoData?.location2Depth.required}
                data-testid="location2Depth"
                type="text"
                id={woundInfoData?.location2Depth.id!}
                inputProps={{
                  inputMode: "decimal",
                  pattern: "^[0-9.]+$",
                }}
              />
            </InputWithLabel>
          </Grid>
          <Grid item xs={isSmallerMobileScreen ? 6 : isMobileScreen ? 4 : 2.8}>
            <InputWithLabel
              label="Clock Position"
              isRequired={false}
              error={
                woundInfoData?.location2Position.valid ===
                ValidationStatus.INVALID
              }
              testId="test-location2Position"
              id={woundInfoData?.location2Position.componentId!}
              isShowWarning={true}
              warningMessage={woundInfoData?.location2Position.errorMessage}
              isDropdown={true}
            >
              <CustomDropDown
                handleChange={validateAndSetData}
                menuItem={positionDropDownDataText}
                name="location2Position"
                placeHolder="00:00"
                selectpropsClassName={
                  woundInfoData.location2Position.value
                    ? "clockPosition-select"
                    : "placeHolder"
                }
                selectClassName={
                  woundInfoData.location2Position.value
                    ? "clockPosition-input"
                    : "placeHolder"
                }
                testId="test-location2Position-dropdown"
                value={
                  woundInfoData?.location2Position.value
                    ? getTextFromCode(
                        positionDropDownData,
                        woundInfoData?.location2Position.value
                      )
                    : null
                }
              />
            </InputWithLabel>
          </Grid>
          <Grid item xs={isMobileScreen ? 2 : 0.5}>
            <div className="clockText" data-testid="oclock-second">
              <p className="text">o’clock</p>
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default TunnelingDetails;
