import { format } from "react-string-format";
import "./createServiceRequestContainer.css";
import {
  RolesPermissionContext,
  RolesPermissionContextType,
} from "../../../context/RolesPermissionContext";
import {
  IInputField,
  ValidationStatus,
} from "../../../core/interfaces/input.interface";
import {
  CreateServiceRequestContext,
  CreateServiceRequestContextType,
} from "../../../context/CreateServiceRequestContext";
import ErrorPopup, {
  IErrorPopUp,
  defaultErrorPopUp,
} from "../../../core/errorPopup/errorPopup.component";
import { useHistory, useLocation } from "react-router-dom";
import { Popup } from "../../../core/popup/popup.component";
import { DD_SERVICE_OPTIONS } from "../../../util/staticText";
import { ReactNode, useContext, useMemo, useState } from "react";
import { getDropDownContent } from "../../../util/dropDownService";
import { LoadingSpinner } from "../../../core/loader/LoadingSpinner";
import {
  LOGGED_IN_USERNAME_FAILURE,
  NO_DROPDOWN_DATA_AVAIALABLE,
  REGISTERED_FACILITY_OBJ_FROM_AUTH_CONTEXT_FAILURE,
  SITEUSEID_FROM_REGISTERED_FACILITY_OBJ_FAILURE,
} from "../../../util/errorCode";
import {
  ERROR_MSG_ACUTE_ORDER_VIEW,
  FETCH_SERVICE_OPTIONS_FAILED,
  STORAGE_LOCATION_LOAD_SERVICE_ERROR,
  SUBMIT_CREATE_SERVICE_REQUEST_FAILED,
  SVC_SERVICE_ALREADY_REQUESTED,
} from "../../../util/errorMsg";
import { CreateServiceRequestPageSection } from "./createServiceRequest.enum";
import { CreateServiceRequestValidator } from "./createServiceRequest.validator";
import { ReviewServiceRequest } from "./reviewServiceRequest/reviewServiceRequest.component";
import { CreateServiceRequest } from "./createServiceRequest/createServiceRequest.component";
import { IDropdownValue } from "./createServiceRequest/serviceOptions/serviceOptions.component";
import { ServiceRequestSummary } from "./serviceRequestSummary/serviceRequestSummary.component";
import { ICustomerPlusListingDetails } from "../customerPlusInventory/customerPlusInventory.interface";
import { CreateServiceRequestFooterButtonGroup } from "./footerButtonGroup/createServiceRequestFooterButtonGroup.component";
import { AuthContext, AuthContextType } from "../../../context/AuthContext";
import {
  formatPhoneNumberWithoutSpecialChar,
  getSiteUseId,
} from "../../../util/utilityFunctions";
import {
  getFacilityStorageLocation,
  submitCreateServiceRequest,
} from "../../../util/inventoryMgrService";
import { IRouter } from "../../helpAndSupport/Navigator/navigator.interface";
import {
  inventoryNavigatorArray,
  mpdNavigatorArray,
} from "./createServiceRequest.model";
import { IStorageLocation } from "../popUpContainer/inventoryPopUpContainer.interface";
import { IPatient } from "../../myPatients/patient.interface";
import { ICreateServiceRequest } from "./createServiceRequest.interface";
import { getAcuteOrderRoDetails } from "../../../util/actueService";

interface IContactDetails {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  department: string;
}

export const CreateServiceRequestContainer = () => {
  const history = useHistory();
  const location: any = useLocation();
  const [error, setError] = useState<IErrorPopUp>(defaultErrorPopUp);
  const selectedProduct: ICustomerPlusListingDetails = location.state.product;
  const isSolventumProduct: boolean = location.state.isSolventumProduct;
  const patientData: IPatient = location.state.patientData ?? {};
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [isToShowErrorCode, setIsToShowErrorCode] = useState<boolean>(true);
  const [isToShowPhoneNumber, setIsToShowPhoneNumber] = useState<boolean>(true);
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const createServiceRequestObj =
    useContext<CreateServiceRequestContextType | null>(
      CreateServiceRequestContext
    );
  const storageLocations = createServiceRequestObj!.storageLocations;
  const setStorageLocations = createServiceRequestObj!.setStorageLocations;
  const ropnValue = createServiceRequestObj!.ropnValue;
  const setRopnValue = createServiceRequestObj!.setRopnValue;
  const initialLoadTriggered = createServiceRequestObj!.initialLoadTriggerd;
  const setInitialLoadTriggered =
    createServiceRequestObj!.setInitialLoadTriggerd;
  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );

  const fetchDropDownContent = async () => {
    const ddContent = format("{0}", DD_SERVICE_OPTIONS);
    const response = await getDropDownContent(ddContent);
    if (response && response.succeeded && response.items.length > 0) {
      const availableOptionsObject = response.items.filter(
        (item: { name: string }) => item.name === DD_SERVICE_OPTIONS
      );
      const availableOptions = availableOptionsObject[0].data.sort(
        (a: { order: number }, b: { order: number }) =>
          a.order > b.order ? 1 : -1
      );
      createServiceRequestObj!.setAvailableOptions(availableOptions);
    } else {
      let code;
      if (response && response.items && response.items.length === 0) {
        code = NO_DROPDOWN_DATA_AVAIALABLE;
      } else {
        code =
          response?.error?.code ||
          response?.error?.errorCode ||
          response?.status;
      }
      const localError: IErrorPopUp = {
        errorActionType: "dropdown",
        errorCode: code,
        errorFlag: true,
        errorMessage: FETCH_SERVICE_OPTIONS_FAILED,
      };
      setError(localError);
    }
  };

  const submitCreateServiceRequestAPI = async () => {
    setShowLoader(true);
    const data = createServiceRequestObj!.data;
    const siteUseId = getSiteUseId(authObj);
    const contactData = getContactDetails();
    if (
      authObj &&
      authObj.userProfile &&
      authObj.userProfile.userName &&
      authObj.userProfile.userName !== "" &&
      authObj.registeredFaciltyAddress &&
      siteUseId !== "" &&
      contactData
    ) {
      const pickupLocation =
        data.pickupLocation.value.toLowerCase() === "other location"
          ? `Other Location - ${data.otherPickupLocation.value}`
          : data.pickupLocation.value;
      const request = {
        appVersion: process.env.REACT_APP_VERSION,
        callerDepartment: contactData.department.trim(),
        customerAccountNumber: authObj.registeredFaciltyAddress.accountNumber,
        deliveryInstructions:
          pickupLocation + " " + data.specialInstrutions.value.trim(),
        describeProblemOrInjurywith3m: data.describeTheProblem.value.trim(),
        employeeId:
          authObj.userProfile.employeeID &&
          authObj.userProfile.employeeID !== ""
            ? authObj.userProfile.employeeID
            : "INTERNET",
        estimatedArrivalTime: data.isCallOnEstimatedArrivalTime.value === "yes",
        facilityName: authObj.registeredFaciltyAddress.accountName,
        medicalEvent: data.injuryCauseBy3MDevice.value,
        patientFirstName: selectedProduct.patientFirstName,
        patientLastName: selectedProduct.patientLastName,
        product: selectedProduct.productNameDescription ?? "",
        productProblem: data.problemWith3MDevice.value,
        productUsed: selectedProduct.productName,
        reqShipAdd1: authObj.registeredFaciltyAddress.address1,
        reqShipAdd2: authObj.registeredFaciltyAddress.address2,
        reqShipCity: authObj.registeredFaciltyAddress.city,
        reqShipState: authObj.registeredFaciltyAddress.state,
        reqShipZipCode: authObj.registeredFaciltyAddress.zip,
        roNum: selectedProduct.roNumber,
        ropn: selectedProduct.ropn ?? ropnValue,
        serialNumber: selectedProduct.serialNumber,
        serviceOption: data.serviceOptions.map(
          (option: IInputField) => option.value
        ),
        serviceCenter: authObj.registeredFaciltyAddress.customerServiceCode,
        shipAdd1: authObj.registeredFaciltyAddress.address1,
        shipAdd2: authObj.registeredFaciltyAddress.address2,
        shipCity: authObj.registeredFaciltyAddress.city,
        shipState: authObj.registeredFaciltyAddress.state,
        shipZipCode: authObj.registeredFaciltyAddress.zip,
        siteUseId: siteUseId,
        unitTagId: selectedProduct.unitTagId,
        userEmail: contactData.email,
        userFname: contactData.firstName.trim(),
        userLName: contactData.lastName.trim(),
        userName: authObj.userProfile.userName,
        userPhone: contactData.phone,
      };
      const response = await submitCreateServiceRequest(request);
      setShowLoader(false);
      if (response && response.succeeded) {
        const workOrderNumberResponse = response.item.workOrderNumber;
        createServiceRequestObj?.setWorkOrderNumber(workOrderNumberResponse);
        window.scrollTo(0, 0);
        createServiceRequestObj?.setPage(
          CreateServiceRequestPageSection.SERVICE_REQUEST_SUMMARY
        );
      } else {
        let errorCode =
          response?.error?.code ||
          response?.error?.errorCode ||
          response?.status;
        let localError: IErrorPopUp;
        if (response && errorCode.toString() === "2069") {
          setIsToShowErrorCode(false);
          setIsToShowPhoneNumber(false);
          localError = {
            errorCode: "2069",
            errorFlag: true,
            errorMessage: SVC_SERVICE_ALREADY_REQUESTED,
          };
        } else {
          localError = {
            errorCode: errorCode,
            errorFlag: true,
            errorMessage: SUBMIT_CREATE_SERVICE_REQUEST_FAILED,
          };
        }
        setError(localError);
      }
    } else {
      let code;
      if (!authObj || (authObj && !authObj.registeredFaciltyAddress)) {
        code = REGISTERED_FACILITY_OBJ_FROM_AUTH_CONTEXT_FAILURE;
      } else if (siteUseId === "") {
        code = SITEUSEID_FROM_REGISTERED_FACILITY_OBJ_FAILURE;
      } else {
        code = LOGGED_IN_USERNAME_FAILURE;
      }
      const localError = {
        errorCode: code,
        errorFlag: true,
        errorMessage: SUBMIT_CREATE_SERVICE_REQUEST_FAILED,
      };
      setError(localError);
    }
  };

  const getContactDetails = (): IContactDetails | null => {
    const data = createServiceRequestObj!.data;
    if (data.isCallOnEstimatedArrivalTime.value === "yes") {
      const phoneString = formatPhoneNumberWithoutSpecialChar(
        data.phoneNumber.value
      );
      return {
        firstName: data.firstName.value,
        lastName: data.lastName.value,
        phone: phoneString,
        email: data.email!.value,
        department: data.department.value,
      };
    } else {
      if (authObj && authObj.userProfile) {
        const profile = authObj.userProfile;
        const phoneValue =
          profile.phoneNumber && profile.phoneNumber !== ""
            ? profile.phoneNumber.slice(-10)
            : profile.mobilePhoneNumber.slice(-10);
        return {
          firstName: profile.firstName,
          lastName: profile.lastName,
          phone: phoneValue,
          email: profile.emailAddress,
          department: profile.departmentName,
        };
      }
      return null;
    }
  };

  const checkForPreviousButtonText = (): string => {
    return createServiceRequestObj?.page !==
      CreateServiceRequestPageSection.CREATE_SERVICE_REQUEST
      ? "Previous"
      : "";
  };

  const checkForNextButtonText = (): string => {
    return createServiceRequestObj?.page ===
      CreateServiceRequestPageSection.REVIEW_SERVICE_REQUEST
      ? "Submit Request"
      : "Continue";
  };

  const checkErroredFieldAndFocusOnInputField = async (
    errorField: IInputField
  ) => {
    if (errorField.componentId && errorField.componentId !== "") {
      const scrollableComponent = document.getElementById(
        errorField.componentId
      );
      await setTimeout(() => {
        if (scrollableComponent) {
          scrollableComponent.scrollIntoView({
            behavior: "smooth",
          });
          if (errorField.id && errorField.id !== "") {
            const autoCursorComponentId = document.getElementById(
              errorField.id
            );
            setTimeout(() => {
              if (autoCursorComponentId) {
                autoCursorComponentId.focus();
              }
            }, 500);
          }
        }
      }, 500);
    }
  };

  const cancelButtonAction = () => {
    createServiceRequestObj?.resetContext();
    history.goBack();
  };

  const previousButtonAction = () => {
    switch (createServiceRequestObj?.page) {
      case CreateServiceRequestPageSection.REVIEW_SERVICE_REQUEST:
        window.scrollTo(0, 0);
        createServiceRequestObj?.setPage(
          CreateServiceRequestPageSection.CREATE_SERVICE_REQUEST
        );
        break;
      case CreateServiceRequestPageSection.SERVICE_REQUEST_SUMMARY:
        window.scrollTo(0, 0);
        createServiceRequestObj?.setPage(
          CreateServiceRequestPageSection.REVIEW_SERVICE_REQUEST
        );
        break;
      default:
        break;
    }
  };

  const nextButtonAction = () => {
    switch (createServiceRequestObj?.page) {
      case CreateServiceRequestPageSection.CREATE_SERVICE_REQUEST:
        const validator = new CreateServiceRequestValidator();
        const [isAllValid, errorField] = validator.validateAll(
          createServiceRequestObj!.data,
          createServiceRequestObj!.setData
        );
        if (errorField) {
          checkErroredFieldAndFocusOnInputField(errorField);
        }
        if (isAllValid === ValidationStatus.VALID) {
          window.scrollTo(0, 0);
          createServiceRequestObj?.setPage(
            CreateServiceRequestPageSection.REVIEW_SERVICE_REQUEST
          );
        }
        break;
      case CreateServiceRequestPageSection.REVIEW_SERVICE_REQUEST:
        submitCreateServiceRequestAPI();
        break;
      default:
        break;
    }
  };

  const editButtonAction = (sectionId: string) => {
    if (sectionId !== "") {
      window.scrollTo(0, 0);
      createServiceRequestObj?.setPage(
        CreateServiceRequestPageSection.CREATE_SERVICE_REQUEST
      );
      setTimeout(() => {
        const scrollableComponent = document.getElementById(sectionId);
        if (scrollableComponent) {
          scrollableComponent.scrollIntoView({
            behavior: "smooth",
          });
        }
      }, 500);
    } else {
      // This is for edit button on VAC Therapy Unit.
      // User need to navigate back to inventory page.
      history.goBack();
    }
  };

  const closePopUpButtonAction = async (isForDropdown: boolean) => {
    setError(defaultErrorPopUp);
    setIsToShowErrorCode(true);
    setIsToShowPhoneNumber(true);
    if (isForDropdown) {
      history.goBack();
    }
  };

  const getStorageLocationsMenuOptions = async () => {
    const request = {
      siteUseId: getSiteUseId(authObj),
      userName: authObj && authObj.userProfile?.userName,
    };
    const response = await getFacilityStorageLocation(request);
    if (response && response.succeeded) {
      const allLocations: IStorageLocation[] = response.items;
      const filteredResult: IStorageLocation[] = allLocations.filter(
        (location: IStorageLocation) => location.isEnable
      );
      const locations = filteredResult.map((location) => location.locationName);
      setStorageLocations([...locations, "Other Location"]);
    } else {
      setShowLoader(false);
      const localError: IErrorPopUp = {
        errorCode:
          response?.error?.errorCode ||
          response?.error?.code ||
          response?.status,
        errorFlag: true,
        errorMessage: STORAGE_LOCATION_LOAD_SERVICE_ERROR,
      };
      setError(localError);
    }
  };

  const getAcuteOrderDetails = async () => {
    if (
      patientData &&
      patientData.roNumber &&
      authObj &&
      authObj.userProfile &&
      authObj.userProfile.userName &&
      authObj.userProfile.userName !== "" &&
      authObj.registeredFaciltyAddress &&
      authObj.registeredFaciltyAddress.siteUseId &&
      authObj.registeredFaciltyAddress.siteUseId !== ""
    ) {
      let reqParams = {
        RentalOrderNumber: patientData.roNumber,
        userName: authObj.userProfile.userName,
        siteUseId: authObj.registeredFaciltyAddress.siteUseId,
      };
      const AcuteOrderDetailsResponse = await getAcuteOrderRoDetails(reqParams);
      if (AcuteOrderDetailsResponse && AcuteOrderDetailsResponse.succeeded) {
        const ropn = AcuteOrderDetailsResponse.item.ropn;
        setRopnValue(ropn);
      } else {
        setShowLoader(false);
        let localError: IErrorPopUp = {
          errorCode:
            AcuteOrderDetailsResponse.error.errorCode ||
            AcuteOrderDetailsResponse.error.code ||
            AcuteOrderDetailsResponse.status,
          errorFlag: true,
          errorMessage: ERROR_MSG_ACUTE_ORDER_VIEW,
        };
        setError(localError);
      }
    }
  };

  const initialRequiredApiCall = async (collection: []) => {
    setShowLoader(true);
    await Promise.all(collection);
    setShowLoader(false);
  };

  useMemo(() => {
    if (!initialLoadTriggered) {
      let apiCollection: any = [];
      if (createServiceRequestObj!.availableOptions.length === 0) {
        apiCollection.push(fetchDropDownContent());
      }
      if (isSolventumProduct) {
        apiCollection.push(
          getStorageLocationsMenuOptions(),
          getAcuteOrderDetails()
        );
        let tempData: ICreateServiceRequest = createServiceRequestObj!.data;
        tempData.pickupLocation = {
          ...tempData.pickupLocation,
          value: "",
          required: true,
          valid: ValidationStatus.UNTOUCHED,
        };
        createServiceRequestObj!.setData(tempData);
      }
      if (apiCollection.length > 0) {
        initialRequiredApiCall(apiCollection);
      }
      setInitialLoadTriggered(true);
    }
  }, []);

  const getNavigatorForPageSections = (): IRouter[] => {
    let navigatorsArr: IRouter[] = [];
    switch (createServiceRequestObj!.page) {
      case CreateServiceRequestPageSection.CREATE_SERVICE_REQUEST:
      case CreateServiceRequestPageSection.REVIEW_SERVICE_REQUEST:
        if (isSolventumProduct) {
          navigatorsArr = mpdNavigatorArray;
        } else {
          navigatorsArr = inventoryNavigatorArray;
        }
    }
    return navigatorsArr;
  };

  const navigatorForPageSections = getNavigatorForPageSections();

  const sectionToDisplay = () => {
    let page: ReactNode;
    switch (createServiceRequestObj!.page) {
      case CreateServiceRequestPageSection.CREATE_SERVICE_REQUEST:
        page = (
          <CreateServiceRequest
            availableOptions={createServiceRequestObj!.availableOptions}
            data={createServiceRequestObj!.data}
            setAvailableOptions={createServiceRequestObj!.setAvailableOptions}
            setData={createServiceRequestObj!.setData}
            selectedProduct={selectedProduct}
            navigators={navigatorForPageSections}
            isSolventumOrder={isSolventumProduct}
            storageLocations={storageLocations}
            patientData={patientData}
            pageSection={createServiceRequestObj!.page}
          />
        );
        break;
      case CreateServiceRequestPageSection.REVIEW_SERVICE_REQUEST:
        page = (
          <ReviewServiceRequest
            availableOptions={createServiceRequestObj!.availableOptions}
            data={createServiceRequestObj!.data}
            editButtonAction={editButtonAction}
            isReviewRequest={true}
            setAvailableOptions={createServiceRequestObj!.setAvailableOptions}
            setData={createServiceRequestObj!.setData}
            selectedProduct={selectedProduct}
            navigators={navigatorForPageSections}
            isSolventumOrder={isSolventumProduct}
            patientData={patientData}
          />
        );
        break;
      case CreateServiceRequestPageSection.SERVICE_REQUEST_SUMMARY:
        page = (
          <ServiceRequestSummary
            availableOptions={createServiceRequestObj!.availableOptions}
            data={createServiceRequestObj!.data}
            editButtonAction={editButtonAction}
            isReviewRequest={true}
            isReviewSummary={true}
            setAvailableOptions={createServiceRequestObj!.setAvailableOptions}
            setData={createServiceRequestObj!.setData}
            selectedProduct={selectedProduct}
            workOrderNumber={createServiceRequestObj!.workOrderNumber}
            isSolventumOrder={isSolventumProduct}
            patientData={patientData}
          />
        );
        break;
    }
    return page;
  };

  return (
    <>
      {sectionToDisplay()}
      {createServiceRequestObj?.page !==
        CreateServiceRequestPageSection.SERVICE_REQUEST_SUMMARY && (
        <CreateServiceRequestFooterButtonGroup
          firstButtonAction={cancelButtonAction}
          firstButtonDisabled={false}
          firstButtonTitle="Cancel"
          secondButtonAction={previousButtonAction}
          secondButtonDisabled={false}
          secondButtonTitle={checkForPreviousButtonText()}
          thirdButtonAction={nextButtonAction}
          thirdButtonDisabled={
            (permissionObj &&
              permissionObj.mappedRolesPermissionData.IsSupportRole) ??
            false
          }
          thirdButtonTitle={checkForNextButtonText()}
        />
      )}
      <Popup
        closeHandler={() => setShowLoader(false)}
        dialogParentClass={"create-service-request-container-loader-pop-up"}
        hideCloseButton={true}
        openFlag={showLoader}
      >
        <div className="create-service-request-container-loader-pop-up-div">
          <LoadingSpinner />
        </div>
      </Popup>
      <ErrorPopup
        buttontitle="Done"
        errorCode={error.errorCode}
        errorPopupFlag={error.errorFlag}
        errorMessage={error.errorMessage}
        isShortSentence={true}
        handleBackButton={() => {
          const isForDropdown =
            (error.errorActionType !== undefined &&
              error.errorActionType === "dropdown") ??
            false;
          closePopUpButtonAction(isForDropdown);
        }}
        popUpStyles="errorPopup"
        phone="(800) 275-4524 ext 41858."
        showTitle={false}
        isSupportPhoneRequired={isToShowPhoneNumber}
        isErrorCodeVisible={isToShowErrorCode}
      />
    </>
  );
};
