import { useContext, useState } from "react";
import "./facilityBaseUserRole.css";
import Icons from "../../../assets/Icons.jpg";
import { PageContext } from "../../page/page.context";
import { Grid, Box, Typography } from "@mui/material";
import { MobileDisplayContext } from "../../../context/MobileDisplayContext";

export const FacilityBaseUserRole = () => {
  const techSupport = useContext(PageContext);
  const mobileContext = useContext(MobileDisplayContext)
  const {isMobileScreen} = mobileContext
  

  return (
    
    <div className="facilityBaseUserRole"   data-testid="facilityBaseUserRole">
      <div className="facilityimg" data-testid="facilityimg">
        <img src={Icons} alt={Icons}></img>
      </div>

      
      <Grid className="baserole-desc-container base-role-grid-container" container >
        <Grid item>
            <div className="baseroletitle" data-testid="baseroletitle">
              <Typography variant="h5" fontSize={isMobileScreen ? 25 : 28}>
                Your account is not associated
              </Typography>
              <Typography variant="h5" fontSize={isMobileScreen ? 25 : 28}>
                with a facility in 3M Express
              </Typography>
            </div>
        </Grid>
        <Grid item xs={isMobileScreen ? 0: 8.5} >
          <div className="baseroledesc" data-testid="baseroledesc">
              <Typography className="base-role-desc-text" fontSize={isMobileScreen ? 16: 18}>
                If you've already applied to be associated with a facility, 
                please note that it can take 1-3 business days to complete. 
                If it has been longer than that, please contact our National Contact
              </Typography>
              <div className="sub-sections">
                Center at
                <span className="contact-type" data-testid="phone1">
                  {" "}
                </span>
                <a
                className="contact-values"
                href={`tel:${techSupport.techRepContacts?.phoneNo}`}
                >
                  {techSupport.techRepContacts?.phoneNo + "."}
                </a>
            </div>
          </div>  
        </Grid>
      </Grid>
    </div>
    
  );
};
