import { Button, Grid } from "@mui/material";
import "./endTherapySuccessPopUp.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { ICustomerPlusListingDetails } from "../../../customerPlusInventory/customerPlusInventory.interface";
import {
  STATIC_TEXT_END_THERAPY_SUCCESS,
  STATIC_TEXT_MAKE_UNIT_AVAILABLE_SUCCESS,
} from "../../../../../util/staticText";
import {
  convertStringToDate,
  makeCapitalEachWordInString,
} from "../../../../../util/utilityFunctions";

interface IEndTherapySuccessProps {
  closePopUp: Function;
  selectedProduct: ICustomerPlusListingDetails;
  isComingFromMakeUnitAvailable?: boolean;
}

export const EndTherapySuccess = ({
  closePopUp,
  selectedProduct,
  isComingFromMakeUnitAvailable = false,
}: IEndTherapySuccessProps) => {
  const formattedStartDate =
    selectedProduct.therapyStartDate !== null ||
    selectedProduct.therapyStartDate !== ""
      ? convertStringToDate(selectedProduct.therapyStartDate)
      : "--";
  const formattedEndDate =
    selectedProduct.therapyEndDate !== null ||
    selectedProduct.therapyEndDate !== ""
      ? convertStringToDate(selectedProduct.therapyEndDate)
      : "--";
  const dateAvailableforPlacement =
    selectedProduct.availableDate !== null ||
    selectedProduct.availableDate !== ""
      ? convertStringToDate(selectedProduct.availableDate)
      : "--";
  const product = `${selectedProduct.productNameDescription} - ${selectedProduct.serialNumber}`;
  return (
    <div className="end-therapy-success-component">
      <p
        className="title"
        data-testid="end-therapy-success-title"
        id="title-id"
      >
        Status Updated
      </p>
      <Grid className="grid-container" container>
        <Grid className="grid-item" item xs={12}>
          <div className="success">
            <CheckCircleIcon className="success-icon" />
            <p
              className="success-text"
              data-testid="end-therapy-success-desc"
              id="success-text-id"
            >
              {isComingFromMakeUnitAvailable
                ? STATIC_TEXT_MAKE_UNIT_AVAILABLE_SUCCESS
                : STATIC_TEXT_END_THERAPY_SUCCESS}
            </p>
          </div>
        </Grid>
        <Grid
          className="grid-item top-space"
          data-testid="end-therapy-success-unit-tag-id"
          item
          xs={6}
        >
          <div className="product-details">
            <p className="product-detail-title">Unit Tag ID</p>
            <p className="product-detail-value">
              {makeCapitalEachWordInString(selectedProduct.unitTagId)}
            </p>
          </div>
        </Grid>
        <Grid
          className="grid-item top-space"
          data-testid="end-therapy-success-serial-number"
          item
          xs={6}
        >
          <div className="product-details productSpace">
            <p className="product-detail-title">Product</p>
            <p className="product-detail-value">{product}</p>
          </div>
        </Grid>
        {!isComingFromMakeUnitAvailable && (
          <Grid
            className="grid-item top-space"
            data-testid="end-therapy-success-start-date"
            item
            xs={6}
          >
            <div className="product-details">
              <p className="product-detail-title">Therapy Start Date</p>
              <p className="product-detail-value">{formattedStartDate}</p>
            </div>
          </Grid>
        )}
        <Grid
          className="grid-item top-space"
          data-testid="end-therapy-success-end-date"
          item
          xs={6}
        >
          <div
            className={`product-details ${
              isComingFromMakeUnitAvailable ? "" : "productSpace"
            }`}
          >
            <p className="product-detail-title">Therapy End Date</p>
            <p className="product-detail-value">{formattedEndDate}</p>
          </div>
        </Grid>
        {isComingFromMakeUnitAvailable && (
          <Grid
            className="grid-item top-space"
            data-testid="end-therapy-success-start-date"
            item
            xs={6}
          >
            <div className="product-details productSpace">
              <p className="product-detail-title">
                Date Available For Placement
              </p>
              <p className="product-detail-value">
                {dateAvailableforPlacement}
              </p>
            </div>
          </Grid>
        )}
        <Grid
          className="grid-item top-space"
          data-testid="end-therapy-success-storage-location"
          item
          xs={12}
        >
          <div className="product-details">
            <p className="product-detail-title">
              {isComingFromMakeUnitAvailable
                ? "Storage Location"
                : "Return to Storage Location"}
            </p>
            <p className="product-detail-value">
              {selectedProduct.storageLocation}
            </p>
          </div>
        </Grid>
        <Grid className="grid-item top-space" xs={12}>
          <Button
            classes={{ root: "return-to-inventory-button" }}
            data-testid="end-therapy-return-to-inventory-button"
            onClick={() => closePopUp()}
            variant="contained"
          >
            Done
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
