import { createContext, useState } from "react";
import { ManageUserAccountsTabs } from "../components/administration/manageUserAccounts/manageUserAccounts.enum";
import { IManageUserAccounts } from "../components/administration/manageUserAccounts/manageUserAccounts.interface";
import { getDeepClone } from "../util/ObjectFunctions";
import { defaultManageUserAccountsData } from "../components/administration/manageUserAccounts/manageUserAccounts.model";

export type ManageUserAccountsContextType = {
  data: IManageUserAccounts;
  setData: React.Dispatch<React.SetStateAction<IManageUserAccounts>>;
  selectedManageUserAccountsTab: string | null;
  setSelectedManageUserAccountsTab: React.Dispatch<
    React.SetStateAction<string | null>
  >;
  resetContextData: () => void;
  resetData: () => void;
  resetTab: () => void;
};

type ManageUserAccountsContextProviderProps = {
  children: React.ReactNode;
};

export const ManageUserAccountsContext =
  createContext<ManageUserAccountsContextType | null>(null);

export const ManageUserAccountsContextProvider = ({
  children,
}: ManageUserAccountsContextProviderProps) => {
  const [data, setData] = useState<IManageUserAccounts>(
    getDeepClone(defaultManageUserAccountsData)
  );
  const [selectedManageUserAccountsTab, setSelectedManageUserAccountsTab] =
    useState<string | null>(ManageUserAccountsTabs.USERS);

  const resetContextData = () => {
    resetData();
    resetTab();
  };

  const resetData = () => {
    setData(getDeepClone(defaultManageUserAccountsData));
  };

  const resetTab = () => {
    setSelectedManageUserAccountsTab(ManageUserAccountsTabs.USERS);
  };

  return (
    <ManageUserAccountsContext.Provider
      value={{
        data,
        setData,
        selectedManageUserAccountsTab,
        setSelectedManageUserAccountsTab,
        resetContextData,
        resetData,
        resetTab,
      }}
    >
      {children}
    </ManageUserAccountsContext.Provider>
  );
};
