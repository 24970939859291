export let mockSaleManageEditUsersData: any = {
  name: "Thompson",
  emailAddress: "Thompson@mmm.com",
  contactPhoneNo: "0123456789",
  territories: [
    {
      roleType: "PSS",
      regionCode: "R402",
      territoryCode: "PSS1134",
      territoryName: "Houston W TX",
      isPrimary: false,
    },
  ],
};
