import { format } from "react-string-format";
import {
  SVC_ADD_SITE_STATUS,
  SVC_GET_APPLICATION_SITE_STATUSES,
  SVC_GET_SITE_STATUSES,
  SVC_UPDATE_SITE_STATUS,
} from "./staticText";
import { getKeyData } from "./encryptDecrypt";

export const getSiteStatuses = async () => {
  try {
    const authDetails = sessionStorage.getItem("okta-token-storage");
    const data = JSON.parse(authDetails ?? "");
    const accessToken = data.accessToken?.accessToken;
    const userName = data.idToken?.claims?.preferred_username;
    const authorizationToken = format("Bearer {0}", accessToken);
    const baseUrl = process.env.REACT_APP_SITE_STATUS_FUNC_BASE_URL ?? "";
    const siteStatusesUrl = format("{0}{1}", baseUrl, SVC_GET_SITE_STATUSES);
    const funckeyParse = getKeyData();
    const response = await fetch(siteStatusesUrl, {
      method: "POST",
      headers: {
        Authorization: authorizationToken,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "x-functions-key": funckeyParse.SiteAccessFunction ?? "",
      },
      body: JSON.stringify({
        userName: userName,
      }),
    });
    if (response.status === 200) {
      return response.json();
    }
    return response;
  } catch (error) {
    console.log("error", error);
  }
};

export const getApplicationSiteStatus = async () => {
  try {
    const baseUrl = process.env.REACT_APP_SITE_STATUS_FUNC_BASE_URL ?? "";
    const siteStatusesUrl = format(
      "{0}{1}",
      baseUrl,
      SVC_GET_APPLICATION_SITE_STATUSES
    );
    const funckeyParse = getKeyData();
    const response = await fetch(siteStatusesUrl, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "x-functions-key": funckeyParse.SiteAccessFunction ?? "",
      },
    });
    if (response.status === 200) {
      return response.json();
    }
    return response;
  } catch (error) {
    console.log("error", error);
  }
};

export const addSiteStatus = async (reqParams: any) => {
  try {
    const authDetails = sessionStorage.getItem("okta-token-storage");
    const data = JSON.parse(authDetails ?? "");
    const accessToken = data.accessToken?.accessToken;
    const authorizationToken = format("Bearer {0}", accessToken);
    const baseUrl = process.env.REACT_APP_SITE_STATUS_FUNC_BASE_URL ?? "";
    const addSiteStatusUrl = format("{0}{1}", baseUrl, SVC_ADD_SITE_STATUS);
    const funckeyParse = getKeyData();
    const response = await fetch(addSiteStatusUrl, {
      method: "POST",
      headers: {
        Authorization: authorizationToken,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "x-functions-key": funckeyParse.SiteAccessFunction ?? "",
      },
      body: JSON.stringify(reqParams),
    });
    if (response.status === 200) {
      return response.json();
    }
    return response;
  } catch (error) {
    console.log("error", error);
  }
};

export const updateSiteStatus = async (reqParams: any) => {
  try {
    const authDetails = sessionStorage.getItem("okta-token-storage");
    const data = JSON.parse(authDetails ?? "");
    const accessToken = data.accessToken?.accessToken;
    const authorizationToken = format("Bearer {0}", accessToken);
    const baseUrl = process.env.REACT_APP_SITE_STATUS_FUNC_BASE_URL ?? "";
    const updateSiteStatusUrl = format(
      "{0}{1}",
      baseUrl,
      SVC_UPDATE_SITE_STATUS
    );
    const funckeyParse = getKeyData();
    const response = await fetch(updateSiteStatusUrl, {
      method: "POST",
      headers: {
        Authorization: authorizationToken,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "x-functions-key": funckeyParse.SiteAccessFunction ?? "",
      },
      body: JSON.stringify(reqParams),
    });
    if (response.status === 200) {
      return response.json();
    }
    return response;
  } catch (error) {
    console.log("error", error);
  }
};
