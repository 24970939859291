import {
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  useMediaQuery,
} from "@mui/material";
import "./inpatientTransition.css";
import {
  convertStringToDate,
  getCodeFromText,
  getTextFromCode,
} from "../../../util/utilityFunctions";
import {
  NewOrderContext,
  NewOrderContextType,
} from "../../../context/NewOrderContext";
import { format } from "react-string-format";
import { useContext, useEffect, useState } from "react";
import { NewOrderValidator } from "../newOrder.validator";
import { Popup } from "../../../core/popup/popup.component";
import { Validator } from "../../../util/order.validations";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { getdropDownContent } from "../../../util/dropDownService";
import { DD_REASON_FOR_TRANSITION } from "../../../util/staticText";
import { IInpatientTransition } from "./inpatientTransition.interface";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AuthContext, AuthContextType } from "../../../context/AuthContext";
import { ValidationStatus } from "../../../core/interfaces/input.interface";
import { ReactComponent as CalendarIcon } from "../../../assets/calendar.svg";
import { ReactComponent as RadioButtonIcon } from "../../../assets/radioButton.svg";
import { CustomDropDown } from "../../../core/customDropdown/customDropdown.component";
import { InputWithLabel } from "../../../core/inputWithLabel/inputWithLabel.component";
import { IFacility } from "../../manageProfile/facilityInformation/facility.interface";
import { ReactComponent as SelectedRadioButtonIcon } from "../../../assets/selectedRadioButton.svg";
import { InpatientTransitionReviewOrder } from "./reviewOrder/inpatientTransitionReviewOrder.component";
import { FacilityInfoDetail } from "../../manageProfile/facilityInformation/facilityInfoDetail.component";
import { AddFacilityContext } from "../../manageProfile/facilityInformation/addFacilityContainer/addFacilityContainer.context";
import { AddFacilityContainer } from "../../manageProfile/facilityInformation/addFacilityContainer/addFacilityContainer.component";
import {
  InternalSignOnContext,
  InternalSignOnContextType,
} from "../../../context/InternalSignOnContext";

export const InpatientTransition = ({
  data,
  newValidator = new NewOrderValidator(),
  setData,
  isReviewOrder = false,
  editButtonClicked,
  isOrderSummary = false,
  isTransitionOrder = false,
}: IInpatientTransition) => {
  const [focusClasses, setFocusClasses] = useState({ dateInitiated: "" });
  const [validator] = useState<NewOrderValidator>(newValidator!);
  const [facility, setFacility] = useState<IFacility>();
  const newOrderObj = useContext<NewOrderContextType | null>(NewOrderContext);
  const internalObj = useContext<InternalSignOnContextType | null>(
    InternalSignOnContext
  );
  const isMobileScreen = useMediaQuery("(max-width:920px)");
  const [open, setOpen] = useState<boolean>(false);
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const [reasonForTransitionText, setReasonForTransitionText] = useState([]);
  const [reasonForTransition, setReasonForTransition] = useState([]);

  const fetchDropDownContent = async () => {
    try {
      const ddContent = format("{0}", DD_REASON_FOR_TRANSITION);
      const data = await getdropDownContent(ddContent);
      if (data.items.length > 0) {
        const reasonObject = data.items.filter(
          (item: { name: string }) => item.name === DD_REASON_FOR_TRANSITION
        );
        const reasonData = reasonObject[0].data.sort(
          (a: { order: number }, b: { order: number }) =>
            a.order > b.order ? 1 : -1
        );
        setReasonForTransition(reasonData);
        setReasonForTransitionText(
          reasonData.map((x: { text: string }) => x.text)
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const validateAndSetData = (e: any) => {
    newOrderObj?.setIsHandleChangeTriggered(true);
    const { name, value } = e.target;
    const isValid = validator.validate(value, name);
    if (name === "wasNPWTInitiated") {
      if (value !== data.wasNPWTInitiated.value) {
        data.dateInitiated = {
          ...data.dateInitiated,
          errorMessage: null,
          valid: ValidationStatus.UNTOUCHED,
          value: "",
        };
      }
      setData({
        ...data,
        [name]: {
          ...Object(data)[name],
          errorMessage: null,
          valid: isValid!.status,
          value: value,
        },
        dateInitiated: {
          ...data.dateInitiated,
          errorMessage: null,
          required: value === "yes" ? true : false,
          valid:
            value === "yes"
              ? ValidationStatus.UNTOUCHED
              : ValidationStatus.VALID,
          value: "",
        },
        reasonForTransition: {
          ...data.reasonForTransition,
          errorMessage: null,
          required: value === "yes" ? true : false,
          valid: ValidationStatus.VALID,
          value: "1",
        },
      });
    }
    if (e.target.name === "wasNPWTUsedin60Days") {
      setData({
        ...data,
        [name]: {
          ...Object(data)[name],
          errorMessage: null,
          valid: ValidationStatus.VALID,
          value: value,
        },
      });
    }
  };

  const validateAndSetDataDropDown = (e: any) => {
    newOrderObj?.setIsHandleChangeTriggered(true);
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: {
        ...Object(data)[name],
        valid: ValidationStatus.VALID,
        value: getCodeFromText(reasonForTransition, value),
      },
    });
  };

  const validateAndSetDate = (date: string | null | undefined) => {
    newOrderObj?.setIsHandleChangeTriggered(true);
    const formattedDate = date === null ? "" : convertStringToDate(date);
    const isValid = validator.validate(formattedDate!, "dateInitiated");
    setData({
      ...data,
      dateInitiated: {
        ...data.dateInitiated,
        errorMessage: null,
        required: true,
        valid: isValid!.status,
        value: formattedDate,
      },
    });
  };

  const setClasses = (e: any, classname: string) => {
    setFocusClasses(
      Object.assign({}, focusClasses, { [e.target.name]: classname })
    );
  };

  const changeFacilityButtonClick = () => {
    setOpen(true);
  };

  const addNewFacility = (newFacility: IFacility) => {
    setFacility(newFacility);
    data.inpatientFacility = newFacility;
    data.inpatientFacilityAsDefault = false;
    setOpen(false);
  };

  useEffect(() => {
    if (
      data.inpatientFacilityAsDefault === false &&
      data.inpatientFacility?.accountNumber !== null
    ) {
      setFacility(data.inpatientFacility!);
    } else if (
      authObj &&
      (authObj.userProfile?.facilities.length !== 0 ||
        data.inpatientFacilityAsDefault !== false) &&
      !data.requestingFacility?.value
    ) {
      if (authObj.registeredFaciltyAddress) {
        const userFacility = authObj.registeredFaciltyAddress!;
        //(bug-39688) spread operator wont work here hence using direct object setter.
        data.inpatientFacility = userFacility;
        data.inpatientFacilityAsDefault = true;
        setFacility(userFacility);
      }
    } else if (data && data.inpatientFacilityAsDefault !== false) {
      if (data.requestingFacility?.value) {
        const userFacility = data.requestingFacility.value!;
        //(bug-39688) spread operator wont work here hence using direct object setter.
        data.inpatientFacility = userFacility;
        data.inpatientFacilityAsDefault = true;
        setFacility(userFacility);
      }
    }
  }, [
    authObj?.userProfile?.facilities,
    authObj?.registeredFaciltyAddress,
    data.requestingFacility?.value,
    data.inpatientFacility,
  ]);

  useEffect(() => {
    fetchDropDownContent();
  }, []);

  useEffect(() => {
    if (
      data.inpatientFacilityAsDefault === false &&
      data.inpatientFacility !== null &&
      data.inpatientFacility?.accountNumber !== null
    ) {
      setFacility(data.inpatientFacility!);
    }
  }, [data.inpatientFacility]);

  return (
    <div className="inpatientTransition-component">
      {!isReviewOrder && (
        <div className="inpatientTransition">
          <h2
            className="inpatientTransition-title"
            data-testid="inpatientTransition-title"
          >
            Inpatient Transition
          </h2>
          <InputWithLabel
            error={data.wasNPWTInitiated.valid === ValidationStatus.INVALID}
            isRequired={true}
            isShowWarning={true}
            label="Was Negative Pressure Wound Therapy (NPWT) initiated in an inpatient facility?"
            labelClassName="was-NPWT-initiated"
            testId="inpatientTransition-was-NPWT-initiated1"
            warningMessage={data.wasNPWTInitiated.errorMessage!}
          >
            <Grid
              className="inpatientTransition-grid-container"
              container
              spacing={2}
            >
              <Grid
                className="inpatientTransition-grid-item"
                item
                xs={isMobileScreen ? 12 : 6}
              >
                <RadioGroup
                  name="wasNPWTInitiated"
                  classes={{ root: "radioRoot" }}
                  onChange={validateAndSetData}
                  value={data.wasNPWTInitiated.value}
                >
                  <FormControlLabel
                    classes={{
                      root:
                        data.wasNPWTInitiated.value === "yes"
                          ? "optionRoot-active"
                          : "optionRoot",
                    }}
                    componentsProps={{
                      typography: {
                        classes: {
                          root:
                            data.wasNPWTInitiated.value === "yes"
                              ? "optiontxtSelect"
                              : "optiontxt",
                        },
                      },
                    }}
                    control={
                      <Radio
                        icon={<RadioButtonIcon />}
                        checkedIcon={<SelectedRadioButtonIcon />}
                      />
                    }
                    data-testid="inpatientTransition-was-NPWT-initiated-yes1"
                    label="Yes"
                    value="yes"
                  />
                  <FormControlLabel
                    classes={{
                      root:
                        data.wasNPWTInitiated.value === "no"
                          ? "optionRoot-active"
                          : "optionRoot",
                    }}
                    componentsProps={{
                      typography: {
                        classes: {
                          root:
                            data.wasNPWTInitiated.value === "no"
                              ? "optiontxtSelect"
                              : "optiontxt",
                        },
                      },
                    }}
                    control={
                      <Radio
                        icon={<RadioButtonIcon />}
                        checkedIcon={<SelectedRadioButtonIcon />}
                      />
                    }
                    data-testid="inpatientTransition-was-NPWT-initiated-no1"
                    label="No"
                    value="no"
                  />
                </RadioGroup>
              </Grid>
            </Grid>
          </InputWithLabel>
          {data.wasNPWTInitiated.value === "yes" && (
            <Grid
              className="inpatientTransition-grid-container"
              container
              spacing={2}
            >
              <Grid
                className="inpatientTransition-grid-item-yes"
                item
                xs={isMobileScreen ? 6 : 4}
              >
                <InputWithLabel
                  error={data.dateInitiated.valid === ValidationStatus.INVALID}
                  id={data.dateInitiated.componentId!}
                  isRequired={data.dateInitiated.required}
                  isShowWarning={true}
                  label={data.dateInitiated.title!}
                  labelClassName={focusClasses.dateInitiated}
                  testId="inpatientTransition-date-initiated"
                  warningMessage={data.dateInitiated.errorMessage}
                >
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      components={{ OpenPickerIcon: CalendarIcon }}
                      InputAdornmentProps={{
                        classes: {
                          root: "adornedRoot",
                        },
                      }}
                      InputProps={{
                        classes: {
                          root: `dateInitiated ${
                            data.dateInitiated.valid ===
                            ValidationStatus.INVALID
                              ? "showError"
                              : "noError"
                          }`,
                          input: "input",
                          notchedOutline: "outline",
                        },
                      }}
                      onChange={(value) => validateAndSetDate(value)}
                      renderInput={(params) => {
                        params.error = false;
                        params.inputProps!.placeholder = "__/__/____";
                        return (
                          <TextField
                            data-testid="inpatientTransition-date-initiated-value"
                            id={data.dateInitiated.id!}
                            name="dateInitiated"
                            onFocus={(e) => setClasses(e, "Mui-focused")}
                            onBlur={(e) => setClasses(e, "")}
                            {...params}
                          />
                        );
                      }}
                      value={data.dateInitiated.value}
                    />
                  </LocalizationProvider>
                </InputWithLabel>
              </Grid>
              {isTransitionOrder && data.previousRo.value && (
                <Grid
                  className="inpatientTransition-grid-item-ro"
                  item
                  xs={isMobileScreen ? 6 : 4}
                >
                  <InputWithLabel
                    label={data.previousRo.title}
                    testId="inpatientTransition-previous-ro"
                  >
                    <p className="previous-ro" data-testid="previousRo-value">
                      {data.previousRo.value}
                    </p>
                  </InputWithLabel>
                </Grid>
              )}
            </Grid>
          )}
          {data.wasNPWTInitiated.value === "yes" && (
            <div className="inpatientTransitionReason-block">
              <Grid
                className="inpatientTransitionReason-grid-container"
                container
                spacing={2}
              >
                <Grid item xs={12} lg={6} md={6}>
                  <InputWithLabel
                    error={
                      data.reasonForTransition?.valid ===
                      ValidationStatus.INVALID
                    }
                    isRequired={true}
                    isShowWarning={true}
                    label="Reason for Transition?"
                    testId={"test-inpatientTransitionReason"}
                    warningMessage={data.reasonForTransition?.errorMessage}
                    isDropdown={true}
                  >
                    <CustomDropDown
                      handleChange={validateAndSetDataDropDown}
                      menuItem={reasonForTransitionText}
                      name="reasonForTransition"
                      placeHolder="Select Reason"
                      selectpropsClassName={
                        data.reasonForTransition?.value
                          ? "inpatientTransition-select"
                          : "placeHolder"
                      }
                      selectClassName={
                        data.reasonForTransition?.value
                          ? "inpatientTransition-input"
                          : "placeHolder"
                      }
                      testId="inpatient-reasonForTransition"
                      value={
                        data.reasonForTransition?.value
                          ? getTextFromCode(
                              reasonForTransition,
                              data.reasonForTransition.value
                            )
                          : null
                      }
                    />
                  </InputWithLabel>
                </Grid>
              </Grid>
            </div>
          )}
          <div className="wasNPWTUsedin60Days-block">
            <InputWithLabel
              error={
                data.wasNPWTUsedin60Days.valid === ValidationStatus.INVALID
              }
              isRequired={true}
              isShowWarning={true}
              label="Has the patient been on Negative Pressure Wound Therapy (NPWT) anytime in the last 60 days?"
              labelClassName="was-NPWT-initiated"
              testId="inpatientTransition-was-NPWT-initiated"
              warningMessage={data.wasNPWTUsedin60Days.errorMessage}
            >
              <Grid
                className="inpatientTransition-grid-container"
                container
                spacing={2}
              >
                <Grid
                  className="inpatientTransition-grid-item"
                  item
                  xs={isMobileScreen ? 12 : 6}
                >
                  <RadioGroup
                    name="wasNPWTUsedin60Days"
                    classes={{ root: "radioRoot" }}
                    onChange={validateAndSetData}
                    value={data?.wasNPWTUsedin60Days?.value}
                  >
                    <FormControlLabel
                      classes={{
                        root:
                          data?.wasNPWTUsedin60Days.value === "yes"
                            ? "optionRoot-active"
                            : "optionRoot",
                      }}
                      componentsProps={{
                        typography: {
                          classes: {
                            root:
                              data?.wasNPWTUsedin60Days.value === "yes"
                                ? "optiontxtSelect"
                                : "optiontxt",
                          },
                        },
                      }}
                      control={
                        <Radio
                          icon={<RadioButtonIcon />}
                          checkedIcon={<SelectedRadioButtonIcon />}
                        />
                      }
                      data-testid="inpatientTransition-was-NPWT-initiated-yes"
                      label="Yes"
                      value="yes"
                    />
                    <FormControlLabel
                      classes={{
                        root:
                          data?.wasNPWTUsedin60Days.value === "no"
                            ? "optionRoot-active"
                            : "optionRoot",
                      }}
                      componentsProps={{
                        typography: {
                          classes: {
                            root:
                              data?.wasNPWTUsedin60Days.value === "no"
                                ? "optiontxtSelect"
                                : "optiontxt",
                          },
                        },
                      }}
                      control={
                        <Radio
                          icon={<RadioButtonIcon />}
                          checkedIcon={<SelectedRadioButtonIcon />}
                        />
                      }
                      data-testid="inpatientTransition-was-NPWT-initiated-no"
                      label="No"
                      value="no"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </InputWithLabel>
          </div>
          {data.wasNPWTInitiated.value === "yes" && (
            <Grid
              className="inpatientTransition-grid-container"
              container
              spacing={2}
            >
              <Grid className="inpatientTransition-grid-item" item xs={12}>
                <div className="facilityDetail">
                  {facility && (
                    <FacilityInfoDetail
                      facilityInfo={facility}
                      index={0}
                      key={0}
                      openConfirmationBox={null}
                      showTrash={false}
                      hideFacilityID={false}
                      showManualFacilityNote={false}
                    />
                  )}
                </div>
              </Grid>
              {!internalObj?.isVACOrderSSO && (
                <Button
                  classes={{ root: "button-change-facility" }}
                  data-testid="button-change-facility"
                  variant="outlined"
                  onClick={changeFacilityButtonClick}
                >
                  Change Facility
                </Button>
              )}
            </Grid>
          )}
          <AddFacilityContext.Provider
            value={{
              closePopup: () => setOpen(false),
              facilitySearchValidator: new Validator(),
              addFacilityToList: addNewFacility,
            }}
          >
            <Popup
              dialogParentClass="add-facility-popup"
              openFlag={open}
              closeHandler={() => setOpen(false)}
            >
              <AddFacilityContainer isForNewOrder={true} />
            </Popup>
          </AddFacilityContext.Provider>
        </div>
      )}
      {isReviewOrder && (
        <InpatientTransitionReviewOrder
          data={data}
          facility={data.inpatientFacility}
          editButtonClicked={editButtonClicked}
          isOrderSummary={isOrderSummary}
          reasonForTransition={reasonForTransition}
          isCreateTransitionOrder={isTransitionOrder}
        />
      )}
    </div>
  );
};
