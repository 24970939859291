import { Grid, Tab } from "@mui/material";
import { ReactNode, useContext, useEffect, useState } from "react";
import { useTabs } from "react-headless-tabs";
import { useHistory } from "react-router-dom";
import { ReactComponent as BackIcon } from "../../assets/LPBackButton.svg";
import { AuthContext, AuthContextType } from "../../context/AuthContext";
import {
  InternalSignOnContext,
  InternalSignOnContextType,
} from "../../context/InternalSignOnContext";
import {
  CustomDropDown,
  DropDownValue,
} from "../../core/customDropdown/customDropdown.component";
import { ExpressButton } from "../../core/expressButton/expressButton.component";
import { InputWithLabel } from "../../core/inputWithLabel/inputWithLabel.component";
import { ScrollableTabHeader } from "../../core/scrollableTabs/scrollableTabHeader.component";
import { getUserTerritories } from "../../util/userService";
import {
  getCodeFromTextWhenTextMayEmpty,
  getTextFromCode,
} from "../../util/utilityFunctions";
import "./viewReports.css";
import { ViewReportsTabs } from "./viewReports.enum";
import InProcessOrder from "./inProcessOrder/inProcessOrder.component";

export const ViewReports = ({}) => {
  const history: any = useHistory();
  const [selectedTab, setSelectedTab] = useTabs([ViewReportsTabs.IN_PROCESS]);
  const AuthObj = useContext<AuthContextType | null>(AuthContext);
  const internalObj = useContext<InternalSignOnContextType | null>(
    InternalSignOnContext
  );

  const sectionToDisplay = () => {
    let page: ReactNode;
    switch (selectedTab) {
      case ViewReportsTabs.IN_PROCESS:
        page = (
          <InProcessOrder selectedTerritory={internalObj?.selectedTerritory} />
        );
        break;
    }
    return page;
  };
  const [scrollTabValue, setScrollTabValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setScrollTabValue(newValue);
  };

  const setOrderSelectedTab = async (selectedTabDetails: any) => {
    setSelectedTab(selectedTabDetails);
  };
  const [territoryView, setTerritoryView] = useState([]);
  const [territoryViewText, setTerritoryViewText] = useState([]);
  const selectedTerritory = internalObj?.selectedTerritory;
  const setSelectedTerritory = internalObj?.setSelectedTerritory;
  useEffect(() => {
    getTerritories();
    switch (selectedTab) {
      case ViewReportsTabs.IN_PROCESS:
        setScrollTabValue(0);
        break;
      default:
        setScrollTabValue(0);
        break;
    }
    AuthObj?.setIsInternalUserFacilitySelected(false);
  }, [selectedTab]);

  const handleReturnToSelectAFacilityClick = () => {
    history.push("/ssoRedirect");
  };
  const getTerritories = async () => {
    const reqBody = {
      userName: AuthObj?.userProfile?.userName,
    };
    const territories = await getUserTerritories(reqBody);
    if (territories !== undefined) {
      if (territories.length > 0) {
        let territoryObject: any = [];
        territories.map((x: { code: string; name: string }) => {
          const dropDownValue: DropDownValue = {
            code: x.code,
            text: `${
              x.name.toLowerCase() !== x.code.toLowerCase()
                ? `${x.code} ${x.name}`
                : ""
            }`,
          };
          territoryObject.push(dropDownValue);
        });
        const territoryTextObject = territories
          .sort((a: { sortOrder: number }, b: { sortOrder: number }) =>
            a.sortOrder > b.sortOrder ? 1 : -1
          )
          .map(
            (x: { name: string; code: string }) =>
              `${x.code}${
                x.name.toLowerCase() !== x.code.toLowerCase()
                  ? " " + x.name
                  : ""
              }`
          );
        setTerritoryView(territoryObject);
        setTerritoryViewText(territoryTextObject);
        if (territoryTextObject && territoryTextObject.length > 0) {
          const territoryCode = getCodeFromTextWhenTextMayEmpty(
            territoryObject,
            territoryTextObject[0].toString()
          );

          if (territoryCode && !selectedTerritory) {
            setSelectedTerritory!(territoryCode);
          }
        }
      }
    }
  };

  const handleTerritoryChange = (e: any) => {
    let value = e.target.value;
    value = getCodeFromTextWhenTextMayEmpty(territoryView, e.target.value);
    internalObj?.setSelectedTerritory(value);
  };

  return (
    <div className="view-report-component-container">
      <div className="select-a-facility-back-div">
        <ExpressButton
          testId="return-to-search-facility"
          variant="text"
          parentClass="select-a-facility-back"
          clickHandler={handleReturnToSelectAFacilityClick}
          children={"Return to Select a Facility"}
          startIcon={<BackIcon className="backIcon-SelectaFacility" />}
        ></ExpressButton>
      </div>
      <div
        className="view-report-component"
        data-testid="view-report-component"
      >
        <p className="view-report-header" data-testid="view-report-header">
          Reports
        </p>
        <div className="short-form">
          <div className="scroll-tab-div">
            <ScrollableTabHeader
              scrollTabValue={scrollTabValue}
              handleChange={handleChange}
              tabsIndicator="scroll-tabs-indicator"
              tabsRoot="scroll-tabs"
              variant="scrollable"
            >
              <Tab
                classes={{
                  textColorPrimary: "scroll-tabs-textlabel",
                  selected: "scroll-tabs-textlabel-select",
                  root: "scroll-tabs-height",
                }}
                disableRipple={true}
                iconPosition="end"
                label="In-Process Orders"
                onClick={() => setOrderSelectedTab("In-Process Orders")}
              />
            </ScrollableTabHeader>
          </div>
          <Grid className="reports-sub-header">
            <Grid item xs={12}>
              <span className="sub-header" data-testid="sub-header">
                In-Process V.A.C.<sup className="superscript-style">®</sup>
                &nbsp;Therapy Home Orders
              </span>
            </Grid>
            <Grid item xs={12}>
              <span className="sub-header-desc" data-testid="sub-header-desc">
                This report shows all in-process order before the status is "in
                therapy". Orders you place and orders placed in your territory
                will appear on your report.
              </span>
            </Grid>
          </Grid>
          <div
            className="territoryView-reports"
            data-testid="territoryView-reports"
          >
            <InputWithLabel
              label="Territory to view"
              testId={"territory-view"}
              isDropdown={true}
            >
              <CustomDropDown
                name="TerritoryView"
                menuItem={territoryViewText}
                handleChange={handleTerritoryChange}
                selectpropsClassName="territoryView-reports-select"
                selectClassName="territoryView-reports-input"
                testId="territoryView-reports-DropDown"
                value={
                  selectedTerritory
                    ? getTextFromCode(territoryView, selectedTerritory)
                    : ""
                }
              />
            </InputWithLabel>
          </div>
          <div data-testid="tab-nav">{sectionToDisplay()}</div>
        </div>
      </div>
    </div>
  );
};
