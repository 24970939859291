import "./productNotAvailablePopUp.css";
export interface IProductNotAvailablePopup {
  description: string;
  productName: string;
  title: string;
}

export interface IProductNotAvailableError {
  errorFlag: boolean;
  errorMessage: string;
  productName: string;
}

export let defaultProductUnavailableErrorPopUp: IProductNotAvailableError = {
  errorMessage: "",
  productName: "",
  errorFlag: false,
};

export const ProductNotAvailablePopUp = ({
  description,
  productName,
  title,
}: IProductNotAvailablePopup) => {
  return (
    <div className="product-not-available-main">
      <h2
        className="product-not-available-title"
        data-testid="product-not-available-title"
      >
        {title}
      </h2>
      <h4
        className="product-not-available-description"
        data-testid="product-not-available-description"
      >
        <span
          className="unavailable-product-name"
          data-testid="unavailable-product-name"
        >
          {productName}
        </span>
        {description}
      </h4>
    </div>
  );
};
