import Grid from "@mui/material/Grid";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  AuthContext,
  AuthContextType,
} from "../../../../../context/AuthContext";
import {
  InpatientOrderContext,
  InpatientOrderContextType,
} from "../../../../../context/InpatientOrderContext";
import ErrorPopup, {
  defaultErrorPopUp,
  IErrorPopUp,
} from "../../../../../core/errorPopup/errorPopup.component";
import { LoadingSpinner } from "../../../../../core/loader/LoadingSpinner";
import {
  REGISTERED_FACILITY_OBJ_FROM_AUTH_CONTEXT_FAILURE,
  USER_PROFILE_OBJ_FROM_AUTH_CONTEXT_FAILURE,
} from "../../../../../util/errorCode";
import { SVC_IN_PATIENT_ORDER_SUBMIT } from "../../../../../util/errorMsg";
import {
  reSubmitAcuteOrderDetails,
  reSubmitAcuteOrderWoundDetails,
} from "../../../../../util/orderMgrService";
import { getSiteUseId } from "../../../../../util/utilityFunctions";
import { getCustomerClassName } from "../../../../inpatientOrder/components/mapper/inpatientOrder.request.mapper";
import { IPatient } from "../../../patient.interface";
import { IAlertsIndicatorPopupProps } from "../../../patientAlerts/alerts/alerts.interface";
import { ResubmissionIndicatorPopup } from "../component/resubmissionOfOrderOrWound/resubmissionOfOrderOrWound.component";
import { ResubmissionOfOrderOrWoundSuccessPopup } from "../component/resubmissionOfOrderOrWoundSuccess/resubmissionOfOrderOrWoundSuccessPopup.component";
import { ResubmissionOfOrderOrWoundSection } from "./reSubmissionContainer.enum";
interface Props {
  closePopUpAction: Function;
  patientData: IPatient;
  successAndCloseIndicatorPopup?: Function;
}

export const ReSubmissionContainer = ({
  closePopUpAction,
  patientData,
  successAndCloseIndicatorPopup,
}: Props) => {
  const history = useHistory();
  const inpatientOrderObj = useContext<InpatientOrderContextType | null>(
    InpatientOrderContext
  );
  const [resubmissionPopUpSection, setresubmissionPopUpSection] =
    useState<ResubmissionOfOrderOrWoundSection>(
      ResubmissionOfOrderOrWoundSection.RESUBMISSION_ORDER_WOUND_POPUP
    );
  const [popupProps, setPopProps] = useState<IAlertsIndicatorPopupProps | null>(
    null
  );
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const [error, setError] = useState<IErrorPopUp>(defaultErrorPopUp);

  const resubmitAcuteOrderInfo = async () => {
    setresubmissionPopUpSection(ResubmissionOfOrderOrWoundSection.LOADER);
    if (
      authObj &&
      authObj.userProfile &&
      authObj.registeredFaciltyAddress &&
      authObj.registeredFaciltyAddress?.accountName
    ) {
      let reqParams = getResubmitAcuteOrderReqParam();
      try {
        const response = await reSubmitAcuteOrderDetails(reqParams);
        if (response && response.succeeded) {
          inpatientOrderObj?.resetContext();
          inpatientOrderObj?.setIsFromStartNewOrder(true);
          inpatientOrderObj?.setErrorCode(200);
          history.push({
            pathname: "/orders/inpatientOrder",
            state: {
              orderID: patientData.orderID,
              allProducts: [],
              product: null,
            },
          });
        } else {
          inpatientOrderObj?.resetContext();
          inpatientOrderObj?.setIsFromStartNewOrder(true);
          inpatientOrderObj?.setErrorCode(
            response?.error?.code ||
              response?.error?.errorCode ||
              response?.status
          );
          history.push({
            pathname: "/orders/inpatientOrder",
            state: {
              orderID: patientData.orderID,
              allProducts: [],
              product: null,
            },
          });
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      let errorCode = REGISTERED_FACILITY_OBJ_FROM_AUTH_CONTEXT_FAILURE;
      if (!authObj || (authObj && !authObj.userProfile)) {
        errorCode = USER_PROFILE_OBJ_FROM_AUTH_CONTEXT_FAILURE;
      } else if (!authObj || (authObj && !authObj.registeredFaciltyAddress)) {
        errorCode = REGISTERED_FACILITY_OBJ_FROM_AUTH_CONTEXT_FAILURE;
      }
      const errorFailure = {
        errorCode: errorCode,
        errorFlag: true,
        errorMessage: SVC_IN_PATIENT_ORDER_SUBMIT,
      };
      setError(errorFailure);
      setresubmissionPopUpSection(ResubmissionOfOrderOrWoundSection.ERROR);
    }
  };

  const callOpenReviewModeInPatientOrder = async () => {
    inpatientOrderObj?.resetContext();
    inpatientOrderObj?.setIsFromStartNewOrder(true);
    setresubmissionPopUpSection(ResubmissionOfOrderOrWoundSection.LOADER);
    history.push({
      pathname: "/orders/inpatientOrder",
      state: {
        orderID: patientData.orderID,
        allProducts: [],
        product: null,
      },
    });
  };

  const resubmitWoundInformation = async () => {
    setresubmissionPopUpSection(ResubmissionOfOrderOrWoundSection.LOADER);
    if (
      authObj &&
      authObj.userProfile &&
      authObj.registeredFaciltyAddress &&
      authObj.registeredFaciltyAddress?.accountName
    ) {
      let reqParams = getResubmitWoundInfoReqParam();
      try {
        const response = await reSubmitAcuteOrderWoundDetails(reqParams);
        if (response && response.succeeded) {
          setresubmissionPopUpSection(
            ResubmissionOfOrderOrWoundSection.RESUBMISSION_ORDER_WOUND_SUCCESS
          );
        } else {
          inpatientOrderObj?.resetContext();
          inpatientOrderObj?.setIsFromStartNewOrder(true);
          inpatientOrderObj?.setErrorCode(
            response?.error?.code ||
              response?.error?.errorCode ||
              response?.status
          );
          history.push({
            pathname: "/orders/inpatientOrder",
            state: {
              orderID: patientData.orderID,
              allProducts: [],
              product: null,
              errorCode:
                response?.error?.code ||
                response?.error?.errorCode ||
                response?.status,
            },
          });
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      let errorCode = REGISTERED_FACILITY_OBJ_FROM_AUTH_CONTEXT_FAILURE;
      if (!authObj || (authObj && !authObj.userProfile)) {
        errorCode = USER_PROFILE_OBJ_FROM_AUTH_CONTEXT_FAILURE;
      } else if (!authObj || (authObj && !authObj.registeredFaciltyAddress)) {
        errorCode = REGISTERED_FACILITY_OBJ_FROM_AUTH_CONTEXT_FAILURE;
      }
      const errorFailure = {
        errorCode: errorCode,
        errorFlag: true,
        errorMessage: SVC_IN_PATIENT_ORDER_SUBMIT,
      };
      setError(errorFailure);
      setresubmissionPopUpSection(ResubmissionOfOrderOrWoundSection.ERROR);
    }
  };

  const getResubmitAcuteOrderReqParam = () => {
    let reqParams = {
      OrderId: patientData.orderID,
      employeeId: authObj!.userProfile!.employeeID
        ? authObj!.userProfile!.employeeID
        : "INTERNET",
      requestorSiteUseId: getSiteUseId(authObj),
      requestorFirstName: authObj!.userProfile!.firstName,
      requestorLastName: authObj!.userProfile!.lastName,
      requestorPhone: authObj!.userProfile!.phoneNumber
        ? authObj!.userProfile!.phoneNumber
        : authObj!.userProfile!.mobilePhoneNumber,
      requestorDepartment: authObj!.userProfile!.departmentName,
      requestorAccountNumber: authObj!.registeredFaciltyAddress?.accountNumber,
      requestorUserName: authObj!.userProfile!.userName,
      requestorTitle: authObj!.userProfile!.title,
      requestorAddress1: authObj!.registeredFaciltyAddress!.address1,
      requestorAddress2: authObj!.registeredFaciltyAddress!.address2,
      requestorCity: authObj!.registeredFaciltyAddress!.city,
      requestorState: authObj!.registeredFaciltyAddress!.state,
      requestorZipCode: authObj!.registeredFaciltyAddress!.zip.toString(),
      RequestorFacilityName: authObj!.registeredFaciltyAddress?.accountName,
      CustomerGroup: getCustomerClassName(authObj!),
      ServiceCenter:
        authObj!.registeredFaciltyAddress!.customerServiceCode ?? "",
      appVersion: process.env.REACT_APP_VERSION,
      InventoryType: 2,
      ReplaceStoragePlacement: true,
      SpReplaceQuantity: 1,
    };
    return reqParams;
  };

  const getResubmitWoundInfoReqParam = () => {
    let reqParams = {
      OrderId: patientData.orderID,
      siteUseId: getSiteUseId(authObj),
      loggedInUserFirstName: authObj!.userProfile!.firstName,
      loggedInUserLastName: authObj!.userProfile!.lastName,
      LoggedInUserPhone: authObj!.userProfile!.phoneNumber
        ? authObj!.userProfile!.phoneNumber
        : authObj!.userProfile!.mobilePhoneNumber,
      loggedInUserName: authObj!.userProfile!.userName,
      requestorFacility: authObj!.registeredFaciltyAddress?.accountName,
      requestorFacilityAccountNo:
        authObj!.registeredFaciltyAddress?.accountNumber,
    };
    return reqParams;
  };

  useEffect(() => {
    getAndSetUndicatorPopupProps();
  }, []);

  const getAndSetUndicatorPopupProps = () => {
    let props: IAlertsIndicatorPopupProps = {
      indicatorHeader: patientData?.isAcuteOrderFailed
        ? "Incomplete Order"
        : "Incomplete Wound Details",
      indicatorDescription: patientData.isAcuteOrderFailed
        ? "Your order could not be submitted. Please try again."
        : "Wound details were not added to your order. Please try again. ",
      indicatorDescriptionSubSection1: patientData.isAcuteOrderFailed
        ? "If you see this message repeatedly, please wait 5 minutes and try again. If it continues to persist, call the 3M Service Center at 1-800-275-4524 for more assistance."
        : "Your order has been successfully submitted, but your wound information failed to be added. Please submit your wound information again or go to My Patients and try again later.",
      indicatorDescriptionSubSection2: patientData.isAcuteOrderFailed
        ? null
        : "If you see this message repeatedly, please wait 5 minutes and try again. If it continues to persist, call the 3M Service Center at 1-800-275-4524 for more assistance.",
      firstButtonLabel: patientData?.isAcuteOrderFailed
        ? "Review Order"
        : "Close",
      secondButtonLable: patientData?.isAcuteOrderFailed
        ? "Submit Order Again"
        : "Resubmit Wound Information",
    };
    setPopProps(props);
  };

  const resubmissionDisplaySection = () => {
    switch (resubmissionPopUpSection) {
      case ResubmissionOfOrderOrWoundSection.RESUBMISSION_ORDER_WOUND_POPUP:
        return (
          <ResubmissionIndicatorPopup
            closePopUpAction={closePopUpAction}
            patientData={patientData}
            indicatorPopupProps={popupProps!}
            resubmitAcuteOrderInfo={resubmitAcuteOrderInfo}
            resubmitWoundInformation={resubmitWoundInformation}
            openInPatientReviewOrder={callOpenReviewModeInPatientOrder}
          />
        );
      case ResubmissionOfOrderOrWoundSection.RESUBMISSION_ORDER_WOUND_SUCCESS:
        return (
          <ResubmissionOfOrderOrWoundSuccessPopup
            btnAction={successAndCloseIndicatorPopup!}
            description1={
              "Success! Your wound information has been added to your order."
            }
          />
        );
      case ResubmissionOfOrderOrWoundSection.LOADER:
        return LoadSpinner();
      case ResubmissionOfOrderOrWoundSection.ERROR:
        return (
          <ErrorPopup
            errorPopupFlag={error?.errorFlag}
            handleBackButton={closePopUpAction}
            popUpStyles="errorPopup"
            errorMessage={error?.errorMessage}
            buttontitle="Done"
            showTitle={false}
            isShortSentence={true}
            errorCode={error?.errorCode}
            isAlreadyPopUpOpen={true}
          />
        );
    }
  };

  const LoadSpinner = () => {
    return (
      <div className="resubmission-spinner" data-testid="loading-spinner">
        <LoadingSpinner />
      </div>
    );
  };

  return (
    <Grid className="resubmission-indicator-detail">
      <div data-testId="resubmissionContainer">
        {popupProps && resubmissionDisplaySection()}
      </div>
    </Grid>
  );
};
