import { useContext } from "react";
import { Grid } from "@mui/material";
import { ISalesPurchaseOrderNumber } from "../../../inpatientSupplyOrder.interface";
import "./salesPurchaseOrder.css";
import { MobileDisplayContext } from "../../../../../context/MobileDisplayContext";
import { InpatientSupplyOrderPageSection } from "../../../inpatientSupplyOrder.enum";

interface Props {
  data: ISalesPurchaseOrderNumber;
  editButtonAction?: any;
  isSummaryOrDetail?: boolean;
  isOrderDetails?: boolean;
}

export const SalesPoOrderInfoReview = ({
  data,
  editButtonAction,
  isSummaryOrDetail = false,
  isOrderDetails = false,
}: Props) => {
  const { isMobileScreen } = useContext(MobileDisplayContext);

  const showTitleAndValue = (
    title: string,
    value: string | null,
    testId: string
  ) => {
    return (
      <div
        className="show-title-and-value-div"
        id="id_show-title-and-value-div"
      >
        <p
          className="show-title-and-value-title"
          data-testid={`${testId}-title`}
          id={`${testId}-title-id`}
        >
          {title}
        </p>
        <p
          className="show-title-and-value-value"
          data-testid={`${testId}-value`}
          id={`${testId}-value-id`}
        >
          {value && value !== "" ? value.trim() : "--"}
        </p>
      </div>
    );
  };

  return (
    <div
      className="order-information-review-component"
      data-testid="order-information-review-component"
      id="id_order-information-review-component"
    >
      <div
        className="order-information-review-header"
        id="id_order-information-review-header"
      >
        <p
          className="order-information-review-title"
          data-testid="order-information-review-title"
          id="order-information-review-title-id"
        >
          {isOrderDetails ? "Sales Information" : "Order Information"}
        </p>
        {!isSummaryOrDetail && (
          <button
            className="order-information-review-edit-button"
            data-testid="order-information-review-edit-button"
            id="order-information-review-edit-button-id"
            onClick={() =>
              editButtonAction(
                "in-patient-order-salespurchase-information-component",
                InpatientSupplyOrderPageSection.SECOND_PAGE
              )
            }
          >
            Edit
          </button>
        )}
      </div>
      <Grid
        className="order-information-review-grid-container"
        container
        id="id_order-information-review-grid-container"
      >
        {isOrderDetails && (
          <Grid
            className="order-information-review-grid-item"
            item
            id="id_order-information-review-grid-item-ordered-by"
            xs={isMobileScreen ? 12 : 5}
          >
            {showTitleAndValue("Ordered By", data.orderedBy!, "ordered-by")}
          </Grid>
        )}
        <Grid
          className="order-information-review-grid-item"
          item
          id="id_order-information-review-grid-item-sales-po"
          xs={
            isOrderDetails ? (isMobileScreen ? 12 : 5) : isMobileScreen ? 12 : 6
          }
        >
          {showTitleAndValue(
            "Sales PO#",
            data.salesPONumber.value,
            "sales-po-number"
          )}
        </Grid>
      </Grid>
    </div>
  );
};
