import { Box, Typography } from "@mui/material";
import "./productAvailibilityPopup.css";
import { ExpressButton } from "../../../../../core/expressButton/expressButton.component";

export interface IPopup {
  dialogParentClass: string;
  firstButtonHandler: Function;
  secondButtonHandler: Function;
  firstButtonText?: string;
  secondButtonText?: string;
  title?: string;
  showFirstButton?: boolean;
}

export const ProductAvailibiltyPopup = ({
  dialogParentClass,
  firstButtonHandler,
  secondButtonHandler,
  firstButtonText,
  secondButtonText,
  title,
  showFirstButton = true,
}: IPopup) => {
  return (
    <Box
      className="product-availibility-supply-order-Popup"
      id="product-availibility-supply-order-popup-id"
      data-testid="product-availibility-popup-container"
    >
      <Box
        className="product-availibility-supply-order-PopupContent"
        id="product-availibility-supply-order-PopupContent-id"
        data-testid="product-availibility-supply-order-PopupContent"
      >
        <Typography
          variant="h6"
          className="product-availibility-supply-order-PopupTitle"
          id="product-availibility-supply-order-PopupTitle-id"
          data-testid="product-availibility-popup-title"
        >
          {title}
        </Typography>

        <Typography
          component="p"
          className="product-availibility-supply-order-PopupDialogParent"
          id="product-availibility-supply-order-PopupDialogParent-id"
          data-testid="product-availibility-popup-dialog-parent"
          dangerouslySetInnerHTML={{
            __html: dialogParentClass,
          }}
        />

        <Box
          className="buttons-alignment-column"
          id="buttons-alignment-column-id"
          data-testid="popup-buttons-container"
        >
          {showFirstButton && (
            <ExpressButton
              parentClass="product-availibility-supply-order-BackToNewBtn"
              id="product-availibility-supply-order-BackToNewBtn-id"
              variant="outlined"
              clickHandler={() => firstButtonHandler()}
              testId="product-availibility-supply-order-BackToNewBtn"
            >
              {firstButtonText}
            </ExpressButton>
          )}
          <ExpressButton
            parentClass="product-availibility-supply-order-SelectSuppliesBtn"
            id="product-availibility-supply-order-SelectSuppliesBtn-id"
            variant="contained"
            clickHandler={() => secondButtonHandler()}
            testId="product-availibility-supply-order-SelectSuppliesBtn"
          >
            {secondButtonText}
          </ExpressButton>
        </Box>
      </Box>
    </Box>
  );
};
