import React from "react";
import { ExpressButton } from "../../../../core/expressButton/expressButton.component";
import { Popup } from "../../../../core/popup/popup.component";
import { ERROR_MSG_CANCEL_VAC_ORDER } from "../../../../util/errorMsg";
import "./sharedOrder.css";
import parse from "html-react-parser";
import { ErrorInfo } from "../../../../core/errorInfo/errorInfo.component";

interface Props {
  closePopUpAction: any;
  sharingStopped: boolean;
  cancelShareError: boolean;
  sharedByLoggedInUser: boolean;
  errorCode?: any;
}

export const SharedOrderStop = ({
  sharingStopped,
  closePopUpAction,
  cancelShareError,
  sharedByLoggedInUser,
  errorCode = "",
}: Props) => {
  const handleClosePopup = () => {
    closePopUpAction(true);
  };

  const cancelShareOrderNote = () => {
    if (sharedByLoggedInUser) {
      return `Any edits others have made and saved will remain in the order. If you wish to share the order again, you can now do so.`;
    } else {
      return `Any edits you have made and saved will remain in the order. You can also re-add the order to your list using the Add Patient option from the My Patients dashboard.`;
    }
  };
  return (
    <Popup
      dialogParentClass="order-sharing-stop"
      closeHandler={handleClosePopup}
      openFlag={sharingStopped}
    >
      <div className="stopper-header" data-testid="stopper-header">
        {cancelShareError
          ? "Failed"
          : "Success! This order is no longer shared"}
      </div>
      <div
        className={
          errorCode && errorCode !== ""
            ? "stopper-desp stopper-desp-errorCode"
            : "stopper-desp"
        }
        data-testid="stopper-desp"
      >
        {cancelShareError ? (
          <>
            <span>
              {parse(ERROR_MSG_CANCEL_VAC_ORDER)}
              <a
                className="phone-value"
                data-testid="phone-value"
                href={`tel:+8002754524`}
              >
                (800) 275-4524 ext 41858.
              </a>
              {errorCode && errorCode !== "" && (
                <ErrorInfo errorCode={errorCode} />
              )}
            </span>
          </>
        ) : (
          cancelShareOrderNote()
        )}
      </div>
      <ExpressButton variant="contained" clickHandler={handleClosePopup}>
        Close
      </ExpressButton>
    </Popup>
  );
};
