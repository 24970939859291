import { useContext, useEffect, useState } from "react";
import {
  ERROR_MSG_ESTIMATE_DELIVERY_ACUTE_SUPPLY_ORDER_ERROR,
  ERROR_MSG_POPUP_BLOCKER,
  ERROR_MSG_SHIPPING_TOTAL_ACUTE_SUPPLY_ORDER_ERROR,
} from "../../../util/errorMsg";
import { OPEN_PDF_POP_UP_BLOCKED } from "../../../util/errorCode";
import ErrorPopup, {
  defaultErrorPopUp,
  IErrorPopUp,
} from "../../../core/errorPopup/errorPopup.component";
import {
  DeliveryDetail,
  EstimatedDeliveryDetail,
  IDeliveryDropwnTypes,
  IDropDownContent,
  IInpatientSupplyOrder,
} from "../inpatientSupplyOrder.interface";
import { ShippingAddress } from "../component/shippingAddress/shippingAddress.component";
import "./inPatientSupplyOrderSecondPage.css";
import SelectedSupplies from "../component/selectedSupplies/selectedSupplies.component";
import SupplyOrderDeliveryInformation from "../component/supplyOrderDeliveryInformation/supplyOrderDeliveryInformation.component";
import { SalesPurchaseOrderNumber } from "../component/salesPurchaseOrder/salesPurchaseOrder.component";
import { CartState } from "../component/cart/cart.reducer";
import {
  InpatientSupplyOrderContext,
  InpatientSupplyOrderContextType,
} from "../../../context/InpatientSupplyOrderContext";
import {
  getAcuteSupplyOrderDeliveryDetails,
  getAcuteSupplyOrderShippingDetails,
} from "../../../util/inPatientOrderService";
import { getCodeFromText } from "../../../util/utilityFunctions";
import { useCart } from "../../../context/CartContext";
import { Popup } from "../../../core/popup/popup.component";
import { LoadingSpinner } from "../../../core/loader/LoadingSpinner";
import { useHistory } from "react-router-dom";
import { ValidationStatus } from "../../../core/interfaces/input.interface";
import { format } from "react-string-format";

interface IInpatientSupplyOrderSecondPageProps {
  data: IInpatientSupplyOrder;
  setData: Function;
  states: any;
  statesText: any;
  maxDate: string;
  cartItemsData: CartState;
  setDeliveryMethodTypesComs: Function;
}

export const InPatientSupplyOrderSecondPage = ({
  data,
  setData,
  states,
  statesText,
  maxDate,
  cartItemsData,
  setDeliveryMethodTypesComs,
}: IInpatientSupplyOrderSecondPageProps) => {
  const history = useHistory();
  const [isPopUpBlocked, setIsPopUpBlocked] = useState<boolean>(false);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const { cartState, dispatch } = useCart();
  const [error, setError] = useState<IErrorPopUp>(defaultErrorPopUp);
  const [deliveryMethodTypes, setDeliveryMethodTypes] = useState<string[]>([]);
  const [deliveryMethod, setDeliveryMethod] = useState([]);
  const [estimateDeliveryResponse, setEstimateDeliveryResponse] = useState([]);
  const inpatientSupplyOrderObj =
    useContext<InpatientSupplyOrderContextType | null>(
      InpatientSupplyOrderContext
    );
  useEffect(() => {
    if (
      inpatientSupplyOrderObj?.data.shippingAddress.shippingAddressZip.value !==
        "" &&
      inpatientSupplyOrderObj?.data.shippingAddress.shippingAddressZip.value
        .length === 5 &&
      inpatientSupplyOrderObj?.data.deliveryInformation.needByDate.valid !==
        ValidationStatus.INVALID
    ) {
      getAcuteSupplyOrderDeliveryDetailsData();
    }
  }, [
    inpatientSupplyOrderObj?.data.shippingAddress.shippingAddressZip.value,
    inpatientSupplyOrderObj?.data.deliveryInformation.needByDate.value,
  ]);
  useEffect(() => {
    if (
      inpatientSupplyOrderObj?.data.shippingAddress.shippingAddressZip.value !==
        "" &&
      inpatientSupplyOrderObj?.data.shippingAddress.shippingAddressZip.value
        .length === 5 &&
      inpatientSupplyOrderObj?.data.deliveryInformation.deliveryMethod.value !==
        "" &&
      estimateDeliveryResponse.length > 0 &&
      deliveryMethod.length > 0
    ) {
      getAcuteSupplyOrderShippingCostData();
    }
  }, [
    inpatientSupplyOrderObj?.data.deliveryInformation.deliveryMethod.value,
    estimateDeliveryResponse,
    deliveryMethod,
  ]);

  const getTotalCase = () => {
    let totalCase = 0;
    cartItemsData?.cartItems?.map((x) => {
      if (x.packageType.toLowerCase().includes("each")) {
        totalCase = totalCase + 1;
      } else {
        totalCase = totalCase + x.quantity;
      }
      return totalCase;
    });
    return totalCase;
  };

  const closeErrorPopUpButtonAction = () => {
    let errortemp = { ...error, errorFlag: false };
    setError(errortemp);
  };

  const getAcuteSupplyOrderDeliveryDetailsData = async () => {
    let wareHouseIdArr = cartItemsData.cartItems
      .map((item) => item.wareHouseID)
      .filter((value, index, self) => self.indexOf(value) === index);
    if (
      inpatientSupplyOrderObj?.data.shippingAddress.shippingAddressLine1
        .value &&
      inpatientSupplyOrderObj?.data.shippingAddress.shippingAddressCity.value &&
      inpatientSupplyOrderObj?.data.shippingAddress.shippingAddressState
        .value &&
      inpatientSupplyOrderObj?.data.shippingAddress.shippingAddressZip.value &&
      wareHouseIdArr.length > 0
    ) {
      let reqBody = {
        ShipToAddressLine1:
          inpatientSupplyOrderObj?.data.shippingAddress.shippingAddressLine1
            .value,
        ShipToCity:
          inpatientSupplyOrderObj?.data.shippingAddress.shippingAddressCity
            .value,
        ShipToCountry: "US",
        ShipToState:
          inpatientSupplyOrderObj?.data.shippingAddress.shippingAddressState
            .value,
        ShipToZipCode:
          inpatientSupplyOrderObj?.data.shippingAddress.shippingAddressZip
            .value,
        ShipDate: new Date(),
        NeedByDate:
          inpatientSupplyOrderObj?.data.deliveryInformation.needByDate.value,
        WareHouseID: wareHouseIdArr,
      };
      setShowLoader(true);
      const response = await getAcuteSupplyOrderDeliveryDetails(reqBody);
      if (response.succeeded) {
        setEstimateDeliveryResponse(response.item);
        let shippingMethodObj: any = [];
        let shippingText: any = [];
        let shippingDeliveryCms: IDeliveryDropwnTypes[] = [];
        response.item.map((x: DeliveryDetail) => {
          x.estimatedDeliveryDetails.map(
            (y: EstimatedDeliveryDetail, index: number) => {
              shippingDeliveryCms.push({
                code: y.kciDeliveryCode,
                text: y.deliveryDescription,
                order: index,
                deliveryDescription: y.deliveryDescription,
                estArrivalDate: y.estArrivalDate,
              });
              return (
                shippingMethodObj.push({
                  text: y.deliveryDescription,
                  code: y.kciDeliveryCode,
                }),
                shippingText.push(y.deliveryDescription)
              );
            }
          );
        });
        let uniqueShippingMethods = shippingMethodObj?.filter(
          (value: any, index: any, self: any) =>
            self.map((x: any) => x.text).indexOf(value.text) == index
        );
        setDeliveryMethod(uniqueShippingMethods);
        setDeliveryMethodTypes(Array.from(new Set(shippingText)));
        setDeliveryMethodTypesComs(shippingDeliveryCms);
        setShowLoader(false);
      } else {
        let error: IErrorPopUp = {
          errorCode:
            response?.error?.code ||
            response?.error?.errorCode ||
            response?.status,
          errorMessage: ERROR_MSG_ESTIMATE_DELIVERY_ACUTE_SUPPLY_ORDER_ERROR,
          errorFlag: true,
        };
        setShowLoader(false);
        setError(error);
      }
    }
  };
  const getAcuteSupplyOrderShippingCostData = async () => {
    let shippingMethod = getCodeFromText(
      deliveryMethod,
      inpatientSupplyOrderObj?.data?.deliveryInformation?.deliveryMethod?.value
    );
    let deliveryResponse = estimateDeliveryResponse;
    if (
      inpatientSupplyOrderObj?.data.shippingAddress.shippingAddressZip.value &&
      inpatientSupplyOrderObj?.data.billToSiteId &&
      inpatientSupplyOrderObj?.data.shipToSiteId &&
      inpatientSupplyOrderObj?.data.customerNumber &&
      shippingMethod !== ""
    ) {
      let reqBody = {
        ShipToZipCode:
          inpatientSupplyOrderObj?.data.shippingAddress.shippingAddressZip
            .value,
        BillToSiteId: inpatientSupplyOrderObj?.data.billToSiteId,
        ShipToSiteId: inpatientSupplyOrderObj?.data.shipToSiteId,
        TotalCases: getTotalCase(),
        ShippingMethod: shippingMethod,
        CustomerNumber: inpatientSupplyOrderObj?.data.customerNumber,
      };
      setShowLoader(true);
      const response = await getAcuteSupplyOrderShippingDetails(reqBody);
      if (response.succeeded) {
        const res = response.item;
        dispatch({
          type: "UPDATE_ESTIMATEDATE",
          inPatientDeliveryDetails: deliveryResponse,
          shippingTotal: response.item.shippingCost,
          shippingMethod: shippingMethod,
        });
        setShowLoader(false);
      } else {
        let error: IErrorPopUp = {
          errorCode:
            response?.error?.code ||
            response?.error?.errorCode ||
            response?.status,
          errorMessage: ERROR_MSG_SHIPPING_TOTAL_ACUTE_SUPPLY_ORDER_ERROR,
          errorFlag: true,
        };
        setShowLoader(false);
        setError(error);
      }
    }
  };
  return (
    <div
      className="in-patient-supply-order-second-page-component"
      id="id_in-patient-supply-order-second-page"
      data-testid="in-patient-supply-order-second-page"
    >
      <div className="short-form">
        <SupplyOrderDeliveryInformation
          data={data}
          setData={setData}
          maxDate={maxDate}
          deliveryMethodTypes={deliveryMethodTypes}
        />

        <SelectedSupplies
          isComingFromReview={false}
          cartItemsData={cartItemsData}
        />
        <SalesPurchaseOrderNumber data={data} setData={setData} />
        <ShippingAddress
          data={data}
          setData={setData}
          states={states}
          statesText={statesText}
        />
      </div>

      <ErrorPopup
        errorPopupFlag={isPopUpBlocked}
        popUpStyles="errorPopup"
        showTitle={false}
        handleBackButton={() => setIsPopUpBlocked(false)}
        isSupportPhoneRequired={false}
        errorMessage={ERROR_MSG_POPUP_BLOCKER}
        errorCode={OPEN_PDF_POP_UP_BLOCKED}
      />
      <Popup
        closeHandler={() => setShowLoader(false)}
        dialogParentClass="in-patient-supply-order-second-page-popup"
        hideCloseButton={true}
        openFlag={showLoader}
      >
        <div
          className="in-patient-supply-order-second-page-popup-div"
          id="in-patient-supplyspinner"
        >
          <LoadingSpinner />
        </div>
      </Popup>
      <ErrorPopup
        buttontitle={"Done"}
        errorCode={error.errorCode}
        errorPopupFlag={error.errorFlag}
        errorMessage={error.errorMessage}
        isShortSentence={true}
        handleBackButton={() => {
          closeErrorPopUpButtonAction();
        }}
        popUpStyles="errorPopup"
        isSupportPhoneRequired={true}
        showTitle={false}
      />
    </div>
  );
};
