import { useContext, useState } from "react";
import "./myPatientOrderFilter.css";
import {
  MyPatientContextType,
  MyPatientContext,
} from "../../../context/MyPatientContext";
import { Grid, IconButton, useMediaQuery } from "@mui/material";
import { CustomCheckBox } from "../../../core/checkBox/checkBox.component";
import { ReactComponent as CloseIcon } from "../../../assets/popupcloseicon.svg";
import { ExpressButton } from "../../../core/expressButton/expressButton.component";
import {
  DropDownMultiCheckboxValue,
  DropDownWithMultiCheckbox,
} from "../../../core/dropdownWithMutliCheckbox/dropdownWithMutliCheckbox.component";
import { FilterType } from "./myPatientOrderFilter.interface";

type Props = {
  allSelectCount: number;
  dropdownOptions: DropDownMultiCheckboxValue[];
  handleApplyFilter: any;
  handleRemoveFilter: any;
  hasAlertSelected: boolean;
  setDropdownOption: any;
  setHasAlertSelected: any;
  setSelectedFilterOptions: any;
  setSelectedValue: any;
  selectedValue: string[];
  selectedOrderTypeValue: string[];
  selectedFilteredOrderTypes: string[];
  orderTypesOptions: DropDownMultiCheckboxValue[];
  orderTypesFilterOptions: DropDownMultiCheckboxValue[];
  setOrderTypesOptions: any;
  setSelectedOrderTypes: any;
  setSelectedFilteredOrderTypes: any;
  allSelectOrderTypesCount: number;
};

export const MyPatientOrderFilter = ({
  allSelectCount,
  dropdownOptions,
  handleApplyFilter,
  handleRemoveFilter,
  hasAlertSelected,
  setDropdownOption,
  setHasAlertSelected,
  setSelectedFilterOptions,
  setSelectedValue,
  selectedValue,
  selectedOrderTypeValue,
  orderTypesOptions,
  setOrderTypesOptions,
  setSelectedOrderTypes,
  setSelectedFilteredOrderTypes,
  allSelectOrderTypesCount,
}: Props) => {
  // MediaQuery
  const isMobileScreen = useMediaQuery("(max-width:926px)");

  // Context
  const myPatientObj = useContext<MyPatientContextType | null>(
    MyPatientContext
  );
  // dropdown
  const filterData = myPatientObj!.orderFilterData;
  const setFilterData = myPatientObj!.setOrderFilterData;

  // has alert
  const isAlertFilterEnabled = filterData.isAlertFilterEnabled;

  //dropdown close on out focus
  const [orderTypeOpenFlag, setorderTypeOpenFlag] = useState<boolean>(false);
  const [statusTypeOpenFlag, setStatusTypeOpenFlag] = useState<boolean>(false);

  const handleCloseButton = () => {
    setFilterData({
      isAlertFilterEnabled: isAlertFilterEnabled,
      isOrderFilterCardShown: false,
      selectedFilters: [],
      orderTypeFilters: [],
    });
    handleSelectOrDeselectOption(false, FilterType.orderType);
    handleSelectOrDeselectOption(false, FilterType.statusType);
    handleRemoveFilter();
  };

  const handleSelectOrDeselectOption = (
    isSelected: boolean,
    type: FilterType
  ) => {
    let updatedValues =
      type === FilterType.orderType ? orderTypesOptions : dropdownOptions;
    updatedValues.forEach((option) => {
      if (option.options) {
        option.options.forEach((childOption) => {
          childOption.isSelected = isSelected;
        });
        option.isSelected = isSelected;
        option.isPartialSelected = false;
      } else {
        option.isSelected = isSelected;
      }
    });
    if (type === FilterType.orderType) {
      setOrderTypesOptions(updatedValues);
    } else if (type === FilterType.statusType) {
      setDropdownOption(updatedValues);
    }
    getDropdownSelectedValues(updatedValues, type);
  };

  const handleOrderTypeChange = (
    element: DropDownMultiCheckboxValue,
    index: number
  ) => {
    let updatedValues = [...orderTypesOptions];
    element.isSelected = element.isSelected ? !element.isSelected : true;
    element.isPartialSelected = false;
    updatedValues.splice(index, 1, element);
    setOrderTypesOptions(updatedValues);
    getDropdownSelectedValues(updatedValues, FilterType.orderType);
  };

  const handleChange = (
    element: DropDownMultiCheckboxValue,
    index: number,
    parentIndex?: number | null
  ) => {
    let updatedValues = [...dropdownOptions];
    element.isSelected = element.isSelected ? !element.isSelected : true;
    // parent element logic
    if (element.options && element.options.length > 0) {
      element.options.forEach((option) => {
        option.isSelected = element.isSelected;
      });
      element.isPartialSelected = false;
      updatedValues.splice(index, 1, element);
    } else {
      // child element logic
      if (parentIndex !== null) {
        let parentElement = updatedValues[parentIndex!];
        parentElement.options!.splice(index, 1, element);
        const selectedOptions: string[] = [];
        parentElement.options!.forEach((option) => {
          if (option.isSelected && option.isSelected === true) {
            selectedOptions.push(option.text);
          }
        });
        const isPartialSelected =
          selectedOptions.length > 0 &&
          selectedOptions.length !== parentElement.options!.length;
        const isSelected = selectedOptions.length > 0;
        parentElement.isSelected = isSelected;
        parentElement.isPartialSelected = isPartialSelected;
        updatedValues.splice(parentIndex!, 1, parentElement);
      }
    }
    setDropdownOption(updatedValues);
    getDropdownSelectedValues(updatedValues, FilterType.statusType);
  };

  const getDropdownSelectedValues = (
    options: DropDownMultiCheckboxValue[],
    typeOfFilter: FilterType
  ) => {
    let stringValues: Array<string> = [];
    let selectedFilterValues: Array<string> = new Array<string>();
    options.forEach((item) => {
      if (item.options && item.options.length > 0) {
        let selectedChildOptionsCount = 0;
        item.options.forEach((option) => {
          if (option.isSelected && option.isSelected === true) {
            stringValues.push(option.text);
            selectedFilterValues.push(option.text);
            selectedChildOptionsCount += 1;
          }
        });
        if (item.options.length === selectedChildOptionsCount) {
          const totalCount = item.options.length;
          const startIndex = stringValues.length - totalCount;
          const deleteCount = totalCount;
          stringValues.splice(startIndex, deleteCount, item.text);
        }
      } else {
        if (item.isSelected && item.isSelected === true) {
          stringValues.push(item.text);
          selectedFilterValues.push(item.text);
        }
      }
    });
    if (typeOfFilter === FilterType.orderType) {
      if (selectedFilterValues.length === allSelectOrderTypesCount) {
        setSelectedOrderTypes(["All Order Types"]);
      } else if (selectedFilterValues.length === 0) {
        setSelectedOrderTypes(["Order Types"]);
      } else {
        setSelectedOrderTypes(stringValues);
      }
      setSelectedFilteredOrderTypes(selectedFilterValues);
    } else if (typeOfFilter === FilterType.statusType) {
      if (selectedFilterValues.length === allSelectCount) {
        setSelectedValue(["All Orders"]);
      } else if (selectedFilterValues.length === 0) {
        setSelectedValue(["Select Orders"]);
      } else {
        setSelectedValue(stringValues);
      }
      setSelectedFilterOptions(selectedFilterValues);
    }
  };

  return (
    <Grid container className="my-patient-filter-grid-container">
      <Grid
        item
        xs={isMobileScreen ? 5.5 : 3.5}
        className="my-patient-filter-grid-item"
      >
        <DropDownWithMultiCheckbox
          testId="order-type-drop-down"
          deselectAllBtnAction={() => {
            handleSelectOrDeselectOption(false, FilterType.orderType);
          }}
          handleChange={handleOrderTypeChange}
          name="drop-down"
          options={orderTypesOptions}
          paperPropsClassName="drop-down-with-multi-options-paper-props"
          placeHolder="Order Type"
          selectAllBtnAction={() => {
            handleSelectOrDeselectOption(true, FilterType.orderType);
          }}
          selectClassName="drop-down-with-multi-options"
          selectPropsClassName="drop-down-with-multi-options-select"
          showFooterButton={true}
          value={selectedOrderTypeValue}
          open={orderTypeOpenFlag}
          setOpen={setorderTypeOpenFlag}
        />
      </Grid>
      <Grid
        item
        xs={isMobileScreen ? 5.5 : 3.5}
        className="my-patient-filter-grid-item"
      >
        <DropDownWithMultiCheckbox
          testId="status-type-drop-down"
          deselectAllBtnAction={() => {
            handleSelectOrDeselectOption(false, FilterType.statusType);
          }}
          handleChange={handleChange}
          name="drop-down"
          options={dropdownOptions}
          paperPropsClassName="drop-down-with-multi-options-paper-props"
          placeHolder="Select Order"
          selectAllBtnAction={() => {
            handleSelectOrDeselectOption(true, FilterType.statusType);
          }}
          selectClassName="drop-down-with-multi-options"
          selectPropsClassName="drop-down-with-multi-options-select"
          showFooterButton={true}
          value={selectedValue}
          open={statusTypeOpenFlag}
          setOpen={setStatusTypeOpenFlag}
        />
      </Grid>
      <Grid
        item
        xs={1}
        order={isMobileScreen ? 0 : 1}
        className="my-patient-filter-close-btn"
      >
        <IconButton onClick={handleCloseButton}>
          <CloseIcon />
        </IconButton>
      </Grid>
      <Grid
        xs={isMobileScreen ? 3.5 : 0}
        className={isMobileScreen ? "margin-right" : ""}
      ></Grid>
      <Grid
        item
        xs={isMobileScreen ? 4 : 2}
        className={`has-alert-check-box ${isMobileScreen ? "margin-top" : ""}`}
      >
        <CustomCheckBox
          value={hasAlertSelected}
          checked={hasAlertSelected}
          handleChange={() => {
            setHasAlertSelected(!hasAlertSelected);
          }}
          labelText="Has alerts"
          labelClassName="has-alerts-text"
          testId="has-alerts-test"
          isDisabled={!isAlertFilterEnabled}
        />
      </Grid>
      <Grid
        item
        xs={isMobileScreen ? 3 : 2}
        className={`apply-filter-button ${isMobileScreen ? "margin-top" : ""}`}
      >
        <ExpressButton
          parentClass="apply-filter-btn"
          variant="outlined"
          clickHandler={handleApplyFilter}
          testId="apply-filter-btn-test"
        >
          {isMobileScreen ? "Apply" : "Apply Filter"}
        </ExpressButton>
      </Grid>
    </Grid>
  );
};
