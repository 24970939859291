import jsPDF from "jspdf";
import "./pickupRequestConfirm.css";
import {
  checkPopUpBlocker,
  convertStringToDate,
  formatDate,
  formatDateWithNull,
  getPdfUrlGif,
  maskProductInUse,
} from "../../../../util/utilityFunctions";
import { useHistory } from "react-router-dom";
import {
  PickUpRequestContext,
  PickUpRequestContextType,
} from "../../../../context/PickUpRequestContext";
import { useContext, useState, useEffect } from "react";
import { Button, Grid, useMediaQuery } from "@mui/material";
import { IPickUpRequest } from "../pickUpRequest.interface";
import { getCMSContent } from "../../../../util/cmsService";
import { Popup } from "../../../../core/popup/popup.component";
import { WindowService } from "../../../../util/window.service";
import { IPatient } from "../../../myPatients/patient.interface";
import defaultImage from "../../../../assets/grey_background.jpg";
import VideoDialog from "../../../helpAndSupport/faq/VideoDialog";
import { LoadingSpinner } from "../../../../core/loader/LoadingSpinner";
import { PickUpDetails } from "../pickUpDetails/pickUpDetails.component";
import ErrorPopup from "../../../../core/errorPopup/errorPopup.component";
import { ReactComponent as PrintIcon } from "../../../../assets/print.svg";
import { CMS_PICKUP_RETURN_INSTRUCTION } from "../../../../util/staticText";
import { AuthContext, AuthContextType } from "../../../../context/AuthContext";
import { getShipmentLabelPdf } from "../../../../util/pickUpOrDischargeService";
import { ReactComponent as DownloadIcon } from "../../../../assets/download.svg";
import { IVideoContent } from "../../../helpAndSupport/faq/videoContent.interface";
import { DeviceInformation } from "../deviceInformation/deviceInformation.component";
import { ExpressButton } from "../../../../core/expressButton/expressButton.component";
import WoundTitleValue from "../../../myPatients/patientAndTherapyDetails/woundProgress/addWoundAssessment/woundReviewAssessment/woundTitleValue/woundTitleValue.component";
import { ERROR_MSG_PICKUP_REQUEST_LOAD } from "../../../../util/errorMsg";
import { ERROR_MSG_POPUP_BLOCKER } from "../../../../util/errorMsg";
import { OPEN_PDF_POP_UP_BLOCKED } from "../../../../util/errorCode";

type Props = { data: IPickUpRequest; patient: IPatient; setData: any };

const PickUpRequestConfirm = ({ data, patient, setData }: Props) => {
  const AuthObj = useContext<AuthContextType | null>(AuthContext);
  const history = useHistory();
  const [failedToPrintShipmentLabel, setFailedToPrintShipmentLabel] =
    useState(false);
  const pickUpRequestObj = useContext<PickUpRequestContextType | null>(
    PickUpRequestContext
  );
  const [showLoader, setShowLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [returnInstructionsVideo, setReturnInstructionsVideo] =
    useState<IVideoContent>();
  const [returnInstructionFile, setReturnInstructionFile] = useState("");
  const [error, setError] = useState(false);
  const [errorCode, setErrorCode] = useState(true);
  const [isPopUpBlocked, setIsPopUpBlocked] = useState<boolean>(false);

  const isIPadScreen = useMediaQuery("(max-width:932px)");
  const isMobileScreen = useMediaQuery("(max-width:640px)");
  const isIPadProScreen = useMediaQuery(
    "(min-width:933px) and (max-width:1024px)"
  );

  const openOrderDetail = () => {
    if (patient) {
      history.push({
        pathname: "/home/orderOverview",
        state: {
          stateData: patient,
        },
      });
    }
  };

  const getCMSData = async () => {
    setShowLoader(true);
    const content = await getCMSContent(CMS_PICKUP_RETURN_INSTRUCTION, true);
    setShowLoader(false);
    if (content && content.succeeded && content.item !== undefined) {
      const videoDetails = content.item.returnInstructionsVideo;
      const fileDetails = content.item.returnInstructionFile;
      setReturnInstructionsVideo({
        accountId: videoDetails.accountId,
        duration: videoDetails.duration,
        order: videoDetails.order,
        text: videoDetails.text,
        poster: videoDetails.poster,
        videoId: videoDetails.videoId,
      });
      setReturnInstructionFile(fileDetails.FileLink);
    } else {
      setError(true);
      setErrorCode(content?.error?.errorCode || content?.status);
    }
  };

  const openReturnInstructions = () => {
    if (returnInstructionFile && returnInstructionFile.length > 0) {
      const windowService = new WindowService();
      windowService.openPdf(returnInstructionFile);
    }
  };

  const openReturnInstructionVideo = () => {
    setOpen(true);
  };

  const handlePrint = () => {
    window.print();
  };

  const getPickupLabel = async () => {
    setShowLoader(true);
    let reqBody = {
      WorkOrderNumber: pickUpRequestObj?.patient?.workOrderNumber!,
      serialnumber: pickUpRequestObj?.patient?.productSerialNumber!,
    };
    const shipmentLabelResObj = await getShipmentLabelPdf(reqBody);
    if (shipmentLabelResObj && shipmentLabelResObj.succeeded) {
      if (shipmentLabelResObj.succeeded) {
        const pdf = new jsPDF("landscape", "px", "legal");
        const url: any = await getPdfUrlGif(
          shipmentLabelResObj.item.shippingLabel
        );
        pdf.addImage(url, "gif", 0, 5, 0, 0);
        const windowObject = window.open(pdf.output("bloburl"));
        const result = checkPopUpBlocker(windowObject);
        setIsPopUpBlocked(result);
        setShowLoader(false);
      }
    } else {
      setShowLoader(false);
      setFailedToPrintShipmentLabel(true);
      setErrorCode(
        shipmentLabelResObj?.error?.errorCode || shipmentLabelResObj?.status
      );
    }
  };

  useEffect(() => {
    getCMSData();
  }, []);

  const spinner = () => {
    return (
      <div>
        <div className="getPickupPdf-spinner">
          <LoadingSpinner />
        </div>
      </div>
    );
  };

  return (
    <div className="pickupConfirm">
      <div>
        <Grid
          container
          display="flex"
          flexDirection="row"
          className="gridContainerRow"
        >
          <Grid item>
            <div
              className="pickupConfirmDesp-summarypage-title"
              data-testId="pickupConfirmDesp-summarypage-title"
            >
              Pickup Request Confirmation
            </div>
          </Grid>
          <Grid item className="pickupConfirmDesp-saveOrderDiv">
            <div>
              <ExpressButton
                clickHandler={() => handlePrint()}
                parentClass="pickupConfirmDesp-saveOrderBtn"
                testId="pickupConfirmDesp-saveOrderBtn"
                variant="text"
                startIcon={<DownloadIcon />}
              >
                Save & Print Confirmation
              </ExpressButton>
            </div>
          </Grid>
        </Grid>
        <div className="pickupConfirmDesp" data-testId="pickupConfirmDesp">
          Your invoice will reflect billing through{" "}
          {data.pickupRequestDate.value && data.pickupRequestDate.value !== ""
            ? formatDateWithNull(data.pickupRequestDate.value)
            : convertStringToDate(Date())}
          . You will be receiving a confirmation email for your records shortly.
          You may also print the following Order Detail:
        </div>
        <Grid
          className="pick-up-pending-grid-container"
          container
          display="flex"
          flexDirection="row"
        >
          <Grid
            className="pick-up-pending-header"
            item
            xs={
              isMobileScreen ? 12 : isIPadScreen ? 6 : isIPadProScreen ? 5.5 : 7
            }
          >
            <div className="pick-up-pending-header-note-and-print-button">
              <p className="pick-up-pending-note">
                Product pickups are performed by UPS. To prepare for pickup,
                please
                <span
                  onClick={openReturnInstructions}
                  className="pick-up-pending-note-link"
                >
                  review our return instructions
                </span>
                .
              </p>
              <Button
                classes={{ root: "button-print-label" }}
                data-testid="button-print-label"
                variant="outlined"
                onClick={getPickupLabel}
                startIcon={<PrintIcon />}
              >
                Print Shipment Label
              </Button>
            </div>
          </Grid>
          {returnInstructionsVideo && returnInstructionsVideo.videoId && (
            <Grid
              className="pick-up-pending-header"
              item
              onClick={openReturnInstructionVideo}
              xs={
                isMobileScreen
                  ? 12
                  : isIPadScreen
                  ? 6
                  : isIPadProScreen
                  ? 6.5
                  : 5
              }
            >
              <div
                className={`pick-up-pending-video ${
                  isIPadScreen ? "mobile" : ""
                }`}
              >
                <div className="pick-up-pending-video-container">
                  <div
                    className={`pick-up-pending-video-poster-div`} // pick-up-pending-video-mobile
                  >
                    <img
                      alt=""
                      className="pick-up-pending-video-poster"
                      data-testid={`pick-up-pending-video-poster`}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = defaultImage;
                      }}
                      src={returnInstructionsVideo.poster}
                    />
                  </div>
                  <div className="pick-up-pending-video-text">
                    <h4 className="pick-up-pending-video-name-title">WATCH</h4>
                    <h4 className="pick-up-pending-video-name">
                      {`${returnInstructionsVideo.text} (${returnInstructionsVideo.duration})`}
                    </h4>
                  </div>
                </div>
              </div>
            </Grid>
          )}
        </Grid>
        <div className="pickupConfirmDesp-short-form">
          <div className="pickupConfirmDesp-title">Customer</div>
          <div className="pickupConfirmDesp-rowDiv">
            <WoundTitleValue
              title={"Customer Name"}
              value={patient.facilityName}
              testID="pickupConfirmDesp-facilityName"
            />
            <WoundTitleValue
              title={"Customer Number"}
              value={patient.customerNumber!}
              testID="pickupConfirmDesp-customerNumber"
            />
          </div>
          <div className="pickupConfirmDesp-title">Patient Information</div>
          <div className="pickupConfirmDesp-rowDiv">
            <WoundTitleValue
              title={"Patient Name"}
              value={`${patient.lastName} ${patient.firstName}`}
            />
            <WoundTitleValue
              title={"Date of Birth"}
              value={formatDate(patient.dob)}
            />
          </div>
          <div className="pickupConfirmDesp-title">Product Information</div>
          <div className="pickupConfirmDesp-rowDiv">
            <WoundTitleValue
              title={"Product Name"}
              value={maskProductInUse(patient.productName!)}
              formatValue={false}
            />
            <WoundTitleValue
              title={"Product Serial #"}
              value={patient.productSerialNumber!}
              formatValue={false}
            />
          </div>
          <div className="pickupConfirmDesp-rowDiv">
            <WoundTitleValue
              title={"Rental Order #"}
              value={patient.roNumber.toString()}
              valueClassName="reviewpage-ro-no"
              onValueClick={openOrderDetail}
            />
            <WoundTitleValue
              title={"Therapy Start Date"}
              value={formatDate(patient.placementDate!)}
            />
          </div>
          <PickUpDetails
            data={data}
            setData={setData}
            patient={patient!}
            isConfirmPickUpSummary={true}
          />
          <DeviceInformation
            data={data}
            setData={setData}
            isConfirmPickUpSummary={true}
          />
        </div>
      </div>
      <ErrorPopup
        popUpStyles="shipmentLabelErrorPopup"
        errorCode={errorCode}
        errorMessage=" Your request to print the shipping label has failed. Please try again
          or contact 3M for assistance with this order"
        errorPopupFlag={failedToPrintShipmentLabel}
        handleBackButton={() => {
          setFailedToPrintShipmentLabel(false);
        }}
      />
      <ErrorPopup
        popUpStyles="error-popup-design"
        errorMessage={ERROR_MSG_PICKUP_REQUEST_LOAD}
        errorCode={errorCode}
        errorPopupFlag={error}
        handleBackButton={() => {
          setError(false);
        }}
        buttontitle="Done"
        showTitle={false}
      />
      <Popup
        closeHandler={() => {}}
        openFlag={showLoader}
        hideCloseButton={true}
        dialogParentClass="getPickupPdf-spinner-loader-pop-up"
      >
        {spinner()}
      </Popup>
      <ErrorPopup
        errorPopupFlag={isPopUpBlocked}
        popUpStyles="errorPopup"
        showTitle={false}
        handleBackButton={() => setIsPopUpBlocked(false)}
        isSupportPhoneRequired={false}
        errorMessage={ERROR_MSG_POPUP_BLOCKER}
        errorCode={OPEN_PDF_POP_UP_BLOCKED}
      />
      {open && returnInstructionsVideo && (
        <VideoDialog
          accountId={returnInstructionsVideo.accountId}
          duration={returnInstructionsVideo.duration}
          open={open}
          setOpen={setOpen}
          text={returnInstructionsVideo.text}
          videoId={returnInstructionsVideo.videoId}
        />
      )}
    </div>
  );
};

export default PickUpRequestConfirm;
