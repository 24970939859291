import "./payerDetails.css";
import { useContext, useEffect, useState } from "react";
import { Grid, InputBase } from "@mui/material";
import { INewOrder } from "../../../newOrder.interface";
import { ValidationStatus } from "../../../../../core/interfaces/input.interface";
import { MobileDisplayContext } from "../../../../../context/MobileDisplayContext";
import { CustomDropDown } from "../../../../../core/customDropdown/customDropdown.component";
import { InputWithLabel } from "../../../../../core/inputWithLabel/inputWithLabel.component";
import { InsuranceInformationValidator } from "../../insuranceInformation/insuranceInformation.validator";
import {
  NewOrderContext,
  NewOrderContextType,
} from "../../../../../context/NewOrderContext";

type Props = {
  data: INewOrder;
  setData: Function;
  isPrimaryComponent: boolean;
  insuranceTypeSelected: string;
  insuredRelation: any;
};

const PayerDetails = ({
  data,
  setData,
  isPrimaryComponent,
  insuranceTypeSelected,
  insuredRelation,
}: Props) => {
  const [validator] = useState<InsuranceInformationValidator>(
    new InsuranceInformationValidator()
  );
  const newOrderObj = useContext<NewOrderContextType | null>(NewOrderContext);
  const { isMobileScreen } = useContext(MobileDisplayContext);
  const insuranceTypeText = isPrimaryComponent
    ? "primaryInsurance"
    : "secondaryInsurance";
  const componentType =
    insuranceTypeSelected === "medicare" ? "medicare" : "medicaid";

  const validateAndSetInsuranceData = (e: any) => {
    newOrderObj?.setIsHandleChangeTriggered(true);
    const { value, name } = e.target;
    const isValid = validator.validate(value, name);
    let tempData = {
      ...data,
      [insuranceTypeText]: {
        ...data[insuranceTypeText],
        insuranceType: data[insuranceTypeText].insuranceType,
        insuranceTypeCode: data[insuranceTypeText].insuranceTypeCode,
        [insuranceTypeSelected]: {
          ...data[insuranceTypeText][componentType],
          [name]: {
            ...Object(data[insuranceTypeText][componentType])[name],
            errorMessage: null,
            value: value,
            valid: isValid!.status,
          },
        },
      },
    };
    setData(tempData);
  };

  return (
    <div className="medicare-container">
      <Grid container spacing={2} className="medicare-grid">
        <Grid item xs={isMobileScreen ? 12 : 6}>
          <div>
            <InputWithLabel
              error={
                data[insuranceTypeText][componentType].memberID.valid ===
                ValidationStatus.INVALID
                  ? true
                  : false
              }
              id={data[insuranceTypeText][componentType].memberID.componentId!}
              isRequired={true}
              isShowWarning={true}
              label={data[insuranceTypeText][componentType].memberID.title!}
              testId="payer-memberid"
              warningMessage={
                data[insuranceTypeText][componentType].memberID.errorMessage
              }
            >
              <InputBase
                className="medicare-information-input"
                id={data[insuranceTypeText][componentType].memberID.id!}
                name="memberID"
                value={data[insuranceTypeText][componentType].memberID.value}
                onChange={(e: any) => validateAndSetInsuranceData(e)}
                data-testid="medicare-information-memberid"
              />
            </InputWithLabel>
          </div>
        </Grid>
        <Grid item xs={isMobileScreen ? 12 : 6}>
          <div>
            <InputWithLabel
              error={
                data[insuranceTypeText][componentType].relationShipInsured
                  .valid === ValidationStatus.INVALID
                  ? true
                  : false
              }
              id={
                data[insuranceTypeText][componentType].relationShipInsured
                  .componentId!
              }
              isRequired={false}
              isShowWarning={true}
              label={
                data[insuranceTypeText][componentType].relationShipInsured
                  .title!
              }
              labelClassName="insurance-type-title"
              testId="payer-relationshiptype"
              warningMessage={
                data[insuranceTypeText][componentType].relationShipInsured
                  .errorMessage
              }
              isDropdown={true}
            >
              <CustomDropDown
                handleChange={(e: any) => validateAndSetInsuranceData(e)}
                menuItem={insuredRelation}
                name={"relationShipInsured"}
                placeHolder="Select"
                selectpropsClassName={
                  data[insuranceTypeText][componentType].relationShipInsured
                    ?.value
                    ? "insurance-informantion-select"
                    : "placeHolder"
                }
                selectClassName={
                  data[insuranceTypeText][componentType].relationShipInsured
                    .value
                    ? "insurance-informantion-input"
                    : "placeHolder"
                }
                testId={`relationship-type`}
                value={
                  data[insuranceTypeText][componentType].relationShipInsured
                    .value
                }
              />
            </InputWithLabel>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default PayerDetails;
