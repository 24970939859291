import { Grid, useMediaQuery } from "@mui/material";
import { useContext, useState } from "react";
import quantityminusactive from "../../../../assets/quantityminusactive.svg";
import quantityplusactive from "../../../../assets/quantityplusactive.svg";
import {
  SubmitProofOfDeliveryContext,
  SubmitProofOfDeliveryContextType,
} from "../../../../context/submitProofOfDeliveryContext";
import { ProofOfDeliveryDocumentUpload } from "../proofOfDeliveryDocumentUpload/proofOfDeliveryDocumentUpload.component";
import { ProofOfDeliveryESignature } from "../proofOfDeliveryESign/proofOfDeliveryESign.component";
import { ProofOfDeliveryFax } from "../submitProofOfDeliveryFax/proofOfDeliveryFax.component";
import "./proofOfDeliveryOptions.css";
import { IProofOfDeliveryOptions } from "./proofOfDeliveryOptions.interface";

export const ProofOfDeliveryOptions = ({
  options,
  setIsUploadSuccess,
  setIsLoading,
  setOpenLoaderPopUp,
}: {
  options: IProofOfDeliveryOptions[];
  setIsUploadSuccess: Function;
  setIsLoading: Function;
  setOpenLoaderPopUp: Function;
}) => {
  const submitProofOfDeliveryObj =
    useContext<SubmitProofOfDeliveryContextType | null>(
      SubmitProofOfDeliveryContext
    );
  const [editESignatureMode, setEditESignatureMode] = useState(false);
  const isMobileScreen = useMediaQuery("(max-width:600px)");
  const onButtonClick = (optionTitle: string) => {
    submitProofOfDeliveryObj?.setIsUploadActive(
      (prevActiveOptions: string[]) => {
        if (prevActiveOptions.includes(optionTitle)) {
          return prevActiveOptions.filter(
            (activeOption) => activeOption !== optionTitle
          );
        } else {
          return [...prevActiveOptions, optionTitle];
        }
      }
    );
  };

  return (
    <div className="proofOfDeliveryOptions">
      <Grid container className="pod-container">
        {options.map((option) => (
          <>
            <Grid item>
              <img
                data-testid="minus-button-disabled"
                src={
                  submitProofOfDeliveryObj?.isUploadActive.includes(
                    option.optionTitle
                  )
                    ? quantityminusactive
                    : quantityplusactive
                }
                alt={option.optionTitle}
                className="pod-option-icon"
                onClick={() => onButtonClick(option.optionTitle)}
              />
            </Grid>
            <Grid
              item
              xs={isMobileScreen ? 10 : 6}
              className="pod-option-title-grid"
            >
              <span className="pod-option-title"> {option.optionTitle}</span>
            </Grid>
            <Grid item xs={12}>
              <div className="pod-option-desc">{option.optionDescription}</div>
            </Grid>
            {submitProofOfDeliveryObj?.isUploadActive.includes(
              option.optionTitle
            ) && (
              <Grid item xs={12}>
                {option.optionTitle === "Electronic signature (E-Sign)" ? (
                  <div>
                    {submitProofOfDeliveryObj &&
                      submitProofOfDeliveryObj.patient && (
                        <ProofOfDeliveryESignature
                          patientData={submitProofOfDeliveryObj.patient}
                          setIsLoading={setOpenLoaderPopUp}
                          editESignatureMode={editESignatureMode}
                          setEditESignatureMode={setEditESignatureMode}
                        />
                      )}
                  </div>
                ) : option.optionTitle === "Document Upload" ? (
                  <div data-testid="pod-upload-test">
                    <ProofOfDeliveryDocumentUpload
                      setIsUploadSuccess={setIsUploadSuccess}
                      setIsLoading={setOpenLoaderPopUp}
                    />
                  </div>
                ) : (
                  <div data-testid="pod-fax-test">
                    <ProofOfDeliveryFax setIsLoading={setOpenLoaderPopUp} />
                  </div>
                )}
              </Grid>
            )}
          </>
        ))}
      </Grid>
    </div>
  );
};
