import { Button, Grid, InputBase, useMediaQuery } from "@mui/material";
import { ExpressButton } from "../../../../../../../core/expressButton/expressButton.component";
import { AddSalePersonToTerritory } from "../addTerritorySalePersonContainer/addTerritorySalePerson.enum";
import "./addSalePersonDetailList.css";
import {
  IAddSalesPersonDetailList,
  ISalesPersonAPIData,
} from "./addSalePersonDetailList.interface";
import { makeCapitalEachWordInString } from "../../../../../../../util/utilityFunctions";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
export const AddSalesPersonDetailList = ({
  data,
  setSalesMngUserData,
  changeFormHandler,
  setOpenpopup,
}: IAddSalesPersonDetailList) => {
  const [searchInput, setSearchInput] = useState<string>("");
  const [debouncedText] = useDebounce(searchInput, 500);
  const [originalData, setOriginalData] = useState<ISalesPersonAPIData[]>(data);
  const [sortData, setSortData] = useState<ISalesPersonAPIData[]>(data);
  const isMobileScreen = useMediaQuery("(max-width:600px)");

  const handleSearch = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const re = /^[a-zA-Z]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setSearchInput(e.target.value);
    }
  };

  const filteredUsers = (searchParam: string) => {
    if (originalData && originalData.length > 0) {
      let filteredUsers = originalData;

      if (searchParam.length > 0) {
        filteredUsers = filteredUsers.filter(
          (obj: ISalesPersonAPIData) =>
            obj.firstName
              .toLocaleLowerCase()
              .includes(searchParam.toLowerCase()) ||
            obj.lastName
              .toLocaleLowerCase()
              .includes(searchParam.toLowerCase()) ||
            JSON.stringify(
              obj.territories.map((rest) => {
                return rest.split("-")[1];
              })
            )
              .toLocaleLowerCase()
              .includes(searchParam.toLowerCase())
        );
      }
      setSortData(filteredUsers);
    }
  };

  const selectOpen = (selectedSalesPersonData: ISalesPersonAPIData | null) => {
    setSalesMngUserData(selectedSalesPersonData);
    changeFormHandler(AddSalePersonToTerritory.ADD_SALE_PERSON_DETAILS);
  };

  useEffect(() => {
    filteredUsers(debouncedText);
  }, [debouncedText]);

  return (
    <Grid className="salesPersonListContainer">
      <p className="title" data-testId="title-test-Id">
        Add Salesperson to this Territory
      </p>
      <div className="territory-user-searchbar">
        <div className="territory-search-icon-div">
          <SearchIcon className="territory-search-icon" />
        </div>
        <InputBase
          className="territory-user-search-input"
          data-testid="territory-user-search-input"
          name="search-input"
          onChange={handleSearch}
          placeholder="Filter by Salesperson or Territory Name"
          value={searchInput}
        />
      </div>
      <Grid className="salesPersonListContainerList">
        <>
          {sortData && sortData.length > 0 ? (
            sortData.map(
              (salesPersonObj: ISalesPersonAPIData, index: number) => {
                return (
                  <>
                    <Grid
                      item
                      xs={isMobileScreen ? 12 : 2}
                      className={`salesPersonList${
                        index % 2 === 1 ? " gray" : ""
                      }`}
                    >
                      <Button
                        className="selectButton"
                        onClick={() => {
                          selectOpen(salesPersonObj);
                        }}
                        data-testid={"add-sales-territory"}
                        disabled={salesPersonObj.isCurrentlyAssigned}
                      >
                        Select
                      </Button>
                      <Grid
                        container
                        className="territory-detail-container-main"
                      >
                        <Grid
                          className="territoriesDetails-main"
                          item
                          xs={isMobileScreen ? 12 : 10}
                        >
                          <div className="territoriesDetails">
                            {makeCapitalEachWordInString(
                              salesPersonObj.firstName
                            ) +
                              " " +
                              makeCapitalEachWordInString(
                                salesPersonObj.lastName
                              )}
                          </div>
                          <div className="territoryRegionmain">
                            <div className="territoryRegion">
                              Sales Territory
                            </div>
                            <div>
                              {salesPersonObj.territories.map(
                                (terriToryObj: any) => {
                                  return (
                                    <div className="territoriesDetails">
                                      {terriToryObj}
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </Grid>
                        {salesPersonObj.isCurrentlyAssigned && (
                          <Grid
                            className="territoryRegion"
                            item
                            xs={isMobileScreen ? 12 : 2}
                          >
                            Currently Assigned
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </>
                );
              }
            )
          ) : (
            <div className="noRecords">No records to display. </div>
          )}
        </>
      </Grid>
      <Grid className="salesPersonListBtnGroup">
        <ExpressButton
          parentClass="salesPersonListCancelBtn"
          variant="outlined"
          clickHandler={() => {
            setOpenpopup(false);
          }}
        >
          Cancel
        </ExpressButton>
      </Grid>
    </Grid>
  );
};
