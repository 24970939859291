export enum NewOrderPageSection {
  NEWORDER_PATIENT_INFO = "patientinfo",
  NEWORDER_PATIENT_WOUND_INFO = "patientwoundinfo",
  NEWORDER_REVIEW_ORDER = "revieworder",
  NEWORDER_SUMMARY = "newordersummary",
}

export enum UploadDocumentAction {
  ADDITION = 1,
  DELETION = 2,
  UPDATION = 3,
}
