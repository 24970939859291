import { Grid } from "@mui/material";
import { makeCapitalEachWordInString } from "../../../../../util/utilityFunctions";
import { IUnlinkedUser } from "../../manageUserAccounts.interface";
import "./unlinkedUsersRequestedFacility.css";

type Props = {
  requestedFacility: IUnlinkedUser;
  facilityFound?: boolean;
};

export const UnlinkedUsersRequestedFacility = ({
  requestedFacility,
  facilityFound = false,
}: Props) => {
  return (
    <div className="parent-div">
      <div className="unlinkedUsers-requestedFacility-component ">
        <Grid
          container
          classes={{ root: "unlinkedUsers-requestedFacility-grid-container" }}
        >
          <Grid
            item
            xs={12}
            className="unlinkedUsers-requestedFacility-grid-item"
          >
            <div
              className="requested-facility"
              data-testid="requested-facility"
            >
              <span className="unlinkedUsers-requestedFacility-title">
                Requested Facility
              </span>
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            className="unlinkedUsers-requestedFacility-grid-item"
          >
            <div className="requested-facility-name">
              <span
                className="requested-facility-name-value"
                data-testid="unlinkedUsers-requestedFacility-title-product-name"
              >
                {makeCapitalEachWordInString(requestedFacility.facilityName)}
              </span>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            className="unlinkedUsers-requestedFacility-grid-item"
          >
            <div className="requested-facility-address">
              <span className="requested-facility-address-value">
                {`${makeCapitalEachWordInString(
                  requestedFacility.facilityAddress1
                )}, ${
                  requestedFacility.facilityAddress2 &&
                  requestedFacility.facilityAddress2 !== ""
                    ? `${makeCapitalEachWordInString(
                        requestedFacility.facilityAddress2
                      )},`
                    : ""
                } ${makeCapitalEachWordInString(
                  requestedFacility.facilityCity
                )}, ${requestedFacility.facilityState} ${
                  requestedFacility.facilityZipCode
                }`}
              </span>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="last-div">
        {facilityFound ? (
          <span className="last-div-msg">
            Select the facility that matches the request or search again.
          </span>
        ) : (
          <span className="last-div-msg">
            To find a facility that matches the request, search with either of
            the criteria sets below.
          </span>
        )}
      </div>
    </div>
  );
};
