import "./submitProofOfServiceOptions.css";
import { Grid, useMediaQuery } from "@mui/material";
import { useContext, useState } from "react";
import {
  ExchangeVacUnitContext,
  ExchangeVacUnitContextType,
} from "../../../../context/ExchangeVacUnitContext";
import quantityminusactive from "../../../../assets/quantityminusactive.svg";
import quantityplusactive from "../../../../assets/quantityplusactive.svg";
import { ProofOfServiceDocumentUpload } from "../submitProofOfServiceDocumentUpload/proofOfServiceDocumentUpload.component";
import { ProofOfServiceFax } from "../submitProofOfServiceFax/submitProofOfServiceFax.component";
export interface IProofOfExchangeOptions {
  optionTitle: string;
  optionDescription: string;
}

export const ProofOfServiceOptions = ({
  options,
  setIsLoading,
  setOpenLoaderPopUp,
}: {
  options: IProofOfExchangeOptions[];
  setIsLoading: Function;
  setOpenLoaderPopUp: Function;
}) => {
  const exchangeVacUnitObj = useContext<ExchangeVacUnitContextType | null>(
    ExchangeVacUnitContext
  );
  const isMobileScreen = useMediaQuery("(max-width:600px)");
  const onButtonClick = (optionTitle: string) => {
    exchangeVacUnitObj?.setIsUploadActive((prevActiveOptions: string[]) => {
      if (prevActiveOptions.includes(optionTitle)) {
        return prevActiveOptions.filter(
          (activeOption) => activeOption !== optionTitle
        );
      } else {
        return [...prevActiveOptions, optionTitle];
      }
    });
  };
  return (
    <div className="proofOfServiceOptions">
      <Grid container className="pos-option-container">
        {options.map((option) => (
          <>
            <Grid item>
              <img
                data-testid="minus-button-disabled"
                src={
                  exchangeVacUnitObj?.isUploadActive.includes(
                    option.optionTitle
                  )
                    ? quantityminusactive
                    : quantityplusactive
                }
                alt={option.optionTitle}
                className="pos-option-icon"
                onClick={() => onButtonClick(option.optionTitle)}
              />
            </Grid>
            <Grid
              item
              xs={isMobileScreen ? 10 : 6}
              className="pos-option-title-grid"
            >
              <span className="pos-option-title"> {option.optionTitle}</span>
            </Grid>
            <Grid item xs={12}>
              <div className="pos-option-desc">{option.optionDescription}</div>
            </Grid>
            {exchangeVacUnitObj?.isUploadActive.includes(
              option.optionTitle
            ) && (
              <Grid className="pos-options" item xs={12}>
                {option.optionTitle === "Document Upload" ? (
                  <div data-testid="pos-upload-test">
                    <ProofOfServiceDocumentUpload
                      setIsLoading={setOpenLoaderPopUp}
                    />
                  </div>
                ) : (
                  <div data-testid="pos-fax-test">
                    <ProofOfServiceFax setIsLoading={setOpenLoaderPopUp} />
                  </div>
                )}
              </Grid>
            )}
          </>
        ))}
      </Grid>
    </div>
  );
};
