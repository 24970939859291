export enum IInPatientOrderPopupSection {
  RE_SUBMIT_ACUTE_ORDER = "re-submit-acute-order",
  RE_SUBMIT_WOUND_IFNO_ACUTE_ORDER = "re-submit-wound-order",
  SUCCESS_ACUTE_ORDER = "success-submit-order",
}

export interface IAcuteOrderFailurePopup {
  popupTitle: string;
  popupDescription1p1: string;
  popupDescription1p2: string;
  popUpDescription2: string;
  popUpButton1Title: string;
  popUpButton2Title: string;
}
