import { useState } from "react";
import "./enterPickupInformation.css";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { ERROR_TO_SELECT_ANY_OPTION } from "../../../../util/errorMsg";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import {
  Grid,
  InputBase,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { ValidationStatus } from "../../../../core/interfaces/input.interface";
import { ReactComponent as CalendarIcon } from "../../../../assets/calendar.svg";
import { IAcuteOrderPickUpRequest } from "../../acuteOrderPickUpRequest.interface";
import { IEnterPickUpInformationLocation } from "./enterPickupInformation.interface";
import { InputWithLabel } from "../../../../core/inputWithLabel/inputWithLabel.component";
import { CustomDropDown } from "../../../../core/customDropdown/customDropdown.component";
import { AcuteOrderPickUpRequestValidator } from "../../acuteOrderPickUpRequest.validatior";
import { convertStringToDate } from "../../../../util/utilityFunctions";
import moment from "moment";
import { STATIC_TEXT_FOR_CUSTOMER_OWNED_PRODUCT } from "../../../../util/staticText";

export interface IEnterPickupLocation {
  data: IAcuteOrderPickUpRequest;
  enterPickUpInformation: IEnterPickUpInformationLocation;
  setData: Function;
  storageLocations: string[];
  isCustomerOwnedProduct?: boolean;
  placementDate?: string;
}

export const EnterPickupInformation = ({
  data,
  enterPickUpInformation,
  setData,
  storageLocations,
  isCustomerOwnedProduct = false,
  placementDate,
}: IEnterPickupLocation) => {
  const [focusClasses, setFocusClasses] = useState({
    therapyDischargeDate: "",
  });

  const setClasses = (e: any, classname: string) => {
    setFocusClasses(
      Object.assign({}, focusClasses, { [e.target.name]: classname })
    );
  };
  const isMobileScreen = useMediaQuery("(max-width:600px)");
  const currentDate = new Date().toString();
  const validateAndSetData = (e: any) => {
    const { name, value } = e.target;
    const validator = new AcuteOrderPickUpRequestValidator();
    const isValid = validator.validate(value, name);
    if (
      name === "specialInstructions" &&
      isValid!.status === ValidationStatus.INVALID &&
      value.length > 100
    ) {
      return;
    }
    const tempData = {
      ...data,
      enterPickUpInformation: {
        ...data.enterPickUpInformation,
        value: {
          ...data.enterPickUpInformation.value,
          [name]: {
            ...Object(enterPickUpInformation)[name],
            errorMessage: null,
            valid: isValid!.status,
            value: value,
          },
        },
      },
    };
    setData(tempData);
  };
  const validateAndSetDate = (date: string | null | undefined) => {
    const name = "therapyDischargeDate";
    const formatteddate = convertStringToDate(date);
    const validator = new AcuteOrderPickUpRequestValidator();
    const isValid = validator.validate(formatteddate!, "therapyDischargeDate");
    const tempData = {
      ...data,
      enterPickUpInformation: {
        ...data.enterPickUpInformation,
        value: {
          ...data.enterPickUpInformation.value,
          [name]: {
            ...Object(enterPickUpInformation)[name],
            errorMessage: null,
            valid: isValid!.status,
            value: formatteddate,
          },
        },
      },
    };
    setData(tempData);
  };

  return (
    <div
      className="enter-pickup-information-component"
      data-testid="enter-pickup-information-component"
    >
      <div
        className="enter-pickup-info-title"
        data-testid="enter-pickup-info-title"
        id="enter-pickup-info-title"
      >
        Enter Pickup Information
      </div>
      <div
        className="enter-pickup-info-describtion"
        data-testid="enter-pickup-info-describtion"
        id="enter-pickup-info-describtion"
      >
        Your invoice will reflect billing through{" "}
        <span className="enter-pickup-info-date">
          {convertStringToDate(currentDate)}
        </span>
      </div>
      <Grid
        className="enter-pickup-information-grid-container"
        container
        spacing={2}
      >
        <Grid
          className="enter-pickup-information-grid-item"
          item
          xs={isMobileScreen ? 12 : 6}
        >
          <InputWithLabel
            isRequired={true}
            label={enterPickUpInformation.therapyDischargeDate.title}
            testId="acute-order-pickup-therapy-discharge-date"
            error={
              enterPickUpInformation.therapyDischargeDate.valid ===
              ValidationStatus.INVALID
            }
            warningMessage={
              enterPickUpInformation.therapyDischargeDate.errorMessage
            }
            isShowWarning={true}
          >
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                InputAdornmentProps={{
                  classes: {
                    root: "adornedRoot",
                  },
                }}
                InputProps={{
                  classes: {
                    root: `therapyDischargeDate ${
                      enterPickUpInformation.therapyDischargeDate.valid ===
                      ValidationStatus.INVALID
                        ? "showError"
                        : "noError"
                    }`,
                    input: "input",
                    notchedOutline: "outline",
                  },
                }}
                maxDate={moment().toString()}
                minDate={moment(placementDate).toString()}
                components={{ OpenPickerIcon: CalendarIcon }}
                value={enterPickUpInformation.therapyDischargeDate.value}
                onChange={(value) => {
                  validateAndSetDate(value);
                }}
                renderInput={(params) => {
                  params.error = false;
                  params.inputProps!.placeholder = "__/__/____";
                  return (
                    <TextField
                      name="therapyDischargeDate"
                      onFocus={(e) => setClasses(e, "Mui-focused")}
                      onBlur={(e) => setClasses(e, "")}
                      {...params}
                    />
                  );
                }}
              />
            </LocalizationProvider>
          </InputWithLabel>
        </Grid>
        <Grid
          className="enter-pickup-information-grid-item"
          item
          xs={isMobileScreen ? 12 : 6}
        >
          <InputWithLabel
            isRequired={false}
            label="Stop Bill Date"
            testId="acute-order-pickup-stopBill-date"
          >
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                InputAdornmentProps={{
                  classes: {
                    root: "adornedRoot",
                  },
                }}
                InputProps={{
                  classes: {
                    root: `StopBillDate noError`,
                    input: "input",
                    notchedOutline: "outline",
                  },
                }}
                disabled={true}
                components={{ OpenPickerIcon: CalendarIcon }}
                value={convertStringToDate(currentDate)}
                onChange={() => {}}
                renderInput={(params) => {
                  params.error = false;
                  params.inputProps!.placeholder = "__/__/____";
                  return (
                    <TextField
                      name="stopBillDate"
                      onFocus={() => {}}
                      onBlur={() => {}}
                      {...params}
                    />
                  );
                }}
              />
            </LocalizationProvider>
          </InputWithLabel>
        </Grid>
        {isCustomerOwnedProduct ? (
          <Grid className="enter-pickup-information-grid-item" item xs={12}>
            <span className="enter-pickup-information-static-text-description">
              {STATIC_TEXT_FOR_CUSTOMER_OWNED_PRODUCT}
            </span>
            <InputWithLabel
              error={
                enterPickUpInformation.pickupLocation.valid ===
                ValidationStatus.INVALID
              }
              id={enterPickUpInformation.pickupLocation.componentId!}
              isRequired={enterPickUpInformation.pickupLocation.required}
              isShowWarning={true}
              isDropdown={true}
              label={enterPickUpInformation.pickupLocation.title!}
              labelClassName="pickup-location-drop-down-label"
              testId={"acute-Order-pickup-location-test"}
              warningMessage={
                enterPickUpInformation.pickupLocation.errorMessage
                  ? ERROR_TO_SELECT_ANY_OPTION
                  : ""
              }
            >
              <CustomDropDown
                handleChange={validateAndSetData}
                menuItem={storageLocations}
                name="pickupLocation"
                placeHolder="Select pickup location"
                selectpropsClassName={
                  enterPickUpInformation.pickupLocation.value
                    ? "pickup-location-select"
                    : "placeHolder"
                }
                selectClassName={"therapy-device-input"}
                value={enterPickUpInformation.pickupLocation.value}
              />
            </InputWithLabel>
          </Grid>
        ) : (
          <Grid className="enter-pickup-information-grid-item" item xs={12}>
            <InputWithLabel
              error={
                enterPickUpInformation.roomNo.valid === ValidationStatus.INVALID
              }
              id={enterPickUpInformation.roomNo.componentId!}
              isRequired={true}
              isShowWarning={true}
              label={enterPickUpInformation.roomNo.title}
              testId={"acute-order-pickup-room-number-test"}
              labelClassName="pickup-location-room-number-label"
              warningMessage={enterPickUpInformation.roomNo.errorMessage}
            >
              <InputBase
                autoFocus={
                  enterPickUpInformation.roomNo.valid ===
                  ValidationStatus.UNTOUCHED
                }
                className="pickup-location-room-number-textBox"
                data-testid="acute-order-pickup-room-number-value"
                error={
                  enterPickUpInformation.roomNo.valid ===
                  ValidationStatus.INVALID
                }
                id={enterPickUpInformation.roomNo.id!}
                name="roomNo"
                onChange={validateAndSetData}
                value={enterPickUpInformation.roomNo.value}
              />
            </InputWithLabel>
          </Grid>
        )}
        <Grid className="enter-pickup-information-grid-item" item xs={12}>
          <InputWithLabel
            error={
              enterPickUpInformation.specialInstructions.valid ===
              ValidationStatus.INVALID
            }
            id={enterPickUpInformation.specialInstructions.componentId!}
            isRequired={false}
            isShowWarning={true}
            label={enterPickUpInformation.specialInstructions.title}
            testId={"acute-order-pickup-spl-instructions-test"}
            labelClassName="pickup-location-special-instructions-label"
            warningMessage={
              enterPickUpInformation.specialInstructions.errorMessage
            }
          >
            <InputBase
              autoFocus={
                data &&
                enterPickUpInformation.specialInstructions.valid ===
                  ValidationStatus.UNTOUCHED
              }
              className="pickup-location-special-instructions-textBox"
              data-testid="acute-order-pickup-spl-instructions-value"
              error={
                enterPickUpInformation.specialInstructions.valid ===
                ValidationStatus.INVALID
              }
              id={enterPickUpInformation.specialInstructions?.id!}
              name="specialInstructions"
              onChange={validateAndSetData}
              inputProps={{
                maxLength: 100,
                className:
                  enterPickUpInformation.specialInstructions.valid ===
                  ValidationStatus.INVALID
                    ? "showSpecialInstructionError"
                    : "noSpecialInstructionError",
              }}
              multiline={true}
              value={enterPickUpInformation.specialInstructions.value}
            />
          </InputWithLabel>
        </Grid>
      </Grid>
    </div>
  );
};
