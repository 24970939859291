import { ValidationStatus } from "../../../../core/interfaces/input.interface";
import { IDeliveryPreference } from "./delivveryPreference.interface";

export const defaultDeliveryPreferenceData: IDeliveryPreference = {
  needByDate: {
    componentId: "delivery-preference-need-by-date",
    id: "delivery-preference-need-by-date-input",
    order: 13,
    required: true,
    title: "Product Need By Date",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  needByTime: {
    componentId: "delivery-preference-need-by-time",
    id: "delivery-preference-need-by-time-input",
    order: 14,
    required: true,
    title: "Need By Time",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  isCallOnEstimatedArrivalTime: {
    componentId: "delivery-preference-is-call-on-estimated-arrival-time",
    id: "delivery-preference-is-call-on-estimated-arrival-time-input",
    required: true,
    order: 15,
    title: "Would you like a call with an estimated time of arrival?",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  firstName: {
    componentId: "delivery-preference-first-name",
    id: "delivery-preference-first-name-input",
    order: 16,
    required: false,
    title: "First Name",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  lastName: {
    componentId: "delivery-preference-request-last-name",
    id: "delivery-preference-last-name-input",
    order: 17,
    required: false,
    title: "Last Name",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  phoneNumber: {
    componentId: "delivery-preference-phone-number",
    id: "delivery-preference-phone-number-input",
    order: 18,
    required: false,
    title: "Phone number",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    isPhoneNumber: true,
  },
  deliveryInstructions: {
    componentId: "delivery-preference-phone-number",
    id: "delivery-preference-phone-number-input",
    order: 19,
    required: false,
    title: "Delivery Instructions",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
};
