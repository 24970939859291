import "./registeredPermissions.css";
import {
  getFacilityPermissionCategoryName,
  getFacilityPermissionName,
} from "../../../../util/utilityFunctions";
import { RegisteredPermission } from "./registeredPermissions.enum";
import { IPermissionDetails } from "../adminRolesPermissions.interface";
import { IRegisteredPermissions } from "./registeredPermissions.interface";
import { CustomCheckBox } from "../../../../core/checkBox/checkBox.component";
import { Grid, useMediaQuery } from "@mui/material";

export const RegisteredPermissions = ({
  categorisedPermissions,
  type,
  validateAndUpdateCheckBox,
}: IRegisteredPermissions) => {
  const isMobileScreen = useMediaQuery("(max-width:600px)");
  const isIPadScreen = useMediaQuery("(max-width:1024px)");

  return (
    <div className="role-permissions-component">
      <Grid className="role-permissions-grid-container" container>
        {categorisedPermissions.map((categorisedPermission, index) => (
          <Grid
            className="role-permissions-grid-item"
            item
            xs={
              isMobileScreen
                ? 12
                : isIPadScreen
                ? index === 2
                  ? 2.5
                  : 4.75
                : 4
            }
          >
            <div className="role-permissions-category-div">
              <p
                className="role-permission-category-title"
                data-testid={`role-permission-category-title-${index + 1}`}
                id={`role-permission-category-title-${index + 1}`}
              >
                {getFacilityPermissionCategoryName(
                  categorisedPermission.category
                )}
              </p>
              {categorisedPermission.permissions.map(
                (permission: IPermissionDetails, index: number) => (
                  <div className="role-permission-checkbox-div">
                    <CustomCheckBox
                      checked={
                        type === RegisteredPermission.SELF_REGISTER
                          ? permission.selfRegistered!
                          : permission.adminRegistered!
                      }
                      handleChange={(e: any) =>
                        validateAndUpdateCheckBox(e, type)
                      }
                      isDisabled={false}
                      key={index}
                      labelClassName={
                        permission.selfRegistered
                          ? "registered-permissions-checkbox-description-text-active"
                          : "registered-permissions-checkbox-description-text"
                      }
                      labelText={getFacilityPermissionName(
                        permission.permissionName
                      )}
                      name={permission.permissionName}
                      required={false}
                      selectClassName="registered-permissions-checkbox"
                      selectpropsClassName="registered-permissions-checkbox-root"
                      testId={permission.permissionName}
                      value={permission.permissionName}
                    />
                  </div>
                )
              )}
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
