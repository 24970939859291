import React from "react";
import Table from "../../../../../core/customSortingTable/table.component";
import { makeCapitalEachWordInString } from "../../../../../util/utilityFunctions";
import { IUsersSearchResultTable } from "../../manageUserAccounts.interface";
import "./usersSearchResult.css";
import { CustomDropDown } from "../../../../../core/customDropdown/customDropdown.component";
import { IoMdArrowDropleft, IoMdArrowDropright } from "react-icons/io";
import { ExpressButton } from "../../../../../core/expressButton/expressButton.component";
import Skeleton from "react-loading-skeleton";
import { Grid, useMediaQuery } from "@mui/material";

const UsersSearchResult = ({
  allPageNumbers,
  users,
  numberOfPages,
  perPageCount,
  selectedPageNumber,
  totalUsersCount,
  handleSelectChange,
  handlePreviousClick,
  handleNextClick,
  handlePageNumberClick,
  setSelectedSortField,
  setSelectedSortOrder,
  clearResult,
  skeletonLoading,
  openSelectedUserProfile,
}: IUsersSearchResultTable) => {
  const isMobileScreen = useMediaQuery("(max-width:600px)");
  const columns = [
    { label: "Email", accessor: "emailAddress", sortable: true, sortID: 1 },
    { label: "Last Name", accessor: "lastName", sortable: true, sortID: 2 },
    {
      label: "First Name",
      accessor: "firstName",
      sortable: true,
      sortID: 3,
    },
    { label: "Facility ID", accessor: "facilityId", sortable: true, sortID: 4 },
  ];

  const getFieldIDByFieldName = (selectedSortField: any) => {
    const sortID = columns
      .filter((item) => item.accessor === selectedSortField)
      .map((x: { sortID: number }) => x.sortID)[0];
    return sortID;
  };

  const handleSorting = (sortField: any, sortOrder: any) => {
    clearResult();
    setSelectedSortField(getFieldIDByFieldName(sortField));
    setSelectedSortOrder(sortOrder === "asc" ? "Asc" : "Dsc");
  };

  return (
    <div className="search-user-table">
      <div
        className="search-user-table-container"
        data-testid="search-user-table-table-container"
      >
        <Table
          tableClassName="searchuser-table"
          tableColumns={columns}
          handleSorting={handleSorting}
        >
          {skeletonLoading ? (
            [...Array(10)].map((_) => (
              <tr>
                <td className="select-result-facility">
                  <Skeleton />
                </td>
                <td className="search-result-tables_users-static-data">
                  <Skeleton />
                </td>
                <td className="search-result-tables_users-static-data">
                  <Skeleton />
                </td>
                <td className="search-result-tables_users-static-data">
                  <Skeleton />
                </td>
              </tr>
            ))
          ) : (
            <>
              {columns && users && users.length > 0 ? (
                <tbody>
                  {users.map((data: any) => {
                    return (
                      <tr>
                        <td
                          className="search-result-tables_users-link-data"
                          data-testid="selectFacilityCareSettingtest"
                          onClick={() => {
                            openSelectedUserProfile(data.emailAddress);
                          }}
                        >
                          {data.emailAddress}
                        </td>
                        <td
                          className="search-result-tables_users-static-data"
                          data-testid="selectFacilityAccountNumbertest"
                        >
                          {makeCapitalEachWordInString(`${data.lastName}`)}
                        </td>
                        <td
                          className="search-result-tables_users-static-data"
                          data-testid="selectFacilityTypeNametest"
                        >
                          {makeCapitalEachWordInString(`${data.firstName}`)}
                        </td>
                        <td
                          className="search-result-tables_users-static-data"
                          data-testid="selectFacilityCareSettingtest"
                        >
                          {makeCapitalEachWordInString(`${data.facilityId}`)}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              ) : (
                <tbody></tbody>
              )}
            </>
          )}
        </Table>
      </div>
      <Grid className="pagination-grid-container" container>
        <Grid
          className="pagination-grid-item"
          item
          order={{ xs: isMobileScreen ? 2 : 1 }}
          xs={isMobileScreen ? 7 : 4}
        >
          <div className="items-per-page-dropdown" data-testid="itemsPerPage">
            <p
              className="items-per-page-title"
              data-testid="items-per-page-title"
            >
              Items per page:
            </p>
            <CustomDropDown
              value={`${perPageCount}`}
              handleChange={handleSelectChange}
              menuItem={["25", "50", "75", "100"]}
              selectClassName="items-per-page-input"
              selectpropsClassName="items-per-page-select"
            />
          </div>
        </Grid>
        <Grid
          className="pagination-grid-item"
          item
          order={{ xs: isMobileScreen ? 1 : 2 }}
          xs={isMobileScreen ? 12 : 4}
        >
          <div className="pagination-number-list">
            <ExpressButton
              clickHandler={(e) => handlePreviousClick(e)}
              disabled={selectedPageNumber === 1 ? true : false}
              variant="text"
              parentClass={`${selectedPageNumber === 1 ? "disabled" : ""}`}
              testId="previous-button"
            >
              <IoMdArrowDropleft size={20} />
            </ExpressButton>
            {allPageNumbers.map((item, index) => {
              return (
                <li
                  key={index}
                  className={`${
                    selectedPageNumber === item ? "active" : ""
                  } list`}
                  onClick={() => handlePageNumberClick(item)}
                >
                  {item}
                </li>
              );
            })}
            <ExpressButton
              clickHandler={(e) => handleNextClick(e)}
              disabled={
                numberOfPages.length === 0 ||
                selectedPageNumber === numberOfPages.length
                  ? true
                  : false
              }
              id="buttonClass"
              parentClass={`${
                numberOfPages.length === 0 ||
                selectedPageNumber === numberOfPages.length
                  ? "disabled"
                  : ""
              }`}
              testId="next-button"
              variant="text"
            >
              <IoMdArrowDropright size={20} />
            </ExpressButton>
          </div>
        </Grid>
        <Grid
          className="pagination-grid-item"
          item
          order={{ xs: 3 }}
          xs={isMobileScreen ? 5 : 4}
        >
          <div
            className="pagination-total-counts"
            data-testid="pagination-total-counts"
          >
            <p
              className="last-section-right"
              data-testid="last-section-right"
            >{`${
              totalUsersCount === 0
                ? 0
                : (selectedPageNumber - 1) * perPageCount + 1
            }-${
              selectedPageNumber * perPageCount < totalUsersCount
                ? selectedPageNumber * perPageCount
                : totalUsersCount
            } of ${totalUsersCount} items`}</p>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default UsersSearchResult;
