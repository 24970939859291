import { Grid, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { format } from "react-string-format";
import { ReactComponent as CalendarIcon } from "../../../../../../assets/calendar.svg";
import { CustomDropDown } from "../../../../../../core/customDropdown/customDropdown.component";
import { ExpressButton } from "../../../../../../core/expressButton/expressButton.component";
import { InputWithLabel } from "../../../../../../core/inputWithLabel/inputWithLabel.component";
import { ValidationStatus } from "../../../../../../core/interfaces/input.interface";
import { getdropDownContent } from "../../../../../../util/dropDownService";
import { DD_ASSIGNMENT_CHANGE_REASON } from "../../../../../../util/staticText";
import {
  changeDateFormat,
  convertStringToDate,
  getInvalidObj,
  getValidObj,
} from "../../../../../../util/utilityFunctions";
import {
  ISaleManageUserEditData,
  ITerritory,
} from "../../salesUserProfile.interface";
import { ITerritoryDetails } from "../addSalesTerritory.interface";
import "./manualAddTerritory.css";
import { IManualAddTerritory } from "./manualAddTerritory.interface";
import { ManualAddTerritoryValidator } from "./manualAddTerritory.validator";

interface Props {
  data: IManualAddTerritory;
  setData: React.Dispatch<React.SetStateAction<IManualAddTerritory>>;
  Validator?: ManualAddTerritoryValidator;
  selectedTerritoryDetails: ITerritoryDetails;
  selectOpen: (territory: ITerritoryDetails | null) => void;
  setSelectedTerritory: Function;
  setSalesMngUserData: any;
  salesMngUserData: ISaleManageUserEditData;
  setOpenpopup: any;
}
export const ManualAddTerritories = ({
  data,
  setData,
  Validator = new ManualAddTerritoryValidator(),
  selectedTerritoryDetails,
  selectOpen,
  setSelectedTerritory,
  setSalesMngUserData,
  salesMngUserData,
  setOpenpopup,
}: Props) => {
  const [validator] = React.useState<ManualAddTerritoryValidator>(Validator);
  const validateAndSetDateStartDate = (
    date: string | null | undefined | any
  ) => {
    const formatteddate = changeDateFormat(date);
    const isValid = validator.validate(formatteddate, "startDate");
    const isValidEndDate = providedDatePlus180(
      data.endDate.value,
      formatteddate
    );
    setData({
      ...data,
      startDate: {
        value: formatteddate,
        valid: isValid?.status!,
        required: true,
      },
      endDate: {
        value: data.endDate.value,
        valid:
          data.endDate.value === ""
            ? ValidationStatus.UNTOUCHED
            : isValidEndDate?.status!,
        required: true,
      },
    });
  };
  const [focusClasses, setFocusClasses] = React.useState({
    startDate: "",
    endDate: "",
  });
  const [assignmentChangeReasonText, setAssignmentChangeReasonText] =
    useState();

  const setClasses = (e: any, classname: string) => {
    setFocusClasses(
      Object.assign({}, focusClasses, { [e.target.name]: classname })
    );
  };

  const validateAndSetDateEndDate = (date: string | null | undefined | any) => {
    if (date) {
      const formatteddate = changeDateFormat(date);
      const isValid = providedDatePlus180(formatteddate, data.startDate.value);
      setData({
        ...data,
        endDate: {
          value: formatteddate,
          valid: isValid?.status!,
          required: true,
        },
      });
    } else {
      setData({
        ...data,
        endDate: {
          value: "",
          valid: ValidationStatus.UNTOUCHED,
          required: false,
        },
      });
    }
  };

  const providedDatePlus180 = (txt: string, startDateTxt: string) => {
    const formatteddate = moment(txt);
    const startDate = moment(startDateTxt);
    if (
      startDate.isValid() &&
      formatteddate.isValid() &&
      formatteddate.isBetween(
        startDate.subtract(1, "d").format("MM/DD/YYYY"),
        startDate.add(181, "d").format("MM/DD/YYYY")
      )
    ) {
      return getValidObj();
    }
    return getInvalidObj(null);
  };

  const validateAndSetData = (e: any) => {
    setData({
      ...data,
      [e.target.name]: {
        value: e.target.value,
        valid: ValidationStatus.VALID,
        required: true,
      },
    });
  };

  useEffect(() => {
    fetchDropDownContent();
  }, []);

  const fetchDropDownContent = async () => {
    try {
      const ddContent = format("{0}", DD_ASSIGNMENT_CHANGE_REASON ?? "");
      const data = await getdropDownContent(ddContent);
      if (data.items.length > 0) {
        const assignmentReasonObj = data.items.filter(
          (item: { name: string }) => item.name === DD_ASSIGNMENT_CHANGE_REASON
        );
        const assignmentReasonData = assignmentReasonObj[0].data.sort(
          (a: { order: number }, b: { order: number }) =>
            a.order > b.order ? 1 : -1
        );
        setAssignmentChangeReasonText(
          assignmentReasonData.map((x: { text: string }) => x.text)
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const callAddTerritory = () => {
    let territoryDetails: ITerritory = {
      roleType: selectedTerritoryDetails.roleType,
      regionCode: selectedTerritoryDetails.regionDistrict,
      territoryCode: selectedTerritoryDetails.code,
      territoryName: selectedTerritoryDetails.name,
      isPrimary: false,
      regionDistrict: "",
      assignedFrom: data.startDate.value,
      assignedTo: data.endDate.value,
      reason: data.reasonForAssignmentChange.value,
    };
    setSalesMngUserData(
      salesMngUserData,
      salesMngUserData.territories.push(territoryDetails)
    );
    setOpenpopup(false);
  };

  return (
    <>
      <Grid className="addTerritoryMainContainer">
        <div
          className="changeButton"
          onClick={() => {
            setSelectedTerritory(false);
          }}
          data-testid={"add-sales-territory"}
        >
          Change
        </div>
        <Grid item className="addTerritoryDetailsBlock">
          <div className="addTerritoryDetails">Territory & Region</div>
          <div className="addTerritoryDetailsText">
            {selectedTerritoryDetails.code} - {selectedTerritoryDetails.name}
          </div>
          <div className="addTerritoryDetailsText">
            {selectedTerritoryDetails.regionDistrict}
          </div>
        </Grid>
      </Grid>
      <Grid className="assignDatesContainer">
        <div className="assignDatesTitle">Assign dates</div>
        <div className="assignDatesFields">
          <InputWithLabel
            labelClassName={focusClasses.startDate}
            label="Start Date"
            isRequired={true}
            error={data?.startDate.valid === ValidationStatus.INVALID}
          >
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                InputAdornmentProps={{
                  classes: {
                    root: "adornedRoot",
                  },
                }}
                InputProps={{
                  classes: {
                    root: `startDate ${
                      data?.startDate.valid === ValidationStatus.INVALID
                        ? "showError"
                        : "noError"
                    }`,
                    input: "input",
                    notchedOutline: "outline",
                  },
                }}
                components={{ OpenPickerIcon: CalendarIcon }}
                value={data?.startDate.value}
                onChange={(value) => validateAndSetDateStartDate(value)}
                minDate={moment().toString()}
                maxDate={moment().add(179, "d").toString()}
                renderInput={(params) => {
                  params.error = false;
                  params.inputProps!.placeholder = "__/__/____";
                  return (
                    <TextField
                      name="startDate"
                      onFocus={(e) => setClasses(e, "Mui-focused")}
                      onBlur={(e) => setClasses(e, "")}
                      {...params}
                    />
                  );
                }}
              />
            </LocalizationProvider>
          </InputWithLabel>
          <InputWithLabel
            labelClassName={focusClasses.endDate}
            label="End Date"
            isRequired={false}
            error={data?.endDate.valid === ValidationStatus.INVALID}
          >
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                InputAdornmentProps={{
                  classes: {
                    root: "adornedRoot",
                  },
                }}
                InputProps={{
                  classes: {
                    root: `endDate ${
                      data?.endDate.valid === ValidationStatus.INVALID
                        ? "showError"
                        : "noError"
                    }`,
                    input: "input",
                    notchedOutline: "outline",
                  },
                }}
                components={{ OpenPickerIcon: CalendarIcon }}
                value={data?.endDate.value}
                onChange={(value) => validateAndSetDateEndDate(value)}
                minDate={
                  data.startDate.valid === ValidationStatus.VALID
                    ? moment(data.startDate.value).toString()
                    : ""
                }
                maxDate={
                  data.startDate.valid === ValidationStatus.VALID
                    ? moment(data.startDate.value).add(179, "d").toString()
                    : ""
                }
                renderInput={(params) => {
                  params.error = false;
                  params.inputProps!.placeholder = "__/__/____";
                  return (
                    <TextField
                      name="endDate"
                      onFocus={(e) => setClasses(e, "Mui-focused")}
                      onBlur={(e) => setClasses(e, "")}
                      {...params}
                    />
                  );
                }}
              />
            </LocalizationProvider>
          </InputWithLabel>
        </div>
      </Grid>
      <div className="assignReasonFields">
        <InputWithLabel
          label="Reason for assignment change"
          isRequired={true}
          error={
            data?.reasonForAssignmentChange.valid === ValidationStatus.INVALID
          }
          testId={"test-reasonForAssignmentChange"}
          isDropdown={true}
        >
          <CustomDropDown
            handleChange={(e: any) => validateAndSetData(e)}
            menuItem={assignmentChangeReasonText}
            name="reasonForAssignmentChange"
            placeHolder="Select reason"
            selectpropsClassName={
              data.reasonForAssignmentChange.value
                ? "reasonForAssignmentChange-select"
                : "placeHolder"
            }
            selectClassName={
              data.reasonForAssignmentChange.value
                ? "reasonForAssignmentChange-input"
                : "placeHolder"
            }
            testId="reasonForAssignmentChange-id"
            value={data.reasonForAssignmentChange.value}
          />
        </InputWithLabel>
      </div>
      <div className="addSaleTerritoryBtnGroup">
        <ExpressButton
          parentClass="addSaleTerritoryCancelBtn"
          variant="outlined"
          clickHandler={() => {
            setOpenpopup(false);
          }}
        >
          Cancel
        </ExpressButton>
        <ExpressButton
          disabled={
            data.startDate.valid !== ValidationStatus.VALID ||
            data.endDate.valid === ValidationStatus.INVALID ||
            data.reasonForAssignmentChange.valid !== ValidationStatus.VALID
          }
          parentClass="addSaleTerritoryAddBtn"
          variant="contained"
          clickHandler={callAddTerritory}
        >
          Add territory
        </ExpressButton>
      </div>
    </>
  );
};
