import "./siteStatusType.css";
import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  useMediaQuery,
} from "@mui/material";
import { InputWithLabel } from "../../../../../core/inputWithLabel/inputWithLabel.component";
import { ValidationStatus } from "../../../../../core/interfaces/input.interface";
import { AddSiteStatusValidator } from "../addSiteStatus.validator";
import { IAddSiteStatus } from "../addSiteStatus.interface";
import { ERROR_TO_SELECT_ANY_OPTION } from "../../../../../util/errorMsg";
import { StatusType } from "../../manageSiteStatuses.interface";
import { ReactComponent as RadioButtonIcon } from "../../../../../assets/radioButton.svg";
import { ReactComponent as SelectedRadioButtonIcon } from "../../../../../assets/selectedRadioButton.svg";

export interface ISiteStatusType {
  data: IAddSiteStatus;
  Validator?: AddSiteStatusValidator;
  setData: Function;
}

export const SiteStatusType = ({ data, setData }: ISiteStatusType) => {
  const isMobileScreen = useMediaQuery("(max-width:920px)");

  const setStatusButtonAction = async (e: any) => {
    setData({
      ...data,
      statusType: {
        isDefaultValid: true,
        value: "yes",
      },
    });
  };
  return (
    <div
      className="site-status-type-component"
      data-testid="site-status-type-component"
    >
      <span
        className="site-status-type-header"
        data-testid="site-status-type-header"
      >
        Status type
      </span>
      <Grid container className="site-status-type-grid-container">
        <Grid
          className="site-status-type-grid-item"
          item
          xs={isMobileScreen ? 12 : 9}
        >
          <InputWithLabel
            error={data.statusType.valid === ValidationStatus.INVALID}
            id={data.statusType.componentId!}
            isRequired={true}
            isShowWarning={true}
            label="What kind of status change would you like to make?"
            labelClassName="site-status-type-label"
            testId="site-status-type-label"
            warningMessage={
              data.statusType.errorMessage ? ERROR_TO_SELECT_ANY_OPTION : ""
            }
          >
            <div className="site-status-type-radio-button-div">
              <RadioGroup
                name="status"
                classes={{ root: "radioRoot site-status-type-radio-title" }}
                onChange={setStatusButtonAction}
                value={data?.statusType.value}
                data-testid={"site-status-type-radio-button"}
              >
                <FormControlLabel
                  classes={{
                    root:
                      data?.statusType.value === StatusType.MESSAGE
                        ? "optionRoot-active"
                        : "optionRoot",
                  }}
                  control={
                    <Radio
                      icon={<RadioButtonIcon />}
                      checkedIcon={<SelectedRadioButtonIcon />}
                      data-testid={"site-status-type-radio-test-image"}
                    />
                  }
                  data-testid="site-status-type-radio-title"
                  label="Post a message"
                  value="message"
                />
              </RadioGroup>
            </div>
            <div
              className="site-status-type-radio-description-div"
              data-testid="site-status-type-radio-description-div"
            >
              <span
                className="site-status-type-radio-description"
                data-testid="site-status-type-radio-description"
              >
                Show a banner message on selected pages to convey news or
                updates. Allow users to login and perform actions accordingly.
              </span>
            </div>
          </InputWithLabel>
        </Grid>
      </Grid>
    </div>
  );
};
