import "./selectedPrescriber.css";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import {
  formatNumber,
  makeCapitalEachWordInString,
} from "../../../../util/utilityFunctions";
import { IPrescriberModal } from "../../../newOrder/prescriberInformation/prescriberSearch/prescriberSearch.model";

export interface ISelectedPrescriber {
  data: IPrescriberModal | null;
}
export const SelectedPrescriber = ({ data }: ISelectedPrescriber) => {
  const isMobileScreen = useMediaQuery("(max-width:920px)");
  return (
    <>
      <div
        className="selected-prescriber-component"
        data-testid="selected-prescriber-component"
        id="selected-prescriber-component"
      >
        <div className="short-form">
          <h2
            className="current-selected-prescriber-title"
            data-testid="current-selected-prescriber-title"
            id="current-selected-prescriber-title"
          >
            Current Selected Prescriber
          </h2>
          {data && data.npi ? (
            <>
              <Grid container className={"selected-precriber-details-grid"}>
                <Grid item xs={12}>
                  <div
                    className="selected-prescriber-name"
                    data-testid="selected-prescriber-name"
                    id="selected-prescriber-name"
                  >
                    {makeCapitalEachWordInString(data.firstName)}{" "}
                    {makeCapitalEachWordInString(data.lastName)}
                  </div>
                </Grid>
                <Grid item xs={isMobileScreen ? 12 : 4}>
                  <div className="selected-prescriber-div">
                    <span
                      className="selected-prescriber-sub-header"
                      data-testid="selected-prescriber-npi-title"
                    >
                      NPI
                    </span>
                    <div
                      className="selected-prescriber-value"
                      data-testid="selected-prescriber-npi-value"
                    >
                      {data.npi === "" || data.npi === null ? "--" : data.npi}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={isMobileScreen ? 6 : 4}>
                  <div className="selected-prescriber-div-left">
                    <span
                      className="selected-prescriber-sub-header"
                      data-testid="selected-prescriber-phone-title"
                    >
                      Phone
                    </span>
                    <div
                      className="selected-prescriber-value"
                      data-testid="selected-prescriber-phone-value"
                    >
                      {data.telephoneNumber === "" ||
                      data.telephoneNumber === null
                        ? "--"
                        : formatNumber(data.telephoneNumber)}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={isMobileScreen ? 6 : 4}>
                  <div className="selected-prescriber-div">
                    <span
                      className="selected-prescriber-sub-header"
                      data-testid="selected-prescriber-fax-title"
                    >
                      {" "}
                      Fax
                    </span>
                    <div
                      className="selected-prescriber-value"
                      data-testid="selected-prescriber-fax-value"
                    >
                      {data.faxNumber === "" || data.faxNumber === null
                        ? "--"
                        : data.faxNumber}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={isMobileScreen ? 6 : 4}>
                  <div
                    className="selected-prescriber-div-left"
                    data-testid="selected-prescriber-address-title"
                  >
                    <span className="selected-prescriber-sub-header">
                      {" "}
                      Address
                    </span>
                    <div
                      className="selected-prescriber-value"
                      data-testid="selected-prescriber-address-line1"
                    >
                      {makeCapitalEachWordInString(data.address1!)}
                    </div>
                    <div
                      className="selected-prescriber-value"
                      data-testid="selected-prescriber-address-line2"
                    >
                      {makeCapitalEachWordInString(data.address2!)}
                    </div>
                    <div
                      className="selected-prescriber-value"
                      data-testid="selected-prescriber-address-zip"
                    >
                      {makeCapitalEachWordInString(data.city!)}, {data.state}{" "}
                      {data.zipCode}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={isMobileScreen ? 6 : 8}>
                  <div className="selected-prescriber-div">
                    <span
                      className="selected-prescriber-sub-header"
                      data-testid="selected-prescriber-email-title"
                    >
                      Email
                    </span>
                    <div
                      className="selected-prescriber-value"
                      data-testid="selected-prescriber-email-value"
                    >
                      {data.email === "" || data.email === null
                        ? "--"
                        : data.email}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Grid container className={"selected-precriber-details-grid"}>
                <Grid item xs={12}>
                  <Typography
                    className="selected-precriber-details-no-data"
                    data-testid="selected-precriber-details-no-data"
                    id="selected-precriber-details-no-data"
                  >
                    No Prescriber has been associated to this order.
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
        </div>
      </div>
    </>
  );
};
