import "./reviewAcuteOrderPickUpRequest.css";
import { IPatient } from "../../myPatients/patient.interface";
import { IAcuteOrderPickUpRequest } from "../acuteOrderPickUpRequest.interface";
import { EnterPickupInformationReview } from "../components/enterPickupInformation/reviewPage/enterPickupInformationReview.component";
import { EstimatedArrivalTime } from "../../inventory/requestService/createServiceRequest/estimatedArrivalTime/estimatedArrivalTime.component";
import { DeviceComplications } from "../../inventory/requestService/createServiceRequest/deviceComplications/deviceComplications.component";
import { PatientInformationReviewOrder } from "../components/patientInformation/reviewOrder/patientInformationReviewOrder.component";

interface IAcuteOrderPickUpRequestReviewPage {
  data: IAcuteOrderPickUpRequest;
  editButtonAction: Function;
  isReviewOrder: boolean;
  patient: IPatient;
  setData: Function;
  isCustomerOwnedProduct: boolean;
}

export const AcuteOrderPickUpRequestReviewPage = ({
  data,
  editButtonAction,
  isReviewOrder,
  patient,
  setData,
  isCustomerOwnedProduct = false,
}: IAcuteOrderPickUpRequestReviewPage) => {
  return (
    <div
      className="acute-order-pickUp-request-review-page-component"
      data-testid="acute-order-pickUp-request-review-page-component"
    >
      <div className="short-form">
        <p
          className="acute-order-pickUp-request-review-page-title"
          data-testid="acute-order-pickUp-request-review-page-title"
          id="acute-order-pickUp-request-review-page-title"
        >
          Review Pickup/Discharge Request
        </p>
        <p
          className="acute-order-pickUp-request-page-description"
          data-testid="acute-order-pickUp-request-page-description"
          id="acute-order-pickUp-request-page-description"
        >
          Please verify the data provided below is accurate before placing your
          request.
        </p>
        <PatientInformationReviewOrder patient={patient} />
        <EnterPickupInformationReview
          data={data.enterPickUpInformation.value}
          editButtonAction={editButtonAction}
          isCustomerOwnedProduct={isCustomerOwnedProduct}
        />
        <EstimatedArrivalTime
          data={data}
          editButtonAction={editButtonAction}
          isReviewRequest={isReviewOrder}
          setData={setData}
          isCustomerOwnedProduct={isCustomerOwnedProduct}
        />
        <DeviceComplications
          data={data}
          editButtonAction={editButtonAction}
          isReviewRequest={isReviewOrder}
          setData={setData}
        />
      </div>
    </div>
  );
};
