import { createContext, useState } from "react";
import { MyListsTabs } from "../components/administration/myLists/myLists.enum";
import { IMyListPrescriber } from "../components/administration/myLists/myListPrescribers/myListPrescriber.interface";
import { SearchPrescriberModal } from "../components/newOrder/prescriberInformation/searchPrescriber.enum";
import { ICaregiverFacility } from "../core/caregiverSearchAndAdd/caregiverFacilitySearchAndAdd.model";

export type AdminMyListsContextType = {
  selectedMyListsTab: string | null;
  setSelectedMyListsTab: React.Dispatch<React.SetStateAction<string | null>>;
  resetContextData: () => void;
  resetData: () => void;
  resetTab: () => void;
  addPrescriberPopup: boolean;
  setAddPrescriberPopup: React.Dispatch<React.SetStateAction<boolean>>;
  addPrescriberPopupSection: string;
  setAddPrescriberPopupSection: React.Dispatch<React.SetStateAction<string>>;
  npiPrescriberTabList: IMyListPrescriber[];
  setNpiPrescriberTabList: React.Dispatch<
    React.SetStateAction<IMyListPrescriber[]>
  >;
  isFromMyList: boolean;
  setIsFromMyList: React.Dispatch<React.SetStateAction<boolean>>;
  isFromMyListHcp: boolean;
  setIsFromMyListHcp: React.Dispatch<React.SetStateAction<boolean>>;
  isPrescriberAddedAlready: boolean;
  setIsPrescriberAddedAlready: React.Dispatch<React.SetStateAction<boolean>>;
  isMyListHcpAddedAlready: boolean;
  setIsMyListHcpAddedAlready: React.Dispatch<React.SetStateAction<boolean>>;
  hcpTabList: any;
  setHcpTabList: React.Dispatch<React.SetStateAction<any>>;
};
type AdminMyListsContextProviderProps = {
  children: React.ReactNode;
};

export const AdminMyListsContext =
  createContext<AdminMyListsContextType | null>(null);

export const AdminMyListsContextProvider = ({
  children,
}: AdminMyListsContextProviderProps) => {
  const [selectedMyListsTab, setSelectedMyListsTab] = useState<string | null>(
    MyListsTabs.HOME_CARE_PROVIDER
  );
  const [addPrescriberPopup, setAddPrescriberPopup] = useState<boolean>(false);
  const [addPrescriberPopupSection, setAddPrescriberPopupSection] =
    useState<string>(SearchPrescriberModal.NATIONAL_REGISTRY_SEARCH);
  const [npiPrescriberTabList, setNpiPrescriberTabList] = useState<
    IMyListPrescriber[]
  >([]);
  const [isFromMyList, setIsFromMyList] = useState<boolean>(false);
  const [isPrescriberAddedAlready, setIsPrescriberAddedAlready] =
    useState<boolean>(false);
  const [isMyListHcpAddedAlready, setIsMyListHcpAddedAlready] =
    useState<boolean>(false);
  const [isFromMyListHcp, setIsFromMyListHcp] = useState<boolean>(false);
  const [hcpTabList, setHcpTabList] = useState<any>([]);
  const resetContextData = () => {
    resetData();
    resetTab();
  };

  const resetData = () => {};

  const resetTab = () => {
    setSelectedMyListsTab(MyListsTabs.HOME_CARE_PROVIDER);
  };

  return (
    <AdminMyListsContext.Provider
      value={{
        selectedMyListsTab,
        setSelectedMyListsTab,
        resetContextData,
        resetData,
        resetTab,
        addPrescriberPopup,
        setAddPrescriberPopup,
        addPrescriberPopupSection,
        setAddPrescriberPopupSection,
        npiPrescriberTabList,
        setNpiPrescriberTabList,
        isFromMyList,
        setIsFromMyList,
        isPrescriberAddedAlready,
        setIsPrescriberAddedAlready,
        isMyListHcpAddedAlready,
        setIsMyListHcpAddedAlready,
        isFromMyListHcp,
        setIsFromMyListHcp,
        hcpTabList,
        setHcpTabList,
      }}
    >
      {children}
    </AdminMyListsContext.Provider>
  );
};
