import "./errorInfo.css";

type Props = {
  errorCode: any;
};

export const ErrorInfo = ({ errorCode }: Props) => {
  return (
    <div>
      <p className="errorCodeInfo">Error Code : {errorCode}</p>
    </div>
  );
};
