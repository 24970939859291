import moment from "moment";
import { ValidationStatus } from "../../../../core/interfaces/input.interface";
import { getDeepClone } from "../../../../util/ObjectFunctions";
import { ISecondaryWoundInfo } from "../../clinicalInformation/secondaryWoundInfo/secondaryWoundInfo.interface";
import { defaultNewOrdeSecondaryWoundInfoData } from "../../clinicalInformation/secondaryWoundInfo/secondaryWoundInfo.model";
import { INewOrder } from "../../newOrder.interface";
import { defaultNewOrderData } from "../../newOrder.model";
import { NewOrderValidator } from "../../newOrder.validator";
import { defaultNewOrderWoundInfoData } from "../../newOrderWoundInfoStepper/newOrderWoundInfo.model";
import {
  mapWoundDimensionDepth,
  mapWoundDimensionLength,
  mapWoundDimensionWidth,
} from "../VacOrderSummary/newOrderResponseMapper";
import { ICreateTransitionOrder } from "./createTransitionOrderResponseMapper.interface";

export const mapCreateTransitionOrderResponse = async (
  data: ICreateTransitionOrder,
  validator = new NewOrderValidator()
) => {
  let defaultOrderData: INewOrder = getDeepClone(defaultNewOrderData);
  let createTransitionOrder: INewOrder = {
    ...defaultOrderData,
    firstName: {
      ...defaultOrderData.firstName,
      valid: validate(
        validator,
        "patientinfofirstName",
        data && data.patientFirstName
      )!,
      value: data && data.patientFirstName,
    },
    lastName: {
      ...defaultOrderData.lastName,
      valid: validate(
        validator,
        "patientinfolastName",
        data && data.patientLastName
      )!,
      value: data && data.patientLastName,
    },
    dob: {
      ...defaultOrderData.dob,
      valid: validate(validator, "dob", data && data.patientDOB)!,
      value: data && data.patientDOB,
    },
    woundType: {
      ...defaultOrderData.woundType,
      valid: validate(
        validator,
        "woundType",
        data.primaryWound && data.primaryWound.type === null
          ? ""
          : data.primaryWound && data.primaryWound.type?.toString()
      )!,
      value:
        data.primaryWound && data.primaryWound.type === null
          ? ""
          : data.primaryWound && data.primaryWound.type?.toString(),
      required: true,
    },
    woundLocation: {
      ...defaultOrderData.woundLocation,
      valid: validate(
        validator,
        "woundLocation",
        data.primaryWound && data.primaryWound.location === null
          ? ""
          : data.primaryWound && data.primaryWound.location?.toString()
      )!,
      value:
        data.primaryWound && data.primaryWound.location === null
          ? ""
          : data.primaryWound && data.primaryWound.location?.toString(),
      required: true,
    },
  };
  return createTransitionOrder;
};
export const mapCreateTransitionOrderWoundResponse = (
  data: ICreateTransitionOrder,
  validator = new NewOrderValidator()
) => {
  let woundData = getDeepClone(defaultNewOrderWoundInfoData);
  let transitionWoundData: any = {
    ...woundData,
    woundType: {
      ...woundData.woundType,
      valid: validate(
        validator,
        "woundType",
        data.primaryWound && data.primaryWound.type === null
          ? ""
          : data.primaryWound && data.primaryWound.type?.toString()
      )!,
      value:
        data.primaryWound && data.primaryWound.type === null
          ? ""
          : data.primaryWound && data.primaryWound.type?.toString(),
      required: true,
    },
    woundDirection: {
      ...woundData.woundDirection,
      valid: validate(
        validator,
        "woundDirection",
        data.primaryWound && data.primaryWound.locationWritten === null
          ? ""
          : data.primaryWound &&
            data.primaryWound.locationWritten?.toString().split(",").length > 0
          ? data.primaryWound &&
            data.primaryWound.locationWritten
              ?.toString()
              .split(",")[0]
              .toString()
          : ""
      )!,
      value:
        data.primaryWound && data.primaryWound.locationWritten === null
          ? ""
          : data.primaryWound &&
            data.primaryWound.locationWritten?.toString().split(",").length > 0
          ? data.primaryWound &&
            data.primaryWound.locationWritten
              ?.toString()
              .split(",")[0]
              .toString()
          : "",
      required: false,
    },
    woundOrientation: {
      ...woundData.woundOrientation,
      valid: validate(
        validator,
        "woundOrientation",
        data.primaryWound && data.primaryWound.locationWritten === null
          ? ""
          : data.primaryWound &&
            data.primaryWound.locationWritten.split(",").length >= 2
          ? data.primaryWound &&
            data.primaryWound.locationWritten
              ?.toString()
              .split(",")[1]
              .toString()
          : ""
      )!,
      value:
        data.primaryWound && data.primaryWound.locationWritten === null
          ? ""
          : data.primaryWound &&
            data.primaryWound.locationWritten.split(",").length >= 2
          ? data.primaryWound &&
            data.primaryWound.locationWritten
              ?.toString()
              .split(",")[1]
              .toString()
          : "",
      required: false,
    },
    woundLocation: {
      ...woundData.woundLocation,
      valid: validate(
        validator,
        "woundLocation",
        data.primaryWound && data.primaryWound.location === null
          ? ""
          : data.primaryWound && data.primaryWound.location?.toString()
      )!,
      value:
        data.primaryWound && data.primaryWound.location === null
          ? ""
          : data.primaryWound && data.primaryWound.location?.toString(),
      required: true,
    },
    woundMeasurementDate: {
      ...woundData.woundMeasurementDate,
      valid: validate(
        validator,
        "woundMeasurementDate",
        data.primaryWound && data.primaryWound.measurementDate === null
          ? ""
          : data.primaryWound && data.primaryWound.measurementDate
      )!,
      value: moment(data?.primaryWound?.measurementDate ?? "").format(
        "YYYY-MM-DDTHH:mm:ss"
      ),
      required:
        data.primaryWound && data.primaryWound.measurementDate === null
          ? false
          : true,
    },
    woundLength: mapWoundDimensionLength(
      data.primaryWound,
      validator,
      woundData
    ),
    woundWidth: mapWoundDimensionWidth(data.primaryWound, validator, woundData),
    woundDepth: mapWoundDimensionDepth(data.primaryWound, validator, woundData),
    isShowSecondaryWoundInfo: {
      valid: ValidationStatus.UNTOUCHED,
      value: data?.secondaryWound?.type !== null ? "Yes" : "No",
      title: "Secondary Wound Info",
    },
  };
  return transitionWoundData;
};
export const mapCreateTransitionSecondaryWoundIformation = (
  data: ICreateTransitionOrder,
  validator = new NewOrderValidator()
) => {
  let secondaryWoundDetails: ISecondaryWoundInfo = getDeepClone(
    defaultNewOrdeSecondaryWoundInfoData
  );
  secondaryWoundDetails = {
    ...secondaryWoundDetails,
    woundType: {
      ...secondaryWoundDetails.woundType,
      valid: validate(
        validator,
        "woundType",
        data.secondaryWound && data.secondaryWound.type === null
          ? ""
          : data.secondaryWound && data.secondaryWound.type?.toString()
      )!,
      value:
        data.secondaryWound && data.secondaryWound.type === null
          ? ""
          : data.secondaryWound && data.secondaryWound.type?.toString(),
      required: true,
    },
    woundLocation: {
      ...secondaryWoundDetails.woundLocation,
      valid: validate(
        validator,
        "woundLocation",
        data.secondaryWound && data.secondaryWound.location === null
          ? ""
          : data.secondaryWound && data.secondaryWound.location?.toString()
      )!,
      value:
        data.secondaryWound && data.secondaryWound.location === null
          ? ""
          : data.secondaryWound && data.secondaryWound.location?.toString(),
      required: true,
    },
    woundDirection: {
      ...secondaryWoundDetails.woundDirection,
      valid: validate(
        validator,
        "woundDirection",
        data.secondaryWound && data.secondaryWound.locationWritten === null
          ? ""
          : data.secondaryWound &&
            data.secondaryWound.locationWritten?.toString().split(",").length >
              0
          ? data.secondaryWound &&
            data.secondaryWound.locationWritten
              ?.toString()
              .split(",")[0]
              .toString()
          : ""
      )!,
      value:
        data.secondaryWound && data.secondaryWound.locationWritten === null
          ? ""
          : data.secondaryWound &&
            data.secondaryWound.locationWritten?.toString().split(",").length >
              0
          ? data.secondaryWound &&
            data.secondaryWound.locationWritten
              ?.toString()
              .split(",")[0]
              .toString()
          : "",
      required: false,
    },
    woundOrientation: {
      ...secondaryWoundDetails.woundOrientation,
      valid: validate(
        validator,
        "woundOrientation",
        data.secondaryWound && data.secondaryWound.locationWritten === null
          ? ""
          : data.secondaryWound &&
            data.secondaryWound.locationWritten?.toString().split(",").length >=
              2
          ? data.secondaryWound &&
            data.secondaryWound.locationWritten
              ?.toString()
              .split(",")[1]
              .toString()
          : ""
      )!,
      value:
        data.secondaryWound && data.secondaryWound.locationWritten === null
          ? ""
          : data.secondaryWound &&
            data.secondaryWound.locationWritten?.toString().split(",").length >=
              2
          ? data.secondaryWound &&
            data.secondaryWound.locationWritten
              ?.toString()
              .split(",")[1]
              .toString()
          : "",
      required: false,
    },
    woundMeasurementDate: {
      ...secondaryWoundDetails.woundMeasurementDate,
      valid: validate(
        validator,
        "woundMeasurementDate",
        data.secondaryWound && data.secondaryWound.measurementDate === null
          ? ""
          : data.secondaryWound && data.secondaryWound.measurementDate
      )!,
      value: moment(data?.secondaryWound?.measurementDate ?? "").format(
        "YYYY-MM-DDTHH:mm:ss"
      ),
      required:
        data.secondaryWound && data.secondaryWound.measurementDate === null
          ? false
          : true,
    },
    woundLength: mapWoundDimensionLength(
      data.secondaryWound,
      validator,
      secondaryWoundDetails
    ),
    woundWidth: mapWoundDimensionWidth(
      data.secondaryWound,
      validator,
      secondaryWoundDetails
    ),

    woundDepth: mapWoundDimensionDepth(
      data.secondaryWound,
      validator,
      secondaryWoundDetails
    ),
  };
  return secondaryWoundDetails;
};
const validate = (
  Validator: NewOrderValidator,
  Name: string,
  Value: string
) => {
  let notNullValue = Value === null ? "" : Value;
  const Status = Validator?.validate(notNullValue, Name)?.status;
  return Status === ValidationStatus.INVALID
    ? ValidationStatus.UNTOUCHED
    : Status;
};
