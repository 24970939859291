import { Button } from "@mui/material";
import { IAddWoundAssessment } from "../../addWoundAssessment.interface";
import WoundTitleValue from "../../woundReviewAssessment/woundTitleValue/woundTitleValue.component";
import "./reviewWoundAssessmentComorbidities.css";
import { isArray } from "underscore";
import { useEffect, useState } from "react";
type Props = {
  data: IAddWoundAssessment;
  editButtonClicked?: any;
  isWoundAssessmentSummary?: any;
  isWoundDetailFlow?: boolean;
};
const ReviewWoundComorbidities = ({
  data,
  editButtonClicked,
  isWoundAssessmentSummary,
  isWoundDetailFlow = false,
}: Props) => {
  const [isComorbititiesDiabeticProgram, setIsComorbititiesDiabeticProgram] =
    useState<string>("--");

  const getComorbidities = (): string => {
    let output;
    if (isArray(data.woundAssessComorbodities.value)) {
      output = data.woundAssessComorbodities.value
        .filter((x: any) => x.selected === true)
        .map((x: any) => x.label)
        .join(", ");
      return output === "" ? "--" : output;
    } else return "--";
  };
  const checkIsComorbititiesDiabeticProgram = () => {
    if (
      isWoundDetailFlow &&
      data.woundAssessComorbodities.value !== null &&
      data.woundAssessComorbodities.value.filter(
        (x: any) => x.selected === true && x.value === "Diabetes"
      ).length > 0
    ) {
      if (data?.isComorbititiesDiabeticProgram?.value !== null) {
        if (data?.isComorbititiesDiabeticProgram?.value.toString() === "0")
          setIsComorbititiesDiabeticProgram("No");
        else if (data?.isComorbititiesDiabeticProgram?.value.toString() === "1")
          setIsComorbititiesDiabeticProgram("Yes");
        else setIsComorbititiesDiabeticProgram("N/A");
      }
    } else {
      setIsComorbititiesDiabeticProgram("--");
    }
  };
  useEffect(() => {
    checkIsComorbititiesDiabeticProgram();
  }, []);

  return (
    <div className="review-comorbidities">
      <div className="review-comobidities-titlediv ">
        <div
          className="review-comobidities-title"
          data-testid="review-comobidities-title"
        >
          Comorbidities
        </div>
        {!isWoundAssessmentSummary && (
          <div className="review-woundComorbidities-edit">
            <Button
              classes={{ root: "review-woundComorbidities-edit-button" }}
              data-testid="review-woundComorbidities-edit-button"
              onClick={editButtonClicked}
            >
              Edit
            </Button>
          </div>
        )}
      </div>
      <div
        className="review-comobidities-row"
        data-testid="review-woundComorbidities-value"
      >
        <WoundTitleValue
          formatValue={false}
          title="Applicable comorbidities"
          value={getComorbidities()}
        />
      </div>
      {isWoundDetailFlow && isComorbititiesDiabeticProgram !== "--" && (
        <div
          className="review-comobidities-row"
          data-testid="review-woundComorbidities-value"
        >
          <WoundTitleValue
            formatValue={false}
            title=" Is the patient on a Comprehensive Diabetic Management Program?"
            value={isComorbititiesDiabeticProgram}
          />
        </div>
      )}
    </div>
  );
};
export default ReviewWoundComorbidities;
