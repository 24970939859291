import { createContext, useState } from "react";
import { IInventoryRequest } from "../components/inventory/inventoryRequest/inventoryRequest.interface";
import { getDeepClone } from "../util/ObjectFunctions";
import { defaultInventoryRequestData } from "../components/inventory/inventoryRequest/inventoryRequest.model";
import { useTabs } from "react-headless-tabs";
import { ISelectedInventoryTab } from "../components/inventory/inventoryTabContainer.enum";

export type InventoryContextType = {
  data: IInventoryRequest;
  setData: React.Dispatch<React.SetStateAction<IInventoryRequest>>;
  selectedTab: ISelectedInventoryTab;
  setSelectedTab: React.Dispatch<React.SetStateAction<ISelectedInventoryTab>>;
  isFromSideNav: boolean | null;
  setIsFromSideNav: React.Dispatch<React.SetStateAction<boolean | null>>;
  scrollTabValue: number;
  setScrollTabValue: React.Dispatch<React.SetStateAction<number>>;
  resetInventoryData: () => void;
};

type InventoryContextProviderProps = {
  children: React.ReactNode;
};
export const InventoryContext = createContext<InventoryContextType | null>(
  null
);

export const InventoryContextProvider = ({
  children,
}: InventoryContextProviderProps) => {
  const [data, setData] = useState<IInventoryRequest>(
    getDeepClone(defaultInventoryRequestData)
  );
  const [selectedTab, setSelectedTab] = useTabs([
    "vac-ready-care-program-listing",
    "customer-plus-listing",
    "stored-product-listing",
  ]);
  const [isFromSideNav, setIsFromSideNav] = useState<boolean | null>(null);
  const [scrollTabValue, setScrollTabValue] = useState<number>(0);
  const resetInventoryData = () => {
    setData(getDeepClone(defaultInventoryRequestData));
  };

  return (
    <InventoryContext.Provider
      value={{
        data,
        setData,
        selectedTab,
        setSelectedTab,
        isFromSideNav,
        setIsFromSideNav,
        scrollTabValue,
        setScrollTabValue,
        resetInventoryData,
      }}
    >
      {children}
    </InventoryContext.Provider>
  );
};
