import {
  IFacilitySettingPermission,
  IFacilitySettingSubPermission,
} from "../../../facilitySettings.interface";

export const TOGGLE_PERMISSION = "TOGGLE_PERMISSION";
export const TOGGLE_SUB_PERMISSION = "TOGGLE_SUB_PERMISSION";

export interface TogglePermissionAction {
  type: typeof TOGGLE_PERMISSION;
  permissionName: string;
}

export interface ToggleSubPermissionAction {
  type: typeof TOGGLE_SUB_PERMISSION;
  parentPermissionName: string;
  subPermissionName: string;
}

type PermissionAction = TogglePermissionAction | ToggleSubPermissionAction;

export const permissionsReducer = (
  state: IFacilitySettingPermission[],
  action: PermissionAction
): IFacilitySettingPermission[] => {
  switch (action.type) {
    case TOGGLE_PERMISSION:
      return state.map((permission) => {
        if (permission.permissionName === action.permissionName) {
          const newStatus = !permission.isSelected;
          let allSelected = newStatus;
          let indeterminate;
          let newSubPermissions: IFacilitySettingSubPermission[] | null = null;
          if (
            permission.subPermissions &&
            permission.subPermissions.length > 0
          ) {
            const subPermissionStatus = permission.indeterminate
              ? false
              : newStatus;
            let checkedCount = 0;
            newSubPermissions = permission.subPermissions.map(
              (subPermission) => {
                if (
                  subPermission.isDisabled &&
                  subPermission.isDisabled === true
                ) {
                  subPermission.isSelected = false;
                } else {
                  subPermission.isSelected = subPermissionStatus;
                  if (subPermission.isSelected) {
                    checkedCount += 1;
                  }
                }
                return subPermission;
              }
            );
            allSelected = newSubPermissions.length === checkedCount;
            indeterminate = allSelected ? false : checkedCount > 0;
          }
          return {
            ...permission,
            isSelected: allSelected,
            indeterminate: indeterminate,
            subPermissions: newSubPermissions,
          };
        } else if (permission.subPermissions) {
          return {
            ...permission,
            subPermissions: permissionsReducer(
              permission.subPermissions as IFacilitySettingPermission[],
              action
            ),
          };
        }
        return permission;
      });

    case TOGGLE_SUB_PERMISSION:
      return state.map((permission) => {
        if (
          permission.permissionName === action.parentPermissionName &&
          permission.subPermissions
        ) {
          let checkedCount = 0;
          const newSubPermissions = permission.subPermissions.map(
            (subPermission) => {
              if (subPermission.permissionName === action.subPermissionName) {
                if (!subPermission.isSelected) {
                  checkedCount += 1;
                }
                return {
                  ...subPermission,
                  isSelected: !subPermission.isSelected,
                };
              } else {
                if (subPermission.isSelected) {
                  checkedCount += 1;
                }
                return subPermission;
              }
            }
          );
          const allSelected = newSubPermissions.length === checkedCount;
          const indeterminate = allSelected ? false : checkedCount > 0;
          return {
            ...permission,
            isSelected: allSelected,
            indeterminate: indeterminate,
            subPermissions: newSubPermissions,
          };
        }
        return permission;
      });

    default:
      return state;
  }
};
