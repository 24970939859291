import "./inpatientOrderSummaryPage.css";
import { useHistory } from "react-router-dom";
import {
  IInpatientOrder,
  IInpatientOrderSecondPage,
} from "../inpatientOrder.interface";
import { ReactComponent as DownloadIcon } from "../../../assets/download.svg";
import { ExpressButton } from "../../../core/expressButton/expressButton.component";
import { IInventoryProductDetails } from "../../inventory/inventoryTabContainer.enum";
import { BillingAddress } from "../components/billingAddress/billingAddress.component";
import { IBillingAddress } from "../components/billingAddress/billingAddress.interface";
import { DevicePlacement } from "../components/devicePlacement/devicePlacement.component";
import { TherapyStartDate } from "../components/therapyStartDate/therapyStartDate.component";
import { ICustomerPlusListingDetails } from "../../inventory/customerPlusInventory/customerPlusInventory.interface";
import { SummaryHeader } from "../../inventory/requestService/serviceRequestSummary/header/summaryHeader.component";
import WoundInformationSectionReview from "../components/woundInformation/reviewWoundInformation/woundInformatoinReview.component";
import { PatientInformationReview } from "../components/patientInformation/reviewPatientInformation/patientInformationReview.component";
import { PrescriberInformationReview } from "../components/prescriberInformation/reviewprescriberInformation/prescriberInformationReview.component";
import { BillingInformationReview } from "../components/billingInformation/reviewBillingInformation/billingInformationReview.component";
import { PlacementDateReview } from "../components/placementDate/reviewPlacementDate/placementDateReview.component";
import { ReplacementInformationReview } from "../components/replacementInformation/reviewReplacementInformation/replacementInformationReview.component";
import { defaultDevicePlacementTableData } from "../container/inpatientOrderContainer.component";
import { DeliveryPreferenceReview } from "../components/deliveryPreference/reviewDeliveryPreference/deliveryPreferenceReview.component";
import { CartState } from "../../inpatientSupplyOrder/component/cart/cart.reducer";
import { SelectedSuppliesReview } from "../../inpatientSupplyOrder/component/selectedSupplies/reviewSelectedSupplies/selectedSuppliesReview.component";
import { useMediaQuery } from "@mui/material";

interface IInPatientOrderSummary {
  addresses: IBillingAddress[] | null;
  allProducts: ICustomerPlusListingDetails[] | IInventoryProductDetails[];
  data: IInpatientOrder;
  editButtonAction: Function;
  isCustomerPlusProduct: boolean | null;
  isSummaryOrder: boolean;
  secondPageData: IInpatientOrderSecondPage;
  setData: Function;
  isFromSolventum: boolean;
  isSelectionSuppliesFlow?: boolean;
  cartData: CartState;
}

export const InpatientOrderSummaryPage = ({
  addresses,
  allProducts,
  data,
  editButtonAction,
  isCustomerPlusProduct,
  isSummaryOrder,
  secondPageData,
  setData,
  isFromSolventum,
  isSelectionSuppliesFlow = false,
  cartData,
}: IInPatientOrderSummary) => {
  const history = useHistory();
  const handleNavigation = () => {
    history.push("/home");
  };

  const handleClickPdfDownload = async () => {
    window.print();
  };

  return (
    <div
      className="in-patient-order-summary-page-component"
      data-testid="in-patient-order-summary-page-component-testId"
    >
      <SummaryHeader
        successMessage="Success!"
        description1="Your In-Patient Order has been submitted."
        description2="A 3M Medical Solutions representative will contact you if there are any questions about this order."
        backButton="Back to My Patients"
        handleBackButton={handleNavigation}
      />
      <div className="in-patient-summary-header">
        <p
          className="in-patient-summary-title"
          data-testid="in-patient-summary-title"
          id="in-patient-summary-title"
        >
          Order Summary
        </p>
        <div className="in-patient-summary-header-button">
          <ExpressButton
            clickHandler={() => handleClickPdfDownload()}
            id="in-patient-summary-download-button"
            parentClass="in-patient-summary-download-button"
            testId="download-button"
            variant="text"
            startIcon={<DownloadIcon />}
          >
            Save and Print Order Summary
          </ExpressButton>
        </div>
      </div>
      <div
        className="short-form"
        data-testid="inPatient-order-summary-sections-testId"
      >
        <PatientInformationReview
          data={data.patientInformation.value}
          isSummary={isSummaryOrder}
          editButtonAction={editButtonAction}
        />
        <PrescriberInformationReview
          data={data.prescriberInformation.value}
          isSummary={isSummaryOrder}
          editButtonAction={editButtonAction}
        />
        <DevicePlacement
          addresses={addresses ?? []}
          allProducts={allProducts ?? []}
          data={data}
          editButtonAction={editButtonAction}
          isCustomerPlusProduct={isCustomerPlusProduct}
          isReviewOrder={true}
          isSummary={isSummaryOrder}
          setData={setData}
          devicePlacementTableData={defaultDevicePlacementTableData}
          setIsCustomerPlusProduct={() => {}}
          updateSolventumProduct={() => {}}
        />
        {isCustomerPlusProduct ? (
          <TherapyStartDate
            data={data}
            editButtonAction={editButtonAction}
            isReviewOrder={true}
            isSummary={isSummaryOrder}
            setData={setData}
          />
        ) : (
          <>
            {addresses && addresses.length > 1 && (
              <BillingAddress
                addresses={addresses}
                data={data}
                editButtonAction={editButtonAction}
                isReviewOrder={true}
                setData={setData}
                isSummaryOrder={isSummaryOrder}
                updateSolventumProduct={() => {}}
              />
            )}
          </>
        )}
        <WoundInformationSectionReview
          data={data.patientWoundInformation.value}
          editButtonAction={editButtonAction}
          isSummary={isSummaryOrder}
        />
        {!isCustomerPlusProduct && (
          <div>
            {isFromSolventum ? (
              <DeliveryPreferenceReview
                data={secondPageData.deliveryPreference.value}
                editButtonAction={editButtonAction}
                isSummary={isSummaryOrder}
              />
            ) : (
              <>
                <PlacementDateReview
                  data={secondPageData.placementDate}
                  editButtonAction={editButtonAction}
                  isSummary={isSummaryOrder}
                />
                <ReplacementInformationReview
                  data={secondPageData.replacementInformation.value}
                  editButtonAction={editButtonAction}
                  isSummary={isSummaryOrder}
                />
              </>
            )}
            <BillingInformationReview
              data={secondPageData.billingInformation.value}
              editButtonAction={editButtonAction}
              isSummary={isSummaryOrder}
              isSelectionSuppliesFlow={isSelectionSuppliesFlow}
            />
            {isSelectionSuppliesFlow && cartData.cartItems.length > 0 && (
              <SelectedSuppliesReview
                cartItemsData={cartData}
                isSummaryOrDetail={true}
                isFromInpatientOrderFlow={isSelectionSuppliesFlow}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};
