import { format } from "react-string-format";
import {
  SVC_GET_PATIENT_PRODUCT_INFO,
  SVC_PRINT_SHIPMENT_LABEL_PDF,
  SVC_SAVE_PICKUP,
} from "./staticText";
import { ISubmitPickupRequest } from "../components/pickUpAndDischargeRequest/pickUpRequest/pickUpRequest.interface";
import { getKeyData } from "./encryptDecrypt";

export const retrievePatientDetails = async (reqParams: any) => {
  try {
    const baseUrl = process.env.REACT_APP_ORDER_MGR_FUNC_BASE_URL ?? "";
    const retrievePatientProductInfoUrl = format(
      "{0}{1}",
      baseUrl,
      SVC_GET_PATIENT_PRODUCT_INFO
    );
    const AuthDetails = sessionStorage.getItem("okta-token-storage");
    const authData = JSON.parse(AuthDetails ?? "");
    const accessToken = authData.accessToken?.accessToken;
    const authorizationToken = format("Bearer {0}", accessToken);
    const funckeyParse = getKeyData();
    const response = await fetch(retrievePatientProductInfoUrl, {
      method: "POST",
      headers: {
        Authorization: authorizationToken,
        "x-functions-key": funckeyParse.OrderManagerFunction ?? "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(reqParams),
    });
    if (response.status === 200) {
      const data = response.json();
      return data;
    }
  } catch (error) {
    console.log("error", error);
  }
  return null;
};

export const getShipmentLabelPdf = async (reqParams: any) => {
  try {
    const baseUrl = process.env.REACT_APP_DOCUMENT_MGR_FUNC_BASE_URL ?? "";
    const retrievePatientProductInfoUrl = format(
      "{0}{1}",
      baseUrl,
      SVC_PRINT_SHIPMENT_LABEL_PDF
    );
    const AuthDetails = sessionStorage.getItem("okta-token-storage");
    const authData = JSON.parse(AuthDetails ?? "");
    const accessToken = authData.accessToken?.accessToken;
    const authorizationToken = format("Bearer {0}", accessToken);
    const funckeyParse = getKeyData();
    const response = await fetch(retrievePatientProductInfoUrl, {
      method: "POST",
      headers: {
        Authorization: authorizationToken,
        "x-functions-key":
        funckeyParse.DocumentManagerFunction ?? "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(reqParams),
    });
    if (response.status === 200) {
      const data = response.json();
      return data;
    } else {
      return response.json();
    }
  } catch (error) {
    console.log("error", error);
  }
  return null;
};

export const savePickupRequest = async (reqBody: ISubmitPickupRequest) => {
  try {
    const AuthDetails = sessionStorage.getItem("okta-token-storage");
    const data = JSON.parse(AuthDetails ?? "");
    const accessToken = data.accessToken?.accessToken;
    const baseUrl = process.env.REACT_APP_3ME_SVC_BASE_URL ?? "";
    const savePickupUrl = format("{0}{1}", baseUrl, SVC_SAVE_PICKUP);
    const authorizationToken = format("Bearer {0}", accessToken);
    const response = await fetch(savePickupUrl, {
      method: "POST",
      headers: {
        Authorization: authorizationToken,
        AuthorizationKey: process.env.REACT_APP_3ME_SVC_API_KEY ?? "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(reqBody),
    });

    if (response.status === 200) {
      const data = response.json();
      return data;
    } else {
      return response;
    }
  } catch (error) {
    console.log("error", error);
  }
  return null;
};
