import { useContext, useEffect, useState } from "react";
import { Grid, InputBase, useMediaQuery } from "@mui/material";
import { getSiteUseId } from "../../../../util/utilityFunctions";
import { InpatientOrderValidator } from "../../inpatientOrder.validator";
import { ValidationStatus } from "../../../../core/interfaces/input.interface";
import { InputWithLabel } from "../../../../core/inputWithLabel/inputWithLabel.component";
import { IInpatientOrderSecondPage } from "../../inpatientOrder.interface";
import { CustomDropDown } from "../../../../core/customDropdown/customDropdown.component";
import { EstimatedArrivalTimeMain } from "../../../inventory/requestService/createServiceRequest/estimatedArrivalTime/estimatedArrivalTimeMain.component";
import { AuthContext, AuthContextType } from "../../../../context/AuthContext";
import { STORAGE_LOCATION_LOAD_SERVICE_ERROR } from "../../../../util/errorMsg";
import { getFacilityStorageLocation } from "../../../../util/inventoryMgrService";
import { IStorageLocation } from "../../../inventory/popUpContainer/inventoryPopUpContainer.interface";
import "./replacementInformation.css";
import ErrorPopup, {
  defaultErrorPopUp,
  IErrorPopUp,
} from "../../../../core/errorPopup/errorPopup.component";
import { useHistory } from "react-router-dom";

interface IReplacementInformation {
  data: IInpatientOrderSecondPage;
  setData: Function;
  isAutoFocusEnabled?: boolean;
}

export const ReplacementInformation = ({
  data,
  setData,
}: IReplacementInformation) => {
  const [storageLocations, setStorageLocations] = useState<string[]>([]);
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const [error, setError] = useState<IErrorPopUp>(defaultErrorPopUp);
  const history = useHistory();

  const getStorageLocations = async () => {
    const request = {
      siteUseId: getSiteUseId(authObj),
      userName: authObj && authObj.userProfile?.userName,
    };
    const response = await getFacilityStorageLocation(request);
    if (response && response.succeeded) {
      const allLocations: IStorageLocation[] = response.items;
      const filteredResult: IStorageLocation[] = allLocations.filter(
        (location: IStorageLocation) => location.isEnable
      );
      const locations = filteredResult.map((location) => location.locationName);
      setStorageLocations(locations);
    } else {
      const errorForStorageLocation = {
        errorCode:
          response?.error?.code ||
          response?.error?.errorCode ||
          response?.status,
        errorFlag: true,
        errorMessage: STORAGE_LOCATION_LOAD_SERVICE_ERROR,
      };
      setError(errorForStorageLocation);
    }
  };

  useEffect(() => {
    getStorageLocations();
  }, []);

  const updateBasicContactDetails = (
    tempData: IInpatientOrderSecondPage
  ): IInpatientOrderSecondPage => {
    if (authObj && authObj.userProfile) {
      const profile = authObj.userProfile;
      const validator = new InpatientOrderValidator();
      const firstNameValid = validator.validate(
        profile.firstName,
        "firstName"
      )!.status;
      const lastNameValid = validator.validate(
        profile.lastName,
        "lastName"
      )!.status;
      const phoneValue =
        profile.phoneNumber && profile.phoneNumber !== ""
          ? profile.phoneNumber.slice(-10)
          : profile.mobilePhoneNumber.slice(-10);
      const phoneValid = validator.validate(phoneValue, "phone")!.status;
      const departmentValue = profile.departmentName;
      const departmentValid = validator.validate(
        departmentValue,
        "department"
      )!.status;
      tempData = {
        ...tempData,
        replacementInformation: {
          ...data.replacementInformation,
          value: {
            ...data.replacementInformation.value,
            firstName: {
              ...Object(data.replacementInformation.value).firstName,
              required: true,
              valid: firstNameValid,
              value: profile.firstName,
            },
            lastName: {
              ...Object(data.replacementInformation.value).lastName,
              required: true,
              valid: lastNameValid,
              value: profile.lastName,
            },
            department: {
              ...Object(data.replacementInformation.value).department,
              required: false,
              valid: departmentValid,
              value: departmentValue,
            },

            phoneNumber: {
              ...Object(data.replacementInformation.value).phoneNumber,
              required: true,
              valid: phoneValid,
              value: phoneValue,
            },
          },
        },
      };
    }
    return tempData;
  };

  const resetContactDetails = (
    tempData: IInpatientOrderSecondPage
  ): IInpatientOrderSecondPage => {
    tempData = {
      ...tempData,
      replacementInformation: {
        ...data.replacementInformation,
        value: {
          ...data.replacementInformation.value,
          firstName: {
            ...Object(data.replacementInformation.value).firstName,
            required: false,
            valid: ValidationStatus.UNTOUCHED,
            value: "",
          },
          lastName: {
            ...Object(data.replacementInformation.value).lastName,
            required: false,
            valid: ValidationStatus.UNTOUCHED,
            value: "",
          },
          department: {
            ...Object(data.replacementInformation.value).department,
            required: false,
            valid: ValidationStatus.UNTOUCHED,
            value: "",
          },

          phoneNumber: {
            ...Object(data.replacementInformation.value).phoneNumber,
            required: false,
            valid: ValidationStatus.UNTOUCHED,
            value: "",
          },
        },
      },
    };
    return tempData;
  };

  const validateAndSetData = (e: any) => {
    const { name, value } = e.target;
    const validator = new InpatientOrderValidator();
    const isValid = validator.validate(value, name);
    let tempData = { ...data };
    if (
      name === "isCallOnEstimatedArrivalTime" &&
      value !==
        data.replacementInformation.value.isCallOnEstimatedArrivalTime.value
    ) {
      if (value === "yes") {
        tempData = updateBasicContactDetails(tempData);
      } else {
        tempData = resetContactDetails(tempData);
      }
    }
    const isEmpty =
      name === "phoneNumber" && isValid?.updatedVal?.trim() === "";
    tempData = {
      ...tempData,
      replacementInformation: {
        ...tempData.replacementInformation,
        value: {
          ...tempData.replacementInformation.value,
          [name]: {
            ...Object(tempData.replacementInformation.value)[name],
            errorMessage: isEmpty
              ? Object(tempData.replacementInformation.value)[name].errorMessage
              : null,
            valid:
              name === "department" && value === ""
                ? ValidationStatus.VALID
                : isValid!.status,
            value: value,
          },
        },
      },
    };
    setData(tempData);
  };
  const closePopUpButtonAction = async () => {
    history.goBack();
  };
  return (
    <div
      className="in-patient-order-replacement-information-component"
      data-testid="in-patient-order-replacement-information-component"
    >
      <p
        className="in-patient-order-replacement-information-title"
        data-testid="in-patient-order-replacement-information-title"
        id="in-patient-order-replacement-information-title"
      >
        Replacement Information
      </p>
      <p
        className="in-patient-order-replacement-information-desc"
        data-testid="in-patient-order-replacement-information-desc"
        id="in-patient-order-replacement-information-desc"
      >
        Please provide replacement information. A replacement request is
        required for this product.
      </p>
      <Grid
        className="in-patient-order-replacement-information-grid-container"
        container
      >
        <Grid className="replacementinfo-delivery-location-grid-item" xs={12}>
          <InputWithLabel
            error={
              data.replacementInformation.value.deliveryLocation.valid ===
              ValidationStatus.INVALID
            }
            id={data.replacementInformation.value.deliveryLocation.componentId!}
            isRequired={true}
            isShowWarning={true}
            label={data.replacementInformation.value.deliveryLocation.title!}
            labelClassName="deliveryInstructionLabel"
            warningMessage={
              data.replacementInformation.value.deliveryLocation.errorMessage
            }
            isDropdown={true}
          >
            <CustomDropDown
              handleChange={validateAndSetData}
              menuItem={storageLocations}
              name="deliveryLocation"
              placeHolder="Select a delivery Location"
              selectpropsClassName={
                data.replacementInformation.value.deliveryLocation.value !== ""
                  ? "replaceinfo-delivery-location-select"
                  : "placeholder"
              }
              selectClassName={
                data.replacementInformation.value.deliveryLocation.value !== ""
                  ? "replaceinfo-delivery-location-input"
                  : "placeholder"
              }
              testId="replaceinfo-delivery-location-dropdown"
              value={data.replacementInformation.value.deliveryLocation.value}
            />
          </InputWithLabel>
          <Grid>
            <div
              className="estimated-arrival-time-component"
              data-testid="estimated-arrival-time-component"
              id="estimated-arrival-time-component"
            >
              <EstimatedArrivalTimeMain
                data={data.replacementInformation.value}
                validateAndSetData={validateAndSetData}
                isInPatientOrder={true}
              />
            </div>
            <InputWithLabel
              error={
                data.replacementInformation.value.deliveryInstructions.valid ===
                ValidationStatus.INVALID
              }
              id={
                data.replacementInformation.value.deliveryInstructions
                  .componentId!
              }
              isRequired={false}
              isShowWarning={true}
              label={
                data.replacementInformation.value.deliveryInstructions.title!
              }
              labelClassName="deliveryInstructionLabel"
              warningMessage={
                data.replacementInformation.value.deliveryInstructions
                  .errorMessage
              }
            >
              <InputBase
                className="replaceinfo-deliveryInstructions-input"
                data-testid="replace-info-deliveryinstructionTest"
                error={
                  data.replacementInformation.value.deliveryInstructions
                    .valid === ValidationStatus.INVALID
                }
                id={data.replacementInformation.value.deliveryInstructions.id!}
                inputProps={{
                  className:
                    data.replacementInformation.value.deliveryInstructions
                      .valid === ValidationStatus.INVALID
                      ? "showDeliveryInstructionError"
                      : "noDeliveryInstructionError",
                  maxLength: 200,
                }}
                multiline={true}
                name="deliveryInstructions"
                onChange={validateAndSetData}
                rows={2}
                value={
                  data.replacementInformation.value.deliveryInstructions.value
                }
              />
            </InputWithLabel>
          </Grid>
        </Grid>
      </Grid>
      <ErrorPopup
        buttontitle="Done"
        errorCode={error.errorCode}
        errorPopupFlag={error.errorFlag}
        errorMessage={error.errorMessage}
        isShortSentence={true}
        handleBackButton={() => {
          closePopUpButtonAction();
        }}
        popUpStyles="errorPopup"
        phone="(800) 275-4524 ext 41858."
        showTitle={false}
      />
    </div>
  );
};
