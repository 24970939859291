import { useEffect, useState } from "react";
import {
  IInputField,
  ValidationStatus,
} from "../../../../../core/interfaces/input.interface";
import { IPrescriberInformation } from "../../../../inpatientOrder/components/prescriberInformation/prescriberInformation.interface";
import {
  Button,
  FormControlLabel,
  InputBase,
  Radio,
  RadioGroup,
} from "@mui/material";
import "../editPrescriberEmail/editPrescriberEmail.css";
import { InputWithLabel } from "../../../../../core/inputWithLabel/inputWithLabel.component";
import { IPrescriberEmails } from "../../../../administration/myLists/myListPrescribers/myListPrescriber.interface";
import { ReactComponent as RadioButtonIcon } from "../../../../../assets/radioButton.svg";
import { ReactComponent as SelectedRadioButtonIcon } from "../../../../../assets/selectedRadioButton.svg";
import {
  defaultNewEmailData,
  defaultNewEmailDataMultiple,
} from "../addedPrescriber.model";
import { SendNoteValidator } from "../../../sendNote.validator";

interface IEditPrescriberEmailProps {
  data: IInputField;
  setData: Function;
  prescriberInformation: IPrescriberInformation;
  isEditButtonClicked: boolean;
  setIsEditButtonClicked: Function;
  Validator?: SendNoteValidator;
  newPrescriberEmail: IInputField;
  setNewPrescriberEmail: Function;
  newPrescriberEmailMultiple: IInputField;
  setNewPrescriberEmailMultiple: Function;
}

const EditPrescriberEmail = ({
  data,
  setData,
  prescriberInformation,
  isEditButtonClicked,
  setIsEditButtonClicked,
  Validator = new SendNoteValidator(),
  newPrescriberEmail,
  setNewPrescriberEmail,
  newPrescriberEmailMultiple,
  setNewPrescriberEmailMultiple,
}: IEditPrescriberEmailProps) => {
  const [validator] = useState<SendNoteValidator>(Validator);
  const emailText = data.value.email || data.value.emails?.[0]?.value || "";

  const showTitleAndValue = (
    title: string,
    value: string | null,
    testId: string
  ) => (
    <div className="show-title-and-value-div">
      <p
        className="show-title-and-value-title"
        data-testid={`${testId}-title`}
        id={`${testId}-title-id`}
      >
        {title}
      </p>
      <p
        className="show-title-and-value-value"
        data-testid={`${testId}-value`}
        id={`${testId}-value-id`}
      >
        {value && value !== "" ? value.trim() : "--"}
      </p>
    </div>
  );

  const handleEditButtonClicked = () => {
    setIsEditButtonClicked(true);
    const { email, emails } = data.value;

    if (!email && !emails) {
      setNewPrescriberEmail({
        ...newPrescriberEmail,
        required: true,
        value: "",
        valid: ValidationStatus.UNTOUCHED,
      });
      setData({ ...data, value: { ...data.value, email: "", emails: [] } });
      return;
    }

    const defaultEmail = email || emails?.[0]?.value || "";
    if (data.value.emails.length <= 1) {
      setNewPrescriberEmail({
        ...newPrescriberEmail,
        value: defaultEmail,
        valid: defaultEmail
          ? ValidationStatus.VALID
          : ValidationStatus.UNTOUCHED,
        required: true,
      });
    } else {
      setNewPrescriberEmailMultiple({
        ...newPrescriberEmailMultiple,
        required: true,
        valid: ValidationStatus.UNTOUCHED,
        value: {
          ...newPrescriberEmailMultiple.value,
          isDefault: false,
          value: "",
        },
      });
    }
  };

  const validateAndSetEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const isValid = validator.validate(value, name);

    setNewPrescriberEmail({
      ...newPrescriberEmail,
      value: value,
      valid: isValid!.status,
    });

    if (isValid?.status === ValidationStatus.VALID) {
      setData({
        ...data,
        value: {
          ...data.value,
          email: value,
        },
      });
    }
  };

  useEffect(() => {
    if (!data.value.emails || data.value.emails.length === 0) {
      handleEditButtonClicked();
    }
  }, [data.value.npi]);

  const validateAndSetData = (e: any) => {
    const { name, value } = e.target;
    const updatedEmails = data.value.emails.map((email: IPrescriberEmails) =>
      email.value === value
        ? { ...email, isDefault: true }
        : { ...email, isDefault: false }
    );

    setData({
      ...data,
      value: {
        ...data.value,
        emails: updatedEmails,
        email:
          updatedEmails.find((email: IPrescriberEmails) => email.isDefault)
            ?.value || "",
      },
    });
    setNewPrescriberEmailMultiple({
      ...newPrescriberEmailMultiple,
      valid:
        newPrescriberEmailMultiple.valid === ValidationStatus.VALID
          ? ValidationStatus.VALID
          : ValidationStatus.UNTOUCHED,
      value: { ...newPrescriberEmailMultiple.value, isDefault: false },
    });
  };

  const handleOuterRadioSelection = () => {
    setNewPrescriberEmailMultiple({
      ...newPrescriberEmailMultiple,
      valid:
        newPrescriberEmailMultiple.valid === ValidationStatus.VALID
          ? ValidationStatus.VALID
          : ValidationStatus.UNTOUCHED,
      value: { ...newPrescriberEmailMultiple.value, isDefault: true },
    });

    setData({
      ...data,
      value: {
        ...data.value,
        email: newPrescriberEmailMultiple.value.value,
        emails: data.value.emails.map((email: IPrescriberEmails) => ({
          ...email,
          isDefault: false,
        })),
      },
    });
  };

  const validateAndSetOuterEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const isValid = validator.validate(value, name);

    setNewPrescriberEmailMultiple({
      ...newPrescriberEmailMultiple,
      valid: isValid!.status,
      value: { ...newPrescriberEmailMultiple.value, value, isDefault: true },
    });

    setData({
      ...data,
      value: {
        ...data.value,
        email: value,
      },
    });
  };

  const renderMultipleEmails = () => (
    <InputWithLabel
      testId="multiple-email-testId"
      label="Email to associate with this order"
      isRequired
    >
      <RadioGroup
        value={data.value.email}
        className="email-radio-group"
        onChange={validateAndSetData}
      >
        {data.value.emails.map((email: IPrescriberEmails) => (
          <FormControlLabel
            key={email.value}
            classes={{
              root: email.isDefault ? "optionRoot-active" : "optionRoot",
            }}
            componentsProps={{
              typography: {
                classes: {
                  root: email.isDefault ? "optiontxtSelect" : "optiontxt",
                },
              },
            }}
            control={
              <Radio
                icon={<RadioButtonIcon />}
                checkedIcon={<SelectedRadioButtonIcon />}
              />
            }
            name={email.value}
            label={email.value}
            value={email.value}
          />
        ))}
      </RadioGroup>
      <InputWithLabel
        componentClassName="edit-email-input-multiple-input-field"
        sx={{ marginTop: "-14px" }}
        isRequired={newPrescriberEmailMultiple.required}
        error={newPrescriberEmailMultiple.valid === ValidationStatus.INVALID}
      >
        <Radio
          icon={<RadioButtonIcon />}
          checkedIcon={<SelectedRadioButtonIcon />}
          checked={newPrescriberEmailMultiple.value.isDefault}
          onClick={handleOuterRadioSelection}
        />
        <InputBase
          name="prescriberNewEmail"
          value={newPrescriberEmailMultiple.value.value}
          className="edit-email-input-multiple"
          onChange={validateAndSetOuterEmail}
        />
      </InputWithLabel>
    </InputWithLabel>
  );

  const renderSingleEmail = () => (
    <InputWithLabel
      testId="single-email-testId"
      label="Email to associate with this order"
      isRequired={newPrescriberEmail.required}
      error={newPrescriberEmail.valid === ValidationStatus.INVALID}
    >
      <InputBase
        value={newPrescriberEmail.value}
        className="edit-email-input"
        onChange={validateAndSetEmail}
        name="prescriberNewEmail"
      />
    </InputWithLabel>
  );

  const renderEmailSection = () => {
    if (isEditButtonClicked) {
      return data.value.emails?.length > 1
        ? renderMultipleEmails()
        : renderSingleEmail();
    }

    return (
      <div className="email-edit-section-content">
        {showTitleAndValue("Email", emailText, "prescriber-email")}
        <Button
          classes={{ root: "edit-prescriber-email-button" }}
          data-testid="edit-prescriber-email-button"
          id="edit-prescriber-email-button"
          onClick={handleEditButtonClicked}
        >
          Edit Email
        </Button>
      </div>
    );
  };

  return <div className="edit-email-section">{renderEmailSection()}</div>;
};

export default EditPrescriberEmail;
