import "./vacCannister.css";
import {
  NewOrderContext,
  NewOrderContextType,
} from "../../../../context/NewOrderContext";
import { Button, Grid, useMediaQuery } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import closeIcon from "../../../../assets/cross.svg";
import { useContext, useEffect, useMemo, useState } from "react";
import { ICanister, IProductInfo } from "../../newOrder.interface";
import { AuthContextType, AuthContext } from "../../../../context/AuthContext";
import { ValidationStatus } from "../../../../core/interfaces/input.interface";
import QuantityButton from "../../../../core/quantityButton/quantityButton.component";
import parse from "html-react-parser";
import {
  InternalSignOnContext,
  InternalSignOnContextType,
} from "../../../../context/InternalSignOnContext";

interface IVacCannister {
  cannisterProducts: any;
  data: ICanister;
  productInfo: IProductInfo;
  setData: Function;
  isDefaultClicked?: boolean;
}

export const VacCannister = ({
  cannisterProducts,
  data,
  productInfo,
  setData,
  isDefaultClicked,
}: IVacCannister) => {
  const [selectedCannister, setSelectedCannister] = useState<any>(null);
  const [cannisterMaxCases, setCannisterMaxCases] = useState<string>();

  const authObj = useContext<AuthContextType | null>(AuthContext);
  const newOrderObj = useContext<NewOrderContextType | null>(NewOrderContext);

  const internalObj = useContext<InternalSignOnContextType | null>(
    InternalSignOnContext
  );
  const isMobileScreen = useMediaQuery("(max-width:600px)");
  const isSmallerMobileScreen = useMediaQuery("(max-width:450px)");
  const checkForReadyCareFlag = () => {
    return (
      (internalObj?.isVACOrderSSO &&
        newOrderObj?.newOrderData &&
        newOrderObj?.newOrderData.requestingFacility?.value &&
        newOrderObj?.newOrderData.requestingFacility?.value?.readyCareFlag &&
        newOrderObj?.newOrderData.requestingFacility?.value?.readyCareFlag ===
          "Y") ||
      (authObj &&
        authObj.registeredFaciltyAddress &&
        authObj.registeredFaciltyAddress.readyCareFlag &&
        authObj.registeredFaciltyAddress.readyCareFlag === "Y")
    );
  };

  const updateSelectedCannister = () => {
    if (cannisterProducts.length > 0) {
      let canister: { productName: any; sku: any } | null = null;
      if (productInfo.productInformation.value === "yes") {
        canister = cannisterProducts.filter(
          (x: any) => !x.productName.toLowerCase().includes("freedom")
        )[0];
      } else {
        if (productInfo.productValue.value.toLowerCase().includes("2")) {
          canister = cannisterProducts.filter((x: any) =>
            x.productName.toLowerCase().includes("freedom")
          )[0];
        } else {
          canister = cannisterProducts.filter(
            (x: any) => !x.productName.toLowerCase().includes("freedom")
          )[0];
        }
      }
      setSelectedCannister(canister);
      return canister;
    }
  };

  const updateMaxQuantityAndUpdateButtons = (
    tempData: ICanister,
    checkForDefault: boolean = false
  ): ICanister => {
    if (newOrderObj && !newOrderObj.isComingFromPrev) {
      const maxQuantity = getCanisterMaxQuantity();
      const canisterQuantity =
        tempData.canisterProductQuantity.value !== ""
          ? tempData.canisterProductQuantity.value
          : "1";
      if (
        checkForDefault &&
        parseInt(canisterQuantity) !== parseInt(maxQuantity)
      ) {
        tempData.canisterProductQuantity.value = maxQuantity;
      }
      setCannisterMaxCases(maxQuantity);
      newOrderObj?.setIsCannisterProductMinusDisabled(canisterQuantity === "1");
      newOrderObj?.setIsCannisterProductPlusDisabled(
        maxQuantity === canisterQuantity
      );
    }
    return tempData;
  };

  const getCanisterMaxQuantity = (): string => {
    const maxQuantity: string =
      checkForReadyCareFlag() &&
      productInfo.productInformation.value !== "" &&
      productInfo.productInformation.value === "yes"
        ? "1"
        : "2";
    return maxQuantity;
  };

  const handleVacKitDressingPlusClick = () => {
    newOrderObj?.setIsHandleChangeTriggered(true);
    newOrderObj?.setIsCannisterProductMinusDisabled(false);
    newOrderObj?.setIsCannisterProductPlusDisabled(false);
    const dressingPlus = parseInt(data?.canisterProductQuantity.value) + 1;
    if (dressingPlus === 2) {
      newOrderObj?.setIsCannisterProductPlusDisabled(true);
    }
    setData({
      ...data,
      canisterProductQuantity: {
        value: dressingPlus.toString(),
        valid: ValidationStatus.VALID,
      },
    });
  };

  const handleVacKitDressingMinusClick = () => {
    newOrderObj?.setIsHandleChangeTriggered(true);
    newOrderObj?.setIsCannisterProductMinusDisabled(false);
    const dressingPlus = parseInt(data?.canisterProductQuantity.value) - 1;
    if (dressingPlus === 1) {
      newOrderObj?.setIsCannisterProductMinusDisabled(true);
    }
    if (dressingPlus < 3) {
      newOrderObj?.setIsCannisterProductPlusDisabled(false);
    }
    setData({
      ...data,
      canisterProductQuantity: {
        value: dressingPlus.toString(),
        valid: ValidationStatus.VALID,
      },
    });
  };

  const handleCloseIcon = () => {
    newOrderObj?.setshowCannisterType(true);
    const tempData = {
      ...data,
      canisterProductName: {
        value: "",
        valid: ValidationStatus.VALID,
        isDefaultValid: true,
      },
      canisterProductCode: {
        value: "",
        valid: ValidationStatus.VALID,
        isDefaultValid: true,
      },
      canisterProductQuantity: {
        value: "",
        valid: ValidationStatus.VALID,
        isDefaultValid: true,
      },
      canisterProductID: {
        value: "",
        valid: ValidationStatus.VALID,
        isDefaultValid: true,
      },
    };
    setData(tempData);
  };

  const addCannisterClick = () => {
    const updatedCannister = updateSelectedCannister();
    const tempData = {
      ...data,
      canisterProductName: {
        value: updatedCannister?.productName,
        valid: ValidationStatus.VALID,
        isDefaultValid: true,
      },
      canisterProductCode: {
        value: updatedCannister?.sku,
        valid: ValidationStatus.VALID,
        isDefaultValid: true,
      },
      canisterProductQuantity: {
        value: getCanisterMaxQuantity(),
        valid: ValidationStatus.VALID,
        isDefaultValid: true,
      },
      canisterProductID: {
        value: updatedCannister?.sku,
        valid: ValidationStatus.VALID,
        isDefaultValid: true,
      },
    };
    const updatedTempData = updateMaxQuantityAndUpdateButtons(tempData);
    setData(updatedTempData);
    newOrderObj?.setIsHandleChangeTriggered(true);
    newOrderObj?.setshowCannisterType(false);
  };

  const getCaseSize = (): string => {
    const caseSize =
      data &&
      data.canisterProductCode.value &&
      data.canisterProductCode.value !== ""
        ? data.canisterProductCode.value.split("/").length >= 2
          ? data.canisterProductCode.value.split("/")[1]
          : data.canisterProductCode.value
              .match(/\d/g)!
              .join("")
              .replace(/^0+/, "")
        : "";
    return caseSize;
  };
  const updateInitialCountAndUpdatedButtons = () => {
    const tempData: ICanister = {
      ...data,
      canisterProductQuantity: {
        value: "2",
        valid: ValidationStatus.VALID,
        isDefaultValid: true,
      },
    };
    const updatedData = updateMaxQuantityAndUpdateButtons(tempData, true);
    setData(updatedData);
  };

  useEffect(() => {
    if (
      checkForReadyCareFlag() &&
      productInfo.productInformation.value &&
      productInfo.productInformation.value !== ""
    ) {
      updateMaxQuantityAndUpdateButtons(data, true);
    } else {
      if (internalObj?.isVACOrderSSO) {
        updateMaxQuantityAndUpdateButtons(data, true);
      }
    }
  }, [
    authObj?.registeredFaciltyAddress?.readyCareFlag,
    productInfo.productInformation.value,
    newOrderObj?.newOrderData.requestingFacility?.value?.readyCareFlag,
  ]);

  let initialLoad = true;
  useEffect(() => {
    if (!newOrderObj?.isComingFromPrev && initialLoad) {
      updateInitialCountAndUpdatedButtons();
      initialLoad = false;
    }
  }, []);

  useEffect(() => {
    updateSelectedCannister();
  }, [
    productInfo.productValue.value,
    productInfo.productInformation.value,
    cannisterProducts,
  ]);

  useMemo(() => {
    if (newOrderObj?.isDefaultDressingSelection) {
      addCannisterClick();
    }
  }, [
    productInfo.productValue.value,
    productInfo.productInformation.value,
    cannisterProducts,
    newOrderObj?.isDefaultDressingSelection,
  ]);

  useEffect(() => {
    if (selectedCannister) {
      const tempData = {
        ...data,
        canisterProductName: {
          value: selectedCannister.productName,
          valid: ValidationStatus.VALID,
          isDefaultValid: true,
        },
        canisterProductCode: {
          value: selectedCannister.sku,
          valid: ValidationStatus.VALID,
          isDefaultValid: true,
        },
        canisterProductID: {
          value: selectedCannister.sku,
          valid: ValidationStatus.VALID,
          isDefaultValid: true,
        },
      };
      setData(tempData);
    }
  }, [selectedCannister]);

  useEffect(() => {
    if (newOrderObj && !newOrderObj.isComingFromPrev && selectedCannister) {
      const tempData = {
        ...data,
        canisterProductName: {
          value: selectedCannister.productName,
          valid: ValidationStatus.VALID,
          isDefaultValid: true,
        },
        canisterProductCode: {
          value: selectedCannister.sku,
          valid: ValidationStatus.VALID,
          isDefaultValid: true,
        },
        canisterProductID: {
          value: selectedCannister.sku,
          valid: ValidationStatus.VALID,
          isDefaultValid: true,
        },
      };
      const updatedTempData = updateMaxQuantityAndUpdateButtons(tempData, true);
      setData(updatedTempData);
    }
  }, [productInfo.productInformation.value]);

  return (
    <>
      {!newOrderObj?.isCondensedDressing && (
        <div className="cannisterKitInfo" data-testid="cannisterKitHeaderTest">
          <h2 className="cannisterKitHeader">
            3M™ V.A.C.<sup>®</sup> Canisters
          </h2>
          {newOrderObj?.showCannisterType === false && (
            <div className="cannisterdiv">
              <Grid className="currentCannisterMainGrid">
                <Grid className="cannisterTypeLabel" item xs={11}>
                  <div className="canisterTypeHeader">
                    <span className="cannisterType">Canister Type</span>
                    <p
                      className="cannisterBody"
                      data-testid="cannisterBodyTest"
                    >
                      {selectedCannister && selectedCannister?.productName}
                    </p>
                  </div>
                </Grid>
                <Grid className="canisterClose" item xs={1}>
                  {productInfo.productInformation?.value === "yes" && (
                    <img
                      onClick={handleCloseIcon}
                      src={closeIcon}
                      alt={closeIcon}
                      className="cannisterCloseBtn"
                    />
                  )}
                </Grid>
              </Grid>
              <Grid className="currentCannisterMainGridQuantity">
                <Grid
                  item
                  xs={isSmallerMobileScreen ? 7 : isMobileScreen ? 7 : 8.5}
                  className="canisterQuantity"
                >
                  <div data-testid="cannisterQuantityLabelTest">
                    <label className="cannisterQuantityLabel">
                      {`Quantity ${
                        getCaseSize() !== ""
                          ? `(${getCaseSize()} canisters per case)`
                          : ""
                      }`}
                    </label>
                  </div>
                  <p className="cannisterSubLabel">
                    Max {cannisterMaxCases} cases
                  </p>
                </Grid>
                <Grid
                  className="canisterQuntityButton"
                  item
                  xs={isSmallerMobileScreen ? 5 : isMobileScreen ? 5 : 3}
                >
                  <QuantityButton
                    value={data?.canisterProductQuantity.value}
                    onPlusClick={handleVacKitDressingPlusClick}
                    onMinusClick={handleVacKitDressingMinusClick}
                    isPlusDisabled={newOrderObj?.isCannisterProductPlusDisabled}
                    isMinusDisabled={
                      newOrderObj?.isCannisterProductMinusDisabled
                    }
                    showLabel={false}
                  />
                </Grid>
              </Grid>
            </div>
          )}
          {newOrderObj?.showCannisterType === true && (
            <Grid className="cannisterBtnGrid">
              <Grid item xs={isMobileScreen ? 6 : 12}>
                <Button
                  classes={{ root: "canister-add-button" }}
                  data-testid="dressingKit-add-button"
                  onClick={addCannisterClick}
                  startIcon={
                    <AddIcon classes={{ root: "cannisteradd-icon" }} />
                  }
                >
                  Add Canister
                </Button>
              </Grid>
            </Grid>
          )}
        </div>
      )}
    </>
  );
};
