import { ValidationStatus } from "../../../../core/interfaces/input.interface";
import { IDevicePlacement } from "./devicePlacement.interface";
import { DevicePlacementTabs } from "./devicePlacemet.enums";

export const defaultDevicePlacementInformation: IDevicePlacement = {
  productType: {
    componentId: "in-patient-order-product-type",
    hasChildsToValidate: false,
    isDefaultValid: true,
    title: "",
    valid: ValidationStatus.VALID,
    value: DevicePlacementTabs.STORED_PRODUCT_TAB,
  },
  placementType: {
    componentId: "in-patient-order-placement-type",
    hasChildsToValidate: false,
    order: 7,
    required: false,
    title: "Placement Type",
    valid: ValidationStatus.VALID,
    value: null,
  },
  selectedProduct: {
    componentId: "in-patient-order-selected-product",
    hasChildsToValidate: false,
    order: 8,
    required: true,
    title: "Device Placement Product",
    valid: ValidationStatus.UNTOUCHED,
    value: null,
  },
};
