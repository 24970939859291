export interface IInputField {
  componentId?: string | null;
  errorMessage?: string | null;
  hasChildsToValidate?: boolean;
  id?: string | null;
  isDefaultValid?: boolean;
  isOptional?: boolean;
  isPhoneNumber?: boolean;
  isShowWarning?: boolean;
  minimumRequired?: boolean;
  order?: number | null;
  required?: boolean;
  title?: string | null;
  valid: ValidationStatus;
  value: string | any;
}

export interface IInputFieldNullable {
  value: string | null;
  valid: ValidationStatus;
}

export type Validation = {
  status: ValidationStatus;
  message?: string | null;
  updatedVal?: string;
};

export enum ValidationStatus {
  UNTOUCHED = "untouched",
  VALID = "valid",
  INVALID = "invalid",
}

export interface MultiCheckbox {
  componentId?: string | null;
  defaultRequired?: boolean;
  errorMessage?: string | null;
  order?: number | null;
  required?: boolean;
  title?: any;
  value: any;
  valid: ValidationStatus;
  isCheckBox?: boolean;
}
