import "./sendNote.css";
import React from "react";
import moment from "moment";
import { format } from "react-string-format";
import { useHistory, useLocation } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { Popup } from "../../core/popup/popup.component";
import { SendNoteValidator } from "./sendNote.validator";
import {
  DD_CONTACT_REASON,
  DD_PROVIDER_TYPE,
  DD_US_STATES_CONTENT,
} from "../../util/staticText";
import { getDeepClone } from "../../util/ObjectFunctions";
import { getdropDownContent } from "../../util/dropDownService";
import { ISendNote, ISendNoteProps } from "./sendNote.interface";
import { LoadingSpinner } from "../../core/loader/LoadingSpinner";
import { AskQuestion } from "./askQuestion/askQuestion.component";
import { defaultAskQuestionData } from "./askQuestion/askQuestion.model";
import {
  IInputField,
  ValidationStatus,
} from "../../core/interfaces/input.interface";
import TransferPatient from "./transferPatient/transferPatient.component";
import ChangeAddress from "./changePatientAddress/changeAddress.component";
import { Navigator } from "../helpAndSupport/Navigator/navigator.component";
import { retrievePatientDetails } from "../../util/pickUpOrDischargeService";
import {
  formatPhoneNumberWithoutSpecialChar,
  getCodeFromText,
  getSiteUseId,
  getTextFromCode,
} from "../../util/utilityFunctions";
import { defaultAddressData } from "./changePatientAddress/changeAddress.model";
import { SendNoteSuccess } from "./popUps/successPopUp/sendNoteSuccess.component";
import { CustomDropDown } from "../../core/customDropdown/customDropdown.component";
import { FooterButtonGroup } from "./footerButtonGroup/footerButtonGroup.component";
import { InputWithLabel } from "../../core/inputWithLabel/inputWithLabel.component";
import { defaultTransferPatientData } from "./transferPatient/transferPatient.model";
import { PatientDetails } from "../pickUpAndDischargeRequest/pickUpRequest/patientDetails/patientDetails.component";
import { getPatientAddresses } from "../../util/3meService";
import { AuthContext, AuthContextType } from "../../context/AuthContext";
import { send3MNote } from "../../util/3meService";
import { patientMockData } from "../../mockData/patientFound";
import { SendNoteFailure } from "./popUps/failurePopUp/sendNoteFailure.component";
import { SendNoteCancel } from "./popUps/cancelPopUp/sendNoteCancelPopUp.component";
import {
  SendNoteContext,
  SendNoteContextType,
} from "../../context/SendNoteContext";
import {
  OrderDetailContext,
  OrderDetailContextType,
} from "../../context/OrderDetailsContext";
import ErrorPopup from "../../core/errorPopup/errorPopup.component";
import {
  ERROR_MSG_GET_PRESCRIBER_DETAILS,
  ERROR_MSG_SEND_NOTE,
  ERROR_MSG_UPDATE_HOMECAREPROVIDER_DETAILS,
  SVC_SEND_NOTE_FAILURE,
} from "../../util/errorMsg";
import { getAcuteOrderRoDetails } from "../../util/actueService";
import { MissingRequiredFieldPopup } from "../myPatients/patientAndTherapyDetails/woundProgress/addWoundAssessment/addWoundFooterButtonGroup/MissingRequiredFieldPopUp/missingRequiredFieldPopUp.component";
import {
  RolesPermissionContextType,
  RolesPermissionContext,
} from "../../context/RolesPermissionContext";
import { SelectedHomeCareProvider } from "./homeCareProvider/selectedHomeCareProvider/selectedHomeCareProvider.component";
import {
  defaultHcpData,
  IHomeCareProviderData,
} from "./homeCareProvider/homeCareProvider.interface";
import { HomeCareProvider } from "./homeCareProvider/homeCareProvider.component";
import { IPatient } from "../myPatients/patient.interface";
import { VacOrderSummaryData } from "../newOrder/mapper/VacOrderSummary/newOrderResponseMapper.interface";
import { getVACOrderDetails } from "../../util/vacOrderService";
import { IPrescriberModal } from "../newOrder/prescriberInformation/prescriberSearch/prescriberSearch.model";
import { getNPIPrescriber } from "../../util/npiService";
import { SelectedPrescriber } from "./updatePrescriber/selectedPrescriber/selectedPrescriber.component";
import { AddPrescriberInformation } from "./updatePrescriber/addPrescriberSection/addPrescriberSection.component";
import {
  defaultNewEmailData,
  defaultNewEmailDataMultiple,
} from "./updatePrescriber/addPrescriberSection/addedPrescriber.model";
import { mapToRequestParams } from "./sendNote.mapper";

export const SendNote = ({
  testData,
  Validator = new SendNoteValidator(),
}: ISendNoteProps) => {
  const history = useHistory();
  const location: any = useLocation();
  const [openSuccessPopUp, setOpenSuccessPopUp] = useState<boolean>(false);
  const [openCancelPopUp, setOpenCancelPopUp] = useState<boolean>(false);
  const [openLoaderPopUp, setOpenLoaderPopUp] = useState<boolean>(false);
  const [openFailurePopUp, setOpenFailurePopUp] = useState<boolean>(false);
  const [validator] = useState<SendNoteValidator>(Validator!);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorCode, setErrorCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const sendNoteObj = useContext<SendNoteContextType | null>(SendNoteContext);
  const prescriberObj = useContext<SendNoteContextType | null>(SendNoteContext);
  const additionalInfo = useContext<SendNoteContextType | null>(
    SendNoteContext
  );
  const [missingFieldsPopup, setMissingFieldsPopup] = useState<boolean>(false);
  const [states, setStates] = useState([]);
  const [statesText, setStatesText] = useState([]);
  const [newPrescriberEmail, setNewPrescriberEmail] =
    useState<IInputField>(defaultNewEmailData);
  const [newPrescriberEmailMultiple, setNewPrescriberEmailMultiple] =
    useState<IInputField>(defaultNewEmailDataMultiple);
  const [isEditButtonClicked, setIsEditButtonClicked] =
    useState<boolean>(false);

  const contactReasons = sendNoteObj!.contactReasons;
  const setContactReasons = sendNoteObj!.setContactReasons;
  const contactReasonsText = sendNoteObj!.contactReasonsText;
  const setContactReasonsText = sendNoteObj!.setContactReasonsText;
  const selectedPatient: IPatient = location.state?.selectedPatient;
  const prescriberData = sendNoteObj!.currentPrescriberData;
  const setPrescriberData = sendNoteObj!.setCurrentPrescriberData;
  const currentHcp = sendNoteObj!.currentHcp;
  const setCurrentHcp = sendNoteObj!.setCurrentHcp;
  const selectHcp = sendNoteObj!.selectHcp;
  const setSelectedHcp = sendNoteObj!.setSelectedHcp;
  const addedPrescriberData = sendNoteObj!.addedPrescriberData;
  const setAddedPrescriberData = sendNoteObj!.setAddedPrescriberData;
  const patient = sendNoteObj!.patient;
  const setPatient = sendNoteObj!.setPatient;
  const additionalInfoData = sendNoteObj!.additionalInfoData;
  const setAdditionalInfoData = sendNoteObj!.setAdditionalInfoData;

  const data = sendNoteObj!.data;
  const setData = sendNoteObj!.setData;

  const addressData = sendNoteObj!.changeAddressData;
  const setAddressData = sendNoteObj!.setChangeAddressData;
  const patientCurrentAddress = sendNoteObj!.patientCurrentAddress;
  const setPatientCurrentAddress = sendNoteObj!.setPatientCurrentAddress;
  const patientPermanentAddress = sendNoteObj!.patientPermanentAddress;
  const setPatientPermanentAddress = sendNoteObj!.setPatientPermanentAddress;

  const transferPatientData = sendNoteObj!.transferPatientData;
  const setTransferPatientData = sendNoteObj!.setTransferPatientData;

  const askQuestionData = sendNoteObj!.askQuestionData;
  const setAskQuestionData = sendNoteObj!.setAskQuestionData;

  const orderOverViewObj = useContext<OrderDetailContextType | null>(
    OrderDetailContext
  );
  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );

  const getDataForSendNote = async () => {
    setLoading(true);
    await loadPatientProductInfo();
    setLoading(false);
  };

  const loadPatientProductInfo = async () => {
    const selectedPatient = location.state?.selectedPatient;
    const roNumber = selectedPatient!.roNumber.toString();
    const dob = selectedPatient!.dob;
    if (selectedPatient?.isAcuteOrder) {
      let reqParams = {
        RentalOrderNumber: roNumber,
        UserName: authObj!.userProfile?.userName,
        SiteUseId: getSiteUseId(authObj),
      };
      const response = await getAcuteOrderRoDetails(reqParams);
      if (!response || response.error) {
        setErrorCode(
          response?.error?.code ||
            response?.status ||
            response?.error?.errorCode
        );
        setError(true);
        return false;
      } else {
        setPatient((dt: any) => ({
          ...dt,
          productName: response.item.brandName,
          placementDate: response.item.therapyDate,
          productSerialNumber: response.item.serialNumber,
          productNameDescription: selectedPatient?.isCustomerPlus
            ? selectedPatient?.customerPlusProduct?.productNameDescription
            : "",
        }));
        return true;
      }
    } else {
      let reqParams = {
        RentalOrderNumber: roNumber,
        DOB: moment(dob).format("yyyy-MM-DD"),
        isAcuteOrder: selectedPatient!.isAcuteOrder,
      };
      try {
        const response = await retrievePatientDetails(reqParams);
        if (!response || response.error) {
          setError(true);
          setErrorCode(
            response?.error?.code ||
              response?.status ||
              response?.error?.errorCode
          );
          return false;
        } else {
          setPatient((dt: any) => ({
            ...dt,
            productName: response.item.productName,
            placementDate: response.item.placemetDate,
            productSerialNumber: response.item.productSerialNumber,
          }));
          return true;
        }
      } catch (error) {
        console.log("error", error);
        setError(true);
        return false;
      }
    }
  };

  const prescriberDetailsResponseMapper = (
    prescriberResponseData: IPrescriberModal,
    orderDetailsResponseData: VacOrderSummaryData
  ) => {
    let mappedPrescriberData: any = {
      firstName: prescriberResponseData.firstName,
      lastName: prescriberResponseData.lastName,
      npi: prescriberResponseData.npi,
      address1: prescriberResponseData.address1,
      address2: prescriberResponseData.address2,
      city: prescriberResponseData.city,
      state: prescriberResponseData.state,
      zipCode: prescriberResponseData.zipCode,
      telephoneNumber: prescriberResponseData.telephoneNumber,
      faxNumber: prescriberResponseData.faxNumber,
      email: orderDetailsResponseData.prescriberEmailAddress,
    };
    return mappedPrescriberData;
  };

  const resetPrescriberInformation = () => {
    sendNoteObj?.setAddedPrescriberData((p) => ({
      value: null,
      required: true,
      valid: ValidationStatus.UNTOUCHED,
    }));
  };

  const getOrderPrescriberDetails = async () => {
    if (!prescriberData) {
      setOpenLoaderPopUp(true);
      if (
        patient &&
        authObj &&
        authObj.userProfile &&
        authObj.userProfile.userName !== "" &&
        authObj.registeredFaciltyAddress &&
        authObj.registeredFaciltyAddress.siteUseId &&
        authObj.registeredFaciltyAddress.siteUseId !== ""
      ) {
        try {
          const reqParams = {
            RentalOrderNumber: patient.roNumber,
            DOB: moment(patient.dob).format("DD-MMM-yyyy"),
            userName: authObj.userProfile.userName,
            siteUseId: authObj.registeredFaciltyAddress.siteUseId,
            IsAcuteOrder: patient.isAcuteOrder,
          };
          const response = await getVACOrderDetails(reqParams);
          if (response && response.succeeded) {
            mapHomeCareProviderData(response.item);
            let reqParams = {
              NPI: response.item.prescriberNPI,
            };
            const data = await getNPIPrescriber(reqParams);
            if (data != null && data.succeeded && data.items.length > 0) {
              const prescriberData = prescriberDetailsResponseMapper(
                data.items[0],
                response.item
              );
              setPrescriberData(prescriberData);
            } else {
              const prescriberData: IPrescriberModal = {
                firstName: response.item.prescriberFirstName,
                lastName: response.item.prescriberLastName,
                npi: response.item.prescriberNPI,
                email: response.item.prescriberEmailAddress,
                city: "",
                state: "",
                telephoneNumber: "",
                zipCode: "",
                address1: "",
                address2: "",
              };
              setPrescriberData(prescriberData);
            }
          } else {
            if (!response || response.error) {
              setOpenFailurePopUp(true);
              setErrorMessage(ERROR_MSG_GET_PRESCRIBER_DETAILS);
              setErrorCode(
                response?.error?.code ||
                  response?.status ||
                  response?.error?.errorCode
              );
            }
          }
          setOpenLoaderPopUp(false);
        } catch (error) {
          console.log("error", error);
          setOpenLoaderPopUp(false);
        }
      }
    }
  };

  const getHomeOrderDetails = async () => {
    if (
      patient &&
      authObj &&
      authObj.userProfile &&
      authObj.userProfile.userName !== "" &&
      authObj.registeredFaciltyAddress &&
      authObj.registeredFaciltyAddress.siteUseId &&
      authObj.registeredFaciltyAddress.siteUseId !== ""
    ) {
      setOpenLoaderPopUp(true);
      try {
        const reqParams = {
          RentalOrderNumber: sendNoteObj!.patient!.roNumber,
          DOB: moment(sendNoteObj!.patient!.dob).format("DD-MMM-yyyy"),
          userName: authObj?.userProfile!.userName,
          siteUseId: authObj?.registeredFaciltyAddress!.siteUseId,
        };
        const response = await getVACOrderDetails(reqParams);
        if (response.succeeded) {
          const vacOrderSummary: VacOrderSummaryData = response.item;
          setOpenLoaderPopUp(false);
          return vacOrderSummary;
        } else {
          if (!response || response.error) {
            setOpenFailurePopUp(true);
            setErrorMessage(ERROR_MSG_UPDATE_HOMECAREPROVIDER_DETAILS);
            setErrorCode(
              response?.error?.code ||
                response?.status ||
                response?.error?.errorCode
            );
          }
          setOpenLoaderPopUp(false);
        }
        return null;
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  const fetchDropDownContent = async () => {
    const selectedPatient = location.state?.selectedPatient;
    try {
      const ddContent = format("{0}", DD_CONTACT_REASON);
      const response = await getdropDownContent(ddContent);
      if (response.items.length > 0) {
        const reasonsObject = response.items.filter(
          (item: { name: string }) => item.name === DD_CONTACT_REASON
        );
        let reasonsData = reasonsObject[0].data.sort(
          (a: { order: number }, b: { order: number }) =>
            a.order > b.order ? 1 : -1
        );
        if (
          !selectedPatient.isAcuteOrder &&
          selectedPatient.status.toLowerCase() === "closed"
        ) {
          reasonsData = reasonsData.filter(
            (X: any) =>
              X.code !== "4" &&
              X.code !== "5" &&
              X.code !== "1" &&
              X.code !== "2"
          );
        }
        setContactReasons(reasonsData);
        setContactReasonsText(reasonsData.map((x: { text: string }) => x.text));
        return true;
      }
    } catch (error) {
      console.log("error", error);
      return false;
    }
  };

  const retrievePatientAddresses = async () => {
    if (!patientCurrentAddress && !patientPermanentAddress) {
      setOpenLoaderPopUp(true);
      try {
        const roNumber = patient!.roNumber.toString();
        const dob = patient!.dob;
        const reqParams = {
          RentalOrderNumber: roNumber,
          DOB: dob,
        };
        const response = await getPatientAddresses(reqParams);
        if (response.succeeded) {
          const addresses = response.item;
          setPatientCurrentAddress(addresses.currentAddress);
          setPatientPermanentAddress(addresses.permanentAddress);
        }
        setOpenLoaderPopUp(false);
      } catch (error) {
        console.log("error", error);
        setOpenLoaderPopUp(false);
      }
    }
  };

  const callSendNoteAPI = async (params: any) => {
    setOpenLoaderPopUp(true);
    const sendNoteResult = await send3MNote(params);
    setOpenLoaderPopUp(false);
    if (sendNoteResult && sendNoteResult.succeeded) {
      setOpenSuccessPopUp(true);
    } else {
      setOpenFailurePopUp(true);
      setErrorMessage(SVC_SEND_NOTE_FAILURE);
      setErrorCode(
        sendNoteResult?.error?.code ||
          sendNoteResult?.status ||
          sendNoteResult?.error?.errorCode
      );
    }
  };

  const validateAndSetData = (e: any) => {
    let value = getCodeFromText(contactReasons, e.target.value);
    if (value === data.contactResason.value) {
      return;
    } else if (value === "1") {
      retrievePatientAddresses();
    } else if (value === "4") {
      updateHomeCareProviderSetData();
    } else if (value === "5") {
      resetPrescriberInformation();
      if (prescriberData === null) {
        getOrderPrescriberDetails();
      }
    }
    switch (data.contactResason.value) {
      case "1":
        setAddressData(getDeepClone(defaultAddressData));
        break;
      case "2":
        setTransferPatientData(getDeepClone(defaultTransferPatientData));
        break;
      case "3":
        setAskQuestionData(getDeepClone(defaultAskQuestionData));
        break;
      case "4":
        setSelectedHcp(getDeepClone(defaultHcpData));
        break;
      default:
        break;
    }
    const isValid = validator.validate(value, e.target.name);
    setData((dt: ISendNote) => ({
      ...dt,
      [e.target.name]: {
        value: value,
        valid: isValid!.status,
        required: true,
      },
    }));
  };

  const mapHomeCareProviderData = (orderDetails: VacOrderSummaryData) => {
    let hcp: IHomeCareProviderData = getDeepClone(defaultHcpData);
    if (orderDetails && orderDetails.hcp.facilityName) {
      hcp = {
        addedCaregiverName: {
          ...hcp.addedCaregiverName,
          value: orderDetails.hcp.facilityName,
        },
        addedCaregiverAddress1: {
          ...hcp.addedCaregiverAddress1,
          value: orderDetails.hcp.address.addressLine1,
        },
        addedCaregiverAddress2: {
          ...hcp.addedCaregiverAddress2,
          value: orderDetails.hcp.address.addressLine2,
        },
        addedCaregiverCity: {
          ...hcp.addedCaregiverCity,
          value: orderDetails.hcp.address.city,
        },
        addedCaregiverState: {
          ...hcp.addedCaregiverState,
          value: getTextFromCode(states, orderDetails.hcp.address.stateCode),
        },
        addedCaregiverZip: {
          ...hcp.addedCaregiverZip,
          value: orderDetails.hcp.address.postalCode,
        },
        addedCaregiverPhone: {
          ...hcp.addedCaregiverPhone,
          value: orderDetails.hcp.phoneNo,
        },
        addedCaregiverFacilityType: {
          ...hcp.addedCaregiverFacilityType,
          value: orderDetails.hcp.facilityTypeName,
        },
        addedCaregiverFacilityTypeCode: {
          ...hcp.addedCaregiverFacilityTypeCode,
          value: orderDetails.hcp.facilityType,
        },
        addedCaregiverAccountNumber: {
          ...hcp.addedCaregiverAccountNumber,
          value: orderDetails.hcp.accountNumber,
          valid: ValidationStatus.VALID!,
        },
      };
    }
    setCurrentHcp(hcp);
  };

  const updateHomeCareProviderSetData = async () => {
    if (currentHcp.addedCaregiverName.value !== "") {
      await fetchAllStates();
    } else {
      const orderDetails = await getHomeOrderDetails();
      await fetchAllStates();
      mapHomeCareProviderData(orderDetails!);
    }
  };

  const changeAddress = async () => {
    if (
      patient &&
      patient.dob &&
      patient.dob !== "" &&
      patient.roNumber &&
      authObj &&
      authObj.registeredFaciltyAddress &&
      authObj.registeredFaciltyAddress.siteUseId &&
      authObj.registeredFaciltyAddress.siteUseId !== ""
    ) {
      const roNumber = patient.roNumber.toString();
      const loggedInUserSiteUseID = authObj.registeredFaciltyAddress.siteUseId;
      const dob = patient.dob;
      const patientNewAddress = {
        addressLine1: addressData.address1.value.trim(),
        addressLine2: addressData.address2.value.trim(),
        city: addressData.city.value,
        state: addressData.state.value,
        zipCode: addressData.zip.value,
      };
      const sendNoteRequest = {
        siteUseID: loggedInUserSiteUseID,
        RO: roNumber,
        ContactReason: 0,
        AddressToChange: addressData.addressType.value,
        PatientNewAddress: patientNewAddress,
        ClinicianPhone: formatPhoneNumberWithoutSpecialChar(
          addressData.phone.value
        ),

        Comments: addressData.comment.value,
        dob: dob,
      };
      if (addressData.phone.valid === ValidationStatus.INVALID) {
        setMissingFieldsPopup(true);
      } else {
        callSendNoteAPI(sendNoteRequest);
      }
    }
  };

  const transferPatient = async () => {
    if (
      patient &&
      patient.dob &&
      patient.dob !== "" &&
      patient.roNumber &&
      authObj &&
      authObj.registeredFaciltyAddress &&
      authObj.registeredFaciltyAddress.siteUseId &&
      authObj.registeredFaciltyAddress.siteUseId !== ""
    ) {
      const roNumber = patient.roNumber.toString();
      const loggedInUserSiteUseID = authObj.registeredFaciltyAddress.siteUseId;
      const dob = patient.dob;
      let lastVisitDateString =
        transferPatientData.lastVisitDate.value !== ""
          ? moment(transferPatientData.lastVisitDate.value).format("YYYY-MM-DD")
          : null;
      let sendNoteRequest = {
        siteUseID: loggedInUserSiteUseID,
        RO: roNumber,
        ContactReason: 1,
        LastVisitDateForPatient: lastVisitDateString,
        NewFacilityName: transferPatientData.facilityName.value,
        CaregiverOrPhysicianNowResponsible:
          transferPatientData.careGiverName.value,
        FacilityPhoneNumber: formatPhoneNumberWithoutSpecialChar(
          transferPatientData.phone.value
        ),
        Comments: transferPatientData.comment.value,
        dob: dob,
      };
      if (transferPatientData.phone.valid === ValidationStatus.INVALID) {
        setMissingFieldsPopup(true);
      } else {
        callSendNoteAPI(sendNoteRequest);
      }
    }
  };

  const askQuestion = async () => {
    if (patient && patient.roNumber && getSiteUseId(authObj)) {
      const roNumber = patient.roNumber.toString();
      const loggedInUserSiteUseID = getSiteUseId(authObj);
      const dob = patient.dob;
      let sendNoteRequest = {
        siteUseID: loggedInUserSiteUseID,
        RO: roNumber,
        ContactReason: 2,
        Question: askQuestionData.question.value,
        isAcuteOrder: patient?.isAcuteOrder,
        dob: dob ? dob : null,
      };
      callSendNoteAPI(sendNoteRequest);
    }
  };

  const updateHomeCareProvider = async () => {
    if (patient && patient.roNumber && getSiteUseId(authObj)) {
      const roNumber = patient.roNumber.toString();
      const loggedInUserSiteUseID = getSiteUseId(authObj);
      const dob = patient.dob;
      let sendNoteRequest = {
        siteUseID: loggedInUserSiteUseID,
        RO: roNumber,
        ContactReason: 3,
        NewHCPFacilityId: selectHcp?.addedCaregiverAccountNumber?.value,
        NewHCPFacilityName: selectHcp?.addedCaregiverName?.value,
        NewHCPFacilityAddress: {
          addressLine1: selectHcp?.addedCaregiverAddress1?.value,
          addressLine2: selectHcp?.addedCaregiverAddress2?.value,
          city: selectHcp?.addedCaregiverCity?.value,
          state: selectHcp?.addedCaregiverState?.value,
          zipCode:
            selectHcp?.addedCaregiverZip?.value.length > 5
              ? selectHcp?.addedCaregiverZip?.value?.slice(0, 5)
              : selectHcp?.addedCaregiverZip?.value,
        },
        isAcuteOrder: patient?.isAcuteOrder,
        dob: dob ? dob : null,
      };
      callSendNoteAPI(sendNoteRequest);
    }
  };
  const cancelSendNote = () => {
    history.goBack();
  };

  const submitSendNote = () => {
    let isValid;
    if (!patient?.isAcuteOrder) {
      switch (data.contactResason.value) {
        case "1":
          isValid = validator.validateAll(addressData, setAddressData);
          if (isValid) {
            changeAddress();
          }
          break;
        case "2":
          isValid = validator.validateAll(
            transferPatientData,
            setTransferPatientData
          );
          if (isValid) {
            transferPatient();
          }
          break;
        case "3":
          isValid = validator.validateAll(askQuestionData, setAskQuestionData);
          if (isValid) {
            askQuestion();
          }
          break;
        case "4":
          isValid = validator.validateAll(selectHcp, setSelectedHcp);
          if (isValid) {
            updateHomeCareProvider();
          }
          break;
        case "5":
          isValid =
            validator.validateAll(
              addedPrescriberData,
              setAddedPrescriberData,
              true,
              newPrescriberEmail,
              setNewPrescriberEmail,
              newPrescriberEmailMultiple,
              setNewPrescriberEmailMultiple,
              isEditButtonClicked
            ) &&
            validator.validateAll(additionalInfoData, setAdditionalInfoData);
          if (isValid && prescriberObj?.addedPrescriberData.value.email) {
            callSendNoteAPI(
              mapToRequestParams(
                authObj?.registeredFaciltyAddress?.siteUseId!,
                patient!,
                prescriberObj?.addedPrescriberData!,
                additionalInfo?.additionalInfoData!
              )
            );
          }
          break;
        default:
          break;
      }
    } else {
      isValid = validator.validateAll(askQuestionData, setAskQuestionData);
      if (isValid) {
        askQuestion();
      }
    }
  };

  const openOrderOverview = () => {
    orderOverViewObj?.resetSeletedSupplyOrderData();
    orderOverViewObj?.resetWoundData();
    if (selectedPatient) {
      history.push({
        pathname: "/home/orderOverview",
        state: {
          stateData: selectedPatient,
        },
      });
    }
  };

  const fetchAllStates = async () => {
    try {
      const ddContent = format("{0}", DD_US_STATES_CONTENT);
      const data = await getdropDownContent(ddContent);
      if (data.items.length > 0) {
        const commonDocObject = data.items.filter(
          (item: { name: string }) => item.name === DD_US_STATES_CONTENT
        );
        const stateObj = commonDocObject[0].data.sort(
          (a: { order: number }, b: { order: number }) =>
            a.order > b.order ? 1 : -1
        );
        const stateText = stateObj.map((x: { text: any }) => x.text);
        setStatesText(stateText);
        setStates(stateObj);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    history.replace(location.pathname, {});
    if (testData) {
      setData(testData);
      setPatient(patientMockData);
    }
    const selectedPatient = location.state?.selectedPatient;
    if (!patient) {
      if (selectedPatient) {
        setPatient(selectedPatient);
        if (!selectedPatient.placementDate) {
          getDataForSendNote();
        }
      } else {
        history.replace("/home", {});
      }
    }
    if (!selectedPatient?.isAcuteOrder) fetchDropDownContent();
  }, []);

  return (
    <div className="send-note-component">
      {loading && (
        <div className="send-note-loader">
          <LoadingSpinner />
        </div>
      )}
      {!loading && (
        <Navigator
          array={[
            {
              route: "/home",
              pageName: "My Patients",
            },
          ]}
          className="send-note-route-section"
          title="Send a Note"
        />
      )}
      {error && !loading && (
        <ErrorPopup
          popUpStyles="error-popup-design"
          errorMessage={ERROR_MSG_SEND_NOTE}
          errorPopupFlag={error}
          handleBackButton={() => {
            history.goBack();
          }}
          buttontitle="Done"
          showTitle={false}
          errorCode={errorCode}
        />
      )}
      {!error && !loading && (
        <>
          <div className="short-form">
            <div className="headernote">
              <h4 className="title" data-testid="title">
                Send a Note
              </h4>
              <h5 className="description" data-testid="description">
                Please select your contact reason and enter information and
                comments below. You will receive an email confirmation that your
                comments have been sent. Requests and comments are processed
                Monday through Friday (except for Holidays) from 7 a.m. to 7
                p.m. If this is an urgent matter, please contact the National
                Contact Center at 1-800-275-4524.
              </h5>
              {patient && (
                <PatientDetails
                  isAcuteOrderFlow={patient!.isAcuteOrder ?? false}
                  patient={patient!}
                  openOrderOverview={openOrderOverview}
                  isSendNoteFlow={true}
                />
              )}
              {!patient?.isAcuteOrder && (
                <div
                  className="send-note-contact-reason-div"
                  data-testid="send-note-contact-reason-div"
                >
                  <InputWithLabel
                    error={
                      data.contactResason.valid === ValidationStatus.INVALID
                    }
                    isRequired={data.contactResason.required}
                    label="Contact Reason"
                    labelClassName="send-note-contact-reason"
                    testId="send-note-contact-reason"
                    isDropdown={true}
                  >
                    <CustomDropDown
                      handleChange={validateAndSetData}
                      menuItem={contactReasonsText}
                      name="contactResason"
                      placeHolder="Select Reason"
                      selectpropsClassName={
                        data.contactResason.value
                          ? "send-note-select"
                          : "placeHolder"
                      }
                      selectClassName={
                        data.contactResason.value
                          ? "send-note-input"
                          : "placeHolder"
                      }
                      testId="contactResason-dropdown"
                      value={
                        data.contactResason.value
                          ? getTextFromCode(
                              contactReasons,
                              data.contactResason.value
                            )
                          : null
                      }
                    />
                  </InputWithLabel>
                </div>
              )}
            </div>
          </div>
          {patient?.isAcuteOrder ? (
            <>
              {" "}
              <div className="ask-question-div" data-testid="ask-question-div">
                <AskQuestion
                  data={askQuestionData}
                  setData={setAskQuestionData}
                />
              </div>{" "}
            </>
          ) : (
            <>
              {" "}
              {data.contactResason.value === "1" && (
                <div
                  className="change-address-div"
                  data-testid="change-address-div"
                >
                  <ChangeAddress
                    currentAddress={patientCurrentAddress}
                    data={addressData}
                    permanentAddress={patientPermanentAddress}
                    setData={setAddressData}
                  />
                </div>
              )}
              {data.contactResason.value === "2" && (
                <div
                  className="transfer-patient-div"
                  data-testid="transfer-patient-div"
                >
                  <TransferPatient
                    data={transferPatientData}
                    setData={setTransferPatientData}
                  />
                </div>
              )}
              {data.contactResason.value === "3" && (
                <div
                  className="ask-question-div"
                  data-testid="ask-question-div"
                >
                  <AskQuestion
                    data={askQuestionData}
                    setData={setAskQuestionData}
                  />
                </div>
              )}
              {data.contactResason.value === "4" && (
                <div
                  className="updateHomeCareProvider-div"
                  data-testid="updateHomeCareProvider-div"
                >
                  <p className="updateHomeCareProvider-selectedtitle">
                    Current selected home care provider
                  </p>

                  <SelectedHomeCareProvider
                    data={currentHcp!}
                    isNewHcpSelected={false}
                  />

                  <HomeCareProvider
                    data={selectHcp!}
                    setData={setSelectedHcp}
                    states={states}
                    statesText={statesText}
                  />
                </div>
              )}
              {data.contactResason.value === "5" && (
                <div
                  className="current-selected-prescriber-div"
                  data-testid="current-selected-prescriber-div"
                >
                  <SelectedPrescriber data={prescriberData} />
                  <AddPrescriberInformation
                    data={addedPrescriberData}
                    prescriberInformation={addedPrescriberData.value}
                    setData={setAddedPrescriberData}
                    newPrescriberEmail={newPrescriberEmail}
                    setNewPrescriberEmail={setNewPrescriberEmail}
                    newPrescriberEmailMultiple={newPrescriberEmailMultiple}
                    setNewPrescriberEmailMultiple={
                      setNewPrescriberEmailMultiple
                    }
                    isEditButtonClicked={isEditButtonClicked}
                    setIsEditButtonClicked={setIsEditButtonClicked}
                  />
                </div>
              )}
            </>
          )}
        </>
      )}
      <div>
        <FooterButtonGroup
          firstButtonTitle="Cancel"
          firstButtonAction={() => {
            if (error) {
              cancelSendNote();
            } else {
              setOpenCancelPopUp(true);
            }
          }}
          secondButtonTitle="Send Note"
          secondButtonDisabled={
            (patient?.isAcuteOrder
              ? false
              : data.contactResason.valid !== ValidationStatus.VALID) ||
            permissionObj?.mappedRolesPermissionData.IsSupportRole
          }
          secondButtonAction={submitSendNote}
        />
      </div>
      <Popup
        openFlag={openCancelPopUp}
        closeHandler={() => setOpenCancelPopUp(false)}
        dialogParentClass={"send-note-cancel-pop-up"}
        data-testid="cancel-pop-up"
      >
        <SendNoteCancel
          backButtonAction={cancelSendNote}
          closeButtonAction={() => setOpenCancelPopUp(false)}
        />
      </Popup>
      <Popup
        openFlag={openLoaderPopUp}
        closeHandler={() => setOpenLoaderPopUp(false)}
        dialogParentClass={"send-note-loader-pop-up"}
        data-testid="loader-pop-up"
        hideCloseButton={true}
      >
        <div className="send-note-loader">
          <LoadingSpinner />
        </div>
      </Popup>
      <Popup
        openFlag={openSuccessPopUp}
        closeHandler={() => setOpenSuccessPopUp(false)}
        dialogParentClass={"send-note-success-pop-up"}
        data-testid="success-pop-up"
        hideCloseButton={true}
      >
        <div>
          <SendNoteSuccess
            backButtonAction={() => {
              history.push("/home");
            }}
          />
        </div>
      </Popup>
      <ErrorPopup
        errorPopupFlag={openFailurePopUp}
        handleBackButton={() => setOpenFailurePopUp(false)}
        popUpStyles="errorPopup"
        errorMessage={errorMessage}
        buttontitle="Done"
        showTitle={false}
        isShortSentence={true}
        isSupportPhoneRequired={true}
        errorCode={errorCode}
      />
      <Popup
        openFlag={missingFieldsPopup}
        closeHandler={() => {
          setMissingFieldsPopup(false);
        }}
      >
        <MissingRequiredFieldPopup
          missingRequiredFieldPrompt={() => setMissingFieldsPopup(false)}
        />
      </Popup>
    </div>
  );
};
