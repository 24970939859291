import { useContext } from "react";
import {
  NewOrderContext,
  NewOrderContextType,
} from "../../../../../context/NewOrderContext";
import Table from "../../../../../core/customSortingTable/table.component";
import { makeCapitalEachWordInString } from "../../../../../util/utilityFunctions";
import {
  INationalRegistry,
  INationalRegistryResultList,
} from "./nationalRegistry.interface";
import "./nationalRegistryResult.css";
import {
  AdminMyListsContext,
  AdminMyListsContextType,
} from "../../../../../context/AdministrationMyListsContext";

const NationalRegistryResultList = ({
  columns,
  nationalRegistryResultList,
  handleSorting,
  handleselectOption,
  isLocalPrescriberSearchList,
  handleAddPrescriberToDB,
}: INationalRegistryResultList) => {
  const NewOrderObj = useContext<NewOrderContextType | null>(NewOrderContext);
  const myListObj = useContext<AdminMyListsContextType | null>(
    AdminMyListsContext
  );

  const handleSelectLinkForNational = (value: INationalRegistry) => {
    NewOrderObj?.setSearchPrescriberPopup(false);
    const temp = {
      ...value,
      email: "",
    };
    NewOrderObj?.setPrescriberAddedData(temp);
    NewOrderObj?.setPrescriberList(temp);
    NewOrderObj?.setPrescriberEmailList([]);
  };

  const handlePrescrbierSelection = (data: INationalRegistry) => {
    if (myListObj?.isFromMyList) {
      handleAddPrescriberToDB(data);
    } else {
      if (isLocalPrescriberSearchList) {
        handleselectOption(data);
      } else handleSelectLinkForNational(data);
    }
  };

  return (
    <div className="nr-table">
      <Table
        tableClassName="table"
        tableColumns={columns}
        handleSorting={handleSorting}
      >
        {nationalRegistryResultList.length > 0 ? (
          <tbody>
            {nationalRegistryResultList.map((data: INationalRegistry) => {
              return (
                <tr>
                  <td
                    className="select-patient-link"
                    data-testid="prescriber-select-link"
                    onClick={() => handlePrescrbierSelection(data)}
                  >
                    Select
                  </td>
                  <td
                    className="table-static-data"
                    data-testid="prescriber-name-value"
                  >{`${makeCapitalEachWordInString(
                    data.lastName
                  )}, ${makeCapitalEachWordInString(data.firstName)}`}</td>
                  <td
                    className="table-static-data"
                    data-testid="prescriber-npi-value"
                  >
                    {data.npi}
                  </td>
                  <td
                    className="table-static-data"
                    data-testid="prescriber-city-value"
                  >
                    {makeCapitalEachWordInString(data.city)}
                  </td>
                  <td
                    className="table-static-data"
                    data-testid="prescriber-state-value"
                  >
                    {data.state}
                  </td>
                </tr>
              );
            })}
          </tbody>
        ) : (
          ""
        )}
      </Table>
    </div>
  );
};

export default NationalRegistryResultList;
