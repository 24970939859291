import "./saveSuccessfull.css";
import { useContext } from "react";
import { Redirect, useHistory } from "react-router-dom";
import CircleSuccess from "../../../assets/checkcircle.svg";
import { AuthContextType, AuthContext } from "../../../context/AuthContext";
import { ExpressButton } from "../../../core/expressButton/expressButton.component";

type Props = {
  returnButtonText: string;
  returnToPath: string;
};
export const SaveSuccessfull = ({ returnButtonText, returnToPath }: Props) => {
  const history = useHistory();
  const authObj = useContext<AuthContextType | null>(AuthContext);

  const handleReturnHome = () => {
    authObj?.setProfileSaveAuthicated(false);
    if (localStorage.getItem("isComingFromSSO") === "true") {
      history.push("/ssoRedirect");
    } else {
      history.push(returnToPath);
    }
  };
  return (
    <div>
      <div className="successCardContainer">
        {authObj?.profileSaveAuthicated ? (
          <>
            <img src={CircleSuccess} alt={CircleSuccess} />
            <div className="successtext">Success!</div>
            {returnToPath === "/" ? (
              <div>
                <div className="accnt-submitted">
                  Your account request has been submitted
                </div>
                <div className="accnt-submitted-msg">
                  You have successfully signed up for 3M™ Express Therapy
                  Portal. If you have any questions, please contact our 3M™
                  Express Therapy Portal support team at{" "}
                  <b>1-800-275-4524 ext. 41858</b>
                </div>
              </div>
            ) : (
              <div className="successmsg">
                Your user account information has been updated. Select ‘Return
                home’ to go back to the dashboard.
              </div>
            )}

            <ExpressButton
              clickHandler={handleReturnHome}
              parentClass="returnbutton"
              variant="contained"
            >
              {returnButtonText}
            </ExpressButton>
          </>
        ) : (
          <Redirect to={returnToPath} />
        )}
      </div>
    </div>
  );
};
