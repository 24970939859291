import { Grid } from "@mui/material";
import "./therapyStartDateReview.css";
import { IInputField } from "../../../../../core/interfaces/input.interface";

interface Props {
  data: IInputField;
  editButtonAction?: Function;
  isSummary?: boolean;
}

export const TherapyStartDateReview = ({
  data,
  editButtonAction,
  isSummary = false,
}: Props) => {
  const showTitleAndValue = (
    title: string,
    value: string | null,
    testId: string
  ) => {
    return (
      <div className="show-title-and-value-div">
        <p
          className="show-title-and-value-title"
          data-testid={`${testId}-title`}
          id={`${testId}-title-id`}
        >
          {title}
        </p>
        <p
          className="show-title-and-value-value"
          data-testid={`${testId}-value`}
          id={`${testId}-value-id`}
        >
          {value && value !== "" ? value.trim() : "--"}
        </p>
      </div>
    );
  };

  return (
    <div className="inpatient-therapy-start-date-review-component">
      <div className="inpatient-therapy-start-date-review-header">
        <p
          className="inpatient-therapy-start-date-review-title"
          data-testid="inpatient-therapy-start-date-review-title"
          id="inpatient-therapy-start-date-review-title-id"
        >
          Therapy Information
        </p>
        {!isSummary && (
          <button
            className="inpatient-therapy-start-date-review-edit-button"
            data-testid="inpatient-therapy-start-date-review-edit-button"
            id="inpatient-therapy-start-date-review-edit-button-id"
            onClick={() =>
              editButtonAction!("inpatient-therapy-start-date-main-div")
            }
          >
            Edit
          </button>
        )}
      </div>
      <Grid
        className="inpatient-therapy-start-date-review-grid-container"
        container
      >
        <Grid
          className="inpatient-therapy-start-date-review-grid-item"
          item
          xs={12}
        >
          {showTitleAndValue(
            "Therapy Start Date",
            data.value,
            "therapy-start-date"
          )}
        </Grid>
      </Grid>
    </div>
  );
};
