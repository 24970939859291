import { Typography } from "@mui/material";
import { LoadingSpinner } from "../loader/LoadingSpinner";
import "./featuresInfoPopup.css";
import parse from "html-react-parser";

export interface IUserGuidance {
  bodyCopy: string;
  imageLink: string;
  labelText: string;
}

interface FeaturesInfoPopupProps {
  cmsUserGuidence: IUserGuidance;
  cmsLoader: boolean;
}

const FeaturesInfoPopup = ({
  cmsUserGuidence,
  cmsLoader,
}: FeaturesInfoPopupProps) => {
  const LoadSpinner = () => {
    return (
      <div className="myPatientListSpinner" data-testid="loading-spinner">
        <LoadingSpinner />
      </div>
    );
  };

  return (
    <>
      {cmsLoader ? (
        LoadSpinner()
      ) : (
        <div>
          <Typography
            className="add-new-patient-update-title"
            data-testid="add-new-patient-update-title"
          >
            {cmsUserGuidence?.labelText}
          </Typography>
          <img
            className="add-new-patient-update-img"
            src={cmsUserGuidence?.imageLink}
          />
          <p
            className="add-new-patient-update-content"
            data-testid="add-new-patient-update-content"
          >
            {parse(cmsUserGuidence?.bodyCopy || "")}
          </p>
        </div>
      )}
    </>
  );
};

export default FeaturesInfoPopup;
