import "./userProfile.css";
import {
  DD_DEPARTMENT_CONTENT,
  DD_LICENSE_CONTENT,
  SVC_GET_USER,
} from "../../../../util/staticText";
import { Button } from "@mui/material";
import {
  formatPhoneNumber,
  getFacilityPermissionName,
  makeCapitalEachWordInString,
  showVacOrderMenuOption,
  updatePermissionBasedOnAcuteFacility,
} from "../../../../util/utilityFunctions";
import { format } from "react-string-format";
import {
  addUserDetails,
  getFacilityDetails,
  getUserDetails,
  resetPassword,
  updateUserDetails,
} from "../../../../util/facilityUserService";
import {
  UserProfileContext,
  UserProfileContextType,
} from "../../../../context/UserProfileContext";
import { useHistory, useLocation } from "react-router-dom";
import {
  IGetUserDetailsApiResponse,
  IUserFacilityData,
  IUserPermissionsData,
  IUserProfile,
  IUserUnlinkedFacilityData,
} from "./userProfile.interface";
import React, { useContext, useEffect, useState } from "react";
import { Popup } from "../../../../core/popup/popup.component";
import { SiteAccess } from "./siteAccess/siteAccess.component";
import { Validator } from "../../../../util/order.validations";
import { getdropDownContent } from "../../../../util/dropDownService";
import { getRolePermissions } from "../../../../util/facilityService";
import { LoadingSpinner } from "../../../../core/loader/LoadingSpinner";
import { IFacilityToUser } from "./addFacility/addFacilityToUser.interface";
import { AddFacilityToUser } from "./addFacility/addFacilityToUser.component";
import { ValidationStatus } from "../../../../core/interfaces/input.interface";
import { AuthContext, AuthContextType } from "../../../../context/AuthContext";
import { Navigator } from "../../../helpAndSupport/Navigator/navigator.component";
import {
  FacilityMode,
  IFacility,
} from "../../../manageProfile/facilityInformation/facility.interface";
import { UserProfileFooterButtonGroup } from "./footerButton/userProfileFooterButton.component";
import { UserAccountInformation } from "./userAccountInformation/userAccountInformation.component";
import { SendNoteFailure } from "../../../send3MNote/popUps/failurePopUp/sendNoteFailure.component";
import { AddFacilityContext } from "../../../manageProfile/facilityInformation/addFacilityContainer/addFacilityContainer.context";
import { AddFacilityContainer } from "../../../manageProfile/facilityInformation/addFacilityContainer/addFacilityContainer.component";
import { UserProfileValidator } from "./userProfile.validator";
import { EditFacility } from "./editFacility/editFacility.component";
import {
  RolesPermissionContext,
  RolesPermissionContextType,
} from "../../../../context/RolesPermissionContext";
import { getDeepClone, getProperty } from "../../../../util/ObjectFunctions";
import { UserProfileComingFrom } from "../../manageUserAccounts/manageUserAccounts.enum";
import {
  getUser,
  getUserRolePermission,
  linkToFacility,
} from "../../../../util/userService";
import { IPermissionData } from "../../../../RolesPermission/RolesPermission.model";
import { mapUserRolesAndPermissionData } from "../../../../RolesPermission/RolesPermission.Mapper";
import { ISideNav } from "../../../sideNav/sideNavMenuOption.interface";
import UserProfileUpdateConfirmationPopup from "./userProfileUpdateConfirmation/userProfileUpdateConfirmationPopup";
import UserProfileUpdateConfirmation from "./userProfileUpdateConfirmation/userProfileUpdateConfirmation.component";
import { searchFacility } from "../../../manageProfile/facilityInformation/addFacility/addFacility.service";
import { RequestedSites } from "./requestedSites/requestedSites.component";
import { IUnlinkedUser } from "../../manageUserAccounts/manageUserAccounts.interface";
import { ExpressButton } from "../../../../core/expressButton/expressButton.component";
import { UnlinkedUserLinkedSuccess } from "../../manageUserAccounts/UnlinkedUsers/UnlinkedUserLinkedSuccess/unlinkedUserLinkedSuccess.component";
import {
  InternalSignOnContextType,
  InternalSignOnContext,
} from "../../../../context/InternalSignOnContext";
import ErrorPopup from "../../../../core/errorPopup/errorPopup.component";
import {
  SVC_ADD_USER_PROFILE,
  SVC_FETCH_FACILITIES,
  SVC_FETCH_USER_DETAILS,
  SVC_RESET_PASSWORD,
  SVC_UPDATE_USER_PROFILE_DETAILS,
} from "../../../../util/errorMsg";
import { checkPostAcuteFacility } from "../../../../util/3meService";

export const UserProfile = () => {
  const userProfileObj = useContext<UserProfileContextType | null>(
    UserProfileContext
  );
  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const internalObj = useContext<InternalSignOnContextType | null>(
    InternalSignOnContext
  );
  const history = useHistory();
  const location: any = useLocation();
  const isAddingNewUser = location.state
    ? location.state.isAddingNewUser
    : false;
  const isComingFrom = location.state
    ? location.state.isComingFrom
    : UserProfileComingFrom.MANAGE_USER;
  let selectedUserName = location.state
    ? location.state.selectedUserName
    : null;
  const showUnlinkedFacility = location.state
    ? location.state.showUnlinkedFacility
    : false;
  const regFacilities = location.state ? location.state.regFacility : null;
  const [siteUseId, setSiteUseId] = useState<string>(
    authObj?.registeredFaciltyAddress?.siteUseId ?? ""
  );

  const [licenseType, setLicenseType] = React.useState([]);
  const [department, setDepartment] = React.useState([]);

  const [message, setMessage] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);

  const [facilities, setFacilities] = useState<IFacilityToUser[]>([]);
  const [openLoaderPopUp, setOpenLoaderPopUp] = useState<boolean>(false);

  const [openAddFacilityPopUp, setOpenAddFacilityPopUp] =
    useState<boolean>(false);
  const [showSelectAllBtn, setShowSelectAllBtn] = useState<boolean>(false);
  const [isAddBtnEnabled, setIsAddBtnEnabled] = useState<boolean>(false);
  const [isNavigationBackRequired, setIsNavigationBackRequired] =
    useState<boolean>(false);

  const [editableFacility, setEditableFacilty] =
    useState<IUserFacilityData | null>(null);
  const [openEditFacilityPopUp, setOpenEditFacilityPopUp] =
    useState<boolean>(false);

  const [resetPasswordSuccessPopUp, setResetPasswordSuccessPopUp] =
    useState<boolean>(false);
  const [resetPasswordFailurePopUp, setResetPasswordFailurePopUp] =
    useState<boolean>(false);

  const [updateDetailsSuccessPopUp, setUpdateDetailsSuccessPopUp] =
    useState<boolean>(false);
  const [isDeepLink, setIsDeepLink] = useState(false);
  const [selectedUserNameState, setSelectedUserNameState] = useState("");
  const [isUpdateClicked, setIsUpdateClicked] = useState(false);
  const [errorCode, setErrorCode] = useState<string>("");

  // Link to Facility Functionality
  const [selectedUnlinkedFacility, setSelectedUnlinkedFacility] =
    useState<IUnlinkedUser | null>(null);
  const [
    selectedUnlinkedToLinkedFacility,
    setSelectedUnlinkedToLinkedFacility,
  ] = useState<IFacilityToUser | null>(null);
  const [openFacilitySearch, setOpenFacilitySearch] = useState<boolean>(false);
  const [linkToFacilitySuccessPopUp, setLinkToFacilitySuccessPopUp] =
    useState<boolean>(false);
  const [linkToFacilityFailurePopUp, setLinkToFacilityFailurePopUp] =
    useState<boolean>(false);

  const checkUserProfileDeeplink = () => {
    return authObj?.deepLinkPath &&
      authObj?.deepLinkPath.includes(
        "/administration/manageUsers/userProfile/deepLink"
      )
      ? true
      : false;
  };

  const checkValidFacility = (
    allFacilities: IFacility[],
    siteUseID: string
  ) => {
    let facilityFound: IFacility[] = allFacilities?.filter(
      (item: IFacility) => item.siteUseId === siteUseID
    );
    if (facilityFound.length === 0) {
      history.replace("/home");
    } else {
      authObj?.setregisteredFaciltyAddress(facilityFound[0]);
      callUserRolePermission(facilityFound[0]);
    }
  };

  const getUserDetailsFromServer = async () => {
    setOpenLoaderPopUp(true);
    const result = await Promise.all([
      getUserDetailsAPI(),
      fetchDropdownContent(),
    ]);
    setOpenLoaderPopUp(false);
    if (result.length === 2) {
      const getUserDetailsResp = result[0];
      if (!getUserDetailsResp.succeeded && getUserDetailsResp.errorCode) {
        setIsNavigationBackRequired(true);
        setMessage(SVC_FETCH_USER_DETAILS);
        setErrorCode(getUserDetailsResp.errorCode!);
        setResetPasswordFailurePopUp(true);
      } else {
        const userDetails = getUserDetailsResp.data;
        if (userDetails) {
          const validator = new UserProfileValidator();
          const data = userProfileObj!.profileDetails;
          const tempFacilities = userDetails?.facilities.map(
            (tempFacility: IUserFacilityData) => {
              tempFacility.userRole =
                tempFacility.userRole.toLowerCase() === "clinician"
                  ? tempFacility.userRole
                  : "Admin";
              const isPostAcuteFacility = tempFacility.postAcuteFound ?? false;
              const updatedPermissions: IUserPermissionsData[] | undefined =
                tempFacility.permissions?.map(
                  (permission: IUserPermissionsData) => {
                    const isShowPermission =
                      updatePermissionBasedOnAcuteFacility(
                        isPostAcuteFacility,
                        permission.name,
                        permission.status as string
                      );
                    permission.status = isShowPermission
                      ? permission.status
                        ? permission.status === "Enabled"
                        : false
                      : false;
                    permission.isHide = !isShowPermission;
                    return permission;
                  }
                );
              tempFacility.permissions = updatedPermissions ?? [];
              return tempFacility;
            }
          );
          const tempUnlinkedFacilities = userDetails.unlinkedFacilities;
          const tempData = {
            ...data,
            firstName: {
              value: userDetails.firstName,
              valid:
                validator.validate(userDetails.firstName, "firstName")
                  ?.status ?? ValidationStatus.UNTOUCHED,
              required: true,
            },
            lastName: {
              value: userDetails.lastName,
              valid:
                validator.validate(userDetails.lastName, "lastName")?.status ??
                ValidationStatus.UNTOUCHED,
              required: true,
            },
            userName: {
              value: userDetails.emailAddress,
              valid:
                validator.validate(userDetails.emailAddress, "email")?.status ??
                ValidationStatus.VALID,
              required: false,
            },
            title: {
              value: userDetails.title,
              valid: userDetails.title
                ? validator.validate(userDetails.title, "title")?.status ??
                  ValidationStatus.UNTOUCHED
                : ValidationStatus.UNTOUCHED,
              required: true,
            },
            email: {
              value: userDetails.emailAddress,
              valid:
                validator.validate(userDetails.emailAddress, "email")?.status ??
                ValidationStatus.UNTOUCHED,
              required: true,
            },
            phone: {
              value: userDetails.mobilePhoneNo
                ? formatPhoneNumber(userDetails.mobilePhoneNo)
                : `${formatPhoneNumber(userDetails.phoneNo)}${
                    userDetails.extension ? ` ext.${userDetails.extension}` : ""
                  }`,
              valid: ValidationStatus.VALID,
              required: true,
            },
            department: {
              value: userDetails.departmentName,
              valid: ValidationStatus.VALID,
              required: true,
            },
            licenseType: {
              value: userDetails.licenceType,
              valid: ValidationStatus.VALID,
              required: true,
            },
            facilities: tempFacilities ?? [],
            unlinkedFacilities: tempUnlinkedFacilities,
            phoneType: {
              value: userDetails.mobilePhoneNo ? "mobile" : "phone",
              valid: ValidationStatus.VALID,
              required: false,
            },
          };
          updatePermissionCount(tempData, userDetails.facilities ?? []);
          authObj?.setDeepLinkPath(null);
          sessionStorage.removeItem("DeepLinkPath");
        }
      }
    }
  };

  const getUserDetailsAPI = async (): Promise<IGetUserDetailsApiResponse> => {
    let params;
    if (
      authObj &&
      authObj.userProfile &&
      authObj.userProfile.userName !== "" &&
      authObj.registeredFaciltyAddress &&
      authObj.registeredFaciltyAddress.siteUseId &&
      authObj.registeredFaciltyAddress.siteUseId !== ""
    ) {
      if (checkUserProfileDeeplink()) {
        const urlData = authObj?.deepLinkPath!.split("/");
        const onbj: any = JSON.parse(atob(urlData![5]));
        params = {
          Username: authObj.userProfile.userName,
          SelectedUsername: onbj.SelectedUserName,
          SiteUseId: onbj.SiteUseId,
          ShowUnlinkedFacility: onbj.showUnlinkedFacility ?? false,
        };
      } else {
        params = {
          Username: authObj.userProfile.userName,
          SelectedUsername: selectedUserName,
          SiteUseId: authObj.registeredFaciltyAddress.siteUseId,
          ShowUnlinkedFacility: showUnlinkedFacility ?? false,
        };
      }
      const response = await getUserDetails(params);
      if (response && response.succeeded) {
        return { succeeded: true, data: response.data };
      } else {
        return {
          succeeded: false,
          errorCode:
            response?.error?.errorCode ||
            response?.status ||
            response?.error?.code,
        };
      }
    } else {
      if (internalObj?.isInternalAdministration) {
        params = {
          Username: authObj?.userProfile?.userName,
          SelectedUsername: selectedUserName,
          SiteUseId: null,
          ShowUnlinkedFacility: showUnlinkedFacility ?? false,
        };
      }
      const response = await getUserDetails(params);
      if (response && response.succeeded) {
        return { succeeded: true, data: response.data };
      } else {
        return {
          succeeded: false,
          errorCode:
            response?.error?.errorCode ||
            response?.status ||
            response?.error?.code,
        };
      }
    }
  };

  const fetchDropdownContent = async () => {
    try {
      const ddContent = format(
        "{0},{1}",
        DD_LICENSE_CONTENT,
        DD_DEPARTMENT_CONTENT ?? ""
      );
      const data = await getdropDownContent(ddContent);
      if (data.items.length > 0) {
        const licenseObject = data.items.filter(
          (item: { name: string }) => item.name === DD_LICENSE_CONTENT
        );
        const licenseData = licenseObject[0].data
          .sort((a: { order: number }, b: { order: number }) =>
            a.order > b.order ? 1 : -1
          )
          .map((x: { code: any }) => x.code);
        setLicenseType(licenseData);
        const departmentObject = data.items.filter(
          (item: { name: string }) => item.name === DD_DEPARTMENT_CONTENT
        );
        const departmentData = departmentObject[0].data
          .sort((a: { order: number }, b: { order: number }) =>
            a.order > b.order ? 1 : -1
          )
          .map((x: { code: any }) => x.code);
        setDepartment(departmentData);
        return [licenseData, departmentData];
      }
      return [];
    } catch (error) {
      console.log("error", error);
      return [];
    }
  };

  const fetchFacilities = async () => {
    if (authObj && authObj.userProfile && authObj.userProfile.userName !== "") {
      setOpenLoaderPopUp(true);
      const params = {
        Username: authObj.userProfile.userName,
        SelectedUsername: selectedUserName ? selectedUserName : null,
      };
      const response = await getFacilityDetails(params);
      setOpenLoaderPopUp(false);
      if (response && response.succeeded) {
        let allFacilities = response.data;
        allFacilities.forEach((facility: IFacilityToUser) => {
          const data = userProfileObj!.profileDetails;
          const existingFacilities = data.facilities.filter(
            (existingFacility: IUserFacilityData) =>
              existingFacility.siteUseId === facility.siteUseId
          );
          facility.isOriginalSelected = facility.isSelected;
          if (existingFacilities.length > 0) {
            facility.isSelected = true;
          } else {
            facility.isSelected = false;
          }
          facility.activityStauts = facility.isSelected ? 1 : null;
        });
        setFacilities(allFacilities);
        setShowSelectAllBtn(false);
        setOpenAddFacilityPopUp(true);
      } else {
        setIsNavigationBackRequired(false);
        setOpenLoaderPopUp(false);
        setMessage(SVC_FETCH_FACILITIES);
        setErrorCode(
          response?.error?.errorCode ||
            response?.status ||
            response?.error?.code
        );
        setResetPasswordFailurePopUp(true);
      }
    }
  };

  const resetPasswordAPI = async (userName: string) => {
    const params = {
      Username: userName,
    };
    setOpenLoaderPopUp(true);
    const response = await resetPassword(params);
    setOpenLoaderPopUp(false);
    if (response && response.succeeded) {
      setResetPasswordSuccessPopUp(true);
    } else {
      setIsNavigationBackRequired(false);
      setMessage(SVC_RESET_PASSWORD);
      setErrorCode(
        response?.error?.errorCode || response?.status || response?.error?.code
      );
      setResetPasswordFailurePopUp(true);
    }
  };

  const getRolePermissionsInfo = async (siteUseId: string) => {
    const reqBody = {
      siteUseId: siteUseId,
    };
    const response = await getRolePermissions(reqBody);
    if (response && response.succeeded) {
      const permissions = response.data.permissions;
      if (permissions.length > 0) {
        const validFacilityPermissionArray: [] = permissions.filter(
          (x: any) => getFacilityPermissionName(x.permissionName) !== ""
        );
        return {
          siteUseId: siteUseId,
          permissions: validFacilityPermissionArray,
        };
      }
    }
    setOpenLoaderPopUp(false);
    return {
      siteUseId: siteUseId,
      permissions: [],
    };
  };

  const checkThePostAcute = async (
    typeCode: string
  ): Promise<boolean | null> => {
    const response = await checkPostAcuteFacility({
      FacilityTypeCode: typeCode,
    });
    return response.postAcuteFound;
  };

  const callUserRolePermission = async (facilty: IFacility) => {
    let facilityObj = facilty;
    if (facilityObj?.facilityAddressID !== undefined) {
      const userRolesPermissionResponse = await getUserRolePermission(
        facilityObj?.facilityAddressID
      );
      authObj?.setuserRolePermissionData(userRolesPermissionResponse.data);
      const rolesPermissionRes: IPermissionData =
        await mapUserRolesAndPermissionData(
          userRolesPermissionResponse.data!,
          facilityObj?.readyCareFlag
        );
      permissionObj?.setMappedRolesPermissionData(rolesPermissionRes);
      authObj?.setsideNavMenuOptionModelData((dt: ISideNav) => ({
        ...dt,
        orders: {
          ...dt.orders,
          isVisible: showVacOrderMenuOption(rolesPermissionRes),
        },
        inventory: {
          ...dt.inventory,
          isVisible: rolesPermissionRes.IsShowInventoryOption,
        },
        administration: {
          ...dt.administration,
          isVisible: rolesPermissionRes.IsShowAdminstrationOption,
        },
      }));
      authObj?.setuserRolesAndPermissionLoaded(true);
      if (!rolesPermissionRes.IsShowAdminstrationOption) {
        history.replace("/home");
      }
    }
  };

  const linkToFacilityAPI = async (
    facility: any,
    linkedFacility: IFacilityToUser
  ) => {
    const params = {
      LoggedInUserEmail: authObj?.userProfile?.userName ?? "",
      UserEmail: userProfileObj?.profileDetails.email.value ?? "",
      ManualFacilityAddressId:
        selectedUnlinkedFacility!.facilityAddressId.toString(),
      FacilitySiteUseId: facility.siteUseId,
      FacilityNumber: facility.accountNumber,
      FacilityName: facility.accountName,
      CareGiverID: facility.careGiverId,
      FacilityTypeCode: facility.typeCode,
      FacilityAddress: {
        AddressLine1: facility.address1,
        AddressLine2: facility.address2,
        City: facility.city,
        State: facility.state,
        ZipCode: facility.zip,
      },
    };
    setOpenFacilitySearch(false);
    setOpenLoaderPopUp(true);
    const response = await linkToFacility(params);
    setOpenLoaderPopUp(false);
    if (response && response.succeeded) {
      linkedFacility.facilityAddressID = response.data.toString(); // Data is facility address id.
      await setSelectedUnlinkedToLinkedFacility(linkedFacility);
      setLinkToFacilitySuccessPopUp(true);
    } else {
      setLinkToFacilityFailurePopUp(true);
    }
  };

  const updateUserDetailsAPI = async () => {
    setOpenLoaderPopUp(true);
    const data = userProfileObj!.profileDetails;
    const phoneNumber = data.phone.value.split(" ext")[0].replace(/\D/g, "");
    const params = {
      firstName: data.firstName.value,
      lastName: data.lastName.value,
      userName: authObj?.userProfile?.userName ?? "",
      title: data.title.value,
      licenceType: data.licenseType.value,
      departmentName: data.department.value,
      facilityInfo:
        data.facilities.length > 0 ? mapFacilityInfo(data.facilities) : null,
      selectedUserName: selectedUserNameState,
      phoneType: data.phoneType.value === "mobile" ? 1 : 2,
      mobilePhoneNumber:
        data.phoneType.value === "mobile" && data.phone.value
          ? phoneNumber
          : "",
      phoneNumber:
        data.phoneType.value === "phone" && data.phone.value ? phoneNumber : "",
    };
    const reqBody = JSON.stringify(params);
    const response = await updateUserDetails(reqBody);
    setOpenLoaderPopUp(false);
    if (response && response.succeeded) {
      userProfileObj?.setUserProfileGotChanged(false);
      setUpdateDetailsSuccessPopUp(true);
    } else {
      setMessage(SVC_UPDATE_USER_PROFILE_DETAILS);
      setErrorCode(
        response?.error?.errorCode || response?.status || response?.error?.code
      );
      setResetPasswordFailurePopUp(true);
    }
  };

  const addUserDetailsAPI = async () => {
    setOpenLoaderPopUp(true);
    const data = userProfileObj!.profileDetails;
    const type = data!.phoneType.value.toLowerCase() === "mobile" ? 1 : 2;
    const phoneNumber =
      type === 2 ? data.phone.value.replace(/[^+\d]+/g, "") : "";
    const mobilePhoneNumber =
      type === 1 ? data.phone.value.replace(/[^+\d]+/g, "") : "";
    if (type === 1) {
    }
    const params = {
      firstName: data.firstName.value,
      lastName: data.lastName.value?.trim(),
      userName: data.userName.value,
      loggedUserName: authObj?.userProfile?.userName ?? "",
      licenceType: data.licenseType.value,
      departmentName: data.department.value,
      title: data.title.value,
      EmailAddress: data.email.value,
      PhoneType: type,
      PhoneNumber: phoneNumber,
      mobilePhoneNumber: mobilePhoneNumber,
      Extension:
        type === 1
          ? null
          : data!.extension.value !== ""
          ? data!.extension.value
          : null,
      facilityInfo:
        data.facilities.length > 0
          ? mapAddUserFacilityInfo(data.facilities)
          : null,
    };
    const reqBody = JSON.stringify(params);
    const response = await addUserDetails(reqBody);
    setOpenLoaderPopUp(false);
    if (response && response.succeeded) {
      setUpdateDetailsSuccessPopUp(true);
    } else {
      setMessage(SVC_ADD_USER_PROFILE);
      setErrorCode(
        response?.error?.errorCode || response?.status || response?.error?.code
      );
      setResetPasswordFailurePopUp(true);
    }
  };

  const addFacilitiesFromSearch = (result: any) => {
    // If openFacilitySearch is true that means
    // It is Link To Facility Functionality Pop up
    if (openFacilitySearch) {
      // Call new end point to save selected facility to database
      const facility: IFacilityToUser = {
        activityStauts: 1,
        address1: result.address1,
        address2: result.address2,
        city: result.city,
        facilityAddressID: result.facilityAddressID ?? "",
        facilityName: result.accountName,
        isSelected: false,
        isOriginalSelected: false,
        number: result.accountNumber,
        siteUseId: result.siteUseId?.toString() ?? "",
        state: result.state,
        zipCode: result.zip.toString(),
        careGiverId:
          result.careGiverId === null ? "" : result.careGiverId.toString(),
        typeCode: result.typeCode.toString(),
        siteUseCode: result.siteUseCode,
        accountNumber: result.accountNumber,
      };
      linkToFacilityAPI(result, facility);
    } else {
      const facilityList: IFacility[] = result;
      let tempFacilities: IFacilityToUser[] = [];
      facilityList.forEach((facility: IFacility) => {
        const facilityToUser: IFacilityToUser = {
          activityStauts: 2,
          address1: facility.address1,
          address2: facility.address2,
          city: facility.city,
          facilityAddressID: facility.facilityAddressID ?? "",
          facilityName: facility.accountName,
          isSelected: false,
          isOriginalSelected: false,
          number: facility.accountNumber,
          siteUseId: facility.siteUseId?.toString() ?? "",
          state: facility.state,
          zipCode: facility.zip.toString(),
          siteUseCode: facility.siteUseCode,
          accountNumber: facility.accountNumber,
          typeCode: facility.typeCode,
        };
        tempFacilities.push(facilityToUser);
      });
      setFacilities(tempFacilities);
      setOpen(false);
      setOpenAddFacilityPopUp(true);
      setShowSelectAllBtn(false);
    }
  };

  const addFacilityBtnAction = () => {
    const isInternalUser =
      (permissionObj &&
        (permissionObj.mappedRolesPermissionData.Is3MAdminRole ||
          permissionObj.mappedRolesPermissionData.IsSupportRole ||
          permissionObj.mappedRolesPermissionData.IsProdManagerRole)) ??
      false;
    if (isInternalUser) {
      setOpen(true);
      setOpenLoaderPopUp(false);
      setOpenAddFacilityPopUp(false);
    } else {
      fetchFacilities();
    }
  };

  const editBtnClicked = (facility: IUserFacilityData) => {
    setEditableFacilty(facility);
    setOpenEditFacilityPopUp(true);
  };

  const resetPasswordBtnAction = () => {
    if (selectedUserName && selectedUserName !== "") {
      resetPasswordAPI(selectedUserName);
    }
  };

  const closeResetPasswordSuccessPopUp = () => {
    setResetPasswordSuccessPopUp(false);
  };

  const selectOrDeselectAllBtnAction = (isSelected: boolean) => {
    let tempData = [...facilities];
    tempData.forEach((facility: IFacilityToUser) => {
      if (!facility.isOriginalSelected) facility.isSelected = isSelected;
    });
    setFacilities(tempData);
    updateAddBtnEnable();
  };

  const addNewFacilities = () => {
    userProfileObj?.setUserProfileGotChanged(isAddingNewUser ? false : true);
    closeAddFacilityPopUp();
    if (userProfileObj) {
      const originalFacilities: IUserFacilityData[] =
        userProfileObj!.profileDetails.facilities;
      const selectedFacilities = facilities.filter(
        (facility: IFacilityToUser) => facility.isSelected
      );
      const isInternalUser =
        (permissionObj &&
          (permissionObj.mappedRolesPermissionData.Is3MAdminRole ||
            permissionObj.mappedRolesPermissionData.IsSupportRole ||
            permissionObj.mappedRolesPermissionData.IsProdManagerRole)) ??
        false;
      const isMultiFacilityAdmin =
        (authObj &&
          authObj.allFacilities &&
          authObj.allFacilities.length > 1) ??
        false;
      let updatingFacilities: IUserFacilityData[];
      if (!isInternalUser && isMultiFacilityAdmin) {
        updatingFacilities = [];
      } else {
        updatingFacilities = originalFacilities.filter(
          ({ siteUseId: id1 }) =>
            !selectedFacilities.some(({ siteUseId: id2 }) => id2 === id1)
        );
      }
      selectedFacilities.forEach((facility: IFacilityToUser) => {
        let selectedFacility: IUserFacilityData;
        const filteredFacilities = originalFacilities.filter(
          (originalFacility: IUserFacilityData) =>
            originalFacility.siteUseId === facility.siteUseId
        );
        selectedFacility = filteredFacilities[0];
        if (!selectedFacility) {
          let facilityAddressId = parseInt(facility.facilityAddressID);
          selectedFacility = {
            activityStauts: facility.activityStauts!,
            accountName: facility.facilityName,
            accountNumber: facility.number!,
            address1: facility.address1,
            address2: facility.address2,
            city: facility.city,
            facilityAddressID: isNaN(facilityAddressId) ? 0 : facilityAddressId,
            siteUseId: facility.siteUseId,
            state: facility.state,
            zip: facility.zipCode,
            userRole: "Clinician",
            status: "Active",
            permissions: null,
            enabledPermissionsCount: 0,
            availablePermissionsCount: 0,
            siteUseCode: facility.siteUseCode,
            typeCode: facility.typeCode,
          };
        }
        updatingFacilities.push(selectedFacility);
      });
      checkPermissionDetails(updatingFacilities);
    }
  };

  const closeAddFacilityPopUp = () => {
    setOpenAddFacilityPopUp(false);
  };

  const closeEditFacilityPopUp = () => {
    setOpenEditFacilityPopUp(false);
    setEditableFacilty(null);
  };

  const updateEditFacility = (facility: IUserFacilityData) => {
    setIsUpdateClicked(true);
    if (userProfileObj) {
      userProfileObj?.setUserProfileGotChanged(isAddingNewUser ? false : true);
      let latestFacilities = [...userProfileObj.profileDetails.facilities];
      const index = latestFacilities.findIndex(
        (item: IUserFacilityData) => item.siteUseId === facility.siteUseId
      );
      if (index > -1) {
        latestFacilities.splice(index, 1, facility);
        userProfileObj!.setProfileDetails({
          ...userProfileObj!.profileDetails,
          facilities: latestFacilities,
        });
        closeEditFacilityPopUp();
      }
    }
  };

  const handleChangeToFacilityList = (
    e: any,
    facility: IFacilityToUser,
    index: number
  ) => {
    let oldStatus = facility.activityStauts;
    facility.isSelected = e.target.checked;
    if (facility.isSelected) {
      facility.activityStauts =
        oldStatus && facility.isOriginalSelected ? 1 : 2;
    } else {
      facility.activityStauts = 3;
    }
    let tempData = [...facilities];
    tempData.splice(index, 1, facility);
    setFacilities(tempData);
    if (!showSelectAllBtn) setShowSelectAllBtn(true);
    updateAddBtnEnable();
  };

  const updateAddBtnEnable = () => {
    const isEnabled = facilities.some(
      (facility: IFacilityToUser) => facility.isSelected
    );
    setIsAddBtnEnabled(isEnabled);
  };

  const checkPermissionDetails = async (
    latestFacilities: IUserFacilityData[],
    isUnlinkedFacilityFlow: boolean = false
  ) => {
    let apiCollections: any[] = [];
    if (latestFacilities.length > 0) {
      const permissionRequiredFacilities = latestFacilities.filter(
        (facility: IUserFacilityData) => !facility.permissions
      );
      let updateFacilities = latestFacilities;
      if (permissionRequiredFacilities.length > 0) {
        permissionRequiredFacilities.forEach((facility: IUserFacilityData) => {
          apiCollections.push(getRolePermissionsInfo(facility.siteUseId));
          apiCollections.push(checkThePostAcute(facility.typeCode));
        });
        setOpenLoaderPopUp(true);
        let result = await Promise.all(apiCollections);
        setOpenLoaderPopUp(false);
        if (result.length === permissionRequiredFacilities.length * 2) {
          for (let index = 0; index < result.length; index += 2) {
            const data = result[index];
            const filteredFacilities = latestFacilities.filter(
              (facility: IUserFacilityData) =>
                facility.siteUseId === data.siteUseId
            );
            if (filteredFacilities.length > 0) {
              let filteredFacility = filteredFacilities[0];
              let updatedPermissions: IUserPermissionsData[] = [];
              let enabledPermissionsCount = 0;
              let availablePermissionsCount = 0;
              const isPostAcuteFacility = result[index + 1];
              data.permissions.forEach((permission: any) => {
                const isShowPermission = updatePermissionBasedOnAcuteFacility(
                  isPostAcuteFacility,
                  permission.permissionName
                );
                if (isShowPermission) {
                  if (permission.adminRegistered) {
                    enabledPermissionsCount += 1;
                  }
                  availablePermissionsCount += 1;
                }
                updatedPermissions.push({
                  category: permission.category,
                  isHide: !isShowPermission,
                  name: permission.permissionName,
                  status: permission.adminRegistered ?? false,
                });
              });
              filteredFacility.permissions = updatedPermissions;
              filteredFacility.enabledPermissionsCount =
                enabledPermissionsCount;
              filteredFacility.availablePermissionsCount =
                availablePermissionsCount;
            }
          }
        }
        if (isUnlinkedFacilityFlow) {
          updateFacilities = [
            ...userProfileObj!.profileDetails.facilities,
            ...latestFacilities,
          ];
        }
        userProfileObj!.setProfileDetails({
          ...userProfileObj!.profileDetails,
          facilities: updateFacilities,
        });
      } else {
        if (isUnlinkedFacilityFlow) {
          updateFacilities = [
            ...userProfileObj!.profileDetails.facilities,
            ...latestFacilities,
          ];
        }
        userProfileObj!.setProfileDetails({
          ...userProfileObj!.profileDetails,
          facilities: updateFacilities,
        });
      }
    }
  };

  const closeBtnActionForFailurePopUp = () => {
    setResetPasswordFailurePopUp(false);
    if (isNavigationBackRequired) {
      if (checkUserProfileDeeplink()) {
        authObj?.setDeepLinkPath(null);
        sessionStorage.removeItem("DeepLinkPath");
        history.push("/home");
      } else {
        history.goBack();
      }
    }
    setIsNavigationBackRequired(false);
  };

  const cancelAndExitBtnAction = () => {
    userProfileObj?.resetUserProfile();
    if (isDeepLink) {
      history.push("/administration/manageUsers");
    } else {
      history.goBack();
    }
  };

  const cancelBtnAction = async () => {
    if (userProfileObj?.userProfileGotChanged) {
      userProfileObj?.setUpdateUserProfilePromptFlag(true);
    } else {
      if (isDeepLink) {
        history.push("/administration/manageUsers");
      } else {
        history.goBack();
      }
    }
    if (
      authObj?.userProfile?.emailAddress ===
        userProfileObj?.profileDetails.email.value &&
      isUpdateClicked
    ) {
      const res = await getUser(authObj?.userProfile?.userName, SVC_GET_USER);
      authObj?.setuserRolePermissionData(res.data.userPermissions);
      await searchFacilityAddr(res.data);
      history.push("/home");
    }
  };

  const searchFacilityAddr = async (data: any) => {
    let tempSideNavMenuOption = getDeepClone(
      authObj?.sideNavMenuOptionModelData
    );
    let readyCareFlag = "N";
    if (data?.facilities.length > 0) {
      const linkedFacilities = data.facilities.filter(
        (x: any) => x.mode === FacilityMode.LINKED
      );
      if (linkedFacilities.length > 0) {
        const linkedFacility = linkedFacilities[0];
        var facilitySearchRequest = {
          customerNumber: linkedFacility.number,
          customerName: "",
          postalCode: "",
        };
        const searchedFacilities = await searchFacility(facilitySearchRequest);
        if (searchedFacilities) {
          const facilityRes = searchedFacilities.filter((x) => {
            if (
              linkedFacility.siteUseId &&
              x.siteUseId === linkedFacility.siteUseId.toString()
            ) {
              return x;
            }
          })[0];
          let registeredFaciltyAddress = {
            ...facilityRes,
            facilityAddressID: linkedFacilities[0].facilityAddressID,
            careGiverId: linkedFacilities[0].careGiverId,
          };
          readyCareFlag = registeredFaciltyAddress.readyCareFlag!;
          authObj?.setregisteredFaciltyAddress(registeredFaciltyAddress);
          const rolesPermissionRes = await mapUserRolesAndPermissionData(
            data.userPermissions,
            readyCareFlag
          );
          permissionObj?.setMappedRolesPermissionData(rolesPermissionRes);
          tempSideNavMenuOption = {
            ...authObj?.sideNavMenuOptionModelData,
            orders: {
              labelText: "Start New Order",
              isVisible: showVacOrderMenuOption(rolesPermissionRes),
            },
            inventory: {
              labelText: "Inventory",
              isVisible: rolesPermissionRes.IsShowInventoryOption,
            },
            administration: {
              labelText: "Administration",
              isVisible: rolesPermissionRes.IsShowAdminstrationOption,
            },
          };
          authObj?.setuserRolesAndPermissionLoaded(true);
        }
      } else {
        updateSideNavMenuoptions(data);
      }
    } else {
      updateSideNavMenuoptions(data);
    }
    authObj?.setsideNavMenuOptionModelData(tempSideNavMenuOption);
    authObj?.setUpdateFacilityBannerFacility(true);
  };

  const updateSideNavMenuoptions = async (data: any) => {
    let tempSideNavMenuOption = getDeepClone(
      authObj?.sideNavMenuOptionModelData
    );
    const rolesPermissionRes = await mapUserRolesAndPermissionData(
      data.userPermissions,
      "N"
    );
    permissionObj?.setMappedRolesPermissionData(rolesPermissionRes);
    tempSideNavMenuOption = {
      ...authObj?.sideNavMenuOptionModelData,
      orders: {
        labelText: "Start New Order",
        isVisible: showVacOrderMenuOption(rolesPermissionRes),
      },
      inventory: {
        labelText: "Inventory",
        isVisible: rolesPermissionRes.IsShowInventoryOption,
      },
      administration: {
        labelText: "Administration",
        isVisible: rolesPermissionRes.IsShowAdminstrationOption,
      },
    };
    authObj?.setuserRolesAndPermissionLoaded(true);
    authObj?.setsideNavMenuOptionModelData(tempSideNavMenuOption);
  };

  const updateUserProfileBtnAction = async () => {
    const validator = new UserProfileValidator();
    const data = userProfileObj!.profileDetails;
    const setData = userProfileObj!.setProfileDetails;
    const isAllValid = await validator.validateAll(data, setData);
    if (isAllValid === ValidationStatus.VALID) {
      if (isAddingNewUser) {
        history.goBack();
      } else {
        updateUserDetailsAPI();
      }
    }
  };

  const addUserProfileBtnAction = async () => {
    const validator = new UserProfileValidator();
    const data = userProfileObj!.profileDetails;
    const setData = userProfileObj!.setProfileDetails;
    const isAllValid = await validator.validateAll(data, setData);
    if (isAllValid === ValidationStatus.VALID) {
      addUserDetailsAPI();
    }
  };

  const mapFacilityInfo = (facilities: IUserFacilityData[]) => {
    let mappedFacility: any[] = [];
    facilities.map((facility: IUserFacilityData) => {
      const faclityDetails = {
        activityStatus: facility.activityStauts ?? 1,
        facilityAddressID: facility.facilityAddressID
          ? facility.facilityAddressID.toString()
          : null,
        siteUseId: facility.siteUseId,
        userRole:
          facility.userRole === "Admin" ? "Facility Admin" : facility.userRole,
        status: facility.status.toLowerCase() === "active" ? true : false,
        permissions: mapFacilityPermissions(facility.permissions),
        siteUseCode: facility.siteUseCode,
        accountNumber: facility.accountNumber,
      };
      mappedFacility.push(faclityDetails);
    });
    return mappedFacility;
  };

  const mapAddUserFacilityInfo = (facilities: IUserFacilityData[]) => {
    let mappedFacility: any[] = [];
    facilities.map((facility: IUserFacilityData) => {
      const faclityDetails = {
        siteUseId: facility.siteUseId,
        userRole:
          facility.userRole === "Admin" ? "Facility Admin" : facility.userRole,
        status: facility.status.toLowerCase() === "active" ? true : false,
        permissions: mapFacilityPermissions(facility.permissions),
        siteUseCode: facility.siteUseCode,
        accountNumber: facility.accountNumber,
      };
      mappedFacility.push(faclityDetails);
    });
    return mappedFacility;
  };

  const mapFacilityPermissions = (
    permissions: IUserPermissionsData[] | null
  ): IUserPermissionsData[] | null => {
    return permissions
      ? permissions.map((permission) => {
          permission.status = permission.isHide ? false : permission.status;
          permission.isHide = undefined;
          return permission;
        })
      : null;
  };

  const updatePermissionCount = (
    data: IUserProfile,
    facilitiesFromAPI: IUserFacilityData[]
  ) => {
    const latestFacilities: IUserFacilityData[] = [];
    Array.isArray(facilitiesFromAPI) &&
      facilitiesFromAPI.forEach((facility: IUserFacilityData) => {
        const permissions: IUserPermissionsData[] | null = facility.permissions;
        if (permissions && permissions.length > 0) {
          let enabledPermissionsCount = 0;
          let availablePermissionsCount = 0;
          permissions.forEach((permission: IUserPermissionsData) => {
            if (!permission.isHide) {
              if (permission.status) {
                enabledPermissionsCount += 1;
              }
              availablePermissionsCount += 1;
            }
          });
          facility.enabledPermissionsCount = enabledPermissionsCount;
          facility.availablePermissionsCount = availablePermissionsCount;
        }
        latestFacilities.push(facility);
      });
    userProfileObj!.setProfileDetails({
      ...data,
      facilities: latestFacilities,
    });
  };

  const updateBasicDetails = async () => {
    if (isAddingNewUser) {
      fetchDropdownContent();
      if (
        userProfileObj &&
        userProfileObj.profileDetails.facilities.length === 0
      ) {
        userProfileObj!.setProfileDetails({
          ...userProfileObj!.profileDetails,
          facilities: regFacilities,
        });
      }
    } else {
      if (userProfileObj!.profileDetails.firstName.value === "") {
        if (!selectedUserName || selectedUserName === "") {
          history.goBack();
        } else {
          if (checkUserProfileDeeplink()) {
            const urlData = authObj?.deepLinkPath!.split("/");
            const onbj: any = JSON.parse(atob(urlData![5]));
            setSiteUseId(onbj.SiteUseId);
          } else {
            if (internalObj?.isInternalAdministration) {
              setSiteUseId("");
            } else {
              setSiteUseId(authObj!.registeredFaciltyAddress!.siteUseId!);
            }
          }
          getUserDetailsFromServer();
        }
      }
    }
  };

  const getNavigatorLinks = () => {
    let array = [
      {
        route: "/administration",
        pageName: "Administration",
      },
    ];
    switch (isComingFrom) {
      case UserProfileComingFrom.MANAGE_USER:
        array.push({
          route: "/administration/manageUsers",
          pageName: "Manage Users",
        });
        break;
      case UserProfileComingFrom.MANAGE_USER_ACCOUNTS:
        array.push({
          route: "/administration/manageUserAccounts",
          pageName: "Manage User Accounts",
        });
        break;
    }
    return array;
  };

  const linkToFacilityBtnAction = (
    selectedFacility: IUserUnlinkedFacilityData
  ) => {
    const tempUnlinkedFacility: IUnlinkedUser = {
      dateOfRegistration: selectedFacility.requestedDate,
      email: userProfileObj?.profileDetails?.email.value ?? "",
      facilityAddress1: selectedFacility.address1,
      facilityAddress2: selectedFacility.address2,
      facilityAddressId: selectedFacility.facilityAddressID,
      facilityCity: selectedFacility.city,
      facilityName: selectedFacility.facilityName,
      facilityState: selectedFacility.state,
      facilityZipCode: selectedFacility.zipCode,
      firstName: userProfileObj?.profileDetails?.firstName.value ?? "",
      lastName: userProfileObj?.profileDetails?.lastName.value ?? "",
    };
    setSelectedUnlinkedFacility(tempUnlinkedFacility);
    setOpenFacilitySearch(true);
  };

  const closeLinkToFacilitySuccessPopUp = () => {
    setLinkToFacilitySuccessPopUp(false);
    // Update unlinked user count
    updateUnlinkedUserCount();
    // On success update newly added facility to user site access
    // and remove unlinked from list.
    updateNewFacilityToSiteAccess(selectedUnlinkedToLinkedFacility!);
  };

  const updateUnlinkedUserCount = () => {
    if (authObj) {
      const updateCount = authObj.unLinkedFacilitesCount
        ? parseInt(authObj.unLinkedFacilitesCount) - 1
        : 0;
      const adminText: any = (
        <div className="sideNavmadminBtnMain">
          Administration
          {updateCount !== 0 && (
            <span className="sideNavmadminBtnStyle">{` ${updateCount} `}</span>
          )}
        </div>
      );
      const updateSideNavMenuOption: ISideNav = {
        ...authObj.sideNavMenuOptionModelData!,
        administration: {
          labelText: adminText,
          isVisible:
            authObj?.sideNavMenuOptionModelData?.administration?.isVisible!,
        },
      };
      authObj.setUnLinkedFacilityCount(updateCount.toString());
      authObj.setsideNavMenuOptionModelData(updateSideNavMenuOption);
    }
  };

  const updateNewFacilityToSiteAccess = (facility: IFacilityToUser) => {
    const newLinkedFacility: IUserFacilityData = {
      accountName: facility.facilityName,
      accountNumber: facility.number ?? 0,
      address1: facility.address1,
      address2: facility.address2 ?? "",
      activityStauts: facility.activityStauts ?? 2,
      city: facility.city,
      facilityAddressID: facility.facilityAddressID
        ? parseInt(facility.facilityAddressID)
        : 0,
      state: facility.state,
      zip: facility.zipCode.toString(),
      userRole: "Clinician",
      status: "Active",
      siteUseId: facility.siteUseId,
      permissions: null,
      enabledPermissionsCount: 0,
      availablePermissionsCount: 0,
      siteUseCode: facility.siteUseCode,
      typeCode: facility.typeCode,
    };
    // Remove unlinked facility from list
    const originalUnlinkedFacilities: IUserUnlinkedFacilityData[] =
      userProfileObj!.profileDetails.unlinkedFacilities ?? [];
    const index = originalUnlinkedFacilities.findIndex(
      (unlinkedFacility: IUserUnlinkedFacilityData) =>
        unlinkedFacility.facilityAddressID ===
        selectedUnlinkedFacility?.facilityAddressId
    );
    if (index > -1) {
      originalUnlinkedFacilities.splice(index, 1);
      userProfileObj!.setProfileDetails({
        ...userProfileObj!.profileDetails,
        unlinkedFacilities: originalUnlinkedFacilities,
      });
    }
    const originalFacilities: IUserFacilityData[] =
      userProfileObj!.profileDetails.facilities;
    // Check selected Facility is already exist in user list.
    const selectedFacilities: IUserFacilityData[] = originalFacilities.filter(
      (existingFacility: IUserFacilityData) =>
        existingFacility.siteUseId === facility.siteUseId
    );
    const isSelectedFacilityAlreadyExist = selectedFacilities.length !== 0;
    if (isSelectedFacilityAlreadyExist) {
      let selectedFacility = selectedFacilities[0];
      if (selectedFacility.status.toLowerCase() === "inactive") {
        selectedFacility.status = "Active";
        const index = originalFacilities.findIndex(
          (facility: IUserFacilityData) =>
            facility.siteUseId === selectedFacility.siteUseId
        );
        if (index > -1) {
          originalFacilities.splice(index, 1, selectedFacility);
          userProfileObj!.setProfileDetails({
            ...userProfileObj!.profileDetails,
            facilities: originalFacilities,
          });
        }
      }
    } else {
      // If selected facility is not already exist in user list then fetch permission details.
      // Check permission count and details to newly added facility.
      checkPermissionDetails([newLinkedFacility], true);
    }
  };

  useEffect(() => {
    setOpenLoaderPopUp(true);
    setSelectedUserNameState(selectedUserName);
    if (
      authObj &&
      authObj?.allFacilities &&
      authObj.registeredFaciltyAddress &&
      authObj.registeredFaciltyAddress.siteUseId &&
      authObj.registeredFaciltyAddress.siteUseId !== ""
    ) {
      if (checkUserProfileDeeplink()) {
        setIsDeepLink(true);
        setOpenLoaderPopUp(false);
        const urlData = authObj?.deepLinkPath!.split("/");
        const onbj: any = JSON.parse(atob(urlData![5]));
        setSiteUseId(onbj.SiteUseId);
        selectedUserName = onbj.SelectedUserName;
        setSelectedUserNameState(onbj.SelectedUserName);
        checkValidFacility(authObj?.allFacilities, onbj.SiteUseId);
        updateBasicDetails();
      } else {
        setOpenLoaderPopUp(false);
        if (
          getProperty(authObj.sideNavMenuOptionModelData, "administration")
            .isVisible
        ) {
          updateBasicDetails();
        } else {
          history.replace("/home");
        }
      }
    } else if (internalObj?.isInternalAdministration) {
      setOpenLoaderPopUp(false);
      if (
        getProperty(
          internalObj?.ssoSideNavMenuOptionModelData,
          "internalAdministration"
        ).isVisible
      ) {
        updateBasicDetails();
      } else {
        history.replace("/home");
      }
    }
  }, [authObj?.registeredFaciltyAddress, authObj?.allFacilities]);

  return (
    <div
      className="user-profile-component"
      data-testid="user-profile-component"
    >
      <div className="user-profile-container">
        <Navigator
          array={getNavigatorLinks()}
          className="user-profile-route-section"
          title={isAddingNewUser === true ? "Add new user" : "User profile"}
        />
        <div
          className="user-profile-hearder"
          data-testid="user-profile-hearder"
        >
          {isAddingNewUser ? (
            <span>Add new user</span>
          ) : (
            <span>
              User profile for
              {makeCapitalEachWordInString(
                ` ${userProfileObj?.profileDetails.firstName.value} ${userProfileObj?.profileDetails.lastName.value}`
              )}
            </span>
          )}
        </div>
        <UserAccountInformation
          data={userProfileObj?.profileDetails!}
          setData={userProfileObj?.setProfileDetails!}
          licenseType={licenseType}
          department={department}
          isAddingNewUser={isAddingNewUser}
        />
        {!isAddingNewUser && (
          <Button
            className={`reset-password ${
              userProfileObj &&
              userProfileObj?.profileDetails &&
              userProfileObj.profileDetails.unlinkedFacilities &&
              userProfileObj.profileDetails.unlinkedFacilities.length > 0
                ? "update-margin"
                : ""
            }`}
            data-testid="reset-password"
            disabled={permissionObj?.mappedRolesPermissionData.IsSupportRole}
            onClick={resetPasswordBtnAction}
            variant="outlined"
          >
            Reset password
          </Button>
        )}
        {!isAddingNewUser &&
          userProfileObj &&
          userProfileObj.profileDetails &&
          userProfileObj.profileDetails.unlinkedFacilities &&
          userProfileObj.profileDetails.unlinkedFacilities.length > 0 && (
            <RequestedSites
              linkToFacilityBtnAction={linkToFacilityBtnAction}
              unlinkedFacilities={
                userProfileObj.profileDetails.unlinkedFacilities
              }
            />
          )}
        <SiteAccess
          addFacilityBtnAction={addFacilityBtnAction}
          editBtnAction={editBtnClicked}
          facilities={userProfileObj?.profileDetails.facilities!}
        />
        <UserProfileFooterButtonGroup
          firstButtonTitle="Cancel"
          firstButtonAction={cancelBtnAction}
          firstButtonDisabled={false}
          secondButtonTitle={isAddingNewUser ? "Add User" : "Update User"}
          secondButtonAction={
            isAddingNewUser
              ? addUserProfileBtnAction
              : updateUserProfileBtnAction
          }
          secondButtonDisabled={
            permissionObj?.mappedRolesPermissionData.IsSupportRole
          }
        />
        <AddFacilityContext.Provider
          value={{
            closePopup: () => setOpen(false),
            facilitySearchValidator: new Validator(),
            addFacilityToList: addFacilitiesFromSearch,
          }}
        >
          <Popup
            dialogParentClass="add-facility-popup"
            openFlag={open}
            closeHandler={() => setOpen(false)}
          >
            <AddFacilityContainer isForAdminFlow={true} isForNewOrder={false} />
          </Popup>
          {selectedUnlinkedFacility && (
            <Popup
              dialogParentClass="add-facility-popup"
              openFlag={openFacilitySearch}
              closeHandler={() => setOpenFacilitySearch(false)}
            >
              <AddFacilityContainer
                requestedFacility={selectedUnlinkedFacility}
                isUnlinkedUserFlow={true}
              />
            </Popup>
          )}
        </AddFacilityContext.Provider>
        <Popup
          openFlag={openLoaderPopUp}
          closeHandler={() => setOpenLoaderPopUp(false)}
          dialogParentClass={"add-facility-loader-pop-up"}
          data-testid="loader-pop-up"
          hideCloseButton={true}
        >
          <div className="add-facility-loader">
            <LoadingSpinner />
          </div>
        </Popup>
        <Popup
          openFlag={openAddFacilityPopUp}
          closeHandler={closeAddFacilityPopUp}
          dialogParentClass={"add-facility-pop-up"}
          data-testid="add-facility-pop-up"
        >
          <div className="add-facility">
            <AddFacilityToUser
              addBtnAction={addNewFacilities}
              cancelBtnAction={closeAddFacilityPopUp}
              deselectAllBtnAction={() => {
                selectOrDeselectAllBtnAction(false);
              }}
              facilities={facilities}
              handleChange={handleChangeToFacilityList}
              isAddBtnEnabled={isAddBtnEnabled}
              showSelectAllBtn={showSelectAllBtn}
              selectAllBtnAction={() => {
                selectOrDeselectAllBtnAction(true);
              }}
            />
          </div>
        </Popup>
        <Popup
          openFlag={openEditFacilityPopUp}
          closeHandler={closeEditFacilityPopUp}
          dialogParentClass={"edit-facility-pop-up"}
          data-testid="edit-facility-pop-up"
        >
          <div className="edit-facility">
            {editableFacility && (
              <EditFacility
                cancelBtnAction={closeEditFacilityPopUp}
                facility={editableFacility!}
                updateBtnAction={updateEditFacility}
              />
            )}
          </div>
        </Popup>
        <Popup
          openFlag={resetPasswordSuccessPopUp}
          closeHandler={() => setResetPasswordSuccessPopUp(false)}
          dialogParentClass={"reset-password-success-pop-up"}
          data-testid="reset-password-success-pop-up"
        >
          <div className="reset-password-success">
            <p className="title">Password Reset</p>
            <p className="description">
              Password reset has been sent to
              <span className="email">
                {userProfileObj!.profileDetails.email.value}
              </span>
              .
            </p>
            <Button
              className="close-btn"
              onClick={closeResetPasswordSuccessPopUp}
              variant="contained"
            >
              Close
            </Button>
          </div>
        </Popup>
        <ErrorPopup
          popUpStyles="error-popup-design"
          errorMessage={message}
          errorPopupFlag={resetPasswordFailurePopUp}
          handleBackButton={() => {
            closeBtnActionForFailurePopUp();
          }}
          buttontitle="Done"
          errorCode={errorCode}
          showTitle={false}
          isSupportPhoneRequired={false}
        />
        <Popup
          openFlag={updateDetailsSuccessPopUp}
          closeHandler={cancelBtnAction}
          dialogParentClass={"update-user-success-pop-up"}
          data-testid="update-user-success-pop-up"
        >
          <div className="update-user-success">
            {isAddingNewUser === true ? (
              <>
                <p className="description">
                  You have successfully added the user profile details.
                </p>
                <Button
                  className="ok-btn"
                  onClick={cancelBtnAction}
                  variant="contained"
                >
                  Done
                </Button>
              </>
            ) : (
              <>
                <p className="description">
                  You have successfully updated the user profile details. The
                  updates will become effective after the user logs out and logs
                  back again into 3M™ Express Therapy Portal
                </p>
                <Button
                  className="ok-btn"
                  onClick={cancelBtnAction}
                  variant="contained"
                >
                  Done
                </Button>
              </>
            )}
          </div>
        </Popup>
        {userProfileObj?.updateUserProfilePromptFlag && (
          <UserProfileUpdateConfirmationPopup
            closeModal={() => {
              userProfileObj?.setUpdateUserProfilePromptFlag(false);
            }}
            handleExitButton={cancelAndExitBtnAction}
          />
        )}
        <Popup
          dialogParentClass="unlinked-requested-add-facility-popup"
          openFlag={linkToFacilitySuccessPopUp}
          closeHandler={closeLinkToFacilitySuccessPopUp}
        >
          {selectedUnlinkedFacility && selectedUnlinkedToLinkedFacility && (
            <UnlinkedUserLinkedSuccess
              buttonAction={closeLinkToFacilitySuccessPopUp}
              buttonTitle={"Close"}
              description={
                "The user <b>" +
                makeCapitalEachWordInString(
                  selectedUnlinkedFacility.firstName
                ) +
                " " +
                makeCapitalEachWordInString(selectedUnlinkedFacility.lastName) +
                "</b> has been associated with the following facility."
              }
              title={"Success!"}
              requestedFacility={selectedUnlinkedToLinkedFacility!}
            />
          )}
        </Popup>
        <Popup
          openFlag={linkToFacilityFailurePopUp}
          closeHandler={() => setLinkToFacilityFailurePopUp(false)}
          dialogParentClass={"link-to-facility-failure-pop-up"}
          data-testid="link-to-facility-failure-pop-up"
        >
          <div className="link-to-facility-failure-pop-up-div">
            <h4
              className="failure-pop-up-message"
              data-testid="failure-pop-up-message"
            >
              Your request to update the User/Facility link has failed. Please
              try again or contact 3M™ Express Therapy Portal support team at
              <span className="phone-number"> (800) 275-4524 </span> ext. 41858.
            </h4>
            <ExpressButton
              clickHandler={() => {
                setLinkToFacilityFailurePopUp(false);
              }}
              parentClass="failure-pop-up-back-button"
              testId="back-button"
              variant="contained"
            >
              Done
            </ExpressButton>
          </div>
        </Popup>
      </div>
      <UserProfileUpdateConfirmation
        when={userProfileObj?.userProfileGotChanged!}
        navigate={(path) => history.push(path)}
        shouldBlockNavigation={(location) => {
          if (userProfileObj?.userProfileGotChanged) {
            if (
              location.pathname !== "/administration/manageUsers/userProfile"
            ) {
              return true;
            }
          }
          return false;
        }}
      />
    </div>
  );
};
