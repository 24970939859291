import { useContext, useEffect, useState } from "react";
import { Grid, InputBase, TextField, useMediaQuery } from "@mui/material";
import { InpatientOrderValidator } from "../../inpatientOrder.validator";
import { ValidationStatus } from "../../../../core/interfaces/input.interface";
import { InputWithLabel } from "../../../../core/inputWithLabel/inputWithLabel.component";
import { IInpatientOrderSecondPage } from "../../inpatientOrder.interface";
import { EstimatedArrivalTimeMain } from "../../../inventory/requestService/createServiceRequest/estimatedArrivalTime/estimatedArrivalTimeMain.component";
import { AuthContext, AuthContextType } from "../../../../context/AuthContext";
import "./deliveryPreference.css";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import {
  convertStringToDate,
  deliveryPrefernceRoundToNearestMinutes,
  getCodeFromText,
  getTextFromCode,
} from "../../../../util/utilityFunctions";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { ReactComponent as CalendarIcon } from "../../../../assets/calendar.svg";
import moment from "moment";
import { getCurrentServerDateTime } from "../../../../util/3meService";
import { CustomDropDown } from "../../../../core/customDropdown/customDropdown.component";
import { CreateServiceRequestValidator } from "../../../inventory/requestService/createServiceRequest.validator";
import {
  InpatientOrderContext,
  InpatientOrderContextType,
} from "../../../../context/InpatientOrderContext";

interface IDeliveryPreference {
  data: IInpatientOrderSecondPage;
  setData: Function;
}

export const DeliveryPreference = ({ data, setData }: IDeliveryPreference) => {
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const isSmallerMobileScreen = useMediaQuery("(max-width:430px)");
  const [focusClasses, setFocusClasses] = useState({
    deliveryPreferenceNeedByDate: "",
  });
  const [maxDate, setMaxDate] = useState<string>("");
  const inpatientOrderObj = useContext<InpatientOrderContextType | null>(
    InpatientOrderContext
  );

  const updateBasicContactDetails = (
    tempData: IInpatientOrderSecondPage
  ): IInpatientOrderSecondPage => {
    if (authObj && authObj.userProfile) {
      const profile = authObj.userProfile;
      const validator = new InpatientOrderValidator();
      const firstNameValid = validator.validate(
        profile.firstName,
        "firstName"
      )!.status;
      const lastNameValid = validator.validate(
        profile.lastName,
        "lastName"
      )!.status;
      const phoneValue =
        profile.phoneNumber && profile.phoneNumber !== ""
          ? profile.phoneNumber.slice(-10)
          : profile.mobilePhoneNumber.slice(-10);
      const phoneValid = validator.validate(phoneValue, "phone")!.status;
      tempData = {
        ...tempData,
        deliveryPreference: {
          ...data.deliveryPreference,
          value: {
            ...data.deliveryPreference.value,
            firstName: {
              ...Object(data.deliveryPreference.value).firstName,
              required: true,
              valid: firstNameValid,
              value: profile.firstName,
            },
            lastName: {
              ...Object(data.deliveryPreference.value).lastName,
              required: true,
              valid: lastNameValid,
              value: profile.lastName,
            },
            phoneNumber: {
              ...Object(data.deliveryPreference.value).phoneNumber,
              required: true,
              valid: phoneValid,
              value: phoneValue,
            },
          },
        },
      };
    }
    return tempData;
  };

  const resetContactDetails = (
    tempData: IInpatientOrderSecondPage
  ): IInpatientOrderSecondPage => {
    tempData = {
      ...tempData,
      deliveryPreference: {
        ...data.deliveryPreference,
        value: {
          ...data.deliveryPreference.value,
          firstName: {
            ...Object(data.deliveryPreference.value).firstName,
            required: false,
            valid: ValidationStatus.UNTOUCHED,
            value: "",
          },
          lastName: {
            ...Object(data.deliveryPreference.value).lastName,
            required: false,
            valid: ValidationStatus.UNTOUCHED,
            value: "",
          },
          phoneNumber: {
            ...Object(data.deliveryPreference.value).phoneNumber,
            required: false,
            valid: ValidationStatus.UNTOUCHED,
            value: "",
          },
        },
      },
    };
    return tempData;
  };

  const setDatetime = async () => {
    if (inpatientOrderObj?.currentServerDate) {
      const dateTime = moment(
        inpatientOrderObj?.currentServerDate.currentCstTime
      )
        .add(30, "hours")
        .toDate();
      const formattedDate = moment(dateTime).format("MM/DD/YYYY");
      const formatMaxDate: any = moment(
        inpatientOrderObj?.currentServerDate.currentCstTime
      ).add(7, "days");
      setMaxDate(formatMaxDate);
      var time = moment(
        deliveryPrefernceRoundToNearestMinutes(dateTime)
      ).format("h:mm A");
      let tempData: IInpatientOrderSecondPage = data;
      if (
        data.deliveryPreference.value.needByDate.value === "" &&
        data.deliveryPreference.value.needByTime.value === ""
      ) {
        if (
          (dateTime.getHours() === 21 && dateTime.getMinutes() === 0) ||
          (dateTime.getHours() === 6 && dateTime.getMinutes() === 0)
        ) {
          tempData = {
            ...tempData,
            deliveryPreference: {
              ...tempData.deliveryPreference,
              valid: ValidationStatus.VALID,
              value: {
                ...tempData.deliveryPreference.value,
                needByDate: {
                  ...tempData.deliveryPreference.value.needByDate,
                  errorMessage: null,
                  valid: ValidationStatus.VALID,
                  value: formattedDate,
                },
                needByTime: {
                  ...tempData.deliveryPreference.value.needByTime,
                  errorMessage: null,
                  valid: ValidationStatus.VALID,
                  value: getCodeFromText(inpatientOrderObj?.needTimes, time),
                },
              },
            },
          };
        } else if (dateTime.getHours() >= 21 || dateTime.getHours() < 6) {
          if (dateTime.getHours() <= 5) {
            tempData = {
              ...tempData,
              deliveryPreference: {
                ...tempData.deliveryPreference,
                valid: ValidationStatus.VALID,
                value: {
                  ...tempData.deliveryPreference.value,
                  needByDate: {
                    ...tempData.deliveryPreference.value.needByDate,
                    errorMessage: null,
                    valid: ValidationStatus.VALID,
                    value: formattedDate,
                  },
                  needByTime: {
                    ...tempData.deliveryPreference.value.needByTime,
                    errorMessage: null,
                    valid: ValidationStatus.VALID,
                    value: getCodeFromText(
                      inpatientOrderObj?.needTimes,
                      inpatientOrderObj?.needTimesText[0]
                    ),
                  },
                },
              },
            };
          } else {
            //assign to next day first slot
            const addedExtraDay = moment(dateTime)
              .add(1, "days")
              .format("MM/DD/YYYY");
            tempData = {
              ...tempData,
              deliveryPreference: {
                ...tempData.deliveryPreference,
                valid: ValidationStatus.VALID,
                value: {
                  ...tempData.deliveryPreference.value,
                  needByDate: {
                    ...tempData.deliveryPreference.value.needByDate,
                    errorMessage: null,
                    valid: ValidationStatus.VALID,
                    value: addedExtraDay,
                  },
                  needByTime: {
                    ...tempData.deliveryPreference.value.needByTime,
                    errorMessage: null,
                    valid: ValidationStatus.VALID,
                    value: getCodeFromText(
                      inpatientOrderObj?.needTimes,
                      inpatientOrderObj?.needTimesText[0]
                    ),
                  },
                },
              },
            };
          }
        } else {
          tempData = {
            ...tempData,
            deliveryPreference: {
              ...tempData.deliveryPreference,
              valid: ValidationStatus.VALID,
              value: {
                ...tempData.deliveryPreference.value,
                needByDate: {
                  ...tempData.deliveryPreference.value.needByDate,
                  errorMessage: null,
                  valid: ValidationStatus.VALID,
                  value: formattedDate,
                },
                needByTime: {
                  ...tempData.deliveryPreference.value.needByTime,
                  errorMessage: null,
                  valid: ValidationStatus.VALID,
                  value: getCodeFromText(inpatientOrderObj?.needTimes, time),
                },
              },
            },
          };
        }
      }
      setData(tempData);
    }
  };

  const validateAndSetData = (e: any) => {
    const { name, value } = e.target;
    const validator = new CreateServiceRequestValidator();
    const isValid = validator.validate(value, name);
    let tempData = data;
    if (
      name === "isCallOnEstimatedArrivalTime" &&
      value !== data.deliveryPreference.value.isCallOnEstimatedArrivalTime.value
    ) {
      if (value === "yes") {
        tempData = updateBasicContactDetails(tempData);
      } else {
        tempData = resetContactDetails(tempData);
      }
    }
    const isEmpty =
      name === "phoneNumber" && isValid?.updatedVal?.trim() === "";
    tempData = {
      ...tempData,
      deliveryPreference: {
        ...tempData.deliveryPreference,
        value: {
          ...tempData.deliveryPreference.value,
          [name]: {
            ...tempData.deliveryPreference.value[name],
            errorMessage: isEmpty
              ? tempData.deliveryPreference.value[name]?.errorMessage
              : null,
            valid: isValid?.status,
            value: value,
          },
        },
      },
    };
    setData(tempData);
  };

  const setClasses = (e: any, classname: string) => {
    setFocusClasses(
      Object.assign({}, focusClasses, { [e.target.name]: classname })
    );
  };

  const removeAllBefore = (array: any, time: string) => {
    const index = inpatientOrderObj?.needTimesText.findIndex(
      (x: string) => x === time
    );
    if (index !== -1) {
      return array.splice(index, array.length - 1);
    }
    return array;
  };

  const validateAndSetDate = async (date: string | null | undefined) => {
    const formattedDate = date === null ? null : convertStringToDate(date);
    const validator = new InpatientOrderValidator();
    const isValid = validator.validate(formattedDate!, "needByDate");
    const isDateAfterMaxDate = moment(date).isAfter(maxDate);
    let tempData = {
      ...data,
      deliveryPreference: {
        ...data.deliveryPreference,
        valid:
          isValid?.status === ValidationStatus.INVALID
            ? isValid.status
            : data.deliveryPreference.valid,
        value: {
          ...data.deliveryPreference.value,
          needByDate: {
            ...data.deliveryPreference.value.needByDate,
            errorMessage: null,
            required: true,
            valid: isDateAfterMaxDate
              ? ValidationStatus.INVALID
              : isValid?.status,
            value: formattedDate,
          },
          needByTime: {
            ...data.deliveryPreference.value.needByTime,
            errorMessage: null,
            required: true,
            valid: ValidationStatus.UNTOUCHED,
            value: "",
          },
        },
      },
    };
    const currentDateRes = await getCurrentServerDateTime();
    if (currentDateRes) {
      if (
        formattedDate === convertStringToDate(currentDateRes.currentCstTime)
      ) {
        const dateTime = moment(currentDateRes.currentCstTime)
          .add(5, "hours")
          .toDate();
        const time = moment(
          deliveryPrefernceRoundToNearestMinutes(dateTime)
        ).format("h:mm A");
        const arr = removeAllBefore(inpatientOrderObj?.needTimesText!, time);
        inpatientOrderObj?.setNeedTimesText(arr);
      } else {
        const arr: any = inpatientOrderObj?.needTimes.map(
          (x: { text: string }) => x.text
        );
        inpatientOrderObj?.setNeedTimesText(arr);
      }
    }
    setData(tempData);
  };

  useEffect(() => {
    if (inpatientOrderObj?.needTimes.length > 0) {
      setDatetime();
    }
  }, [inpatientOrderObj?.needTimes, inpatientOrderObj?.currentServerDate]);

  return (
    <div
      className="in-patient-order-delivery-preference-component"
      data-testid="in-patient-order-delivery-preference-component"
    >
      <p
        className="in-patient-order-delivery-preference-title"
        data-testid="in-patient-order-delivery-preference-title"
        id="in-patient-order-delivery-preference-title"
      >
        Delivery Preferences
      </p>
      <Grid
        className="in-patient-order-delivery-preference-grid-container"
        container
        spacing={2}
      >
        <Grid
          className="delivery-preference-grid-item"
          item
          xs={isSmallerMobileScreen ? 12 : 6}
        >
          <InputWithLabel
            error={
              data.deliveryPreference.value.needByDate.valid ===
              ValidationStatus.INVALID
            }
            id={data.deliveryPreference.value.needByDate.componentId!}
            isRequired={data.deliveryPreference.value.needByDate.required}
            isShowWarning={true}
            label={data.deliveryPreference.value.needByDate.title!}
            labelClassName={focusClasses.deliveryPreferenceNeedByDate}
            testId="delivery-preference-need-by-date"
            warningMessage={
              data.deliveryPreference.value.needByDate.errorMessage
            }
          >
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                components={{ OpenPickerIcon: CalendarIcon }}
                InputAdornmentProps={{
                  classes: {
                    root: "adornedRoot",
                  },
                }}
                InputProps={{
                  classes: {
                    root: `deliveryPreferenceNeedByDate ${
                      data.deliveryPreference.value.needByDate.valid ===
                      ValidationStatus.INVALID
                        ? "showError"
                        : "noError"
                    }`,
                    input: "input",
                    notchedOutline: "outline",
                  },
                }}
                onChange={(value: any) => validateAndSetDate(value)}
                renderInput={(params: any) => {
                  params.error = false;
                  params.inputProps!.placeholder = "__/__/____";
                  return (
                    <TextField
                      id={data.deliveryPreference.value.needByDate.id!}
                      name="deliveryPreferenceNeedByDate"
                      onFocus={(e) => setClasses(e, "Mui-focused")}
                      onBlur={(e) => setClasses(e, "")}
                      {...params}
                    />
                  );
                }}
                value={data.deliveryPreference.value.needByDate.value}
                maxDate={maxDate}
              />
            </LocalizationProvider>
          </InputWithLabel>
        </Grid>
        <Grid
          className="delivery-preference-grid-item"
          item
          xs={isSmallerMobileScreen ? 12 : 6}
        >
          <InputWithLabel
            error={
              data.deliveryPreference.value.needByTime.valid ===
              ValidationStatus.INVALID
            }
            id={data.deliveryPreference.value.needByTime.componentId!}
            isRequired={data.deliveryPreference.value.needByTime.required}
            isShowWarning={true}
            label={data.deliveryPreference.value.needByTime.title!}
            testId="delivery-preference-need-by-time"
            warningMessage={
              data.deliveryPreference.value.needByTime.errorMessage
            }
            isDropdown={true}
          >
            <CustomDropDown
              handleChange={validateAndSetData}
              menuItem={inpatientOrderObj?.needTimesText}
              name="needByTime"
              placeHolder="Select Time"
              selectpropsClassName={
                data.deliveryPreference.value.needByTime.value
                  ? "delivery-preference-select"
                  : "placeHolder"
              }
              selectClassName={
                data.deliveryPreference.value.needByTime.value
                  ? "delivery-preference-input "
                  : "placeHolder"
              }
              testId="delivery-preference-needByTime"
              value={
                data.deliveryPreference.value.needByTime.value
                  ? getTextFromCode(
                      inpatientOrderObj?.needTimes,
                      data.deliveryPreference.value.needByTime.value
                    )
                  : null
              }
            />
          </InputWithLabel>
        </Grid>

        <Grid className="delivery-preference-grid-item" xs={12}>
          <Grid className="delivery-preference-eta">
            <div
              className="estimated-arrival-time-component"
              data-testid="estimated-arrival-time-component"
              id="estimated-arrival-time-component"
            >
              <EstimatedArrivalTimeMain
                data={data.deliveryPreference.value}
                validateAndSetData={validateAndSetData}
                isInPatientOrder={true}
                isSolventumProduct={true}
              />
            </div>
            <InputWithLabel
              error={
                data.deliveryPreference.value.deliveryInstructions.valid ===
                ValidationStatus.INVALID
              }
              id={
                data.deliveryPreference.value.deliveryInstructions.componentId!
              }
              isRequired={
                data.deliveryPreference.value.deliveryInstructions.required
              }
              isShowWarning={true}
              label={data.deliveryPreference.value.deliveryInstructions.title!}
              labelClassName="deliveryInstructionLabel"
              warningMessage={
                data.deliveryPreference.value.deliveryInstructions.errorMessage
              }
            >
              <InputBase
                className="deliveryPreference-deliveryInstructions-input"
                data-testid="delivery-Preference-delivery-instruction-Test"
                error={
                  data.deliveryPreference.value.deliveryInstructions.valid ===
                  ValidationStatus.INVALID
                }
                id={data.deliveryPreference.value.deliveryInstructions.id!}
                inputProps={{
                  className:
                    data.deliveryPreference.value.deliveryInstructions.valid ===
                    ValidationStatus.INVALID
                      ? "showDeliveryInstructionError"
                      : "",
                  maxLength: 200,
                }}
                multiline={true}
                name="deliveryInstructions"
                onChange={validateAndSetData}
                rows={2}
                value={
                  data.deliveryPreference.value.deliveryInstructions.value!
                }
              />
            </InputWithLabel>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
