import { createContext, useState } from "react";
import { getDeepClone } from "../util/ObjectFunctions";
import { IUserProfile } from "../components/administration/manageUsers/userProfile/userProfile.interface";
import { defaultUserProfileData } from "../components/administration/manageUsers/userProfile/userProfile.model";

export type UserProfileContextType = {
  profileDetails: IUserProfile;
  setProfileDetails: React.Dispatch<React.SetStateAction<IUserProfile>>;
  resetUserProfile: () => void;
  updateUserProfilePromptFlag: boolean;
  setUpdateUserProfilePromptFlag: React.Dispatch<React.SetStateAction<boolean>>;
  userProfileGotChanged: boolean;
  setUserProfileGotChanged: React.Dispatch<React.SetStateAction<boolean>>;
};

type UserProfileContextProviderProps = {
  children: React.ReactNode;
};

export const UserProfileContext = createContext<UserProfileContextType | null>(
  null
);

export const UserProfileContextProvider = ({
  children,
}: UserProfileContextProviderProps) => {
  const [profileDetails, setProfileDetails] = useState<IUserProfile>(
    getDeepClone(defaultUserProfileData)
  );
  const [updateUserProfilePromptFlag, setUpdateUserProfilePromptFlag] =
    useState(false);
  const [userProfileGotChanged, setUserProfileGotChanged] = useState(false);
  const resetUserProfile = () => {
    setProfileDetails(getDeepClone(defaultUserProfileData));
    setUpdateUserProfilePromptFlag(false);
    setUserProfileGotChanged(false);
  };
  return (
    <UserProfileContext.Provider
      value={{
        profileDetails,
        setProfileDetails,
        resetUserProfile,
        updateUserProfilePromptFlag,
        setUpdateUserProfilePromptFlag,
        userProfileGotChanged,
        setUserProfileGotChanged,
      }}
    >
      {children}
    </UserProfileContext.Provider>
  );
};
