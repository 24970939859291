import "./nationalRegistryResultTable.css";
import Table from "../../../../../../../../core/customSortingTable/table.component";
import { IPrescriberInformation } from "../../../../prescriberInformation.interface";
import { makeCapitalEachWordInString } from "../../../../../../../../util/utilityFunctions";

export interface INationalRegistryResultTable {
  columns: {}[];
  nationalRegistries: IPrescriberInformation[];
  handleSorting: Function;
  selectButtonAction: Function;
  selectedNpi?: string;
}

export const NationalRegistryResultTable = ({
  columns,
  nationalRegistries,
  handleSorting,
  selectButtonAction,
  selectedNpi,
}: INationalRegistryResultTable) => {
  return (
    <div
      className="national-registry-result-table-component"
      data-testid="national-registry-result-table-component"
    >
      <Table
        tableClassName="table"
        tableColumns={columns}
        handleSorting={handleSorting}
      >
        {nationalRegistries.length > 0 ? (
          <tbody>
            {nationalRegistries.map((data: IPrescriberInformation) => {
              let isNpiSelected =
                selectedNpi !== "" && selectedNpi === data.npi;
              return (
                <tr>
                  <td
                    className={
                      isNpiSelected
                        ? "selected-button-disabled"
                        : "select-national-registry-link"
                    }
                    data-testid="select-national-registry-link"
                    id="select-national-registry-link"
                    onClick={() => !isNpiSelected && selectButtonAction(data)}
                  >
                    {!isNpiSelected ? "Select" : "Current"}
                  </td>
                  <td
                    className="table-static-data"
                    data-testid="prescriber-full-name-value"
                    id="prescriber-full-name-value"
                  >{`${makeCapitalEachWordInString(
                    data.lastName
                  )}, ${makeCapitalEachWordInString(data.firstName)}`}</td>
                  <td
                    className="table-static-data"
                    data-testid="prescriber-npi-value"
                    id="prescriber-npi-value"
                  >
                    {data.npi}
                  </td>
                  <td
                    className="table-static-data"
                    data-testid="prescriber-city-value"
                    id="prescriber-city-value"
                  >
                    {makeCapitalEachWordInString(data.city)}
                  </td>
                  <td
                    className="table-static-data"
                    data-testid="prescriber-state-value"
                    id="prescriber-state-value"
                  >
                    {data.state}
                  </td>
                </tr>
              );
            })}
          </tbody>
        ) : (
          ""
        )}
      </Table>
    </div>
  );
};
